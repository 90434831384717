//React
import React from 'react';
//import * as ReactDOM from 'react-dom';
//Apollo
import { Query } from "react-apollo";
//Components
import GridDay from './../components/GridView/GridDay/GridDay'
import DayListView from "./../components/DayListView/DayListView";
import { Spinner, Stack, Label } from 'office-ui-fabric-react/lib';
import DownloadDialog from './../components/DetailView/DetailPivot/PivotElements/Transscript/DownloadDialog'
import SearchExport from './../components/DetailView/DetailPivot/PivotElements/Transscript/SearchExport'
import { MyContext } from '../context';
import gql from "graphql-tag";

export const queryGetEntry = gql`

        query ent ($id : ID!, $auth:AuthInput, $callStartMs: String ) {
          getEntry (input:$auth, id: $id, callStartMs: $callStartMs) {
            id
            versionId
            conversationId
            scenarioId
            botAppId
            onDemand
            callId
            hasVideo
            hasAudio
            hasChat
            hasFiles
            hasScreenSharing
            errorSpeechToText
            isVideoProcessing
            timein
            timeout
            topic
            day
            duration
            userId
            botAppId
            recordingType
            mediaAssets
            mediaAssetVideo
            analyticsStatus
            assetStatus
            languageTranscript
            notes
            participantStreams
            needsRequest
            needsApproval
            requestUser
            approvedUser
            approvedUserName
            replayAllowed
            replayRequestUserName 
            ttl
            deleteRequestUser
            deleteRequestUserName
            deleteAllowance
            deleteReasoning
            deleteReasoningText
            deletePropose
            stopReason
            conversationSafe
            isWrapUpCall
            keepDeleteAllowed
            encKeyUrl
            transferorName
            conversationSafeFolders{
                id
                name,
                userId,
                supervisors{
                    userId
                }
                exportFolders
            }
            aiCategorizationPolicies
            analyticRemark
            tenantId
            participantOffset
            requestReplayReasoningText
            requestRestoreArchiveReasoningText
            archiveStatus
            archiveAllowed
            archiveAccessType
            isRestoreApproved
            isApprover
            isDeleteApproverUser
            hasRestoreStarted
            customFields
            callStartMs
            engagementId
            chatId
            linkedUser
        	    {
                id
                userId
                isRecordingOwner
                tenantId
                name
                deviceDisplayName
                detail
                userPrincipal
                photo
                joinDateTime
                leftDateTime
                videoAsset
                audioAsset
                type
                recordingType
                onBehalfOfUserId
                }
        summarizedInsights
          {
            categories
            sentiments
                    {
                    sentimentKey
                    appearances
                        {
                        startSeconds
                        endSeconds
                        startTime
                         }

                  }
            labels
                    {
                        name
                        appearances
                            {
                            startSeconds
                            startTime
                          }
                    }
            topics
                    {
                        name
                        referenceUrl
                        appearances
                            {
                            startSeconds
                            startTime
                          }
                    }
            keywords
                    {
                    name
                    isTranscript
                    categorie
                    hasSearchExactKeywords
                    appearances
                        {
                        startSeconds
                        startTime
                        endTime
                        endSeconds
                      }
                  }
          }
          waveFormAnalysis
            {
                isAllAudioFileSilence
                isFileZeroLength
                isEveryValueZero
                isStreamNullValue
                isAudioLenghtZero
                endTimeMiliseconds
                rmsAudioSegments
                stepCountAudioSegments
                silenceStartStopList
                    {
                        startTime
                        stopTime
                    }
            }
          unifiedTranscript
          	{
              language
              transcript
              	{
                      language
                      reactions
                    {
                        reactionType
                        userId
                    }
                  text
                  type
                  url
                  speakerId
                  speakerUserId
                  changedNote
                  changedTime
                  priority
                  instances
                  	{
                     start
                      end
                      adjustedStart
                      adjustedEnd
                    }
                }
                speakers {
                    name
                }
            }

          }
        }
    `;
export const queryGetEntryByFolder = gql`

        query ent ($id : ID!, $folderId : ID! $auth:AuthInput, $callStartMs: String ) {
          getEntryByFolder (input:$auth, id: $id, folderId: $folderId, callStartMs: $callStartMs) {
            id
            conversationId
            scenarioId
            botAppId
            onDemand
            callId
            hasVideo
            hasAudio
            hasChat
            hasFiles
            hasScreenSharing
            timein
            timeout
            topic
            day
            duration
            userId
            botAppId
            recordingType
            mediaAssets
            mediaAssetVideo
            analyticsStatus
            assetStatus
            languageTranscript
            notes
            participantStreams
            needsRequest
            needsApproval
            requestUser
            approvedUser
            replayAllowed
            ttl
            deleteRequestUser
            deleteAllowance
            deleteReasoning
            deleteReasoningText
            deletePropose
            conversationSafe
            conversationSafeFolders{
                id
                name,
                userId,
                supervisors{
                    userId
                }
                exportFolders
            }
            analyticRemark
            tenantId
            participantOffset
            requestReplayReasoningText
            requestRestoreArchiveReasoningText
            archiveStatus
            archiveAllowed
            archiveAccessType
            isRestoreApproved
            isApprover
            hasRestoreStarted
            customFields
            callStartMs
            engagementId
            linkedUser
        	    {
                id
                userId
                isRecordingOwner
                tenantId
                name
                deviceDisplayName
                detail
                userPrincipal
                photo
                joinDateTime
                leftDateTime
                videoAsset
                audioAsset
                type
                recordingType
                }
        summarizedInsights
          {
            categories
            sentiments
                    {
                    sentimentKey
                    appearances
                        {
                        startSeconds
                        endSeconds
                        startTime
                         }

                  }
            labels
                    {
                        name
                        appearances
                            {
                            startSeconds
                            startTime
                          }
                    }
            topics
                    {
                        name
                        referenceUrl
                        appearances
                            {
                            startSeconds
                            startTime
                          }
                    }
            keywords
                    {
                    name
                    isTranscript
                    categorie
                    hasSearchExactKeywords
                    appearances
                        {
                        startSeconds
                        startTime
                        endTime
                        endSeconds
                      }
                  }
          }
          waveFormAnalysis
            {
                isAllAudioFileSilence
                isFileZeroLength
                isEveryValueZero
                isStreamNullValue
                isAudioLenghtZero
                endTimeMiliseconds
                rmsAudioSegments
                stepCountAudioSegments
                silenceStartStopList
                    {
                        startTime
                        stopTime
                    }
            }
          unifiedTranscript
          	{
              language
             
              transcript
              	{
                  reactions
                    {
                        reactionType
                        userId
                    }
                  text
                  type
                  url
                  speakerId
                  speakerUserId
                  changedNote
                  changedTime
                  priority
                  instances
                  	{
                     start
                      end
                      adjustedStart
                      adjustedEnd
                    }
                }
                speakers {
                    name
                }
            }

          }
        }
    `;

export const queryGetLiveEntry = gql`
        query ent ($id : ID!, $auth:AuthInput) {
          getLiveEntry (input:$auth, id: $id) {
            id
            userId
            conversationId
            scenarioId
            botAppId
            callId
            timein
            topic
            day
            onDemand
            replayAllowed
            hasRestoreStarted
            disableNextRecording
            callStartMs
            activeConversation
            customFields
            rodReasons
            keepDeleteAllowed
            deleteState
            wrapUpDateTime
            hasTimer
            isWrapUpCall
            unifiedTranscript
          	{
              transcript
              	{
                  text
                  type
                  speakerId
                  speakerUserId
                  url
                  instances
                  	{
                     start
                      end
                      adjustedStart
                      adjustedEnd
                    }
                }
            }
        }
}
    `;

export const queryGetFilterRecordings = gql`
       query getFilterRecordings ($val : String!, $filter: RecordingFilterInputType, $withSave: Boolean, $auth:AuthInput, $path: String, $searchIn: [String]){
          getFilterRecordings(search:$val, filter:$filter, withSave:$withSave, input:$auth, path:$path, searchIn: $searchIn)
          {
            userRules
  	        {
              blockDeletion
              defaultFilter {
                 id
                 type
                 cycle
                 startDate
                 endDate
                 categories
                 policies
                 persons
                 durationFrom
                 durationTo
              }
              recordingFilter 
              {
                 id
                 type
                 cycle
                 startDate
                 endDate
                 persons
                 defaultFilter
                 userFilter
                 categories
                 integrations
                 policies
                 folders
                 timeBasedType
                 durationFrom
                 durationTo
              }
            }
            statistics 
            {
              count
              inform
            }
            days
  	        {
              date
              content
              {
                    day
                    timein
                    timeout
                    topic
                    id
                    conversationId
                    policies{
                        policyId
                        monitoredBy
                        riskRating
                    }
                    recordingType
                    duration
                    thumbnail
                    speech2Text
                    notes
                    isVideo
                    hasVideo
                    hasAudio
                    hasChat
                    hasFiles
                    hasScreenSharing
                    replayAllowed
                    archiveAllowed
                    archiveAccessType
                    isRestoreApproved
                    isApprover
                    participantCount
                    participantShort
                    callStartMs
                    ownerDisplayName
                    userId
                    archiveStatus
                    hasRestoreStarted
                    customFields
                    conversationSafe
                    scenarioId
                    keepDeleteAllowed
                    deleteState
                    transferorName
                    conversationSafeFolders{
                        id
                        name
                    }
                    ttl
                }
            }
        }
       }
    `;


export const queryRecordings = gql`
     query ent ($auth:AuthInput)
     {
        getRecordings(input:$auth) 
        {
          userRules
  	      {
              access
              valid
              analytics
              viewType
              blockDeletion
              visibilityDetails
              visibilityDetailsTranscript
              visibilityDetailsAnalytics
              visibilityDetailsAnalyticsChange
              visibilityDetailsNotes
              visibilityDetailsTTL
              visibilityDetailsCustomFields
              visibilityDetailsMetadata
              visibilityDetailsQM
              defaultFilter {
                 id
                 type
                 cycle
                 startDate
                 endDate
                 categories
                 persons
                 policies
                 recordingOwner
                 durationFrom
                 durationTo
              }
              recordingFilter {
                 id
                 type
                 cycle
                 startDate
                 endDate
                 categories
                 persons
                 defaultFilter
                 userFilter
                 integrations
                 policies
                 recordingOwner
                 durationFrom
                 durationTo
                 timeBasedType
              }
              ruleAnalytics {
                withSentiment
                withTranscription
                withVideoIndexer
                transcriptLanguage
                translationType
                translationLanguage
                accessType
              }
              ruleAccess {
                userLiveView
                userDenyExport
                userDenyUpload
                userBulkExport
                userBulkRestoreArchive
                userBulkSafe
                userBulkDelete
                userBulkTranscript
                userBulkExcel
                userBulkPasswordLength
                userSafe
                userReporting
                tenantCreate
                tenantChange
                confListUser
                confAddUser
                confTicket
                confRulesRecording
                confRulesReplay
                confRulesAccess
                confRulesAnalytics
                confMapUser
                confUserAssignment
                confAssignRecording
                confAssignReplay
                confAssignAccess
                confAssignAnalytics
                userAssignmentUsers
                confAuditLogs
                confJobs
                userKpiWallboard
                archivedRequestRetrievalReasoningRequired
                userCompliancePolicy
                userCompliancePolicyActivate
                userCompliancePolicyEdit
                userCompliancePolicyAssign
                hasCustomFieldsAccess
                customFieldsAccessType
                accesibleCustomFields
              }
              ruleRecording {
                audioType
                inboundType
                disableNextRecording
                demandStartOnly
              }
              allowedTenants {
                name
                id
            }
          }
          statistics {
            count
            inform
          }
         }
      }
    `;

//getFilterRecordings
export const renderSearchTeams = (isGrid, val, filter, withSave, auth, cc, userRules, path, theme, searchIn) => (
    <Query
        query={queryGetFilterRecordings}
        variables={{ val, filter, auth, withSave, path, searchIn}}  >
        {({ loading, error, data }) => {

            if (loading) return <MyContext.Consumer>{(context) => (<div><Spinner label={context.getTranslation("configuration", "loadingConversations")} ariaLive="assertive" labelPosition="top" /></div>)}</MyContext.Consumer>;
            if (error) return <div></div>;
            let cntDays = data.getFilterRecordings.days.length;
            let bulkOperation = userRules.ruleAccess != null && (userRules.ruleAccess.userBulkExport || userRules.ruleAccess.userBulkRestoreArchive || userRules.ruleAccess.userBulkSafe || userRules.ruleAccess.userBulkDelete || userRules.ruleAccess.userBulkTranscript);
            return <MyContext.Consumer>{(context) => (<div >
                {isGrid && <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <div className="app-statistics">{data.getFilterRecordings.statistics.count + " " + context.getTranslation("common", "conversations") + " " + data.getFilterRecordings.statistics.inform}</div>
                    {bulkOperation && data.getFilterRecordings.statistics.count > 0 &&
                        <Stack.Item>
                            <DownloadDialog
                                refetchQueryFields={{val, filter, auth, withSave, path}}
                                client={cc}
                                authorization={auth}
                                bulkOperation={true}
                                bulkExport={data.getFilterRecordings.days}
                                userRules={userRules}
                                count={data.getFilterRecordings.statistics.count}
                                passwordLength={(userRules.ruleAccess != null && userRules.ruleAccess.userBulkPasswordLength != undefined) ? userRules.ruleAccess.userBulkPasswordLength : 8}
                            />
                        </Stack.Item>
                    }
                    {userRules.ruleAccess != null && !userRules.ruleAccess.userBulkExcel && <Stack.Item>
                        <SearchExport
                            data={data.getFilterRecordings.days}
                            userRules={userRules}
                            apol={cc}
                            count={data.getFilterRecordings.statistics.count}
                        />
                    </Stack.Item>}
                </Stack>}

                    {isGrid ? data.getFilterRecordings.days.map(
                        ({ date, content }) => (
                            <GridDay
                                apol={cc}
                                key={date}
                                dayTitle={date}
                                date={date}
                                dayContent={content}
                                authorization={auth}
                                userRules={userRules}
                                cntDays={cntDays}
                                onlyOne={data.getFilterRecordings.statistics.count == 1}
                                theme={theme}
                            />)
                ) : <DayListView
                        refetchQueryFields={{ val, filter, auth, withSave, path, searchIn }}
                        apol={cc}
                        authorization={auth}
                        userRules={userRules}
                        data={data.getFilterRecordings.days}
                        theme={theme}
                        count={data.getFilterRecordings.statistics.count}
                        inform={data.getFilterRecordings.statistics.inform}
                    />}
                </div>)}
                </MyContext.Consumer>
        }}
    </Query>
);

export const renderZoomAuthorize = (isGrid, id, filter, withSave, auth, cc, userRules, path, theme) => (
    <Query
        query={gql`
       query ent ($id: String, $path: String){
          authenticateZoom(id: $id, path:$path)
       }
    `}
        variables={{ id, path }}  >
        {({ loading, error, data }) => {

            if (loading) return <MyContext.Consumer>{(context) => (<div><Spinner label={context.getTranslation("configuration", "authenticateWithZoom")} ariaLive="assertive" labelPosition="top" /></div>)}</MyContext.Consumer>;
            if (error) return <MyContext.Consumer>{(context) => (<Stack><Stack.Item align="center"><Label> {context.getTranslation("configuration", "authenticationError")} </Label></Stack.Item></Stack>)}</MyContext.Consumer>;
            return <MyContext.Consumer>{(context) => (<Stack><Stack.Item align="center"><Label> {data.authenticateZoom ? context.getTranslation("configuration", "authenticationSuccess") : context.getTranslation("configuration", "authenticationError")} </Label></Stack.Item></Stack>)}</MyContext.Consumer>;
        }}
    </Query>
);
