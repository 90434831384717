import React from 'react'
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption, SelectableOptionMenuItemType, TextField, ComboBox, FontWeights, Persona, PersonaSize } from 'office-ui-fabric-react/lib';
import { queryGetConfigData, mutationCreateRuleRecording, mutationCreateRuleReplay, mutationCreateRuleAccess, mutationCreateRuleAnalytics, getAllCustomFieldDefinitions } from "../../../queries/querySettings";
import { mutationUpdateRuleRecording, mutationUpdateRuleReplay, mutationUpdateRuleAccess, mutationUpdateRuleAnalytics, getCognitiveServicesLocalesTranscription, checkRetentionTimeValidity } from "../../../queries/querySettings";
import { mutationDeleteRuleRecording, mutationDeleteRuleReplay, mutationDeleteRuleAccess, mutationDeleteRuleAnalytics } from "../../../queries/querySettings";

import UserPicker from './../../ReportView/ReportConfiguration/UserPicker';
import DateTimePicker from 'react-datetime-picker';

import { Link } from 'office-ui-fabric-react/lib/Link';
import Popup from "reactjs-popup";
import Utils from "../../../components/common/Utils";
import RestrictedAccessForm from "./RestrictedAccessForm"
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { MessageBar, MessageBarButton, MessageBarType, TeachingBubble } from 'office-ui-fabric-react/lib';
import { toast } from "react-toastify";
import {
    SelectionMode,
    Selection,
    DetailsList,
    Slider,
    DetailsListLayoutMode,
    IconButton, Modal,
    Spinner,
    CommandBarButton,
} from "office-ui-fabric-react";

import {
    Pivot,
    PivotItem,
    Checkbox,
    ChoiceGroup,
    DefaultButton,
    Text,
    Stack,
    Button,
    Label
} from "office-ui-fabric-react";
import { mergeStyleSets } from '@uifabric/merge-styles';

import { MyContext } from '../../../context';
import { TranscriptLanuages } from '../../SettingsView/TranscriptLangArr';
import { TranslateLanuages } from './../../SettingsView/TranslateLangArr'
import { Query } from 'react-apollo';
import NumberPrefix from './NumberPrefix';
import UploadAnnouncement from "../UploadAnnouncement";
import ConversationSafeAccessRule from './AddRuleComponents/ConversationSafeAccessRule';
import SafetyDialog from '../../CustomComponents/SafetyDialog';
import VisibilityReplayRule from './AddRuleComponents/VisibilityReplayRule'
import KeepDeleteSettings from '../RuleList/AddRuleComponents/KeepDeleteSettings'


const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 250 }
};
const styles = {
    root: {
        paddingTop: "20px"
    }
};
const checkboxStyles = () => {
    return {
        root: {
            marginTop: '10px'
        }
    };
};
const listStyles = () => {
    return {
        root: {
            padding: '20px',
            height: '100%'
        }
    };
};
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        {
            flex: '1 1 auto',
            borderTop: '4px solid blue',
            // color: theme.palette.neutralPrimary,
            display: 'flex',
            // fontSize: FontSizes.xLarge,
            alignItems: 'center',
            // fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0',
            },
            'p:first-child': {
                marginTop: 0,
            },
            'p:last-child': {
                marginBottom: 0,
            },
        },
    },
    userList: {
        display: "inline-block",
        margin: "2px"
    },
    userNameAlign: {
        'vertical-align': 'sub',
        display: 'inline-block',
        'line-height': 'normal',
        'margin-left': '5px',
        'text-overflow': 'ellipsis'

    },
    groupList: {
        margin: "2px"
    }
});

const iconButtonStyles = mergeStyleSets({
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    }
});
const secondsBoxStyles = () => {
    return {
        root: {
            width: '100px'
        }
    };
};
export default class AddRule extends React.Component {

    constructor(props) {
        super(props);
        console.log(props)
        this.handleChange = this.handleChange.bind(this);
        this.modifyState = this.modifyState.bind(this);
        this.transcriptLanguagesList = [];
        this.tmpUserUsage = [];
        this.displayUsers = [];
        this.tmpGroupUsage = [];
        this.displayGroups = [];
        if (this.props.dataUsers != null) {
            for (let x = 0; x < this.props.dataUsers.length; x++) {
                if (this.props.data != null && this.props.dataUsers[x].groupId == null) {
                    if ((this.props.dataUsers[x].access != null && this.props.dataUsers[x].access.includes(this.props.data.id))
                        || (this.props.dataUsers[x].replay != null && this.props.dataUsers[x].replay.includes(this.props.data.id))
                        || (this.props.dataUsers[x].recordingRules != null && this.props.dataUsers[x].recordingRules.includes(this.props.data.id))
                        || (this.props.dataUsers[x].analytics != null && this.props.dataUsers[x].analytics.includes(this.props.data.id))
                    ) {
                        this.tmpUserUsage.push(this.props.dataUsers[x].userId);
                        this.displayUsers.push({ "userid": this.props.dataUsers[x].userId, "name": this.props.dataUsers[x].name });
                    }
                }
            }
        }
        if (this.props.dataGroups != null) {
            for (let x = 0; x < this.props.dataGroups.length; x++) {
                if (this.props.data != null) {
                    if ((this.props.dataGroups[x].access != null && this.props.dataGroups[x].access.includes(this.props.data.id))
                        || (this.props.dataGroups[x].replay != null && this.props.dataGroups[x].replay.includes(this.props.data.id))
                        || (this.props.dataGroups[x].recordingRules != null && this.props.dataGroups[x].recordingRules.includes(this.props.data.id))
                        || (this.props.dataGroups[x].analytics != null && this.props.dataGroups[x].analytics.includes(this.props.data.id))
                    ) {
                        this.tmpGroupUsage.push(this.props.dataGroups[x].name);
                        this.displayGroups.push({ "id": this.props.dataGroups[x].id, "name": this.props.dataGroups[x].name });
                    }
                }
            }
        }

        this.state = {
            saveMessage: false,
            selectedItem: { key: "recording" },
            keyword: "",
            name: "",
            group: "recording",
            date: "730",
            text: "",
            time: "",
            filterEnabled: false,
            visibility: "",
            replay: "",
            sel4list: [],
            sel4listEntities: [],
            sel4listKeyword: [],
            selectedOptionKeys: "",
            selectedOptionEntities: "",
            selectedOptionKeyword: "",
            recAudio: false,
            recVideo: false,
            recScreenOnly: false,
            recChat: false,
            recAudioType: "Bulk",
            recChatType: "Bulk",
            recChatTypeDeny: false,
            recChannelType: "Never",
            recAreaCode: "",
            recInboundType: "Bulk",
            recOutboundType: "Bulk",
            recMeetingType: "Bulk",
            recMeetingExternalType: "Bulk",
            recMeetingInternalType: "Bulk",
            recInternalType: "Bulk",
            recSilentRecording: false,
            recInboundSpecial: "No",
            recOutboundSpecial: "No",
            recMeetingExternalSpecial: "No",
            recMeetingInternalSpecial: "No",
            recInternalSpecial: "No",
            recMeetingOrganizer: "",
            recMeetingParticipant: "",
            recUserStreamOnly: false,
            recAllowList: "",
            recDenyList: "",
            recDemandStartOnly: false,
            recChannelList: "",
            recMeetingSubjectDenyList: "",
            recMeetingSubjectAllowList: "",
            accListUser: false,
            accAddUser: false,
            accTicket: false,
            accRulesRecording: false,
            accRulesReplay: false,
            accRulesAccess: false,
            accRulesAnalytics: false,
            accMapUser: false,
            accAssignment: "No",
            accAssignRecording: false,
            accAssignReplay: false,
            accAssignAccess: false,
            accAssignAnalytics: false,
            accAuditLogs: false,
            accJobs: false,
            accReporting: "No",
            accKpiTotal: false,
            accKpiIncoming: false,
            accKpiOutgoing: false,
            accKpiKeywords: false,
            accKpiWallboard: false,
            accUserDelete: "No",
            accUserPropose: false,
            accUserProposeReasoning: false,
            accUserDenyExport: false,
            accUserDenyUpload: false,
            accUserBulkExport: false,
            accUserBulkRestoreArchive: false,
            accUserBulkSafe: false,
            userBulkSafeAdd: false,
            userBulkSafeRemove: false,
            accUserBulkDelete: false,
            accUserBulkTranscript: false,
            accUserBulkExcel: false,
            accUserBulkIpRange: "",
            accUserBulkPasswordLength: 8,
            accUserArchievedConversationType: "NoAccess",
            accUserRequestArchiveRetrievalProcessReasoning: false,
            complianceResultsAccess: false,
            complianceQMResultsAccess: false,
            accUserCompliancePolicy: false,
            accUserCompliancePolicyActivate: false,
            accUserCompliancePolicyEdit: false,
            accUserCompliancePolicyAssign: false,
            accUserComplianceAIQuestions: false,
            userComplianceQM: false,
            anaType: "NoAnalytics",
            anaStartDate: "",
            anaEndDate: "",
            anaTranscription: false,
            anaSpeechLanguage: "",
            anaSentiment: false,
            anaKeyphrase: false,
            anaTranslate: "No",
            anaVisibility: "All",
            anaVideoIndexer: false,
            anaDemandSpeech2Text: false,
            anaDemandCategories: false,
            anaDemandKeywords: false,
            anaIndividualKeywords: false,
            anaTranslationLanguage: "",
            translation: false,
            anaHasSpeakerRecognition:false, 
            accLiveView: false,
            updated: false,
            updatedName: false,
            internalRule: false,
            visibilityUser: [],
            replayUser: [],
            userProposeUsers: [],
            userAssignmentUsers: [],
            replayFilterEnabled: false,
            replayFilterType: "",
            replayFilterCycle: "",
            replayFilterStartDate: '',
            replayFilterEndDate: '',
            replayVisibilityDetails: false,
            replayVisibilityDetailsNotes: false,
            replayVisibilityDetailsTranscript: false,
            replayVisibilityDetailsTTL: false,
            replayVisibilityDetailsCustomFields: false,
            replayVisibilityDetailsMetadata: false,
            visibilityDetailsQM: false,
            replayVisibilityDetailsAnalytics: false,
            replayVisibilityDetailsAnalyticsChange: false,
            denyReportAlarming: false,
            allowTTLChangeViaCategorization: false,
            bulkIpRangeError: "",
            alternativeLanguage: false,
            optionAlternativeLanguageKey: "",
            maxAlternativeLanguages: 3,
            delegateFeature: false,
            recSwitchInitialState: false,
            recDisableNextRecording: false,
            showModal: false,
            modalDisplay: "",
            pstnAnnouncement: false,
            multilingualConversations: false,
            approvedUsersReplay: [],
            hasCustomFieldsAccess: false,
            customFieldsAccessType: "",
            customFieldsEditableTime: 1,
            accesibleCustomFields: [],
            editableTimeCustomFields: false,
            ascBeeptone: false,
            ascBeeptoneFrequency: 5,
            announcementType: "DefaultAnnouncement",
            selectFileStart: "",
            selectedFileStop: "",
            defaultCustomAnnouncement: false,
            prefixNumber: "",
            announcements: [],
            numberPrefixIndex: 1,
            defaultAnnouncement: {
                prefixList: ["default"],
                start: "",
                stop: ""
            },
            deletedFilesFromAnnouncements: [],
            rodDmtfSTart: "*999*",
            rodDmtfStop: "#999#",
            isDtmfEnabled: false,
            isDtmfAnnouncementSuppressionEnabled: false,
            announcementSuppressionCode: "*345#",
            announcementSuppressionTimeInDays: 180,
            accMergeAudioToVideo: false,
            recReevaluateChangeFromOneToOneToMeeting: false,
            recTransferorAllowList: [],
            recTransferorDenyList: [],
            conversationSafeAccess: null,
            analyzeAllConditions: [],
            ttlBtnMessage: false,
            ttlConfirmed: false,
            replayDurationFrom: -1,
            replayDurationTo: -1,
            replayRecordingOwner: false,
            replayFilterPersons: "",
            timeBasedType: "",
            filterUpdated: false,
            replayCycle: "",
            keepDeleteSettingsNonComplex: {
                type: "NonComplex",
                startMode: "Keep",
                hasWrapUp: false,
                wrapUpTimer: 60,
                recordingMode: "Bulk",
                isWrapUpOnly: false,
                actions: false,
                hasPopupAlert: false,
                isSendToDo: false,
                toDoTimer: 1,
                hasUserNotification: false,
                specificSettings: false,
                isActive: false
            },
            keepDeleteSettingsInbound: {
                type: "Inbound",
                startMode: "Keep",
                hasWrapUp: false,
                wrapUpTimer: 60,
                recordingMode: "Bulk",
                isWrapUpOnly: false,
                actions: false,
                hasPopupAlert: false,
                isSendToDo: false,
                toDoTimer: 1,
                hasUserNotification: false,
                specificSettings: false,
                isActive: false
            },
            keepDeleteSettingsOutbound: {
                type: "Outbound",
                startMode: "Keep",
                hasWrapUp: false,
                wrapUpTimer: 60,
                recordingMode: "Bulk",
                isWrapUpOnly: false,
                actions: false,
                hasPopupAlert: false,
                isSendToDo: false,
                toDoTimer: 1,
                hasUserNotification: false,
                specificSettings: false,
                isActive: false
            },
            keepDeleteSettingsMeeting: {
                type: "Meeting",
                startMode: "Keep",
                hasWrapUp: false,
                wrapUpTimer: 60,
                recordingMode: "Bulk",
                isWrapUpOnly: false,
                actions: false,
                hasPopupAlert: false,
                isSendToDo: false,
                toDoTimer: 1,
                hasUserNotification: false,
                specificSettings: false,
                isActive: false
            },
            keepDeleteSettingsMeetingExternal: {
                type: "ExternalMeeting",
                startMode: "Keep",
                hasWrapUp: false,
                wrapUpTimer: 60,
                recordingMode: "Bulk",
                isWrapUpOnly: false,
                actions: false,
                hasPopupAlert: false,
                isSendToDo: false,
                toDoTimer: 1,
                hasUserNotification: false,
                specificSettings: false,
                isActive: false
            },
            keepDeleteSettingsMeetingInternal: {
                type: "InternalMeeting",
                startMode: "Keep",
                hasWrapUp: false,
                wrapUpTimer: 60,
                recordingMode: "Bulk",
                isWrapUpOnly: false,
                actions: false,
                hasPopupAlert: false,
                isSendToDo: false,
                toDoTimer: 1,
                hasUserNotification: false,
                specificSettings: false,
                isActive: false
            },
            keepDeleteSettingsInternal: {
                type: "Internal",
                startMode: "Keep",
                hasWrapUp: false,
                wrapUpTimer: 60,
                recordingMode: "Bulk",
                isWrapUpOnly: false,
                actions: false,
                hasPopupAlert: false,
                isSendToDo: false,
                toDoTimer: 1,
                hasUserNotification: false,
                specificSettings: false,
                isActive: false
            },
            roDStopReasons: "",
            roDStopReasonRequired: false,
            rodStopReasonsPlaceholder: "Up to 10 reasons, separated by '|' (e.g., 'Reason 1 | Reason 2')",
            isNameValidationError: ""
        };
        if (this.props.data != null) {
            if (this.props.data.group === "replay") {
                let visibilityUsers = [];
                let visibilityUsersSource = (this.props.data.visibilityUser === "undefined" || this.props.data.visibilityUser == null || this.props.data.visibilityUser == "") ? "" : this.props.data.visibilityUser.split(',');
                for (let x = 0; x < visibilityUsersSource.length; x++) {
                    visibilityUsers.push({
                        userId: visibilityUsersSource[x]
                    });
                }
                let replayUsers = [];
                let replayUsersSource = (this.props.data.replayUser === "undefined" || this.props.data.replayUser == null || this.props.data.replayUser == "") ? "" : this.props.data.replayUser.split(',');
                for (let x = 0; x < replayUsersSource.length; x++) {
                    replayUsers.push({
                        userId: replayUsersSource[x]
                    }
                    );
                }
                let categoriesList = [];
                let categoriesListSource = [];
                if (this.props.data.recordingFilter != null && this.props.data.recordingFilter.categories != null) {
                    categoriesListSource = this.props.data.recordingFilter.categories;
                    for (let x = 0; x < categoriesListSource.length; x++) {
                        categoriesList.push({
                            key: categoriesListSource[x],
                            id: categoriesListSource[x]
                        }
                        );
                    }
                }
                this.integrationList = [];
                this.integrationListSource = [];
                if (this.props.data.recordingFilter != null && this.props.data.recordingFilter.integrations != null) {
                    this.integrationsListSource = this.props.data.recordingFilter.integrations;
                    for (let x = 0; x < this.integrationsListSource.length; x++) {
                        this.integrationList.push({
                            key: this.integrationsListSource[x],
                            id: this.integrationsListSource[x]
                        }
                        );
                    }
                }
                console.log(this.integrationList);

                let typeListSource = [];
                if (this.props.data.recordingFilter != null && this.props.data.recordingFilter.type != null && this.props.data.recordingFilter.type != "") {
                    typeListSource = this.props.data.recordingFilter.type.split(',');
                }

                this.listPersons = [];
                if (this.props.data.recordingFilter != null && this.props.data.recordingFilter.persons != null && this.props.data.recordingFilter.persons !== "") {

                    for (let x = 0; x < this.props.data.recordingFilter.persons.length; x++) {
                        this.listPersons.push({
                            userId: this.props.data.recordingFilter.persons[x],
                            key: this.props.data.recordingFilter.persons[x]
                        }
                        );
                    }
                }

                this.state = {
                    selectedItem: { key: this.props.data.group },
                    name: this.props.data.name,
                    group: this.props.data.group,
                    text: this.props.data.text != null ? this.props.data.text : "",
                    visibility: this.props.data.visibility,
                    replay: this.props.data.replay,
                    filterEnabled: this.checkFilterEnabled(),
                    updated: false,
                    updatedName: false,
                    visibilityUser: visibilityUsers,
                    replayUser: replayUsers,
                    internalRule: this.props.data.tenantId !== "" ? false : true,
                    replayFilterEnabled: this.props.data.recordingFilter != null ? true : false,
                    replayFilterType: typeListSource,
                    replayFilterCycle: this.props.data.recordingFilter != null ? this.props.data.recordingFilter.cycle : "",
                    replayFilterStartDate: this.props.data.recordingFilter != null ? (this.props.data.recordingFilter.startDate !== '' && this.props.data.recordingFilter.startDate !== '0001-01-01T00:00:00') ? new Date(this.props.data.recordingFilter.startDate) : '' : "",
                    replayFilterEndDate: this.props.data.recordingFilter != null ? (this.props.data.recordingFilter.endDate !== '' && this.props.data.recordingFilter.endDate !== '0001-01-01T00:00:00') ? new Date(this.props.data.recordingFilter.endDate) : '' : "",
                    sel4list: categoriesList,
                    selectedOptionKeys: categoriesListSource,
                    selIntegration4List: this.integrationList,
                    selectedOptionKeysIntegration: this.integrationsListSource,
                    replayVisibilityDetails: this.props.data.visibilityDetails,
                    replayVisibilityDetailsNotes: this.props.data.visibilityDetailsNotes,
                    replayVisibilityDetailsTranscript: this.props.data.visibilityDetailsTranscript,
                    replayVisibilityDetailsAnalytics: this.props.data.visibilityDetailsAnalytics,
                    replayVisibilityDetailsMetadata: this.props.data.visibilityDetailsMetadata,
                    visibilityDetailsQM: this.props.data.visibilityDetailsQM,
                    replayVisibilityDetailsCustomFields: this.props.data.visibilityDetailsCustomFields,
                    replayVisibilityDetailsAnalyticsChange: this.props.data.visibilityDetailsAnalyticsChange,
                    replayVisibilityDetailsTTL: this.props.data.visibilityDetailsTTL,
                    replayDurationFrom: this.props.data.recordingFilter != null ? this.props.data.recordingFilter.durationFrom : -1,
                    replayDurationTo: this.props.data.recordingFilter != null ? this.props.data.recordingFilter.durationTo : -1,
                    replayRecordingOwner: this.props.data.recordingFilter != null ? this.props.data.recordingFilter.recordingOwner : false,
                    replayFilterPersons: this.listPersons,
                    timeBasedType: this.props.data.recordingFilter != null ? this.props.data.recordingFilter.timeBasedType : "",
                    replayCycle: this.props.data != null ? this.props.data.replayCycle : "",
                    isNameValidationError: ""



                };
            }
            else if (this.props.data.group === "recording") {
                let allowListUsers = [];
                let allowListUsersSource = (this.props.data.allowList === "undefined" || this.props.data.allowList == null || this.props.data.allowList == "") ? "" : this.props.data.allowList.split(',');
                for (let x = 0; x < allowListUsersSource.length; x++) {
                    allowListUsers.push({
                        userId: allowListUsersSource[x],
                        key: allowListUsersSource[x]
                    });
                }
                let denyListUsers = [];
                let denyListUsersSource = (this.props.data.whieList === "undefined" || this.props.data.denyList == null || this.props.data.denyList == "") ? "" : this.props.data.denyList.split(',');
                for (let x = 0; x < denyListUsersSource.length; x++) {
                    denyListUsers.push({
                        userId: denyListUsersSource[x],
                        key: denyListUsersSource[x]
                    });
                }
                let meetingOrganizer = [];
                let meetingOrganizerSource = (this.props.data.meetingOrganizer === "undefined" || this.props.data.meetingOrganizer == null || this.props.data.meetingOrganizer == "") ? "" : this.props.data.meetingOrganizer.split(',');
                for (let x = 0; x < meetingOrganizerSource.length; x++) {
                    meetingOrganizer.push({
                        userId: meetingOrganizerSource[x],
                        key: meetingOrganizerSource[x]
                    }
                    );
                }

                let meetingParticipant = [];
                let meetingParticipantSource = (this.props.data.meetingParticipant === "undefined" || this.props.data.meetingParticipant == null || this.props.data.meetingParticipant == "") ? "" : this.props.data.meetingParticipant.split(',');
                for (let x = 0; x < meetingParticipantSource.length; x++) {
                    meetingParticipant.push({
                        userId: meetingParticipantSource[x],
                        key: meetingParticipantSource[x]
                    }
                    );
                }

                let announcementsWithoutDefault = JSON.parse(this.props.data.announcements);
                let defaultAnnouncementValue = {
                    prefixList: ["default"],
                    start: "",
                    stop: ""
                }
                announcementsWithoutDefault.map((item, i) => {

                    if (item.prefixList[0] == "default") {
                        defaultAnnouncementValue = item;
                        announcementsWithoutDefault.splice(i, 1);
                    }
                });

                let announcementsStringPrefix = [];
                announcementsWithoutDefault.map(item => announcementsStringPrefix.push({ start: item.start, stop: item.stop, prefixList: item.prefixList.toString() }));

                let recordingTransferorAllowList = [];
                let recordingTransferorAllowListSource = (this.props.data.recordingTransferorAllowList === "undefined" || this.props.data.recordingTransferorAllowList == null || this.props.data.recordingTransferorAllowList == "") ? "" : this.props.data.recordingTransferorAllowList.split(',').filter(item => item !== '');
                for (let x = 0; x < recordingTransferorAllowListSource.length; x++) {
                    recordingTransferorAllowList.push({
                        userId: recordingTransferorAllowListSource[x],
                        key: recordingTransferorAllowListSource[x]
                    }
                    );
                }

                let recordingTransferorDenyList = [];
                let recordingTransferorDenyListSource = (this.props.data.recordingTransferorDenyList === "undefined" || this.props.data.recordingTransferorDenyList == null || this.props.data.recordingTransferorDenyList == "") ? "" : this.props.data.recordingTransferorDenyList.split(',').filter(item => item !== '');
                for (let x = 0; x < recordingTransferorDenyListSource.length; x++) {
                    recordingTransferorDenyList.push({
                        userId: recordingTransferorDenyListSource[x],
                        key: recordingTransferorDenyListSource[x]
                    }
                    );
                }

                this.state = {
                    selectedItem: { key: this.props.data.group },
                    name: this.props.data.name,
                    group: this.props.data.group,
                    date: this.props.data.time,
                    text: this.props.data.text != null ? this.props.data.text : "",
                    recAudio: this.props.data.audio,
                    recVideo: this.props.data.video,
                    recScreenOnly: this.props.data.screenOnly,
                    recChat: this.props.data.chat,
                    recAudioType: this.props.data.audioType,
                    recChatType: this.props.data.chatType,
                    recChatTypeDeny: this.props.data.chatTypeDeny,
                    recChannelType: this.props.data.channelType,
                    recAreaCode: this.props.data.audio,
                    recInboundType: this.props.data.inboundType,
                    recOutboundType: this.props.data.outboundType,
                    recMeetingType: this.props.data.meetingType,
                    recMeetingExternalType: this.props.data.meetingExternalType,
                    recMeetingInternalType: this.props.data.meetingInternalType,
                    recInternalType: this.props.data.internalType,
                    recSilentRecording: this.props.data.silentRecording,
                    recSwitchInitialState: this.props.data.switchInitialState,
                    recDisableNextRecording: this.props.data.disableNextRecording,
                    recMeetingOrganizer: meetingOrganizer,
                    recMeetingParticipant: meetingParticipant,
                    recAllowList: allowListUsers,
                    recDenyList: denyListUsers,
                    recUserStreamOnly: this.props.data.userStreamOnly,
                    recDemandStartOnly: this.props.data.demandStartOnly,
                    recChannelList: this.props.data.channelList,
                    recMeetingSubjectDenyList: this.props.data.meetingSubjectDenyList,
                    recMeetingSubjectAllowList: this.props.data.meetingSubjectAllowList,
                    updated: false,
                    updatedName: false,
                    internalRule: this.props.data.tenantId !== "" ? false : true,
                    delegateFeature: this.props.data.useOnBehalfOfUserSetting,
                    pstnAnnouncement: this.props.data.pstnAnnouncement,
                    ascBeeptone: this.props.data.ascBeeptone,
                    ascBeeptoneFrequency: this.props.data.ascBeeptoneFrequency,
                    announcementType: this.props.data.announcementType,
                    announcements: announcementsStringPrefix,
                    numberPrefixIndex: announcementsWithoutDefault.length,
                    defaultCustomAnnouncement: this.checkDefaultCustomAnnouncement(),
                    defaultAnnouncement: defaultAnnouncementValue,
                    deletedFilesFromAnnouncements: [],
                    rodDmtfSTart: (this.props.data.start !== null && this.props.data.start !== "") ? this.props.data.start : "*999*",
                    rodDmtfStop: (this.props.data.stop !== null && this.props.data.stop !== "") ? this.props.data.stop : "#999#",
                    isDtmfEnabled: this.props.data.isDtmfEnabled,
                    isDtmfAnnouncementSuppressionEnabled: this.props.data.isDtmfAnnouncementSuppressionEnabled,
                    announcementSuppressionCode: (this.props.data.announcementSuppressionCode !== null && this.props.data.announcementSuppressionCode !== "") ? this.props.data.announcementSuppressionCode : "*345#",
                    announcementSuppressionTimeInDays: this.props.data.announcementSuppressionTimeInDays,
                    recReevaluateChangeFromOneToOneToMeeting: this.props.data.recReevaluateChangeFromOneToOneToMeeting,
                    ttlConfirmed: (this.props.data !== null && this.props.data.time !== null) ? true : false,
                    keepDeleteSettingsNonComplex: this.retrieveKeepDeleteArrayForState("NonComplex"),
                    keepDeleteSettingsInbound: this.retrieveKeepDeleteArrayForState("Inbound"),
                    keepDeleteSettingsOutbound: this.retrieveKeepDeleteArrayForState("Outbound"),
                    keepDeleteSettingsMeeting: this.retrieveKeepDeleteArrayForState("Meeting"),
                    keepDeleteSettingsMeetingExternal: this.retrieveKeepDeleteArrayForState("ExternalMeeting"),
                    keepDeleteSettingsMeetingInternal: this.retrieveKeepDeleteArrayForState("InternalMeeting"),
                    keepDeleteSettingsInternal: this.retrieveKeepDeleteArrayForState("Internal"),
                    roDStopReasons: this.props.data.roDStopReasons,
                    roDStopReasonRequired: this.props.data.roDStopReasonRequired,
                    recTransferorAllowList: recordingTransferorAllowList,
                    recTransferorDenyList: recordingTransferorDenyList,
                    isNameValidationError: ""
                };
            }
            else if (this.props.data.group === "access") {
                let assignmentUsers = [];
                let assignmentUsersSource = (this.props.data.userAssignmentUsers === "undefined" || this.props.data.userAssignmentUsers == null || this.props.data.userAssignmentUsers == "") ? "" : this.props.data.userAssignmentUsers.split(',');
                for (let x = 0; x < assignmentUsersSource.length; x++) {
                    assignmentUsers.push({
                        userId: assignmentUsersSource[x]
                    });
                }
                let proposeUsers = [];
                let proposeUsersSource = (this.props.data.userProposeUsers === "undefined" || this.props.data.userProposeUsers == null || this.props.data.userProposeUsers == "") ? "" : this.props.data.userProposeUsers.split(',');
                for (let x = 0; x < proposeUsersSource.length; x++) {

                    proposeUsers.push({
                        userId: proposeUsersSource[x]
                    }
                    );
                }
                let approvedUsersReplayList = [];
                let approvedUsersReplaySource = (this.props.data.approvedUsersReplay === "undefined" || this.props.data.approvedUsersReplay == null || this.props.data.approvedUsersReplay == "") ? "" : this.props.data.approvedUsersReplay.split(',');
                for (let x = 0; x < approvedUsersReplaySource.length; x++) {
                    approvedUsersReplayList.push({
                        userId: approvedUsersReplaySource[x]
                    }
                    );
                }
                this.state = {
                    selectedItem: { key: this.props.data.group },
                    name: this.props.data.name,
                    group: this.props.data.group,
                    text: this.props.data.text != null ? this.props.data.text : "",
                    accListUser: this.props.data.confListUser,
                    accAddUser: this.props.data.confAddUser,
                    accTicket: this.props.data.confTicket,
                    accRulesRecording: this.props.data.confRulesRecording,
                    accRulesReplay: this.props.data.confRulesReplay,
                    accRulesAccess: this.props.data.confRulesAccess,
                    accRulesAnalytics: this.props.data.confRulesAnalytics,
                    accMapUser: this.props.data.confMapUser,
                    accAssignment: this.props.data.confUserAssignment,
                    accAssignRecording: this.props.data.confAssignRecording,
                    accAssignReplay: this.props.data.confAssignReplay,
                    accAssignAccess: this.props.data.confAssignAccess,
                    accAssignAnalytics: this.props.data.confAssignAnalytics,
                    accAuditLogs: this.props.data.confAuditLogs,
                    accJobs: this.props.data.confJobs,
                    customFields: this.props.data.confCustomFields,
                    administrateCustomFields: this.props.data.confAdministrateCustomFields,
                    defActionCustomFields: this.props.data.confDefineActionOnCustomFields,
                    cfDisableTagging: this.props.data.cfDisableTagging,
                    accReporting: this.props.data.userReporting,
                    accKpiTotal: this.props.data.userKpiTotal,
                    accKpiIncoming: this.props.data.userKpiIncoming,
                    accKpiOutgoing: this.props.data.userKpiOutgoing,
                    accKpiKeywords: this.props.data.userKpiKeywords,
                    accKpiWallboard: this.props.data.userKpiWallboard,
                    conversationSafeAccess: {
                        accUserSafe: this.props.data.userSafe,
                        restrictedAccess: { ownFolder: this.props.data.ownFolder }
                    },
                    accUserDelete: this.props.data.userDelete,
                    accUserPropose: this.props.data.userPropose,
                    accUserProposeReasoning: this.props.data.userProposeReasoning,
                    accUserDenyExport: this.props.data.userDenyExport,
                    accUserDenyUpload: this.props.data.userDenyUpload,
                    accUserBulkExport: this.props.data.userBulkExport,
                    accUserBulkRestoreArchive: this.props.data.userBulkRestoreArchive,
                    accUserBulkSafe: this.props.data.userBulkSafe,
                    userBulkSafeAdd: this.props.data.userBulkSafeAdd,
                    userBulkSafeRemove: this.props.data.userBulkSafeRemove,
                    accUserBulkDelete: this.props.data.userBulkDelete,
                    accUserBulkTranscript: this.props.data.userBulkTranscript,
                    accUserBulkExcel: this.props.data.userBulkExcel,
                    accUserBulkIpRange: this.props.data.userBulkIpRange,
                    accUserBulkPasswordLength: this.props.data.userBulkPasswordLength == null ? 8 : this.props.data.userBulkPasswordLength,
                    complianceResultsAccess: this.props.data.complianceResultsAccess,
                    complianceQMResultsAccess: this.props.data.complianceQMResultsAccess,
                    accUserCompliancePolicy: this.props.data.userCompliancePolicy,
                    accUserCompliancePolicyActivate: this.props.data.userCompliancePolicyActivate,
                    accUserCompliancePolicyInitiateWorkflow: this.props.data.userCompliancePolicyInitiateWorkflow,
                    accUserCompliancePolicyEdit: this.props.data.userCompliancePolicyEdit,
                    accUserCompliancePolicyAssign: this.props.data.userCompliancePolicyAssign,
                    accUserComplianceAIQuestions: this.props.data.userComplianceAIQuestions,
                    userComplianceQM: this.props.data.userComplianceQM,
                    accLiveView: this.props.data.userLiveView,
                    accUserArchievedConversationType: this.props.data.archivedConversationAccessType,
                    accUserRequestArchiveRetrievalProcessReasoning: this.props.data.archivedRequestRetrievalReasoningRequired,
                    updated: false,
                    updatedName: false,
                    internalRule: this.props.data.tenantId !== "" ? false : true,
                    userProposeUsers: proposeUsers,
                    userAssignmentUsers: assignmentUsers,
                    denyReportAlarming: this.props.data.denyReportAlarming,
                    approvedUsersReplay: approvedUsersReplayList,
                    hasCustomFieldsAccess: this.props.data.hasCustomFieldsAccess,
                    customFieldsAccessType: this.props.data.customFieldsAccessType,
                    customFieldsEditableTime: this.props.data.customFieldsEditableTime,
                    accesibleCustomFields: this.props.data.accesibleCustomFields !== null ? this.props.data.accesibleCustomFields : [],
                    editableTimeCustomFields: this.props.data.editableTimeCustomFields,
                    allowTTLChangeViaCategorization: this.props.data.allowTTLChangeViaCategorization,
                    suspensionConvDelProcess: this.props.data.suspensionConvDelProcess,
                    accMergeAudioToVideo: this.props.data.accMergeAudioToVideo,
                    isNameValidationError: ""
                };
            }
            else if (this.props.data.group === "analytics") {
                let keywordList = [];
                let keywordListSource = (this.props.data.keywordList === "undefined" || this.props.data.keywordList == null) ? "" : this.props.data.keywordList.split(',');
                for (let x = 0; x < keywordListSource.length; x++) {
                    keywordList.push({
                        key: keywordListSource[x]
                    });
                }
                let categoriesList = [];
                let categoriesListSource = (this.props.data.keywordCategories === "undefined" || this.props.data.keywordCategories == null || this.props.data.keywordCategories == "") ? [] : this.props.data.keywordCategories.split(',');
                for (let x = 0; x < categoriesListSource.length; x++) {
                    categoriesList.push({
                        key: categoriesListSource[x],
                        id: categoriesListSource[x]
                    }
                    );
                }
                let entitiesList = [];
                let entitiesListSource = (this.props.data.entityCategories === "undefined" || this.props.data.entityCategories == null) ? "" : this.props.data.entityCategories.split(',');
                for (let x = 0; x < entitiesListSource.length; x++) {
                    entitiesList.push({
                        key: entitiesListSource[x]
                    }
                    );
                }
                this.state = {
                    selectedItem: { key: this.props.data.group },
                    name: this.props.data.name,
                    group: this.props.data.group,
                    text: this.props.data.text != null ? this.props.data.text : "",
                    anaType: this.props.data.accessType,
                    anaStartDate: (this.props.data.startTime === "" || this.props.data.startTime === "0001-01-01T00:00:00" || this.props.data.startTime === "9999-12-31T00:00:00") ? "" : new Date("2015-11-08T19:00:00.0000000"),
                    anaEndDate: (this.props.data.endTime === "" || this.props.data.endTime === "9999-12-31T00:00:00" || this.props.data.endTime === "0001-01-01T00:00:00") ? "" : new Date(this.props.data.endTime),
                    anaTranscription: this.props.data.withTranscription,
                    anaSpeechLanguage: this.props.data.transcriptLanguage,
                    anaSentiment: this.props.data.withSentiment,
                    anaKeyphrase: this.props.data.keyphraseExtraction,
                    anaTranslate: this.props.data.translationType,
                    translation: this.props.data.translationType !== "No",
                    anaVisibility: this.props.data.userVisible,
                    anaVideoIndexer: this.props.data.withVideoIndexer,
                    anaDemandSpeech2Text: this.props.data.onDemandSpeech2Text,
                    anaDemandCategories: this.props.data.onDemandEntities,
                    anaDemandKeywords: this.props.data.onDemandKeywords,
                    anaIndividualKeywords: this.props.data.individualKeywords,
                    anaTranslationLanguage: this.props.data.translationLanguage,
                    sel4listKeyword: keywordList,
                    selectedOptionKeyword: keywordListSource,
                    sel4list: categoriesList,
                    selectedOptionKeys: categoriesListSource,
                    sel4listEntities: entitiesList,
                    selectedOptionEntities: entitiesListSource,
                    updated: false,
                    updatedName: false,
                    internalRule: this.props.data.tenantId !== "" ? false : true,
                    alternativeLanguage: this.props.data.alternativeLanguage,
                    optionAlternativeLanguageKey: this.props.data.optionAlternativeLanguageKey !== "" ? this.props.data.optionAlternativeLanguageKey.split(',') : "",
                    maxAlternativeLanguages: 3,
                    multilingualConversations: this.props.data.multilingualConversations,
                    analyzeAllConditions: (this.props.data.analyzeAllConditions != null && this.props.data.analyzeAllConditions != undefined) ? this.props.data.analyzeAllConditions : [],
                    anaHasSpeakerRecognition:this.props.data.hasSpeakerRecognition,
                    isNameValidationError: ""

                }
            }
        }

    }

    checkFilterEnabled = () => {
        return (this.props.data.visibility != null && this.props.data.visibility.length > 0) ||
            (this.props.data.replay != null && this.props.data.replay.length > 0)
    }

    componentDidMount() {
        if (this.state.accesibleCustomFields !== undefined && this.state.accesibleCustomFields !== null && this.state.accesibleCustomFields.length > 0 && this.state.hasCustomFieldsAccess) {
            this.props.apol
                .query({
                    query: getAllCustomFieldDefinitions,
                    variables: { auth: this.props.authorization }
                })
                .then((res) => {
                    //set option custom fields
                    var customFieldsOptions = [];
                    for (var i = 0; i < res.data.getAllCustomFieldDefinitions.length; i++) {
                        customFieldsOptions.push({ key: res.data.getAllCustomFieldDefinitions[i].id, text: res.data.getAllCustomFieldDefinitions[i].fieldName })
                    }
                    //get always id
                    let accesibleCustomFields = this.keyConverter(customFieldsOptions, this.state.accesibleCustomFields)
                    this.setState({
                        accesibleCustomFields
                    })
                });
        }
    }

    columns = [
        { key: 'col1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "category")}</>)}</MyContext.Consumer>, fieldName: 'text', minWidth: 100, maxWidth: 200, isResizable: true },
    ];

    columnsKeyword = [
        { key: 'col1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "keyword")}</>)}</MyContext.Consumer>, fieldName: 'text', minWidth: 100, maxWidth: 200, isResizable: true },
    ];

    columnsUser = [
        { key: 'col1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "user")}</>)}</MyContext.Consumer>, fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'col1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "name")}</>)}</MyContext.Consumer>, fieldName: 'text', minWidth: 100, maxWidth: 200, isResizable: true }
    ];

    _onChange = (event, item) => {
        //console.log(`Selection change: ${item.text} ${item.selected ? 'selected' : 'unselected'}`);
        //console.log(item);
        this.setState({ selectedItem: item, group: item.key });
    };

    handleChange(event, id) {
        const text = event.target.value;
        switch (id) {
            case 1: this.setState({ keyword: text }); break;
            default: break;
        }
    };

    addToKeylist = (value) => {
        let tmp = this.state.sel4listKeyword;
        let ex = true;
        for (let x = 0; x < tmp.length; x++) {
            if (tmp[x].text === value) {
                ex = false;
                break;
            }
        }

        if (ex) {
            tmp.push({ text: value })
            this.setState({ keyword: "", sel4listKeyword: tmp })
        }
        else {
            toast.error(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "keywordAlreadyInList")}</>)}</MyContext.Consumer>, this.toastyy)
        }

    }
    delKeylist = (value) => {
        let tmp = this.state.sel4listKeyword;
        let out = [];

        let ex = false;

        for (let x = 0; x < tmp.length; x++) {
            if (tmp[x].text !== value) {
                out.push(tmp[x])
            } else {
                ex = true;

            }
        }

        if (ex) {
            toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "deletingSuccessful")}</>)}</MyContext.Consumer>, this.toastyy);
            this.setState({ sel4listKeyword: out, selectionDetails: undefined })
        }
        else {
            toast.error(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "errorDeleting")}</>)}</MyContext.Consumer>, this.toastyy)
        }

    }

    _selection = new Selection({
        onSelectionChanged: () => {
            this.setState({

                selectionDetails: this._selection.getSelection()[0]
            });
        }
    });
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.addToKeylist(this.state.keyword)
        }
    }
    onFileChange = () => {
        alert('OK');
    }
    onUpdatedAnnouncement = (i, announcement, prevFile) => {
        if (this.state.announcements.length > i) {
            this.setState(prevState => ({
                announcements: prevState.announcements.map((item, index) => {
                    return index != i ? item : announcement
                }),
                updated: true
            }))
        }
        else {
            var announcements = this.state.announcements;
            announcements.push(announcement)
            this.setState(() => ({ announcements: announcements, updated: true }))
        }

        if (prevFile !== undefined && prevFile !== "") {
            let deletedFiles = this.state.deletedFilesFromAnnouncements;
            deletedFiles.push(prevFile);
            this.setState({ deletedFilesFromAnnouncements: deletedFiles })
        }

    }

    onDeleteAnnouncement = (i, defaultAnn) => {
        let deletedFiles = this.state.deletedFilesFromAnnouncements;

        if (defaultAnn) {
            let annDefault = this.state.defaultAnnouncement;

            if (annDefault !== null && annDefault !== undefined && annDefault.start !== "") {
                deletedFiles.push(annDefault.start);
            }
            if (annDefault !== null && annDefault !== undefined && annDefault.stop !== "") {
                deletedFiles.push(annDefault.stop);
            }

            if (document.getElementById("startResDef") !== null && document.getElementById("startResDef") !== undefined) {
                document.getElementById("startResDef").innerHTML = "";
            }
            if (document.getElementById("stopResDef") !== null && document.getElementById("stopResDef") !== undefined) {
                document.getElementById("stopResDef").innerHTML = "";
            }
            if (document.getElementById("stopErrDef") !== null && document.getElementById("stopErrDef") !== undefined) {
                document.getElementById("stopErrDef").innerHTML = "";
            }
            if (document.getElementById("startErrDef") !== null && document.getElementById("startErrDef") !== undefined) {
                document.getElementById("startErrDef").innerHTML = "";
            }

            var defAnnouncementEmpty = {
                prefixList: ["default"],
                start: "",
                stop: ""
            }

            if (this.state !== undefined && this.state !== null) {
                this.setState(prevState => ({
                    defaultAnnouncement: defAnnouncementEmpty,
                    updated: true,
                    deletedFilesFromAnnouncements: deletedFiles
                }))
            }

        } else {
            var announcements = this.state.announcements

            //create an array with deleted files from deleted announcements
            //this helps in case user deletes an announcement but the doesn't click save
            //files will be deleted only after pressing save to make sure files are not lost if canceled
            if (announcements[i] !== null && announcements[i] !== undefined && announcements[i].start !== "") {
                deletedFiles.push(announcements[i].start);
            }
            if (announcements[i] !== null && announcements[i] !== undefined && announcements[i].stop !== "") {
                deletedFiles.push(announcements[i].stop);
            }

            //remove announcement from list
            announcements.splice(i, 1)
            if (this.state !== undefined && this.state !== null) {
                this.setState(prevState => ({
                    announcements,
                    updated: true,
                    numberPrefixIndex: (prevState.numberPrefixIndex - 1),
                    deletedFilesFromAnnouncements: deletedFiles
                }))
            }
        }

    }
    checkTTLValidity = async (apol) => {
        this.setState({ TTLLoading: true });

        var data = await apol
            .mutate({
                mutation: checkRetentionTimeValidity,
                variables: {
                    ttl: parseInt(this.state.date)
                }
            });
        if (data.data.checkRetentionTimeValidity) {
            this.setState({ ttlConfirmed: true });
            document.getElementById("categoryRetentionTimeField").style.border = "1px solid green";
            document.querySelector('#categoryRetentionTimeBtn').style.border = "1px solid green";
        }
        else {
            document.getElementById("categoryRetentionTimeField").style.border = "1px solid orange";
            document.querySelector('#categoryRetentionTimeBtn').style.border = "1px solid orange";
            this.setState({ ttlBtnMessage: true })

        }
        this.setState({ TTLLoading: false });

    }

    checkDefaultCustomAnnouncement = () => {
        let list = JSON.parse(this.props.data.announcements);
        let res = false;

        list.map(item => {
            if (item.prefixList[0] === "default") {
                res = true;
            }
        });
        return res;
    }
    onChangeMulti = (event, option, index, value) => {
        const currentSelectedKeys = this.state.selectedOptionKeys || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            let xx = [];
            for (let i = 0; i < tmp.length; i++) {
                let findResult = this.findKeys(tmp[i], this.tmpKey);
                if (findResult !== null && findResult !== undefined) {
                    xx.push(findResult);
                }
            }
            this.setState({
                selectedOptionKeys: tmp,
                sel4list: xx,
                updated: true
            });
        }
    };

    onChangeMultiFields = (event, option, index, value) => {
        const currentSelectedKeys = this.state.accesibleCustomFields || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            this.setState({
                accesibleCustomFields: tmp,
                updated: true
            });
        }
    };
    onChangeMultiEntities = (event, option, index, value) => {
        const currentSelectedKeys = this.state.selectedOptionEntities || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            let xx = [];
            for (let i = 0; i < tmp.length; i++) {
                xx.push(this.findKeys(tmp[i], this.tmpKey))
            }
            this.setState({
                selectedOptionEntities: tmp,
                sel4listEntities: xx
            });
        }
    };
    onChangeMultiLanguages = (event, option, index, value) => {
        const currentSelectedKeys = this.state.optionAlternativeLanguageKey || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeysAltLanguages(currentSelectedKeys, option);

            this.setState({
                optionAlternativeLanguageKey: tmp,
                updated: true
            }, console.log(this.state.optionAlternativeLanguageKey));
        }
    }

    onChangeMultiIntegration = (event, option, index, value) => {
        const currentSelectedKeys = this.state.selectedOptionKeysIntegration || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            let xx = [];
            for (let i = 0; i < tmp.length; i++) {
                xx.push(this.findKeys(tmp[i], this.tmpKeyIntegration))
            }
            this.setState({
                selectedOptionKeysIntegration: tmp,
                selIntegration4List: xx,
                filterDefaultEnabled: false,
                filterUserEnabled: true,
                updated: true
            });
        }
    };

    updateSelectedOptionKeys = (selectedKeys, option): string[] => {
        selectedKeys = [...selectedKeys]; // modify a copy
        const index = selectedKeys.indexOf(option.key);
        if (option.selected && index < 0) {
            selectedKeys.push(option.key);
        } else {
            selectedKeys.splice(index, 1);
        }
        return selectedKeys;
    };

    updateSelectedOptionKeysAltLanguages = (selectedKeys, option): string[] => {
        selectedKeys = [...selectedKeys]; // modify a copy
        const index = selectedKeys.indexOf(option.key);
        //check if selected keys array has more than max alternative languages allowed
        //if true - will not let user to check new if another option is deselected
        if ((selectedKeys.length >= this.state.maxAlternativeLanguages) && option.selected && (index < 0)) {
            option.selected = "false";
            toast.error("Maximum " + this.state.maxAlternativeLanguages + " language" + (this.state.maxAlternativeLanguages > 1 ? "s" : "") + " allowed", { autoClose: 5000, position: "top-left" });
        } else {
            if (option.selected && index < 0) {
                selectedKeys.push(option.key);
            } else {
                selectedKeys.splice(index, 1);
            }
        }
        return selectedKeys;
    };

    findKeys = (k, options) => {
        let out;
        for (let x = 0; x < options.length; x++) {
            if (k === options[x].key) {
                out = options[x];
                break;
            }
        }
        return out;
    }

    availableAlternativeLanguages = (languageList) => {

        let alternativeLanguageList = [];

        languageList.map((language) => {
            if (language.key != this.state.anaSpeechLanguage) {
                alternativeLanguageList.push(language);
            }
        });

        return alternativeLanguageList;
    }

    displayGroupsUsingTheRule = () => {
        let list = this.tmpGroupUsage.map((group) =>
            <li key={group}>{group}</li>
        );
        return <ul>
            {list}
        </ul>
    }
    _closeModal = () => {
        this.setState({ showModal: false });
    }

    _openModal = (modalDisplay) => {
        this.setState({ showModal: true, modalDisplay: modalDisplay });


    }

    //Legacy method to convert the old customField name based in the new custom field id based.
    //For the customField id based this method should be transparent
    keyConverter = (customFieldsMap, accesibleCustomFields) => {
        if (accesibleCustomFields.length > 0) {
            if (accesibleCustomFields[0].match("[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}")) {
                return accesibleCustomFields
            }

            return customFieldsMap.filter(e => { return accesibleCustomFields.includes(e.text) }).map(e => e.key)

        } else { return []; }
    }

    getCount = (option) => {
        let count = "";
        this.state.accesibleCustomFields.map((name, i) => {

            if (option.key == name || option.text == name) {
                count = i + 1;
            }
        });
        return count;
    }
    validateDTMFCode = (str, messageErrorId) => {

        let strReturn = str;
        //check if matches 0-9*#

        if (str === "") {
            document.getElementById(messageErrorId).innerHTML = "Field cannot be empty";
            document.getElementById(messageErrorId).style.color = "red";
            return strReturn;

        } else {
            for (let i = 0; i < str.length; i++) {
                console.log(str[i]);
                if ((str[i] >= 0 && str[i] <= 9) || str[i] === "*" || str[i] == "#") {

                    document.getElementById(messageErrorId).innerHTML = "";
                } else {

                    document.getElementById(messageErrorId).innerHTML = "Characters allowed are 0-9, * and #";
                    document.getElementById(messageErrorId).style.color = "red";
                    return strReturn.replace(str[i], '');
                }
            }
            return strReturn;
        }
    }

    //set state for keep delete component
    modifyState(type, nextState, field) {
        switch (type) {

            case "NonComplex":
                this.setState({ keepDeleteSettingsNonComplex: { ...this.state.keepDeleteSettingsNonComplex, [field]: nextState }, updated: true });
                break;
            case "Inbound":
                this.setState({ keepDeleteSettingsInbound: { ...this.state.keepDeleteSettingsInbound, [field]: nextState }, updated: true });
                break;
            case "Outbound":
                this.setState({ keepDeleteSettingsOutbound: { ...this.state.keepDeleteSettingsOutbound, [field]: nextState }, updated: true });
                break;
            case "Meeting":
                this.setState({ keepDeleteSettingsMeeting: { ...this.state.keepDeleteSettingsMeeting, [field]: nextState }, updated: true });
                break;
            case "InternalMeeting":
                this.setState({ keepDeleteSettingsMeetingInternal: { ...this.state.keepDeleteSettingsMeetingInternal, [field]: nextState }, updated: true });
                break;
            case "ExternalMeeting":
                this.setState({ keepDeleteSettingsMeetingExternal: { ...this.state.keepDeleteSettingsMeetingExternal, [field]: nextState }, updated: true });
                break;
            case "Internal":
                this.setState({ keepDeleteSettingsInternal: { ...this.state.keepDeleteSettingsInternal, [field]: nextState }, updated: true });
                break;
            default: break;
        }
    }

    //get all keep delete settings and put them in array for graphql
    createKeepDeleteFinalArray = () => {
        let finalList = [];
        if (this.state.recAudioType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsNonComplex;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsNonComplex;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsNonComplex.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recInboundType === "Keep/Delete" || this.state.keepDeleteSettingsInbound.hasWrapUp) {
            let list = this.state.keepDeleteSettingsInbound;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsInbound;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsInbound.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recOutboundType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsOutbound;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsOutbound;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsOutbound.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recMeetingType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsMeeting;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsMeeting;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsMeeting.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recMeetingExternalType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsMeetingExternal;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsMeetingExternal;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsMeetingExternal.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recMeetingInternalType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsMeetingInternal;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsMeetingInternal;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsMeetingInternal.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recInternalType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsInternal;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsInternal;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsInternal.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }

        return finalList
    }

    //get data from graphql and spread it in state
    retrieveKeepDeleteArrayForState = (type) => {
        let propsKeepDelete = (this.props.data !== null && this.props.data.keepDeleteSettings !== null) ? this.props.data.keepDeleteSettings : [];
        let res = {
            type: type,
            startMode: "Keep",
            hasWrapUp: false,
            wrapUpTimer: 60,
            recordingMode: "Bulk",
            isWrapUpOnly: false,
            actions: false,
            hasPopupAlert: false,
            isSendToDo: false,
            toDoTimer: 1,
            hasUserNotification: false,
            specificSettings: false,
            isActive: false
        }
        propsKeepDelete.map(item => {
            if (item.type === type) {
                res = {
                    type: item.type,
                    startMode: item.startMode,
                    hasWrapUp: item.hasWrapUp,
                    wrapUpTimer: item.wrapUpTimer,
                    recordingMode: item.recordingMode,
                    isWrapUpOnly: item.isWrapUpOnly,
                    actions: item.actions,
                    hasPopupAlert: item.hasPopupAlert,
                    isSendToDo: item.isSendToDo,
                    toDoTimer: item.toDoTimer,
                    hasUserNotification: item.hasUserNotification,
                    specificSettings: item.specificSettings,
                    isActive: item.isActive
                }
            }
        });
        return res;
    }

    //when on update rule it changes isActive for each keep delete settings depending whether is selected or not
    //keeps the data from graphql for keep delete settings if nothing is changed
    createKeepDeleteFinalArrayForUpdate() {
        //let propsData = (this.props.data !== null && this.props.data.keepDeleteSettings !== null) ? this.props.data.keepDeleteSettings : [];
        let finalList = [];


        if (this.state.recAudioType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsNonComplex;
            list.isActive = true;
            finalList.push(list);
        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsNonComplex;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsNonComplex.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }

        if (this.state.recInboundType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsInbound;
            list.isActive = true;
            finalList.push(list);
        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsInbound;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsInbound.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }

        if (this.state.recOutboundType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsOutbound;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsOutbound;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsOutbound.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recMeetingType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsMeeting;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsMeeting;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsMeeting.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recMeetingExternalType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsMeetingExternal;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsMeetingExternal;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsMeetingExternal.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recMeetingInternalType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsMeetingInternal;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsMeetingInternal;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsMeetingInternal.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }
        if (this.state.recInternalType === "Keep/Delete") {
            let list = this.state.keepDeleteSettingsInternal;
            list.isActive = true;
            finalList.push(list);

        } else {
            let listNoKeepDelete = this.state.keepDeleteSettingsInternal;
            listNoKeepDelete.isActive = this.state.keepDeleteSettingsInternal.hasWrapUp;
            listNoKeepDelete.startMode = "None";
            finalList.push(listNoKeepDelete);
        }

        return finalList;

    }


    render(): React.ReactNode {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div style={{ float: "left", padding: "5px" }}>
                        {this.tmpUserUsage.length > 0 && <Link onClick={() => this._openModal("users")}>{window.innerWidth < 600 ? "Exp" : context.getTranslation("configuration", "ruleUsedBy") + this.tmpUserUsage.length + context.getTranslation("configuration", "users")}</Link>}
                        <br />
                        {this.tmpGroupUsage.length > 0 && <Link onClick={() => this._openModal("groups")}>{window.innerWidth < 600 ? "Exp" : context.getTranslation("configuration", "ruleUsedBy") + this.tmpGroupUsage.length + " groups"}</Link>}
                        <Modal
                            id="usersList"
                            isOpen={this.state.showModal}
                            onDismiss={this._closeModal}
                            isBlocking
                        >
                            <div className={contentStyles.header}>
                                {this.state.modalDisplay === "users"
                                    ? (
                                        <span><b>{context.getTranslation("configuration", "ruleUsedBy") + this.tmpUserUsage.length + context.getTranslation("configuration", "users")}</b></span>

                                    ) : (
                                        <span><b>{context.getTranslation("configuration", "ruleUsedBy") + this.tmpGroupUsage.length + " groups"}</b></span>

                                    )}


                                <IconButton
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel="Close popup modal"
                                    onClick={this._closeModal}
                                    styles={iconButtonStyles}

                                />
                            </div>
                            <div className={contentStyles.body}>
                                {this.state.modalDisplay === "users"
                                    ? (
                                        <Stack verical styles={listStyles}>
                                            {this.displayUsers.map((user) => <div key={user.userid}>
                                                <div className={contentStyles.userList}><mgt-person key={user.userid} show-name={true} user-id={user.userid} person-card="hover"></mgt-person></div>
                                                <div className={contentStyles.userList}><span className={contentStyles.userNameAlign}>{user.name}</span></div>
                                            </div>
                                            )}
                                        </Stack>
                                    ) : (
                                        <Stack verical styles={listStyles}>
                                            {this.displayGroups.map((group) => <div key={group.id}>
                                                <Persona
                                                    key={group.id}
                                                    size={PersonaSize.size32}
                                                    id={group.id}
                                                    text={group.name}
                                                    className={contentStyles.groupList}
                                                />
                                            </div>
                                            )}
                                        </Stack>
                                    )
                                }
                            </div>
                        </Modal>
                        <Dropdown
                            selectedKey={this.state.group}
                            onChange={this._onChange}
                            label={context.getTranslation("configuration", "ruleType")}
                            options={[
                                { key: 'recordingHeader', text: context.getTranslation("configuration", "types"), itemType: DropdownMenuItemType.Header },
                                { key: 'recording', text: context.getTranslation("configuration", "recording") },
                                { key: 'replay', text: context.getTranslation("configuration", "replay") },
                                { key: 'access', text: context.getTranslation("configuration", "accessRight") },
                                { key: 'analytics', text: context.getTranslation("configuration", "analytics") }
                            ]}
                            styles={dropdownStyles}
                            disabled={this.props.data != null}
                        />
                        {this.rendeSel()}
                        <p style={{ margin: "16px 0 8px" }}>
                            {this.state.saveMessage && (
                                <TeachingBubble
                                    target={`#${"saveButton"}`}
                                    secondaryButtonProps={{ children: context.getTranslation("common", "no"), onClick: () => { this.setState({ saveMessage: false }) } }}
                                    primaryButtonProps={{ children: context.getTranslation("common", "yes"), onClick: () => { this.notifyUpdateRule(this.props.apol, false); this.props.closeModal(); } }}
                                    onDismiss={!this.state.saveMessage}
                                    headline={context.getTranslation("common", "save")}
                                >
                                    {context.getTranslation("configuration", "usedByUserOrGroupQuestion")}
                                </TeachingBubble>
                            )}
                            {(this.props.data != null && !this.state.internalRule) &&
                                <PrimaryButton
                                    id={"saveButton"}
                                    data-automation-id="test"
                                    text={context.getTranslation("common", "save")}
                                    disabled={this.disableSaveButtons()}
                                    onClick={() => { this.props.data != null ? this.notifyUpdateOldRule(this.props.apol) : this.notifySaveRule(this.props.apol); }}
                                    allowDisabledFocus={true}
                                    style={{ marginRight: "8px" }}
                                />}

                            <DefaultButton
                                data-automation-id='test'
                                text={context.getTranslation("configuration", "saveAsNewRule")}
                                disabled={this.disableSaveButtons()}
                                onClick={() => { this.props.data != null ? this.notifyUpdateRule(this.props.apol, true) : this.notifySaveRule(this.props.apol); this.props.closeModal(); }}
                                allowDisabledFocus={true}
                                style={{ marginRight: "8px" }}
                            />

                            <DefaultButton
                                data-automation-id="test"
                                text={context.getTranslation("common", "cancel")}
                                onClick={() => { this.props.closeModal(); }}
                                allowDisabledFocus={true}
                            />
                            {(this.props.data != null && !this.state.internalRule && !this.tmpUserUsage.length) && <Popup trigger={
                                <DefaultButton
                                    data-automation-id="test"
                                    style={{ background: "red", color: "white" }}
                                    text={context.getTranslation("configuration", "deleteRule")}
                                    allowDisabledFocus={true}
                                />}
                                modal >
                                {close => (
                                    <div>
                                        <div className="ms-Grid" dir="ltr">
                                            <p>{context.getTranslation("configuration", "deleteRuleQuestion")}</p>
                                            <p>
                                                <DefaultButton
                                                    onClick={
                                                        () => {
                                                            this.notifyDelete(this.props.apol);
                                                            close();
                                                            this.props.closeModal();
                                                        }
                                                    }
                                                    data-automation-id="test"
                                                    text={context.getTranslation("common", "yes")}
                                                    allowDisabledFocus={true}
                                                />

                                                <PrimaryButton
                                                    data-automation-id="test"
                                                    text={context.getTranslation("common", "no")}
                                                    allowDisabledFocus={true}
                                                    onClick={
                                                        () => {
                                                            close();
                                                            this.props.cl()

                                                        }
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Popup>}
                        </p>
                    </div>
                )
                }
            </MyContext.Consumer>
        );
    }

    filterFilled = () => {
        return !this.state.replayFilterEnabled
            || (
                this.state.text.length > 0 ||
                this.state.sel4list.length > 0 ||
                (this.state.selIntegration4List != null && this.state.selIntegration4List.length > 0) ||
                (this.state.replayFilterType != null && this.state.replayFilterType.length > 0) ||
                this.state.replayFilterCycle != '' ||
                Date.parse(this.state.replayFilterStartDate) != null && !isNaN(Date.parse(this.state.replayFilterStartDate)) && Date.parse(this.state.replayFilterStartDate) != undefined ||
                Date.parse(this.state.replayFilterEndDate) != null && !isNaN(Date.parse(this.state.replayFilterEndDate)) && Date.parse(this.state.replayFilterEndDate) != undefined

            )
    }

    disableSaveButtons = () => {
        let checkTtlConfirmed = true
        if (this.state.group === "recording") {
            checkTtlConfirmed = this.state.ttlConfirmed
        }
        let validName = true;
        if (this.state.isNameValidationError.length > 0 && !this.state.updatedName) {
            validName = false;
        }

        //(this.state.updated || this.state.updatedName) this enables buttin only if name or someting else changed
        //validateName cofirms that the name is valid even if onl updated changed. If I keep only first condition user will be able to save with ivalid name
        let result = (this.props.data != null)
            ? ((this.state.updated || this.state.updatedName) && checkTtlConfirmed && validName)
            : this.state.updatedName  //create new rule only if name is valid and set

        return !(this.filterFilled() && result)
    }

    notifyDelete = async (apol) => {
        switch (this.state.selectedItem.key) {
            case null:
                return;
            case "recording":
                var data = await apol
                    .mutate({
                        mutation: mutationDeleteRuleRecording,
                        refetchQueries: [{
                            query: queryGetConfigData,
                            variables: { auth: this.props.authorization },
                        }],
                        variables: {
                            id: this.props.data.id
                        }
                    });
                if (data.data.deleteRuleRecording == null || data.data.deleteRuleRecording.name === "error") {
                    toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "deleteRecordingRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "deleteRecordingRuleSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                return;
            case "access":
                data = await apol
                    .mutate({
                        mutation: mutationDeleteRuleAccess,
                        refetchQueries: [{
                            query: queryGetConfigData,
                            variables: { auth: this.props.authorization },
                        }],
                        variables: {
                            id: this.props.data.id
                        }
                    });
                if (data.data.deleteRuleAccess == null || data.data.deleteRuleAccess.name === "error") {
                    toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "deleteAccessRulNotSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "deleteAccessRulSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                return;
            case "replay":
                data = await apol
                    .mutate({
                        mutation: mutationDeleteRuleReplay,
                        refetchQueries: [{
                            query: queryGetConfigData,
                            variables: { auth: this.props.authorization },
                        }],
                        variables: {
                            id: this.props.data.id
                        }
                    });
                if (data.data.deleteRuleReplay == null || data.data.deleteRuleReplay.name === "error") {
                    toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "deleteReplayRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "deleteReplayRuleSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                return;
            case "analytics":
                data = await apol
                    .mutate({
                        mutation: mutationDeleteRuleAnalytics,
                        refetchQueries: [{
                            query: queryGetConfigData,
                            variables: { auth: this.props.authorization },
                        }],
                        variables: {
                            id: this.props.data.id
                        }
                    });
                if (data.data.deleteRuleAnalytics == null || data.data.deleteRuleAnalytics.name === "error") {
                    toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "deleteAnalyticsRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "deleteAnalyticsRuleSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                return;
            default:
                return;
        }
    }

    notifySaveRule = async (apol) => {
        // Add Rule
        switch (this.state.selectedItem.key) {
            case null:
                return;
            case "recording":
                {
                    // Collect ID's for allowList, denyList and meetingOrganizer
                    let allowList = "";
                    let denyList = "";
                    let meetingOrganizer = "";
                    let meetingParticipant = "";
                    let retentionTime = "";
                    let beeptoneFrequency = this.state.ascBeeptoneFrequency;
                    let beeptone = this.state.ascBeeptone;
                    for (let x = 0; x < this.state.recMeetingOrganizer.length; x++) {
                        if (x !== 0)
                            meetingOrganizer += ",";
                        meetingOrganizer += this.state.recMeetingOrganizer[x].key;

                    };
                    for (let x = 0; x < this.state.recMeetingParticipant.length; x++) {
                        if (x !== 0)
                            meetingParticipant += ",";
                        meetingParticipant += this.state.recMeetingParticipant[x].key;

                    };
                    console.log(this.state.recAllowList);
                    for (let y = 0; y < this.state.recAllowList.length; y++) {
                        if (y !== 0)
                            allowList += ",";
                        allowList += this.state.recAllowList[y].key;

                    };
                    console.log(this.state.recDenyList);
                    for (let y = 0; y < this.state.recDenyList.length; y++) {
                        if (y !== 0)
                            denyList += ",";
                        denyList += this.state.recDenyList[y].key;

                    };

                    if (this.state.date == "" || this.state.date < 7) {
                        retentionTime = "7";
                    } else {
                        retentionTime = this.state.date;
                    }

                    if (beeptoneFrequency < 5) {
                        beeptoneFrequency = 5
                    }
                    if (!this.state.pstnAnnouncement) {
                        beeptone = false;
                    }

                    let announcementsList = [];
                    let filesToDeleteFromAnnouncement = this.state.deletedFilesFromAnnouncements;



                    //send announcements list only if they custom announcement is selected
                    if (this.state.announcementType === "CustomAnnouncement") {

                        if (this.state.defaultCustomAnnouncement) {
                            announcementsList.push({ start: this.state.defaultAnnouncement.start, stop: this.state.defaultAnnouncement.stop, prefixList: this.state.defaultAnnouncement.prefixList });
                        }

                        this.state.announcements.map(item => announcementsList.push({ start: item.start, stop: item.stop, prefixList: item.prefixList.replace(" ", "").split(",") }));

                        //if default announcement not checked delete the existing files
                        if (!this.state.defaultCustomAnnouncement) {
                            if (this.state.defaultAnnouncement.start !== "") {
                                filesToDeleteFromAnnouncement.push(this.state.defaultAnnouncement.start)
                            }
                            if (this.state.defaultAnnouncement.stop !== "") {
                                filesToDeleteFromAnnouncement.push(this.state.defaultAnnouncement.stop)
                            }
                        }
                    } else if (this.state.announcementType === "DefaultAnnouncement") {
                        if (this.state.announcements !== undefined && this.state.announcements !== null) {
                            this.state.announcements.map(item => {
                                if (item.start !== undefined && item.start !== "") {
                                    filesToDeleteFromAnnouncement.push(item.start);
                                }
                                if (item.stop !== undefined && item.stop !== "") {
                                    filesToDeleteFromAnnouncement.push(item.stop);
                                }
                            });
                        }

                        if (this.state.defaultAnnouncement.start !== undefined && this.state.defaultAnnouncement.start !== "") {
                            filesToDeleteFromAnnouncement.push(this.state.defaultAnnouncement.start);
                        }
                        if (this.state.defaultAnnouncement.stop !== undefined && this.state.defaultAnnouncement.stop !== "") {
                            filesToDeleteFromAnnouncement.push(this.state.defaultAnnouncement.stop);
                        }
                    }

                    let rodStartDtmf = this.state.rodDmtfSTart;
                    if (this.state.rodDmtfSTart === "") {
                        rodStartDtmf = "*999*";
                    }
                    let rodStopDtmf = this.state.rodDmtfStop;
                    if (this.state.rodDmtfStop === "") {
                        rodStopDtmf = "#999#";
                    }
                    let suppressionCode = this.state.announcementSuppressionCode;
                    if (this.state.announcementSuppressionCode === "") {
                        suppressionCode = "*345#";
                    }

                    let keepDeleteSettings = this.createKeepDeleteFinalArray();

                    let rodReasonsList = "";
                    if (this.state.roDStopReasons !== null && this.state.roDStopReasonRequired) {
                        rodReasonsList = this.state.roDStopReasons.replace(/\s*\|\s*/g, '|')
                    }

                    let recordingTransferorAllowList = this.state.recTransferorAllowList.map(item => item.key).join(',');

                    let recordingTransferorDenyList = this.state.recTransferorDenyList.map(item => item.key).join(',');


                    var data = await apol
                        .mutate({
                            mutation: mutationCreateRuleRecording,
                            refetchQueries: [{
                                query: queryGetConfigData,
                                variables: { auth: this.props.authorization },
                            }],
                            variables: {
                                auth: this.props.authorization,
                                name: this.state.name,
                                group: this.state.selectedItem.key,
                                time: retentionTime,
                                text: this.state.text,
                                audio: this.state.recAudio,
                                video: this.state.recVideo,
                                screenOnly: this.state.recScreenOnly,
                                chat: this.state.recChat,
                                audioType: this.state.recAudioType,
                                chatType: this.state.recChatType,
                                chatTypeDeny: this.state.recChatTypeDeny,
                                channelType: this.state.recChannelType,
                                areaCode: this.state.recAreaCode.toString(),
                                inboundType: this.state.recInboundType,
                                outboundType: this.state.recOutboundType,
                                meetingType: this.state.recMeetingType,
                                meetingExternalType: this.state.recMeetingExternalType,
                                meetingInternalType: this.state.recMeetingInternalType,
                                internalType: this.state.recInternalType,
                                silentRecording: this.state.recSilentRecording,
                                meetingOrganizer: meetingOrganizer,
                                meetingParticipant: meetingParticipant,
                                meetingSubjectAllowList: this.state.recMeetingSubjectAllowList,
                                meetingSubjectDenyList: this.state.recMeetingSubjectDenyList,
                                userStreamOnly: this.state.recUserStreamOnly,
                                demandStartOnly: this.state.recDemandStartOnly,
                                allowList: allowList,
                                denyList: denyList,
                                useOnBehalfOfUserSetting: this.state.delegateFeature,
                                switchInitialState: this.state.recSwitchInitialState,
                                disableNextRecording: this.state.recDisableNextRecording,
                                pstnAnnouncement: this.state.pstnAnnouncement,
                                ascBeeptone: beeptone,
                                ascBeeptoneFrequency: beeptoneFrequency,
                                announcementType: this.state.announcementType,
                                announcements: JSON.stringify(announcementsList),
                                deletedFilesFromAnnouncements: filesToDeleteFromAnnouncement,
                                start: rodStartDtmf,
                                stop: rodStopDtmf,
                                isDtmfEnabled: this.state.isDtmfEnabled,
                                isDtmfAnnouncementSuppressionEnabled: this.state.isDtmfAnnouncementSuppressionEnabled,
                                announcementSuppressionCode: suppressionCode,
                                announcementSuppressionTimeInDays: this.state.announcementSuppressionTimeInDays,
                                recReevaluateChangeFromOneToOneToMeeting: this.state.recReevaluateChangeFromOneToOneToMeeting,
                                channelList: this.state.recChannelList,
                                roDStopReasons: rodReasonsList,
                                roDStopReasonRequired: this.state.roDStopReasonRequired,
                                keepDeleteSettings: keepDeleteSettings,
                                recordingTransferorAllowList: recordingTransferorAllowList,
                                recordingTransferorDenyList: recordingTransferorDenyList,
                            }
                        });
                    if (data.data.createRuleRecording == null || data.data.createRuleRecording.name === "error") {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "addRecordingRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "recordingRuleAdded")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                    return;
                }
            case "replay":
                {
                    // Collect User for Visibility and Replay
                    let visibilityUser = "";
                    let replayUser = "";
                    let replayPersons = [];
                    for (let x = 0; x < this.state.visibilityUser.length; x++) {
                        if (x !== 0)
                            visibilityUser += ",";
                        visibilityUser += this.state.visibilityUser[x].userId;

                    };
                    for (let y = 0; y < this.state.replayUser.length; y++) {
                        if (y !== 0)
                            replayUser += ",";
                        replayUser += this.state.replayUser[y].userId;
                    };
                    let filterCategories = [];
                    for (let x = 0; x < this.state.sel4list.length; x++) {
                        filterCategories.push(this.state.sel4list[x].id);
                    };

                    let filterTypeKomma = "";
                    for (let x = 0; x < this.state.replayFilterType.length; x++) {
                        if (x !== 0)
                            filterTypeKomma += ",";
                        filterTypeKomma += this.state.replayFilterType[x];
                    };

                    for (let x = 0; x < this.state.replayFilterPersons.length; x++) {

                        replayPersons.push(this.state.replayFilterPersons[x].key);

                    };

                    data = await apol
                        .mutate({
                            mutation: mutationCreateRuleReplay,
                            refetchQueries: [{
                                query: queryGetConfigData,
                                variables: { auth: this.props.authorization },
                            }],
                            variables: {
                                auth: this.props.authorization,
                                name: this.state.name,
                                group: this.state.selectedItem.key,
                                visibility: this.state.visibility,
                                replay: this.state.replay,
                                text: this.state.text,
                                visibilityUser: visibilityUser,
                                visibilityDetails: this.state.replayVisibilityDetails,
                                visibilityDetailsTTL: this.state.replayVisibilityDetailsTTL,
                                visibilityDetailsTranscript: this.state.replayVisibilityDetailsTranscript,
                                visibilityDetailsMetadata: this.state.replayVisibilityDetailsMetadata,
                                visibilityDetailsQM: this.state.visibilityDetailsQM,
                                visibilityDetailsAnalytics: this.state.replayVisibilityDetailsAnalytics,
                                visibilityDetailsAnalyticsChange: this.state.replayVisibilityDetailsAnalyticsChange,
                                visibilityDetailsCustomFields: this.state.replayVisibilityDetailsCustomFields,
                                visibilityDetailsNotes: this.state.replayVisibilityDetailsNotes,
                                replayUser: replayUser,
                                replayCycle: this.state.replayCycle,
                                recordingFilter: this.state.replayFilterEnabled ? {
                                    id: Utils.guidGenerator(),
                                    type: filterTypeKomma,
                                    cycle: this.state.replayFilterCycle,
                                    startDate: this.state.replayFilterStartDate === '' ? null : this.state.replayFilterStartDate,
                                    endDate: this.state.replayFilterEndDate === '' ? null : this.state.replayFilterEndDate,
                                    categories: filterCategories,
                                    integrations: this.state.selectedOptionKeysIntegration,
                                    durationFrom: this.state.replayDurationFrom,
                                    durationTo: this.state.replayDurationTo,
                                    recordingOwner: this.state.replayRecordingOwner,
                                    persons: replayPersons,
                                    timeBasedType: this.state.timeBasedType
                                } : null
                            }
                        });
                    if (data.data.createRuleReplay == null || data.data.createRuleReplay.name === "error") {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "addReplayRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "replayRuleAdded")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                    return;
                }
            case "access":
                {
                    let userAssignment = "";
                    let userProposeUsers = "";
                    let approvedUsersReplay = "";
                    for (let x = 0; x < this.state.userAssignmentUsers.length; x++) {
                        if (x !== 0)
                            userAssignment += ",";
                        userAssignment += this.state.userAssignmentUsers[x].userId;

                    };

                    for (let z = 0; z < this.state.userProposeUsers.length; z++) {
                        if (z !== 0)
                            userProposeUsers += ",";
                        userProposeUsers += this.state.userProposeUsers[z].userId;
                    };
                    for (let y = 0; y < this.state.approvedUsersReplay.length; y++) {
                        if (y !== 0)
                            approvedUsersReplay += ",";
                        approvedUsersReplay += this.state.approvedUsersReplay[y].userId;

                    };
                    data = await apol
                        .mutate({
                            mutation: mutationCreateRuleAccess,
                            refetchQueries: [{
                                query: queryGetConfigData,
                                variables: { auth: this.props.authorization },
                            }],
                            variables: {
                                auth: this.props.authorization,
                                name: this.state.name,
                                group: this.state.selectedItem.key,
                                text: this.state.text,
                                confListUser: this.state.accListUser,
                                confAddUser: this.state.accAddUser,
                                confTicket: this.state.accTicket,
                                confRulesRecording: this.state.accRulesRecording,
                                confRulesReplay: this.state.accRulesReplay,
                                confRulesAccess: this.state.accRulesAccess,
                                confRulesAnalytics: this.state.accRulesAnalytics,
                                confMapUser: this.state.accMapUser,
                                confUserAssignment: this.state.accAssignment,
                                confAssignRecording: this.state.accAssignRecording,
                                confAssignReplay: this.state.accAssignReplay,
                                confAssignAccess: this.state.accAssignAccess,
                                confAssignAnalytics: this.state.accAssignAnalytics,
                                confAuditLogs: this.state.accAuditLogs,
                                confJobs: this.state.accJobs,
                                confCustomFields: this.state.customFields,
                                confAdministrateCustomFields: this.state.administrateCustomFields,
                                confDefineActionOnCustomFields: this.state.defActionCustomFields,
                                cfDisableTagging: this.state.cfDisableTagging,
                                userReporting: this.state.accReporting,
                                userKpiTotal: this.state.accKpiTotal,
                                userKpiIncoming: this.state.accKpiIncoming,
                                userKpiOutgoing: this.state.accKpiOutgoing,
                                userKpiKeywords: this.state.accKpiKeywords,
                                userKpiWallboard: this.state.accKpiWallboard,
                                userSafeAdd: this.state.accUserSafeAdd,
                                userSafeExport: this.state.accUserSafeExport,
                                userSafeShare: this.state.accUserSafeShare,
                                userDelete: this.state.accUserDelete,
                                userPropose: this.state.accUserPropose,
                                userProposeReasoning: this.state.accUserProposeReasoning,
                                userProposeUsers: userProposeUsers,
                                userSafe: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.accUserSafe : 'No',
                                ownFolder: {
                                    addConversations: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.addConversations : false,
                                    removeConversations: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.removeConversations : false,
                                    upserFolder: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.upserFolder : false,
                                    userIds: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.userIds : [],
                                    exportFolders: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.exportFolders : false,
                                    deleteFolders: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.deleteFolders : false,
                                    shareFolders: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.shareFolders : false,
                                },
                                userAssignmentUsers: userAssignment,
                                userLiveView: this.state.accLiveView,
                                userDenyExport: this.state.accUserDenyExport,
                                userDenyUpload: this.state.accUserDenyUpload,
                                userBulkExport: this.state.accUserBulkExport,
                                userBulkRestoreArchive: this.state.accUserBulkRestoreArchive,
                                userBulkSafe: this.state.accUserBulkSafe,
                                userBulkSafeAdd: this.state.userBulkSafeAdd,
                                userBulkSafeRemove: this.state.userBulkSafeRemove,
                                userBulkDelete: this.state.accUserBulkDelete,
                                userBulkTranscript: this.state.accUserBulkTranscript,
                                userBulkExcel: this.state.accUserBulkExcel,
                                userBulkIpRange: this.state.accUserBulkIpRange,
                                userBulkPasswordLength: this.state.accUserBulkPasswordLength.toString(),
                                complianceResultsAccess: this.state.complianceResultsAccess,
                                complianceQMResultsAccess: this.state.complianceQMResultsAccess,
                                userCompliancePolicy: this.state.accUserCompliancePolicy,
                                userCompliancePolicyActivate: this.state.accUserCompliancePolicyActivate,
                                userCompliancePolicyInitiateWorkflow: this.state.accUserCompliancePolicyInitiateWorkflow,
                                userCompliancePolicyEdit: this.state.accUserCompliancePolicyEdit,
                                userCompliancePolicyAssign: this.state.accUserCompliancePolicyAssign,
                                userComplianceAIQuestions: this.state.accUserComplianceAIQuestions,
                                userComplianceQM: this.state.userComplianceQM,
                                denyReportAlarming: this.state.denyReportAlarming,
                                archivedConversationAccessType: this.state.accUserArchievedConversationType,
                                archivedRequestRetrievalReasoningRequired: this.state.accUserArchievedConversationType == "RequestRetrieval" ? this.state.accUserRequestArchiveRetrievalProcessReasoning : false,
                                approvedUsersReplay: approvedUsersReplay,
                                hasCustomFieldsAccess: this.state.hasCustomFieldsAccess,
                                customFieldsAccessType: this.state.customFieldsAccessType,
                                customFieldsEditableTime: this.state.customFieldsEditableTime,
                                accesibleCustomFields: this.state.accesibleCustomFields,
                                editableTimeCustomFields: this.state.editableTimeCustomFields,
                                allowTTLChangeViaCategorization: this.state.allowTTLChangeViaCategorization,
                                accMergeAudioToVideo: this.state.accMergeAudioToVideo,

                                suspensionConvDelProcess: this.state.suspensionConvDelProcess
                            }
                        });
                    if (data.data.createRuleAccess == null || data.data.createRuleAccess.name === "error") {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "addAccessRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "accessRuleAdded")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                    return;
                }
            case "analytics":
                {
                    // Collect Entities, Keywords, Categories
                    let anaEntities = "";
                    let anaCategories = "";
                    let anaKeywords = "";
                    for (let x = 0; x < this.state.sel4list.length; x++) {
                        if (x !== 0)
                            anaCategories += ",";
                        anaCategories += this.state.sel4list[x].id;

                    };
                    for (let y = 0; y < this.state.sel4listEntities.length; y++) {
                        if (y !== 0)
                            anaEntities += ",";
                        anaEntities += this.state.sel4listEntities[y].id;

                    };
                    for (let z = 0; z < this.state.sel4listKeyword.length; z++) {
                        if (z !== 0)
                            anaKeywords += ",";
                        anaKeywords += this.state.sel4listKeyword[z].id;

                    };

                    data = await apol
                        .mutate({
                            mutation: mutationCreateRuleAnalytics,
                            refetchQueries: [{
                                query: queryGetConfigData,
                                variables: { auth: this.props.authorization },
                            }],
                            variables: {
                                auth: this.props.authorization,
                                name: this.state.name,
                                group: this.state.selectedItem.key,
                                accessType: this.state.anaType,
                                startTime: this.state.anaStartDate == "" ? null : this.state.anaStartDate,
                                endTime: this.state.anaEndDate == "" ? null : this.state.anaEndDate,
                                withTranscription: this.state.anaTranscription,
                                withVideoIndexer: this.state.anaVideoIndexer,
                                transcriptLanguage: this.state.anaSpeechLanguage,
                                withSentiment: this.state.anaSentiment,
                                keywordCategories: anaCategories,
                                entityCategories: anaEntities,
                                keyphraseExtraction: this.state.anaKeyphrase,
                                translationType: this.state.anaTranslate,
                                userVisible: this.state.anaVisibility,
                                onDemandSpeech2Text: this.state.anaDemandSpeech2Text,
                                onDemandKeywords: this.state.anaDemandCategories,
                                onDemandEntities: this.state.anaDemandKeywords,
                                individualKeywords: this.state.anaIndividualKeywords,
                                translationLanguage: this.state.anaTranslationLanguage,
                                keywordList: anaKeywords,
                                alternativeLanguage: this.state.anaTranscription ? this.state.alternativeLanguage : false,
                                optionAlternativeLanguageKey: this.state.optionAlternativeLanguageKey.toString(),
                                multilingualConversations: this.state.alternativeLanguage ? this.state.multilingualConversations : false,
                                text: this.state.text,
                                analyzeAllConditions: this.state.analyzeAllConditions,
                                hasSpeakerRecognition: this.state.anaHasSpeakerRecognition 

                            }
                        });
                    if (data.data.createRuleAnalytics == null || data.data.createRuleAnalytics.name === "error") {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "addAnalyticsRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "analyticsRuleAdded")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                    return;
                }
            default: return;
        }

    };

    onChangeReasonsInput = (event) => {
        const inputValue = event.target.value;
        const values = inputValue.split('|');

        if (values.length <= 10) {
            this.setState({
                roDStopReasons: inputValue,
                rodStopReasonsPlaceholder: "Up to 10 reasons, separated by '|' (e.g., 'Reason 1 | Reason 2')",
                updated: true
            });
        } else {
            this.setState({ rodStopReasonsPlaceholder: "You have reached the maximum limit of 10 reasons." })
        }
    }

    validateNameOnUpdate = (saveAsNewRule) => {

        //early return is saveAsNewRule is not pressed
        if (!saveAsNewRule) {
            return this.state.name;
        }

        //go here only if saveAsNewRule is pressed
        //for other cases of name changes the validation is done directly on the field not on save
        if (this.state.name.trim().toLowerCase() == this.props.data?.name?.trim().toLowerCase()) {
            //validate name with "_new" if aleready exits it will add a random number at the end" 
            if (this.props.validateNameFcn(this.state.name + "_New")) {
                return this.state.name + "_New";
            }

            return this.state.name + "_New" + Math.floor(Math.random() * 100);

        }
        return this.state.name
    };

    notifyUpdateOldRule = async (apol) => {
        if (this.tmpGroupUsage.length || this.tmpUserUsage.length) {
            this.setState({ saveMessage: true });
        }
        else {
            this.notifyUpdateRule(apol, false);
            this.props.closeModal();
        }
    }

    notifyUpdateRule = async (apol, saveAsNewRule) => {
        // Change Rule
        switch (this.state.selectedItem.key) {
            case null:
                return;
            case "recording":
                {
                    // Collect ID's for allowList, denyList and meetingOrganizer
                    let allowList = "";
                    let denyList = "";
                    let meetingOrganizer = "";
                    let meetingParticipant = "";
                    let nameSave = this.validateNameOnUpdate(saveAsNewRule);
                    let retentionTime = "";
                    let beeptoneFrequency = this.state.ascBeeptoneFrequency;
                    let beeptone = this.state.ascBeeptone;
                    for (let x = 0; x < this.state.recMeetingOrganizer.length; x++) {
                        if (x !== 0)
                            meetingOrganizer += ",";
                        meetingOrganizer += this.state.recMeetingOrganizer[x].key;

                    };
                    for (let x = 0; x < this.state.recMeetingParticipant.length; x++) {
                        if (x !== 0)
                            meetingParticipant += ",";
                        meetingParticipant += this.state.recMeetingParticipant[x].key;

                    };
                    console.log(this.state.recAllowList);
                    for (let y = 0; y < this.state.recAllowList.length; y++) {
                        if (y !== 0)
                            allowList += ",";
                        allowList += this.state.recAllowList[y].key;

                    };
                    console.log(this.state.recDenyList);
                    for (let y = 0; y < this.state.recDenyList.length; y++) {
                        if (y !== 0)
                            denyList += ",";
                        denyList += this.state.recDenyList[y].key;

                    };
                    if (this.state.date == "" || this.state.date < 7) {
                        retentionTime = "7";
                    } else {
                        retentionTime = this.state.date;
                    }
                    if (beeptoneFrequency < 5) {
                        beeptoneFrequency = 5
                    }
                    if (!this.state.pstnAnnouncement) {
                        beeptone = false;
                    }

                    let announcementsList = [];
                    let filesToDeleteFromAnnouncement = this.state.deletedFilesFromAnnouncements;
                    //send announcements list only if they custom announcement is selected
                    if (this.state.announcementType === "CustomAnnouncement") {

                        //send default only if is checked
                        if (this.state.defaultCustomAnnouncement) {
                            announcementsList.push({ start: this.state.defaultAnnouncement.start, stop: this.state.defaultAnnouncement.stop, prefixList: this.state.defaultAnnouncement.prefixList });
                        }


                        this.state.announcements.map(item => announcementsList.push({ start: item.start, stop: item.stop, prefixList: item.prefixList.replace(" ", "").split(",") }));

                        //if default announcement not checked delete the existing files
                        if (!this.state.defaultCustomAnnouncement) {
                            if (this.state.defaultAnnouncement.start !== "") {
                                filesToDeleteFromAnnouncement.push(this.state.defaultAnnouncement.start)
                            }
                            if (this.state.defaultAnnouncement.stop !== "") {
                                filesToDeleteFromAnnouncement.push(this.state.defaultAnnouncement.stop)
                            }
                        }

                    } else if (this.state.announcementType === "DefaultAnnouncement") {
                        if (this.state.announcements !== undefined && this.state.announcements !== null) {
                            this.state.announcements.map(item => {
                                if (item.start !== undefined && item.start !== "") {
                                    filesToDeleteFromAnnouncement.push(item.start);
                                }
                                if (item.stop !== undefined && item.stop !== "") {
                                    filesToDeleteFromAnnouncement.push(item.stop);
                                }
                            });


                        }

                        if (this.state.defaultAnnouncement.start !== undefined && this.state.defaultAnnouncement.start !== "") {
                            filesToDeleteFromAnnouncement.push(this.state.defaultAnnouncement.start);
                        }
                        if (this.state.defaultAnnouncement.stop !== undefined && this.state.defaultAnnouncement.stop !== "") {
                            filesToDeleteFromAnnouncement.push(this.state.defaultAnnouncement.stop);
                        }

                    }

                    let rodStartDtmf = this.state.rodDmtfSTart;
                    if (this.state.rodDmtfSTart === "") {
                        rodStartDtmf = "*999*";
                    }
                    let rodStopDtmf = this.state.rodDmtfStop;
                    if (this.state.rodDmtfStop === "") {
                        rodStopDtmf = "#999#";
                    }
                    let suppressionCode = this.state.announcementSuppressionCode;
                    if (this.state.announcementSuppressionCode === "") {
                        suppressionCode = "*345#";
                    }

                    let keepDeleteSettings = this.createKeepDeleteFinalArrayForUpdate();

                    let rodReasonsList = "";
                    if (this.state.roDStopReasons !== null && this.state.roDStopReasonRequired) {
                        rodReasonsList = this.state.roDStopReasons.replace(/\s*\|\s*/g, '|')
                    }

                    let recordingTransferorAllowList = this.state.recTransferorAllowList.map(item => item.key).join(',');

                    let recordingTransferorDenyList = this.state.recTransferorDenyList.map(item => item.key).join(',');


                    var data = await apol
                        .mutate({
                            mutation: mutationUpdateRuleRecording,
                            refetchQueries: [{
                                query: queryGetConfigData,
                                variables: { auth: this.props.authorization },
                            }],
                            variables: {
                                id: (!saveAsNewRule) ? this.props.data.id : Utils.guidGenerator(),
                                auth: this.props.authorization,
                                name: nameSave,
                                group: this.state.selectedItem.key,
                                time: retentionTime,
                                text: this.state.text,
                                audio: this.state.recAudio,
                                video: this.state.recVideo,
                                screenOnly: this.state.recScreenOnly,
                                chat: this.state.recChat,
                                audioType: this.state.recAudioType,
                                chatType: this.state.recChatType,
                                chatTypeDeny: this.state.recChatTypeDeny,
                                channelType: this.state.recChannelType,
                                areaCode: this.state.recAreaCode.toString(),
                                inboundType: this.state.recInboundType,
                                outboundType: this.state.recOutboundType,
                                meetingType: this.state.recMeetingType,
                                meetingExternalType: this.state.recMeetingExternalType,
                                meetingInternalType: this.state.recMeetingInternalType,
                                internalType: this.state.recInternalType,
                                silentRecording: this.state.recSilentRecording,
                                meetingOrganizer: meetingOrganizer,
                                meetingParticipant: meetingParticipant,
                                meetingSubjectAllowList: this.state.recMeetingSubjectAllowList,
                                meetingSubjectDenyList: this.state.recMeetingSubjectDenyList,
                                userStreamOnly: this.state.recUserStreamOnly,
                                demandStartOnly: this.state.recDemandStartOnly,
                                allowList: allowList,
                                denyList: denyList,
                                useOnBehalfOfUserSetting: this.state.delegateFeature,
                                switchInitialState: this.state.recSwitchInitialState,
                                disableNextRecording: this.state.recDisableNextRecording,
                                pstnAnnouncement: this.state.pstnAnnouncement,
                                ascBeeptone: beeptone,
                                ascBeeptoneFrequency: beeptoneFrequency,
                                announcementType: this.state.announcementType,
                                announcements: JSON.stringify(announcementsList),
                                deletedFilesFromAnnouncements: filesToDeleteFromAnnouncement,
                                start: rodStartDtmf,
                                stop: rodStopDtmf,
                                isDtmfEnabled: this.state.isDtmfEnabled,
                                isDtmfAnnouncementSuppressionEnabled: this.state.isDtmfAnnouncementSuppressionEnabled,
                                announcementSuppressionCode: suppressionCode,
                                announcementSuppressionTimeInDays: this.state.announcementSuppressionTimeInDays,
                                recReevaluateChangeFromOneToOneToMeeting: this.state.recReevaluateChangeFromOneToOneToMeeting,
                                channelList: this.state.recChannelList,
                                roDStopReasons: rodReasonsList,
                                roDStopReasonRequired: this.state.roDStopReasonRequired,
                                keepDeleteSettings: keepDeleteSettings,
                                recordingTransferorAllowList: recordingTransferorAllowList,
                                recordingTransferorDenyList: recordingTransferorDenyList,
                            }
                        });
                    if (data.data.updateRuleRecording == null || data.data.updateRuleRecording.name === "error") {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "updateRecordingRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "recordingRuleUpdated")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                    return;
                }
            case "replay":
                {
                    // Collect User for Visibility and Replay
                    let visibilityUser = "";
                    let replayUser = "";
                    let replayPersons = [];
                    let nameSave = this.validateNameOnUpdate(saveAsNewRule);
                    for (let x = 0; x < this.state.visibilityUser.length; x++) {
                        if (x !== 0)
                            visibilityUser += ",";
                        visibilityUser += this.state.visibilityUser[x].userId;

                    };
                    for (let y = 0; y < this.state.replayUser.length; y++) {
                        if (y !== 0)
                            replayUser += ",";
                        replayUser += this.state.replayUser[y].userId;

                    };
                    let filterCategories = [];
                    for (let x = 0; x < this.state.sel4list.length; x++) {
                        filterCategories.push(this.state.sel4list[x].id);
                    };

                    let filterTypeKomma = "";
                    for (let x = 0; x < this.state.replayFilterType.length; x++) {
                        if (x !== 0)
                            filterTypeKomma += ",";
                        filterTypeKomma += this.state.replayFilterType[x];
                    };
                    for (let x = 0; x < this.state.replayFilterPersons.length; x++) {

                        replayPersons.push(this.state.replayFilterPersons[x].key);

                    };
                    data = await apol
                        .mutate({
                            mutation: mutationUpdateRuleReplay,
                            refetchQueries: [{
                                query: queryGetConfigData,
                                variables: { auth: this.props.authorization },
                            }],
                            variables: {
                                id: (!saveAsNewRule) ? this.props.data.id : Utils.guidGenerator(),
                                auth: this.props.authorization,
                                name: nameSave,
                                group: this.state.selectedItem.key,
                                visibility: this.state.visibility,
                                replay: this.state.replay,
                                text: this.state.text,
                                visibilityUser: visibilityUser,
                                replayUser: replayUser,
                                visibilityDetails: this.state.replayVisibilityDetails,
                                visibilityDetailsTTL: this.state.replayVisibilityDetailsTTL,
                                visibilityDetailsTranscript: this.state.replayVisibilityDetailsTranscript,
                                visibilityDetailsMetadata: this.state.replayVisibilityDetailsMetadata,
                                visibilityDetailsQM: this.state.visibilityDetailsQM,
                                visibilityDetailsAnalytics: this.state.replayVisibilityDetailsAnalytics,
                                visibilityDetailsAnalyticsChange: this.state.replayVisibilityDetailsAnalyticsChange,
                                visibilityDetailsCustomFields: this.state.replayVisibilityDetailsCustomFields,
                                visibilityDetailsNotes: this.state.replayVisibilityDetailsNotes,
                                replayCycle: this.state.replayCycle,
                                recordingFilter: this.state.replayFilterEnabled ? {
                                    id: Utils.guidGenerator(),
                                    type: filterTypeKomma,
                                    cycle: this.state.replayFilterCycle,
                                    startDate: this.state.replayFilterStartDate === '' ? null : this.state.replayFilterStartDate,
                                    endDate: this.state.replayFilterEndDate === '' ? null : this.state.replayFilterEndDate,
                                    categories: filterCategories,
                                    integrations: this.state.selectedOptionKeysIntegration,
                                    durationFrom: this.state.replayDurationFrom,
                                    durationTo: this.state.replayDurationTo,
                                    recordingOwner: this.state.replayRecordingOwner,
                                    persons: replayPersons,
                                    timeBasedType: this.state.timeBasedType
                                } : null
                            }
                        });
                    if (data.data.updateRuleReplay == null || data.data.updateRuleReplay.name === "error") {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "updateReplayRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "updateRuleAdded")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                    return;
                }
            case "access":
                {
                    let userAssignment = "";
                    let userProposeUsers = "";
                    let approvedUsersReplay = "";
                    let nameSave = this.validateNameOnUpdate(saveAsNewRule);
                    for (let x = 0; x < this.state.userAssignmentUsers.length; x++) {
                        if (x !== 0)
                            userAssignment += ",";
                        userAssignment += this.state.userAssignmentUsers[x].userId;

                    };
                    for (let z = 0; z < this.state.userProposeUsers.length; z++) {
                        if (z !== 0)
                            userProposeUsers += ",";
                        userProposeUsers += this.state.userProposeUsers[z].userId;
                    };
                    for (let y = 0; y < this.state.approvedUsersReplay.length; y++) {
                        if (y !== 0)
                            approvedUsersReplay += ",";
                        approvedUsersReplay += this.state.approvedUsersReplay[y].userId;

                    };

                    data = await apol
                        .mutate({
                            mutation: mutationUpdateRuleAccess,
                            refetchQueries: [{
                                query: queryGetConfigData,
                                variables: { auth: this.props.authorization },
                            }],
                            variables: {
                                id: (!saveAsNewRule) ? this.props.data.id : Utils.guidGenerator(),
                                auth: this.props.authorization,
                                name: nameSave,
                                group: this.state.selectedItem.key,
                                text: this.state.text,
                                confListUser: this.state.accListUser,
                                confAddUser: this.state.accAddUser,
                                confTicket: this.state.accTicket,
                                confRulesRecording: this.state.accRulesRecording,
                                confRulesReplay: this.state.accRulesReplay,
                                confRulesAccess: this.state.accRulesAccess,
                                confRulesAnalytics: this.state.accRulesAnalytics,
                                confMapUser: this.state.accMapUser,
                                confUserAssignment: this.state.accAssignment,
                                confAssignRecording: this.state.accAssignRecording,
                                confAssignReplay: this.state.accAssignReplay,
                                confAssignAccess: this.state.accAssignAccess,
                                confAssignAnalytics: this.state.accAssignAnalytics,
                                confAuditLogs: this.state.accAuditLogs,
                                confJobs: this.state.accJobs,
                                confCustomFields: this.state.customFields,
                                confAdministrateCustomFields: this.state.administrateCustomFields,
                                confDefineActionOnCustomFields: this.state.defActionCustomFields,
                                cfDisableTagging: this.state.cfDisableTagging,
                                userReporting: this.state.accReporting,
                                userKpiTotal: this.state.accKpiTotal,
                                userKpiIncoming: this.state.accKpiIncoming,
                                userKpiOutgoing: this.state.accKpiOutgoing,
                                userKpiKeywords: this.state.accKpiKeywords,
                                userKpiWallboard: this.state.accKpiWallboard,
                                userSafe: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.accUserSafe : 'No',
                                ownFolder: {
                                    addConversations: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.addConversations : false,
                                    removeConversations: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.removeConversations : false,
                                    upserFolder: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.upserFolder : false,
                                    userIds: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.userIds : [],
                                    exportFolders: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.exportFolders : false,
                                    deleteFolders: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.deleteFolders : false,
                                    shareFolders: this.state.conversationSafeAccess !== null ? this.state.conversationSafeAccess.restrictedAccess.ownFolder.shareFolders : false,
                                },
                                userDelete: this.state.accUserDelete,
                                userPropose: this.state.accUserPropose,
                                userProposeReasoning: this.state.accUserProposeReasoning,
                                userProposeUsers: userProposeUsers,
                                userAssignmentUsers: userAssignment,
                                userLiveView: this.state.accLiveView,
                                userDenyExport: this.state.accUserDenyExport,
                                userDenyUpload: this.state.accUserDenyUpload,
                                userBulkExport: this.state.accUserBulkExport,
                                userBulkRestoreArchive: this.state.accUserBulkRestoreArchive,
                                userBulkSafe: this.state.accUserBulkSafe,
                                userBulkSafeAdd: this.state.userBulkSafeAdd,
                                userBulkSafeRemove: this.state.userBulkSafeRemove,
                                userBulkDelete: this.state.accUserBulkDelete,
                                userBulkTranscript: this.state.accUserBulkTranscript,
                                userBulkExcel: this.state.accUserBulkExcel,
                                userBulkIpRange: this.state.accUserBulkIpRange,
                                userBulkPasswordLength: this.state.accUserBulkPasswordLength.toString(),
                                complianceResultsAccess: this.state.complianceResultsAccess,
                                complianceQMResultsAccess: this.state.complianceQMResultsAccess,
                                userCompliancePolicy: this.state.accUserCompliancePolicy,
                                userCompliancePolicyActivate: this.state.accUserCompliancePolicyActivate,
                                userCompliancePolicyInitiateWorkflow: this.state.accUserCompliancePolicyInitiateWorkflow,
                                userCompliancePolicyEdit: this.state.accUserCompliancePolicyEdit,
                                userCompliancePolicyAssign: this.state.accUserCompliancePolicyAssign,
                                userComplianceAIQuestions: this.state.accUserComplianceAIQuestions,
                                userComplianceQM: this.state.userComplianceQM,
                                denyReportAlarming: this.state.denyReportAlarming,
                                archivedConversationAccessType: this.state.accUserArchievedConversationType,
                                archivedRequestRetrievalReasoningRequired: this.state.accUserArchievedConversationType == "RequestRetrieval" ? this.state.accUserRequestArchiveRetrievalProcessReasoning : false,
                                approvedUsersReplay: approvedUsersReplay,
                                hasCustomFieldsAccess: this.state.hasCustomFieldsAccess,
                                customFieldsAccessType: this.state.customFieldsAccessType,
                                customFieldsEditableTime: this.state.customFieldsEditableTime,
                                accesibleCustomFields: this.state.accesibleCustomFields,
                                editableTimeCustomFields: this.state.editableTimeCustomFields,
                                allowTTLChangeViaCategorization: this.state.allowTTLChangeViaCategorization,
                                suspensionConvDelProcess: this.state.suspensionConvDelProcess,
                                accMergeAudioToVideo: this.state.accMergeAudioToVideo,
                                shareOptionWasDisabled: this.props.data.ownFolder.shareFolders && !this.state.conversationSafeAccess.restrictedAccess.ownFolder.shareFolders
                            }
                        });
                    if (data.data.updateRuleAccess == null || data.data.updateRuleAccess.name === "error") {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "updateAccessRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "accessRuleUpdated")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                    return;
                }
            case "analytics":
                {
                    // Collect Entities, Keywords, Categories
                    let anaEntities = "";
                    let anaCategories = "";
                    let anaKeywords = "";
                    let nameSave = this.validateNameOnUpdate(saveAsNewRule);
                    for (let x = 0; x < this.state.sel4list.length; x++) {
                        if (x !== 0)
                            anaCategories += ",";
                        anaCategories += this.state.sel4list[x].id;

                    };
                    for (let y = 0; y < this.state.sel4listEntities.length; y++) {
                        if (y !== 0)
                            anaEntities += ",";
                        anaEntities += this.state.sel4listEntities[y].id;

                    };
                    for (let z = 0; z < this.state.sel4listKeyword.length; z++) {
                        if (z !== 0)
                            anaKeywords += ",";
                        anaKeywords += this.state.sel4listKeyword[z].id;

                    };

                    data = await apol
                        .mutate({
                            mutation: mutationUpdateRuleAnalytics,
                            refetchQueries: [{
                                query: queryGetConfigData,
                                variables: { auth: this.props.authorization },
                            }],
                            variables: {
                                id: (!saveAsNewRule) ? this.props.data.id : Utils.guidGenerator(),
                                auth: this.props.authorization,
                                name: nameSave,
                                group: this.state.selectedItem.key,
                                accessType: this.state.anaType,
                                startTime: this.state.anaStartDate == "" ? null : this.state.anaStartDate,
                                endTime: this.state.anaEndDate == "" ? null : this.state.anaEndDate,
                                withTranscription: this.state.anaTranscription,
                                withVideoIndexer: this.state.anaVideoIndexer,
                                transcriptLanguage: this.state.anaSpeechLanguage,
                                withSentiment: this.state.anaSentiment,
                                keywordCategories: anaCategories,
                                entityCategories: anaEntities,
                                keyphraseExtraction: this.state.anaKeyphrase,
                                translationType: this.state.anaTranslate,
                                userVisible: this.state.anaVisibility,
                                onDemandSpeech2Text: this.state.anaDemandSpeech2Text,
                                onDemandKeywords: this.state.anaDemandCategories,
                                onDemandEntities: this.state.anaDemandKeywords,
                                individualKeywords: this.state.anaIndividualKeywords,
                                translationLanguage: this.state.anaTranslationLanguage,
                                keywordList: anaKeywords,
                                alternativeLanguage: this.state.anaTranscription ? this.state.alternativeLanguage : false,
                                optionAlternativeLanguageKey: this.state.optionAlternativeLanguageKey.toString(),
                                multilingualConversations: this.state.alternativeLanguage ? this.state.multilingualConversations : false,
                                text: this.state.text,
                                analyzeAllConditions: this.state.analyzeAllConditions,
                                hasSpeakerRecognition: this.state.anaHasSpeakerRecognition 
                            }
                        });
                    if (data.data.updateRuleAnalytics == null || data.data.updateRuleAnalytics.name === "error") {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "updateAnalyticsRuleNotSuccessful")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.info(<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "analyticsRuleUpdated")}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                    return;
                }
            default: return;
        }

    };

    rendeSel() {
        if (this.state.selectedItem != null) {
            switch (this.state.selectedItem.key) {
                case null:
                    return;
                case "recording":
                    return this.renderRec();
                case "replay":
                    return this.renderRep();
                case "access":
                    return this.renderAcc();
                case "analytics":
                    return this.renderAna();
                default:
                    return <div> </div>
            }
        }
    }

    uploadToDefault = (file, type) => {
        var ann = this.state.defaultAnnouncement;
        let prevFile = "";
        let deletedFiles = this.state.deletedFilesFromAnnouncements;
        if (type === "Start") {
            prevFile = ann.start;
            ann.start = file;
        } else if (type === "Stop") {
            prevFile = ann.stop;
            ann.stop = file;
        }
        if (prevFile !== undefined && prevFile !== "") {
            deletedFiles.push(prevFile);
        }
        this.setState({ defaultAnnouncement: ann, deletedFilesFromAnnouncements: deletedFiles, updated: true });
    }

    handleBlurName = (event) => {
        const newValue = event.target.value;

        let isValid = this.props.validateNameFcn(newValue);

        //if current name matches with props.data name then is valid
        if (this.props.data !== null && typeof this.props.data !== 'undefined') {
            if (newValue.trim().toLowerCase() === this.props.data.name.trim().toLowerCase()) {
                isValid = true;
            }
        }

        //updatedName is true only if it's valid, this is used later for disabling buttons
        this.setState({
            isNameValidationError: isValid ? "" : "Rule name cannot be empty and must be unique. Please choose another name.",
            updatedName: isValid,
        });
    };

    renderRec = () => {
        let numberPrefixes = [];

        for (var i = 0; i < this.state.numberPrefixIndex; i++) {
            numberPrefixes.push(<NumberPrefix id={i} key={i} announcement={this.state.announcements[i]} styles={styles} deleteAnnouncement={this.onDeleteAnnouncement} updateAnnouncement={this.onUpdatedAnnouncement} onFileChange={this.onFileChange} authorization={this.props.authorization} client={this.props.apol} />)
        }

        return <MyContext.Consumer>
            {(context) => (
                <Stack>
                    <TextField type={"text"}
                        value={this.state.name}
                        placeholder={context.getTranslation("configuration", "ruleNamePlaceholder")}
                        label={context.getTranslation("configuration", "ruleName")}
                        onChange={(event, newValue) => {
                            this.setState({
                                name: newValue,
                            });
                        }}
                        onBlur={this.handleBlurName}
                        errorMessage={this.state.isNameValidationError.length > 0 ? this.state.isNameValidationError : ""}
                        required
                    />

                    <Stack>


                        <Checkbox
                            label={context.getTranslation("configuration", "audio")}
                            checked={this.state.recAudio}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    recAudio: newValue,
                                    updated: true
                                });
                            }}
                        />

                        <Checkbox
                            label={context.getTranslation("configuration", "videoRecording")}
                            checked={this.state.recVideo}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    recVideo: newValue,
                                    recScreenOnly: newValue === true ? false : this.state.recScreenOnly,
                                    updated: true
                                });
                            }}
                        />
                        <Checkbox
                            label={context.getTranslation("configuration", "screenOnly")}
                            checked={this.state.recScreenOnly}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    recScreenOnly: newValue,
                                    recVideo: newValue === true ? false : this.state.recVideo,
                                    updated: true
                                });
                            }}
                        />
                        <Checkbox
                            label={context.getTranslation("configuration", "reevaluateCallWhenChange1:1ToMeeting")}
                            checked={this.state.recReevaluateChangeFromOneToOneToMeeting}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    recReevaluateChangeFromOneToOneToMeeting: newValue,
                                    updated: true
                                });
                            }}
                        />
                    </Stack>

                    {(this.state.recAudio || this.state.recVideo || this.state.recScreenOnly) &&
                        <Stack>
                            <ChoiceGroup
                                selectedKey={this.state.recAudioType}
                                style={{ width: "200px" }}
                                options={[
                                    {
                                        key: 'Bulk',
                                        text: context.getTranslation("configuration", "bulkRecording"),
                                        'data-automation-id': 'auto1',
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'Never',
                                        text: context.getTranslation("configuration", "neverRecord"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'Demand',
                                        text: context.getTranslation("configuration", "recordOnDemand"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'ControlBulkRecording',
                                        text: context.getTranslation("configuration", "recordingControlForBulkRecording"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'Switch',
                                        text: context.getTranslation("configuration", "recordSwitch"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'Keep/Delete',
                                        text: context.getTranslation("configuration", "keepDelete"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'Complex',
                                        text: context.getTranslation("configuration", "complexRecording"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    }
                                ]}
                                onChange={(event, newValue) => {
                                    if (newValue.key === 'Keep/Delete') {
                                        this.modifyState("NonComplex", "Keep", "startMode")
                                    }
                                    this.setState({
                                        recAudioType: newValue.key,
                                        updated: true
                                    });
                                }}
                                label={context.getTranslation("configuration", "recordingType")}
                            />

                            {this.state.recAudioType === "Switch" &&
                                <Checkbox
                                    label={context.getTranslation("configuration", "recordSwitchInitialState")}
                                    checked={this.state.recSwitchInitialState}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            recSwitchInitialState: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                            }
                            {this.state.recAudioType === "Keep/Delete" &&
                                <KeepDeleteSettings type="NonComplex" updateState={this.modifyState} data={this.state.keepDeleteSettingsNonComplex} styleCheckbox={checkboxStyles} />
                            }

                            {this.state.recAudioType === "Complex" && <Stack>
                                <ChoiceGroup
                                    selectedKey={this.state.recInboundType}
                                    style={{ width: "200px" }}
                                    options={[
                                        {
                                            key: 'Bulk',
                                            text: context.getTranslation("configuration", "bulkRecording"),
                                            'data-automation-id': 'auto1',
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Never',
                                            text: context.getTranslation("configuration", "neverRecord"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Demand',
                                            text: context.getTranslation("configuration", "recordOnDemand"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ControlBulkRecording',
                                            text: context.getTranslation("configuration", "recordingControlForBulkRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Allowlist',
                                            text: context.getTranslation("configuration", "recordAllowlist"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ExclusiveAllowList',
                                            text: context.getTranslation("configuration", "exclusiveAllowList"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Denylist',
                                            text: context.getTranslation("configuration", "recordDenylist"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ExclusiveDenyList',
                                            text: context.getTranslation("configuration", "exclusiveDenyList"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'AllowTransferorList',
                                            text: context.getTranslation("configuration", "allowQueueRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'DenyTransferorList',
                                            text: context.getTranslation("configuration", "denyQueueRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Keep/Delete',
                                            text: context.getTranslation("configuration", "keepDelete"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },

                                    ]}
                                    onChange={(event, newValue) => {
                                        if (newValue.key === 'Keep/Delete') {
                                            this.modifyState("Inbound", "Keep", "startMode")
                                        }
                                        this.setState({
                                            recInboundType: newValue.key,
                                            updated: true
                                        });
                                    }}
                                    label={context.getTranslation("configuration", "recordingTypeInbound")}
                                />
                                {this.state.recInboundType === "Keep/Delete" &&
                                    <KeepDeleteSettings type="Inbound" updateState={this.modifyState} data={this.state.keepDeleteSettingsInbound} styleCheckbox={checkboxStyles} />
                                }
                                <Stack horizontal>

                                    <Checkbox
                                        label={context.getTranslation("configuration", "wrapTimer")}
                                        checked={this.state.keepDeleteSettingsInbound.hasWrapUp}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => { this.modifyState("Inbound", newValue, "hasWrapUp") }}

                                    />
                                    <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                                        <TextField type={"number"}
                                            value={this.state.keepDeleteSettingsInbound.wrapUpTimer}
                                            onChange={(event, newValue) => { this.modifyState("Inbound", parseInt(newValue), "wrapUpTimer") }}
                                            disabled={!this.state.keepDeleteSettingsInbound.hasWrapUp}
                                            min="1"
                                            max="900"
                                            errorMessage={this.state.keepDeleteSettingsInbound.wrapUpTimer < 1 ? "Minimum value is 1 sec" : this.state.keepDeleteSettingsInbound.wrapUpTimer > 900 ? "Maximum value is 900 sec" : ""}
                                        />
                                    </div>
                                </Stack>
                                <ChoiceGroup
                                    selectedKey={this.state.recOutboundType}
                                    style={{ width: "200px" }}
                                    options={[
                                        {
                                            key: 'Bulk',
                                            text: context.getTranslation("configuration", "bulkRecording"),
                                            'data-automation-id': 'auto1',
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Never',
                                            text: context.getTranslation("configuration", "neverRecord"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Demand',
                                            text: context.getTranslation("configuration", "recordOnDemand"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ControlBulkRecording',
                                            text: context.getTranslation("configuration", "recordingControlForBulkRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Allowlist',
                                            text: context.getTranslation("configuration", "recordAllowlist"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ExclusiveAllowList',
                                            text: context.getTranslation("configuration", "exclusiveAllowList"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Denylist',
                                            text: context.getTranslation("configuration", "recordDenylist"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ExclusiveDenyList',
                                            text: context.getTranslation("configuration", "exclusiveDenyList"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'AllowTransferorList',
                                            text: context.getTranslation("configuration", "allowQueueRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'DenyTransferorList',
                                            text: context.getTranslation("configuration", "denyQueueRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Keep/Delete',
                                            text: context.getTranslation("configuration", "keepDelete"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                    ]}
                                    onChange={(event, newValue) => {
                                        if (newValue.key === 'Keep/Delete') {
                                            this.modifyState("Outbound", "Keep", "startMode")
                                        }
                                        this.setState({
                                            recOutboundType: newValue.key,
                                            updated: true
                                        });
                                    }}
                                    label={context.getTranslation("configuration", "recordingTypeOutbound")}
                                />
                                {this.state.recOutboundType === "Keep/Delete" &&
                                    <KeepDeleteSettings type="Outbound" updateState={this.modifyState} data={this.state.keepDeleteSettingsOutbound} styleCheckbox={checkboxStyles} />
                                }
                                <Stack horizontal>

                                    <Checkbox
                                        label={context.getTranslation("configuration", "wrapTimer")}
                                        checked={this.state.keepDeleteSettingsOutbound.hasWrapUp}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => { this.modifyState("Outbound", newValue, "hasWrapUp") }}

                                    />
                                    <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                                        <TextField type={"number"}
                                            value={this.state.keepDeleteSettingsOutbound.wrapUpTimer}
                                            onChange={(event, newValue) => { this.modifyState("Outbound", parseInt(newValue), "wrapUpTimer") }}
                                            disabled={!this.state.keepDeleteSettingsOutbound.hasWrapUp}
                                            min="1"
                                            max="900"
                                            errorMessage={this.state.keepDeleteSettingsOutbound.wrapUpTimer < 1 ? "Minimum value is 1 sec" : this.state.keepDeleteSettingsOutbound.wrapUpTimer > 900 ? "Maximum value is 900 sec" : ""}
                                        />
                                    </div>
                                </Stack>
                                <ChoiceGroup
                                    selectedKey={this.state.recMeetingType}
                                    style={{ width: "200px" }}

                                    options={[
                                        {
                                            key: 'Bulk',
                                            text: context.getTranslation("configuration", "bulkRecording"),
                                            'data-automation-id': 'auto1',
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Never',
                                            text: context.getTranslation("configuration", "neverRecord"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Demand',
                                            text: context.getTranslation("configuration", "recordOnDemand"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ControlBulkRecording',
                                            text: context.getTranslation("configuration", "recordingControlForBulkRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Keep/Delete',
                                            text: context.getTranslation("configuration", "keepDelete"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Complex',
                                            text: context.getTranslation("configuration", "complexRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        }
                                    ]}
                                    onChange={(event, newValue) => {
                                        if (newValue.key === 'Keep/Delete') {
                                            this.modifyState("Meeting", "Keep", "startMode")
                                        }
                                        this.setState({
                                            recMeetingType: newValue.key,
                                            updated: true
                                        });
                                    }}
                                    label={context.getTranslation("configuration", "recordingTypeMeeting")}
                                />
                                {this.state.recMeetingType === "Keep/Delete" &&
                                    <KeepDeleteSettings type="Meeting" updateState={this.modifyState} data={this.state.keepDeleteSettingsMeeting} styleCheckbox={checkboxStyles} />
                                }
                                {this.state.recMeetingType !== "Complex" && <Stack horizontal>

                                    <Checkbox
                                        label={context.getTranslation("configuration", "wrapTimer")}
                                        checked={this.state.keepDeleteSettingsMeeting.hasWrapUp}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => { this.modifyState("Meeting", newValue, "hasWrapUp") }}

                                    />
                                    <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                                        <TextField type={"number"}
                                            value={this.state.keepDeleteSettingsMeeting.wrapUpTimer}
                                            onChange={(event, newValue) => { this.modifyState("Meeting", parseInt(newValue), "wrapUpTimer") }}
                                            disabled={!this.state.keepDeleteSettingsMeeting.hasWrapUp}
                                            min="1"
                                            max="900"
                                            errorMessage={this.state.keepDeleteSettingsMeeting.wrapUpTimer < 1 ? "Minimum value is 1 sec" : this.state.keepDeleteSettingsMeeting.wrapUpTimer > 900 ? "Maximum value is 900 sec" : ""}
                                        />
                                    </div>
                                </Stack>
                                }
                                {this.state.recMeetingType === "Complex" && <Stack>
                                    <ChoiceGroup
                                        selectedKey={this.state.recMeetingExternalType}
                                        style={{ width: "200px" }}
                                        options={[
                                            {
                                                key: 'Bulk',
                                                text: context.getTranslation("configuration", "bulkRecording"),
                                                'data-automation-id': 'auto1',
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Never',
                                                text: context.getTranslation("configuration", "neverRecord"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Demand',
                                                text: context.getTranslation("configuration", "recordOnDemand"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'ControlBulkRecording',
                                                text: context.getTranslation("configuration", "recordingControlForBulkRecording"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Allowlist',
                                                text: context.getTranslation("configuration", "recordAllowlist"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'ExclusiveAllowList',
                                                text: context.getTranslation("configuration", "exclusiveAllowList"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Denylist',
                                                text: context.getTranslation("configuration", "recordDenylist"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'ExclusiveDenyList',
                                                text: context.getTranslation("configuration", "exclusiveDenyList"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Organizer',
                                                text: context.getTranslation("configuration", "recordOrganizer"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'AllowMeetingSubjectList',
                                                text: context.getTranslation("configuration", "allowMeetingSubjectList"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'DenyMeetingSubjectList',
                                                text: context.getTranslation("configuration", "denyMeetingSubjectList"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Keep/Delete',
                                                text: context.getTranslation("configuration", "keepDelete"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                        ]}
                                        onChange={(event, newValue) => {
                                            if (newValue.key === 'Keep/Delete') {
                                                this.modifyState("ExternalMeeting", "Keep", "startMode")
                                            }
                                            this.setState({
                                                recMeetingExternalType: newValue.key,
                                                updated: true
                                            });
                                        }}
                                        label={context.getTranslation("configuration", "meetingExternal")}
                                    />
                                    {this.state.recMeetingExternalType === "Keep/Delete" &&
                                        <KeepDeleteSettings type="ExternalMeeting" updateState={this.modifyState} data={this.state.keepDeleteSettingsMeetingExternal} styleCheckbox={checkboxStyles} />
                                    }
                                    <Stack horizontal>

                                        <Checkbox
                                            label={context.getTranslation("configuration", "wrapTimer")}
                                            checked={this.state.keepDeleteSettingsMeetingExternal.hasWrapUp}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => { this.modifyState("ExternalMeeting", newValue, "hasWrapUp") }}

                                        />
                                        <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                                            <TextField type={"number"}
                                                value={this.state.keepDeleteSettingsMeetingExternal.wrapUpTimer}
                                                onChange={(event, newValue) => { this.modifyState("ExternalMeeting", parseInt(newValue), "wrapUpTimer") }}
                                                disabled={!this.state.keepDeleteSettingsMeetingExternal.hasWrapUp}
                                                min="1"
                                                max="900"
                                                errorMessage={this.state.keepDeleteSettingsMeetingExternal.wrapUpTimer < 1 ? "Minimum value is 1 sec" : this.state.keepDeleteSettingsMeetingExternal.wrapUpTimer > 900 ? "Maximum value is 900 sec" : ""}
                                            />
                                        </div>
                                    </Stack>
                                    <ChoiceGroup
                                        selectedKey={this.state.recMeetingInternalType}
                                        style={{ width: "200px" }}
                                        options={[
                                            {
                                                key: 'Bulk',
                                                text: context.getTranslation("configuration", "bulkRecording"),
                                                'data-automation-id': 'auto1',
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Never',
                                                text: context.getTranslation("configuration", "neverRecord"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Demand',
                                                text: context.getTranslation("configuration", "recordOnDemand"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'ControlBulkRecording',
                                                text: context.getTranslation("configuration", "recordingControlForBulkRecording"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Allowlist',
                                                text: context.getTranslation("configuration", "recordAllowlist"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'ExclusiveAllowList',
                                                text: context.getTranslation("configuration", "exclusiveAllowList"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Denylist',
                                                text: context.getTranslation("configuration", "recordDenylist"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'ExclusiveDenyList',
                                                text: context.getTranslation("configuration", "exclusiveDenyList"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Organizer',
                                                text: context.getTranslation("configuration", "recordOrganizer"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'AllowMeetingSubjectList',
                                                text: context.getTranslation("configuration", "allowMeetingSubjectList"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'DenyMeetingSubjectList',
                                                text: context.getTranslation("configuration", "denyMeetingSubjectList"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Keep/Delete',
                                                text: context.getTranslation("configuration", "keepDelete"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                        ]}
                                        onChange={(event, newValue) => {
                                            if (newValue.key === 'Keep/Delete') {
                                                this.modifyState("InternalMeeting", "Keep", "startMode")
                                            }
                                            this.setState({
                                                recMeetingInternalType: newValue.key,
                                                updated: true
                                            });
                                        }}
                                        label={context.getTranslation("configuration", "meetingInternal")}
                                    />
                                    {(this.state.recMeetingType === 'Complex' && (this.state.recMeetingExternalType === 'Organizer' || this.state.recMeetingInternalType === 'Organizer')) && <Stack>
                                        <Label variant="large" block>{context.getTranslation("configuration", "recordOrganizerItems")}</Label>
                                        <UserPicker
                                            user={this.state.recMeetingOrganizer}

                                            fc={(user) => {
                                                this.setState({
                                                    recMeetingOrganizer: user,
                                                    updated: true
                                                });
                                            }}
                                        />

                                    </Stack>}
                                    {(this.state.recMeetingType === 'Complex' && (this.state.recMeetingExternalType === 'Organizer' || this.state.recMeetingInternalType === 'Organizer')) && <Stack>
                                        <Label variant="large" block>{context.getTranslation("configuration", "recordOrganizerAttendee")}</Label>
                                        <UserPicker
                                            user={this.state.recMeetingParticipant}

                                            fc={(user) => {
                                                this.setState({
                                                    recMeetingParticipant: user,
                                                    updated: true
                                                });
                                            }}
                                        />
                                    </Stack>}

                                    {(this.state.recMeetingType === 'Complex' && (this.state.recMeetingExternalType === 'AllowMeetingSubjectList' || this.state.recMeetingInternalType === 'AllowMeetingSubjectList')) && <Stack>
                                        <TextField
                                            label={context.getTranslation("configuration", "meetingSubjectAllowList")}
                                            value={this.state.recMeetingSubjectAllowList}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    recMeetingSubjectAllowList: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />

                                    </Stack>}

                                    {(this.state.recMeetingType === 'Complex' && (this.state.recMeetingExternalType === 'DenyMeetingSubjectList' || this.state.recMeetingInternalType === 'DenyMeetingSubjectList')) && <Stack>
                                        <TextField
                                            label={context.getTranslation("configuration", "meetingSubjectDenyList")}
                                            value={this.state.recMeetingSubjectDenyList}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    recMeetingSubjectDenyList: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />

                                    </Stack>}

                                    {this.state.recMeetingInternalType === "Keep/Delete" &&
                                        <KeepDeleteSettings type="InternalMeeting" updateState={this.modifyState} data={this.state.keepDeleteSettingsMeetingInternal} styleCheckbox={checkboxStyles} />
                                    }
                                    <Stack horizontal>

                                        <Checkbox
                                            label={context.getTranslation("configuration", "wrapTimer")}
                                            checked={this.state.keepDeleteSettingsMeetingInternal.hasWrapUp}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => { this.modifyState("InternalMeeting", newValue, "hasWrapUp") }}

                                        />
                                        <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                                            <TextField type={"number"}
                                                value={this.state.keepDeleteSettingsMeetingInternal.wrapUpTimer}
                                                onChange={(event, newValue) => { this.modifyState("InternalMeeting", parseInt(newValue), "wrapUpTimer") }}
                                                disabled={!this.state.keepDeleteSettingsMeetingInternal.hasWrapUp}
                                                min="1"
                                                max="900"
                                                errorMessage={this.state.keepDeleteSettingsMeetingInternal.wrapUpTimer < 1 ? "Minimum value is 1 sec" : this.state.keepDeleteSettingsMeetingInternal.wrapUpTimer > 900 ? "Maximum value is 900 sec" : ""}
                                            />
                                        </div>
                                    </Stack>
                                </Stack>}

                                <ChoiceGroup
                                    selectedKey={this.state.recInternalType}
                                    style={{ width: "200px" }}

                                    options={[
                                        {
                                            key: 'Bulk',
                                            text: context.getTranslation("configuration", "bulkRecording"),
                                            'data-automation-id': 'auto1',
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Never',
                                            text: context.getTranslation("configuration", "neverRecord"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Demand',
                                            text: context.getTranslation("configuration", "recordOnDemand"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ControlBulkRecording',
                                            text: context.getTranslation("configuration", "recordingControlForBulkRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Allowlist',
                                            text: context.getTranslation("configuration", "recordAllowlist"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ExclusiveAllowList',
                                            text: context.getTranslation("configuration", "exclusiveAllowList"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Denylist',
                                            text: context.getTranslation("configuration", "recordDenylist"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'ExclusiveDenyList',
                                            text: context.getTranslation("configuration", "exclusiveDenyList"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'AllowTransferorList',
                                            text: context.getTranslation("configuration", "allowQueueRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'DenyTransferorList',
                                            text: context.getTranslation("configuration", "denyQueueRecording"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Keep/Delete',
                                            text: context.getTranslation("configuration", "keepDelete"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                    ]}
                                    onChange={(event, newValue) => {
                                        if (newValue.key === 'Keep/Delete') {
                                            this.modifyState("Internal", "Keep", "startMode")
                                        }
                                        this.setState({
                                            recInternalType: newValue.key,
                                            updated: true
                                        });
                                    }}
                                    label={context.getTranslation("configuration", "recordingTypeInternal")}
                                />
                                {this.state.recInternalType === "Keep/Delete" &&
                                    <KeepDeleteSettings type="Internal" updateState={this.modifyState} data={this.state.keepDeleteSettingsInternal} styleCheckbox={checkboxStyles} />
                                }
                                <Stack horizontal>

                                    <Checkbox
                                        label={context.getTranslation("configuration", "wrapTimer")}
                                        checked={this.state.keepDeleteSettingsInternal.hasWrapUp}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => { this.modifyState("Internal", newValue, "hasWrapUp") }}

                                    />
                                    <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                                        <TextField type={"number"}
                                            value={this.state.keepDeleteSettingsInternal.wrapUpTimer}
                                            onChange={(event, newValue) => { this.modifyState("Internal", parseInt(newValue), "wrapUpTimer") }}
                                            disabled={!this.state.keepDeleteSettingsInternal.hasWrapUp}
                                            min="1"
                                            max="900"
                                            errorMessage={this.state.keepDeleteSettingsInternal.wrapUpTimer < 1 ? "Minimum value is 1 sec" : this.state.keepDeleteSettingsInternal.wrapUpTimer > 900 ? "Maximum value is 900 sec" : ""}
                                        />
                                    </div>
                                </Stack>
                                {(this.state.recInboundType === 'Allowlist' || this.state.recInternalType === 'Allowlist' || this.state.recOutboundType === 'Allowlist' || this.state.recMeetingInternalType === 'Allowlist' || this.state.recMeetingExternalType === 'Allowlist' ||
                                    this.state.recInboundType === 'ExclusiveAllowList' || this.state.recInternalType === 'ExclusiveAllowList' || this.state.recOutboundType === 'ExclusiveAllowList' || this.state.recMeetingInternalType === 'ExclusiveAllowList' || this.state.recMeetingExternalType === 'ExclusiveAllowList') && <Stack>
                                        <Label variant="large" block>{context.getTranslation("configuration", "recordAllowlistItems")}</Label>
                                        <UserPicker
                                            user={this.state.recAllowList}
                                            fc={(user) => {
                                                this.setState({
                                                    recAllowList: user,
                                                    updated: true
                                                });
                                            }}

                                        />
                                    </Stack>}
                                {(this.state.recInboundType === 'Denylist' || this.state.recInternalType === 'Denylist' || this.state.recOutboundType === 'Denylist' || this.state.recMeetingInternalType === 'Denylist' || this.state.recMeetingExternalType === 'Denylist' ||
                                    this.state.recInboundType === 'ExclusiveDenyList' || this.state.recInternalType === 'ExclusiveDenyList' || this.state.recOutboundType === 'ExclusiveDenyList' || this.state.recMeetingInternalType === 'ExclusiveDenyList' || this.state.recMeetingExternalType === 'ExclusiveDenyList') && <Stack>
                                        <Label variant="large" block>{context.getTranslation("configuration", "recordDenylistItems")}</Label>
                                        <UserPicker
                                            user={this.state.recDenyList}

                                            fc={(user) => {
                                                this.setState({
                                                    recDenyList: user,
                                                    updated: true
                                                });
                                            }}
                                        />
                                    </Stack>}

                                {(this.state.recInboundType === "AllowTransferorList" || this.state.recOutboundType === "AllowTransferorList" || this.state.recInternalType === "AllowTransferorList") && <>


                                    <Text variant="large" block>{context.getTranslation("configuration", "allowQueueRecordingList")}</Text>
                                    <UserPicker
                                        user={this.state.recTransferorAllowList}
                                        placeholder={context.getTranslation("configuration", "typeCallQueueUsername")}
                                        fc={(user) => {
                                            this.setState({
                                                recTransferorAllowList: user,
                                                updated: true
                                            });
                                        }}
                                    />
                                </>}
                                {(this.state.recInboundType === "DenyTransferorList" || this.state.recOutboundType === "DenyTransferorList" || this.state.recInternalType === "DenyTransferorList") && <>

                                    <Text variant="large" block>{context.getTranslation("configuration", "denyQueueRecordingList")}</Text>
                                    <UserPicker
                                        user={this.state.recTransferorDenyList}
                                        placeholder={context.getTranslation("configuration", "typeCallQueueUsername")}
                                        fc={(user) => {
                                            this.setState({
                                                recTransferorDenyList: user,
                                                updated: true
                                            });
                                        }}
                                    />
                                </>}
                            </Stack>
                            }


                            {this.state.recAudioType === 'Inbound' && <Stack>
                                <Label>{context.getTranslation("configuration", "areaCode")}:</Label>
                                <p><TextField
                                    value={this.state.recAreaCode}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            recAreaCode: newValue,
                                            updated: true
                                        });
                                    }}
                                /></p>
                            </Stack>}
                        </Stack>}
                    <Stack>
                        <Checkbox
                            label={context.getTranslation("configuration", "chat")}
                            checked={this.state.recChat}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    recChat: newValue,
                                    updated: true
                                });
                            }}
                        />
                        {this.state.recChat && <Stack>
                            <ChoiceGroup
                                selectedKey={this.state.recChatType}
                                style={{ width: "200px" }}

                                options={[
                                    {
                                        key: 'Bulk',
                                        text: context.getTranslation("configuration", "bulkRecording"),
                                        'data-automation-id': 'auto1',
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'Sync',
                                        text: context.getTranslation("configuration", "recordLikeAudioVideo"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'SyncExternal',
                                        text: context.getTranslation("configuration", "recChatSyncExternal"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'External',
                                        text: context.getTranslation("configuration", "recChatExternal"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    }
                                ]}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        recChatType: newValue.key,
                                        updated: true
                                    });
                                }}
                                label={context.getTranslation("configuration", "recChatType")}
                            />
                            <ChoiceGroup
                                selectedKey={this.state.recChannelType}
                                style={{ width: "200px" }}

                                options={[
                                    {
                                        key: 'Never',
                                        text: context.getTranslation("configuration", "neverRecord"),
                                        'data-automation-id': 'auto1',
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'Internal',
                                        text: context.getTranslation("configuration", "recChannelInternal"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'External',
                                        text: context.getTranslation("configuration", "recChannelExternal"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'Select',
                                        text: context.getTranslation("configuration", "recChannelSelect"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    },
                                    {
                                        key: 'Bulk',
                                        text: context.getTranslation("configuration", "bulkRecording"),
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    }
                                ]}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        recChannelType: newValue.key,
                                        updated: true
                                    });
                                }}
                                label={context.getTranslation("configuration", "recChannelType")}
                            />
                            <Stack>
                                <Checkbox
                                    label={context.getTranslation("configuration", "recChatTypeDeny")}
                                    checked={this.state.recChatTypeDeny}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            recChatTypeDeny: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                            </Stack>
                        </Stack>}
                        {this.state.recChannelType === 'Select' && <Stack>
                            <p>{context.getTranslation("configuration", "recChannelList")}:</p>
                            <p><TextField
                                value={this.state.recChannelList}
                                placeholder={context.getTranslation("configuration", "recChannelListPlaceholder")}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        recChannelList: newValue,
                                        updated: true
                                    });
                                }}
                            /></p>
                        </Stack>}
                    </Stack>
                    <hr />
                    {/*add DTMF code*/}

                    {((this.state.recAudioType === 'Demand' || this.state.recAudioType === 'ControlBulkRecording')
                        || (this.state.recAudioType === 'Complex' && (this.state.recInboundType === 'Demand' || this.state.recOutboundType === 'Demand' || this.state.recMeetingType === 'Demand' || this.state.recInternalType === 'Demand' || this.state.recInboundType === 'ControlBulkRecording' || this.state.recOutboundType === 'ControlBulkRecording' || this.state.recMeetingType === 'ControlBulkRecording' || this.state.recInternalType === 'ControlBulkRecording'))
                        || ((this.state.recAudioType === 'Complex' && this.state.recMeetingType === 'Complex') && (this.state.recMeetingExternalType === 'Demand' || this.state.recMeetingInternalType === 'Demand' || this.state.recMeetingExternalType === 'ControlBulkRecording' || this.state.recMeetingInternalType === 'ControlBulkRecording'))
                    ) &&
                        <>
                            <Stack>
                                {/*ROD control via DTMF code*/}
                                <Checkbox
                                    label={context.getTranslation("configuration", "rodControlViaDTMF")}
                                    checked={this.state.isDtmfEnabled}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            isDtmfEnabled: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                <div>
                                    <p className="display-inline" style={{ marginRight: "10px", marginLeft: "28px" }}>{context.getTranslation("configuration", "rodStartCode")}</p>
                                    <TextField type={"text"}
                                        placeholder="0-9*#"
                                        value={this.state.rodDmtfSTart}
                                        onChange={(event, newValue) => {
                                            var res = this.validateDTMFCode(newValue, "rodStartError");

                                            this.setState({
                                                rodDmtfSTart: res,
                                                updated: true
                                            });
                                        }}
                                        className="display-inline"
                                        disabled={!this.state.isDtmfEnabled}
                                    />
                                </div>
                                <span id="rodStartError" style={{ marginLeft: "128px" }}></span>

                                <div>
                                    <p className="display-inline" style={{ marginRight: "10px", marginLeft: "28px" }}>{context.getTranslation("configuration", "rodStopCode")}</p>
                                    <TextField type={"text"}
                                        placeholder="0-9*#"
                                        value={this.state.rodDmtfStop}
                                        onChange={(event, newValue) => {

                                            var res = this.validateDTMFCode(newValue, "rodStopError");
                                            this.setState({
                                                rodDmtfStop: res,
                                                updated: true
                                            });
                                        }}
                                        className="display-inline"
                                        disabled={!this.state.isDtmfEnabled}

                                    />
                                </div>
                                <span id="rodStopError" style={{ marginLeft: "128px" }}></span>
                                <Checkbox
                                    label={context.getTranslation("configuration", "recordDemandStartOnly")}
                                    checked={this.state.recDemandStartOnly}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            this.setState({
                                                recDemandStartOnly: newValue,
                                                roDStopReasonRequired: false,
                                                updated: true
                                            });
                                        } else {
                                            this.setState({
                                                recDemandStartOnly: newValue,
                                                updated: true
                                            });
                                        }
                                    }}
                                />
                                {/*--------------stop reason for ROD-----------------------------------*/}

                                <Checkbox
                                    label={context.getTranslation("configuration", "rodStopReasonRequired")}
                                    checked={this.state.roDStopReasonRequired}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            this.setState({
                                                roDStopReasonRequired: newValue,
                                                recDemandStartOnly: false,
                                                updated: true
                                            });
                                        } else {
                                            this.setState({
                                                roDStopReasonRequired: newValue,
                                                updated: true
                                            });
                                        }

                                    }}
                                />

                                {this.state.roDStopReasonRequired && <>
                                    <TextField
                                        value={this.state.roDStopReasons}
                                        onChange={this.onChangeReasonsInput}
                                        placeholder="Up to 10 reasons, separated by '|' (e.g., 'Reason 1 | Reason 2')"
                                    />
                                    <span>{this.state.rodStopReasonsPlaceholder}</span>
                                </>
                                }
                                {/*--------------------------------------------------------*/}

                            </Stack>
                        </>
                    }




                    {/*announcement suppression via DTMF code*/}
                    {((this.state.recAudio || this.state.recVideo || this.state.recScreenOnly) && (this.state.recAudioType !== 'Never' || this.state.recInboundType !== 'Never' || this.state.recOutboundType !== 'Never' || this.state.recMeetingType !== 'Never' || this.state.recMeetingExternalType !== 'Never' || this.state.recMeetingInternalType !== 'Never' || this.state.recInternalType !== 'Never')) &&
                        <Stack>
                            <Checkbox
                                label={context.getTranslation("configuration", "announcementsuppressionViaDtmfCode")}
                                checked={this.state.isDtmfAnnouncementSuppressionEnabled}
                                styles={checkboxStyles}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        isDtmfAnnouncementSuppressionEnabled: newValue,
                                        updated: true
                                    });
                                }}
                            />
                            <div>
                                <p className="display-inline" style={{ marginRight: "10px", marginLeft: "28px" }}>{context.getTranslation("configuration", "suppressionCode")}</p>
                                <TextField type={"text"}
                                    placeholder="0-9*#"
                                    value={this.state.announcementSuppressionCode}
                                    onChange={(event, newValue) => {
                                        var res = this.validateDTMFCode(newValue, "suppressionCodeError");

                                        this.setState({
                                            announcementSuppressionCode: res,
                                            updated: true
                                        });
                                    }}
                                    className="display-inline"
                                    disabled={!this.state.isDtmfAnnouncementSuppressionEnabled}
                                />
                            </div>
                            <span id="suppressionCodeError" style={{ marginLeft: "146px" }}></span>

                            <div>
                                <p className="display-inline" style={{ marginRight: "10px", marginLeft: "30px" }}>{context.getTranslation("configuration", "suppressionDays")}</p>
                                <TextField type={"number"}
                                    value={this.state.announcementSuppressionTimeInDays}
                                    onChange={(event, newValue) => {

                                        this.setState({
                                            announcementSuppressionTimeInDays: parseInt(newValue),
                                            updated: true
                                        });
                                    }}
                                    className="display-inline"
                                    disabled={!this.state.isDtmfAnnouncementSuppressionEnabled}
                                    min={0}

                                />
                            </div>
                            <br /><hr />
                        </Stack>
                    }



                    <Checkbox
                        label={context.getTranslation("configuration", "silentRecording")}
                        checked={this.state.recSilentRecording}
                        styles={checkboxStyles}
                        onChange={(event, newValue) => {
                            this.setState({
                                recSilentRecording: newValue,
                                updated: true
                            });
                        }}
                        disabled={this.state.pstnAnnouncement ? true : false}
                    />
                    <Checkbox
                        label={context.getTranslation("configuration", "recordUserStreamOnly")}
                        checked={this.state.recUserStreamOnly}
                        styles={checkboxStyles}
                        onChange={(event, newValue) => {
                            this.setState({
                                recUserStreamOnly: newValue,
                                updated: true
                            });
                        }}
                    />
                    <Checkbox
                        label={context.getTranslation("configuration", "pstnAnnouncement")}
                        checked={this.state.pstnAnnouncement}
                        styles={checkboxStyles}
                        onChange={(event, newValue) => {
                            this.setState({
                                pstnAnnouncement: newValue,
                                updated: true
                            });

                        }}
                        disabled={this.state.recSilentRecording ? true : false}
                    />

                    {this.state.pstnAnnouncement && <Stack.Item style={{ marginLeft: "25px" }}>
                        <ChoiceGroup
                            selectedKey={this.state.announcementType}
                            style={{ width: "200px" }}

                            options={[
                                {
                                    key: 'DefaultAnnouncement',
                                    text: context.getTranslation("configuration", "RIDefaultAnnouncement"),
                                    'data-automation-id': 'auto1',
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                },
                                {
                                    key: 'CustomAnnouncement',
                                    text: context.getTranslation("configuration", "customAnnouncement"),
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                }
                            ]}
                            onChange={(event, newValue) => {
                                this.setState({
                                    announcementType: newValue.key,
                                    updated: true
                                });
                            }}

                        />
                        {this.state.announcementType == "CustomAnnouncement" && <Stack>
                            {numberPrefixes}
                            <Stack.Item>
                                <CommandBarButton
                                    styles={{ root: { paddingTop: 5, paddingBottom: 5 } }}
                                    iconProps={{ iconName: 'Add' }}
                                    text={context.getTranslation("configuration", "addNumberPrefix")}
                                    onClick={() => {
                                        this.setState(prevState => ({
                                            numberPrefixIndex: (prevState.numberPrefixIndex + 1)
                                        }))
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item styles={styles}>
                                <Checkbox
                                    label={context.getTranslation("configuration", "defaultCustomAnnouncement")}
                                    checked={this.state.defaultCustomAnnouncement}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            defaultCustomAnnouncement: newValue,
                                            updated: true
                                        });

                                    }}
                                    disabled={this.state.recSilentRecording ? true : false}
                                />
                            </Stack.Item>
                            {this.state.defaultCustomAnnouncement && <>
                                <Stack horizontal>
                                    <IconButton style={{ marginLeft: "-10px" }} iconProps={{ iconName: 'Delete' }} onClick={() => { this.onDeleteAnnouncement("", true) }} />
                                    <p style={{ marginTop: "5px" }}>{context.getTranslation("configuration", "removeAnnouncement")}</p>
                                </Stack>
                                <Stack.Item>
                                    <UploadAnnouncement authorization={this.props.authorization} apol={this.props.apol} fileBtnText={this.state.defaultAnnouncement.start === "" ? "Start file" : this.state.defaultAnnouncement.start.slice(36)} idResult="startResDef" idError="startErrDef" idFiles="startFileDef" updateAnnouncement={this.uploadToDefault} type="Start" file={this.state.defaultAnnouncement.start} />
                                    <UploadAnnouncement authorization={this.props.authorization} apol={this.props.apol} fileBtnText={this.state.defaultAnnouncement.stop === "" ? "Stop file" : this.state.defaultAnnouncement.stop.slice(36)} idResult="stopResDef" idError="stopErrDef" idFiles="stopFileDef" updateAnnouncement={this.uploadToDefault} type="Stop" file={this.state.defaultAnnouncement.stop} />
                                </Stack.Item>
                            </>}
                        </Stack>}
                        <Checkbox
                            label={context.getTranslation("configuration", "ascBeeptone")}
                            checked={this.state.ascBeeptone}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    ascBeeptone: newValue,
                                    updated: true
                                });

                            }}
                        />
                        <div style={{ display: "flex", marginLeft: "28px" }}>
                            <span style={{ marginRight: "5px", marginTop: "5px" }}>{context.getTranslation("configuration", "repeatEvery")}</span>
                            <TextField type={"number"}
                                min={5}
                                styles={secondsBoxStyles}
                                value={this.state.ascBeeptoneFrequency}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        ascBeeptoneFrequency: parseInt(newValue),
                                        updated: true
                                    });
                                }}
                                errorMessage={this.state.ascBeeptoneFrequency < 5 ? <span style={{ fontSize: "11px" }}>Minimum 5 seconds</span> : ""}
                                disabled={this.state.ascBeeptone ? false : true}

                            />
                            <span style={{ marginLeft: "5px", marginTop: "5px" }}>{context.getTranslation("common", "seconds")}</span>
                        </div>
                    </Stack.Item>
                    }
                    <Checkbox
                        label={context.getTranslation("configuration", "delegationFeature")}
                        checked={this.state.delegateFeature}
                        styles={checkboxStyles}
                        onChange={(event, newValue) => {
                            this.setState({
                                delegateFeature: newValue,
                                updated: true
                            });
                        }}
                    />

                    <Checkbox
                        label={context.getTranslation("configuration", "disableNextRecordingFeature")}
                        checked={this.state.recDisableNextRecording}
                        styles={checkboxStyles}
                        onChange={(event, newValue) => {
                            this.setState({
                                recDisableNextRecording: newValue,
                                updated: true
                            });
                        }}
                    />
                    {this.state.recAudioType !== "Complex" && <Stack horizontal>

                        <Checkbox
                            label={context.getTranslation("configuration", "wrapTimer")}
                            checked={this.state.keepDeleteSettingsNonComplex.hasWrapUp}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => { this.modifyState("NonComplex", newValue, "hasWrapUp") }}

                        />
                        <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                            <TextField type={"number"}
                                value={this.state.keepDeleteSettingsNonComplex.wrapUpTimer}
                                onChange={(event, newValue) => { this.modifyState("NonComplex", parseInt(newValue), "wrapUpTimer") }}
                                disabled={!this.state.keepDeleteSettingsNonComplex.hasWrapUp}
                                min="1"
                                max="900"
                                errorMessage={this.state.keepDeleteSettingsNonComplex.wrapUpTimer < 1 ? "Minimum value is 1 sec" : this.state.keepDeleteSettingsNonComplex.wrapUpTimer > 900 ? "Maximum value is 900 sec" : ""}
                            />
                        </div>
                    </Stack>
                    }
                    <br /><hr />

                    <p>{context.getTranslation("configuration", "retentionTime")}:</p>
                    <TextField
                        id="categoryRetentionTimeField"
                        value={this.state.date}
                        type="Number"
                        min={7}
                        errorMessage={(this.state.date < 7) ? "Minimum 7 days allowed" : ""}
                        onChange={(event, newValue) => {
                            this.setState({
                                date: newValue,
                                ttlConfirmed: false,
                                updated: true

                            });
                        }}
                    />
                    <Button id="categoryRetentionTimeBtn" text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "checkValidity")}</>)}</MyContext.Consumer>} onClick={(event) => { this.checkTTLValidity(this.props.apol) }} style={{ width: "150px", marginTop: "5px" }} disabled={this.state.TTLLoading} />
                    {this.state.ttlBtnMessage && <TeachingBubble
                        target={`#${"categoryRetentionTimeBtn"}`}
                        secondaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "no")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ ttlBtnMessage: false, ttlConfirmed: false }) } }}
                        primaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "yes")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ ttlBtnMessage: false, ttlConfirmed: true }) } }}
                        headline={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "warning")}</>)}</MyContext.Consumer>
                        }

                    >
                        <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "TTLErrorMessage")}</>)}</MyContext.Consumer>

                    </TeachingBubble>}

                    <p>{context.getTranslation("configuration", "description")}:</p>
                    <TextField type={"text"} multiline rows="3" cols="75" value={this.state.text} onChange={(event, newValue) => { this.setState({ text: newValue }); }} />
                </Stack>
            )
            }
        </MyContext.Consumer>

    }

    onTimeChange = date => this.setState({ date });

    renderRep = () => {
        this.tmpKey = [];
        this.tmpKey.push({ key: "Header1", text: "Categories", itemType: 2 });
        if (this.props.dataCategory != null) {
            for (let x = 0; x < this.props.dataCategory.length; x++) {
                let i = {
                    name: this.props.dataCategory[x].name,
                    text: this.props.dataCategory[x].name,
                    keywords: this.props.dataCategory[x].keywords,
                    id: this.props.dataCategory[x].id,
                    key: this.props.dataCategory[x].id,
                    tenantId: this.props.dataCategory[x].tenantId,
                    frequence: this.props.dataCategory[x].frequence,
                    link: this.props.dataCategory[x].link,
                    sentiments: this.props.dataCategory[x].sentiments,
                };
                this.tmpKey.push(i);
            }
        }
        this.tmpKeyIntegration = [];
        this.tmpKeyIntegration.push({ key: "Header1", text: "Integrations", itemType: 2 });
        // First of all put teams as standard integration
        this.tmpKeyIntegration.push({
            name: "Teams",
            text: "Teams",
            id: "1",
            key: "1"
        });
        if (this.props.dataIntegration != null) {
            for (let x = 0; x < this.props.dataIntegration.length; x++) {
                let i = {
                    name: this.props.dataIntegration[x].name,
                    text: this.props.dataIntegration[x].name,
                    id: this.props.dataIntegration[x].id,
                    key: this.props.dataIntegration[x].id
                };
                this.tmpKeyIntegration.push(i);
            }
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Stack>
                        <TextField type={"text"}
                            label={context.getTranslation("configuration", "ruleName")}
                            value={this.state.name}
                            placeholder={context.getTranslation("configuration", "ruleNamePlaceholder")}
                            onChange={(event, newValue) => {
                                this.setState({
                                    name: newValue,
                                });
                            }}
                            onBlur={this.handleBlurName}
                            errorMessage={this.state.isNameValidationError.length > 0 ? this.state.isNameValidationError : ""}
                            required
                        />
                        <Dropdown
                            label={context.getTranslation("configuration", "visibility")}
                            options={
                                [
                                    { key: 'visabilityHeader', text: context.getTranslation("configuration", "visibilityTypes"), itemType: DropdownMenuItemType.Header },
                                    { key: 'Own Conversations', text: context.getTranslation("configuration", "ownConversations"), title: 'User can see it\'s own conversations' },
                                    { key: 'No Recordings', text: context.getTranslation("configuration", "noRecordings"), title: 'User can\'t see any recordings' },
                                    { key: 'All Recordings', text: context.getTranslation("configuration", "allRecordings"), title: 'All Recordings' },
                                    { key: 'Certain Users', text: context.getTranslation("configuration", "certainUsers"), title: 'The User can see conversations of certain users' }
                                ]}
                            styles={dropdownStyles}
                            selectedKey={this.state.visibility}
                            onChange={(event, option, index, newValue) => {
                                this.setState({
                                    visibility: option.key,
                                    filterEnabled: option.key != 'visibilityHeader' || this.state.replay != '',
                                    updated: true
                                });
                            }}
                        />

                        {this.state.visibility === 'Certain Users' && <Stack vertical>
                            <Stack.Item styles={{ root: { padding: '0px' } }}>
                                <Label>{context.getTranslation("configuration", "seeConversationFrom")}</Label>
                                <UserPicker
                                    user={this.state.visibilityUser}
                                    fc={(user) => {
                                        this.setState({
                                            visibilityUser: user,
                                            updated: true
                                        });
                                    }}
                                />
                            </Stack.Item>
                        </Stack>}

                        <Dropdown label={context.getTranslation("configuration", "replay")}
                            options={
                                [

                                    { key: 'replayHeader', text: context.getTranslation("configuration", "replayTypes"), itemType: DropdownMenuItemType.Header },
                                    { key: 'Own', text: context.getTranslation("configuration", "ownConversations"), title: "Own Recordings can be replayed" },
                                    { key: 'Allowed', text: context.getTranslation("configuration", "allowed"), title: "Recordings can be replayed" },
                                    { key: 'Request', text: context.getTranslation("configuration", "request"), title: "recordings can be requested for replay" },
                                    { key: 'TimeBased', text: context.getTranslation("configuration", "timeBasedReplay"), title: "recordings can be requested for replay during a selected timeframe" },
                                    { key: 'Forbidden', text: context.getTranslation("configuration", "forbidden"), title: "Recordings can not be replayed by the User" }
                                ]}
                            styles={dropdownStyles}
                            selectedKey={this.state.replay}
                            onChange={(event, option, index, newValue) => {

                                if (option.key === "TimeBased") {
                                    this.setState({
                                        replay: option.key,
                                        replayFilterEnabled: false,
                                        updated: true
                                    });
                                } else {
                                    this.setState({
                                        replay: option.key,
                                        filterEnabled: option.key != 'replayHeader' || this.state.visibility != '',
                                        updated: true
                                    });
                                }

                            }}
                        />
                        {(this.state.replay === 'Request' || this.state.replay === 'TimeBased') &&
                            <Stack vertical >

                                <Stack.Item styles={{ root: { padding: '0px' } }}>
                                    <Label>{context.getTranslation("configuration", "requestReplayFrom")}</Label>
                                    <UserPicker
                                        user={this.state.replayUser}

                                        fc={(user) => {
                                            this.setState({
                                                replayUser: user,
                                                updated: true
                                            });
                                        }}
                                    />
                                </Stack.Item>
                            </Stack>}
                        {this.state.replay === 'TimeBased' &&
                            <Stack.Item>
                                <ComboBox
                                    selectedKey={this.state.replayCycle}
                                    label={context.getTranslation("dashboard", "cycle")}
                                    onChange={(event, option, index, newValue) => {
                                        this.setState({
                                            replayCycle: option.key,
                                            updated: true
                                        });
                                    }}
                                    options={[
                                        { key: 'Today', text: context.getTranslation("dashboard", "Today") },
                                        { key: 'Yesterday', text: context.getTranslation("dashboard", "Yesterday") },
                                        { key: 'Last24h', text: context.getTranslation("dashboard", "Last24h") },
                                        { key: 'Last48h', text: context.getTranslation("dashboard", "Last48h") },
                                        { key: 'Last72h', text: context.getTranslation("dashboard", "Last72h") },
                                        { key: 'Last96h', text: context.getTranslation("dashboard", "Last96h") },
                                        { key: 'ThisWeek', text: context.getTranslation("dashboard", "ThisWeek") },
                                        { key: 'Last7d', text: context.getTranslation("dashboard", "Last7d") },
                                        { key: 'LastWeek', text: context.getTranslation("dashboard", "LastWeek") },
                                        { key: 'Last14d', text: context.getTranslation("dashboard", "Last14d") },
                                        { key: 'ThisMonth', text: context.getTranslation("dashboard", "ThisMonth") },
                                        { key: 'Last30d', text: context.getTranslation("dashboard", "Last30d") },
                                        { key: 'LastMonth', text: context.getTranslation("dashboard", "LastMonth") },
                                        { key: 'ThisQuarter', text: context.getTranslation("dashboard", "ThisQuarter") },
                                        { key: 'LastQuarter', text: context.getTranslation("dashboard", "LastQuarter") },
                                        { key: 'ThisYear', text: context.getTranslation("dashboard", "ThisYear") },
                                        { key: 'LastYear', text: context.getTranslation("dashboard", "LastYear") },
                                        { key: 'Current', text: 'Current' },
                                    ]}
                                />
                            </Stack.Item>
                        }

                        <Stack vertical>
                            <Checkbox
                                label={context.getTranslation("configuration", "replayVisibilityDetails")}
                                checked={this.state.replayVisibilityDetails}
                                styles={checkboxStyles}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        replayVisibilityDetails: newValue,
                                        updated: true
                                    });
                                }}
                            />
                            {this.state.replayVisibilityDetails && <Stack vertical>
                                <Checkbox
                                    label={context.getTranslation("configuration", "replayVisibilityDetailsTranscript")}
                                    checked={this.state.replayVisibilityDetailsTranscript}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            replayVisibilityDetailsTranscript: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={context.getTranslation("configuration", "replayVisibilityDetailsAnalyticsChange")}
                                    checked={this.state.replayVisibilityDetailsAnalyticsChange}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            replayVisibilityDetailsAnalyticsChange: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={context.getTranslation("configuration", "replayVisibilityDetailsAnalytics")}
                                    checked={this.state.replayVisibilityDetailsAnalytics}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            replayVisibilityDetailsAnalytics: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={context.getTranslation("configuration", "replayVisibilityDetailsNotes")}
                                    checked={this.state.replayVisibilityDetailsNotes}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            replayVisibilityDetailsNotes: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={context.getTranslation("configuration", "replayVisibilityDetailsTTL")}
                                    checked={this.state.replayVisibilityDetailsTTL}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            replayVisibilityDetailsTTL: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={context.getTranslation("configuration", "replayVisibilityDetailsCustomFields")}
                                    checked={this.state.replayVisibilityDetailsCustomFields}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            replayVisibilityDetailsCustomFields: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={context.getTranslation("configuration", "replayVisibilityDetailsMetadata")}
                                    checked={this.state.replayVisibilityDetailsMetadata}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            replayVisibilityDetailsMetadata: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                {this.props.userRules.addOnQualityManagement && (<Checkbox
                                    label={context.getTranslation("compliance", "AIQM")}
                                    checked={this.state.visibilityDetailsQM}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            visibilityDetailsQM: newValue,
                                            updated: true
                                        });
                                    }}
                                />)}
                            </Stack>}
                        </Stack>

                        {this.state.filterEnabled && (
                            <div>
                                <Text variant="large" block>{context.getTranslation("configuration", "filter")}</Text>
                                <Checkbox
                                    label={context.getTranslation("filterPanel", "filterEnabled")}
                                    disabled={(this.state.replay === "TimeBased" ? true : false)}
                                    checked={this.state.replayFilterEnabled}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            replayFilterEnabled: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                            </div>
                        )}


                        {this.state.replayFilterEnabled &&

                            <Stack vertical >
                                <Stack.Item>
                                    <Dropdown
                                        selectedKey={this.state.timeBasedType}
                                        onChange={(event, option, index, newValue) => {
                                            this.setState({
                                                timeBasedType: option.key,
                                                updated: true
                                            })
                                        }}
                                        label={context.getTranslation("filterPanel", "filterType")}
                                        options={[
                                            { key: 'filterTypes', text: context.getTranslation("filterPanel", "filterType"), itemType: DropdownMenuItemType.Header },
                                            { key: '', text: context.getTranslation("filterPanel", "defaultFilter") },
                                            { key: 'VisibilityFilter', text: context.getTranslation("filterPanel", "visibilityFilter") },
                                            { key: 'ReplayFilter', text: context.getTranslation("filterPanel", "replayFilter") },
                                        ]}
                                        styles={dropdownStyles}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Dropdown
                                        multiSelect
                                        selectedKeys={this.state.replayFilterType}
                                        options={
                                            [
                                                { key: 'Header1', text: 'Type', itemType: SelectableOptionMenuItemType.Header },
                                                { key: 'Inbound', text: context.getTranslation("filterPanel", "inbound") },
                                                { key: 'Outbound', text: context.getTranslation("filterPanel", "outbound") },
                                                { key: 'Meeting', text: context.getTranslation("filterPanel", "meetings") },
                                                { key: 'Internal', text: context.getTranslation("filterPanel", "internal") },
                                                { key: 'External', text: context.getTranslation("filterPanel", "external") },
                                                { key: 'Chat', text: context.getTranslation("configuration", "chat") },
                                                { key: 'Other', text: context.getTranslation("filterPanel", "upload") },
                                                { key: 'SMS', text: context.getTranslation("filterPanel", "sms") }
                                            ]}
                                        onChange={(event, item) => {
                                            if (item) {
                                                let replayFilterType = item.selected ? [...this.state.replayFilterType, item.key] : this.state.replayFilterType.filter(key => key !== item.key)
                                                this.setState({
                                                    replayFilterType,
                                                    updated: true
                                                });
                                            }
                                        }}
                                        label={context.getTranslation("filterPanel", "type")}
                                    />
                                </Stack.Item>

                                <Stack.Item>
                                    <ComboBox
                                        selectedKey={this.state.replayFilterCycle}
                                        label={context.getTranslation("dashboard", "cycle")}
                                        onChange={(event, option, index, newValue) => {
                                            this.setState({
                                                replayFilterCycle: option.key,
                                                replayFilterStartDate: '',
                                                replayFilterEndDate: '',
                                                updated: true
                                            });
                                        }}
                                        options={[
                                            { key: 'Today', text: context.getTranslation("dashboard", "Today") },
                                            { key: 'Yesterday', text: context.getTranslation("dashboard", "Yesterday") },
                                            { key: 'Last24h', text: context.getTranslation("dashboard", "Last24h") },
                                            { key: 'Last48h', text: context.getTranslation("dashboard", "Last48h") },
                                            { key: 'Last72h', text: context.getTranslation("dashboard", "Last72h") },
                                            { key: 'Last96h', text: context.getTranslation("dashboard", "Last96h") },
                                            { key: 'ThisWeek', text: context.getTranslation("dashboard", "ThisWeek") },
                                            { key: 'Last7d', text: context.getTranslation("dashboard", "Last7d") },
                                            { key: 'LastWeek', text: context.getTranslation("dashboard", "LastWeek") },
                                            { key: 'Last14d', text: context.getTranslation("dashboard", "Last14d") },
                                            { key: 'ThisMonth', text: context.getTranslation("dashboard", "ThisMonth") },
                                            { key: 'Last30d', text: context.getTranslation("dashboard", "Last30d") },
                                            { key: 'LastMonth', text: context.getTranslation("dashboard", "LastMonth") },
                                            { key: 'ThisQuarter', text: context.getTranslation("dashboard", "ThisQuarter") },
                                            { key: 'LastQuarter', text: context.getTranslation("dashboard", "LastQuarter") },
                                            { key: 'ThisYear', text: context.getTranslation("dashboard", "ThisYear") },
                                            { key: 'LastYear', text: context.getTranslation("dashboard", "LastYear") },
                                            { key: 'Current', text: 'Current' },
                                        ]}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <div>{context.getTranslation("dashboard", "startDate")}</div>
                                    <DateTimePicker
                                        locale={this.props.authorization.locale}
                                        calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}

                                        onChange={(date) => {
                                            if (date <= this.state.replayFilterStartDate || this.state.replayFilterEndDate === '') {
                                                this.setState({
                                                    replayFilterStartDate: date,
                                                    replayFilterCycle: '',
                                                    updated: true
                                                });
                                            }
                                        }}
                                        value={this.state.replayFilterStartDate}
                                    />
                                    <div>{context.getTranslation("dashboard", "endDate")}</div>
                                    <DateTimePicker
                                        locale={this.props.authorization.locale}
                                        calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}

                                        onChange={(date) => {
                                            if (date >= this.state.replayFilterStartDate || this.state.replayFilterEndDate === '') {
                                                this.setState({
                                                    replayFilterEndDate: date,
                                                    replayFilterCycle: '',
                                                    updated: true
                                                });
                                            }
                                        }}
                                        value={this.state.replayFilterEndDate}
                                    />

                                    <Stack.Item >
                                        <br />
                                        <Label>{context.getTranslation("filterPanel", "recordingDuration")}</Label>
                                        <div>
                                            <div style={{ display: "inline-block", width: "35%", marginRight: "18px" }}>
                                                <TextField type={"number"}
                                                    label={context.getTranslation("settings", "ticketFrom")}
                                                    value={this.state.replayDurationFrom === -1 ? '' : this.state.replayDurationFrom}
                                                    onChange={(event, newValue) => {
                                                        if (isNaN(newValue) || newValue === "") {
                                                            this.setState({
                                                                replayDurationFrom: parseInt(-1),
                                                                updated: true
                                                            });
                                                        } else {
                                                            this.setState({
                                                                replayDurationFrom: parseInt(newValue),
                                                                updated: true
                                                            });
                                                        }
                                                    }}
                                                    min={0}
                                                />
                                            </div>
                                            <div style={{ display: "inline-block", width: "35%" }}>
                                                <TextField type={"number"}
                                                    label={context.getTranslation("settings", "ticketTo")}
                                                    value={this.state.replayDurationTo === -1 ? '' : this.state.replayDurationTo}
                                                    onChange={(event, newValue) => {
                                                        if (isNaN(newValue) || newValue === "") {
                                                            this.setState({
                                                                replayDurationTo: parseInt(-1),
                                                                updated: true
                                                            });
                                                        } else {
                                                            this.setState({
                                                                replayDurationTo: parseInt(newValue),
                                                                updated: true
                                                            });
                                                        }
                                                    }}
                                                    min={0}

                                                />
                                            </div>
                                        </div>

                                    </Stack.Item>
                                    <br />
                                    <Label>{context.getTranslation("filterPanel", "selectedUser")}</Label>

                                    <Stack.Item>
                                        <UserPicker
                                            placeholder={context.getTranslation("filterPanel", "selectedUser")}
                                            user={this.state.replayFilterPersons}
                                            fc={(user) => {
                                                if (user.length > 0) {
                                                    this.setState({
                                                        replayFilterPersons: user,
                                                        updated: true
                                                    });
                                                } else {
                                                    this.setState({
                                                        replayFilterPersons: user,
                                                        replayRecordingOwner: false,
                                                        updated: true
                                                    });
                                                }

                                            }}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Checkbox
                                            label={context.getTranslation("filterPanel", "recordingOwner")}
                                            checked={this.state.replayRecordingOwner}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {

                                                this.setState({
                                                    replayRecordingOwner: newValue,
                                                    updated: true
                                                });
                                            }}
                                            disabled={this.state.replayFilterPersons.length > 0 ? false : true}
                                        />
                                    </Stack.Item>
                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <Stack vertical>
                                        <Stack.Item>
                                            <ComboBox
                                                style={{ width: "300px" }}
                                                label={context.getTranslation("filterPanel", "integrationsSelection")}
                                                selectedKey={this.state.selectedOptionKeysIntegration}
                                                allowFreeform={false}
                                                onChange={this.onChangeMultiIntegration}
                                                autoComplete={true}
                                                options={this.tmpKeyIntegration}
                                                multiSelect
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <Stack vertical>
                                        <Stack.Item>
                                            <ComboBox
                                                style={{ width: "300px" }}
                                                label={context.getTranslation("filterPanel", "categorySelection")}
                                                selectedKey={this.state.selectedOptionKeys}
                                                allowFreeform={false}
                                                onChange={this.onChangeMulti}
                                                autoComplete={true}
                                                options={this.tmpKey}
                                                multiSelect
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>}

                        <p>{context.getTranslation("configuration", "description")}:</p>
                        <TextField type={"text"} multiline rows="3" cols="75" value={this.state.text} onChange={(event, newValue) => {
                            this.setState({
                                text: newValue,
                                updated: true
                            });
                        }} />
                    </Stack>
                )
                }
            </MyContext.Consumer>
        );
    }

    renderAcc = () => { // NOTE: Add Access & Right Rules Form


        return (
            <MyContext.Consumer>
                {(context) => (
                    <Stack>


                        <TextField type={"text"}
                            label={context.getTranslation("configuration", "ruleName")}
                            value={this.state.name}
                            placeholder={context.getTranslation("configuration", "ruleNamePlaceholder")}
                            onChange={(event, newValue) => {
                                this.setState({
                                    name: newValue,
                                });
                            }}
                            onBlur={this.handleBlurName}
                            errorMessage={this.state.isNameValidationError.length > 0 ? this.state.isNameValidationError : ""}
                            required
                        />

                        <TextField
                            label={context.getTranslation("configuration", "description")}
                            type={"text"}
                            multiline
                            rows="3"
                            cols="75"
                            value={this.state.text}
                            onChange={(event, newValue) => {
                                this.setState({
                                    text: newValue,
                                    updated: true
                                });
                            }}
                        />

                        <Pivot>
                            <PivotItem
                                headerText={context.getTranslation("configuration", "adminRights")}
                            >



                                <Text variant="large" block>{context.getTranslation("configuration", "licenseManagement")}</Text>
                                <Checkbox
                                    label={context.getTranslation("configuration", "listUser")}
                                    checked={this.state.accListUser}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accListUser: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={context.getTranslation("configuration", "addUser")}
                                    checked={this.state.accAddUser}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accAddUser: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                                <Checkbox
                                    label={context.getTranslation("configuration", "ticket")}
                                    checked={this.state.accTicket}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accTicket: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                                <Text variant="large" block>{context.getTranslation("configuration", "createRules")}</Text>

                                <Stack>
                                    {/* TODO: Change label copy */}
                                    <Checkbox
                                        label={context.getTranslation("configuration", "recording")}
                                        styles={checkboxStyles}
                                        checked={this.state.accRulesRecording}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accRulesRecording: newValue,
                                                updated: true
                                            });
                                        }}
                                    />

                                    <Checkbox
                                        label={context.getTranslation("configuration", "replay")}
                                        styles={checkboxStyles}
                                        checked={this.state.accRulesReplay}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accRulesReplay: newValue,
                                                updated: true
                                            });
                                        }}
                                    />

                                    <Checkbox
                                        label={context.getTranslation("configuration", "accessRight")}
                                        styles={checkboxStyles}
                                        checked={this.state.accRulesAccess}

                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accRulesAccess: newValue,
                                                updated: true
                                            });
                                        }}
                                    />

                                    <Checkbox
                                        label={context.getTranslation("configuration", "analytics")}
                                        styles={checkboxStyles}
                                        checked={this.state.accRulesAnalytics}

                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accRulesAnalytics: newValue,
                                                updated: true
                                            });
                                        }}
                                    />

                                </Stack>

                                <Checkbox
                                    label={context.getTranslation("configuration", "accessAuditLogs")}
                                    styles={checkboxStyles}
                                    checked={this.state.accAuditLogs}

                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accAuditLogs: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                                <Checkbox
                                    label={context.getTranslation("configuration", "accessJobs")}
                                    styles={checkboxStyles}
                                    checked={this.state.accJobs}

                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accJobs: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                                {/*custom fields*/}
                                <Checkbox
                                    label={context.getTranslation("configuration", "customFields")}
                                    styles={checkboxStyles}
                                    checked={this.state.customFields}

                                    onChange={(event, newValue) => {
                                        if (!newValue) {
                                            this.setState({
                                                customFields: newValue,
                                                administrateCustomFields: false,
                                                defActionCustomFields: false,
                                                cfDisableTagging: false,
                                                updated: true
                                            });
                                        } else {
                                            this.setState({
                                                customFields: newValue,
                                                updated: true
                                            });
                                        }

                                    }}
                                />
                                {this.state.customFields && <div style={{ marginLeft: "30px" }}>
                                    <Checkbox
                                        label={context.getTranslation("configuration", "administrateCustomFields")}
                                        styles={checkboxStyles}
                                        checked={this.state.administrateCustomFields}

                                        onChange={(event, newValue) => {
                                            if (!newValue) {
                                                this.setState({
                                                    administrateCustomFields: newValue,
                                                    defActionCustomFields: false,
                                                    updated: true
                                                });
                                            } else {
                                                this.setState({
                                                    administrateCustomFields: newValue,
                                                    updated: true
                                                });
                                            }


                                        }}
                                    />
                                    {this.state.administrateCustomFields && <div style={{ marginLeft: "30px" }}>
                                        <Checkbox
                                            label={context.getTranslation("configuration", "defActionCustomFields")}
                                            styles={checkboxStyles}
                                            checked={this.state.defActionCustomFields}

                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    defActionCustomFields: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                    </div>}

                                </div>

                                }

                                {/*allow suspension of all conversation deletion process*/}
                                <Checkbox
                                    label={context.getTranslation("configuration", "allowSuspentionConvDeleteProcess")}
                                    styles={checkboxStyles}
                                    checked={this.state.suspensionConvDelProcess}

                                    onChange={(event, newValue) => {
                                        this.setState({
                                            suspensionConvDelProcess: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                                <Text variant="large" block>{context.getTranslation("configuration", "userMapping")}</Text>
                                <Checkbox
                                    label={context.getTranslation("configuration", "mapExternalUser")}
                                    styles={checkboxStyles}
                                    checked={this.state.accMapUser}

                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accMapUser: newValue,
                                            updated: true
                                        });
                                    }}
                                />


                                <Text variant="large" block>{context.getTranslation("configuration", "userAssignment")}</Text>
                                <ChoiceGroup
                                    selectedKey={this.state.accAssignment}
                                    style={{ width: "200px" }}

                                    options={[
                                        {
                                            key: 'No',
                                            text: context.getTranslation("configuration", "noAssignment"),
                                            'data-automation-id': 'auto1',
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Certain',
                                            text: context.getTranslation("configuration", "certainUsers"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Hierarchy',
                                            text: context.getTranslation("configuration", "hierarchy"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'All',
                                            text: context.getTranslation("configuration", "all"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        }
                                    ]}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accAssignment: newValue.key,
                                            updated: true
                                        });
                                    }}
                                />

                                {this.state.accAssignment === 'Certain' && <Stack vertical>
                                    <Stack.Item styles={{ root: { padding: '0px' } }}>
                                        <Label>{context.getTranslation("configuration", "assignUsers")}</Label>
                                        <UserPicker
                                            user={this.state.userAssignmentUsers}

                                            fc={(user) => {
                                                this.setState({
                                                    userAssignmentUsers: user,
                                                    updated: true
                                                });
                                            }}
                                        />
                                    </Stack.Item>
                                </Stack>}

                                {(this.state.accAssignment !== "No") && <Stack>
                                    <Checkbox
                                        label={context.getTranslation("configuration", "recordingRules")}
                                        styles={checkboxStyles}
                                        checked={this.state.accAssignRecording}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accAssignRecording: newValue,
                                                updated: true
                                            });
                                        }}
                                    />

                                    <Checkbox
                                        label={context.getTranslation("configuration", "replayRules")}
                                        styles={checkboxStyles}
                                        checked={this.state.accAssignReplay}

                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accAssignReplay: newValue,
                                                updated: true
                                            });
                                        }}
                                    />

                                    <Checkbox
                                        label={context.getTranslation("configuration", "accessAndRightRules")}
                                        checked={this.state.accAssignAccess}

                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accAssignAccess: newValue,
                                                updated: true
                                            });
                                        }}
                                    />
                                    <Checkbox
                                        label={context.getTranslation("configuration", "analytics")}
                                        styles={checkboxStyles}
                                        checked={this.state.accAssignAnalytics}

                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accAssignAnalytics: newValue,
                                                updated: true
                                            });
                                        }}
                                    />


                                </Stack>}


                            </PivotItem>

                            <PivotItem headerText={context.getTranslation("configuration", "userRights")}>

                                <Text variant="large" block>{context.getTranslation("configuration", "variousUserRights")}</Text>


                                <Checkbox
                                    label={context.getTranslation("configuration", "showLiveView")}
                                    checked={this.state.accLiveView}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accLiveView: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                                {/*CUSTOM FIELDS*/}
                                <Checkbox
                                    label="Access Fields"
                                    checked={this.state.hasCustomFieldsAccess}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            hasCustomFieldsAccess: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                                {this.state.hasCustomFieldsAccess && <Stack horizontal style={{ marginLeft: "30px" }}>

                                    <ChoiceGroup
                                        selectedKey={this.state.customFieldsAccessType}
                                        style={{ width: "200px" }}
                                        options={[
                                            {
                                                key: 'ReadOnly',
                                                text: "Read Only",
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {

                                                key: 'FullAccess',
                                                text: "Full Access",
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },

                                        ]}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                customFieldsAccessType: newValue.key,
                                                updated: true
                                            });
                                        }}
                                    />

                                    <Query
                                        query={getAllCustomFieldDefinitions}
                                        variables={this.props.authorization}>

                                        {({ loading, error, data }) => {
                                            if (loading) return <>
                                                <Spinner label={context.getTranslation("common", "loadingData")} ariaLive="assertive" labelPosition="top" />
                                            </>;
                                            if (error) return <h1> </h1>;

                                            if (data.getAllCustomFieldDefinitions == null) {
                                                return <div> </div>;

                                            }

                                            var customFieldsOptions = [];
                                            var customFieldsOptionsNoCount = []
                                            customFieldsOptions.push({ key: "Header1", text: "Custom Fields", itemType: SelectableOptionMenuItemType.Header });

                                            for (var i = 0; i < data.getAllCustomFieldDefinitions.length; i++) {
                                                let count = this.getCount({ key: data.getAllCustomFieldDefinitions[i].id, text: data.getAllCustomFieldDefinitions[i].fieldName })
                                                customFieldsOptions.push({
                                                    key: data.getAllCustomFieldDefinitions[i].id, text: count + " " + data.getAllCustomFieldDefinitions[i].fieldName
                                                })
                                                customFieldsOptionsNoCount.push({
                                                    key: data.getAllCustomFieldDefinitions[i].id, text: data.getAllCustomFieldDefinitions[i].fieldName
                                                })
                                            }

                                            let selectedKey = this.keyConverter(customFieldsOptionsNoCount, this.state.accesibleCustomFields)

                                            return <span style={{ marginTop: "18px" }}>
                                                <ComboBox
                                                    id="selectFields"
                                                    style={{ width: "300px" }}
                                                    selectedKey={selectedKey}
                                                    allowFreeform={false}
                                                    onChange={this.onChangeMultiFields}
                                                    autoComplete={true}
                                                    options={customFieldsOptions}
                                                    multiSelect
                                                    placeholder="Select custom fields"
                                                />
                                            </span>
                                        }
                                        }
                                    </Query>

                                </Stack>

                                }


                                {(this.state.customFieldsAccessType == "FullAccess" && this.state.hasCustomFieldsAccess) && <Stack horizontal style={{ marginLeft: "55px" }}>
                                    <Checkbox
                                        label={context.getTranslation("configuration", "editableFor")}
                                        checked={this.state.editableTimeCustomFields}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                editableTimeCustomFields: newValue,
                                                updated: true
                                            });
                                        }}
                                        style={{ marginRight: "10px" }}
                                    />
                                    <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                                        <TextField
                                            value={this.state.customFieldsEditableTime}
                                            type="Number"
                                            style={{ width: "60px" }}
                                            onChange={(event, newValue) => {
                                                if (newValue <= 0 && this.state.editableTimeCustomFields) {

                                                    document.getElementById("editTimeError").innerHTML = "Value must be greater than 0";
                                                    document.getElementById("editTimeError").style.color = "red";
                                                    this.setState({
                                                        customFieldsEditableTime: parseInt(newValue),
                                                        updated: false
                                                    });
                                                } else {
                                                    this.setState({
                                                        customFieldsEditableTime: parseInt(newValue),
                                                        updated: true
                                                    });
                                                    document.getElementById("editTimeError").innerHTML = "";

                                                }

                                            }}
                                            min={1}
                                            disabled={!this.state.editableTimeCustomFields}
                                        />
                                    </span>
                                    <span style={{ marginLeft: "10px", marginTop: "10px" }}>days</span>
                                </Stack>}
                                <p id="editTimeError" style={{ marginLeft: "84px" }}></p>



                                {this.state.hasCustomFieldsAccess && <div style={{ marginLeft: "30px" }}>
                                    <Checkbox
                                        label={context.getTranslation("configuration", "disableTaggingField")}
                                        styles={checkboxStyles}
                                        checked={this.state.cfDisableTagging}

                                        onChange={(event, newValue) => {
                                            this.setState({
                                                cfDisableTagging: newValue,
                                                updated: true
                                            });
                                        }}
                                    />
                                </div>
                                }
                                <Checkbox
                                    label={context.getTranslation("configuration", "userDenyExport")}
                                    checked={this.state.accUserDenyExport}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accUserDenyExport: newValue,
                                            updated: true
                                        });
                                    }}
                                />


                                <Checkbox
                                    label={context.getTranslation("configuration", "userDenyUpload")}
                                    checked={this.state.accUserDenyUpload}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accUserDenyUpload: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                                <Checkbox
                                    label={context.getTranslation("configuration", "denyReportAlarming")}
                                    checked={this.state.denyReportAlarming}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            denyReportAlarming: newValue,
                                            updated: true
                                        });
                                    }}
                                />

                                <Checkbox
                                    label={context.getTranslation("configuration", "allowTTLChangeViaCategorization")}
                                    checked={this.state.allowTTLChangeViaCategorization}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            allowTTLChangeViaCategorization: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                <hr />


                                <Text variant="large" block>{context.getTranslation("configuration", "reporting")}</Text>

                                <ChoiceGroup
                                    selectedKey={this.state.accReporting}
                                    style={{ width: "200px" }}

                                    options={[
                                        {
                                            key: 'No',
                                            text: context.getTranslation("configuration", "notAllowed"),
                                            'data-automation-id': 'auto1',
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Own',
                                            text: context.getTranslation("configuration", "onOwnData"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'All',
                                            text: context.getTranslation("configuration", "all"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        }
                                    ]}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accReporting: newValue.key,
                                            updated: true
                                        });
                                    }}
                                    label={context.getTranslation("configuration", "createReports")}
                                />

                                {this.state.accReporting !== "No" && <Stack>

                                    <Checkbox
                                        label="Wallboard"
                                        checked={this.state.accKpiWallboard}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accKpiWallboard: newValue,
                                                updated: true
                                            });
                                        }}
                                    />

                                </Stack>}
                                {this.props.userRules.addOnCompliance && (<div>
                                    <hr />
                                    <Text variant="large" block>{context.getTranslation("configuration", "compliancePolicy")}</Text>
                                    <Checkbox
                                        label={context.getTranslation("configuration", "complianceResultsAccess")}
                                        checked={this.state.complianceResultsAccess}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                complianceResultsAccess: newValue,
                                                updated: true
                                            });
                                        }}
                                    />
                                    {this.props.userRules.addOnQualityManagement && (<Checkbox
                                        label={context.getTranslation("configuration", "complianceQMResultsAccess")}
                                        checked={this.state.complianceQMResultsAccess}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                complianceQMResultsAccess: newValue,
                                                updated: true
                                            });
                                        }}
                                    />)}

                                    <Checkbox
                                        label={context.getTranslation("configuration", "userAccessCompliancePolicy")}
                                        checked={this.state.accUserCompliancePolicy}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accUserCompliancePolicy: newValue,
                                                updated: true
                                            });
                                        }}
                                    />
                                    <Stack style={{ paddingLeft: 15 }}>
                                        <Checkbox
                                            label={context.getTranslation("configuration", "userAccessCompliancePolicyActivate")}
                                            disabled={!this.state.accUserCompliancePolicy}
                                            checked={this.state.accUserCompliancePolicyActivate && this.state.accUserCompliancePolicy}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserCompliancePolicyActivate: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        <Checkbox
                                            label={context.getTranslation("configuration", "userAccessCompliancePolicyInitiateWorkflow")}
                                            disabled={!this.state.accUserCompliancePolicy}
                                            checked={this.state.accUserCompliancePolicyInitiateWorkflow && this.state.accUserCompliancePolicy}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserCompliancePolicyInitiateWorkflow: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        <Checkbox
                                            label={context.getTranslation("configuration", "userAccessCompliancePolicyEdit")}
                                            disabled={!this.state.accUserCompliancePolicy}
                                            checked={this.state.accUserCompliancePolicyEdit && this.state.accUserCompliancePolicy}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserCompliancePolicyEdit: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        <Checkbox
                                            label={context.getTranslation("configuration", "userAccessCompliancePolicyRuleEdit")}
                                            disabled={!this.state.accUserCompliancePolicy}
                                            checked={this.state.accUserCompliancePolicyAssign && this.state.accUserCompliancePolicy}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserCompliancePolicyAssign: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        <Checkbox
                                            label={context.getTranslation("configuration", "userComplianceAIQuestions")}
                                            disabled={!this.state.accUserCompliancePolicy}
                                            checked={this.state.accUserComplianceAIQuestions && this.state.accUserCompliancePolicy}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserComplianceAIQuestions: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        {this.props.userRules.addOnQualityManagement && (<Checkbox
                                            label={context.getTranslation("configuration", "userComplianceQM")}
                                            disabled={!this.state.accUserCompliancePolicy}
                                            checked={this.state.userComplianceQM && this.state.accUserCompliancePolicy}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    userComplianceQM: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />)}
                                    </Stack>


                                    {/* ConversationSafeAccessRule, to configure conversation safe access */}
                                </div>)}

                                <hr />
                                <ConversationSafeAccessRule
                                    conversationSafeAccess={this.state.conversationSafeAccess}
                                    updateConversationSafeAccess={(conversationSafeAccess) => {
                                        let accUserBulkSafe = (this.state.conversationSafeAccess === null || this.state.conversationSafeAccess.accUserSafe === "No") ? false : this.state.accUserBulkSafe
                                        this.setState({
                                            conversationSafeAccess,
                                            accUserBulkSafe,
                                            userBulkSafeAdd: accUserBulkSafe,
                                            userBulkSafeRemove: accUserBulkSafe,
                                            updated: true
                                        })
                                    }} />
                                {/*Export config*/}
                                <hr />
                                <Text variant="large" block>{context.getTranslation("configuration", "bulkExport")}</Text>
                                <Checkbox
                                    label={context.getTranslation("configuration", "mergeAudioToVideo")}
                                    checked={this.state.accMergeAudioToVideo}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accMergeAudioToVideo: newValue,
                                            updated: true
                                        });
                                    }}
                                />
                                {/* bulk operations */}
                                <hr />
                                <Stack>

                                    <Text variant="large" block>{context.getTranslation("configuration", "bulkOperation")}</Text>
                                    <Stack.Item>
                                        <Checkbox
                                            label={context.getTranslation("configuration", "bulkExcel")}
                                            checked={this.state.accUserBulkExcel}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserBulkExcel: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        <Checkbox
                                            label={context.getTranslation("configuration", "bulkExport")}
                                            checked={this.state.accUserBulkExport}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserBulkExport: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        {this.state.accUserBulkExport && <Stack>
                                            <p>{context.getTranslation("configuration", "userBulkIpRange")}</p>
                                            <p><TextField
                                                value={this.state.accUserBulkIpRange}
                                                errorMessage={this.state.bulkIpRangeError}
                                                onChange={(event, newValue) => {
                                                    if (newValue == ""
                                                        || /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/.test(newValue)
                                                        || /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b-\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/.test(newValue)
                                                    ) {
                                                        this.setState({
                                                            accUserBulkIpRange: newValue,
                                                            bulkIpRangeError: "",
                                                            updated: true
                                                        });
                                                    }
                                                    else {
                                                        this.setState({
                                                            accUserBulkIpRange: newValue,
                                                            bulkIpRangeError: "Ip or Ip Range",
                                                            updated: false
                                                        });
                                                    }
                                                }}
                                            /></p>
                                            <p>{context.getTranslation("configuration", "userBulkPasswordLength")}</p>
                                            <p><TextField type="Number" min={8}
                                                value={this.state.accUserBulkPasswordLength}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        accUserBulkPasswordLength: newValue,
                                                        updated: true
                                                    });
                                                }}
                                            /></p>
                                        </Stack>}
                                        <Checkbox
                                            label={context.getTranslation("configuration", "bulkSafe")}
                                            checked={this.state.accUserBulkSafe}
                                            styles={checkboxStyles}
                                            disabled={this.state.conversationSafeAccess === null || this.state.conversationSafeAccess.accUserSafe === "No"}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserBulkSafe: newValue,
                                                    userBulkSafeAdd: newValue,
                                                    userBulkSafeRemove: !newValue ? newValue : this.state.userBulkSafeRemove,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        <div style={{ marginLeft: "20px" }}>
                                            <Checkbox
                                                label={context.getTranslation("configuration", "bulkSafeAdd")}
                                                checked={this.state.userBulkSafeAdd}
                                                styles={checkboxStyles}
                                                disabled={true}
                                            /*onChange={(event, newValue) => {
                                                this.setState({
                                                    userBulkSafeAdd: newValue,
                                                    updated: true
                                                });
                                            }}*/
                                            />
                                            <Checkbox
                                                label={context.getTranslation("configuration", "bulkSafeRemove")}
                                                checked={this.state.userBulkSafeRemove}
                                                styles={checkboxStyles}
                                                disabled={this.state.conversationSafeAccess === null || this.state.conversationSafeAccess.accUserSafe === "No"}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        userBulkSafeRemove: newValue,
                                                        updated: true,
                                                        hideDialogSafe: !newValue
                                                    });
                                                }}
                                            />
                                        </div>
                                        {/*safety dialog to alert remove from conversation safe*/}
                                        <SafetyDialog
                                            title={context.getTranslation("configuration", "removeToFolders")}
                                            subText={context.getTranslation("configuration", "removeToFoldersText")}
                                            hideDialog={this.state.hideDialogSafe}
                                            closeDialog={() => { this.setState({ hideDialogSafe: true, userBulkSafeRemove: false }) }}
                                            ok={() => { this.setState({ hideDialogSafe: true }) }} />

                                        <Checkbox
                                            label={context.getTranslation("configuration", "bulkDelete")}
                                            checked={this.state.accUserBulkDelete}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserBulkDelete: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        <Checkbox
                                            label={context.getTranslation("configuration", "bulkTranscript")}
                                            checked={this.state.accUserBulkTranscript}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserBulkTranscript: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                        <Checkbox
                                            label={context.getTranslation("configuration", "bulkRestoreArchive")}
                                            checked={this.state.accUserBulkRestoreArchive}
                                            styles={checkboxStyles}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserBulkRestoreArchive: newValue,
                                                    updated: true
                                                });
                                            }}
                                        />
                                    </Stack.Item>
                                </Stack>



                                {/*archive*/}

                                <hr />

                                <Stack>
                                    <Text variant="large" block>{context.getTranslation("configuration", "archivedConversations")}</Text>
                                    <Stack.Item>
                                        <ChoiceGroup
                                            selectedKey={this.state.accUserArchievedConversationType}
                                            style={{ width: "200px" }}

                                            options={[
                                                {
                                                    key: 'NoAccess',
                                                    text: context.getTranslation("configuration", "noAccessToArchievedFiles"),
                                                    'data-automation-id': 'auto1',
                                                    styles: { field: { fontWeight: FontWeights.regular } }
                                                },
                                                {
                                                    key: 'AllowedToRetrieve',
                                                    text: context.getTranslation("configuration", "allowedToRetrieve"),
                                                    styles: { field: { fontWeight: FontWeights.regular } }
                                                },
                                                //{
                                                //    key: 'ProposedConversationsOnly',
                                                //    text: context.getTranslation("configuration", "proposedConversationsOnly"),
                                                //    styles: { field: { fontWeight: FontWeights.regular } }
                                                //},
                                                {
                                                    key: 'RequestRetrieval',
                                                    text: context.getTranslation("configuration", "requestRetrievalProcess"),
                                                    styles: { field: { fontWeight: FontWeights.regular } }
                                                }
                                            ]}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    accUserArchievedConversationType: newValue.key,
                                                    updated: true
                                                });
                                            }}
                                            label={context.getTranslation("configuration", "accessArchivedConversations")}
                                        />
                                    </Stack.Item>
                                    {this.state.accUserArchievedConversationType == "RequestRetrieval" && <>
                                        <Stack.Item>
                                            <Label>{context.getTranslation("configuration", "requestReplayFrom")}</Label>
                                            <UserPicker
                                                user={this.state.approvedUsersReplay}

                                                fc={(user) => {
                                                    this.setState({
                                                        approvedUsersReplay: user,
                                                        updated: true
                                                    });
                                                }}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Checkbox
                                                label={context.getTranslation("configuration", "reasoningTextRequired")}
                                                checked={this.state.accUserRequestArchiveRetrievalProcessReasoning}
                                                styles={checkboxStyles}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        accUserRequestArchiveRetrievalProcessReasoning: newValue,
                                                        updated: true
                                                    });
                                                }}
                                            />
                                        </Stack.Item>
                                    </>}

                                </Stack>
                                <hr />

                                <Text variant="large" block>{context.getTranslation("configuration", "deleteConversations")}</Text>


                                <ChoiceGroup
                                    selectedKey={this.state.accUserDelete}
                                    style={{ width: "200px" }}

                                    options={[
                                        {
                                            key: 'No',
                                            text: context.getTranslation("configuration", "notAllowed"),
                                            'data-automation-id': 'auto1',
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Yes',
                                            text: context.getTranslation("configuration", "allowed"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        },
                                        {
                                            key: 'Proposed',
                                            text: context.getTranslation("configuration", "proposedConversationsOnly"),
                                            styles: { field: { fontWeight: FontWeights.regular } }
                                        }
                                    ]}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            accUserDelete: newValue.key,
                                            updated: true
                                        });
                                    }}
                                    label={context.getTranslation("configuration", "deletionRight")}
                                />

                                <Text variant="medium" block>{context.getTranslation("configuration", "proposeDeletion")}</Text>
                                <Stack>

                                    <Checkbox
                                        label={context.getTranslation("configuration", "allowed")}
                                        checked={this.state.accUserPropose}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accUserPropose: newValue,
                                                updated: true
                                            });
                                        }}
                                    />
                                    {this.state.accUserPropose && <Stack vertical>
                                        <Stack.Item styles={{ root: { padding: '0px' } }}>
                                            <Label>{context.getTranslation("configuration", "proposeUsers")}</Label>
                                            <UserPicker
                                                user={this.state.userProposeUsers}

                                                fc={(user) => {
                                                    this.setState({
                                                        userProposeUsers: user,
                                                        updated: true
                                                    });
                                                }}
                                            />
                                        </Stack.Item>
                                    </Stack>}
                                    <Checkbox
                                        label={context.getTranslation("configuration", "reasoningTextRequired")}
                                        checked={this.state.accUserProposeReasoning}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accUserProposeReasoning: newValue,
                                                updated: true
                                            });
                                        }}
                                    />
                                </Stack>

                            </PivotItem>

                        </Pivot>


                    </Stack>
                )
                }
            </MyContext.Consumer>
        );

    }

    renderAna = () => { // NOTE: Add Analytics Form
        this.tmpKey = [];
        this.tmpKey.push({ key: "Header1", text: "Categories", itemType: 2 });
        if (this.props.dataCategory != null) {
            for (let x = 0; x < this.props.dataCategory.length; x++) {
                let i = {
                    name: this.props.dataCategory[x].name,
                    text: this.props.dataCategory[x].name,
                    keywords: this.props.dataCategory[x].keywords,
                    id: this.props.dataCategory[x].id,
                    key: this.props.dataCategory[x].id,
                    tenantId: this.props.dataCategory[x].tenantId,
                    frequence: this.props.dataCategory[x].frequence,
                    link: this.props.dataCategory[x].link,
                    sentiments: this.props.dataCategory[x].sentiments,
                };
                this.tmpKey.push(i);
            }
        }
        let analyzeSelectedOptions = [
            { id: "inbound", value: false },
            { id: "outbound", value: false },
            { id: "meeting", value: false },
            { id: "external", value: false },
            { id: "internal", value: false },
            { id: "chat", value: false },
            { id: "chatAll", value: false },

        ];

        if (this.state.analyzeAllConditions !== null && this.state.analyzeAllConditions !== undefined && this.state.analyzeAllConditions.length > 0) {
            for (let i = 0; i < this.state.analyzeAllConditions.length; i++) {

                let index = analyzeSelectedOptions.findIndex(obj => {
                    return obj.id === this.state.analyzeAllConditions[i];
                });
                console.log(index);
                if (index !== -1) {
                    analyzeSelectedOptions[index].value = true;

                }

            }
        }
        const displayAnalytics = () => {
            if (this.state.anaType !== "NoAnalytics" && this.state.anaType !== "AnalyzeSelected") {
                return true;
            } else if (this.state.anaType == "AnalyzeSelected" && this.state.anaType !== "NoAnalytics" && this.state.analyzeAllConditions.length > 0) {
                return true;
            } else {
                return false;
            }
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Stack>


                        <TextField type={"text"}
                            value={this.state.name}
                            label={context.getTranslation("configuration", "ruleName")}
                            placeholder={context.getTranslation("configuration", "ruleNamePlaceholder")}
                            onChange={(event, newValue) => {
                                this.setState({
                                    name: newValue,
                                });
                            }}
                            onBlur={this.handleBlurName}
                            errorMessage={this.state.isNameValidationError.length > 0 ? this.state.isNameValidationError : ""}
                            required
                        />

                        <TextField
                            label={context.getTranslation("configuration", "description")}
                            type={"text"}
                            multiline
                            rows="3"
                            cols="75"
                            value={this.state.text}
                            onChange={(event, newValue) => {
                                this.setState({
                                    text: newValue,
                                    updated: true
                                });
                            }}
                        />

                        <ChoiceGroup
                            selectedKey={this.state.anaType}
                            style={{ width: "200px" }}

                            options={[
                                {
                                    key: 'NoAnalytics',
                                    text: context.getTranslation("configuration", "noAnalytics"),
                                    'data-automation-id': 'auto1',
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                },
                                {
                                    key: 'OnDemand',
                                    text: context.getTranslation("configuration", "analyticsOnDemand"),
                                    'data-automation-id': 'auto2',
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                },
                                {
                                    key: 'Full',
                                    text: context.getTranslation("configuration", "analyzeAll"),
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                },
                                {
                                    key: 'AnalyzeSelected',
                                    text: context.getTranslation("configuration", "analyzeSelected"),
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                }
                            ]}
                            onChange={(event, newValue) => {
                                this.setState({
                                    anaType: newValue.key,
                                    updated: true
                                });
                            }}
                            label={context.getTranslation("configuration", "analyticsRights")}
                        />

                        {this.state.anaType === "AnalyzeSelected" && <Stack style={{ marginLeft: "25px" }}>
                            {analyzeSelectedOptions.map((checkbox) => {
                                return (
                                    <Checkbox key={checkbox.id}
                                        label={context.getTranslation("configuration", checkbox.id)}
                                        title={checkbox.id}
                                        checked={checkbox.value}
                                        styles={checkboxStyles}

                                        onChange={(newValue) => {
                                            if (newValue.target.checked) {
                                                console.log(newValue.target.value);
                                                let arr = this.state.analyzeAllConditions;
                                                arr.push(checkbox.id);
                                                checkbox.value = newValue;
                                                this.setState({ analyzeAllConditions: arr });
                                            } else {

                                                let index = this.state.analyzeAllConditions.indexOf(checkbox.id);
                                                let arr = this.state.analyzeAllConditions;
                                                checkbox.value = newValue;

                                                arr.splice(index, 1);
                                                this.setState({ analyzeAllConditions: arr });

                                            }
                                        }} />
                                )
                            })}
                        </Stack>}



                        {displayAnalytics() && <Stack>
                            <br /><hr />
                            <Checkbox
                                label={context.getTranslation("configuration", "videoIndexer")}
                                checked={this.state.anaVideoIndexer}
                                styles={checkboxStyles}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        anaVideoIndexer: newValue,
                                        anaTranscription: !newValue,
                                        anaSentiment: false,
                                        updated: true
                                    });
                                }}
                            />

                            <Checkbox
                                label={context.getTranslation("configuration", "speechToText")}
                                checked={this.state.anaTranscription}
                                styles={checkboxStyles}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        anaTranscription: newValue,
                                        anaVideoIndexer: !newValue,
                                        anaSentiment: false,
                                        updated: true
                                    });
                                }}
                            />

                            {this.state.anaTranscription &&
                                <Stack>
                                    <Stack.Item>
                                        <TranscriptLanuages
                                            label={context.getTranslation("configuration", "defaultLanguage")}
                                            selectedKey={this.state.anaSpeechLanguage}
                                            onChange={(e, option) => {

                                                var selectedAltLangaugeIndex = this.state.optionAlternativeLanguageKey.indexOf(option.key);
                                                var newAltLanguage = this.state.optionAlternativeLanguageKey;
                                                if (selectedAltLangaugeIndex > -1) {
                                                    newAltLanguage.splice(selectedAltLangaugeIndex, 1);
                                                }
                                                this.setState({
                                                    anaSpeechLanguage: option.key,
                                                    optionAlternativeLanguageKey: newAltLanguage,
                                                    updated: true
                                                });

                                            }
                                            }
                                            placeholder={context.getTranslation("common", "selectAnOption")}

                                        />

                                    </Stack.Item>
                                    {this.state.anaSpeechLanguage &&
                                        <Stack.Item>
                                            <Checkbox
                                                label={context.getTranslation("configuration", "addAlternativeLanguage")}
                                                checked={this.state.alternativeLanguage}
                                                styles={checkboxStyles}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        alternativeLanguage: newValue,
                                                        updated: true
                                                    });
                                                }}
                                            />
                                        </Stack.Item>
                                    }
                                    {this.state.alternativeLanguage && <>
                                        <Stack.Item>
                                            <Query
                                                query={getCognitiveServicesLocalesTranscription}
                                                variables={{}}
                                            >
                                                {({ loading, error, data }) => {
                                                    if (loading) {
                                                        return (
                                                            <div style={{ float: "left", "margin-left": "50px" }}>
                                                                <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                                            </div>
                                                        );
                                                    }
                                                    if (error) return <h4>Failed to connect</h4>;
                                                    if (data.getTranscriptionLocales == null) {
                                                        return <div />;
                                                    }
                                                    let transcriptLanguageList = [];

                                                    if (data.getTranscriptionLocales != null) {

                                                        let languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
                                                        for (var i = 0; i < data.getTranscriptionLocales.length; i++) {
                                                            var countryName = languageNames.of(data.getTranscriptionLocales[i]);
                                                            transcriptLanguageList.push({
                                                                key: data.getTranscriptionLocales[i],
                                                                text: countryName
                                                            });
                                                        }
                                                    }

                                                    return <ComboBox
                                                        placeholder={"Select maximum " + this.state.maxAlternativeLanguages + " languages"}
                                                        id="selectAlternativeLanguage"
                                                        style={{ width: "300px" }}
                                                        label={context.getTranslation("configuration", "selectAlternativeLanguage")}
                                                        selectedKey={this.state.optionAlternativeLanguageKey}
                                                        allowFreeform={false}
                                                        onChange={this.onChangeMultiLanguages}
                                                        autoComplete={true}
                                                        options={this.availableAlternativeLanguages(transcriptLanguageList)}
                                                        errorMessage={this.state.optionAlternativeLanguageKey.length > this.state.maxAlternativeLanguages ? ('Maximum ' + (this.state.maxAlternativeLanguages) + ' alternative languages') : undefined}
                                                        multiSelect

                                                    />

                                                }}

                                            </Query>


                                        </Stack.Item>
                                        {((this.state.optionAlternativeLanguageKey != null) && (this.state.optionAlternativeLanguageKey != "")) && <Stack.Item>
                                            <Checkbox
                                                label={context.getTranslation("configuration", "multilingualConversations")}
                                                checked={this.state.multilingualConversations}
                                                styles={checkboxStyles}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        multilingualConversations: newValue,
                                                        updated: true
                                                    });
                                                }}
                                            />
                                        </Stack.Item>}
                                    </>}
                                    <Stack.Item styles={this.styles}>
                                        <Stack vertical>
                                            <Stack.Item>
                                                <ComboBox
                                                    style={{ width: "300px" }}
                                                    label={context.getTranslation("configuration", "categorySelection")}
                                                    placeholder={context.getTranslation("common", "selectAnOption")}
                                                    selectedKey={this.state.selectedOptionKeys}
                                                    allowFreeform={false}
                                                    onChange={this.onChangeMulti}
                                                    autoComplete={true}
                                                    options={this.tmpKey}
                                                    multiSelect
                                                />
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>

                                    <Checkbox
                                        label={context.getTranslation("configuration", "speakerRecognition")}
                                        disabled={true}
                                        checked={this.state.anaHasSpeakerRecognition}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                anaHasSpeakerRecognition: newValue,
                                                updated: true
                                            });
                                        }}
                                    />
                                </Stack>}

                            {!this.state.anaVideoIndexer && <Stack>
                                <br /><hr />
                                <Checkbox
                                    label={context.getTranslation("configuration", "speechAnalytics")}
                                    checked={this.state.anaSentiment}
                                    styles={checkboxStyles}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            anaSentiment: newValue,
                                            updated: true
                                        });
                                    }}
                                    disabled={!this.state.anaTranscription}
                                />
                            </Stack>}
                            {(this.state.anaSentiment || this.state.anaVideoIndexer) && <Stack>
                                {/*COMMENTED THIS IN CASE WE NEED TO USE THIS FUNCTIONALITY AGAIN*/}
                                {/*<Stack.Item styles={this.styles}>*/}
                                {/*    <Slider label={context.getTranslation("configuration", "sentimentPositive")} min={0} max={100} valueFormat={(value: number) => `${value}%`} showValue={true}*/}
                                {/*        value={this.state.anaSentimentPositive}*/}
                                {/*        onChange={(value: number) => {*/}
                                {/*            this.setState({*/}
                                {/*                anaSentimentPositive: value,*/}
                                {/*                updated: true*/}
                                {/*            })*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Stack.Item>*/}
                                {/*<Stack.Item styles={this.styles}>*/}
                                {/*    <Slider label={context.getTranslation("configuration", "sentimentNegative")} min={0} max={100} valueFormat={(value: number) => `${value}%`} showValue={true}*/}
                                {/*        value={this.state.anaSentimentNegative}*/}
                                {/*        onChange={(value: number) => {*/}
                                {/*            this.setState({*/}
                                {/*                anaSentimentNegative: value,*/}
                                {/*                updated: true*/}
                                {/*            })*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Stack.Item>*/}

                                {this.state.anaType === "Individual" && <div>
                                    <Stack.Item styles={this.styles}>
                                        <Stack horizontal>
                                            <Stack.Item>
                                                <TextField label={"Keywords"}
                                                    onChange={(event) => { this.handleChange(event, 1) }}
                                                    value={this.state.keyword}
                                                    onKeyDown={this._handleKeyDown}
                                                />
                                            </Stack.Item>
                                            <Stack.Item styles={{
                                                root: {
                                                    paddingTop: "28px",
                                                    paddingLeft: "25px"
                                                }
                                            }}>
                                                <PrimaryButton onClick={event => {
                                                    if (this.state.keyword.length > 0) {
                                                        this.addToKeylist(this.state.keyword)
                                                    } else {
                                                        toast.error("Keyword to short.", this.toastyy)
                                                    }
                                                }} text={"Add"} />
                                            </Stack.Item>

                                        </Stack>

                                    </Stack.Item>

                                    <Stack.Item styles={this.styles}>
                                        <Stack horizontal>
                                            <Stack.Item styles={{ root: { width: "85%" } }}>
                                                <DetailsList
                                                    key={this.state.sel4listKeyword.length + ""}
                                                    items={this.state.sel4listKeyword}
                                                    columns={this.columnsKeyword}
                                                    setKey="set"
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    ariaLabelForSelectionColumn="Toggle selection"
                                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                                    checkButtonAriaLabel="Row checkbox"
                                                    selectionMode={SelectionMode.single}
                                                    selection={this._selection}
                                                    styles={{ root: { color: "black" } }}
                                                />
                                            </Stack.Item>
                                            <Stack.Item styles={{ root: { paddingLeft: "15px" } }}>
                                                <PrimaryButton onClick={event => {
                                                    if (this.state.selectionDetails !== undefined && this.state.selectionDetails.text != null) {
                                                        this.delKeylist(this.state.selectionDetails.text);
                                                    } else {
                                                        toast.error("Nothing to delete.", this.toastyy)
                                                    }
                                                }} text={"Del"} />
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>


                                </div>}

                            </Stack>}

                            {(this.state.anaType !== "NoAnalytics" && (this.state.anaVideoIndexer || this.state.anaTranscription)) && <Stack>
                                <hr />
                                <Stack>
                                    <Checkbox
                                        label={context.getTranslation("configuration", "translation")}
                                        checked={this.state.translation}
                                        styles={checkboxStyles}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                translation: newValue,
                                                anaTranslate: !newValue ? "No" : "Manual",
                                                updated: true
                                            });
                                        }}
                                    />

                                    <ChoiceGroup
                                        style={{ width: "200px", marginLeft: "20px" }}
                                        selectedKey={this.state.anaTranslate}
                                        disabled={!this.state.translation}
                                        options={[
                                            {
                                                key: 'Manual',
                                                text: context.getTranslation("configuration", "manualStart"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Auto',
                                                text: context.getTranslation("configuration", "automaticalStart"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            }
                                        ]}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                anaTranslate: newValue.key,
                                                updated: true
                                            });
                                        }} />
                                </Stack>

                                {this.state.anaTranslate === "Auto" &&
                                    <Stack>
                                        <TranslateLanuages
                                            placeholder={context.getTranslation("common", "selectAnOption")}
                                            label={context.getTranslation("configuration", "language")}
                                            selectedKey={this.state.anaTranslationLanguage}
                                            onChange={(event, option, index, newValue) => {
                                                this.setState({
                                                    anaTranslationLanguage: option.key,
                                                    updated: true
                                                });

                                            }}
                                        />
                                    </Stack>

                                }
                                {/*
                           {false && <Text variant="medium" block>{context.getTranslation("configuration", "visibilityOfResults")}</Text>}
                                {false && <Stack>

                                    <ChoiceGroup
                                        selectedKey={this.state.anaVisibility}
                                        style={{ width: "200px" }}

                                        options={[
                                            {
                                                key: 'All',
                                                text: context.getTranslation("configuration", "everyoneThatCanReplay"),
                                                'data-automation-id': 'auto1',
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            },
                                            {
                                                key: 'Certain',
                                                text: context.getTranslation("configuration", "onlyByCertainUsers"),
                                                styles: { field: { fontWeight: FontWeights.regular } }
                                            }
                                        ]}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                anaVisibility: newValue.key,
                                                updated: true
                                            });
                                        }} />

                                </Stack>}
                                */}
                            </Stack>}
                        </Stack>}
                    </Stack>
                )}
            </MyContext.Consumer>);

    }
}
