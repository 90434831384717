import React from 'react';
import TreeMap, { Tooltip } from 'devextreme-react/tree-map';
import {Size} from "devextreme-react/vector-map";

class TreeMaping extends React.Component{

    render() {
        return (
            <TreeMap
                id="treemap"
                dataSource={this.props.data}
                palette="Soft Pastel"
            >
                <Tooltip
                    enabled={true}
                    format="thousands"
                    customizeTooltip={customizeTooltip}
                />
                <Size
                    height={this.props.size.height}
                    width={this.props.size.width}
                />
            </TreeMap>
        );
    }
}

function customizeTooltip(arg) {
    const data = arg.node.data;

    return {
        text: arg.node.isLeaf() ?
            `<span class="city">Keyword: </span> ${data.name} <br/>Quantity: ${arg.valueText}` :
            null
    };
}

export default TreeMaping;
