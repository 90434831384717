import React, { Component } from 'react'
import { DetailsList, SelectionMode } from 'office-ui-fabric-react';
import { MyContext } from '../../../../../context';


export default class ScorecardSettings extends Component {
    static contextType = MyContext
    render() {
        return (
            <DetailsList
                compact={true}
                items={this.props.settingItems}
                columns={[
                    {
                        key: 'name',
                        name: this.context.getTranslation("compliance", "evaluationKey"),
                        fieldName: 'name',
                        minWidth: 100,
                        maxWidth: 100,
                        isResizable: true
                    },
                    {
                        key: 'color',
                        fieldName: 'color',
                        minWidth: 30,
                        maxWidth: 30,
                        isResizable: true,
                        onRender: (item) => {
                            return <div style={{ width: "20px", height: "20px", backgroundColor: item.color }}></div>
                        }
                    },
                    {
                        key: 'range',
                        fieldName: 'range',
                        minWidth: 80,
                        maxWidth: 80,
                        isResizable: true,
                        onRender: (item) => {
                            return <span>{item.minRange}% - {item.maxRange}%</span>
                        }
                    }

                ]}
                selectionMode={SelectionMode.none}
            />
        )
    }
}