import React, { Component } from 'react';
import { ASCHeader } from "../ASCHeader/ASCHeader";
import ASCFooter from "../ASCFooter/ASCFooter";
import { Text, IFontStyles} from 'office-ui-fabric-react';
import Styler from "../ShopView/ShopStyle";

import {
    Stack
} from "office-ui-fabric-react";
import { MyContext } from '../../context';


// Styles definition
const stackContainerStyles: IStackStyles = {
    root: {
        paddingTop: 50
    }
};


const stackStyles: IStackStyles = {
    root: {
        maxWidth: 660,
        marginBottom: 50
    }
};

// Tokens definition
const stackTokens: IStackTokens = { childrenGap: 5 };


export default class Privacy extends Component {

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <>

                        <ASCHeader title={context.getTranslation("privacy","privacyPolicy")} />

                        <Stack tokens={stackTokens}>

                            <Stack horizontalAlign="center" styles={stackContainerStyles}>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">1. {context.getTranslation("privacy", "overview")} </Text>
                                    <Text variant="large">{context.getTranslation("privacy", "overviewText")}</Text>

                                </Stack>
                                <Stack styles={stackStyles}>

                                    <Text variant="xxLarge">2. {context.getTranslation("privacy", "dataProtection")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "dataProtectionText1")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "dataProtectionText2")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "dataProtectionText3")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "dataProtectionText4")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "dataProtectionText5")}</Text>

                                </Stack>
                                <Stack styles={stackStyles}>

                                    <Text variant="xxLarge">2.1 {context.getTranslation("privacy", "rightsRegardingData")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "rightsRegardingDataText")}</Text>

                                </Stack>
                                <Stack styles={stackStyles}>

                                    <Text variant="xxLarge">2.2 {context.getTranslation("privacy", "dataCollectionWhenUsingTheApp")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "dataCollectionWhenUsingTheAppText1")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "dataCollectionWhenUsingTheAppText2")}</Text>

                                </Stack>
                                <Stack styles={stackStyles}>

                                    <Text variant="xxLarge">2.3 {context.getTranslation("privacy", "dataCollectionResponsible")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "dataCollectionResponsibleText")}</Text>

                                </Stack>
                                <Stack styles={stackStyles}>

                                    <Text variant="xxLarge">3. {context.getTranslation("privacy", "contact")}</Text>
                                    <Text variant="large">{context.getTranslation("privacy", "contactText1")} <a href="mailto:webcontact@asc.de">e-mail</a> {context.getTranslation("privacy", "contactText2")}  <a href="https://asctechnologies.com/english/contact.html" target="_blank">contact form</a>,{context.getTranslation("privacy", "contactText3")} </Text>

                                </Stack>
                            </Stack>


                        </Stack>

                        <ASCFooter />
                    </>
                )
                }
            </MyContext.Consumer>
        );
    }

}
