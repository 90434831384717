import { CommandBarButton, FontIcon, Panel, PanelType, Spinner, Stack, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import '../ComplianceView.scss';
import { MyContext } from '../../../context';
import { Query } from 'react-apollo';
import * as QueryPolicy from './PolicyQuery';
import EditPolicyContainer from './Panels/EditPolicyContainer';
import PolicyTileView from './PolicyTileView';
import PolicyListView from './PolicyListView';
import { NetworkStatus } from 'apollo-client';
import SafetyDialog from '../../CustomComponents/SafetyDialog';
import AlertBar from '../../CustomComponents/AlertBar';
import { queryGetTenantData } from '../../../queries/querySettings';

export default class Policies extends Component {
    static contextType = MyContext
    //Utils.lsGet("viewType") === "true" ? false : true;

    constructor(props) {
        super(props);

        this.state = {
            isPanelOpen: false,
            policyId: null,
            action: "",
            warningMessage: "",
            policyRuleId: null,
            panelTitle: null,
            viewType: this.props.iconView,
            alertMessagePanel: "",
            hideDialogDelete: true,
            isProcessing: false,
            isTokenExceeded: false
        };

    }

    componentDidMount = () => {
        if (this.props.userRules.ruleAccess.userComplianceAIQuestions) {
            this.props.apol
                .query({
                    query: queryGetTenantData,
                    variables: this.props.auth
                })
                .then((result) => {
                    const { getTenantData } = result.data
                    if (getTenantData) {
                        this.setState({ isTokenExceeded: getTenantData.analyticsAICapacity > 0 && getTenantData.analyticsAICapacityUsed == getTenantData.analyticsAICapacity })
                    }
                });
        }
    }

    selectPolicy = (id) => {
        this.setState({
            policyId: id,
            warningMessage: ""
        });
    }


    openPanel = (action, policyId = null) => {
        var state = {}
        switch (action) {
            case "create":
                state = {
                    isPanelOpen: true,
                    action: "create",
                    panelTitle: "createPolicyPanel"
                }
                break;
            case "edit":
                if (policyId) {
                    state = {
                        ...state,
                        isPanelOpen: true,
                        action: "edit",
                        panelTitle: "editPolicyPanel"
                    }
                }
                else {
                    state = { warningMessage: this.context.getTranslation("compliance", "selectPolicy"), alertType: "warning" }
                }
                break;
        }
        this.setState(state)
    }


    hidePanel = () => {
        this.setState({ isPanelOpen: false, alertMessagePanel: "" });
    };


    getRiskRating = (riskRatingString) => {
        const iconStyle = {
            display: 'block',
            textAlign: 'center',
        }

        switch (riskRatingString) {
            case "Info":
                return <span style={iconStyle}>{<FontIcon iconName="Info" style={{ fontSize: "20px" }} />}</span>;
            case "Warn":
                return <span style={iconStyle}>{<FontIcon iconName="WarningSolid" style={{ fontSize: "20px" }} />}</span>;
            case "Adherence":
                return <span style={iconStyle}>{<FontIcon iconName="Warning" style={{ fontSize: "20px" }} />}</span>;
            default:
                return <span />
        }
    }


    deletePolicy = () => {
        this.setState({ hideDialogDelete: true, isProcessing: true })
        if (this.state.policyId !== null) {
            const id = this.state.policyId
            this.props.apol
                .mutate({
                    mutation: QueryPolicy.deletePolicy,
                    variables: { id }
                })
                .then((result) => {
                    if (result.data.deletePolicy.status !== "ok") {
                        this.setState({
                            warningMessage: this.context.getTranslation("compliance", result.data.deletePolicy.text),
                            alertType: result.data.deletePolicy.status,
                            isProcessing: false
                        })
                    } else {
                        this.setState({
                            isProcessing: false
                        })
                        this.refetchQueryPolicies();
                    }
                });
        }
    }

    refetchQueryPolicies = null;

    refreshPolicies = () => {
        this.refetchQueryPolicies()
    }

    changeView = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                viewType: !prevState.viewType
            }
        })
    }

    setMessageError = (message) => {
        this.setState({
            alertMessagePanel: message
        })
    }


    clearAlert = () => {
        if (this.state.alertMessagePanel !== "") {
            this.setState({
                alertMessagePanel: ""
            })
        }
    }

    getHeader = () => {
        return (
            <div style={{ flex: "auto", marginLeft: "20px" }}>
                <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>{this.context.getTranslation("compliance", this.state.panelTitle)}</div>
                <AlertBar
                    message={this.state.alertMessagePanel}
                    alertType={"error"}
                    clearAlert={this.clearAlert}
                />
            </div >
        )
    }

    openDialogDelete = () => {
        if (this.state.policyId !== null) {
            this.setState({ hideDialogDelete: false })
        } else {
            this.setState({
                warningMessage: this.context.getTranslation("compliance", "selectPolicyRule"),
                alertType: "warning"
            })
        }
    }


    render() {
        const { auth } = this.props

        return (
            <div style={{ overflow: "auto", maxHeight: window.innerHeight - 120, position: 'relative', marginTop: "5px" }}>
                {/*Manage button add and edit policy */}
                <Stack horizontal styles={{ root: { height: 44 } }}>
                    <CommandBarButton
                        styles={{ root: { marginTop: 10 } }}
                        iconProps={{ iconName: this.state.viewType === true ? "List" : "ContactCard" }}
                        disabled={false}
                        checked={false}
                        onClick={this.changeView}
                    />
                    <CommandBarButton
                        styles={{ root: { marginTop: 10 } }}
                        iconProps={{ iconName: 'Add' }}
                        text={this.context.getTranslation("compliance", "addPolicy")}
                        disabled={!this.props.userRules.ruleAccess.userCompliancePolicyEdit}
                        checked={false}
                        onClick={() => this.openPanel("create")}
                    />
                    {!this.state.viewType &&
                        <CommandBarButton
                            styles={{ root: { marginTop: 10 } }}
                            iconProps={{ iconName: 'Edit' }}
                            text={this.context.getTranslation("compliance", "editPolicy")}
                            disabled={!this.props.userRules.ruleAccess.userCompliancePolicyEdit}
                            checked={false}
                            onClick={() => { this.openPanel("edit", this.state.policyId) }}
                        />
                    }
                    {!this.state.viewType &&
                        <CommandBarButton
                            styles={{ root: { marginTop: 10 } }}
                            iconProps={{ iconName: 'Delete' }}
                            disabled={!this.props.userRules.ruleAccess.userCompliancePolicyEdit}
                            text={this.context.getTranslation("compliance", "deletePolicy")}
                            onClick={this.openDialogDelete}
                        />
                    }
                </Stack>

                {/*Warning and error message*/}
                <AlertBar
                    message={this.state.warningMessage}
                    alertType={this.state.alertType}
                    clearAlert={() => { this.setState({ warningMessage: "" }) }}
                />

                {this.state.isTokenExceeded && (<AlertBar
                    message={this.context.getTranslation("compliance", "aiTokenExceeded")}
                    alertType={"warning"}
                />)}

                {this.state.isProcessing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}

                {/*Policies datailList*/}
                {!this.state.isProcessing && <Stack style={{ marginTop: 10 }}>
                    <Query
                        query={QueryPolicy.queryAllPolicies}
                        variables={{ auth }}
                        notifyOnNetworkStatusChange={true}
                        fetchPolicy="network-only">
                        {({ loading, error, data, refetch, networkStatus }) => {
                            this.refetchQueryPolicies = refetch
                            if (loading || networkStatus === NetworkStatus.refetch) return (
                                <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                            )
                            if (error) {
                                console.log(error)
                                return (<AlertBar message={"Error!"} alertType={"error"} />);
                            }
                            if (data.getPolicies == null) return (<AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />);
                            else {
                                return (
                                    <div>
                                        {!this.state.viewType && (<PolicyListView selectPolicy={this.selectPolicy} getRiskRating={this.getRiskRating} policies={data.getPolicies} />)}
                                        {this.state.viewType && (<PolicyTileView selectPolicy={this.selectPolicy} policyId={this.state.policyId} policies={data.getPolicies} theme={this.props.theme} auth={this.props.auth} apol={this.props.apol} refreshPolicies={this.refreshPolicies} openPanel={this.openPanel} deletePolicy={this.openDialogDelete} userRules={this.props.userRules} />)}
                                    </div>

                                )
                            }
                        }}
                    </Query>
                </Stack>}

                {/*Policy panel*/}
                <Panel
                    isOpen={this.state.isPanelOpen}
                    onDismiss={this.hidePanel}
                    isFooterAtBottom={false}
                    onRenderHeader={this.getHeader}
                    type={PanelType.medium}
                >
                    {this.state.action === "edit" && (<EditPolicyContainer apol={this.props.apol} auth={this.props.auth} hidePanel={this.hidePanel} id={this.state.policyId} refreshPolicies={this.refreshPolicies} userRules={this.props.userRules} setMessageError={this.setMessageError} />)}
                    {this.state.action === "create" && (<EditPolicyContainer apol={this.props.apol} auth={this.props.auth} hidePanel={this.hidePanel} id={null} refreshPolicies={this.refreshPolicies} userRules={this.props.userRules} setMessageError={this.setMessageError} />)}
                </Panel>

                {/*SafetyDialog for delete policy*/}
                <SafetyDialog
                    title={this.context.getTranslation("compliance", "deletePolicy")}
                    subText={this.context.getTranslation("compliance", "confirmDelete")}
                    hideDialog={this.state.hideDialogDelete}
                    closeDialog={() => { this.setState({ hideDialogDelete: true }) }}
                    ok={this.deletePolicy}
                />
            </div>
        )
    }


}
