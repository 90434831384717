import { HoverCard } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../context';
import { FontIcon } from "office-ui-fabric-react/lib";

export default class HoverCardFolders extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            folders: props.foldersData == undefined ? null : props.foldersData
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.foldersData != this.props.foldersData) {
            this.setState({
                folders: this.props.foldersData
            })
        }
    }

    plainCardProps = {
        onRenderPlainCard: () => {
            return (
                <MyContext.Consumer>
                    {(context) => (
                        <div>
                            <div style={{ padding: "10px" }}>
                                <div >
                                    {this.state.folders != null
                                        ? (this.state.folders
                                            .map((folder, item) => <div key={item}>{folder.name}</div>))
                                        : <div>{this.context.getTranslation("common", "loading")}</div>
                                    }
                                    {
                                        (this.state.folders != null && this.state.folders.length == 0) && <div>{this.context.getTranslation("detailContainer", "notInSafeFolder")}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                    }
                </MyContext.Consumer>
            );
        }
    }


    render() {
        return (
            (<HoverCard expandingCardProps={this.expandingCardProps}
                plainCardProps={this.plainCardProps}
                instantOpenOnClick={true}
                type="PlainCard">
                <div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {this.state.folders != null ?
                            (<div>
                                <FontIcon aria-label="LockSolid" iconName="LockSolid" />
                                {` ${this.context.getTranslation("detailContainer", "conversationSafe")}
                  (${(this.state.folders.length) ? this.state.folders.length :
                                    this.context.getTranslation("detailContainer", "folderUnallocated")})`}
                            </div>
                            )
                            : <div></div>
                        }
                    </div>

                </div>
            </HoverCard>)

        )
    }

}