import { Stack, Dropdown, Spinner, PrimaryButton, Text, DefaultButton, Dialog, DialogFooter, DialogType } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import {
    getAllFoldersShrinked, getFoldersAllowedForPolicies,
    saveFoldersInConversation
} from "../../../ConversationSafe/ConversationSafeQuery";
import { queryGetEntry } from "../../../../queries/queryRecordings";
import { SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import _ from 'lodash'
import { MessageBar, MessageBarType } from "office-ui-fabric-react";


export default class PolicyCSFolders extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props)
        this.state = {
            availableFolders: null
        }

    }

    componentDidMount() {
        const auth = this.props.auth
        this.props.apol
            .query({
                query: getFoldersAllowedForPolicies,
                variables: { input: auth }
            })
            .then((result) => {
                let availableFolders = result.data.getConversationSafeFolderLabelsPolicy.filter(e => e.id !== null && (e?.allowedInPolicy ?? true))
                this.setState({
                    availableFolders,
                })
            })
            .catch(err => {
                console.log('error', err)
                this.setState({
                    queryError: true,
                    availableFolders: []
                })
            })
    }

    buildDropdownObjects = (allFolders) => {
        let availableFolders = allFolders.map(e => {
            return ({
                "key": e.id,
                "text": e.name,
                disabled: false
            })
        })
        return availableFolders
    }

    onChange = (event, folder) => {
        //when unassigned folder is selected
        console.log('folder', folder)
        let newFolderSelection = this.props.selectedFolders.includes(folder.key) ?
            this.props.selectedFolders.filter(e => e != folder.key) :
            [...this.props.selectedFolders, folder.key]
        this.props.onChangePolicyFolders(newFolderSelection)
    }

    render() {
        return (
            <Stack horizontal style={{ marginLeft: "0px" }}>

                {(this.state?.availableFolders?.length == 0 ?? false) &&
                    <Stack.Item align="center" style={{ maxWidth: "600px", minWidth: "200px", marginRight: "20px" }}>
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            onDismiss={this.clearAlert}>
                            {this.context.getTranslation("detailContainer", "infoNoFoldersForPolicy")}
                        </MessageBar>
                    </Stack.Item>}


                {this.state.availableFolders === null &&
                    <Stack.Item align="center" style={{ maxWidth: "600px", minWidth: "200px", marginRight: "20px" }}>
                        <Spinner style={{ paddingLeft: '5px', position: "relative", top: "2px" }} size={SpinnerSize.xSmall} />
                    </Stack.Item>
                }

                {this.state.availableFolders !== null && this.state.availableFolders.length &&
                    <Stack.Item align="center" style={{ maxWidth: "600px", minWidth: "200px", marginRight: "20px" }}>
                        <Dropdown
                            placeholder={this.context.getTranslation("detailContainer", "foldersId")}
                            //label={this.context.getTranslation("detailContainer", "foldersId")}
                            onChange={this.onChange}
                            multiSelect
                            selectedKeys={this.props.selectedFolders}
                            options={this.buildDropdownObjects(this.state.availableFolders)}
                        />
                    </Stack.Item>
                }
            </Stack>)
    }

}