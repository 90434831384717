import React from 'react'
import { SearchBox } from 'office-ui-fabric-react/lib';
import { queryGetAllGroups, mutationCreateGroup, queryGetConfigData } from "../../../queries/querySettings";
import {toast} from "react-toastify";
import {
    DetailsList,
    DetailsListLayoutMode,
    mergeStyleSets, Persona, PersonaSize, PrimaryButton,
    Selection, SelectionMode
} from "office-ui-fabric-react";
import { MyContext } from '../../../context';

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});

export default class AddGroup extends React.Component {
    _selection: Selection;

    constructor(props) {
        super(props);
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });
    }

    state = {
        groupItems: {},
        selectedItem: {}
    };

    _columns = [
        {
            key: 'column1',
            name: 'Options',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onColumnClick: this._onColumnClick,
            onRender: (item) => {
                return <div>
                    <Persona size={PersonaSize.size24} text={item.value} />
                    </div>

            }
        }
        , { key: 'column2', name: 'Account', fieldName: 'value', minWidth: 50, maxWidth: 160, isResizable: true }
        , { key: 'column3', name: 'Name', fieldName: 'key', minWidth: 50, maxWidth: 220, isResizable: true }
    ];

    getColItems = async (apol,filter) => {
        let _allItems = [];
        var data = await apol
            .query({
                query: queryGetAllGroups,
                variables: {
                    auth: this.props.authorization,
                    filter: filter
                }
            });

        var DataArr = data.data.getAllGroups;
        console.log(DataArr);
        if (DataArr !== undefined) {
            for (let i = 0; i < DataArr.length && i < 20; i++) {
                // Filter all users which have a license already
                var found = false;
                this.props.data.forEach(function (aGroup) {
                    if (aGroup.adGroupId === DataArr[i].adGroupId) {
                        found = true;
                    };
                });
                if (found === false) {
                    _allItems.push({
                        key: DataArr[i].adGroupId,
                        value: DataArr[i].name,
                        groupId: DataArr[i].id
                    });
                }
            }
        }
        this.setState({ groupItems: _allItems });

 
    }

    render(): React.ReactNode {
        return <div>
            <div style={{float: "left", padding: "5px"}}>
                <form>
                    <SearchBox placeholder="Search" onChange={(event,newValue) => (event?.persist(),this.getColItems(this.props.apol, newValue))}
                    />
                    <DetailsList
                        key={"002"}
                        items={this.state.groupItems}
                        columns={this._columns}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        selection={this._selection}
                        selectionMode={SelectionMode.multiple}
                        selectionPreservedOnEmptyClick={true}
                        onActiveItemChanged={this._onItemInvoked}
                    />
                    <PrimaryButton onClick={() => { this.notifyGroupAdd(this.props.apol); this.props.closeModal(); }} data-automation-id="test" text={<MyContext.Consumer>{(context) => (<>{context.getTranslation("configuration", "addSelected")}</>)}</MyContext.Consumer>} allowDisabledFocus={true} />
                </form>
            </div>
        </div>;
    }

    _onItemInvoked = (item, index, ev) => {
        this.setState({ selectedItem: item });
        console.log(item.key);
    }


    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                return '';
            default:
                return `${selectionCount} Items selected`;
        }
    }


    notifyGroupAdd= async (apol)=> {
        // Add Group
        var groupList = [];
        this._selection.getSelection().forEach((aGroup) => {
            console.log(aGroup);
            groupList.push({
                adGroupId: aGroup.key,
                name: aGroup.value,
                id: aGroup.key,
                license: this.props.addType
            });
        });
        var data = await apol
            .mutate({
                mutation: mutationCreateGroup,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    group: groupList
                }
            });
        if (data.data.createGroup == null || data.data.createGroup.length === 0) {
            toast.info('Add Group not successful', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else if (data.data.createGroup[0].name === "error") {
            toast.info('Add Group not successful: ' + data.data.createGroup[0].access, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info('Group added', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

}
