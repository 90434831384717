import React from 'react';

import PieChart, {
    Series,
    Label,
    Connector,
    Size,
    Legend
} from 'devextreme-react/pie-chart';

class PieChartDex extends React.Component {
    constructor(props) {
        super(props);

        this.pointClickHandler = this.pointClickHandler.bind(this);
        this.legendClickHandler = this.legendClickHandler.bind(this);
    }


    render() {
        let data = this.props.data
        if ((this.props.kpi === "PolicyCompared" || this.props.kpi === "CategoryCompared") && this.props.data !== undefined && this.props.data.length > 0) {
            data = [
                { value: this.props.data[0].hit, args: "Hit" },
                { value: this.props.data[0].noHit, args: "No hit" }
            ]
        }
        return (
            <PieChart
                id="pie"
                dataSource={data}
                onPointClick={this.pointClickHandler}
                onLegendClick={this.legendClickHandler}
                palette={this.props.palette}
                ref={this.props.reference}
            >
                <Series
                    argumentField="args"
                    valueField="value"

                >
                    <Label visible={true}>
                        <Connector visible={true} width={1} />
                    </Label>
                </Series>
                <Legend
                    margin={0}
                    horizontalAlignment="center"
                    verticalAlignment="bot"
                />
                <Size width={this.props.size.width} height={this.props.size.height} />

            </PieChart>
        );
    }

    pointClickHandler(e) {
        this.toggleVisibility(e.target);
    }

    legendClickHandler(e) {
        let arg = e.target;
        let item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

        this.toggleVisibility(item);
    }

    toggleVisibility(item) {
        item.isVisible() ? item.hide() : item.show();
    }
}

export default PieChartDex;
