import * as React from 'react';
import { IPersonaProps } from 'office-ui-fabric-react/lib/Persona';
import {
    NormalPeoplePicker,
    ValidationState,
    PeoplePickerItem
} from 'office-ui-fabric-react/lib/Pickers';
import { Query } from 'react-apollo';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { queryGetUser } from '../../../queries/querySettings';
import { PeoplePickerItemSuggestion } from '@fluentui/react'



export default class UserPicker extends React.Component {
    _picker = React.createRef();

    constructor(props) {
        super(props);
        const perSel = [];
        this.query = this.query.bind(this);
        if (props.dataUsers != null) {
            const output = [];
            const suggestion = [];
            let current;
            for (let i = 0; i < props.dataUsers.length; i++) {
                current = {
                    key: props.dataUsers[i].userId,
                    userId: props.dataUsers[i].userId,
                    text: props.dataUsers[i].name,
                    email: props.dataUsers[i].username
                };
                for (let j = 0;
                    this.props.user !== undefined
                    && this.props.user != null
                    && j < this.props.user.length;
                    j++) {
                    if (this.props.user[j].userId === current.userId) {
                        perSel.push(current);
                    }
                }
                output.push(current);
                if (i < 5) {
                    suggestion.push(current);
                }
            }
            this.state = {
                currentPicker: 1,
                delayResults: false,
                peopleList: output,
                mostRecentlyUsed: suggestion,
                currentSelectedItems: [],
                isPickerDisabled: this.props.disabled == undefined ? false : this.props.disabled,
                perSel,
            };
            this.perSel = perSel;
        } else {
            for (let j = 0;
                props.user !== undefined && props.user != null && j < props.user.length;
                j++) {
                perSel.push({ key: props.user[j].userId, userId: props.user[j].userId, text: props.user[j].name != null ? props.user[j].name : props.user[j].userId });
            }
            this.state = {
                currentPicker: 1,
                delayResults: false,
                peopleList: [],
                mostRecentlyUsed: [],
                currentSelectedItems: [],
                isPickerDisabled: this.props.disabled == undefined ? false : this.props.disabled,
                perSel: perSel,
            };
            this.perSel = perSel;
        }

    }


    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            // Update local state when the prop changes
            //doing this for perSel because the states were not synchronized and perSel did not get updated when updating only by state but not by interracting with it,
            //because it is only updated in _onChange function
            this.setState({ perSel: this.props.user });
        }
    }

    render() {
        if (this.props.dataUsers != null) {
            return (
                <>
                    {this._renderNormalPicker()}
                </>
            );
        } else {
            return (
                <>
                    {this.query()}
                </>
            );
        }
    }

    _getTextFromItem(persona) {
        return persona.text;
    }

    query = (layerFunction, auth) => (
        <Query
            onCompleted={(data) => {
                this.convertToUserPicker(data.getUserData.userdata, true);
            }}
            query={queryGetUser}
            variables={{ auth }}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return (
                        <div>
                            <Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />
                        </div>
                    );
                }
                if (error) return <h4>Failed to connect</h4>

                if (data.getUserData == null) return <div />
                //if parent provides passUserDataToParent props func, and props.passUserDataToParent is null
                //we lift "data.getUserData" up to EditPolicy
                if ((this.props.passUserDataToParent != null || this.props.passUserDataToParent != undefined)
                    && this.props.userdata == null) {
                    this.props.passUserDataToParent(data.getUserData)
                    return <div />
                }
                this.users = data.getUserData.userdata;
                this.convertToUserPicker(data.getUserData.userdata, false);
                return this._renderNormalPicker();
            }}
        </Query>
    )

    convertToUserPicker = (data, stateChange) => {
        const presel = [];
        const output = [];
        const sugestion = [];
        let current;

        if (data != null) {
            for (let k = 0; k < data.length; k++) {
                // Collect the users

                //if new prop displayType is set to "noGroups" will not add the groups
                //groups are find based on members prop of the user if it's null it's a user
                if (this.props.displayType === "noGroups" && data[k].members !== null) {
                    continue;
                }
                if (this.props.availableUsers != null && this.props.availableUsers != undefined) {
                    if (!this.props.availableUsers.includes(data[k].userId))
                        continue;
                }
                current = {
                    key: data[k].userId,
                    userId: data[k].userId,
                    text: data[k].name,
                    email: data[k].username,
                    members: data[k].members,
                    addOnCompliance: data[k].addOnCompliance
                }

                output.push(current);
                if (k < 5) {
                    sugestion.push(current);
                }

            }
        }

        for (let j = 0; this.props.user !== undefined && this.props.user != null && j < this.props.user.length; j++) {
            let i = 0;
            for (i = 0; i < data.length; i++) {
                //if new prop displayType is set to "noGroups" will not add the groups
                //groups are find based on members prop of the user if it's null it's a user
                if (this.props.displayType === "noGroups" && data[i].members !== null) {
                    continue;
                }
                current = { key: data[i].userId, userId: data[i].userId, text: data[i].name, email: data[i].username, addOnCompliance: data[i].addOnCompliance };

                if (this.props.user[j].userId === current.userId) {
                    presel.push(current);
                    break;
                }
            }
            if (i >= data.length) {
                // Freestyle user (PSTN)
                presel.push(this.state.perSel[j]);
            }
        }
        if (stateChange) {
            this.setState({ peopleList: output, mostRecentlyUsed: sugestion, perSel: presel });
        }
        this.perSel = presel;
    };

    _renderNormalPicker() {

        const renderItem = (p) => {

            const newProps = {
                ...p,
                styles: { root: { border: this.props.editPolicy !== undefined && this.props.editPolicy && !p.item.addOnCompliance ? '2.5px solid red' : '' } }
            };

            return <PeoplePickerItem {...newProps} />;
        };

        const suggestionProps = {
            suggestionsHeaderText: 'Suggested People',
            mostRecentlyUsedHeaderText: 'Suggested Contacts',
            noResultsFoundText: 'No results found',
            loadingText: 'Loading',
            showRemoveButtons: true,
            suggestionsAvailableAlertText: 'People Picker Suggestions available',
            suggestionsContainerAriaLabel: 'Suggested contacts',
            onRenderSuggestion: (p, suggestionItemProps) => {
                let checkNoAddOn = this.props.editPolicy !== undefined && this.props.editPolicy && !p.addOnCompliance
                let personaProps = {
                    ...p,
                    secondaryText: checkNoAddOn ? "No Add On Compliance" : p.email,
                    styles: {
                        secondaryText: checkNoAddOn ? { color: "red" } : {}
                    }
                }
                return <PeoplePickerItemSuggestion
                    personaProps={personaProps}
                    suggestionsProps={suggestionItemProps}
                />
            }
        }

        return (
            <NormalPeoplePicker
                selectedItems={this.perSel}
                onResolveSuggestions={this._onFilterChanged}
                onEmptyInputFocus={this._returnMostRecentlyUsed}
                onRenderItem={renderItem}
                getTextFromItem={this._getTextFromItem}
                pickerSuggestionsProps={suggestionProps}
                className="ms-PeoplePicker"
                key={`normal${this.state.people ? this.state.people.length : 0}`}
                onRemoveSuggestion={this._onRemoveSuggestion}
                onValidateInput={this._validateInput}
                removeButtonAriaLabel="Remove"
                inputProps={{
                    onBlur: (ev) => console.log('onBlur called'),
                    onFocus: (ev) => console.log('onFocus called'),
                    'aria-label': 'People Picker',
                    placeholder: this.props.placeholder === undefined || this.state.perSel.length > 0 ? "" : this.props.placeholder
                }}
                styles={{
                    itemsWrapper: { paddingTop: 5, paddingLeft: 3, paddingBottom: 5 }
                }}
                componentRef={this._picker}
                onInputChange={this._onInputChange}
                onChange={this._onChange}
                resolveDelay={300}
                disabled={this.state.isPickerDisabled}
                itemLimit={this.props.itemLimit === undefined ? Infinity : this.props.itemLimit}
            />
        );
    }


    _onRemoveSuggestion = (item: IPersonaProps): void => {
        const { peopleList, mostRecentlyUsed: mruState } = this.state;
        const indexPeopleList: number = peopleList.indexOf(item);
        const indexMostRecentlyUsed: number = mruState.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople: IPersonaProps[] = peopleList.slice(0, indexPeopleList).concat(peopleList.slice(indexPeopleList + 1));
            this.setState({ peopleList: newPeople });
        }

        if (indexMostRecentlyUsed >= 0) {
            const newSuggestedPeople: IPersonaProps[] = mruState
                .slice(0, indexMostRecentlyUsed)
                .concat(mruState.slice(indexMostRecentlyUsed + 1));
            this.setState({ mostRecentlyUsed: newSuggestedPeople });
        }
    };

    _onFilterChanged = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = this._filterPersonasByText(filterText);

            filteredPersonas = this._removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.splice(0, limitResults) : filteredPersonas;
            return this._filterPromise(filteredPersonas);
        }
        return [];
    };

    _onChange = (items) => {
        this.props.fc(items, this.state.peopleList);
        this.setState({
            perSel: items
        })
    };

    _returnMostRecentlyUsed = (currentPersonas: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
        let { mostRecentlyUsed } = this.state;
        mostRecentlyUsed = this._removeDuplicates(mostRecentlyUsed, currentPersonas);
        return this._filterPromise(mostRecentlyUsed);
    };


    _filterPromise(personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> {
        if (this.state.delayResults) {
            return this._convertResultsToPromise(personasToReturn);
        }
        return personasToReturn;
    }

    _listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter((item) => item.text === persona.text).length > 0;
    }

    _filterPersonasByText(filterText: string): IPersonaProps[] {
        return this.state.peopleList.filter((item) => this._doesTextStartWith(item.text, filterText));
    }

    _doesTextStartWith(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    }

    _convertResultsToPromise(results: IPersonaProps[]): Promise<IPersonaProps[]> {
        return new Promise((resolve, reject) => setTimeout(() => resolve(results), 2000));
    }

    _removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
        return personas.filter((persona) => !this._listContainsPersona(persona, possibleDupes));
    }

    _validateInput = (input: string): ValidationState => {
        if (input.indexOf('@') !== -1) {
            return ValidationState.valid;
        } if (input.length > 1) {
            return ValidationState.warning;
        }
        return ValidationState.invalid;
    };

    /**
    * Takes in the picker input and modifies it in whichever way
    * the caller wants, i.e. parsing entries copied from Outlook (sample
    * input: "Aaron Reid <aaron>").
    *
    * @param input The text entered into the picker.
    */
    _onInputChange(input: string): string {
        const outlookRegEx = /<.*>/g;
        const emailAddress = outlookRegEx.exec(input);

        if (emailAddress && emailAddress[0]) {
            return emailAddress[0].substring(1, emailAddress[0].length - 1);
        }

        return input;
    }

    convertUser(user) {
        return [];
    }
}
