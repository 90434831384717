import React, { Component } from "react";
import { Stack, CommandBar, ContextualMenuItemType, Icon, FontSizes } from "office-ui-fabric-react";
import '../ConversationSafe.css'
import { MyContext } from "../../../context";
import DownloadDialog from '../../DetailView/DetailPivot/PivotElements/Transscript/DownloadDialog'


export class ConversationSafeAccordionHeader extends Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = { folder: this.props.folder }
    }

    folderName = (name, conversationSize) => {
        var result = name.length > 25 ? `${name.substring(0, 25)} ...` : name
        result += ` (${conversationSize})`
        return result
    }

    folderSupervisors = (folder) => {
        if (this.props.userRules.ruleAccess.userSafe === "Full" || (folder.userId === this.props.userRules.userId && this.props.userRules.ruleAccess.ownFolder.shareFolders)) {
            if (!folder.supervisors.length) return '-'
            var supervisors = folder.supervisors.map(supervisor => {
                return supervisor.mail ? supervisor.mail : supervisor.name
            })
                .join(", ")
            return supervisors.length > 70 ? supervisors.substring(0, 67) + "..." : supervisors
        } else {
            return ""
        }
    }

    menuIsNeeded = () => {
        return this.props.folder.id !== null
    }

    getAttention = () => {
        if (!this.state.folder.supervisors.length) return ''
        let attention = this.state.folder.attentionRequired !== ""
        return attention ? (<Icon style={{ padding: "0px", fontSize: FontSizes.icon, color: "red" }} iconName='Important' title="Attention!" />) : ""
    }

    render() {

        const menuItems = [
            {
                key: 'Actions',
                itemType: ContextualMenuItemType.Header,
                text: this.context.getTranslation("dashboard", "widgetMenuActions"),
                itemProps: {
                    lang: 'en-us',
                },
            },
            {
                key: 'Edit',
                iconProps: {
                    iconName: 'Edit',
                },
                text: this.context.getTranslation("conversationSafe", "folderSettings"),
                disabled: false,
                onClick: () => {
                    this.props.openPanel(this.props.folder.id)
                },
            },
            {
                key: 'Delete',
                hidden: !this.props.rights.userIsCreator && this.props.userRules.ruleAccess.userSafe !== "Full",
                iconProps: {
                    iconName: 'Delete',
                },
                text: this.context.getTranslation("conversationSafe", "deleteFolder"),
                disabled: !this.props.rights.deleteFolder,
                onClick: () => {
                    this.props.openDeleteFolderDialog(this.props.folder.id)
                },
            },
            {
                key: 'Export',
                onRender: () => (
                    <DownloadDialog
                        client={this.props.propsForExportDialog.client}
                        authorization={this.props.propsForExportDialog.auth}
                        export={this.state.folder.conversations.map(e => e.conversationId)}
                        folderId={this.props.folder.id}
                        folderName={this.props.folder.name}
                        renderFromCompliance={true}
                        disableForMenuItemsCompliance={!this.props.rights.exportFolder}
                    />
                )
            }
        ]
        return (
            <>
                <div className="flex" onClick={(e) => e.stopPropagation()}>
                    <div className="accordionHeaderColumns">
                        <div style={{ fontSize: "16px", fontWeight: "600" }}>{this.folderName(this.state.folder.name, this.state.folder.conversations.length)}</div>
                        <div>{this.state.folder.ownerName}</div>
                        <div>{this.folderSupervisors(this.state.folder)}</div>
                        <div style={{ maxWidth: "30px" }} >{this.getAttention()}</div>
                    </div>
                    {(this.menuIsNeeded()) &&
                        <Stack horizontal>
                            <Stack.Item >
                                <CommandBar
                                    className='commandBar'
                                    overflowItems={menuItems}
                                    styles={{
                                        root: {
                                            paddingRight: '0px'
                                        }
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                    }
                </div>
            </>
        );
    }
}