import React, { Component } from 'react';

import { DatePicker, FontWeights, Separator } from 'office-ui-fabric-react/lib';
import { MyContext } from '../../../context';
import { queryGetConfigData, mutationMarkDeleteImportRule, getProcessingRegions } from "../../../queries/querySettings";
import { toast } from 'react-toastify';
import Utils from '../../common/Utils';
import DateTimePicker from 'react-datetime-picker';
import UserPicker from '../../ReportView/ReportConfiguration/UserPicker';
import { Query } from "@apollo/react-components";


import gql from 'graphql-tag';

import {
    DefaultButton,
    PrimaryButton,
    Stack,
    TextField,
    ComboBox, Checkbox, Spinner, Text, Label, SpinButton, ChoiceGroup
    , Toggle
} from "office-ui-fabric-react";


class ImportConfig extends React.Component {
    constructor(props) {
        super(props);


        //I added this part because for new jobs or existing recent jobs are fine with new Date() and new date(jobTime)
        //but we have old jobs that have as value '0001-01-01T00:00:00' and it's not working because it's not recognized as a valid DateTime because it reads as 0000-12-31T22:15:36.000Z
        //so in case of an old wrong value I will change it in 0001-01-01T00:00:00Z because the Z adds an extra 1h:44min and is able to read the date correct
        let jobTime = new Date();
        if (this.props.data != null && this.props.data.jobType != null) {
            if (this.props.data.jobType.jobStartTime === '0001-01-01T00:00:00') {
                jobTime = new Date('0001-01-01T00:00:00Z');
            } else if (this.props.data.jobType.jobStartTime === '') {
                jobTime = new Date();
            } else {
                jobTime = new Date(this.props.data.jobType.jobStartTime)
            }
        }
        let callStartTime = new Date();
        if (this.props.data != null && this.props.data.callStartTime != null) {
            if (this.props.data.callStartTime === '0001-01-01T00:00:00') {
                callStartTime = new Date('0001-01-01T00:00:00Z');
            } else if (this.props.data.callStartTime === '') {
                callStartTime = new Date();
            } else {
                callStartTime = new Date(this.props.data.callStartTime)
            }
        }
        let callEndTime = new Date();
        if (this.props.data != null && this.props.data.callEndTime != null) {
            if (this.props.data.callEndTime === '0001-01-01T00:00:00') {
                callEndTime = new Date('0001-01-01T00:00:00Z');
            } else if (this.props.data.callEndTime === '') {
                callEndTime = new Date();
            } else {
                callEndTime = new Date(this.props.data.callEndTime)
            }
        }


        let importUserGroupUsers = [];
        let importUserGroupIds = (this.props.data == null || this.props.data.userIds === "undefined" || this.props.data.userIds == null || this.props.data.userIds == "") ? "" : this.props.data.userIds;
        for (let x = 0; x < importUserGroupIds.length; x++) {
            importUserGroupUsers.push({
                userId: importUserGroupIds[x],
                key: importUserGroupIds[x]
            }
            );
        }

        this.state = {
            name: this.props.data != null ? this.props.data.name : "Import",
            id: this.props.isNew ? Utils.guidGenerator() : this.props.data.id,
            jobStartTime: jobTime,
            intervalType: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalType : "OneTime",
            intervalMonth: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalMonth : 0,
            intervalDay: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalDay : 0,
            intervalHour: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalHour : 0,
            intervalMinute: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalMinute : 0,
            importType: (this.props.data != null && this.props.data.importType != null) ? this.props.data.importType : "Neo",
            mappingType: (this.props.data != null && this.props.data.mappingType != null) ? this.props.data.mappingType : "UsersToUsers",
            mappingUserId: (this.props.data == null || this.props.data.mappingUserId == null) ? [] : [{
                userId: this.props.data.mappingUserId
            }],
            sourceTtl: this.props.isNew ? -1 : this.props.data.sourceTtl,
            sourceTtlEnabled: this.props.isNew ? false : this.props.data.sourceTtl != -1,
            storageConnectionString: this.props.isNew ? "" : this.props.data.storageConnectionString,
            storageContainerName: this.props.isNew ? "ascimport" : this.props.data.storageContainerName,
            storageRootDirectory: this.props.isNew ? "" : this.props.data.storageRootDirectory,
            targetTtl: this.props.isNew ? "730" : this.props.data.targetTtl,
            enabled: this.props.isNew ? "true" : this.props.data.enabled,
            processingRegion: this.props.isNew ? "EU" : this.props.data.processingRegion,
            sftpPrivateKeyUpload: (this.props.data != null && this.props.data.sftpPrivateKeyUpload != null) ? this.props.data.sftpPrivateKeyUpload : "",
            hasSftpKey: (this.props.data !== null && this.props.data.hasSftpKey !== null) ? this.props.data.hasSftpKey : false,
            jobDeletionActive: false,
            callStartTime: callStartTime,
            callEndTime: callEndTime,
            userIds: importUserGroupUsers

        };
    }
    loadFile = async (file) => {
        let text = await file.text();
        this.setState({ sftpPrivateKeyUpload: text });
    }

    onFileChange = event => {

        let activeFile = event.target.files[0];

        if (activeFile) {
            this.setState({ hasSftpKey: true });

        }

        if (event.target.files[0]) {

            this.loadFile(event.target.files[0]);
        }

    };
    render() {


        return (
            <MyContext.Consumer>
                {(context) => (

                    <Stack vertical>
                        {/* name */}
                        <TextField type={"text"}
                            label={context.getTranslation("configuration", "jobName")}
                            value={this.state.name}
                            onChange={(event, newValue) => {
                                this.setState({ name: newValue });
                            }}
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                        />

                        <Toggle
                            defaultChecked={this.state.enabled}
                            label={context.getTranslation("settings", "enabled")}
                            onText={context.getTranslation("settings", "on")}
                            offText={context.getTranslation("settings", "off")}
                            onChange={(ev, checked) => {
                                this.setState({
                                    enabled: checked,
                                    updated: true
                                });
                            }}
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                        />

                        <Label alignContent="start">{context.getTranslation("configuration", "interval")}</Label>

                        <ChoiceGroup
                            selectedKey={this.state.intervalType}
                            style={{ width: "200px" }}
                            options={[
                                {
                                    key: 'OneTime',
                                    text: context.getTranslation("configuration", "single"),
                                    'data-automation-id': 'auto1',
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                },
                                {
                                    key: 'Periodical',
                                    text: context.getTranslation("configuration", "periodical"),
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                }
                            ]}
                            onChange={(event, newValue) => {
                                this.setState({
                                    intervalType: newValue.key,
                                    updated: true
                                });
                            }}
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                        />

                        <Stack.Item>
                            <Label>{context.getTranslation("configuration", "startTime")}</Label>

                            <DateTimePicker
                                locale={this.props.authorization.locale}
                                calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}

                                firstDayOfWeek={1}
                                value={new Date(this.state.jobStartTime)}
                                onChange={(date) => {
                                    let parsedDate = new Date(date);
                                    if (parsedDate < new Date("0001-01-01T00:00:00Z")) {
                                        console.log("Date is <");
                                        parsedDate = new Date("0001-01-01T00:00:00Z")
                                    }
                                    else if (parsedDate > new Date("9999-12-31T23:59:59Z")) {
                                        console.log("Date is >");
                                        parsedDate = new Date("9999-12-31T23:59:59Z")
                                    }
                                    console.log(parsedDate);
                                    this.setState({
                                        jobStartTime: parsedDate
                                    });
                                }
                                }
                                minDate={new Date("0001-01-01T00:00:00Z")}
                                maxDate={new Date("9999-12-31T23:59:59Z")}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />

                        </Stack.Item>
                        {this.state.intervalType == "Periodical" &&
                            <Stack>
                                <Stack.Item>
                                    <Label>{context.getTranslation("configuration", "cycle")}</Label>

                                    <div style={{ diplay: "flex" }}>
                                        <TextField
                                            name="CycleTime"
                                            type="Number"
                                            min={0}
                                            label={context.getTranslation("configuration", "cycleMonth")}
                                            value={this.state.intervalMonth}
                                            onChange={(event, newValue) => {
                                                if (newValue < 0)
                                                    newValue = 0;
                                                this.setState({
                                                    intervalMonth: newValue
                                                })
                                            }}
                                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                        />

                                    </div>

                                    <div style={{ diplay: "flex" }}>
                                        <TextField
                                            name="CycleTime"
                                            type="Number"
                                            min={0}

                                            label={context.getTranslation("configuration", "cycleDay")}
                                            value={this.state.intervalDay}
                                            onChange={(event, newValue) => {
                                                if (newValue < 0)
                                                    newValue = 0;
                                                this.setState({
                                                    intervalDay: newValue
                                                })
                                            }}
                                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                        />

                                    </div>

                                    <div style={{ diplay: "flex" }}>
                                        <TextField
                                            name="CycleTime"
                                            type="Number"
                                            min={0}

                                            label={context.getTranslation("configuration", "cycleHour")}
                                            value={this.state.intervalHour}
                                            onChange={(event, newValue) => {
                                                if (newValue < 0)
                                                    newValue = 0;
                                                this.setState({
                                                    intervalHour: newValue,
                                                })
                                            }}
                                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                        />

                                    </div>

                                    <div style={{ diplay: "flex" }}>
                                        <TextField
                                            name="CycleTime"
                                            type="Number"
                                            min={0}
                                            step="30"
                                            onKeyPress={(e) => e.preventDefault()}
                                            label={context.getTranslation("configuration", "cycleMinute")}
                                            value={this.state.intervalMinute}
                                            onChange={(event, newValue) => {
                                                if (newValue < 0)
                                                    newValue = 0;
                                                this.setState({
                                                    intervalMinute: newValue
                                                })
                                            }}
                                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                        />

                                    </div>

                                </Stack.Item>
                            </Stack>
                        }
                        <br />
                        <hr />

                        <ComboBox
                            style={{ width: "300px" }}
                            placeholder="Select an option"
                            selectedKey={this.state.importType}
                            label={context.getTranslation("configuration", "importType")}
                            onChange={(ev, newVal) => {
                                this.setState({
                                    importType: newVal.key,
                                    updated: true
                                });
                            }}
                            options={[{
                                key: "Neo",
                                text: "EVOIPneo"
                            },
                            {
                                key: "RedBox",
                                text: "RedBox",
                            },
                            {
                                key: "ASCRI",
                                text: "ASC RI",
                            },
                            {
                                key: "Numonix",
                                text: "Numonix",

                            },
                            {
                                key: "Genesys",
                                text: "Genesys",
                            },
                            {
                                key: "Nice",
                                text: "Nice",
                            },
                            {
                                key: "API8x8",
                                text: "API 8x8",
                            },
                            {
                                key: "Verba",
                                text: "Verba",
                            },
                            {
                                key: "Kerv",
                                text: "Kerv",
                            },
                            {
                                key: "SmartNumbers",
                                text: "SmartNumbers",
                            },
                            {
                                key: "Dubber",
                                text: "Dubber",
                            }]
                            }
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                        />
                        {(this.state.importType === "Genesys" || this.state.importType === "Numonix" || this.state.importType === "Kerv" || this.state.importType === "SmartNumbers") && <Stack.Item style={{ marginTop: "10px" }}>
                            <div id="clickable-area">
                                <input type="file" onChange={this.onFileChange} id="file-btn" disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false} />
                                <div className="upload-button"><span>{this.state.hasSftpKey ? context.getTranslation("common", "updateFile") : context.getTranslation("common", "uploadFile")}</span>
                                </div><p id="clickable-area-text">{this.state.sftpPrivateKeyUpload
                                    ? <i className="ms-Icon ms-Icon--Accept" style={{ fontSize: "20px" }}></i>
                                    : context.getTranslation("common", "noFileSelected")} &nbsp;</p>
                            </div>
                        </Stack.Item>}

                        {this.state.importType === "Genesys" && <Stack.Item style={{ marginTop: "10px" }}>
                            <Label>{context.getTranslation("configuration", "importOnlyForUserGroup")}</Label>
                            <UserPicker
                                user={this.state.userIds}

                                fc={(user) => {
                                    this.setState({
                                        userIds: user
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                        </Stack.Item>}

                        {this.state.importType === "API8x8" && <Stack.Item>
                            <Label>{context.getTranslation("configuration", "callStartTime")}</Label>

                            <DateTimePicker
                                locale={this.props.authorization.locale}
                                calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}

                                firstDayOfWeek={1}
                                value={new Date(this.state.callStartTime)}
                                onChange={(date) => {
                                    let parsedDate = new Date(date);
                                    if (parsedDate < new Date("0001-01-01T00:00:00Z")) {
                                        parsedDate = new Date("0001-01-01T00:00:00Z")
                                    }
                                    else if (parsedDate > new Date("9999-12-31T23:59:59Z")) {
                                        parsedDate = new Date("9999-12-31T23:59:59Z")
                                    }
                                    this.setState({
                                        callStartTime: parsedDate
                                    });
                                }
                                }
                                minDate={new Date("0001-01-01T00:00:00Z")}
                                maxDate={new Date("9999-12-31T23:59:59Z")}
                            />

                            <Label>{context.getTranslation("configuration", "callEndTime")}</Label>

                            <DateTimePicker
                                locale={this.props.authorization.locale}
                                calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}

                                firstDayOfWeek={1}
                                value={new Date(this.state.callEndTime)}
                                onChange={(date) => {
                                    let parsedDate = new Date(date);
                                    if (parsedDate < new Date("0001-01-01T00:00:00Z")) {
                                        parsedDate = new Date("0001-01-01T00:00:00Z")
                                    }
                                    else if (parsedDate > new Date("9999-12-31T23:59:59Z")) {
                                        parsedDate = new Date("9999-12-31T23:59:59Z")
                                    }
                                    this.setState({
                                        callEndTime: parsedDate
                                    });
                                }
                                }
                                minDate={new Date("0001-01-01T00:00:00Z")}
                                maxDate={new Date("9999-12-31T23:59:59Z")}
                            />
                        </Stack.Item>}
                        <br />
                        <Label>{context.getTranslation("configuration", "storage")}</Label>

                        {/* import connection string */}
                        <Stack.Item>
                            <TextField label={context.getTranslation("configuration", "storageConnectionString")}
                                value={this.state.storageConnectionString}
                                onChange={(event, newValue) => {
                                    this.setState({ storageConnectionString: newValue });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                placeholder={this.storageConnectionStringPlaceHolder()}
                            />
                            <TextField label={context.getTranslation("configuration", "storageContainerName")}
                                value={this.state.storageContainerName}
                                onChange={(event, newValue) => {
                                    this.setState({ storageContainerName: newValue });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <TextField label={context.getTranslation("configuration", "storageRootDirectory")}
                                value={this.state.storageRootDirectory}
                                onChange={(event, newValue) => {
                                    this.setState({ storageRootDirectory: newValue });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                        </Stack.Item>
                        <br />
                        <hr />
                        {(this.state.importType !== "Genesys" && this.state.importType !== "Numonix" && this.state.importType !== "Kerv" && this.state.importType !== "SmartNumbers") && <>

                            <Label>{context.getTranslation("configuration", "retentionTimeSource")}</Label>
                            <Toggle
                                defaultChecked={this.state.sourceTtlEnabled}
                                onText={context.getTranslation("settings", "on")}
                                offText={context.getTranslation("settings", "off")}
                                onChange={(ev, checked) => {
                                    this.setState({
                                        sourceTtl: checked ? 1 : -1,
                                        sourceTtlEnabled: checked,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            {this.state.sourceTtlEnabled &&
                                < TextField
                                    value={this.state.sourceTtl}
                                    type="Number"
                                    label={context.getTranslation("configuration", "retentionTimeSourceDays")}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            sourceTtl: newValue,
                                        });
                                    }}
                                    disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                />}
                        </>}

                        {true && < TextField
                            value={this.state.targetTtl}
                            type="Number"
                            label={context.getTranslation("configuration", "retentionTimeTargetDays")}
                            onChange={(event, newValue) => {
                                this.setState({
                                    targetTtl: newValue,
                                });
                            }}
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                        />}
                        <br />
                        <hr />
                        <ChoiceGroup
                            selectedKey={this.state.mappingType}
                            style={{ width: "200px" }}
                            options={[
                                {
                                    key: 'UsersToUsers',
                                    text: context.getTranslation("configuration", "usertouser"),
                                    'data-automation-id': 'auto1',
                                    styles: { field: { fontWeight: FontWeights.regular } },
                                },
                                {
                                    key: 'UsersToComplianceOfficer',
                                    text: context.getTranslation("configuration", "userstocomplianceofficer"),
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                }
                            ]}
                            onChange={(event, newValue) => {
                                this.setState({
                                    mappingType: newValue.key,
                                    updated: true
                                });
                            }}
                            label={context.getTranslation("configuration", "mappingType")}
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                        />
                        {this.state.mappingType == "UsersToComplianceOfficer" && <Stack.Item>
                            <UserPicker
                                user={this.state.mappingUserId}

                                fc={(user) => {
                                    this.setState({
                                        mappingUserId: user
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                        </Stack.Item>}
                        {(this.state.mappingType == "UsersToUsers" && (this.state.importType == "API8x8" || this.state.importType == "Verba" || this.state.importType == "Dubber" )) && <Stack.Item>
                            <Label>{context.getTranslation("configuration", "fallbackUser")}</Label>

                            <UserPicker
                                user={this.state.mappingUserId}

                                fc={(user) => {
                                    this.setState({
                                        mappingUserId: user
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                        </Stack.Item>}
                        <br />
                        <hr />

                        <Query
                            query={getProcessingRegions}
                            variables={this.props.authorization} >
                            {({ loading, error, data }) => {
                                if (loading) return <div>
                                    <Spinner label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "loadingRegions")}</>)}</MyContext.Consumer>} ariaLive="assertive" labelPosition="top" />
                                </div>;
                                if (error) return <h1> </h1>;

                                if (data.getProcessingRegions == null) {
                                    return <div></div>;
                                }

                                const licenseText = {
                                    EU: "EU",
                                    US: "USA",
                                    AS: "APAC",
                                    EUW: "EUW",
                                };
                                this.tmpKey = [];
                                const license = data.getProcessingRegions;
                                if (data.getProcessingRegions != null) {
                                    for (let x = 0; x < license.length; x++) {
                                        let i = {
                                            key: license[x],
                                            text: licenseText[license[x]] || license[x],
                                        };
                                        this.tmpKey.push(i);
                                    }
                                }


                                return <ComboBox
                                    style={{ width: "300px" }}
                                    placeholder="Select an option"
                                    label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "processingRegion")}</>)}</MyContext.Consumer>}
                                    options={this.tmpKey}
                                    selectedKey={this.state.processingRegion}
                                    onChange={(ev, newVal) => {
                                        this.setState({
                                            processingRegion: newVal.key,
                                            updated: true
                                        });
                                    }}
                                    disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                />
                            }
                            }

                        </Query>

                        <br />

                        <br />

                        <Stack.Item>
                            {(this.props.data !== null && this.props.data.isArchived)
                                ? <></>
                                : <PrimaryButton onClick={this.savePanel} text={context.getTranslation("common", "save")} disabled={!this.isValid()} />
                            }
                            <DefaultButton onClick={this.resetState} text={context.getTranslation("common", "cancel")} />
                            {(this.props.data !== null && !this.state.enabled && !this.props.data.isArchived) &&
                                <DefaultButton style={{ background: "red", color: "white", marginLeft: "5px" }} onClick={this.deleteImportJob} text={context.getTranslation("configuration", "deleteJob")} disabled={this.state.jobDeletionActive} />
                            }
                        </Stack.Item>
                    </Stack>
                )
                }
            </MyContext.Consumer>
        );
    }

    storageConnectionStringPlaceHolder = () => {
        var placeholderText = "";
        switch (this.state.importType) {
            case "Neo": break;
            case "RedBox": break;
            case "ASCRI": break;
            case "Numonix": break;
            case "Genesys": break;
            case "Nice": break;
            case "Dubber": break;
            case "API8x8":
                placeholderText = "e.g: clientid =; password =; region =;"
                break;
            default: break;
        }
        return placeholderText;
    }

    isValid = () => {
        if (
            (this.state.name != null && this.state.name.length > 0 && (this.state.mappingType != "UsersToComplianceOfficer" || this.state.mappingUserId.length > 0))
        ) {
            return true;

        }

        return false;
    }

    resetState = () => {
        if (this.props.isNew) {
            this.setState({
                isPanelOpen: false,
                isModalOpen: false,
                isChanged: false,
                name: ""
            });
        } else {
            this.setState({
                isPanelOpen: false,
                isModalOpen: false,
                isChanged: false
            });
        }
        this.props.closeModal()
    }
    deleteImportJob = async () => {
        let importUserGroupIds = [];
        for (let x = 0; x < this.state.userIds.length; x++) {
            importUserGroupIds.push(this.state.userIds[x].key);
        };
        let job =
        {
            id: this.state.id,
            name: this.state.name,
            importType: this.state.importType,
            mappingType: this.state.mappingType,
            mappingUserId: (this.state.mappingUserId != null && this.state.mappingUserId.length > 0) ? this.state.mappingUserId[0].userId : null,
            sourceTtl: this.state.sourceTtlEnabled ? parseInt(this.state.sourceTtl) : -1,
            targetTtl: parseInt(this.state.targetTtl),
            storageConnectionString: this.state.storageConnectionString,
            storageContainerName: this.state.storageContainerName,
            storageRootDirectory: this.state.storageRootDirectory,
            keyVaultConnectionString: this.state.keyVaultConnectionString,
            jobType: {
                intervalType: this.state.intervalType,
                jobStartTime: this.state.jobStartTime === '' ? null : this.state.jobStartTime,
                intervalMonth: parseInt(this.state.intervalMonth),
                intervalDay: parseInt(this.state.intervalDay),
                intervalHour: parseInt(this.state.intervalHour),
                intervalMinute: parseInt(this.state.intervalMinute)
            }
            , enabled: this.state.enabled ? true : false
            , processingRegion: this.state.processingRegion
            , sftpPrivateKeyUpload: this.state.sftpPrivateKeyUpload
            , callStartTime: this.state.callStartTime === '' ? null : this.state.callStartTime
            , callEndTime: this.state.callEndTime === '' ? null : this.state.callEndTime
            , userIds: importUserGroupIds
        };

        var data = await this.props.apol
            .mutate({
                mutation: mutationMarkDeleteImportRule,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    rule: job,
                }
            });

        if (data.data.markDeleteImportRule == null) {
            toast.info('Job delete not successful', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info('Job moved to archive', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }



        this.setState({ jobDeletionActive: false });
        this.props.closeModal();
    }
    savePanel = () => {
        // Save Changes
        console.log(this.state.mappingUserId);

        let importUserGroupIds = [];
        for (let x = 0; x < this.state.userIds.length; x++) {
            importUserGroupIds.push(this.state.userIds[x].key);
        };
        let job =
        {
            id: this.state.id,
            name: this.state.name,
            importType: this.state.importType,
            mappingType: this.state.mappingType,
            mappingUserId: (this.state.mappingUserId != null && this.state.mappingUserId.length > 0) ? this.state.mappingUserId[0].userId : null,
            sourceTtl: this.state.sourceTtlEnabled ? parseInt(this.state.sourceTtl) : -1,
            targetTtl: parseInt(this.state.targetTtl),
            storageConnectionString: this.state.storageConnectionString,
            storageContainerName: this.state.storageContainerName,
            storageRootDirectory: this.state.storageRootDirectory,
            keyVaultConnectionString: this.state.keyVaultConnectionString,
            jobType: {
                intervalType: this.state.intervalType,
                jobStartTime: this.state.jobStartTime === '' ? null : this.state.jobStartTime,
                intervalMonth: parseInt(this.state.intervalMonth),
                intervalDay: parseInt(this.state.intervalDay),
                intervalHour: parseInt(this.state.intervalHour),
                intervalMinute: parseInt(this.state.intervalMinute)
            }
            , enabled: this.state.enabled ? true : false
            , processingRegion: this.state.processingRegion
            , sftpPrivateKeyUpload: this.state.sftpPrivateKeyUpload
            , callStartTime: this.state.callStartTime === '' ? null : this.state.callStartTime
            , callEndTime: this.state.callEndTime === '' ? null : this.state.callEndTime
            , userIds: importUserGroupIds

        };
        if (!this.props.isNew) {

            this.props.apol
                .mutate({
                    mutation: gql`
                    mutation ent (
                        $input: AuthInput, $importJob: ImportRuleInputType) {
                        updateImportJob (
                            input: $input, 
                            importJob: $importJob
                        ) 
                        {
                            id
                        }
                    }
                `,
                    refetchQueries: [{
                        query: queryGetConfigData,
                        variables: { auth: this.props.authorization },
                    }],
                    variables: {
                        input: this.props.authorization,
                        importJob: job
                    },
                })
                .then((result) => {
                    if (result.data.updateImportJob !== null) {
                        toast.info('Import Job changed', {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.error('Import Job not changed', {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }

                });
        }
        else {
            this.props.apol
                .mutate({
                    mutation: gql`
                    mutation ent (
                        $input: AuthInput, $importJob: ImportRuleInputType) {
                        createImportJob (
                            input: $input, 
                            importJob: $importJob
                        ) 
                        {
                            id
                        }
                    }
                `,
                    refetchQueries: [{
                        query: queryGetConfigData,
                        variables: { auth: this.props.authorization },
                    }],
                    variables: {
                        input: this.props.authorization,
                        importJob: job
                    },
                })
                .then((result) => {
                    if (result.data.createImportJob !== null) {
                        toast.info('Import Job created', {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.error('Import Job not created', {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }

                });
        }
        this.props.closeModal();


    };
}



export default ImportConfig;