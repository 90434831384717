import React from 'react'
import { Query } from "react-apollo";
import gql from "graphql-tag";

import {
    Button,
    Stack,
    Panel,
    Text,
    PanelType,
    Spinner,
    TextField, ComboBox, Checkbox
} from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { queryGetNewsList, mutationCreateNewsEntry, mutationDeleteNewsEntry } from '../queries/querySettings';

export default class NewsPanel extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            title: "",
            text: "",
            sendMail: false,
            priority: "",
            isChanged: false
        }
    }

    styles = {
        root: {
            paddingTop: "5px"
        }
    };
    
    render(): React.ReactNode {
        return (
            <Panel
                isOpen={this.props.isOpen}
                type={PanelType.smallFixedFar}
                headerText="News"
                closeButtonAriaLabel="Close"
                onDismiss={this.props.cl}
            >
                {(this.props.userRules != null && this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.tenantCreate) && <Stack>
                    <Text variant="large">Create News Feed</Text>
                    <Stack.Item styles={this.styles}>
                        <TextField value={this.state.title} label={"Title"}
                            onChange={(event) => { this.handleChange(event.target.value, 0) }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <ComboBox
                            style={{ width: "300px" }}
                            placeholder="Select an option"
                            label="Priority"
                            options={[{
                                key: "Information",
                                text: "Information"
                            },
                            {
                                key: "Announcement",
                                text: "Announcement",
                            },
                            {
                                key: "Version",
                                text: "Version",
                                },
                                {
                                    key: "Maintenance",
                                    text: "Maintenance",
                                }]
                            }
                            selectedKey={this.state.priority}
                            onChange={(event, option, index, newValue) => { this.handleChange(option.key, 1) }}
                        />
                    </Stack.Item >
                    <Checkbox styles={this.styles}
                        label="mail to customer admins"
                        checked={this.state.sendMail}
                        onChange={(event, newValue) => { this.handleChange(newValue, 2) }
                        }
                    />
                    <Stack.Item styles={this.styles}>
                        <TextField value={this.state.text} label={"Text"}
                            onChange={(event) => { this.handleChange(event.target.value, 3) }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={this.styles}>
                        <Stack horizontal>
                            <Stack.Item>
                                <Button
                                    onClick={(event) => { this.createNewsEntry(this.props.apol);}}
                                    text={"Save"} disabled={!this.state.isChanged} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>}
                <Query
                    query={queryGetNewsList}
                    variables={{ id: null, auth: this.props.auth }}  >
                    {({ loading, error, data }) => {
                        if (loading) return <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} ><Spinner label="Load News" ariaLive="assertive" labelPosition="top" /></Stack>;
                        if (error) return <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} ></Stack>;
                        if (data.getNewsList == null) return <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} ><Text className="ContentColor" variant="mediumPlus">News</Text></Stack>
                        return data.getNewsList.map(({ id, date, time, title, text, priority }, i) =>
                            (
                                <div key={id}>
                                    <Text variant="large"> {title} </Text> <br />
                                    <Text> {priority}  </Text> <br />
                                    <Text> {date} {time}  </Text> <br />
                                    <div dangerouslySetInnerHTML={{ __html: text }}></div> <br />
                                    {(this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.tenantCreate) && <Button
                                        onClick={
                                            () => {
                                                this.props.apol.mutate({
                                                    mutation: mutationDeleteNewsEntry,
                                                    refetchQueries: [{
                                                        query: queryGetNewsList,
                                                        variables: { id: null, auth: this.props.auth },
                                                    }],
                                                    variables: { id: id }
                                                })
                                                    .then(result => console.log(result));
                                            }
                                        }
                                        text="Delete"
                                        allowDisabledFocus={true}
                                    />}
                                </div>
                            ))
                    }}
                </Query>

            </Panel>);
    }
    handleChange(text, id) {
        switch (id) {
            case 0: this.setState({ title: text }); break;
            case 1: this.setState({ priority: text }); break;
            case 2: this.setState({ sendMail: text }); break;
            case 3: this.setState({ text: text }); break;
            default: break;
        }
        // Decide to set isChanged
        if (
            ((id === 0 ? text : this.state.title) !== "")
            && ((id === 1 ? text : this.state.priority) !== "")
            && ((id === 0 ? text : this.state.text) !== "")
        )
        {
            this.setState({ isChanged: true });
        } else {
            this.setState({ isChanged: false });
        }
    }


    createNewsEntry = async (apol) => {
        // Add or change User
        var newsEntry = {
            title: this.state.title,
            text: this.state.text,
            priority: this.state.priority,
            sendMail: this.state.sendMail
        };
        var data = await apol
                .mutate({
                    mutation: mutationCreateNewsEntry,
                    refetchQueries: [{
                        query: queryGetNewsList,
                        variables: { id: null, auth: this.props.auth },
                    }],
                    variables: {
                        news: newsEntry
                    }
                });
            if (data.data.createNewsEntry) {
                toast.info('Create NewsEntry successful', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.info('Create News Entry not successful', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
        };
    };
}