import React, { Component } from 'react'
import { MyContext } from '../../../../context';
import {
    TextField,
    DefaultButton,
    PrimaryButton,
    Stack,
    DetailsList,
    SelectionMode,
    Label,
    ScrollablePane,
    ScrollbarVisibility,
    IconButton,
    mergeStyleSets,
    ActionButton,
    Selection,
    FontIcon,
    Pivot, PivotItem
} from 'office-ui-fabric-react';
import * as QueryAICat from "../AICategorizationQuery"
import AlertBar from '../../../CustomComponents/AlertBar';
import ASCDetailsList from "./ASCDetailsList";
import './EditAICategorization.css'
import RIAccordion from "../../../ConversationSafe/Accordion/RIAccordion";
import {MergePivotAccordionHeader} from "./MergePivot/MergePivotAccordionHeader";
import {StackItem} from "office-ui-fabric-react/lib";


export default class EditAICategorization extends Component {

    static contextType = MyContext
    _selection = new Selection({
        getKey:(item)=>item.id,
        selectionPreservedOnEmptyClick: true,
        onSelectionChanged: (item) => console.log(item,'all items selectable')
    })



    _selectionOnlyFavorite = new Selection({
        selectionPreservedOnEmptyClick: true,
        canSelectItem: (e, i) => !e.priority,
        onSelectionChanged: () => console.log('only favorites')
    })
    _selectionOnlyNonIgnored = new Selection({
        selectionPreservedOnEmptyClick: true,
        canSelectItem: (e, i) => !e.deleted,
        onSelectionChanged: () => console.log('only non ignored')
    })

    selection3 = new Selection()

    //Names of selection arrays
    selectionNames = {
        addToFavorite: "addToFavorite",
        removeFromFavorites: "removeFromFavorites",
        merge: "merge",
        ignoreCategorization: "ignoreCategorization",
        removeFromIgnored: "removeFromIgnored",
        unlink: "unlink",
    }

    //Names of the input text fields
    inputFormNames = {
        aiCategorizationName: "aiCategorizationName",
        newAICategorizationItem: "newAICategorizationItem",
        mergeAICategorizationName: "mergeAICategorizationName"
    }

    constructor(props) {
        super(props)

        this._selection.label = "all";
        this._selectionOnlyFavorite.label = "favoritesOnly"
        this._selectionOnlyNonIgnored.label = "notIgnoredOnly"

        const aiCat = this.props.data !== undefined ? this.props.data : null
        const aiCategorizationList = aiCat !== null ? aiCat.aiCategorizationList
            .sort((a, b) => { return (a === b) ? 0 : a ? -1 : 1; })
            .map(e => ({...e, key: e.id}))
            : []
        this.state = {
            selectionForDetailsList: this._selection,
            columns: [],
            id: aiCat !== null ? aiCat.id : null,
            userId: aiCat !== null ? aiCat.userId : "",
            tenantId: aiCat !== null ? aiCat.tenantId : "",
            aiCategorizationList: aiCategorizationList,
            //list of items to be unlinked from merged categories items
            itemsToBeUnlinked: {parent:[],child:[]},
            processing: false,
            selectionMode: SelectionMode.none,
            actionButtonPressed: "",
            form: {
                //The name of Categorization
                [this.inputFormNames.aiCategorizationName]: aiCat !== null ? aiCat.name : "",

                //Form to add new items by user
                //The name of new categorization item in the input field
                [this.inputFormNames.newAICategorizationItem]: "",
                //List of new items that user want to add
                aICategorizationItems: [],
                //End

                //The name of merged ai categorization
                [this.inputFormNames.mergeAICategorizationName]: ""
            },
            selections: {
                [this.selectionNames.addToFavorite]: [],
                [this.selectionNames.removeFromFavorites]: [],
                [this.selectionNames.removeFromIgnored]: [],
                [this.selectionNames.merge]: [],
                [this.selectionNames.ignoreCategorization]: [],
                [this.selectionNames.unlink]: {
                    parent:[],
                    child: []
                }
            },
            error: ""
        };

        this.flattenedLinkedItems = new Set(aiCategorizationList.map(e=>e.linkedCategorization).filter(e=>e!=null).flat())
        console.log("this.flattenedLinkedItems", this.flattenedLinkedItems)
        this.mergedParentsWithChildren = aiCategorizationList.map((e, i, array) => {
            // let onlyParents = array.filter(e => (e.linkedCategorization?.length ?? 0) > 0)
            if( (e.linkedCategorization?.length ?? 0) > 0){
                return ({
                    name: e.name,
                    id: e.id,
                    count: 0,
                    children: [...array.filter((j,i,arr) => e.linkedCategorization?.includes(j.id)) ?? false]
                })
            }
            else return null
        }).filter(e => e!=null)
        console.log("this.mergedParentsWithChildren", this.mergedParentsWithChildren)
    }

    componentDidMount() {
        this.setState({
            columns: [
                {
                    key: 'priority',
                    name: "#",
                    fieldName: 'priority',
                    maxWidth: 50,
                    isResizable: true,
                    onRender: (item, i) => {
                        return ++i
                    },
                },
                {
                    key: 'name',
                    name: this.context.getTranslation("compliance", "name"),
                    isCollapsible:true,
                    fieldName: 'name',
                    minWidth: 100,
                    isSortable: true,
                    onColumnClick: (ev, column)=>{
                        let isSortedDescending = column.isSortedDescending;

                        // If we've sorted this column, flip it.
                        if (column.isSorted) {
                            isSortedDescending = !isSortedDescending;
                        }
                        const itemsSortByName = [...this.state.aiCategorizationList].sort((a,b)=>{
                            return a.name.localeCompare(b.name)
                        })
                        if (!isSortedDescending) itemsSortByName.reverse()
                        this.setState({
                            aiCategorizationList: itemsSortByName,
                            columns: this.state.columns.map(col => {
                                col.isSorted = col.key === column.key;

                                if (col.isSorted) {
                                    col.isSortedDescending = isSortedDescending;
                                }

                                return col;
                            })

                        })
                    },
                    maxWidth: 200,
                    isResizable: true,
                    onRender: (item) => {
                        return ( <Stack horizontal
                                        tokens={{ childrenGap: 10 }}
                                        style={{alignItems: "center"}}>

                                <span>{item.name}&ensp;&emsp;</span>
                                {this.renderIcons(item)}


                        </Stack>)
                    },
                },
                {
                    key: 'count', name: this.context.getTranslation("dashboard", "unitcount"),
                    fieldName: 'count',
                    minWidth: 100,
                    maxWidth: 200,
                    isSortable: true,
                    isResizable: true,
                    onColumnClick: (ev, column)=>{
                        let isSortedDescending = column.isSortedDescending;

                        // If we've sorted this column, flip it.
                        if (column.isSorted) {
                            isSortedDescending = !isSortedDescending;
                        }
                        const itemsSortByCount = [...this.state.aiCategorizationList].sort((a,b)=>{
                            return (a.conversationIds?.length ?? 0) - (b.conversationIds?.length ?? 0)
                        })
                        if (isSortedDescending) itemsSortByCount.reverse()
                        this.setState({
                            aiCategorizationList: itemsSortByCount,
                            columns: this.state.columns.map(col => {
                                col.isSorted = col.key === column.key;

                                if (col.isSorted) {
                                    col.isSortedDescending = isSortedDescending;
                                }

                                return col;
                            })

                        })
                    },
                    onRender: (item) => {
                        return item.conversationIds.length
                    }
                }
            ]
        })
    }

    renderIcons = (item) => {
        const iconStyle = {
            display: 'block',
            textAlign: 'center',
            paddingLeft: 5
        }
        const newFavoriteLabel = this.context.getTranslation("compliance", "newFavoriteLabel")
        const unfavoriteLabel = this.context.getTranslation("compliance", "unfavoriteLabel")
        const newMergeLabel = this.context.getTranslation("compliance", "newMergeLabel")
        const mergedItemLabel = this.context.getTranslation("compliance", "mergedItemLabel")
        const favoriteLabel = this.context.getTranslation("compliance", "favoriteLabel")

        return (
            <div style={{marginRight: 40, display:"flex", flexDirection:"row"}}>
                {item.priority
                    && !this.state.selections.removeFromFavorites.includes(item.id)
                    && (
                    <Stack.Item>
                        <span style={iconStyle}>{<FontIcon iconName="FavoriteStarFill" style={{ fontSize: "14px" }} title={favoriteLabel} />}</span>
                    </Stack.Item>
                )}
                {this.state.selections.removeFromIgnored.includes(item.id)  && (
                    <Stack.Item>
                        <span style={iconStyle}>{<FontIcon iconName="DocumentReply" style={{ fontSize: "14px" }} title={newFavoriteLabel} />}</span>
                    </Stack.Item>
                )}
                {this.state.selections.addToFavorite.includes(item.id)  && (
                    <Stack.Item>
                        <span style={iconStyle}>{<FontIcon iconName="AddFavorite" style={{ fontSize: "14px" }} title={newFavoriteLabel} />}</span>
                    </Stack.Item>
                )}
                { this.state.selections.removeFromFavorites.includes(item.id)  && (
                    <Stack.Item>
                        <span style={iconStyle}>{<FontIcon iconName="Unfavorite" style={{ fontSize: "14px" }} title={newFavoriteLabel} />}</span>
                    </Stack.Item>
                )}
                {this.state.selections.ignoreCategorization.includes(item.id) && (
                    <Stack.Item>
                        <span style={iconStyle}>{<FontIcon iconName="Blocked" style={{ fontSize: "14px" }} title={unfavoriteLabel} />}</span>
                    </Stack.Item>
                )}
                {this.state.selections.merge.includes(item.id) && (
                    <Stack.Item>
                        <span style={iconStyle}>{<FontIcon iconName="MergeDuplicate" style={{ fontSize: "14px" }} title={newMergeLabel} />}</span>
                    </Stack.Item>
                )}
                {item.linkedCategorization && item.linkedCategorization.length > 0 && (
                    <Stack.Item>
                        <span style={iconStyle}>{<FontIcon iconName="Merge" style={{ fontSize: "14px" }} title={mergedItemLabel} />}</span>
                    </Stack.Item>
                )}

            </div>
        )
    }

    renderAddAICategorization = () => {
        return (
            <div>
                <Stack horizontal style={{ marginTop: "10px" }}>
                    <span style={{ marginTop: "10px", marginRight: "5px" }}>{`${this.context.getTranslation("compliance", "addNewAICategorization")} (${this.state.form.aICategorizationItems.length})`}</span>
                </Stack>
                <Stack horizontal style={{ marginTop: "10px" }} >
                    <Stack.Item grow={1}>
                        <TextField value={this.state.form.newAICategorizationItem} onChange={(e, value) => this.changeName(e, value, this.inputFormNames.newAICategorizationItem)} />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <PrimaryButton style={{ marginLeft: "20px" }} text={this.context.getTranslation("compliance", "add")} onClick={this.addAICategorization} allowDisabledFocus disabled={this.props.disabled} />
                    </Stack.Item>
                </Stack>
                {this.state.error != "" &&
                    <Stack horizontal style={{ marginLeft: "30px" }}>
                        <Stack.Item align="center">
                            <span style={{ color: "red", fontSize: "10px" }}>{this.state.error}</span>
                        </Stack.Item>
                    </Stack>
                }
                <div style={{ maxHeight: '20vh', position: 'relative', overflow: 'auto' }}>
                    {this.state.form.aICategorizationItems.map(w => {
                        return (<Stack key={w} horizontal style={{ marginTop: "5px", marginLeft: "30px" }}>
                            <Stack.Item align="center"> <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => { this.deleteAICategorizationItem(w) }} disabled={this.props.disabled} /></Stack.Item>
                            <Stack.Item align="center"><span >{w}</span></Stack.Item>
                        </Stack>)
                    })}
                </div>
            </div>
        )
    }

    isValid = () => {
        return this.state.name !== "" && !this.anyActionPressed()
    }

    getAction = () => {
        const { addToFavorite, merge, ignoreCategorization, removeFromFavorites, removeFromIgnored } = this.state.selections
        let dic = {
            [this.selectionNames.addToFavorite]: addToFavorite,
            [this.selectionNames.removeFromFavorites]: removeFromFavorites,
            [this.selectionNames.removeFromIgnored]: removeFromIgnored,
            [this.selectionNames.merge]: merge,
            [this.selectionNames.ignoreCategorization]: ignoreCategorization
        }
        for (var k in dic) {
            if (dic[k].length > 0) {
                return {[k]: dic[k]}
            }
        }
    }

    //Fill the given aiCategorizationItemList with the new items created by the user
    prepareAICategorizationList = () => {
        let result = this.state.aiCategorizationList.map(e=>{delete e.key;return e});
        result.forEach(x=> delete x.__typename)
        if (this.state.form.aICategorizationItems.length > 0) {
            this.state.form.aICategorizationItems.forEach(el => {
                const newItem = {
                    "id": null,
                    "name": el,
                    "priority": true,
                    "conversationIds": [],
                    "linkedCategorization": []

                }
                result.push(newItem)
            })
        }
        console.log(result)
        return result;
    }

    save = () => {
        this.setState({ processing: true })
        const auth = this.props.auth
        var res = this.getAction();
        var act = res != undefined ? Object.keys(res)[0] : (this.state.selections.unlink.child ? "unlink" : null)
        var actList = res != undefined ? Object.values(res)[0] : []
        const aiCategorization = {
            id: this.state.id,
            name: this.state.form.aiCategorizationName,
            aICategorizationItemMergedName : this.state.form.mergeAICategorizationName,
            userId: this.state.userId,
            tenantId: this.state.tenantId,
            aiCategorizationList: this.prepareAICategorizationList(),
            unlinkChildrenList: this.state.selections.unlink.child,
            unlinkParentList: this.state.selections.unlink.parent,
            action: act,
            actionList: actList
        }
        this.props.apol
            .mutate({
                mutation: QueryAICat.upsertAICategorization,
                variables: { auth, aiCategorization }
            })
            .then((result) => {
                var state = {}
                if (result.data.upsertAICategorization.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshAICat()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.upsertAICategorization.text))
                }
                this.setState({ ...state, processing: false })
            });
    }

    onColumnClick = (e, column) => {
        const { columns, aiCategorizationList } = this.state;
        const newColumns = columns.slice();
        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        let key = currColumn.fieldName
        let newItems = []
        switch (key) {
            case "count": {
                newItems = aiCategorizationList.slice(0).sort(
                    (a, b) => ((currColumn.isSortedDescending ? a.conversationIds.length < b.conversationIds.length : a.conversationIds.length > b.conversationIds.length) ? 1 : -1));
                break;
            }
            default: {
                newItems = aiCategorizationList.slice(0).sort((a, b) => ((currColumn.isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
                break;
            }
        }
        this.setState({
            columns: newColumns,
            aiCategorizationList: newItems,
        });
    }

    performAction = (act, selection = this._selection) => {
        this.setState(prevState => {
            return {
                ...prevState,
                selectionForDetailsList: selection,
                actionButtonPressed: act,
                selectionMode: SelectionMode.multi,
            }
        })
    }


    anyActionPressed = () => {
        return this.state.actionButtonPressed.length > 0
    }


    storeArrays = (selection) => {
        let name = this.state.actionButtonPressed
        let resultingArray = selection.getSelection().length > 0 ? selection.getSelection().map(x => x.id) : []
        this.setState(prevState => {
            return {
                ...prevState,
                selections: { ...prevState.selections, [name]: resultingArray },
                actionButtonPressed: "",
                selectionMode: SelectionMode.none
            }
        })
        this._selection.setAllSelected(false);
    }

    editSelection(newSelection){
        this.selection3 = newSelection
    }
    getSelection(){
        return this.selection3
    }

    render() {
        var classNames = mergeStyleSets({
            wrapper: {
                height: this.props.height,
                position: 'relative',
                minHeight: '400px'
            },
            pane: {
            }
        });

        return (
            <div>
                <TextField
                    label={this.context.getTranslation("compliance", "name")}
                    value={this.state.form.aiCategorizationName} onChange={(e, value) => this.changeName(e, value, this.inputFormNames.aiCategorizationName)}
                           required />
                {this.state.id !== null &&
                    <div>
                        {this.renderAddAICategorization()}
                    </div>

                }
                {this.state.id !== null &&
                    <div>
                        {this.state.aiCategorizationList.length <= 0 &&
                            <AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />
                        }

                        {this.state.aiCategorizationList.length > 0 &&
                            <div>

                                <div className={classNames.wrapper}>

                                        <Pivot>
                                            <PivotItem
                                                headerText={this.context.getTranslation("compliance", "overview")}
                                                headerButtonProps={{
                                                    disabled: this.anyActionPressed(),
                                                    style: {color: this.anyActionPressed() ? "gray" : undefined}
                                                }}
                                                // headerButtonProps={{
                                                //     'data-order': 1,
                                                //     'data-title': 'My Files Title',
                                                // }}
                                            >
                                                {/*Buttons*/}
                                                {!this.anyActionPressed() && (
                                                    <div>
                                                        <ActionButton iconProps={{iconName: 'FavoriteStarFill'}}
                                                                      allowDisabledFocus
                                                                      disabled={this.isActionDisabled(this.selectionNames.addToFavorite)}
                                                                      checked={false}
                                                                      onClick={() => {
                                                                          console.log('tasto di add to favorites', this._selectionOnlyFavorite.label)
                                                                          this.performAction(this.selectionNames.addToFavorite, this._selectionOnlyFavorite)
                                                                      }}>
                                                            {this.context.getTranslation("compliance", "addToFavorite")}
                                                        </ActionButton>

                                                        <ActionButton iconProps={{iconName: 'StatusCircleBlock'}}
                                                                      allowDisabledFocus
                                                                      disabled={this.isActionDisabled(this.selectionNames.ignoreCategorization)}
                                                                      checked={false}
                                                                      onClick={() => this.performAction(this.selectionNames.ignoreCategorization, this._selectionOnlyNonIgnored)}>
                                                            {this.context.getTranslation("compliance", "ignoreCategorization")}
                                                        </ActionButton>

                                                        <ActionButton iconProps={{iconName: 'Merge'}} allowDisabledFocus
                                                                      disabled={this.isActionDisabled(this.selectionNames.merge)}
                                                                      checked={false}
                                                                      onClick={() => this.performAction(this.selectionNames.merge)}>
                                                            {this.context.getTranslation("compliance", "mergeCategorization")}
                                                        </ActionButton>
                                                    </div>)}
                                                {this.anyActionPressed() && (
                                                    <Stack horizontal style={{marginTop: 8}}>
                                                        {this.state.actionButtonPressed == this.selectionNames.merge && (
                                                            <Stack.Item>
                                                                <TextField
                                                                          placeholder={this.context.getTranslation("compliance", "Choose a name")}
                                                                    value={this.state.form.mergeAICategorizationName}
                                                                    onChange={(e, value) => (
                                                                        this.changeName(e, value, this.inputFormNames.mergeAICategorizationName, this._selection)
                                                                    )}
                                                                />
                                                            </Stack.Item>)}
                                                        <Stack.Item>
                                                            <ActionButton iconProps={{iconName: 'SaveAs'}}
                                                                          allowDisabledFocus
                                                                          disabled={ this.state.actionButtonPressed == "merge" && this.state.form.mergeAICategorizationName === ""}
                                                                          checked={false} onClick={this.storeArrays.bind(this, this.state.selectionForDetailsList)}>
                                                               {this.context.getTranslation("compliance", "confirmAction")}
                                                            </ActionButton>
                                                        </Stack.Item>
                                                    </Stack>)}
                                                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}
                                                                    style={{root: classNames.pane, top:84}}>
                                                        <ASCDetailsList
                                                            setKey={e=>e.id}
                                                            editSelection={this.editSelection}
                                                            items={this.state.aiCategorizationList.filter(e => !e.deleted)
                                                                // .sort((a,b)=>b.conversationIds.length-a.conversationIds.length)
                                                        }
                                                            columns={this.state.columns}
                                                            // setFatherSelection={(selection)=>this.setState({selectionForDetailsList:selection})}
                                                            selectionMode={this.state.selectionMode}
                                                            selectionAfterMergeContainerIsRenamed={this.state.selectionAfterMergeContainerIsRenamed}
                                                            selectionPreservedOnEmptyClick
                                                            selection={this.state.selectionForDetailsList}
                                                        ></ASCDetailsList>
                                                    </ScrollablePane>


                                                {/*{this.state.selectionForDetailsList.label == "favoritesOnly" ?*/}
                                                {/*    <DetailsList*/}
                                                {/*    listaCheMiInteressa*/}
                                                {/*    items={this.state.aiCategorizationList.filter(e => !e.deleted)}*/}
                                                {/*    columns={this.state.columns}*/}
                                                {/*    selectionMode={this.state.selectionMode}*/}
                                                {/*    selection={this._selectionOnlyFavorite}*/}
                                                {/*/> : null}*/}
                                                {/*    {this.state.selectionForDetailsList.label == "all" ?*/}
                                                {/*    <DetailsList*/}
                                                {/*    tutti*/}
                                                {/*    items={this.state.aiCategorizationList.filter(e => !e.deleted)}*/}
                                                {/*    columns={this.state.columns}*/}
                                                {/*    selectionMode={this.state.selectionMode}*/}
                                                {/*    selection={this._selection}*/}
                                                {/*/> : null*/}
                                                {/*}*/}
                                            </PivotItem>
                                            {/*FAVORITES*/}
                                            <PivotItem
                                                headerButtonProps={{
                                                    disabled: this.anyActionPressed(),
                                                    style: {color: this.anyActionPressed() ? "gray" : undefined}}}
                                                    headerText={this.context.getTranslation("compliance", "favorites")}>
                                                {!this.anyActionPressed() && (
                                                    <div>
                                                        <ActionButton iconProps={{iconName: 'FavoriteStarFill'}}
                                                                      allowDisabledFocus
                                                                      disabled={this.isActionDisabled(this.selectionNames.removeFromFavorites)}
                                                                      checked={false}
                                                                      onClick={() => this.performAction(this.selectionNames.removeFromFavorites)}>
                                                            {this.context.getTranslation("compliance", "removeFromFavorites")}
                                                        </ActionButton>
                                                    </div>)}
                                                {this.anyActionPressed() && (
                                                    <Stack horizontal>
                                                        <Stack.Item>
                                                            <ActionButton iconProps={{iconName: 'SaveAs'}}
                                                                          allowDisabledFocus disabled={false}
                                                                          checked={false} onClick={this.storeArrays.bind(this, this._selection)}>
                                                                {this.context.getTranslation("compliance", "confirmAction")}
                                                            </ActionButton>
                                                        </Stack.Item>
                                                    </Stack>)}
                                                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}
                                                                style={{root: classNames.pane, top:84}}>
                                                    <DetailsList
                                                        items={this.state.aiCategorizationList.filter(e => e.priority)}
                                                        columns={this.state.columns}
                                                        selectionMode={this.state.selectionMode}
                                                        selection={this._selection}/>
                                                </ScrollablePane>
                                            </PivotItem>
                                            {/*IGNORED PIVOT  ↓↓↓↓↓↓ */}
                                            <PivotItem
                                                headerButtonProps={{
                                                    disabled: this.anyActionPressed(),
                                                    style: {color: this.anyActionPressed() ? "gray" : undefined}
                                                }}
                                                headerText={this.context.getTranslation("compliance", "ignored")}>
                                                {!this.anyActionPressed() && (
                                                    <div>
                                                        <ActionButton iconProps={{iconName: 'DocumentReply'}}
                                                                      allowDisabledFocus
                                                                      disabled={this.isActionDisabled(this.selectionNames.removeFromIgnored)}
                                                                      checked={false}
                                                                      onClick={() => this.performAction(this.selectionNames.removeFromIgnored)}>
                                                            {this.context.getTranslation("compliance", "removeFromList")}
                                                        </ActionButton>
                                                    </div>)}
                                                {this.anyActionPressed() && (
                                                    <Stack horizontal>
                                                        <Stack.Item>
                                                            <ActionButton iconProps={{iconName: 'SaveAs'}}
                                                                          allowDisabledFocus disabled={false}
                                                                          checked={false} onClick={this.storeArrays.bind(this, this._selection)}>
                                                                {this.context.getTranslation("compliance", "confirmAction")}
                                                            </ActionButton>
                                                        </Stack.Item>
                                                    </Stack>)}
                                                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}
                                                                style={{root: classNames.pane, top:84}}>
                                                    <DetailsList
                                                        items={this.state.aiCategorizationList.filter((e,i,array) => {
                                                            return  e.deleted && ![...this.flattenedLinkedItems].includes(e.id)
                                                        })}
                                                        columns={this.state.columns}
                                                        selectionMode={this.state.selectionMode}
                                                        selection={this._selection}/>
                                                </ScrollablePane>
                                            </PivotItem>
                                            {/*FAVORITES PIVOT  ↓↓↓↓↓↓ */}
                                            <PivotItem
                                                headerButtonProps={{
                                                    disabled: this.anyActionPressed(),
                                                    style: {color: this.anyActionPressed() ? "gray" : undefined}
                                                }}
                                                headerText={this.context.getTranslation("compliance", "merged")}>
                                                    <div>
                                                        {this.anyActionPressed() && (
                                                            <Stack horizontal>
                                                                <Stack.Item>
                                                                    <ActionButton iconProps={{iconName: 'SaveAs'}}
                                                                                  allowDisabledFocus disabled={false}
                                                                                  checked={false}
                                                                                  onClick={()=>{
                                                                                      this.setState(prevState=>{
                                                                                          return {
                                                                                              ...prevState,
                                                                                              selections: { ...prevState.selections, unlink: this.state.itemsToBeUnlinked },
                                                                                              actionButtonPressed: "",

                                                                                          }
                                                                                      })
                                                                                  }}>
                                                                        {this.context.getTranslation("compliance", "confirmAction")}
                                                                    </ActionButton>
                                                                </Stack.Item>
                                                            </Stack>)}

                                                        <RIAccordion
                                                        data={this.mergedParentsWithChildren
                                                            .map(e => ({
                                                                header: (<MergePivotAccordionHeader
                                                                    id={e.id}
                                                                    childrenItems={e.children.map(c=>c.id)}
                                                                    name={e.name}
                                                                    updateParentComponentState={(unlinkParent, unlinkChildren)=>{
                                                                        this.setState({
                                                                            actionButtonPressed: this.selectionNames.unlink,
                                                                            itemsToBeUnlinked:{
                                                                                parent: [ ...new Set([...this.state.itemsToBeUnlinked.parent, unlinkParent])],
                                                                                child: [...new Set([...this.state.itemsToBeUnlinked.child, ...unlinkChildren])]
                                                                            }
                                                                        })

                                                                    }}
                                                                />),
                                                                content: (<div style={{
                                                                    display:"flex",
                                                                    flexDirection:"column"
                                                                    // justifyContent: "space-between"
                                                                }}>{
                                                                    e.children.map( (child, i) => {
                                                                        return (<Stack horizontal key={i}style={{display:"flex",justifyContent:"space-between", paddingLeft: 27, paddingRight:4}}>
                                                                            <StackItem align="center">{child.name} ({child.conversationIds.length})</StackItem>
                                                                            <Stack.Item align="center">
                                                                                <IconButton
                                                                                    iconProps={{ iconName: 'Delete' }}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            actionButtonPressed: this.selectionNames.unlink,
                                                                                            itemsToBeUnlinked:{
                                                                                                parent: [ ...new Set([...this.state.itemsToBeUnlinked.parent, e.id])],
                                                                                                child: [...this.state.itemsToBeUnlinked.child, child.id]
                                                                                            }
                                                                                        })
                                                                                        console.log("this will be unlinked") }}
                                                                                    disabled={this.state.itemsToBeUnlinked.child.includes(child.id)} />
                                                                                </Stack.Item>
                                                                        </Stack>)
                                                                    })
                                                                }
                                                                </div>)  }) )}>
                                                        </RIAccordion>
                                                    </div>
                                            </PivotItem>
                                        </Pivot>


                                </div>
                            </div>
                        }
                    </div>
                }


                {/*FOOTER BUTTONS*/}
                <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                    <DefaultButton
                        text={this.context.getTranslation("common", "cancel")}
                        onClick={() => { this.props.closePanel() }}
                        style={{ marginTop: "30px", marginLeft: "15px" }}
                        disabled={this.state.processing}
                    />

                    <PrimaryButton
                        text={this.context.getTranslation("common", "save")}
                        onClick={this.save}
                        style={{ marginTop: "30px", marginLeft: "15px" }}
                        disabled={!this.isValid() || this.state.processing} />

                </Stack>
            </div>)
    }

    addAICategorization = () => {
        let newAiCategorizationItemName = this.state.form.newAICategorizationItem
        if (newAiCategorizationItemName === "") {
            this.setState({
                error: this.context.getTranslation("compliance", "errorEmptyKeywords")
            })
        } else if (this.state.form.aICategorizationItems.includes(newAiCategorizationItemName)) {
            this.setState({
                error: this.context.getTranslation("compliance", "errorDuplicatedKeywords")
            })
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    form: { ...prevState.form, newAICategorizationItem: "", aICategorizationItems: [...prevState.form.aICategorizationItems, this.state.form.newAICategorizationItem] }
                }
            })
        }
    }

    isActionDisabled = (name) => {
        const { addToFavorite, removeFromFavorites, merge, ignoreCategorization, unlink } = this.state.selections
        let arr = []
        let dic = {
            [this.selectionNames.addToFavorite]: addToFavorite,
            [this.selectionNames.merge]: merge,
            [this.selectionNames.removeFromFavorites]: removeFromFavorites,
            [this.selectionNames.ignoreCategorization]: ignoreCategorization,
            [this.selectionNames.unlink]: unlink.child
        }
        for (var k in dic) {
            if (k != name)
                arr.push(...dic[k])
        }

        return arr.length > 0
    }


    deleteAICategorizationItem = (value) => {
        this.setState(prevState => {
            return {
                ...prevState,
                error: "",
                form: { ...prevState.form, aICategorizationItems: prevState.form.aICategorizationItems.filter(ai => ai != value) }
            }
        })
    }

    changeName = (e, value, fieldName, selection) => {

        this.setState(prevState => {
            return {
                ...prevState,
                selectionAfterMergeContainerIsRenamed: selection?.getSelection() ?? [],
                form: { ...prevState.form, [fieldName]: value }
            }
        })
    }

}