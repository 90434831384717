import React,{Component} from 'react'
import "./DetailView.scss"
import DetailContainer from './DetailContainer/DetailContainer'
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { ApolloProvider } from "react-apollo";
import { Query } from "react-apollo";
import { queryGetEntry } from "./../../queries/queryRecordings";
import * as microsoftTeams from "@microsoft/teams-js";
import { iniApplicationId, iniGraphMicrosoft } from "./../../ini/ini";


function login() {
    microsoftTeams.authentication.authenticate({
        url: window.location.origin + "/login",
        width: 600,
        height: 535,
        successCallback: function (result) {
            //console.log("authentication succeeded: ", result);
            window.location.reload();
        },
        failureCallback: function (reason) {
            //console.log("authentication failed: ", reason);
            window.location.reload();
        }
    });
}

export default class DetailViewOverlay extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            isPanelOpen: false,
            id: null,
            data: null
        }
    }

    renderData = (id, auth, callStartMs) => (

        <Query
            query={queryGetEntry}
            variables={{ id, auth, callStartMs }} pollInterval={120000} fetchPolicy="network-only">
            {({ loading, error, data }) => {
                if (loading) return <Panel
                    isOpen={this.state.isPanelOpen}
                    onDismiss={this.hidePanel}
                    isFooterAtBottom={false}
                    headerText=""
                    headerClassName="panel-header"
                    type={PanelType.smallFluid}

                >
                    <DetailContainer
                        apol={this.props.client}
                        authorization={this.props.authorization}
                        data={this.props.data != null ? this.props.data : this.state.data}
                        loading={this.props.loading}
                        hidePanel={this.hidePanel}
                        userRules={this.props.userRules}
                        theme={this.props.theme}
                        wia="56"
                    />
                </Panel>;
                if (error) return <div></div>;
                if (data.getEntry == null)
                    return <Panel
                        isOpen={this.state.isPanelOpen}
                        onDismiss={this.hidePanel}
                        isFooterAtBottom={false}
                        headerText=""
                        headerClassName="panel-header"
                        type={PanelType.smallFluid}

                    >
                        <DetailContainer
                            apol={this.props.client}
                            authorization={this.props.authorization}
                            data={this.props.data != null ? this.props.data : this.state.data}
                            hidePanel={this.hidePanel}
                            userRules={this.props.userRules}
                            theme={this.props.theme}
                            wia="55"
                        />
                    </Panel>;
                //console.log(data.getEntry)
                return (
                    <Panel
                        isOpen={this.state.isPanelOpen}
                        onDismiss={this.hidePanel}
                        isFooterAtBottom={false}
                        headerText=""
                        headerClassName="panel-header"
                        type={PanelType.smallFluid}

                    >
                        <DetailContainer
                            apol={this.props.client}
                            authorization={this.props.authorization}
                            data={data.getEntry}
                            hidePanel={this.hidePanel}
                            userRules={this.props.userRules}
                            theme={this.props.theme}
                            wia="57"

                        />
                    </Panel>);
            }}
        </Query>
    );

    render() {
        if (this.state.id != null) {
            //console.log(this.state.data);
            return (<ApolloProvider client={this.props.client}>
                {this.renderData(this.state.id, this.props.authorization, this.state.data.callStartMs)}
            </ApolloProvider>);
        } else {
            return ""
        }
    }

    showPanel = (itemId) => {
        this.setState({ id: itemId, isPanelOpen: true });
    };

    showPanelItem = (itemId, item) => {
        this.setState({ id: itemId, data: item, isPanelOpen: true });
    };

    hidePanel = () => {
        this.setState({ id: null, isPanelOpen: false });
    };
}
