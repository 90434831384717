import React, { Component } from 'react'
import { Persona, PersonaSize, PersonaPresence } from 'office-ui-fabric-react/lib/Persona';
import { Button } from 'office-ui-fabric-react/lib/Button';
import { Stack } from 'office-ui-fabric-react/lib/Stack';


import "./PersonaContainer.scss"

export default class PersonaContainer extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        }
    }


    render()
        {
            if (window.innerWidth < 600) {
                return (
                    <Stack horizontal>
                        {this.f(this.props,0)}
                    </Stack>
                );
            } else {
                return (
                    <Stack vertical>
                        {this.f(this.props,1)}
                    </Stack>
                );
            }
        }

    getPerson(i, name, detail, photo, id, tenantId, gross, isRecordingOwner, userPrincipal, joinDateTime, leftDateTime) {
        let nameDetail = (userPrincipal != undefined ? userPrincipal : detail != undefined ? detail : "") + (isRecordingOwner ? "(RecordingOwner)" : "");
    
        if (gross === 0) 
        {
            return <Persona key={id} size={PersonaSize.size24} presence={isRecordingOwner ? PersonaPresence.online : PersonaPresence.none} text={name} imageUrl={"data:image/jpeg;base64," + photo} hidePersonaDetails />
        }
        else if (i===5 && !this.state.isExpanded)
        {
            return <Button onClick={this.moreClicked} iconProps={{ iconName: 'More' }} />
        }
        else if ((i === this.props.person.length-1) && i > 5 && this.state.isExpanded)
        {
            return (
                <div key={i}>
                    <Persona key={id} size={PersonaSize.size40} presence={isRecordingOwner ? PersonaPresence.online : PersonaPresence.none} text={name}
                        secondaryText={nameDetail} imageUrl={"data:image/jpeg;base64," + photo} />
                    <Button
                        key={i}
                        onClick={this.moreClicked}
                        iconProps={this.state.isExpanded ? { iconName: 'ChevronUp' } : { iconName: 'More' }}
                    />
                </div>
            );
        } else if (i < 5 || this.state.isExpanded) {
            return (
                <div>
                    <Persona key={id} size={PersonaSize.size40} presence={isRecordingOwner ? PersonaPresence.online : PersonaPresence.none} text={name}
                        secondaryText={nameDetail} imageUrl={"data:image/jpeg;base64," + photo} />
                </div>
                    );
        }
        else {
            return;
        }
    }

    moreClicked = () => {
        this.setState({ isExpanded: !this.state.isExpanded })
    }

    f(props, gross) {
        if (props.person != null) {
            return props.person.map(({ name, detail, photo, userId, tenantId, isRecordingOwner, userPrincipal, joinDateTime, leftDateTime }, i) => (
                <Stack vertical key={i} id={i}>
                    {this.getPerson(i, name, detail, photo, userId, tenantId, gross, isRecordingOwner, userPrincipal, joinDateTime, leftDateTime)}
                    </Stack>
                ));
            }
    }

}

