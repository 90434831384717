import React from 'react';
import {
    BubbleExChart,
    DoughnutExChart, FunnelExChart,
    PiExChart,
    SimpleBarExChart,
    SplineExChart,
    StackedBarExChart, TreeMapExChart, SimpleCounter, palette, colorSet, source, sourceHit, sourceCompare, sourceLoss, sourceTotal, HealthCheck
} from './DevExtremeWidgets';
import { Spinner } from 'office-ui-fabric-react/lib';
import { queryReportData } from "../../../queries/queryReport";

import { Query } from 'react-apollo';

import gql from 'graphql-tag';
import TagCloud from './TagCloud/TagCloud';
const frameStyle = {
    margin: 4 * 2,
    padding: 4 * 2,
    sizeSmal: 76 * 2,
    sizeBig: 178 * 2,
    footer: 40,

};

class DevboardWidget extends React.Component {
    constructor(props) {
        super(props);
        this.reportConf = React.createRef();
        this.state = {
            query: 0,
        };
        this.data = null;
    }

    render() {
        return (
            this.queryWidget(this.props.element.id, this.props.element, this.props.input, this.props.apol)
        )
    }

    queryWidget = (id, element, input, apol) => (

        <Query
            key={`query:${element.id}`}
            query={queryReportData}
            pollInterval={this.props.element.kpi == "CC" ? 10000 : 1800000}
            variables={{ input, id }}
            onCompleted={(data) => {
                this.data = data;
            }
            }
        >
            {({
                loading, error, data, refetch,
            }) => {
                if (loading) return <Spinner label="Loading..." ariaLive="assertive" labelPosition="top" />;
                if (error) return <h1> </h1>;
                if (data.getReportData != null) {

                    let size_w; let
                        size_h;
                    if (this.props.resize === true) {
                        size_h = -1;
                        size_w = -1;
                    } else {
                        size_w = frameStyle.sizeBig;
                        size_h = frameStyle.sizeBig;
                    }

                    let dataTotal = [];
                    if (data.getReportData.data.total !== null) {
                        dataTotal = data.getReportData.data.total;
                    }
                    let elementData = null;
                    let tempArr = [];

                    switch (this.props.element.type) {
                        case 'number':
                            elementData = data.getReportData.data.text;
                            break;
                        case 'total':
                            if (this.props.element.conversationType !== null
                                && this.props.element.conversationType.length !== 0
                                && this.props.element.kpi !== "CC"
                                && this.props.element.kpi !== "AU"
                                && this.props.element.kpi !== "CNR"
                                && this.props.element.kpi !== "ActiveUser"
                                && this.props.element.kpi !== "UserCheck"
                                && this.props.element.kpi !== "HealthCheck"
                                && this.props.element.kpi !== "Export"
                                && this.props.element.kpi !== "Exported"
                                && this.props.element.kpi != "CategoryCompared"
                                && this.props.element.kpi != "PolicyCompared"
                            ) {
                                dataTotal.map(el => {
                                    if (this.props.element.conversationType.includes(el.args)) {
                                        tempArr.push(el);
                                    }
                                });

                                elementData = tempArr;

                            } else if (this.props.element.kpi == "AICategorizationKPI") {
                                //we access first element because at the moment we only consider one AI categorization when creating the graph,
                                //cheges may be needed in case we have more than one AI cat.
                                elementData = data.getReportData.data.keymap != null && data.getReportData.data.keymap.length > 0 ?
                                    data.getReportData.data.keymap[0].items : []
                            } else {
                                elementData = data.getReportData.data.total;


                            }
                            break;
                        case 'keymap':
                            elementData = data.getReportData.data.keymap;
                            break;
                        case 'time':
                            elementData = data.getReportData.data.time;
                            break;
                        case 'user':
                            elementData = data.getReportData.data.total;
                            break;
                        default:
                            elementData = data.getReportData.data.text;
                    }

                    switch (this.props.element.widget) {
                        case 'TagCloud':
                            var words = elementData !== null ? elementData.map(e => (
                                {
                                    value: e.value,
                                    text: e.name,
                                }
                            )) : []
                            return (
                                <div>
                                    {words.length > 0 && !this.props.resize &&
                                        <TagCloud
                                            tags={words}
                                        //fontSize={[10, 60]}
                                        //onClickTag={(tag) => console.log(tag)}
                                        />
                                    }
                                    {words.length > 0 && this.props.resize &&
                                        <TagCloud
                                            tags={words}
                                            containerDim={[1400, 550]}
                                            fontSize={[10, 100]}
                                        //onClickTag={(tag) => console.log(tag)}
                                        />
                                    }
                                    {words.length == 0 &&
                                        <div style={{ height: "200px" }}>
                                            <div style={{ marginTop: "140px" }}>NO DATA FOUND</div>
                                        </div>
                                    }

                                </div>
                            )

                        case 'DoughnutChart': return (
                            <DoughnutExChart
                                reference={this.props.reference}
                                palette={palette}
                                data={elementData}
                                type={this.props.element.type}
                                keyword={this.props.element.keywords}
                                size={{
                                    width: size_w,
                                    height: size_h,
                                }}
                            />
                        );

                        case 'SplineChart':
                            var tmpSource = [];
                            if (this.props.element.conversationType !== null && this.props.element.conversationType.length !== 0) {
                                source.map(el => {
                                    if (this.props.element.conversationType.includes(el.name)) {
                                        tmpSource.push(el);
                                    }
                                });

                            } else {
                                tmpSource = source;
                            }
                            var sourceTypes = tmpSource;
                            switch (this.props.element.kpi) {
                                case "GCTA":
                                case "CTA":
                                case "CIA":
                                case "COA":
                                case "CMA":
                                case "CEA":
                                    sourceTypes = sourceCompare;
                                    break;
                                case "GCNR":
                                case "CNR":
                                    sourceTypes = sourceLoss;
                                    break;
                                case "AU":
                                    sourceTypes = sourceTotal;
                                    break;
                                case "PolicyCompared":
                                    sourceTypes = sourceHit;
                                    break;
                                case "CategoryCompared":
                                    sourceTypes = sourceHit;
                                    break;
                            }
                            return (
                                <SplineExChart
                                    reference={this.props.reference}
                                    palette={palette}
                                    source={sourceTypes}
                                    data={elementData}
                                    type={this.props.element.type}
                                    size={{
                                        width: size_w,
                                        height: size_h,
                                    }}
                                />
                            );


                        case 'PieChart': return (
                            <PiExChart
                                kpi={this.props.element.kpi}
                                reference={this.props.reference}
                                data={elementData}
                                type={this.props.element.type}
                                palette={palette}
                                size={{
                                    width: size_w,
                                    height: size_h,
                                }}
                            />
                        );


                        case 'SimpleBarChart': return (
                            <SimpleBarExChart
                                reference={this.props.reference}
                                data={elementData}
                                kpi={this.props.element.kpi}
                                type={this.props.element.type}
                                palette={palette}
                                size={{
                                    width: size_w,
                                    height: size_h,
                                }}
                            />
                        );
                        case 'StackedBarChart': return (
                            <StackedBarExChart
                                palette={palette}
                                data={elementData}
                                reference={this.props.reference}
                                type={this.props.element.type}
                                kpi={this.props.element.kpi}
                                size={{
                                    width: size_w,
                                    height: size_h,
                                }}
                                convType={this.props.element.conversationType}
                            />
                        );

                        case 'TreeMap':
                            return (
                                <TreeMapExChart
                                    palette={palette}
                                    data={elementData}
                                    reference={this.props.reference}
                                    type={this.props.element.type}
                                    size={{
                                        width: size_w,
                                        height: size_h,
                                    }}
                                />
                            );
                        case 'FunnelChart':
                            return (
                                <FunnelExChart
                                    palette={palette}
                                    data={elementData}
                                    reference={this.props.reference}
                                    type={this.props.element.type}
                                    size={{
                                        width: size_w,
                                        height: size_h,
                                    }}
                                />
                            );
                        case 'BubbleChart':
                            return (
                                <BubbleExChart
                                    palette={palette}
                                    reference={this.props.reference}
                                    size={{
                                        width: size_w,
                                        height: size_h,
                                    }}
                                />
                            );
                        case 'Number':
                        default:
                            {
                                let ttmp = 3;
                                if (this.props.element.alarming != null) {
                                    ttmp = this.props.element.alarming.alarmActive ? 0 : 1;
                                }
                                if (this.props.element.kpi == "HealthCheck")
                                    return (
                                        <HealthCheck
                                            color={palette[ttmp]}
                                            val={elementData}
                                        />
                                    );
                                else
                                    return (
                                        <SimpleCounter
                                            color={palette[ttmp]}
                                            val={elementData}
                                        />
                                    );
                            }
                    }
                }
            }}
        </Query>
    );

}

export default DevboardWidget;