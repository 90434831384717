import React, { Component } from 'react';
import { mutationUpdateAudioStorage, queryGetTenantList } from "../../../queries/querySettings";

import {
    DefaultButton,
    PrimaryButton,
    Stack,
    TextField,
    Checkbox,
    Label
} from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { MyContext } from '../../../context';
import Utils from "./../../common/Utils";
import { ComboBox } from '@fluentui/react';


class AudioStorageConfig extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            convertToMP3: this.props.data != null ? this.props.data.convertToMP3 : false,
            archiveBlobs: this.props.data != null ? this.props.data.archiveBlobs : false,
            convertBlobOlderThanDays: this.props.data != null ? this.props.data.convertBlobOlderThanDays : 30,
            archiveBlobOlderThanDays: this.props.data != null ? this.props.data.archiveBlobOlderThanDays : 90,
            archiveDestinationAudioFormat: this.props.data != null ? this.props.data.archiveDestinationAudioFormat : "NONE",
        };

    }

    render() {

        return (
            <Stack vertical>

                <Label><h4><MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "postCompression")}</>)}</MyContext.Consumer></h4></Label>
                <hr />

                <Stack.Item>
                    <Label><MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "convertToMP3")}</>)}</MyContext.Consumer></Label>
                    <Checkbox
                        label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("settings", "enabled")}</>)}</MyContext.Consumer>}
                        checked={this.state.convertToMP3}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                this.setState({
                                    convertToMP3: newValue,
                                    archiveDestinationAudioFormat: "NONE"
                                });
                            } else {
                                this.setState({
                                    convertToMP3: newValue
                                });
                            }


                        }}
                    />
                    <Stack.Item>
                        <div style={{ diplay: "flex" }}>
                            <TextField type="number" className="displayKeyRotationSettings" label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "convertAfter")}</>)}</MyContext.Consumer>}
                                value={this.state.convertBlobOlderThanDays} onChange={(event) => { this.setState({ convertBlobOlderThanDays: event.target.value }) }}
                                errorMessage={(this.state.convertToMP3 && this.state.convertBlobOlderThanDays < 1) ? "Minimum value 1 days" : ""}
                            />
                            <Label className="displayKeyRotationSettings"><MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "days")}</>)}</MyContext.Consumer></Label>
                        </div>

                    </Stack.Item>
                </Stack.Item>
                <br />

                {this.props.storageType !== "S3Storage" && <div>
                    <Label><h4><MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "archive")}</>)}</MyContext.Consumer></h4></Label>
                    <hr />

                    {/* execution */}
                    <Stack.Item>
                        <Label><MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "archiveStorage")}</>)}</MyContext.Consumer></Label>
                        <Checkbox
                            label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("settings", "enabled")}</>)}</MyContext.Consumer>}
                            checked={this.state.archiveBlobs}
                            onChange={(event, newValue) => {
                                this.setState({
                                    archiveBlobs: newValue
                                });
                            }}
                        />

                        <ComboBox
                            style={{ width: "300px" }}
                            placeholder="Select an option"
                            label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "archiveDestinationAudioFromat")}</>)}</MyContext.Consumer>}
                            options={[{
                                key: "Keep current",
                                text: "None"
                            },
                            {
                                key: "MP3",
                                text: "MP3",
                            }]
                            }
                            selectedKey={this.state.archiveDestinationAudioFormat}
                            onChange={(event, newValue) => {
                                if (newValue.key === "MP3") {
                                    this.setState({
                                        convertToMP3: false,
                                        archiveDestinationAudioFormat: newValue.key
                                    })
                                } else {
                                    this.setState({
                                        archiveDestinationAudioFormat: newValue.key
                                    })
                                }
                            }}
                        />
                        <Stack.Item>
                            <div style={{ diplay: "flex" }}>
                                <TextField type="number" className="displayKeyRotationSettings" label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "archiveAfter")}</>)}</MyContext.Consumer>}
                                    value={this.state.archiveBlobOlderThanDays} onChange={(event) => { this.setState({ archiveBlobOlderThanDays: event.target.value }) }}
                                    errorMessage={(this.state.archiveBlobs && !(this.state.archiveBlobOlderThanDays > 89 && this.state.archiveBlobOlderThanDays < 99999999999)) ? "Minimum value 90 days" : ""}
                                />
                                <Label className="displayKeyRotationSettings"><MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "days")}</>)}</MyContext.Consumer></Label>
                            </div>

                        </Stack.Item>
                    </Stack.Item>
                </div>}
                <br />
                <hr />
                <Stack.Item>
                    <PrimaryButton text={<MyContext.Consumer>{(context) => (<>{context.getTranslation("common", "save")}</>)}</MyContext.Consumer>}
                        disabled={(this.state.archiveBlobs && !(this.state.archiveBlobOlderThanDays > 89 && this.state.archiveBlobOlderThanDays < 99999999999)) || (this.state.convertToMP3 && !(this.state.convertBlobOlderThanDays > 0 && this.state.convertBlobOlderThanDays < 99999999999))}
                        onClick={(event) => { this.notifyAudioStorage(this.props.apol); this.props.cl() }} style={{ "margin-right": "10px" }} />
                    <DefaultButton text={<MyContext.Consumer>{(context) => (<>{context.getTranslation("common", "cancel")}</>)}</MyContext.Consumer>} onClick={(event) => { this.props.cl() }} />
                </Stack.Item>
            </Stack>

        );
    }

    notifyAudioStorage = async (apol) => {
        // Add or change User
        var audioStorage = {
            id: this.props.data != null ? this.props.data.id : Utils.guidGenerator(),
            tenantId: this.props.tenantId,
            convertToMP3: this.state.convertToMP3,
            convertBlobOlderThanDays: parseInt(this.state.convertBlobOlderThanDays),
            archiveBlobs: this.state.archiveBlobs,
            archiveBlobOlderThanDays: parseInt(this.state.archiveBlobOlderThanDays),
            archiveDestinationAudioFormat: this.state.archiveDestinationAudioFormat
        };
        var data = await apol
            .mutate({
                mutation: mutationUpdateAudioStorage,
                refetchQueries: [{
                    query: queryGetTenantList,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    audioStorage: audioStorage
                }
            });
        if (data.data.updateAudioStorage == null || data.data.updateAudioStorage.name === "error") {
            toast.info(<MyContext.Consumer>{(context) => (<> Save Audio Storage not successful </>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info(<MyContext.Consumer>{(context) => (<> Save Audio Storage successful </>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
}

export default AudioStorageConfig;