import React from 'react';
import { FontSizes, Stack, Icon, Slider, Persona, PersonaSize, PersonaPresence, Button, Spinner, Text, TextField, MessageBar, MessageBarType } from 'office-ui-fabric-react/lib';
import "./DetailContainer.scss"
import DetailPivot from '../DetailPivot/DetailPivot';
import PersonaContainer from "../Persona/PersonaContainer";
import MotionMonitor from "../MotionMonitor/MotionMonitor";
import AzurePlayer from "./../../common/AzurePlayer";
import TranscriptSelect from './../../LanguageSelect/TranscriptSelect'
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { TooltipDelay } from 'office-ui-fabric-react/lib/Tooltip';
import Utils from "./../../common/Utils";
import { ReactHeight } from 'react-height';
import { queryGetEntry } from "./../../../queries/queryRecordings";
import { getCognitiveServicesLocalesTranscription } from "../../../queries/querySettings"

import gql from "graphql-tag";
import TtlContainer from "../DetailPivot/PivotElements/TTL/TtlContainer";
import { MyContext } from '../../../context';

import { toast } from "react-toastify";
import "../MotionMonitor/fabric-icons-inline.scss";
import $ from 'jquery';
import { Query } from "react-apollo";
import Soundcurve from "./Soundkurve20x20.png";
import { HoverCard } from "office-ui-fabric-react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import HoverCardFolders from "./HoverCardFolders";
import WaveformAudio from "../WaveformAudio/WaveformAudio.js";
import { PrimaryButton } from '@fluentui/react';


export default class DetailContainer extends React.Component {
    constructor(props) {
        super(props);
        // const { disabled, checked } = this.props;
        this.changeCurrentTime = this.changeCurrentTime.bind(this);
        this.azurePlayerRef1 = React.createRef();
        this.azurePlayerRef2 = React.createRef();
        this.azurePlayerRef3 = React.createRef();

        this.playerSlider = React.createRef();
        this.azurePlayerRefAudio = React.createRef();
        this.playerLoadedCallback1 = this.playerLoadedCallback1.bind(this);
        this.playerLoadedCallback2 = this.playerLoadedCallback2.bind(this);
        this.playerLoadedCallback3 = this.playerLoadedCallback3.bind(this);
        this.playerLoadedCallbackAudio = this.playerLoadedCallbackAudio.bind(this);
        this.playerLoadedCallbackTimeUpdate = this.playerLoadedCallbackTimeUpdate.bind(this);
        this.videoFrame2User = -1;
        this.videoFrame3User = -1;


        this.state = {
            emotion: true,
            spotting: true,
            isOpen: this.props.isOpen,
            heightTop: 500,
            finished: false,
            playerValue: 0,
            stream2: this.videoFrame2User !== -1 ? this.props.data.linkedUser[this.videoFrame2User].videoAsset : null,
            stream3: this.videoFrame3User !== -1 ? this.props.data.linkedUser[this.videoFrame3User].videoAsset : null,
            isPlaying: false,
            bigScreenSharing: false,
            reprocessText: "Reprocess Conversation",
            requestReason: this.props.data.requestReplayReasoningText,
            bigVideo1: false,
            bigVideo2: false,
            graphqlRequestPending: false,
            requestRestoreArchiveReasoningText: this.props.data.requestRestoreArchiveReasoningText,
            keepDeleteClicked: false,
            keepDeleteMessage: "",
            policyDate: []
        };
    }
    // the policy Date state has been decided to be set only when the policy tab is opened
    updatePolicyResults = (polycyDateValue) => {

        let arrayPolicy = [];
        if (polycyDateValue.length > 0) {


            for (let i = 0; i < polycyDateValue.length; i++) {
                let name = polycyDateValue[i].policy.name;
                let riskRating = polycyDateValue[i].policy.riskRating;
                polycyDateValue[i].policyResult.map(p =>
                    arrayPolicy.push({
                        appearances: p.appearances,
                        name: name,
                        policyResultName: p.name,
                        policyResultType: p.type
                    })
                );
            }

        }
        this.setState({ policyDate: arrayPolicy })
    }

    componentDidMount() {
        if (window.innerWidth < 820)
            this.timerID = setInterval(() => this.tick(), 1000);
        else
            this.timerID = setInterval(() => this.tick(), 2000);
        if (window.innerWidth < 820)
            this.playerBeginning();
    }

    tick() {
        this.setState({
            finished: true
        });
        clearInterval(this.timerID);
        if (window.innerWidth < 820)
            this.playerBeginning();
    }


    //this displays both old waveform and old emotion
    showEmotion = () => {
        let tmp = this.state.emotion;


        if (tmp) {
            return <>
                {this.props.data.summarizedInsights !== undefined && this.props.data.summarizedInsights != null && <MotionMonitor sentiments={this.props.data.summarizedInsights.sentiments} data={this.props.data} listener={this.changeCurrentTime} />}
                {this.waveFormDiagram()}
            </>
        }
    };

    //OLD WAVE
    //It's still used for older calls
    waveFormDiagram = () => {
        var id = this.props.data.conversationId;
        var width = parseInt(window.innerWidth * 0.86);
        return (
            // Get Replay Asset Url
            <Query
                query={gql`
                          query ent ($id: ID, $width: Int) {
                            getWaveform(id: $id, width: $width)
                          }
                        `}
                variables={{ id, width }}  >
                {({ loading, error, data }) => {
                    if (loading) return <Stack></Stack>;
                    if (error) return <Stack></Stack>;
                    if (data.getWaveform == null || data.getWaveform == "") return <Stack></Stack>
                    return (<>
                        <div className="display-inline display-sentiment-icons">
                            <img id="wave-icon" className={window.innerWidth < 600 ? "" : "icons-align-big-screen"} src={Soundcurve} />
                        </div>
                        <div className="display-inline display-sentiment-bars">
                            <img id="base64image"
                                src={"data:image/jpeg;base64," + data.getWaveform} />
                        </div>
                    </>);
                }}
            </Query>

        );
    }

    playerLoadedCallbackTimeUpdate = () => {
        if (this.azurePlayerRefAudio.current != null) {
            this.setState({ playerValue: Math.trunc(this.azurePlayerRefAudio.current.getCurrentTime()) });
            console.log("currentTime: " + this.state.playerValue);
        }
    }

    playerLoadedCallback1 = () => {

    };

    playerLoadedCallback2 = () => {
        // Jump to the actual Position and play
        var time = this.azurePlayerRefAudio.current.getCurrentTime();
        if (this.azurePlayerRef1.current != null) {
            this.azurePlayerRef1.current.jumpTo(time);
        }
        if (this.azurePlayerRef2.current != null) {
            this.azurePlayerRef2.current.jumpTo(time);
        }
        if (this.azurePlayerRef3.current != null) {
            this.azurePlayerRef3.current.jumpTo(time);
        }
        if (this.azurePlayerRefAudio.current != null) {
            this.azurePlayerRefAudio.current.jumpTo(time);
        }
        if (this.azurePlayerRef2.current.isRunning()) {
            this.azurePlayerRef2.current.play();
        }
    };

    playerLoadedCallback3 = () => {
        // Jump to the actual Position and play
        var time = this.azurePlayerRefAudio.current.getCurrentTime();
        if (this.azurePlayerRef1.current != null) {
            this.azurePlayerRef1.current.jumpTo(time);
        }
        if (this.azurePlayerRef2.current != null) {
            this.azurePlayerRef2.current.jumpTo(time);
        }
        if (this.azurePlayerRef3.current != null) {
            this.azurePlayerRef3.current.jumpTo(time);
        }
        if (this.azurePlayerRefAudio.current != null) {
            this.azurePlayerRefAudio.current.jumpTo(time);
        }
        if (this.azurePlayerRef3.current.isRunning()) {
            this.azurePlayerRef3.current.play();
        }
    };


    playerLoadedCallbackAudio = () => {

    };

    togleEmotion = () => {
        let tmp = this.state.emotion;
        tmp = !tmp;

        this.setState({ emotion: tmp })
    };


    togleEmotion = () => {
        let tmp = this.state.emotion;
        tmp = !tmp;

        this.setState({ emotion: tmp })
    };

    hidePanel = () => {
        this.setState({ isOpen: false })
    };

    changeCurrentTime = (val) => {
        console.log("Seconds: " + val);
        if (this.azurePlayerRef1.current != null) {
            this.azurePlayerRef1.current.jumpTo(val)
        }
        if (this.azurePlayerRef2.current != null) {
            this.azurePlayerRef2.current.jumpTo(val)
        }
        if (this.azurePlayerRef3.current != null) {
            this.azurePlayerRef3.current.jumpTo(val)
        }
        if (this.azurePlayerRefAudio.current != null) {
            this.azurePlayerRefAudio.current.jumpTo(val)
        }
        if (!this.state.isPlaying) {
            if (this.azurePlayerRef1.current != null) {
                this.azurePlayerRef1.current.pause();
            }
            if (this.azurePlayerRef2.current != null) {
                this.azurePlayerRef2.current.pause();
            }
            if (this.azurePlayerRef3.current != null) {
                this.azurePlayerRef3.current.pause();
            }
            if (this.azurePlayerRefAudio.current != null) {
                this.azurePlayerRefAudio.current.pause();
            }
        } else {
            if (this.azurePlayerRef1.current != null) {
                this.azurePlayerRef1.current.play();
            }
            if (this.azurePlayerRef2.current != null) {
                this.azurePlayerRef2.current.play();
            }
            if (this.azurePlayerRef3.current != null) {
                this.azurePlayerRef3.current.play();
            }
            if (this.azurePlayerRefAudio.current != null) {
                this.azurePlayerRefAudio.current.play();
            }
        }
    };

    playerBeginning = () => {
        this.playerPause();
        this.setState({ isPlaying: false });
        if (this.azurePlayerRef1.current != null) {
            this.azurePlayerRef1.current.jumpTo(0);
        }
        if (this.azurePlayerRef2.current != null) {
            this.azurePlayerRef2.current.jumpTo(0);
        }
        if (this.azurePlayerRef3.current != null) {
            this.azurePlayerRef3.current.jumpTo(0);
        }
        if (this.azurePlayerRefAudio.current != null) {
            this.azurePlayerRefAudio.current.jumpTo(0);
        }
    };

    playerPause = () => {
        if (this.azurePlayerRef1.current != null) {
            this.azurePlayerRef1.current.pause()
        }
        if (this.azurePlayerRef2.current != null) {
            this.azurePlayerRef2.current.pause()
        }
        if (this.azurePlayerRef3.current != null) {
            this.azurePlayerRef3.current.pause()
        }
        if (this.azurePlayerRefAudio.current != null) {
            this.azurePlayerRefAudio.current.pause()
        }
    }

    playerPlay = () => {
        var time = this.azurePlayerRefAudio.current.getCurrentTime();
        if (this.azurePlayerRef1.current != null) {
            this.azurePlayerRef1.current.jumpTo(time);
            this.azurePlayerRef1.current.play();
        }
        if (this.azurePlayerRef2.current != null) {
            this.azurePlayerRef2.current.jumpTo(time);
            this.azurePlayerRef2.current.play();
        }
        if (this.azurePlayerRef3.current != null) {
            this.azurePlayerRef3.current.jumpTo(time);
            this.azurePlayerRef3.current.play();
        }
        if (this.azurePlayerRefAudio.current != null) {
            this.azurePlayerRefAudio.current.play();
        }
    }

    changeSource2 = (val) => {
        if (this.state.isPlaying) {
            this.playerPause();
        }
        this.setState({ stream2: val, isPlaying: false });
        this.azurePlayerRef2.current.changeSource(val);
    }

    changeSource3 = (val) => {
        if (this.state.isPlaying) {
            this.playerPause();
        }
        this.setState({ stream3: val, isPlaying: false });
        this.azurePlayerRef3.current.changeSource(val);
    }

    getDurationInSeconds = () => {

        var t = this.props.data.duration;
        if (t === "")
            return 0;

        //seconds miliseconds and days are divided by dots (d.hh:mm:ss.ml)
        //if there are more dots in string will split by them and divide into time(tmp) and days 
        //if only one dot detected there is no day so will be only tmp
        let tmp = t, d = 0;
        if (t.indexOf(".") !== -1) {
            if (t.split('.').length > 1) {
                tmp = t.split('.')[1];
                d = t.split('.')[0];
            } else {
                tmp = t.split('.')[0];
            }

        }

        let out, s, m, h;
        tmp = tmp.split(':');

        s = Number(tmp[2]);
        m = Number(tmp[1]) * 60;
        h = Number(tmp[0]) * 60 * 60;
        d = Number(d) * 24 * 60 * 60;

        out = s + m + h + d;

        return out;
    }


    showAnalyticsStatus = () => {
        var status = <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "analyticsNoStatus")}</>)}</MyContext.Consumer>;
        if (this.props.data.analyticsStatus === "Started") {
            status = <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "analyticsStillProcessing")}</>)}</MyContext.Consumer>;
        } else if (this.props.data.analyticsStatus === "Failed" && this.props.data.summarizedInsights == null) {
            status = <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "analyticsNotPossible")}</>)}</MyContext.Consumer>;
        } else if (this.props.data.analyticsStatus === "Finished" || this.props.data.summarizedInsights != null) {
            status = "";
        }


        return status;
    }

    onTranslate = async (language, languageText) => {

        this.props.apol
            .mutate({
                mutation: gql`
                        mutation ent ($uri: String, $id: String, $language: String, $type: String) {
                            analyzeConversation (uri: $uri, id: $id, language: $language, type: $type)
                     }
                     `,
                variables: {
                    uri: this.props.data.entryContent,
                    id: this.props.data.conversationId,
                    language: language,
                    type: (this.props.userRules != null && this.props.userRules.ruleAnalytics != null && this.props.userRules.ruleAnalytics.withTranscription) ? "SpeechToText" : "Indexer"
                }
            });
    }

    onReprocess = async () => {
        this.setState({ reprocessText: "Reprocess Conversation: running!" });
        this.props.apol
            .mutate({
                mutation: gql`
                        mutation ent ($id: String, $tenantId: String, $userId: String, $callId: String) {
                            reprocessConversation (id: $id, tenantId: $tenantId, userId: $userId, callId: $callId)
                     }
                     `,
                variables: {
                    tenantId: this.props.data.tenantId,
                    id: this.props.data.conversationId,
                    userId: this.props.data.userId,
                    callId: this.props.data.callId
                }
            })
            .then(result => {
                console.log(result);
                this.setState({ reprocessText: result.data.reprocessConversation ? "Reprocess Conversation: Successful" : "Reprocess Conversation: Not successful" });
            });
        ;
    }


    canReplayByFolder = () => {
        return (this.props.userRules.visibilityDetailsReplay == undefined || (this.props.userRules.visibilityDetailsReplay != undefined && this.props.userRules.visibilityDetailsReplay));
    }

    render() {

        return (
            <MyContext.Consumer >
                {(context) => (
                    <div className={window.innerWidth < 820 ? "no-margin" : "detail-container"}>
                        <React.Fragment>
                            <ReactHeight onHeightReady={(height) => {
                                this.setState({ heightTop: height });
                            }}>
                                {
                                    this.rendHeader()
                                }

                                <div className="flex-container--video-header">
                                    {this.props.data.isVideoProcessing &&
                                        <div style={{ width: "269px", height: "193px", background: "black", alignContent: "center", padding: "20px" }}>
                                            <MyContext.Consumer>
                                                {(context) => (
                                                    <span style={{ color: "white" }}>{context.getTranslation("detailContainer", "videoStillProcessing")}</span>
                                                )
                                                }
                                            </MyContext.Consumer>
                                        </div>
                                    }
                                    {!this.props.data.isVideoProcessing && !this.props.loading === true && this.props.data.replayAllowed === true && this.props.data.archiveStatus === 0 && this.canReplayByFolder() && this.videoStream1()}
                                    {!this.props.data.isVideoProcessing && !this.props.loading === true && this.props.data.replayAllowed === true && this.props.data.archiveStatus === 0 && this.canReplayByFolder() && this.props.loading == null && this.videoStream2()}
                                    {!this.props.data.isVideoProcessing && !this.props.loading === true && this.props.data.replayAllowed === true && this.props.data.archiveStatus === 0 && this.canReplayByFolder() && this.props.loading == null && this.videoStream3()}
                                    {!this.props.loading === true && this.props.data.replayAllowed === true && this.props.data.archiveStatus === 0 && this.canReplayByFolder() && this.videoStreamAudio()}
                                    {this.videoMetadata(this.props.data.replayAllowed && this.canReplayByFolder())}
                                    {this.personaContainer()}
                                    <div className="flex-container--player-emotion">
                                        {!this.props.loading === true && this.props.data.replayAllowed === true && this.props.data.archiveStatus === 0 && this.canReplayByFolder() && this.playerControls()}
                                        {/*    {!this.props.loading === true && this.props.data.replayAllowed === true && this.props.loading == null && this.rendEmote()}*/}
                                    </div>

                                </div>
                            </ReactHeight>
                            {this.rendPivot()}
                            {this.props.loading === true && <div>
                                <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                            </div>}
                        </React.Fragment>
                    </div>
                )
                }
            </MyContext.Consumer>
        );
    }

    requestReplay = (allowReplay) => {
        if (this.props.data.needsRequest) {
            return (<MyContext.Consumer >
                {(context) => (
                    <div style={{ "width": "250px" }}>

                        {this.state.graphqlRequestPending
                            ? <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                            : <>
                                <TextField
                                    label={context.getTranslation("detailContainer", "requestReplayReason")}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            requestReason: newValue,
                                        });
                                    }}
                                    value={this.state.requestReason}
                                    required />
                                {this.state.requestReason
                                    ? <Button text={context.getTranslation("detailContainer", "requestReplay")} onClick={this.sendRequest} style={{ "margin-top": "5px" }} />
                                    : <Button text={context.getTranslation("detailContainer", "requestReplay")} style={{ "margin-top": "5px" }} disabled />
                                }
                            </>
                        }


                    </div>
                )
                }
            </MyContext.Consumer >);

        } else if (this.props.data.needsApproval) {
            return (<MyContext.Consumer >
                {(context) => (
                    <div>

                        <Text> {context.getTranslation("detailContainer", "replayRequestFromUser")}</Text>
                        <mgt-person show-name={true} user-id={this.props.data.requestUser} person-card="hover" style={{ "text-align": "center", "vertical-align": "middle", "line-height": "40px", "margin-left": "10px" }}></mgt-person>
                        <Text className="align-text-center">{this.props.data.replayRequestUserName}</Text>
                        <br />
                        <Text>{context.getTranslation("detailContainer", "requestReplayReason")}: {this.props.data.requestReplayReasoningText}</Text>
                        <br />
                        {this.state.graphqlRequestPending
                            ? <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" style={{ "width": "200px" }} />
                            : <>
                                <Button style={{ "margin-top": "10px" }} text={context.getTranslation("detailContainer", "allowReplay")} onClick={this.sendApprove} />
                                <Button text={context.getTranslation("detailContainer", "clear")} onClick={this.sendClear} />
                            </>}
                    </div>
                )
                }
            </MyContext.Consumer >);
        } else if (this.props.data.approvedUser) {
            return <MyContext.Consumer >
                {(context) => (
                    <div>
                        <Text> {context.getTranslation("detailContainer", "replayRequestApprovedBy")} </Text>
                        <mgt-person show-name={true} user-id={this.props.data.approvedUser} person-card="hover" style={{ "text-align": "center", "vertical-align": "middle", "line-height": "40px", "margin-left": "10px" }}></mgt-person>
                        <Text className="align-text-center">{this.props.data.approvedUserName}</Text>

                    </div>
                )
                }
            </MyContext.Consumer >
        } else if (this.props.data.requestUser) {
            if (allowReplay) {
                return <MyContext.Consumer >
                    {(context) => (
                        <div>
                            <Button disabled text={context.getTranslation("detailContainer", "replayRequestPending")} style={{ display: "none" }} />
                        </div>)
                    }
                </MyContext.Consumer >
            } else {
                return <MyContext.Consumer >
                    {(context) => (<div>
                        <Button disabled text={context.getTranslation("detailContainer", "replayRequestPending")} />
                    </div>)
                    }
                </MyContext.Consumer >
            }
        }

    }

    sendRequest = () => {
        this.setState({ graphqlRequestPending: true })
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($id : String!,$reasoning : String, $input:AuthInput) {
                    requestReplay (id: $id, reasoning: $reasoning, input: $input){valid}
                }
                `,
                refetchQueries: [{
                    query: queryGetEntry,
                    variables: { id: this.props.data.conversationId, auth: this.props.data.authorization, callStartMs: this.props.data.callStartMs }
                }],
                variables: {
                    id: this.props.data.conversationId,
                    reasoning: this.state.requestReason,
                    input: this.props.authorization
                }
            })
            .then(result => { console.log(result); this.setState({ graphqlRequestPending: false }) });

        toast.info('Replay request sent. Waiting for approval', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

        });
    }

    sendApprove = () => {
        this.setState({ graphqlRequestPending: true })
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($id : String!, $input:AuthInput) {
                    approveReplay (id: $id, input: $input){valid}
                }
                `,
                refetchQueries: [{
                    query: queryGetEntry,
                    variables: { id: this.props.data.conversationId, auth: this.props.data.authorization, callStartMs: this.props.data.callStartMs }
                }],
                variables: {
                    id: this.props.data.conversationId,
                    input: this.props.authorization
                }
            })
            .then(result => { console.log(result); this.setState({ graphqlRequestPending: false }) });
    }

    sendClear = () => {
        this.setState({ graphqlRequestPending: true })
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($id : String!, $input:AuthInput) {
                    clearAttention (id: $id, input:$input){valid}
                }
                `,
                refetchQueries: [{
                    query: queryGetEntry,
                    variables: { id: this.props.data.conversationId, auth: this.props.data.authorization, callStartMs: this.props.data.callStartMs }
                }],
                variables: {
                    id: this.props.data.conversationId,
                    input: this.props.authorization
                }
            })
            .then(result => { console.log(result); this.setState({ graphqlRequestPending: false }) });
    }

    //REQUEST ARCHIVE APPROVAL PROCESS
    requestReplayFromArchive = () => {
        if (this.props.data.archiveStatus != 0) {
            //user 1 sends request
            if (this.props.data.archiveAccessType === 3 && this.props.data.requestUser == null && !this.props.data.isApprover) {
                return (<MyContext.Consumer >
                    {(context) => (
                        <div style={{ "width": "250px" }}>

                            {this.state.graphqlRequestPending
                                ? <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                : <>
                                    {this.props.userRules.ruleAccess.archivedRequestRetrievalReasoningRequired
                                        ? (<>
                                            < TextField
                                                label={context.getTranslation("detailContainer", "requestRestoreArchiveReason")}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        requestRestoreArchiveReasoningText: newValue,
                                                    });
                                                }}
                                                value={this.state.requestRestoreArchiveReasoningText}
                                                required />


                                            {(this.state.requestRestoreArchiveReasoningText)
                                                ? <Button text={context.getTranslation("detailContainer", "requestReplayArchive")} onClick={this.sendRequestArchive} style={{ "margin-top": "5px", "width": "max-content" }} />
                                                : <Button text={context.getTranslation("detailContainer", "requestReplayArchive")} style={{ "margin-top": "5px", "width": "max-content" }} disabled />
                                            }
                                        </>
                                        ) : (
                                            <Button text={context.getTranslation("detailContainer", "requestReplayArchive")} onClick={this.sendRequestArchive} style={{ "margin-top": "5px", "width": "max-content" }} />
                                        )
                                    }
                                </>
                            }


                        </div>
                    )
                    }
                </MyContext.Consumer >);

                //request pending message
            } else if (this.props.data.requestUser != null && !this.props.data.isApprover && !this.props.data.isRestoreApproved) {
                return <MyContext.Consumer >
                    {(context) => (<div>
                        <Button disabled text={context.getTranslation("detailContainer", "archiveRequestPending")} style={{ "margin-top": "5px", "width": "max-content" }} />
                    </div>)
                    }
                </MyContext.Consumer >
                //user 2 approves/cancels request
            } else if (this.props.data.requestUser !== null && this.props.data.isApprover && !this.props.data.isRestoreApproved) {
                return (<MyContext.Consumer >
                    {(context) => (
                        <div>

                            <Text> {context.getTranslation("detailContainer", "restoreRequestFromUser")}</Text>
                            <mgt-person show-name={true} user-id={this.props.data.requestUser} person-card="hover" style={{ "text-align": "center", "vertical-align": "middle", "line-height": "40px", "margin-left": "10px" }}></mgt-person>
                            <Text className="align-text-center">{this.props.data.replayRequestUserName}</Text>
                            <br />
                            <Text>{context.getTranslation("detailContainer", "requestRestoreArchiveReason")}: {this.props.data.requestRestoreArchiveReasoningText}</Text>
                            <br />
                            {this.state.graphqlRequestPending
                                ? <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" style={{ "width": "200px" }} />
                                : <>
                                    <Button style={{ "margin-top": "10px", "width": "max-content" }} text={context.getTranslation("detailContainer", "allowReplayArchive")} onClick={this.onApproveArchivedConversation} />
                                    <Button text={context.getTranslation("detailContainer", "clear")} onClick={this.sendClearArchive} />
                                </>}
                        </div>
                    )
                    }
                </MyContext.Consumer >);
                //user 1 sees if call is approved
            } else if (this.props.data.approvedUser && !this.props.data.isApprover) {
                return <MyContext.Consumer >
                    {(context) => (
                        <div>
                            <Text> {context.getTranslation("detailContainer", "restoreRequestApprovedBy")} </Text>
                            <mgt-person show-name={true} user-id={this.props.data.approvedUser} person-card="hover" style={{ "text-align": "center", "vertical-align": "middle", "line-height": "40px", "margin-left": "10px" }}></mgt-person>
                            <Text className="align-text-center">{this.props.data.approvedUserName}</Text>

                        </div>
                    )
                    }
                </MyContext.Consumer >
                //default
            } else {
                return "";
            }


        } else {
            return "";
        }


    }

    displayArchiveStatus = () => {
        if (this.props.data.archiveStatus == 1) {
            return <MyContext.Consumer >
                {(context) => (<div>
                    <Button disabled text={context.getTranslation("detailContainer", "archivedConversation")} style={{ "margin-top": "5px", "width": "max-content" }} />
                </div>)
                }
            </MyContext.Consumer >
        } else if (this.props.data.archiveStatus == 2) {
            return <MyContext.Consumer >
                {(context) => (<div>
                    <Button disabled text={context.getTranslation("detailContainer", "arhiveBeingRestored")} style={{ "margin-top": "5px", "width": "max-content" }} />
                </div>)
                }
            </MyContext.Consumer >
        } else {
            return "";
        }
    }
    onApproveArchivedConversation = () => {
        var answer = window.confirm("Do you really want to retrieve the conversation from archive storage ?");
        if (answer) {
            this.sendApproveArchive();
        }

    };
    sendRequestArchive = () => {
        this.setState({ graphqlRequestPending: true })
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($id : String!,$reasoning : String, $input:AuthInput) {
                    requestRestoreFromArchive (id: $id, reasoning: $reasoning, input: $input){valid}
                }
                `,
                refetchQueries: [{
                    query: queryGetEntry,
                    variables: { id: this.props.data.conversationId, auth: this.props.data.authorization, callStartMs: this.props.data.callStartMs }
                }],
                variables: {
                    id: this.props.data.conversationId,
                    reasoning: this.state.requestRestoreArchiveReasoningText,
                    input: this.props.authorization
                }
            })
            .then(result => { console.log(result); this.setState({ graphqlRequestPending: false }) });

        toast.info('Replay request sent. Waiting for approval', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

        });
    }

    sendApproveArchive = () => {
        this.setState({ graphqlRequestPending: true })
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($id : String!, $input:AuthInput) {
                    approveRestoreArchive (id: $id, input: $input){valid}
                }
                `,
                refetchQueries: [{
                    query: queryGetEntry,
                    variables: { id: this.props.data.conversationId, auth: this.props.data.authorization, callStartMs: this.props.data.callStartMs }
                }],
                variables: {
                    id: this.props.data.conversationId,
                    input: this.props.authorization
                }
            })
            .then(result => { console.log(result); this.setState({ graphqlRequestPending: false }) });
    }

    sendClearArchive = () => {
        this.setState({ graphqlRequestPending: true })
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($id : String!, $input:AuthInput) {
                    clearAttentionArchive (id: $id, input:$input){valid}
                }
                `,
                refetchQueries: [{
                    query: queryGetEntry,
                    variables: { id: this.props.data.conversationId, auth: this.props.data.authorization, callStartMs: this.props.data.callStartMs }
                }],
                variables: {
                    id: this.props.data.conversationId,
                    input: this.props.authorization
                }
            })
            .then(result => { console.log(result); this.setState({ graphqlRequestPending: false }) });
    }

    personaContainer = () => {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="flex-item--persona">
                        <TooltipHost content={context.getTranslation("detailContainer", "personaContainer")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                            <PersonaContainer person={this.props.data.linkedUser} authorization={this.props.authorization} />
                        </TooltipHost>
                    </div>
                )
                }
            </MyContext.Consumer>
        );
    }

    videoMetadata = (replayAllowed) => {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="flex-item--stretch">
                        <div className="video-meta-wrapper">
                            <Stack vertical tokens={{ childrenGap: 5 }}>
                                {this.props.data.archiveStatus != 0 && this.displayArchiveStatus()}
                                {(this.props.data.archiveStatus != 0)
                                    ? this.requestReplayFromArchive()
                                    : this.requestReplay(replayAllowed)
                                }

                                {this.keepOrDelete()}
                                <Text variant='medium'>
                                    <TooltipHost content={context.getTranslation("detailContainer", "dateMessage")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                        {context.getTranslation("detailContainer", "date")}: {this.props.data.day}
                                    </TooltipHost>
                                </Text>

                                <Text variant='medium'>
                                    <TooltipHost content={context.getTranslation("detailContainer", "startMessage")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                        {context.getTranslation("detailContainer", "start")}: {this.props.data.timein}
                                    </TooltipHost>
                                </Text>

                                <Text variant='medium'>
                                    <TooltipHost content={context.getTranslation("detailContainer", "durationMessage")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                        {context.getTranslation("detailContainer", "duration")}: {this.props.data.duration}
                                    </TooltipHost>
                                </Text>

                                {this.props.data.recordingType != null && this.props.data.recordingType !== "Unknown" && <Text variant='medium'>
                                    <TooltipHost content={context.getTranslation("detailContainer", "typeMessage")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                        {context.getTranslation("detailContainer", "type")}: {this.props.data.recordingType}
                                    </TooltipHost>
                                </Text>}

                                <Text variant='medium'>
                                    <TooltipHost content={context.getTranslation("detailContainer", "analyticsMessage")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                        {this.showAnalyticsStatus()}
                                    </TooltipHost>
                                </Text>
                                <Text variant='medium'>{context.getTranslation("detailContainer", "id")}: {this.props.data.conversationId}</Text>
                                <Text variant='medium'>
                                    <TooltipHost content={context.getTranslation("detailContainer", "languageMessage")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                        {this.props.data.unifiedTranscript != null ?
                                            (context.getTranslation("detailContainer", "language") + ': ' + this.props.data.unifiedTranscript.language)
                                            : ""}
                                    </TooltipHost>
                                </Text>


                                {this.props.data.conversationSafe !== false && this.props.userRules.ruleAccess.userSafe !== "No" &&
                                    <Stack.Item>
                                        <HoverCardFolders foldersData={this.props.data.conversationSafeFolders} />
                                    </Stack.Item>
                                }

                                {
                                    this.props.userRules != null && this.props.userRules.ruleAccess != null &&
                                    this.props.userRules.ruleAccess.tenantCreate &&
                                    this.props.data.tenantId != null &&
                                    this.props.data.callId != null &&
                                    this.props.data.conversationId != null &&
                                    this.props.data.userId != null &&
                                    <Stack.Item>
                                        <Button text={this.state.reprocessText} onClick={this.onReprocess} />
                                    </Stack.Item>
                                }
                            </Stack>
                        </div>
                    </div>
                )
                }
            </MyContext.Consumer>);
    }

    videoStream1 = () => {
        if (this.props.data.mediaAssetVideo != null && this.props.data.mediaAssetVideo !== "") {
            return (
                <div className={this.state.bigScreenSharing ? "flex-item--video--big" : "flex-item--video"} >
                    <Stack vertical>
                        <AzurePlayer
                            ref={this.azurePlayerRef1}
                            fluid={true}
                            controls={false}
                            autoplay={window.innerWidth < 820 ? true : false}
                            volume={0.0}
                            offset={this.props.data.participantOffset}
                            streamingUrls={this.props.data.mediaAssetVideo != null ? this.props.data.mediaAssetVideo : (this.props.data.assetStatus === "Critical" ? "error" : this.props.data.mediaAssets)}
                            callbackPlayerLoaded={this.playerLoadedCallback1}
                        />
                    </Stack>
                    <div style={{ "margin-top": "5px" }}>
                        <Button
                            className="enlargeBtn"
                            key={1}
                            onClick={this.moreClicked}
                            iconProps={this.state.bigScreenSharing ? { iconName: 'SnapToGrid' } : { iconName: 'FullScreen' }}
                        />
                    </div>
                </div>);

        }
    }
    moreClicked = () => {
        this.setState({ bigScreenSharing: !this.state.bigScreenSharing });
        this.setState({ bigVideo1: false });
        this.setState({ bigVideo2: false });
    }

    bigVideo1Clicked = () => {
        this.setState({ bigVideo1: !this.state.bigVideo1 });
        this.setState({ bigScreenSharing: false });
        this.setState({ bigVideo2: false });
    }

    bigVideo2Clicked = () => {
        this.setState({ bigVideo2: !this.state.bigVideo2 });
        this.setState({ bigScreenSharing: false });
        this.setState({ bigVideo1: false });

    }

    handlePlayerClick = () => {
        if (!this.state.isPlaying) {
            this.setState({ isPlaying: true });
            this.playerPlay();
        } else {
            this.setState({ isPlaying: false });
            this.playerPause();
        }
    }

    playerControls = () => {
        let wavedata = this.props.data.waveFormAnalysis;
        let audioSegments = (wavedata === null || wavedata === undefined) ? "" : wavedata.rmsAudioSegments;

        if ((this.props.data.mediaAssets != null && this.props.data.mediaAssets != "") || (this.props.data.mediaAssetVideo != null && this.props.mediaAssetVideo != "")) {
            return (
                <div className="flex-item--player">
                    <Stack horizontal align="stretch">
                        {(wavedata !== null || wavedata !== "") && (audioSegments !== null && audioSegments !== "")
                            ? <Icon className={window.innerWidth < 600 ? "play-pause-layout-small-screen with-wave-layout" : "play-pause-layout with-wave-layout"} iconName={this.state.isPlaying ? "Pause" : "Play"} onClick={this.handlePlayerClick} />
                            : <Icon className={window.innerWidth < 600 ? "play-pause-layout-small-screen without-wave-layout" : "play-pause-layout without-wave-layout"} iconName={this.state.isPlaying ? "Pause" : "Play"} onClick={this.handlePlayerClick} />
                        }
                        {/*{(wavedata !== null || wavedata !== "") && (wavedata.rmsAudioSegments !== null && wavedata.rmsAudioSegments !== "")*/}
                        {/*    ? <Stack.Item grow="10" className="wave-container">*/}
                        {/*        <Slider className="detail-container-slider"*/}
                        {/*            ref={this.playerSlider}*/}
                        {/*            label=""*/}
                        {/*            min={0}*/}
                        {/*            max={this.getDurationInSeconds() - 1}*/}
                        {/*            valueFormat={(value) => {*/}
                        {/*                var h = Math.floor(value / 3600);*/}
                        {/*                var m = Math.floor((value % 3600) / 60);*/}
                        {/*                var s = (value % 3600) % 60;*/}
                        {/*                return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);*/}
                        {/*            }}*/}
                        {/*            step={1}*/}
                        {/*            value={this.state.playerValue}*/}
                        {/*            showValue={true}*/}
                        {/*            onChange={(value) => this.changeCurrentTime(value)}*/}
                        {/*        />*/}
                        {/*        <div className="wave-bar-display">*/}
                        {/*            <WaveformAudio*/}
                        {/*                waveData={wavedata}*/}
                        {/*                audioSegments={audioSegments}*/}
                        {/*                sentiments={(this.props.data.summarizedInsights != null && this.props.data.summarizedInsights.sentiments != null) ? this.props.data.summarizedInsights.sentiments : undefined}*/}
                        {/*                data={this.props.data}*/}
                        {/*                listener={this.changeCurrentTime}*/}
                        {/*                ref={this.playerSlider}*/}
                        {/*                min={0}*/}
                        {/*                max={this.getDurationInSeconds() - 1}*/}
                        {/*                value={this.state.playerValue}*/}
                        {/*                valueFormat={(value) => {*/}
                        {/*                    { var h = Math.floor(value / 3600); }*/}
                        {/*                    { var m = Math.floor((value % 3600) / 60); }*/}
                        {/*                    { var s = (value % 3600) % 60; }*/}
                        {/*                    { return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s); }*/}

                        {/*                }}*/}
                        {/*                duration={this.getDurationInSeconds()}*/}
                        {/*                step={this.props.data.waveFormAnalysis.stepCountAudioSegments}*/}
                        {/*                onChange={(value) => this.changeCurrentTime(value)}*/}
                        {/*                isPlaying={this.state.isPlaying}*/}
                        {/*            />*/}
                        {/*        </div>*/}

                        {/*    </Stack.Item>*/}


                        {/*    : <Stack.Item grow="10">*/}
                        {/*        <Slider className="detail-container-slider-with-no-wave"*/}
                        {/*            ref={this.playerSlider}*/}
                        {/*            label=""*/}
                        {/*            min={0}*/}
                        {/*            max={this.getDurationInSeconds() - 1}*/}
                        {/*            valueFormat={(value) => {*/}
                        {/*                var h = Math.floor(value / 3600);*/}
                        {/*                var m = Math.floor((value % 3600) / 60);*/}
                        {/*                var s = (value % 3600) % 60;*/}
                        {/*                return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);*/}
                        {/*            }}*/}
                        {/*            step={1}*/}
                        {/*            value={this.state.playerValue}*/}
                        {/*            showValue={true}*/}
                        {/*            onChange={(value) => this.changeCurrentTime(value)}*/}
                        {/*        />*/}

                        {/*        {this.rendEmote()}*/}
                        {/*    </Stack.Item>*/}

                        {/*}*/}

                        {/*THIS COMMENTED PART IS USED FOR THE CUSTOM MADE SLIDER WHICH AT THE MOMENT IS REPLACED WITH THE OLD SLIDER*/}



                        {/*THIS IS THE ORIGINAL PLAYER SLIDER*/}

                        {/*<Slider className="detail-container-slider"*/}
                        {/*    ref={this.playerSlider}*/}
                        {/*    label=""*/}
                        {/*    min={0}*/}
                        {/*    max={this.getDurationInSeconds() - 1}*/}
                        {/*    valueFormat={(value) => {*/}
                        {/*        var h = Math.floor(value / 3600);*/}
                        {/*        var m = Math.floor((value % 3600) / 60);*/}
                        {/*        var s = (value % 3600) % 60;*/}
                        {/*        return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);*/}
                        {/*    }}*/}
                        {/*    step={1}*/}
                        {/*    value={this.state.playerValue}*/}
                        {/*    showValue={true}*/}
                        {/*    onChange={(value) => this.changeCurrentTime(value)}*/}
                        {/*/>*/}
                        {(wavedata !== null && wavedata !== "") && (wavedata.rmsAudioSegments !== null && wavedata.rmsAudioSegments !== "")
                            ? <Stack.Item grow="10">
                                <WaveformAudio
                                    waveData={wavedata}
                                    audioSegments={wavedata.rmsAudioSegments}
                                    sentiments={(this.props.data.summarizedInsights != null && this.props.data.summarizedInsights.sentiments != null) ? this.props.data.summarizedInsights.sentiments : undefined}
                                    data={this.props.data}
                                    listener={this.changeCurrentTime}
                                    min={0}
                                    max={this.getDurationInSeconds() - 1}
                                    value={this.state.playerValue}
                                    valueFormat={(value) => {
                                        { var h = Math.floor(value / 3600); }
                                        { var m = Math.floor((value % 3600) / 60); }
                                        { var s = (value % 3600) % 60; }
                                        { return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s); }

                                    }}
                                    duration={this.getDurationInSeconds()}
                                    step={this.props.data.waveFormAnalysis.stepCountAudioSegments}
                                    onChange={(value) => this.changeCurrentTime(value)}
                                    isPlaying={this.state.isPlaying}
                                    keywords={(this.props.data.summarizedInsights != null && this.props.data.summarizedInsights.keywords != null) ? this.props.data.summarizedInsights.keywords : []}
                                    stageHeight={100}
                                    sliderHeight={37}
                                    policiesData={this.state.policyDate}
                                />
                            </Stack.Item>

                            : <Stack.Item grow="10" className="wave-container">
                                <WaveformAudio
                                    waveData={""}
                                    audioSegments={""}
                                    sentiments={(this.props.data.summarizedInsights != null && this.props.data.summarizedInsights.sentiments != null) ? this.props.data.summarizedInsights.sentiments : undefined}
                                    data={this.props.data}
                                    listener={this.changeCurrentTime}
                                    min={0}
                                    max={this.getDurationInSeconds() - 1}
                                    value={this.state.playerValue}
                                    valueFormat={(value) => {
                                        { var h = Math.floor(value / 3600); }
                                        { var m = Math.floor((value % 3600) / 60); }
                                        { var s = (value % 3600) % 60; }
                                        { return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s); }

                                    }}
                                    duration={this.getDurationInSeconds()}
                                    step={""}
                                    onChange={(value) => this.changeCurrentTime(value)}
                                    isPlaying={this.state.isPlaying}
                                    keywords={""}
                                    stageHeight={50}
                                    sliderHeight={0}
                                    policiesData={""}
                                />
                                {this.rendEmote()}

                            </Stack.Item>
                        }

                    </Stack>

                </div >);
        }
    }


    videoStream2 = () => {
        // Check videos from participants
        let videoFrame2User = -1;
        let videoFrame3User = -1;
        if (this.props.data.linkedUser != null && this.props.data.linkedUser.length > 0) {
            this.props.data.linkedUser.map(({ userId, name, detail, photo, type, videoAsset, audioAsset }, i) => {
                if (videoAsset != null && videoAsset != "") {
                    if (videoFrame2User === -1) {
                        videoFrame2User = i;
                    } else if (videoFrame3User === -1) {
                        videoFrame3User = i;
                    }
                }
            });
        }
        if (this.props.data.participantStreams && videoFrame2User !== -1) {
            var owner2 = this.props.data.linkedUser[videoFrame2User];

            return (
                <div className={this.state.bigVideo1 ? "flex-item--video--big" : "flex-item--video"}>
                    <Stack vertical tokens={{ childrenGap: 5 }}>

                        <AzurePlayer
                            ref={this.azurePlayerRef2}
                            fluid={true}
                            controls={false}
                            autoplay={window.innerWidth < 820 ? true : false}
                            volume={0.0}
                            offset={this.props.data.participantOffset}
                            streamingUrls={this.props.data.linkedUser[videoFrame2User].videoAsset}
                            callbackPlayerLoaded={this.playerLoadedCallback2}
                        />

                        <Stack horizontal tokens={{ childrenGap: 0 }}>
                            <Button
                                className="enlargeBtn"
                                key={2}
                                onClick={this.bigVideo1Clicked}
                                iconProps={this.state.bigVideo1 ? { iconName: 'SnapToGrid' } : { iconName: 'FullScreen' }}
                            />
                            <ParticipantsDropdown
                                dropdownOptions={this.props.data.linkedUser.map(({ userId, name, detail, photo, type, videoAsset, audioAsset }, i) => (
                                    this.getParticipant(i, userId, name, detail, photo, type, videoAsset, audioAsset, 2)

                                ))}
                                id="stream2"
                                theme={this.props.theme}
                                defaultPlayer={this.getParticipant(0, owner2.userId, owner2.name, owner2.detail, owner2.photo, owner2.type, owner2.videoAsset, owner2.audioAsset, 2)}
                            />

                        </Stack>


                    </Stack>
                </div>);
        } else {
            return;
        }
    }

    getParticipant = (i, userId, name, detail, photo, type, videoAsset, audioAsset, cnt) => {
        if (videoAsset != null && cnt === 2)
            return <Persona key={userId} size={PersonaSize.size24} hidden={videoAsset != null ? false : true} text={name} imageUrl={"data:image/jpeg;base64," + photo} presence={this.state.stream2 === videoAsset ? PersonaPresence.online : PersonaPresence.none} secondaryText="" onClick={() => { this.changeSource2(videoAsset); }} />
        else if (videoAsset != null && cnt === 3)
            return <Persona key={userId} size={PersonaSize.size24} hidden={videoAsset != null ? false : true} text={name} imageUrl={"data:image/jpeg;base64," + photo} presence={this.state.stream3 === videoAsset ? PersonaPresence.online : PersonaPresence.none} secondaryText="" onClick={() => { this.changeSource3(videoAsset); }} />
        else
            return <></>
    }

    videoStream3 = () => {
        // Check videos from participants
        let videoFrame2User = -1;
        let videoFrame3User = -1;
        if (this.props.data.linkedUser != null && this.props.data.linkedUser.length > 0) {
            this.props.data.linkedUser.map(({ userId, name, detail, photo, type, videoAsset, audioAsset }, i) => {
                if (videoAsset != null && videoAsset != "") {
                    if (videoFrame2User === -1) {
                        videoFrame2User = i;
                    } else if (videoFrame3User === -1) {
                        videoFrame3User = i;
                    }
                }
            });
        }
        if (this.props.data.participantStreams && videoFrame3User !== -1) {
            var owner3 = this.props.data.linkedUser[videoFrame3User]


            return (
                <div className={this.state.bigVideo2 ? "flex-item--video--big" : "flex-item--video"}>
                    <Stack vertical tokens={{ childrenGap: 5 }}>

                        <AzurePlayer
                            ref={this.azurePlayerRef3}
                            fluid={true}
                            controls={false}
                            autoplay={window.innerWidth < 820 ? true : false}
                            volume={0.0}
                            offset={this.props.data.participantOffset}
                            streamingUrls={this.props.data.linkedUser[videoFrame3User].videoAsset}
                            callbackPlayerLoaded={this.playerLoadedCallback3}
                        />

                        <Stack horizontal tokens={{ childrenGap: 0 }}>
                            <Button
                                className="enlargeBtn"
                                key={1}
                                onClick={this.bigVideo2Clicked}
                                iconProps={this.state.bigVideo2 ? { iconName: 'SnapToGrid' } : { iconName: 'FullScreen' }}
                            />
                            <ParticipantsDropdown
                                id="stream3"
                                theme={this.props.theme}
                                dropdownOptions={this.props.data.linkedUser.map(({ userId, name, detail, photo, type, videoAsset, audioAsset }, i) => (
                                    this.getParticipant(i, userId, name, detail, photo, type, videoAsset, audioAsset, 3)
                                ))}
                                defaultPlayer={this.getParticipant(0, owner3.userId, owner3.name, owner3.detail, owner3.photo, owner3.type, owner3.videoAsset, owner3.audioAsset, 3)}
                            />

                        </Stack>
                    </Stack>
                </div>);
        } else {
            return;
        }
    }
    videoStreamAudio = () => {
        return (
            <div className="flex-item--video" hidden={true}>
                <AzurePlayer
                    ref={this.azurePlayerRefAudio}
                    fluid={true}
                    controls={false}
                    autoplay={false}
                    volume={1}
                    offset={0}
                    streamingUrls={(this.props.data.mediaAssets != null && this.props.data.mediaAssets !== "") ? this.props.data.mediaAssets : this.props.data.mediaAssetVideo}
                    callbackPlayerLoaded={this.playerLoadedCallbackAudio}
                    callbackTimeUpdate={this.playerLoadedCallbackTimeUpdate}
                />
            </div>);
    }
    rendPivot = () => {
        if (this.props.loading != null) {
            return <div></div>
        } else {
            return <div className="flex-item--controles">

                <DetailPivot
                    apol={this.props.apol}
                    authorization={this.props.authorization}
                    listener={this.changeCurrentTime}
                    data={this.props.data}
                    hidePanel={this.props.hidePanel}
                    height={window.innerHeight - this.state.heightTop - 195}
                    userRules={this.props.userRules}
                    refreshFolderList={this.props.refreshFolderList}
                    updatePolicyResults={this.updatePolicyResults}
                    theme={this.props.theme}
                />
            </div>
        }

    }

    //displays old wave nd emotion for older calls
    rendEmote = () => {
        switch (this.props.type) {
            case "delete":
                break;
            case undefined:
            case null:
            default:
                return (
                    <MyContext.Consumer>
                        {(context) => (
                            <div className="flex-item--emotion">

                                <TooltipHost content={context.getTranslation("detailContainer", "sentimentBarOfWholeConversation")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                    {this.showEmotion()}
                                </TooltipHost>

                            </div>
                        )
                        }
                    </MyContext.Consumer>
                );

        }
    }

    rendHeader = () => {
        return <>
            <header className="panel-header ms-Grid-row">

                {/* == Recording Header ========================================= */}

                <div className="panel-title ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Text variant='large'>{this.props.data.topic}</Text>
                </div>

            </header>
        </>
    }

    keepOrDelete = () => {
        //if is in keep delete state
        var hasTimer = false;//in case we add the countdown
        if (this.props.userRules.ruleRecording !== null && this.props.userRules.ruleRecording.keepDeleteSettings !== null && this.props.data !== null) {
            if (this.props.data.keepDeleteAllowed && this.props.userRules.ruleRecording.keepDeleteSettings.isSendToDo) {
                return (<MyContext.Consumer >
                    {(context) => (
                        <>
                            {this.state.graphqlRequestPending
                                ? <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" style={{ "width": "200px" }} />
                                : <>
                                    {(this.props.data.deleteState !== null && this.props.data.deleteState !== undefined) &&
                                        <MessageBar
                                            messageBarType={MessageBarType.info}
                                            isMultiline={false}
                                            dismissButtonAriaLabel="Close"
                                        >
                                            <span>{this.props.data.deleteState} state selected</span>
                                        </MessageBar>}
                                    <div className="align-keep-delete-btn">
                                        <PrimaryButton style={{ "margin-top": "10px" }} text={context.getTranslation("detailContainer", "keepRecording")} onClick={() => { this.sendKeepOrDelete("Keep", "Conversation Kept") }} />
                                        <Button text={context.getTranslation("detailContainer", "deleteRecording")} style={{ color: 'white', backgroundColor: 'red', marginLeft: "10px" }} onClick={() => { this.sendKeepOrDelete("Delete", "Conversation Deleted") }} />
                                        {hasTimer && <p style={{ marginLeft: "10px" }}>1,5 h time left for decision</p>}
                                    </div>
                                </>
                            }
                        </>
                    )
                    }
                </MyContext.Consumer >);

            } else if (this.props.data.deleteState !== null && !this.props.data.keepDeleteAllowed) {
                return (<MessageBar
                    messageBarType={MessageBarType.info}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                >
                    <span>{this.props.data.deleteState} state selected</span>
                </MessageBar>);
            }
        }
        return;
    }


    sendKeepOrDelete = (operation, text) => {
        this.setState({ graphqlRequestPending: true })
        var date = Date.now().toString();

        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($input:AuthInput $id : String!, $date: String, $operation: String, $scenarioId: String, $botAppId: String, $callId: String, $userId: String, $isLive:Boolean) {
                    setKeepDeleteSliderInfo (input: $input, id: $id, date: $date, operation: $operation, scenarioId: $scenarioId, botAppId: $botAppId, callId: $callId, userId: $userId, isLive:$isLive)
                }
                `,
                variables: {
                    id: this.props.data.id,
                    date: date,
                    operation: operation,
                    scenarioId: this.props.data.scenarioId,
                    botAppId: this.props.data.botAppId,
                    callId: this.props.data.callId,
                    userId: this.props.data.userId,
                    input: this.props.authorization,
                    isLive: false
                }
            })

        this.setState({ graphqlRequestPending: false, keepDeleteClicked: true, keepDeleteMessage: text });
        //to hide panel after operation completed
        //this.props.hidePanel();

    }
}


const ParticipantsDropdown = ({ dropdownOptions, id, defaultPlayer, theme }) => {
    const mappedDropdownOptions = dropdownOptions.map((option, i) => {
        return (
            <li style={{ backgroundColor: theme.palette.neutralLighterAlt }} key={i} name={id}><a>{option}</a></li>
        )
    });
    var click = true;
    $('div.dropdownCustom[name=' + id + ']').on('click', function (e) {
        e.preventDefault();
        if (click) {
            $('.optionCustom[name=' + id + ']').stop().slideDown(300);
            click = false;
        } else {
            $('.optionCustom[name=' + id + ']').stop().slideUp(300);
            click = true;
        }

    });

    $('.optionCustom li[name=' + id + ']').on('click', function (e) {
        e.preventDefault();

        var option_value = ($(this).find('a').clone()).html();
        $('.dropdownCustom span[name=' + id + ']').html(option_value);
        $('.optionCustom[name=' + id + ']').slideUp(300);
        click = true;
    });

    if ($("div").hasClass("flex-item--video--big")) {
        $(".optionCustom").addClass("bigScreen");
    } else {
        $(".optionCustom").removeClass("bigScreen");
    }

    return (<>
        <div>
            <div className="dropdownCustom" style={{ backgroundColor: theme.palette.neutralLighterAlt }} name={id}>
                <span><i className="ms-Icon ms-Icon--ChevronDownMed"></i></span>

                <span name={id}>
                    {defaultPlayer}
                </span>

            </div>

            <ul className="optionCustom" name={id}>
                {mappedDropdownOptions}
            </ul>
        </div>

    </>)

}











//HOMEMADE SLIDER IN CASE IS NEEDED
{/*{(wavedata !== null || wavedata !== "") && (audioSegments !== null && audioSegments !== "")*/ }
{/*    ? <Stack.Item grow="10" className="wave-container">*/ }
{/*        <Slider className="detail-container-slider"*/ }
{/*            ref={this.playerSlider}*/ }
{/*            label=""*/ }
{/*            min={0}*/ }
{/*            max={this.getDurationInSeconds() - 1}*/ }
{/*            valueFormat={(value) => {*/ }
{/*                var h = Math.floor(value / 3600);*/ }
{/*                var m = Math.floor((value % 3600) / 60);*/ }
{/*                var s = (value % 3600) % 60;*/ }
{/*                return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);*/ }
{/*            }}*/ }
{/*            step={1}*/ }
{/*            value={this.state.playerValue}*/ }
{/*            showValue={true}*/ }
{/*            onChange={(value) => this.changeCurrentTime(value)}*/ }
{/*        />*/ }
{/*        <div className="wave-bar-display">*/ }
{/*            <WaveformAudio*/ }
{/*                waveData={wavedata}*/ }
{/*                audioSegments={audioSegments}*/ }
{/*                sentiments={(this.props.data.summarizedInsights != null && this.props.data.summarizedInsights.sentiments != null) ? this.props.data.summarizedInsights.sentiments : undefined}*/ }
{/*                data={this.props.data}*/ }
{/*                listener={this.changeCurrentTime}*/ }
{/*                ref={this.playerSlider}*/ }
{/*                min={0}*/ }
{/*                max={this.getDurationInSeconds() - 1}*/ }
{/*                value={this.state.playerValue}*/ }
{/*                valueFormat={(value) => {*/ }
{/*                    { var h = Math.floor(value / 3600); }*/ }
{/*                    { var m = Math.floor((value % 3600) / 60); }*/ }
{/*                    { var s = (value % 3600) % 60; }*/ }
{/*                    { return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s); }*/ }

{/*                }}*/ }
{/*                duration={this.getDurationInSeconds()}*/ }
{/*                step={this.props.data.waveFormAnalysis.stepCountAudioSegments}*/ }
{/*                onChange={(value) => this.changeCurrentTime(value)}*/ }
{/*                isPlaying={this.state.isPlaying}*/ }
{/*            />*/ }
{/*        </div>*/ }

{/*    </Stack.Item>*/ }


{/*    : <Stack.Item grow="10">*/ }
{/*        <Slider className="detail-container-slider-with-no-wave"*/ }
{/*            ref={this.playerSlider}*/ }
{/*            label=""*/ }
{/*            min={0}*/ }
{/*            max={this.getDurationInSeconds() - 1}*/ }
{/*            valueFormat={(value) => {*/ }
{/*                var h = Math.floor(value / 3600);*/ }
{/*                var m = Math.floor((value % 3600) / 60);*/ }
{/*                var s = (value % 3600) % 60;*/ }
{/*                return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);*/ }
{/*            }}*/ }
{/*            step={1}*/ }
{/*            value={this.state.playerValue}*/ }
{/*            showValue={true}*/ }
{/*            onChange={(value) => this.changeCurrentTime(value)}*/ }
{/*        />*/ }

{/*        {this.rendEmote()}*/ }
{/*    </Stack.Item>*/ }

{/*}*/ }

{/*THIS COMMENTED PART IS USED FOR THE CUSTOM MADE SLIDER WHICH AT THE MOMENT IS REPLACED WITH THE OLD SLIDER*/ }



{/*THIS IS THE ORIGINAL PLAYER SLIDER*/ }

{/*<Slider className="detail-container-slider"*/ }
{/*    ref={this.playerSlider}*/ }
{/*    label=""*/ }
{/*    min={0}*/ }
{/*    max={this.getDurationInSeconds() - 1}*/ }
{/*    valueFormat={(value) => {*/ }
{/*        var h = Math.floor(value / 3600);*/ }
{/*        var m = Math.floor((value % 3600) / 60);*/ }
{/*        var s = (value % 3600) % 60;*/ }
{/*        return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);*/ }
{/*    }}*/ }
{/*    step={1}*/ }
{/*    value={this.state.playerValue}*/ }
{/*    showValue={true}*/ }
{/*    onChange={(value) => this.changeCurrentTime(value)}*/ }
{/*/>*/ }

