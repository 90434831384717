import * as React from 'react';
import { Dialog, DialogType, DialogFooter, TextField, Stack, Dropdown, ChoiceGroup, Spinner, FontSizes, ComboBox, SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib';
import { CommandBarButton, PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { hiddenContentStyle, mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import * as Azure from "@azure/storage-blob";
import { saveAs } from 'file-saver';
import gql from "graphql-tag";
import { MyContext } from '../../../../../context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Query } from 'react-apollo'
import { getAllFoldersShrinked, bulkFoldersMutation } from '../../../../ConversationSafe/ConversationSafeQuery'
import { queryGetFilterRecordings } from '../../../../../queries/queryRecordings'
import _ from 'lodash'

const screenReaderOnly = mergeStyles(hiddenContentStyle);

export default class DownloadDialog extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            hideDialog: true,
            isDraggable: true,
            subTitle: <MyContext.Consumer>{(context) => (<> {this.props.count != undefined ? (this.props.count + " " + context.getTranslation("common", "conversations")) : ""} </>)}</MyContext.Consumer>,
            buttonText: "Start",
            buttonDisabled: (this.props.bulkExport != null || this.props.bulkData != null) ? true : false,
            downloadType: this.props.renderFromCompliance ? "" : "video",
            downloadActive: false,
            zipPassword: "",
            zipPasswordDuplicate: "",
            bulkName: "bulkDownload",
            bulkOperation: 'bulkOperation',
            bulkOperationType: 'bulkOperation',
            selectedOptionFolders: [],
            step1: this.props.renderFromCompliance ? true : null,
            csRemoveConfirmationStep: false,
            downloadTypeChoiceMade: false,
            isArchived: false,
            archiveCheckOk: true,
        };
        // Use getId() to ensure that the IDs are unique on the page.
        // (It's also okay to use plain strings without getId() and manually ensure uniqueness.)
        this._labelId = getId('dialogLabel');
        this._subTextId = getId('subTextLabel');
        this._dragOptions = {
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu,
        };
    }


    render = () => {
        const { hideDialog, isDraggable } = this.state;
        
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>
                        {this.props.bulkOperation ? <Dropdown
                            selectedKey={this.state.bulkOperation}
                            onChange={this._showDialog}
                            style={{ width: "auto" }}
                            options={[
                                {
                                    key: 'bulkOperation',
                                    text: context.getTranslation('configuration', 'bulkOperation'),
                                    hidden: true
                                },
                                {
                                    key: 'bulkExport',
                                    text: context.getTranslation('configuration', 'bulkExport'),
                                    hidden: !this.props.userRules.ruleAccess.userBulkExport,
                                    disabled: this.props.count > 200
                                },
                                {
                                    key: 'bulkExportTranscript',
                                    text: context.getTranslation('configuration', 'bulkExportTranscript'),
                                    hidden: !this.props.userRules.ruleAccess.userBulkExport,
                                    disabled: this.props.count > 800
                                },
                                {
                                    key: 'bulkExportMetadata',
                                    text: context.getTranslation("configuration", "bulkExportMetadata"),
                                    hidden: !this.props.userRules.ruleAccess.userBulkExport,
                                    disabled: this.props.count > 800
                                },
                                {
                                    key: 'bulkTranscript',
                                    text: context.getTranslation("configuration", "bulkTranscript"),
                                    hidden: !this.props.userRules.ruleAccess.userBulkTranscript,
                                    disabled: this.props.count > 800
                                },
                                {
                                    key: 'bulkDelete',
                                    text: context.getTranslation('configuration', 'bulkDelete'),
                                    hidden: !this.props.userRules.ruleAccess.userBulkDelete || this.props.userRules.blockDeletion,
                                    disabled: this.props.count > 800
                                },
                                {
                                    key: 'addToFolders',
                                    text: context.getTranslation("configuration", "addToFoldersBulk"),
                                    hidden: this.props.userRules.ruleAccess.userSafe === "No" || !this.props.userRules.ruleAccess.userBulkSafeAdd,
                                    disabled: this.props.count > 800
                                },
                                {
                                    key: 'removeToFolders',
                                    text: context.getTranslation("configuration", "removeToFoldersBulk"),
                                    hidden: this.props.userRules.ruleAccess.userSafe === "No" || !this.props.userRules.ruleAccess.userBulkSafeRemove,
                                    disabled: this.props.count > 800
                                },
                                {
                                    key: 'bulkRestoreArchive',
                                    text: context.getTranslation("configuration", "bulkRestoreArchive"),
                                    hidden: !this.props.userRules.ruleAccess.userBulkRestoreArchive,
                                    disabled: this.props.count > 800
                                }

                            ]}
                        /> :

                            <CommandBarButton
                                disabled={this.props.disableForMenuItemsCompliance}
                                iconProps={{ iconName: 'Download' }}
                                style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon, width: "100%", textAlign: "left" }}
                                onClick={this._showDialogCommandBar}
                                text={window.innerWidth < 600 ? (this.props.bulkTranscript ? context.getTranslation("detailContainer", "tr") : context.getTranslation("detailContainer", "exp")) : (this.props.bulkTranscript ? context.getTranslation("detailContainer", "transcript") : context.getTranslation("detailContainer", "exportButton"))} />}

                        <label id={this._labelId} className={screenReaderOnly}>
                            {context.getTranslation("detailContainer", "exportDialog")}
                        </label>

                        <label id={this._subTextId} className={screenReaderOnly}>
                            {this.state.subTitle}
                        </label>

                        <Dialog
                            hidden={hideDialog}
                            onDismiss={this._closeDialog}
                            dialogContentProps={{
                                type: DialogType.normal,
                                title: this.props.bulkOperation
                                    ? context.getTranslation("configuration", "bulkOperation") + ": " + context.getTranslation("configuration", this.state.bulkOperationType)
                                    : context.getTranslation("detailContainer", "exportDialogTitle"),
                                closeButtonAriaLabel: 'Close',
                                subText: this.state.subTitle,
                            }}
                            modalProps={{
                                titleAriaId: this._labelId,
                                subtitleAriaId: this._subTextId,
                                isBlocking: false,
                                styles: { main: { maxWidth: 450 } },
                                dragOptions: isDraggable ? this._dragOptions : undefined,
                            }}
                        >
                            {this.state.downloadActive && <Spinner />}
                            {((!this.props.bulkOperation && !this.props.renderFromCompliance) || (this.state.step1 && this.props.renderFromCompliance)) &&
                                <ChoiceGroup
                                    selectedKey={this.state.downloadType}
                                    options={[
                                        {
                                            key: 'audio',
                                            text: context.getTranslation('detailContainer', 'exportDialogOption1'),
                                        },
                                        {
                                            key: 'video',
                                            text: context.getTranslation('detailContainer', 'exportDialogOption3'),
                                        },
                                        {
                                            key: 'metadata',
                                            text: context.getTranslation('detailContainer', 'exportDialogOption2'),
                                        },
                                        {
                                            key: 'transcript',
                                            text: context.getTranslation('detailContainer', 'exportDialogOption4'),
                                        },
                                    ]}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            downloadType: newValue.key,
                                            downloadTypeChoiceMade: true,
                                        })
                                    }}
                                />}
                            {(this.props.bulkOperation || this.props.renderFromCompliance) &&
                                <Stack>
                                    {(this.state.bulkOperationType === "bulkExport" || this.state.bulkOperationType === "bulkRestoreArchive") && <MyContext.Consumer>{(context) => (
                                        <Stack>
                                            {this.state.streamCount > 0 && <Label> {this.state.streamCount + "  streams with " + (this.state.streamSize / 1024 / 1024).toFixed(2) + " MB"}</Label>}
                                            {this.state.archiveCount > 0 && <Label> {this.state.archiveCount + " archived streams with " + (this.state.archiveSize / 1024 / 1024).toFixed(2) + " MB"}</Label>}
                                            {this.state.archivePercentage > 0.1 && <Label> amount of archived data exceeds maximum allowed volume</Label>}
                                            {(this.state.bulkOperationType === "bulkRestoreArchive" && this.state.archiveCount === 0) && <Label> no archived conversations</Label>}
                                        </Stack>)}</MyContext.Consumer>}
                                    {(this.state.bulkOperationType === "bulkExport"
                                        || this.state.bulkOperationType === "bulkExportTranscript"
                                        || this.state.bulkOperationType === "bulkExportMetadata"
                                        || (this.props.renderFromCompliance && this.state.step2))
                                        &&
                                        <Stack>               
                                            <TextField
                                                min={0} value={this.state.zipPassword}
                                                type="Password"
                                                label={context.getTranslation('detailContainer', 'zipPassword')}
                                                onChange={(event) => {
                                                    if (event.target.value.length < this.props.passwordLength) {
                                                        this.setState({
                                                            buttonDisabled: true,
                                                            zipPassword: event.target.value
                                                        })
                                                    } else {
                                                        this.setState({
                                                            buttonDisabled: this.state.zipPasswordDuplicate !== event.target.value
                                                                ? true
                                                                : false,
                                                            zipPassword: event.target.value
                                                        })
                                                    }
                                                }}
                                                errorMessage={
                                                    this.state.zipPassword.length < this.props.passwordLength
                                                        ? context.getTranslation("detailContainer", "zipPasswordMinimalLength") + this.props.passwordLength
                                                        : ""}
                                            />
                                            <TextField
                                                min={0} value={this.state.zipPasswordDuplicate}
                                                type="Password"
                                                label={context.getTranslation("detailContainer", "zipPasswordCheck")}
                                                onChange={(event) => {
                                                    if (event.target.value !== this.state.zipPassword) {
                                                        this.setState({
                                                            buttonDisabled: true,
                                                            zipPasswordDuplicate: event.target.value
                                                        })
                                                    }
                                                    else {
                                                        this.setState({
                                                            buttonDisabled: this.state.zipPassword.length < this.props.passwordLength ? true : false,
                                                            zipPasswordDuplicate: event.target.value
                                                        })
                                                    }
                                                }}
                                                errorMessage={this.state.zipPasswordDuplicate !== this.state.zipPassword ? "Password not matching" : ""}
                                            />
                                            <TextField
                                                value={this.state.bulkName}
                                                label={context.getTranslation("detailContainer", "bulkName")}
                                                onChange={(event) => {
                                                    this.setState({
                                                        bulkName: event.target.value
                                                    }
                                                    )
                                                }}
                                            />
                                        </Stack>}
                                    {(this.state.bulkOperationType === "addToFolders" || this.state.bulkOperationType === "removeToFolders") &&
                                        <Stack>
                                            {!this.state.csRemoveConfirmationStep && <Query
                                                query={getAllFoldersShrinked}
                                                variables={{ input: this.props.authorization }}>
                                                {({ loading, error, data }) => {
                                                    if (loading) {
                                                        return (
                                                            <div>
                                                                <Spinner label="Still loading..." ariaLive="assertive"
                                                                         labelPosition="top"/>
                                                            </div>
                                                        )
                                                    }
                                                    if (error) return <h4>Failed to connect</h4>
            
                                                    let foldersOptionHeader = [
                                                        {
                                                            key: 'Header1',
                                                            text: `${context.getTranslation('filterPanel', 'convSafe')}`,
                                                            itemType: SelectableOptionMenuItemType.Header
                                                        },
                                                        {
                                                            key: 'selectAll',
                                                            text: `${context.getTranslation('filterPanel', 'selectAll')}`
                                                        },
                                                        {
                                                            key: 'divider',
                                                            text: '-',
                                                            itemType: SelectableOptionMenuItemType.Divider
                                                        }
                                                    ]
            
                                                    let availableFolders = this.filterFolderRights(
                                                        data.getConversationSafeFolders,
                                                        this.state.bulkOperationType === 'addToFolders'
                                                            ? 'addConversations'
                                                            : 'removeConversations')
            
                                                    let options = _.concat(
                                                        foldersOptionHeader,
                                                        availableFolders.map(f => {
                                                            return ({
                                                                key: f.id,
                                                                text: f.name,
                                                                itemType: SelectableOptionMenuItemType.Normal
                                                            })
                                                        }))
                                                    this.foldersOption = availableFolders

                                                    return (
                                                        <ComboBox
                                                            id={'combobox'}
                                                            label={`${context.getTranslation('filterPanel', 'convSafe')}`}
                                                            selectedKey={this.state.selectedOptionFolders}
                                                            onChange={this.onChangeFolders}
                                                            options={options}
                                                            multiSelect
                                                            calloutProps={{ doNotLayer: false }}
                                                        />
                                                    )
                                                }
                                                }
                                            </Query>}
                                            {this.state.csRemoveConfirmationStep
                                                && <div>{context.getTranslation('detailContainer', 'warningDeleteFromFolders')}</div>
                                            }
                                            

                                        </Stack>
                                    }
                                </Stack>
                            }
                            <DialogFooter>
                                {this.props.renderFromCompliance && this.state.step1 &&
                                    <PrimaryButton
                                        disabled={!this.state.downloadTypeChoiceMade}
                                        onClick={this.goToStep2}
                                        text={context.getTranslation('detailContainer', 'next')}
                                    />
                                }
                                {((this.props.renderFromCompliance && this.state.step2) || !this.props.renderFromCompliance) &&
                                    <PrimaryButton
                                        disabled={this.state.buttonDisabled || !this.state.archiveCheckOk}
                                        onClick={this._onExportAudio}
                                        text={context.getTranslation('detailContainer', 'exportstart')}
                                    />
                                }
                                <DefaultButton
                                    onClick={this._closeDialog}
                                    text={context.getTranslation("common", "close")}
                                />
                            </DialogFooter>
                        </Dialog>
                    </div>)}
            </MyContext.Consumer>
        );
    }

    onChangeFolders = (
        event,
        option,
        index,
        value
    ) => {

        let selectableOptions = this.foldersOption
        const selected = option?.selected
        let selectedOptions = this.state.selectedOptionFolders
        const currentSelectedOptionKeys = selectedOptions

        const selectAllState = selectedOptions.filter(k => k !== 'selectAll').length === (selectableOptions.filter(o => !o.disabled).length);

        if (option) {
            if (option.key === 'selectAll') {
                selectAllState
                    ? this.setState({ selectedOptionFolders: [] })
                    : this.setState({
                        selectedOptionFolders: ['selectAll', ...selectableOptions.filter(o => !o.disabled).map(o => o.id)]
                    })

            } else {
                let updatedKeys = selected
                updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option.key].filter(k => k !== '-1')
                    : currentSelectedOptionKeys.filter(k => k !== option.key && k !== 'selectAll');




                if (selected && updatedKeys.length === selectableOptions.length) {
                    updatedKeys.push('selectAll')
                }

                this.setState({
                    selectedOptionFolders: updatedKeys,
                    filterDefaultEnabled: false,
                    filterUserEnabled: true,
                    updated: true
                }
                )
            }
        }
    }

    filterFolderRights = (folders, right) => {
        let conversationSafeAccess = this.props.userRules.ruleAccess.userSafe 
        let user = this.props.userRules.userId
        let own = this.props.userRules.ruleAccess.ownFolder[right]
        return folders.filter(f => {
            if (f.id !== null) {
                if (conversationSafeAccess === "Restricted") {
                    if (f.userId === user) {
                        return own
                    } else {
                        return f[right]
                    }
                } else {
                    return true
                }
            } else {
                return false
            }
        })
    }

    goToStep2 = () => {
        this.setState({
            step1: false,
            step2: true
        })
    }

    _onExportAudio = async (ev) => {
        // Convert Bulk Items
        if (this.props.bulkOperation) {
            let input = this.props.bulkExport;
            let day;
            let outArr = [];
            let duration = 0;
            if (input !== undefined && input != null) {
                for (let i = 0; i < input.length; i++) {
                    day = input[i].date;
                    for (let j = 0; j < input[i].content.length; j++) {
                        outArr.push(input[i].content[j].conversationId);
                        duration += input[i].content[j].duration;
                    }
                }
            }
            else if (this.props.bulkData != null) {
                // Listenansicht
                console.log(this.props.bulkData);
                for (let i = 0; i < this.props.bulkData.length; i++) {
                    outArr.push(this.props.bulkData[i].conversationId);
                    duration += this.props.bulkData[i].duration;
                }
                console.log(outArr);

            }
            if (this.state.bulkOperationType === "bulkExport"
                || this.state.bulkOperationType === "bulkExportTranscript"
                || this.state.bulkOperationType === "bulkExportMetadata") {
                this.setState({ subTitle: "Zip " + outArr.length + " conversations", buttonDisabled: true, downloadActive: true });
                this.props.client
                    .mutate({
                        mutation: gql`
                mutation ent ($listId : [String]!, $zipPassword: String, $bulkName: String, $onlyTranscript: Boolean, $onlyMetadata: Boolean) {
                    bulkExport (listId: $listId, zipPassword: $zipPassword, bulkName: $bulkName, onlyTranscript: $onlyTranscript, onlyMetadata: $onlyMetadata)
                }
                `,
                        variables: { listId: outArr, zipPassword: this.state.zipPassword, bulkName: this.state.bulkName, onlyTranscript: this.state.bulkOperationType === "bulkExportTranscript" ? true : false, onlyMetadata: this.state.bulkOperationType === "bulkExportMetadata" ? true : false }
                    });
                toast.info('Bulk Export started. Email Notification will be sent with downloadUrl after finishing.', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.setState({ subTitle: "", downloadActive: false, hideDialog: true });
            }
            else if (this.state.bulkOperationType === "bulkTranscript") {
                this.setState({ subTitle: "Transcribe " + outArr.length + " conversations", buttonDisabled: true, downloadActive: true });
                this.props.client
                    .mutate({
                        mutation: gql`
                mutation ent ($listId : [String]!, $type: String) {
                    analyzeConversationList (listId: $listId, type: $type)
                }
                `,
                        variables: { listId: outArr, type: this.props.userRules.ruleAnalytics.withTranscription ? "SpeechToText" : "Indexer" }
                    })
                    .then(res => {
                        this.setState({ subTitle: "Bulk transcript completed", downloadActive: false });
                    });
                toast.info('Bulk Transcript started.', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            else if (this.state.bulkOperationType === "bulkRestoreArchive") {
                this.setState({ subTitle: "Restore " + outArr.length + " conversations from archive", buttonDisabled: true, downloadActive: true });
                this.props.client
                    .mutate({
                        mutation: gql`
                mutation ent ($listId : [String]!) {
                    restoreFromArchiveList (listId: $listId)
                }
                `,
                        variables: { listId: outArr }
                    })
                    .then(res => {
                        this.setState({ subTitle: "Bulk restore from archive triggered! Restore can take up to 24 hours!", downloadActive: false });
                    });
                toast.info('Bulk restore from archive started.', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            else if (this.state.bulkOperationType === "bulkDelete") {
                this.setState({ subTitle: "Delete " + outArr.length + " conversations", buttonDisabled: true, downloadActive: true });
                this.props.client
                    .mutate({
                        mutation: gql`
                mutation ent ($listId : [String]!) {
                    deleteConversationList (listId: $listId)
                    {
                        id
                    }
                }
                `,
                        variables: { listId: outArr }
                    })
                    .then(res => {
                        this.setState({ subTitle: "Bulk deletion completed", downloadActive: false });
                    });
                toast.info('Bulk Delete started.', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            else if (this.state.bulkOperationType === "addToFolders") {
                if (this.state.selectedOptionFolders.length > 0) {
                    this.setState({ subTitle: "Add to conversation safe folders", buttonDisabled: true, downloadActive: false })
                    this.props.client
                        .mutate({
                            mutation: bulkFoldersMutation,
                            variables: {
                                input: this.props.authorization,
                                conversationId: outArr,
                                selectedFolders: this.state.selectedOptionFolders.filter(k => k !== 'selectAll'),
                                action: "add"
                            },
                            refetchQueries: [{
                                query: queryGetFilterRecordings,
                                variables: this.props.refetchQueryFields

                            }]
                        })
                        .then(res => {
                            this.setState({ subTitle: "Add to conversation safe folders completed" });
                        })
                        .catch(err => {
                            console.log('err', err)
                        })
                    toast.info('Bulk conversation safe started.', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.error('Select at least one folder', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            else if (this.state.bulkOperationType === "removeToFolders") {
                if (this.state.selectedOptionFolders.length > 0) {
                    if (!this.state.csRemoveConfirmationStep){
                        this.setState({
                            csRemoveConfirmationStep: true
                        })
                    }
                    else {
                        this.setState({
                            csRemoveConfirmationStep: false,
                            subTitle: 'Remove from conversation safe folders',
                            buttonDisabled: true,
                            downloadActive: false
                        })
                        this.props.client
                            .mutate({
                                mutation: bulkFoldersMutation,
                                variables: {
                                    input: this.props.authorization,
                                    conversationId: outArr,
                                    selectedFolders: this.state.selectedOptionFolders.filter(k => k !== 'selectAll'),
                                    action: 'remove'
                                },
                                refetchQueries: [{
                                    query: queryGetFilterRecordings,
                                    variables: this.props.refetchQueryFields
                    
                                }]
                            })
                            .then(res => {
                                this.setState({ subTitle: 'Remove from conversation safe folders completed' })
                            })
                            .catch(err => {
                                console.log('err', err)
                            })
                        toast.info('Bulk conversation safe started.', {
                            position: 'bottom-left',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        })
                    }
                } else {
                    toast.error('Select at least one folder', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            else {
                this.setState({ subTitle: "Get Metadata", buttonDisabled: true, downloadActive: true });
                this.props.client
                    .mutate({
                        mutation: gql`
                        mutation ent ($conversationId : String!) {
                            downloadMetadata (conversationId: $conversationId)
                        }
                    `,
                        variables: { conversationId: this.props.export.conversationId }
                    })
                    .then(async result => {
                        if (result.data.downloadMetadata !== "") {
                            this.setState({ subTitle: "Save Metadata" });
                            var blob = new Blob([result.data.downloadMetadata], { type: "application/xml;charset=utf-8" });
                            saveAs(blob, this.props.export.conversationId + ".xml");
                            this.setState({ subTitle: "Download finished", downloadActive: false });
                        }
                    });
            }
            //this condition is needed to keep the dialog open in case of bulktype = remove conv from folders,
            //as the code was structured the contained portion of code was always executed and we needed not to
            //when the confirmation step for removeFromFolders bulk was not true
            if(this.state.bulkOperationType === "removeToFolders" && this.state.csRemoveConfirmationStep){
                this.setState({
                    subTitle: this.props.count != undefined
                        ? (this.props.count + ' conversations')
                        : '',
                    buttonDisabled: false,
                    downloadActive: false,
                    hideDialog: true
                })
            }
        }
        else if ((this.state.downloadType === "audio" || this.state.downloadType === "video") && !this.props.renderFromCompliance) {
            this.setState({ subTitle: 'Copy Data', buttonDisabled: true, downloadActive: true });
            this.props.client
                .mutate({
                    mutation: gql`
                mutation ent ($conversationId : String!, $audioOnly: Boolean, $zipPassword: String) {
                    downloadBlob (conversationId: $conversationId, audioOnly: $audioOnly, zipPassword: $zipPassword)
                }
                `,
                    variables: { conversationId: this.props.export.conversationId, zipPassword: this.state.zipPassword, audioOnly: (this.state.downloadType === "audio" ? true : false) }
                })
                .then(async result => {
                    const anonymousCredential = new Azure.AnonymousCredential();
                    const pipeline = Azure.StorageURL.newPipeline(anonymousCredential);
                    if (result.data.downloadBlob !== "") {
                        this.setState({ subTitle: "Download Zip File" });
                        const serviceURL = new Azure.ServiceURL(result.data.downloadBlob, pipeline);
                        const containerURL = Azure.ContainerURL.fromServiceURL(serviceURL, "");
                        const blockBlobURL = Azure.BlockBlobURL.fromContainerURL(containerURL, this.props.export.conversationId + ".zip");
                        // Download BlockBlob
                        const downloadBlockBlobResponse = await blockBlobURL.download();
                        const downloaded = (await downloadBlockBlobResponse.blobBody);
                        this.setState({ subTitle: "Save Zip File" });
                        saveAs(downloaded, this.props.export.conversationId + ".zip");
                        // Finish (Delete) Export Blob
                        this.props.client
                            .mutate({
                                mutation: gql`
                                mutation ent ($conversationId : String!, $url: String!) {
                                    downloadFinished (conversationId: $conversationId, url: $url)
                                }
                                `,
                                variables: { conversationId: this.props.export.conversationId, url: result.data.downloadBlob }
                            });
                        this.setState({ subTitle: "Download finished", downloadActive: false });
                    } else {
                        this.setState({ subTitle: "Audio not available", downloadActive: false });
                    }
                });
        }
        else if (this.state.downloadType === "transcript" && !this.props.renderFromCompliance) {
            this.setState({ subTitle: "Get Transcript", buttonDisabled: true, downloadActive: true });
            this.props.client
                .mutate({
                    mutation: gql`
                mutation ent ($conversationId : String!) {
                    downloadTranscript (conversationId: $conversationId)
                }
                `,
                    variables: { conversationId: this.props.export.conversationId }
                })
                .then(async result => {
                    if (result.data.downloadTranscript !== "") {
                        this.setState({ subTitle: "Save Transcript" });
                        var blob = new Blob([result.data.downloadTranscript], { type: "text/csv;charset=utf-8" });
                        saveAs(blob, this.props.export.conversationId + "_transcript.csv");
                        this.setState({ subTitle: "Download finished", downloadActive: false });
                    }
                });
        }
        else if (!this.props.bulkOperation && this.props.renderFromCompliance) {
            let bulkExportDownloadOptions
            if (this.state.downloadType == 'audio') bulkExportDownloadOptions = { exportTranscript: false, exportMetadata: false, exportAudio: true }
            if (this.state.downloadType == 'video') bulkExportDownloadOptions = { exportTranscript: true, exportMetadata: true, exportAudio: true }
            if (this.state.downloadType == 'metadata') bulkExportDownloadOptions = { exportTranscript: false, exportMetadata: true, exportAudio: false }
            if (this.state.downloadType == 'transcript') bulkExportDownloadOptions = { exportTranscript: true, exportMetadata: true, exportAudio: false }
            this.props.client
                .mutate({
                    mutation: gql`
                        mutation ent (
                            $listId : [String]!, $zipPassword: String,
                            $bulkName: String, $exportTranscript: Boolean, $exportMetadata: Boolean, $exportAudio: Boolean,  $folderId: String) {
                            bulkExportConversationSafe (
                                listId: $listId, zipPassword: $zipPassword,
                                bulkName: $bulkName, exportTranscript: $exportTranscript, exportMetadata: $exportMetadata, exportAudio: $exportAudio, folderId: $folderId
                            )
                        }`,
                    variables: {
                        listId: this.props.export,
                        zipPassword: this.state.zipPassword,
                        bulkName: this.state.bulkName,
                        ...bulkExportDownloadOptions,
                        folderId: this.props.folderId
                    }
                })
            toast.info('Bulk Export started. Email Notification will be sent with downloadUrl after finishing.', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
            this.setState({
                hideDialog: true,
                step2: false
            })
        }
        else {
            this.setState({ subTitle: "Get Metadata", buttonDisabled: true, downloadActive: true });
            this.props.client
                .mutate({
                    mutation: gql`
                        mutation ent ($conversationId : String!) {
                            downloadMetadata (conversationId: $conversationId)
                        }
                    `,
                    variables: { conversationId: this.props.export.conversationId }
                })
                .then(async result => {
                    if (result.data.downloadMetadata !== "") {
                        this.setState({ subTitle: "Save Metadata" });
                        var blob = new Blob([result.data.downloadMetadata], { type: "application/xml;charset=utf-8" });
                        saveAs(blob, this.props.export.conversationId + ".xml");
                        this.setState({ subTitle: "Download finished", downloadActive: false });
                    }
                });
        }
    }

    _showDialog = async (event, item) => {
        let archiveCheckOk = true;
        let archivePercentage = 0.0;
        let archiveSize = 0;
        let archiveCount = 0;
        let streamCount = 0;
        let streamSize = 0;
        let outArr = [];
        let input = this.props.bulkExport;
        if (input !== undefined && input != null) {
            for (let i = 0; i < input.length; i++) {
                for (let j = 0; j < input[i].content.length; j++) {
                    outArr.push(input[i].content[j].conversationId);
                }
            }
        }
        else if (this.props.bulkData != null) {
            // Listenansicht
            for (let i = 0; i < this.props.bulkData.length; i++) {
                outArr.push(this.props.bulkData[i].conversationId);
            }

        }
        if (item.key === "bulkExport" || item.key === "bulkRestoreArchive") {
            let result = await this.props.client
                .query({
                    query: gql`
                            query ent ($listId : [String]!){
                                    getConversationsInfo(listId: $listId) {
                                        size
                                        sizeArchived
                                        count
                                        countArchived
                                        sizeBought
                                    }
                            }
                        `,
                    variables: { listId: outArr }
                });

            archiveCount = result.data.getConversationsInfo.countArchived;
            archiveSize = result.data.getConversationsInfo.sizeArchived;
            streamSize = result.data.getConversationsInfo.size;
            streamCount = result.data.getConversationsInfo.count;
            if (result.data.getConversationsInfo.sizeBought > 0)
                archivePercentage = (archiveSize / result.data.getConversationsInfo.sizeBought);
            if (item.key === "bulkRestoreArchive") {
                if (archivePercentage < 0.1 && archiveSize > 0)
                    archiveCheckOk = true;
                else
                    archiveCheckOk = false;
            }
            else {
                if (archiveCount === 0 || archivePercentage < 0.1)
                    archiveCheckOk = true;
                else
                    archiveCheckOk = false;
            }
        }
        this.setState({
            bulkOperationType: item.key,
            hideDialog: false,
            subTitle: this.props.count != undefined
                ? (this.props.count + ' conversations')
                : '',
            buttonDisabled: false,
            archiveCheckOk: archiveCheckOk,
            archivePercentage: archivePercentage,
            archiveCount: archiveCount,
            archiveSize: archiveSize,
            streamCount: streamCount,
            streamSize: streamSize
        })
    }

    _showDialogCommandBar = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        if (this.props.folderId !== undefined) {
            this.setState({
                hideDialog: false,
                step1: true,
                bulkName: this.props.folderName.replaceAll(' ', '_')
            });
        } else {
            this.setState({
                hideDialog: false,
                step1: true,
            });
        }
        
    };

    _closeDialog = () => {
        this.setState({
            hideDialog: true,
            subTitle: this.props.count != undefined ? (this.props.count + " conversations") : "",
            buttonDisabled: false,
            step2: false
        })
    };

    _toggleDraggable = () => {
        this.setState({ isDraggable: !this.state.isDraggable });
    };


}
