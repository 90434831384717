import * as React from 'react';
import Transscript from './PivotElements/Transscript/Transscript'
import { PivotItem, Pivot, Text } from 'office-ui-fabric-react/lib';
import { Spinner } from 'office-ui-fabric-react';
import CategoryContainer from "./PivotElements/Category/CategoryContainer";
import KeywordContainer from "./PivotElements/Keyword/KeywordContainer";
import NotesContainer from "./PivotElements/Notes/NotesContainer";
import TtlContainer from "./PivotElements/TTL/TtlContainer";
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import "./DetailPivot.scss";
import { MyContext } from '../../../context';
import PolicyContainer from './PivotElements/Compliance/PolicyContainer';
import FieldsContainer from './PivotElements/Fields/FieldsContainer';
import MetadataList from './PivotElements/Metadata/MetadataList';
import _ from 'lodash'
import * as Query from './PivotElements/Compliance/PolicyContainerQuery'
import AIQualityManagementResult from './PivotElements/QualityManagement/AIQualityManagementResult';

export default class DetailPivot extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            transcriptExpanded: false,
            selectedKey: 0,
            processing: false,
            dataPolicy: null,
            isFilteredByAIWithError: false,
            policyFilteredByAIWithError: "",
            summarizedInsights: this.props.data.summarizedInsights,
            policyResultAdherence: [],
            loadingPolicy: false
        }
    }

    isProcessing = (value) => {
        this.setState({ processing: value })
    }

    componentDidMount() {
        const conversationId = this.props.data.conversationId
        if (this.props.userRules.addOnCompliance && (!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsAnalytics) &&
            (this.props.userRules.ruleAccess.complianceResultsAccess)) {
            this.setState({ loadingPolicy: true })
            this.props.apol
                .query({
                    query: Query.queryGetPolicyResult,
                    variables: { conversationId }
                })
                .then((result) => {
                    var dataPolicy = null;
                    var isFilteredByAIWithError = false;
                    var policyFilteredByAIWithError = "";
                    if (result.data.getPolicyResult !== null && result.data.getPolicyResult.length > 0) {
                        if (!this.props.userRules.ruleAccess.userComplianceAIQuestions) {
                            var dataResult = [];
                            result.data.getPolicyResult.forEach(pol => {
                                var r = pol.policyResult.filter(polRes => {
                                    return polRes.type != "AIQuestion"
                                })
                                if (r != null && r.length > 0) {
                                    dataResult.push(pol);
                                }
                            })
                            dataResult = dataResult.filter(policy => policy.policyResult.length > 0).sort(function (a, b) { a.selected - b.selected })
                            dataPolicy = dataResult.length > 0 ? dataResult : null;
                        } else {
                            isFilteredByAIWithError = result.data.getPolicyResult.filter(res => {
                                return res.errorMessage != null && res.errorMessage != "";
                            }).length > 0;

                            if (isFilteredByAIWithError) {
                                policyFilteredByAIWithError = result.data.getPolicyResult.filter(res => {
                                    return res.errorMessage != null && res.errorMessage != "";
                                }).map(p => {
                                    return p.policy.name;
                                }).join(", ");
                            }
                            dataPolicy = result.data.getPolicyResult.filter(pol => pol.policyResult.length > 0).sort(function (a, b) { a.selected - b.selected })
                        }
                        this.props.updatePolicyResults(dataPolicy)
                    }
                    let policyK = []
                    let policyResultAdherence = []
                    if (dataPolicy !== null) {
                        dataPolicy.forEach(policyRes => {
                            policyRes.policyResult.forEach(r => {
                                if (r.type === "Category" || r.type === "PatternDetection") {
                                    policyK.push(...r.appearances.map(a => {
                                        return ({
                                            appearances: [a],
                                            name: a.words[0],
                                            hasSearchExactKeywords: false,
                                            isTranscript: true,
                                            fromPolicy: true,
                                            policy: policyRes.policy.name
                                        })
                                    })
                                    )
                                } else if (r.type === "AdherencePhrase" || r.type === "AIAdherencePhrase") {
                                    policyResultAdherence.push(
                                        ...r.appearances.map(a => {
                                            return ({
                                                appearances: [a],
                                                name: a.words[0],
                                                hasSearchExactKeywords: false,
                                                isTranscript: true,
                                                fromPolicy: true,
                                                policy: policyRes.policy.name
                                            })
                                        })
                                    )
                                } else if (r.type !== "AIQuestion") {
                                    policyK.push(({
                                        appearances: r.appearances,
                                        name: r.name,
                                        hasSearchExactKeywords: false,
                                        isTranscript: true,
                                        fromPolicy: true,
                                        policy: policyRes.policy.name
                                    }))
                                }

                            })

                        })
                    }
                    let keywords = []
                    if (this.state.summarizedInsights !== null && this.state.summarizedInsights !== undefined)
                        keywords = [...this.state.summarizedInsights.keywords, ...policyK]
                    else
                        keywords = [...policyK]
                    let summarizedInsights = {
                        ...this.state.summarizedInsights, keywords
                    }
                    this.setState({
                        dataPolicy,
                        isFilteredByAIWithError,
                        policyFilteredByAIWithError,
                        summarizedInsights,
                        policyResultAdherence,
                        loadingPolicy: false
                    })
                });
        }

    }


    render() {
        return (<MyContext.Consumer>
            {(context) => (
                <div>
                    {
                        !this.state.loadingPolicy && <Pivot className="pivot pivot--details"
                            onLinkClick={this._expandClicked}
                            selectedKey={`${this.state.selectedKey}`}>
                            <PivotItem
                                linkText={window.innerWidth < 600 ? "" : context.getTranslation("detailContainer", "transcript")}
                                itemIcon="AlignLeft"
                                itemKey="0"
                                headerButtonProps={{
                                    'disabled': this.state.processing,
                                    'style': this.state.processing ? { color: "grey" } : {}
                                }}                >
                                <div className="ms-Grid-row pivot-item">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        {this.props.data.analyticRemark == "AnalyticLimitReached" &&
                                            <Text>No Analytics processed. Maximum Analytics hours reached!</Text>}
                                        {this.props.data.analyticRemark == "TranslateLimitReached" &&
                                            <Text>No Translation processed. Maximum Translate hours reached!</Text>}
                                        <Transscript
                                            key="0"
                                            client={this.props.apol}
                                            authorization={this.props.authorization}
                                            listener={this.props.listener}
                                            sum={this.state.summarizedInsights}
                                            policyResultAdherence={this.state.policyResultAdherence}
                                            data={this.props.data.unifiedTranscript}
                                            user={this.props.data.linkedUser}
                                            id={this.props.data.id}
                                            viewType="one2oneView"
                                            height={this.props.height}
                                            export={this.props.data}
                                            userRules={this.props.userRules}
                                            metadata={this.props.data}

                                        />
                                    </div>
                                </div>
                            </PivotItem>
                            {(!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsTranscript) && <PivotItem linkText="" itemIcon="FullScreen" itemKey="1"
                                headerButtonProps={{
                                    'disabled': this.state.processing,
                                    'style': this.state.processing ? { color: "grey" } : {}
                                }}>
                                <Panel
                                    isOpen={this.state.transcriptExpanded}
                                    onDismiss={this._hideClicked}
                                    isFooterAtBottom={false}
                                    headerText={this.props.data.topic}
                                    headerClassName="panel-header-small"
                                    type={PanelType.smallFluid}
                                >

                                    {this.props.data.analyticRemark == "AnalyticLimitReached" &&
                                        <Text>No Analytics processed. Maximum Analytics hours reached!</Text>}
                                    <Transscript
                                        key="0"
                                        client={this.props.apol}
                                        authorization={this.props.authorization}
                                        listener={this.props.listener}
                                        sum={this.state.summarizedInsights}
                                        policyResultAdherence={this.state.policyResultAdherence}
                                        data={this.props.data.unifiedTranscript}
                                        user={this.props.data.linkedUser}
                                        id={this.props.data.id}
                                        viewType="one2oneView"
                                        height={window.innerHeight - 110}
                                        export={this.props.data}
                                        isFullScreen="true"
                                        userRules={this.props.userRules}
                                        metadata={this.props.data}
                                    />

                                </Panel>
                            </PivotItem>}

                            {/*POLICY*/}
                            {this.props.userRules.addOnCompliance && (!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsAnalytics) &&
                                (this.props.userRules.ruleAccess.complianceResultsAccess) &&
                                <PivotItem linkText={window.innerWidth < 600 ? "" : context.getTranslation("detailContainer", "policy")}
                                    itemIcon="Shield" itemKey="7"
                                    headerButtonProps={{
                                        'disabled': this.state.processing,
                                        'style': this.state.processing ? { color: "grey" } : {}
                                    }}
                                >
                                    <div className="pivot-item">
                                        <PolicyContainer key="6"
                                            conversationId={this.props.data.conversationId}
                                            listener={this.props.listener}
                                            height={this.props.height}
                                            theme={this.props.theme}
                                            apol={this.props.apol}
                                            userRules={this.props.userRules}
                                            dataPolicy={this.state.dataPolicy}
                                            aiCategorizationPolicies={this.props.data.aiCategorizationPolicies}
                                            isFilteredByAIWithError={this.state.isFilteredByAIWithError}
                                            policyFilteredByAIWithError={this.state.policyFilteredByAIWithError}
                                        />
                                    </div>
                                </PivotItem>}
                            {(!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsQM && this.props.userRules.addOnQualityManagement) &&
                                this.props.userRules.ruleAccess.complianceQMResultsAccess && this.props.userRules.addOnQualityManagement &&
                                <PivotItem
                                    linkText={window.innerWidth < 600 ? "" : context.getTranslation("compliance", "AIQM")}
                                    itemIcon="TrackersMirrored"
                                    itemKey="10"
                                    headerButtonProps={{
                                        'disabled': this.state.processing,
                                        'style': this.state.processing ? { color: "grey" } : {}
                                    }}
                                >
                                    <div className="pivot-item">
                                        <AIQualityManagementResult
                                            data={this.props.data}
                                            userRules={this.props.userRules}
                                            apol={this.props.apol}
                                            auth={this.props.authorization}
                                            isProcessing={this.isProcessing}
                                        />
                                    </div>
                                </PivotItem>}
                            {(!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsAnalytics) &&
                                <PivotItem
                                    linkText={window.innerWidth < 600 ? "" : context.getTranslation("detailContainer", "category")}
                                    itemIcon="PowerBILogo16" itemKey="2"
                                    headerButtonProps={{
                                        'disabled': this.state.processing,
                                        'style': this.state.processing ? { color: "grey" } : {}
                                    }}>
                                    <div className="pivot-item">
                                        <CategoryContainer key="1" data={this.props.data} listener={this.props.listener} height={this.props.height} />
                                    </div>
                                </PivotItem>}

                            {(!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsAnalytics) &&
                                <PivotItem linkText={window.innerWidth < 600 ? "" : context.getTranslation("detailContainer", "keywords")} itemIcon="6PointStar" itemKey="4"
                                    headerButtonProps={{
                                        'disabled': this.state.processing,
                                        'style': this.state.processing ? { color: "grey" } : {}
                                    }}
                                >

                                    <div className="pivot-item">
                                        <KeywordContainer key="3" data={this.props.data} listener={this.props.listener} height={this.props.height} />
                                    </div>

                                </PivotItem>}

                            {(!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsNotes) && <PivotItem linkText={window.innerWidth < 600 ? "" : context.getTranslation("detailContainer", "notes")} itemIcon="QuickNote" itemKey="5"
                                headerButtonProps={{
                                    'disabled': this.state.processing,
                                    'style': this.state.processing ? { color: "grey" } : {}
                                }}
                            >


                                <div className="pivot-item">
                                    <NotesContainer key="4" client={this.props.apol} data={this.props.data} authorization={this.props.authorization} listener={this.props.listener} height={this.props.height} />
                                </div>

                            </PivotItem>}

                            {this.props.userRules.ruleAccess.hasCustomFieldsAccess && (!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsCustomFields) &&
                                < PivotItem linkText={window.innerWidth < 600 ? "" : context.getTranslation("detailContainer", "fields")} itemIcon="ClipboardList" itemKey="8"
                                    headerButtonProps={{
                                        'disabled': this.state.processing,
                                        'style': this.state.processing ? { color: "grey" } : {}
                                    }}
                                >
                                    <div className="pivot-item">
                                        <FieldsContainer data={this.props.data} authorization={this.props.authorization} client={this.props.apol} userRules={this.props.userRules} isProcessing={this.isProcessing} />
                                    </div>
                                </PivotItem>}

                            {(!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsTTL) && <PivotItem linkText={window.innerWidth < 600 ? "" : context.getTranslation("detailContainer", "ttl")} itemIcon="Settings" itemKey="6"
                                headerButtonProps={{
                                    'disabled': this.state.processing,
                                    'style': this.state.processing ? { color: "grey" } : {}
                                }}>


                                <div className="pivot-item">
                                    <TtlContainer key="5"
                                        data={this.props.data}
                                        authorization={this.props.authorization}
                                        hidePanel={this.props.hidePanel}
                                        client={this.props.apol}
                                        listener={this.props.listener}
                                        isProcessing={this.isProcessing}
                                        refreshFolderList={this.props.refreshFolderList}
                                        userRules={this.props.userRules}
                                    />
                                </div>

                            </PivotItem>}

                            {(!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsMetadata) &&
                                <PivotItem linkText={window.innerWidth < 600 ? "" : context.getTranslation("detailContainer", "metadata")} itemIcon="Table" itemKey="9"
                                    headerButtonProps={{
                                        'disabled': this.state.processing,
                                        'style': this.state.processing ? { color: "grey" } : {}
                                    }}
                                >
                                    <div className="pivot-item">
                                        <MetadataList data={this.props.data} userRules={this.props.userRules} client={this.props.apol} authorization={this.props.authorization} />
                                    </div>
                                </PivotItem>}

                        </Pivot>
                    }
                    {this.state.loadingPolicy && <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}

                </div>
            )
            }

        </MyContext.Consumer>
        );
    }



    _expandClicked = (item) => {
        this.setState({ selectedKey: item.props.itemKey, transcriptExpanded: true });
        this.setState({ transcriptExpanded: true });
    }
    _hideClicked = () => {
        this.setState({ selectedKey: "0", transcriptExpanded: false });
    }
}

//
