import React, { Component } from 'react'
import "./ReportView.scss"
import ReportDetailContainer from './ReportDetailContainer/ReportDetailContainer'
import AuditDetailContainer from './ReportDetailContainer/AuditDetailContainer'
import LossDetailContainer from './ReportDetailContainer/LossDetailContainer'
import { ApolloProvider } from "react-apollo";
import { Query } from "react-apollo";
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';

import gql from "graphql-tag";
import { Modal, Spinner } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { MyContext } from '../../context';

export default class ReportView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPanelOpen: false,
            loading: true,
            id: null
        }
    }

    renderData = (input, project,apol) => (

        <Query
            query={gql`

        query ent ($input: AuthInput, $project : String!) {
          getReportDetails (input: $input, reportId: $project) {
            id
            title
            widget
            type
            kpi
            startDate
            endDate
            valueType
            cycle
            auditEvents {
                id
                name
                key
                created
                createdMs
                userId
                tenantId
                title
                group
                conversationId
                scenarioId
                text
                idType
                userLogin
                details
            }
            entry {
                callStartMs
                day
                timein
                conversationId
                day
                id
                topic
                recordingType
                speech2Text
                duration
                timeout
                notes
                mediaAssets
                mediaAssetVideo
                userId
                userLogin
                size
                isVideo
                hasVideo
                hasAudio
                hasChat
                hasFiles
                hasScreenSharing
                exportComment
                errorMessage
                errorCode
                policyComparedHit
                categoryComparedHit
                linkedUser
        	    {
                  id
                  name
                }
            }
            lossEntry {
                callStart
                callStartMs
                day
                timein
                conversationId
                day
                id
                topic
                userId
                botAppId
                scenarioId
                tenantId
                callId
                recordingType
                duration
                errorText
                conversationRecorded
                lossType
                participantCount
                userLogin
            }
          }
        }
    `}
            fetchPolicy="network-only"
            variables={{ input, project }} >
            {({ loading, error, data }) => {
                if (loading) return <Modal


                    isOpen={this.state.loading}
                >
                    <div style={{
                        borderStyle: "solid",
                        borderColor: "blue",
                        background: "transparent",
                        padding: "20px"
                    }}>
                        <MyContext.Consumer>
                            {(context) => (
                                <Spinner label={context.getTranslation("common", "loadingReportList")} />
                            )
                            }
                        </MyContext.Consumer>
                    </div>


                </Modal>;
                if (error) { toast.error("Error loading report list,\n please check your connection "); return <h1> </h1> };
                if (data.getReportDetails.lossEntry != null) {
                    return (
                        <Panel
                            isOpen={this.state.isPanelOpen}
                            onDismiss={this.hidePanel}
                            isFooterAtBottom={false}
                            headerText=""
                            type={PanelType.smallFluid}>
                            <LossDetailContainer authorization={this.props.authorization} apol={apol} data={data.getReportDetails} userRules={this.props.userRules} theme={this.props.theme}/>
                        </Panel>
                    )
                } else if (data.getReportDetails.auditEvents == null) {
                    return (
                        <Panel
                            isOpen={this.state.isPanelOpen}
                            onDismiss={this.hidePanel}
                            isFooterAtBottom={false}
                            headerText=""
                            type={PanelType.smallFluid}>
                            <ReportDetailContainer authorization={this.props.authorization} apol={apol} data={data.getReportDetails} userRules={this.props.userRules} theme={this.props.theme}/>
                        </Panel>
                    )
                } else {
                    return (
                        <Panel
                            isOpen={this.state.isPanelOpen}
                            onDismiss={this.hidePanel}
                            isFooterAtBottom={false}
                            headerText=""
                            type={PanelType.smallFluid}>
                            <AuditDetailContainer authorization={this.props.authorization} apol={apol} data={data.getReportDetails} userRules={this.props.userRules} theme={this.props.theme}/>
                        </Panel>
                    )
                }
            }}
        </Query>
    );

    render() {
        if (this.state.id != null) {
            return (<ApolloProvider client={this.props.apol}>
                {this.renderData(this.props.authorization, this.state.id, this.props.apol)}
            </ApolloProvider>);
        }
        else {
            return ""
        }
    }

    showPanel = (itemId) => {
        this.setState({ id: itemId, isPanelOpen: true });
    };

    hidePanel = () => {
        this.setState({ id: null, isPanelOpen: false });
    };
}
