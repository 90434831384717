import React from 'react'

import '../Transscript.scss'
import { Icon, FontSizes, IStackStyles } from 'office-ui-fabric-react/lib';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Persona, PersonaSize, TooltipHost } from 'office-ui-fabric-react/lib';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { MyContext } from '../../../../../../context';


export default class TranscriptLine extends React.Component {

    getSplitForExactKeyword = (keyword, soundlike, input, betterTime) => {
        var tmp = [];
        let indexKeyword = input.search(keyword);
        let indexSoundlike = soundlike != undefined ? input.search(soundlike) : -1;
        if (indexKeyword != -1) {
            var lengthOfKeyword = keyword.length;
            tmp.push({ src: input.slice(0, indexKeyword) })
            tmp.push({ src: input.slice(indexKeyword, indexKeyword + lengthOfKeyword), sec: betterTime })
            let newInput = input.slice(indexKeyword + lengthOfKeyword + 1, input.length);
            if (newInput != undefined) {
                tmp = tmp.concat(this.getSplitForExactKeyword(keyword, soundlike, newInput, betterTime));
            }
        } else if (indexSoundlike != -1 && soundlike != undefined) {
            let lengthOfSoundlike = soundlike.length;
            tmp.push({ src: input.slice(0, indexSoundlike) })
            tmp.push({ src: input.slice(indexSoundlike, indexSoundlike + lengthOfSoundlike), sec: betterTime })
            let newInput = input.slice(indexSoundlike + lengthOfSoundlike + 1, input.length);
            if (newInput != undefined) {
                tmp = tmp.concat(this.getSplitForExactKeyword(keyword, soundlike, newInput, betterTime));
            }
        } else {
            tmp.push({ src: input })
        }
        return tmp;
    }

    emojiMap = {
        like: '\u{1F44D}',
        heart: '\u{2764}\u{FE0F}',
        laugh: '\u{1F604}',
        surprised: '\u{1F632}',
    };

    getSplitForContainedKeyword = (keyword, soundlike, input, betterTime) => {
        var tmp = [];
        let indexKeyword = input.indexOf(keyword);
        let indexSoundlike = soundlike != undefined ? input.indexOf(soundlike) : -1;
        if (indexKeyword != -1) {
            var lengthOfKeyword = keyword.length;
            tmp.push({ src: input.slice(0, indexKeyword) })
            tmp.push({ src: input.slice(indexKeyword, indexKeyword + lengthOfKeyword), sec: betterTime })
            let newInput = input.slice(indexKeyword + lengthOfKeyword + 1, input.length);
            if (newInput != undefined) {
                tmp = tmp.concat(this.getSplitForContainedKeyword(keyword, soundlike, newInput, betterTime));
            }
        } else if (indexSoundlike != -1 && soundlike != undefined) {
            let lengthOfSoundlike = soundlike.length;
            tmp.push({ src: input.slice(0, indexSoundlike) })
            tmp.push({ src: input.slice(indexSoundlike, indexSoundlike + lengthOfSoundlike), sec: betterTime })
            let newInput = input.slice(indexSoundlike + lengthOfSoundlike + 1, input.length);
            if (newInput != undefined) {
                tmp = tmp.concat(this.getSplitForContainedKeyword(keyword, soundlike, newInput, betterTime));
            }
        } else {
            tmp.push({ src: input })
        }
        return tmp;
    }

    getOutObject = (text, keyWords) => {
        let out = [];
        out.push({ src: text.toLowerCase() })

        for (let y = 0; y < keyWords.length; y++) {
            let keyword = "";
            let soundlike = undefined;
            let keywordAndSoundslikeList = keyWords[y].name.split('_'); //split keyword by keyword and soundlike
            keyword = keywordAndSoundslikeList[0].toLowerCase();
            if (keywordAndSoundslikeList[1]) {
                soundlike = keywordAndSoundslikeList[1].toLowerCase();
            }
            out = this.searchKeyword(out, keyword, soundlike, keyWords[y].hasSearchExactKeywords, keyWords[y].betterTime)
        }
        return out;
    }

    searchKeyword = (out, keyword, soundlike, hasSearchExactKeywords, betterTime) => {

        var tmp = [];
        var tmpOut = [];
        for (let x = 0; x < out.length; x++) {

            if (out[x].sec == undefined) {

                if (hasSearchExactKeywords) {
                    if (out[x].src != undefined)
                        tmpOut = tmpOut.concat(this.getSplitForExactKeyword(keyword, soundlike, out[x].src, betterTime))
                } else {
                    if (out[x].src != undefined)
                        tmpOut = tmpOut.concat(this.getSplitForContainedKeyword(keyword, soundlike, out[x].src, betterTime))
                }
            } else {
                tmpOut.push(out[x])
            }

        }
        return tmpOut;
    }


    formatMention = (text) => {

        const mentionRegex = /<at id="(.*?)">(.*?)<\/at>/g;
        return text.replace(mentionRegex, (_, id, username) => {
            const mentionStyles = {
                color: '#0078D4',
                fontWeight: 'bold',
                display: 'inline-block',
                position: 'relative',
                cursor: 'pointer'
            };

            const mentionClassName = mergeStyles(mentionStyles);

            return `
                <span class="mention-container">
                    <span class="${mentionClassName} mention-username">
                        ${username}
                        <span class="mention-person">
                            <mgt-person user-id="${id}" person-card="hover"></mgt-person>
                            <span class="text-align-name">${this.getUserDisplayName(id)}</span>
                        </span>
                    </span>
                </span>
            `;

        });
    };

    getUserDisplayName = (userId) => {
        let displayName = userId != null ? userId : "";
        if (this.props.usersList !== null && userId !== null) {
            for (let user of this.props.usersList) {
                if (user.userId === userId) {
                    displayName = user.name;
                }
            }
        }
        return displayName;
    }

    SimpleTimeParser = (input) => {
        let out = 0;
        let tmp = "00", tmp2 = "00";

        tmp = input.split(":");

        if (tmp[2].indexOf(".") !== -1) {
            tmp2 = tmp[2].split(".");
        }


        out = tmp[0] * 60 * 60 + tmp[1] * 60 + tmp2[0] * 1;

        if (tmp2.length > 1) {
            out += (tmp2[1] / 10000000) - 0.01;
        }

        return out;
    };

    // Function to calculate the adherence percentage between two words
    adherencePercentage = (word, subword) => {
        if (word != undefined && subword != undefined) {
            const maxLength = Math.max(word.length, subword.length);
            const minRequiredLength = Math.ceil(maxLength * 0.8); // Minimum required length for adherence

            let matchingChars = 0;
            const subwordLower = subword.toLowerCase();
            const wordLower = word.toLowerCase();

            // Check for the presence of the substring in the word
            for (let i = 0; i <= wordLower.length - subwordLower.length; i++) {
                let matchCount = 0;
                // Check character-by-character match between the substring and part of the word
                for (let j = 0; j < subwordLower.length; j++) {
                    if (subwordLower[j] === wordLower[i + j]) {
                        matchCount++;
                    }
                }
                // If a sufficient match is found, consider the substring as found
                if (matchCount >= minRequiredLength) {
                    return (matchCount / subwordLower.length) * 100;
                }
            }
        }

        return 0; // No sufficient match found
    }


    // Function to calculate the Levenshtein distance between two strings
    levenshteinDistance = (s1, s2) => {
        const m = s1.length;
        const n = s2.length;

        // Create a matrix for Levenshtein distance
        const dp = Array.from(Array(m + 1), () => Array(n + 1).fill(0));

        // Initialize the matrix
        for (let i = 0; i <= m; i++) {
            dp[i][0] = i;
        }
        for (let j = 0; j <= n; j++) {
            dp[0][j] = j;
        }

        // Calculate the Levenshtein distance
        for (let i = 1; i <= m; i++) {
            for (let j = 1; j <= n; j++) {
                const cost = s1[i - 1] === s2[j - 1] ? 0 : 1;
                dp[i][j] = Math.min(
                    dp[i - 1][j] + 1, // Deletion
                    dp[i][j - 1] + 1, // Insertion
                    dp[i - 1][j - 1] + cost // Substitution
                );
            }
        }

        return dp[m][n];
    }

    // Function to determine the similarity percentage between two words
    similarityPercentage = (word1, word2) => {
        if (word1 != undefined && word2 != undefined) {
            const distance = this.levenshteinDistance(word1, word2);
            const maxLength = Math.max(word1.length, word2.length);
            const similarity = (1 - distance / maxLength) * 100;
            return similarity;
        }
        return 0
    }


    outPutText = (props) => {
        let out = [];
        if (props.type === "chat") {
            return <>
                <div className="transcript-text" dir={props.languageDirection} dangerouslySetInnerHTML={{ __html: this.formatMention(props.text) }} />
                <div style={{ display: "flex" }}>
                    {this.props.reactions != null && this.props.reactions.map((item, i) => {
                        //default emojis from reactions are not recognized in the backend and I recieve them as text
                        //I did a mapping only for those 4 values that are not displayed correct
                        const emoji = this.emojiMap[item.reactionType] || item.reactionType; // Use the emoji from the map, or the original value

                        return (
                            <div key={i} style={{ marginRight: "5px" }}>
                                <TooltipHost
                                    content={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <mgt-person user-id={item.userId} person-card="hover"></mgt-person>
                                            <span style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '14px' }}>{this.getUserDisplayName(item.userId)}</span>
                                        </div>
                                    } closeDelay={100}
                                >
                                    <span className="reaction-wrap" style={{ cursor: "pointer" }} aria-describedby={i + "tooltip"}>
                                        {emoji}
                                    </span>
                                </TooltipHost>
                            </div>
                        );
                    })}
                </div>

            </>
        } else if (props.type === "attach" && props.url != null) {
            return <>
                <a className="transcript-text" dir={props.languageDirection} href={props.url} >{props.text}</a>
                <div style={{ display: "flex" }}>
                    {this.props.reactions != null && this.props.reactions.map((item, i) => {
                        //default emojis from reactions are not recognized in the backend and I recieve them as text
                        //I did a mapping only for those 4 values that are not displayed correct
                        const emoji = this.emojiMap[item.reactionType] || item.reactionType; // Use the emoji from the map, or the original value

                        return (
                            <div key={i} style={{ marginRight: "5px" }}>
                                <TooltipHost
                                    content={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <mgt-person user-id={item.userId} person-card="hover"></mgt-person>
                                            <span style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '14px' }}>{this.getUserDisplayName(item.userId)}</span>
                                        </div>
                                    } closeDelay={100}
                                >
                                    <span className="reaction-wrap" style={{ cursor: "pointer" }} aria-describedby={i + "tooltip"}>
                                        {emoji}
                                    </span>
                                </TooltipHost>
                            </div>
                        );
                    })}
                </div>
            </>
        } else if (props.keyWords != null && undefined !== props.keyWords && !props.translated) {
            if (props.keyWords.length > 0) {
                let tmp, tmptime;
                var regex = /(<([^>]+)>)/ig;
                let result = props.text.replace(regex, "").replace(/&[^;]+;/g, " ");
                tmp = splitWithPunctuation(result);
                if (tmp.length == 0) {
                    out = this.getOutObject(props.text, props.keyWords);
                } else {
                    //union of words from policy and keywordspotting
                    let keys = props.keyWords.map(k => {
                        let keyword = k.name ? k.name.split('_')[0].toLowerCase() : "keyword";
                        return keyword;
                    });
                    let uniqueChars = [...new Set(keys)];
                    let collectKeywords = uniqueChars.map(uniqueKey => {
                        let filterSameKeyword = props.keyWords.filter(k => {
                            let keyword = k.name ? k.name.split('_')[0].toLowerCase() : "keyword";
                            return uniqueKey === keyword;
                        });
                        let filterPolicyResult = filterSameKeyword.filter(k => k.fromPolicy)
                        if (filterSameKeyword.length > 1 && filterPolicyResult.length > 0) {
                            return ({
                                ...filterSameKeyword[0],
                                fromPolicy: true,
                                policy: filterPolicyResult[0].policy
                            })
                        } else {
                            return filterSameKeyword[0]
                        }
                    })
                    //end union of words from policy and keywordspotting
                    for (let x = 0; x < tmp.length; x++) {
                        if (tmp[x] !== " " && tmp[x] !== "," && tmp[x] !== ".") {
                            let isKeyword = false;
                            let item = null

                            for (let y = 0; y < collectKeywords.length; y++) {
                                tmptime = null;
                                let keyword = "";
                                let soundlike = undefined;
                                let keywordAndSoundslikeList = (collectKeywords[y].name ? collectKeywords[y].name : "keyword").split('_'); //split keyword by keyword and soundlike
                                keyword = keywordAndSoundslikeList[0].toLowerCase();
                                if (keywordAndSoundslikeList[1]) {
                                    soundlike = keywordAndSoundslikeList[1].toLowerCase();
                                }
                                //split the word
                                let keywordSplit = keyword.split(' ')
                                keywordSplit.forEach(k => {
                                    if (collectKeywords[y].hasSearchExactKeywords) {
                                        if (tmp[x].toLowerCase() === k || tmp[x].toLowerCase() === soundlike) {
                                            isKeyword = true;
                                            tmptime = collectKeywords[y].betterTime;
                                        }
                                    } else {
                                        var word = tmp[x].toLowerCase();
                                        if (this.similarityPercentage(word, k) >= 80 || this.similarityPercentage(word, soundlike) >= 80) {
                                            isKeyword = true;
                                            tmptime = collectKeywords[y].betterTime;
                                        }

                                        //if (tmp[x].toLowerCase().includes(k) || tmp[x].toLowerCase().includes(soundlike)) {
                                        //    isKeyword = true;
                                        //    tmptime = collectKeywords[y].betterTime;
                                        //}
                                    }
                                })

                                if (isKeyword) {
                                    item = collectKeywords[y]
                                    break
                                }
                            }

                            if (isKeyword) {
                                out.push({ src: tmp[x] + " ", sec: tmptime, fromPolicy: item.fromPolicy, policy: item.policy, categorie: item.categorie })
                            } else {
                                out.push({ src: tmp[x] + " " })
                            }
                        }
                    }

                }

                let blockStart = this.SimpleTimeParser(this.props.instances[0].start)
                let blockEnd = this.SimpleTimeParser(this.props.instances[0].end)
                let highlight = false
                this.props.policyResultAdherence.forEach(pr => {
                    let app = pr.appearances[0]
                    if (pr.name != "notMentioned" && (blockStart >= this.SimpleTimeParser(app.startTime) && blockEnd <= this.SimpleTimeParser(app.endTime))) {
                        highlight = true
                    }
                })

                return <div dir={this.props.languageDirection}>
                    {out.map(({ src, sec, categorie, policy, fromPolicy }, i) => {
                        return (
                            <SeekPlayer
                                key={i}
                                text={src}
                                listener={this.props.listener}
                                trans={true} sec={sec}
                                categorie={categorie}
                                fromPolicy={fromPolicy}
                                policy={policy}
                                highlight={highlight}
                                blockStart={blockStart}
                            />
                        )

                    })}
                </div>
            }
            //Adherence and pattern case
            else if (props.policyResultAdherence != null && undefined !== props.policyResultAdherence && props.policyResultAdherence.length > 0) {
                let regex = /(<([^>]+)>)/ig
                let result = props.text.replace(regex, "").replace(/&[^;]+;/g, " ");
                let tmp = splitWithPunctuation(result);

                let blockStart = this.SimpleTimeParser(props.instances[0].start)
                let blockEnd = this.SimpleTimeParser(props.instances[0].end)

                let highlight, fromPolicy = false
                let policy = ""

                props.policyResultAdherence.forEach(pr => {
                    let app = pr.appearances[0]
                    if (Math.floor(blockStart) >= Math.floor(app.startSeconds) && Math.floor(blockEnd) <= Math.floor(app.endSeconds)) {
                        highlight = true
                        fromPolicy = true
                        policy = pr.policy
                    }
                })
                return <div dir={this.props.languageDirection}>
                    {tmp.map((text, i) => {
                        return (
                            <SeekPlayer
                                key={i}
                                text={text}
                                listener={this.props.listener}
                                trans={true} sec={undefined}
                                categorie={undefined}
                                fromPolicy={fromPolicy}
                                policy={policy}
                                highlight={highlight}
                                blockStart={blockStart}
                            />
                        )

                    })}
                </div>
            }
            else {
                    return <div className="transcript-text" dir={props.languageDirection}>{props.text} </div>
            }
        }
        else {
            if (props.type === "chat") {
                return <>
                    <div className="transcript-text" dir={props.languageDirection} dangerouslySetInnerHTML={{ __html: this.formatMention(props.text) }} />

                    <div style={{ display: "flex" }}>
                        {this.props.reactions != null && this.props.reactions.map((item, i) => {
                            //default emojis from reactions are not recognized in the backend and I recieve them as text
                            //I did a mapping only for those 4 values that are not displayed correct
                            const emoji = this.emojiMap[item.reactionType] || item.reactionType; // Use the emoji from the map, or the original value

                            return (
                                <div key={i} style={{ marginRight: "5px" }}>
                                    <TooltipHost
                                        content={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <mgt-person user-id={item.userId} person-card="hover"></mgt-person>
                                                <span style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '14px' }}>{this.getUserDisplayName(item.userId)}</span>
                                            </div>
                                        } closeDelay={100}
                                    >
                                        <span className="reaction-wrap" style={{ cursor: "pointer" }} aria-describedby={i + "tooltip"}>
                                            {emoji}
                                        </span>
                                    </TooltipHost>
                                </div>
                            );
                        })}
                    </div>
                </>
            } else if (props.type === "attach") {
                return <>
                    <div className="transcript-text" dir={props.languageDirection}>{props.text}{props.url != null ? props.url : ""}</div>
                    <div style={{ display: "flex" }}>
                        {this.props.reactions != null && this.props.reactions.map((item, i) => {
                            //default emojis from reactions are not recognized in the backend and I recieve them as text
                            //I did a mapping only for those 4 values that are not displayed correct
                            const emoji = this.emojiMap[item.reactionType] || item.reactionType; // Use the emoji from the map, or the original value

                            return (
                                <div key={i} style={{ marginRight: "5px" }}>
                                    <TooltipHost
                                        content={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <mgt-person user-id={item.userId} person-card="hover"></mgt-person>
                                                <span style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '14px' }}>{this.getUserDisplayName(item.userId)}</span>
                                            </div>
                                        } closeDelay={100}
                                    >
                                        <span className="reaction-wrap" style={{ cursor: "pointer" }} aria-describedby={i + "tooltip"}>
                                            {emoji}
                                        </span>
                                    </TooltipHost>
                                </div>
                            );
                        })}
                    </div>
                </>
            } else {
                return <div className="transcript-text" dir={props.languageDirection}>{props.text}</div>
            }
        }

    };

    //translate text comming from graph for info headers like "call started, call ended, etc."
    translateInformationText = (context, text, translated) => {
        let translatedText = "";

        // Check if text is empty
        if (!text) {
            return translatedText;
        }

        //return original text if already translated from translation option
        if (translated) {
            return text;
        } else { //translate to be displayed in teams language by default

            //text should came in format "CallStarted CallKeep" or "Stop�:�my reason". What cand be translated like callStarted or Stop, will show translated
            //and personalized text like "my reason" will be shown as it is since we don't have control over it
            const values = text.split(" ");

            for (var i = 0; i < values.length; i++) {
                if (values.length <= 1) {
                    translatedText = context.getTranslation("detailContainer", values[i]);
                } else {
                    const translatedValue = context.getTranslation("detailContainer", values[i]);
                    translatedText += ` ${translatedValue}`;
                }
            }
        }



        return translatedText;
    }

    render(): React.ReactNode {
        if (this.props.type === "rec" && this.props.viewType === "liveView") {
            return (
                <MyContext.Consumer>
                    {(context) => (
                        <section id="transcriptAlignLeft" className="ms-Grid-row transcript">
                            <Stack horizontal horizontalAlign="space-evenly">
                                <div dir={this.props.languageDirection}><b>{this.props.viewType === "liveView" ? "" : this.props.speakerId} {getStart(this.props)} {this.translateInformationText(context, this.props.text, this.props.translated)}</b></div>
                            </Stack>
                        </section>
                    )}
                </MyContext.Consumer>
            );
        } else if (this.props.type === "rec") {
            return (
                <MyContext.Consumer>
                    {(context) => (
                        <section className="ms-Grid-row transcript ">
                            <Stack horizontal horizontalAlign="space-evenly">
                                <div dir={this.props.languageDirection}><b>{this.props.viewType === "liveView" ? "" : this.props.speakerId} {getStart(this.props)} {this.translateInformationText(context, this.props.text, this.props.translated)}</b></div>
                            </Stack>
                        </section>
                    )}
                </MyContext.Consumer>
            );
        } else if (this.props.lastSpeaker === this.props.speakerId || (this.props.one2oneView && this.props.viewType !== "attachView")) {
            var reversed = this.props.one2oneSide === 1 ? true : ((!this.props.one2oneView && this.props.languageDirection === "rtl") ? true : false);
            if (this.props.viewType === "attachView")
                reversed = false;
            return (
                <section className="ms-Grid-row transcript ">
                    <Stack horizontal reversed={reversed} tokens={{ childrenGap: this.props.one2oneView ? 10 : 42 }}>
                        {this.props.speakerId === "BOT" && <Persona text={this.props.speakerId} size={PersonaSize.size32} hidePersonaDetails={true} imageUrl={"data:image/jpeg;base64," + this.props.photo} />}

                        <div></div>
                        <article className={(this.props.one2oneSide === 1 && this.props.viewType !== "attachView") ? "transcript-rightContent" : "transcript-content"}>
                            <Stack vertical>
                                <Stack horizontal reversed={reversed} style={{ fontSize: FontSizes.small }} tokens={{ childrenGap: 5 }}>
                                    <Icon style={{ cursor: "pointer" }} iconName={(this.props.type === "audio" || this.props.type == null) ? "Microphone" : (this.props.type === "chat" ? "Chat" : this.props.type === "attach" ? "Attach" : "Tag")} />
                                    <div> {getStart(this.props)} {getChange(this.props)} {getPriority(this.props)}</div>
                                </Stack>
                                {this.outPutText(this.props)}
                            </Stack>
                        </article>
                    </Stack>
                </section>
            );
        } else {
            reversed = this.props.one2oneSide === 1 ? true : ((!this.props.one2oneView && this.props.languageDirection === "rtl") ? true : false);
            if (this.props.viewType === "attachView")
                reversed = false;
            return (
                <section className="ms-Grid-row transcript ">
                    <Stack horizontal reversed={reversed} tokens={{ childrenGap: 10 }}>
                        <Persona text={this.props.speakerId} size={PersonaSize.size32} hidePersonaDetails={true} imageUrl={"data:image/jpeg;base64," + this.props.photo} hide={this.props.speakerId === this.props.lastSpeaker} />
                        <Stack vertical>
                            <Stack horizontal reversed={reversed} style={{ fontSize: FontSizes.small }} tokens={{ childrenGap: 5 }} >
                                <Icon style={{ cursor: "pointer" }} iconName={getIcon(this.props.type)} />
                                <div> {this.props.speakerId} {getStart(this.props)} {getChange(this.props)} {getPriority(this.props)}</div>
                            </Stack>
                            {this.outPutText(this.props)}
                        </Stack>
                    </Stack>
                </section>
            );
        }
    }
}
function splitWithPunctuation(text2) {
    var word = "";
    var tmp = [];
    let text = text2 + " "
    for (let i = 0; i < text.length; i++) {
        if ((text[i].charCodeAt(0) >= 32 && text[i].charCodeAt(0) <= 47)
            || (text[i].charCodeAt(0) >= 58 && text[i].charCodeAt(0) <= 63)
            || (text[i].charCodeAt(0) >= 91 && text[i].charCodeAt(0) <= 96)
            || (text[i].charCodeAt(0) >= 123 && text[i].charCodeAt(0) <= 126)
        ) {
            if (word != "") {
                tmp.push(word);
            }
            tmp.push(text[i]);
            word = "";
        } else {
            word += text[i];
        }

    }

    return tmp;
}
function getStart(props) {
    if (props.instances !== undefined && props.instances != null) {
        return <SeekPlayer sec={props.instances[0].start} listener={props.listener} text={props.instances[0].start} />
    }
    else {
        return "00:00";
    }
}

function getChange(props) {
    if (props.changedNote != null && props.changedNote != "") {
        return <>
            <Icon style={{ cursor: "pointer" }} iconName={getIconChanged(props.changedNote)} />
            {props.changedTime != null ? timeWithoutMs(props.changedTime) : ""}
        </>
    }
    else {
        return <></>
    }
}

function getPriority(props) {
    if (props.priority != null && props.priority != "") {
        return <>
            <Icon style={{ cursor: "pointer" }} iconName={getIconChanged(props.priority)} />
        </>
    }
    else {
        return <></>
    }
}

function getIconChanged(type) {
    if (type === "Deleted")
        return "PageRemove";
    else if (type === "Edited")
        return "FieldChanged";
    else if (type === "high")
        return "Important";
    else if (type === "urgent")
        return "Warning";
    else
        return "";
}

function getIcon(type) {
    if (type === "audio")
        return "Microphone";
    else if (type === "chat")
        return "Chat";
    else if (type === "tag")
        return "Tag";
    else if (type === "attach")
        return "Attach";
    else
        return "Chat";
}

function convertTime(t) {
    let out, s, m, h, d = 0, raw;
    let timeSpanList = t.split('.');
    if (timeSpanList.length < 3) {
        raw = timeSpanList[0];
    } else {
        raw = timeSpanList[1];
        d = Number(timeSpanList[0]) * 60 * 60 * 24;
    }

    let tmp = raw.split(':');

    s = Number(tmp[2]);
    m = Number(tmp[1]) * 60;
    h = Number(tmp[0]) * 60 * 60;
    out = s + m + h + d;

    return out;
}

function timeWithoutMs(t) {
    let out = "";
    let tList = t.split('.');
    if (tList.length < 3) {
        out = tList[0];
    } else {
        out = tList[0] + "." + tList[1];
    }

    return out;
}





class SeekPlayer extends React.Component {
    seek = () => {
        if (this.props.listener !== undefined && this.props.listener != null) {
            this.props.listener(convertTime(this.props.sec));
        }
    };

    seekHighlight = () => {
        if (this.props.listener !== undefined && this.props.listener != null) {
            this.props.listener(this.props.blockStart);
        }
    };

    getTitle = () => {
        if (this.props.fromPolicy) {
            return this.props.categorie !== undefined ? (this.props.policy + "-" + this.props.categorie) : this.props.policy
        } else {
            return this.props.categorie
        }
    }

    getStyle = () => {
        if (this.props.fromPolicy || this.props.highlight) {
            return this.props.categorie !== undefined ? { textDecoration: "underline 2px solid #e59100", /*maringLeft: "3px", marginRight: "3px",*/ padding: "0px" } : { color: "#e59100", /*maringLeft: "3px", marginRight: "3px",*/padding: "0px" }
        } else {
            return {}
        }
    }

    caseSelect = () => {
        if (this.props.sec !== undefined && this.props.sec != null) {
            if (this.props.trans === undefined) {
                return <a className={"link"} onClick={() => (this.seek())}>{timeWithoutMs(this.props.text)}</a>;
            }
            else {
                return <a className={"transcript-special-link"}
                    style={this.getStyle()}
                    title={this.getTitle()} onClick={() => (this.seek())
                    }> {timeWithoutMs(this.props.text)}</a >;
            }
        } else if (this.props.highlight) {
            return <a className={"transcript-special-link"}
                style={this.getStyle()}
                title={this.getTitle()} onClick={() => (this.seekHighlight())
                }> {timeWithoutMs(this.props.text)}</a >;
        }
        else {
            return <span >{this.props.text}</span>;
        }

    };
    render(): React.ReactNode {
        return <span>{this.caseSelect()}</span>
    }
}

