import { SelectionMode } from '@fluentui/react';
import { DetailsList, Stack, ScrollablePane, ScrollbarVisibility } from 'office-ui-fabric-react';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import React, { Component } from 'react'
import { MyContext } from '../../../../../context';
import { getAllCustomFields } from '../../../../../queries/querySettings'

const classNames = mergeStyleSets({
    wrapper: {
        height: '80vh',
        position: 'relative',
        backgroundColor: 'white',
    }
});

export default class MetadataList extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            columns: []
        };
    }

    addDurationToDate = (date, duration) => {
        let splitDuration = duration.split(":")
        var result = new Date(date.getTime())
        result.setHours(date.getHours() + parseInt(splitDuration[0]));
        result.setMinutes(date.getMinutes() + parseInt(splitDuration[1]));
        result.setSeconds(date.getSeconds() + parseInt(splitDuration[2]))
        return result;
    }


    getMaxDatesFromParticipants = (participants) => {
        var initialValue = 1;
        participants.map(o1 => {
            var max = o1.joinDateTime.split(";").length > o1.leftDateTime.split(";").length ? o1.joinDateTime.split(";").length : o1.leftDateTime.split(";").length
            initialValue = initialValue > max ? initialValue : max
        })
        return initialValue;
    }

    async componentDidMount() {
        //console.log(this.props.data)
        //create items for table
        let items = []
        let transcript = this.props.data.unifiedTranscript
        let participant = this.props.data.linkedUser
        let startDate = new Date(parseInt(this.props.data.callStartMs))
        let stopDate = this.addDurationToDate(startDate, this.props.data.duration)
        let recordingStart = "";
        let recordingStop = ""
        if (transcript != null) {
            recordingStart = transcript.transcript.filter(t => t.text === "Start ").map(t => t.instances[0]).map(i => this.addDurationToDate(startDate, i.start.substring(0, 8)).toISOString()).join(" | ")
            recordingStop = transcript.transcript.filter(t => t.text === "Stop ").map(t => t.instances[0]).map(i => this.addDurationToDate(startDate, i.start.substring(0, 8)).toISOString()).join(" | ")
        }
        let mediaType = "";
        if (this.props.data.hasAudio) {
            mediaType = "Voice";
        }
        if (this.props.data.hasVideo) {
            mediaType = mediaType == "" ? "Video" : mediaType + ", Video "
        }
        if (this.props.data.hasScreenSharing) {
            mediaType = mediaType == "" ? "Screenshare" : mediaType + ", Screenshare "
        }
        if (this.props.data.hasChat) {
            mediaType = mediaType == "" ? "Chat" : mediaType + ", Chat "
        }
        if (this.props.data.hasFiles) {
            mediaType = mediaType == "" ? "Files" : mediaType + ", Files "
        }

        items.push({
            value: this.context.getTranslation("configuration", "mediaType"),
            content: mediaType
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "callStartTime"),
            content: startDate.toISOString()
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "callEndTime"),
            content: stopDate.toISOString()
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "duration"),
            content: this.props.data.duration
        })
        items.push({
            value: this.context.getTranslation("conversationsPage", "conversationId"),
            content: this.props.data.conversationId
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "ScenarioID"),
            content: this.props.data.scenarioId
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "MicrosoftCallID"),
            content: this.props.data.callId
        })

        items.push({
            value: this.context.getTranslation("detailContainer", "RecordingOwnerID"),
            content: participant.filter(p => p.isRecordingOwner)[0].userId
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "RecordingOwnerName"),
            content: participant.filter(p => p.isRecordingOwner)[0].name
        })
        items.push({
            value: this.context.getTranslation("configuration", "recordingType"),
            content: participant.filter(p => p.isRecordingOwner)[0].recordingType
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "RecorderIDBotID"),
            content: this.props.data.botAppId
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "HeaderText"),
            content: this.props.data.topic
        })
        items.push({
            value: this.context.getTranslation("configuration", "tenantId"),
            content: this.props.data.tenantId
        })
        items.push({
            value: this.context.getTranslation("conversationsPage", "conversationType"),
            content: this.props.data.recordingType
        })
        if (this.props.data.engagementId != null) {
            items.push({
                value: this.context.getTranslation("conversationsPage", "engagementId"),
                content: this.props.data.engagementId
            })
        }
        items.push({
            value: this.context.getTranslation("detailContainer", "RecordingStartedTimes"),
            content: recordingStart
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "RecordingStoppedTimes"),
            content: recordingStop
        })
        items.push({
            value: this.context.getTranslation("configuration", "retentionTime"),
            content: this.props.data.ttl
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "ParticipantCount"),
            content: participant.length
        })
        items.push({
            value: this.context.getTranslation("conversationsPage", "participants"),
            content: <HorizontalTable data={participant.map(p => p.name)} />
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "ParticipantTenantIDs"),
            content: <HorizontalTable data={participant.map(p => p.tenantId)} />
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "ParticipantUserIDs"),
            content: <HorizontalTable data={participant.map(p => p.userId)} />
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "DeviceDisplayName"),
            content: <HorizontalTable data={participant.map(p => p.deviceDisplayName)} />
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "OnbehalfOfUserID"),
            content: <HorizontalTable data={participant.map(p => p.onBehalfOfUserId)} />
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "chatid"),
            content: this.props.data.chatId
        })
        for (var i = 0; i < this.getMaxDatesFromParticipants(participant); i++) {
            items.push({
                value: (i + 1) + ". " + this.context.getTranslation("detailContainer", "ParticipantJoinDateTimes"),
                content: <HorizontalTable data={participant.map(p => p.joinDateTime.split(";")[i] != undefined ? p.joinDateTime.split(";")[i] : "-")} />
            })
            items.push({
                value: (i + 1) + ". " + this.context.getTranslation("detailContainer", "ParticipantLeaveDateTimes"),
                content: <HorizontalTable data={participant.map(p => p.leftDateTime.split(";")[i] != undefined ? p.leftDateTime.split(";")[i] : "-")} />
            })
        }
        items.push({
            value: this.context.getTranslation("conversationsPage", "notes"),
            content: this.props.data.notes
        })
        items.push({
            value: this.context.getTranslation("conversationsPage", "stopReason"),
            content: this.props.data.stopReason
        })
        items.push({
            value: this.context.getTranslation("conversationsPage", "encKeyUrl"),
            content: this.props.data.encKeyUrl
        })
        items.push({
            value: this.context.getTranslation("detailContainer", "transferorName"),
            content: this.props.data.transferorName
        })
        //manage values custom fields
        if (this.props.data.customFields !== null) {
            let customFields = JSON.parse(this.props.data.customFields).customFields
            let res = await this.props.client.query({
                query: getAllCustomFields, variables: { auth: this.props.authorization }
            })
            var allCustomFields = res.data.getAllCustomFieldDefinitions.filter(cf => {
                return this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.id) || this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.fieldName);
            });
            //sort the list by accesibleCustomFields
            var sortCustomFields = (a, b) => {
                let indexA = this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(a.fieldName) !== undefined ? this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(a.fieldName) : this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(a.id)
                let indexB = this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(b.fieldName) !== undefined ? this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(b.fieldName) : this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(b.id)
                return indexA - indexB;
            }
            allCustomFields.sort(sortCustomFields);
            let customFieldsItem = allCustomFields.map(cf => {
                let valueCF = customFields.filter(c => c.fieldId == cf.id)[0]
                return (
                    {
                        value: cf.fieldName,
                        content: valueCF != undefined ? valueCF.fieldValues.join(" | ") : ""
                    }
                )
            })
            items = [...items, ...customFieldsItem]
        }


        //columns
        const columns = [
            {
                key: 'value',
                name: this.context.getTranslation("detailContainer", "value"),
                fieldName: 'value',
                minWidth: 150,
                maxWidth: 250,
                isResizable: true,
                data: 'string',
            },
            {
                key: 'content',
                name: this.context.getTranslation("detailContainer", "content"),
                fieldName: 'content',
                isResizable: true,
                data: 'string',
                minWidth: 260 * participant.length,
            }]
        this.setState({
            columns,
            items
        })
    }

    render() {
        return (
            <div className={classNames.wrapper}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsList
                        items={this.state.items}
                        columns={this.state.columns}
                        selectionMode={SelectionMode.none}
                        setKey="value" />
                </ScrollablePane>
            </div>
        )
    }
}

class HorizontalTable extends Component {

    static contextType = MyContext

    render() {
        return (
            <div>
                <Stack horizontal>
                    {this.props.data.map((d, i) => {
                        return (<Stack.Item key={i}>
                            <div style={{ marginRight: "10px", paddingRight: "5px", borderRight: "1px solid rgb(141, 141, 141)", minWidth: "250px", fontSize: "12px" }}>{d}</div>
                        </Stack.Item>)
                    })}
                </Stack>
            </div>
        )
    }
}