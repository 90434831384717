import React from 'react';
import {
  Checkbox,
  Dropdown,
  DropdownMenuItemType, Icon,
  IStackStyles, IStackTokens,
  Label,
  PrimaryButton,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { ASCHeader } from '../ASCHeader/ASCHeader';
import ASCFooter from '../ASCFooter/ASCFooter';


// ASC Ci colours
const headerBlue = '#00315E';
const headerRed = '#91001E';
const headerLightBlue = '#71A2D2';
const headerOrange = '#DF6F15';
const headerGreen = '#90BF38';


const styleVisualsWrapper = {

};
const dropdownStyles = {
};

const options = [
  { key: 'Header', text: 'Category', itemType: DropdownMenuItemType.Header },
  { key: 'General', text: 'General' },
  { key: 'Product', text: 'Product' },
  { key: 'Service', text: 'Service' },
  { key: 'Cooperation', text: 'Cooperation' },

];


const stackContainerStyles = {
  root: {
    paddingTop: 200,
    paddingBottom: 100,
  },
};

// Stack Row
const stackStyles = {
  root: {
    maxWidth: 1023,
    marginBottom: 150,
  },
};
const stackStyles2 = {
  root: {
    maxWidth: 1023,
    marginBottom: 10,
  },
};

const stackItemVisualStyles = {
  root: {
    width: 550,
    textAlign: 'center',
  },
};
const stackContractStylesSmall = {
  root: {
    width: 350,
    textAlign: 'center',
  },
};
const stackContractStylesLagre = {
  root: {
    width: stackContractStylesSmall.root.width * 2,
    textAlign: 'center',
  },
};

const stackItemVisualTextStyles = {
  root: {
    width: 550,
    paddingLeft: '10%',

  },
};

const stackItemCopyStyles = {
  root: {
    width: 400,
  },
};

const visualH1Style = {
  color: headerBlue,
  fontWeight: 900,
  fontSize: 36,
};

const visualH2Style = {
  fontWeight: 500,
  fontSize: 22,
  marginTop: 10,
};

const rowStackToken = {
  childrenGap: 10,
};


// Feature Overview


const IconChecked = () => <Icon iconName="CheckMark" className="shop-checkmark" />;
const IconNoo = () => <Icon iconName="Cancel" className="shop-checkmark" style={{ color: 'red' }} />;

const stackTableRowStyles = {
  root: {
    flexWrap: 'nowrap',
  },
};

const stackTableHeaderItemStyles = {
  root: {
    fontWeight: 900,
    width: 170,
    display: 'flex',
    alignItems: 'flex-end',
    padding: '5px 10px',
    borderBottom: '3px solid #00315E',
  },
};

const stackTableItemStyles = {
  root: {
    width: 170,
    display: 'flex',
    alignItems: 'center',
    height: 50,
  },
};

const stackTableItemCheckMarkStyles = {
  root: {
    width: 170,
    fontSize: 30,
    textAlign: 'center',
    color: '#90BF38',
    height: 50,
  },
};


export default class Contact extends React.Component {
  render() {
    return (
      <div>
        <Stack
          horizontalAlign="center"
          style={{ paddingBottom: 60 }}
        >
          <h2 style={{ paddingTop: 40 }}> Get in contact </h2>
          <Stack
            horizontal
            horizontalAlign="center"
            styles={stackStyles2}
            style={{ paddingTop: 5 }}
            tokens={rowStackToken}
            wrap="true"
          >
            <br />
            <Stack.Item align="stretch" grow={1} styles={stackContractStylesSmall}>

              <TextField required label="First name" />
              <TextField required label="Company" />
              <TextField label="Street" />
              <TextField label="Zip-Code" />

              <TextField label="Country" />
              <TextField required label="E-Mail" />

            </Stack.Item>
            <Stack.Item align="stretch" grow={1} styles={stackContractStylesSmall}>

              <TextField required label="Last name" />
              <TextField label="Position" />
              <TextField label="Building number" />

              <TextField label="City" />
              <TextField label="Phone number" />
              <Dropdown placeholder="Select an option" label="Category" options={options} styles={dropdownStyles} />

              <br />
              <Checkbox label="Please contact me" />
              <Checkbox label="Cooperation" />
              <Checkbox label="Please send me Information" />


            </Stack.Item>

          </Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            styles={stackStyles2}
            tokens={rowStackToken}
            wrap="true"
          >
            <Stack.Item align="stretch" grow={1} styles={stackContractStylesLagre}>
              <TextField multiline label="Send me information about ..." />
              <TextField multiline label="Comment" />
            </Stack.Item>


          </Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            styles={stackStyles2}
            tokens={rowStackToken}
            wrap="true"
          >
            <Stack.Item align="stretch" grow={1} styles={stackContractStylesLagre}>
              <Label> Privacy Policy: The information provided by you using the contact form above will be used solely to provide you with the information you require. Your data will only be sent to ASC using this form via e-mail and will not be stored in a database. This generated email will be deleted after completing the process. If you would like further information, we ask you to fill out the contact form again, to register for our newsletter or to send an e-mail to {this.props.data.support} </Label>
              <Checkbox label="I agree that, for the purposes of processing my communication, the data provided by me will be used as above; I have taken note of the privacy policy." />
              <PrimaryButton style={{ color: 'white', background: '#90BF38' }} className="buyButton" text="Submit" />
            </Stack.Item>
          </Stack>

        </Stack>

      </div>
    );
  }
}
