import React, { Component } from 'react';
import { Stack, IconButton } from 'office-ui-fabric-react';

export default class ScorecardFolding extends Component {

    render() {
        return (
            <Stack>
                {!this.props.selectedKey.includes(this.props.selected) && <IconButton
                    iconProps={{ iconName: 'ChevronRight' }}
                    onClick={this.props.open}
                />}
                {this.props.selectedKey.includes(this.props.selected) && <IconButton
                    iconProps={{ iconName: 'ChevronDown' }}
                    onClick={this.props.close}
                />}
            </Stack>
        )
    }
}