import React from 'react'
import { SearchBox } from 'office-ui-fabric-react/lib';
import { queryGetAllUsers, mutationCreateUser, queryGetConfigData } from "../../../queries/querySettings";
import { toast } from "react-toastify";

import {
    DetailsList,
    DetailsListLayoutMode,
    mergeStyleSets, Persona, PersonaSize, PrimaryButton,
    Selection, SelectionMode
} from "office-ui-fabric-react";
import { MyContext } from '../../../context';

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});

export default class AddUser extends React.Component {
    _selection: Selection;

    constructor(props) {
        super(props);
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });
    }


    state = {
        userItems: {},
        selectedItem: {}
    };




    _columns = [
        {
            key: 'column1',
            name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "options")}</>)}</MyContext.Consumer>,
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onColumnClick: this._onColumnClick,
            onRender: (item) => {
                return <div>
                    <Persona size={PersonaSize.size24} text={item.value} />
                </div>

            }
        }
        , { key: 'column2', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "account")}</>)}</MyContext.Consumer>, fieldName: 'value', minWidth: 50, maxWidth: 160, isResizable: true }
        , { key: 'column3', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "username")}</>)}</MyContext.Consumer>, fieldName: 'key', minWidth: 50, maxWidth: 220, isResizable: true }
    ];

    getColItems = async (apol, filter) => {
        let _allItems = [];
        var data = await apol
            .query({
                query: queryGetAllUsers,
                variables: {
                    auth: this.props.authorization,
                    filter: filter
                }
            });

        var DataArr = data.data.getAllUsers;
        if (DataArr !== undefined) {
            for (let i = 0; i < DataArr.length && i < 20; i++) {
                // Filter all users which have a license already
                var found = false;
                this.props.data.forEach(function (aUser) {
                    if (aUser.userId === DataArr[i].userId) {
                        found = true;
                    };
                });
                if (found === false) {
                    _allItems.push({
                        key: DataArr[i].username,
                        value: DataArr[i].name,
                        userId: DataArr[i].userId
                    });
                }
            }
        }
        this.setState({ userItems: _allItems });


    }

    render(): React.ReactNode {
        return <MyContext.Consumer>
            {(context) => (<div>
            <div style={{float: "left", padding: "5px"}}>
                <form>
                    <SearchBox placeholder="Search" onChange={(event,newValue) => (event?.persist(),this.getColItems(this.props.apol, newValue))}
                    />
                    <DetailsList
                        key={"002"}
                        items={this.state.userItems}
                        columns={this._columns}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        selection={this._selection}
                        selectionMode={SelectionMode.multiple}
                        selectionPreservedOnEmptyClick={true}
                        onActiveItemChanged={this._onItemInvoked}
                    />
                    <PrimaryButton onClick={() => { this.notifyUserAdd(this.props.apol); this.props.closeModal(); }} data-automation-id="test" text={context.getTranslation("configuration", "addSelected")}  allowDisabledFocus={true} />
                </form>
            </div>
            </div>)
            }
        </MyContext.Consumer>;
    }

    _onItemInvoked = (item, index, ev) => {
        this.setState({ selectedItem: item });
        console.log(item.key);
    }


    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                return '';
            default:
                return `${selectionCount} Items selected`;
        }
    }


    notifyUserAdd = async (apol) => {
        // Add User
        var userList = [];
        console.log("addType: " + this.props.addType);
        this._selection.getSelection().forEach((aUser) => {
            userList.push({
                userId: aUser.userId,
                name: aUser.value,
                username: aUser.key,
                license: this.props.addType
            });
        });
        var data = await apol
            .mutate({
                mutation: mutationCreateUser,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    user: userList
                }
            });
        if (data.data.createUser == null || data.data.createUser.length === 0) {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration","addUserNotSuccessful")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else if (data.data.createUser[0].name === "error") {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addUserNotSuccessful")}</>)}</MyContext.Consumer> + data.data.createUser[0].access, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userAdded")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

}
