import React, { Component } from 'react';
import { ASCHeader } from "../ASCHeader/ASCHeader";
import ASCFooter from "../ASCFooter/ASCFooter";
import { Text } from 'office-ui-fabric-react';

import {
    // Checkbox,
    // DefaultButton,
    // PrimaryButton,
    Stack
} from "office-ui-fabric-react";
import { MyContext } from '../../context';


// Styles definition
const stackContainerStyles: IStackStyles = {
    root: {
        paddingTop: 50
    }
};


const stackStyles: IStackStyles = {
    root: {
        maxWidth: 660,
        marginBottom: 50
    }
};

// Tokens definition
const stackTokens: IStackTokens = { childrenGap: 5 };


export default class Privacy extends Component {

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <>
                        <ASCHeader title={context.getTranslation("impressum", "legalStatements")} />
                        <Stack tokens={stackTokens}>

                            <Stack horizontalAlign="center" styles={stackContainerStyles}>
                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">ASC Technologies AG</Text>
                                    <Text variant="large">

                                        Seibelstraße 2<br />
                                        63768 Hösbach - Germany<br />
                                        {context.getTranslation("impressum", "phone")}: +49 6021 5001 - 0<br />
                                        {context.getTranslation("impressum", "fax")}: +49 6021 5001 - 310<br />
                                        {context.getTranslation("impressum", "email")}: hq@asctechnologies.com<br />
                                        <br />
                                        {context.getTranslation("impressum", "executiveBoard")}: Günter Müller, Marco Müller, Dr. Gerald Kromer<br />
                                        {context.getTranslation("impressum", "chairman")}: Gisela Müller<br />
                                        <br />
                                        {context.getTranslation("impressum", "registrationNumber")}: DE 812494216<br />
                                        {context.getTranslation("impressum", "courtOfJurisdiction")}: Aschaffenburg HRB 6980<br />
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack>

                        <ASCFooter />
                    </>
                )
                }
            </MyContext.Consumer>
        );
    }

}
