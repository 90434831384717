import { Stack, DocumentCard, MessageBarType, MessageBar, Spinner, Icon, FontSizes, CommandBar, ContextualMenuItemType, Dialog, PrimaryButton, DialogFooter, DefaultButton, DialogType } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import '../ComplianceView.scss';
import { MyContext } from '../../../context';
import { FontIcon, Toggle } from '@fluentui/react';
import { Text, FontWeights } from 'office-ui-fabric-react/lib/';
import * as QueryPolicy from './PolicyQuery';
import SafetyDialog from '../../CustomComponents/SafetyDialog';

export default class PolicyTileView extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            alertMessage: "",
            alertType: "",
            isLoading: false,
            hideDialog: true
        }
    }

    getUsersAndGroups = (users, groups) => {
        return (<span>TODO</span>)
    }

    activeDeactivePolicy = () => {
        let policyToBeActivated = this.props.policies.filter(pol => { return pol.id == this.props.policyId })[0]
        this.setState({
            isLoading: true,
            hideDialog: true
        })
        this.props.apol
            .mutate({
                mutation: QueryPolicy.changeStatusPolicy,
                variables: { policyId: policyToBeActivated.id }
            })
            .then((result) => {
                if (result.data.changeStatusPolicy.status !== "ok") {
                    this.setState({
                        alertMessage: this.context.getTranslation("compliance", result.data.changeStatusPolicy.text),
                        alertType: result.data.changeStatusPolicy.status,
                        isLoading: false
                    })
                } else {
                    this.props.refreshPolicies()
                }
            });
    }

    getChannelRender = (channelString) => {
        switch (channelString) {
            case 'None':
                return "";
            case 'Text':
                return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Chat' title="ActionCenter" />;
            case 'Voice':
                return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Microphone' title="Microphone" />;
            case 'Both':
                return (
                    <Stack horizontal>
                        <Stack.Item>
                            <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Microphone' title="Microphone" />
                        </Stack.Item>

                        <Stack.Item>
                            <Icon className="ContentColor" style={{ padding: "0px", paddingLeft: "4px", fontSize: FontSizes.icon }} iconName='Chat' title="ActionCenter" />
                        </Stack.Item>
                    </Stack>)
        }
    }

    shortTitle = (title, windowsWidth) => {
        const titleLength = title.length;
        const maxLength = windowsWidth < 600 ? 18 : 25;
        // Replace _ or [
        var title_new = title.replace('_', " ");
        title_new = title_new.replace("[", " ");
        title_new = title_new.replace("]", " ");
        if (titleLength > maxLength && maxLength > 20) {
            return title_new.slice(0, maxLength) + '...';
        } else if (titleLength > maxLength) {
            return title_new.slice(0, maxLength) + '..';
        } else {
            return title_new;
        }
    }

    getRiskRating = (riskRatingString) => {
        switch (riskRatingString) {
            case "Info":
                return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Info' title="Information" />;
            case "Warn":
                return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='WarningSolid' title="Warning" />;
            case "Adherence":
                return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Warning' title="Attention" />;
            default:
                return <span />
        }
    }

    getTiles = (name, policies, active) => {
        const menuItems = [
            {
                key: 'Actions',
                itemType: ContextualMenuItemType.Header,
                text: this.context.getTranslation("dashboard", "widgetMenuActions"),
                itemProps: {
                    lang: 'en-us',
                },
            },
            {
                key: 'Edit',
                iconProps: {
                    iconName: 'Edit',
                },
                text: this.context.getTranslation("compliance", "editPolicy"),
                disabled: !this.props.userRules.ruleAccess.userCompliancePolicyEdit,
                onClick: () => {
                    this.props.openPanel("edit", true)
                },
            },
            {
                key: 'Delete',
                iconProps: {
                    iconName: 'Delete',
                    style: {
                        color: 'red',
                    },
                },
                text: this.context.getTranslation("compliance", "deletePolicy"),
                disabled: !this.props.userRules.ruleAccess.userCompliancePolicyEdit,
                onClick: () => {
                    this.props.deletePolicy()
                },
            }
        ];
        return (
            <div>
                {/*Alert*/}
                {this.state.alertMessage !== "" && <Stack>
                    <MessageBar
                        messageBarType={this.state.alertType === "warning" ? MessageBarType.warning : MessageBarType.error}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close"
                    >
                        {this.state.alertMessage}
                    </MessageBar>
                </Stack>
                }
                <div className="grid-row-header">
                    <Text variant={'xLarge'} nowrap block style={{ marginLeft: "20px" }}>
                        {this.context.getTranslation("compliance", name)}
                    </Text>
                    {policies.length == 0 && (<span style={{ margin: '10px' }}>{this.context.getTranslation("compliance", "noDataTile")}</span>)}
                    {policies.length > 0 && (
                        <div className="grid-row-inner">
                            {policies.map((policy, i) => {
                                let iconName = ""
                                let shortTitle = ""
                                switch (policy.basedOn) {
                                    case "PolicyRule": {
                                        iconName = "TaskManager"
                                        shortTitle = this.shortTitle(policy.policyRules !== null ? policy.policyRules.map(cat => { return cat.name }).join() : "", window.innerWidth)
                                        break;
                                    }
                                    case "AICategorization": {
                                        iconName = "UserGauge"
                                        shortTitle = this.shortTitle(policy.aICategorizationInfos !== null ? policy.aICategorizationInfos.map(cat => { return cat.categorizationName }).join() : "", window.innerWidth)
                                        break;
                                    }
                                    case "Scorecard": {
                                        iconName = "TrackersMirrored" 
                                        shortTitle = this.shortTitle(policy.aIScorecardInfos !== null ? policy.aIScorecardInfos.map(s => { return s.scorecardName }).join() : "", window.innerWidth)
                                        break;
                                    }
                                }
                                return (
                                    <div key={i} className={window.innerWidth < 600 ? "GridItemSmall" : "GridItem"}  >
                                        <DocumentCard key={i}
                                            onClick={() => { this.props.selectPolicy(policy.id) }}
                                            style={policy.id == this.props.policyId ? { borderStyle: 'groove', margin: '20px' } : { margin: '20px' }}
                                            className={window.innerWidth < 600 ? "GridItemSmall" : "GridItem"}
                                        >
                                            <Stack vertical
                                                className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"}
                                                style={{ width: "100%" }}>
                                                <Stack horizontal
                                                    tokens={{ childrenGap: '10' }}
                                                    styles={{
                                                        root: { 'justifyContent': 'space-between' }
                                                    }}>
                                                    <Stack horizontalAlign="start"
                                                        style={{ color: this.props.theme.palette.themePrimary, padding: "0px" }}>
                                                        <Stack.Item style={{ padding: "0px" }}>
                                                            {this.getChannelRender(policy.channel)}
                                                        </Stack.Item>
                                                    </Stack>
                                                    <Stack horizontalAlign="start"
                                                        style={{ color: this.props.theme.palette.themePrimary, padding: "0px", height: "30px" }}>
                                                        <Stack.Item style={{ padding: "0px" }}>
                                                            <CommandBar
                                                                overflowItems={menuItems}
                                                                styles={{
                                                                    root: {
                                                                        color: 'transparent',
                                                                        background: 'transparent',
                                                                        paddingRight: '0px',
                                                                        marginLeft: '15px',
                                                                    },
                                                                }}
                                                                onClickCapture={() => { this.props.selectPolicy(policy.id) }}
                                                            />
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack>
                                                <Text className="ContentTitle"
                                                    variant="mediumPlus"
                                                    wrap
                                                    style={{ color: this.props.theme.palette.themePrimary, padding: "0px", fontWeight: FontWeights.semibold }}
                                                >
                                                    {this.shortTitle(policy.name, window.innerWidth)}
                                                </Text>
                                                <Stack horizontalAlign="start" horizontal>
                                                    <FontIcon
                                                        iconName={iconName}
                                                        style={{ color: this.props.theme.palette.themePrimary, marginRight: "5px", marginTop: "2px" }}
                                                    />
                                                    <Text className="ContentColor"
                                                        variant="mediumPlus"
                                                        style={{ color: this.props.theme.palette.themePrimary }} >
                                                        {shortTitle}
                                                    </Text>
                                                </Stack>
                                                <Stack horizontal
                                                    tokens={{ childrenGap: '10' }}
                                                    style={{ marginTop: "35px" }}
                                                    styles={{
                                                        root: { 'justifyContent': 'space-between' }
                                                    }}>
                                                    <Stack horizontalAlign="start"
                                                        style={{ color: this.props.theme.palette.themePrimary, padding: "0px" }}>
                                                        <Stack.Item style={{ padding: "0px", marginLeft: "1px" }}>
                                                            {this.getRiskRating(policy.riskRating)}
                                                        </Stack.Item>
                                                    </Stack>
                                                    <Stack horizontalAlign="start"
                                                        style={{ color: this.props.theme.palette.themePrimary, padding: "0px", height: "30px" }}>
                                                        <Stack.Item>
                                                            <Toggle
                                                                checked={policy.active}
                                                                disabled={!this.props.userRules.ruleAccess.userCompliancePolicyActivate || !policy.users.length}
                                                                onChange={() => this.toggleHideDialog(false, policy.id, policy.active)}
                                                                ariaLabel="active action"
                                                                id={`activeButton_${policy.id}`}
                                                            />
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack>

                                            </Stack>
                                        </DocumentCard>
                                    </div>
                                )
                            })
                            }
                        </div>
                    )}
                </div>
            </div>
        )
    }

    toggleHideDialog = (hide, policyId, policyIsActive) => {
        this.setState({
            hideDialog: hide,
            policyId,
            policyIsActive
        })
    }

    render() {
        return (
            <div>
                {this.state.isLoading && <div>
                    <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                </div>}
                {!this.state.isLoading &&
                    <div className="grid-row-wrapper">
                        {this.getTiles('activePolicies', this.props.policies.filter(x => { return x.active }, true))}
                        {this.getTiles('noActivePolicies', this.props.policies.filter(x => { return !x.active }, false))}
                    </div>}
                <SafetyDialog
                    title={this.state.policyIsActive ? this.context.getTranslation("compliance", "teachingBubbleTitleDeActivePolicy") : this.context.getTranslation("compliance", "teachingBubbleTitleActivePolicy")}
                    subText={this.state.policyIsActive ? this.context.getTranslation("compliance", "teachingBubbleTextDeActivePolicy") : this.context.getTranslation("compliance", "teachingBubbleTextActivePolicy")}
                    hideDialog={this.state.hideDialog}
                    closeDialog={() => { this.setState({ hideDialog: true }) }}
                    ok={this.activeDeactivePolicy}
                />
            </div>
        )
    }
}
