import { Pivot, PivotItem, Stack, Text } from 'office-ui-fabric-react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { CommandBarButton } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import './ComplianceView.scss';
import { MyContext } from '../../context';
import PolicyRules from './PolicyRules/PolicyRules';
import Policies from './Policy/Policies';
import PatternDetection from './PatternDetection/PatternDetection';
import CategoryWrapper from '../RuleSetter/CategoryWrapper';
import AdherencePhrases from './AdherencePhrases/AdherencePhrases';
import AIAdherencePhrases from './AIAdherencePhrases/AIAdherencePhrases';
import ConversationSafe from "../ConversationSafe/ConversationSafe";
import AIQuestions from './AIQuestion/AIQuestions';
import AICategorization from './AICategorization/AICategorization';
import AIQualityManagement from './AIQualityManagement/AIQualityManagement';

// Styles definition
const stackContainerStyles: IStackStyles = {
    root: {
        paddingTop: 50
    }
};
const stackStyles: IStackStyles = {
    root: {
        maxWidth: 660,
        marginBottom: 50
    }
};
const stackTokens: IStackTokens = { childrenGap: 5 };


export default class ComplianceView extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            helpPanel: false,
            query: 0,
            openCategory: true,
            openAdherencePhrase: true,
            selectedKey: "Policies"
        };
        this.data = null;
    }

    getPivotSelectCategory = () => {
        let option = [
            {
                "key": true,
                "text": this.context.getTranslation("compliance", "categoriesTabName"),
                "onClick": () => this.setState({ openCategory: true, selectedKey: "Cat" })
            },
            {
                "key": false,
                "text": this.context.getTranslation("compliance", "categorizationTabName"),
                "onClick": () => this.setState({ openCategory: false, selectedKey: "Cat" })
            }
        ]
        const optionTypePattern = {
            items: option,
            directionalHintFixed: true,
        };

        return (
            <div >
                <Icon style={{ padding: "7px", cursor: "pointer" }} iconName={"UserGauge"} />
                <span>{this.state.openCategory ? this.context.getTranslation("compliance", "categoriesTabName") : this.context.getTranslation("compliance", "categorizationTabName")}</span>
                <CommandBarButton
                    styles={{ root: {} }}
                    disabled={false}
                    checked={false}
                    menuProps={optionTypePattern}
                />
            </div>)
    }

    getPivotSelectAdherencePhrase = () => {
        let option = [
            {
                "key": true,
                "text": this.context.getTranslation("compliance", "AdherencePhrases"),
                "onClick": () => this.setState({ openAdherencePhrase: true, selectedKey: "AdherencePhrases" })
            },
            {
                "key": false,
                "text": this.context.getTranslation("compliance", "AIAdherencePhrases"),
                "onClick": () => this.setState({ openAdherencePhrase: false, selectedKey: "AdherencePhrases" })
            }
        ]

        const optionTypePattern = {
            items: option,
            directionalHintFixed: true,
        };

        return (
            <div >
                <Icon style={{ padding: "7px", cursor: "pointer" }} iconName={"EntitlementRedemption"} />
                <span>{this.state.openAdherencePhrase ? this.context.getTranslation("compliance", "AdherencePhrases") : this.context.getTranslation("compliance", "AIAdherencePhrases")}</span>
                <CommandBarButton
                    styles={{ root: {} }}
                    disabled={false}
                    checked={false}
                    menuProps={optionTypePattern}
                />
            </div>)
    }

    render() {
        return (
            window.innerWidth > 750 ?
                <div>
                    <header className="app-header ms-Grid-row" style={{ backgroundColor: this.props.theme.palette.neutralLighterAlt, boxShadow: Depths.depth8 }} >
                        <Pivot selectedKey={String(this.state.selectedKey)} aria-label="compliance-navbar" linkSize="large" onLinkClick={(e) => { this.setState({ selectedKey: e.props.itemKey }) }}>
                            <PivotItem itemKey="Policies" headerText={this.context.getTranslation("compliance", "policiesTabName")} itemIcon="Shield">
                                <Policies theme={this.props.theme} auth={this.props.authorization} apol={this.props.apol} iconView={this.props.iconView} userRules={this.props.userRules} />
                            </PivotItem>
                            {this.props.userRules.addOnQualityManagement && this.props.userRules.ruleAccess.userComplianceQM && 
                                <PivotItem itemKey="QM" headerText={this.context.getTranslation("compliance", "AIQM")} itemIcon="TrackersMirrored">
                                    <AIQualityManagement apol={this.props.apol} auth={this.props.authorization} userRules={this.props.userRules} theme={this.props.theme} />
                                </PivotItem>
                            }
                            <PivotItem itemKey="PolicyRules" headerText={this.context.getTranslation("compliance", "policyRulesTabName")} itemIcon="TaskManager">
                                <PolicyRules theme={this.props.theme} auth={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} />
                            </PivotItem>
                            <PivotItem itemKey="PatternDetection" headerText={this.context.getTranslation("compliance", "patternsTabName")} itemIcon="NumberSequence">
                                <PatternDetection theme={this.props.theme} auth={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} />
                            </PivotItem>
                            <PivotItem itemKey="AdherencePhrases" onRenderItemLink={this.getPivotSelectAdherencePhrase} >
                                {this.state.openAdherencePhrase && <AdherencePhrases theme={this.props.theme} auth={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} />}
                                {!this.state.openAdherencePhrase && <AIAdherencePhrases theme={this.props.theme} auth={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} />}
                            </PivotItem>
                            {this.props.userRules.ruleAccess.userComplianceAIQuestions &&
                                <PivotItem itemKey="AIQuestions" headerText={this.context.getTranslation("compliance", "AIQuestions")} itemIcon="EntitlementRedemption">
                                    <AIQuestions theme={this.props.theme} auth={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} />
                                </PivotItem>}
                            <PivotItem itemKey="Cat" onRenderItemLink={this.getPivotSelectCategory} >
                                {this.state.openCategory && <CategoryWrapper authorization={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} theme={this.props.theme} fromCompliance={true} />}
                                {!this.state.openCategory && <AICategorization authorization={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} theme={this.props.theme} />}
                            </PivotItem>
                            {this.props.userRules.ruleAccess.userSafe != "No" &&
                                <PivotItem itemKey="ConversationSafe" headerText={`${this.context.getTranslation("compliance", "conversationSafeTabName")}`} itemIcon="LockSolid">
                                    <ConversationSafe apol={this.props.apol} auth={this.props.authorization} userRules={this.props.userRules} theme={this.props.theme} />
                                </PivotItem>}

                        </Pivot>
                    </header>
                </div>
                :
                <div>
                    <Stack tokens={stackTokens}>
                        <Stack horizontalAlign="center" styles={stackContainerStyles}>
                            <Stack styles={stackStyles}>
                                <Text variant="xxLarge">{this.context.getTranslation("compliance", "frameToSmallTitle")}</Text>
                                <Text variant="xLarge">{this.context.getTranslation("compliance", "frameToSmallText")}</Text>
                            </Stack>
                        </Stack>
                    </Stack>
                </div>
        )
    }







}
