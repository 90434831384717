import gql from "graphql-tag";

export const queryGetCategory = gql`
    query getCategoryData ($auth:AuthInput){
        getCategoryData(input:$auth)
        {
            categorydata{
                name
                keywords
                id
                tenantId
                frequence
                fromCompliance
                sentiment
                link
                searchExactKeywords
                countUniqueWordsOnce
                categorizationActionType
                retentionTimeSetting
                masterItemId
                categoryGroup {
                        id
                        name
                        description
                        tenantId
                        generalGroup
                    }
            }
        }
    }
`;

export const getAllCategoryVersions = gql`
    query getAllCategoryVersions ($auth:AuthInput){
        getAllCategoryVersions(input:$auth)
        {
            categorydata{
                name
                keywords
                id
                version
                selected
                tenantId
                frequence
                fromCompliance
                sentiment
                link
                searchExactKeywords
                countUniqueWordsOnce
                categorizationActionType
                retentionTimeSetting
                masterItemId
                categoryGroup {
                        id
                        name
                        description
                        tenantId
                        generalGroup
                    }
            }
        }
    }
`;


export const getAllVersionOfCategoryData = gql`query ent ($masterItemId: String) {
                                        getAllVersionOfCategoryData(masterItemId: $masterItemId) {
                                                id
                                                version
                                                name
                                                keywords
                                                tenantId
                                                frequence
                                                sentiment
                                                link
                                                searchExactKeywords
                                                countUniqueWordsOnce
                                                masterItemId
                                            }
                                    }`

export const restoreCategory = gql`mutation ent ($id: String) {
                                        restoreCategory(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`

export const deleteCategory = gql`mutation ent ($id: String) {
                                        deleteCategory(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`
