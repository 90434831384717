import React, { Component } from "react";
import { TagPicker, ITag, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers';
import { Label } from 'office-ui-fabric-react';
import gql from 'graphql-tag';
import { MyContext } from "../../context";
import _ from 'lodash'


export default class ConversationPicker extends Component {

    static contextType = MyContext;



    constructor(props) {
        super(props)
        this.state = {
            selectedConversationIds: [],
            hideDialog: [],
            initialConversation: []
        }
    }

    componentDidMount() {
        var newSelection = []
        const { selectedConversationIds } = this.props
        if (selectedConversationIds != undefined && selectedConversationIds.length > 0) {
            this.setState({
                selectedConversationIds,
                initialConversation: selectedConversationIds.map(item => ({ key: item, name: item[0] + item.slice(1) }))
            })
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedConversationIds != this.props.selectedConversationIds) {
            this.setState({
                selectedConversationIds: this.props.selectedConversationIds,
                initialConversation: this.props.selectedConversationIds.map(item => ({ key: item, name: item[0] + item.slice(1) }))
            })
        }
    }

    getConversationIds = gql`query ent ( $search: String) {
                getConversationIds ( search: $search) 
                       }`



    getElements = async (search) => {
        var testTags: ITag[] = []
        let result = await this.props.apol
            .query({
                query: this.getConversationIds,
                variables: { search }
            })
        if (result.data != undefined && result.data.getConversationIds != undefined && result.data.getConversationIds.length > 0) {
            testTags = result.data.getConversationIds.map(item => ({ key: item, name: item[0] + item.slice(1) }));
        }
        return testTags
    }



    listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some(compareTag => compareTag.key === tag.key);
    };

    filterSuggestedTags = async (filterText: string, tagList: ITag[]): ITag[] => {
        var suggestions = []
        if (filterText.length > 3) {
            suggestions = await this.getElements(filterText)
        }
        return filterText
            ? suggestions.filter(
                tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !this.listContainsTagList(tag, tagList),
            )
            : [];
    };

    getTextFromItem = (item: ITag) => {
        item.name
    };

    onChange = (items) => {
        if (this.state.initialConversation && this.state.initialConversation.length > items.length) {
            var result = _.differenceWith(this.state.initialConversation, items, _.isEqual)
            if (this.props.canRemoveConversations) {
                if (this.props.conversationList.get(result[0].key) < 2) {
                    this.props.open(items)
                } else {
                    this.setState({ initialConversation: items })
                    this.removeItem(items)
                }
            }
        } else {
            var actualSelection = items.length > 0 ? items.map(item => item.key) : []
            this.setState({ selectedConversationIds: actualSelection })
            if (this.props.onSelectionChange != undefined) {
                this.props.onSelectionChange(actualSelection)
            }
        }
    };

    removeItem = (items) => {
        var actualSelection = items.length > 0 ? items.map(item => item.key) : []
        this.setState({ selectedConversationIds: actualSelection })
        if (this.props.onSelectionChange != undefined) {
            this.props.onSelectionChange(actualSelection)
        }
    }

    render() {
        let pickerSuggestionsProps = {
            suggestionsHeaderText: this.context.getTranslation("conversationSafe", "suggestionsConversationPicker"),
            noResultsFoundText: this.context.getTranslation("conversationSafe", "noResultsFoundConversationPicker"),
        };

        return (
            <div>
                <Label>{this.context.getTranslation("conversationSafe", "addToFolderById")}</Label>
                <TagPicker
                    className={ this.props.canRemoveConversations ? undefined : "cannotRemove"}
                    itemLimit={this.props.canAddConversations ? undefined : this.state.selectedConversationIds.length}
                    selectedItems={this.state.selectedConversationIds.map(item => ({ key: item, name: item[0] + item.slice(1) }))}
                    onChange={this.onChange}
                    removeButtonAriaLabel="Remove"
                    onResolveSuggestions={this.filterSuggestedTags}
                    getTextFromItem={this.getTextFromItem}
                    pickerSuggestionsProps={pickerSuggestionsProps}
                    pickerCalloutProps={{ doNotLayer: true }}
                    resolveDelay={300}
                    inputProps={{
                        id: 'conversationPicker',
                        placeholder: this.state.selectedConversationIds.length == 0 ? this.context.getTranslation("conversationSafe", "typeIdConversation") : ""
                    }}
                />
            </div>
        );
    }
}