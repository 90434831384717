import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import * as Azure from "@azure/storage-blob";

import {
    DefaultButton,
    PrimaryButton,
    Stack,
    TextField,
    Checkbox,
    Label
} from "office-ui-fabric-react";
import { MyContext } from '../../../context';


class BotOnboardingConfig extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            botAppId1 : "d4363296-d43c-4a7a-bafd-50f34aea89f7",
            botAppName1 : "asc-t09-d00",
            botDisplayName1 : "WebAppBot asc-t09-d00 Service Principal",
            botAppId2 : "", 
            botAppName2 : "",
            botDisplayName2: "",
            compliancePolicy: false,
            botMeetingJoin: false,
            botMeetingKeep: false,
            botCallJoin: false,
            botCallKeep: false
        };
    }

    render() {

        return (
            <Stack vertical>
                <Label><h4><MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "createOnboardingScript")}</>)}</MyContext.Consumer></h4></Label>
                <hr />
                <Stack.Item>
                    <Checkbox
                        label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "botCompliancePolicy")}</>)}</MyContext.Consumer>}
                        checked={this.state.compliancePolicy}
                        onChange={(event, newValue) => {
                                this.setState({
                                    compliancePolicy: newValue
                                });
                            }
                        }
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "botAppId1")}</>)}</MyContext.Consumer>}
                        value={this.state.botAppId1} onChange={(event) => { this.setState({ botAppId1: event.target.value }) }}                 
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "botAppName1")}</>)}</MyContext.Consumer>}
                        value={this.state.botAppName1} onChange={(event) => { this.setState({ botAppName1: event.target.value }) }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "botDisplayName1")}</>)}</MyContext.Consumer>}
                        value={this.state.botDisplayName1} onChange={(event) => { this.setState({ botDisplayName1: event.target.value }) }}
                            />
                </Stack.Item>
                {this.state.compliancePolicy &&
                    <Stack vertical>
                        <Stack.Item>
                            <TextField label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "botAppId2")}</>)}</MyContext.Consumer>}
                                value={this.state.botAppId2} onChange={(event) => { this.setState({ botAppId2: event.target.value }) }}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "botAppName2")}</>)}</MyContext.Consumer>}
                                value={this.state.botAppName2} onChange={(event) => { this.setState({ botAppName2: event.target.value }) }}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField label={<MyContext.Consumer>{(context) => (<>{context.getTranslation("tenants", "botDisplayName2")}</>)}</MyContext.Consumer>}
                                value={this.state.botDisplayName2} onChange={(event) => { this.setState({ botDisplayName2: event.target.value }) }}
                            />
                        </Stack.Item>
                    </Stack>
                }


                
                <br />
                <hr />
                <Stack.Item>
                    <PrimaryButton text={<MyContext.Consumer>{(context) => (<>{context.getTranslation("common", "export")}</>)}</MyContext.Consumer>}
                        disabled={(this.state.archiveBlobs && !(this.state.archiveBlobOlderThanDays > 89 && this.state.archiveBlobOlderThanDays < 99999999999)) || (this.state.convertToMP3 && !(this.state.convertBlobOlderThanDays > 0 && this.state.convertBlobOlderThanDays < 99999999999))}
                        onClick={(event) => { this.exportScript(this.props.apol); this.props.cl() }} style={{ "margin-right": "10px" }} />
                    <DefaultButton text={<MyContext.Consumer>{(context) => (<>{context.getTranslation("common", "cancel")}</>)}</MyContext.Consumer>} onClick={(event) => { this.props.cl() }} />
                </Stack.Item>
            </Stack>

        );
    }

    exportScript = async (apol) => {
        var botConfiguration = {
            botAppId1: this.state.botAppId1,
            botAppName1: this.state.botAppName1,
            botDisplayName1: this.state.botDisplayName1,
            botAppId2: this.state.botAppId2,
            botAppName2: this.state.botAppName2,
            botDisplayName2: this.state.botDisplayName2,
            botMeetingJoin: this.state.botMeetingJoin,
            botMeetingKeep: this.state.botMeetingKeep,
            botCallJoin: this.state.botCallJoin,
            botCallKeep: this.state.botCallJoin
        };

        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($botConfiguration: BotConfigurationTypeInput) {
                    getBotScript (botConfiguration: $botConfiguration)
                }
                `,
                variables: { $botConfiguration: botConfiguration }
            })
            .then(async result => {
                const anonymousCredential = new Azure.AnonymousCredential();
                const pipeline = Azure.StorageURL.newPipeline(anonymousCredential);
                if (result.data.downloadBlob !== "") {
                    var downloaded = new Blob([result.data.downloadBlob], { type: "text/plain;charset=utf-8" });
                    saveAs(downloaded, "bot.ps1");
                }
            });
    }
}

export default BotOnboardingConfig;