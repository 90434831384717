
import { Checkbox, CommandBarButton, DefaultButton, FontSizes, IconButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Separator, Stack } from 'office-ui-fabric-react'
import React, { Component } from 'react'
import { MyContext } from '../../context'
import { mutationSaveListViewSettings, queryGetLoggedUserData } from "../../queries/querySettings";

export default class SettingsColumnsRecording extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        let selected = this.props.settingsColumns.map(c => {
            return this.props.allColumns.filter(col => col.fieldName === c)[0]
        }).filter(d => d !== undefined)
        let notSelected = this.props.allColumns.filter(col => !this.props.settingsColumns.includes(col.fieldName) && col !== undefined)
        this.state = {
            isPanelOpen: false,
            error: "",
            processing: false,
            settingsColumns: this.props.settingsColumns,
            selected,
            notSelected,
        };

    }


    getHeader = () => {
        return (
            <div style={{ flex: "auto", marginLeft: "20px" }}>
                <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>{this.context.getTranslation("settings", "settingsColumns")}</div>
                {/*Alert*/}
                {this.state.error !== "" && <Stack style={{ marginTop: "10px" }}>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close"
                        onDismiss={() => { this.setState({ error: "" }) }}
                    >
                        {this.state.error}
                    </MessageBar>
                </Stack>
                }
            </div >
        )
    }


    save = () => {
        this.setState({ processing: true })
        let auth = this.props.auth;
        //save into db the settings
        let listViewSettings = this.state.settingsColumns
        this.props.apol
            .mutate({
                mutation: mutationSaveListViewSettings,
                refetchQueries: [{
                    query: queryGetLoggedUserData,
                    variables: { auth }
                }],
                variables: { listViewSettings }
            })
            .then((result) => {
                if (result.data.saveListViewSettings.status === "ok") {
                    let resultColumns = this.state.settingsColumns.map(c => {
                        return this.props.allColumns.filter(col => col.fieldName === c)[0]
                    }).filter(d => d !== undefined)
                    this.props.updateColumnsSettings(resultColumns)
                    this.setState({
                        processing: false,
                        isPanelOpen: false
                    })
                } else {
                    this.setState({
                        processing: false,
                        error: result.data.saveListViewSettings.text
                    })
                }
            });
    }


    onChange = (name) => {
        let settingsColumns = []
        if (this.state.settingsColumns.includes(name)) {
            settingsColumns = this.state.settingsColumns.filter(c => c !== name)
        } else {
            settingsColumns = [...this.state.settingsColumns, name]
        }
        let selected = settingsColumns.map(c => {
            return this.props.allColumns.filter(col => col.fieldName === c)[0]
        }).filter(d => d !== undefined)
        let notSelected = this.props.allColumns.filter(col => !settingsColumns.includes(col.fieldName) && col !== undefined)
        this.setState({
            settingsColumns,
            selected,
            notSelected
        })
    }

    up = (i) => {
        let newSettingsColumns = this.state.settingsColumns
        newSettingsColumns[i] = newSettingsColumns.splice(i - 1, 1, newSettingsColumns[i])[0];
        let selected = newSettingsColumns.map(c => {
            return this.props.allColumns.filter(col => col.fieldName === c)[0]
        }).filter(d => d !== undefined)
        let notSelected = this.props.allColumns.filter(col => !newSettingsColumns.includes(col.fieldName) && col !== undefined)
        this.setState({
            settingsColumns: newSettingsColumns,
            selected,
            notSelected
        })
    }

    down = (i) => {
        let newSettingsColumns = this.state.settingsColumns
        newSettingsColumns[i] = newSettingsColumns.splice(i + 1, 1, newSettingsColumns[i])[0];
        let selected = newSettingsColumns.map(c => {
            return this.props.allColumns.filter(col => col.fieldName === c)[0]
        }).filter(d => d !== undefined)
        let notSelected = this.props.allColumns.filter(col => !newSettingsColumns.includes(col.fieldName) && col !== undefined)
        this.setState({
            settingsColumns: newSettingsColumns,
            selected,
            notSelected
        })
    }


    render() {
        let lengthSelected = this.state.selected.length
        return (
            <div>
                {/*Wheel button for open settings panel*/}
                <CommandBarButton
                    iconProps={{ iconName: 'Settings', title: this.context.getTranslation("settings", "settingsColumns") }}
                    style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                    onClick={() => { this.setState({ isPanelOpen: true }) }} />
                {/*Settings panel*/}
                <Panel
                    isOpen={this.state.isPanelOpen}
                    onDismiss={() => { this.setState({ isPanelOpen: false }) }}
                    isFooterAtBottom={false}
                    onRenderHeader={this.getHeader}
                    type={PanelType.small}
                >
                    <div style={{ marginTop: "10px" }}>
                        {this.state.selected.map((c, i) => {
                            return (
                                <div key={i}>
                                    <Stack horizontal>
                                        <IconButton iconProps={{ iconName: 'ChevronUpMed' }} onClick={() => this.up(i)} disabled={i === 0} />
                                        <IconButton iconProps={{ iconName: 'ChevronDownMed' }} onClick={() => this.down(i)} disabled={i === lengthSelected - 1} />
                                        <div style={{ marginTop: "5px" }}>
                                            <Checkbox label={c.name} checked={true} onChange={() => { this.onChange(c.fieldName) }} disabled={c.fixed} />
                                        </div>
                                    </Stack>
                                </div>
                            )
                        })}
                        {this.state.notSelected.length > 0 && <Separator className="separator" />}
                        {this.state.notSelected.map((c, i) => {
                            return (
                                <div key={i}>
                                    <Checkbox label={c.name} checked={false} onChange={() => { this.onChange(c.fieldName) }} disabled={c.fixed} />
                                </div>
                            )
                        })}
                        {/*FOOTER BUTTONS*/}
                        <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                            <DefaultButton text={this.context.getTranslation("common", "cancel")} onClick={() => { this.setState({ isPanelOpen: false }) }} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={this.state.processing} />
                            <PrimaryButton text={this.context.getTranslation("common", "save")} onClick={this.save} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={this.state.processing} />
                        </Stack>
                    </div>
                </Panel>
            </div>
        )
    }
}