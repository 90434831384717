//React
import React from 'react';
//import * as ReactDOM from 'react-dom';
//Apollo
//Components
import { Panel, PanelType, Spinner, Stack, Button, Link } from 'office-ui-fabric-react/lib';
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { Text } from 'office-ui-fabric-react/lib/Text';
import { MyContext } from '../context';
import NewsPanel from './NewsPanel';


export const renderPanelRecording = (self, auth, client, userRules, theme) => (
    <MyContext.Consumer>
        {(context) => (
            <Panel
                isOpen={self.state.helpPanel}
                type={PanelType.smallFixedFar}
                headerText={context.getTranslation("helpPanel", "titleRecording")}
                closeButtonAriaLabel="Close"
                onDismiss={() => { self.setState({ helpPanel: false }) }}

            >
                <br />
                <div>
                    <Text>{context.getTranslation("helpPanel", "helpRecordingText")}</Text>
                </div><br />

                <Stack className="centered-stack" horizontal>
                    <a style={{ color: theme.palette.neutralPrimary }} className="link-help" href="manual/index.html">{context.getTranslation("helpPanel", "userManual")}</a><br />
                </Stack>
                <br />
                <Stack className="centered-stack" horizontal>
                    <Button className="btn-help" onClick={() => { self.newsPanelOpen() }} text={context.getTranslation("helpPanel", "appNews")} />
                    <Button className="btn-help" href="https://forms.office.com/pages/responsepage.aspx?id=enF9Xuqqj0u9_dHp-4uHvH1QlNZOcbBMgI2OtZDA2eZURDMzUkFURVRQTlZFMlhFQlBGREQzSjBPMy4u">{context.getTranslation("helpPanel", "feedback")}</Button>
                </Stack>                <NewsPanel auth={auth} apol={client} userRules={userRules} isOpen={self.state.newsPanelIsOpen} cl={() => { self.newsPanelClose() }} />

            </Panel>
        )
        }
    </MyContext.Consumer>);

export const renderPanelRules = (self, auth, client, userRules, theme) => (
    <MyContext.Consumer>
        {(context) => (
            <Panel
                isOpen={self.state.helpPanel}
                type={PanelType.smallFixedFar}
                headerText={context.getTranslation("helpPanel", "titleConfiguration")}
                closeButtonAriaLabel="Close"
                onDismiss={() => { self.setState({ helpPanel: false }) }}

            >
                <br />

                <div>
                    <Text>{context.getTranslation("helpPanel", "helpRulesText")}</Text>
                </div><br />

                <Stack className="centered-stack" horizontal>
                    <a style={{ color: theme.palette.neutralPrimary }} className="link-help" href="installation/index.html">{context.getTranslation("helpPanel", "administrationManual")}</a>
                    <a style={{ color: theme.palette.neutralPrimary }} className="link-help" href="manual/index.html">{context.getTranslation("helpPanel", "userManual")}</a><br />
                </Stack>
                <br />
                <Stack className="centered-stack" horizontal>
                    <Button className="btn-help" onClick={() => { self.newsPanelOpen() }} text={context.getTranslation("helpPanel", "appNews")} />
                    <Button className="btn-help" href="https://forms.office.com/pages/responsepage.aspx?id=enF9Xuqqj0u9_dHp-4uHvH1QlNZOcbBMgI2OtZDA2eZURDMzUkFURVRQTlZFMlhFQlBGREQzSjBPMy4u">{context.getTranslation("helpPanel", "feedback")}</Button>
                </Stack>
                <NewsPanel auth={auth} apol={client} userRules={userRules} isOpen={self.state.newsPanelIsOpen} cl={() => { self.newsPanelClose() }} />

            </Panel>
        )
        }
    </MyContext.Consumer>);

export const renderPanelTenant = (self, auth, client, userRules, theme) => (
    <MyContext.Consumer>
        {(context) => (
            <Panel
                isOpen={self.state.helpPanel}
                type={PanelType.smallFixedFar}
                headerText={context.getTranslation("helpPanel", "titleTenant")}
                closeButtonAriaLabel="Close"
                onDismiss={() => { self.setState({ helpPanel: false }) }}

            >
                <br />

                <div>
                    <Text>{context.getTranslation("helpPanel", "helpTenantText")}</Text>
                </div><br />

                <Stack className="centered-stack" horizontal>
                    <a style={{ color: theme.palette.neutralPrimary }} className="link-help" href="installation/index.html">{context.getTranslation("helpPanel", "administrationManual")}</a>
                    <a style={{ color: theme.palette.neutralPrimary }} className="link-help" href="manual/index.html">{context.getTranslation("helpPanel", "userManual")}</a><br />
                </Stack>
                <br />
                <Stack className="centered-stack" horizontal>
                    <Button className="btn-help" onClick={() => { self.newsPanelOpen() }} text={context.getTranslation("helpPanel", "appNews")} />
                    <Button className="btn-help" href="https://forms.office.com/pages/responsepage.aspx?id=enF9Xuqqj0u9_dHp-4uHvH1QlNZOcbBMgI2OtZDA2eZURDMzUkFURVRQTlZFMlhFQlBGREQzSjBPMy4u">{context.getTranslation("helpPanel", "feedback")}</Button>
                </Stack>
                <NewsPanel auth={auth} apol={client} userRules={userRules} isOpen={self.state.newsPanelIsOpen} cl={() => { self.newsPanelClose() }} />

            </Panel>
        )
        }
    </MyContext.Consumer>
);

export const renderPanelSettings = (self, auth, client, userRules, theme) => (
    <MyContext.Consumer>
        {(context) => (
            <Panel
                isOpen={self.state.helpPanel}
                type={PanelType.smallFixedFar}
                headerText={context.getTranslation("helpPanel", "titleSettings")}
                closeButtonAriaLabel="Close"
                onDismiss={() => { self.setState({ helpPanel: false }) }}

            >
                <br />

                <div>
                    <Text>{context.getTranslation("helpPanel", "helpSettingsText")}</Text>
                </div><br />

                <Stack className="centered-stack" horizontal>
                    <a style={{ color: theme.palette.neutralPrimary }} className="link-help" href="manual/index.html">{context.getTranslation("helpPanel", "userManual")}</a><br />
                </Stack>
                <br />
                <Stack className="centered-stack" horizontal>
                    <Button className="btn-help" onClick={() => { self.newsPanelOpen() }} text={context.getTranslation("helpPanel", "appNews")} />
                    <Button className="btn-help" href="https://forms.office.com/pages/responsepage.aspx?id=enF9Xuqqj0u9_dHp-4uHvH1QlNZOcbBMgI2OtZDA2eZURDMzUkFURVRQTlZFMlhFQlBGREQzSjBPMy4u">{context.getTranslation("helpPanel", "feedback")}</Button>
                </Stack>
                <NewsPanel auth={auth} apol={client} userRules={userRules} isOpen={self.state.newsPanelIsOpen} cl={() => { self.newsPanelClose() }} />

            </Panel>
        )
        }
    </MyContext.Consumer>
);

export const renderPanelUpload = (self, auth, client, userRules, theme) => (<MyContext.Consumer>
    {(context) => (
        <Panel
            isOpen={self.state.helpPanel}
            type={PanelType.smallFixedFar}
            headerText={context.getTranslation("helpConversationUpload", "title")}
            closeButtonAriaLabel="Close"
            onDismiss={() => { self.setState({ helpPanel: false }) }}

        >
            <br />

            <div>
                <Text>{context.getTranslation("helpConversationUpload", "titleText")}</Text>
            </div><br />
            <div>
                <Text>{context.getTranslation("helpConversationUpload", "language")}</Text>
            </div>
            <div>
                <Text>{context.getTranslation("helpConversationUpload", "languageText")}</Text>
            </div><br />
            <div><Text>{context.getTranslation("helpConversationUpload", "fileTypes")}</Text></div>
            <div>
                <Text>{context.getTranslation("helpConversationUpload", "fileTypesText")}</Text>
            </div><br />
            <div><Text>{context.getTranslation("helpConversationUpload", "upload")}</Text></div>
            <div>
                <Text>{context.getTranslation("helpConversationUpload", "uploadText")}</Text>
            </div><br />
            <div><Text>{context.getTranslation("helpConversationUpload", "analysis")}</Text></div>
            <div>
                <Text>{context.getTranslation("helpConversationUpload", "analysisText")}</Text>
            </div>
            <br />

            <Stack className="centered-stack" horizontal>
                <a style={{ color: theme.palette.neutralPrimary }} className="link-help" href="manual/index.html">{context.getTranslation("helpPanel", "userManual")}</a><br />
            </Stack>
            <br />
            <Stack className="centered-stack" horizontal>
                <Button className="btn-help" onClick={() => { self.newsPanelOpen() }} text={context.getTranslation("helpPanel", "appNews")} />
                <Button className="btn-help" href="https://forms.office.com/pages/responsepage.aspx?id=enF9Xuqqj0u9_dHp-4uHvH1QlNZOcbBMgI2OtZDA2eZURDMzUkFURVRQTlZFMlhFQlBGREQzSjBPMy4u">{context.getTranslation("helpPanel", "feedback")}</Button>
            </Stack>
            <NewsPanel auth={auth} apol={client} userRules={userRules} isOpen={self.state.newsPanelIsOpen} cl={() => { self.newsPanelClose() }} />

        </Panel>
    )
    }
</MyContext.Consumer>);

export const renderPanelDashboard = (self, auth, client, userRules, theme) => (
    <MyContext.Consumer>
        {(context) => (
            <Panel
                isOpen={self.state.helpPanel}
                type={PanelType.smallFixedFar}
                headerText={context.getTranslation("helpPanel", "titleDashboard")}
                onDismiss={() => { self.setState({ helpPanel: false }) }}
                closeButtonAriaLabel="Close"
            >
                <br />

                <div>
                    <Text>{context.getTranslation("helpPanel", "helpReportDashboardText")}</Text>
                </div><br />

                <Stack className="centered-stack" horizontal>
                    <a style={{ color: theme.palette.neutralPrimary }} className="link-help" href="manual/index.html">{context.getTranslation("helpPanel", "userManual")}</a><br />
                </Stack>
                <br />
                <Stack className="centered-stack" horizontal>
                    <Button className="btn-help" onClick={() => { self.newsPanelOpen() }} text={context.getTranslation("helpPanel", "appNews")} />
                    <Button className="btn-help" href="https://forms.office.com/pages/responsepage.aspx?id=enF9Xuqqj0u9_dHp-4uHvH1QlNZOcbBMgI2OtZDA2eZURDMzUkFURVRQTlZFMlhFQlBGREQzSjBPMy4u">{context.getTranslation("helpPanel", "feedback")}</Button>
                </Stack>
                <NewsPanel auth={auth} apol={client} userRules={userRules} isOpen={self.state.newsPanelIsOpen} cl={() => { self.newsPanelClose() }} />
            </Panel>
        )
        }
    </MyContext.Consumer>
);


export const renderPanelCompliance = (self, auth, client, userRules, theme) => (
    <MyContext.Consumer>
        {(context) => (
            <Panel
                isOpen={self.state.helpPanel}
                type={PanelType.smallFixedFar}
                headerText={context.getTranslation("helpPanel", "titlePolicy")}
                onDismiss={() => { self.setState({ helpPanel: false }) }}
                closeButtonAriaLabel="Close"
            >
                <br />

                <div>
                    <Text>{context.getTranslation("helpPanel", "policyText")}</Text>
                </div><br />


                <Stack className="centered-stack" horizontal>
                    <a style={{ color: theme.palette.neutralPrimary }} className="link-help" href="manual/index.html">{context.getTranslation("helpPanel", "userManual")}</a><br />
                </Stack>
                <br />
                <Stack className="centered-stack" horizontal>
                    <Button className="btn-help" onClick={() => { self.newsPanelOpen() }} text={context.getTranslation("helpPanel", "appNews")} />
                    <Button className="btn-help" href="https://forms.office.com/pages/responsepage.aspx?id=enF9Xuqqj0u9_dHp-4uHvH1QlNZOcbBMgI2OtZDA2eZURDMzUkFURVRQTlZFMlhFQlBGREQzSjBPMy4u">{context.getTranslation("helpPanel", "feedback")}</Button>
                </Stack>
                <NewsPanel auth={auth} apol={client} userRules={userRules} isOpen={self.state.newsPanelIsOpen} cl={() => { self.newsPanelClose() }} />
            </Panel>
        )
        }
    </MyContext.Consumer>
);