import React, { Component } from "react";
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Icon } from "office-ui-fabric-react";
import { FontSizes, Stack } from 'office-ui-fabric-react/lib/';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { DetailsList, SelectionMode } from "office-ui-fabric-react";
import { MyContext } from "../../context";
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Query } from "react-apollo";
import DetailContainer from "../DetailView/DetailContainer/DetailContainer";
import { queryGetEntry, queryGetEntryByFolder } from "../../queries/queryRecordings";

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    accordionItem: {
        paddingBottom: '9px'
    }
});

export class ConversationSafeFolder extends Component {
    static contextType = MyContext;

    onItemInvoked = (item) => {
        this.setState({
            selectedConversationId: item.conversationId,
            showPanel: true
        })
    }

    constructor(props) {
        super(props);
        this.state = {
            allColumns: [],
            columns: [],
            showPanel: false,
            selectedConversationId: "",
            folder: this.props.folder.conversations,
            refreshCsList: false,
        }
    }

    componentDidMount() {
        const columns = [
            {
                key: 'column1',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "conversationType")}</>)}</MyContext.Consumer>,
                className: classNames.fileIconCell,
                iconClassName: classNames.fileIconHeaderIcon,
                ariaLabel: 'Column operations for File type, Press to sort on File type',
                iconName: 'Page',
                isIconOnly: true,
                fieldName: 'recordingType',
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    const typeIcon = item.recordingType != null ? item.recordingType : "Unknown";
                    if (typeIcon === "Meeting") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Group' title="Meeting" />;
                    } else if (typeIcon === "Inbound") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ArrowDownRightMirrored8' title="Inbound" />;
                    } else if (typeIcon === "Outbound") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ArrowUpRight8' title="Outbound" />;
                    } else if (typeIcon === "Internal") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ConnectContacts' title="Internal" />;
                    } else if (typeIcon === "Chat") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Chat' title="Chat" />;
                    } else if (typeIcon === "") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='CloudUpload' />;
                    }
                    else {
                        return <div></div>;
                    }

                }
            },
            {
                key: 'column2',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "header")}</>)}</MyContext.Consumer>,
                fieldName: 'topic',
                minWidth: 120,
                maxWidth: 400,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                fixed: true,
            }
            ,
            {
                key: 'column3',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "media")}</>)}</MyContext.Consumer>,
                fieldName: 'media',
                minWidth: 80,
                maxWidth: 80,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    if (item.isVideo) {
                        return (
                            <Stack.Item style={{ padding: "0px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Video' title="Video" />
                            </Stack.Item>
                        );
                    }
                    else {
                        return (<Stack horizontal align="start" style={{ padding: "0px" }} tokens={{ childrenGap: '10' }}>
                            {item.hasAudio && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Microphone' title="Audio" />
                            </Stack.Item>}
                            {item.hasScreenSharing && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ScreenCast' title="Screen share" />
                            </Stack.Item>}
                            {item.hasVideo && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Video' title="Video" />
                            </Stack.Item>}
                            {item.hasChat && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Chat' title="Chat" />
                            </Stack.Item>}
                            {item.hasFiles && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Attach' title="Files" />
                            </Stack.Item>}
                        </Stack>);
                    }
                }
            },
            {
                key: 'column4',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "date")}</>)}</MyContext.Consumer>,
                fieldName: 'dateValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'date',
                onRender: (item: IDocument) => {
                    return <span>{item.day}</span>;
                },
                fixed: true,
            }
            , {
                key: 'column5',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "start")}</>)}</MyContext.Consumer>,
                fieldName: 'start',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'date',
                onRender: (item: IDocument) => {
                    return <span>{item.timein}</span>;
                },
                fixed: true
            }
            ,
            {
                key: 'column5a',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "duration")}</>)}</MyContext.Consumer>,
                fieldName: 'duration',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item: IDocument) => {
                    return <span>{item.duration}</span>;
                },
                isPadded: true
            },
            {
                key: 'column6',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "owner")}</>)}</MyContext.Consumer>,
                fieldName: 'owner',
                minWidth: 120,
                maxWidth: 140,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item: IDocument) => {
                    return <span>{item.ownerDisplayName}</span>;
                },
                isPadded: true
            },
            {
                key: 'column7',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "participants")}</>)}</MyContext.Consumer>,
                fieldName: 'participantCount',
                minWidth: 220,
                maxWidth: 1000,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'number',
                onRender: (item: IDocument) => {
                    return <span>{item.linkedUser.map((item) => {
                        return item.name;
                    }).join(", ")}</span>;
                },
                isPadded: true
            },
            {
                key: 'column9',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "conversationId")}</>)}</MyContext.Consumer>,
                fieldName: 'conversationId',
                minWidth: 200,
                maxWidth: 300,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            }
        ];

        this.setState({
            columns: columns
        })
    }

    refreshFolderList = () => {
        this.setState({
            refreshCsList: true,
        })
    }

    sortEntriesByColumn = (entries, selectedColumn) => {
        function defaultSortFunction(a, b) {
            if (isSortedDescending) {
                return a[key] > b[key] ? 1 : -1
            } else {
                return a[key] < b[key] ? 1 : -1
            }
        }
        let key = selectedColumn.fieldName
        const isSortedDescending = selectedColumn.isSortedDescending
        let itemsSorted = entries
            .slice()
        if (key == "media") key = 'recordingType'
        if (key == "start") key = 'timein'
        itemsSorted
            .sort(defaultSortFunction)

        return itemsSorted

    }

    _onColumnClick = (ev, column) => {
        let newColumns = this.state.columns
        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        let sortedRows = this.sortEntriesByColumn(this.state.folder, currColumn)
        this.setState({
            columns: newColumns,
            folder: sortedRows
        });
    }


    render() {
        return (
            <div>
                <Panel isOpen={this.state.showPanel}
                    onDismiss={() => {
                        this.setState({ showPanel: false })
                        if (this.state.refreshCsList) this.props.refreshCSList()
                    }}
                    type={PanelType.smallFluid}>
                    {/*MANAGED GET ENTRY FOR CONVERSATION SAFE FOLDER*/}
                    {this.props.folder.id !== null &&
                        < Query
                            query={queryGetEntryByFolder}
                            variables={{
                                id: this.state.selectedConversationId,
                                folderId: this.props.folder.id,
                                auth: this.props.auth,
                                callStartMs: this.props.callStartMs
                            }}>
                            {({ loading, error, data }) => {
                                if (loading) {
                                    return (
                                        <div>
                                            <Spinner label={this.context.getTranslation("configuration", "loading")} ariaLive="assertive" labelPosition="top" />
                                        </div>
                                    )
                                };
                                if (error) return <div></div>;
                                if (data.getEntryByFolder === null) return <div> <h5 align="center">{this.context.getTranslation("conversationSafe", "userNotAllowed")}</h5></div>;
                                if (data.getEntryByFolder !== null) {

                                    var userRules = { ...this.props.userRules }
                                    //if the folder does not contain the visibility rules,show all the information present on the detail view
                                    if (this.props.folder.visibilityDetails != undefined && this.props.folder.userId != this.props.userRules.userId) {
                                        userRules.visibilityDetails = this.props.folder.visibilityDetails.visibilityDetailsActive;
                                        userRules.visibilityDetailsNotes = this.props.folder.visibilityDetails.visibilityDetailsNotes;
                                        userRules.visibilityDetailsTranscript = this.props.folder.visibilityDetails.visibilityDetailsTranscript;
                                        userRules.visibilityDetailsTTL = this.props.folder.visibilityDetails.visibilityDetailsTTL;
                                        userRules.visibilityDetailsAnalytics = this.props.folder.visibilityDetails.visibilityDetailsAnalytics;
                                        userRules.visibilityDetailsAnalyticsChange = this.props.folder.visibilityDetails.visibilityDetailsAnalyticsChange;
                                        userRules.visibilityDetailsCustomFields = this.props.folder.visibilityDetails.visibilityDetailsCustomFields;
                                        userRules.visibilityDetailsMetadata = this.props.folder.visibilityDetails.visibilityDetailsMetadata;
                                        userRules["visibilityDetailsReplay"] = this.props.folder.visibilityDetails.visibilityDetailsReplay;
                                    } else {
                                        userRules.visibilityDetails = this.props.userRules.visibilityDetails;
                                        userRules.visibilityDetailsNotes = this.props.userRules.visibilityDetailsNotes;
                                        userRules.visibilityDetailsTranscript = this.props.userRules.visibilityDetailsTranscript;
                                        userRules.visibilityDetailsTTL = this.props.userRules.visibilityDetailsTTL;
                                        userRules.visibilityDetailsAnalytics = this.props.userRules.visibilityDetailsAnalytics;
                                        userRules.visibilityDetailsAnalyticsChange = this.props.userRules.visibilityDetailsAnalyticsChange;
                                        userRules.visibilityDetailsCustomFields = this.props.userRules.visibilityDetailsCustomFields;
                                        userRules.visibilityDetailsMetadata = this.props.userRules.visibilityDetailsMetadata;
                                        userRules.visibilityDetailsQM = this.props.folder.visibilityDetails.visibilityDetailsQM;
                                        userRules["visibilityDetailsReplay"] = true;
                                    }
                                    return (
                                        <DetailContainer
                                            apol={this.props.apol}
                                            authorization={this.props.auth}
                                            data={data.getEntryByFolder}
                                            theme={this.props.theme}
                                            userRules={userRules}
                                            refreshFolderList={this.refreshFolderList}
                                            hidePanel={() => { this.setState({ showPanel: false }) }}
                                        />);
                                }
                            }}
                        </Query>}
                    {/*MANAGED GET ENTRY FOR UNALLOCATED FOLDER*/}
                    {this.props.folder.id === null &&
                        <Query
                            query={queryGetEntry}
                            variables={{
                                id: this.state.selectedConversationId,
                                auth: this.props.auth,
                                callStartMs: this.props.callStartMs
                            }}>
                            {({ loading, error, data }) => {
                                if (loading) {
                                    return (<div>
                                        <Spinner label={this.context.getTranslation("configuration", "loading")} ariaLive="assertive" labelPosition="top" />
                                    </div>)
                                };
                                if (error) return <div></div>;
                                if (data.getEntry === null) return <div> <h5 align="center">{this.context.getTranslation("conversationSafe", "userNotAllowed")}</h5></div>;
                                return (
                                    <DetailContainer
                                        apol={this.props.apol}
                                        authorization={this.props.auth}
                                        data={data.getEntry}
                                        hidePanel={() => { this.setState({ showPanel: false }) }}
                                        userRules={this.props.userRules}
                                        theme={this.props.theme}
                                    />
                                );
                            }}
                        </Query>
                    }
                </Panel>
                {this.state.folder.length > 0 && <DetailsList
                    items={this.state.folder}
                    columns={this.state.columns}
                    selectionMode={SelectionMode.none}
                    onRenderRow={(props, defaultRender) => (
                        <div>
                            {defaultRender({
                                ...props, onClick: (event) => {
                                    event.preventDefault(); this.onItemInvoked(props.item)
                                }
                            })
                            }
                        </div>
                    )}>
                </DetailsList>}

                {this.props.folder.conversations.length === 0 &&
                    <div>
                        <h5 align="center">{this.context.getTranslation("conversationSafe", "conversationNorExist")}</h5>
                    </div>}

            </div>
        );
    }
}