import { FontIcon, mergeStyles } from 'office-ui-fabric-react';
import React from 'react';


const iconClass = mergeStyles({
	fontSize: 15,
	height: 15,
	width: 15,
	margin: '0 25px',
	color: 'red'
});

export default class RecordingBlink extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: true,
			duration: 530
		}
	}

	componentDidMount() {
		this.blink();
	}

	blink = () => {
		this.setState(prevState => {
			return {
				visible: !prevState.visible
			};
		});
		setTimeout(this.blink, this.state.duration);
	}

	render() {
		let style = { visibility: this.state.visible ? 'visible' : 'hidden' };
		return (<div style={style}><FontIcon aria-label="CircleFill" iconName="CircleFill" className={iconClass} /></div>)
	}
}