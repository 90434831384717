import * as React from 'react';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IContextualMenuListProps, IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { IRenderFunction } from '@uifabric/utilities';
import { MyContext } from '../../context';

export default class LanguageSelect extends React.Component {
    constructor(props) {
        super(props);

        this._renderMenuList = this._renderMenuList.bind(this);
        this._onAbort = this._onAbort.bind(this);
        this._onChange = this._onChange.bind(this);

        this.state = {
            items: this.props.languageList,
            languageFrom: this.props.language !== undefined ? this.props.language.split("-")[0] : "",
            languageTo: "",
            languageToText: ""
        };
    }

    render() {
        return (<MyContext.Consumer>{(context) => (
            <div>
                <DefaultButton
                    id="ContextualMenuWithCustomMenuListExample"
                    text={window.innerWidth > 600 ? (this.state.languageToText !== "" ?
                        context.getTranslation("detailContainer", "translateTo") + this.state.languageToText : context.getTranslation("detailContainer", "translate"))
                        : context.getTranslation("detailContainer", "translateShort")}
                    menuProps={{
                        onRenderMenuList: this._renderMenuList,
                        onItemClick: this._onItemClick,
                        title: 'Language',
                        shouldFocusOnMount: true,
                        items: this.state.items
                    }}
                />
            </div>)}
        </MyContext.Consumer>
        );
    }

    _onAbort() {
        this.setState({ items: this.props.languageList });
    }

    _onItemClick = (ev, item) => {
        this.setState({ languageTo: item.key, languageToText: item.text });
        this.props.onTranslate(item.key, item.text);
    }

    _onChange = (e) => {
        const filteredItems = this.props.languageList.filter(item => item.text && item.text.toLowerCase().includes(e.target.value.toLowerCase()));

        if (!filteredItems || !filteredItems.length) {
            filteredItems.push({
                key: 'no_results',
                onRender: (item, dismissMenu) => (
                    <div
                        key="no_results"
                        style={{
                            width: '100%',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}
                    >
                        <Icon iconName="SearchIssue" title="No results found" />
                        <span>No results found</span>
                    </div>
                )
            });
        }

        this.setState((prevState, props) => ({
            items: filteredItems
        }));
    }

    _renderMenuList(menuListProps: IContextualMenuListProps, defaultRender: IRenderFunction<IContextualMenuListProps>) {
        return (
            <div>
                <div style={{ borderBottom: '1px solid #ccc' }}>
                    <SearchBox
                        ariaLabel="Filter actions by text"
                        placeholder="Filter language"
                        onAbort={this._onAbort}
                        onChange={this._onChange}
                        styles={{
                            root: [{ margin: '8px' }]
                        }}
                    />
                </div>
                {defaultRender(menuListProps)}
            </div>
        );
    }
}