import React, { Component } from 'react';
import { ASCHeader } from "../ASCHeader/ASCHeader";
import ASCFooter from "../ASCFooter/ASCFooter";
import UploadMail from "../UploadView/UploadMail"
import { Text, FontSizes, Button } from 'office-ui-fabric-react';

import {
    Stack
} from "office-ui-fabric-react";


// Styles definition
const stackContainerStyles: IStackStyles = {
    root: {
        paddingTop: 50
    }
};


const stackStyles: IStackStyles = {
    root: {
        maxWidth: 660,
        marginBottom: 50
    }
};

// Tokens definition
const stackTokens: IStackTokens = { childrenGap: 5 };


export default class ProductLicense extends Component {
    constructor(props) {
        super(props);
        this.uploadMail = React.createRef();
        this.state = {
            uploadActive: false
        }
    }





    onSendMail = (ev) => {
        if (this.uploadMail.current) {
            this.uploadMail.current.showPanel();
        }
    }


    render() {
        return (
            <>

                <ASCHeader title={"Recording Insights"} />

                <Stack tokens={stackTokens}>

                    <Stack horizontalAlign="center" styles={stackContainerStyles}>
                        <Stack styles={stackStyles}>
                            <Text variant="xxLarge">No valid user logged in!</Text>
                            <Text variant="xxLarge">Please contact ASC to buy a license.</Text>
                        </Stack>
                        <Button id="licenseButton" style={{ backgroundColor: 'darkgrey', padding: "8px", fontSize: FontSizes.icon }} onClick={() => { this.onSendMail() }} >Contact ASC</Button>

                        <UploadMail
                            id={1}
                            apol={this.props.apol}
                            authorization={this.props.authorization}
                            ref={this.uploadMail} />
                        
                        <Stack styles={stackStyles}>                           
                            <Text variant="xxLarge">Description</Text>
                            <Text variant="large">Microsoft has selected ASC as a key, strategic partner for compliance recording for Microsoft Teams. Mutual customers such as financial institutions will benefit from this collaboration as they now have a turnkey option for compliance-grade recording when making the decision to adopt Microsoft Teams.</Text>
                            <Text variant="large">Our new cloud service &quot;ASC Recording Insights&quot; offers users a compliant recording and analytics solution for their entire communications within Teams - including chat, phone calls, and video conferences. No separate, external recording system required. This integrated application captures and stores your communications in the cloud, allowing you to meet regulations like MiFID II or Dodd-Frank.</Text>
                            <Text variant="large">&quot;ASC Recording Insights&quot; is available in the Teams Store. Customers can select in which geographical region they want to save their data in Azure - and thus comply with applicable security standards. Nonetheless, an ASC app is already available in Teams offering users analytics tools to evaluate previously recorded audio and video data.</Text>
                            <Text variant="large">ASC additionally harnesses Microsoft&apos;s powerful Cognitive Services to further analyze communications using automatic transcription, topic extraction, and emotion detection.</Text>
                           
                        </Stack>
                        
                    </Stack>


                </Stack>

                <ASCFooter />
            </>
        );
    }

}