import React, { Component } from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DocumentCard } from 'office-ui-fabric-react/lib/DocumentCard';
import { Text, FontSizes, FontWeights, Spinner } from 'office-ui-fabric-react/lib/';
import { Query } from "react-apollo";


import gql from "graphql-tag";
import './GridElement.scss'
import DetailViewOverlay from "./../../DetailView/DetailViewOverlay"

//import ReactPlayer from "react-player";
import AzurePlayer from "./../../common/AzurePlayer";


export default class gridItem extends Component {

    constructor(props) {
        super(props);
        this.detailContainerRef = React.createRef();
        this.playerLoadedCallback = this.playerLoadedCallback.bind(this);
    }
    state =
        {
            thumbnailMode: false,
            playerClick: false,
            timeStart: this.props.item.timein
        };

    playerLoadedCallback = () => {

    };

    switchView = () => {
        this.setState({ playerClick: true })
        let bol = !this.state.thumbnailMode;
        this.setState({ thumbnailMode: bol })
        this.detailContainerRef.current.hidePanel()
    };

    detailView = () => {
        if (!this.state.thumbnailMode) {
            this.detailContainerRef.current.showPanelItem(this.props.item.conversationId, this.props.item)
        }
    };

    showDetail = () => {
        if (this.state.thumbnailMode) {
            this.switchView();
        }
        this.detailContainerRef.current.showPanelItem(this.props.item.conversationId, this.props.item)
    };

    componentDidMount() {
        if (this.props.onlyOne) {
            this.detailContainerRef.current.showPanelItem(this.props.item.conversationId, this.props.item)
        }
    }

    showPlaybuttonIfVideoExists = () => {
        if (this.props.item.replayAllowed && this.props.item.recordingType != null && this.props.item.recordingType != "Chat") {
            if (this.state.thumbnailMode) {
                return <div className="IconContainer PlayIcon"><Icon className='PlayStop' iconName='CircleStop' onClickCapture={this.switchView} /></div>
            } else {
                return <div className="IconContainer PlayIcon MoreButton"><Icon id="BoxPlaySolid" iconName='BoxPlaySolid' onClickCapture={this.switchView} /></div>
            }
        }
    };

    getViewDocumentCard = () => {
        return (
            <DocumentCard className={window.innerWidth < 600 ? "GridItemSmall" : "GridItem"}
                onClickHref=""
                onClick={this.detailView}
            >
                {this.getView()}
            </DocumentCard>
        );
    }

    getView = () => {

        if (!this.state.thumbnailMode) {
            let date = this.props.toDoArea ? `${this.props.item.day} - ${this.state.timeStart} - ${this.props.duration === "00:00:00" ? "" : this.props.item.duration}` : `${this.state.timeStart} - ${this.props.duration === "00:00:00" ? "" : this.props.item.duration}`
            return (
                <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} style={{ padding: window.innerWidth < 600 ? "0px" : "4px" }} >
                    <Stack horizontal horizontalAlign="space-between" style={{ color: this.props.theme.palette.themePrimary, padding: "0px" }} tokens={{ childrenGap: '10' }}>
                        <Stack.Item>
                            <Stack horizontal>
                                {callIcon(this.props)} {getLength(this.props)}
                                {callTypeIcon(this.props)}
                            </Stack>
                        </Stack.Item>
                        {this.props.userRules.ruleAccess.userSafe !== "No" && <div>
                            {callSafe(this.props)}
                        </div>}
                    </Stack>
                    <Text className="ContentTitle" variant="mediumPlus" wrap
                        style={{
                            color: this.props.theme.palette.themePrimary,
                            padding: "0px",
                            fontWeight: FontWeights.semibold,
                            width: (window.innerWidth < 600 ? '' : '246px'),
                            height: (window.innerWidth < 600 ? "24px" : "48px")
                        }}>
                        {shortTitle(this.props.item.topic, window.innerWidth < 600 ? 17 : 40)}
                    </Text>
                    <Stack horizontal align="start">
                        <Text className="ContentColor" variant="mediumPlus" style={{ color: this.props.theme.palette.themePrimary }} >{shortTitle(this.props.item.ownerDisplayName, window.innerWidth < 600 ? 17 : 48)} </Text>
                    </Stack>
                    <Stack horizontal align="start">
                        <Text className="ContentColor" variant="mediumPlus" style={{ color: this.props.theme.palette.themePrimary }}>{date}</Text>
                    </Stack>

                    {this.showKeepDeleteInToDoMode()}

                </Stack>
            );

        } else {
            var id = this.props.item.conversationId;
            return (
                // Get Replay Asset Url
                <Query
                    query={gql`
                          query ent ($id: ID) {
                            getReplayAsset(id: $id)
                          }
                        `}
                    variables={{ id }}  >
                    {({ loading, error, data }) => {
                        if (loading) return <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} ><Spinner label="Load Asset" ariaLive="assertive" labelPosition="top" /></Stack>;
                        if (error) return <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} ></Stack>;
                        if (data.getReplayAsset == null) return <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} ><Text className="ContentColor" variant="mediumPlus">No Asset</Text></Stack>
                        return (<div>
                            {data.getReplayAsset.length > 1 && data.getReplayAsset[1] != "" && <div className="grid-player" id={this.props.item.id} onClick={this.showDetail} >
                                <AzurePlayer
                                    height="100%"
                                    width="100%"
                                    fluid={true}
                                    controls={false}
                                    autoplay={true}
                                    streamingUrls={data.getReplayAsset[1]}
                                    callbackPlayerLoaded={this.playerLoadedCallback}
                                />
                            </div>
                            }
                            {data.getReplayAsset[0] != "" && <div className="grid-player" id={this.props.item.id + "2"} hidden={true}>
                                <AzurePlayer
                                    height="100%"
                                    width="100%"
                                    fluid={true}
                                    controls={false}
                                    autoplay={true}
                                    streamingUrls={data.getReplayAsset[0]}
                                    callbackPlayerLoaded={this.playerLoadedCallback}
                                />
                            </div>}
                        </div>
                        )
                    }}
                </Query>
            );

        }

    }

    getThumbnail = () => {
        if (this.props.item.thumbnail != null) {
            return { "backgroundImage": "url(" + this.props.item.thumbnail + ")" }
        }
    };

    showArchivedCallOrPlayButton = () => {
        if (this.props.item.hasRestoreStarted) {
            return <div className="IconContainer PlayIcon"><Icon className='BoxPlaySolid ms-Icon--ArchiveUndo ms-Icon' iconName='ArchiveUndo' /></div>
        }
        else {
            if (this.props.item.archiveStatus === 1 || this.props.item.archiveStatus === 2) {
                return <div className="IconContainer PlayIcon"><Icon className='BoxPlaySolid' iconName='Archive' /></div>

            }
            else {
                return this.showPlaybuttonIfVideoExists();
            }
        }

    }
    showKeepDeleteInToDoMode = () => {
        //see mobile display on _config.css
        if (this.props.userRules.ruleRecording !== null && this.props.userRules.ruleRecording.keepDeleteSettings !== null && this.props.item !== null) {
            if (this.props.item.keepDeleteAllowed && this.props.userRules.ruleRecording.keepDeleteSettings.isSendToDo) {
                return <div>
                    <Icon className='keep-delete-icon-display' iconName={"Delete"} title="Keep or Delete To Do" style={{ color: (this.props.item.deleteState !== null && this.props.item.deleteState=="Delete")?"blue":"black"}}/>
                    <Icon className='keep-delete-icon-display' iconName='Archive' title="Keep or Delete To Do" style={{ color: (this.props.item.deleteState !== null && this.props.item.deleteState == "Keep") ? "blue" : "black" }}/>
                </div>
            }
        }
        return;

    }
    render() {
        return (
            <div className={window.innerWidth < 600 ? "GridItemSmall" : "GridItem"}  >
                <DetailViewOverlay client={this.props.apol} authorization={this.props.authorization} ref={this.detailContainerRef} data={this.props.item} loading={true} userRules={this.props.userRules} theme={this.props.theme} />
                <div className={window.innerWidth < 600 ? "GridItemBackgroundImageSmall" : "GridItemBackgroundImage"} style={this.getThumbnail()}>
                </div>
                <div className={window.innerWidth < 600 ? "GridItemBackgroundSmall" : "GridItemBackground"}>
                </div>
                {this.getViewDocumentCard()}
                <div>
                    {this.showArchivedCallOrPlayButton()}
                </div>
            </div>
        );
    }
};


// Shorten GridItem Title
// usage: shortTitle(tileVar, maxLengh)
let shortTitle = (title, max = 18) => {

    const titleLength = title.length;
    const maxLength = max;
    // Replace _ or [
    var title_new = title.replace('_', " ");
    title_new = title_new.replace("[", " ");
    title_new = title_new.replace("]", " ");
    if (titleLength > maxLength && maxLength > 20) {
        return title_new.slice(0, maxLength) + '...';
    } else if (titleLength > maxLength) {
        return title_new.slice(0, maxLength) + '..';
    } else {
        return title_new;
    }
}

let callTypeIcon = (props) => {
    if (props.item.isVideo) {
        return (
            <Stack.Item style={{ padding: "0px" }}>
                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Video' title="Video" />
            </Stack.Item>
        );
    }
    else {
        return (<Stack horizontal align="start" style={{ padding: "0px" }} tokens={{ childrenGap: '10' }}>
            {props.item.isDuplicate && <Stack.Item style={{ padding: "4px" }}>
                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon, color: 'red' }} iconName='Error' title="Duplicate" />
            </Stack.Item>}
            {props.item.hasAudio && <Stack.Item style={{ padding: "4px" }}>
                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Microphone' title="Audio" />
            </Stack.Item>}
            {props.item.hasScreenSharing && <Stack.Item style={{ padding: "4px" }}>
                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ScreenCast' title="Screen share" />
            </Stack.Item>}
            {props.item.hasVideo && <Stack.Item style={{ padding: "4px" }}>
                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Video' title="Video" />
            </Stack.Item>}
            {props.item.hasChat && props.item.recordingType != "Chat" && <Stack.Item style={{ padding: "4px" }}>
                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Chat' title="Chat" />
            </Stack.Item>}
            {props.item.hasFiles && props.item.recordingType != "File" && <Stack.Item style={{ padding: "4px" }}>
                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Attach' title="Files" />
            </Stack.Item>}
        </Stack>);
    }
}

let callSafe = (props) => {
    let item = props.item
    if (item.conversationSafe === false) {
        <></>
    } else {
        return (<Icon style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='LockSolid' />)
    }
}


function getLength(props) {

    if (
        props != null &&
        undefined !== props.item.participantCount &&
        props.item.recordingType != null && props.item.recordingType === "Meeting"
    ) {
        return <Text className="ContentColor" variant="large" style={{ padding: "0px" }} title="Number of Participants"> {props.item.participantCount}</Text>;
    }
    return <div></div>
}

function callIcon(props) {
    const typeIcon = props.item.recordingType != null ? props.item.recordingType : "Unknown";
    if (typeIcon === "Meeting") {
        return <Icon className="ContentColor" style={{ padding: "4px", fontSize: FontSizes.icon }} iconName='Group' title="Meeting" />;
    } else if (typeIcon === "Inbound") {
        return <Icon className="ContentColor" style={{ padding: "4px", fontSize: FontSizes.icon }} iconName='ArrowDownRightMirrored8' title="Inbound" />;
    } else if (typeIcon === "Outbound") {
        return <Icon className="ContentColor" style={{ padding: "4px", fontSize: FontSizes.icon }} iconName='ArrowUpRight8' title="Outbound" />;
    } else if (typeIcon === "Internal") {
        return <Icon className="ContentColor" style={{ padding: "4px", fontSize: FontSizes.icon }} iconName='ConnectContacts' title="Internal" />;
    } else if (typeIcon === "Chat") {
        return <Icon className="ContentColor" style={{ padding: "4px", fontSize: FontSizes.icon }} iconName='Chat' title="Chat" />;
    } else if (typeIcon === "File") {
        return <Icon className="ContentColor" style={{ padding: "4px", fontSize: FontSizes.icon }} iconName='Attach' title="File" />;
    } else if (typeIcon === "") {
        return <Icon className="ContentColor" style={{ padding: "4px", fontSize: FontSizes.icon }} iconName='CloudUpload' />;
    }
    else {
        return <div></div>;
    }
}
/*
*
*
*
*           <p className="Time"> {this.props.timeS} (+{getLength(this.props) }min)</p>
*
*
*
*
* */
