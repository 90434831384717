import React, { Component } from 'react';
import { ApolloProvider, Query } from "react-apollo";
import { mutationCreateTicket, mutationUpdateTicket, queryGetTickets, mutationCreateTicketComment} from "../../../queries/querySettings";
import { Dropdown, IDropdownStyles } from 'office-ui-fabric-react/lib/Dropdown';
import { ComboBox, Spinner, Text } from 'office-ui-fabric-react/lib/';
import { queryGetEmailList } from "../../../queries/querySettings";

import Utils from "./../../common/Utils";

import {
    DefaultButton,
    PrimaryButton,
    Stack,
    TextField
} from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { MyContext } from '../../../context';
import "../fabric-icons-inline.css";
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { generateGUID } from '@microsoft/teams-js';


const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 250 }
};
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        {
            flex: '1 1 auto',
            borderTop: '4px solid blue',
            // color: theme.palette.neutralPrimary,
            display: 'flex',
            // fontSize: FontSizes.xLarge,
            alignItems: 'center',
            // fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0',
            },
            'p:first-child': {
                marginTop: 0,
            },
            'p:last-child': {
                marginBottom: 0,
            },
        },
    },
});

const iconButtonStyles = mergeStyleSets({
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    }
});


export default class AddTenant extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.allowedTenantsList = [];
        // Collect Tenant ComboBox
        if (this.props.userRules.allowedTenants != null) {
            for (let x = 0; x < this.props.userRules.allowedTenants.length; x++) {
                this.allowedTenantsList.push({ key: this.props.userRules.allowedTenants[x].id, text: this.props.userRules.allowedTenants[x].name });
            }
        }
 
        if (this.props.data != null && this.props.data !== undefined) {
            this.state = {
                title: this.props.data.title,
                priority: this.props.data.priority,
                caseId: this.props.data.caseId,
                state: this.props.data.state,
                id: this.props.data.id,
                description: this.props.data.description,
                created: this.props.data.created,
                changed: this.props.data.changed,
                tenantId: this.props.data.tenantId,
                isChecked: false,
                showModal: false,
                comment: ""
            }
        }
        else {
            this.state = {
                title: "",
                priority: "",
                caseId: "",
                state: "",
                description: "",
                id: null,
                created: "",
                changed: "",
                isChecked: false,
                showModal: false,
                comment: ""
            }
        }
    }



    styles = {
        root: {
            paddingTop: "20px"
        }
    };
    toastyy = {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    };
    toastyy2 = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    };

    render() {
        let auth = this.props.authorization;

        return (
            <Stack vertitcal>
            
            <Stack.Item styles={this.styles}>
                <Stack>
                    <Stack.Item>
                        
                            {this.props.data != null && <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketCaseId")}</>)}</MyContext.Consumer>}
                                value={this.state.caseId}
                                onKeyDown={this._handleKeyDown}
                                disabled
                            />}
                            {this.props.data != null && <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketCreated")}</>)}</MyContext.Consumer>}
                                value={this.state.created}
                                onKeyDown={this._handleKeyDown}
                                disabled
                            />}
                            {this.props.data != null && <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketChanged")}</>)}</MyContext.Consumer>}
                                value={this.state.changed}
                                onKeyDown={this._handleKeyDown}
                                disabled
                            />}
                            {this.props.userRules.allowedTenants != null && this.props.userRules.allowedTenants.length > 0 &&
                                <Stack.Item>
                                    {this.props.data == null ? <ComboBox
                                        defaultSelectedKey={this.state.tenantId}
                                        selectedKey={this.state.tenantId}
                                        options={this.allowedTenantsList}
                                        label={"TenantId"}
                                        onChange={(ev, newVal) => {
                                            this.setState({
                                                tenantId: newVal.key
                                            });
                                        }}
                                    /> : <ComboBox
                                        defaultSelectedKey={this.state.tenantId}
                                        selectedKey={this.state.tenantId}
                                        options={this.allowedTenantsList}
                                        label={"TenantId"}
                                        disabled
                                    />}
                                </Stack.Item>
                            }
                            {this.props.data != null && <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketState")}</>)}</MyContext.Consumer>}
                                value={this.state.state}
                                onKeyDown={this._handleKeyDown}
                                disabled
                            />}
                            
                            <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketTitle")}</>)}</MyContext.Consumer>}
                                onChange={(event) => { this.handleChange(event.target.value, 0) }}
                                value={this.state.title}
                                onKeyDown={this._handleKeyDown}
                            />
                            <Dropdown
                                selectedKey={this.state.priority}
                                onChange={(event, item) => { this.handleChange(item.key, 1) }}
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketPriority")}</>)}</MyContext.Consumer>}
                                options={[
                                    { key: 'SOS', text: 'SOS' },
                                    { key: 'Critical', text: 'Prio 1' },
                                    { key: 'Major', text: 'Prio 2' },
                                    { key: 'Minor', text: 'Prio 3' }
                                ]}
                                styles={dropdownStyles}
                            />
                            <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketDescription")}</>)}</MyContext.Consumer>}
                                onChange={(event) => { this.handleChange(event.target.value, 3) }}
                                value={this.state.description}
                                onKeyDown={this._handleKeyDown}
                                multiline rows={10}
                            />
                            
                    </Stack.Item>
                    
                </Stack>
                </Stack.Item>

                {this.props.data != null && <Stack.Item styles={this.styles}>
                    <Stack vertical>
                        <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketComment")}</>)}</MyContext.Consumer>}
                            value={this.state.comment}
                            onChange={(event) => { this.setState({ comment: event.target.value }) }}
                        />                    
                        <Stack.Item>
                            <DefaultButton disabled={this.state.comment === ""}  onClick={(event) => { this.notifyTicketEmail(this.props.apol) }} text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "save")}</>)}</MyContext.Consumer>} />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>}

                <Stack.Item styles={this.styles}>
                    <Stack horizontal>
                        <Stack.Item>
                            <DefaultButton onClick={(event) => { this.props.cl() }} text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "cancel")}</>)}</MyContext.Consumer>} />
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton
                                id="saveBtn"
                                onClick={(event) => { this.notifyTenantChange(this.props.apol); this.props.cl() }}
                                text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "save")}</>)}</MyContext.Consumer>} disabled={!this.state.isChanged} />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>

                {this.props.data != null && <Stack.Item styles={this.styles}>
                    <Stack vertical>
                        <Query
                            query={queryGetEmailList}
                            fetchPolicy="network-only"
                            variables={{ id: this.props.data.id, tenantId: this.props.data.tenantId, auth: this.props.auth }}  >
                            {({ loading, error, data }) => {
                                if (loading) return <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} ><Spinner label="Load Emails" ariaLive="assertive" labelPosition="top" /></Stack>;
                                if (error) return <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} ></Stack>;
                                if (data.getEmailList == null) return <Stack vertical className={window.innerWidth < 600 ? "GridItem-content-small" : "GridItem-content"} ><Text className="ContentColor" variant="mediumPlus">Emails</Text></Stack>
                                return data.getEmailList.map(({ id, subject, from, to, created, changed, text }, i) =>
                                (
                                    <div key={id}>
                                        <Text variant="large"><MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketMail")}</>)}</MyContext.Consumer> {created}</Text>
                                        <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketSubject")}</>)}</MyContext.Consumer>}
                                            value={subject}
                                            disabled
                                        />
                                        <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketFrom")}</>)}</MyContext.Consumer>}
                                            value={from}
                                            disabled
                                        />
                                        <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketTo")}</>)}</MyContext.Consumer>}
                                            value={to}
                                            disabled
                                        />
                                        <Text variant="medium"><MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketContent")}</>)}</MyContext.Consumer></Text>
                                        <div dangerouslySetInnerHTML={{ __html: text }}></div> <br />
                                    </div>
                                ))
                            }}
                        </Query>
                    </Stack>
                </Stack.Item>}
        </Stack>
        );
    }

    handleChange(text, id) {       
        switch (id) {
            case 0: this.setState({ title: text }); break;
            case 1: this.setState({ priority: text }); break;
            case 2: this.setState({ state: text }); break;
            case 3: this.setState({ description: text }); break;

            default: break;
        }
        // Decide to set isChanged
        if (
               (id === 0 ? text : this.state.title) !== ""
            && (id === 3 ? text : this.state.description) !== ""
        ) {
                this.setState({ isChanged: true });  
        } else {
            this.setState({ isChanged: false });
        }
    }

 

    notifyTenantChange = async (apol) => {
        // Add or change User

        
        var ticket = {
            title: this.state.title,
            priority: this.state.priority,
            caseId: this.state.caseId,
            description: this.state.description,
            tenantId: this.state.tenantId,
            id: this.props.data != null ? this.state.id : Utils.guidGenerator()
        };
        if (this.props.data != null) {
            var data = await apol
                .mutate({
                    mutation: mutationUpdateTicket,
                    refetchQueries: [{
                        query: queryGetTickets,
                        variables: { input: this.props.authorization, tenantId: null, asPartner: true },
                    }],
                    variables: {
                        ticket: ticket
                    }
                });
            if (data.data.updateTicket == null || !data.data.updateTicket) {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "changeTicketNotSuccessful")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "ticketChanged")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } else {
            data = await apol
                .mutate({
                    mutation: mutationCreateTicket,
                    refetchQueries: [{
                        query: queryGetTickets,
                        variables: { input: this.props.authorization, tenantId: null, asPartner: true },
                    }],
                    variables: {
                        ticket: ticket
                    }
                });
            if (data.data.createTicket == null || !data.data.createTicket) {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addTicketNotSuccessful")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "ticketAdded")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    };

    notifyTicketEmail = async (apol) => {
        // Create Email
        var ticket = {
            title: this.state.title,
            priority: this.state.priority,
            caseId: this.state.caseId,
            description: this.state.description,
            tenantId: this.state.tenantId,
            id: this.state.id
        };
        if (this.props.data != null) {
            var data = await apol
                .mutate({
                    mutation: mutationCreateTicketComment,
                    variables: {
                        ticket: ticket,
                        comment: this.state.comment
                    }
                });
            if (data.data.createTicketComment == null || !data.data.createTicketComment) {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "createTicketCommentNotSuccessful")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "createTicketCommentSuccessful")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    };


    _closeModal = () => {
        this.setState({ showModal: false });
    }

    _openModal = () => {
        this.setState({ showModal: true });
    }
}
