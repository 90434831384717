import React from 'react'
import Papa from "papaparse";
import { mutationCreateCategory, mutationCreateCategoryTemplate, mutationUpdateCategory, mutationUpdateCategoryTemplate, queryGetConfigData, checkRetentionTimeValidity } from "../../../queries/querySettings";
import { getAllVersionOfCategoryData, restoreCategory, queryGetCategory } from "../../../queries/queryCategory"
import {
    DefaultButton,
    SelectionMode,
    DetailsList,
    DetailsListLayoutMode,
    PrimaryButton,
    Stack,
    Selection,
    TextField,
    Slider,
    Checkbox,
    Spinner,
    Dropdown,
    ComboBox,
    Button,
    TeachingBubble
} from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { MyContext } from '../../../context';
import { number } from 'prop-types';
import { SpinButton } from '@fluentui/react';
import { Query } from 'react-apollo';

export default class AddCategorie extends React.Component {

    constructor(props) {
        super(props);
        this.fileUploadRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        if (this.props.data != null && this.props.data !== undefined) {
            this.state = this.props.data;
            this.state.allowTTLChangeViaCategorization = (this.props.userRules != null && this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.allowTTLChangeViaCategorization) ? this.props.userRules.ruleAccess.allowTTLChangeViaCategorization : false;
            this.state.ttlConfirmed = (this.props.data != null && this.props.data.categorizationActionType !== null && this.props.data.categorizationActionType == "SetRetentionTime") ? true : false
        }
        else {
            this.state = {
                sel4list: [],
                name: "",
                sentiment: 0,
                frequence: 1,
                keyword: "",
                link: "",
                id: null,
                searchExactKeywords: false,
                soundslike: "",
                countUniqueWordsOnce: false,
                fromCompliance: this.props.fromCompliance,
                categorizationActionType: "NoAction",
                retentionTimeSetting: 0,
                allowTTLChangeViaCategorization: (this.props.userRules != null && this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.allowTTLChangeViaCategorization) ? this.props.userRules.ruleAccess.allowTTLChangeViaCategorization : false,
                version: 0,
                masterItemId: null,
                selected: true,
                //versions
                selectedOtherVersion: false,
                selectedVersion: null,
                maxTtlFound: 0,
                ttlConfirmed: false,
                ttlBtnMessage: false
            }
        }


    }
    columns = [
        { key: 'col1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "keyword")}</>)}</MyContext.Consumer>, fieldName: 'text', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'col2', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "soundslike")}</>)}</MyContext.Consumer>, fieldName: 'soundlike', minWidth: 100, maxWidth: 200, isResizable: true },
    ];
    styles = {
        root: {
            paddingTop: "20px"
        }
    };
    toastyy = {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    };
    toastyy2 = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    };

    componentDidMount() {
        this.refreshVersions()
    }

    allVersions = null

    refetchQueryVersions = null;

    refreshVersions = () => {
        if (this.refetchQueryVersions !== null) {
            this.refetchQueryVersions()
        }
    }

    onChangeVersion = (e, item) => {
        let id = item.key
        var filterVersion = this.allVersions.filter(f => { return f.id === id })
        if (filterVersion !== null && filterVersion.length > 0) {
            var oldVersion = filterVersion[0]
            let tmpp = [];
            if (oldVersion.keywords != null) {
                for (let x = 0; x < oldVersion.keywords.length; x++) {
                    let splitKeywordAndSoundlike = oldVersion.keywords[x].split('_');
                    tmpp.push({ text: splitKeywordAndSoundlike[0], soundlike: splitKeywordAndSoundlike[1] });
                }
            }
            this.setState({
                sel4list: tmpp,
                name: oldVersion.name,
                sentiment: oldVersion.sentiment,
                frequence: oldVersion.frequence,
                link: oldVersion.link,
                keyword: "",
                searchExactKeywords: oldVersion.searchExactKeywords,
                countUniqueWordsOnce: oldVersion.countUniqueWordsOnce,
                selectedVersion: id,
                selectedOtherVersion: true
            })
        }
    }

    restoreVersion = () => {
        this.setState({ processing: true })
        let id = this.state.selectedVersion
        this.props.apol
            .mutate({
                mutation: restoreCategory,
                variables: { id }
            })
            .then((result) => {
                if (result.data.restoreCategory.status === "ok") {
                    this.props.cl()
                } else {
                    if (this.props.fromCompliance) {
                        this.props.setMessageError(result.data.restoreCategory.text)
                    } else {
                        toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", result.data.restoreCategory.text)}</>)}</MyContext.Consumer>, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                }
                this.setState({
                    selectedOtherVersion: false,
                    processing: false
                })
            });
    }

    import = () => {
        if (this.fileUploadRef.current) {
            this.fileUploadRef.current.click()
        }
    }

    uploadFile = (clickEvent) => {
        clickEvent.preventDefault()
        var file = this.fileUploadRef.current.files[0];
        var textType = /text.*/;
        if (file.type.match(textType)) {
            Papa.parse(file, {
                complete: function (results, file) {
                    let data = results.data
                        .filter(d => d[0].trim() !== "") // Filtert leere Zeilen
                        .map(d => {
                        return ({
                            soundlike: d[1],
                            text: d[0]
                        })
                    })
                    this.setState({
                        sel4list: data,
                        message: ""
                    })
                }.bind(this),
                delimiter: ";"
            })
        } else {
            /*fixme language*/
            this.setState({
                message: "Error: file not supported"
            })
        }

    }

    render() {
        let masterItemId = this.state.masterItemId
        var saveAsTemplateStyle = { marginTop: "30px", marginLeft: "15px" }
        return (<MyContext.Consumer>
            {(context) => (
                <Stack vertitcal>
                    {masterItemId && (<Stack.Item>
                        {/*QUERY ALL VERSIONS*/}
                        <Query
                            query={getAllVersionOfCategoryData}
                            variables={{ masterItemId }}>
                            {({ loading, error, data, refetch }) => {
                                this.refetchQueryVersions = refetch
                                if (loading) {
                                    return (<Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />)
                                }
                                if (error) {
                                    return ""
                                }
                                if (data.getAllVersionOfCategoryData == null) {
                                    return ""
                                }
                                let versions = data.getAllVersionOfCategoryData.map(v => {
                                    return {
                                        "key": v.id,
                                        "text": v.version
                                    }
                                })
                                this.allVersions = data.getAllVersionOfCategoryData
                                return (<div>
                                    {versions.length > 0 && <Stack >
                                        <Stack.Item>
                                            <Dropdown
                                                label={context.getTranslation("compliance", "version")}
                                                selectedKey={this.state.selectedVersion}
                                                onChange={this.onChangeVersion}
                                                options={versions}
                                            />
                                        </Stack.Item>
                                    </Stack>}
                                </div>
                                )
                            }}
                        </Query>
                    </Stack.Item>)}
                    <Stack.Item>
                        <TextField label={context.getTranslation("configuration", "name")}
                            onChange={(event) => { this.handleChange(event, 0) }}
                            value={this.state.name}
                        />
                    </Stack.Item>

                    <Stack.Item styles={this.styles}>
                        <Stack.Item>
                            <Checkbox
                                label={context.getTranslation("configuration", "searchExactKeywords")}
                                checked={this.state.searchExactKeywords}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        searchExactKeywords: newValue,
                                        selectedOtherVersion: false
                                    });
                                }}
                            />
                        </Stack.Item>
                        <Stack horizontal>
                            <Stack.Item>
                                <TextField label={context.getTranslation("configuration", "keyword")}
                                    onChange={(event) => { this.handleChange(event, 1) }}
                                    value={this.state.keyword}
                                    onKeyDown={this._handleKeyDown}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{
                                root: {
                                    paddingLeft: "80px"
                                }
                            }}>
                                <TextField label={context.getTranslation("configuration", "soundslike")}
                                    onChange={(event) => { this.handleChange(event, 4) }}
                                    value={this.state.soundslike}
                                    onKeyDown={this._handleKeyDown}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{
                                root: {
                                    paddingTop: "29px",
                                    paddingLeft: "50px",
                                    display: "flex",
                                    flex: "1",
                                    "flex-direction": "row-reverse"
                                }
                            }}>
                                <PrimaryButton onClick={event => {
                                    if (this.state.keyword.length > 0) {
                                        this.addToKeylist(this.state.keyword, this.state.soundslike)
                                    } else {
                                        toast.error(context.getTranslation("configuration", "keywordToShort"), this.toastyy)
                                    }
                                }} text={context.getTranslation("configuration", "add")} />
                            </Stack.Item>

                        </Stack>

                    </Stack.Item>

                    <Stack.Item styles={this.styles}>
                        <Stack horizontal>
                            <Stack.Item styles={{ root: { width: "85%" } }}>
                                <DetailsList
                                    key={this.state.sel4list.length + ""}
                                    items={this.state.sel4list}
                                    columns={this.columns}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    checkButtonAriaLabel="Row checkbox"
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                    styles={{ root: { color: "black" } }}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{
                                root: {
                                    paddingLeft: "15px",
                                }
                            }}>
                                <PrimaryButton onClick={event => {
                                    if (this.state.selectionDetails !== undefined && this.state.selectionDetails.text != null) {
                                        this.delKeylist(this.state.selectionDetails.text);
                                    } else {
                                        toast.error(context.getTranslation("configuration", "nothingToDelete"), this.toastyy)
                                    }
                                }} text={"Del"} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item styles={this.styles}>
                        {/*IMPORT FILE CSV*/}
                        <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                            <Stack.Item>
                                <PrimaryButton text={context.getTranslation("tenants", "import")} onClick={this.import} style={{ marginLeft: "15px" }} >
                                    <input type="file" accept=".csv" ref={this.fileUploadRef} onChange={this.uploadFile} text="" style={{ display: "none" }} />
                                </PrimaryButton>
                            </Stack.Item>
                            <Stack.Item>
                                {/*fixme style red or similar*/}
                                <div style={{ marginTop: "8px" }}> <span>{this.state.message}</span></div>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item styles={this.styles}>
                        <TextField min={1} value={this.state.frequence} type="Number" label={context.getTranslation("configuration", "frequency")}
                            onChange={(event) => { this.handleChange(event, 2) }}
                            errorMessage={(this.state.frequence < 1) ? "Minimum frequency cannot be lower than 1" : this.state.countUniqueWordsOnce && this.state.frequence > this.state.sel4list.length ? "Frequency must be at most the exact number of the items" : ""}

                        />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { paddingTop: "15px" } }}>
                        <Checkbox
                            label={context.getTranslation("configuration", "countUniqueWords")}
                            checked={this.state.countUniqueWordsOnce}
                            onChange={(event, newValue) => {
                                this.setState({
                                    countUniqueWordsOnce: newValue,
                                    selectedOtherVersion: false
                                });
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={this.styles}>
                        <Slider label={context.getTranslation("configuration", "sentiment")} min={0} max={100} valueFormat={(value: number) => `${value}%`} showValue={true}
                            value={this.state.sentiment}
                            onChange={(value: number) => { this.setState({ sentiment: value, selectedOtherVersion: false }) }}
                        />
                    </Stack.Item>

                    <Stack.Item>

                        <Stack horizontal>
                            <Stack.Item styles={{ root: { width: "85%" } }}>
                                <MyContext.Consumer>
                                    {(context) => (
                                        <ComboBox
                                            style={{ width: "230px" }}
                                            placeholder="Select an option"
                                            label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "actionCategorization")}</>)}</MyContext.Consumer>}
                                            options={[{
                                                key: "NoAction",
                                                text: context.getTranslation("configuration", "noAction"),
                                            },
                                            {
                                                key: "SetRetentionTime",
                                                disabled: !this.state.allowTTLChangeViaCategorization,
                                                text: context.getTranslation("configuration", "setRetentionTime"),
                                            }]
                                            }
                                            selectedKey={this.state.categorizationActionType}
                                            onChange={(event, option, index, newValue) => { this.handleChange(event, 5, option.key) }}

                                        />
                                    )
                                    }
                                </MyContext.Consumer>
                            </Stack.Item>

                            {this.state.categorizationActionType == "SetRetentionTime" && <Stack vertical>
                                <TextField min={0} type="Number"
                                    style={{ width: "250px" }}
                                    label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "days")}</>)}</MyContext.Consumer>}
                                    onChange={(event) => { this.handleChange(event, 6) }}
                                    value={this.state.retentionTimeSetting}
                                    id="categoryRetentionTimeField"
                                />
                                <Button id="categoryRetentionTimeBtn" text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "checkValidity")}</>)}</MyContext.Consumer>} onClick={(event) => { this.checkTTLValidity(this.props.apol) }} style={{ width: "150px", marginTop: "5px" }} disabled={this.state.TTLLoading} />
                                {this.state.ttlBtnMessage && <TeachingBubble
                                    target={`#${"categoryRetentionTimeBtn"}`}
                                    secondaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "no")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ ttlBtnMessage: false, ttlConfirmed: false }) } }}
                                    primaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "yes")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ ttlBtnMessage: false, ttlConfirmed: true }) } }}
                                    headline={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "warning")}</>)}</MyContext.Consumer>
                                    }

                                >
                                    <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "TTLErrorMessage")}</>)}</MyContext.Consumer>

                                </TeachingBubble>}
                            </Stack>}
                        </Stack>
                    </Stack.Item>

                    <Stack.Item styles={this.styles}>
                        <TextField value={this.state.link} label={context.getTranslation("configuration", "link")}
                            onChange={(event) => { this.handleChange(event, 3) }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={this.styles}>
                        {/*FOOTER BUTTONS*/}
                        <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                            <DefaultButton text={context.getTranslation("common", "cancel")} onClick={(event) => { this.props.cl() }} style={{ marginTop: "30px", marginLeft: "15px" }} />
                            {(!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion) &&
                                <PrimaryButton text={context.getTranslation("common", "save")} onClick={(event) => { this.notifyCategoryChange(this.props.apol, false); this.props.cl() }} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={(this.state.categorizationActionType == "SetRetentionTime" && !this.state.ttlConfirmed)?true:false} />
                            }
                            {(this.props.userRules.userTemplateAllowed && this.props.fromCompliance && (!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion)) &&
                                <PrimaryButton text={context.getTranslation("common", "saveAsTemplate")} onClick={(event) => { this.notifyCategoryChange(this.props.apol, true) }} style={(this.state.categorizationActionType == "SetRetentionTime" && !this.state.ttlConfirmed) || this.state.processing ? saveAsTemplateStyle : { ...saveAsTemplateStyle, background: "red", color: "white" }} allowDisabledFocus={true} disabled={(this.state.categorizationActionType == "SetRetentionTime" && !this.state.ttlConfirmed) || this.state.processing} />
                            }
                            {this.state.selectedOtherVersion && this.state.id !== this.state.selectedVersion &&
                                <PrimaryButton text={context.getTranslation("compliance", "restoreVersion")} onClick={this.restoreVersion} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={this.state.processing} />
                            }
                        </Stack>
                    </Stack.Item>

                </Stack>
            )
            }
        </MyContext.Consumer>
        );
    }
    handleRetentionTimeIncrDecr(event, id) {
        switch (id) {
            case 1: this.setState({ retentionTimeSetting: event.target.value + 1 }); break;
            case 2: this.setState({ retentionTimeSetting: event - 1 }); break;
            default: break;
        }
    }
    handleChange(event, id, newCategorizationActionType) {

        const text = event.target.value;
        let state = {}
        switch (id) {
            case 0: this.setState({ name: text, selectedOtherVersion: false }); break;
            case 1: this.setState({ keyword: text, selectedOtherVersion: false }); break;
            case 2: this.setState({ frequence: parseInt(text), selectedOtherVersion: false }); break;
            case 3: this.setState({ link: text, selectedOtherVersion: false }); break;
            case 4: this.setState({ soundslike: text, selectedOtherVersion: false }); break;
            case 5: this.setState({ categorizationActionType: newCategorizationActionType, selectedOtherVersion: false, ttlConfirmed: false }); break;
            case 6: this.setState({ retentionTimeSetting: parseInt(text), selectedOtherVersion: false, ttlConfirmed: false }); break;
            default: break;
        }
        this.setState({ ...state })
    }
    addToKeylist = (keyword, soundlike) => {

        let tmp = this.state.sel4list;
        let ex = true;
        for (let x = 0; x < tmp.length; x++) {
            if (tmp[x].text.toUpperCase() === keyword.toUpperCase()) {
                ex = false;
                break;
            }
        }

        if (ex) {
            tmp.push({ text: keyword, soundlike: soundlike })
            this.setState({ keyword: "", soundslike: "", sel4list: tmp, selectedOtherVersion: false })
        }
        else {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "keywordAlreadyInList")}</>)}</MyContext.Consumer>, this.toastyy)
        }

    }
    delKeylist = (value) => {
        let tmp = this.state.sel4list;
        let out = [];

        let ex = false;

        for (let x = 0; x < tmp.length; x++) {
            if (tmp[x].text !== value) {
                out.push(tmp[x])
            } else {
                ex = true;

            }
        }

        if (ex) {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "deletingSuccessful")}</>)}</MyContext.Consumer>, this.toastyy);
            this.setState({ sel4list: out, selectionDetails: undefined, selectedOtherVersion: false })
        }
        else {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "errorDeleting")}</>)}</MyContext.Consumer>, this.toastyy)
        }

    }

    _selection = new Selection({
        onSelectionChanged: () => {
            this.setState({

                selectionDetails: this._selection.getSelection()[0]
            });
        }
    });
    _handleKeyDown = (e) => { 
        if (e.key === 'Enter') {
            if (this.state.keyword.length > 0) {
                this.addToKeylist(this.state.keyword, this.state.soundslike);

            } else {
                toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "keywordToShort")}</>)}</MyContext.Consumer>, this.toastyy)

            }
        }
        return;
    }

    notifyCategoryChange = async (apol, saveAsTemplate) => {
        // Add or change category
        var category;
        //console.log("addCategory: " + this.state.name);
        let keywordList = [];

        for (let x = 0; x < this.state.sel4list.length; x++) {
            var keywordCorrect = "";
            var soundlikeCorrect = "";
            if (this.state.sel4list[x].text != undefined) {
                keywordCorrect = this.state.sel4list[x].text;
            }
            if (this.state.sel4list[x].soundlike != undefined) {
                soundlikeCorrect = this.state.sel4list[x].soundlike;
            }
            keywordList.push(keywordCorrect + "_" + soundlikeCorrect);
        }
        let frequence;
        if (this.state.frequence < 1) {
            frequence = 1;
        } else if (this.state.countUniqueWordsOnce && this.state.frequence >= this.state.sel4list.length) {
            frequence = this.state.sel4list.length;
        } else {
            frequence = this.state.frequence;
        }
        let categoryGroup = null;
        if (this.state.categoryGroup !== undefined) {
            let { __typename, ...categoryGroupDes } = this.state.categoryGroup;
            categoryGroup = categoryGroupDes
        }
        category = {
            name: this.state.name,
            sentiment: this.state.sentiment,
            frequence: parseInt(this.state.frequence),
            keywords: keywordList,
            link: this.state.link,
            id: this.state.id,
            searchExactKeywords: this.state.searchExactKeywords,
            countUniqueWordsOnce: this.state.countUniqueWordsOnce,
            fromCompliance: this.state.fromCompliance,
            categorizationActionType: this.state.categorizationActionType,
            retentionTimeSetting: this.state.retentionTimeSetting,
            // manage version
            version: this.allVersions !== null && this.allVersions.length !== 0 ? this.allVersions.length : 1, //set always the number of the last version also if is not selected
            masterItemId: this.state.masterItemId,
            selected: true,
            categoryGroup
        };
        if (this.props.alreadyExistingNames == null || !this.props.alreadyExistingNames.includes(this.state.name.toLowerCase())) {
            if (this.props.data != null) {
                var data = await apol
                    .mutate({
                        mutation: saveAsTemplate ? mutationUpdateCategoryTemplate : mutationUpdateCategory,
                        refetchQueries: [{
                            query: this.props.fromCompliance ? queryGetCategory : queryGetConfigData,
                            variables: { auth: this.props.authorization },
                        }],
                        variables: {
                            auth: this.props.authorization,
                            category: category
                        }
                    });
                let resultData = saveAsTemplate ? data.data.updateCategoryTemplate : data.data.updateCategory
                if (resultData == null || resultData.name === "error") {
                    toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "changeCategoryNotSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "categoryChanged")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.props.cl()
                }
            } else {
                data = await apol
                    .mutate({
                        mutation: saveAsTemplate ? mutationCreateCategoryTemplate: mutationCreateCategory,
                        refetchQueries: [{
                            query: this.props.fromCompliance ? queryGetCategory : queryGetConfigData,
                            variables: { auth: this.props.authorization },
                        }],
                        variables: {
                            auth: this.props.authorization,
                            category: category
                        }
                    });
                let resultData = saveAsTemplate ? data.data.createCategoryTemplate : data.data.createCategory
                if (resultData == null || resultData.name === "error") {
                    toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addCategoryNotSuccessful")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "categoryAdded")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.props.cl()
                }
            }
        } else {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "nameAlreadyExist")}</>)}</MyContext.Consumer>, this.toastyy)
        }
    };

    checkTTLValidity = async (apol) => {
        this.setState({ TTLLoading: true });
        var data = await apol
            .mutate({
                mutation: checkRetentionTimeValidity,
                variables: {
                    ttl: this.state.retentionTimeSetting
                }
            });

       
        if (data.data.checkRetentionTimeValidity) {
            this.setState({ ttlConfirmed: true });
            document.getElementById("categoryRetentionTimeField").style.border = "1px solid green";
            document.querySelector('#categoryRetentionTimeBtn').style.border = "1px solid green";
        }
        else {
            document.getElementById("categoryRetentionTimeField").style.border = "1px solid orange";
            document.querySelector('#categoryRetentionTimeBtn').style.border = "1px solid orange";
            this.setState({ ttlBtnMessage: true })

        }
        this.setState({ TTLLoading: false });

    }

}