import React, { Component } from 'react'
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import "./DetailView.scss"
import LiveContainer from './DetailContainer/LiveContainer'
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { ApolloProvider } from "react-apollo";
import { Query } from "react-apollo";
import { queryGetLiveEntry } from "./../../queries/queryRecordings";
import { MyContext } from '../../context';

export default class LiveView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPanelOpen: this.props.isPanelOpen != null ? this.props.isPanelOpen : false,
            id: this.props.id != null ? this.props.id : null
        }
    }

    renderData = (id, auth) => (
        <MyContext.Consumer>
            {(context) => (
                <Query
                    query={queryGetLiveEntry}
                    variables={{ id, auth }} pollInterval={10000}>
                    {({ loading, error, data }) => {
                        if (loading) return (
                            <Panel
                                isOpen={this.state.isPanelOpen}
                                onDismiss={this.hidePanel}
                                isFooterAtBottom={false}
                                headerText={context.getTranslation("liveViewPanel","liveViewPanelTitle")}
                                type={PanelType.smallFixedFar}
                            >
                                <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                            </Panel>);
                        if (error) return <div></div>;
                        if (data.getLiveEntry == null) return <div></div>;
                        return (
                            <Panel
                                isOpen={this.state.isPanelOpen}
                                onDismiss={this.hidePanel}
                                isFooterAtBottom={false}
                                headerText={context.getTranslation("liveViewPanel", "liveViewPanelTitle")}
                                type={PanelType.smallFixedFar}
                            >
                                <LiveContainer
                                    apol={this.props.client}
                                    authorization={this.props.authorization}
                                    data={data.getLiveEntry}
                                    userRules={this.props.userRules}
                                />
                            </Panel>);
                    }}
                </Query>
            )
            }
        </MyContext.Consumer>
    );

    render() {
        //console.log("renderLiveView!");
        if (this.state.id != null) {
            //console.log("Client");
            //console.log(this.props.client);
            return (<ApolloProvider client={this.props.client}>
                {this.renderData(this.state.id, this.props.authorization)}
            </ApolloProvider>);
        }
        else {
            return ""
        }
    }

    showPanel = (itemId) => {
        this.setState({ id: itemId, isPanelOpen: true });
    };

    hidePanel = () => {
        this.setState({ id: null, isPanelOpen: false });
    };
}
