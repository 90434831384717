import gql from 'graphql-tag';


export const queryAllAdherencePhrases = gql`query  {
                getAIAdherencePhrases  {
                        id
                        masterItemId
                        name
                        description
                        phrase
                        adherence
                        tenantId
                        contained
                        relatedPhrases
                        unrelatedPhrases
                    }
         }`

export const queryGetAdherencePhrase = gql`query ent ($id: String) {
                getAIAdherencePhrase (id: $id) {
                        id
                        name
                        description
                        phrase
                        adherence
                        tenantId
                        version
                        masterItemId
                        selected
                        contained
                        relatedPhrases
                        unrelatedPhrases
                    }
         }`

export const createAdherencePhrase = gql`mutation ent($aiAdherencePhrase: AIAdherencePhraseInputType){
                                        upsertAIAdherencePhrase(aiAdherencePhrase: $aiAdherencePhrase) {
                                                id
                                                text
                                                status
                                            }
                                        }`

export const deleteAdherencePhrase = gql`mutation ent ($id: String) {
                                        deleteAIAdherencePhrase(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`

export const getAllVersionOfAdherencePhrase = gql`query ent ($masterItemId: String) {
                                        getAllVersionOfAIAdherencePhrase(masterItemId: $masterItemId) {
                                                id
                                                version
                                            }
                                    }`

export const restoreAdherencePhrase = gql`mutation ent ($id: String) {
                                        restoreAIAdherencePhrase(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`
