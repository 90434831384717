import React from 'react';
import "./../../ReportView/ReportDetailContainer/ReportDetailContainer.scss"
import DetailViewOverlay from "./../../DetailView/DetailViewOverlay"
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { Fabric } from 'office-ui-fabric-react/lib/';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { FontSizes, Stack, Text } from 'office-ui-fabric-react/lib';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';


import { Icon } from "office-ui-fabric-react";

import zipcelx from 'zipcelx';
import { MyContext } from '../../../context';

const config = {
    filename: 'report',
    sheet: {
        data: [
            [{
                value: "Conversation ID",
                type: 'string'
            }, {
                value: 'ConversationType',
                type: 'string'
            }, {
                value: "Header",
                type: 'string'
            }, {
                value: "Date",
                type: 'string'
            }, {
                value: "Start",
                type: 'string'
            }, {
                value: "Duration",
                type: 'string'
            }, {
                value: "Participants",
                type: 'string'
            }, {
                value: "userLogin",
                type: 'string'
            }, {
                value: "userId",
                type: 'string'
            }, {
                value: "hasAudio",
                type: 'string'
            }, {
                value: "hasVideo",
                type: 'string'
            }, {
                value: "hasScreen",
                type: 'string'
            }, {
                value: "size",
                type: 'string'
            }, {
                value: "transcript",
                type: 'string'
            }, {
                value: "errorMessage",
                type: 'string'
            }
            ]
        ]
    }
};

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});

export interface IDocument {
    conversationType: Boolean,
    conversationID: String,
    conversationDirection: String,
    initiator: String,
    date: String,
    starttime: String,
    endtime: String,
    duration: String,
    attendees: String,
    notes: String,
    transcription: String

}

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}



export default class ReportDetailContainer extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: IDocument[];
    constructor(props: {}) {
        super(props);

        this.detailContainerRef = React.createRef();

        this._allItems = this._generateDocuments(props.data.entry);

        let columns = [
            {
                key: 'column1',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "conversationType")}</>)}</MyContext.Consumer>,
                className: classNames.fileIconCell,
                iconClassName: classNames.fileIconHeaderIcon,
                ariaLabel: 'Column operations for File type, Press to sort on File type',
                iconName: 'Page',
                isIconOnly: true,
                fieldName: 'name',
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    const typeIcon = item.conversationType != null ? item.conversationType : "Unknown";
                    if (typeIcon === "Meeting") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Group' title="Meeting" />;
                    } else if (typeIcon === "Inbound") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ArrowDownRightMirrored8' title="Inbound" />;
                    } else if (typeIcon === "Outbound") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ArrowUpRight8' title="Outbound" />;
                    } else if (typeIcon === "Internal") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ConnectContacts' title="Internal" />;
                    } else if (typeIcon === "Chat") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Chat' title="Chat" />;
                    } else if (typeIcon === "") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='CloudUpload' />;
                    }
                    else {
                        return <div></div>;
                    }
                }
            },
            {
                key: 'column2',
                name: 'Header',
                fieldName: 'topic',
                minWidth: 120,
                maxWidth: 400,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IDocument) => {
                    return <span>{item.topic}</span>;
                }
            },
            {
                key: 'column3',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "date")}</>)}</MyContext.Consumer>,
                fieldName: 'dateValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'date',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.date}</span>;
                }
            }, {
                key: 'column4',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "startTime")}</>)}</MyContext.Consumer>,
                fieldName: 'dateValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'date',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.starttime}</span>;
                }
            },
            {
                key: 'column5',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "duration")}</>)}</MyContext.Consumer>,
                fieldName: 'duration',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.duration}</span>;
                },
                isPadded: true
            },
            {
                key: 'column6',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "attendees")}</>)}</MyContext.Consumer>,
                fieldName: 'participants',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.participants}</span>;
                },
                isPadded: true
            },
            {
                key: 'column7',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "userId")}</>)}</MyContext.Consumer>,
                fieldName: 'participants',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.userId}</span>;
                },
                isPadded: true
            },
            {
                key: 'column8',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "userLogin")}</>)}</MyContext.Consumer>,
                fieldName: 'participants',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.userLogin}</span>;
                },
                isPadded: true
            },
            {
                key: 'column9',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "conversationId")}</>)}</MyContext.Consumer>,
                fieldName: 'conversationId',
                minWidth: 200,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IDocument) => {
                    return <span>{item.conversationId}</span>;
                }
            },
            {
                key: 'column10',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "media")}</>)}</MyContext.Consumer>,
                fieldName: 'media',
                minWidth: 80,
                maxWidth: 80,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IDocument) => {
                    if (item.data.isVideo) {
                        return (
                            <Stack.Item style={{ padding: "0px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Video' title="Video" />
                            </Stack.Item>
                        );
                    }
                    else {
                        return (<Stack horizontal align="start" style={{ padding: "0px" }} tokens={{ childrenGap: '10' }}>
                            {item.data.hasAudio && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Microphone' title="Audio" />
                            </Stack.Item>}
                            {item.data.hasScreenSharing && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ScreenCast' title="Screen share" />
                            </Stack.Item>}
                            {item.data.hasVideo && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Video' title="Video" />
                            </Stack.Item>}
                            {item.data.hasChat && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Chat' title="Chat" />
                            </Stack.Item>}
                            {item.data.hasFiles && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Attach' title="Files" />
                            </Stack.Item>}
                        </Stack>);
                    }
                }
            },
            {
                key: 'column11',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "transcript")}</>)}</MyContext.Consumer>,
                fieldName: 'transcription',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                isPadded: true
            }];

        if (this.props.data.kpi === "Export" || this.props.data.valueType === "storageAudio" || this.props.data.valueType === "storageVideo" || this.props.data.valueType === "storage") {
            columns.push(
                {
                    key: 'column12',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "size")}</>)}</MyContext.Consumer>,
                    fieldName: 'size',
                    minWidth: 70,
                    maxWidth: 70,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    isPadded: true,
                    onRender: (item: IDocument) => {
                        return <span>{parseFloat(item.size).toFixed(2)}</span>;
                    }
                });
        }

        if (this.props.data.kpi === "Export") {
            columns.push(
                {
                    key: 'column13',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "notExportedError")}</>)}</MyContext.Consumer>,
                    fieldName: 'errorCode',
                    minWidth: 200,
                    maxWidth: 300,
                    isResizable: true,
                    data: 'string',
                    onColumnClick: this._onColumnClick,
                    isPadded: true,
                    onRender: (item: IDocument) => {
                        return <span>{this.generateFriendlyErrorCode(item.errorCode)}</span>;
                    }
                });
        }
        if (this.props.data.kpi === "NotImported") {
            columns.push(
                {
                    key: 'column13',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "notImportedError")}</>)}</MyContext.Consumer>,
                    fieldName: 'errorCode',
                    minWidth: 200,
                    maxWidth: 300,
                    isResizable: true,
                    data: 'string',
                    onColumnClick: this._onColumnClick,
                    isPadded: true,
                    onRender: (item: IDocument) => {
                        return <span>{this.generateFriendlyErrorCode(item.errorCode)}</span>;
                    }
                });
        }
        if (this.props.data.kpi === "Export" || this.props.data.kpi === "NotImported") {
            columns.push(
                {
                    key: 'column14',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "errorDescription")}</>)}</MyContext.Consumer>,
                    fieldName: 'errorMessage',
                    minWidth: 600,
                    maxWidth: 700,
                    isResizable: true,
                    data: 'string',
                    onColumnClick: this._onColumnClick,
                    isPadded: true,
                    onRender: (item: IDocument) => {
                        return <span>{item.errorMessage}</span>;
                    }
                });
        }
        if(this.props.data.kpi === "PolicyCompared"){
            columns.push({
                key:'column15',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "hit")}</>)}</MyContext.Consumer>,
                fieldName: 'Hit Policy',
                minWidth: 600,
                maxWidth: 700,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item)=> {
                    console.log(item.data.policyComparedHit)
                    return <span>{item.data.policyComparedHit ? "Yes" : "No"}</span>
                }
            })
        }
        if(this.props.data.kpi === "CategoryCompared"){
            columns.push({
                key:'column16',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "hit")}</>)}</MyContext.Consumer>,
                fieldName: 'Hit Category',
                minWidth: 600,
                maxWidth: 700,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item)=> {
                    console.log(item.data.categoryComparedHit)
                    return <span>{item.data.categoryComparedHit ? "Yes" : "No"}</span>
                }
            })
        }

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            items: this._allItems,
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: false,
            isCompactMode: false,
            isDialogOpen: false,
            total: this.total,
            totalSize: this.totalSize,
            totalTranscript: this.totalTranscript
        };

    }

    _onItemInvoked = (item) => {
        this.detailContainerRef.current.showPanelItem(item.conversationId, item.data)
    }

    _onExport = (ev) => {
        var itemList = [];
        itemList.push(config.sheet.data[0].slice());
        if(this.props.data.kpi == "PolicyCompared") itemList[0].push({value: 'PolicyCompared Hit', type: 'string'})
        if(this.props.data.kpi == "CategoryCompared") itemList[0].push({value: 'CategoryCompared Hit', type: 'string'})
        this.state.items.forEach((anItem) => {
            itemList.push(
                [
                    {
                        value: anItem.conversationId,
                        type: 'string'
                    }, {
                        value: anItem.conversationType,
                        type: 'string'
                    }, {
                        value: anItem.topic,
                        type: 'string'
                    }, {
                        value: anItem.date,
                        type: 'string'
                    }, {
                        value: anItem.starttime,
                        type: 'string'
                    }, {
                        value: anItem.duration,
                        type: 'string'
                    }, {
                        value: anItem.participants,
                        type: 'string'
                    }, {
                        value: anItem.userLogin,
                        type: 'string'
                    }, {
                        value: anItem.userId,
                        type: 'string'
                    }, {
                        value: anItem.data.hasAudio ? 1 : 0,
                        type: 'number'
                    }, {
                        value: anItem.data.hasVideo ? 1 : 0,
                        type: 'number'
                    }, {
                        value: anItem.data.hasScreenSharing ? 1 : 0,
                        type: 'number'
                    }, {
                        value: anItem.size,
                        type: 'number'
                    }, {
                        value: anItem.transcription,
                        type: 'number'
                    }, {
                        value: anItem.errorMessage,
                        type: 'string'
                    }
                ])
            if(this.props.data.kpi == "PolicyCompared") itemList[itemList.length-1].push({
                value: anItem.policyComparedHit, type: 'string'
            })
            if(this.props.data.kpi == "CategoryCompared") itemList[itemList.length-1].push({
                value: anItem.categoryComparedHit, type: 'string'
            })
        });
        config.sheet.data = itemList;
        zipcelx(config)
        this.setState({ isDialogOpen: true });
    }


    _dialogClose = () => this.setState({ isDialogOpen: false })

    render() {
        const { columns, items, isModalSelection } = this.state;

        return (
            <MyContext.Consumer>
                {(context) => (<Fabric>
                    <DetailViewOverlay authorization={this.props.authorization} client={this.props.apol} ref={this.detailContainerRef} loading={true} userRules={this.props.userRules} theme={this.props.theme} />
                    <Dialog
                        isOpen={this.state.isDialogOpen}
                        type={DialogType.close}
                        onDismiss={this._dialogClose.bind(this)}
                        title='Excel export'
                        subText='Excel file created and downloaded to your favourite directory'
                        isBlocking={false}
                        closeButtonAriaLabel='Close'
                    />
                    <header className="report-panel-header ms-Grid-row" style={{ boxShadow: Depths.depth8 }}>
                        {/* == Recording Header ========================================= */}

                        <Stack horizontal>

                            <Text style={{ padding: "5px" }} >{this.props.data.title}</Text>
                            <Text style={{ padding: "5px" }} >{context.getTranslation("dashboard", this.props.data.kpi)}</Text>
                            <Text style={{ padding: "5px" }} >{this.props.data.kpi === "Export" ? this.state.total + ", " + parseInt(this.state.totalSize) + " MB, " + this.state.totalTranscript : ""}</Text>

                            <Stack.Item grow={2} ><div /></Stack.Item>

                            <SearchBox
                                placeholder="Search"
                                onChange={(event, newValue) => {
                                    if (event != null) event.persist();
                                    this.setState({
                                        items: newValue ? this._allItems.filter(i => (i.topic.toLowerCase().indexOf(newValue.toLowerCase()) > -1 || i.transcription.toLowerCase().indexOf(newValue.toLowerCase()) > -1 || i.notes.toLowerCase().indexOf(newValue.toLowerCase()) > -1)) : this._allItems
                                    });
                                }}
                            />
                            <CommandBarButton
                                iconProps={{ iconName: 'Download' }}
                                text="Export Report"
                                style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                                onClick={this._onExport}
                            />
                        </Stack>
                    </header>
                    <div className="report-details-wrapper">

                        {/* == Player and meta data. ===================================== */}

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className="ms-Grid-row">



                                </div>
                                <MarqueeSelection selection={this._selection}>
                                    <DetailsList
                                        items={items}
                                        columns={columns}
                                        selectionMode={isModalSelection ? SelectionMode.multiple : SelectionMode.none}
                                        setKey="set"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        selection={this._selection}
                                        selectionPreservedOnEmptyClick={true}
                                        onActiveItemChanged={this._onItemInvoked}
                                        enterModalSelectionOnTouch={true}
                                        ariaLabelForSelectionColumn="Toggle selection"
                                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    />
                                </MarqueeSelection>
                            </div> {/* end of main contenten */}
                        </div>
                    </div>
                </Fabric>)
                }
            </MyContext.Consumer>);

    }
    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {

        this.setState({
            items: text ? this._allItems.filter(i => i.topic.toLowerCase().indexOf(text) > -1) : this._allItems
        });
    };

    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
            default:
                return `${selectionCount} items selected`;
        }
    }

    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({
            items: text ? this._allItems.filter(i => i.topic.toLowerCase().indexOf(text) > -1) : this._allItems
        });
    };

    _generateDocuments = (DataArr) => {
        const items = [];
        if (DataArr !== undefined) {
            this.totalSize = 0;
            this.totalTranscript = 0;
            this.total = 0;
            for (let i = 0; i < DataArr.length; i++) {
                let participants = "";
                if (DataArr[i].linkedUser != null) {
                    participants += DataArr[i].linkedUser.length;
                    DataArr[i].linkedUser.map((item, i) => {
                        participants += " , " + item.name;
                    });
                }
                items.push({
                    id: DataArr[i].id,
                    conversationType: DataArr[i].recordingType,
                    topic: DataArr[i].topic != null ? DataArr[i].topic : "",
                    conversationDirection: DataArr[i].recordingType,
                    conversationId: DataArr[i].conversationId,
                    date: DataArr[i].day,
                    dateValue: DataArr[i].callStartMs,
                    starttime: DataArr[i].timein,
                    duration: DataArr[i].duration,
                    userId: DataArr[i].userId,
                    userLogin: DataArr[i].userLogin,
                    botAppId: DataArr[i].botAppId,
                    participants: participants,
                    notes: DataArr[i].notes != null ? DataArr[i].notes : "",
                    transcription: DataArr[i].speech2Text != null ? DataArr[i].speech2Text : 0,
                    size: DataArr[i].size,
                    exportComment: DataArr[i].exportComment,
                    data: DataArr[i],
                    errorMessage: DataArr[i].errorMessage,
                    errorCode: DataArr[i].errorCode
                });
                if(this.props.data.kpi == "PolicyCompared") items[items.length-1].policyComparedHit=DataArr[i].policyComparedHit
                if(this.props.data.kpi == "CategoryCompared") items[items.length-1].categoryComparedHit=DataArr[i].categoryComparedHit
                this.total++;
                this.totalSize += DataArr[i].size;
                this.totalTranscript += parseInt(DataArr[i].speech2Text);
            }
        }
        return items;
    }

    generateFriendlyErrorCode = (errCode) => {
        switch (errCode) {
            case "UNKNOWN":
                return "Unknown";
            case "FILE_NOT_FOUND":
                return "File not found";
            case "COULD_NOT_IMPORT_CONV":
                return "Could not import conversation";
            case "EXCEPTION":
                return "Exception"
            case "COULD_NOT_IMPORT_METADATA":
                return "Could not import metadata";
            case "CONV_EXISTS_ALREADY":
                return "Conversation already exists";
            case "USER_NOT_FOUND":
                return "User not found";
            case "INVALID_FORMAT":
                return "Invalid format";
            case "MEDIA_PROCESSING":
                return "Media processing";
            case "DATABASE_ERROR":
                return "Database error";
            case "FILE_TRANSFER":
                return "File transfer";
            case "PENDING":
                return "Pending";
            default: return ""
        }
    }
}



function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}




