import React, { Component } from 'react'
import { MyContext } from '../../../../context';
import RestrictedAccessForm from '../RestrictedAccessForm';
import { ChoiceGroup, Text, Stack } from "office-ui-fabric-react";
import { FontWeights } from 'office-ui-fabric-react/lib';

class ConversationSafeAccessRule extends Component {

    static contextType = MyContext;

    constructor(props) {
        super(props)

        if (this.props.conversationSafeAccess !== null) {
            this.state = {
                accUserSafe: this.props.conversationSafeAccess.accUserSafe,
                restrictedAccess: this.props.conversationSafeAccess.restrictedAccess
            }
        } else {
            this.state = {
                accUserSafe: 'No',
                restrictedAccess: {
                    ownFolder: {}
                }
            }
            this.props.updateConversationSafeAccess(this.state)
        }
    }


    render() {
        return (
            <div>
                <Text variant="large" block>{this.context.getTranslation("configuration", "conversationSafe")}</Text>

                <ChoiceGroup
                    selectedKey={this.state.accUserSafe}
                    options={[
                        {
                            key: 'No',
                            text: this.context.getTranslation("configuration", "noAccess"),
                            'data-automation-id': 'auto1',
                            styles: { field: { fontWeight: FontWeights.regular } }
                        },
                        {
                            key: 'Full',
                            text: this.context.getTranslation("configuration", "fullAccess"),
                            styles: { field: { fontWeight: FontWeights.regular } }
                        },
                        {
                            key: 'Restricted',
                            text: this.context.getTranslation("configuration", "ownConversationsFolder"),
                            styles: { field: { fontWeight: FontWeights.regular } }
                        }
                    ]}
                    onChange={(event, newValue) => {
                        let state = {
                            accUserSafe: newValue.key,
                        }
                        let conversationSafeAccess = {}
                        if (newValue.key === "No" || newValue.key === "Full") {
                            state = {
                                ...state,
                                restrictedAccess: {
                                    ownFolder: {}
                                }
                            }
                            conversationSafeAccess = state
                        } else {
                            conversationSafeAccess = {
                                accUserSafe: newValue.key,
                                restrictedAccess: this.state.restrictedAccess
                            }
                        }
                        this.setState({
                            ...state
                        });
                        this.props.updateConversationSafeAccess(conversationSafeAccess)
                    }}
                    label={this.context.getTranslation("configuration", "accessToConversationSafe")}
                />

                {this.state.accUserSafe === 'Restricted' &&
                    <Stack vertical>
                        <Stack.Item styles={{ root: { padding: '0px' } }}>
                            <RestrictedAccessForm
                                data={this.props.conversationSafeAccess}
                                updateRestrictedAccess={(rest) => {
                                    this.setState({
                                        restrictedAccess: rest
                                    })
                                    let conversationSafeAccess = {
                                        accUserSafe: this.state.accUserSafe,
                                        restrictedAccess: rest
                                    }
                                    this.props.updateConversationSafeAccess(conversationSafeAccess)
                                }
                                }
                            />
                        </Stack.Item>
                    </Stack>}
            </div>
        )
    }
}

export default ConversationSafeAccessRule