import React from 'react';
import { DefaultButton, Button, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Panel, PanelType, Separator, Label, ComboBox, SelectableOptionMenuItemType, TextField, Checkbox, Dropdown, Stack, ChoiceGroup, Spinner } from 'office-ui-fabric-react/lib/';
import gql from 'graphql-tag';
import './ReportConfiguration.scss';
import { mergeStyleSets } from '@uifabric/merge-styles';
import UserPicker from './UserPicker';
import AuditPicker from './AuditPicker';
import { MyContext } from '../../../context';
import { queryReportData, checkMailForDomainRestrictions, getAvailableExportJobList, getAvailableImportJobList } from "../../../queries/queryReport";
import { toast } from 'react-toastify';
import { getAllCategoryVersions } from "../../../queries/queryCategory";
import { Query } from "react-apollo";
import DateTimePicker from 'react-datetime-picker';
import { queryAllPolicyVersions } from '../../ComplianceView/Policy/PolicyQuery';

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        {
            flex: '1 1 auto',
            borderTop: '4px solid blue',
            color: 'black',
            display: 'flex',
            fontSize: '12px',
            alignItems: 'center',
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0',
            },
            'p:first-child': {
                marginTop: 0,
            },
            'p:last-child': {
                marginBottom: 0,
            },
        },
    },
});




export default class ReportConfiguration extends React.Component {
    constructor(props) {
        super(props);


        this.allowedTenantsList = [];
        this.categoriesList = [];
        this.categoriesListSource = [];
        this.jobsList = [];
        this.jobsListSource = [];
        this.reportConf = React.createRef();

        this.state = this.setStateFromReport(props)

        this.getAvailableTenantsList = () => {
            let allowedTenantsList = []
            if (this.props.userRules.allowedTenants != null) {
                for (let x = 0; x < this.props.userRules.allowedTenants.length; x++) {
                    allowedTenantsList.push({ key: this.props.userRules.allowedTenants[x].id, text: this.props.userRules.allowedTenants[x].name });
                }
            }
        return allowedTenantsList
        }

        this.getKpiList = (context) => {
            //set hidden elements as "divider" to be able to hide them without deleting
            let kpiList =
                [
                    { key: 'CI', text: context.getTranslation("dashboard", "CI"), itemType: SelectableOptionMenuItemType.Divider },
                    { key: 'CO', text: context.getTranslation("dashboard", "CO"), itemType: SelectableOptionMenuItemType.Divider },
                    { key: 'CM', text: context.getTranslation("dashboard", "CM"), itemType: SelectableOptionMenuItemType.Divider },
                    { key: 'CE', text: context.getTranslation("dashboard", "CE"), itemType: SelectableOptionMenuItemType.Divider },

                    { key: 'Header1', text: context.getTranslation("dashboard", "recording"), itemType: SelectableOptionMenuItemType.Header },
                    { key: 'CT', text: context.getTranslation("dashboard", "CT") },
                    { key: 'CTA', text: context.getTranslation("dashboard", "CTA") },

                ];
            if (this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.confAuditLogs) {
                kpiList.push(...[
                    { key: 'divider1', text: '-', itemType: SelectableOptionMenuItemType.Divider },
                    { key: 'Header3', text: context.getTranslation("dashboard", "audit"), itemType: SelectableOptionMenuItemType.Header },
                    { key: "CC", text: context.getTranslation("dashboard", "CC") },
                    { key: 'AU', text: context.getTranslation("dashboard", "AU") },
                    { key: 'CNR', text: context.getTranslation("dashboard", "CNR") },
                    { key: 'ActiveUser', text: context.getTranslation("dashboard", "ActiveUser") },
                    { key: 'UserCheck', text: context.getTranslation("dashboard", "UserCheck") },
                    { key: 'HealthCheck', text: context.getTranslation("dashboard", "HealthCheck") },
                    { key: 'Export', text: context.getTranslation("dashboard", "Export") },
                    { key: 'Exported', text: context.getTranslation("dashboard", "Exported") },
                    { key: 'NotImported', text: context.getTranslation("dashboard", "NotImported") },
                    { key: 'Imported', text: context.getTranslation("dashboard", "Imported") }

                ]);
            }
            if (this.props.userRules.ruleAnalytics != null) {
                kpiList.push(...[
                    { key: 'divider2', text: '-', itemType: SelectableOptionMenuItemType.Divider },
                    { key: 'Header4', text: context.getTranslation("dashboard", "analytics"), itemType: SelectableOptionMenuItemType.Header },
                    { key: 'AK', text: context.getTranslation("dashboard", "AK") },
                    { key: 'AC', text: context.getTranslation("dashboard", "AC") },
                ]);
            }
            if (this.props.userRules.ruleAnalytics != null
                && (this.props.userRules?.ruleAccess?.complianceResultsAccess ?? false)
                && (this.props.userRules?.addOnCompliance ?? false)) {
                kpiList.push(...[
                    { key: 'AICategorizationKPI', text: context.getTranslation("dashboard", "AICategorizationKPI") }
                ]);
                kpiList.push(...[
                    { key: 'Policies', text: context.getTranslation("dashboard", "Policies") }
                ]);
                kpiList.push(...[
                    { key: 'PolicyCompared', text: context.getTranslation("dashboard", "PolicyCompared") }
                ])
            }
            //ask/check if it Category Compared options have conditions to appear in list
            kpiList.push(...[
                { key: 'CategoryCompared', text: context.getTranslation("dashboard", "CategoryCompared") }
            ])

            if (this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.userKpiWallboard) {
                kpiList.push(...[
                    { key: 'divider3', text: '-', itemType: SelectableOptionMenuItemType.Divider },
                    { key: 'Header5', text: context.getTranslation("dashboard", "wallboard"), itemType: SelectableOptionMenuItemType.Header },
                    //{ key: "ActiveAgents", text: context.getTranslation("dashboard", "ActiveAgents") },
                    { key: "AgentsOnCall", text: context.getTranslation("dashboard", "AgentsOnCall") },
                    { key: "LiveInbound", text: context.getTranslation("dashboard", "LiveInbound") },
                    { key: "LiveTotal", text: context.getTranslation("dashboard", "LiveTotal") },
                    { key: "AgentTalkTime", text: context.getTranslation("dashboard", "AgentTalkTime") },
                    //{ key: "Answertime", text: "Average Answertime" },
                    //{ key: "AnsweredCalls", text: "Percentage Answered Calls" },
                    //{ key: "MissedCalls", text: "Percentage Missed Calls" },
                    //{ key: "CallQueue", text: "Calls in Call Queue" },
                    //{ key: "CallsMainLine", text: "Calls Main Line" },
                    //{ key: "Summary", text: context.getTranslation("dashboard", "Summary") },
                ]);
            }
            if (this.props.userRules.ruleAccess != null && this.props.userRules.access === "SuperUser") {
                kpiList.push(...[
                    { key: 'divider4', text: '-', itemType: SelectableOptionMenuItemType.Divider },
                    { key: 'Header6', text: context.getTranslation("dashboard", "globalView"), itemType: SelectableOptionMenuItemType.Header },
                    { key: 'GCT', text: context.getTranslation("dashboard", "GCT") },
                    { key: 'GCTA', text: context.getTranslation("dashboard", "GCTA") },
                    { key: 'GCNR', text: context.getTranslation("dashboard", "GCNR") },
                    { key: "GCC", text: context.getTranslation("dashboard", "GCC") },
                    { key: "GlobalActiveUser", text: context.getTranslation("dashboard", "GlobalActiveUser") },
                ]);
            }
            return kpiList;
        }
    }

    setStateFromReport = (props) => {
        let categoriesList = [];
        let categoriesListSource = [];
        if (this.props.categories != null) {
            categoriesListSource = this.props.categories.split(",");
            for (let x = 0; x < categoriesListSource.length; x++) {
                categoriesList.push({
                    key: categoriesListSource[x],
                    id: categoriesListSource[x]
                }
                );
            }

        }
        if (this.props.jobId != null) {
            this.jobsListSource = this.props.jobId.split(",");

            for (let x = 0; x < this.jobsListSource.length; x++) {
                this.jobsList.push({
                    key: this.jobsListSource[x],
                    text: this.jobsListSource[x]
                }
                );
            }

        }
      

        let conversationTypeList = [];
        if (props.conversationType !== null && props.conversationType !== "") {
            conversationTypeList = props.conversationType
        }
        return {
            isModalOpen: false,
            isChanged: false,
            changedTitle: props.title,
            changedKpi: props.kpi,
            changedKeyword: props.keywords,
            changedStartDate: (props.startDate !== '' && props.startDate !== '0001-01-01T00:00:00') ? new Date(props.startDate) : '',
            changedEndDate: (props.endDate !== '' && props.endDate !== '0001-01-01T00:00:00') ? new Date(props.endDate) : '',
            changedCycle: props.cycle,
            changedSortOrder: props.sortOrder,
            changedType: props.type,
            changedWidget: props.widget,
            tType: props.type,
            tForm: props.tForm,
            tValue: props.valueType,
            tenantId: props.tenantId,
            botAppId: props.botAppId,
            alarming: props.alarming != null ? true : false,
            alarmingMail: props.alarming != null ? props.alarming.mail : "",
            alarmingEvent: props.alarming != null ? props.alarming.eventType : "",
            alarmingValue: props.alarming != null ? props.alarming.value : "",
            domainCheck: "",
            saveDomainCheck: false,
            sel4list: categoriesList,
            selectedOptionKeys: categoriesListSource,
            reportSend: props.reportSend != null ? props.reportSend : false,
            mailDate: (props.jobType != null && props.jobType.jobStartTime !== '' && props.jobType.jobStartTime !== '0001-01-01T00:00:00') ? new Date(props.jobType.jobStartTime) : '',
            mailMonth: props.jobType != null ? props.jobType.intervalMonth : 0,
            mailDay: props.jobType != null ? props.jobType.intervalDay : 0,
            mailHour: props.jobType != null ? props.jobType.intervalHour : 0,
            mailMinute: props.jobType != null ? props.jobType.intervalMinute : 0,
            reportMail: props.reportMail,
            conversationType: conversationTypeList,
            showAllVersionPolicies: true,
            showAllVersionCategories: true,
            user: (props.persons !== null && props.persons !== undefined) ? props.persons : [],
            jobsList: this.jobsList,
            selectedJobs: this.jobsListSource,
           

        }
    }

    styles = {
        root: {
            paddingTop: "20px"
        }
    };

    getWidgetOption = (context) => {
        switch (this.state.changedKpi) {
            default:
                return [
                    { key: 'Number', text: context.getTranslation("dashboard", "number"), iconProps: { iconName: 'NumberField' } }
                ];
            case 'CI':
            case 'CO':
            case 'CM':
            case 'CE':
            case 'CT':
            case 'GCT':
            case 'GCNR':
            case 'CIA':
            case 'COA':
            case 'CTA':
            case 'CMA':
            case 'CNR':
            case 'CEA':
            case 'GCTA':
            case 'Export':
            case 'NotImported':
            case 'Imported':
                return [
                    { key: 'Number', text: context.getTranslation("dashboard", "number"), iconProps: { iconName: 'NumberField' } },
                    { key: 'SplineChart', text: context.getTranslation("dashboard", "spline"), iconProps: { iconName: 'SplitObject' } },
                    { key: 'SimpleBarChart', text: context.getTranslation("dashboard", "simpleBar"), iconProps: { iconName: 'BarChartVertical' } },
                    { key: 'StackedBarChart', text: context.getTranslation("dashboard", "stackedBar"), iconProps: { iconName: 'BarChartVerticalFill' } },
                    //{ key: 'TreeMap', text: context.getTranslation("dashboard", "treeMap"), iconProps: { iconName: 'Tiles' }, disabled: false },
                    { key: 'DoughnutChart', text: context.getTranslation("dashboard", "doughnut"), iconProps: { iconName: 'PieDouble' }, },
                    { key: 'PieChart', text: context.getTranslation("dashboard", "pie"), iconProps: { iconName: 'PieSingle' }, },
                ];

            case 'CC':
            case 'GCC':
            case 'LiveInbound':
            case 'LiveTotal':
            case 'AgentsOnCall':
                return [
                    { key: 'Number', text: context.getTranslation("dashboard", "number"), iconProps: { iconName: 'NumberField' } },
                    { key: 'SplineChart', text: context.getTranslation("dashboard", "spline"), iconProps: { iconName: 'SplitObject' } },
                    { key: 'StackedBarChart', text: context.getTranslation("dashboard", "stackedBar"), iconProps: { iconName: 'BarChartVerticalFill' } },
                ];
            case 'AK':
            case 'AC':
            case 'Policies':
                return [
                    { key: 'Number', text: context.getTranslation("dashboard", "number"), iconProps: { iconName: 'NumberField' } },
                    { key: 'SplineChart', text: context.getTranslation("dashboard", "spline"), iconProps: { iconName: 'SplitObject' } },
                    { key: 'DoughnutChart', text: context.getTranslation("dashboard", "doughnut"), disabled: false, iconProps: { iconName: 'PieDouble' } },
                    { key: 'PieChart', text: context.getTranslation("dashboard", "pie"), disabled: false, iconProps: { iconName: 'PieSingle' } },
                    //{ key: 'TreeMap', text: context.getTranslation("dashboard", "treeMap"), iconProps: { iconName: 'Tiles' } }
                ];
            case 'PolicyCompared':
                return [
                    { key: 'PieChart', text: context.getTranslation("dashboard", "pie"), disabled: false, iconProps: { iconName: 'PieSingle' } },
                    { key: 'SplineChart', text: context.getTranslation("dashboard", "spline"), iconProps: { iconName: 'SplitObject' } },
                    { key: 'SimpleBarChart', text: context.getTranslation("dashboard", "simpleBar"), iconProps: { iconName: 'BarChartVertical' } },
                ]
            case 'CategoryCompared':
                return [
                    { key: 'PieChart', text: context.getTranslation("dashboard", "pie"), disabled: false, iconProps: { iconName: 'PieSingle' } },
                    { key: 'SplineChart', text: context.getTranslation("dashboard", "spline"), iconProps: { iconName: 'SplitObject' } },
                    { key: 'SimpleBarChart', text: context.getTranslation("dashboard", "simpleBar"), iconProps: { iconName: 'BarChartVertical' } },
                ]
            case 'AICategorizationKPI':
                return [
                    {
                        key: 'TagCloud',
                        text: context.getTranslation("dashboard", "tagCloud"),
                        iconProps: { iconName: 'Cloud' }
                    }]
            case 'ActiveAgents':
            case 'Answertime':
            case 'AnsweredCalls':
            case 'MissedCalls':
            case 'CallQueue':
            case 'CallsMainLine':
            case 'Summary':
            case 'ActiveUser':
                return [
                    { key: 'Number', text: context.getTranslation("dashboard", "number"), iconProps: { iconName: 'NumberField' } },
                    { key: 'SplineChart', text: context.getTranslation("dashboard", "spline"), iconProps: { iconName: 'SplitObject' } },
                    { key: 'SimpleBarChart', text: context.getTranslation("dashboard", "simpleBar"), iconProps: { iconName: 'BarChartVertical' } },
                    { key: 'StackedBarChart', text: context.getTranslation("dashboard", "stackedBar"), iconProps: { iconName: 'BarChartVerticalFill' } },
                    //{ key: 'TreeMap', text: context.getTranslation("dashboard", "treeMap"), iconProps: { iconName: 'Tiles' } },
                    { key: 'DoughnutChart', text: context.getTranslation("dashboard", "doughnut"), iconProps: { iconName: 'PieDouble' }, },
                    { key: 'PieChart', text: context.getTranslation("dashboard", "pie"), iconProps: { iconName: 'PieSingle' }, },
                ];
            case 'AU':
                return [
                    { key: 'Number', text: context.getTranslation("dashboard", "number"), iconProps: { iconName: 'NumberField' } },
                    { key: 'SplineChart', text: context.getTranslation("dashboard", "spline"), iconProps: { iconName: 'SplitObject' } },
                    { key: 'SimpleBarChart', text: context.getTranslation("dashboard", "simpleBar"), iconProps: { iconName: 'BarChartVertical' } },
                    { key: 'StackedBarChart', text: context.getTranslation("dashboard", "stackedBar"), iconProps: { iconName: 'BarChartVerticalFill' } },
                    { key: 'DoughnutChart', text: context.getTranslation("dashboard", "doughnut"), iconProps: { iconName: 'PieDouble' }, },
                    { key: 'PieChart', text: context.getTranslation("dashboard", "pie"), iconProps: { iconName: 'PieSingle' }, },
                ];
            case 'AgentTalkTime':
                return [
                    { key: 'Number', text: context.getTranslation("dashboard", "number"), iconProps: { iconName: 'NumberField' } },
                    { key: 'SimpleBarChart', text: context.getTranslation("dashboard", "simpleBar"), iconProps: { iconName: 'BarChartVertical' } },
                    { key: 'StackedBarChart', text: context.getTranslation("dashboard", "stackedBar"), iconProps: { iconName: 'BarChartVerticalFill' } },
                ];
        }
    }

    getTypeOptionKey = (widget, kpi) => {
        switch (kpi) {
            case 'AU':
            case 'CC':
            case 'AgentsOnCall':
            case 'LiveTotal':
            case 'LiveInbound':
            case 'ActiveAgents':
            case 'GCC':
                switch (widget) {
                    case 'Number':
                        return 'text';
                    case 'DoughnutChart':
                    case 'PieChart':
                    case 'SimpleBarChart':
                        return 'user';
                    case 'StackedBarChart':
                    case 'SplineChart':
                        return 'time';
                    default:
                        return null;
                }
            case 'AICategorizationKPI':
                return 'total';
            default:
                {
                    switch (widget) {
                        case 'Number':
                            return 'text';
                        case 'DoughnutChart':
                        case 'PieChart':
                        case 'SimpleBarChart':
                            return 'total';
                        case 'StackedBarChart':
                        case 'SplineChart':
                            return 'time';
                        case 'TreeMap':
                            return 'keymap';
                        default:
                            return null;
                    }
                }
        }
    }

    getTypeOption = (key, context, kpi) => {
        switch (kpi) {
            case 'PolicyCompared':
            case 'CategoryCompared': {
                switch (key) {
                    case 'SimpleBarChart':
                        return [
                            { key: 'user', text: context.getTranslation("dashboard", "user"), iconProps: { iconName: 'NumberField' } },
                            { key: 'total', text: context.getTranslation("dashboard", "total"), iconProps: { iconName: 'NumberField' } },
                        ]
                    case 'SplineChart':
                        return [
                            { key: 'time', text: context.getTranslation("dashboard", "time"), iconProps: { iconName: 'NumberField' } }]
                    case 'PieChart':
                        return [{ key: 'total', text: context.getTranslation("dashboard", "total"), iconProps: { iconName: 'NumberField' } }]
                }
                break
            }
            case 'AU': {
                switch (key) {
                    case 'Number':
                        return [{ key: 'text', text: context.getTranslation("dashboard", "number"), iconProps: { iconName: 'NumberField' } }]
                    case 'DoughnutChart':
                    case 'PieChart':
                    case 'SimpleBarChart':
                        return [
                            { key: 'user', text: context.getTranslation("dashboard", "user"), iconProps: { iconName: 'NumberField' } }]
                    case 'StackedBarChart':
                    case 'SplineChart':
                        return [
                            { key: 'time', text: context.getTranslation("dashboard", "time"), iconProps: { iconName: 'NumberField' } }]
                    default:
                        return [
                            { key: 'text', text: context.getTranslation("dashboard", "number"), disabled: true, iconProps: { iconName: 'NumberField' } },
                            { key: 'time', text: context.getTranslation("dashboard", "time"), iconProps: { iconName: 'NumberField' } },
                            { key: 'keymap', text: context.getTranslation("dashboard", "keymap"), iconProps: { iconName: 'NumberField' } },
                            { key: 'user', text: context.getTranslation("dashboard", "user"), iconProps: { iconName: 'NumberField' } },
                            { key: 'total', text: context.getTranslation("dashboard", "total"), iconProps: { iconName: 'NumberField' } }]
                }
            }
            case 'AICategorizationKPI':
                return [{ key: 'total', text: context.getTranslation("dashboard", "total"), iconProps: { iconName: 'NumberField' } }]
            default: {
                switch (key) {
                    case 'Number':
                        return [{ key: 'text', text: context.getTranslation("dashboard", "number"), iconProps: { iconName: 'NumberField' } }]
                    case 'DoughnutChart':
                    case 'PieChart':
                    case 'SimpleBarChart':
                        return [
                            { key: 'user', text: context.getTranslation("dashboard", "user"), iconProps: { iconName: 'NumberField' } },
                            { key: 'total', text: context.getTranslation("dashboard", "total"), iconProps: { iconName: 'NumberField' } }]
                    case 'StackedBarChart':
                    case 'SplineChart':
                        return [
                            { key: 'time', text: context.getTranslation("dashboard", "time"), iconProps: { iconName: 'NumberField' } }]
                    case 'TreeMap':
                        return [
                            { key: 'keymap', text: context.getTranslation("dashboard", "keymap"), iconProps: { iconName: 'NumberField' } }]
                    default:
                        return [
                            { key: 'text', text: context.getTranslation("dashboard", "number"), disabled: true, iconProps: { iconName: 'NumberField' } },
                            { key: 'time', text: context.getTranslation("dashboard", "time"), iconProps: { iconName: 'NumberField' } },
                            { key: 'keymap', text: context.getTranslation("dashboard", "keymap"), iconProps: { iconName: 'NumberField' } },
                            { key: 'user', text: context.getTranslation("dashboard", "user"), iconProps: { iconName: 'NumberField' } },
                            { key: 'total', text: context.getTranslation("dashboard", "total"), iconProps: { iconName: 'NumberField' } }]
                }
            }
        }
    }

    getValueOptionKey = (key) => {
        switch (key) {
            case 'CC':
            case 'GCC':
            case 'LiveInbound':
            case 'LiveTotal':
            case 'AgentsOnCall':
                return 'count'
            case 'AgentTalkTime':
                return 'duration'
            default:
                return 'count';
        }
    }

    getCycleOptionKey = (key, widget) => {
        switch (key) {
            case 'CC':
            case 'GCC':
            case 'LiveInbound':
            case 'AgentsOnCall':
            case 'HealthCheck':
            case 'LiveTotal':
                if (widget == 'Number')
                    return 'Current';
                else
                    return 'Today';
            default:
                return this.state.changedCycle;
        }
    }

    getWidgetOptionKey = (key) => {
        switch (key) {
            case 'CC':
            case 'GCC':
            case 'LiveInbound':
            case 'LiveTotal':
            case 'AgentsOnCall':
            case 'HealthCheck':
            case 'UserCheck':
            case 'AgentTalkTime':
                return 'Number'
            case 'AICategorizationKPI':
                return 'TagCloud';
            case "PolicyCompared":
                return "PieChart"
            case "CategoryCompared":
                return "PieChart"
            case "Policies":
                return "Number"
            default:
                return this.state.changedWidget;
        }
    }

    getValueOption = (key, context) => {
        switch (key) {
            case 'CI':
            case 'CO':
            case 'CM':
            case 'CE':
            case 'CT':
            case 'GCT':
                return [
                    { key: 'count', text: context.getTranslation("dashboard", "unitcount") },
                    /*{ key: 'countProcessed', text: context.getTranslation("dashboard", "unitcountProcessed") },
                    { key: 'countVideo', text: context.getTranslation("dashboard", "unitcountVideo") },
                    { key: 'countVideoProcessed', text: context.getTranslation("dashboard", "unitcountVideoProcessed") },*/
                    { key: 'duration', text: context.getTranslation("dashboard", "unitduration") },
                    /*{ key: 'storageVideo', text: context.getTranslation("dashboard", "unitstorageVideo") },
                    { key: 'storageAudio', text: context.getTranslation("dashboard", "unitstorageAudio") },
                    { key: 'storage', text: context.getTranslation("dashboard", "unitstorage") }*/
                ]
            case 'AgentTalkTime': return [
                { key: 'duration', text: context.getTranslation("dashboard", "unitduration") }]
            default:
                return [
                    { key: 'count', text: context.getTranslation("dashboard", "unitcount") }]
        }
    }

    getCycleOption = (key, widget, context) => {
        switch (key) {
            case 'CC':
            case 'GCC':
            case 'LiveInbound':
            case 'AgentsOnCall':
            case 'LiveTotal':
                if (widget == 'Number')
                    return [
                        { key: 'Current', text: context.getTranslation("dashboard", "Current") },
                    ];
                else
                    return [
                        { key: 'Today', text: context.getTranslation("dashboard", "Today") },
                        { key: 'Yesterday', text: context.getTranslation("dashboard", "Yesterday") },
                    ];
            default:
                return [
                    { key: 'Today', text: context.getTranslation("dashboard", "Today") },
                    { key: 'Yesterday', text: context.getTranslation("dashboard", "Yesterday") },
                    { key: 'Last24h', text: context.getTranslation("dashboard", "Last24h") },
                    { key: 'ThisWeek', text: context.getTranslation("dashboard", "ThisWeek") },
                    { key: 'LastWeek', text: context.getTranslation("dashboard", "LastWeek") },
                    { key: 'ThisMonth', text: context.getTranslation("dashboard", "ThisMonth") },
                    { key: 'LastMonth', text: context.getTranslation("dashboard", "LastMonth") },
                    { key: 'ThisQuarter', text: context.getTranslation("dashboard", "ThisQuarter") },
                    { key: 'LastQuarter', text: context.getTranslation("dashboard", "LastQuarter") },
                    { key: 'ThisYear', text: context.getTranslation("dashboard", "ThisYear") },
                    { key: 'LastYear', text: context.getTranslation("dashboard", "LastYear") },
                ];
        }
    }


    renderAvailableJobs = () => {
        let querySelection;

        //select what query to use so I don't need to make 2 same fucntions with same functionality for each query
        if (this.state.changedKpi === 'Exported' || this.state.changedKpi === 'Export') {
            querySelection = { query: getAvailableExportJobList, data: "getAvailableExportJobList" };
        } else if (this.state.changedKpi === 'Imported' || this.state.changedKpi === 'NotImported') {
            querySelection = { query: getAvailableImportJobList, data: "getAvailableImportJobList" };
        }


        return (
            <MyContext.Consumer>
                {(context) => (
                    <Query
                        query={querySelection.query}
                        variables={{}}
                    >
                        {({ loading, error, data }) => {
                            if (loading) {
                                return (
                                    <div>
                                        <Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />
                                    </div>
                                );
                            }
                            if (error) return <h4>Failed to connect</h4>;

                            if (data[querySelection.data] == null) {
                                return <div />;
                            }
                            this.tmpKey = [];
                            this.tenants = [];

                            if (querySelection.data != null) {
                                const availableJobs = data[querySelection.data];
                                //check how many tenants the user has access to
                                this.tenants = availableJobs.map(job => ({ key: job.tenantId, text: job.tenantId }));

                                //if user has access to only one tenant jobs will be directly displayed
                                let selectedTenantJobs = [];
                                if (this.tenants.length === 1) {
                                    selectedTenantJobs = availableJobs.find(tenant => tenant.tenantId === (this.tenants[0].key))?.jobList || [];
                                } else {//else user have to select tenant first

                                     selectedTenantJobs = availableJobs.find(tenant => tenant.tenantId === (this.state.tenantId))?.jobList || [];
                                }

                                this.tmpKey = selectedTenantJobs.map(job => ({ key: job.jobId, text: job.jobName }));


                            }


                            return <ComboBox
                                label={context.getTranslation("filterPanel", "jobId")}
                                placeholder={this.tmpKey.length > 0 ? context.getTranslation("filterPanel", "selectJobId") : context.getTranslation("filterPanel", "noAvailableJobs")}
                                selectedKey={this.state.selectedJobs}
                                allowFreeform={false}
                                onChange={this.onChangeJobs}
                                autoComplete={true}
                                options={this.tmpKey}
                                multiSelect={true}
                            />
                        }}
                    </Query>
                )
                }

            </MyContext.Consumer>
        );

    }
    render() {

        return (<MyContext.Consumer>
            {(context) => (

                <div>
                    <Panel
                        isOpen={this.state.isModalOpen}
                        onDismiss={this.hidePanel}
                        titleAriaId={this._titleId}
                        subtitleAriaId={this._subtitleId}
                        headerText={this.props.title !== "" ? context.getTranslation("dashboard", "changeReport") : context.getTranslation("dashboard", "createReport")}
                        closeButtonAriaLabel="Close"
                        type={PanelType.medium}
                    >

                        <div id={this._subtitleId} className={contentStyles.body} >
                            <Stack vertical>
                                <Stack.Item>
                                    <TextField
                                        label={context.getTranslation("dashboard", "title")}
                                        value={this.state.changedTitle}
                                        onChange={(event, newValue) => {
                                            if (newValue == "CC")
                                                this.setState({ changedCycle: "Current" });

                                            this.setState({
                                                changedTitle: newValue
                                            });
                                        }}
                                        errorMessage={this.state.changedTitle === '' ? "Set Title" : ""}
                                    />
                                </Stack.Item>

                                <Stack.Item>
                                    <ComboBox
                                        defaultSelectedKey={this.state.changedKpi}
                                        selectedKey={this.state.changedKpi}
                                        label={context.getTranslation("dashboard", "KPI")}
                                        onChange={(event, option, index, newValue) => {
                                            this.setState({
                                                showAllVersionPolicies: option.key === "AICategorizationKPI" ? false : true,
                                                changedWidget: this.getWidgetOptionKey(option.key),
                                                changedKpi: option.key,
                                                tValue: this.getValueOptionKey(option.key),
                                                tType: this.getTypeOptionKey(this.getWidgetOptionKey(option.key), option.key),
                                                changedCycle: this.getCycleOptionKey(option.key),
                                                selectedOptionKeys: [],
                                                sel4list: null,
                                                selectedJobs: [],
                                                jobList: null
                                            });
                                        }}
                                        options={this.getKpiList(context)}

                                    />

                                </Stack.Item>

                                <Stack.Item styles={{ root: { padding: '0px' } }}>
                                    <h5>{context.getTranslation("dashboard", "selectedUser")}</h5>
                                    <UserPicker
                                        placeholder={context.getTranslation("compliance", "selectUserOrGroup")}
                                        user={this.state.user}
                                        fc={(user) => {
                                            this.setState({
                                                user: user
                                            });
                                        }}
                                    />

                                </Stack.Item>

                                <Stack.Item>
                                    <ChoiceGroup
                                        label={context.getTranslation("dashboard", "widget")}
                                        options={this.getWidgetOption(context)}
                                        defaultSelectedKey={this.state.changedWidget}

                                        selectedKey={this.state.changedWidget}
                                        onChange={(event, option, index, newValue) => {
                                            this.setState({
                                                changedWidget: option.key,
                                                tType: this.getTypeOptionKey(option.key, this.state.changedKpi),
                                                changedCycle: this.getCycleOptionKey(this.state.changedKpi, option.key),
                                                alarming: option.key != "Number" ? false : this.state.alarming
                                            });
                                        }}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <ComboBox
                                        defaultSelectedKey={this.state.tType}
                                        selectedKey={this.state.tType}
                                        options={this.getTypeOption(this.state.changedWidget, context, this.state.changedKpi)}
                                        label={context.getTranslation("dashboard", "viewOption")}
                                        onChange={(ev, newVal) => {
                                            this.setState({
                                                tType: newVal.key
                                            });
                                        }}
                                    />
                                </Stack.Item>
                                {(this.state.changedKpi !== "AU" && this.state.changedKpi !== "AgentsOnCall" && this.state.changedKpi !== "LiveInbound" && this.state.changedKpi !== "LiveTotal" && this.state.changedKpi !== "AgentTalkTime" && this.state.changedKpi !== "CC" && this.state.changedKpi !== "GCC") && <Stack.Item>
                                    <Dropdown
                                        multiSelect
                                        placeholder="Select a type"
                                        selectedKeys={this.state.conversationType}
                                        options={[
                                            { key: 'Inbound', text: context.getTranslation("filterPanel", "inbound") },
                                            { key: 'Outbound', text: context.getTranslation("filterPanel", "outbound") },
                                            { key: 'Meeting', text: context.getTranslation("filterPanel", "meetings") },
                                            { key: 'Internal', text: context.getTranslation("filterPanel", "internal") },
                                            { key: 'Chat', text: context.getTranslation("filterPanel", "chat") },
                                            { key: 'Upload', text: context.getTranslation("filterPanel", "upload") },
                                            { key: 'SMS', text: context.getTranslation("filterPanel", "sms") }
                                            //{ key: 'Other', text: context.getTranslation("product", "other") }
                                        ]}
                                        onChange={(event, item) => {
                                            if (item) {
                                                this.setState({
                                                    conversationType: item.selected ? [...this.state.conversationType, item.key] : this.state.conversationType.filter(key => key !== item.key)
                                                });
                                            }
                                        }}
                                        autoComplete={true}
                                        allowFreeform={false}
                                        label={context.getTranslation("dashboard", "conversationType")}
                                    />
                                </Stack.Item>}
                                <Stack.Item>
                                    <ComboBox
                                        defaultSelectedKey={this.state.tValue}
                                        selectedKey={this.state.tValue}
                                        options={this.getValueOption(this.state.changedKpi, context)}
                                        onChange={(ev, newVal) => {
                                            this.setState({
                                                tValue: newVal.key
                                            });
                                        }}
                                        label={context.getTranslation("dashboard", "valueType")}
                                    />
                                </Stack.Item>
                                {this.props.userRules.allowedTenants != null && this.props.userRules.allowedTenants.length > 0 &&
                                    <Stack.Item>
                                        <ComboBox
                                            defaultSelectedKey={this.state.tenantId}
                                            selectedKey={this.state.tenantId}
                                            options={this.getAvailableTenantsList()}
                                            label={"TenantId"}
                                            onChange={(ev, newVal) => {
                                                this.setState({
                                                    tenantId: newVal.key
                                                });
                                            }}
                                        />
                                    </Stack.Item>
                                }
                                {this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.tenantCreate && <Stack.Item>
                                    <TextField value={this.state.botAppId} type="Text" label={"BotAppId"}
                                        onChange={(event) => { this.setState({ botAppId: event.target.value }) }}
                                    />
                                  
                                </Stack.Item>}

                                {this.props.userRules.ruleAccess != null && <Stack.Item>
                                    {(this.state.changedKpi === 'Exported' || this.state.changedKpi === 'Export' || this.state.changedKpi === 'Imported' || this.state.changedKpi === 'NotImported') && <>
                                        {this.renderAvailableJobs()}
                                    </>
                                    }
                                </Stack.Item>}

                                <Stack.Item>
                                    <ComboBox
                                        defaultSelectedKey={this.state.tForm}
                                        selectedKey={this.state.tForm}
                                        options={[{ key: 'cycle', text: context.getTranslation("dashboard", "cycle") }, { key: 'custom', text: context.getTranslation("dashboard", "custom") }]}
                                        onChange={(ev, newVal) => {
                                            this.setState({
                                                tForm: newVal.key
                                            });
                                        }}
                                        label={context.getTranslation("dashboard", "timeFormat")}
                                    />
                                </Stack.Item>

                                {this.selectForm()}

                                <Stack.Item>
                                    {this.renderKeyWordInput()}
                                </Stack.Item>
                                {!this.props.userRules.ruleAccess.denyReportAlarming && <Stack>
                                    {this.state.changedWidget == "Number" && <Stack.Item styles={this.styles}>
                                        <Checkbox
                                            label={context.getTranslation("dashboard", "alarmConfiguration")}

                                            checked={this.state.alarming}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    alarming: newValue
                                                });
                                            }}
                                        />
                                    </Stack.Item>}
                                    {this.state.alarming && <Stack.Item>
                                        <Stack.Item>
                                            <ComboBox
                                                defaultSelectedKey={this.state.alarmingEvent}
                                                selectedKey={this.state.alarmingEvent}
                                                options={[
                                                    { key: 'greaterThan', text: '>' },
                                                    { key: 'lowerThan', text: '<' },
                                                    { key: 'greaterThanOrEqual', text: '>=' },
                                                    { key: 'lowerThanOrEqual', text: '<=' },
                                                    { key: 'equalTo', text: '==' },
                                                    { key: 'change', text: '<>' }
                                                ]}
                                                onChange={(ev, newVal) => {
                                                    this.setState({
                                                        alarmingEvent: newVal.key
                                                    });
                                                }}
                                                label={context.getTranslation("filterPanel", "valueType")}
                                            />

                                        </Stack.Item>
                                        <Stack.Item>
                                            <TextField value={this.state.alarmingValue} type="Text" label={"Value"}
                                                onChange={(event) => {
                                                    this.setState({
                                                        alarmingValue: event.target.value
                                                    })
                                                }}
                                            />
                                        </Stack.Item>
                                    </Stack.Item>}

                                    {(this.state.alarming) && <Stack.Item>
                                        <TextField value={this.state.alarmingMail} type="Text" label={context.getTranslation("dashboard", "mail")}
                                            onChange={(event) => {
                                                this.setState({
                                                    alarmingMail: event.target.value,
                                                    domainCheck: ""
                                                })
                                            }}
                                        />
                                        <span id="mailError" style={{ "color": "red" }}></span>
                                        <br />

                                        {this.state.alarmingMail ? (
                                            <DefaultButton text={context.getTranslation("dashboard", "checkValidity")} onClick={(event) => { this.checkMailForDomainRestrictions(this.props.apol) }} />
                                        ) : (
                                            <DefaultButton text={context.getTranslation("dashboard", "checkValidity")} disabled />
                                        )}
                                    </Stack.Item>}

                                    {!this.props.userRules.ruleAccess.denyReportAlarming && this.jobType()}


                                </Stack>}
                                <Stack vertical horizontalAlign="center" >
                                    <Separator />

                                    {this.onRenderFooterContent()}

                                </Stack>
                            </Stack>

                        </div>

                    </Panel>
                </div>
            )
            }
        </MyContext.Consumer>

        );
    }

    renderKeyWordInput = () => {
        if (this.state.changedKpi === 'AK') {
            return (
                <MyContext.Consumer>
                    {(context) => (
                        <TextField
                            label={context.getTranslation("dashboard", "keyword")}
                            value={this.state.changedKeyword}
                            onChange={(event, newValue) => {
                                this.setState({
                                    changedKeyword: newValue
                                });
                            }}
                        />
                    )
                    }
                </MyContext.Consumer>
            );
        } else if (this.state.changedKpi === 'AC' || this.state.changedKpi === 'CategoryCompared') {
            return (
                <MyContext.Consumer>
                    {(context) => (
                        <Query
                            query={getAllCategoryVersions}
                            variables={{}}
                        >
                            {({ loading, error, data }) => {
                                if (loading) {
                                    return (
                                        <div>
                                            <Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />
                                        </div>
                                    );
                                }
                                if (error) return <h4>Failed to connect</h4>;

                                if (data.getAllCategoryVersions == null) {
                                    return <div />;
                                }
                                this.tmpKey = [];
                                this.tmpKey.push({ key: "Header1", text: "Categories", itemType: 2 });
                                if (data.getAllCategoryVersions.categorydata != null) {
                                    let dataFilter = data.getAllCategoryVersions.categorydata;
                                    if (!this.state.showAllVersionCategories) {
                                        dataFilter = data.getAllCategoryVersions.categorydata.filter(c => c.selected)
                                    }
                                    for (let x = 0; x < dataFilter.length; x++) {
                                        let i = {
                                            name: dataFilter[x].name + " - v" + dataFilter[x].version,
                                            fromCompliance: dataFilter[x].fromCompliance,
                                            text: dataFilter[x].name + " - v" + dataFilter[x].version,
                                            keywords: dataFilter[x].keywords,
                                            id: dataFilter[x].id,
                                            key: dataFilter[x].id,
                                            tenantId: dataFilter[x].tenantId,
                                            frequence: dataFilter[x].frequence,
                                            link: dataFilter[x].link,
                                            sentiments: dataFilter[x].sentiments,
                                            selectedDb: dataFilter[x].selected,
                                        };
                                        this.tmpKey.push(i);
                                    }
                                }
                                const allCategories = this.tmpKey
                                const allButComplianceCategories = allCategories.filter(e => e.fromCompliance == false)
                                return <div>
                                        {/*combobox for category selection*/}
                                        <ComboBox
                                            label={context.getTranslation("filterPanel", "categorySelection")}
                                            selectedKey={this.state.selectedOptionKeys}
                                            allowFreeform={false}
                                            onChange={this.state.changedKpi !== 'CategoryCompared' ? this.onChangeMulti : this.changeSingle}
                                            autoComplete={true}
                                            options={
                                                (this.props.userRules?.ruleAccess?.complianceResultsAccess ?? false)
                                                    && (this.props.userRules?.addOnCompliance ?? false) ? allCategories : allButComplianceCategories }
                                            multiSelect={this.state.changedKpi !== 'CategoryCompared'}
                                        />
                                        <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                label={context.getTranslation("compliance", "categoriesSelecionShowVersions")}
                                                checked={this.state.showAllVersionCategories}
                                                onChange={() => {
                                                    let showAllVersionCategories = !this.state.showAllVersionCategories
                                                    let optionIds = []
                                                    let optionsCategories = (this.props.userRules?.ruleAccess?.complianceResultsAccess ?? false)
                                                        && (this.props.userRules?.addOnCompliance ?? false) ? allCategories : allButComplianceCategories;
                                                    if (showAllVersionCategories) {
                                                        optionIds = optionsCategories.map(p => p.id);
                                                    } else {
                                                        optionIds = optionsCategories.filter(p => p.selectedDb).map(p => p.id);
                                                    }
                                                    let selectedOptionKeys = this.state.selectedOptionKeys.filter(p => optionIds.includes(p));
                                                    this.setState({
                                                        showAllVersionCategories,
                                                        selectedOptionKeys
                                                    })
                                                }}
                                            />
                                        </div>
                                </div>
                            }}
                        </Query>
                    )
                    }

                </MyContext.Consumer>
            );
        } else if (this.state.changedKpi === 'Policies' || this.state.changedKpi === 'PolicyCompared' || this.state.changedKpi === 'AICategorizationKPI') {
            return (
                <MyContext.Consumer>
                    {(context) => (
                        <Query
                            query={queryAllPolicyVersions}
                            variables={{}}
                        >
                            {({ loading, error, data }) => {
                                if (loading) { return <Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" /> }
                                if (error) return <h4>Failed to connect</h4>;
                                if (data.getAllPolicyVersions == null) {
                                    return <div />;
                                }
                                console.log(data.getAllPolicyVersions)
                                this.tmpKey = [];
                                this.tmpKey.push({ key: "Header1", text: context.getTranslation("compliance", "policiesSelecion"), itemType: 2 });
                                if (data.getAllPolicyVersions !== null && data.getAllPolicyVersions.length > 0) {
                                    let dataFilter = []
                                    if (this.state.showAllVersionPolicies) {
                                        dataFilter = data.getAllPolicyVersions
                                    } else {
                                        dataFilter = data.getAllPolicyVersions.filter(pol => pol.selected)
                                    }
                                    if (this.state.changedKpi === 'AICategorizationKPI') {
                                        dataFilter.filter(e => e.basedOn === "AICategorization").forEach(p => {
                                            let o = {
                                                name: p.name,
                                                text: p.name,
                                                key: p.id,
                                                id: p.id,
                                                selectedDB: p.selected
                                            }
                                            this.tmpKey.push(o);
                                        })
                                    }
                                    if (this.state.changedKpi === 'Policies' || this.state.changedKpi === 'PolicyCompared') {
                                        dataFilter.filter(e => e.basedOn === "PolicyRule").forEach(p => {
                                            let o = {
                                                name: p.name,
                                                text: p.name + " - v" + p.version,
                                                key: p.id,
                                                id: p.id,
                                                selectedDB: p.selected
                                            }
                                            this.tmpKey.push(o);
                                        })
                                    }
                                    dataFilter.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                }
                                return <div>
                                    {/*combobox for policy selection*/}
                                    {
                                        (
                                            this.state.changedKpi === 'Policies'
                                            || this.state.changedKpi === 'PolicyCompared'
                                            || this.state.changedKpi === 'AICategorizationKPI'
                                        ) &&
                                        <ComboBox
                                            label={context.getTranslation("filterPanel", "policySelection")}
                                            selectedKey={this.state.selectedOptionKeys}
                                            allowFreeform={false}
                                            onChange={this.state.changedKpi === 'Policies' ? this.onChangeMulti : this.changeSingle}
                                            autoComplete={true}
                                            options={this.tmpKey}
                                            multiSelect={this.state.changedKpi === 'Policies'}
                                        />}
                                    {(this.state.changedKpi === 'Policies' || this.state.changedKpi === 'PolicyCompared') &&
                                        <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                label={context.getTranslation("compliance", "policiesSelecionShowVersions")}
                                                checked={this.state.showAllVersionPolicies}
                                                onChange={() => {
                                                    let showAllVersionPolicies = !this.state.showAllVersionPolicies
                                                    let optionIds = []
                                                    if (showAllVersionPolicies) {
                                                        optionIds = this.tmpKey.map(p => p.id)
                                                    } else {
                                                        optionIds = this.tmpKey.filter(p => p.selectedDB).map(p => p.id)
                                                    }
                                                    let selectedOptionKeys = this.state.selectedOptionKeys.filter(p => optionIds.includes(p))
                                                    //thre is a mess with this field sel4list and also is bad that policies ids are saved in categories field in the db
                                                    let sel4list = this.tmpKey.filter(p => selectedOptionKeys.includes(p.id))
                                                    this.setState({
                                                        showAllVersionPolicies,
                                                        selectedOptionKeys,
                                                        sel4list
                                                    })
                                                }}
                                            />
                                        </div>}
                                </div>
                            }}
                        </Query>
                    )
                    }

                </MyContext.Consumer>
            );
        } else if (this.state.changedKpi === 'AU') {
            return (
                <MyContext.Consumer>
                    {(context) => (
                        <div>
                            {' '}

                            <Text variant="medium" block>{context.getTranslation("dashboard", "auditLogEvents")}</Text>
                            <AuditPicker events={this.state.changedKeyword} fc={(audit) => {
                                console.log(audit); this.setState({
                                    changedKeyword: audit
                                });
                            }} />
                        </div>
                    )
                    }
                </MyContext.Consumer>
            );
        }
    }

    selectForm = () => {
        switch (this.state.tForm) {
            case 'custom':
                return (
                    <MyContext.Consumer>
                        {(context) => (
                            <Stack.Item>
                                <div>{context.getTranslation("dashboard", "startDate")}</div>
                                <DateTimePicker
                                    locale={this.props.authorization.locale}
                                    calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}

                                    onChange={(date) => {
                                        if (date <= this.state.changedEndDate || this.state.changedEndDate === '') {
                                            console.log(`new Date: ${date}`);
                                            this.setState({
                                                changedStartDate: date,
                                                changedCycle: ''
                                            });
                                        }
                                    }}
                                    value={this.state.changedStartDate}
                                />
                                <div>{context.getTranslation("dashboard", "endDate")}</div>
                                <DateTimePicker
                                    locale={this.props.authorization.locale}
                                    calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}

                                    onChange={(date) => {
                                        if (date >= this.state.changedStartDate || this.state.changedStartDate === '') {
                                            console.log(`new Date: ${date}`);
                                            this.setState({
                                                changedEndDate: date,
                                                changedCycle: '',
                                            });
                                        }
                                    }}
                                    value={this.state.changedEndDate}
                                />
                            </Stack.Item>
                        )
                        }
                    </MyContext.Consumer>
                );


            case 'cycle':
                return (
                    <MyContext.Consumer>
                        {(context) => (
                            <Stack.Item>
                                <ComboBox
                                    defaultSelectedKey={this.state.changedCycle}
                                    selectedKey={this.state.changedCycle}
                                    label={context.getTranslation("dashboard", "cycle")}
                                    onChange={(event, option, index, newValue) => {
                                        this.setState({
                                            changedCycle: option.key,
                                            changedStartDate: '',
                                            changedEndDate: '',
                                        });
                                    }}
                                    options={this.getCycleOption(this.state.changedKpi, this.state.changedWidget, context)}
                                />
                            </Stack.Item>
                        )
                        }
                    </MyContext.Consumer>
                );
            default: return <div> </div>;
        }
    }

    jobType = () => {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Stack vertical>
                        {this.state.changedWidget == "Number" && <Stack.Item styles={this.styles}>
                            <Checkbox
                                label={context.getTranslation("configuration", "sendMail")}
                                checked={this.state.reportSend}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        reportSend: newValue
                                    });
                                }}
                            />
                        </Stack.Item>}
                        {this.state.reportSend && <Stack>
                            {/* start */}
                            <br />
                            <Stack.Item>
                                <Label>{context.getTranslation("configuration", "startTime")}</Label>

                                <DateTimePicker
                                    locale={this.props.authorization.locale}
                                    calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}

                                    firstDayOfWeek={1}
                                    value={this.state.mailDate}
                                    onChange={(date) => {
                                        console.log(date);
                                        this.setState({
                                            mailDate: date,
                                        });
                                    }
                                    }
                                />

                            </Stack.Item>
                            <Stack.Item>
                                <Label>{context.getTranslation("configuration", "cycle")}</Label>

                                <div style={{ diplay: "flex" }}>
                                    <TextField
                                        name="CycleTime"
                                        type="Number"
                                        label={context.getTranslation("configuration", "cycleMonth")}
                                        value={this.state.mailMonth}
                                        onChange={(event, newValue) => {
                                            console.log(newValue);
                                            this.setState({
                                                mailMonth: newValue,
                                                reportDomainCheck: ""
                                            })
                                        }}
                                    />

                                </div>

                                <div style={{ diplay: "flex" }}>
                                    <TextField
                                        name="CycleTime"
                                        type="Number"
                                        label={context.getTranslation("configuration", "cycleDay")}
                                        value={this.state.mailDay}
                                        onChange={(event, newValue) => {
                                            console.log(newValue);
                                            this.setState({
                                                mailDay: newValue,
                                                reportDomainCheck: ""
                                            })
                                        }}
                                    />

                                </div>

                                <div style={{ diplay: "flex" }}>
                                    <TextField
                                        name="CycleTime"
                                        type="Number"
                                        label={context.getTranslation("configuration", "cycleHour")}
                                        value={this.state.mailHour}
                                        onChange={(event, newValue) => {
                                            console.log(newValue);
                                            this.setState({
                                                mailHour: newValue,
                                                reportDomainCheck: ""
                                            })
                                        }}
                                    />

                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <TextField value={this.state.reportMail} type="Text" label={context.getTranslation("dashboard", "mail")}
                                    onChange={(event) => {
                                        this.setState({
                                            reportMail: event.target.value,
                                            reportDomainCheck: ""
                                        })
                                    }}
                                />
                                <span id="mailError" style={{ "color": "red" }}></span>
                                <br />

                                {this.state.reportMail ? (
                                    <DefaultButton text={context.getTranslation("dashboard", "checkValidity")} onClick={(event) => { this.checkReportMailForDomainRestrictions(this.props.apol, this.state.reportMail) }} />
                                ) : (
                                    <DefaultButton text={context.getTranslation("dashboard", "checkValidity")} disabled />
                                )}
                            </Stack.Item>
                        </Stack>}
                    </Stack>)}
            </MyContext.Consumer>
        );
    }

    onRenderFooterContent = () => {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Stack horizontal horizontalAlign="center" >

                        <Stack.Item>
                            <PrimaryButton onClick={this.savePanel} text={context.getTranslation("common", "save")} disabled={!this.isValid()} />
                        </Stack.Item>
                        {!this.props.isNew && <Stack.Item>
                            <Button onClick={this.deleteReport} text={context.getTranslation("common", "delete")} style={{ backgroundColor: 'red' }} />
                        </Stack.Item>}
                        <Stack.Item>
                            <DefaultButton onClick={this.resetState} text={context.getTranslation("common", "cancel")} />
                        </Stack.Item>
                    </Stack>
                )
                }
            </MyContext.Consumer>
        );
    };

    isValid = () => {
        let selectedOptionKeysCheck = (
            this.state.changedKpi === 'Policies' ||
            this.state.changedKpi === 'PolicyCompared' ||
            this.state.changedKpi === 'AICategorizationKPI' ||
            this.state.changedKpi === "CategoryCompared") ? this.state.selectedOptionKeys.length > 0 : true;
        if (
            (this.state.changedTitle != null && this.state.changedTitle.length > 0)
            && (this.state.changedKpi != null && this.state.changedKpi.length > 0)
            && (this.state.changedWidget !== undefined && this.state.changedWidget != null && this.state.changedWidget.length > 0)
            && (this.state.changedKeyword !== '' || this.state.changedKpi !== 'AK')
            && (!this.state.reportSend || (this.state.mailMonth > 0 || this.state.mailDay > 0 || this.state.mailHour > 0))
            && selectedOptionKeysCheck
        ) {

            if (this.state.tForm === 'cycle' && this.state.changedCycle.length > 0) {
                return true;
            } if (this.state.tForm === 'custom' && this.state.changedStartDate !== '' && this.state.changedEndDate !== '') {
                return true;
            }
            return false;
        }
        return false;
    }

    showModal = () => {
        //state is updated on open to refkect recent changes
        this.resetState();
        this.setState({ isModalOpen: true });
    };

    hidePanel = () => {
        this.resetState();
    };

    onChangeMulti = (event, option, index, value) => {
        let selected = option?.selected;
        if (option) {
            let selectedOptionKeys = selected ?
                [...this.state.selectedOptionKeys, option.key] :
                this.state.selectedOptionKeys.filter(k => k !== option.key)
            let sel4list = selectedOptionKeys.map(k => this.findKeys(k, this.tmpKey))
            this.setState({
                selectedOptionKeys,
                sel4list,
                filterDefaultEnabled: false,
                filterUserEnabled: true,

                updated: true
            });
        }
    };
    onChangeJobs = (event, option, index, value) => {
       
        const currentSelectedKeys = this.state.selectedJobs || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            let xx = [];
            for (let i = 0; i < tmp.length; i++) {
                xx.push(this.findKeys(tmp[i], this.tmpKey))
            }
            this.setState({
                selectedJobs: tmp,
                jobsList: xx,
                filterDefaultEnabled: false,
                filterUserEnabled: true,

                updated: true
            });
        }
    };
    updateSelectedOptionKeys = (selectedKeys, option): string[] => {
        selectedKeys = [...selectedKeys]; // modify a copy
        const index = selectedKeys.indexOf(option.key);
        if (option.selected && index < 0) {
            selectedKeys.push(option.key);
        } else {
            selectedKeys.splice(index, 1);
        }
        return selectedKeys;
    };


    changeSingle = (event, option, index, value) => {
        if (option) {
            let key = option?.key;
            this.setState({
                selectedOptionKeys: [key],
                sel4list: [option],
                filterDefaultEnabled: false,
                filterUserEnabled: true,

                updated: true
            });
        }
    }

    findKeys = (k, options) => {
        let out;
        for (let x = 0; x < options.length; x++) {
            if (k === options[x].key) {
                out = options[x];
                break;
            }
        }
        return out;
    }


    deleteReport = () => {
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($input: AuthInput, $id : String!) {
                    deleteReport (input: $input, id: $id) {
                        id
                    }
                }
            `,
                variables: { id: this.props.id, input: this.props.input },
            })
            .then((result) => {
                window.location.href = '/dashboard/';
                console.log(result);
            });
    }

    savePanel = () => {
        // Collect persons
        const persons = [];
        if (this.state.user != null) {
            for (let x = 0; x < this.state.user.length; x++) {
                const person = {
                    userId: this.state.user[x].userId,
                };
                persons.push(person);
            }
        }
        var alarming = this.state.alarming == true
            ? { mail: this.state.alarmingMail, eventType: this.state.alarmingEvent, value: this.state.alarmingValue }
            : null;

        //Check if alarm email domain exists in domain restrictions list
        var alarmCheck = this.state.alarming;
        var domainCheck = this.state.domainCheck;
        var savedMail = (this.props.alarming != null) ? this.props.alarming.mail : "";

        if (alarmCheck && (domainCheck == "false" || (domainCheck == "" && savedMail != alarming.mail && alarming.mail != ""))) {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "saveEmailDomainError")}</>)}</MyContext.Consumer>, { autoClose: 6000, position: "bottom-left" });
            this.setState({ domainCheck: "" });
        } else {
            if (domainCheck == "" && alarmCheck) {
                if (this.state.alarmingMail) {
                    this.checkMailForDomainRestrictionsOnSave(this.props.apol);
                    if (!this.state.saveDomainCheck) {
                        return;
                    }
                }
            }

            // Check reportMail email
            var reportCheck = this.state.reportSend;
            var reportDomainCheck = this.state.reportDomainCheck;
            var savedReportMail = (this.props.reportSend != null) ? this.props.reportMail : "";

            if (reportCheck && (reportDomainCheck == "false" || (reportDomainCheck == "" && savedReportMail != this.state.reportMail && this.state.reportMail != ""))) {
                toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "saveEmailDomainError")}</>)}</MyContext.Consumer>, { autoClose: 6000, position: "bottom-left" });
                this.setState({ reportDomainCheck: "" });
            } else {
                if (reportDomainCheck == "" && reportCheck) {
                    if (this.state.reportMail) {
                        this.checkReportMailForDomainRestrictionsOnSave(this.props.apol);
                        if (!this.state.saveReportDomainCheck) {
                            return;
                        }
                    } else {
                        toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "emailAbsentError")}</>)}</MyContext.Consumer>, { autoClose: 5000, position: "bottom-left" });
                        return;
                    }
                }

                // Save Changes
                var filterCategories = "";
                if (this.state.sel4list != null) {
                    for (let x = 0; x < this.state.sel4list.length; x++) {
                        if (this.state.sel4list[x] != null)
                            filterCategories += filterCategories == "" ? this.state.sel4list[x].id : "," + this.state.sel4list[x].id;
                    };
                }
                var jobIds = "";
                if (this.state.jobsList != null) {
                    for (let x = 0; x < this.state.jobsList.length; x++) {
                        if (this.state.jobsList[x] != null)
                            jobIds += jobIds == "" ? this.state.jobsList[x].key : "," + this.state.jobsList[x].key;
                    };
                }

                if (!this.props.isNew) {
                    this.props.apol
                        .mutate({
                            mutation: gql`

                    mutation ent ($input: AuthInput, $id : String!, $title: String, $kpi: String, $keyword: String, $sortOrder: Int, $widget: String, $cycle: String, $startDate: DateTime, $endDate: DateTime, $person: [PersonInputType], $type: String, $tenantId: String, $valueType: String, $tForm: String, $alarming: AlarmingInputType, $categories: String, $botAppId: String, $reportSend: Boolean, $jobType: JobInputType, $reportMail: String, $jobId: String, $conversationType:[String]) {
                        setReportData (input: $input, report: {id: $id, title: $title, kpi: $kpi, keywords: $keyword, sortOrder: $sortOrder, widget: $widget, cycle: $cycle, startDate: $startDate, endDate: $endDate, persons: $person, type: $type, tenantId: $tenantId, valueType: $valueType, tForm: $tForm, alarming: $alarming, categories: $categories, botAppId: $botAppId,  reportSend: $reportSend, jobType: $jobType, reportMail: $reportMail, jobId: $jobId, conversationType:$conversationType}) {
                            id
                        }
                    }
                `,
                            refetchQueries: [{
                                query: queryReportData,
                                variables: { input: this.props.input, id: this.props.id },
                            }],
                            variables: {
                                input: this.props.input,
                                userIDs: this.state.user,
                                id: this.props.id,
                                title: this.state.changedTitle,
                                kpi: this.state.changedKpi,
                                keyword: this.state.changedKeyword,
                                sortOrder: this.state.changedSortOrder,
                                widget: this.state.changedWidget,
                                cycle: this.state.changedCycle,
                                type: this.state.tType,
                                startDate: this.state.changedStartDate === '' ? null : this.state.changedStartDate,
                                endDate: this.state.changedEndDate === '' ? null : this.state.changedEndDate,
                                person: persons,
                                tenantId: this.state.tenantId,
                                valueType: this.state.tValue,
                                tForm: this.state.tForm,
                                alarming: alarming,
                                categories: filterCategories,
                                reportSend: this.state.reportSend,
                                mailCycle: this.state.mailCycle,
                                reportMail: this.state.reportMail,
                                botAppId: this.state.botAppId,
                                jobId: jobIds,
                                jobType: {
                                    intervalType: "Periodical",
                                    jobStartTime: this.state.mailDate === '' ? null : this.state.mailDate,
                                    intervalMonth: parseInt(this.state.mailMonth),
                                    intervalDay: parseInt(this.state.mailDay),
                                    intervalHour: parseInt(this.state.mailHour)
                                },
                                conversationType: this.state.conversationType
                            },
                        })
                        .then((result) => {
                            toast.success('Report changed');
                            this.props.reloadFunc();
                        });
                } else {


                    this.props.apol
                        .mutate({
                            mutation: gql`
                    mutation ent ($input: AuthInput, $id: String, $title: String, $kpi: String, $keyword: String, $sortOrder: Int, $widget: String, $cycle: String, $startDate: DateTime, $endDate: DateTime, $person: [PersonInputType], $type: String, $tenantId: String, $valueType: String, $tForm: String, $alarming: AlarmingInputType, $categories: String, $reportSend: Boolean, $jobType: JobInputType, $reportMail: String,  $botAppId: String, $jobId: String, $dashboard: String,$conversationType:[String]) {
                        createReport (input: $input, report: {id: $id, title: $title, kpi: $kpi, keywords: $keyword, sortOrder: $sortOrder, widget: $widget, cycle: $cycle, startDate: $startDate, endDate: $endDate, persons: $person, type: $type, tenantId: $tenantId, valueType: $valueType, tForm: $tForm, alarming: $alarming, categories: $categories, reportSend: $reportSend, jobType: $jobType, reportMail: $reportMail, botAppId: $botAppId, jobId: $jobId, dashboard: $dashboard,conversationType:$conversationType}) {
                            id
                        }
                    }
                `,
                            variables: {
                                input: this.props.input,
                                id: this.props.id,
                                title: this.state.changedTitle,
                                kpi: this.state.changedKpi,
                                keyword: this.state.changedKeyword,
                                sortOrder: this.state.changedSortOrder,
                                userIDs: this.state.user,
                                widget: this.state.changedWidget,
                                cycle: this.state.changedCycle,
                                type: this.state.tType,
                                startDate: this.state.changedStartDate === '' ? null : this.state.changedStartDate,
                                endDate: this.state.changedEndDate === '' ? null : this.state.changedEndDate,
                                person: persons,
                                tenantId: this.state.tenantId,
                                valueType: this.state.tValue,
                                tForm: this.state.tForm,
                                alarming: alarming,
                                categories: filterCategories,
                                reportSend: this.state.reportSend,
                                reportMail: this.state.reportMail,
                                botAppId: this.state.botAppId,
                                jobId: jobIds,
                                jobType: {
                                    intervalType: "Periodical",
                                    intervalMonth: parseInt(this.state.mailMonth),
                                    intervalDay: parseInt(this.state.mailDay),
                                    intervalHour: parseInt(this.state.mailHour),
                                    jobStartTime: this.state.mailDate === '' ? null : this.state.mailDate,
                                },
                                dashboard: this.props.dashboardId,
                                conversationType: this.state.conversationType

                            },
                        })
                        .then((result) => {
                            toast.success('Report created');
                            this.props.reloadFunc();

                        });
                }
            }
            this.setState({ domainCheck: "" });
            this.setState({ reportDomainCheck: "" });
            this.hidePanel();
        }
        this.setState({ domainCheck: "" });
        this.setState({ reportDomainCheck: "" });

    };

    resetState = () => {
        if (this.props.isNew) {
            this.setState({
                isModalOpen: false,
                isChanged: false,
                changedTitle: this.props.title,
                changedKpi: this.props.kpi,
                changedKeyword: this.props.keywords,
                changedStartDate: (this.props.startDate !== '' && this.props.startDate !== '0001-01-01T00:00:00') ? new Date(this.props.startDate) : '',
                changedEndDate: (this.props.endDate !== '' && this.props.endDate !== '0001-01-01T00:00:00') ? new Date(this.props.endDate) : '',
                changedCycle: this.props.cycle,
                changedSortOrder: this.props.sortOrder,
                changedType: this.props.type,
                changedWidget: this.props.widget,
                tForm: 'cycle',
                tValue: '',
                tenantId: '',
                alarming: false,
                alarmingMail: '',
                alarmingEvent: '',
                alarmingValue: '',
                domainCheck: "",
                reportDomainCheck: "",
                saveReportDomainCheck: false,
                reportMail: "",
                reportSend: false,
                conversationType: [],
                persons: [],
                jobsList: this.jobsList,
                selectedJobs: this.jobsListSource,
                

            });
        } else {
            this.setState({
                ...this.setStateFromReport(this.props)
            });
        }
    }

    checkMailForDomainRestrictions = async (apol) => {
        var data = await apol
            .mutate({
                mutation: checkMailForDomainRestrictions,
                variables: {
                    email: this.state.alarmingMail
                }
            });
        if (data.data.checkMailForDomainRestrictions) {
            toast.success(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "emailDomainAccepted")}</>)}</MyContext.Consumer>, { autoClose: 5000, position: "bottom-left" });
            this.setState({ domainCheck: "true" });

        } else {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "emailDomainNotAllowed")}</>)}</MyContext.Consumer>, { autoClose: 5000, position: "bottom-left" });
            this.setState({ domainCheck: "false" });
        }
    }

    checkMailForDomainRestrictionsOnSave = async (apol) => {
        var data = await apol
            .mutate({
                mutation: checkMailForDomainRestrictions,
                variables: {
                    email: this.state.alarmingMail
                }
            });
        if (!data.data.checkMailForDomainRestrictions) {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "emailDomainNotValidError")}</>)}</MyContext.Consumer>, { autoClose: 5000, position: "bottom-left" });
            this.setState({ saveDomainCheck: false });

        } else if (data.data.checkMailForDomainRestrictions) {
            this.setState({ saveDomainCheck: true })
        }
    }

    checkReportMailForDomainRestrictions = async (apol) => {
        var data = await apol
            .mutate({
                mutation: checkMailForDomainRestrictions,
                variables: {
                    email: this.state.reportMail
                }
            });
        if (data.data.checkMailForDomainRestrictions) {
            toast.success(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "emailDomainAccepted")}</>)}</MyContext.Consumer>, { autoClose: 5000, position: "bottom-left" });
            this.setState({ reportDomainCheck: "true" });

        } else {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "emailDomainNotAllowed")}</>)}</MyContext.Consumer>, { autoClose: 5000, position: "bottom-left" });
            this.setState({ reportDomainCheck: "false" });
        }
    }

    checkReportMailForDomainRestrictionsOnSave = async (apol) => {
        var data = await apol
            .mutate({
                mutation: checkMailForDomainRestrictions,
                variables: {
                    email: this.state.reportMail
                }
            });
        if (!data.data.checkMailForDomainRestrictions) {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "emailDomainNotValidError")}</>)}</MyContext.Consumer>, { autoClose: 5000, position: "bottom-left" });
            this.setState({ saveReportDomainCheck: false });

        } else if (data.data.checkMailForDomainRestrictions) {
            this.setState({ saveReportDomainCheck: true })
        }
    }


}
