import * as React from 'react';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { FontSizes } from 'office-ui-fabric-react/lib/';
import { MyContext } from '../../../../../context';
import zipcelx from 'zipcelx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllNameCustomFields} from '../../../../../queries/querySettings'

const config = 
            [{
                value: "Conversation ID",
                type: 'string'
            }, {
                value: 'ConversationType',
                type: 'string'
            }, {
                value: "Header",
                type: 'string'
            }, {
                value: "Date",
                type: 'string'
            }, {
                value: "Start",
                type: 'string'
            }, {
                value: "Duration",
                type: 'string'
            }, {
                value: "Participants",
                type: 'string'
            }, {
                value: "userLogin",
                type: 'string'
            }, {
                value: "userId",
                type: 'string'
            }, {
                value: "hasAudio",
                type: 'string'
                }, {
                    value: "hasVideo",
                    type: 'string'
                }, {
                    value: "hasScreen",
                    type: 'string'
                }, {
                    value: "hasChat",
                    type: 'string'
                }, {
                value: "hasFiles",
                type: 'string'
                }
                , {
                    value: "note",
                    type: 'string'
                }, {
                    value: "toDo",
                    type: 'string'
                }, {
                    value: "scenarioId",
                    type: 'string'
                },{
                    value: "transferorName",
                    type: 'string'
                },
                {
                    value: "compliance",
                    type: 'string'
                }
            ]
        ;

export default class SearchExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render = () => {
        const { hideDialog, isDraggable } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>
                        <CommandBarButton
                            iconProps={{ iconName: 'ExcelDocument', title:'Export'}}
                            style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                            onClick={this._onExport} />
                    </div>)}
            </MyContext.Consumer>
            );
    }

    convertArray = () => {
        let input = this.props.data;
        let day;
        let outArr = [];

        if (input !== undefined && input != null) {
            for (let i = 0; i < input.length; i++) {
                day = input[i].date;

                for (let j = 0; j < input[i].content.length; j++) {
                    outArr.push({
                        id: input[i].content[j].id,
                        conversationType: input[i].content[j].recordingType,
                        topic: input[i].content[j].topic,
                        conversationId: input[i].content[j].conversationId,
                        date: day,
                        dateValue: input[i].content[j].callStartMs,
                        starttime: input[i].content[j].timein,
                        duration: input[i].content[j].duration,
                        notes: input[i].content[j].notes != null ? input[i].content[j].notes : "",
                        transcription: input[i].content[j].speech2Text != null ? input[i].content[j].speech2Text : "",
                        participantCount: input[i].content[j].participantCount,
                        recordingType: input[i].content[j].recordingType,
                        participants: input[i].content[j].participantShort,
                        todo: input[i].content[j].date === "To Do",
                        scenarioId: input[i].content[j].scenarioId,
                        data: input[i].content[j]
                    }
                    )
                }

            }
        }
        else if (this.props.dataList != null) {
            outArr = this.props.dataList;
        }

        return outArr
    };

    _onExport = async (ev) => {
        var itemList = [];
        var columnTitles = [];
        var exportConfig = {
            filename: 'searchResult',
            sheet: { data: [] }
        };
        config.forEach(cf => columnTitles.push(cf));

        var customFieldDefinition = undefined;
        if (this.props.userRules.ruleAccess.hasCustomFieldsAccess) {
            customFieldDefinition = await this.props.apol.query({
                query: getAllNameCustomFields,
                variables: { auth: this.props.authorization }
            });
            customFieldDefinition.data.getAllCustomFieldDefinitions.forEach(cf => {
                if (this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.id) || this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.fieldName)) {
                    columnTitles.push({ value: cf.fieldName, type: 'string' });
                }
            });
        }

        itemList.push(columnTitles);
        this.convertArray().forEach((anItem) => {
            var policy = this.props.userRules.ruleAccess.userCompliancePolicy ? "NO" : "No Right";
            var customFields = this.props.userRules.ruleAccess.hasCustomFieldsAccess ? "" : "No Right";
            if (this.props.userRules.ruleAccess.userCompliancePolicy) {
                const policies = anItem.data.policies
                var resultRiskRatings = [];
                if (policies !== null && policies !== undefined && policies.length > 0) {
                    policies.forEach(pol => {
                        if (!resultRiskRatings.includes(pol.riskRating))
                            resultRiskRatings.push(pol.riskRating)
                    });
                }
                if (resultRiskRatings.length > 0) {
                    policy = resultRiskRatings;
                }
            }
            let itemArray =
                [
                    {
                        value: anItem.conversationId,
                        type: 'string'
                    }, {
                        value: anItem.conversationType,
                        type: 'string'
                    }, {
                        value: anItem.topic,
                        type: 'string'
                    }, {
                        value: anItem.date,
                        type: 'string'
                    }, {
                        value: anItem.starttime,
                        type: 'string'
                    }, {
                        value: anItem.duration,
                        type: 'string'
                    }, {
                        value: anItem.participants,
                        type: 'string'
                    }, {
                        value: anItem.data.ownerDisplayName,
                        type: 'string'
                    }, {
                        value: anItem.data.userId,
                        type: 'string'
                    }, {
                        value: anItem.data.hasAudio ? 1 : 0,
                        type: 'number'
                    }, {
                        value: anItem.data.hasVideo ? 1 : 0,
                        type: 'number'
                    }, {
                        value: anItem.data.hasScreenSharing ? 1 : 0,
                        type: 'number'
                    }, {
                        value: anItem.data.hasChat ? 1 : 0,
                        type: 'number'
                    }, {
                        value: anItem.data.hasFiles ? 1 : 0,
                        type: 'number'
                    }, {
                        value: (anItem.data.notes != undefined && anItem.data.notes != "") ? "YES" : "NO",
                        type: 'string'
                    }, {
                        value: anItem.todo ? "To Do" : "",
                        type: 'string'

                    }, {
                        value: anItem.scenarioId,
                        type: 'string'

                    },{
                        value: anItem.transferorName,
                        type: 'string'

                    },
                    {
                        value: policy,
                        type: 'string'
                    }
                ];
            if (this.props.userRules.ruleAccess.hasCustomFieldsAccess) {
                customFieldDefinition.data.getAllCustomFieldDefinitions.forEach(cf => {
                    if (this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.id) || this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.fieldName)) {
                        let value = "";
                        let customFields = JSON.parse(anItem.data.customFields);
                        if (customFields.customFields.length > 0) {
                            let customField = customFields.customFields.filter(cfv => {
                                return cfv.fieldId === cf.id
                            })
                            if (customField !== null && customField.length > 0) {
                                value = customField[0].fieldValues.join(",")
                            };
                        }
                        itemArray.push({
                            type: 'string',
                            value: value
                        });
                    }
                });
            }
            itemList.push(itemArray);
        });
        
        exportConfig.sheet.data = itemList;
        zipcelx(exportConfig);
        toast.info('Search result downloaded to your favorite download directory.', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
}
