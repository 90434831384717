import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
//React
import React, { Component } from 'react';
//import * as ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
//Apollo
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { Query } from "react-apollo";
import 'whatwg-fetch';
//Components
import LiveView from './components/DetailView/LiveView'
import LiveViewOnly from './components/DetailView/LiveViewOnly'
import Setter from './components/RuleSetter/Setter'
import SetterTenant from './components/RuleSetter/SetterTenant'
import SetterTicket from './components/RuleSetter/SetterTicket'
//Fabric IO
import FilterBox from './components/SearchBox/FilterBox'
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import * as microsoftTeams from "@microsoft/teams-js";
import Utils from "./components/common/Utils";
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import { FontSizes, Stack } from 'office-ui-fabric-react/lib';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { Pivot, PivotItem, Label } from 'office-ui-fabric-react/lib';
import ProductLicense from './components/Legal/ProductLicense'

//STYLE
import './App.scss'
import DayView from "./components/DayView/DayView";
import DashboardView from "./components/DashboardView/DashboardView";
import SettingsView from "./components/SettingsView/SettingsView";
import ReportView from "./components/ReportView/ReportView";
import Upload from "./components/UploadView/UpLoad";
import Login from "./components/Login/Login";
import Logout from "./components/Login/Logout";
import LoginResult from "./components/Login/LoginResult";
import Config from "./components/Login/Config";
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { TooltipDelay } from 'office-ui-fabric-react/lib/Tooltip';
import Termsofuse from "./components/Legal/Termsofuse";
import Product from "./components/Legal/Product";
import Privacy from "./components/Legal/Privacy";
import BotRegistration from "./components/Legal/BotRegistration";
import TransactableView from "./components/Transactable/TransactableView";
import Impressum from "./components/Legal/Impressum";
import AuthenticationContext from "adal-angular";
import jwt_decode from "jwt-decode";
import solutenLogo from './styles/images/asc-logo.svg';


//import ShopControler from "./components/ShopView/ShopControler";

import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import {
    renderSearchTeams,
    renderZoomAuthorize
} from "./queries/queryRecordings";
import { queryGetAuth } from "./queries/querySettings";
import { renderPanelRecording, renderPanelRules, renderPanelSettings, renderPanelUpload, renderPanelTenant, renderPanelDashboard, renderPanelCompliance, renderPanelNewsFeed } from "./panel/helpPanel";
import { iniApplicationId, iniGraphqlUrl, iniGraphMicrosoft } from "./ini/ini";
import { Providers, SimpleProvider, ProviderState } from '@microsoft/mgt/dist/commonjs';

import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import Contact from "./components/Legal/Contact";
//import Pricing from "./components/ShopView/SubPages/Pricing";
import { MyContext } from '../src/context';
import { ThemeProvider, PartialTheme } from '@fluentui/react-theme-provider';
import ComplianceView from './components/ComplianceView/ComplianceView';
import ComplianceLicense from './components/ComplianceView/ComplianceLicense';
import ConversationSafe from './components/ConversationSafe/ConversationSafe';


initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');

// Read DarkMode
const ASCThemeNormal: PartialTheme = {
    palette: {
        themePrimary: '#00315e',
        themeLighterAlt: '#cfdeec',
        themeLighter: '#a8c2da',
        themeLight: '#84a7c8',
        themeTertiary: '#648fb7',
        themeSecondary: '#4978a5',
        themeDarkAlt: '#316493',
        themeDark: '#1d5182',
        themeDarker: '#0c4070',
        neutralLighterAlt: '#f1f1f1',
        neutralLighter: '#ededed',
        neutralLight: '#e3e3e3',
        neutralQuaternaryAlt: '#d3d3d3',
        neutralQuaternary: '#cacaca',
        neutralTertiaryAlt: '#c2c2c2',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#f7f7f7',
    }
};

const ASCThemeContrast: PartialTheme = {
    palette: {
        themePrimary: '#71a2d2',
        themeLighterAlt: '#050608',
        themeLighter: '#121a21',
        themeLight: '#22303f',
        themeTertiary: '#44617d',
        themeSecondary: '#638eb8',
        themeDarkAlt: '#7da9d6',
        themeDark: '#8fb6dc',
        themeDarker: '#aac8e5',
        neutralLighterAlt: '#0b0b0b',
        neutralLighter: '#151515',
        neutralLight: '#252525',
        neutralQuaternaryAlt: '#2f2f2f',
        neutralQuaternary: '#373737',
        neutralTertiaryAlt: '#595959',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#000000'
    }
};

const ASCThemeDark: PartialTheme = {
    palette: {
        themePrimary: '#71a2d2',
        themeLighterAlt: '#050608',
        themeLighter: '#121a21',
        themeLight: '#22303f',
        themeTertiary: '#44617d',
        themeSecondary: '#638eb8',
        themeDarkAlt: '#7da9d6',
        themeDark: '#8fb6dc',
        themeDarker: '#aac8e5',
        neutralLighterAlt: '#2b2b2b',
        neutralLighter: '#333333',
        neutralLight: '#414141',
        neutralQuaternaryAlt: '#4a4a4a',
        neutralQuaternary: '#515151',
        neutralTertiaryAlt: '#6f6f6f',
        neutralTertiary: '#e0e0e0',
        neutralSecondary: '#e5e5e5',
        neutralPrimaryAlt: '#eaeaea',
        neutralPrimary: '#d1d1d1',
        neutralDark: '#f4f4f4',
        black: '#f9f9f9',
        white: '#202020',
    }
};

let theme = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? ASCThemeDark : ASCThemeNormal;
document.body.style.backgroundColor = theme.palette.neutralLighterAlt;

let config = {
    clientId: iniApplicationId,
    redirectUri: window.location.origin + "/loginResult",
    cacheLocation: "localStorage",
    navigateToLoginRequestUrl: false,
    extraQueryParameter: "scope=openid+profile"
};
let authContext = new AuthenticationContext(config); // from the ADAL.js library

let timerId = null;

let authTokenSuccess = (result) => {
    Utils.lsSet("access_token", result);
    console.log("SSO login successfull");
};

let authTokenRequest = {
    successCallback: authTokenSuccess,
    failureCallback: function (error) { console.log("Failure: " + error); },
};

const authLink = setContext((_, { headers }) => {
    if (window.location.pathname.includes("shop")) {
        return;
    }
    // get the authentication token from local storage if it exists    

    const token = Utils.lsGet("access_token");
    // Check Token Validation
    var decoded = jwt_decode(token);
    var expire = decoded.exp;
    var remaining = parseInt(decoded.exp - Date.now() / 1000);
    console.log("Valid for " + remaining + " seconds");
    if (remaining < 300) {
        // Renew token
        console.log("Renew Token");
        microsoftTeams.authentication.getAuthToken(authTokenRequest);
        var tokenId = authContext.getCachedToken(iniApplicationId);
        authContext._renewIdToken(function (err, tokenId) {
            if (err) {
                console.log("Renewal access token failed: " + err);
            }
            else {
                let token = authContext.getCachedToken(iniApplicationId);
                Utils.lsSet("access_token", token);
            };
        });
    }
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const httpLink = createHttpLink({
    uri: iniGraphqlUrl
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});


let pp = window.location.href;

function getAccessToken(scopes) {
    return authContext.getCachedToken(iniGraphMicrosoft);
}

function login() {
    microsoftTeams.authentication.authenticate({
        url: window.location.origin + "/login",
        width: 600,
        height: 535,
        successCallback: function (result) {
            console.log("authentication succeeded: ", result);
            window.location.reload();
        },
        failureCallback: function (reason) {
            console.log("authentication failed: ", reason);
            window.location.reload();
        }
    });
}

function logout() {
    authContext.clearCache();
    authContext._user = null;
    window.location.href = window.location.origin + "/logout";
}

let provider = new SimpleProvider(getAccessToken, login, logout);
provider.setState(ProviderState.SignedOut);
Providers.globalProvider = provider;

export default class App extends Component {


    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this);
        this.LiveViewRef = React.createRef();
        // Delete access_token
        Utils.lsRemove("access_token");
        Utils.lsSet("adal_authentication", "0");
        this.timerID = null;
    }
    displayName = App.name;
    commandBarSync = React.createRef();

    state = {
        isDebug: true,
        isteams: false,
        userContext: null,
        loginChecked: false,
        loginChecked2: false,
        auth: null,
        id: "0",
        val: "",
        withSave: false,
        backgroundColor: "#F7F7F7",
        shopState: null,
        helpMode: Utils.lsGet("helpMode") === "true" ? true : false,
        helpPanel: false,
        configurationPanel: false,
        newsPanelIsOpen: false,
        access_token: null,
        selectedPivot: Utils.lsGet("selectedPivot") != null ? (Utils.lsGet("selectedPivot")) : ".0",
        iconView: true,
        isRefresh: true,
        isConversationSafeNew: true,
        searchIn: []
    };

    handler(search, filter, save, searchIn) {
        this.setState({ id: "1", val: search, filter: filter, withSave: save, searchIn: searchIn })
    }
    switchView = () => {
        if (this.state.isRefresh) {
            this.setState({ isRefresh: false })
        }
        //isConversationSafeNew is true only if the conversation safe was not accessed
        if (this.state.isConversationSafeNew && this.state.id == "2") {
            this.setState({ isConversationSafeNew: false })
        }
        this.setState({ iconView: this.state.iconView ? false : true, backgroundColor: '#F7F7F7' })
    };

    newsPanelOpen = () => {
        this.setState({ newsPanelIsOpen: true });
    }

    newsPanelClose = () => {
        this.setState({ newsPanelIsOpen: false });
    }

    authTokenSuccess = (result) => {
        Utils.lsSet("access_token", result);
        console.log("SSO login successfull");
        Utils.lsSet("adal_authentication", "0");
        this.setState({ access_token: result });
    };

    authTokenRequest = {
        successCallback: this.authTokenSuccess,
        failureCallback: function (error) { console.log("Failure: " + error); },
    };

    componentDidMount() {
        microsoftTeams.initialize();
        document.title = "ASC Recording Insights";
        // Try Teams approach to get token
        microsoftTeams.authentication.getAuthToken(this.authTokenRequest);
        Utils.lsSet("refreshView", "no");
        microsoftTeams.getContext((context => {
            // Read if Dark Mode
            console.log("Theme: " + context.theme);
            theme = context.theme === "dark" ? ASCThemeDark : (context.theme === "contrast" ? ASCThemeContrast : ASCThemeNormal)
            document.body.style.backgroundColor = theme.palette.neutralLighterAlt;

            if (authContext.getCachedUser() != null && authContext.getCachedUser().profile.upn !== context.loginHint) {
                // Then delete old user and force new check
                console.log("Clear Cache");
                // User doesn't match, clear the cache
                authContext.clearCache();
                window.location.reload();
            } else {
                this.setState({ isteams: true, userContext: context });
            }
        }));
        var isState = window.localStorage.getItem("saved_state");
        if (isState) {
            this.setState({ id: isState });
            window.localStorage.setItem("saved_state", "0");
        }
    };

    // Check Authentication
    checkTeamsAuthentication = () => {
        console.log("checkTeamsAuthentication: ", this.timerID);
        if (this.state.access_token == null) {
            // Then use old Authentication
            console.log("Use old authentication");
            Utils.lsSet("adal_authentication", "1");
            this.authenticateUser();
        }
        else {
            clearInterval(this.timerID);
        }
    }

    componentDidUpdate() {

        window.localStorage.setItem("saved_state", this.state.id);
    }

    // Old Adal Login
    authenticateUser = () => {
        if (!this.state.loginChecked || !this.state.loginChecked2) {
            if (!this.state.loginChecked) {
                console.log("Authenticate User 1");
                this.setState({ loginChecked: true });
            }
            else {
                console.log("Authenticate User 2");
                this.setState({ loginChecked2: true });
                clearInterval(this.timerID);
            }
            let user = authContext.getCachedUser();
            if (this.state.userContext != null && this.state.userContext.loginHint) {
                if (user) {
                    console.log("LoginHint: " + this.state.userContext.loginHint + " UserName: " + user.profile.upn);

                    if (user.profile.upn !== this.state.userContext.loginHint) {
                        console.log("Clear Cache");
                        // User doesn't match, clear the cache
                        authContext.clearCache();
                        window.location.reload();
                    }
                }
            }
            let token = authContext.getCachedToken(iniApplicationId);
            if (token) {
                let accessToken = authContext.getCachedToken(iniGraphMicrosoft);
                if (accessToken == null) {
                    authContext.acquireToken(iniGraphMicrosoft, function (error, access_token) {
                        if (error || !access_token) {
                            console.log('ADAL error occurred: ' + error);
                        }
                        else {
                            provider.setState(ProviderState.SignedIn);
                            this.setState({ auth: { tenantId: user.profile.tid, userId: user.profile.oid, sessionToken: token, locale: user.profile.locale, timeZone: new Date().getTimezoneOffset(), timeZoneId: new Intl.DateTimeFormat().resolvedOptions().timeZone } });
                            Utils.lsSet("access_token", token);

                        }
                    }.bind(this)
                    );
                } else {
                    provider.setState(ProviderState.SignedIn);
                    this.setState({ auth: { tenantId: user.profile.tid, userId: user.profile.oid, sessionToken: token, locale: user.profile.locale, timeZone: new Date().getTimezoneOffset(), timeZoneId: new Intl.DateTimeFormat().resolvedOptions().timeZone } });
                    Utils.lsSet("access_token", token);

                }
            } else {
                // No token, or token is expired
                console.log("Renew 2 Token");
                authContext._renewIdToken(function (err, idToken) {
                    if (err) {
                        console.log("Renewal 2 failed: " + err);
                    } else {
                        if (idToken) {
                            authContext.acquireToken(iniGraphMicrosoft, function (error, access_token) {
                                if (error || !access_token) {
                                    console.log('ADAL 2 error occurred: ' + error);

                                }
                                else {
                                    provider.setState(ProviderState.SignedIn);
                                    this.setState({ auth: { tenantId: user.profile.tid, userId: user.profile.oid, sessionToken: token, locale: user.profile.locale, timeZone: new Date().getTimezoneOffset(), timeZoneId: new Intl.DateTimeFormat().resolvedOptions().timeZone } });
                                    Utils.lsSet("access_token", token);
                                }
                            }.bind(this)
                            );
                        }
                    }
                });
            }
        }
    };

    render() {

        // If login related things immediately return Object
        if (window.location.pathname == "/loginResult")
            return (<ThemeProvider theme={theme}>
                <LoginResult path={pp} authContext={authContext} />
            </ThemeProvider>);
        else if (window.location.pathname == "/login")
            return (<ThemeProvider theme={theme}>
                <Login path={pp} />
            </ThemeProvider>);

        else if (window.location.pathname == "/logout")
            return (<ThemeProvider theme={theme}>
                <Logout path={pp} />
            </ThemeProvider>);

        let auth = { userId: this.state.auth != null ? this.state.auth.userId : "", locale: navigator.language || navigator.userLanguage, timeZone: new Date().getTimezoneOffset(), timeZoneId: new Intl.DateTimeFormat().resolvedOptions().timeZone };
        let token = authContext.getCachedToken(iniApplicationId);
        let access_token = authContext.getCachedToken(iniGraphMicrosoft);
        let withAdalAuthentication = false;
        let color;
        switch (window.location.pathname) {
            case "/shop/":
            case "/contact/":
            case "/impressum/":
            case "/privacy/":
            case "/shop":
            case "/contact":
            case "/impressum":
            case "/privacy":
                //case "/pricing":

                color = "white";
                break;

            default:
                color = this.state.backgroundColor
                break;
        }
        if (token && access_token) {
            // Check old Adal authentication
            provider.setState(ProviderState.SignedIn);
            console.log("Old Adal Authentication");
            Utils.lsSet("access_token", token);
            Utils.lsSet("adal_authentication", "1");
        }

        if (Utils.lsGet("access_token") == null && Utils.lsGet("adal_authentication") == "0" && !window.location.pathname.includes("shop")) {
            // Check teams authentication
            if (this.timerID == null) {
                this.timerID = setInterval(() => this.checkTeamsAuthentication(), 2000);
                Utils.lsSet("adal_authentication", "1");
            }
            console.log("Authenticate...", this.timerID);
            return (
                <ThemeProvider theme={theme}>
                    <Spinner label="Authenticate..." ariaLive="assertive" labelPosition="top" />
                </ThemeProvider>);

        }
        else {
            return (
                <ThemeProvider theme={theme}>

                    <div className="App" style={{ color: theme.palette.neutralDark }}>

                        <ApolloProvider client={client}>
                            {/* Main Routing */}
                            <Route path="/" exact render={() => { return <div>{this.updateState(false, "/", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />

                            {/* Other Routing */}
                            <Route path="/rules/" exact render={() => { return <div>{this.updateState(false, "/rules", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/tenant/" exact render={() => { return <div>{this.updateState(false, "/tenant", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/ticket/" exact render={() => { return <div>{this.updateState(false, "/ticket", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/day/" render={() => { return <div>{this.updateState(false, "/day", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/upload/" exact render={() => { return <div>{this.updateState(false, "/upload", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/compliance/" render={() => { return <div>{this.updateState(false, "/compliance", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/dashboard/" render={() => { return <div>{this.updateState(false, "/dashboard", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/report/" render={() => {
                                return <ReportView path={pp} theme={this.props.theme} />
                            }} />
                            <Route path="/liveView/" render={() => { return <div>{this.updateState(false, "/liveView", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />

                            <Route path="/config/" render={() => { return <Config path={pp} /> }} />
                            {/* <Route path="/shop/" render={() => { return <div><ShopControler authorization={this.state.auth} apol={client} page={0} /></div> }} /> */}
                            <Route path="/configure/" render={() => { return <div><span style={{ padding: "10px", cursor: "pointer" }}><Icon onClick={this.switchView} iconName={"Home"} /></span><Setter authorization={this.state.auth} apol={client} /></div> }} />
                            {/* <Route path="/return/" render={() => { return <div><ShopControler authorization={this.state.auth} apol={client} page={2} /></div> }} /> */}
                            <Route path="/settings/" render={() => { return <div>{this.updateState(false, "/settings", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            {/* <Route path="/pricing/" render={() => { return <Pricing authorization={this.state.auth} path={pp} /> }} /> */}
                            <Route path="/conversations/" render={() => { return <div>{this.updateState(true, "/", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/users/" render={() => { return <div>{this.updateState(true, "/", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/tags/" render={() => { return <div>{this.updateState(true, "/", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route exact path="/main/" render={() => { return <div>{this.updateState(true, "/", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />
                            <Route path="/zoom/" render={() => { return <div>{this.updateState(true, "/zoom", this.state, this.handler, this.switchView, this.ruleView, this.shopView, client)} </div> }} />


                            {/* Legal Routing */}
                            <Route path="/privacy/" render={() => { return <Privacy path={pp} /> }} />
                            <Route path="/contact/" render={() => { return <Contact path={pp} /> }} />
                            <Route path="/product/" render={() => { return <Product path={pp} authorization={this.state.auth} apol={client} /> }} />
                            <Route path="/termsofuse/" render={() => { return <Termsofuse path={pp} /> }} />
                            <Route path="/impressum/" render={() => { return <Impressum path={pp} /> }} />
                            <Route path="/botRegistration/" render={() => { return <BotRegistration path={pp} /> }} />
                            <Route path="/shop/" render={() => { return <TransactableView path={pp} apol={client} /> }} />

                        </ApolloProvider>
                    </div>
                </ThemeProvider>
            );
        }
    }

    updateState(withMainMenu, type, st, handler, onCkHome, onCkRule, onShop, cc) {
        {

            let auth = { locale: navigator.language || navigator.userLanguage, timeZone: new Date().getTimezoneOffset(), timeZoneId: new Intl.DateTimeFormat().resolvedOptions().timeZone };

            let val = st.val;
            let filter = st.filter;
            let withSave = st.withSave;
            let searchIn = st.searchIn;
            console.log("auth: " + auth.locale + " " + auth.timeZone);
            let token = authContext.getCachedToken(iniApplicationId);
            let access_token = authContext.getCachedToken(iniGraphMicrosoft);
            let day = "";
            if (type === "/day") {
                let xx = window.location.href;
                let yy = xx.lastIndexOf('day');
                day = xx.substring(yy + 4, xx.length);
            }
            if (withMainMenu) {
                console.log("SelectedPivot: " + this.state.selectedPivot);
                Utils.lsRemove("selectedPivot");
            }
            if ((!token || !access_token) && !this.state.access_token) {

                // Dann SignIn
                return <MyContext.Consumer>
                    {(context) => (
                        <div>
                            <header className="app-header ms-Grid-row" style={{ backgroundColor: theme.palette.neutralLighterAlt, boxShadow: Depths.depth8 }} >
                                <Stack horizontal>
                                    <TooltipHost content={"Sign in Teams User"} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                        <mgt-login class={theme.palette.black < "#100000" ? "mgt-light" : "mgt-dark"} person-card="hover"></mgt-login>
                                    </TooltipHost>
                                    <Stack.Item grow={2} ><div /></Stack.Item>

                                    <Stack.Item align="stretch">
                                        {type === "/rules" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("configuration", "configurationTitle")}</Text>}
                                        {type === "/zoom" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("configuration", "zoomAuthentication")}</Text>}
                                        {type === "/tenant" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("tenants", "tenantsTitle")}</Text>}
                                        {type === "/ticket" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("settings", "ticket")}</Text>}
                                        {type === "/settings" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("settings", "settingsTitle")}</Text>}
                                        {type === "/upload" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("upload", "uploadTitle")}</Text>}
                                        {type === "/dashboard" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("dashboard", "dashboardTitle")}</Text>}
                                        {type === "/liveView" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("dashboard", "liveView")}</Text>}
                                        {type === "/main" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>ASC Recording Insights </Text>}
                                        {type === "/compliance" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("compliance", "complianceTitle")}</Text>}
                                        {type === "/day" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>DayView {day}</Text>}
                                        {type === "/" && st.id === "2" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("conversationSafe", "conversationSafeTitle")}</Text>}
                                    </Stack.Item>
                                    <Stack.Item align="end">
                                        <div style={{ padding: "8px", fontSize: FontSizes.icon, textAlign: "right" }} >
                                            <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }} onClick={
                                                () => {
                                                    if (type === "/") {
                                                        if (!this.state.iconView) {
                                                            Utils.lsSet("refreshView", "list")
                                                        } else {
                                                            Utils.lsSet("refreshView", "icon")
                                                        }
                                                    }
                                                    window.location.reload(false);
                                                }
                                            } iconName={"Sync"}
                                                title={context.getTranslation("common", "refresh")}
                                            />
                                        </div>
                                    </Stack.Item>
                                </Stack>

                            </header>
                        </div>)}
                </MyContext.Consumer>
            }

            return (<MyContext.Consumer>
                {(context) => (
                    <div>
                        <Query
                            query={queryGetAuth}
                            variables={{ auth, val }}  >
                            {({ loading, error, data }) => {
                                if (loading) return <div><Spinner label={context.getTranslation("configuration", "checkAccessRules")} ariaLive="assertive" labelPosition="top" /></div>;
                                if (error) return <div></div>;
                                let loginValid = (data.getAuth == null || !data.getAuth.valid) ? false : true;

                                let viewSetting = false;  //default list

                                //is data exists and si != list set to  grid
                                if (data !== null && data.getAuth !== null) {
                                    if (data.getAuth.viewType !== "list") {
                                        viewSetting = true;
                                    }
                                }

                                if ((this.state.iconView != viewSetting) && this.state.isRefresh === true) {
                                    this.switchView();
                                }
                                if ((this.state.iconView != viewSetting) && this.state.isConversationSafeNew === true && this.state.id == "2") {
                                    this.switchView();
                                }
                                const styleConversationSafeContainer = { marginLeft: "10px", marginRight: "10px", marginTop: "-5px" }
                                return (
                                    <div>
                                        {type !== "/liveView" && <header className={withMainMenu ? "app-header-main" : "app-header"} style={{ background: theme.palette.white, boxShadow: Depths.depth8 }} >
                                            {withMainMenu && type !== "/zoom" && <Stack horizontal>
                                                <a href="https://www.asctechnologies.com"><img src={solutenLogo} height="32px" className="headerLogo" /></a>

                                                <Pivot aria-label="ASC Recording Insights" onLinkClick={this.showPivotItem} selectedKey={this.state.selectedPivot}>
                                                    <PivotItem headerText={context.getTranslation("configuration", "recording")} itemKey=".0">
                                                    </PivotItem>
                                                    <PivotItem headerText={context.getTranslation("dashboard", "dashboardTitle")} itemKey=".1">
                                                    </PivotItem>
                                                    <PivotItem headerText={context.getTranslation("compliance", "complianceTitle")} itemKey=".2">
                                                    </PivotItem>
                                                    <PivotItem headerText={context.getTranslation("settings", "settingsTitle")} itemKey=".3">
                                                    </PivotItem>
                                                    {(data.getAuth != null && data.getAuth.ruleAccess != null && (data.getAuth.ruleAccess.confAddUser || data.getAuth.ruleAccess.confListUser)) &&
                                                        <PivotItem headerText={context.getTranslation("configuration", "configurationTitle")} itemKey=".4">
                                                        </PivotItem>
                                                    }
                                                    {(data.getAuth != null && data.getAuth.ruleAccess != null && (data.getAuth.ruleAccess.tenantCreate || data.getAuth.ruleAccess.tenantChange)) &&

                                                        <PivotItem headerText={context.getTranslation("tenants", "tenantsTitle")} itemKey=".5">
                                                        </PivotItem>
                                                    }
                                                    {(data.getAuth != null && data.getAuth.ruleAccess != null && (data.getAuth.ruleAccess.confTicket)) &&
                                                        <PivotItem headerText={context.getTranslation("settings", "ticket")} itemKey=".6">
                                                        </PivotItem>
                                                    }
                                                </Pivot>
                                            </Stack>}
                                            <Stack horizontal >
                                                {(type === "/" && ((this.state.selectedPivot === ".0" && st.id !== "2") || this.state.selectedPivot === ".2")) && <Icon onClick={onCkHome} iconName={this.state.iconView === true ? "List" : "ContactCard"} style={{ padding: "10px", cursor: "pointer", fontSize: FontSizes.icon }} />}
                                                {Utils.lsGet("adal_authentication") == "1" && (window.innerWidth < 600) && <mgt-login class={theme.palette.black < "#100000" ? "mgt-light app-login-small" : "mgt-dark app-login-small"} person-card="hover"></mgt-login>}
                                                {Utils.lsGet("adal_authentication") == "1" && (window.innerWidth >= 600) && <mgt-login class={theme.palette.black < "#100000" ? "mgt-light" : "mgt-dark"} person-card="hover"></mgt-login>}

                                                {loginValid && (type === "/" && st.id !== "2" && this.state.selectedPivot === ".0") &&
                                                    <div style={{ padding: "8px", fontSize: FontSizes.icon }}>
                                                        {(window.innerWidth < 600) && <FilterBox theme={theme} userRules={data.getAuth} sizeSmall="true" style={{ padding: "0px", fontSize: FontSizes.icon }} handler={handler} tooltip="Search conversations that contain the typed text in title, transcript or notes" authorization={auth} />}
                                                        {(window.innerWidth >= 600) && <FilterBox theme={theme} userRules={data.getAuth} style={{ padding: "0px", fontSize: FontSizes.icon }} handler={handler} tooltip="Search conversations that contain the typed text in title, transcript or notes" authorization={auth} />}

                                                    </div>
                                                }

                                                <Stack.Item grow={2}>
                                                    {(type !== "/" || st.id === "2" || this.state.selectedPivot != ".0") && <div style={{ padding: "14px", fontSize: FontSizes.icon }}>
                                                        {!loginValid && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>ASC Recording Insights</Text>}
                                                        {loginValid && type === "/rules" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("configuration", "configurationTitle")}</Text>}
                                                        {loginValid && type === "/zoom" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("configuration", "zoomAuthentication")}</Text>}
                                                        {loginValid && type === "/tenant" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("tenants", "tenantsTitle")}</Text>}
                                                        {loginValid && type === "/ticket" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("settings", "ticket")}</Text>}
                                                        {loginValid && type === "/settings" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("settings", "settingsTitle")}</Text>}
                                                        {loginValid && type === "/upload" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("upload", "uploadTitle")}</Text>}
                                                        {loginValid && type === "/dashboard" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("dashboard", "dashboardTitle")}</Text>}
                                                        {loginValid && type === "/liveView" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("dashboard", "liveView")}</Text>}
                                                        {loginValid && type === "/compliance" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("compliance", "complianceTitle")}</Text>}
                                                        {loginValid && type === "/day" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>DayView {day}</Text>}
                                                        {loginValid && type === "/" && this.state.selectedPivot == ".0" && st.id === "2" && <Text style={{ padding: "14px", fontSize: FontSizes.icon }}>{context.getTranslation("conversationSafe", "conversationSafeTitle")}</Text>}
                                                    </div>}
                                                </Stack.Item>
                                                <Stack.Item align="end">
                                                    <div style={{ padding: "8px", fontSize: FontSizes.icon, textAlign: "right" }} >
                                                        {(type === "/" && this.state.selectedPivot == ".0") && data.getAuth != null && data.getAuth.ruleAccess != null && data.getAuth.ruleAccess.userLiveView && <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }} onClick=
                                                            {
                                                                () => {
                                                                    this.showLiveView();
                                                                }
                                                            }
                                                            iconName={"InternetSharing"}
                                                            title={context.getTranslation("common", "liveView")}
                                                        />}
                                                        {(type === "/" && this.state.selectedPivot == ".0") && data.getAuth != null && data.getAuth.ruleAccess != null
                                                            && data.getAuth.ruleAccess.userSafe !== "No" && st.id !== "2" &&
                                                            <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }}
                                                                onClick={() => {
                                                                    this.setState({ id: "2", backgroundColor: '#F7F7F7', iconView: viewSetting });
                                                                }}
                                                                iconName={"LockSolid"}
                                                                title={context.getTranslation("detailContainer", "conversationSafe")}
                                                            />}
                                                        {type === "/day" && <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }} onClick=
                                                            {
                                                                () => {
                                                                    this.onCkHome();
                                                                }
                                                            }
                                                            iconName={"Home"} />}

                                                        <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }} onClick=
                                                            {
                                                                () => {
                                                                    this.setState({ helpPanel: true });
                                                                }
                                                            } iconName={"Help"}
                                                            title={context.getTranslation("common", "help")}
                                                        />
                                                        <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }} onClick={
                                                            () => {
                                                                if (st.id === "2") {
                                                                    if (!this.state.iconView) {
                                                                        Utils.lsSet("refreshView", "list");
                                                                    } else {
                                                                        Utils.lsSet("refreshView", "icon");
                                                                    }
                                                                    window.localStorage.setItem("saved_state", "2");
                                                                    window.location.reload(false);
                                                                } else {
                                                                    if (!this.state.iconView) {
                                                                        Utils.lsSet("refreshView", "list")
                                                                    } else {
                                                                        Utils.lsSet("refreshView", "icon")
                                                                    }
                                                                    Utils.lsSet("selectedPivot", this.state.selectedPivot);
                                                                    window.location.reload(false);
                                                                }
                                                            }
                                                        } iconName={"Sync"}
                                                            title={context.getTranslation("common", "refresh")}
                                                        />
                                                        {(type === "/" && this.state.selectedPivot == ".0" && st.id === "2") && <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }} onClick={
                                                            () => {
                                                                if (!this.state.iconView) {
                                                                    Utils.lsSet("refreshView", "list")
                                                                } else {
                                                                    Utils.lsSet("refreshView", "icon")
                                                                }
                                                                window.localStorage.setItem("saved_state", "0");
                                                                window.location.reload(false);
                                                            }
                                                        }
                                                            iconName={"Cancel"} />
                                                        }
                                                        {(type == "/rules" || type == "/tenant") &&
                                                            <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }} onClick={
                                                                () => {
                                                                    if (type === "/rules") {
                                                                        window.location.href = window.location.origin + "/settings";

                                                                    }
                                                                    if (type === "/tenant") {
                                                                        window.location.href = window.location.origin + "/settings";

                                                                    }

                                                                }
                                                            } iconName={"Cancel"} />}

                                                        {!loginValid && renderPanelRecording(this, theme)}
                                                        {loginValid && (type === "/" && this.state.selectedPivot == ".0") && renderPanelRecording(this, auth, client, data.getAuth, theme)}
                                                        {loginValid && (type === "/rules" || this.state.selectedPivot === ".4") && renderPanelRules(this, auth, client, data.getAuth, theme)}
                                                        {loginValid && (type === "/tenant" || this.state.selectedPivot === ".5") && renderPanelTenant(this, auth, client, data.getAuth, theme)}
                                                        {loginValid && (type === "/settings" || this.state.selectedPivot === ".3") && renderPanelSettings(this, auth, client, data.getAuth, theme)}
                                                        {loginValid && (type === "/dashboard" || this.state.selectedPivot === ".1") && renderPanelDashboard(this, auth, client, data.getAuth, theme)}
                                                        {loginValid && (type === "/compliance" || this.state.selectedPivot === ".2") && renderPanelCompliance(this, auth, client, data.getAuth, theme)}
                                                        {loginValid && type === "/upload" && renderPanelUpload(this, auth, client, data.getAuth, theme)}
                                                        {loginValid && type === "/day" && renderPanelRecording(this, auth, client, data.getAuth, theme)}

                                                        <LiveView ref={this.LiveViewRef} client={cc} authorization={auth} userRules={data.getAuth} />
                                                    </div>
                                                </Stack.Item>
                                            </Stack>
                                        </header>}
                                        <div className={type === "/liveView" ? "" : (withMainMenu ? "app-body-main" : "app-body")} style={{ backgroundColor: theme.palette.neutralLighterAlt }}>
                                            <div>
                                                {!loginValid && <ProductLicense authorization={st.auth} apol={cc} />}
                                                {loginValid && type === "/" && this.state.selectedPivot == ".0" && st.id === "0" && renderSearchTeams(this.state.iconView, "", null, false, auth, cc, data.getAuth, window.location.href, theme, searchIn)}
                                                {loginValid && type === "/" && this.state.selectedPivot == ".0" && st.id === "1" && renderSearchTeams(this.state.iconView, val, filter, withSave, auth, cc, data.getAuth, window.location.href, theme, searchIn)}
                                                {loginValid && type === "/" && this.state.selectedPivot == ".0" && st.id === "2" && <div style={styleConversationSafeContainer}><ConversationSafe apol={client} auth={auth} userRules={data.getAuth} theme={theme} /></div>}
                                                {loginValid && (type === "/rules" || this.state.selectedPivot === ".4") && <Setter authorization={auth} apol={client} userRules={data.getAuth} theme={theme} />}
                                                {loginValid && (type === "/zoom") && renderZoomAuthorize(this.state.iconView, "", null, false, auth, cc, data.getAuth, window.location.href, theme)}
                                                {loginValid && (type === "/tenant" || this.state.selectedPivot === ".5") && data.getAuth != null && data.getAuth.ruleAccess != null && (data.getAuth.ruleAccess.tenantCreate || data.getAuth.ruleAccess.tenantChange) && <SetterTenant authorization={auth} apol={client} userRules={data.getAuth} />}
                                                {loginValid && (type === "/ticket" || this.state.selectedPivot === ".6") && data.getAuth != null && data.getAuth.ruleAccess != null && (data.getAuth.ruleAccess.confTicket) && <SetterTicket authorization={auth} apol={client} userRules={data.getAuth} />}
                                                {loginValid && type === "/upload" && <Upload authorization={auth} apol={client} userRules={data.getAuth} />}
                                                {loginValid && (type === "/settings" || this.state.selectedPivot === ".3") && <SettingsView authorization={auth} apol={client} path={pp} userRules={data.getAuth} />}
                                                {loginValid && (type === "/dashboard" || this.state.selectedPivot === ".1") && <DashboardView authorization={auth} apol={client} path={pp} userRules={data.getAuth} theme={theme} />}
                                                {loginValid && type === "/liveView" && <LiveViewOnly authorization={auth} apol={client} path={pp} userRules={data.getAuth} theme={theme} />}
                                                {loginValid && (type === "/compliance" || this.state.selectedPivot === ".2") && data.getAuth.addOnCompliance && data.getAuth.ruleAccess.userCompliancePolicy && <ComplianceView authorization={auth} apol={client} path={pp} userRules={data.getAuth} theme={theme} iconView={this.state.iconView} />}
                                                {loginValid && (type === "/compliance" || this.state.selectedPivot === ".2") && (!data.getAuth.ruleAccess.userCompliancePolicy || !data.getAuth.addOnCompliance) && <ComplianceLicense authorization={auth} apol={client} path={pp} userRules={data.getAuth} theme={theme} iconView={this.state.iconView} />}
                                                {loginValid && type === "/day" && <DayView authorization={auth} apol={client} path={pp} userRules={data.getAuth} />}

                                            </div>
                                        </div>
                                    </div>);
                            }
                            }
                        </Query></div>)}
            </MyContext.Consumer>);
        }
    }

    onCkHome = () => {
        window.location.href = "/";
    }

    showLiveView = () => {
        this.LiveViewRef.current.showPanel(Utils.guidGenerator())
    };

    showPivotItem = (item, ev) => {
        console.log(item);
        this.setState({ selectedPivot: item.key })
    }

}