import React from 'react';

import { ReactComponent as Logo } from '../../styles/images/asc-logo.svg';
import solutenLogo from '../../styles/images/ASC_Logo_Cloud-Solutions_700pxBreit.jpg';
import './ASCHeader.scss';
import {
    PrimaryButton,
    DefaultButton, Persona, Icon, Panel,
} from 'office-ui-fabric-react';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import Pricing from '../ShopView/SubPages/Pricing';
import { fallbackData } from '../ShopView/FallbackData';

const headerBlue = '#00315E';
const headerRed = '#91001E';
const headerLightBlue = '#71A2D2';
const headerOrange = '#DF6F15';
const headerGreen = '#90BF38';
const grey = 'rgba(171,169,161,0.41)';

const ASCHeader = (props) => {
    let code = '';
    // console.log(props)Mi
    if (props.region !== undefined && props.region.length === 2) {
        code = props.region;
    }

    if (props.user !== undefined && props.user !== null) {
        return (
            <div id={`001${code}`} className="PageHeader">
                <div className="header-col-1">
                    <a href="https://asctechnologies.com"><img src={solutenLogo} height="64px" className="headerLogo" /></a>
                </div>
                <div className="header-col-2">
                    <div className="header-title">{props.title}</div>
                </div>

                <div style={{
                    float: 'right',
                    margin: '14px',
                }}
                >
                    <span>
                        <Persona
                            text={props.user.profile.name}
                            secondaryText={props.user.userName}
                        />
                    </span>
                </div>
                <div style={{
                    float: 'right',
                    paddingTop: '10px',
                    margin: '14px',
                }}
                >
                    <span>
                        <PrimaryButton text="Switch User" onClick={props.switchcall} />
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div id={`001${code}`} className="PageHeader">
            <div className="header-col-1">
                <a href="https://asctechnologies.com"><img src={solutenLogo} height="64px" className="headerLogo" /></a>
            </div>
            <div className="header-col-2">
                <div className="header-title">{props.title}</div>
            </div>
            <div style={{
                float: 'right',
                margin: '14px',
            }}
            >
                <span style={{ color: grey }}>{code}</span>
            </div>
        </div>
    );
};


class ASCHeaderBuyNow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            panelOpen: false,
        };
        this.dismissPanel = this.dismissPanel.bind(this);
        this.openPanel = this.openPanel.bind(this);
    }

    dismissPanel = () => {
        this.setState({
            panelOpen: false,
        });
    }

    openPanel = () => {
        this.setState({
            panelOpen: true,
        });
    }

    render() {
        let currency = '';
        if (this.props.region !== undefined && this.props.region !== null) {
            switch (this.props.region.currency) {
                case 'EUR':
                    currency = '€';
                    break;
                case 'USD':
                    currency = '$';
                    break;
                case 'GBP':
                    currency = '£';
                    break;
                case 'YEN':
                    currency = '¥';
                    break;
            }
        }

        return (
            <div
                id="002"
                style={{
                    position: 'fixed',
                    width: '100%',
                    zIndex: 3333,
                }}
                className="PageHeader"
            >
                <Panel
                    isOpen={this.state.panelOpen}
                    onDismiss={this.dismissPanel}
                    type={PanelType.smallFluid}
                    onRenderNavigationContent={() => (
                        <div style={{ padding: '25px' }}>
                            <PrimaryButton
                                style={{
                                    color: 'white',
                                    fontSize: '16px',
                                    background: headerLightBlue,
                                }}
                                onClick={this.dismissPanel}
                                text="Back To Shop"
                                className="buyButton"
                            />
                            {' '}

                        </div>
                    )}

                >
                    {
                        // TODO Replace fallback data with data from the backen product query
                    }
                    <Pricing caller={this.props.caller} closeFunction={this.dismissPanel} authorization={this.props.auth} authContext={this.props.authContext} currency={currency} data={fallbackData(this.props.region)} />
                </Panel>
                <div className="header-col-1">
                    <a href="https://asctechnologies.com"><img src={solutenLogo} height="64px" className="headerLogo" /></a>
                </div>
                <div style={{
                    float: 'right',
                    marginTop: '14px',
                }}
                >

                    <PrimaryButton
                        onClick={this.props.callBack}
                        style={{
                            color: 'white',
                            background: headerGreen,
                            marginTop: '10px',
                            marginRight: '30px',
                            marginLeft: '5px',
                            fontSize: '16px',
                        }}
                        text="Get It Now"
                    />

                </div>
                <div style={{
                    float: 'right',
                    marginTop: '14px',
                }}
                >

                    <PrimaryButton
                        onClick={this.openPanel}
                        style={{
                            color: headerBlue,
                            background: 'white',
                            marginTop: '10px',
                            marginRight: '20px',
                            marginLeft: '10px',
                            fontSize: '16px',

                        }}
                        text="Packages"
                    />

                </div>

                <div style={{
                    float: 'right',
                    marginTop: '14px',
                }}
                >

                    <PrimaryButton
                        style={{
                            color: headerBlue,
                            background: 'white',
                            marginTop: '10px',
                            marginRight: '30px',
                            marginLeft: '5px',
                            fontSize: '16px',
                        }}
                        text="Self-Service"
                    />

                </div>
            </div>
        );
    }
}


export { ASCHeaderBuyNow, ASCHeader };
