import { Checkbox, Stack, TextField, PrimaryButton, SecondaryButton, DefaultButton, Dropdown, Toggle, Separator, Spinner } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import * as QueryPR from '../PolicyRulesQuery';
import FormKeywords from './FormKeywords';
import FormCategories from './FormCategories';
import FormPatternsDetection from './FormPatternsDetection';
import FormAdherencePhrases from './FormAdherencePhrases';
import FormAIAdherencePhrases from './FormAIAdherencePhrases';
import '../../ComplianceView.scss';
import { Query } from 'react-apollo';
import FormAIQuestions from './FormAIQuestions';


export default class EditPolicyRule extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        const policyRule = this.props.data !== undefined ? this.props.data : null
        this.state = {
            //page data
            processing: false,
            processingVersion: false,
            selectedArea: null,
            availableAreas: [],
            availableConnections: [],
            isConnectionAreaInAnd: true,
            deletedElement: false,
            //policy rule data
            id: policyRule !== null ? policyRule.id : null,
            name: policyRule !== null ? policyRule.name : "",
            description: policyRule !== null ? policyRule.description : "",
            personal: policyRule !== null ? policyRule.personal : false,
            userId: policyRule !== null ? policyRule.userId : null,
            tenantId: policyRule !== null ? policyRule.tenantId : null,
            policyRuleGroup: policyRule !== null ? policyRule.policyRuleGroup.id : null,
            generalGroup: policyRule !== null ? policyRule.policyRuleGroup.generalGroup : false,
            areas: policyRule !== null && policyRule.areas !== undefined ? this.getAreas(policyRule.areas) : [],
            version: policyRule !== null ? policyRule.version : 0,
            masterItemId: policyRule !== null ? policyRule.masterItemId : null,
            selected: policyRule !== null ? policyRule.selected : true,
            //versions
            selectedOtherVersion: false,
            selectedVersion: policyRule !== null ? policyRule.id : null
        }
    }

    getAreas = (areas) => {
        var availableAreas = areas;
        if (!this.props.ruleAccess.userComplianceAIQuestions) {
            availableAreas = areas.filter(x => x.searchAreaType != "AIQuestions");
        }

        return availableAreas.map(a => {
            return (
                {
                    connectionType: a.connectionType,
                    position: a.position,
                    frequence: a.frequence,
                    searchAreaType: a.searchAreaType,
                    areaItems: {
                        connectionType: a.areaItems.connectionType,
                        items: a.areaItems.items.map(i => {
                            return ({
                                operatorType: i.operatorType,
                                position: i.position,
                                value: i.value
                            })
                        })
                    }
                }
            )
        })
    }

    refetchQueryVersions = null;
    numberOfVersions = 1

    refreshVersions = () => {
        if (this.refetchQueryVersions !== null) {
            this.refetchQueryVersions()
        }
    }

    componentDidMount() {
        this.props.apol
            .query({
                query: QueryPR.getSearchAreaTypeEnum,
            })
            .then((result) => {
                var areas = result.data.getSearchAreaTypeEnum.filter(a => a.name !== "QMQuestions");
                if (!this.props.ruleAccess.userComplianceAIQuestions) {
                    areas = areas.filter(x => x.name != "AIQuestions");
                }
                var availableAreas = areas.map(r => {

                        return {
                            "key": r.name,
                            "text": this.context.getTranslation("compliance", r.name)
                        }
  
                })
                this.setState({
                    availableAreas
                })
            });

        this.props.apol
            .query({
                query: QueryPR.getConnectionTypeEnum,
            })
            .then((result) => {
                var availableConnections = []
                result.data.getConnectionTypeEnum.forEach(r => {
                    if (r.name !== "NONE")
                        availableConnections.push({
                            "key": r.name,
                            "text": this.context.getTranslation("compliance", "connection_"+r.name)
                        })
                })
                this.setState({
                    availableConnections
                })
            });

        this.refreshVersions()
    }

    changeName = (e, value) => {
        this.setState({
            name: value,
            selectedOtherVersion: false,
            deletedElement: false,
            areas: this.state.deletedElement ? [] : this.state.areas,
        })
    }

    changeDescription = (e, value) => {
        this.setState({
            description: value,
            selectedOtherVersion: false,
            deletedElement: false,
            areas: this.state.deletedElement ? [] : this.state.areas,
        })
    }

    onChangePersonal = () => {
        this.setState({
            personal: !this.state.personal,
            selectedOtherVersion: false,
            deletedElement: false,
            areas: this.state.deletedElement ? [] : this.state.areas,
        })
    }

    /*Buttons cancel and save*/

    cancel = () => {
        this.props.closePanel()
    }

    save = (saveAsTemplate) => {
        this.setState({ processing: true })
        const policyRule = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            personal: this.state.personal,
            userId: this.state.userId,
            tenantId: this.state.tenantId,
            policyRuleGroup: { id: this.state.policyRuleGroup, generalGroup: this.state.generalGroup != null ? this.state.generalGroup : saveAsTemplate },
            areas: this.state.areas,
            // manage version
            version: this.numberOfVersions,
            masterItemId: this.state.masterItemId,
            selected: this.state.selected,
        }
        this.props.apol
            .mutate({
                mutation: saveAsTemplate ? QueryPR.createPolicyRuleTemplate : QueryPR.createPolicyRule,
                variables: { policyRule }
            })
            .then((result) => {
                var resultData = saveAsTemplate ? result.data.createPolicyRuleTemplate : result.data.createPolicyRule
                var state = {}
                if (resultData.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshPolicyRules()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", resultData.text))
                }
                this.setState({ ...state, processing: false })
            });
    }

    //form validation -> disable save button with this method
    isValid = () => {
        var allAreasAreValid = true;
        this.state.areas.forEach(area => {
            if (area.areaItems.connectionType == "AT_LEAST" && area.frequence > area.areaItems.items.length) {
                allAreasAreValid = false
            }
            if ((area.areaItems.connectionType === null && area.areaItems.items.length > 1) || (area.frequence < 1 || isNaN(area.frequence)) || area.areaItems.items.length === 0) {
                allAreasAreValid = false;
            }
        })
        return this.state.name !== "" && this.state.areas.length > 0 && allAreasAreValid
    }

    onChangeSelectArea = (e, item) => {
        if (item !== null) {
            this.setState({
                selectedArea: item.key,
                deletedElement: false,
                areas: this.state.deletedElement ? [] : this.state.areas,
            })
        }
    }

    changeConnectionArea = (e, checked) => {
        this.setState({
            isConnectionAreaInAnd: checked
        })
    }

    addArea = () => {
        if (this.state.selectedArea !== null) {
            const newArea = {
                searchAreaType: this.state.selectedArea,
                position: this.state.areas.length,
                connectionType: this.state.areas.length === 0 ? "NONE" : this.state.isConnectionAreaInAnd ? "AND" : "OR",
                frequence: 1,
                areaItems: {
                    connectionType: null,
                    items: []
                }
            }
            this.setState({
                areas: [...this.state.areas, newArea],
                selectedArea: null,
                isConnectionAreaInAnd: true,
                selectedOtherVersion: false
            })
        }
    }

    deleteArea = (position) => {
        var newAreas = this.state.areas.filter(area => area.position !== position);
        newAreas = newAreas.map((area, i) => {
            return (
                {
                    ...area,
                    position: i,
                    frequence: 1,
                    connectionType: i === 0 ? "NONE" : area.connectionType
                }
            )
        })
        this.setState({
            selectedOtherVersion: false,
            deletedElement: false,
            areas: this.state.deletedElement ? [] : newAreas,
        })
    }

    updateItems = (position, elements) => {
        const newAreas = this.state.areas.map(area => {
            if (area.position === position) {
                return (
                    {
                        ...area,
                        areaItems: {
                            connectionType: area.areaItems.connectionType,
                            items: elements.map((e, i) => {
                                return ({
                                    position: i,
                                    operatorType: "equals",
                                    value: e
                                })
                            })
                        }
                    }
                )
            } else {
                return area
            }
        })
        this.setState({
            areas: newAreas,
            selectedOtherVersion: false
        })
    }

    updateConnectionInsideArea = (position, connection) => {
        const newAreas = this.state.areas.map(area => {
            if (area.position === position) {
                return (
                    {
                        ...area,
                        areaItems: {
                            connectionType: connection,
                            items: area.areaItems.items,
                        }
                    }
                )
            } else {
                return area
            }
        })
        this.setState({
            areas: newAreas,
            selectedOtherVersion: false
        })
    }

    updateFrequenceInsideArea = (position, frequence) => {
        const newAreas = this.state.areas.map(area => {
            if (area.position === position) {
                return (
                    {
                        ...area,
                        frequence: frequence
                    }
                )
            } else {
                return area
            }
        })
        this.setState({
            areas: newAreas,
            selectedOtherVersion: false
        })
    }

    updateConnectionOutsideArea = (position) => {
        const newAreas = this.state.areas.map(area => {
            if (area.position === position) {
                return (
                    {
                        ...area,
                        connectionType: area.connectionType === "AND" ? "OR" : "AND"
                    }
                )
            } else {
                return area
            }
        })
        this.setState({
            areas: newAreas,
            selectedOtherVersion: false
        })
    }

    onChangeVersion = (e, item) => {
        //get specific version to fill the fields
        this.setState({ processingVersion: true })
        let id = item.key
        this.props.apol
            .query({
                query: QueryPR.queryPolicyRule,
                variables: { id }
            })
            .then(({ loading, error, data, refetch }) => {
                let oldVersion = data.getPolicyRule;
                this.setState({
                    name: oldVersion.name,
                    description: oldVersion.description,
                    personal: oldVersion.personal,
                    userId: oldVersion.userId,
                    policyRuleGroup: oldVersion.policyRuleGroup.id,
                    generalGroup: oldVersion.policyRuleGroup.generalGroup,
                    areas: this.getAreas(oldVersion.areas),
                    tenantId: oldVersion.tenantId,
                    selectedVersion: id,
                    selectedOtherVersion: true,
                    processingVersion: false
                })
            });
    }

    restoreVersion = () => {
        this.setState({ processing: true })
        //restorePolicyRule -> id vesrion to restore
        let id = this.state.selectedVersion
        this.props.apol
            .mutate({
                mutation: QueryPR.restorePolicyRule,
                variables: { id }
            })
            .then((result) => {
                if (result.data.restorePolicyRule.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshPolicyRules()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.restorePolicyRule.text))
                }
                this.setState({
                    selectedOtherVersion: false,
                    processing: false
                })
            });
    }

    checkElementToRestoreOldVersion = (deletedElement) => {
        this.setState({
            deletedElement
        })
    }

    renderAreas = () => {
        //console.log(this.state.areas)
        if (this.state.areas.length > 0 && this.state.availableAreas.length > 0 && this.state.availableConnections.length > 0) {
            const result = this.state.areas.map(area => {
                return (<div key={area.position}>
                    {area.connectionType !== "NONE" &&
                        <Separator className="separator">
                            <div style={{ padding: "5px", marginTop: "5px" }}>
                                <Toggle
                                    label=""
                                    checked={area.connectionType === "AND"}
                                    onText="AND"
                                    offText="OR"
                                    onChange={() => this.updateConnectionOutsideArea(area.position)}
                                    disabled={this.state.deletedElement} />
                            </div>
                        </Separator>}
                    {area.searchAreaType === "Keywords" &&
                        <FormKeywords
                            keywordsCheck={true}
                            keywords={area.areaItems.items.length > 0 ? area.areaItems.items.map(i => i.value) : []}
                            frequence={area.frequence != undefined && area.frequence != "" ? area.frequence : 1}
                            updateKeywords={this.updateItems}
                            position={area.position}
                            deleteArea={this.deleteArea}
                            updateConnectionInsideArea={this.updateConnectionInsideArea}
                            updateFrequenceInsideArea={this.updateFrequenceInsideArea}
                            connectionInsideArea={area.areaItems.connectionType}
                            availableConnections={this.state.availableConnections}
                            disabled={this.state.deletedElement}
                        />
                    }
                    {area.searchAreaType === "Categories" &&
                        <FormCategories
                            categoriesCheck={true}
                            selectedKeywordCategories={area.areaItems.items.length > 0 ? area.areaItems.items.map(i => i.value) : []}
                            frequence={area.frequence != undefined && area.frequence != "" ? area.frequence : 1}
                            updateCategories={this.updateItems}
                            apol={this.props.apol}
                            auth={this.props.auth}
                            position={area.position}
                            deleteArea={this.deleteArea}
                            updateConnectionInsideArea={this.updateConnectionInsideArea}
                            updateFrequenceInsideArea={this.updateFrequenceInsideArea}
                            connectionInsideArea={area.areaItems.connectionType}
                            availableConnections={this.state.availableConnections}
                            checkElementToRestoreOldVersion={this.checkElementToRestoreOldVersion}
                        />}
                    {area.searchAreaType === "Patterns" &&
                        <FormPatternsDetection
                            patternsCheck={true}
                            selectedPatterns={area.areaItems.items.length > 0 ? area.areaItems.items.map(i => i.value) : []}
                            frequence={area.frequence != undefined && area.frequence != "" ? area.frequence : 1}
                            updatePatterns={this.updateItems}
                            apol={this.props.apol}
                            auth={this.props.auth}
                            position={area.position}
                            deleteArea={this.deleteArea}
                            updateConnectionInsideArea={this.updateConnectionInsideArea}
                            updateFrequenceInsideArea={this.updateFrequenceInsideArea}
                            connectionInsideArea={area.areaItems.connectionType}
                            availableConnections={this.state.availableConnections}
                            checkElementToRestoreOldVersion={this.checkElementToRestoreOldVersion}
                        />
                    }
                    {area.searchAreaType === "AdherencePhrases" &&
                        <FormAdherencePhrases
                            selectedPhrases={area.areaItems.items.length > 0 ? area.areaItems.items.map(i => i.value) : []}
                            frequence={area.frequence != undefined && area.frequence != "" ? area.frequence : 1}
                            updatePhrases={this.updateItems}
                            apol={this.props.apol}
                            auth={this.props.auth}
                            position={area.position}
                            deleteArea={this.deleteArea}
                            updateConnectionInsideArea={this.updateConnectionInsideArea}
                            updateFrequenceInsideArea={this.updateFrequenceInsideArea}
                            connectionInsideArea={area.areaItems.connectionType}
                            availableConnections={this.state.availableConnections}
                            checkElementToRestoreOldVersion={this.checkElementToRestoreOldVersion}
                        />
                    }
                    {area.searchAreaType === "AIQuestions" &&
                        <FormAIQuestions
                            selectedQuestions={area.areaItems.items.length > 0 ? area.areaItems.items.map(i => i.value) : []}
                            frequence={area.frequence != undefined && area.frequence != "" ? area.frequence : 1}
                            updateQuestions={this.updateItems}
                            apol={this.props.apol}
                            auth={this.props.auth}
                            position={area.position}
                            deleteArea={this.deleteArea}
                            updateConnectionInsideArea={this.updateConnectionInsideArea}
                            updateFrequenceInsideArea={this.updateFrequenceInsideArea}
                            connectionInsideArea={area.areaItems.connectionType}
                            availableConnections={this.state.availableConnections}
                        />
                    }
                    {area.searchAreaType === "AIAdherencePhrases" &&
                        <FormAIAdherencePhrases
                            selectedPhrases={area.areaItems.items.length > 0 ? area.areaItems.items.map(i => i.value) : []}
                            frequence={area.frequence != undefined && area.frequence != "" ? area.frequence : 1}
                            updatePhrases={this.updateItems}
                            apol={this.props.apol}
                            auth={this.props.auth}
                            position={area.position}
                            deleteArea={this.deleteArea}
                            updateConnectionInsideArea={this.updateConnectionInsideArea}
                            updateFrequenceInsideArea={this.updateFrequenceInsideArea}
                            connectionInsideArea={area.areaItems.connectionType}
                            availableConnections={this.state.availableConnections}
                            checkElementToRestoreOldVersion={this.checkElementToRestoreOldVersion}
                        />
                    }
                </div>
                )
            })
            return result;
        } else {
            return this.state.areas.length > 0 ? (
                <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
            ) : ""
        }
    }

    render() {
        var saveAsTemplateStyle = { marginTop: "30px", marginLeft: "15px" }
        return (
            <div>
                {
                    this.state.processingVersion && <div>
                        <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                    </div>
                }
                {
                    !this.state.processingVersion && <div>
                        {/*QUERY ALL VERSIONS*/}
                        {this.state.masterItemId !== null && <Query
                            query={QueryPR.getAllVersionOfPolicyRule}
                            variables={{ masterItemId: this.state.masterItemId }}>
                            {({ loading, error, data, refetch }) => {
                                this.refetchQueryVersions = refetch
                                if (loading) {
                                    return (<Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />)
                                }
                                if (error) {
                                    return ""
                                }
                                if (data.getAllVersionOfPolicyRule == null) {
                                    return ""
                                }
                                let versions = data.getAllVersionOfPolicyRule.map(v => {
                                    return {
                                        "key": v.id,
                                        "text": v.version
                                    }
                                })
                                this.numberOfVersions = versions.length
                                return (<div>
                                    {versions.length > 0 && <Stack >
                                        <Stack.Item>
                                            <Dropdown
                                                label={this.context.getTranslation("compliance", "version")}
                                                selectedKey={this.state.selectedVersion}
                                                onChange={this.onChangeVersion}
                                                options={versions}
                                            />
                                        </Stack.Item>
                                    </Stack>}
                                </div>
                                )
                            }}
                        </Query>}
                        <TextField label={this.context.getTranslation("compliance", "name")} value={this.state.name} onChange={this.changeName} required />
                        <TextField label={this.context.getTranslation("compliance", "description")} value={this.state.description} onChange={this.changeDescription} />
                        <Stack style={{ marginTop: "10px" }}>
                            <Checkbox label={this.context.getTranslation("compliance", "personal")} checked={this.state.personal} onChange={this.onChangePersonal} />
                        </Stack>
                        <Stack style={{ marginTop: "20px" }}>
                            <h4>{this.context.getTranslation("compliance", "textbasedIdentifiers")}</h4>
                        </Stack>
                        {this.renderAreas()}
                        {this.state.areas.length > 0 && <Separator className="separator" />}
                        <Stack horizontal style={{ marginTop: "10px" }} >
                            <Stack.Item align="center" grow>
                                <Dropdown
                                    placeholder={this.context.getTranslation("compliance", "selectArea")}
                                    label=""
                                    selectedKey={this.state.selectedArea}
                                    onChange={this.onChangeSelectArea}
                                    options={this.state.availableAreas}
                                />
                            </Stack.Item>
                            {this.state.areas.length > 0 && < Stack.Item align="center" >
                                <div style={{ marginLeft: "10px", marginTop: "5px" }}>
                                    <Toggle label="" checked={this.state.isConnectionAreaInAnd} onText="AND" offText="OR" onChange={this.changeConnectionArea} />
                                </div>
                            </Stack.Item>}
                            <Stack.Item align="center" grow>
                                <PrimaryButton style={{ marginLeft: "30px" }} text={this.context.getTranslation("compliance", "add")} onClick={this.addArea} allowDisabledFocus />
                            </Stack.Item>
                        </Stack>
                        {/*FOOTER BUTTONS*/}
                        <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                            <DefaultButton text={this.context.getTranslation("common", "cancel")} onClick={this.cancel} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={this.state.processing} />
                            {(!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion) &&
                                <PrimaryButton text={this.context.getTranslation("common", "save")} onClick={() => this.save(false)} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                            }
                            {(this.props.userRules.userTemplateAllowed && (!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion)) &&
                                <PrimaryButton text={this.context.getTranslation("common", "saveAsTemplate")} onClick={() => this.save(true)} style={!this.isValid() || this.state.processing ? saveAsTemplateStyle : { ...saveAsTemplateStyle, background: "red", color: "white" }} allowDisabledFocus={true} disabled={!this.isValid() || this.state.processing} />
                            }
                            {this.state.selectedOtherVersion && this.state.id !== this.state.selectedVersion &&
                                <PrimaryButton text={this.context.getTranslation("compliance", "restoreVersion")} onClick={this.restoreVersion} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                            }

                        </Stack>
                    </div>
                }
            </div>
        )
    }


}