import { Stack, Dropdown, Spinner, PrimaryButton, Text, DefaultButton, Dialog, DialogFooter, DialogType } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../../context';
import {
    getAllFoldersShrinked,
    saveFoldersInConversation
} from "../../../../ConversationSafe/ConversationSafeQuery";
import { queryGetEntry } from "../../../../../queries/queryRecordings";
import { SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import _ from 'lodash'
import { MessageBar, MessageBarType } from "office-ui-fabric-react";


export default class FormConversationSafeFolders extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        let { data: propsData } = this.props

        let dbDataDefault = propsData.conversationSafeFolders.length == 0
            ? (propsData.conversationSafe === true ?
                ["-1"] :
                []
            ) : propsData.conversationSafeFolders.map(folder => folder.id).sort()

        this.state = {
            conversationSafeFolders: [],
            availableFolders: null,
            dbData: dbDataDefault,
            selectedFolders: dbDataDefault,
            mutationLoading: false, //mutation loading of saving
            selectionModified: false, //if changed the dropdown
            propsUpdated: true, //if getEntry is finished
            alertMessagePanel: "",
            isOpenWarningLastFolder: false
        }
    }


    componentDidMount() {
        const auth = this.props.auth
        this.props.apol
            .query({
                query: getAllFoldersShrinked,
                variables: { input: auth }
            })
            .then((result) => {
                let conversationSafeFolders = result.data.getConversationSafeFolders.filter(e => e.id !== null)
                this.setState({
                    conversationSafeFolders,
                    availableFolders: this.getAvailableFolders(conversationSafeFolders)
                })
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let arr = this.props.data.conversationSafeFolders.length == 0
            ? (this.props.data.conversationSafe === true ?
                ["-1"] :
                []
            ) : this.props.data.conversationSafeFolders.map(folder => folder.id).sort()

        if (!_.isEqual(prevState.dbData, arr)) {
            this.props.isProcessing(false)
            //props of data has changed and is now different from dbData state, which holds
            // previous version of the props. we are ready to stop the spinner
            this.setState({
                mutationLoading: false,
                dbData: arr,
                selectionModified: false,
                propsUpdated: true
            })
        }
    }

    getAvailableFolders = (allFolders) => {
        let disabledFolder = this.getFolderToDisabled(allFolders)
        let availableFolders = allFolders.map(e => {
            return ({
                "key": e.id,
                "text": e.name,
                disabled: disabledFolder.includes(e.id)
            })
        })
        return availableFolders
    }

    getFolderToDisabled = (allFolders) => {
        let disabledFolder = []
        if (this.props.ruleAccess.userSafe === "Restricted") {
            let ownAdd = this.props.ruleAccess.ownFolder.addConversations
            let ownRemove = this.props.ruleAccess.ownFolder.removeConversations
            for (var i = 0; i < allFolders.length; i++) {
                var f = allFolders[i]
                //my folder
                if (f.userId == this.props.userId) {
                    if (this.state.dbData.includes(f.id) && !ownRemove) {
                        disabledFolder.push(f.id)
                    } 
                    if (!this.state.dbData.includes(f.id) && !ownAdd) {
                        disabledFolder.push(f.id)
                    }
                } else {
                    if (this.state.dbData.includes(f.id) && !f.removeConversations) {
                        disabledFolder.push(f.id)
                    }
                    if (!this.state.dbData.includes(f.id) && !f.addConversations) {
                        disabledFolder.push(f.id)
                    }
                }
            }
        }
        return disabledFolder;
    }

    clearAlert = () => {
        if (this.state.alertMessagePanel !== "") {
            this.setState({
                alertMessagePanel: ""
            })
        }
    }

    onChange = (event, folder) => {
        //when unassigned folder is selected
        let selectedFolders
        if (folder.key == '-1' && folder.selected) {
            selectedFolders = ['-1']
        } else {
            selectedFolders = (folder.selected ?
                [...this.state.selectedFolders, folder.key].filter(id => id != '-1').sort() :
                this.state.selectedFolders.filter(e => e != folder.key)).sort()
        }
        let selectionModified = !_.isEqual(this.state.dbData, selectedFolders)

        this.setState({
            selectedFolders,
            selectionModified
        });
    }

    checkLastFolder = () => {
        if (this.state.selectedFolders.length !== 0) {
            this.save()
        } else {
            this.setState({ isOpenWarningLastFolder: true })
        }
    }

    save = () => {
        //call f in detailPivot to update
        this.setState({
            mutationLoading: true,
            propsUpdated: false
        })
        this.props.isProcessing(true)
        const auth = this.props.auth
        this.props.apol
            .mutate({
                mutation: saveFoldersInConversation,
                variables: {
                    input: auth,
                    conversationId: this.props.conversationId,
                    folders: this.state.selectedFolders
                },
                refetchQueries: [{
                    query: queryGetEntry,
                    variables: { id: this.props.conversationId, auth: auth, callStartMs: this.props.data.callStartMs }
                }]
            })
            .then(res => {
                if (res.data.saveFoldersInConversation.status !== "ok") {
                    this.setState({
                        alertMessagePanel: this.context.getTranslation("conversationSafe", res.data.saveFoldersInConversation.text),
                        mutationLoading: false,
                        availableFolders: this.getAvailableFolders(this.state.conversationSafeFolders)
                    })
                } else {
                    if (this.props.refreshFolderList != undefined) this.props.refreshFolderList()
                }
            })
            .catch(err => {
                //TODO for Alessandro: manage an internazionaliz error message also here
                this.setState({
                    mutationLoading: false
                })
            });

    }

    render() {
        return (
            <div>
                <Stack horizontal>
                    <span style={{ marginLeft: "5px", marginTop: "5px", fontWeight: "bold" }}>
                        {`${this.context.getTranslation("detailContainer", "selectFolderInConversation")}`}
                    </span>
                </Stack>
                {this.state.alertMessagePanel !== "" &&
                    <Stack style={{ marginTop: "10px", width: "500px" }}>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            onDismiss={this.clearAlert}>
                            {this.state.alertMessagePanel}
                        </MessageBar>
                    </Stack>
                }
                <Stack horizontal style={{ marginTop: "10px", marginLeft: "0px" }}>
                    <Stack.Item align="center" style={{ maxWidth: "600px", minWidth: "200px", marginRight: "20px" }}>
                        {this.state.availableFolders === null &&
                            <Spinner style={{ paddingLeft: '5px', position: "relative", top: "2px" }} size={SpinnerSize.xSmall} />
                        }
                        {this.state.availableFolders !== null && this.state.availableFolders.length === 0 &&
                            <Text>No folder available!</Text>
                        }
                        {this.state.availableFolders !== null && this.state.availableFolders.length > 0 &&
                            <Dropdown
                                placeholder={this.context.getTranslation("detailContainer", "foldersId")}
                                label=""
                                onChange={this.onChange}
                                multiSelect
                                selectedKeys={this.state.selectedFolders}
                                options={this.state.availableFolders}
                            />
                        }
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton text={
                            (this.state.mutationLoading || !this.state.propsUpdated) ?
                                (<Spinner style={{ paddingLeft: '5px', position: "relative", top: "2px" }} size={SpinnerSize.xSmall} />) :
                                (this.context.getTranslation("common", "save"))
                        }
                            onClick={this.checkLastFolder}
                            disabled={this.state.mutationLoading || !this.state.propsUpdated || !this.state.selectionModified} />
                    </Stack.Item>
                </Stack>
                {/*DIALOG WARNING DELETE LAST FOLDER*/}
                <Dialog
                    isOpen={this.state.isOpenWarningLastFolder}
                    type={DialogType.close}
                    onDismiss={() => { this.setState({ isOpenWarningLastFolder: false }) }}
                    title={this.context.getTranslation("compliance", "riskRatingAdherence")}
                    subText={this.context.getTranslation("conversationSafe", "teachingBubbleTextLastFolder")}
                    isBlocking={false}
                    closeButtonAriaLabel='Close'
                >
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                this.setState({ isOpenWarningLastFolder: false })
                                this.save()
                            }}
                            text={this.context.getTranslation("common", "remove")} />
                        <DefaultButton onClick={() => { this.setState({ isOpenWarningLastFolder: false }) }} text={this.context.getTranslation("common", "cancel")} />
                    </DialogFooter>
                </Dialog>
            </div>
        )
    }
}