import React from 'react'
import { Checkbox, Stack } from 'office-ui-fabric-react'
import { MyContext } from '../../../../context'

const checkboxStyles = () => {
  return {
    root: {
      marginTop: '10px'
    }
  };
};

//This components was initially intended to serve on multiple parts of the project but as its logic was further refined
// became very specialized and it would now be better changed its name to something less generic.

export default class ListOfDependentCheckboxes extends React.Component {
  
  static contextType = MyContext
  
  constructor(props) {
    super(props);
    this.currentCount = 0
    let initialSelectedCheckBoxCount = this.props.secondary.filter(e=> e.value).length ? this.props.secondary.filter(e=> e.value).length : 1
    this.state = {
      defaultIsNeeded: this.props.secondary.filter(e=> e.value).length ? false : true,
      secondaryCBCheckedN: initialSelectedCheckBoxCount
    }
    this.props.updateParentState({
      visibilityCount: initialSelectedCheckBoxCount })
  
  
  }
  
  updateCount = (lastCBHasBeenFlagged) => {
    if(lastCBHasBeenFlagged){
      if(this.state.secondaryCBCheckedN == 1 && this.state.defaultIsNeeded){
          this.setState({
            defaultIsNeeded:false,
            secondaryCBCheckedN: 1
          })
          this.currentCount = 1
      } else{
        this.setState({
          defaultIsNeeded:false,
          secondaryCBCheckedN: this.state.secondaryCBCheckedN +1
        })
        this.currentCount = this.state.secondaryCBCheckedN +1
      }
    } else{
      if(this.state.secondaryCBCheckedN > 1){
        this.setState({
          secondaryCBCheckedN: this.state.secondaryCBCheckedN - 1
        })
        this.currentCount = this.state.secondaryCBCheckedN -1
      }else{
        this.setState({
          defaultIsNeeded: true,
          secondaryCBCheckedN: 0
        })
        this.currentCount = 0
      }
    }
  }
  
  
  updateStateInParentComponent = (newState) => {
    this.props.updateParentState({
      ...newState,
      visibilityCount: this.currentCount })
  }

render(){
    return (
        <Stack vertical>
          <Checkbox
              label={this.context.getTranslation(this.props.primary.translation.key1, this.props.primary.translation.key2)}
              checked={this.props.primary.value ?? false}
              styles={checkboxStyles}
              onChange={(event, newValue) => {
                let newState = {}
                newState[this.props.primary.key] = newValue
                this.updateStateInParentComponent(newState)
              }
          }
          />
          {this.props.primary.value && <Stack vertical style={{marginLeft:15}}>
            <div style={{marginBottom:10, marginTop:5}}>{this.context.getTranslation('configuration', 'visibilityCSFolderPanelLabel')}</div>
            {this.props.secondary.slice(0,-1).map((e, i) => {
                  return (<Checkbox
                      className={'secondaryCheckboxes'}
                      key={`visibility-${i}`}
                      disabled={(!(e?.rules ?? false) && (this.props.primary.rules))  }
                      label={this.context.getTranslation(e.translation.key1, e.translation.key2)}
                      checked={(e.value ?? false)}
                      styles={checkboxStyles}
                      onChange={(event, newValue) => {
                        let newState = {}
                        newState[e.key] = newValue
                        this.updateCount(newValue)
                        this.updateStateInParentComponent(newState)
                      }}
                  />)
                }
            )}
            {/*This CB comes from the same array of data as the above CBs in this same section of the component but needs*/}
            {/*to implement a different logic for its checked and disabled properties. That's why is on its own.*/}
            <Checkbox
                className={'secondaryCheckboxes'}
                // disabled={}
                label={this.context.getTranslation('configuration', 'replayVisibilityDetailsReplay')}
                checked={(this.props.secondary.at(-1).value ?? false)}
                styles={checkboxStyles}
                onChange={(event, newValue) => {
                  let newState = {}
                  newState[this.props.secondary.at(-1).key] = newValue
                  this.updateCount(newValue)
                  this.updateStateInParentComponent(newState)
                }}
            />
          
          </Stack>}
    </Stack>
    )
}
}