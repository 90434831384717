/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react'
import { Stack, CommandBarButton, Panel, PanelType, Spinner, MarqueeSelection, DetailsList, SelectionMode, Selection } from 'office-ui-fabric-react';
import { MyContext } from '../../../context';
import SafetyDialog from '../../CustomComponents/SafetyDialog';
import AlertBar from '../../CustomComponents/AlertBar';
import { queryAllScorecards, deleteScorecardQuery } from "./AIQualityManagementQuery";
import { Query } from 'react-apollo';
import { NetworkStatus } from 'apollo-boost';
import EditScorecardContainer from './Panels/EditScorecardContainer';

export default class AIQualityManagement extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);

        this.selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    scorecardId: this.selection.getSelection().length > 0 ? this.selection.getSelection()[0].id : null,
                    warningMessage: "",
                });
            }
        });

        this.state = {
            isProcessing: false,
            hideDialogDelete: true,
            warningMessage: "",
            alertType: "warning",
            scorecardId: null,
            isPanelOpen: false,
            panelTitle: "",
            action: "create",
            alertMessagePanel: "",
            scorecards: [],
            columns: []
        };
    }

    refetchQueryScorecards = null

    componentDidMount() {
        const columns = [
            {
                key: 'name',
                name: this.context.getTranslation("compliance", "name"),
                fieldName: 'name',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true
            },
            {
                key: 'description',
                name: this.context.getTranslation("compliance", "description"),
                fieldName: 'description',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true
            },
            {
                key: "automatedEvaluation",
                name: this.context.getTranslation("compliance", "evaluationType"),
                fieldName: "automatedEvaluation",
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                onRender: (item) => {
                    return <span>{item.automatedEvaluation ?
                        this.context.getTranslation("compliance", "automated") :
                        this.context.getTranslation("compliance", "manual")}
                    </span>
                }
            },
            {
                key: "version",
                name: this.context.getTranslation("compliance", "version"),
                fieldName: "version",
                minWidth: 60,
                maxWidth: 60,
                isResizable: true
            },
            {
                key: "creator",
                name: this.context.getTranslation("compliance", "creator"),
                fieldName: "creator",
                minWidth: 100,
                maxWidth: 150,
                isResizable: true
            },
            {
                key: "creationDate",
                name: this.context.getTranslation("compliance", "creationDate"),
                fieldName: "creationDate",
                minWidth: 100,
                maxWidth: 150,
                isResizable: true
            },
            {
                key: "lastUpdateDate",
                name: this.context.getTranslation("compliance", "lastUpdateDate"),
                fieldName: "lastUpdateDate",
                minWidth: 100,
                maxWidth: 150,
                isResizable: true
            }
        ]
        this.setState({ columns })
    }

    getHeaderPanel = () => {
        return (
            <div style={{ flex: "auto", marginLeft: "20px" }}>
                <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>
                    {this.context.getTranslation("compliance", this.state.panelTitle)}
                </div>
                <AlertBar
                    message={this.state.alertMessagePanel}
                    alertType={"error"}
                    clearAlert={() => {
                        if (this.state.alertMessagePanel !== "")
                            this.setState({ alertMessagePanel: "" })
                    }}
                />
            </div >
        )
    }

    openPanel = (type, scorecardId = null) => {
        if (type === "create") {
            this.setState({
                panelTitle: "createScorecardPanel",
                action: "create",
                isPanelOpen: true,
                warningMessage: ""
            })
        } else {
            if (scorecardId !== null) {
                this.setState({
                    panelTitle: "editScorecardPanel",
                    action: "edit",
                    isPanelOpen: true
                })
            } else {
                this.setState({
                    warningMessage: this.context.getTranslation("compliance", "selectScorecard"),
                    alertType: "warning"
                })
            }
        }
    }

    renderItemColumn = (item, index, column) => {
        //console.log(item, index, column)
        const fieldName = column.fieldName
        switch (fieldName) {
            case "automatedEvaluation":
                return <span>{item[fieldName] ? this.context.getTranslation("common", "yes") : this.context.getTranslation("common", "no")}</span>
            case "creationDate": {
                let date = new Date(item[fieldName])
                return <span>{date.toLocaleString()}</span>
            }
            case "lastUpdateDate": {
                let date = new Date(item[fieldName])
                return <span>{date.toLocaleString()}</span>
            }
            default: return <span>{item[fieldName]}</span>
        }
    }



    deleteScorecard = () => {
        this.setState({ hideDialogDelete: true, isProcessing: true })
        if (this.state.scorecardId !== null) {
            //console.log("delete scorecard", scorecardId)
            this.props.apol.mutate({
                mutation: deleteScorecardQuery,
                variables: { id: this.state.scorecardId }
            }).then((result) => {
                if (result.data.deleteScorecard.status !== "ok") {
                    this.setState({
                        warningMessage: this.context.getTranslation("compliance", result.data.deleteScorecard.text),
                        alertType: result.data.deleteScorecard.status,
                        isProcessing: false
                    })
                } else {
                    this.setState({ isProcessing: false })
                    this.refetchQueryScorecards();
                }

            });
        }
    }

    render() {
        return <div style={{ overflow: "auto", maxHeight: window.innerHeight - 120, position: 'relative', marginTop: "5px" }}>
            {/*Manage button add, edit and delete*/}
            <Stack horizontal styles={{ root: { height: 44 } }}>
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Add' }}
                    text={this.context.getTranslation("compliance", "addScorecard")}
                    disabled={false}
                    checked={false}
                    onClick={() => this.openPanel("create")}
                />
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Edit' }}
                    text={this.context.getTranslation("compliance", "editScorecard")}
                    disabled={false}
                    checked={false}
                    onClick={() => { this.openPanel("edit", this.state.scorecardId) }}
                />
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Delete' }}
                    disabled={false}
                    text={this.context.getTranslation("compliance", "deleteScorecard")}
                    onClick={() => {
                        if (this.state.scorecardId !== null)
                            this.setState({ hideDialogDelete: false })
                        else {
                            this.setState({
                                warningMessage: this.context.getTranslation("compliance", "selectScorecard"),
                                alertType: "warning"
                            })
                        }
                    }}
                />
            </Stack>
            {/*SafetyDialog for delete*/}
            <SafetyDialog
                title={this.context.getTranslation("compliance", "deleteScorecard")}
                subText={this.context.getTranslation("compliance", "confirmDelete")}
                hideDialog={this.state.hideDialogDelete}
                closeDialog={() => { this.setState({ hideDialogDelete: true }) }}
                ok={this.deleteScorecard}
            />
            {/*Warning and error message*/}
            <AlertBar
                message={this.state.warningMessage}
                alertType={this.state.alertType}
                clearAlert={() => { this.setState({ warningMessage: "" }) }}
            />
            {this.state.isProcessing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}
            {!this.state.isProcessing &&
                <Query
                    query={queryAllScorecards}
                    notifyOnNetworkStatusChange={true}>
                    {({ loading, error, data, refetch, networkStatus }) => {
                        this.refetchQueryScorecards = refetch;
                        if (loading || networkStatus === NetworkStatus.refetch) return (
                            <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                        )
                        if (error) {
                            console.log(error)
                            return (
                                <AlertBar message={this.context.getTranslation("compliance", "error")} alertType="error" />
                            );
                        }
                        if (data.getScorecards == null) return (
                            <AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />
                        );
                        const scorecards = data.getScorecards
                        const groupsFromScorecard = []
                        scorecards.forEach(s => {
                            let filterGroup = groupsFromScorecard.filter(g => g.group.id === s.scorecardGroup.id)
                            if (filterGroup.length === 0) {
                                groupsFromScorecard.push({ group: s.scorecardGroup, items: [s] })
                            } else {
                                filterGroup[0].items.push(s)
                            }
                        })
                        let items = []
                        let groups = []
                        groupsFromScorecard.sort((a, b) => b.group.generalGroup - a.group.generalGroup).forEach((g, i) => {
                            let startIndex = 0
                            if (i !== 0) {
                                const prevGroup = groups[i - 1]
                                startIndex = prevGroup.startIndex + prevGroup.count
                            }
                            groups.push({ key: g.group.id, name: g.group.name, startIndex, count: g.items.length, level: 0 })
                            items.push(...g.items)
                        })
                        return (
                            <div style={{ marginTop: 10 }}>
                                <MarqueeSelection selection={this.selection}>
                                    <DetailsList
                                        items={items}
                                        groups={groups}
                                        columns={this.state.columns}
                                        selectionMode={SelectionMode.single}
                                        selection={this.selection}
                                        setKey="single"
                                        onRenderItemColumn={this.renderItemColumn}
                                    />
                                </MarqueeSelection>
                            </div>
                        )
                    }}
                </Query>
            }
            {/*Scorecard panel*/}
            <Panel
                isOpen={this.state.isPanelOpen}
                onDismiss={() => {
                    this.setState({ isPanelOpen: false, alertMessagePanel: "" })
                }}
                isFooterAtBottom={false}
                onRenderHeader={this.getHeaderPanel}
                type={PanelType.medium}
            >
                <EditScorecardContainer
                    apol={this.props.apol}
                    auth={this.props.auth}
                    hidePanel={() => { this.setState({ isPanelOpen: false, alertMessagePanel: "" }) }}
                    updateScorecardId={(scorecardId) => { this.setState({ scorecardId }) }}
                    id={this.state.action === "create" ? null : this.state.scorecardId}
                    refreshScorecards={() => { this.refetchQueryScorecards() }}
                    userRules={this.props.userRules}
                    setMessageError={(message) => { this.setState({ alertMessagePanel: message }) }}
                />
            </Panel>
        </div>
    }
}
