import * as React from "react";
import Utils from "./Utils";


export default class AzurePlayer extends React.Component {


    constructor(props) {
        super(props);
        console.log("offset: " + this.props.offset);
        this.state = {
            uuid: Utils.guidGenerator(),
            offset: this.props.offset != undefined ? this.props.offset : 0
        };
    }

    componentDidMount() {
        if (this.props.streamingUrls && this.props.streamingUrls !== "error") {
            const myOptions = {
                "nativeControlsForTouch": false,
                "logo": { "enabled": false },
                controls: this.props.controls,
                autoplay: this.props.autoplay,
                width: this.props.width,
                fluid: this.props.fluid,
                plugins: {
                }
            };

            this.mPlayer = window.amp(this.state.uuid,
                myOptions,
                () => {
                    console.log("AMP ready");
                });

            var streamingUrl = this.props.streamingUrls;
            var encodedList = streamingUrl.split("|");
            if (encodedList.length > 1) {
                // Encoded stream
                this.mPlayer.src([
                    {
                        "src": encodedList[0],
                        "type": "application/vnd.ms-sstr+xml",
                        "protectionInfo": [{ "type": "AES", "authenticationToken": encodedList[1] }]
                    }
                ]);
            } else if (streamingUrl.search(".mp3") != -1) {
                // then sas token
                this.mPlayer.src([
                    {
                        "src": this.props.streamingUrls,
                        "type": "audio/mp3"
                    }
                ]);
            } else if (streamingUrl.search(".wav") != -1) {
                // then sas token
                this.mPlayer.src([
                    {
                        "src": this.props.streamingUrls,
                        "type": "audio/wav"
                    }
                ]);
            } else if (streamingUrl.search(".mp4") != -1) {
                // then sas token
                this.mPlayer.src([
                    {
                        "src": this.props.streamingUrls,
                        "type": "video/mp4"
                    }
                ]);
            } else {
                this.mPlayer.src([
                    {
                        "src": this.props.streamingUrls,
                        "type": "application/vnd.ms-sstr+xml"
                    }
                ]);
            }
            this.mPlayer.volume(this.props.volume);
            if (this.props.callbackTimeUpdate != null) {
                this.mPlayer.addEventListener("timeupdate", this.props.callbackTimeUpdate);
            }
            this.mPlayer.play();
            this.mPlayer.pause();
            this.mPlayer.currentTime(this.state.offset);
            if (this.props.autoplay) {
                this.mPlayer.play();
            }
        }
    }

    render() {
        if (this.props.streamingUrls != null) {
            if (this.props.streamingUrls === "error") {
                return (
                    <div>
                        Media Asset not playable
                </div>
                );
            } else {
                return (
                    
                        <video id={this.state.uuid
                        } className="azuremediaplayer amp-default-skin amp-big-play-centered"></video>


                );
            }
        } else {
            return (
                <div  >
                    Media Asset will shortly be playable,
                    please refresh the page
                </div>
            );
        }
    }

    jumpTo(timestamp) {
        if (this.mPlayer!==undefined && this.mPlayer!=null)
        {
        console.log(`Jumping to ${timestamp}`);
        this.mPlayer.currentTime(this.state.offset + timestamp);
        }
    }

    play() {
        if (this.mPlayer!==undefined && this.mPlayer!=null)
        {
            this.mPlayer.play();
            this.mPlayer.removeEventListener("loadeddata", this.props.callbackPlayerLoaded);
        }
    }

    isRunning() {
        if (this.mPlayer!==undefined && this.mPlayer!=null)
        {
            return !this.mPlayer.paused();
        }
        else
            {
                return null;
            }
    }

    pause() {
        if (this.mPlayer!==undefined && this.mPlayer!=null)
        {
            this.mPlayer.pause();
        }
    }

    getCurrentTime() {
        return this.mPlayer.currentTime()-this.state.offset;
    }

    getWidth() {
        if (this.mPlayer!==undefined && this.mPlayer!=null)
            {
                return this.mPlayer.width();
            }
        else return null
    }

    changeSource(val) {
        this.mPlayer.addEventListener("loadeddata", this.props.callbackPlayerLoaded);
        var encodedList = val.split("|");
        if (encodedList.length > 1) {
            // Encoded stream
            this.mPlayer.src([
                {
                    "src": encodedList[0],
                    "type": "application/vnd.ms-sstr+xml",
                    "protectionInfo": [{ "type": "AES", "authenticationToken": encodedList[1] }]
                }
            ]);
        } else if (val.search(".mp3") != -1) {
            // then sas token
            this.mPlayer.src([
                {
                    "src": val,
                    "type": "audio/mp3"
                }
            ]);
        } else if (val.search(".wav") != -1) {
            // then sas token
            this.mPlayer.src([
                {
                    "src": val,
                    "type": "audio/wav"
                }
            ]);
        } else if (val.search(".mp4") != -1) {
            // then sas token
            this.mPlayer.src([
                {
                    "src": val,
                    "type": "video/mp4"
                }
            ]);
        } else {
            this.mPlayer.src([
                {
                    "src": val,
                    "type": "application/vnd.ms-sstr+xml",
                }
            ]);
        }
        this.mPlayer.play();
        this.mPlayer.pause();
    }

    getHeight() {
        return this.mPlayer.height();
    }

    resize(width, height) {
        if (this.mPlayer) {
            if (width !== undefined)
                this.mPlayer.width(width);
            else
                this.mPlayer.width("auto");

            if (height !== undefined)
                this.mPlayer.height(height);
            else
                this.mPlayer.height("auto");
        }
    }
}