import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { MyContext } from '../../../context';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { FontSizes} from 'office-ui-fabric-react/lib';

import zipcelx from 'zipcelx';

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});

const config = {
    filename: 'TenantReport',
    sheet: {
        data: [
            [{
                value: "Title",
                type: 'string'
            },
                {
                    value: 'Created',
                    type: 'string'
                },
                {
                    value: 'Changed',
                    type: 'string'
                },
            {
                value: 'Priority',
                type: 'string'
            },
            {
                value: 'CaseId',
                type: 'string'
            },
            {
                value: 'State',
                type: 'string'
            },
            {
                value: 'TenantId',
                type: 'string'
            },
            {
                value: 'Description',
                type: 'string'
            }
            ]
        ]
    }
};

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}


export default class UserList extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: [];

    constructor(props) {
        super(props);


        let columns: IColumn[] = [
            {
                key: 'column1',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "options")}</>)}</MyContext.Consumer>,
                isIconOnly: true,
                isResizable: false,
                fieldName: 'title',
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <a style={{ cursor: "pointer" }} onClick={() => { this.props.func(item, 0) }}><Icon iconName={"Settings"} /></a>
                }
            },
            {
                key: 'column2',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketCaseId")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'caseId',

                minWidth: 110,
                maxWidth: 140,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'column3',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketPriority")}</>)}</MyContext.Consumer>,
                fieldName: 'priority',
                minWidth: 60,
                maxWidth: 60,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.priority === "SOS" ? "SOS" : (item.priority === "Critical" ? "Prio 1" : (item.priority === "Major" ? "Prio 2" : "Prio 3"))}</span>;
                }
            },
            {
                key: 'column4',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketState")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'state',

                minWidth: 80,
                maxWidth: 150,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'column5',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketChanged")}</>)}</MyContext.Consumer>,
                fieldName: 'changedMs',
                minWidth: 120,
                maxWidth: 120,
                isSortedDescending: true,
                isResizable: true,
                data: 'date',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.changed}</span>;
                }
            },
            {
                key: 'column6',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketTitle")}</>)}</MyContext.Consumer>,
                fieldName: 'title',
                minWidth: 160,
                maxWidth: 500,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick
            },
            {
                key: 'column7',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketCreated")}</>)}</MyContext.Consumer>,
                fieldName: 'createdMs',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                data: 'date',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.created}</span>;
                }
            },
            {
                key: 'column8',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketDetails")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'description',

                minWidth: 400,
                maxWidth: 1200,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'column9',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketTenantName")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'tenantId',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{this.tenantList[item.tenantId]}</span>;
                }
            },
            {
                key: 'column10',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "ticketTenantId")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'tenantId',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick
            }
            
        ];

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: true,
            items: this._allItems,
            filter: "",
            isDialogOpen: false
        };

    }

    _dialogClose = () => this.setState({ isDialogOpen: false })


    _onExport = (ev) => {
        var itemList = [];
        itemList.push(config.sheet.data[0].slice());
        this.userData.forEach((anItem) => {
            itemList.push([
                {
                    value: anItem.title,
                    type: 'string'
                },
                {
                    value: anItem.created,
                    type: 'string'
                },
                {
                    value: anItem.changed,
                    type: 'string'
                },
                {
                    value: anItem.priority === "SOS" ? "SOS" : (anItem.priority === "Critical" ? "Prio 1" : (anItem.priority === "Major" ? "Prio 2" : "Prio 3")),
                    type: 'string'
                },
                {
                    value: anItem.caseId,
                    type: 'string'
                },
                {
                    value: anItem.state,
                    type: 'string'
                },
                {
                    value: anItem.tenantId,
                    type: 'string'
                },
                {
                    value: anItem.description,
                    type: 'string'
                }
            ]);
        });
        config.sheet.data = itemList;
        zipcelx(config)
        this.setState({ isDialogOpen: true });
    }


    // Data for CommandBar
    getItems = () => {
        let addbutton = {
            key: 'add',
            name: 'Add Ticket',
            onClick: () => { this.props.func(null, 1) },
            iconProps: {
                iconName: 'Add'
            }

        };

        let searchField = {
            key: 'text',
            name: 'text',
            onRender: () => { return <div style={{ padding: "4px" }} > <TextField onChange={this._onChangeText} /> </div> },
        }

        let download = {
            key: 'download',
            iconProps : { iconName: 'Download' },
            name : 'Export Report',
            onClick : () => { this._onExport() }
        }

        if (this.props.userRules.ruleAccess.confTicket) {
            return [addbutton, searchField, download];
        }
        else if (this.props.userRules.access == null || this.props.userRules.access == "Administrator" || this.props.userRules.access == "") {
                return [];
        } else {
            return [download];
        }
    };

    render() {
        const { columns, isModalSelection } = this.state;
        this.ruleData = this.props.ruleData;
        this.userData = this._generateDocuments(this.props.data, this.state.filter);
        this.tenantList = this._generateTenantList(this.props.userRules)
        return (<MyContext.Consumer >
            {(context) => (
            <Fabric>
                <Dialog
                    isOpen={this.state.isDialogOpen}
                    type={DialogType.close}
                    onDismiss={this._dialogClose.bind(this)}
                    title='Excel export'
                    subText='Excel file created and downloaded to your favourite directory'
                    isBlocking={false}
                    closeButtonAriaLabel='Close'
                />
                <div className={classNames.controlWrapper}>
                </div>
                <CommandBar
                    items={this.getItems()}
                    ariaLabel={context.getTranslation("common", "navigateBetweenCommands")}
                />
                

                        <MarqueeSelection selection={this._selection}>
                            <DetailsList
                                items={this.userData}
                                compact={false}
                                columns={columns}
                                selectionMode={isModalSelection ? SelectionMode.multiple : SelectionMode.none}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                selection={this._selection}
                                selectionPreservedOnEmptyClick={true}
                                onItemInvoked={this._onItemInvoked}
                                enterModalSelectionOnTouch={true}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            />
                        </MarqueeSelection>
                    </Fabric>
                )
                }
            </MyContext.Consumer>
        );
    }

    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isCompactMode: checked });
    };

    _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isModalSelection: checked });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.userData = this.userData.filter(i => i.title.includes(text) || i.title.toLocaleLowerCase().includes(text));
        this.setState({ filter: text });
    };

    openMultySelect = () => {
        console.log(this._selection.getSelection())
        if (this._selection.getSelection().length > 0) {
            this.props.func(this._selection.getSelection(), 0);
        }
    };

    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                return '';
            default:
                return `${selectionCount} Items selected`;
        }
    }

    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        this.setState({
            columns: newColumns,
        });
    };

    _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    };

    _generateDocuments(DataArr, text) {
        // First filter
        let DataArrFiltered = DataArr.filter(i => i.title.includes(text) || i.title.toLocaleLowerCase().includes(text));
        // Then sort
        let newColumns = this.state.columns;
        newColumns.forEach((newCol: IColumn) => {
            if (newCol.isSorted) {
                DataArrFiltered = this._copyAndSort(DataArrFiltered, newCol.fieldName, newCol.isSortedDescending);
            }
        });
        return DataArrFiltered;
    };

    _generateTenantList = (userRules) => {
        let tenantList = [];
        console.log(userRules.allowedTenants);
        if (userRules.allowedTenants != null) {
            userRules.allowedTenants.forEach((aTenant) => {
                tenantList[aTenant.id] = aTenant.name;
            });
        }
        console.log(tenantList);
        return tenantList;
    };
}