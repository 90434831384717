import React, {Component} from 'react'
import './GridDay.scss'
import GridRow from '../GridRow/GridRow'
import { Route } from "react-router-dom";
import ProductLicense from "./../../Legal/ProductLicense";
import { Button } from 'office-ui-fabric-react/lib/Button';

export default class GridDay extends Component{
    constructor(props) {
        super(props);
        this.uploadMail = React.createRef();

    }

    state= {
        width: window.innerWidth,
        bocCount: 10,
        showAll: false
    };

    onSendMail = (ev) => {
        if (this.uploadMail.current) {
            this.uploadMail.current.showPanel();
        }
    }

    render()
    {
        if (this.props.dayTitle === "No valid user logged in.") {
            return (
                <ProductLicense authorization={this.props.authorization} apol={this.props.apol} />
                );
        } else {
            return (
                <div className="grid-row-wrapper">


                    <Route path="/day/" render={() => {
                        return (
                            <div className="grid-row-header">
                                <h2 className="grid-row-title" >{this.props.dayTitle}&nbsp;</h2>
                                <GridRow
                                    apol={this.props.apol}
                                    authorization={this.props.authorization}
                                    rowContent={this.props.dayContent}
                                    userRules={this.props.userRules}
                                    theme={this.props.theme}
                                    toDoArea={this.props.dayTitle === "To Do"}
                                />
                            </div>
                        )
                    }} />
                    <Route exact path="/" render={() => {
                        return (
                            <div className="grid-row-header">
                                <h2 className="grid-row-title">{this.props.dayTitle}&nbsp;</h2>
                                {this.f1(this.props)}
                                <GridRow
                                    apol={this.props.apol}
                                    authorization={this.props.authorization}
                                    rowContent={this.f(this.props)}
                                    userRules={this.props.userRules}
                                    theme={this.props.theme}
                                    toDoArea={this.props.dayTitle === "To Do"}
                                />
                            </div>
                        )
                    }} />
                    <Route path={["/conversations/" ,"/users/", "/tags/", "/main", "/compliance/" ] } render={() => {
                        return (
                            <div className="grid-row-header">
                                <h2 className="grid-row-title">{this.props.dayTitle}&nbsp;</h2>
                                {this.f1(this.props)}
                                <GridRow apol={this.props.apol} authorization={this.props.authorization} rowContent={this.f(this.props)} userRules={this.props.userRules} onlyOne={this.props.onlyOne} theme={this.props.theme} toDoArea={this.props.dayTitle === "To Do"} />
                            </div>
                        )
                    }} />
                   
                </div>
            );
        }
    }

    f=(props)=>
    {
        let windowsSize;
        if (this.state.showAll || (this.props.cntDays != null && this.props.cntDays < 3))
        {
            return props.dayContent;
        }
        else
        {
            if (this.state.width === undefined)
                windowsSize = window.innerWidth;
            else
                windowsSize = this.state.width;

            let bocCoutn = 10;
            if (windowsSize >= 0 && windowsSize <= 600) { bocCoutn = 4; }
            else if (windowsSize <= 844) { bocCoutn = 4; }
            else if (windowsSize <= 1120) { bocCoutn = 6; }
            else if (windowsSize <= 1396) { bocCoutn = 8; }
            else if (windowsSize <= 1672) { bocCoutn = 10; }
            else if (windowsSize <= 1948) { bocCoutn = 12; }
            else if (windowsSize <= 2224) { bocCoutn = 14; }
            else if (windowsSize <= 2500) { bocCoutn = 16; }
            else if (windowsSize <= 2776) { bocCoutn = 18; }
            else if (windowsSize <= 3052) { bocCoutn = 20; }
            else if (windowsSize <= 3328) { bocCoutn = 22; }
            else if (windowsSize <= 3604) { bocCoutn = 24; }
            else if (windowsSize <= 3880) { bocCoutn = 26; }
            else if (windowsSize <= 4156) { bocCoutn = 28; }
            else if (windowsSize <= 4432) { bocCoutn = 30; }
            const out = props.dayContent.slice(0, bocCoutn);
            return out;
        }
    };

    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }
    UNSAFE_componentWillMount()
    {
    this.updateDimensions();
    }
    componentDidMount()
    {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    componentWillUnmount()
    {
        window.removeEventListener("resize", this.updateDimensions);
    }

    f1(props) {
        if (props.dayContent.length > this.state.bocCount && (this.props.cntDays == null || this.props.cntDays > 2)) {
            return (<Button text={props.dayContent.length} onClick={this.moreClicked} iconProps={{ iconName: this.state.showAll ? 'ChevronUp' : 'ChevronDown' }} />);
        }
    }

    moreClicked = () => {
        this.setState({ showAll: !this.state.showAll })
    }

    parseDate(date) {
        let tmp = date.split(" ");
        if (tmp.length !== 3) {
        return date;
        }
        else {
            return tmp[0] + "-" + tmp[1].substring(0, 2) + "-" + tmp[2];
        }
    }
}


