import React from 'react';
import {
    Text, IFontStyles, PrimaryButton, Dropdown, Panel,
    Stack, IStackStyles, IStackTokens, IStackItemStyles, ComboBox,
} from 'office-ui-fabric-react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import './ASCFooter.scss';
import MasterCardLogo1 from '../../styles/images/Mastercard-logo.svg';
import MasterCardLogo2 from '../../styles/images/MasterCard@2x.png';
import VisaCardLogo1 from '../../styles/images/Visa_logo.svg';
import VisaCardLogo2 from '../../styles/images/Visa@2x.png';
import Impressum from '../Legal/Impressum';
import region from '../ShopView/RegionOption';
import sepaLogo from '../../styles/images/SepaLogoEN.jpg';
import { MyContext } from '../../context';


// Styles definition
const stackStyles: IStackStyles = {
    root: {
        alignItems: 'flex-end',
    },
};
const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
};
const stackItemStylesExtend: IStackItemStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        height: 80,
        justifyContent: 'center',
    },
};

// Tokens definition
const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};


// Styles
const creditCardLogoFooter = {
    marginRight: 10,
};

class ASCFooter extends React.Component {
    constructor(props) {
        super(props);
        if ((props.region !== undefined && props.region !== null)
            || this.props.region === undefined
            || this.props.sel !== 0) {
            this.state = {
                dialogOpen: false,
                region: props.region,
                panelOpen: false,
            };
        } else {
            this.state = {
                dialogOpen: true,
                region: null,
                panelOpen: false,
            };
        }


        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.changeRegion = this.changeRegion.bind(this);
    }

    changeRegion = (ev, newVal) => {
        this.setState({
            region: newVal,
        });
    }

    closeDialog = () => {
        this.setState({
            dialogOpen: false,
        });
    }

    openDialog = () => {
        this.setState({
            dialogOpen: true,
        });
    }

    render() {
        return (
            <>
                <Panel
                    isOpen={this.state.panelOpen}
                    onDismiss={this.dismissPanel}
                    type={PanelType.smallFluid}
                    closeButtonAriaLabel="Close"
                >
                    {this.getPanelContent()}
                </Panel>
                <Dialog
                    hidden={!this.state.dialogOpen}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                    }}
                    title={(
                        <Stack styles={{ root: { textAlign: 'center' } }} vertival horizontalAlign="center">
                            <h4>
                                {' '}
                                Welcome @ ASC Recording Insights.
                                {'\n'}
                                {' '}
                                Please choose your country.
                            </h4>
                        </Stack>
                    )}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 650 } },
                        dragOptions: false,
                    }}
                >

                    <ComboBox
                        label="Country"
                        // selectedKey={this.state.region ? this.state.region.key : null }
                        onChange={this.changeRegion}
                        errorMessage={this.state.errMessage ? this.state.errMessage : undefined}
                        placeholder="Select an option"
                        autoComplete="on"
                        options={region}
                        styles={{ dropdown: { width: 300 } }}
                    />

                    <Stack styles={{ root: { paddingTop: 10 } }} vertical horizontalAlign="center">
                        <PrimaryButton
                            onClick={() => {
                                if (this.state.region !== null) {
                                    switch (this.props.sel) {
                                        case 0:
                                            this.closeDialog();
                                            this.props.func({
                                                region: this.state.region,
                                            });
                                            break;
                                        default:
                                            this.closeDialog();
                                            if (this.props.func !== undefined) {
                                                this.props.func({
                                                    region: this.state.region,
                                                });
                                            }
                                    }
                                }
                            }}
                            text="Ok"
                        />
                    </Stack>
                </Dialog>
                <Stack style={{ background: 'white' }} horizontal  tokens={stackTokens}>

                    <Stack.Item grow styles={stackItemStyles}>
                        <Text style={{color:"black"}}>
                            &copy;
                            {new Date().getFullYear()}
                            {' '}
                            ASC Technologies AG
                        </Text>
                    </Stack.Item>


                    <Stack.Item grow styles={stackItemStyles}>
                        <ul className="footerNav">
                            <li><a href="https://asctechnologies.com/english/ASC_offices.html" target="_blank"><b><u>Contact</u></b></a></li>
                            <li><a onClick={this.openPanelLegal}>Legal Statements</a></li>
                            {/* <li><a href="./privacy/">Privacy Policy</a></li> */}
                            <li><a href={`${window.origin}/Terms_of_Use_Cloud_Services.pdf`} target="_blank">Terms of Use</a></li>
                            <li><a href="https://asctechnologies.com/english/asc_data_privacy_statement.html" target="_blank">Data Privacy</a></li>
                            <li>{this.renderRegion()}</li>
                        </ul>


                    </Stack.Item>


                    <Stack.Item>
                        <img
                            height={35}
                            src={VisaCardLogo1}
                            srcSet={`${VisaCardLogo1}, ${VisaCardLogo2}`} // NOTE: Alt. resolutions
                            style={creditCardLogoFooter}
                        />
                        <img
                            height={50}
                            src={MasterCardLogo1}
                            srcSet={`${MasterCardLogo1}, ${MasterCardLogo2}`} // NOTE: Alt. resolutions
                            style={creditCardLogoFooter}
                        />

                        <img height={40} style={stackItemStylesExtend} src={sepaLogo} />

                        <a href="https://pci.usd.de/compliance/8090-068F-77D4-40D0-A5F4-8BE4/details_en.html" target="_blank">
                            <img height={80} style={stackItemStylesExtend} src="https://pci.usd.de/compliance/8090-068F-77D4-40D0-A5F4-8BE4/seal_80.png" />
                        </a>

                    </Stack.Item>


                </Stack>
            </>
        );
    }

    dismissPanel = () => {
        this.setState({
            panelOpen: false,
        });
    }

    openPanelLegal = () => {
        this.setState({
            panelOpen: true,
            panelType: 'legal',
        });
    }

    openPanelTerms = () => {
        this.setState({
            panelOpen: true,
            panelType: 'terms',
        });
    }


    renderRegion = () => {
        const sel = this.props.func !== undefined && this.props.region !== undefined;

        if (sel) {
            return (
                <a onClick={this.openDialog}>
                    {' '}
                    {this.state.region && this.state.dialogOpen === false ? this.state.region.text : ''}
                </a>

            );
        }

        return null;
    }


    getPanelContent() {
        switch (this.state.panelType) {
            case 'legal':
                return <Impressum />;
            default:
                return null;
        }
    }
}

export default ASCFooter;
