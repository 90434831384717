import { Stack, Dropdown, IconButton, Separator, Spinner, TextField } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import * as query from '../PolicyRulesQuery';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';



export default class FormAdherencePhrases extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            availablePhrases: null,
            frequence: this.props.frequence,
            selectedConnection: this.props.connectionInsideArea,
            connections: this.props.availableConnections,
            isFrequencyEnabled: this.props.frequence > 1
        }
    }

    componentDidMount() {
        const auth = this.props.auth
        this.props.apol
            .query({
                query: query.getAdherencePhrases,
                variables: { auth }
            })
            .then((result) => {
                const availablePhrases = result.data.getAdherencePhrases.map(p => {
                    return ({
                        "key": p.masterItemId,
                        "text": p.name
                    })
                })

                //to understand if a adhph was deleted
                let idAvailableAP = availablePhrases.map(ap => ap.key)
                let includedAP = this.props.selectedPhrases.filter(ap => idAvailableAP.includes(ap))
                let deletedElement = includedAP.length !== this.props.selectedPhrases.length
                this.props.checkElementToRestoreOldVersion(deletedElement)

                this.setState({
                    availablePhrases,
                    deletedElement
                })
            });
    }

    onChangeSelectPhrases = (e, item) => {
        if (item) {
            const selectedPhrases = item.selected ? [...this.props.selectedPhrases, item.key] : this.props.selectedPhrases.filter(key => key !== item.key)
            this.props.updatePhrases(this.props.position, selectedPhrases)
        }
    }

    onChangeSelectConnection = (e, item) => {
        this.setState({
            selectedConnection: item.key,
            isFrequencyEnabled: !(item.key == "AND" || item.key == "OR")

        })
        this.props.updateConnectionInsideArea(this.props.position, item.key)
    }

    changeNewFrequence = (e, value) => {
        this.setState({
            frequence: parseInt(value),
            error: ""
        })
        this.props.updateFrequenceInsideArea(this.props.position, parseInt(value))
    }

    changeFrequencyVisibility = () => {
        if (this.state.selectedConnection != "NONE" && this.state.selectedConnection != null) {
            let isFrequencyEnabled = this.state.selectedConnection == "AT_LEAST" ? true : !this.state.isFrequencyEnabled
            if (!isFrequencyEnabled) {
                this.props.updateFrequenceInsideArea(this.props.position, parseInt(1))
            }
            this.setState(prevState => ({
                isFrequencyEnabled: isFrequencyEnabled,
                frequence: isFrequencyEnabled ? prevState.frequence : 1
            }))
        }
    }

    render() {
        return (
            <div>
                <Stack horizontal>
                    <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => this.props.deleteArea(this.props.position)} />
                    <span style={{ marginLeft: "5px", marginTop: "5px", fontWeight: "bold" }}>{`${this.context.getTranslation("compliance", "AdherencePhrases")} (${this.props.selectedPhrases.length})`}</span>
                    {!["NONE", "AT_LEAST", null].includes(this.state.selectedConnection) && (<TooltipHost
                        content={this.context.getTranslation("compliance", "tooltipFrequencyButton")}
                        setAriaDescribedBy={false}
                    >
                        <IconButton style={this.state.isFrequencyEnabled ? { color: "gray" } : {}} iconProps={{ iconName: 'Info' }} aria-label="Emoji" disabled={false} onClick={this.changeFrequencyVisibility} />
                    </TooltipHost>)}
                </Stack>
                {!this.state.deletedElement && <Stack horizontal style={{ marginTop: "10px", marginLeft: "30px" }}>
                    <Stack.Item align="center" style={{ maxWidth: "600px", minWidth: "200px" }}>
                        {this.state.availablePhrases === null &&
                            <Spinner ariaLive="assertive" />
                        }
                        {this.state.availablePhrases !== null && this.state.availablePhrases.length === 0 &&
                            <span>{this.context.getTranslation("compliance", "noAdherencePhrases")}</span>
                        }
                        {this.state.availablePhrases !== null && this.state.availablePhrases.length > 0 && < Dropdown
                            placeholder={this.context.getTranslation("compliance", "selectAdherencePhrases")}
                            label=""
                            selectedKeys={this.props.selectedPhrases}
                            onChange={this.onChangeSelectPhrases}
                            multiSelect
                            options={this.state.availablePhrases}
                        />
                        }
                    </Stack.Item>
                    <Stack.Item align="center">
                        {this.props.selectedPhrases.length > 1 && <Dropdown
                            style={{ marginLeft: "20px" }}
                            placeholder={this.context.getTranslation("compliance", "selectConnection")}
                            label=""
                            selectedKey={this.state.selectedConnection}
                            onChange={this.onChangeSelectConnection}
                            options={this.state.connections}
                        />}
                    </Stack.Item>
                    {this.state.isFrequencyEnabled && (<Stack.Item align="center" style={{ marginLeft: "20px", width: "50px" }}>
                        <TextField type="number" value={this.state.frequence} onChange={this.changeNewFrequence} disabled={this.props.disabled} />
                    </Stack.Item>)}
                </Stack>}
                {this.state.deletedElement && <Stack horizontal style={{ marginTop: "10px", marginLeft: "30px" }}>
                    <span style={{ color: "red" }}>{this.context.getTranslation("compliance", "deletedElement") + " " + this.props.selectedPhrases.join(", ")}</span>
                </Stack>}
            </div>
        )
    }
}