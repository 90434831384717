import React from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { MyContext } from '../../../context';

export default class ComanderBar extends React.Component<{}, {}>
{

    constructor(props) {
        super(props);
        this.state =
        {
            open: false,
            addType: "1"
        };

        this.openModal1 = this.openModal1.bind(this);
        this.openModal2 = this.openModal2.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    openModal1() {
        console.log("Modal1");
        this.props.openNew();

    }
    openModal2() {
        console.log("Modal2");

        this.props.openNew2();

    }
    async closeModal() {
        this.setState({ open: false })
    }

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>
                        <CommandBar
                            items={this.getItems()}
                            ariaLabel={context.getTranslation("common", "navigateBetweenCommands")}
                        />
                    </div>
                )
                }
            </MyContext.Consumer>
        );
    }
    //
    //
    // Data for CommandBar
    getItems = () => {

        let addbutton;
        let deletebutton = { key: "delete" };
        let editLabel = "";
        switch (this.props.sel) {
            case undefined: break;
            case null: break;
            case "rule":
                addbutton = {
                    key: 'add',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addRule")}</>)}</MyContext.Consumer>,
                    onClick: () => { this.props.openNew(); },
                    iconProps: {
                        iconName: 'Add'
                    }

                };
                editLabel = <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "edit")}</>)}</MyContext.Consumer>
                break;
            case "cat":
                addbutton = {
                    key: 'add',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addCategory")}</>)}</MyContext.Consumer>,
                    onClick: () => { this.props.openNew(); },
                    iconProps: {
                        iconName: 'Add'
                    }

                };
                editLabel = <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "editCategory")}</>)}</MyContext.Consumer>
                deletebutton = {
                    key: 'delete',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "deleteCategory")}</>)}</MyContext.Consumer>,
                    onClick: () => { this.props.delete(); },
                    iconProps: {
                        iconName: 'Delete'
                    }
                }
                break;
            case "report":
                addbutton = {
                    key: 'add',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addReport")}</>)}</MyContext.Consumer>,
                    onClick: () => { this.props.openNew(); },
                    iconProps: {
                        iconName: 'Add'
                    }

                };
                editLabel = <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "edit")}</>)}</MyContext.Consumer>
                break;
            case "user":
                addbutton = {
                    key: 'add',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addUser")}</>)}</MyContext.Consumer>,
                    iconProps: { iconName: 'Add' },
                    subMenuProps: {
                        items: [
                            {
                                key: 'Recording License ',
                                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "recordingLicense")}</>)}</MyContext.Consumer>,
                                iconProps: {
                                    iconName: 'Microphone'
                                },
                                onClick: () => { this.openModal1() }
                            },
                            {
                                key: 'calendarEvent',
                                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "recordingAnalyticsLicense")}</>)}</MyContext.Consumer>,
                                iconProps: {
                                    iconName: 'AnalyticsView'
                                },
                                onClick: () => { this.openModal2() }
                            }
                        ]
                    }
                };
                editLabel = <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "edit")}</>)}</MyContext.Consumer>
                break;
            case "group":
                addbutton = {
                    key: 'add',
                    name: 'Add Group',
                    iconProps: { iconName: 'Add' },
                    subMenuProps: {
                        items: [
                            {
                                key: 'Recording License ',
                                name: 'Recording License ',
                                iconProps: {
                                    iconName: 'Microphone'
                                },
                                onClick: () => { this.openModal1() }
                            },
                            {
                                key: 'calendarEvent',
                                name: 'Recording & Analytics License',
                                iconProps: {
                                    iconName: 'AnalyticsView'
                                },
                                onClick: () => { this.openModal2() }
                            }
                        ]
                    }
                };
                editLabel = <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "edit")}</>)}</MyContext.Consumer>
                break;
            case "field":
                addbutton = {
                    key: 'add',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addCustomField")}</>)}</MyContext.Consumer>,
                    onClick: () => { this.props.openNew(); },
                    iconProps: {
                        iconName: 'Add'
                    }
                };
                editLabel = <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "editCustomField")}</>)}</MyContext.Consumer>
                break;
            default: break;

        }
        if (this.props.sel === "cat") {
            return [
                addbutton,
                {
                    key: 'Edit',
                    name: editLabel,
                    onClick: () => { if (this.props.edit != null && isFunction(this.props.edit)) { this.props.edit() } },
                    iconProps: {
                        iconName: 'Edit'
                    }
                },
                deletebutton
            ];
        } else {
            return [
                addbutton,
                {
                    key: 'Edit',
                    name: editLabel,
                    onClick: () => { if (this.props.edit != null && isFunction(this.props.edit)) { this.props.edit() } },
                    iconProps: {
                        iconName: 'Edit'
                    }
                }
            ];
        }
       
    };

}
function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
//
