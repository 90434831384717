import React, { Component } from 'react';
import { ASCHeader } from "../ASCHeader/ASCHeader";
import ASCFooter from "../ASCFooter/ASCFooter";
import { Text } from 'office-ui-fabric-react';

import {
    Stack
} from "office-ui-fabric-react";
import { MyContext } from '../../context';


// Styles definition
const stackContainerStyles: IStackStyles = {
    root: {
        paddingTop: 50
    }
};


const stackStyles: IStackStyles = {
    root: {
        maxWidth: 660,
        marginBottom: 50
    }
};

// Tokens definition
const stackTokens: IStackTokens = { childrenGap: 5 };


export default class Privacy extends Component {


render() {
    return (<MyContext.Consumer >
        {(context) => (
      <>
        <ASCHeader />
        {/* <ASCHeader title={"Terms of Service & EULA"} /> */}

                        <Stack tokens={stackTokens}>

                            <Stack horizontalAlign="center" styles={stackContainerStyles}>

            <Stack styles={stackStyles}>
              <Text variant="xxLarge">Terms of Service & EULA</Text>
            </Stack>
            <Stack styles={stackStyles}>
              <Text variant="large">
              THIS TERMS OF USE & END USER LICENSE AGREEMENT (�AGREEMENT�) IS BETWEEN ASC TECHNOLOGIES AG (�ASC�) AND THE PERSON (�YOU�) WHO SUBSCRIBES TO ASC�S RECORDING INSIGHT SOLUTION (THE �SOLUTION�); BOTH HEREINAFTER REFERRED TO COLLECTIVELY OR INDIVIDUALLY AS �PARTY(IES)�.
              THIS AGREEMENT GIVES YOU THE RIGHT TO ACCESS AND USE THE SOLUTION SUBSCRIBED FROM ASC. ASC WILL CHARGE ACCORDING TO THE SUBSCRIPTION PLAN OF THE SOLUTION.
              GENERALLY, ASC IS ONLY WILLING TO GRANT YOU THE RIGHT TO ACCESS AND USE ITS SOLUTION IF YOU ACCEPT ALL OF THE TERMS OF THIS AGREEMENT AND PAY OR HAVE PAID ASC THE FULL SUBSCRIPTION PRICE FOR USE OF THE LICENSE TO WHICH YOU HAVE SUBSCRIBED.
              BY CLICKING THE �I AGREE� CHECKBOX AND ACCESSING THE SOLUTION AND THE SERVICE PROVIDED BY IT, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY IT. IF YOU DO NOT AGREE TO ALL OF THE TERMS IN THIS AGREEMENT, YOU SHOULD NOT ACCESS OR OTHERWISE UTILIZE THE SOLUTION BECAUSE NO LICENSE SHALL HAVE BEEN GRANTED THERETO.
              </Text>
            </Stack>

                                <Stack styles={stackStyles}>

                                    <Text variant="xxLarge">1. {context.getTranslation("termsOfUse", "overview")} </Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "overviewText1")} <a href='https://asctechnologies.com/english/index.html'>{context.getTranslation("termsOfUse", "ourWebsite")}</a>.</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "overviewText2")}</Text>
                                </Stack>

                                <Stack styles={stackStyles}>

                                    <Text variant="xxLarge">2. {context.getTranslation("termsOfUse", "generalUsageConditions")}</Text>
                                    <Text variant="large"> {context.getTranslation("termsOfUse", "generalUsageConditionsText1")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "generalUsageConditionsText2")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "generalUsageConditionsText3")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "generalUsageConditionsText4")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "generalUsageConditionsText5")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "generalUsageConditionsText6")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "generalUsageConditionsText7")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "generalUsageConditionsText8")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "generalUsageConditionsText9")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "generalUsageConditionsText10")}</Text>

                                </Stack>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">3. {context.getTranslation("termsOfUse", "license")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "licenseText")}</Text>
                                </Stack>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">4. {context.getTranslation("termsOfUse", "restrictions")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "restrictionsText")}</Text>
                                </Stack>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">5. {context.getTranslation("termsOfUse", "ownership")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "ownershipText")}</Text>
                                </Stack>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">6. {context.getTranslation("termsOfUse", "term")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "termText")}</Text>

                                </Stack>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">7. {context.getTranslation("termsOfUse", "termination")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "terminationText")}</Text>

                                </Stack>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">8. {context.getTranslation("termsOfUse", "warantyDisclaimer")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "warantyDisclaimerText")}</Text>

                                </Stack>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">9. {context.getTranslation("termsOfUse", "suplementaryTerms")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "suplementaryTermsText")}</Text>

                                </Stack>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">10. {context.getTranslation("termsOfUse", "miscellaneous")}</Text>
                                    <Text variant="large">{context.getTranslation("termsOfUse", "miscellaneousText1")}</Text>

                                    <Text variant="large">{context.getTranslation("termsOfUse", "miscellaneousText2")}</Text>

                                    <Text variant="large">{context.getTranslation("termsOfUse", "miscellaneousText3")}</Text>

                                    <Text variant="large">{context.getTranslation("termsOfUse", "miscellaneousText4")}</Text>

                                </Stack>

                            </Stack>

                        </Stack>


                        <ASCFooter />
                    </>
                )
                }
            </MyContext.Consumer>
        );
    }

}
