import gql from 'graphql-tag';

export const queryAllPatterns = gql`query  {
                getPatternsDetection  {
                         id
                         name
                         description
                         tenantId
                         withFragments
                         patternDetectionGroup {
                            id
                            name
                            generalGroup
                        }
                         regExp
                         fragments{
                            index
                            nDigits
                            value
                            fragmentType
                            operatorType
                         }
                    }
         }`

export const queryGetPattern = gql`query ent ($id: String) {
                getPatternDetection (id: $id) {
                         id
                         name
                         description
                         tenantId
                         withFragments
                         regExp
                         patternDetectionGroup {
                            id
                            name
                            generalGroup
                        }
                         fragments{
                            index
                            nDigits
                            value
                            fragmentType
                            operatorType
                         }
                        version
                        masterItemId
                        selected
                    }
         }`

export const createPattern = gql`mutation ent($patternDetection: PatternDetectionInputType){
                                        upsertPatternDetection(patternDetection: $patternDetection)  {
                                            text
                                            status
                                            id
            }
                                        }`


export const createPatternAsTemplate = gql`mutation ent($patternDetection: PatternDetectionInputType){
                                        upsertPatternDetectionAsTemplate(patternDetection: $patternDetection)  {
                                            text
                                            status
                                            id
            }
                                        }`

export const deletePattern = gql`mutation ent ($id: String) {
                                        deletePatternDetection (id: $id) {
                                            text
                                            status
                                            id
            }
                                    }`


export const getFragmentType = gql`query ent {
                                    getFragmentTypeEnum
                                    {
                                        name
                                        description
                                        customField
                                    }
                                }`

export const getOperatorType = gql`query ent {
                                    getOperatorTypeEnum
                                    {
                                        name
                                        description
                                    }
                                }`

export const getAllVersionOfPatternDetection = gql`query ent ($masterItemId: String) {
                                        getAllVersionOfPatternDetection(masterItemId: $masterItemId) {
                                                id
                                                version
                                            }
                                    }`


export const restorePatternDetection = gql`mutation ent ($id: String) {
                                        restorePatternDetection(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`