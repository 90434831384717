import { DetailsList, MarqueeSelection, Selection, SelectionMode } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../context';
import Utils from '../../common/Utils'

const orderAlphabetically  = Utils.orderAlphabetically


class PatternListView extends Component {
    static contextType = MyContext
    _selection: Selection;

    constructor(props) {
        super(props);
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.props.selectPattern(this._selection.getSelection().length > 0 ? this._selection.getSelection()[0].id : null)
            }
        });
    }

    getPatternGroups = (data) => {
        //create groups for dataillist
        var templateElements = data.filter(x => x.tenantId == "" || (x.patternDetectionGroup != null && x.patternDetectionGroup.generalGroup)).length 
        let customGroup = {
            "key": "customPat",
            "name": this.context.getTranslation("compliance", "customPat"),
            "startIndex": templateElements,
            "count": data.length - templateElements,
            "level": 0
        }
        let groups = []
        if (templateElements !== 0) {
            groups = [
                {
                    "key": "templatePat",
                    "name": this.context.getTranslation("compliance", "templatePat"),
                    "startIndex": 0,
                    "count": templateElements,
                    "level": 0
                },
                customGroup
            ]
        } else {
            groups = [customGroup]
        }
        return groups;
    }

    _renderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName]
        switch (column.key) {
            case 'content':
                var result = ""
                if (item.withFragments) {
                    result = [...new Set(item.fragments.map(frag => {
                        return this.context.getTranslation("compliance", frag.fragmentType)
                    }))].join(", ")
                } else {
                    result = item.regExp
                }
                return <span>{result}</span>;
            default:
                return <span>{fieldContent !== null ? fieldContent : ""}</span>;
        }
    }

    _getKey = (item, index) => {
        return item.key;
    }

    render() {
        const columns = [
            { key: 'name', name: this.context.getTranslation("compliance", "detailPolicyName"), fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
            { key: 'description', name: this.context.getTranslation("compliance", "detailPolicyDescription"), fieldName: 'description', minWidth: 100, maxWidth: 200 },
            { key: 'content', name: this.context.getTranslation("compliance", "content"), fieldName: 'content', minWidth: 100, maxWidth: 200 },
        ]

        var template = this.props.patterns.filter(x => x.tenantId == "" || (x.patternDetectionGroup != null && x.patternDetectionGroup.generalGroup))
        var nonTemplate = this.props.patterns.filter(x => (x.patternDetectionGroup == null || !x.patternDetectionGroup.generalGroup))
        var sortData = [
            ...template.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase())),
            ...nonTemplate.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))
        ]

        var groups = this.getPatternGroups(sortData)

        return (
            <MarqueeSelection selection={this._selection}>
                <DetailsList
                    items={sortData}
                    columns={columns}
                    groups={groups}
                    selectionMode={SelectionMode.single}
                    selection={this._selection}
                    getKey={this._getKey}
                    setKey="single"
                    onRenderItemColumn={this._renderItemColumn}
                />
            </MarqueeSelection>
        );
    }
}

export default PatternListView;