import * as React from 'react';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { Panel, PanelType, Text, Icon, DefaultButton, Checkbox, Dropdown, Spinner, PrimaryButton, ComboBox, SelectableOptionMenuItemType, TextField, Label, Nav } from 'office-ui-fabric-react/lib';
import { FontSizes, Stack, StackItem } from 'office-ui-fabric-react/lib';
import UserPicker from './../ReportView/ReportConfiguration/UserPicker';
import { getAllCategoryVersions } from "../../queries/queryCategory";
import { queryAllPolicyVersions } from "../ComplianceView/Policy/PolicyQuery"
import { getAllFoldersShrinked } from "../ConversationSafe/ConversationSafeQuery";
import { queryGetIntegration } from "../../queries/queryIntegration";
import { Query } from "react-apollo";
import { MyContext } from '../../context';
import { queryGetRuleReplay } from '../../queries/querySettings';
import DateTimePicker from 'react-datetime-picker';
import _ from 'lodash'

import Utils from "./../common/Utils";

import './FilterBox.scss';


const checkboxStyles = () => {
    return {
        root: {
            marginTop: '10px'
        }
    };
};
const iconStyle = () => {
    return {
        root: {
            borderBottom: '1px solid',
            borderRight: '1px solid',
            borderTop: '1px solid',
            borderLeft: '0',
            cursor: "pointer",
            fontSize: FontSizes.icon,
            borderRadius: '0 2px 2px 0',
            transition: 'background-color 0.3s ease-in-out',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            '&:hover': {
                backgroundColor: '#ebebeb',
            },
            paddingTop: "5px",
            paddingRight: "2px",
            height: "32px"
        }
    };
};
const searchStyle = (small) => {
    return {
        root: {
            borderRight: 'none',
            borderRadius: '2px 0px 0px 2px',
            width: small ? "150px;" : "330px;"
        },

    };
};





export default class FilterBox extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.searchBoxRef = React.createRef();
        this.navRef = React.createRef();
        this.listPersons = [];
        if (this.props.userRules.recordingFilter != null && this.props.userRules.recordingFilter.persons != null) {
            for (let x = 0; x < this.props.userRules.recordingFilter.persons.length; x++) {
                this.listPersons.push({
                    userId: this.props.userRules.recordingFilter.persons[x],
                    key: this.props.userRules.recordingFilter.persons[x]
                }
                );
            }
        }
        this.defaultListPersons = [];
        if (this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.persons != null && this.props.userRules.defaultFilter.persons != "") {
            for (let x = 0; x < this.props.userRules.defaultFilter.persons.length; x++) {
                this.defaultListPersons.push({
                    userId: this.props.userRules.defaultFilter.persons[x],
                    key: this.props.userRules.defaultFilter.persons[x]
                }
                );
            }
        }

        this.categoriesList = [];
        this.categoriesListSource = [];
        if (this.props.userRules.recordingFilter != null && this.props.userRules.recordingFilter.categories != null) {
            this.categoriesListSource = this.props.userRules.recordingFilter.categories;
            for (let x = 0; x < this.categoriesListSource.length; x++) {
                this.categoriesList.push({
                    key: this.categoriesListSource[x],
                    id: this.categoriesListSource[x]
                }
                );
            }

        }

        this.categoriesDefaultList = [];
        this.categoriesDefaultListSource = [];
        if (this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.categories != null) {
            this.categoriesDefaultListSource = this.props.userRules.defaultFilter.categories;
            for (let x = 0; x < this.categoriesDefaultListSource.length; x++) {
                this.categoriesDefaultList.push({
                    key: this.categoriesDefaultListSource[x],
                    id: this.categoriesDefaultListSource[x]
                }
                );
            }

        }

        this.integrationList = [];
        this.integrationListSource = [];
        if (this.props.userRules.recordingFilter != null && this.props.userRules.recordingFilter.integrations != null) {
            this.integrationListSource = this.props.userRules.recordingFilter.integrations;
            for (let x = 0; x < this.integrationListSource.length; x++) {
                this.integrationList.push({
                    key: this.integrationListSource[x],
                    id: this.integrationListSource[x]
                }
                );
            }
        }
        //console.log(this.integrationList);

        let typeListSource = this.getTypeListSource();



        this.state = {
            filterDefault: this.props.userRules.defaultFilter,
            filterPanelOpen: false,
            filterDefaultEnabled: this.props.userRules.recordingFilter != null ? this.props.userRules.recordingFilter.defaultFilter : false,
            filterUserEnabled: this.props.userRules.recordingFilter != null ? this.props.userRules.recordingFilter.userFilter : false,
            filterType: typeListSource,
            filterCycle: this.props.userRules.recordingFilter != null ? this.props.userRules.recordingFilter.cycle : "",
            filterStartDate: this.props.userRules.recordingFilter != null ? (this.props.userRules.recordingFilter.startDate !== '' && this.props.userRules.recordingFilter.startDate !== '0001-01-01T00:00:00') ? new Date(this.props.userRules.recordingFilter.startDate) : '' : '',
            filterEndDate: this.props.userRules.recordingFilter != null ? (this.props.userRules.recordingFilter.endDate !== '' && this.props.userRules.recordingFilter.endDate !== '0001-01-01T00:00:00') ? new Date(this.props.userRules.recordingFilter.endDate) : '' : '',
            filterPersons: this.listPersons,
            sel4list: this.categoriesList,
            selectedOptionKeys: this.categoriesListSource,
            selIntegration4List: this.integrationList,
            selectedOptionKeysIntegration: this.integrationListSource,
            activeSearch: "",
            activeFilter: null,
            filterState: false,
            selectedOptionPolicies: this.props.userRules.recordingFilter != null ? this.props.userRules.recordingFilter.policies : [],
            selectedOptionFolders: this.props.userRules.recordingFilter !== null && this.props.userRules.recordingFilter.folders !== undefined && this.props.userRules.recordingFilter.folders !== null ? this.props.userRules.recordingFilter.folders : [],
            recordingOwner: this.props.userRules.recordingFilter != null ? this.props.userRules.recordingFilter.recordingOwner : false,
            durationFrom: this.props.userRules.recordingFilter != null ? this.props.userRules.recordingFilter.durationFrom : -1,
            durationTo: this.props.userRules.recordingFilter != null ? this.props.userRules.recordingFilter.durationTo : -1,
            searchIn: [],
            showNavBar: false,
            showAllVersionPolicies: true,
            showAllVersionCategories: true
        };

        this.timerID = 0;

    }

    componentDidMount() {
        if (this.props.userRules.defaultFilter !== null && this.props.userRules.defaultFilter.timeBasedType === "ReplayFilter") {
            this.fillFromDefaultFilter(true);
        }
    }

    getTypeListSource = () => {
        let typeListSource = [];
        if (this.props.userRules.recordingFilter != null && this.props.userRules.recordingFilter.type != null && this.props.userRules.recordingFilter.type != "") {
            typeListSource = this.props.userRules.recordingFilter.type.split(',');
        }
        return typeListSource;
    }

    navStyles = {
        root: {
            boxSizing: 'border-box',
            border: '2px solid #eee',
            overflowY: 'auto',
            position: 'unset',
            zIndex: 9999,
            backgroundColor: this.props.theme.palette.white,
            color: this.props.theme.palette.black,
            minWidth: "200px",

        },
        link: {
            fontSize: '12px',
        },
        chevronButton: {
            fontSize: '14px',
        },
    };
    handleSearchFocus = () => {
        this.setState({ showNavBar: true });
    };
    handleButtonSearchIn = () => {
        this.setState(prevState => ({
            showNavBar: !prevState.showNavBar,
        }));
    };

    handleSearchBlur = () => {
        setTimeout(() => {
            const activeElement = document.activeElement;
            const searchBoxRef = this.searchBoxRef.current;
            const navRef = this.navRef.current;

            if (
                searchBoxRef
                && activeElement !== searchBoxRef
                && (!navRef || !navRef.contains(activeElement))
            ) {
                this.setState({ showNavBar: false });
            }
        }, 0);
    };
    handleNavBlur = () => {
        setTimeout(() => {
            const activeElement = document.activeElement;
            const searchBoxRef = this.searchBoxRef.current;
            const navRef = this.navRef.current;

            if (
                !searchBoxRef.state.hasFocus
                && (!navRef || !navRef.contains(activeElement))

            ) {
                this.setState({ showNavBar: false });
            }
        }, 0);
    };

    /*Functions to fill the filter */
    getSelectOptionsType = (context) => {
        let allOptions = [
            { key: 'Inbound', text: context.getTranslation("filterPanel", "inbound") },
            { key: 'Outbound', text: context.getTranslation("filterPanel", "outbound") },
            { key: 'Meeting', text: context.getTranslation("filterPanel", "meetings") },
            { key: 'Internal', text: context.getTranslation("filterPanel", "internal") },
            { key: 'External', text: context.getTranslation("filterPanel", "external") },
            { key: 'Chat', text: context.getTranslation("configuration", "chat") },
            { key: 'Upload', text: context.getTranslation("filterPanel", "upload") },
            { key: 'SMS', text: context.getTranslation("filterPanel", "sms") }
        ]
        if (this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.type != null && this.props.userRules.defaultFilter.timeBasedType === "VisibilityFilter" && this.props.userRules.defaultFilter.type !== "") {
            allOptions = allOptions.filter(x => this.props.userRules.defaultFilter.type.split(",").includes(x.key))
        }
        return allOptions
    }

    getSelectOptionsCycle = (context) => {
        let allOptions = [
            { key: '', text: "", order: 0 },
            { key: 'Today', text: context.getTranslation("dashboard", "Today"), order: 0 },
            { key: 'Yesterday', text: context.getTranslation("dashboard", "Yesterday"), order: 1 },
            { key: 'Last24h', text: context.getTranslation("dashboard", "Last24h"), order: 2 },
            { key: 'Last48h', text: context.getTranslation("dashboard", "Last48h"), order: 3 },
            { key: 'Last72h', text: context.getTranslation("dashboard", "Last72h"), order: 4 },
            { key: 'Last96h', text: context.getTranslation("dashboard", "Last96h"), order: 5 },
            { key: 'ThisWeek', text: context.getTranslation("dashboard", "ThisWeek"), order: 0 },
            { key: 'Last7d', text: context.getTranslation("dashboard", "Last7d"), order: 6 },
            { key: 'LastWeek', text: context.getTranslation("dashboard", "LastWeek"), order: 0 },
            { key: 'Last14d', text: context.getTranslation("dashboard", "Last14d"), order: 7 },
            { key: 'ThisMonth', text: context.getTranslation("dashboard", "ThisMonth"), order: 0 },
            { key: 'Last30d', text: context.getTranslation("dashboard", "Last30d"), order: 8 },
            { key: 'LastMonth', text: context.getTranslation("dashboard", "LastMonth"), order: 0 },
            { key: 'ThisQuarter', text: context.getTranslation("dashboard", "ThisQuarter"), order: 0 },
            { key: 'LastQuarter', text: context.getTranslation("dashboard", "LastQuarter"), order: 0 },
            { key: 'ThisYear', text: context.getTranslation("dashboard", "ThisYear"), order: 0 },
            { key: 'LastYear', text: context.getTranslation("dashboard", "LastYear"), order: 0 },
            { key: 'Current', text: 'Current', order: 0 }
        ]

        if (this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.cycle != null && this.props.userRules.defaultFilter.timeBasedType === "VisibilityFilter" && this.props.userRules.defaultFilter.cycle !== "") {

            let getOrder = [];
            getOrder.push(allOptions.find(x => x.key === this.props.userRules.defaultFilter.cycle));

            //if order is zero means that only the selected option will be displayed
            if (getOrder[0].order === 0) {
                allOptions = getOrder;

            } else {
                //if order is >0 wll display the selected options like "last 7days" plus what is below it like last48h, last24h and so on

                let getSubOptions = allOptions.filter(x => x.order > 0 && x.order <= getOrder[0].order);

                allOptions = getSubOptions;

                //allOptions = allOptions.slice(0, 1 + allOptions.map(x => x.key).indexOf(this.props.userRules.defaultFilter.cycle)) --display everything up to the selected cycle in settings

            }
        }
        return allOptions;
    }

    getIntegrationComboBoxes = (allOptions) => {
        if (this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.integrations != null && this.props.userRules.defaultFilter.timeBasedType === "VisibilityFilter" && this.props.userRules.defaultFilter.integrations.length > 0) {
            allOptions = allOptions.filter(x => this.props.userRules.defaultFilter.integrations.includes(x.id))
        }
        return allOptions;
    }

    getCategoriesComboBoxes = (allOptions) => {
        if (this.props.userRules.defaultFilter != null
            && this.props.userRules.defaultFilter.categories != null
            && this.props.userRules.defaultFilter.timeBasedType === "VisibilityFilter"
            && this.props.userRules.defaultFilter.categories.length > 0) {
            allOptions = allOptions.filter(x => this.props.userRules.defaultFilter.categories.includes(x.id))
        }
        if ((!this.props.userRules?.ruleAccess?.complianceResultsAccess ?? true) || (!this.props.userRules?.addOnCompliance ?? true)) {
            allOptions = allOptions.filter(e => !e.fromCompliance)
        }

        return allOptions;
    }

    getMinDate = () => {
        if (this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.startDate != null && this.props.userRules.defaultFilter.startDate.length > 0 && this.props.userRules.defaultFilter.startDate != "0001-01-01T00:00:00") {
            return new Date(this.props.userRules.defaultFilter.startDate)
        } else if (this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.cycle != null && this.props.userRules.defaultFilter.cycle.length > 0) {
            var today = new Date();
            if (this.props.userRules.defaultFilter.cycle == "Today") {
                let value = today.setDate(today.getDate() - 1)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "Yesterday") {
                let value = today.setDate(today.getDate() - 2)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "Last24h") {
                let value = today.setHours(today.getHours() - 24)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "Last48h") {
                let value = today.setHours(today.getHours() - 48)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "Last72h") {
                let value = today.setHours(today.getHours() - 72)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "Last96h") {
                let value = today.setHours(today.getHours() - 96)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "ThisWeek") {
                var day = today.getDay()
                var diff = today.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday  
                let value = new Date(today.setDate(diff))
                return value

            }
            else if (this.props.userRules.defaultFilter.cycle == "Last7d") {
                let value = today.setDate(today.getDate() - 7)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "LastWeek") {
                let value = today.setDate(today.getDate() - 8)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "Last14d") {
                let value = today.setDate(today.getDate() - 15)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "ThisMonth") {
                let value = new Date(today.getFullYear(), today.getMonth(), 1);
                return value
            }
            else if (this.props.userRules.defaultFilter.cycle == "Last30d") {
                let value = today.setDate(today.getMonth() - 1)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "LastMonth") {
                let value = today.setDate(today.getMonth() - 1)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "ThisQuarter") {
                let value = new Date(today.getFullYear(), Math.floor((today.getMonth() / 3)) * 3, 1)
                return value
            }
            else if (this.props.userRules.defaultFilter.cycle == "LastQuarter") {
                let value = today.setDate(today.getMonth() - 3)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "ThisYear")
                return new Date(today.getFullYear(), 0, 1)
            else if (this.props.userRules.defaultFilter.cycle == "LastYear") {
                let value = today.setDate(today.getMonth() - 12)
                return new Date(value)
            }
            else if (this.props.userRules.defaultFilter.cycle == "Current")
                return null
        }
        return null;
    }

    getMaxDate = () => {
        if (this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.endDate != null && this.props.userRules.defaultFilter.endDate.length > 0 && this.props.userRules.defaultFilter.endDate != "0001-01-01T00:00:00") {
            return new Date(this.props.userRules.defaultFilter.endDate)
        } else if (this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.cycle != null && this.props.userRules.defaultFilter.cycle.length > 0) {
            return this.props.userRules.defaultFilter.cycle == "Current" ? null : new Date()
        }
        return null;
    }

    sendSearch = (searchValue) => {
        this.setState({ activeSearch: searchValue });
        clearTimeout(this.timerID);
        this.timerID = setTimeout(
            () => this.startSearch(),
            1000
        );
    }

    startSearch = () => {
        clearTimeout(this.timerID);
        this.props.handler(this.state.activeSearch, this.state.activeFilter, false, this.state.searchIn);
    }


    sendFilter = (withSave) => {
        let resultPersons = [];
        for (let x = 0; x < this.state.filterPersons.length; x++) {
            resultPersons.push(this.state.filterPersons[x].key);
        };
        let filterCategories = [];
        for (let x = 0; x < this.state.sel4list.length; x++) {
            filterCategories.push(this.state.sel4list[x].id);
        };
        let filterIntegrations = [];
        for (let x = 0; x < this.state.selIntegration4List.length; x++) {
            filterIntegrations.push(this.state.selIntegration4List[x].id);
        };
        let filterTypeKomma = "";
        for (let x = 0; x < this.state.filterType.length; x++) {
            if (x !== 0)
                filterTypeKomma += ",";
            filterTypeKomma += this.state.filterType[x];

        };
        let activeFilter = {
            id: Utils.guidGenerator(),
            type: filterTypeKomma,
            cycle: this.state.filterCycle,
            startDate: this.state.filterStartDate === '' ? null : this.state.filterStartDate,
            endDate: this.state.filterEndDate === '' ? null : this.state.filterEndDate,
            persons: resultPersons,
            categories: filterCategories,
            integrations: filterIntegrations,
            defaultFilter: this.state.filterDefaultEnabled,
            userFilter: this.state.filterUserEnabled,
            policies: this.state.selectedOptionPolicies,
            folders: this.state.selectedOptionFolders.filter(e => e != 'selectAll'),
            recordingOwner: this.state.recordingOwner,
            durationFrom: this.state.durationFrom,
            durationTo: this.state.durationTo,
        };
        //console.log(activeFilter);
        this.setState({ activeFilter: activeFilter });
        this.props.handler(this.state.activeSearch, activeFilter, withSave);
    }

    onChangeMulti = (event, option, index, value) => {
        //console.log('_onChangeMulti() is called: option = ' + JSON.stringify(option));
        const currentSelectedKeys = this.state.selectedOptionKeys || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            let xx = [];
            for (let i = 0; i < tmp.length; i++) {
                xx.push(this.findKeys(tmp[i], this.tmpKey))
            }
            this.setState({
                selectedOptionKeys: tmp,
                sel4list: xx,
                filterDefaultEnabled: false,
                filterUserEnabled: true,
                updated: true
            });
        }
    };

    onChangeMultiIntegration = (event, option, index, value) => {
        //console.log('_onChangeMultiIntegration() is called: option = ' + JSON.stringify(option));
        const currentSelectedKeys = this.state.selectedOptionKeysIntegration || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            let xx = [];
            for (let i = 0; i < tmp.length; i++) {
                xx.push(this.findKeys(tmp[i], this.tmpKeyIntegration))
            }
            //console.log(xx);
            this.setState({
                selectedOptionKeysIntegration: tmp,
                selIntegration4List: xx,
                filterDefaultEnabled: false,
                filterUserEnabled: true,
                updated: true
            });
        }
    };

    handleSearchValuesChange = (item) => {
        this.setState((prevState) => {
            const { searchIn } = prevState;
            const updatedItems = searchIn.includes(item)
                ? [] //For multiple selection: searchIn.filter((selectedItem) => selectedItem !== item)
                : [item]    //For multiple selection: [...searchIn, item];
            return { searchIn: updatedItems };
        });

        if (this.state.activeSearch !== "") {
            this.timerID = setTimeout(
                () => this.startSearch(),
                1000
            );
        }

    };


    updateSelectedOptionKeys = (selectedKeys, option): string[] => {
        selectedKeys = [...selectedKeys]; // modify a copy
        const index = selectedKeys.indexOf(option.key);
        if (option.selected && index < 0) {
            selectedKeys.push(option.key);
        } else {
            selectedKeys.splice(index, 1);
        }
        return selectedKeys;
    };


    findKeys = (k, options) => {
        let out;
        for (let x = 0; x < options.length; x++) {
            if (k === options[x].key) {
                out = options[x];
                break;
            }
        }
        return out;
    }

    onChangeMultiPolicies = (event, option, index, value) => {
        const currentSelectedKeys = this.state.selectedOptionPolicies || [];
        if (option) {
            // User selected/de-selected an existing option
            const selectedOptionPolicies = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            this.setState({
                selectedOptionPolicies,
                filterDefaultEnabled: false,
                filterUserEnabled: true,
                updated: true
            });
        }
    }
    onChangeFolders = (
        event,
        option,
        index,
        value
    ) => {
        // let selectableOptions = this.state.selectableCSFolders
        let selectableOptions = this.foldersOption
        const selected = option?.selected
        let selectedOptions = this.state.selectedOptionFolders
        const currentSelectedOptionKeys = selectedOptions.filter(key => key !== 'selectAll');
        const selectAllState = currentSelectedOptionKeys.length === selectableOptions.length;

        if (option) {
            //console.log('SelectableOptionMenuItemType', SelectableOptionMenuItemType)
            // selectableOptions = this.state.selectableCSFolders
            if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
                // if (option.key === 'selectAll') {
                selectAllState
                    ? this.setState({ selectedOptionFolders: [] })
                    : this.setState({ selectedOptionFolders: ['selectAll', ...selectableOptions.map(o => o.key)] });
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option.key]
                    : currentSelectedOptionKeys.filter(k => k !== option.key);
                if (updatedKeys.length === selectableOptions.length) {
                    updatedKeys.push('selectAll');
                }
                this.setState({
                    selectedOptionFolders: updatedKeys,
                    filterDefaultEnabled: false,
                    filterUserEnabled: true,
                    updated: true
                }
                )
            }
        }
    }

    fillFromDefaultFilter = (newValue) => {
        this.setState({
            sel4list: newValue ? this.categoriesDefaultList : this.categoriesList,
            selectedOptionKeys: newValue ? this.categoriesDefaultListSource : this.categoriesListSource,
            filterType: newValue ? this.props.userRules.defaultFilter.type.length > 0 ? this.props.userRules.defaultFilter.type.split(",") : [] : [],
            filterCycle: newValue ? this.props.userRules.defaultFilter.cycle : "",
            filterStartDate: newValue ? (this.props.userRules.defaultFilter.startDate !== '' && this.props.userRules.defaultFilter.startDate !== '0001-01-01T00:00:00') ? new Date(this.props.userRules.defaultFilter.startDate) : '' : '',
            filterEndDate: newValue ? (this.props.userRules.defaultFilter.endDate !== '' && this.props.userRules.defaultFilter.endDate !== '0001-01-01T00:00:00') ? new Date(this.props.userRules.defaultFilter.endDate) : '' : '',
            filterPersons: newValue ? this.defaultListPersons : this.listPersons,
            filterDefaultEnabled: newValue,
            durationFrom: newValue ? this.props.userRules.defaultFilter.durationFrom : -1,
            durationTo: newValue ? this.props.userRules.defaultFilter.durationTo : -1,
            recordingOwner: newValue ? this.props.userRules.defaultFilter.recordingOwner : false,
            filterUserEnabled: newValue ? false : true,
            updated: true,
            filterState: newValue ? true : false
        });
    }

    //this function is used to fill the icon of filter in recording page 
    getFilterState = () => {
        let filterState = false;
        //console.log(this.state.sel4list);
        if (this.state.filterDefaultEnabled) {
            filterState = true;
        } else if (
            (this.state.sel4list !== null && this.state.sel4list.length > 0)
            || this.state.filterType.length > 0
            || this.state.filterCycle != ""
            || this.state.filterStartDate !== ''
            || this.state.filterEndDate !== ''
            || this.state.selIntegration4List.length > 0
            || this.state.selectedOptionPolicies != null && this.state.selectedOptionPolicies.length > 0
            || this.state.filterPersons.length > 0
            || this.state.selectedOptionFolders.length > 0
        ) {
            filterState = true;
        }
        return filterState;
    }

    render(): JSX.Element {
        var className = (this.props.sizeSmall != null && this.props.sizeSmall === "true") ? "BarSmall" : "Bar";
        let selectedOptions = this.state.searchIn.join(', ');
        const navGroups = [
            {
                name: (
                    <span className="searchText">
                        Search in {selectedOptions}
                    </span>
                ),
                links: [
                    {
                        key: 'Header',
                        title: null,
                        name: (
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "header")}</>)}</MyContext.Consumer>}
                                checked={this.state.searchIn.includes('Header')}
                                onChange={() => this.handleSearchValuesChange('Header')}
                                ariaLabel={<MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "header")}</>)}</MyContext.Consumer>}
                            />
                        ),
                    },
                    {
                        key: 'Speech2Text',
                        title: null,
                        name: (
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "speechToText")}</>)}</MyContext.Consumer>}
                                checked={this.state.searchIn.includes('Speech2Text')}
                                onChange={() => this.handleSearchValuesChange('Speech2Text')}
                            />
                        ),
                    },
                    {
                        key: 'Notes',
                        title: null,
                        name: (
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "notes")}</>)}</MyContext.Consumer>}
                                checked={this.state.searchIn.includes('Notes')}
                                onChange={() => this.handleSearchValuesChange('Notes')}
                            />
                        ),
                    },
                    {
                        key: 'ScenarioId',
                        title: null,
                        name: (
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "ScenarioID")}</>)}</MyContext.Consumer>}
                                checked={this.state.searchIn.includes('ScenarioId')}
                                onChange={() => this.handleSearchValuesChange('ScenarioId')}
                            />
                        ),
                    },
                    {
                        key: 'ConversationId',
                        title: null,
                        name: (
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "conversationId")}</>)}</MyContext.Consumer>}
                                checked={this.state.searchIn.includes('ConversationId')}
                                onChange={() => this.handleSearchValuesChange('ConversationId')}
                            />
                        ),
                    },
                    {
                        key: 'UserId',
                        title: null,
                        name: (
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userId")}</>)}</MyContext.Consumer>}
                                checked={this.state.searchIn.includes('UserId')}
                                onChange={() => this.handleSearchValuesChange('UserId')}
                            />
                        ),
                    },
                    {
                        key: 'Participants',
                        title: null,
                        name: (
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "participants")}</>)}</MyContext.Consumer>}
                                checked={this.state.searchIn.includes('Participants')}
                                onChange={() => this.handleSearchValuesChange('Participants')}
                            />
                        ),
                    },
                    {
                        key: 'CustomFields',
                        title: null,
                        name: (
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "customFields")}</>)}</MyContext.Consumer>}
                                checked={this.state.searchIn.includes('CustomFields')}
                                onChange={() => this.handleSearchValuesChange('CustomFields')}
                            />
                        ),
                    },
                    {
                        key: 'TransferorName',
                        title: null,
                        name: (
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "transferorName")}</>)}</MyContext.Consumer>}
                                checked={this.state.searchIn.includes('TransferorName')}
                                onChange={() => this.handleSearchValuesChange('TransferorName')}
                            />
                        ),
                    },
                ],
            },
        ];
        return (<MyContext.Consumer>{(context) => (
            <Stack horizontal>
                <Panel
                    isOpen={this.state.filterPanelOpen}
                    isBlocking={false}
                    type={PanelType.smallFixedFar}
                    headerText={context.getTranslation("filterPanel", "title")}
                    closeButtonAriaLabel="Close"
                    onDismiss={() => { this.setState({ filterPanelOpen: false }) }}
                >
                    {(this.state.filterDefault != null && "ReplayFilter" != this.props.userRules.defaultFilter.timeBasedType && "VisibilityFilter" != this.props.userRules.defaultFilter.timeBasedType) && <div>< Text variant="medium" block>{context.getTranslation("filterPanel", "defaultFilter")}</Text>
                        <Checkbox
                            label="Enabled"
                            checked={this.state.filterDefaultEnabled}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.fillFromDefaultFilter(newValue)
                            }}
                        /></div>}

                    <Stack vertical >
                        <Stack.Item>
                            <Dropdown
                                multiSelect
                                selectedKeys={this.state.filterType}
                                options={this.getSelectOptionsType(context)}
                                onChange={(event, item) => {
                                    if (item) {
                                        this.setState({
                                            filterType: item.selected ? [...this.state.filterType, item.key] : this.state.filterType.filter(key => key !== item.key),
                                            filterDefaultEnabled: false,
                                            filterUserEnabled: true,
                                            updated: true
                                        });
                                    }
                                }}
                                autoComplete={true}
                                allowFreeform={false}
                                label={context.getTranslation("filterPanel", "type")}
                            />

                        </Stack.Item>

                        <Stack.Item>
                            <ComboBox
                                selectedKey={this.state.filterCycle}
                                label={context.getTranslation("dashboard", "cycle")}
                                onChange={(event, option, index, newValue) => {
                                    this.setState({
                                        filterCycle: option.key,
                                        filterStartDate: '',
                                        filterEndDate: '',
                                        filterDefaultEnabled: false,
                                        filterUserEnabled: true,

                                        updated: true
                                    });
                                }}
                                options={this.getSelectOptionsCycle(context)}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <div>{context.getTranslation("dashboard", "startDate")}</div>
                            <DateTimePicker
                                minDate={(this.props.userRules.defaultFilter !== null && this.props.userRules.defaultFilter.timeBasedType === "VisibilityFilter") ? this.getMinDate() : ''}
                                maxDate={(this.props.userRules.defaultFilter !== null && this.props.userRules.defaultFilter.timeBasedType === "VisibilityFilter") ? this.getMaxDate() : ''}
                                locale={this.props.authorization.locale}
                                calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}
                                onChange={(date) => {
                                    //console.log(this.state.filterStartDate, this.state.filterEndDate);
                                    if (date <= this.state.filterEndDate || this.state.filterEndDate === '' || this.state.filterEndDate == null) {
                                        this.setState({
                                            filterStartDate: date,
                                            filterCycle: '',
                                            filterDefaultEnabled: false,
                                            filterUserEnabled: true,

                                            updated: true
                                        });
                                    }
                                }}
                                value={this.state.filterStartDate}
                            />
                            <div>{context.getTranslation("dashboard", "endDate")}</div>
                            <DateTimePicker
                                minDate={(this.props.userRules.defaultFilter !== null && this.props.userRules.defaultFilter.timeBasedType === "VisibilityFilter") ? this.getMinDate() : ''}
                                maxDate={(this.props.userRules.defaultFilter !== null && this.props.userRules.defaultFilter.timeBasedType === "VisibilityFilter") ? this.getMaxDate() : ''}
                                locale={this.props.authorization.locale}
                                calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}
                                onChange={(date) => {
                                    if (date >= this.state.filterStartDate || this.state.filterEndDate === '' || this.state.filterEndDate == null) {
                                        this.setState({
                                            filterEndDate: date,
                                            filterCycle: '',
                                            filterDefaultEnabled: false,
                                            filterUserEnabled: true,

                                            updated: true
                                        });
                                    }
                                }}
                                value={this.state.filterEndDate}
                            />
                        </Stack.Item>

                        <Stack.Item >
                            <br />
                            <Label>{context.getTranslation("filterPanel", "recordingDuration")}</Label>
                            <div>
                                <div style={{ display: "inline-block", width: "35%", marginRight: "18px" }}>
                                    <TextField type={"number"}
                                        label={context.getTranslation("settings", "ticketFrom")}
                                        value={this.state.durationFrom === -1 ? '' : this.state.durationFrom}
                                        onChange={(event, newValue) => {
                                            //in case user leaves empty will sends -1 and ui displays empty
                                            //because leaving it 0 instead of empty is confusing
                                            if (isNaN(newValue) || newValue === "") {
                                                this.setState({
                                                    durationFrom: parseInt(-1),
                                                    filterDefaultEnabled: false,
                                                    filterUserEnabled: true,
                                                    updated: true
                                                });
                                            } else {
                                                this.setState({
                                                    durationFrom: parseInt(newValue),
                                                    filterDefaultEnabled: false,
                                                    filterUserEnabled: true,
                                                    updated: true
                                                });
                                            }
                                        }}
                                        min={0}
                                    />
                                </div>
                                <div style={{ display: "inline-block", width: "35%" }}>
                                    <TextField type={"number"}
                                        label={context.getTranslation("settings", "ticketTo")}
                                        value={this.state.durationTo === -1 ? '' : this.state.durationTo}
                                        onChange={(event, newValue) => {
                                            //in case user leaves empty will sends -1 and ui displays empty
                                            //because leaving it 0 instead of empty is confusing
                                            if (isNaN(newValue) || newValue === "") {
                                                this.setState({
                                                    durationTo: parseInt(-1),
                                                    filterDefaultEnabled: false,
                                                    filterUserEnabled: true,
                                                    updated: true
                                                });
                                            } else {
                                                this.setState({
                                                    durationTo: parseInt(newValue),
                                                    filterDefaultEnabled: false,
                                                    filterUserEnabled: true,
                                                    updated: true
                                                });
                                            }
                                        }}
                                        min={0}

                                    />
                                </div>
                            </div>

                        </Stack.Item>
                        <br />
                        <Label>{context.getTranslation("filterPanel", "selectedUser")}</Label>

                        <Stack.Item>
                            <UserPicker
                                placeholder={context.getTranslation("filterPanel", "selectedUser")}
                                user={this.state.filterPersons}
                                availableUsers={this.props.userRules.defaultFilter != null && this.props.userRules.defaultFilter.persons != null && this.props.userRules.defaultFilter.persons.length > 0 && this.props.userRules.defaultFilter.timeBasedType === "VisibilityFilter" ? this.props.userRules.defaultFilter.persons : null}
                                fc={(user) => {
                                    if (user.length > 0) {
                                        this.setState({
                                            filterPersons: user,
                                            filterDefaultEnabled: false,
                                            filterUserEnabled: true,

                                            updated: true
                                        });
                                    } else {
                                        this.setState({
                                            filterPersons: user,
                                            filterDefaultEnabled: false,
                                            filterUserEnabled: true,
                                            recordingOwner: false,
                                            updated: true
                                        });
                                    }

                                }}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label={context.getTranslation("filterPanel", "recordingOwner")}
                                checked={this.state.recordingOwner}
                                styles={checkboxStyles}
                                onChange={(event, newValue) => {

                                    this.setState({
                                        recordingOwner: newValue,
                                        filterDefaultEnabled: false,
                                        filterUserEnabled: true,
                                        updated: true
                                    });
                                }}
                                disabled={this.state.filterPersons.length > 0 ? false : true}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <Query
                                query={queryGetIntegration}
                                variables={{}}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) {
                                        return (
                                            <div>
                                                <Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />
                                            </div>
                                        );
                                    }
                                    if (error) return <h4>Failed to connect</h4>;

                                    if (data.getIntegrationData == null) {
                                        return <div />;
                                    }
                                    this.tmpKeyIntegration = [];
                                    this.tmpKeyIntegration.push({ key: "Header1", text: context.getTranslation("filterPanel", "integrationsSelection"), itemType: 2 });
                                    // First of all put teams as standard integration
                                    this.tmpKeyIntegration.push({
                                        name: "Teams",
                                        text: "Teams",
                                        id: "1",
                                        key: "1"
                                    });
                                    if (data.getIntegrationData.integrationdata != null) {
                                        for (let x = 0; x < data.getIntegrationData.integrationdata.length; x++) {
                                            let i = {
                                                name: data.getIntegrationData.integrationdata[x].name,
                                                text: data.getIntegrationData.integrationdata[x].name,
                                                id: data.getIntegrationData.integrationdata[x].id,
                                                key: data.getIntegrationData.integrationdata[x].id
                                            };
                                            this.tmpKeyIntegration.push(i);
                                        }
                                    }
                                    return <ComboBox
                                        label={context.getTranslation("filterPanel", "integrationsSelection")}
                                        selectedKey={this.state.selectedOptionKeysIntegration}
                                        allowFreeform={false}
                                        onChange={this.onChangeMultiIntegration}
                                        autoComplete={true}
                                        options={this.getIntegrationComboBoxes(this.tmpKeyIntegration)}
                                        multiSelect
                                    />
                                }}
                            </Query>

                        </Stack.Item>
                        <Stack.Item>
                            <Query
                                query={getAllCategoryVersions}
                                variables={{}}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) {
                                        return (
                                            <div>
                                                <Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />
                                            </div>
                                        );
                                    }
                                    if (error) return <h4>Failed to connect</h4>;

                                    if (data.getAllCategoryVersions == null) {
                                        return <div />;
                                    }
                                    this.tmpKey = [];
                                    this.tmpKey.push({ key: "Header1", text: context.getTranslation("filterPanel", "categorySelection"), itemType: 2 });
                                    if (data.getAllCategoryVersions.categorydata != null) {
                                        let dataFilter = data.getAllCategoryVersions.categorydata;
                                        if (!this.state.showAllVersionCategories) {
                                            dataFilter = data.getAllCategoryVersions.categorydata.filter(c => c.selected)
                                        }
                                        for (let x = 0; x < dataFilter.length; x++) {
                                            let i = {
                                                name: dataFilter[x].name + " - v" + dataFilter[x].version,
                                                fromCompliance: dataFilter[x].fromCompliance,
                                                text: dataFilter[x].name + " - v" + dataFilter[x].version,
                                                keywords: dataFilter[x].keywords,
                                                id: dataFilter[x].id,
                                                key: dataFilter[x].id,
                                                tenantId: dataFilter[x].tenantId,
                                                frequence: dataFilter[x].frequence,
                                                link: dataFilter[x].link,
                                                sentiments: dataFilter[x].sentiments,
                                                selectedDb: dataFilter[x].selected,
                                            };
                                            this.tmpKey.push(i);
                                        }
                                    }
                                    
                                    return <div>
                                        <ComboBox
                                            label={context.getTranslation("filterPanel", "categorySelection")}
                                            selectedKey={this.state.selectedOptionKeys}
                                            allowFreeform={false}
                                            onChange={this.onChangeMulti}
                                            autoComplete={true}
                                            options={this.getCategoriesComboBoxes(this.tmpKey)}
                                            multiSelect
                                        />
                                        <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                label={context.getTranslation("compliance", "categoriesSelecionShowVersions")}
                                                checked={this.state.showAllVersionCategories}
                                                onChange={() => {
                                                    let showAllVersionCategories = !this.state.showAllVersionCategories
                                                    let optionIds = []
                                                    let optionsCategories = this.getCategoriesComboBoxes(this.tmpKey);
                                                    if (showAllVersionCategories) {
                                                        optionIds = optionsCategories.map(p => p.id)
                                                    } else {
                                                        optionIds = optionsCategories.filter(p => p.selectedDB).map(p => p.id)
                                                    }
                                                    let selectedOptionKeys = this.state.selectedOptionKeys !== undefined ?
                                                        this.state.selectedOptionKeys.filter(p => optionIds.includes(p)) : []
                                                    this.setState({
                                                        showAllVersionCategories,
                                                        selectedOptionKeys
                                                    })
                                                }}
                                            />
                                        </div>

                                    </div>
                                }}
                            </Query>

                        </Stack.Item>
                        {this.props.userRules.ruleAccess != null
                            && this.props.userRules.ruleAccess.userCompliancePolicy !== undefined
                            && this.props.userRules.ruleAccess.userCompliancePolicy &&
                            <Stack.Item>
                                <Query
                                    query={queryAllPolicyVersions}
                                    variables={{}}>
                                    {({ loading, error, data }) => {
                                        if (loading) {
                                            return (
                                                <div>
                                                    <Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />
                                                </div>
                                            );
                                        }
                                        if (error) return <h4>Failed to connect</h4>;
                                        if (data.getAllPolicyVersions == null) {
                                            return <div />;
                                        }
                                        this.optionsPolicies = [];
                                        this.optionsPolicies.push({ key: "Header1", text: context.getTranslation("compliance", "policiesSelecion"), itemType: 2 });
                                        if (data.getAllPolicyVersions !== null && data.getAllPolicyVersions.length > 0) {
                                            let dataFilter = []
                                            if (this.state.showAllVersionPolicies) {
                                                dataFilter = data.getAllPolicyVersions
                                            } else {
                                                dataFilter = data.getAllPolicyVersions.filter(pol => pol.selected)
                                            }
                                            dataFilter/*.sort((a, b) => a.masterItemId > b.masterItemId ? 1 : -1)*/.forEach(p => {
                                                let o = {
                                                    text: p.name + " - v" + p.version,
                                                    key: p.id,
                                                    selectedDB: p.selected,
                                                    id: p.id
                                                }
                                                this.optionsPolicies.push(o);
                                            })
                                            let comboOptionsTitle = this.optionsPolicies.splice(0, 1)[0]
                                            this.optionsPolicies.sort((a, b) => {
                                                if (a.text.toLowerCase() > b.text.toLowerCase()) return 1
                                                return -1
                                            })
                                            this.optionsPolicies.splice(0, 0, comboOptionsTitle)
                                        }
                                        return <div>
                                            <ComboBox
                                                label={context.getTranslation("compliance", "policiesSelecion")}
                                                selectedKey={this.state.selectedOptionPolicies}
                                                onChange={this.onChangeMultiPolicies}
                                                options={this.optionsPolicies}
                                                multiSelect
                                                disabled={!this.props.userRules.addOnCompliance}
                                            />
                                            <div style={{ marginTop: "10px" }}>
                                                <Checkbox
                                                    label={context.getTranslation("compliance", "policiesSelecionShowVersions")}
                                                    checked={this.state.showAllVersionPolicies}
                                                    onChange={() => {
                                                        let showAllVersionPolicies = !this.state.showAllVersionPolicies
                                                        let optionIds = []
                                                        if (showAllVersionPolicies) {
                                                            optionIds = this.optionsPolicies.map(p => p.id)
                                                        } else {
                                                            optionIds = this.optionsPolicies.filter(p => p.selectedDB).map(p => p.id)
                                                        }
                                                        let selectedOptionPolicies = this.state.selectedOptionPolicies !== undefined ?
                                                            this.state.selectedOptionPolicies.filter(p => optionIds.includes(p)) : []
                                                        this.setState({
                                                            showAllVersionPolicies,
                                                            selectedOptionPolicies
                                                        })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    }}
                                </Query>
                            </Stack.Item>}
                        {this.props.userRules.ruleAccess.userSafe !== "No" && <StackItem>
                            <Query
                                query={getAllFoldersShrinked}
                                variables={{ input: this.props.authorization }}>
                                {({ loading, error, data }) => {
                                    if (loading) {
                                        return (
                                            <div>
                                                <Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />
                                            </div>
                                        );
                                    }
                                    if (error) return <h4>Failed to connect</h4>;
                                    this.foldersOption = [
                                        { key: 'Header1', text: `${context.getTranslation("filterPanel", "convSafe")}`, itemType: SelectableOptionMenuItemType.Header },
                                        { key: 'selectAll', text: `${context.getTranslation("filterPanel", "selectAll")}`, itemType: SelectableOptionMenuItemType.SelectAll },
                                        { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider }
                                    ];
                                    let availableFolders = data.getConversationSafeFolders.map(e => {
                                        return ({
                                            "key": e.id ? e.id : '-1',
                                            "text": e.name,
                                            "itemType": SelectableOptionMenuItemType.Normal
                                        })
                                    })
                                    let options = _.concat(this.foldersOption, availableFolders)
                                    this.foldersOption = availableFolders



                                    return (
                                        <ComboBox
                                            label={`${context.getTranslation("filterPanel", "convSafe")}`}
                                            selectedKey={this.state.selectedOptionFolders}
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onChange={this.onChangeFolders}
                                            options={options}
                                            multiSelect
                                        />
                                    )
                                }
                                }
                            </Query>
                        </StackItem>}
                    </Stack>
                    <p style={{ margin: "16px 0 8px" }} />

                    <Stack horizontal>
                        <StackItem>
                            <PrimaryButton
                                text={context.getTranslation("filterPanel", "search")}
                                disabled={!this.state.updated}
                                onClick={() => {
                                    this.sendFilter(false);
                                }}
                                allowDisabledFocus={true}
                                style={{ marginRight: "8px" }}
                            />
                        </StackItem>
                        <StackItem styles={this.styles}>
                            <DefaultButton
                                text={context.getTranslation("common", "save")}
                                disabled={!this.state.updated}
                                onClick={() => {
                                    this.sendFilter(true);
                                }}
                                allowDisabledFocus={true}
                            />
                        </StackItem>
                    </Stack>

                </Panel>
                <StackItem style={{ display: 'flex', flexDirection: 'column' }}>
                    <Stack horizontal >
                        <StackItem >
                            <SearchBox className={className}
                                placeholder={context.getTranslation("filterPanel", "search")}
                                onEscape={ev => {

                                }}
                                onClear={ev => {
                                    setTimeout(() => {
                                        document.activeElement.blur();
                                    }, 10);
                                }}
                                onChange={(event, newValue) => (
                                    event?.persist, this.sendSearch(newValue)
                                )
                                }
                                onSearch={newValue => { }}
                                //onFocus={this.handleSearchFocus}
                                //onBlur={this.handleSearchBlur}
                                ref={this.searchBoxRef}
                                styles={searchStyle(this.props.sizeSmall)}

                            />

                            {this.state.showNavBar && (
                                <div ref={this.navRef} >  {/*onBlur={this.handleNavBlur}*/}
                                    <Nav styles={this.navStyles} groups={navGroups} />
                                </div>
                            )}
                        </StackItem>
                        <StackItem>
                            <Icon styles={iconStyle} onClick={this.handleButtonSearchIn} iconName={this.state.showNavBar ? "ChevronUp" : "ChevronDown"} />
                        </StackItem>
                    </Stack>

                </StackItem>



                <StackItem>
                    <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }} onClick=
                        {
                            () => {
                                this.setState({ filterPanelOpen: this.state.filterPanelOpen ? false : true })
                            }
                        } iconName={this.getFilterState() ? "FilterSolid" : "Filter"} />
                </StackItem>

            </Stack>)}
        </MyContext.Consumer>
        );
    }
}
