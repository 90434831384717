import { MessageBar, Stack, MessageBarType } from 'office-ui-fabric-react'
import React, { Component } from 'react'
import { MyContext } from '../../context';

export default class AlertBar extends Component {

    static contextType = MyContext

    render() {
        return (<div>
            {
                this.props.message !== "" && <Stack style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <MessageBar
                        messageBarType={this.props.alertType === "warning" ? MessageBarType.warning : MessageBarType.error}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close"
                        onDismiss={this.props.clearAlert}
                    >
                        {this.props.message}
                    </MessageBar>
                </Stack>
            }
        </div>
        )
    }
}