import React, { Component } from 'react';
//APPOLO + GQL
import { ApolloProvider, Query } from "react-apollo";
import { queryGetCategory } from "../../queries/queryCategory";
import { queryGetConfigData } from "../../queries/querySettings";
//OFFICE Fabric
import { Customizer, getId } from '@uifabric/utilities';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
//Styles
import './Setter.scss';
//OTHER COMPONENS
import { ToastContainer } from "react-toastify";
import CategoryList from "./RuleList/CategoryList";
import AddCategorie from "./RuleList/AddCategorie";
import { MyContext } from '../../context';
import AlertBar from '../CustomComponents/AlertBar';



export default class CategoryWrapper extends Component {

    state = {
        showPanel: false,
        trapPanel: false,
        alertMessagePanel: ""
    };

    categorydata = []

    _layerHostId: string = getId('layerHost');

    getHeaderPanel = () => {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div style={{ flex: "auto", marginLeft: "20px" }}>
                        <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>{this.state.info === null ? context.getTranslation("configuration", "addCategory") : context.getTranslation("configuration", "editCategory")}</div>
                        <AlertBar
                            message={context.getTranslation("configuration", this.state.alertMessagePanel)}
                            alertType={"error"}
                            clearAlert={this.clearAlert}
                        />
                    </div >)}
            </MyContext.Consumer>
        )
    }

    setMessageError = (message) => {
        this.setState({
            alertMessagePanel: message
        })
    }

    refechCategory = null;

    _onDismissPanel = (): void => {
        this.setState({
            showPanel: false
        });
        if (this.refechCategory !== null) {
            //this.refechCategory()

            this.props.apol
                .query({
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization }
                })
        }
    };

    _show = (current, vall) => {
        switch (vall) {
            case undefined:
            case null:
                break;
            default:
                if (current == null) {
                    this.setState({ showPanel: true, case: vall, info: null });

                }
                else {
                    switch (current.length) {
                        case undefined:
                            this.setState({ showPanel: true, case: vall, info: [current] });
                            break;
                        default:
                            this.setState({ showPanel: true, case: vall, info: current });
                            break;
                    }
                }
        }
    }

    getAllCategories = (layerFunction, auth, apol, userRules) => {
        return <MyContext.Consumer>
            {(context) => (
                <Query
                    query={queryGetCategory}
                    variables={{ auth }} >
                    {({ loading, error, data, refetch }) => {
                        //this.refechCategory = refetch
                        if (loading) return <div>
                            <Spinner label={context.getTranslation("common", "loadingData")} ariaLive="assertive" labelPosition="top" />
                        </div>;
                        if (error) {
                            console.log(error)
                            return (<AlertBar message={"Error!"} alertType={"error"} />);
                        }
                        if (data.getCategoryData == null) {
                            return (<AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />);
                        }
                        this.categorydata = data.getCategoryData.categorydata
                        return <CategoryList key="04" icon={"AnalyticsView"} data={data.getCategoryData.categorydata} tenantData={data.getCategoryData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                    }
                    }
                </Query>
            )
            }
            </MyContext.Consumer>
        }

    panelCategory = () => {
        let alreadyExistingNames = this.categorydata !== null ?  this.categorydata.map(c => c.name.toLowerCase()) : []
        let out = null;
        if (this.state.info != null) {
            let tmpp = [];
            if (this.state.info[0].keywords != null) {
                for (let x = 0; x < this.state.info[0].keywords.length; x++) {
                    let splitKeywordAndSoundlike = this.state.info[0].keywords[x].split('_');
                    tmpp.push({ text: splitKeywordAndSoundlike[0], soundlike: splitKeywordAndSoundlike[1] });
                }
            }
            out = {
                sel4list: tmpp,
                name: this.state.info[0].name,
                sentiment: this.state.info[0].sentiment,
                frequence: this.state.info[0].frequence,
                link: this.state.info[0].link,
                keyword: "",
                id: this.state.info[0].id,
                searchExactKeywords: this.state.info[0].searchExactKeywords,
                countUniqueWordsOnce: this.state.info[0].countUniqueWordsOnce,
                categorizationActionType: this.state.info[0].categorizationActionType,
                retentionTimeSetting: this.state.info[0].retentionTimeSetting,
                version: this.state.info[0].version,
                masterItemId: this.state.info[0].masterItemId,
                selected: this.state.info[0].selected,
                selectedOtherVersion: false,
                selectedVersion: this.state.info[0].id,
                categoryGroup: this.state.info[0].categoryGroup
            }
            alreadyExistingNames = alreadyExistingNames.filter(c => c !== this.state.info[0].name.toLowerCase())
        }
        return <AddCategorie
            data={out}
            cl={this._onDismissPanel}
            authorization={this.props.authorization}
            apol={this.props.apol}
            userRules={this.props.userRules}
            fromCompliance={true}
            setMessageError={this.setMessageError}
            alreadyExistingNames={alreadyExistingNames}
        />
    }


    render() {
        return (
            <div style={{ overflow: "auto", maxHeight: window.innerHeight - 120, position: 'relative', marginTop: "15px" }} >
                <div className="">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            {/*CREATE/EDIT PANEL CATEGORY*/}
                            <Customizer
                                scopedSettings={
                                    this.state.trapPanel
                                        ? {
                                            Layer: {
                                                hostId: this._layerHostId
                                            }
                                        }
                                        : {}
                                }
                            >
                                {this.state.showPanel && (
                                    <MyContext.Consumer>
                                        {(context) => (
                                            <Panel
                                                type={PanelType.medium}
                                                isOpen={true}
                                                hasCloseButton={true}
                                                focusTrapZoneProps={{
                                                    isClickableOutsideFocusTrap: true,
                                                    forceFocusInsideTrap: false
                                                }}
                                                onDismiss={this._onDismissPanel}
                                                onRenderHeader={this.getHeaderPanel}

                                            >
                                                {this.panelCategory()}
                                            </Panel>)}
                                    </MyContext.Consumer>

                                )}
                            </Customizer>

                            {/*LIST CATEGORIES*/}
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                <ApolloProvider client={this.props.apol}>
                                    {this.getAllCategories(this._show, this.props.authorization, this.props.apol, this.props.userRules)}

                                </ApolloProvider>

                            </div>
                        </div>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }



}
