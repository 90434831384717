import { IconButton, Stack, Dropdown, TextField } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';

class FragmentItem extends Component {
    static contextType = MyContext

    //no internal state, its state is managed by his father FragmentList

    up = () => {
        this.props.up(this.props.data.index)
    }

    down = () => {
        this.props.down(this.props.data.index)
    }

    delete = () => {
        this.props.delete(this.props.data.index)
    }

    onChangeSelectType = (e, item) => {
        if (item) {
            const selected = item !== null ? item.key : null
            this.props.updateFragmentType(selected, this.props.data.index)
        }
    }

    changeNDigits = (e, value) => {
        this.props.changeNDigits(value, this.props.data.index)

    }

    chanegValue = (e, value) => {
        this.props.changeValue(value, this.props.data.index)
    }

    add = () => {
        this.props.addFragment()
    }

    render() {
        const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 200 } };
        return (
            <div style={{ marginTop: "10px" }}>
                <Stack horizontal>
                    <IconButton iconProps={{ iconName: 'ChevronUpMed' }} onClick={this.up} disabled={this.props.first} />
                    <IconButton iconProps={{ iconName: 'ChevronDownMed' }} onClick={this.down} disabled={this.props.last} />
                    <IconButton iconProps={{ iconName: 'Delete' }} onClick={this.delete} disabled={this.props.onlyOneFragment} />
                    {this.props.fragmentTypeOptions !== null && this.props.fragmentTypeOptions.length > 0 &&
                        <Dropdown
                            styles={dropdownStyles}
                            placeholder={this.context.getTranslation("compliance", "selectType")}
                            label=""
                            selectedKey={this.props.data.fragmentType}
                            onChange={this.onChangeSelectType}
                            options={this.props.fragmentTypeOptions}
                            disabled={false}
                        />
                    }
                    <span style={{ padding: "10px" }}> # </span>
                    <TextField value={this.props.data.nDigits} onChange={this.changeNDigits} style={{ width: "50px" }} />
                    <span style={{ padding: "10px" }}>{this.context.getTranslation("compliance", "orExact")}</span>
                    <TextField value={this.props.data.value} onChange={this.chanegValue} style={{ width: "100px" }} />
                    <IconButton iconProps={{ iconName: 'Add' }} onClick={this.add} hidden={!this.props.last} />
                </Stack>
            </div>
        );
    }
}

export default FragmentItem;