import React, { Component } from 'react';
import { Spinner } from 'office-ui-fabric-react';
import { MyContext } from '../../../../context';
import EditScorecard from './EditScorecard';
import { queryScorecard } from "../AIQualityManagementQuery"
import { Query } from 'react-apollo';
import AlertBar from '../../../CustomComponents/AlertBar';

export default class EditScorecardContainer extends Component {

    static contextType = MyContext

    render() {
        const { auth, id, apol, hidePanel, userRules } = this.props
        return (
            <div>
                {id !== null && <Query
                    query={queryScorecard}
                    variables={{ id }}
                >
                    {({ loading, error, data }) => {
                        if (loading) { return <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" /> }
                        if (error) {
                            console.log(error)
                            return <AlertBar message={this.context.getTranslation("compliance", "error")} alertType="error" />
                        }
                        if (data.getScorecard == null) {
                            return <AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />
                        }
                        return (
                            <EditScorecard
                                data={data.getScorecard}
                                hidePanel={hidePanel}
                                apol={apol}
                                auth={auth}
                                userRules={userRules}
                                refreshScorecards={this.props.refreshScorecards}
                                setMessageError={this.props.setMessageError}
                                updateScorecardId={this.props.updateScorecardId}
                            />
                        )
                    }}
                </Query>
                }
                {id === null &&
                    <EditScorecard
                        hidePanel={hidePanel}
                        apol={apol}
                        auth={auth}
                        userRules={userRules}
                        refreshScorecards={this.props.refreshScorecards}
                        setMessageError={this.props.setMessageError}
                        updateScorecardId={this.props.updateScorecardId}
                    />
                }
            </div >
        )
    }
}