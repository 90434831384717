import 'core-js/es/';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import fetch from 'node-fetch';
import Promise from 'promise-polyfill';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MyProvider } from '../src/context';

global.fetch = fetch

// To add to window
if (!window.Promise) {
    window.Promise = Promise;
}

const rootElement = document.getElementById('root');

ReactDOM.render(
    <MyProvider>
        <BrowserRouter >
            <ToastContainer
                position="bottom-right"
                autoClose={10000}
                pauseOnFocusLoss={false}
                hideProgressBar={false}
                newestOnTop
                closeOnClick={false}
                rtl={false}
                draggable={false}
                pauseOnHover
            />
            <App/>
        </BrowserRouter>
        </MyProvider>,
    rootElement);

registerServiceWorker();
