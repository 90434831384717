import gql from "graphql-tag";

export const queryReportData = gql`
        query ent ($id:String, $input : AuthInput) {
          getReportData (reportId: $id, input: $input)
          {
            id
            title
            widget
            text
            type
            kpi
            startDate
            endDate
            tForm
            reportSend
            reportMail
            conversationType
            jobType
            {
                intervalMonth
                intervalDay
                intervalHour
                intervalMinute
                intervalType
                jobStartTime
            }
            categories
            alarming {
                mail
                eventType
                value
                alarmActive
            }
            position {
                w
                h
                x
                y
            }
            cycle
            keywords
            data {
                text
                user {
                   userId 
                   items {
                     value
                     args
                   }
                }
                total {
                    value
                    args
                    hit
                    noHit
                }
                time {
                    time
                    inbound
                    outbound
                    meeting
                    intern
                    unknown
                    recorded
                    total
                    no
                    noStart
                    error
                    totalLoss
                    onlySilence
                    onlyTranscript
                    lessDuration
                    packetLoss
                    callTry
                    chat
                    upload
                    hit
                    noHit
                }
                keymap {
                    name
                    items {
                        value
                        name
                    }
                }
            }
          }
        }

    `;
export const checkMailForDomainRestrictions = gql`
                         mutation ent ($auth:AuthInput, $email: String){
                            checkMailForDomainRestrictions(input:$auth, email: $email)
                                   
                              }
                            `;

export const getAvailableExportJobList = gql`
    query getAvailableExportJobList($auth:AuthInput){
        getAvailableExportJobList(input:$auth)
            {
            tenantId
            jobList{
                jobId
                jobName
                }
            }
        }
        `;

export const getAvailableImportJobList = gql`
    query getAvailableImportJobList($auth:AuthInput){
        getAvailableImportJobList(input:$auth)
            {
            tenantId
            jobList{
                jobId
                jobName
                }
            }
        }
        `;
