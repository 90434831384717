import React, { Component } from 'react';
import ListOfDependentCheckboxes from './ListOfDependentCheckboxes'

class VisibilityReplayRule extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        let primaryProp = {
            key: 'replayVisibilityDetails',
            value: this.props.data.replayVisibilityDetails,
            rules: this.props.data.userRulesVisibilityActive,
            translation: {
                key1: 'configuration',
                key2: 'replayVisibilityDetails',
            },
        };
        let secondaryRules = this.props.data.secondary
        let secondaryProps = [
            {
                key: 'replayVisibilityDetailsTranscript',
                value: secondaryRules.replayVisibilityDetailsTranscript.value,
                rules: secondaryRules.replayVisibilityDetailsTranscript.rules,
                translation: {
                    key1: "configuration",
                    key2: "replayVisibilityDetailsTranscript"
                }
            },
            {
                key: 'replayVisibilityDetailsAnalyticsChange',
                value: secondaryRules.replayVisibilityDetailsAnalyticsChange.value,
                rules: secondaryRules.replayVisibilityDetailsAnalyticsChange.rules,
                translation: {
                    key1: "configuration",
                    key2: "replayVisibilityDetailsAnalyticsChange"
                }
            },
            {
                key: 'replayVisibilityDetailsAnalytics',
                value: secondaryRules.replayVisibilityDetailsAnalytics.value,
                rules: secondaryRules.replayVisibilityDetailsAnalytics.rules,
                translation: {
                    key1: "configuration",
                    key2: "replayVisibilityDetailsAnalytics"
                }
            },
            {
                key: 'replayVisibilityDetailsNotes',
                value: secondaryRules.replayVisibilityDetailsNotes.value,
                rules: secondaryRules.replayVisibilityDetailsNotes.rules,
                translation: {
                    key1: "configuration",
                    key2: "replayVisibilityDetailsNotes"
                }
            },
            {
                key: 'replayVisibilityDetailsTTL',
                value: secondaryRules.replayVisibilityDetailsTTL.value,
                rules: secondaryRules.replayVisibilityDetailsTTL.rules,
                translation: {
                    key1: "configuration",
                    key2: "replayVisibilityDetailsTTL"
                }
            },
            {
                key: 'replayVisibilityDetailsCustomFields',
                value: secondaryRules.replayVisibilityDetailsCustomFields.value,
                rules: secondaryRules.replayVisibilityDetailsCustomFields.rules,
                translation: {
                    key1: "configuration",
                    key2: "replayVisibilityDetailsCustomFields"
                }
            },
            {
                key: 'replayVisibilityDetailsMetadata',
                value: secondaryRules.replayVisibilityDetailsMetadata.value,
                rules: secondaryRules.replayVisibilityDetailsMetadata.rules,
                translation: {
                    key1: "configuration",
                    key2: "replayVisibilityDetailsMetadata"
                }
            },


        ]

        if (this.props.userRules.addOnQualityManagement) {
            secondaryProps = [...secondaryProps, {
                key: 'visibilityDetailsQM',
                value: secondaryRules.visibilityDetailsQM.value,
                rules: secondaryRules.visibilityDetailsQM.rules,
                // default: true,
                translation: {
                    key1: "compliance",
                    key2: "AIQM"
                }
            }]
        }
        secondaryProps = [...secondaryProps,
        {
            key: 'replayVisibilityDetailsReplay',
            value: secondaryRules.replayVisibilityDetailsReplay.value,
            rules: secondaryRules.replayVisibilityDetailsReplay.rules,
            // default: true,
            translation: {
                key1: "configuration",
                key2: "replayVisibilityDetailsReplay"
            }
        }]

        return (
            <div>
                {/* Your additional component code or JSX */}
                <ListOfDependentCheckboxes
                    {...this.props}
                    primary={primaryProp}
                    secondary={secondaryProps}
                    secondaryDefault={'replayVisibilityDetailsMetadata'}
                />
            </div>
        );
    }
}

export default VisibilityReplayRule;
