import React, { Component } from 'react';
import { Separator, IconButton, Stack, TextField, Dropdown, Spinner, Toggle } from 'office-ui-fabric-react';
import { MyContext } from '../../../../context';
import { queryAllAdherencePhrases } from "../../AdherencePhrases/AdherencePhrasesQuery"
import { queryAllAIQuestions } from '../../AIQuestion/AIQuestionQuery';
import { queryAllAdherencePhrases as queryAllAIAdherencePhrases } from "../../AIAdherencePhrases/AIAdherencePhrasesQuery"
import AlertBar from '../../../CustomComponents/AlertBar';
import { Query } from 'react-apollo';

export default class EditScorecardSection extends Component {

    static contextType = MyContext

    deleteSection = (e) => {
        this.props.updateSection(null, this.props.indexSection, "delete")
    }

    deleteEvaluation = (indexEvaluation) => {
        const newSection = {
            ...this.props.section,
            evaluationItems: this.props.section.evaluationItems.filter((item, j) => indexEvaluation !== j)
        }
        this.props.updateSection(newSection, this.props.indexSection, "update")
    }

    updateSectionField = (e, field) => {
        const newSection = { ...this.props.section, [field]: e.target.value }
        this.props.updateSection(newSection, this.props.indexSection, "update")
    }

    updateEvaluationField = (value, indexEvaluation, field) => {
        const newSection = {
            ...this.props.section,
            evaluationItems: this.props.section.evaluationItems.map(
                (item, j) => {
                    if (j === indexEvaluation) {
                        if (field === "itemType") {
                            return { ...item, itemType: value, itemId: "", itemValue: "" }
                        } else {
                            return { ...item, [field]: value }
                        }
                    } else {
                        return item
                    }
                }
            )
        }
        this.props.updateSection(newSection, this.props.indexSection, "update")
    }

    render() {
        const { section } = this.props
        return (
            <div>
                <Stack horizontal>
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        onClick={this.deleteSection} />
                    <h5>{this.context.getTranslation("compliance", "section")}</h5>
                </Stack>
                <div style={{ marginLeft: "30px" }}>
                    <TextField
                        placeholder={this.context.getTranslation("compliance", "name")}
                        value={section.name}
                        onChange={(e) => this.updateSectionField(e, "name")}
                    />
                </div>
                {section.evaluationItems.length > 0 &&
                    <div style={{ marginLeft: "20px" }}>
                        {
                            //MANAGE EVALUATIONS
                            section.evaluationItems.map((evaluation, indexEvaluation) => {
                                return <div key={"evaluation" + indexEvaluation}>
                                    <Stack horizontal>
                                        <IconButton
                                            iconProps={{ iconName: 'Delete' }}
                                            onClick={() => this.deleteEvaluation(indexEvaluation)}
                                        />
                                        <h5>{this.context.getTranslation("compliance", "evaluation")}</h5>
                                    </Stack>
                                    <Stack horizontal style={{ marginLeft: "30px" }}>
                                        <Stack.Item align="center" grow>
                                            <Dropdown
                                                placeholder={this.context.getTranslation("compliance", "selectCriteria")}
                                                label=""
                                                selectedKey={evaluation.itemType}
                                                onChange={(e, item) => { this.updateEvaluationField(item.key, indexEvaluation, "itemType") }}
                                                options={this.props.availableAreas}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    {evaluation.itemType !== "" &&
                                        <Stack style={{ marginLeft: "30px" }}>
                                            <Stack.Item>
                                                <Stack horizontal style={{ marginTop: "10px" }} >
                                                    <Stack.Item grow={3} style={{ marginRight: "10px" }}>
                                                        {evaluation.itemType === "AdherencePhrases" &&
                                                            <Query query={queryAllAdherencePhrases}>
                                                                {({ loading, error, data, refetch }) => {
                                                                    if (loading) return <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                                                    if (error) {
                                                                        console.log(error)
                                                                        return (
                                                                            <AlertBar message={this.context.getTranslation("compliance", "error")} alertType="error" />
                                                                        );
                                                                    }
                                                                    if (data.getAdherencePhrases == null) {
                                                                        return <AlertBar message={this.context.getTranslation("compliance", "noAdherencePhrases")} alertType={"warning"} />
                                                                    }
                                                                    let adherencePhrases = data.getAdherencePhrases.map(v => {
                                                                        return {
                                                                            "key": v.masterItemId,
                                                                            "text": v.name
                                                                        }
                                                                    })
                                                                    return (
                                                                        <Dropdown
                                                                            placeholder={this.context.getTranslation("compliance", "selectPhrase")}
                                                                            selectedKey={evaluation.itemId}
                                                                            onChange={(e, item) => {
                                                                                this.updateEvaluationField(item.key, indexEvaluation, "itemId")
                                                                            }}
                                                                            options={adherencePhrases}
                                                                        />
                                                                    )
                                                                }}
                                                            </Query>
                                                        }
                                                        {evaluation.itemType === "AIQuestions" &&
                                                            <Query query={queryAllAIQuestions}>
                                                                {({ loading, error, data, refetch }) => {
                                                                    if (loading) return <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                                                    if (error) {
                                                                        console.log(error)
                                                                        return (
                                                                            <AlertBar message={this.context.getTranslation("compliance", "error")} alertType="error" />
                                                                        );
                                                                    }
                                                                    if (data.getAIQuestions == null) {
                                                                        return <AlertBar message={this.context.getTranslation("common", "noData")} alertType={"warning"} />
                                                                    }
                                                                    let aIQuestions = data.getAIQuestions.map(v => {
                                                                        return {
                                                                            "key": v.masterItemId,
                                                                            "text": v.name
                                                                        }
                                                                    })
                                                                    return (
                                                                        <Dropdown
                                                                            placeholder={this.context.getTranslation("compliance", "selectAIQuestion")}
                                                                            selectedKey={evaluation.itemId}
                                                                            onChange={(e, item) => {
                                                                                this.updateEvaluationField(item.key, indexEvaluation, "itemId")
                                                                            }}
                                                                            options={aIQuestions}
                                                                        />
                                                                    )
                                                                }}
                                                            </Query>}
                                                        {evaluation.itemType === "AIAdherencePhrases" &&
                                                            <Query query={queryAllAIAdherencePhrases}>
                                                                {({ loading, error, data, refetch }) => {
                                                                    if (loading) return <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                                                    if (error) {
                                                                        console.log(error)
                                                                        return (
                                                                            <AlertBar message={this.context.getTranslation("compliance", "error")} alertType="error" />
                                                                        );
                                                                    }
                                                                    if (data.getAIAdherencePhrases == null) {
                                                                        return <AlertBar message={this.context.getTranslation("common", "noData")} alertType={"warning"} />
                                                                    }
                                                                    let adherencePhrases = data.getAIAdherencePhrases.map(v => {
                                                                        return {
                                                                            "key": v.masterItemId,
                                                                            "text": v.name
                                                                        }
                                                                    })
                                                                    return (
                                                                        <Dropdown
                                                                            placeholder={this.context.getTranslation("compliance", "selectAIAdherencePhrases")}
                                                                            selectedKey={evaluation.itemId}
                                                                            onChange={(e, item) => {
                                                                                this.updateEvaluationField(item.key, indexEvaluation, "itemId")
                                                                            }}
                                                                            options={adherencePhrases}
                                                                        />
                                                                    )
                                                                }}
                                                            </Query>
                                                        }
                                                        {evaluation.itemType === "QMQuestions" &&
                                                            <TextField
                                                                placeholder={this.context.getTranslation("compliance", "insertQMQuestions")}
                                                                value={evaluation.itemValue}
                                                                onChange={(e) => this.updateEvaluationField(e.target.value, indexEvaluation, "itemValue")}
                                                            />
                                                        }
                                                    </Stack.Item>
                                                    <Stack.Item style={{ width: "80px" }}>
                                                        <TextField
                                                            value={evaluation.points}
                                                            type={"number"}
                                                            min={0}
                                                            onChange={(event, newValue) => {
                                                                this.updateEvaluationField(parseFloat(newValue), indexEvaluation, "points")
                                                            }}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            {(evaluation.itemType === "AIQuestions" || evaluation.itemType === "QMQuestions") &&
                                                <Stack.Item style={{ marginTop: "10px" }}>
                                                    <Toggle
                                                        checked={evaluation.awareOfPoints}
                                                        onText={this.context.getTranslation("compliance", "awareOfPoints")}
                                                        offText={this.context.getTranslation("compliance", "yesNoQuestion")}
                                                        onChange={(e, checked) => {
                                                            this.updateEvaluationField(checked, indexEvaluation, "awareOfPoints")
                                                        }}
                                                    />
                                                </Stack.Item>
                                            }
                                        </Stack>}
                                </div>
                            })
                        }
                    </div>
                }
                <Separator className="separator" />
            </div>
        )
    }
}