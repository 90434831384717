import { DetailsList, MarqueeSelection, Selection, SelectionMode } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../context';

export default class AICategorizationList extends Component {

    static contextType = MyContext

    _selection: Selection;

    constructor(props) {
        super(props);
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.props.selectAICat(this._selection.getSelection().length > 0 ? this._selection.getSelection()[0].id : null)
            }
        });
    }

    getKey = (item, index) => {
        return item.key;
    }

    render() {
        const columns = [
            { key: 'name', name: this.context.getTranslation("compliance", "name"), fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
        ]

        return (
            <MarqueeSelection selection={this._selection}>
                <DetailsList
                    items={this.props.aiCategorization.sort((a,b)=> a.name.toUpperCase().localeCompare(b.name.toUpperCase()))}
                    //groups={this.props.}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    selection={this._selection}
                    getKey={this.getKey}
                    setKey="single"
                />
            </MarqueeSelection>
        );
    }
}