import React,{Component} from 'react'
import "../DetailView/DetailView.scss"
import { ApolloProvider } from "react-apollo";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import GridDay from "../GridView/GridDay/GridDay";
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { FontSizes } from 'office-ui-fabric-react/lib';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import { Text } from 'office-ui-fabric-react/lib/Text';

    const renderDataDay = (sel, auth, apol, rules) => (

    <Query
        query={gql `

        query ent ($auth : AuthInput, $sel : String!) {
          getDetailDay (input:$auth, select: $sel)
          {
           date
            content
                {
                    timein
                    timeout
                    topic
                    conversationId
                    id
                    recordingType
                    duration
                    entryContent
                    thumbnail
                    speech2Text
                    notes
                    isVideo
                    hasAudio
                    hasVideo
                    hasScreenSharing
                    mediaAssets
                    replayAllowed
                    archiveStatus
                    hasRestoreStarted
                    callStartMs
                    linkedUser
                    {
                        id
                        userId
                        tenantId
                        name
                    }
                    ownerDisplayName
                }
            }
          }

    `} variables={{auth, sel}} >
        {({ loading, error, data }) => {
            if (loading) return <div> </div>;
            if (error) return <h1> </h1>;

            return data.getDetailDay.map(({ date , content}) => (

                    <GridDay
                    key={date}
                    dayTitle={date}
                    date={date}
                    apol={apol}
                    dayContent={content}
                    authorization={auth}
                    userRules={rules}
                    />
                )

            );

        }}
    </Query>
);




function project()
{
    let xx=window.location.href;
    let yy=xx.lastIndexOf('day');
    return xx.substring(yy+4,xx.length);
}


export default class DayView extends Component
{

    onCkHome = () => {
        window.location.href = "/";
    }

    render(){
        return <div className="Out" >
            <ApolloProvider client={this.props.apol}>                
                    <div >
                        {renderDataDay(project(),this.props.authorization, this.props.apol, this.props.rules)}
                    </div>

            </ApolloProvider>
        </div>;
    }
}
