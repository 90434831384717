export const countriesGDP = {
    'Afghanistan': {
        'agriculture': 34.9, 'industry': 25, 'services': 40, 'total': 20312
    },
    'Albania': {
        'agriculture': 20.7, 'industry': 19.7, 'services': 59.6, 'total': 13262
    },
    'Algeria': {
        'agriculture': 12, 'industry': 56.5, 'services': 31.5, 'total': 214080
    },
    'Angola': {
        'agriculture': 9.6, 'industry': 65.8, 'services': 24.6, 'total': 128564
    },
    'Anguilla': {
        'agriculture': 2.2, 'industry': 28.5, 'services': 69.3
    },
    'Antigua and Barbuda': {
        'agriculture': 3.8, 'industry': 32.9, 'services': 63.4, 'total': 1242
    },
    'Argentina': {
        'agriculture': 10, 'industry': 30.7, 'services': 59.2, 'total': 540164
    },
    'Armenia': {
        'agriculture': 19.1, 'industry': 40.5, 'services': 40.3, 'total': 10279
    },
    'Aruba': {
        'agriculture': 0.4, 'industry': 33.3, 'services': 66.3
    },
    'Australia': {
        'agriculture': 4, 'industry': 24.6, 'services': 71.4, 'total': 1444189
    },
    'Austria': {
        'agriculture': 1.5, 'industry': 29.4, 'services': 69.1, 'total': 437123
    },
    'Azerbaijan': {
        'agriculture': 5.5, 'industry': 62.1, 'services': 32.4, 'total': 74145
    },
    'Bahamas, The': {
        'agriculture': 2.2, 'industry': 6.5, 'services': 91.3, 'total': 8657
    },
    'Bahrain': {
        'agriculture': 0.4, 'industry': 63.6, 'services': 36, 'total': 33862
    },
    'Bangladesh': {
        'agriculture': 18.4, 'industry': 28.6, 'services': 53, 'total': 185415
    },
    'Barbados': {
        'agriculture': 3.2, 'industry': 13.9, 'services': 82.9, 'total': 4348
    },
    'Belarus': {
        'agriculture': 9.4, 'industry': 45.9, 'services': 44.7, 'total': 76139
    },
    'Belgium': {
        'agriculture': 0.7, 'industry': 21.7, 'services': 77.6, 'total': 534672
    },
    'Belize': {
        'agriculture': 9.7, 'industry': 19.8, 'services': 70.5, 'total': 1693
    },
    'Benin': {
        'agriculture': 36, 'industry': 6.4, 'services': 57.6, 'total': 8701
    },
    'Bermuda': {
        'agriculture': 0.7, 'industry': 7, 'services': 92.2
    },
    'Bhutan': {
        'agriculture': 16.7, 'industry': 45.4, 'services': 37.9, 'total': 2092
    },
    'Bolivia': {
        'agriculture': 10, 'industry': 40, 'services': 50, 'total': 34425
    },
    'Bosnia and Herz.': {
        'agriculture': 8.3, 'industry': 26.3, 'services': 65.4, 'total': 17977
    },
    'Botswana': {
        'agriculture': 2.1, 'industry': 45, 'services': 52.9, 'total': 15789
    },
    'Brazil': {
        'agriculture': 5.5, 'industry': 27.5, 'services': 67, 'total': 2353025
    },
    'British Virgin Islands': {
        'agriculture': 1, 'industry': 11.9, 'services': 87.2
    },
    'Brunei': {
        'agriculture': 0.8, 'industry': 66.7, 'services': 32.5, 'total': 15102
    },
    'Bulgaria': {
        'agriculture': 5.2, 'industry': 30.6, 'services': 64.2, 'total': 55837
    },
    'Burkina Faso': {
        'agriculture': 34.7, 'industry': 23.3, 'services': 42, 'total': 12503
    },
    'Burundi': {
        'agriculture': 31, 'industry': 21.4, 'services': 47.7, 'total': 3094
    },
    'Cambodia': {
        'agriculture': 30, 'industry': 30, 'services': 40, 'total': 16551
    },
    'Cameroon': {
        'agriculture': 19.5, 'industry': 31, 'services': 49.5, 'total': 31669
    },
    'Canada': {
        'agriculture': 1.9, 'industry': 27.1, 'services': 71, 'total': 1788717
    },
    'Cape Verde': {
        'agriculture': 8.4, 'industry': 15.7, 'services': 75.9, 'total': 1899
    },
    'Cayman Islands': {
        'agriculture': 0.5, 'industry': 50.4, 'services': 49.1
    },
    'Central African Rep.': {
        'agriculture': 53.1, 'industry': 14.5, 'services': 32.4, 'total': 1786
    },
    'Chad': {
        'agriculture': 52.7, 'industry': 6.7, 'services': 40.6, 'total': 13947
    },
    'Chile': {
        'agriculture': 5.1, 'industry': 41.8, 'services': 53.1, 'total': 257968
    },
    'China': {
        'agriculture': 10.1, 'industry': 46.8, 'services': 43.1, 'total': 10380380
    },
    'Colombia': {
        'agriculture': 7, 'industry': 37.6, 'services': 55.5, 'total': 384901
    },
    'Comoros': {
        'agriculture': 51.6, 'industry': 13.6, 'services': 47, 'total': 717
    },
    'Dem. Rep. Congo': {
        'agriculture': 37.5, 'industry': 27.6, 'services': 35, 'total': 34677
    },
    'Congo': {
        'agriculture': 4.2, 'industry': 70.7, 'services': 25.1, 'total': 13502
    },
    'Cook Islands': {
        'agriculture': 11.7, 'industry': 9.9, 'services': 78.5
    },
    'Costa Rica': {
        'agriculture': 6.3, 'industry': 21.7, 'services': 72, 'total': 48144
    },
    'Ivory Coast': {
        'agriculture': 30, 'industry': 21, 'services': 49, 'total': 32000
    },
    'Croatia': {
        'agriculture': 5.4, 'industry': 25.5, 'services': 69.1, 'total': 57159
    },
    'Cuba': {
        'agriculture': 4, 'industry': 20.8, 'services': 75.2
    },
    'Curacao': {
        'agriculture': 0.7, 'industry': 15.5, 'services': 83.8
    },
    'Cyprus': {
        'agriculture': 2.4, 'industry': 16.5, 'services': 81.1, 'total': 23269
    },
    'Czech Rep.': {
        'agriculture': 1.6, 'industry': 38.1, 'services': 60.3, 'total': 205658
    },
    'Denmark': {
        'agriculture': 4.5, 'industry': 19.1, 'services': 76.4, 'total': 340806
    },
    'Djibouti': {
        'agriculture': 3, 'industry': 17, 'services': 79.9, 'total': 1589
    },
    'Dominica': {
        'agriculture': 21.4, 'industry': 22.4, 'services': 56.3, 'total': 526
    },
    'Dominican Rep.': {
        'agriculture': 6.7, 'industry': 26.1, 'services': 67.2, 'total': 64077
    },
    'Ecuador': {
        'agriculture': 6.5, 'industry': 34.6, 'services': 58.9, 'total': 100755
    },
    'Egypt': {
        'agriculture': 14.5, 'industry': 37.6, 'services': 47.6, 'total': 286435
    },
    'El Salvador': {
        'agriculture': 10.6, 'industry': 30, 'services': 59.3, 'total': 25329
    },
    'Equatorial Guinea': {
        'agriculture': 3.6, 'industry': 89.7, 'services': 4.3, 'total': 14308
    },
    'Eritrea': {
        'agriculture': 11, 'industry': 34, 'services': 55, 'total': 3858
    },
    'Estonia': {
        'agriculture': 3.5, 'industry': 28.5, 'services': 61, 'total': 25953
    },
    'Ethiopia': {
        'agriculture': 41, 'industry': 13, 'services': 46, 'total': 52335
    },
    'European Union': {
        'agriculture': 1.8, 'industry': 24.9, 'services': 73.2
    },
    'Falkland Islands': {
        'agriculture': 95, 'industry': 0, 'services': 0
    },
    'Faroe Islands': {
        'agriculture': 16, 'industry': 29, 'services': 55
    },
    'Fiji': {
        'agriculture': 12, 'industry': 20.2, 'services': 67.7, 'total': 4212
    },
    'Finland': {
        'agriculture': 3, 'industry': 29.2, 'services': 67.8, 'total': 271165
    },
    'France': {
        'agriculture': 1.8, 'industry': 18.8, 'services': 79.4, 'total': 2846889
    },
    'French Polynesia': {
        'agriculture': 3.5, 'industry': 20.4, 'services': 76.1
    },
    'Gabon': {
        'agriculture': 5.2, 'industry': 54.4, 'services': 40.4, 'total': 17182
    },
    'Gambia, The': {
        'agriculture': 26.7, 'industry': 17.4, 'services': 55.9, 'total': 825
    },
    'Georgia': {
        'agriculture': 8.8, 'industry': 22.7, 'services': 68.5, 'total': 16535
    },
    'Germany': {
        'agriculture': 0.8, 'industry': 28.6, 'services': 70.6, 'total': 3859547
    },
    'Ghana': {
        'agriculture': 28.3, 'industry': 21, 'services': 50.7, 'total': 38648
    },
    'Gibraltar': {
        'agriculture': 0, 'industry': 0, 'services': 100
    },
    'Greece': {
        'agriculture': 3.3, 'industry': 17.9, 'services': 78.9, 'total': 238023
    },
    'Greenland': {
        'agriculture': 4, 'industry': 29, 'services': 67, 'total': 2160
    },
    'Grenada': {
        'agriculture': 5.3, 'industry': 14.2, 'services': 80.5, 'total': 862
    },
    'Guatemala': {
        'agriculture': 13.3, 'industry': 24, 'services': 62.7, 'total': 60422
    },
    'Guernsey': {
        'agriculture': 3, 'industry': 10, 'services': 87
    },
    'Guinea': {
        'agriculture': 16.9, 'industry': 53.6, 'services': 29.5, 'total': 6529
    },
    'Guinea-Bissau': {
        'agriculture': 55.8, 'industry': 13, 'services': 31.2, 'total': 1024
    },
    'Guyana': {
        'agriculture': 18.5, 'industry': 30.6, 'services': 50.9, 'total': 2997
    },
    'Haiti': {
        'agriculture': 25, 'industry': 16, 'services': 59, 'total': 8711
    },
    'Honduras': {
        'agriculture': 12.6, 'industry': 26.3, 'services': 61.1, 'total': 19511
    },
    'Hong Kong': {
        'agriculture': 0.1, 'industry': 6.8, 'services': 93.2, 'total': 289628
    },
    'Hungary': {
        'agriculture': 3.7, 'industry': 31.3, 'services': 65, 'total': 137104
    },
    'Iceland': {
        'agriculture': 5.4, 'industry': 24.7, 'services': 69.9, 'total': 16693
    },
    'India': {
        'agriculture': 17.2, 'industry': 26.4, 'services': 56.4, 'total': 2049501
    },
    'Indonesia': {
        'agriculture': 14.7, 'industry': 47.2, 'services': 38.1, 'total': 888648
    },
    'Iran': {
        'agriculture': 10.4, 'industry': 37.7, 'services': 51.8, 'total': 404132
    },
    'Iraq': {
        'agriculture': 9.7, 'industry': 60.5, 'services': 29.8, 'total': 221130
    },
    'Ireland': {
        'agriculture': 2, 'industry': 29, 'services': 69, 'total': 246438
    },
    'Isle of Man': {
        'agriculture': 1, 'industry': 11, 'services': 88
    },
    'Israel': {
        'agriculture': 2.5, 'industry': 31.1, 'services': 64.9, 'total': 303771
    },
    'Italy': {
        'agriculture': 2, 'industry': 24.7, 'services': 73.4, 'total': 2147952
    },
    'Jamaica': {
        'agriculture': 5.8, 'industry': 29.5, 'services': 64.7, 'total': 13787
    },
    'Japan': {
        'agriculture': 1.2, 'industry': 27.3, 'services': 71.6, 'total': 4616335
    },
    'Jersey': {
        'agriculture': 1, 'industry': 2, 'services': 97
    },
    'Jordan': {
        'agriculture': 4.5, 'industry': 30.8, 'services': 64.7, 'total': 35765
    },
    'Kazakhstan': {
        'agriculture': 5.2, 'industry': 37.9, 'services': 56.9, 'total': 212260
    },
    'Kenya': {
        'agriculture': 19, 'industry': 16.4, 'services': 64.6, 'total': 60770
    },
    'Kiribati': {
        'agriculture': 8.9, 'industry': 24.2, 'services': 66.8, 'total': 181
    },
    'Dem. Rep. Korea': {
        'agriculture': 23, 'industry': 43.4, 'services': 33.6, 'total': 28000
    },
    'Korea': {
        'agriculture': 2.6, 'industry': 39.2, 'services': 58.2, 'total': 1416949
    },
    'Kosovo': {
        'agriculture': 12.9, 'industry': 22.6, 'services': 64.5, 'total': 7318
    },
    'Kuwait': {
        'agriculture': 0.3, 'industry': 47.4, 'services': 52.3, 'total': 172350
    },
    'Kyrgyzstan': {
        'agriculture': 20.1, 'industry': 28.8, 'services': 51.1, 'total': 7402
    },
    'Lao PDR': {
        'agriculture': 27.8, 'industry': 34.8, 'services': 37.4, 'total': 11676
    },
    'Latvia': {
        'agriculture': 4, 'industry': 21.7, 'services': 74.3, 'total': 31970
    },
    'Lebanon': {
        'agriculture': 4.6, 'industry': 19.7, 'services': 75.7, 'total': 49919
    },
    'Lesotho': {
        'agriculture': 7.5, 'industry': 33.1, 'services': 59.4, 'total': 2159
    },
    'Liberia': {
        'agriculture': 76.9, 'industry': 5.4, 'services': 17.7, 'total': 2028
    },
    'Libya': {
        'agriculture': 3.2, 'industry': 49.5, 'services': 47.3, 'total': 41148
    },
    'Liechtenstein': {
        'agriculture': 8, 'industry': 37, 'services': 55
    },
    'Lithuania': {
        'agriculture': 3.3, 'industry': 28.2, 'services': 68.6, 'total': 48232
    },
    'Luxembourg': {
        'agriculture': 0.4, 'industry': 13.6, 'services': 86, 'total': 62395
    },
    'Macau': {
        'agriculture': 0.1, 'industry': 9.4, 'services': 90.6
    },
    'Macedonia': {
        'agriculture': 9.5, 'industry': 27.1, 'services': 63.4, 'total': 11342
    },
    'Madagascar': {
        'agriculture': 28.3, 'industry': 16.4, 'services': 55.2, 'total': 10595
    },
    'Malawi': {
        'agriculture': 30.3, 'industry': 16.7, 'services': 53, 'total': 4263
    },
    'Malaysia': {
        'agriculture': 12, 'industry': 40, 'services': 48, 'total': 326933
    },
    'Maldives': {
        'agriculture': 5.6, 'industry': 16.9, 'services': 77.5, 'total': 2854
    },
    'Mali': {
        'agriculture': 38.8, 'industry': 21.9, 'services': 39.3, 'total': 11915
    },
    'Malta': {
        'agriculture': 1.9, 'industry': 17.2, 'services': 80.9, 'total': 10582
    },
    'Marshall Islands': {
        'agriculture': 31.7, 'industry': 14.9, 'services': 53.4, 'total': 193
    },
    'Mauritania': {
        'agriculture': 17.8, 'industry': 37.6, 'services': 44.7, 'total': 5079
    },
    'Mauritius': {
        'agriculture': 4.5, 'industry': 24, 'services': 71.4, 'total': 13240
    },
    'Mexico': {
        'agriculture': 3.8, 'industry': 34.2, 'services': 62, 'total': 1282725
    },
    'Micronesia, Federated States of': {
        'agriculture': 28.9, 'industry': 15.2, 'services': 55.9
    },
    'Moldova': {
        'agriculture': 16.2, 'industry': 20.3, 'services': 63.5, 'total': 7944
    },
    'Monaco': {
        'agriculture': 0, 'industry': 4.9, 'services': 95.1
    },
    'Mongolia': {
        'agriculture': 15.8, 'industry': 32.6, 'services': 51.6, 'total': 11981
    },
    'Montenegro': {
        'agriculture': 0.8, 'industry': 11.3, 'services': 87.9, 'total': 4462
    },
    'Montserrat': {
        'agriculture': 1.6, 'industry': 23, 'services': 75.3
    },
    'Morocco': {
        'agriculture': 16.6, 'industry': 32.2, 'services': 51.2, 'total': 109201
    },
    'Mozambique': {
        'agriculture': 31.5, 'industry': 23.8, 'services': 44.7, 'total': 16684
    },
    'Myanmar': {
        'agriculture': 38.2, 'industry': 18.2, 'services': 43.6, 'total': 62802
    },
    'Namibia': {
        'agriculture': 7.2, 'industry': 33.3, 'services': 59.5, 'total': 13353
    },
    'Nepal': {
        'agriculture': 34.9, 'industry': 15, 'services': 50.1, 'total': 19637
    },
    'Netherlands': {
        'agriculture': 2.7, 'industry': 24.2, 'services': 73.1, 'total': 866354
    },
    'New Caledonia': {
        'agriculture': 2, 'industry': 27.1, 'services': 70.9
    },
    'New Zealand': {
        'agriculture': 4.8, 'industry': 24.5, 'services': 70.7, 'total': 198118
    },
    'Nicaragua': {
        'agriculture': 17.4, 'industry': 25.7, 'services': 56.8, 'total': 11707
    },
    'Niger': {
        'agriculture': 39, 'industry': 16, 'services': 45, 'total': 8025
    },
    'Nigeria': {
        'agriculture': 35.4, 'industry': 33.6, 'services': 31, 'total': 573652
    },
    'Niue': {
        'agriculture': 23.5, 'industry': 26.9, 'services': 49.5
    },
    'Norway': {
        'agriculture': 2.6, 'industry': 39.7, 'services': 57.7, 'total': 500244
    },
    'Oman': {
        'agriculture': 1.5, 'industry': 49.5, 'services': 48.9, 'total': 77755
    },
    'Pakistan': {
        'agriculture': 20.9, 'industry': 25.8, 'services': 53.3, 'total': 250136
    },
    'Palau': {
        'agriculture': 6.2, 'industry': 12, 'services': 81.8, 'total': 269
    },
    'Panama': {
        'agriculture': 4.1, 'industry': 16.7, 'services': 79.2, 'total': 43784
    },
    'Papua New Guinea': {
        'agriculture': 29.9, 'industry': 37.6, 'services': 32.4, 'total': 16060
    },
    'Paraguay': {
        'agriculture': 23.1, 'industry': 18.6, 'services': 58.3, 'total': 29704
    },
    'Peru': {
        'agriculture': 8, 'industry': 38, 'services': 54, 'total': 202948
    },
    'Philippines': {
        'agriculture': 12.8, 'industry': 31.5, 'services': 55.7, 'total': 284927
    },
    'Poland': {
        'agriculture': 3.4, 'industry': 33.6, 'services': 63, 'total': 546644
    },
    'Portugal': {
        'agriculture': 2.5, 'industry': 22.8, 'services': 74.7, 'total': 230012
    },
    'Puerto Rico': {
        'agriculture': 1, 'industry': 45, 'services': 54, 'total': 103500
    },
    'Qatar': {
        'agriculture': 0.1, 'industry': 73, 'services': 26.9, 'total': 210002
    },
    'Romania': {
        'agriculture': 7.9, 'industry': 32.9, 'services': 59.2, 'total': 199950
    },
    'Russia': {
        'agriculture': 4.5, 'industry': 36.9, 'services': 58.6, 'total': 1857461
    },
    'Rwanda': {
        'agriculture': 33, 'industry': 13.9, 'services': 53.1, 'total': 8012
    },
    'Saint Kitts and Nevis': {
        'agriculture': 2.4, 'industry': 22.8, 'services': 74.7, 'total': 841
    },
    'Saint Lucia': {
        'agriculture': 4.1, 'industry': 18.4, 'services': 77.5, 'total': 1356
    },
    'Saint Martin': {
        'agriculture': 1, 'industry': 15, 'services': 84
    },
    'Saint Vincent and the Grenadines': {
        'agriculture': 7.8, 'industry': 25.9, 'services': 66.3, 'total': 736
    },
    'Samoa': {
        'agriculture': 9.9, 'industry': 28.8, 'services': 61.4, 'total': 826
    },
    'San Marino': {
        'agriculture': 0.1, 'industry': 39.2, 'services': 60.7, 'total': 1786
    },
    'Sao Tome and Principe': {
        'agriculture': 15, 'industry': 24.4, 'services': 60.6
    },
    'Saudi Arabia': {
        'agriculture': 2, 'industry': 69.1, 'services': 28.9, 'total': 752459
    },
    'Senegal': {
        'agriculture': 15, 'industry': 22.8, 'services': 62.2, 'total': 15584
    },
    'Serbia': {
        'agriculture': 11.2, 'industry': 18.7, 'services': 70, 'total': 43866
    },
    'Seychelles': {
        'agriculture': 2, 'industry': 18.5, 'services': 79.5, 'total': 1420
    },
    'Sierra Leone': {
        'agriculture': 51.5, 'industry': 22, 'services': 26.5, 'total': 5033
    },
    'Singapore': {
        'agriculture': 0, 'industry': 26.6, 'services': 73.4, 'total': 308051
    },
    'Sint Maarten': {
        'agriculture': 0.4, 'industry': 18.3, 'services': 81.3
    },
    'Slovakia': {
        'agriculture': 3.8, 'industry': 35.5, 'services': 60.7, 'total': 99971
    },
    'Slovenia': {
        'agriculture': 2.5, 'industry': 6.9, 'services': 90.5, 'total': 49506
    },
    'Solomon Islands': {
        'agriculture': 37.7, 'industry': 6.4, 'services': 55.9, 'total': 1155
    },
    'Somalia': {
        'agriculture': 60.2, 'industry': 7.4, 'services': 32.5, 'total': 1071
    },
    'South Africa': {
        'agriculture': 2.5, 'industry': 31.6, 'services': 65.9, 'total': 350082
    },
    'Spain': {
        'agriculture': 3.2, 'industry': 25.8, 'services': 71, 'total': 1406855
    },
    'Sri Lanka': {
        'agriculture': 13, 'industry': 29.6, 'services': 57.4, 'total': 74588
    },
    'Sudan': {
        'agriculture': 25, 'industry': 29.3, 'services': 45.7, 'total': 73816
    },
    'S. Sudan': {
        'total': 12833
    },
    'Suriname': {
        'agriculture': 10.8, 'industry': 24.4, 'services': 64.8, 'total': 5297
    },
    'Swaziland': {
        'agriculture': 8.2, 'industry': 46.9, 'services': 44.9, 'total': 3676
    },
    'Sweden': {
        'agriculture': 1.8, 'industry': 27.3, 'services': 70.9, 'total': 570137
    },
    'Switzerland': {
        'agriculture': 1.3, 'industry': 27.5, 'services': 71.3, 'total': 712050
    },
    'Syria': {
        'agriculture': 16.9, 'industry': 27.4, 'services': 55.7, 'total': 71998
    },
    'Taiwan': {
        'agriculture': 1.3, 'industry': 32, 'services': 66.9, 'total': 529550
    },
    'Tajikistan': {
        'agriculture': 21.4, 'industry': 21.7, 'services': 56.8, 'total': 9242
    },
    'Tanzania': {
        'agriculture': 27.8, 'industry': 24.2, 'services': 48, 'total': 47932
    },
    'Thailand': {
        'agriculture': 13.3, 'industry': 34, 'services': 52.7, 'total': 373804
    },
    'Timor-Leste': {
        'agriculture': 26.5, 'industry': 17.8, 'services': 55.6, 'total': 4478
    },
    'Togo': {
        'agriculture': 46, 'industry': 23, 'services': 31, 'total': 4604
    },
    'Tonga': {
        'agriculture': 20.8, 'industry': 18.1, 'services': 61.1, 'total': 454
    },
    'Trinidad and Tobago': {
        'agriculture': 0.3, 'industry': 58.4, 'services': 41.3, 'total': 28788
    },
    'Tunisia': {
        'agriculture': 10.6, 'industry': 34.6, 'services': 54.8, 'total': 48553
    },
    'Turkey': {
        'agriculture': 9.3, 'industry': 28.1, 'services': 62.6, 'total': 806108
    },
    'Turkmenistan': {
        'agriculture': 7.9, 'industry': 24.5, 'services': 67.6, 'total': 47932
    },
    'Turks and Caicos Islands': {
        'agriculture': 1, 'industry': 24.2, 'services': 74.8
    },
    'Tuvalu': {
        'agriculture': 16.6, 'industry': 27.2, 'services': 56.2, 'total': 38
    },
    'Uganda': {
        'agriculture': 22, 'industry': 25.4, 'services': 52.6, 'total': 27616
    },
    'Ukraine': {
        'agriculture': 9.4, 'industry': 34.4, 'services': 56.2, 'total': 130660
    },
    'United Arab Emirates': {
        'agriculture': 0.8, 'industry': 53.9, 'services': 45.3, 'total': 401647
    },
    'United Kingdom': {
        'agriculture': 0.7, 'industry': 21.4, 'services': 77.8, 'total': 2945146
    },
    'United States': {
        'agriculture': 1.2, 'industry': 19.2, 'services': 79.6, 'total': 17418925
    },
    'Uruguay': {
        'agriculture': 9.4, 'industry': 21.7, 'services': 68.9, 'total': 55143
    },
    'Uzbekistan': {
        'agriculture': 21.9, 'industry': 37.7, 'services': 40.3, 'total': 62619
    },
    'Vanuatu': {
        'agriculture': 21.2, 'industry': 10.4, 'services': 68.4, 'total': 812
    },
    'Venezuela': {
        'agriculture': 3.8, 'industry': 35.8, 'services': 60.4, 'total': 205787
    },
    'Vietnam': {
        'agriculture': 22, 'industry': 40.3, 'services': 37.7, 'total': 186049
    },
    'U.S. Virgin Islands': {
        'agriculture': 1, 'industry': 19, 'services': 80
    },
    'West Bank and Gaza': {
        'agriculture': 3.7, 'industry': 13.6, 'services': 82.7
    },
    'Western Sahara': {
        'agriculture': 0, 'industry': 0, 'services': 40
    },
    'Yemen': {
        'agriculture': 7.9, 'industry': 42.2, 'services': 49.9, 'total': 43229
    },
    'Zambia': {
        'agriculture': 21.4, 'industry': 35.1, 'services': 43.5, 'total': 26758
    },
    'Zimbabwe': {
        'agriculture': 20.4, 'industry': 24.6, 'services': 54.9, 'total': 13672
    }
};
