import React, { Component } from 'react'
import * as microsoftTeams from "@microsoft/teams-js";

import './Login.scss'

export default class Config extends Component {

    componentDidMount() {
        microsoftTeams.initialize();
        microsoftTeams.settings.setValidityState(true);
        microsoftTeams.settings.registerOnSaveHandler(function (saveEvent) {

            microsoftTeams.settings.setSettings({
                entityId: "1",
                contentUrl: "https://dev-teams.asc-recording.app/",
                suggestedDisplayName: "Recordings"
            });
            saveEvent.notifySuccess();
        });
    }

    render() {
        return (
            <div className="login-body">
            </div>
        );
    }
}
