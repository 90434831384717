import React from 'react'
import './Transscript.scss'
import TranscriptLine from './TranscriptLine/TranscriptLine'
import LanguageSelect from '../../../../LanguageSelect/LanguageSelect'
import { SearchBox, TextField } from 'office-ui-fabric-react/lib';
import DownloadDialog from './DownloadDialog'
import { CommandBarButton, Dropdown, DefaultButton, Label } from 'office-ui-fabric-react/lib/';
import { Icon, FontSizes, Text, ScrollablePane, mergeStyleSets, TooltipHost, TooltipDelay, Spinner } from 'office-ui-fabric-react/lib';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import gql from "graphql-tag";
import zipcelx from 'zipcelx';
import Utils from "./../../../../common/Utils";
import { mutationWriteAuditEvent, getCognitiveServicesLocalesTranslation, getCognitiveServicesLocalesTranscription } from "../../../../../queries/querySettings";
import TranscriptSelect from './../../../../LanguageSelect/TranscriptSelect'
import { MyContext } from '../../../../../context';
import { Query } from "@apollo/react-components";
import AlertBar from '../../../../CustomComponents/AlertBar';


const config = {
    filename: 'conversationExport',
    sheet: {
        data: [
            [{
                value: 'id',
                type: 'string'
            },
            {
                value: "time",
                type: 'string'
            },
            {
                value: "speaker",
                type: 'string'
            },
            {
                value: "text",
                type: 'string'
            }]
        ]
    }
};

export default class Transscript extends React.Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.findKeywordsInTranscript = this.findKeywordsInTranscript.bind(this);
        this.sortArr = this.sortArr.bind(this);
        this.convertArr = this.convertArr.bind(this);
        console.log("Height: " + this.props.height)
    }

    state = {
        id: "0",
        val: "",
        transcript: null,
        translationActive: false,
        translated: false,
        language: null,
        languageCode: "",
        audioActive: this.props.onlyTag === true ? false : true,
        chatActive: this.props.onlyTag === true ? false : true,
        tagActive: true,
        attachActive: true,
        one2oneView: (this.props.user != null && this.props.user.filter(user => user.type !== "Bot").length == 2) ? true : false,
        exportDialogOpen: false,
        openaiType: "openai",
        openaiText: "",
        openaiResponse: "",
        loadingOpenAI: false,
        openaiActive: false
    };

    lastSpeaker = "";
    handler(input) {
        console.log("Handler Triger");
        this.setState({ id: "1", val: input })
    }

    onTranslate = async (language, languageText) => {
        this.setState({ translationActive: true });
        this.setState({ language: languageText });
        this.setState({ languageCode: language });

        var data = await this.props.client
            .query({
                query: gql`
                query ent ($input: AuthInput, $id : ID!, $language: String) {
                    getTranslation (input: $input, id: $id, language: $language) 
                    {
                        id         
                        unifiedTranscript
          	            {
                            language
                            transcript
              	            {
                                text
                                type
                                speakerId
                                speakerUserId 
                                url
                                instances
                  	            {
                                    start
                                    end
                                    adjustedStart
                                    adjustedEnd
                                }
                            }
                            speakers {
                               name
                            }
}
                    }
                }
                `,
                fetchPolicy: "network-only",
                variables: {
                    id: this.props.id, language: language, input: this.props.authorization
                }
            });
        this.setState({ translationActive: false });
        this.setState({ translated: true });
        this.setState({ transcript: data.data.getTranslation.unifiedTranscript.transcript });
    }

    onOpenAIQuestionChange = (event, item) => {
        this.setState({ openaiText: this.context.getTranslation("openAI", item.key), openaiType: item.key })
    }

    onOpenai = async () => {
        this.setState({ loadingOpenAI: true })
        var data = await this.props.client
            .query({
                query: gql`
                query ent ($input: AuthInput, $id : ID!, $type: String, $question: String) {
                    getOpenAIQuery (input: $input, id: $id, type: $type, question: $question) 
                    
                }
                `,
                fetchPolicy: "network-only",
                variables: {
                    id: this.props.id, type: this.state.openaiType, question: this.state.openaiText, input: this.props.authorization
                }
            });

        var res = data.data.getOpenAIQuery;
        //check possible error
        if ("aiException" === res) {
            res = this.context.getTranslation("openAI", "aiException")
        } else if ("tokenExceeded" === res) {
            res = this.context.getTranslation("openAI", "tokenExceeded")
        }
        this.setState({ openaiResponse: res, loadingOpenAI: false });
    }

    findKeywords = () => {//TestFunction
        if (this.props.data != null && this.props.data.transcript !== undefined) {
            let out = [];
            for (let xx = 0; xx < this.props.data.transcript.length; xx++) {

                out.push(this.findKeywordsInTranscript(this.props.data.transcript[xx].text, this.props.data.transcript[xx].instances, this.sortArr(this.convertArr(this.props.sum.keywords))));
            }
        }
    };

    convertArr = (arr) =>//SummarizedInsight
    {
        let outPut = [];
        let tmpKey;

        for (let i = 0; i < arr.length; i++) {
            tmpKey = arr[i];
            let keywordCategory = arr[i].categorie;
            for (let j = 0; j < tmpKey.appearances.length; j++) {
                outPut.push({
                    name: tmpKey.name,
                    time: tmpKey.appearances[j].startSeconds,
                    betterTime: tmpKey.appearances[j].startTime,
                    trans: tmpKey.isTranscript,
                    categorie: keywordCategory,
                    hasSearchExactKeywords: tmpKey.hasSearchExactKeywords,
                    fromPolicy: tmpKey.fromPolicy,
                    policy: tmpKey.policy
                });
            }
        }

        return outPut;
    };

    SimpleTimeParser = (input) => {
        let out = 0;
        let tmp = "00", tmp2 = "00";

        tmp = input.split(":");

        if (tmp[2].indexOf(".") !== -1) {
            tmp2 = tmp[2].split(".");
        }


        out = tmp[0] * 60 * 60 + tmp[1] * 60 + tmp2[0] * 1;

        if (tmp2.length > 1) {
            out += (tmp2[1] / 10000000) - 0.01;
        }

        return out;
    };
    sortArr = (arr) => {
        return arr.sort((a, b) => { return a.time - b.time });
    };

    getKeysForTranscript = (timeIn, timeOut, arr) => {

        return arr.filter((el) => {
            return Math.floor(el.time) >= Math.floor(timeIn) && (Math.floor(el.time) <= Math.floor(timeOut) || Math.floor(timeOut) < 0) && el.trans === true
        });
    };

    findKeywordsInTranscript = (text, instances, arr) => {
        //text is the block, instances time of the block, arr is array of keywords
        let out = [], tmp;
        // console.log(this.SimpleTimeParser(instances[0].adjustedStart)+" "+this.SimpleTimeParser(instances[0].adjustedEnd));
        //seacrh for keywords in the time of the block
        tmp = this.getKeysForTranscript(this.SimpleTimeParser(instances[0].adjustedStart), this.SimpleTimeParser(instances[0].adjustedEnd), arr);
        for (let i = 0; i < tmp.length; i++) {
            var keyword = "";
            var soundlike = "^";
            var keywordAndSoundlike = tmp[i].name?.split('_') ?? [''];
            keyword = keywordAndSoundlike[0];
            if (keywordAndSoundlike[1]) {
                soundlike = keywordAndSoundlike[1];
            }
            if ((text.toLowerCase().includes(keyword.toLowerCase())) || (text.toLowerCase().includes(soundlike.toLowerCase()))) {
                out.push(tmp[i]);
            }
            else {//console.log("#"+tmp[i].name)
            }

        }
        // console.log(tracscript.text);
        return out;


    }

    renderLines = (props) => {
        this.lastSpeaker = "";
        if ((this.state.transcript != null && this.state.transcript !== undefined)) {
            let languageDirection = "ltl";
            if (this.state.languageCode === "he" || this.state.languageCode === "ar") {
                languageDirection = "rtl";
            }
            return this.state.transcript.map(({ text, speakerId, speakerUserId, instances, type, url, changedNote, changedTime, priority, reactions }, i) =>
            (
                this.getTranscriptLine(i, text, speakerId, speakerUserId, this.lastSpeaker, instances, this.state.languageCode, languageDirection, type, url, changedNote, changedTime, priority, reactions)
            ));
        }
        else if ((this.props.data != null && this.props.data.transcript != null && this.props.data.transcript !== undefined)) {
            let languageDirection = "ltl";
            if (((this.props.data.language != null) && this.props.data.language.split("-")[0] === "he") || ((this.props.data != null && this.props.data.language != null && this.props.data.language.split("-")[0]) === "ar")) {
                languageDirection = "rtl";
            }
            return this.props.data.transcript.map(({ text, speakerId, speakerUserId, instances, type, url, changedNote, changedTime, priority, reactions }, i) =>
            (
                this.getTranscriptLine(i, text, speakerId, speakerUserId, this.lastSpeaker, instances, this.props.data.language, languageDirection, type, url, changedNote, changedTime, priority, reactions)
            ));
        }
        else if (this.props.data != null) {
            return <MyContext.Consumer>
                {(context) => (
                    <h3>{context.getTranslation("detailContainer", "transcriptNotPossible")}</h3>
                )
                }
            </MyContext.Consumer>
        }
        else {
            return <MyContext.Consumer>
                {(context) => (
                    <h3>{context.getTranslation("detailContainer", "noTranscriptData")}</h3>
                )
                }
            </MyContext.Consumer>
        }
    }

    getSpeakerNumberBasedOnUserId = (speakerId, userList) => {
        //this is used only for 1 to 1 calls to searate left to right speakers
        //for new metadata version 1.2 speakerId is no longer used so a solution around speakerUserId is needed
        //speakerId is still used for old metadata versions
        if (this.props.metadata.versionId >= "1.2") {
            let filteredUserList = userList.filter(user => user.type !== "Bot");

            if (!filteredUserList) {
                return 0;
            }

            const userIndex = filteredUserList.findIndex(user => user.userId === speakerId);
            return userIndex !== -1 ? userIndex : 0;


        } else {
            return speakerId
        }
    }

    getTranscriptLine = (i, text, speakerId, speakerUserId, lastSpeaker, instances, languageCode, languageDirection, type, url, changedNote, changedTime, priority, reactions) => {
        if (this.state.id === "1" && this.state.val !== "") {
            if (!text.toLowerCase().includes(this.state.val.toLowerCase()))
                return;
        } else if ((type === "audio" || type === "") && (!this.state.audioActive || this.props.viewType === "liveView" || this.props.viewType === "attachView")) {
            return;
        } else if (type === "chat" && (!this.state.chatActive || this.props.viewType === "liveView" || this.props.viewType === "attachView")) {
            return;
        } else if ((type === "tag" || type === "rec") && (!this.state.tagActive || this.props.viewType === "attachView")) {
            return;
        } else if ((type === "attach") && (!this.state.attachActive || this.props.viewType === "liveView")) {
            return;
        }
        var speakerName = "";
        var speakerPhoto = null;
        var speakerIdVersion = speakerId;
        if (this.props.metadata.versionId >= "1.2") { //check if metadata version ahs unmixed audio and use speakerUserId. to make sure old version will work
            speakerIdVersion = speakerUserId;
        }
        if (type === "audio")
            speakerName = getSpeakerName(speakerIdVersion, this.props.user, this.props.metadata.versionId);
        else {
            speakerName = getSpeakerName(speakerIdVersion, this.props.user, this.props.metadata.versionId);
            speakerPhoto = getSpeakerPhoto(speakerIdVersion, this.props.user, this.props.metadata.versionId);
        }
        var lastSpeakerSave = lastSpeaker;
        this.lastSpeaker = speakerName;
        if (type === "rec")
            this.lastSpeaker = null;
        return <TranscriptLine
            key={i}
            type={type}
            viewType={this.props.viewType}
            one2oneView={this.state.one2oneView}
            one2oneSide={this.state.one2oneView ? this.getSpeakerNumberBasedOnUserId(speakerIdVersion, this.props.user) : 0}
            keyWords={this.props.sum != null ? (this.findKeywordsInTranscript(text, instances, this.sortArr(this.convertArr(this.props.sum.keywords)))) : []}
            policyResultAdherence={this.props.policyResultAdherence}
            listener={this.props.listener}
            text={this.state.translationActive ? "Translation to " + this.state.language + " running" : text}
            lastSpeaker={lastSpeakerSave}
            speakerId={speakerName}
            photo={speakerPhoto}
            instances={instances}
            translated={this.state.translated}
            languageCode={languageCode}
            languageDirection={languageDirection}
            url={url}
            changedNote={changedNote}
            changedTime={changedTime}
            priority={priority}
            reactions={reactions}
            usersList={this.props.user}
        />
    }

    showDownloadDialog = () => {
        //check if the conversation is in safe 
        if (this.props.metadata.conversationSafe) {
            let conversationSafeAccess = this.props.userRules !== undefined && this.props.userRules !== null && this.props.userRules.ruleAccess.userSafe !== "No"; // No | Full | Restricted
            //case resricted and conversations in one folder
            if (this.props.userRules.ruleAccess.userSafe === "Restricted" && this.props.metadata.conversationSafeFolders !== undefined && this.props.metadata.conversationSafeFolders.length > 0) {
                let ownExport = this.props.userRules.ruleAccess.ownFolder.exportFolders
                let user = this.props.userRules.userId
                //if i can export one of its folder i can export the conversation
                let download = this.props.metadata.conversationSafeFolders.map(f => {
                    if (f.userId === user) {
                        return ownExport;
                    } else {
                        return f.exportFolders
                    }
                })
                conversationSafeAccess = download.includes(true)
            }
            return window.innerWidth > 600 && this.props.isFullScreen == null && (this.props.userRules.ruleAccess.userDenyExport == null || !this.props.userRules.ruleAccess.userDenyExport) && conversationSafeAccess
        } else {
            return window.innerWidth > 600 && this.props.isFullScreen == null && (this.props.userRules.ruleAccess.userDenyExport == null || !this.props.userRules.ruleAccess.userDenyExport)
        }
    }



    renderHeader = () => {
        return (<MyContext.Consumer>
            {(context) =>
                <Stack vertical style={{ "margin": "10px" }} >
                    <Stack horizontal tokens={{ childrenGap: "20" }} horizontalAlign="space-between">
                        {window.innerWidth > 600 && (this.props.isFullScreen != null) && <Stack.Item>
                            <CommandBarButton
                                iconProps={{ iconName: 'Download' }}
                                text={"Transcript"}
                                style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                                onClick={this._onExport}
                            />
                        </Stack.Item>}
                        {window.innerWidth > 600 && this.props.userRules != null && this.props.userRules.ruleAnalytics != null && (!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsAnalyticsChange) && <TooltipHost content={context.getTranslation("detailContainer", "transcriptMessage")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                            <Query
                                query={getCognitiveServicesLocalesTranscription}
                                variables={{}}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) {
                                        return (
                                            <div style={{ float: "left", marginLeft: "50px" }}>
                                                <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                            </div>
                                        );
                                    }
                                    if (error) return <h4>Failed to connect</h4>;
                                    if (data.getTranscriptionLocales == null) {
                                        return <div />;
                                    }
                                    let transcriptLanguageList = [];

                                    if (data.getTranscriptionLocales != null) {

                                        let languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
                                        for (var i = 0; i < data.getTranscriptionLocales.length; i++) {
                                            var countryName = languageNames.of(data.getTranscriptionLocales[i]);
                                            transcriptLanguageList.push({
                                                key: data.getTranscriptionLocales[i],
                                                text: countryName
                                            });
                                        }
                                    }

                                    return <TranscriptSelect
                                        className="Selector"
                                        language={(this.props.data != null && this.props.data.languageTranscript != null) ? this.props.data.languageTranscript : context.getTranslation("detailContainer", "select")}
                                        onTranslate={this.onTranscript}
                                        isSpeechToText={(this.props.userRules.ruleAnalytics.withTranscription) ? true : false}
                                        languageList={transcriptLanguageList}
                                    />

                                }}

                            </Query>
                        </TooltipHost>}

                        {window.innerWidth > 600
                            && (!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsAnalyticsChange)
                            && (this.props.userRules.ruleAnalytics !== null && this.props.userRules.ruleAnalytics.translationType !== "No")
                            && <Stack.Item >
                                <Query
                                    query={getCognitiveServicesLocalesTranslation}
                                    variables={{}}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading) {
                                            return (
                                                <div style={{ float: "left", marginLeft: "50px" }}>
                                                    <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                                </div>
                                            );
                                        }
                                        if (error) return <h4>Failed to connect</h4>;
                                        if (data.getTranslatorLocales == null) {
                                            return <div />;
                                        }

                                        let translateLanguageList = [];

                                        if (data.getTranslatorLocales != null) {

                                            for (var i = 0; i < data.getTranslatorLocales.length; i++) {
                                                let translateLanguageData = JSON.parse(data.getTranslatorLocales[i]);

                                                translateLanguageList.push({
                                                    key: translateLanguageData.key,
                                                    text: translateLanguageData.text
                                                });
                                            }
                                        }

                                        return <LanguageSelect
                                            className="Selector"
                                            language={this.state.language != null ? this.state.language : ""}
                                            onTranslate={this.onTranslate}
                                            languageList={translateLanguageList}
                                        />

                                    }}

                                </Query>

                            </Stack.Item >}

                        {this.showDownloadDialog() && <Stack.Item>
                            <DownloadDialog
                                refetchQueryFields={this.props.refetchQueryFields}
                                client={this.props.client}
                                authorization={this.props.authorization}
                                export={this.props.export}
                            />
                        </Stack.Item>}
                        {window.innerWidth > 600 && <Stack.Item>
                            {this.state.openaiActive ? < Dropdown
                                selectedKey={this.state.openaiType}
                                onChange={this.onOpenAIQuestionChange}
                                style={{ width: "150px" }}
                                options={[
                                    {
                                        key: 'openai',
                                        text: context.getTranslation('configuration', 'openai'),
                                        hidden: true
                                    }, {
                                        key: 'summarize',
                                        text: context.getTranslation('configuration', 'openaiSummarize'),
                                    },
                                    {
                                        key: 'sentiment',
                                        text: context.getTranslation('configuration', 'openaiSentiment'),
                                    },
                                    {
                                        key: 'content',
                                        text: context.getTranslation('configuration', 'openaiQuestion'),
                                    },
                                    {
                                        key: 'aiQuestionTest',
                                        text: context.getTranslation('configuration', 'aiQuestion'),
                                    }
                                ]}
                            />
                                : this.props.userRules.addOnCompliance && this.props.userRules.ruleAccess.userComplianceAIQuestions && (
                                    <CommandBarButton
                                        style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                                        onClick={(event, newValue) => { this.setState({ openaiActive: true }) }}
                                        text={context.getTranslation("configuration", "openai")}
                                    />
                                )
                            }

                        </Stack.Item >}
                        <Stack.Item grow="1">
                            <SearchBox
                                styles={{ root: { width: 200 } }}
                                placeholder={context.getTranslation("detailContainer", "search")}
                                onEscape={ev => {
                                    console.log('Custom onEscape Called');
                                }}
                                onClear={ev => {
                                    console.log('Custom onClear Called');
                                }}
                                onChange={(event, newValue) => (event?.persist(), this.handler(newValue))}
                                onSearch={newValue => console.log('SearchBox onSearch fired: ' + newValue)}
                                onFocus={() => console.log('onFocus called')}
                                onBlur={() => console.log('onBlur called')}
                            />
                        </Stack.Item>
                    </Stack>
                    {this.state.openaiActive &&
                        (
                            <Stack>
                                <Label>Question</Label>
                                <Stack horizontal tokens={{ childrenGap: "6" }} horizontalAlign="space-between">
                                    <Stack.Item grow="4">
                                        <TextField key="4" value={this.state.openaiText} onChange={(event, newValue) => this.setState({ openaiText: newValue })}
                                            disabled={(this.state.openaiType !== 'content' && this.state.openaiType !== 'aiQuestionTest') || this.state.loadingOpenAI} onKeyDown={(ev) => {
                                                if (ev.key === 'Enter' && this.state.openaiText.length > 0) {
                                                    this.onOpenai()
                                                    ev.preventDefault();
                                                }
                                            }} />
                                    </Stack.Item>
                                    <Stack.Item grow="2">
                                        <DefaultButton text="Send to AI" onClick={this.onOpenai} disabled={this.state.openaiText.length == 0 || this.state.loadingOpenAI} />
                                    </Stack.Item>
                                </Stack>
                                {!this.state.loadingOpenAI && (<TextField label="Response" key="5" multiline rows={10} value={this.state.openaiResponse} disabled />)}
                                {this.state.loadingOpenAI && (
                                    <div style={{ float: "left", "margin": "50px" }}>
                                        <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                    </div>)}
                            </Stack>
                        )
                    }
                    {this.props.metadata.errorSpeechToText &&
                        <Stack>
                            <Stack.Item>
                                <AlertBar message={context.getTranslation("detailContainer", "analysisError")} alertType={"error"} />
                            </Stack.Item>
                        </Stack>
                    }
                </Stack>}
        </MyContext.Consumer>);
    }

    renderHeaderAttach = (props) => {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Text variant="large" >{context.getTranslation("detailContainer", "attachments")}</Text>
                )
                }
            </MyContext.Consumer>
        );
    }

    renderParticipants = (props) => {
        let filteredByTypeUser = props.user.filter(user => user.type !== "Bot");
        return (
            <div className="transcript-persona">
                <Stack horizontal horizontalAlign="space-between" >
                    <Persona
                        key={0}
                        size={PersonaSize.size32}
                        className="margin_low"
                        text={filteredByTypeUser[0].name}
                        secondaryText={filteredByTypeUser[0].detail}
                        imageUrl={"data:image/jpeg;base64," + filteredByTypeUser[0].photo}>
                    </Persona>
                    <Persona
                        key={1}
                        size={PersonaSize.size32}
                        className="margin_low"
                        text={filteredByTypeUser[1].name}
                        secondaryText={filteredByTypeUser[1].detail}
                        imageUrl={"data:image/jpeg;base64," + filteredByTypeUser[1].photo}>
                    </Persona>
                </Stack>
            </div>
        );
    }

    onTranscript = async (language, languageText) => {

        this.props.client
            .mutate({
                mutation: gql`
                        mutation ent ($uri: String, $id: String, $language: String, $type: String) {
                            analyzeConversation (uri: $uri, id: $id, language: $language, type: $type)
                     }
                     `,
                variables: {
                    uri: this.props.metadata.entryContent,
                    id: this.props.metadata.conversationId,
                    language: language,
                    type: (this.props.userRules != null && this.props.userRules.ruleAnalytics != null && this.props.userRules.ruleAnalytics.withTranscription) ? "SpeechToText" : "Indexer"
                }
            });
    }


    render() {
        var classNames = mergeStyleSets({
            wrapper: {
                height: this.props.height - 35,
                position: 'relative',
                minHeight: '200px'
            },
            pane: {
            }
        });

        if (this.props.viewType === "liveView" || this.props.viewType === "attachView") {
            return <MyContext.Consumer>
                {(context) => (
                    <Stack vertical>
                        <Text variant="large" >{context.getTranslation("liveViewPanel", "tagging")}</Text>
                        {window.innerWidth > 600 ?
                            <div className={classNames.wrapper}>
                                <ScrollablePane styles={{ root: classNames.pane }}>
                                    {this.renderLines(this.props)}
                                </ScrollablePane>
                            </div>
                            :
                            <div>
                                {this.renderLines(this.props)}
                            </div>
                        }
                    </Stack>
                )
                }
            </MyContext.Consumer>
        } else if (this.state.one2oneView === true) {
            classNames = mergeStyleSets({
                wrapperonetoone: {
                    height: this.props.height - 75,
                    position: 'relative',
                    minHeight: '200px'
                },
                pane: {
                }
            });

            return <Stack vertical >
                {this.renderHeader()}
                {this.renderParticipants(this.props)}
                {window.innerWidth > 600 ?
                    <div className={classNames.wrapperonetoone}>
                        <ScrollablePane styles={{ root: classNames.pane }}>
                            {this.renderLines(this.props)}
                        </ScrollablePane>
                    </div> :
                    <div>
                        {this.renderLines(this.props)}
                    </div>}
            </Stack>
        } else {
            return <div>
                {this.renderHeader()}
                {window.innerWidth > 600 ?
                    <div className={classNames.wrapper}>
                        <ScrollablePane styles={{ root: classNames.pane }}>
                            {this.renderLines(this.props)}
                        </ScrollablePane>
                    </div> :
                    <div>
                        {this.renderLines(this.props)}
                    </div>}
            </div>
        }
    }

    _onExport = async (ev) => {
        await this.props.client
            .mutate({
                mutation: mutationWriteAuditEvent,
                variables: {
                    key: "RECORDING_EXPORT",
                    id: this.props.export.conversationId,
                    text: ""
                }
            });
        var itemList = [];
        // Header
        itemList.push(config.sheet.data[0].slice());
        // Title
        itemList.push([
            {
                value: "title",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: this.props.export.topic,
                type: 'string'
            }]);
        // Date
        itemList.push([
            {
                value: "date",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: this.props.export.day,
                type: 'string'
            }]);
        // Time
        itemList.push([
            {
                value: "time",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: this.props.export.timein,
                type: 'string'
            }]);
        // Duration
        itemList.push([
            {
                value: "duration",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: this.props.export.duration,
                type: 'string'
            }]);
        // Conversation Id
        itemList.push([
            {
                value: "conversationid",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: this.props.export.conversationId,
                type: 'string'
            }]);
        // Language
        itemList.push([
            {
                value: "language",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: "",
                type: 'string'
            }, {
                value: this.state.languageCode != null ? this.state.languageCode : this.props.data.language,
                type: 'string'
            }]);
        var i = 0;
        if (this.state.transcript != null) {
            this.state.transcript.forEach((anItem) => {
                itemList.push([
                    {
                        value: i.toString(),
                        type: 'string'
                    }, {
                        value: anItem.instances[0].start,
                        type: 'string'
                    }, {
                        value: anItem.speakerId,
                        type: 'string'
                    }, {
                        value: anItem.text,
                        type: 'string'
                    }]);
                i++;
            });
        } else {
            this.props.data.transcript.forEach((anItem) => {
                itemList.push([
                    {
                        value: i.toString(),
                        type: 'string'
                    }, {
                        value: anItem.instances[0].start,
                        type: 'string'
                    }, {
                        value: anItem.speakerId,
                        type: 'string'
                    }, {
                        value: anItem.text,
                        type: 'string'
                    }]);
                i++;
            });
        }
        config.sheet.data = itemList;
        zipcelx(config)
        this.setState({ isDialogOpen: true });
    }
}


function getSpeakerName(speakerId, userlist, metadataVersion) {
    if (metadataVersion >= "1.2") {
        let filteredUser = userlist.find(user => user.userId === speakerId);

        if (filteredUser !== undefined && filteredUser !== null) {
            return filteredUser.name;

        } else {
            return "Speaker"
        }

    } else {
        if (userlist !== undefined && userlist[speakerId] !== undefined) {
            return userlist[speakerId].name;
        }
        else {
            return "Speaker #" + speakerId;
        }
    }

}

function getSpeakerPhoto(speakerId, userlist, metadataVersion) {
    if (metadataVersion >= "1.2") {

        let filteredUser = userlist.find(user => user.userId === speakerId);

        if (filteredUser !== undefined && filteredUser !== null) {
            return filteredUser.photo;

        } else {
            return null;
        }

    } else {
        if (userlist !== undefined && userlist[speakerId] !== undefined) {
            return userlist[speakerId].photo;
        }
        else {
            return null;
        }
    }
}

