import React from 'react';

import Funnel, {
    Export,
    Item,
    Border,
    Label
} from 'devextreme-react/funnel';
import {Size} from "devextreme-react/vector-map";

class FunnelChart extends React.Component{
    render() {
        return (
            <Funnel id="funnel"
                    dataSource={this.props.data}
                    palette="Soft Pastel"
                    argumentField="argument"
                    valueField="value"
            >
                <Export enabled={true} />

                <Item>
                    <Border visible={true} />
                </Item>
                <Label
                    visible={true}
                    position="inside"
                    backgroundColor="none"
                    customizeText={formatLabel}
                />
                <Size
                    height={this.props.size.height}
                    width={this.props.size.width}
                />
            </Funnel>
        );
    }
}

function formatLabel(arg) {
    return `<span class="label">${arg.percentText}</span><br/>${arg.item.argument}`;
}

export default FunnelChart;
