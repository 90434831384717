export default class Utils {
    static guidGenerator() {
        const s4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4());
    }
    static lsGet<T>(key: string): T {
        const val = localStorage.getItem(key);
        if (val)
            return (JSON.parse(val));

        //console.log("Unexpected error in lsGet. No value for key", key);
        return (undefined);
    }

    static lsSet<T>(key: string, value: T): T {
        if (value) {
            localStorage.setItem(key, JSON.stringify(value));
            return value;
        }

        //console.log("Unexpected error in lsSet. No value for key", key);
        return (undefined);
    }

    static lsRemove(key: string) {
            localStorage.removeItem(key);
    }
    
    
    static orderAlphabetically(a,b){
        if(a > b) return 1
        return -1
    }
}