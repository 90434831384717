import React, { Component } from 'react'
import * as microsoftTeams from "@microsoft/teams-js";

import './Login.scss'

export default class LoginResult extends Component {

    componentDidMount() {
        microsoftTeams.initialize();
        console.log("loginReply: Start");
        if (this.props.authContext.isCallback(window.location.hash)) {
            this.props.authContext.handleWindowCallback(window.location.hash);
            console.log("loginReply: Callback");
            if (this.props.authContext.getCachedUser()) {
                console.log("loginReply: Success");
                microsoftTeams.authentication.notifySuccess();
            } else {
                console.log("loginReply: Failure");
                microsoftTeams.authentication.notifyFailure(this.props.authContext.getLoginError());
            }
        } else {
            // Unexpected condition: hash does not contain error or access_token parameter
            microsoftTeams.authentication.notifyFailure("UnexpectedFailure");
        }
        window.close();
    }

    render() {
        return (
            <div className="login-body">
            </div>
        );
    }
}
