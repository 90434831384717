import React from 'react';

export default class Styler {
    // #Default Colorset
    static colorset={
      headerBlue: '#00315E',
      headerRed: '#91001E',
      headerLightBlue: '#71A2D2',
      headerOrange: '#DF6F15',
      headerGreen: '#90BF38',
    }

    // add -> Option to integrate inline JS-CSS to Funtions

    // # Header Styling function

    static basicStyleHeader(add) {
      if (add !== undefined) {
        return {
          color: this.colorset.headerLightBlue,

          ...add,
        };
      }

      return {
        color: this.colorset.headerLightBlue,
      };
    }

    static fabricStyleHeader(add) {
      if (add !== undefined) {
        return {
          root: {
            color: this.colorset.headerLightBlue,
            ...add,
          },
          subComponentStyles: {
            label: {
              root: [
                {
                  color: this.colorset.headerLightBlue,
                },
              ],
              color: this.colorset.headerLightBlue,
            },
            color: this.colorset.headerLightBlue,

          },
        };
      }
      return {
        root: this.basicStyleHeader(),
        subComponentStyles: {
          label: {
            root: [
              {
                color: this.colorset.headerLightBlue,
              },
            ],
            color: this.colorset.headerLightBlue,
          },
          color: this.colorset.headerLightBlue,

        },
      };
    }

    // # Text Styling function
    // Usage:
    // style={Styler.basicStyleText({ ... })}
    //                                 ^-- add additional inline CSS
    // style={Styler.basicStyleText()}

    static basicStyleText(add) {
      if (add !== undefined) {
        return {
          color: this.colorset.headerBlue,

          ...add,
        };
      }

      return {
        color: this.colorset.headerBlue,
      };
    }

    static fabricStyleText(add) {
      if (add !== undefined) {
        return {
          root: {
            color: this.colorset.headerBlue,
            ...add,
          },
          subComponentStyles: {
            label: {
              root: [
                {
                  color: this.colorset.headerBlue,
                },
              ],
              color: this.colorset.headerBlue,
            },
            color: this.colorset.headerBlue,

          },
        };
      }
      return {
        root: this.basicStyleText(),
        subComponentStyles: {
          label: {
            root: [
              {
                color: this.colorset.headerBlue,
              },
            ],
            color: this.colorset.headerBlue,
          },
          color: this.colorset.headerBlue,

        },
      };
    }
}
