import React, { Component } from "react";
import { Stack, CommandBar, ContextualMenuItemType, Icon, FontSizes } from "office-ui-fabric-react";
import { MyContext } from "../../../../../context"


export class MergePivotAccordionHeader extends Component {

	static contextType = MyContext;

	constructor(props) {
		super(props);
		this.state = { mergeParent: this.props.parent }
	}

	render() {

		const menuItems = [
			{
				key: 'Delete',
				hidden: false,
				iconProps: {
					iconName: 'Delete',
				},
				text: this.context.getTranslation("compliance", "unlinkWholeContainer"),
				disabled: false,
				onClick: (e) => {
					// console.log('schiaccio bottone unlink parent', this.props.id, this.props.childrenItems)
					this.props.updateParentComponentState(this.props.id, this.props.childrenItems)
				},
			}
		]
		return (
			<>
				<div className="flex" onClick={(e) => e.stopPropagation()}>
					<div className="accordionHeaderColumns">
						<div style={{ fontSize: "16px", fontWeight: "600" }}>{this.props.name}</div>
					</div>
						<Stack horizontal>
							<Stack.Item >
								<CommandBar
									className='commandBar'
									overflowItems={menuItems}
									styles={{
										root: {
											paddingRight: '0px'
										}
									}}
								/>
							</Stack.Item>
						</Stack>
				</div>
			</>
		);
	}
}