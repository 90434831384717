import React from 'react';
import {
    ContextualMenuItemType,
    CommandBar, IconButton, Modal,
} from 'office-ui-fabric-react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import ReportConfiguration from '../ReportView/ReportConfiguration/ReportConfiguration';
import DevboardWidget from '../DevboardView/Widgets/DevboardWidget';
import { MyContext } from '../../context';

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        {
            flex: '1 1 auto',
            borderTop: '4px solid blue',
            // color: theme.palette.neutralPrimary,
            display: 'flex',
            // fontSize: FontSizes.xLarge,
            alignItems: 'center',
            // fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0',
            },
            'p:first-child': {
                marginTop: 0,
            },
            'p:last-child': {
                marginBottom: 0,
            },
        },
    },
});

const iconButtonStyles = mergeStyleSets({
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    }
});


export default class CardOption extends React.Component {
    constructor(props) {
        super();
        this.reportConf = React.createRef();
        this.state = {
            active: false,
            showModal: false,
        };
        this.exportChart = this.exportChart.bind(this);
        this.chartRef = props.reference;

        this.menueItems = [{
            key: 'Max',
            onClick: () => {
                this._openModal();
            },
            iconProps: {
                iconName: 'ChromeFullScreen'
            },
        },
        {
            key: 'Actions',
            itemType: ContextualMenuItemType.Header,
            text: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "widgetMenuActions")}</>)}</MyContext.Consumer>,
            itemProps: {
                lang: 'en-us',
            },
        },
        {
            key: 'Entry',
            iconProps: {
                iconName: 'GroupedList',
            },
            text: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "widgetMenuEntryList")}</>)}</MyContext.Consumer>,
            title: "Display all relevant entry's ",
            onClick: () => {
                this.props.list(this.props.keyId);
            },
        },
        {
            key: 'Edit',
            iconProps: {
                iconName: 'Edit',
            },
            text: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "widgetMenuEdit")}</>)}</MyContext.Consumer>,
            title: 'Edit this element',
            disabled: false,
            onClick: () => {
                // this.reportRef.showPanel();
                this.reportConf.current.showModal();
            },
            },
        //future feature
        //{
        //    key: 'Print',
        //    iconProps: {
        //        iconName: 'Print',
        //    },
        //    text: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "widgetMenuPrint")}</>)}</MyContext.Consumer>,
        //    title: 'Print',
        //    disabled: false,
        //    onClick: () => {
        //        // this.reportRef.showPanel();
        //        this.reportConf.current.showModal();
        //    },
        //},
        {
            key: 'Delete',
            iconProps: {
                iconName: 'Delete',
                style: {
                    color: 'red',
                },
            },
            text: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "delete")}</>)}</MyContext.Consumer>,

            onClick: () => {
                this.props.deleteReport(this.props.keyId);
                this.props.delfunc();
            },
        },
        ];
    }


    reportRef = React.createRef();

    render() {
        const overflowProps = {
            root: { paddingRight: '5px' },
        };

        return (

            <div
                ref={this.reportRef}
                style={{
                    width: 60 * 2,
                }}
            >
                <ReportConfiguration
                    reloadFunc={this.props.reloadFunc}
                    id={this.props.element.id}
                    title={this.props.element.title}
                    text={this.props.element.text}
                    icon=""
                    type={this.props.element.type}
                    cycle={this.props.element.cycle}
                    keywords={this.props.element.keywords}
                    startDate={this.props.element.startDate}
                    endDate={this.props.element.endDate}
                    sortOrder={this.props.element.sortOrder}
                    kpi={this.props.element.kpi}
                    tForm={this.props.element.tForm}
                    valueType={this.props.element.valueType}
                    tenantId={this.props.element.tenantId}
                    botAppId={this.props.element.botAppId}
                    jobId={this.props.element.jobId}
                    alarming={this.props.element.alarming}
                    categories={this.props.element.categories}
                    isNew={false}
                    authorization={this.props.authorization}
                    apol={this.props.apol}
                    ref={this.reportConf}
                    widget={this.props.element.widget}
                    persons={this.props.element.persons}
                    userRules={this.props.userRules}
                    input={this.props.input}
                    reportSend={this.props.element.reportSend}
                    reportMail={this.props.element.reportMail}
                    jobType={this.props.element.jobType}
                    theme={this.props.theme}
                    conversationType={this.props.element.conversationType}


                />

                <CommandBar
                    items={this.menueItems}
                    buttonProps={overflowProps}
                    overflowButtonProps={overflowProps}

                    styles={{
                        root: {
                            color: 'transparent',
                            background: 'transparent',
                            paddingRight: '0px',
                            marginLeft: '15px',

                        },
                    }}
                />
                <Modal
                    isOpen={this.state.showModal}
                    onDismiss={this._closeModal}
                    isBlocking
                    containerClassName={contentStyles.container}
                >
                    <div className={contentStyles.header}>
                        <span id={this._titleId}>{this.props.title}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close popup modal"
                            onClick={this._closeModal}
                        />
                    </div>
                    <div id={this._subtitleId} className={contentStyles.body}>
                        {
                            <DevboardWidget element={this.props.element} reference={this.props.reference} resize={true} input={this.props.input} ></DevboardWidget>
                        }
                    </div>
                </Modal>
            </div>
        );
    }

    _closeModal = () => {
        this.setState({ showModal: false });
    }

    _openModal = () => {
        this.setState({ showModal: true });
    }

    get chart() {
        return this.chartRef.current.instance;
    }

    exportChart() {
        this.chart.exportTo('export', 'png');
    }
}
