import { Stack } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import FragmentItem from './FragmentItem'
import * as QueryPatterns from '../PatternsQuery';

class FragmentsList extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.fragments !== null ? FragmentsList.getCleanFragments(this.props.fragments) : [
                {
                    index: 1,
                    nDigits: 1,
                    value: "",
                    fragmentType: "",
                    operatorType: "Equals" //todo
                }
            ],
            fragmentTypeOptions: [],
            operatorTypeOptions: [],
            example: "",
            updated: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.fragments !== null && !FragmentsList.equalsFragments(props.fragments, state.data) && !state.updated) {
            return {
                data: FragmentsList.getCleanFragments(props.fragments),
                example: FragmentsList.getExample(props.fragments, state.fragmentTypeOptions)
            };
        }
        return null;
    }

    static equalsFragments = (f1, f2) => {
        if (f1.length !== f2.length) return false;
        for (var i = 0; i < f1.length; i++) {
            if (f1[i].fragmentType !== f2[i].fragmentType || f1[i].index !== f2[i].index || f1[i].nDigits !== f2[i].nDigits || f1[i].operatorType !== f2[i].operatorType || f1[i].value !== f2[i].value) {
                return false;
            }
        }
        return true;
    }

    static getCleanFragments = (fragments) => {
        return fragments.map(f => {
            return ({ fragmentType: f.fragmentType, index: f.index, nDigits: f.nDigits, operatorType: f.operatorType, value: f.value })
        })
    }

    componentDidMount() {
        if (this.props.fragments !== null)
            this.props.validationFragments(this.props.fragments)
        this.props.apol
            .query({
                query: QueryPatterns.getFragmentType,
            })
            .then((result) => {
                const fragmentTypeOptions = result.data.getFragmentTypeEnum.map(t => {
                    return ({
                        "key": t.name,
                        "text": this.context.getTranslation("compliance", t.name),
                        "description": t.description,
                        "regex": t.customField
                    })
                })
                this.setState({
                    fragmentTypeOptions,
                    example: FragmentsList.getExample(this.state.data, fragmentTypeOptions)
                })
            });
        this.props.apol
            .query({
                query: QueryPatterns.getOperatorType,
            })
            .then((result) => {
                const operatorTypeOptions = result.data.getOperatorTypeEnum.map(t => {
                    return ({
                        "key": t.name,
                        "text": this.context.getTranslation("compliance", t.name),
                    })
                })
                this.setState({
                    operatorTypeOptions
                })
            });
    }

    setNewData = (newData) => {
        this.setState({
            data: newData,
            example: FragmentsList.getExample(newData, this.state.fragmentTypeOptions),
            updated: true
        })
    }

    updateFragmentType = (selected, index) => {
        var newData = this.state.data.map(d => {
            if (d.index === index) {
                return (
                    {
                        ...d,
                        fragmentType: selected,
                        nDigits: "1",
                        value: ""
                    }
                )
            } else {
                return d;
            }
        })
        this.setNewData(newData)
        this.props.validationFragments(newData)
    }

    deleteFragment = (index) => {
        var newData = this.state.data.filter(d => d.index !== index).map((dat, i) => {
            return ({ ...dat, index: i + 1 })
        })
        this.setNewData(newData)
        this.props.validationFragments(newData)
    }

    addFragment = () => {
        var newFragment = {
            index: this.state.data.length + 1,
            nDigits: 1,
            value: "",
            fragmentType: "",
            operatorType: "Equals" //todo
        }
        var newData = [...this.state.data, newFragment]
        this.setState({
            data: newData,
            updated: true
        })
        this.props.validationFragments(newData)
    }

    upFragment = (index) => {
        var newData = this.state.data.map(d => {
            if (d.index === index) {
                return (
                    {
                        ...d,
                        index: index - 1
                    }
                )
            } else if (d.index === index - 1) {
                return (
                    {
                        ...d,
                        index: index
                    }
                )
            } else {
                return d;
            }
        })
        this.setNewData(newData)
    }

    downFragment = (index) => {
        var newData = this.state.data.map(d => {
            if (d.index === index) {
                return (
                    {
                        ...d,
                        index: index + 1
                    }
                )
            } else if (d.index === index + 1) {
                return (
                    {
                        ...d,
                        index: index
                    }
                )
            } else {
                return d;
            }
        })
        this.setNewData(newData)
    }

    changeNDigits = (value, index) => {
        var newData = this.state.data.map(d => {
            if (d.index === index) {
                return (
                    {
                        ...d,
                        nDigits: value,
                        value: ""
                    }
                )
            } else {
                return d;
            }
        })
        this.setNewData(newData)
        this.props.validationFragments(newData)
    }

    changeValue = (value, index) => {
        var newData = this.state.data.map(d => {
            if (d.index === index && d.fragmentType !== '' && (value === "" || this.isValidValue(value, d.fragmentType))) {
                return (
                    {
                        ...d,
                        nDigits: "",
                        value: value
                    }
                )
            } else {
                return d;
            }
        })
        this.setNewData(newData)
        this.props.validationFragments(newData)
    }

    isValidValue = (value, fragmentType) => {
        var result = false
        this.state.fragmentTypeOptions.forEach(type => {
            if (type.key === fragmentType) {
                try {
                    var r = new RegExp(type.regex);
                    result = r.test(value)
                } catch (error) {
                    console.log(`Error create regex ${error}`)
                    result = false;
                }
            }
        })
        return result;
    }

    static getExample = (newData, fragmentTypeOptions) => {
        var result = ""
        var sortData = newData.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
        sortData.forEach((frag, i) => {
            //if i have the exact value 
            if (frag.value !== "") {
                result = result + " " + frag.value
            } else {
                //manage the range #
                var length = parseInt(frag.nDigits)
                if (isNaN(length) || frag.nDigits.toString().includes("-")) {
                    var range = frag.nDigits.split("-")
                    length = range[range.length - 1]
                }
                fragmentTypeOptions.forEach(type => {
                    if (type.key === frag.fragmentType) {
                        result = result + " " + FragmentsList.createExample(type.description, length)
                    }
                })
            }
        })
        return result;
    }

    static createExample = (range, length) => {
        var result = '';
        for (var i = 0; i < length; i++) {
            result += range.charAt(Math.floor(Math.random() * range.length));
        }
        return result;
    }


    render() {
        var dataLength = this.state.data.length
        var sortData = this.state.data.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
        this.props.updateFragments(sortData)

        return (
            <div>
                <Stack style={{ marginTop: "10px" }}>
                    <h4>{this.context.getTranslation("compliance", "patternExample")}</h4>
                </Stack>
                <span>{`${this.state.example}`}</span>
                <Stack style={{ marginTop: "10px" }} horizontal>
                    <h4>{this.context.getTranslation("compliance", "addFragments")}</h4>
                </Stack>
                {
                    sortData.map((d, i) => {
                        return (
                            <FragmentItem
                                key={i}
                                data={d}
                                updateFragmentType={this.updateFragmentType}
                                first={i === 0}
                                last={i + 1 === dataLength}
                                fragmentTypeOptions={this.state.fragmentTypeOptions}
                                delete={this.deleteFragment}
                                up={this.upFragment}
                                down={this.downFragment}
                                changeNDigits={this.changeNDigits}
                                changeValue={this.changeValue}
                                addFragment={this.addFragment}
                                onlyOneFragment={dataLength === 1}
                            />)
                    })
                }
            </div>
        );
    }
}

export default FragmentsList;