import React, { Component } from 'react'
import * as microsoftTeams from "@microsoft/teams-js";

// Create new context
export const MyContext = React.createContext()

// Then create a Provider Component
export class MyProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "",
            language_en: require('./translations/en.json')
        }
        this.getTranslation = this.getTranslation.bind(this);
    }


    componentDidMount() {
        //get microsoft teams language
        microsoftTeams.initialize();

        microsoftTeams.getContext((context => {
            this.setState({ language: context.locale })
        }));
        //console.log("Teams Language: " + this.state.language);

        //get browser language
        let navigatorLanguage = navigator.language || navigator.userLanguage;
        this.setState({ language: navigatorLanguage });
        //console.log("browser language " + this.state.language);
    }

    //get the translation for a word from json based on pagekey and workey
    getTranslation(pageKey, wordKey) {

        var language = this.getLanguageJson();
        //console.log("LIMBA: " + JSON.stringify(language));
        //get the translation by searching for pagekey and wordkey in language file.
        //.translation => access translation
        //.original =>access original english text
        //.description =>access the description/context of the translated word/sentence
        if (language[pageKey] && language[pageKey][wordKey]) {
            var translation = language[pageKey][wordKey].translation;
            return translation;
        } else if (this.state.language_en[pageKey][wordKey]) {
            var translationEn = this.state.language_en[pageKey][wordKey].translation;
            return translationEn;
        } else {
            return wordKey;
        }
    }

    //get json translation file based on the browser language
    getLanguageJson() {
        let browserLanguage = this.state.language;

        switch (browserLanguage.toLowerCase()) {
            case "ro":
                var RO = require('./translations/ro.json')
                return RO;
            case "de":
            case "de-de":
            case "de-li":
            case "de-lu":
            case "de-ch":
            case "de-at":
                var DE = require('./translations/de.json');
                return DE;
            case "ja":
            case "ja-jp":
                var JA = require('./translations/ja.json');
                return JA;
            case "nl":
            case "nl-nl":
                var NL = require('./translations/nl.json');
                return NL;
            case "fr":
            case "fr-ca":
            case "fr-ch":
            case "fr-fr":
                var FR = require('./translations/fr.json');
                return FR;
            case "zh-tw":
            case "zh-hant":
                var ZH = require('./translations/zh-Hant.json');
                return ZH;
            case "es":
            case "es-sp":
            case "es-ar":
            case "es-bo":
            case "es-cl":
            case "es-co":
            case "es-cr":
            case "es-cu":
            case "es-do":
            case "es-ec":
            case "es-sv":
            case "es-gt":
            case "es-hn":
            case "es-mx":
            case "es-ni":
            case "es-py":
            case "es-pe":
            case "es-pr":
            case "es-es":
            case "es-uy":
            case "es-us":
            case "es-ve":
                var ES = require('./translations/es.json');
                return ES;
            case "pt":
            case "pt-pt":
                var PT = require('./translations/pt.json');
                return PT;
            case "pt-br":
                var PT_BR = require('./translations/pt-br.json');
                return PT_BR;
            case "sv-se":
            case "sv":
                var SV = require('./translations/se.json');
                return SV;
            case "it":
            case "it-it":
                var IT = require('./translations/it.json');
                return IT;
            default:
                var EN = require('./translations/en.json');
                return EN;
        }
    }
    render() {
        return (
            <MyContext.Provider value={{
                state: this.state,
                getTranslation: this.getTranslation,
            }}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}