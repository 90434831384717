import gql from "graphql-tag";

export const queryGetAuth = gql`query ent($auth: AuthInput) {
    getAuth(input: $auth) {
        access
        userId
        valid
        analytics
        viewType
        blockDeletion
        visibilityDetails
        visibilityDetailsTranscript
        visibilityDetailsAnalytics
        visibilityDetailsAnalyticsChange
        visibilityDetailsNotes
        visibilityDetailsTTL
        visibilityDetailsCustomFields
        visibilityDetailsMetadata
        visibilityDetailsQM
        userTemplateAllowed
        addOnCompliance
        addOnQualityManagement
        ruleAnalytics {
            withSentiment
            withTranscription
            withVideoIndexer
            transcriptLanguage
            translationType
            translationLanguage
            accessType
        }
        ruleAccess {
            userLiveView
            userDenyExport
            userDenyUpload
            userBulkExport
            userBulkRestoreArchive
            userBulkSafe
            userBulkSafeAdd
            userBulkSafeRemove
            userBulkDelete
            userBulkTranscript
            userBulkExcel
            userBulkIpRange
            userBulkPasswordLength
            userSafe
            userReporting
            tenantCreate
            tenantChange
            confListUser
            confAddUser
            confTicket
            confRulesRecording
            confRulesReplay
            confRulesAccess
            confRulesAnalytics
            confMapUser
            confUserAssignment
            confAssignRecording
            confAssignReplay
            confAssignAccess
            confAssignAnalytics
            userAssignmentUsers
            userKpiWallboard
            confAuditLogs
            confJobs
            confCustomFields
            confAdministrateCustomFields
            confDefineActionOnCustomFields
            hasCustomFieldsAccess
            customFieldsAccessType
            customFieldsEditableTime
            accesibleCustomFields
            groupFeature
            denyReportAlarming
            complianceResultsAccess
            complianceQMResultsAccess
            userCompliancePolicy
            userCompliancePolicyActivate
            userCompliancePolicyInitiateWorkflow
            userCompliancePolicyEdit
            userCompliancePolicyAssign
            userComplianceAIQuestions
            userComplianceQM
            editableTimeCustomFields
            cfDisableTagging
            allowTTLChangeViaCategorization
            suspensionConvDelProcess
            ownFolder{
                addConversations 
                removeConversations 
                upserFolder 
                userIds 
                exportFolders
                deleteFolders
                shareFolders
            }
        }
        ruleRecording {
            audioType
            inboundType
            disableNextRecording
            demandStartOnly
            keepDeleteSettings{
            type
            startMode
            hasWrapUp
            wrapUpTimer
            isWrapUpOnly
            recordingMode
            actions
            hasPopupAlert
            isSendToDo
            toDoTimer
            hasUserNotification
            specificSettings
            isActive
            }
            roDStopReasons
            roDStopReasonRequired
        }
        defaultFilter {
            id
            timeBasedType
            type
            cycle
            startDate
            endDate
            categories
            integrations
            persons
            recordingOwner
            durationFrom
            durationTo
        }
        recordingFilter {
            id
            type
            cycle
            startDate
            endDate
            persons
            defaultFilter
            userFilter
            categories
            policies
            integrations
            folders
            timeBasedType
            recordingOwner
            durationFrom
            durationTo
        }
        allowedTenants {
            name
            id
        }
    }
}
`;


export const queryGetConfigData = gql`
    query ent($auth: AuthInput) {
        getConfigData(input: $auth) {
            tenant {
                licenseComp {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                licenseCompAna {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                licenseSmart {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                licenseSmartAna {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                licenseAna {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                licenseCompVoice {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                licenseCompVoiceAna {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                licenseSmartVoice {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                licenseSmartVoiceAna {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                licenseRecordingInsinghtsAI {
                    bought
                    used
                    id
                    type {
                        id
                    }
                }
                packageStorage
                packageAnalyticsVideo
                packageAnalyticsSpeech
                packageAnalyticsTranscript
                packageAnalyticsTranslate
                packageAnalyticsAI
                analyticsVideoCapacity
                analyticsVideoCapacityUsed
                analyticsSpeechCapacity
                analyticsSpeechCapacityUsed
                analyticsTranscriptCapacity
                analyticsTranscriptCapacityUsed
                analyticsTranslateCapacity
                analyticsTranslateCapacityUsed
                addOnAnalytics
                addOnAnalyticsUsed
                addOnFullChat
                addOnFullChatUsed
                addOnZoom
                addOnZoomUsed
                addOnGenesys
                addOnGenesysUsed
                addOnRingcentral
                addOnRingcentralUsed
                addOnDynamics
                addOnDynamicsUsed
                addOnCompliance
                addOnComplianceUsed
                addOnQualityManagement
                addOnQualityManagementUsed
                addOnLeapXpert
                addOnLeapXpertUsed
                packageExport
                storage
                analyticsAICapacityUsed
                analyticsAICapacity
            }
    
            userdata {
                access
                department
                name
                recordingRules
                replay
                analytics
                username
                id
                userId
                license
                groupId
                groupName
                addOnAnalytics
                addOnFullChat
                addOnZoom
                addOnGenesys
                addOnRingcentral
                addOnDynamics
                addOnCompliance
                addOnQualityManagement
                addOnLeapXpert
                regionalStorageLocation
                regionalStorageCustomLocation
                integrationUser
                awsAccessKeyId
                awsSecretAccess
                awsBucketName
                awsRegion
                awsServiceUrl
                awsType
            }
            botdata {
                botAppId
                id
                linkId
                name
                region
            }
            groupdata {
                tenantId
                adGroupId
                id
                recordingRules
                replay
                analytics
                access
                license
                name
                priority
                addOnAnalytics
                addOnFullChat
                addOnZoom
                addOnGenesys
                addOnRingcentral
                addOnDynamics
                addOnCompliance
                addOnQualityManagement
                addOnLeapXpert
                regionalStorageLocation
                regionalStorageCustomLocation
                awsAccessKeyId
                awsSecretAccess
                awsBucketName
                awsRegion
                awsServiceUrl
                awsType
            }
            ruledata {
                created
                group
                id
                name
                usage
            }
            categorydata {
                tenantId
                name
                id
                frequence
                sentiment
                keywords
                link
                searchExactKeywords
                countUniqueWordsOnce
                fromCompliance
                categorizationActionType
                retentionTimeSetting
                version
                masterItemId
                selected
                categoryGroup {
                    id
                    name
                    description
                    tenantId
                    generalGroup
                }
            }
            customfieldsdata {
                tenantId
                fieldName
                isNumeric
                isAlphanumeric
                maxNumberOfFields
                fieldLength
                hasExactLength
                isCheckbox
                id
                hasTriggerAction
                actionType
                ttlSettingYes
                ttlSettingNo
                notifications
                mapping
                hasAdditionalNumberCheck
                numberCheckAlgorithmType
            }
            importdata {
                id
                name
                callStartTime
                callEndTime
                jobType {
                    intervalDay
                    intervalHour
                    intervalMinute
                    intervalMonth
                    intervalType
                    jobStartTime
                }
                mappingType
                mappingUserId
                importType
                keyVaultConnectionString
                sourceTtl
                targetTtl
                enabled
                processingRegion
                storageConnectionString
                storageContainerName
                storageRootDirectory
                tenantId
                hasSftpKey
                state
                nextRun
                lastSuccessfulRun
                numberOfConversations
                remainingPackageSize
                isArchived
                userIds
                totalCalls
            }
            integrationdata {
                id
                name
                integrationType
                enabled
                processingRegion
                integrationClientId
                integrationClientSecret
                integrationUsername
                integrationPassword
                tenantId
                syncRecordingRule
                allRecordings
                mappingUserId
                folderName
                integrationPrivateKey
                integrationPrivateKeyFile
                hasPrivateKey
            }
            exportdata {
                id
                name
                userIds
                jobType {
                    intervalDay
                    intervalHour
                    intervalMinute
                    intervalMonth
                    intervalType
                    jobStartTime
                }
                exportType
                sourceTtl
                storageConnectionString
                storageContainerName
                storageRootDirectory
                audioCodec
                callStartTime
                callEndTime
                conversationType
                tenantId
                processingRegion
                enabled
                defaultRegion
                pgpEncryption
                hasSftpKey
                auditEventTypes
                state
                nextRun
                lastSuccessfulRun
                numberOfConversations
                remainingPackageSize
                isArchived
                mergeAudioToVideo
                totalCalls
            }
            reportdata {
                id
                title
                widget
                text
                type
                kpi
                tenantId
                valueType
                startDate
                endDate
                position {
                    w
                    h
                    x
                    y
                }
                cycle
                keywords
                tForm
                categories
                alarming {
                    mail
                    eventType
                    value
                    alarmActive
                }
                persons {
                    userId
                    name
                    id
                }
            }
        }
    }
`

export const queryGetRuleData = gql`
    query ent($auth: AuthInput) {
        getRuleData(input: $auth) {
            ruledata {
                created
                group
                id
                name
                usage
            }
        }
    }
`;


export const queryGetUser = gql`
    query ent ($auth:AuthInput){
        getUserData(input:$auth)
        {
            userdata
            {
                name
                username
                id
                userId
                members
                addOnCompliance
                addOnQualityManagement
            }

        }
    }
`;

export const queryGetRuleRecording = gql`
    query ent ($id : ID!, $auth:AuthInput ){
        getRuleRecording(input:$auth, id: $id)
        {
            id
            name
            tenantId
            group
            text
            time
            audio
            video
            screenOnly
            chat
            audioType
            chatType
            chatTypeDeny
            channelType
            areaCode
            inboundType
            outboundType
            meetingType
            meetingExternalType
            meetingInternalType
            internalType
            silentRecording
            meetingOrganizer
            meetingParticipant
            meetingSubjectAllowList
            meetingSubjectDenyList
            allowList
            denyList
            useOnBehalfOfUserSetting
            switchInitialState
            disableNextRecording
            userStreamOnly
            demandStartOnly
            pstnAnnouncement
            ascBeeptone
            ascBeeptoneFrequency
            announcementType
            announcements
            start
            stop
            isDtmfEnabled
            isDtmfAnnouncementSuppressionEnabled
            announcementSuppressionCode
            announcementSuppressionTimeInDays
            recReevaluateChangeFromOneToOneToMeeting
            channelList
            roDStopReasons
            roDStopReasonRequired
            recordingTransferorAllowList
            recordingTransferorDenyList
            keepDeleteSettings{
            type
            startMode
            hasWrapUp
            wrapUpTimer
            isWrapUpOnly
            recordingMode
            actions
            hasPopupAlert
            isSendToDo
            toDoTimer
            hasUserNotification
            specificSettings
            isActive
            }
        }
    }
`;

export const queryGetRuleReplay = gql`
    query ent ($id : ID!, $auth:AuthInput ){
        getRuleReplay(input:$auth, id: $id)
        {
            id
            name
            tenantId
            group
            text
            replay
            visibility
            visibilityUser
            replayUser
            visibilityDetails
            visibilityDetailsTranscript
            visibilityDetailsAnalytics
            visibilityDetailsAnalyticsChange
            visibilityDetailsNotes
            visibilityDetailsTTL
            visibilityDetailsCustomFields
            visibilityDetailsMetadata
            visibilityDetailsQM
            replayCycle
            recordingFilter {
               id
               type
               cycle
               startDate
               endDate
               categories
               integrations
               persons
               timeBasedType
               durationFrom
               durationTo
               recordingOwner
               defaultFilter
            }
        }
    }
`;

export const queryGetRuleAccess = gql`
    query ent ($id : ID!, $auth:AuthInput ){
        getRuleAccess(input:$auth, id: $id)
        {
            id
            name
            tenantId
            group
            text
            confListUser
            confAddUser
            confTicket
            confRulesRecording
            confRulesReplay
            confRulesAccess
            confRulesAnalytics
            confMapUser
            confUserAssignment
            confAssignRecording 
            confAssignReplay
            confAssignAccess
            confAssignAnalytics 
            confAuditLogs
            confJobs
            confCustomFields
            confAdministrateCustomFields
            confDefineActionOnCustomFields
            hasCustomFieldsAccess
            customFieldsAccessType
            customFieldsEditableTime
            accesibleCustomFields
            cfDisableTagging
            userReporting
            userKpiTotal
            userKpiIncoming
            userKpiOutgoing
            userKpiKeywords
            userKpiWallboard
            userSafe
            userSafeAdd
            userSafeExport
            userSafeShare
            ownFolder{
                addConversations
                removeConversations
                upserFolder
                userIds 
                exportFolders
                deleteFolders
                shareFolders
            }
            userDelete
            userPropose
            userProposeReasoning
            userProposeUsers
            userSafeUsers
            userAssignmentUsers 
            userLiveView
            userDenyExport
            userDenyUpload
            userBulkExport
            userBulkRestoreArchive
            userBulkSafe
            userBulkSafeAdd
            userBulkSafeRemove
            userBulkDelete
            userBulkTranscript
            userBulkExcel
            userBulkIpRange
            userBulkPasswordLength
            complianceResultsAccess
            complianceQMResultsAccess
            userCompliancePolicy
            userCompliancePolicyActivate
            userCompliancePolicyInitiateWorkflow
            userCompliancePolicyEdit
            userCompliancePolicyAssign
            userComplianceAIQuestions
            userComplianceQM
            denyReportAlarming
            archivedConversationAccessType
            archivedRequestRetrievalReasoningRequired
            approvedUsersReplay
            editableTimeCustomFields
            allowTTLChangeViaCategorization
            suspensionConvDelProcess
            accMergeAudioToVideo
        }
    }
`;

export const queryGetRuleAnalytics = gql`
    query ent ($id : ID!, $auth:AuthInput ){
        getRuleAnalytics(input:$auth, id: $id)
        {
            id
            name
            tenantId
            group
            text
            accessType
            startTime
            endTime
            withTranscription
            withVideoIndexer
            transcriptLanguage
            withSentiment
            keywordCategories
            entityCategories                      
            keyphraseExtraction 
            translationType
            userVisible
            onDemandSpeech2Text 
            onDemandKeywords
            onDemandEntities
            individualKeywords
            translationLanguage 
            keywordList
            alternativeLanguage
            optionAlternativeLanguageKey
            multilingualConversations
            analyzeAllConditions
            hasSpeakerRecognition
       }
    }
`;

export const queryGetNewsList = gql`
                          query ent ($id: String, $auth:AuthInput) {
                            getNewsList(id: $id,input:$auth) {
                                id
                                date
                                time
                                title
                                priority
                                text
                            }
                          }
                        `;

export const queryGetEmailList = gql`
                          query ent ($id: String, $tenantId: String, $auth:AuthInput) {
                            getEmailList(id: $id,tenantId: $tenantId, input:$auth) {
                                id
                                created
                                createdMs
                                changed
                                subject
                                to
                                from
                                text
                            }
                          }
                        `;

export const mutationDeleteNewsEntry = gql` mutation ent ($id : String!) { deleteNewsEntry (id: $id) }`;

export const mutationCreateNewsEntry = gql`
                          mutation ent ($news: NewsInputType){
                            createNewsEntry(news: $news)
                              }
                            `;



export const queryGetAudit = gql`
    query ent ($auth:AuthInput){
        getAuditData(input:$auth)
        {
            id
            name
            key
            group
        }
    }
`;

export const queryGetTickets = gql`
                          query ent ($auth:AuthInput, $tenantId:String, $asPartner: Boolean){
                            getTickets(input:$auth, tenantId: $tenantId, asPartner: $asPartner )
                                    {
            title
            userId
            priority
            created
            createdMs
            tenantId
            caseId
            changed
            changedMs
            description
            id
            state
                                  }
                              }
                            `;

export const queryGetTenantList = gql`
                          query ent ($auth:AuthInput){
                            getTenantList(input:$auth)
                                    {
                                        tenantId
                                        name
                                        id
                                        adminId
                                        storage
                                        customStorage
                                        packageStorage
                                        packageAnalyticsVideo
                                        packageAnalyticsSpeech
                                        packageAnalyticsTranscript
                                        packageAnalyticsTranslate
                                        packageAnalyticsAI
                                        analyticsVideoCapacity
                                        analyticsVideoCapacityUsed
                                        analyticsSpeechCapacity
                                        analyticsSpeechCapacityUsed
                                        analyticsTranscriptCapacity
                                        analyticsTranscriptCapacityUsed
                                        analyticsTranslateCapacity
                                        analyticsTranslateCapacityUsed
                                        analyticsAICapacity
                                        analyticsAICapacityUsed
                                        addOnAnalytics
                                        addOnAnalyticsUsed
                                        addOnFullChat
                                        addOnFullChatUsed
                                        addOnZoom
                                        addOnZoomUsed
                                        addOnGenesys
                                        addOnGenesysUsed
                                        addOnRingcentral
                                        addOnRingcentralUsed
                                        addOnDynamics
                                        addOnDynamicsUsed
                                        addOnCompliance
                                        addOnComplianceUsed
                                        addOnQualityManagement
                                        addOnQualityManagementUsed
                                        addOnLeapXpert
                                        addOnLeapXpertUsed
                                        packageExport
                                        packageImport
                                        domainRestrictionsList
                                        mailAlarming
                                        storageContent
                                        contractNumber
                                        storageMax
                                        exportType
                                        exportStorage
                                        exportSizeUsed
                                        importSizeUsed
                                        reseller
                                        type
                                        country
                                        partner
                                        partnerContract
                                        support
                                        bot01
                                        bot02
                                        bot03
                                        bot04
                                        bot05
                                        bot06
                                        bot07
                                        bot08
                                        bot09
                                        bot10
                                        bot11
                                        bot12
                                        bot13
                                        bot14
                                        bot15
                                        bot16
                                        bot17
                                        bot18
                                        bot19
                                        bot20
                                        onboardingDate
                                        billingDate
                                        trainingDate
                                        supportNotes
                                        systemId
                                        tenantSuspensionConvDelProcess
                                        awsAccessKeyId
                                        awsSecretAccess
                                        awsBucketName
                                        awsRegion
                                        awsServiceUrl
                                        awsType
                                        archiveSizeUsed
                                        packageArchive
                                        licenseComp {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseCompAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseSmart {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseSmartAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseCompVoice {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseCompVoiceAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseSmartVoice {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseSmartVoiceAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseRecordingInsinghtsAI {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        keyManagementType
                                        keyManagement {
                                           rotationType
                                           intervalDays
                                           activationDelay
                                           willExpire
                                           expireDays
                                           keySecurity
                                           customEncryptionKeyVaultUrl
                                           customEncryptionKeySecretName
                                            asymmetricKeySettings {
                                                keyType
                                                keySetting
                                                asymmetricKeyFileName
                                            }
                                        asymmetricKeyEnabled
                                        }
                                        keyManagementEnabled
                                        chatModelA 
                                        audioStorage {
                                            id
                                            tenantId
                                            convertToMP3
                                            convertBlobOlderThanDays
                                            archiveBlobs
                                            archiveBlobOlderThanDays
                                            archiveDestinationAudioFormat
                                        }
                                      
                                    }
                              }
                            `;

export const queryGetTenantData = gql`
                          query ent ($auth:AuthInput){
                            getTenantData(input:$auth)
                                    {
                                        tenantId
                                        name
                                        id
                                        adminId
                                        storage
                                        customStorage
                                        packageStorage
                                        packageAnalyticsVideo
                                        packageAnalyticsSpeech
                                        packageAnalyticsTranscript
                                        packageAnalyticsTranslate
                                        packageAnalyticsAI
                                        analyticsVideoCapacity
                                        analyticsVideoCapacityUsed
                                        analyticsSpeechCapacity
                                        analyticsSpeechCapacityUsed
                                        analyticsTranscriptCapacity
                                        analyticsTranscriptCapacityUsed
                                        analyticsTranslateCapacity
                                        analyticsTranslateCapacityUsed
                                        analyticsAICapacity
                                        analyticsAICapacityUsed
                                        addOnAnalytics
                                        addOnAnalyticsUsed
                                        addOnFullChat
                                        addOnFullChatUsed
                                        addOnZoom
                                        addOnZoomUsed
                                        addOnGenesys
                                        addOnGenesysUsed
                                        addOnRingcentral
                                        addOnRingcentralUsed
                                        addOnDynamics
                                        addOnDynamicsUsed
                                        addOnCompliance
                                        addOnComplianceUsed
                                        addOnQualityManagement
                                        addOnQualityManagementUsed
                                        addOnLeapXpert
                                        addOnLeapXpertUsed
                                        packageExport
                                        packageImport
                                        domainRestrictionsList
                                        mailAlarming
                                        storageContent
                                        contractNumber
                                        storageMax
                                        exportType
                                        exportStorage
                                        exportSizeUsed
                                        importSizeUsed
                                        reseller
                                        type
                                        country
                                        partner
                                        partnerContract
                                        support
                                        bot01
                                        bot02
                                        bot03
                                        bot04
                                        bot05
                                        bot06
                                        bot07
                                        bot08
                                        bot09
                                        bot10
                                        bot11
                                        bot12
                                        bot13
                                        bot14
                                        bot15
                                        bot16
                                        bot17
                                        bot18
                                        bot19
                                        bot20
                                        onboardingDate
                                        billingDate
                                        trainingDate
                                        supportNotes
                                        systemId
                                        tenantSuspensionConvDelProcess
                                        awsAccessKeyId
                                        awsSecretAccess
                                        awsBucketName
                                        awsRegion
                                        awsServiceUrl
                                        awsType
                                        archiveSizeUsed
                                        packageArchive
                                        licenseComp {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseCompAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseSmart {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseSmartAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseCompVoice {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseCompVoiceAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseSmartVoice {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseSmartVoiceAna {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        licenseRecordingInsinghtsAI {
                                            bought
                                            used
                                            id
                                            type {
                                                id
                                            }
                                        }
                                        keyManagementType
                                        keyManagement {
                                           rotationType
                                           intervalDays
                                           activationDelay
                                           willExpire
                                           expireDays
                                           keySecurity
                                           customEncryptionKeyVaultUrl
                                           customEncryptionKeySecretName
                                            asymmetricKeySettings {
                                                keyType
                                                keySetting
                                                asymmetricKeyFileName
                                            }
                                        asymmetricKeyEnabled
                                        }
                                        keyManagementEnabled
                                        chatModelA
                                        audioStorage {
                                            id
                                            tenantId
                                            convertToMP3
                                            convertBlobOlderThanDays
                                            archiveBlobs
                                            archiveBlobOlderThanDays
                                            archiveDestinationAudioFormat
                                        }
                                      
                                    }
                              }
                            `;


export const mutationCreateTenant = gql`
                          mutation ent ($auth:AuthInput, $tenant:TenantInputType){
                            createTenant(input:$auth, tenant: $tenant)
                                    {
                                        tenantId
                                        name
                                        id
                                        adminId
                                    }
                              }
                            `;

export const mutationUpdateTenant = gql`
                          mutation ent ($auth:AuthInput, $tenant:TenantInputType){
                            updateTenant(input:$auth, tenant: $tenant)
                                    {
                                        tenantId
                                        name
                                        id
                                        adminId
                                    }
                              }
                            `;

export const mutationCreateTicket = gql`
                          mutation ent ($ticket:ServiceTicketInputType){
                            createTicket(ticket: $ticket)
                              }
                            `;

export const mutationCreateTicketComment = gql`
                          mutation ent ($ticket:ServiceTicketInputType, $comment: String){
                            createTicketComment(ticket: $ticket, comment: $comment)
                              }
                            `;

export const mutationUpdateTicket = gql`
                          mutation ent ($ticket:ServiceTicketInputType){
                            updateTicket(ticket: $ticket)
                          }
                            `;

export const mutationUpdateAudioStorage = gql`
                          mutation ent ($auth:AuthInput, $audioStorage:AudioStorageInputType){
                            updateAudioStorage(input:$auth, audioStorage: $audioStorage)
                                    {
                                        tenantId
                                        id
                                    }
                              }
                            `;

export const mutationWriteAuditEvent = gql`
                          mutation ent ($key:String, $id:String, $text: String){
                            writeAuditEvent(auditEvent: {key: $key, conversationId: $id, text: $text})
                          }
                            `;


export const mutationDeleteBot = gql`
                         mutation ent ($auth:AuthInput, $id:String){
                            deleteBot(input:$auth, id: $id)
                                    {
                                          id
                                    }
                              }
                            `;

export const mutationDeleteGroup = gql`
                         mutation ent ($auth:AuthInput, $id:String){
                            deleteGroup(input:$auth, id: $id)
                                    {
                                          id
                                    }
                              }
                            `;

export const mutationDeleteUser = gql`
                         mutation ent ($auth:AuthInput, $id:[String]){
                            deleteUser(input:$auth, id: $id)
                                    {
                                          id
                                          name
                                    }
                              }
                            `;

export const mutationCreateUser = gql`
                         mutation ent ($auth:AuthInput, $user:[UserDataInput]){
                            createUser(input:$auth, user: $user)
                                    {
                                            name
                                            access
                                    }
                              }
                            `;

export const mutationCreateGroup = gql`
                         mutation ent ($auth:AuthInput, $group:[GroupInputType]){
                            createGroup(input:$auth, group: $group)
                                    {
                                            name
                                            access
                                    }
                              }
                            `;

export const mutationBulkImport = gql`
                         mutation ent ($auth:AuthInput, $user:[UserDataInput], $tenantId: String){
                            bulkImport(input:$auth, user: $user, tenantId: $tenantId)
                              }
                            `;

export const deleteTenant = gql`
                         mutation ent ($auth:AuthInput, $tenantId: String){
                            deleteTenant(input:$auth, tenantId: $tenantId)
                                   
                              }
                            `;

export const deleteTenantMetadataQuery = gql`
                         mutation ent ($auth:AuthInput, $tenantId: String, $date: DateTime){
                            deleteTenantRecordings(input:$auth, tenantId: $tenantId, date: $date)
                                   
                              }
                            `;

export const insertMessage = gql`
                         mutation ent ($auth:AuthInput, $message: String){
                            insertMessage(input:$auth, message: $message)
                                   
                              }
                            `;

export const userGroupSync = gql`
                         mutation ent ($auth:AuthInput){
                            userGroupSync(input:$auth)                                   
                              }
                            `;

export const changeEncryptionKey = gql`
                         mutation ent {
                            changeEncryptionKey                                  
                              }
                            `;

export const checkCustomKeyVault = gql`
                         mutation ent ($auth:AuthInput, $customKeyVaultUrl:String, $customSecretName: String, $isAsymmetric:Boolean){
                            checkCustomKeyVault(input:$auth, customKeyVaultUrl:$customKeyVaultUrl, customSecretName: $customSecretName, isAsymmetric:$isAsymmetric)
                                   
                              }
                            `;
export const checkMaxRetentionTimeSettings = gql`
                         mutation ent ($auth:AuthInput, $tenantId: String){
                            checkMaxRetentionTimeSettings(input:$auth, tenantId: $tenantId)
                                   
                              }
                            `;
export const createCustomKeySecret = gql`
                         mutation ent ($auth:AuthInput, $customKeyVaultUrl:String, $customSecretName: String){
                            createCustomKeySecret(input:$auth, customKeyVaultUrl:$customKeyVaultUrl, customSecretName: $customSecretName)
                                   
                              }
                            `;
export const mutationCreateCategory = gql`
                         mutation ent ($auth:AuthInput, $category:CategoryInput){
                            createCategory(input:$auth, category: $category)
                                    {
                                          name
                                    }
                              }
                            `;

export const mutationCreateCategoryTemplate = gql`
                         mutation ent ($auth:AuthInput, $category:CategoryInput){
                            createCategoryTemplate(input:$auth, category: $category)
                                    {
                                          name
                                    }
                              }
                            `;

export const mutationUpdateCategory = gql`
                         mutation ent ($auth:AuthInput, $category:CategoryInput){
                            updateCategory(input:$auth, category: $category)
                                    {
                                          name
                                          id
                                    }
                              }
                            `;

export const mutationUpdateCategoryTemplate = gql`
                         mutation ent ($auth:AuthInput, $category:CategoryInput){
                            updateCategoryTemplate(input:$auth, category: $category)
                                    {
                                          name
                                          id
                                    }
                              }
                            `;


export const queryGetAllUsers = gql`
                         query ent ($auth:AuthInput, $filter:String){
                            getAllUsers(input:$auth,filter:$filter)
                                    {
                                          access
                                          department
                                          name
                                          recordingRules
                                          replay
                                          analytics
                                          username
                                          userId
                                     }
                              }
                            `;

export const queryGetAllGroups = gql`
                         query ent ($auth:AuthInput, $filter:String){
                            getAllGroups(input:$auth,filter:$filter)
                                    {
                                          access
                                          name
                                          recordingRules
                                          replay
                                          analytics
                                          adGroupId
                                     }
                              }
                            `;

export const mutationUpdateUser = gql`
                         mutation ent ($auth:AuthInput, $user: [UserDataInput]){
                            updateUser(input:$auth, user: $user)
                                    {
                                          name
                                    }
                              }
                            `;

export const mutationResetUser = gql`
                         mutation ent ($auth:AuthInput, $user: [UserDataInput]){
                            resetUser(input:$auth, user: $user)
                                    {
                                          name
                                    }
                              }
                            `;

export const mutationUpdateGroup = gql`
                         mutation ent ($auth:AuthInput, $group: [GroupInputType]){
                            updateGroup(input:$auth, group: $group)
                                    {
                                          name
                                    }
                              }
                            `;

export const mutationUpdateBot = gql`
                         mutation ent ($auth:AuthInput, $bot: BotInputType){
                            updateBot(input:$auth, bot: $bot)
                                    {
                                          name
                                    }
                              }
                            `;

export const mutationCreateRule = gql`
                         mutation ent ($auth:AuthInput, $name:String, $group:String, $visibility:String, $replay:String, $time:String, $text:String){
                            createRule(input:$auth, rule: {name: $name, group: $group, text: $text, replay: $replay, visibility: $visibility, time: $time})
                                    {
                                          name
                                     }
                              }
                            `;

export const mutationDeleteRuleAccess = gql`
                         mutation ent ($id:String){
                            deleteRuleAccess(id: $id)
                                    {
                                          name
                                     }
                              }
                            `;
export const mutationDeleteRuleRecording = gql`
                         mutation ent ($id:String){
                            deleteRuleRecording(id: $id)
                                    {
                                          name
                                     }
                              }
                            `;
export const mutationDeleteRuleReplay = gql`
                         mutation ent ($id:String){
                            deleteRuleReplay(id: $id)
                                    {
                                          name
                                     }
                              }
                            `;
export const mutationDeleteRuleAnalytics = gql`
                         mutation ent ($id:String){
                            deleteRuleAnalytics(id: $id)
                                    {
                                          name
                                     }
                              }
                            `;

export const mutationCreateRuleRecording = gql`
                         mutation ent ($auth:AuthInput, $name:String, $group:String, $time:String, $text:String, $audio:Boolean, $video: Boolean, $screenOnly: Boolean, $chat: Boolean, $audioType: String, $chatType: String, $chatTypeDeny: Boolean, $channelType: String, $areaCode: String, $inboundType: String, $outboundType: String, $meetingType: String, $meetingExternalType: String, $meetingInternalType: String, $internalType: String, $silentRecording: Boolean, $allowList: String, $denyList: String, $meetingOrganizer: String, $meetingParticipant: String, $useOnBehalfOfUserSetting: Boolean, $switchInitialState: Boolean,$disableNextRecording: Boolean, $userStreamOnly: Boolean, $pstnAnnouncement: Boolean, $ascBeeptone:Boolean, $ascBeeptoneFrequency:Int, $announcementType:String, $announcements: String, $deletedFilesFromAnnouncements:[String], $start:String, $stop:String, $isDtmfEnabled: Boolean, $isDtmfAnnouncementSuppressionEnabled:Boolean, $announcementSuppressionCode:String, $announcementSuppressionTimeInDays:Int,
                         $recReevaluateChangeFromOneToOneToMeeting: Boolean, $channelList: String, $demandStartOnly: Boolean,$roDStopReasons:String, $roDStopReasonRequired:Boolean,$keepDeleteSettings:[RecordingKeepDeleteSettingInputType],$recordingTransferorAllowList:String, $recordingTransferorDenyList:String, $meetingSubjectAllowList:String, $meetingSubjectDenyList:String){
                            createRuleRecording(input:$auth, rule: {name: $name, group: $group, text: $text, time: $time, audio: $audio, video: $video, screenOnly: $screenOnly, chat: $chat, audioType: $audioType, chatType: $chatType, chatTypeDeny: $chatTypeDeny, channelType: $channelType, areaCode: $areaCode, inboundType: $inboundType, outboundType: $outboundType, meetingType: $meetingType, meetingExternalType: $meetingExternalType, meetingInternalType: $meetingInternalType, internalType: $internalType, silentRecording: $silentRecording, allowList: $allowList, denyList: $denyList, meetingOrganizer: $meetingOrganizer, meetingParticipant: $meetingParticipant, useOnBehalfOfUserSetting:$useOnBehalfOfUserSetting, switchInitialState: $switchInitialState, disableNextRecording: $disableNextRecording, userStreamOnly: $userStreamOnly, pstnAnnouncement: $pstnAnnouncement, ascBeeptone:$ascBeeptone, ascBeeptoneFrequency:$ascBeeptoneFrequency,announcementType: $announcementType, announcements:$announcements, deletedFilesFromAnnouncements:$deletedFilesFromAnnouncements, start:$start, stop:$stop, isDtmfEnabled:$isDtmfEnabled, isDtmfAnnouncementSuppressionEnabled:$isDtmfAnnouncementSuppressionEnabled, announcementSuppressionCode:$announcementSuppressionCode, announcementSuppressionTimeInDays:$announcementSuppressionTimeInDays, recReevaluateChangeFromOneToOneToMeeting:$recReevaluateChangeFromOneToOneToMeeting, channelList: $channelList, demandStartOnly: $demandStartOnly,roDStopReasons:$roDStopReasons, roDStopReasonRequired:$roDStopReasonRequired,keepDeleteSettings:$keepDeleteSettings, recordingTransferorAllowList:$recordingTransferorAllowList, recordingTransferorDenyList:$recordingTransferorDenyList, meetingSubjectAllowList:$meetingSubjectAllowList, meetingSubjectDenyList:$meetingSubjectDenyList})

                                    {
                                          name
                                     }
                              }
                            `;
export const mutationCreateRuleReplay = gql`
                         mutation ent ($auth:AuthInput, 
                            $name:String, 
                            $group:String, 
                            $visibility:String, 
                            $replay:String,  
                            $text:String, 
                            $visibilityUser:String, 
                            $replayUser:String, 
                            $replayCycle:String, 
                            $recordingFilter:RecordingFilterInputType,
                            $visibilityDetails: Boolean,
                            $visibilityDetailsTranscript: Boolean,
                            $visibilityDetailsAnalytics: Boolean,
                            $visibilityDetailsAnalyticsChange: Boolean,
                            $visibilityDetailsNotes: Boolean,
                            $visibilityDetailsTTL: Boolean,
                            $visibilityDetailsCustomFields: Boolean,
                            $visibilityDetailsMetadata: Boolean,
                            $visibilityDetailsQM: Boolean
                        ){
                            createRuleReplay(input:$auth, rule: {
                                name: $name, 
                                group: $group, 
                                text: $text, 
                                replay: $replay, 
                                visibility: $visibility, 
                                visibilityUser: $visibilityUser, 
                                replayUser: $replayUser, 
                                replayCycle: $replayCycle, 
                                recordingFilter: $recordingFilter,
                                visibilityDetails: $visibilityDetails,
                                visibilityDetailsTranscript: $visibilityDetailsTranscript,
                                visibilityDetailsAnalytics: $visibilityDetailsAnalytics,
                                visibilityDetailsAnalyticsChange: $visibilityDetailsAnalyticsChange,
                                visibilityDetailsNotes: $visibilityDetailsNotes,
                                visibilityDetailsTTL: $visibilityDetailsTTL,
                                visibilityDetailsCustomFields: $visibilityDetailsCustomFields,
                                visibilityDetailsMetadata: $visibilityDetailsMetadata,
                                visibilityDetailsQM: $visibilityDetailsQM
                             })
                                    {
                                          name
                                     }
                              }
                            `;
export const mutationCreateRuleAccess = gql`
                         mutation createRuleAccess ($auth:AuthInput, $name:String, $group:String, $text:String,
                            $confListUser: Boolean,
                            $confAddUser: Boolean,
                            $confTicket: Boolean,
                            $confRulesRecording: Boolean,
                            $confRulesReplay: Boolean,
                            $confRulesAccess: Boolean,
                            $confRulesAnalytics: Boolean,
                            $confMapUser: Boolean,
                            $confUserAssignment: String,
                            $confAssignRecording: Boolean,
                            $confAssignReplay: Boolean,
                            $confAssignAccess: Boolean,
                            $confAssignAnalytics: Boolean,
                            $confAuditLogs: Boolean,
                            $confJobs: Boolean,
                            $confCustomFields: Boolean,
                            $confAdministrateCustomFields: Boolean,
                            $confDefineActionOnCustomFields: Boolean,
                            $hasCustomFieldsAccess: Boolean,
                            $customFieldsAccessType:String,
                            $customFieldsEditableTime: Int,
                            $accesibleCustomFields: [String],
                            $cfDisableTagging: Boolean,
                            $userReporting: String,
                            $userKpiTotal: Boolean,
                            $userKpiIncoming: Boolean,
                            $userKpiOutgoing: Boolean,
                            $userKpiKeywords: Boolean,
                            $userKpiWallboard: Boolean,
                            $userSafe: String,
                            $ownFolder: ConversationSafeRulesInputType,
                            $userDelete: String,
                            $userPropose: Boolean,
                            $userProposeReasoning: Boolean 
                            $userProposeUsers: String,
                            $userSafeUsers: String,
                            $userAssignmentUsers: String,
                            $userLiveView: Boolean,
                            $userDenyExport: Boolean,
                            $userDenyUpload: Boolean,
                            $userBulkExport: Boolean,
                            $userBulkRestoreArchive: Boolean,
                            $userBulkSafe: Boolean,
                            $userBulkSafeAdd: Boolean,
                            $userBulkSafeRemove: Boolean,
                            $userBulkDelete: Boolean,
                            $userBulkTranscript: Boolean,
                            $userBulkExcel: Boolean,
                            $userBulkIpRange: String,
                            $userBulkPasswordLength: String,
                            $complianceResultsAccess: Boolean,
                            $complianceQMResultsAccess: Boolean,
                            $userCompliancePolicy: Boolean,
                            $userCompliancePolicyActivate: Boolean,
                            $userCompliancePolicyEdit: Boolean,
                            $userCompliancePolicyAssign: Boolean,
                            $userComplianceAIQuestions: Boolean,
                            $userComplianceQM: Boolean,
                            $denyReportAlarming: Boolean,
                            $archivedConversationAccessType: String,
                            $archivedRequestRetrievalReasoningRequired: Boolean,
                            $approvedUsersReplay: String,
                            $editableTimeCustomFields: Boolean,
                            $allowTTLChangeViaCategorization: Boolean,
                            $suspensionConvDelProcess:Boolean,
                            $accMergeAudioToVideo:Boolean
                        ){

                            
                          createRuleAccess(input:$auth, rule: {name: $name, group: $group, text: $text,
                            confListUser: $confListUser,
                            confAddUser: $confAddUser,
                            confTicket: $confTicket,
                            confRulesRecording: $confRulesRecording,
                            confRulesReplay: $confRulesReplay,
                            confRulesAccess: $confRulesAccess,
                            confRulesAnalytics: $confRulesAnalytics,
                            confMapUser: $confMapUser,
                            confUserAssignment: $confUserAssignment,
                            confAssignRecording: $confAssignRecording,
                            confAssignReplay: $confAssignReplay,
                            confAssignAccess: $confAssignAccess,
                            confAssignAnalytics: $confAssignAnalytics,
                            confAuditLogs: $confAuditLogs,
                            confJobs: $confJobs,
                            confCustomFields: $confCustomFields,
                            confAdministrateCustomFields: $confAdministrateCustomFields,
                            confDefineActionOnCustomFields: $confDefineActionOnCustomFields,
                            hasCustomFieldsAccess: $hasCustomFieldsAccess,
                            customFieldsAccessType:$customFieldsAccessType,
                            customFieldsEditableTime: $customFieldsEditableTime,
                            accesibleCustomFields: $accesibleCustomFields,
                            cfDisableTagging: $cfDisableTagging,
                            userReporting: $userReporting,
                            userKpiTotal: $userKpiTotal,
                            userKpiIncoming: $userKpiIncoming,
                            userKpiOutgoing: $userKpiOutgoing,
                            userKpiKeywords: $userKpiKeywords,
                            userKpiWallboard: $userKpiWallboard,
                            userSafe: $userSafe,
                            ownFolder: $ownFolder,
                            userDelete: $userDelete,
                            userPropose: $userPropose,
                            userProposeReasoning: $userProposeReasoning
                            userProposeUsers: $userProposeUsers,
                            userSafeUsers: $userSafeUsers,
                            userAssignmentUsers: $userAssignmentUsers,
                            userLiveView: $userLiveView,
                            userDenyExport: $userDenyExport,
                            userDenyUpload: $userDenyUpload
                            userBulkExport: $userBulkExport,
                            userBulkRestoreArchive: $userBulkRestoreArchive,
                            userBulkSafe: $userBulkSafe,
                            userBulkSafeAdd: $userBulkSafeAdd,
                            userBulkSafeRemove: $userBulkSafeRemove,
                            userBulkDelete: $userBulkDelete,
                            userBulkTranscript: $userBulkTranscript,
                            userBulkExcel: $userBulkExcel,
                            userBulkIpRange: $userBulkIpRange,
                            userBulkPasswordLength: $userBulkPasswordLength,
                            complianceResultsAccess: $complianceResultsAccess,
                            complianceQMResultsAccess: $complianceQMResultsAccess,
                            userCompliancePolicy: $userCompliancePolicy,
                            userCompliancePolicyActivate: $userCompliancePolicyActivate,
                            userCompliancePolicyEdit: $userCompliancePolicyEdit,
                            userCompliancePolicyAssign: $userCompliancePolicyAssign,
                            userComplianceAIQuestions: $userComplianceAIQuestions,
                            userComplianceQM: $userComplianceQM,
                            denyReportAlarming: $denyReportAlarming,
                            archivedConversationAccessType: $archivedConversationAccessType,
                            archivedRequestRetrievalReasoningRequired: $archivedRequestRetrievalReasoningRequired,
                            approvedUsersReplay: $approvedUsersReplay,
                            editableTimeCustomFields: $editableTimeCustomFields,
                            allowTTLChangeViaCategorization: $allowTTLChangeViaCategorization,
                            suspensionConvDelProcess:$suspensionConvDelProcess,
                            accMergeAudioToVideo:$accMergeAudioToVideo
                          })
                                    {
                                          name
                                     }
                              }
                            `;
export const mutationCreateRuleAnalytics = gql`
                         mutation ent ($auth:AuthInput, $name:String, $group:String, $text:String, 
                            $accessType: String,
                            $startTime: DateTime,
                            $endTime: DateTime,
                            $withTranscription: Boolean,
                            $withVideoIndexer: Boolean,
                            $transcriptLanguage: String,
                            $withSentiment: Boolean,
                            $keywordCategories: String,
                            $entityCategories: String,                            
                            $keyphraseExtraction: Boolean,
                            $translationType: String,
                            $userVisible: String,
                            $onDemandSpeech2Text: Boolean,
                            $onDemandKeywords: Boolean,
                            $onDemandEntities: Boolean,
                            $individualKeywords: Boolean,
                            $translationLanguage: String,
                            $keywordList: String,
                            $alternativeLanguage: Boolean,
                            $optionAlternativeLanguageKey: String,
                            $multilingualConversations: Boolean,
                            $analyzeAllConditions:[String],
                            $hasSpeakerRecognition:Boolean,
                        ){
                            createRuleAnalytics(input:$auth, rule: {name: $name, group: $group, text: $text, 
                            accessType: $accessType,
                            startTime: $startTime,
                            endTime: $endTime,
                            withTranscription: $withTranscription,
                            withVideoIndexer: $withVideoIndexer,
                            transcriptLanguage: $transcriptLanguage,
                            withSentiment: $withSentiment,
                            keywordCategories: $keywordCategories,
                            entityCategories: $entityCategories,                            
                            keyphraseExtraction: $keyphraseExtraction,
                            translationType: $translationType,
                            userVisible: $userVisible,
                            onDemandSpeech2Text: $onDemandSpeech2Text,
                            onDemandKeywords: $onDemandKeywords,
                            onDemandEntities: $onDemandEntities,
                            individualKeywords: $individualKeywords,
                            translationLanguage: $translationLanguage,
                            keywordList: $keywordList
                            alternativeLanguage: $alternativeLanguage,
                            optionAlternativeLanguageKey: $optionAlternativeLanguageKey,
                            multilingualConversations: $multilingualConversations,
                            analyzeAllConditions:$analyzeAllConditions,
                            hasSpeakerRecognition:$hasSpeakerRecognition
                        })
                                    {
                                          name
                                     }
                              }
                            `;

export const mutationUpdateRuleRecording = gql`
                         mutation ent ($id :String, $auth:AuthInput, $name:String, $group:String, $time:String, $text:String, $audio:Boolean, $video: Boolean, $screenOnly: Boolean, $chat: Boolean, $audioType: String, $chatType: String, $chatTypeDeny: Boolean, $channelType: String, $areaCode: String, $inboundType: String, $outboundType: String, $meetingType: String, $meetingExternalType: String, $meetingInternalType: String, $internalType: String, $silentRecording: Boolean, $allowList: String, $denyList: String, $meetingOrganizer: String, $meetingParticipant: String, $useOnBehalfOfUserSetting:Boolean, $switchInitialState: Boolean, $disableNextRecording: Boolean, $userStreamOnly: Boolean, $pstnAnnouncement: Boolean, $ascBeeptone:Boolean, $ascBeeptoneFrequency:Int, $announcementType: String, $announcements: String, $deletedFilesFromAnnouncements:[String], $start:String, $stop:String, $isDtmfEnabled: Boolean, $isDtmfAnnouncementSuppressionEnabled:Boolean, $announcementSuppressionCode:String, $announcementSuppressionTimeInDays:Int, $recReevaluateChangeFromOneToOneToMeeting:Boolean, $channelList: String, $demandStartOnly: Boolean, $roDStopReasons:String, $roDStopReasonRequired:Boolean,$keepDeleteSettings:[RecordingKeepDeleteSettingInputType],$recordingTransferorAllowList:String, $recordingTransferorDenyList:String, $meetingSubjectAllowList:String, $meetingSubjectDenyList:String){
                            updateRuleRecording(input:$auth, rule: {id: $id, name: $name, group: $group, text: $text, time: $time, audio: $audio, video: $video, screenOnly: $screenOnly, chat: $chat, audioType: $audioType, chatType: $chatType, chatTypeDeny: $chatTypeDeny, channelType: $channelType, areaCode: $areaCode, inboundType: $inboundType, outboundType: $outboundType, meetingType: $meetingType, meetingExternalType: $meetingExternalType, meetingInternalType: $meetingInternalType, internalType: $internalType, silentRecording: $silentRecording, allowList: $allowList, denyList: $denyList, meetingOrganizer: $meetingOrganizer, meetingParticipant: $meetingParticipant, useOnBehalfOfUserSetting:$useOnBehalfOfUserSetting, switchInitialState: $switchInitialState, disableNextRecording: $disableNextRecording, userStreamOnly:$userStreamOnly, pstnAnnouncement: $pstnAnnouncement, ascBeeptone:$ascBeeptone, ascBeeptoneFrequency:$ascBeeptoneFrequency,announcementType:$announcementType, announcements:$announcements, deletedFilesFromAnnouncements:$deletedFilesFromAnnouncements, start:$start, stop:$stop, isDtmfEnabled:$isDtmfEnabled, isDtmfAnnouncementSuppressionEnabled:$isDtmfAnnouncementSuppressionEnabled, announcementSuppressionCode:$announcementSuppressionCode, announcementSuppressionTimeInDays:$announcementSuppressionTimeInDays, recReevaluateChangeFromOneToOneToMeeting:$recReevaluateChangeFromOneToOneToMeeting, channelList: $channelList, demandStartOnly: $demandStartOnly,roDStopReasons:$roDStopReasons, roDStopReasonRequired:$roDStopReasonRequired,keepDeleteSettings:$keepDeleteSettings,recordingTransferorAllowList:$recordingTransferorAllowList, recordingTransferorDenyList:$recordingTransferorDenyList, meetingSubjectAllowList:$meetingSubjectAllowList, meetingSubjectDenyList:$meetingSubjectDenyList})
                                    {
                                          name
                                     }
                              }
                            `;
export const mutationUpdateRuleReplay = gql`
                         mutation updateRuleReplay ($id :String, $auth:AuthInput, $name:String, $group:String, $visibility:String, $replay:String,  $text:String, $visibilityUser:String, $replayUser:String, $replayCycle:String, $recordingFilter:RecordingFilterInputType,
                            $visibilityDetails: Boolean,
                            $visibilityDetailsTranscript: Boolean,
                            $visibilityDetailsAnalytics: Boolean,
                            $visibilityDetailsAnalyticsChange: Boolean,
                            $visibilityDetailsNotes: Boolean,
                            $visibilityDetailsTTL: Boolean,
                            $visibilityDetailsCustomFields: Boolean,
                            $visibilityDetailsMetadata: Boolean,
                            $visibilityDetailsQM: Boolean){
                            updateRuleReplay(
                                input:$auth,
                                rule: {
                                    id: $id,
                                    name: $name,
                                    group: $group,
                                    text: $text,
                                    replay: $replay,
                                    visibility: $visibility,
                                    visibilityUser: $visibilityUser,
                                    replayUser: $replayUser,
                                    replayCycle: $replayCycle,
                                    recordingFilter: $recordingFilter,
                                    visibilityDetails: $visibilityDetails,
                                    visibilityDetailsTranscript: $visibilityDetailsTranscript,
                                    visibilityDetailsAnalytics: $visibilityDetailsAnalytics,
                                    visibilityDetailsAnalyticsChange: $visibilityDetailsAnalyticsChange,
                                    visibilityDetailsNotes: $visibilityDetailsNotes,
                                    visibilityDetailsTTL: $visibilityDetailsTTL,
                                    visibilityDetailsCustomFields: $visibilityDetailsCustomFields,
                                    visibilityDetailsMetadata: $visibilityDetailsMetadata,
                                    visibilityDetailsQM: $visibilityDetailsQM
                                })
                                    {
                                          name
                                     }
                              }
                            `;
export const mutationUpdateRuleAccess = gql`
                         mutation updateRuleAccess ($id : String, $auth:AuthInput, $name:String, $group:String, $text:String,
                            $confListUser: Boolean,
                            $confAddUser: Boolean,
                            $confTicket: Boolean,
                            $confRulesRecording: Boolean,
                            $confRulesReplay: Boolean,
                            $confRulesAccess: Boolean,
                            $confRulesAnalytics: Boolean,
                            $confMapUser: Boolean,
                            $confUserAssignment: String,
                            $confAssignRecording: Boolean,
                            $confAssignReplay: Boolean,
                            $confAssignAccess: Boolean,
                            $confAssignAnalytics: Boolean,
                            $confAuditLogs: Boolean,
                            $confJobs: Boolean,
                            $confCustomFields: Boolean,
                            $confAdministrateCustomFields: Boolean,
                            $confDefineActionOnCustomFields: Boolean,
                            $hasCustomFieldsAccess: Boolean,
                            $customFieldsAccessType:String,
                            $customFieldsEditableTime: Int,
                            $accesibleCustomFields: [String],
                            $cfDisableTagging: Boolean,
                            $userReporting: String,
                            $userKpiTotal: Boolean,
                            $userKpiIncoming: Boolean,
                            $userKpiOutgoing: Boolean,
                            $userKpiKeywords: Boolean,
                            $userKpiWallboard: Boolean,
                            $userSafe: String,
                            $ownFolder: ConversationSafeRulesInputType,
                            $userDelete: String,
                            $userPropose: Boolean,
                            $userProposeReasoning: Boolean 
                            $userProposeUsers: String,
                            $userSafeUsers: String,
                            $userAssignmentUsers: String,
                            $userLiveView: Boolean,
                            $userDenyExport: Boolean,
                            $userDenyUpload: Boolean,
                            $userBulkExport: Boolean,
                            $userBulkRestoreArchive: Boolean,
                            $userBulkSafe: Boolean,
                            $userBulkSafeAdd: Boolean,
                            $userBulkSafeRemove: Boolean,
                            $userBulkDelete: Boolean,
                            $userBulkTranscript: Boolean,
                            $userBulkExcel: Boolean,
                            $userBulkIpRange: String,
                            $userBulkPasswordLength: String,
                            $complianceResultsAccess: Boolean,
                            $complianceQMResultsAccess: Boolean,
                            $userCompliancePolicy: Boolean,
                            $userCompliancePolicyActivate: Boolean,
                            $userCompliancePolicyInitiateWorkflow: Boolean,
                            $userCompliancePolicyEdit: Boolean,
                            $userCompliancePolicyAssign: Boolean,
                            $userComplianceAIQuestions: Boolean,
                            $userComplianceQM: Boolean,
                            $denyReportAlarming: Boolean,
                            $archivedConversationAccessType: String,
                            $archivedRequestRetrievalReasoningRequired: Boolean,
                            $approvedUsersReplay: String,
                            $editableTimeCustomFields: Boolean,
                            $allowTTLChangeViaCategorization: Boolean,
                            $suspensionConvDelProcess:Boolean,

                            $accMergeAudioToVideo:Boolean,
                            $shareOptionWasDisabled: Boolean
                        ){

                            
                          updateRuleAccess(input:$auth, rule: {id: $id, name: $name, group: $group, text: $text,
                            confListUser: $confListUser,
                            confAddUser: $confAddUser,
                            confTicket: $confTicket,
                            confRulesRecording: $confRulesRecording,
                            confRulesReplay: $confRulesReplay,
                            confRulesAccess: $confRulesAccess,
                            confRulesAnalytics: $confRulesAnalytics,
                            confMapUser: $confMapUser,
                            confUserAssignment: $confUserAssignment,
                            confAssignRecording: $confAssignRecording,
                            confAssignReplay: $confAssignReplay,
                            confAssignAccess: $confAssignAccess,
                            confAssignAnalytics: $confAssignAnalytics,
                            confAuditLogs: $confAuditLogs,
                            confJobs: $confJobs,
                            confCustomFields: $confCustomFields,
                            confAdministrateCustomFields: $confAdministrateCustomFields,
                            confDefineActionOnCustomFields: $confDefineActionOnCustomFields,
                            hasCustomFieldsAccess: $hasCustomFieldsAccess,
                            customFieldsAccessType:$customFieldsAccessType,
                            customFieldsEditableTime: $customFieldsEditableTime,
                            accesibleCustomFields: $accesibleCustomFields,
                            cfDisableTagging: $cfDisableTagging,
                            userReporting: $userReporting,
                            userKpiTotal: $userKpiTotal,
                            userKpiIncoming: $userKpiIncoming,
                            userKpiOutgoing: $userKpiOutgoing,
                            userKpiKeywords: $userKpiKeywords,
                            userKpiWallboard: $userKpiWallboard,
                            userSafe: $userSafe,
                            ownFolder: $ownFolder,
                            userDelete: $userDelete,
                            userPropose: $userPropose,
                            userProposeReasoning: $userProposeReasoning
                            userProposeUsers: $userProposeUsers,
                            userSafeUsers: $userSafeUsers,
                            userAssignmentUsers: $userAssignmentUsers,
                            userLiveView: $userLiveView,
                            userDenyExport: $userDenyExport,
                            userDenyUpload: $userDenyUpload,
                            userBulkExport: $userBulkExport,
                            userBulkRestoreArchive: $userBulkRestoreArchive,
                            userBulkSafe: $userBulkSafe,
                            userBulkSafeAdd: $userBulkSafeAdd,
                            userBulkSafeRemove: $userBulkSafeRemove,
                            userBulkDelete: $userBulkDelete,
                            userBulkTranscript: $userBulkTranscript,
                            userBulkExcel: $userBulkExcel,
                            userBulkIpRange: $userBulkIpRange,
                            userBulkPasswordLength: $userBulkPasswordLength,
                            complianceResultsAccess: $complianceResultsAccess,
                            complianceQMResultsAccess: $complianceQMResultsAccess,
                            userCompliancePolicy: $userCompliancePolicy,
                            userCompliancePolicyActivate: $userCompliancePolicyActivate,
                            userCompliancePolicyInitiateWorkflow: $userCompliancePolicyInitiateWorkflow,  
                            userCompliancePolicyEdit: $userCompliancePolicyEdit,
                            userCompliancePolicyAssign: $userCompliancePolicyAssign,
                            userComplianceAIQuestions: $userComplianceAIQuestions,
                            userComplianceQM: $userComplianceQM,
                            denyReportAlarming: $denyReportAlarming,
                            archivedConversationAccessType: $archivedConversationAccessType,
                            archivedRequestRetrievalReasoningRequired: $archivedRequestRetrievalReasoningRequired,
                            approvedUsersReplay: $approvedUsersReplay,
                            editableTimeCustomFields: $editableTimeCustomFields,
                            allowTTLChangeViaCategorization: $allowTTLChangeViaCategorization,
                            suspensionConvDelProcess:$suspensionConvDelProcess,
                            accMergeAudioToVideo:$accMergeAudioToVideo
                          },
                              shareOptionWasDisabled : $shareOptionWasDisabled
                          )
                                    {
                                          name
                                     }
                              }
                            `;
export const mutationUpdateRuleAnalytics = gql`
                         mutation ent ($id :String, $auth:AuthInput, $name:String, $group:String, $text:String, 
                            $accessType: String,
                            $startTime: DateTime,
                            $endTime: DateTime,
                            $withTranscription: Boolean,
                            $withVideoIndexer: Boolean,
                            $transcriptLanguage: String,
                            $withSentiment: Boolean,
                            $keywordCategories: String,
                            $entityCategories: String,                            
                            $keyphraseExtraction: Boolean,
                            $translationType: String,
                            $userVisible: String,
                            $onDemandSpeech2Text: Boolean,
                            $onDemandKeywords: Boolean,
                            $onDemandEntities: Boolean,
                            $individualKeywords: Boolean,
                            $translationLanguage: String,
                            $keywordList: String,
                            $alternativeLanguage: Boolean,
                            $optionAlternativeLanguageKey: String,
                            $multilingualConversations: Boolean,
                            $analyzeAllConditions:[String],
                            $hasSpeakerRecognition:Boolean,

                        ){
                            updateRuleAnalytics(input:$auth, rule: {id: $id, name: $name, group: $group, text: $text, 
                            accessType: $accessType,
                            startTime: $startTime,
                            endTime: $endTime,
                            withTranscription: $withTranscription,
                            withVideoIndexer: $withVideoIndexer,
                            transcriptLanguage: $transcriptLanguage,
                            withSentiment: $withSentiment,
                            keywordCategories: $keywordCategories,
                            entityCategories: $entityCategories,                            
                            keyphraseExtraction: $keyphraseExtraction,
                            translationType: $translationType,
                            userVisible: $userVisible,
                            onDemandSpeech2Text: $onDemandSpeech2Text,
                            onDemandKeywords: $onDemandKeywords,
                            onDemandEntities: $onDemandEntities,
                            individualKeywords: $individualKeywords,
                            translationLanguage: $translationLanguage,
                            keywordList: $keywordList,
                            alternativeLanguage: $alternativeLanguage,
                            optionAlternativeLanguageKey: $optionAlternativeLanguageKey,
                            multilingualConversations: $multilingualConversations,
                            analyzeAllConditions:$analyzeAllConditions,
                            hasSpeakerRecognition:$hasSpeakerRecognition,
                        })
                                    {
                                          name
                                     }
                              }
                            `;

export const queryGetAWSRegions = gql`
    query ent ($auth:AuthInput){
        getAWSRegions(input:$auth)
        {
            displayName
            code
        }
    }
`;

export const getCognitiveServicesLocalesTranscription = gql`
    query ent ($auth:AuthInput){
        getTranscriptionLocales(input:$auth)
            
        }
        `;
export const getCognitiveServicesLocalesTranslation = gql`
    query ent ($auth:AuthInput){
        getTranslatorLocales(input:$auth)
            
        }
        `;




export const mutationUpdateCustomField = gql`
                         mutation ent ($auth:AuthInput, $customField:CustomFieldsInput){
                            updateCustomField(input:$auth, customField: $customField)
                                    {
                                          fieldName
                                          id
                                    }
                              }
                            `;

export const mutationCreateCustomField = gql`
                         mutation ent ($auth:AuthInput, $customField:CustomFieldsInput){
                            createCustomField(input:$auth, customField: $customField)
                                    {
                                          fieldName
                                    }
                              }
                            `;

export const getAllCustomFields = gql`
                          query ent ($auth:AuthInput){
                            getAllCustomFieldDefinitions(input:$auth)
                                    {
                                              tenantId
                                              fieldName
                                              isNumeric
                                              isAlphanumeric
                                              maxNumberOfFields
                                              fieldLength
                                              hasExactLength
                                              isCheckbox
                                              id
                                              hasTriggerAction
                                              hasAdditionalNumberCheck
                                              actionType
                                              ttlSettingYes
                                              ttlSettingNo
                                              notifications
                                              mapping
                                    }
                              }
                            `;

export const getAllNameCustomFields = gql`
                          query ent ($auth:AuthInput){
                            getAllCustomFieldDefinitions(input:$auth) {
                                    id
                                    fieldName
                            }
                          }
                      `;

export const updateCallCustomField = gql`
                mutation ent ($auth:AuthInput, $conversationId : String!, $customFields: String) {
                    updateCallCustomField (input:$auth, conversationId: $conversationId, customFields: $customFields)
                }
                `

export const getAllCustomFieldDefinitions = gql`
                          query ent ($auth:AuthInput){
                            getAllCustomFieldDefinitions(input:$auth) {
                                 id
                                 fieldName
                            }
                          }
                      `;


export const mutationCheckCustomFieldIsUsed = gql`
                         mutation ent ($auth:AuthInput, $customFieldId:String){
                            checkCustomFieldIsUsed(input:$auth, customFieldId:$customFieldId)
                                   
                              }
                            `;
export const mutationDeleteCustomField = gql`
                         mutation ent ($auth:AuthInput, $customFieldId:String){
                            deleteCustomField(input:$auth,customFieldId: $customFieldId)
                                
                              }
                            `;

export const mutationSaveListViewSettings = gql`
                         mutation ent ($listViewSettings:[String]){
                            saveListViewSettings(listViewSettings: $listViewSettings){
                                            text
                                            status
                                            id
                                }

                              }
                            `;

export const mutationSaveListTenantSettings = gql`
                         mutation ent ($listTenantSettings:[String]){
                            saveListTenantSettings(listTenantSettings: $listTenantSettings){
                                            text
                                            status
                                            id
                                }

                              }
                            `;


export const queryGetLoggedUserData = gql`
                          query ent ($auth:AuthInput){
                            getLoggedUserData(input:$auth)
                                    {
                                        userId
                                       listViewSettings
                                       listTenantSettings
                                    }
                              }
                            `;

export const mutationMarkDeleteExportRule = gql`
                         mutation ent ($auth:AuthInput, $rule:ExportRuleInputType){
                            markDeleteExportRule(input:$auth, rule:$rule)
                              }
                            `;
export const mutationMarkDeleteImportRule = gql`
                         mutation ent ($auth:AuthInput,$rule:ImportRuleInputType){
                            markDeleteImportRule(input:$auth,rule:$rule)
                              }
                            `;
export const checkRetentionTimeValidity = gql`
                         mutation ent ($auth:AuthInput, $ttl: Int){
                            checkRetentionTimeValidity(input:$auth, ttl: $ttl)
                                   
                              }
                            `;
export const checkS3StorageConnection = gql`
                         mutation ent ($auth:AuthInput, $id:String, $secret:String, $region:String, $bucket:String, $awsServiceUrl:String, $awsType:String){
                            checkS3StorageConnection(input:$auth, id:$id, secret:$secret, region:$region, bucket:$bucket, awsServiceUrl:$awsServiceUrl, awsType:$awsType)
                                   
                              }
                            `;

export const createAsymmetricKey = gql`
                         mutation ent ($auth:AuthInput, $tenantid:String, $uploadedContent: String, $vaultUrl: String, $keyName: String){
                            createAsymmetricKey(input:$auth, tenantid:$tenantid, uploadedContent: $uploadedContent, vaultUrl:$vaultUrl, keyName:$keyName)
                                   
                              }
                            `;

export const checkCustomFieldName = gql`
                         mutation ent ($auth:AuthInput, $fieldName:String){
                            checkCustomFieldName(input:$auth, fieldName:$fieldName)
                              
                              }
                            `;
export const getProcessingRegions = gql`
    query ent ($auth:AuthInput){
        getProcessingRegions(input:$auth)
            
        }
        `;