import { CommandBarButton, PanelType, Spinner, Stack, Panel } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { MyContext } from '../../../context';
import AlertBar from '../../CustomComponents/AlertBar';
import SafetyDialog from '../../CustomComponents/SafetyDialog';
import EditPatternContainer from './Panels/EditPatternContainer';
import PatternListView from './PatternListView';
import * as QueryPatterns from './PatternsQuery';

export default class PatternDetection extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            isPanelOpen: false,
            patternId: null,
            withFragments: null,
            warningMessage: "",
            optionTypePattern: null,
            action: "",
            panelTitle: null,
            alertMessagePanel: "",
            hideDialogDelete: true,
            isProcessing: false
        };
    }

    componentDidMount() {
        this.setState({
            optionTypePattern: [
                {
                    "key": true,
                    "text": this.context.getTranslation("compliance", "fragments"),
                    "onClick": () => this.openPanel("create", true)
                },
                {
                    "key": false,
                    "text": this.context.getTranslation("compliance", "regex"),
                    "onClick": () => this.openPanel("create", false)
                }
            ]
        })
    }

    openPanel = (action, withFragments) => {
        var state = {}
        switch (action) {
            case "create":
                state = {
                    isPanelOpen: true,
                    action: "create",
                    withFragments: withFragments,
                    panelTitle: "createPattern",
                    warningMessage:""
                }
                break;
            case "edit":
                if (this.state.patternId !== null) {
                    state = {
                        ...state,
                        isPanelOpen: true,
                        action: "edit",
                        panelTitle: "editPattern"
                    }
                }
                else {
                    state = { warningMessage: this.context.getTranslation("compliance", "selectPattern"), alertType: "warning" }
                }
                break;
        }
        this.setState(state)
    }

    hidePanel = () => {
        this.setState({ isPanelOpen: false, alertMessagePanel: "" });
    };

    selectPattern = (id) => {
        this.setState({
            patternId: id,
            warningMessage: ""
        });
    }

    deletePattern = () => {
        this.setState({ hideDialogDelete: true, isProcessing: true })
        if (this.state.patternId !== null) {
            const id = this.state.patternId
            this.props.apol
                .mutate({
                    mutation: QueryPatterns.deletePattern,
                    variables: { id }
                })
                .then((result) => {
                    if (result.data.deletePatternDetection.status !== "ok") {
                        this.setState({
                            warningMessage: this.context.getTranslation("compliance", result.data.deletePatternDetection.text),
                            alertType: result.data.deletePatternDetection.status,
                            isProcessing: false
                        })
                    } else {
                        this.setState({
                            isProcessing: false
                        })
                        this.refetchQueryPatterns();
                    }
                });
        }
    }

    refreshPatterns = () => {
        this.refetchQueryPatterns()
    }

    refetchQueryPatterns = null;

    setMessageError = (message) => {
        this.setState({
            alertMessagePanel: message
        })
    }


    clearAlert = () => {
        if (this.state.alertMessagePanel !== "") {
            this.setState({
                alertMessagePanel: ""
            })
        }
    }

    getHeader = () => {
        return (
            <div style={{ flex: "auto", marginLeft: "20px" }}>
                <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>{this.context.getTranslation("compliance", this.state.panelTitle)}</div>
                <AlertBar
                    message={this.state.alertMessagePanel}
                    alertType={"error"}
                    clearAlert={this.clearAlert}
                />
            </div >
        )
    }

    openDialogDelete = () => {
        if (this.state.patternId !== null) {
            this.setState({ hideDialogDelete: false })
        } else {
            this.setState({
                warningMessage: this.context.getTranslation("compliance", "selectPattern"),
                alertType: "warning"
            })
        }
    }

    render() {

        const optionTypePattern = {
            onDismiss: ev => {
                if (ev && ev.shiftKey) {
                    ev.preventDefault();
                }
            },
            items: this.state.optionTypePattern,
            directionalHintFixed: true,
        };

        return (<div style={{ overflow: "auto", maxHeight: window.innerHeight - 120, position: 'relative', marginTop: "5px" }} >

            {/*Manage button add, edit and delete pattern*/}
            <Stack horizontal styles={{ root: { height: 44 } }}>
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Add' }}
                    text={this.context.getTranslation("compliance", "addPattern")}
                    disabled={false}
                    checked={false}
                    menuProps={optionTypePattern}

                />
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Edit' }}
                    text={this.context.getTranslation("compliance", "editPattern")}
                    disabled={false}
                    checked={false}
                    onClick={() => { this.openPanel("edit", this.state.patternId) }}
                />
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Delete' }}
                    text={this.context.getTranslation("compliance", "deletePattern")}
                    onClick={this.openDialogDelete}
                />
            </Stack>

            {/*Warning and error message*/}
            <AlertBar
                message={this.state.warningMessage}
                alertType={this.state.alertType}
                clearAlert={() => { this.setState({ warningMessage: "" }) }}
            />

            {this.state.isProcessing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}

            {/*Pattern datailList*/}
            {!this.state.isProcessing && <Stack style={{ marginTop: 10 }}>
                <Query
                    query={QueryPatterns.queryAllPatterns}
                    notifyOnNetworkStatusChange={true}
                >
                    {({ loading, error, data, refetch, networkStatus }) => {
                        this.refetchQueryPatterns = refetch
                        if (loading) return (
                            <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                        )
                        if (error) {
                            console.log(error)
                            return (<AlertBar message={"Error!"} alertType={"error"} />);
                        }
                        if (data.getPatternsDetection == null || data.getPatternsDetection.length === 0)
                            return (<AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />);
                        return (
                            <div>
                                <PatternListView selectPattern={this.selectPattern} patterns={data.getPatternsDetection} />
                            </div>
                        )
                    }}
                </Query>
            </Stack>}

            {/*Pattern panel*/}
            <Panel
                isOpen={this.state.isPanelOpen}
                onDismiss={this.hidePanel}
                isFooterAtBottom={false}
                onRenderHeader={this.getHeader}
                type={PanelType.medium}
            >
                <EditPatternContainer
                    apol={this.props.apol}
                    userRules={this.props.userRules}
                    auth={this.props.auth}
                    hidePanel={this.hidePanel}
                    patternId={this.state.action === "create" ? null : this.state.patternId}
                    withFragments={this.state.withFragments}
                    refreshPatterns={this.refreshPatterns}
                    setMessageError={this.setMessageError} />
            </Panel>

            {/*SafetyDialog for delete pattern*/}
            <SafetyDialog
                title={this.context.getTranslation("compliance", "deletePattern")}
                subText={this.context.getTranslation("compliance", "confirmDelete")}
                hideDialog={this.state.hideDialogDelete}
                closeDialog={() => { this.setState({ hideDialogDelete: true }) }}
                ok={this.deletePattern}
            />

        </div>)
    }
}
