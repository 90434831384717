import React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import "./Ttl.scss"
import gql from "graphql-tag";
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { Stack, Spinner, Text } from 'office-ui-fabric-react/lib';
import { toast } from "react-toastify";
import { MyContext } from '../../../../../context';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { queryGetEntry } from "../../../../../queries/queryRecordings";
import FormConversationSafeFolders from "./FormConversationSafeFolders";

const dialogButtonStyles = mergeStyleSets({
    buttons: {
        marginRight: '5px',
    },
    display: {
        margin: "0px"
    },
    allign: {
        display: "flex",
        float: "right"
    },
    size: {
        padding: "1px",
        "margin-right": "8px"
    }

});
export default class TtlContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: false,
            reasoningText: this.props.data.deleteReasoningText,
            graphqlRequestPending: false,
        };
    }

    deletion = false;
    deletionRequest = false;
    conversationSafe = false;
    actiontype = "";
    titleDialog = "";
    textDialog = ""

    deleteConversation = () => {
        this.setState({ graphqlRequestPending: true })

        this.props.client
            .mutate({
                mutation: gql`
                mutation ent ($input: AuthInput, $id : String!) {
                    deleteConversation (input: $input, id: $id) {
                        id
                    }
                }
                `,
                variables: { id: this.props.data.conversationId, input: this.props.authorization }
            })
            .then(result => {
                console.log(result);
                this.setState({
                    graphqlRequestPending: false,
                    isDialogOpen: false
                });
                this._dialogClose();
            });
    };


    requestDeleteConversation = () => {
        this.props.client
            .mutate({
                mutation: gql`
                mutation ent ($id : String!, $reasoning : String) {
                    requestDelete (id: $id, reasoning: $reasoning) {
                        id
                    }
                }
                `,
                variables: { id: this.props.data.conversationId, reasoning: this.state.reasoningText }
            })
            .then(result => {
                console.log(result);
                this.setState({ isDialogOpen: true });


            });
    };

    _dialogClose = () => {
        window.location.href = "/";
    };

    _dialogExit = () => {
        this.setState({ isDialogOpen: false });
    };

    onRestoreArchivedConversation = () => {
        var answer = window.confirm("Do you really want to retrieve the conversation from archive storage ?");
        if (answer) {
            this.restoreArchivedConversation();
        }

    };
    restoreArchivedConversation = () => {
        this.props.client
            .mutate({
                mutation: gql`
                mutation ent ($id : String!, $input:AuthInput) {
                    restoreFromArchive (id: $id, input: $input){valid}
                }
                `,
                refetchQueries: [{
                    query: queryGetEntry,
                    variables: { id: this.props.data.conversationId, auth: this.props.authorization, callStartMs: this.props.data.callStartMs }
                }],
                variables: {
                    id: this.props.data.conversationId,
                    input: this.props.authorization
                }
            })
            .then(result => console.log(result));

    }

    render(): React.ReactNode {
        return (<MyContext.Consumer>
            {(context) => (
                <div className="ms-Grid-row ">
                    <div className="ms-Grid-col ms-sm12 ms-md11 ms-lg11">
                        <div className="notes-container" style={{ paddingLeft: 15 }}>
                            {context.getTranslation("detailContainer", "timeToLive")}: {this.props.data.ttl === "0" ? "infinite" : this.props.data.ttl}
                        </div>
                        <div className="notes-container">
                            {/*DIALOG TO CONFIRM DELETE CONVERSATION*/}
                            <Dialog
                                isOpen={this.state.isDialogOpen}
                                type={DialogType.close}
                                onDismiss={this._dialogExit.bind(this)}
                                title={this.titleDialog}
                                subText={this.textDialog}
                                isBlocking={false}
                                closeButtonAriaLabel='Close'
                            >
                                {this.state.graphqlRequestPending
                                    ? <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                    : <div className={dialogButtonStyles.allign}>
                                        <span className={dialogButtonStyles.display}>
                                            <PrimaryButton className={dialogButtonStyles.buttons, dialogButtonStyles.size} text="Confirm" onClick={(e) => { e.preventDefault(); this.deleteConversation() }} />
                                            <DefaultButton className={dialogButtonStyles.buttons, dialogButtonStyles.size} text={context.getTranslation("common", "cancel")} onClick={this._dialogExit.bind(this)} />
                                        </span>
                                    </div>
                                }
                            </Dialog>

                            <Stack vertical style={{ padding: 15 }} tokens={{ childrenGap: 5 }}>
                                {this.props.userRules.ruleAccess.userSafe !== "No" && <div>
                                    <FormConversationSafeFolders apol={this.props.client}
                                        auth={this.props.authorization}
                                        conversationId={this.props.data.conversationId}
                                        data={this.props.data}
                                        isProcessing={this.props.isProcessing}
                                        refreshFolderList={this.props.refreshFolderList}
                                        ruleAccess={this.props.userRules.ruleAccess}
                                        userId={this.props.userRules.userId}
                                    />
                                </div>}

                                {(this.props.data.deleteAllowance !== "No" || this.props.data.deletePropose) && <div>
                                    {this.props.data.deletePropose && this.props.data.deleteRequestUser == null &&
                                        <DefaultButton text={context.getTranslation("detailContainer", "requestDeletion")} styles={{ root: { marginTop: 10, marginBottom: 10, } }}
                                                       disabled={this.props.data.deleteReasoning && (this.state.reasoningText == null || this.state.reasoningText == "")}
                                                       onClick={this.onRequestDelete} />}
                                    {this.props.data.deleteAllowance === "Yes"
                                        && !this.props.data.conversationSafe
                                        && <DefaultButton
                                            styles={{ root: { marginTop: 10, marginBottom: 10, display: 'block' } }}
                                            iconProps={{ iconName: 'Delete' }}
                                            text={context.getTranslation("detailContainer", "deleteConversation")}
                                            onClick={this.onDelete}
                                        />
                                    }
                                    {this.props.data.deleteRequestUser != null &&
                                        <div>
                                            {context.getTranslation("detailContainer", "deleteProposal")}
                                            <mgt-person show-name={true} user-id={this.props.data.deleteRequestUser} person-card="hover" style={{ "text-align": "center", "vertical-align": "middle", "line-height": "40px", "margin-left": "10px" }}></mgt-person>
                                            <Text className="align-text-center">{this.props.data.deleteRequestUserName}</Text>
                                        </div>}

                                    {((this.props.data.deleteReasoning || this.props.data.deleteReasoningText) && this.props.data.isDeleteApproverUser == true) && 
                                        <div>{context.getTranslation("detailContainer", "reasonDeleteProposal")}: {this.state.reasoningText}
                                        </div>}

                                    {((this.props.data.deleteReasoning || this.props.data.deleteReasoningText) && this.props.data.deleteAllowance !== "Yes") && <div>
                                        <TextField type={"text"}
                                            label={context.getTranslation("detailContainer", "reasonDeleteProposal")}
                                            value={this.state.reasoningText}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    reasoningText: newValue,
                                                });
                                            }}
                                        /></div>}


                                </div>
                                }
                                {/*ARCHIVED CONVERSATION*/}
                                {(this.props.data.archiveStatus != 0) &&
                                    <div>
                                        <PrimaryButton
                                            text={context.getTranslation("detailContainer", "archivedConversation")}
                                            style={{ backgroundColor: 'gray' }}
                                            disabled />
                                    </div>

                                }
                                {(((this.props.data.archiveAccessType === 1) || ((this.props.data.archiveAccessType === 3) && this.props.data.isRestoreApproved)) && this.props.data.archiveStatus == 1) &&
                                    <div>
                                        {/*Restore button*/}
                                        < PrimaryButton text={context.getTranslation("detailContainer", "restore")}
                                            style={{ backgroundColor: 'green' }}
                                            onClick={this.onRestoreArchivedConversation} />
                                    </div>
                                }
                            </Stack>
                        </div>
                    </div>
                </div>
            )
            }
        </MyContext.Consumer>);
    }

    onDelete = () => {
        this.actiontype = "convDelete";
        this.titleDialog = "Delete Conversation!";
        this.textDialog = "Are you sure you want to delete this conversation?";

        this.setState({
            isDialogOpen: true

        });
    };

    onRequestDelete = () => {
        if (!this.deletionRequest) {
            this.props.hidePanel();
            let toastId = toast.info(
                <MyContext.Consumer>
                    {(context) => (
                        <>
                            <p> {context.getTranslation("detailContainer", "undoDeletionRequestMessage")}</p>
                            <DefaultButton onClick={() => { this.dismissRequest(toastId) }} text={context.getTranslation("detailContainer", "undoDeletionConfirmation")} />
                        </>
                    )
                    }
                </MyContext.Consumer>
                , {
                    onClose: () => {
                        if (!this.deletionRequest) {
                            this.requestDeleteConversation();
                            toast.info(<MyContext.Consumer>{(context) => (<h3>{context.getTranslation("detailContainer", "requestDeletionConfirmationMessage")}</h3>)}</MyContext.Consumer>, { autoClose: 5000 })
                        }
                    }
                });
        }
        else {
            toast.warn(<MyContext.Consumer>{(context) => (<>{context.getTranslation("detailContainer", "requestDeletionWarning")}</>)}</MyContext.Consumer>)
        }

    };
    dismiss = (toastId) => {
        this.deletion = true;
        toast.dismiss(toastId);
        toast.info(<MyContext.Consumer>{(context) => (<h3>{context.getTranslation("detailContainer", "deletionSuccessfullyUndoneMessage")}</h3>)}</MyContext.Consumer>, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };
    dismissRequest = (toastId) => {
        this.deletionRequest = true;
        toast.dismiss(toastId);
        toast.info(<MyContext.Consumer>{(context) => (<h3>{context.getTranslation("detailContainer", "deletionRequestSuccessfullyUndoneMessage")}</h3>)}</MyContext.Consumer>, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

}