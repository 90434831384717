import gql from "graphql-tag";

export const queryGetIntegration = gql`
    query ent ($auth:AuthInput){
        getIntegrationData(input:$auth)
        {
            integrationdata{
                 id
                                        name
                                        integrationType
                                        enabled
                                        processingRegion
                                        integrationClientId
                                        integrationClientSecret
                                        integrationUsername
                                        integrationPassword
                                        tenantId
                                        folderName
                                        integrationPrivateKey
                                        integrationPrivateKeyFile
                                        hasPrivateKey
            }
        }
    }
`;
