import React from 'react';
import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    CommonAxisSettings,
    Grid,
    Legend,
    Margin,
    Tooltip,
    Label,
    Format
} from 'devextreme-react/chart';

import {Size} from "devextreme-react/vector-map";


class SplineChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'spline'
        };
        this.types = ['spline', 'stackedspline', 'fullstackedspline'];
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <React.Fragment>
                <Chart
                    palette={this.props.palette}
                    ref={this.props.reference}

                    dataSource={this.props.data}
                >
                    <CommonSeriesSettings
                        argumentField="time"
                        type={this.state.type}
                    />
                    <CommonAxisSettings>
                        <Grid visible={true} />
                    </CommonAxisSettings>
                    {
                        this.props.source.map(function(item) {
                            return <Series key={item.value} valueField={item.value} name={item.name} />;
                        })
                    }
                    <Margin bottom={20} />
                    <ArgumentAxis
                        allowDecimals={false}
                        axisDivisionFactor={60}
                    >
                        <Label>
                            <Format type="decimal" />
                        </Label>
                    </ArgumentAxis>
                    <Legend
                        margin={0}
                        horizontalAlignment="center"
                        verticalAlignment="bot"
                    />
                    <Tooltip enabled={true} />

                    <Size
                        height={this.props.size.height}
                        width={this.props.size.width}
                    />
                </Chart>

            </React.Fragment>
        );
    }

    handleChange(e) {
        this.setState({ type: e.value });
    }
}

export default SplineChart;
