import { Stack, TextField, IconButton, Label } from "office-ui-fabric-react";
import * as React from "react";
import { MyContext } from "../../../context";
import UploadAnnouncement from "../UploadAnnouncement";

export default class NumberPrefix extends React.Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        if (props !== undefined && props !== null && props.announcement !== undefined && props.announcement != null) {
            
            this.state = {
                announcement: {
                    prefixList: props.announcement.prefixList,
                    start: props.announcement.start,
                    stop: props.announcement.stop
                },

            }
        } else {
            this.state = {
                announcement: {
                    prefixList: "",
                    start: "",
                    stop: ""
                },

            }
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.announcement != undefined && this.props.announcement != undefined && (prevProps.announcement.prefixList != this.props.announcement.prefixList
            || prevProps.announcement.start != this.props.announcement.start || prevProps.announcement.stop != this.props.announcement.stop)) {
            this.setState({ announcement: this.props.announcement })
        }
    }

    updateFiles = (file, type) => {
        var ann = this.state.announcement;
        var prevFile = "";
        if (type === "Start") {
            prevFile = ann.start;
            ann.start = file;
        } else if (type === "Stop") {
            prevFile = ann.stop;
            ann.stop = file;
        }
        this.props.updateAnnouncement(this.props.id, ann, prevFile);
        this.setState({
            announcement: ann
        });
    }

    render() {
        return (
            <div id={this.props.id}>
                <Stack.Item style={{ marginLeft: "-10px", marginTop: "5px" }}>
                    <IconButton style={{ float: "left" }} iconProps={{ iconName: 'Delete' }} onClick={() => { this.props.deleteAnnouncement(this.props.id, false) }} />
                    <Label style={{ float: "left" }}>{this.context.getTranslation("configuration", "prefixNumber")}</Label>
                    <br />
                </Stack.Item>
                <Stack.Item styles={this.props.styles} >

                    <TextField type={"text"}
                        id="prefix"
                        value={this.state.announcement.prefixList}
                        onChange={(event, newValue) => {
                            var ann = this.state.announcement
                            ann.prefixList = newValue
                            this.props.updateAnnouncement(this.props.id, ann);
                            this.setState({
                                announcement: ann
                            });

                        }}
                    />
                </Stack.Item>
                <Stack.Item styles={this.props.styles}>
                    <UploadAnnouncement authorization={this.props.authorization} apol={this.props.client} fileBtnText={this.state.announcement.start === "" ? "Start file" : this.state.announcement.start.slice(36)} idResult={"startRes" + this.props.id} idError={"startErr" + this.props.id} idFiles={"startFile" + this.props.id} updateAnnouncement={this.updateFiles} type="Start" file={this.state.announcement.start} />
                    <UploadAnnouncement authorization={this.props.authorization} apol={this.props.client} fileBtnText={this.state.announcement.stop === "" ? "Stop file" : this.state.announcement.stop.slice(36)} idResult={"stopRes" + this.props.id} idError={"stopErr" + this.props.id} idFiles={"stopFile" + this.props.id} updateAnnouncement={this.updateFiles} type="Stop" file={this.state.announcement.stop}/>
                </Stack.Item>
            </div>
        )
    }
}
