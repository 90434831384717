
export const getTotalScore = (scorecardResult) => {
    let totalScore = 0;
    scorecardResult.sections.forEach((s) => {
        const totalSection = s.evaluationItems.reduce((acco, item) => acco + (item.score !== "" && item.score !== -1 ? parseFloat(item.score) : 0), 0)
        totalScore = totalScore + totalSection
    })
    return totalScore;
}

export const getTotalPoints = (scorecardResult) => {
    let totalPoints = 0;
    scorecardResult.sections.forEach((s) => {
        const totalSection = s.evaluationItems.reduce((acco, item) => acco + (item.score !== "" && item.score !== -1 ? item.points : 0), 0)
        totalPoints = totalPoints + totalSection
    })
    return totalPoints;
}

export const getRangeEvaluation = (scorecardResult, totalScore, totalPoints) => {
    let percentage = Math.round(totalScore / totalPoints * 100)
    if (isNaN(percentage)) {
        percentage = 0;
    }
    return scorecardResult.settingItems.find(si => si.minRange <= percentage && si.maxRange >= percentage)
}