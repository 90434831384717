import React, { Component } from 'react';

export default class InputFieldTitle extends Component {

    render() {
        const styleDisable = { color: "rgb(89, 89, 89)" }
        return (
            <div style={{ paddingTop: "5px", paddingBottom: "5px", fontWeight: "600" }}>
                <span style={this.props.disabled ? styleDisable : {}}>{this.props.label}</span>
                {this.props.required ? <span style={this.props.disabled ? styleDisable : { color: "rgb(164, 38, 44)" }}>{` *`}</span> : ""}
            </div>
        )
    }
}
