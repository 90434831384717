import React, { Component } from 'react'
import { Spinner, DetailsList, SelectionMode } from 'office-ui-fabric-react';
import { Query } from 'react-apollo';
import { MyContext } from '../../../../../context';
import { getScorecardResults } from '../../../../ComplianceView/AIQualityManagement/AIQualityManagementQuery';
import AlertBar from '../../../../CustomComponents/AlertBar';
import ScorecardResult from './ScorecardResult';
import * as utils from "./ScorecardResultUtils"


export default class AIQualityManagementResult extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);

        this.state = {
            isProcessing: false,
            showDetail: false,
            scorecardResultId: null
        };
    }

    refetchQueryScorecardsResult = null

    renderItemColumn = (item, index, column) => {
        //console.log(item, index, column)

        let totalScore = utils.getTotalScore(item)
        let totalPoints = utils.getTotalPoints(item)
        let rangeEvaluation = utils.getRangeEvaluation(item, totalScore, totalPoints)

        const fieldName = column.fieldName
        switch (fieldName) {
            case "automatedEvaluation": {
                return <span>{item.automatedEvaluation ?
                    this.context.getTranslation("compliance", "automated") :
                    this.context.getTranslation("compliance", "manual")}
                </span>
            }
            case "assessment": {
                return <span>{`${totalScore}/${totalPoints}`}</span>
            }
            case "evaluationKey": {
                return <span>{rangeEvaluation?.name}</span>
            }
            default: return <span>{item[fieldName]}</span>
        }
    }

    onActiveItemChanged = (item) => {
        this.setState({ showDetail: true, scorecardResultId: item.id })
    };

    render() {
        return <div>
            <Query
                query={getScorecardResults}
                variables={{
                    conversationId: this.props.data.conversationId
                }}
            >
                {({ loading, error, data, refetch }) => {
                    this.refetchQueryScorecardsResult = refetch;
                    if (loading) { return <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" /> }
                    if (error) {
                        console.log(error)
                        return <AlertBar message={this.context.getTranslation("compliance", "error")} alertType="error" />
                    }
                    if (data.getScorecardResults == null || data.getScorecardResults.length === 0) {
                        return <AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />
                    }
                    if (data.getScorecardResults.length === 1) {
                        return (
                            <ScorecardResult
                                scorecardResult={data.getScorecardResults[0]}
                                showButtonCancel={false}
                                apol={this.props.apol}
                                refreshResult={() => { this.refetchQueryScorecardsResult() }}
                                userRules={this.props.userRules}
                            />
                        )
                    } else {
                        return (
                            <div style={{ marginTop: 10 }}>
                                {!this.state.showDetail &&
                                    <DetailsList
                                        items={data.getScorecardResults}
                                        columns={[
                                            {
                                                key: 'scorecardName',
                                                name: this.context.getTranslation("compliance", "name"),
                                                fieldName: 'scorecardName',
                                                minWidth: 100,
                                                maxWidth: 150,
                                                isResizable: true
                                            },
                                            {
                                                key: "automatedEvaluation",
                                                name: this.context.getTranslation("compliance", "evaluationType"),
                                                fieldName: "automatedEvaluation",
                                                minWidth: 100,
                                                maxWidth: 150,
                                                isResizable: true
                                            },
                                            {
                                                key: "assessment",
                                                name: this.context.getTranslation("compliance", "assessment"),
                                                fieldName: "assessment",
                                                minWidth: 100,
                                                maxWidth: 150,
                                                isResizable: true
                                            },
                                            {
                                                key: "evaluationKey",
                                                name: this.context.getTranslation("compliance", "evaluationKey"),
                                                fieldName: "evaluationKey",
                                                minWidth: 100,
                                                maxWidth: 150,
                                                isResizable: true
                                            }
                                        ]}
                                        selectionMode={SelectionMode.none}
                                        onActiveItemChanged={this.onActiveItemChanged}
                                        setKey="set"
                                        onRenderItemColumn={this.renderItemColumn}
                                    />
                                }
                                {this.state.showDetail &&
                                    <ScorecardResult
                                        apol={this.props.apol}
                                        showButtonCancel={true}
                                        closeDetail={() => { this.setState({ showDetail: false }) }}
                                        scorecardResult={data.getScorecardResults.filter(s => s.id === this.state.scorecardResultId)[0]}
                                        refreshResult={() => { this.refetchQueryScorecardsResult() }}
                                        userRules={this.props.userRules}
                                    />
                                }
                            </div>
                        )
                    }

                }}
            </Query>
        </div>
    }
}