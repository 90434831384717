import { CommandBarButton, PrimaryButton, Stack, TextField } from 'office-ui-fabric-react';
import React, { Component } from 'react'
import { MyContext } from '../../../context';

class CustomFieldTypeText extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            error: ""
        };
    }

    componentDidMount() {
        if (this.props.customFieldConversation === undefined || this.props.customFieldConversation.fieldValues.length === 0) {
            this.addNewField()
        }
    }

    componentDidUpdate() {
        if (this.props.customFieldConversation === undefined || this.props.customFieldConversation.fieldValues.length === 0) {
            this.addNewField()
        }
    }

    addNewField = () => {
        let error = ""
        let newFieldValues = [""]
        if (this.props.customFieldConversation !== undefined) {
            if ((this.props.customFieldDefinition.maxNumberOfFields == 0 && this.props.customFieldConversation.fieldValues.length == 0) || this.props.customFieldDefinition.maxNumberOfFields > this.props.customFieldConversation.fieldValues.length) {
                newFieldValues = [...this.props.customFieldConversation.fieldValues, ""]
            } else {
                error = "error"
            }
        }
        if (error === "")
            this.props.changeFieldText(this.props.customFieldDefinition.id, newFieldValues)
        else {
            error = this.context.getTranslation("configuration", "errorNumberOfFields")
        }
        this.setState({
            error
        })
    }

    changeField = (e, i) => {
        e.target.style.border = "";
        let error = ""
        let newValue = e.target.value
        let regexNumeric = /^[0-9\b]+$/
        let validationLength = newValue.length <= this.props.customFieldDefinition.fieldLength;
        let validationType = (this.props.customFieldDefinition.isNumeric && regexNumeric.test(newValue)) || !this.props.customFieldDefinition.isNumeric
        
        if (validationType && validationLength) {
            let newFieldValues = this.props.customFieldConversation.fieldValues.map((v, k) => {        
                if (k === i) {
                    return newValue
                } else {
                    return v
                }
            })
            this.props.changeFieldText(this.props.customFieldDefinition.id, newFieldValues)
        } else if (newValue == "") {//to be able to delete everything in the field
            let newFieldValues = this.props.customFieldConversation.fieldValues.map((v, k) => {
                if (k === i) {
                    return newValue
                } else {
                    return v
                }
            })
            this.props.changeFieldText(this.props.customFieldDefinition.id, newFieldValues)

            error = this.context.getTranslation("configuration", "errorValidationFieldEmpty")
        } else {
            error = !validationLength ? this.context.getTranslation("configuration", "errorValidationFieldLength") : this.context.getTranslation("configuration", "errorValidationFieldType")
        }
        this.setState({
            error
        })
    }

    deleteField = (e, i) => {
        this.setState({
            error: ""
        })
        this.props.customFieldConversation.fieldValues.splice(i, 1);
        this.props.changeFieldText(this.props.customFieldDefinition.id, this.props.customFieldConversation.fieldValues)
    }

    save = () => {
        this.setState({
            error: ""
        })

        if (this.props.customFieldConversation.customFieldDefinition.hasAdditionalNumberCheck) {
            this.props.save(this.props.customFieldConversation.fieldId);
        } else {
            this.props.save("");
        }
    }


    render() {
        return (
            <div style={{ margin: "10px", padding: "10px", boxShadow: "rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px" }}>
                <h5>{this.props.customFieldDefinition.fieldName}</h5>
                <Stack horizontal >
                    <Stack.Item align="center">
                        <span style={{ color: "red", fontSize: "10px" }}>{this.state.error}</span>
                    </Stack.Item>
                </Stack>
                <Stack style={{ paddingTop: "10px" }} >
                    {this.props.customFieldConversation !== undefined &&
                        this.props.customFieldConversation.fieldValues.map((f, i) => {
                            let errorExactLength = this.props.customFieldDefinition.hasExactLength && f.length !== this.props.customFieldDefinition.fieldLength && f != ""
                            return (
                                <Stack.Item key={i} style={{ marginBottom: "10px" }}>
                                    <Stack horizontal>
                                        <TextField id={this.props.customFieldDefinition.fieldName + i} errorMessage={errorExactLength ? " " : ""} value={f} onChange={(e) => this.changeField(e, i)} disabled={this.props.userRules.ruleAccess.customFieldsAccessType !== "FullAccess" || this.props.disabled} />
                                        <CommandBarButton
                                            styles={{ root: { marginLeft: 5 } }}
                                            iconProps={{ iconName: 'Delete' }}
                                            text={""}
                                            disabled={this.props.userRules.ruleAccess.customFieldsAccessType !== "FullAccess" || this.props.disabled}
                                            onClick={(e) => this.deleteField(e, i)}
                                        />
                                    </Stack>
                                </Stack.Item>
                            )
                        })}
                </Stack>
                <Stack horizontal >
                    <CommandBarButton
                        styles={{ root: { paddingTop: 5, paddingBottom: 5 } }}
                        iconProps={{ iconName: 'Add' }}
                        text={this.context.getTranslation("configuration", "addField")}
                        disabled={this.props.userRules.ruleAccess.customFieldsAccessType !== "FullAccess" || this.props.disabled}
                        onClick={this.addNewField}
                    />
                </Stack>
                {this.props.updatedFields.includes(this.props.customFieldDefinition.id) &&
                    <Stack horizontal style={{ flexFlow: "row-reverse", marginTop: "10px" }}>
                        <PrimaryButton text={this.context.getTranslation("common", "save")} onClick={this.save} disabled={!this.props.isValid() || this.props.processing || this.props.userRules.ruleAccess.customFieldsAccessType !== "FullAccess"} />
                    </Stack>
                }
            </div>
        )
    }
}

export default CustomFieldTypeText