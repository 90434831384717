
const fallbackData = (region) => {
  if (region === undefined || region === null) {
    return null;
  }
  switch (region.currency) {
    case 'YEN':
    case 'USD':
      return {
        getShopData: {
          planVariantID: '5e393fc54de0841e4c6249b8',
          currency: 'USD',
          licences: {
            analytics: {
              id: '5e3833d2443e550f04bfba13',
              name: 'Analytics',
              description: '',
              price: 12,
            },
            smartRecording: {
              id: '5e3833f49cc9ba1e184f112f',
              name: 'Smart Recording',
              description: '',
              price: 14.40,
            },
            smartRecordingAnalytics: {
              id: '5e383426443e550f04bfba28',
              name: 'Smart Recording & Analytics',
              description: '',
              price: 18,
            },
            complianceRecording: {
              id: '5e38344f443e550f04bfba3b',
              name: 'Compliance Recording',
              description: '',
              price: 18,
            },
            complianceRecordingAnalytics: {
              id: '5e38347e443e550f04bfba87',
              name: 'Compliance Recording & Analytics',
              description: '',
              price: 21.60,
            },
          },
          storage: [
            {
              name: 'Storage 500 GB - LRS',
              description: 'Storage 500 GB - LRS',
              id: '5e3834ae443e550f04bfba9c',
              price: 36,
            },
            {
              name: 'Storage 500 GB - GRS (Region 1)',
              description: 'Storage 500 GB - GRS (Region 1)',
              id: '5e3834d59cc9ba1e184f116d',
              price: 96,
            },
            {
              name: 'Storage 500 GB - GRS (Region 2)',
              description: 'Storage 500 GB - GRS (Region 2)',
              id: '5e3834f1443e550f04bfbab6',
              price: 168,
            },
            {
              name: 'Recording Export 500 GB',
              description: 'Recording Export 500 GB - per package',
              id: '5e99a7a59cc9ba1fc8c112ec',
              ExternalId: '79541070_USD',
              PlanGroupId: '5e383024443e5529c08c19f4',
              price: 105.6,
            },

          ],
          cognitive: [
            {
              name: 'Speech Analytics Package - 100h',
              description: 'Speech Analytics Package - 100h',
              id: '5e3835234de0841e4c620341',
              price: 462,
            },
            {
              name: 'Video Indexer - 100h',
              description: 'Video Indexer - 100h',
              id: '5e38354d443e550f04bfbaf4',
              price: 2112,
            }, {
              name: 'Translate - 100h',
              description: 'Translate - 100h per package',
              id: '5e99a7769cc9ba1fc8c112d2',
              ExternalId: '79541052_USD',
              PlanGroupId: '5e383024443e5529c08c19f4',
              price: 198,
            },

          ],
          training: {
            name: 'Onboarding & Administration Training',
            description: 'Onboarding & Administration Training',
            id: '5e383914443e5529c08c210e',
            price: 1760,
          },
        },

      };
    case 'GBP':
      return {
        getShopData: {
          planVariantID: '5e393fd39cc9ba1e184f4bec',
          currency: 'GBP',
          licences: {
            analytics: {
              id: '5e38394f4de0842c0801d7c8',
              name: 'Analytics',
              description: '',
              price: 9.5,
            },
            smartRecording: {
              id: '5e3839759cc9ba216cc315b6',
              name: 'Smart Recording',
              description: '',
              price: 11.40,
            },
            smartRecordingAnalytics: {
              id: '5e3839a04de0842c0801d7dc',
              name: 'Smart Recording & Analytics',
              description: '',
              price: 14.25,
            },
            complianceRecording: {
              id: '5e3839c44de0842c0801d7f1',
              name: 'Compliance Recording',
              description: '',
              price: 14.25,
            },
            complianceRecordingAnalytics: {
              id: '5e3839ed443e5529c08c21a2',
              name: 'Compliance Recording & Analytics',
              description: '',
              price: 17.10,
            },
          },
          storage: [
            {
              name: 'Storage 500 GB - LRS',
              description: 'Storage 500 GB - LRS',
              id: '5e383a174de0842c0801d80f',
              price: 28.5,
            },
            {
              name: 'Storage 500 GB - GRS (Region 1)',
              description: 'Storage 500 GB - GRS (Region 1)',
              id: '5e383a414de0842c0801d819',
              price: 76,
            },
            {
              name: 'Storage 500 GB - GRS (Region 2)',
              description: 'Storage 500 GB - GRS (Region 2)',
              id: '5e383a65443e5529c08c21d1',
              price: 133,
            }, {
              name: 'Recording Export 500 GB',
              description: 'Recording Export 500 GB - per package',
              id: '5e99a8144de0841764804709',
              ExternalId: '79541070_GBP',
              PlanGroupId: '5e382fa24de0842c0801d3b4',
              price: 83.6,
            },

          ],
          cognitive: [
            {
              name: 'Speech Analytics Package - 100h',
              description: 'Speech Analytics Package - 100h',
              id: '5e383a97443e5529c08c21e1',
              price: 365.75,
            },
            {
              name: 'Video Indexer - 100h',
              description: 'Video Indexer - 100h',
              id: '5e383aba443e5529c08c21eb',
              price: 1672,
            }, {
              name: 'Translate - 100h',
              description: 'Translate - 100h per package',
              id: '5e99a7e3443e550b8071cfcb',
              ExternalId: '79541052_GBP',
              PlanGroupId: '5e382fa24de0842c0801d3b4',
              price: 156.75,
            },

          ],
          training: {
            name: 'Onboarding & Administration Training',
            description: 'Onboarding & Administration Training',
            id: '5e383914443e5529c08c210e',
            price: 1760,
          },
        },
      };
    case 'EUR':
    default:
      return {
        getShopData: {
          planVariantID: '5e393fa5443e5529c08c6df8',
          currency: 'EUR',
          training: {
            name: 'Onboarding & Administration Training',
            description: 'Onboarding & Administration Training',
            id: '5e383914443e5529c08c210e',
            price: 1760,
          },
          licences: {
            analytics: {
              id: '5e3835b09cc9ba1e184f11bd',
              name: 'Analytics',
              description: '',
              price: 10,
            },
            smartRecording: {
              id: '5e3836534de0841e4c6203bc',
              name: 'Smart Recording',
              description: '',
              price: 12,
            },
            smartRecordingAnalytics: {
              id: '5e3837cc9cc9ba216cc314ee',
              name: 'Smart Recording & Analytics',
              description: '',
              price: 15,
            },
            complianceRecording: {
              id: '5e3837f7443e5529c08c2043',
              name: 'Compliance Recording',
              description: '',
              price: 15,
            },
            complianceRecordingAnalytics: {
              id: '5e3838269cc9ba216cc31531',
              name: 'Compliance Recording & Analytics',
              description: '',
              price: 18,
            },
          },
          storage: [
            {
              name: 'Storage 500 GB - LRS',
              description: 'Storage 500 GB - LRS',
              id: '5e38384f443e5529c08c2087',
              price: 30,
            },
            {
              name: 'Storage 500 GB - GRS (Region 1)',
              description: 'Storage 500 GB - GRS (Region 1)',
              id: '5e383877443e5529c08c2099',
              price: 80,
            },
            {
              name: 'Storage 500 GB - GRS (Region 2)',
              description: 'Storage 500 GB - GRS (Region 2)',
              id: '5e38389c4de0842c0801d773',
              price: 140,
            },
            {
              name: 'Recording Export 500 GB',
              description: 'Recording Export 500 GB - per package',
              id: '5e99a7139cc9ba1fc8c112a8',
              ExternalId: '79541070_EUR',
              PlanGroupId: '5e38300e443e5529c08c19e9',
              price: 88,
            },

          ],
          cognitive: [
            {
              name: 'Speech Analytics Package - 100h',
              description: 'Speech Analytics Package - 100h',
              id: '5e3838ea4de0842c0801d797',
              price: 385,
            },
            {
              name: 'Video Indexer - 100h',
              description: 'Video Indexer - 100h',
              id: '5e383914443e5529c08c210e',
              price: 1760,
            },
            {
              name: 'Translate - 100h',
              description: 'Translate -100h per package',
              id: '5e99a6de443e55241037b04b',
              ExternalId: '79541052_EUR',
              PlanGroupId: '5e38300e443e5529c08c19e9',
              price: 165,
            },

          ],
        },

      };
  }
};

export { fallbackData };
