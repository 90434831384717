import { MessageBar, MessageBarType, Spinner } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { MyContext } from '../../../../context';
import * as QueryAP from '../AdherencePhrasesQuery';
import EditAdherencePhrase from './EditAdherencePhrase';

export default class EditAdherencePhraseContainer extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { auth, apol, hidePanel } = this.props
        const id = this.props.phraseId
        return (
            <div>
                {/*Edit phrase*/}
                {id !== null && (
                    <Query
                        query={QueryAP.queryGetAdherencePhrase}
                        variables={{ auth, id }}>
                        {({ loading, error, data }) => {
                            if (loading) {
                                return (
                                    <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                )
                            }
                            if (error) {
                                return (
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        dismissButtonAriaLabel="Close"
                                    >
                                        {this.context.getTranslation("compliance", "error")}
                                    </MessageBar>
                                )
                            }
                            if (data.getAdherencePhrase == null) {
                                return (<MessageBar>
                                    {this.context.getTranslation("compliance", "noData")}
                                </MessageBar>)
                            }
                            return (<div>
                                <EditAdherencePhrase
                                    data={data.getAdherencePhrase}
                                    closePanel={hidePanel}
                                    apol={apol}
                                    auth={auth}
                                    refreshPhrases={this.props.refreshPhrases}
                                    setMessageError={this.props.setMessageError}
                                />
                            </div>
                            )
                        }}
                    </Query>
                )
                }
                {/*Create phrase*/}
                {
                    id === null && (
                        <div>
                            <EditAdherencePhrase
                                closePanel={hidePanel}
                                apol={apol}
                                auth={auth}
                                refreshPhrases={this.props.refreshPhrases}
                                setMessageError={this.props.setMessageError}
                            />
                        </div>
                    )
                }
            </div>
        );
    }
}
