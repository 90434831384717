import React, { Component } from 'react'
import './Login.scss'

export default class Logout extends Component {

    componentDidMount() {

            // Generate random state string and store it, so we can verify it in the callback
            // Go to the Azure AD authorization endpoint
            let queryParams = {
                post_logout_redirect_uri: window.location.origin
            };
            var esc = encodeURIComponent;
            var query = Object.keys(queryParams)
                .map(k => esc(k) + '=' + esc(queryParams[k]))
                .join('&');
            let authorizeEndpoint = "https://login.microsoftonline.com/common/oauth2/v2.0/logout?" + query
        window.location.assign(authorizeEndpoint);
    }

    render() {
        return (
            <div className="login-body">
            </div>
        );
    }
}
