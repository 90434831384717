import React, { Component } from 'react';

//APPOLO + GQL
import { ApolloProvider, Query } from "react-apollo";
import { queryGetTenantList } from "../../queries/querySettings";

//OFFICE Fabric
import { Customizer, getId } from '@uifabric/utilities';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PivotItem, Pivot } from 'office-ui-fabric-react/lib/Pivot';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';

//Styles

import './Setter.scss';
import TenantList from "./UserList/TenantList";
import { ToastContainer } from "react-toastify";
import AddTenant from "./UserList/AddTenant";
import { MyContext } from '../../context';


export default class SetterTenant extends Component {
    state = {
        showPanel: false,
        trapPanel: false,
    };
    _layerHostId: string = getId('layerHost');

    // This is based on the definition of items
    render()
        {
           return (
              <div>
                   <div className={window.location.pathname.includes("main") ? "setter-content-main" : "setter-content"}>
                <div className="ms-Grid">

                        <div className="ms-Grid-row">

                      <div>
                          <Customizer
                              scopedSettings={
                                  this.state.trapPanel
                                      ? {
                                          Layer: {
                                              hostId: this._layerHostId
                                          }
                                      }
                                      : {}
                              }
                          >
                              {this.state.showPanel && (
                                  <Panel
                                      type={PanelType.medium}
                                      isOpen={true}
                                      hasCloseButton={true}
                                      headerText={this.configurationTextSelect()}
                                      focusTrapZoneProps={{
                                          isClickableOutsideFocusTrap: true,
                                          forceFocusInsideTrap: false}}
                                      onDismiss={this._onDismissPanel}>
                                      {this.panelSelect()}
                                  </Panel>
                              )}
                          </Customizer>

                            </div>


                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                <ApolloProvider client={this.props.apol}>
                                    {this.query(this._show, this.props.authorization, this.props.apol, this.props.userRules)}

                                </ApolloProvider>

                            </div>
                        </div>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }

    _onDismissPanel = (): void => {
        this.setState({
            showPanel: false
        });
    };

    rules = [];

    _show = (current, vall) => {
        switch (vall) {
            case undefined:
            case null:
                break;
            default:


                if (current == null) {
                    this.setState({ showPanel: true, case: vall, info: null });
                }
                else {
                    this.setState({ showPanel: true, case: vall, info: current });
                }


        }
    }

    query = (layerFunction, auth, apol, userRules) => {
        return <MyContext.Consumer>
            {(context) => (
                <Query
                    query={queryGetTenantList}
                    variables={{ auth }} >
                    {({ loading, error, data }) => {
                        if (loading) return <div>
                            <Spinner label={context.getTranslation("common", "stillLoading")} ariaLive="assertive" labelPosition="top" />
                        </div>;
                        if (error) return <h1> </h1>;

                        if (data.getTenantList == null) {
                            return <div> </div>;
                        }
                        this.tenants = data.getTenantList;
                        return <Pivot>
                            {}
                            <PivotItem key="01" linkText={context.getTranslation("tenants", "tenantsTitle")}itemCount={data.getTenantList != null ? data.getTenantList.length : 0} itemIcon="PlayerSettings">
                                <TenantList key="01" icon={"PlayerSettings"} data={data.getTenantList} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                            </PivotItem>
                        </Pivot>
                    }
                    }
                </Query>
            )
            }
        </MyContext.Consumer>
    }

    configurationTextSelect() {
        switch (this.state.case) {
            case 0: return "Tenant Configuration"
            case 1: return "Add Tenant"
            default: return ""
        }
    }

    panelSelect() {
        switch (this.state.case) {
            case 0: return <AddTenant data={this.state.info} cl={this._onDismissPanel} authorization={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} />
            case 1: return <AddTenant data={this.state.info} cl={this._onDismissPanel} authorization={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} />
            default: return <div> </div>
        }
    }

}
