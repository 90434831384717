import React, { Component } from 'react';
import "./Category.scss"
import { ScrollablePane, mergeStyleSets } from 'office-ui-fabric-react/lib';
import { MyContext } from '../../../../../context';

export default class CategoryContainer extends Component {


    renderElements = () => {
        if (this.props.data.summarizedInsights != null && this.props.data.summarizedInsights.categories) {
            return this.props.data.summarizedInsights.categories.map((categorie, i) => (
                <CategoryElement key={i} categories={categorie} summarizedInsights={this.props.data.summarizedInsights} listener={this.props.listener} />
            ));
        }
        else {
            return <MyContext.Consumer>
                {(context) => (
                    <h2>{context.getTranslation("detailContainer", "categoryMessage")}</h2>
                )
                }
            </MyContext.Consumer>
        }

    };

    render(): React.ReactNode {
        var classNames = mergeStyleSets({
            wrapper: {
                height: this.props.height,
                position: 'relative',
                minHeight: '100px'
            },
            pane: {
            }
        });
        return (window.innerWidth > 600 ? <div className={classNames.wrapper}>
            <ScrollablePane styles={{ root: classNames.pane }}>
                {this.renderElements()}
            </ScrollablePane>
        </div> :
            <div>
                {this.renderElements()}
            </div>);
    }
}

class CategoryElement extends Component {
    state =
        {
            categorie: this.props.categories
        };

    getKeywordsByCategories = (category) => {
        let keywordsList = [];

        if (this.props.summarizedInsights != null && this.props.summarizedInsights.keywords != null && this.state.categorie != null) {
            let keywords = this.props.summarizedInsights.keywords;
            for (var i = 0; i < keywords.length; i++) {
                if (keywords[i].categorie === category) {
                    keywordsList.push(keywords[i]);
                }
            }
        }

        if (keywordsList) {
            return keywordsList.map(({ name, appearances, categorie }, i) => (
                <LabelElement key={i} name={name} appearances={appearances} keywordCategorie={categorie} listener={this.props.listener} />
            ));
        }
    }

    render(): React.ReactNode {
        return <div title="Category"> <p style={{ "margin-bottom": "10px" }}>{this.state.categorie}:</p> <span title="Keyword">{this.getKeywordsByCategories(this.state.categorie)}</span></div>;
    }
}

class LabelElement extends Component {

    state =
        {
            keywords: this.props.name,
            appearances: this.props.appearances
        };
    helpRenderTimestamps = () => {
        //console.log(" Element Länge"+this.state.appearances.length);
        //console.log(" Element 0"+this.state.appearances[0].startTime);
        return this.state.appearances.map((
            { startTime, startSeconds }, i) => (<LabelLink key={i} sec={startSeconds} name={this.state.keywords} time={startTime} listener={this.props.listener} />))
    };

    helpRenderKeywordsName = () => {
        var keyword = "";
        var soundlike = "";
        var keywordSoundlikeSeparator = "";
        var keywordsAndSoundlikes = this.state.keywords.split('_');
        keyword = keywordsAndSoundlikes[0];
        if (keywordsAndSoundlikes[1]) {
            soundlike = keywordsAndSoundlikes[1];
            keywordSoundlikeSeparator = "_"
        }
        //returns keyword+separator from soundlike+soundlike(if existent)+ separator from timestamp
        return <> {keyword + keywordSoundlikeSeparator + soundlike + ":"} </>
    }


    render(): React.ReactNode {
        return <p>
            {this.helpRenderKeywordsName()}
            {this.helpRenderTimestamps()}
        </p>;
    }
}

class LabelLink extends Component {
    seek = () => {
        if (this.props.listener !== undefined && this.props.listener != null) {
            let seconds = this.convertToSeconds(this.props.time);
            this.props.listener(seconds);
        }
    };
    removeLastDigitsOfTime = (time) => {
        console.log("Keyword: ", time)
        var tmp = time.split(":");
        var result = "";
        if (tmp[2]) {
            var tmp1 = tmp[2].split(".");
             result = tmp[0] + ":" + tmp[1] + ":" + tmp1[0];
        } else {
             result = tmp[0] + ":" + tmp[1];

        }
       

        return result;
    };
    convertToSeconds = (time) => {
        var tmp = time.split(":");
        var result = 0;
        if (tmp[2]) {
            var tmp1 = tmp[2].split(".");
            result = parseInt(tmp[0]) * 3600 + parseInt(tmp[1]) * 60 + parseInt(tmp1[0]);
        } else {
            result = parseInt(tmp[0]) * 60 + parseInt(tmp[1]);
        }
        return result;
    }
    render(): React.ReactNode {
        return (
            <a className="link" onClick={() => (this.seek())}>
                {this.removeLastDigitsOfTime(this.props.time)}
            </a>);
    }
}