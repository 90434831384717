import { MessageBar, MessageBarType } from '@fluentui/react';
import { Spinner, Stack } from 'office-ui-fabric-react';
import React, { Component } from 'react'
import { MyContext } from '../../../context';
import { getAllCustomFields, updateCallCustomField } from '../../../queries/querySettings';
import CustomFieldTypeCheckbox from './CustomFieldTypeCheckbox';
import CustomFieldTypeText from './CustomFieldTypeText';


class CustomFieldsConversation extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        let customFieldConversation = this.props.data.customFields !== null ? JSON.parse(this.props.data.customFields).customFields : []
        //check for action TTL
        let customFieldChangeDisable = this.getCustomFieldChangeDisable(customFieldConversation)
        //saving custom fileds with already one or more value
        //to know what custom fields are not empty when the user open the page (for show or not save button)
        let alreadyUsedFields = this.getAlreadyUsedFields(customFieldConversation)
        this.state = {
            error: "",
            processing: false,
            allCustomFields: null,
            customFieldConversation,
            customFieldChangeDisable,
            alreadyUsedFields,
            updatedFields: [],
            disableEditCustomFieldTime: this.props.userRules.ruleAccess.editableTimeCustomFields && !this.isTimeToEditOk()
        };
    }
    componentDidUpdate(prevProps) {
        // Check if props.data.customFields has changed
        if (this.props.data.customFields !== prevProps.data.customFields) {
            // Parse customFields and update state
            let customFieldConversation = this.props.data.customFields !== null ? JSON.parse(this.props.data.customFields).customFields : [];
            this.setState({ customFieldConversation });
        }
    }
    componentDidMount() {
        this.props.client
            .query({
                query: getAllCustomFields,
                variables: { auth: this.props.authorization }
            })
            .then((res) => {
                var allCustomFields = res.data.getAllCustomFieldDefinitions.filter(cf => {
                    return this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.id) || this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.fieldName);
                });
                //sort the list by accesibleCustomFields
                var sortCustomFields = (a, b) => {
                    let indexA = this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(a.fieldName) !== undefined ? this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(a.fieldName) : this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(a.id)
                    let indexB = this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(b.fieldName) !== undefined ? this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(b.fieldName) : this.props.userRules.ruleAccess.accesibleCustomFields.indexOf(b.id)
                    return indexA - indexB;
                }
                allCustomFields.sort(sortCustomFields);

                this.setState({
                    allCustomFields
                })
            });
    }

    getCustomFieldChangeDisable = (customFieldConversation) => {
        return customFieldConversation.map(cf => {
            if (cf.customFieldDefinition.isCheckbox && cf.customFieldDefinition.actionType == "SetTTl" && cf.fieldValues[0] == "yes") {
                return cf.fieldId
            }
        }).filter(element => { return element != undefined; })
    }

    getAlreadyUsedFields = (customFieldConversation) => {
        return customFieldConversation.map(cf => {
            if (cf.fieldValues.length > 0) {
                return cf.fieldId
            }
        }).filter(element => { return element != undefined; })
    }

    isTimeToEditOk = () => {
        try {
            let startDate = new Date(parseInt(this.props.data.callStartMs))
            let timeEditableDays = this.props.userRules.ruleAccess.customFieldsEditableTime
            let timeEditable = this.addDays(startDate, timeEditableDays)
            let today = new Date()
            //console.log(startDate)
            //console.log(timeEditable)
            return timeEditable.getTime() >= today.getTime()
        } catch (error) {
            console.log(error)
            return false
        }
    }

    addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }


    changeFieldValue = (fieldId, value) => {
        //console.log(fieldName + ": -" + value + "-")
        let newCustomFieldConversation = []
        let isUpdate = this.state.customFieldConversation.map(cf => { return cf.fieldId }).includes(fieldId)
        if (isUpdate) {
            if (value.length > 0) {
                newCustomFieldConversation = this.state.customFieldConversation.map(cf => {
                    if (cf.fieldId == fieldId) {
                        return ({
                            ...cf,
                            fieldValues: value
                        })
                    } else {
                        return cf
                    }
                })
            } else {
                newCustomFieldConversation = this.state.customFieldConversation.filter(cf => { return cf.fieldId !== fieldId })
            }
        } else {
            let customFieldDefinition = this.state.allCustomFields.filter(cf => { return cf.id === fieldId })[0]
            let newField = {
                fieldId: fieldId,
                fieldName: customFieldDefinition.fieldName,
                fieldValues: value,
                customFieldDefinition: customFieldDefinition
            }
            newCustomFieldConversation = [...this.state.customFieldConversation, newField]
        }
        let updatedFields = this.state.updatedFields;
        //for showing save button and update custom field: if value is not empty and is not already in the list of updated OR if the value is empty but are some old value in the system
        if ((value != '' && !this.state.updatedFields.includes(fieldId)) || (value == '' && this.state.alreadyUsedFields.includes(fieldId))) {
            updatedFields.push(fieldId)
        }
        //for hiding save button if value is empty and it was in the list of updated and there are not old value in the system
        if (value == '' && this.state.updatedFields.includes(fieldId) && !this.state.alreadyUsedFields.includes(fieldId)) {
            updatedFields = updatedFields.filter(f => f !== fieldId)
        }
        this.setState({
            customFieldConversation: newCustomFieldConversation,
            error: "",
            updatedFields
        })
    }


    getCustomFieldItems = () => {
        let fields = this.state.customFieldConversation.map(cf => { return cf.fieldId })
        let result = this.state.allCustomFields.map(customField => {
            if (fields.includes(customField.id)) {
                var customFieldsConversation = this.state.customFieldConversation.filter(cf => { return cf.fieldId === customField.id })[0]
                if (customField.isCheckbox) {
                    return (<Stack.Item key={customField.id} >
                        <CustomFieldTypeCheckbox
                            customFieldDefinition={customField}
                            customFieldConversation={customFieldsConversation}
                            changeCheckbox={this.changeFieldValue}
                            userRules={this.props.userRules}
                            isValid={this.isValid}
                            save={this.save}
                            processing={this.state.processing}
                            updatedFields={this.state.updatedFields}
                            disabled={this.props.disabled || this.state.disableEditCustomFieldTime}
                        />
                    </Stack.Item>)
                } else {
                    return (<Stack.Item key={customField.id}  >
                        <CustomFieldTypeText
                            customFieldDefinition={customField}
                            customFieldConversation={customFieldsConversation}
                            changeFieldText={this.changeFieldValue}
                            userRules={this.props.userRules}
                            isValid={this.isValid}
                            save={this.save}
                            processing={this.state.processing}
                            updatedFields={this.state.updatedFields}
                            disabled={this.props.disabled || this.state.disableEditCustomFieldTime}
                        />
                    </Stack.Item>)
                }
            } else {
                if (customField.isCheckbox) {
                    return (<Stack.Item key={customField.id} >
                        <CustomFieldTypeCheckbox
                            customFieldDefinition={customField}
                            changeCheckbox={this.changeFieldValue}
                            userRules={this.props.userRules}
                            isValid={this.isValid}
                            save={this.save}
                            processing={this.state.processing}
                            updatedFields={this.state.updatedFields}
                            disabled={this.props.disabled || this.state.disableEditCustomFieldTime}
                        />
                    </Stack.Item>)
                } else {
                    return (<Stack.Item key={customField.id} >
                        <CustomFieldTypeText
                            customFieldDefinition={customField}
                            changeFieldText={this.changeFieldValue}
                            userRules={this.props.userRules}
                            isValid={this.isValid}
                            save={this.save}
                            processing={this.state.processing}
                            updatedFields={this.state.updatedFields}
                            disabled={this.props.disabled || this.state.disableEditCustomFieldTime}
                        />
                    </Stack.Item>)
                }
            }
        })
        return result;
    }

    isValid = () => {
        let valid = true;
        this.state.customFieldConversation.forEach(cf => {
            if (cf.customFieldDefinition !== null && !cf.customFieldDefinition.isCheckbox) {
                cf.fieldValues.forEach(v => {
                    if ((v.length === 0 && cf.fieldValues.length !== 1) || (cf.customFieldDefinition.hasExactLength && v.length !== cf.customFieldDefinition.fieldLength && v != "")) {
                        valid = false
                    }
                })
            }
        })
        return valid;
    }

    isValidationActionsType = () => {
        //check for the specific field TTL
        let ttlChanged = this.state.customFieldConversation.filter(cf => this.state.customFieldChangeDisable.includes(cf.fieldId) && cf.fieldValues[0] == "no")
        return ttlChanged.length > 0
    }

    save = (cfId) => {
        if (!this.isValidationActionsType()) {
            this.setState({ processing: true })

            this.props.isProcessing(true)
            //clean the emptys
            let realCustomFields = this.state.customFieldConversation.filter(c => { return c.fieldValues.length > 0 && c.fieldValues[0] !== "" })
            let customFields = JSON.stringify({ customFields: realCustomFields })
            let conversationId = this.props.data.conversationId
            this.props.client
                .mutate({
                    mutation: updateCallCustomField,
                    variables: { customFields, conversationId }
                })
                .then(({ loading, error, data }) => {
                    var state = {}
                    if (data.updateCallCustomField !== null) {
                        let customFieldConversation = JSON.parse(data.updateCallCustomField).customFields
                        //check for action TTL
                        let customFieldChangeDisable = this.getCustomFieldChangeDisable(customFieldConversation)
                        let alreadyUsedFields = realCustomFields.map(cf => cf.fieldId)

                        //digit check validation error

                        if (cfId !== "") {
                            var cfIndex = customFieldConversation.findIndex(x => x.fieldId === cfId);

                            if (customFieldConversation[cfIndex].errorIndexes !== null && customFieldConversation[cfIndex].customFieldDefinition.hasAdditionalNumberCheck) {
                                if (customFieldConversation[cfIndex].customFieldDefinition.numberCheckAlgorithmType == "DigitCheck")


                                    customFieldConversation[cfIndex].errorIndexes.map(i => {
                                        document.getElementById(customFieldConversation[cfIndex].fieldName + i).style.border = "1px solid rgb(164, 38, 44)";
                                    })

                                this.setState({
                                    error: "valueFailedDigitCheck",
                                    processing: false
                                });
                            } else {
                                this.setState({ ...state, customFieldConversation, customFieldChangeDisable, processing: false, updatedFields: [], alreadyUsedFields })

                            }
                        } else {
                            this.setState({ ...state, customFieldConversation, customFieldChangeDisable, processing: false, updatedFields: [], alreadyUsedFields })

                        }
                    } else {
                        //error
                        this.setState({
                            error: "errorSavingCF"
                        })
                    }
                    this.props.isProcessing(false)
                });
        } else {
            //manage TTL changes
            let customFieldConversation = this.state.customFieldConversation.map(cf => {
                if (this.state.customFieldChangeDisable.includes(cf.fieldId) && cf.fieldValues[0] == "no") {
                    return ({
                        ...cf,
                        fieldValues: ["yes"]
                    })
                } else {
                    return cf
                }
            })
            let updatedFields = this.state.updatedFields.filter(cf => !this.state.customFieldChangeDisable.includes(cf))
            this.setState({
                error: "TTLerror",
                customFieldConversation,
                updatedFields
            })
            setTimeout(this.closeError, 5000)
        }
    }

    closeError = () => {
        this.setState({ error: "" })
    }

    render() {
        return (
            <div>
                {this.state.error !== "" && <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={this.closeError}
                    dismissButtonAriaLabel="Close"
                >
                    {this.context.getTranslation("settings", this.state.error)}
                </MessageBar>}
                {this.state.allCustomFields === null && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}
                {this.state.allCustomFields !== null && this.state.allCustomFields.length === 0 && <div>
                    <span style={{ fontWeight: "bold" }}>{this.context.getTranslation("common", "noData")}</span>
                </div>}
                {this.state.allCustomFields !== null && this.state.allCustomFields.length > 0 && <Stack horizontal={!this.props.alignVertical} wrap>
                    {this.getCustomFieldItems()}
                </Stack>}
            </div>
        )
    }
}

export default CustomFieldsConversation