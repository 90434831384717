import React, { Component } from 'react'
import "./SettingsView.scss"
import Utils from "./../common/Utils";
import { FontSizes, Button, Stack, Spinner } from 'office-ui-fabric-react/lib';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Route } from "react-router-dom";
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import gql from "graphql-tag";

import { DetailsListLayoutMode, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ChoiceGroup, ComboBox, DetailsList } from "office-ui-fabric-react";
import { Query } from "@apollo/react-components";
import { queryGetCategory } from "../../queries/queryCategory";
import { MyContext } from '../../context';
import { TranscriptLanuages } from '../SettingsView/TranscriptLangArr';

export default class SettingsView extends Component {
    constructor(props) {
        super(props);
        this.reportConf = React.createRef();
        this.transcriptLanguages = [];;
        let curkey;
        if (Utils.lsGet("viewType") === "true" ? false : true) {
            curkey = "grid"
        } else {
            curkey = "list"
        }
        this.state = {
            helpMode: Utils.lsGet("helpMode") === "true" ? true : false,
            viewType: Utils.lsGet("viewType") === "true" ? true : false,
            selectedViewType: this.props.userRules != null ? this.props.userRules.viewType : "grid",
            stackStyle: {
                marginLeft: "15px",
                marginRight: "15px"
            },
            selectedLanguage: this.props.userRules.ruleAnalytics != null ? this.props.userRules.ruleAnalytics.transcriptLanguage : "en-US",
            switchRecording: this.props.userRules.ruleRecording != null ? (this.props.userRules.ruleRecording.inboundType == "Bulk" ? true : false) : false
        };
    };
    handleCountry = (event, option, index, value) => {

        this.setState({ selectedLanguage: option.key });
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($transcriptLanguage: String) {
                    setTranscriptLanguage (transcriptLanguage: $transcriptLanguage)
                }
                `,
                variables: { transcriptLanguage: option.key }
            });
    }
    onChangeViewType = (event, option, index, value) => {
        this.setState({ selectedViewType: option.key });
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($viewType: String) {
                    setViewType(viewType: $viewType)
                }
                `,
                variables: { viewType: option.key }
            });
        let ke;
        switch (option.key) {

            case "grid":
                ke = true;
                break;
            case "list":
                ke = false;
                break;
            default:
                ke = true;
                break;
        }
        this.setState({ viewType: ke });
        Utils.lsSet("viewType", ke ? "false" : "true");
    }

    onChangeHelpMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean) => {
        this.setState({ helpMode: checked });
        Utils.lsSet("helpMode", checked ? "true" : "false");
        console.log("helpMode: " + checked);
    }

    onSwitchRecording = (ev: React.MouseEvent<HTMLElement>, checked: boolean) => {
        this.setState({ switchRecording: checked });
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($state: Boolean) {
                    switchRecording (state: $state)
                }
                `,
                variables: { state: checked }
            })
    }

    onCreateChatConversations = (ev: React.MouseEvent<HTMLElement>) => {
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($userId: String) {
                    createChatConversations (userId: $userId)
                }
                `,
                variables: { userId: this.props.userId }
            })
    }

    render() {
        return (<MyContext.Consumer>
            {(context) => (

                <div className={window.location.pathname.includes("main") ? "settings-wrapper-main" : "settings-wrapper"}>
                    <Stack vertical >
                            <Route exact path="/settings" render={() => {
                                return (
                                    <Stack vertical style={this.state.stackStyle} tokens={{ childrenGap: 5 }}>
                                        <Text variant='xLarge'>{context.getTranslation("settings", "navigation")}</Text>

                                        {(this.props.userRules.ruleAccess != null && (this.props.userRules.ruleAccess.confAddUser || this.props.userRules.ruleAccess.confListUser)) && <Stack.Item>
                                            <Button
                                                disabled={false}
                                                iconProps={{ iconName: 'Setting' }}
                                                text={context.getTranslation("settings", "userConfiguration")}
                                                style={{ width: "200px", padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                                                onClick={async () => {
                                                    window.location.href = "/rules/";
                                                }}
                                            />
                                        </Stack.Item>}

                                        {(this.props.userRules.ruleAccess != null && (this.props.userRules.ruleAccess.tenantCreate || this.props.userRules.ruleAccess.tenantChange)) && <Stack.Item>
                                            <Button
                                                disabled={false}
                                                iconProps={{ iconName: 'Setting' }}
                                                text={context.getTranslation("settings", "tenantConfiguration")}
                                                style={{ width: "200px", padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                                                onClick={async () => {
                                                    window.location.href = "/tenant/";
                                                }}
                                            />
                                        </Stack.Item>}                                     
                                        {(this.props.userRules.ruleAccess != null && !this.props.userRules.ruleAccess.userDenyUpload) && <Stack.Item>
                                            <Button
                                                disabled={false}
                                                iconProps={{ iconName: 'Setting' }}
                                                text={context.getTranslation("helpConversationUpload", "upload")}
                                                style={{ width: "200px", padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                                                onClick={async () => {
                                                    window.location.href = "/upload/";
                                                }}
                                            />
                                        </Stack.Item>}
                                        {(this.props.userRules.ruleAccess != null && (this.props.userRules.ruleAccess.confTicket)) && <Stack.Item>
                                            <Button
                                                disabled={false}
                                                iconProps={{ iconName: 'Setting' }}
                                                text={context.getTranslation("settings", "ticket")}
                                                style={{ width: "200px", padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                                                onClick={async () => {
                                                    window.location.href = "/ticket/";
                                                }}
                                            />
                                        </Stack.Item>}
                                    </Stack>
                                )
                            }} />


                     

                        <Stack vertical style={this.state.stackStyle}>
                            <Text variant='xLarge'>{context.getTranslation("settings", "tooltip")}</Text>
                            <Toggle
                                defaultChecked={this.state.helpMode}
                                label={context.getTranslation("settings", "enabled")}
                                onText={context.getTranslation("settings", "on")}
                                offText={context.getTranslation("settings", "off")}
                                onChange={this.onChangeHelpMode}
                            />
                        </Stack>

                        <Stack vertical style={this.state.stackStyle}>
                            <Text variant='xLarge'>{context.getTranslation("settings", "appearance")}</Text>
                            <Stack.Item>
                                <ChoiceGroup
                                    selectedKey={this.state.selectedViewType}
                                    options={[
                                        {
                                            key: 'list',
                                            iconProps: { iconName: 'List' },
                                            text: context.getTranslation("settings", "listView")  // This text is long to show text wrapping.
                                        },
                                        {
                                            key: 'grid',
                                            iconProps: { iconName: 'GridViewSmall' },
                                            text: context.getTranslation("settings", "tileView")
                                        }
                                    ]}
                                    onChange={this.onChangeViewType}
                                />
                            </Stack.Item>
                        </Stack>
                        {this.props.userRules.ruleRecording != null && (this.props.userRules.ruleRecording.audioType == "Switch") && <Stack vertical style={this.state.stackStyle}>
                            <Text variant='xLarge'>Switch Recording</Text>
                            <Toggle
                                defaultChecked={this.state.switchRecording}
                                label="Enabled"
                                onText="On"
                                offText="Off"
                                onChange={this.onSwitchRecording}
                            />
                        </Stack>}
                        {this.props.userRules.ruleAnalytics != null && this.props.userRules.ruleAnalytics.withTranscription &&  (!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsAnalyticsChange) && <Stack vertical style={this.state.stackStyle}>
                            <Text variant='xLarge'>{context.getTranslation("settings", "analyticsSettings")}</Text>
                            <Stack.Item>

                                <TranscriptLanuages
                                    label={context.getTranslation("settings", "selectDefaultLanguageTranscription")}
                                    selectedKey={this.state.selectedLanguage}
                                    onChange={this.handleCountry}
                                    placeholder={context.getTranslation("common", "selectAnOption")}
                                />
                                
                            </Stack.Item>
                        </Stack>}
                        {false && <Stack vertical style={this.state.stackStyle}>

                            <Stack.Item>
                                {
                                    console.log(this.state)
                                }
                                <Query
                                    query={queryGetCategory}
                                    variables={this.props.authorization} >
                                    {({ loading, error, data }) => {
                                        if (loading) return <div>
                                            <Spinner label={context.getTranslation("common", "loadingCategories")} ariaLive="assertive" labelPosition="top" />
                                        </div>;
                                        if (error) return <h1> </h1>;

                                        if (data.getCategoryData == null) {
                                            return <div></div>;
                                        }
                                        this.tmpKey = [];
                                        this.tmpKey.push({ key: "Header1", text: "Categories", itemType: 2 });
                                        const stack = data.getCategoryData.categorydata;
                                        if (data.getCategoryData.categorydata != null) {
                                            for (let x = 0; x < stack.length; x++) {
                                                let i = {
                                                    name: stack[x].name,
                                                    text: stack[x].name,
                                                    keywords: stack[x].keywords,
                                                    id: stack[x].id,
                                                    key: stack[x].id,
                                                    tenantId: stack[x].tenantId,
                                                    frequence: stack[x].frequence,
                                                    sentiments: stack[x].sentiments,
                                                };
                                                this.tmpKey.push(i);
                                            }
                                        }

                                        return <ComboBox
                                            style={{ width: "300px" }}
                                            label={context.getTranslation("common", "categorySelection")}
                                            selectedKey={this.state.selectedOptionKeys}
                                            allowFreeform={false}
                                            onChange={this.onChangeMulti}
                                            autoComplete={true}
                                            options={this.tmpKey}
                                            multiSelect
                                        />
                                    }}
                                </Query>
                            </Stack.Item>
                            <br />
                            {this.renderKeyList()}
                        </Stack>}
                    </Stack>
                </div>
            )
            }
        </MyContext.Consumer>);
    }
    columns = [
        { key: 'col1', name: 'Category', fieldName: 'text', minWidth: 100, maxWidth: 200, isResizable: true },
    ];

    renderKeyList = () => {
        if (this.state.sel4list !== undefined && this.state.sel4list != null) {
            return <Stack.Item style={{ paddingTop: "15px" }}>
                <DetailsList
                    items={this.state.sel4list}
                    columns={this.columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    selectionMode={SelectionMode.none}
                />
            </Stack.Item>
        }
    }

    onChangeMulti = (event, option, index, value) => {
        console.log('_onChangeMulti() is called: option = ' + JSON.stringify(option));
        const currentSelectedKeys = this.state.selectedOptionKeys || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            let xx = [];

            for (let i = 0; i < tmp.length; i++) {

                xx.push(this.findKeys(tmp[i]))
            }

            this.setState({
                selectedOptionKeys: tmp,
                sel4list: xx
            });
        } else if (value != null) {
            // User typed a freeform option
            const newOption = { key: value, text: value };
            const updatedSelectedKeys: string[] = [...currentSelectedKeys, newOption.key];
            this.setState({
                optionsMulti: [...this.state.optionsMulti, newOption],
                selectedOptionKeys: updatedSelectedKeys
            });
        }
    };
    updateSelectedOptionKeys = (selectedKeys, option): string[] => {
        selectedKeys = [...selectedKeys]; // modify a copy
        const index = selectedKeys.indexOf(option.key);
        if (option.selected && index < 0) {
            selectedKeys.push(option.key);
        } else {
            selectedKeys.splice(index, 1);
        }
        return selectedKeys;
    };
    findKeys = (k) => {
        let out;
        for (let x = 0; x < this.tmpKey.length; x++) {
            if (k === this.tmpKey[x].key) {
                out = this.tmpKey[x];
                break;
            }
        }
        return out;
    }
}

