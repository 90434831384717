import gql from 'graphql-tag';

export const queryAllPolicies = gql`query  {
                getPolicies  {
                         id
                        name
                        description
                        riskRating
                        channel
                        active
                        basedOn
                        supervisors {
                            id
                            name
                            username
                        }
                        policyRules {
                            id
                            name
                        }
                        aICategorizationInfos {
                            aICategorizationId
                            occurrences
                            categorizationName
                        }
                        users {
                            id
                            name
                            username
                        }
                        groups {
                            id
                            name
                        }
                        aIScorecardInfos{
                            scorecardId
                            conversationType
                            scorecardName
                        }
                    }
         }`

export const queryAllPolicyVersions = gql`query  {
                getAllPolicyVersions  {
                         id
                         name
                         version
                         selected
                    	 basedOn
                    }
         }`

export const queryPolicy = gql`query ent ($id: String) {
                getPolicy (id: $id) {
                         id
                        name
                        description
                        riskRating
                        channel
                        active
                        userId
                        tenantId
                        supervisors {
                            id
                            userId
                            name
                            username
                            addOnCompliance
                        }
                        policyRules {
                            id
                            name
                            masterItemId
                        }
                        users {
                            id
                            userId
                            name
                            username
                            addOnCompliance
                        }
                        groups {
                            id
                            name
                        }
                        version
                        masterItemId
                        selected
                        metaAssigneeUsersDeleted
                        metaMonitorUsersDeleted
                        basedOn
                        conversationSafeFolders{
                            id
                        }
                        aICategorizationInfos{
                            aICategorizationId
                            occurrences
                        }
                        aIScorecardInfos{
                            scorecardId
                            conversationType
                        }
                    }
         }`

export const createPolicy = gql`mutation ent($policy: PolicyInputType){
                                        createPolicy(policy: $policy)  {
                                            text
                                            status
                                            id
            }
                                        }`


export const deletePolicy = gql`mutation ent ($id: String) {
                                        deletePolicy (id: $id) {
                                            text
                                            status
                                            id
            }
                                    }`

export const changeStatusPolicy = gql`mutation ent ($policyId: String) {
                                        changeStatusPolicy (policyId: $policyId) {
                                            text
                                            status
                                            id
            }
                                    }`

export const getAllVersionOfPolicy = gql`query ent ($masterItemId: String) {
                                        getAllVersionOfPolicy(masterItemId: $masterItemId) {
                                                id
                                                version
                                            }
                                    }`

export const restorePolicy = gql`mutation ent ($id: String) {
                                        restorePolicy(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`