import { CommandBarButton, PanelType, Spinner, Stack, Panel } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { MyContext } from '../../../context';
import AIAdherencePhrasesListView from './AIAdherencePhrasesListView';
import * as query from './AIAdherencePhrasesQuery';
import EditAIAdherencePhraseContainer from './Panels/EditAIAdherencePhraseContainer'
import AlertBar from '../../CustomComponents/AlertBar';
import SafetyDialog from '../../CustomComponents/SafetyDialog';

export default class AIAdherencePhrases extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            isPanelOpen: false,
            phraseId: null,
            warningMessage: "",
            panelTitle: null,
            alertMessagePanel: "",
            hideDialogDelete: true,
            isProcessing: false
        };
    }

    openPanel = (title, phraseId) => {
        if (title === "addPhrase" || (title === "editPhrase" && phraseId !== null)) {
            this.setState({
                isPanelOpen: true,
                panelTitle: title,
                warningMessage: ""
            })
        } else {
            this.setState({
                warningMessage: this.context.getTranslation("compliance", "selectPhrase"),
                alertType: "warning"
            })
        }

    }

    getHeaderPanel = () => {
        return (
            <div style={{ flex: "auto", marginLeft: "20px" }}>
                <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>{this.context.getTranslation("compliance", this.state.panelTitle)}</div>
                <AlertBar
                    message={this.state.alertMessagePanel}
                    alertType={"error"}
                    clearAlert={this.clearAlert}
                />
            </div >
        )
    }

    hidePanel = () => {
        this.setState({ isPanelOpen: false, alertMessagePanel: "" });
    };

    selectPhrase = (id) => {
        this.setState({
            phraseId: id,
            warningMessage: ""
        });
    }

    setMessageError = (message) => {
        this.setState({
            alertMessagePanel: message
        })
    }

    refreshPhrases = () => {
        this.refetchQueryPhrases()
    }

    refetchQueryPhrases = null;

    deletePhrase = () => {
        this.setState({ hideDialogDelete: true, isProcessing: true })
        if (this.state.phraseId !== null) {
            const id = this.state.phraseId
            this.props.apol
                .mutate({
                    mutation: query.deleteAdherencePhrase,
                    variables: { id }
                })
                .then((result) => {
                    if (result.data.deleteAIAdherencePhrase.status !== "ok") {
                        this.setState({
                            warningMessage: this.context.getTranslation("compliance", result.data.deleteAdherencePhrase.text),
                            alertType: "error",
                            isProcessing: false
                        })
                    } else {
                        this.setState({
                            phraseId: null,
                            isProcessing: false
                        })
                        this.refetchQueryPhrases();
                    }
                });
        }
    }

    openDialogDelete = () => {
        if (this.state.phraseId !== null) {
            this.setState({ hideDialogDelete: false })
        } else {
            this.setState({
                warningMessage: this.context.getTranslation("compliance", "selectPhrase"),
                alertType: "warning"
            })
        }
    }

    render() {
        return (<div style={{ overflow: "auto", maxHeight: window.innerHeight - 120, position: 'relative', marginTop: "5px" }}>
            {/*Manage button add, edit and delete*/}
            <Stack horizontal styles={{ root: { height: 44 } }}>
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Add' }}
                    text={this.context.getTranslation("compliance", "addPhrase")}
                    disabled={false}
                    checked={false}
                    onClick={() => { this.openPanel("addPhrase", null) }}
                />
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Edit' }}
                    text={this.context.getTranslation("compliance", "editPhrase")}
                    disabled={false}
                    checked={false}
                    onClick={() => { this.openPanel("editPhrase", this.state.phraseId) }}
                />
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Delete' }}
                    text={this.context.getTranslation("compliance", "deletePhrase")}
                    onClick={this.openDialogDelete}
                />
            </Stack>

            {/*Warning and error message*/}
            <AlertBar
                message={this.state.warningMessage}
                alertType={this.state.alertType}
                clearAlert={() => { this.setState({ warningMessage: "" }) }}
            />

            {this.state.isProcessing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}

            {/*Phrases datailList*/}
            {!this.state.isProcessing && < Stack style={{ marginTop: 10 }}>
                <Query
                    query={query.queryAllAdherencePhrases}
                    notifyOnNetworkStatusChange={true}
                >
                    {({ loading, error, data, refetch, networkStatus }) => {
                        this.refetchQueryPhrases = refetch
                        if (loading) return (
                            <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                        )
                        if (error) {
                            console.log(error)
                            return (<AlertBar message={"Error!"} alertType={"error"} />);
                        }
                        if (data.getAIAdherencePhrases == null || data.getAIAdherencePhrases.length === 0)
                            return (<AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />);
                        return (
                            <div>
                                <AIAdherencePhrasesListView selectPhrase={this.selectPhrase} phrases={data.getAIAdherencePhrases} />
                            </div>
                        )
                    }}
                </Query>
            </Stack>}

            {/*Phrase panel*/}
            <Panel
                isOpen={this.state.isPanelOpen}
                onDismiss={this.hidePanel}
                isFooterAtBottom={false}
                onRenderHeader={this.getHeaderPanel}
                type={PanelType.medium}
            >
                <EditAIAdherencePhraseContainer
                    apol={this.props.apol}
                    auth={this.props.auth}
                    hidePanel={this.hidePanel}
                    phraseId={this.state.panelTitle === "addPhrase" ? null : this.state.phraseId}
                    refreshPhrases={this.refreshPhrases}
                    setMessageError={this.setMessageError} />
            </Panel>

            {/*SafetyDialog for delete adherence phrase*/}
            <SafetyDialog
                title={this.context.getTranslation("compliance", "deletePhrase")}
                subText={this.context.getTranslation("compliance", "confirmDelete")}
                hideDialog={this.state.hideDialogDelete}
                closeDialog={() => { this.setState({ hideDialogDelete: true }) }}
                ok={this.deletePhrase}
            />
        </div>)
    }
}