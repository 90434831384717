import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from 'office-ui-fabric-react'
import React, { Component } from 'react'
import { MyContext } from '../../context';

export default class SafetyDialog extends Component {

    static contextType = MyContext

    render() {
        const dialogContentProps = {
            type: DialogType.normal,
            title: this.props.title,
            closeButtonAriaLabel: 'Close',
            subText: this.props.subText,
        };
        return (
            <Dialog
                hidden={this.props.hideDialog}
                onDismiss={this.props.closeDialog}
                dialogContentProps={dialogContentProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={this.props.ok} text={this.context.getTranslation("common", "ok")} />
                    <DefaultButton onClick={this.props.closeDialog} text={this.context.getTranslation("common", "cancel")} />
                </DialogFooter>
            </Dialog>
        )
    }
}