import React, { Component } from 'react';
import { Stack, Text } from 'office-ui-fabric-react';
import { MyContext } from '../../context';

// Styles definition
const stackContainerStyles: IStackStyles = {
    root: {
        paddingTop: 50
    }
};
const stackStyles: IStackStyles = {
    root: {
        maxWidth: 660,
        marginBottom: 50
    }
};
const stackTokens: IStackTokens = { childrenGap: 5 };

export default class ComplianceLicense extends Component {
    static contextType = MyContext

    render() {
        return (<div>
            <Stack tokens={stackTokens}>
                <Stack horizontalAlign="center" styles={stackContainerStyles}>
                    <Stack styles={stackStyles}>
                        <Text variant="xxLarge">{this.context.getTranslation("compliance", "noLicense")}</Text>
                        <Text variant="xLarge">{this.context.getTranslation("compliance", "noLicenseMessage")}</Text>
                    </Stack>
                </Stack>
            </Stack>
        </div>
        )
    }
}