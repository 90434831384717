import { Checkbox, PrimaryButton, Stack } from 'office-ui-fabric-react';
import React, { Component } from 'react'
import { MyContext } from '../../../context';

class CustomFieldTypeCheckbox extends Component {

    static contextType = MyContext

    changeCheckbox = (value) => {
        this.props.changeCheckbox(this.props.customFieldDefinition.id, [value])
    }

    render() {
        let value = this.props.customFieldConversation !== undefined ? this.props.customFieldConversation.fieldValues[0] : null
        return (
            <div style={{ margin: "10px", padding: "10px", boxShadow: "rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px" }}>
                <h5>{this.props.customFieldDefinition.fieldName}</h5>
                <Stack style={{ paddingTop: "10px" }} horizontal >
                    <div>
                        <Checkbox style={{ padding: "10px" }} label={this.context.getTranslation("common", "yes")} checked={value === "yes"} onChange={() => this.changeCheckbox("yes")} disabled={this.props.userRules.ruleAccess.customFieldsAccessType !== "FullAccess" || this.props.disabled} />
                    </div>
                    <div style={{ marginLeft: "10px" }} >
                        <Checkbox label={this.context.getTranslation("common", "no")} checked={value === "no"} onChange={() => this.changeCheckbox("no")} disabled={this.props.userRules.ruleAccess.customFieldsAccessType !== "FullAccess" || this.props.disabled} />
                    </div>
                </Stack>
                {this.props.updatedFields.includes(this.props.customFieldDefinition.id) &&
                    <Stack horizontal style={{ flexFlow: "row-reverse", marginTop: "10px" }}>
                        <PrimaryButton text={this.context.getTranslation("common", "save")} onClick={()=>this.props.save("")} disabled={!this.props.isValid() || this.props.processing || this.props.userRules.ruleAccess.customFieldsAccessType !== "FullAccess" || this.props.disabled} />
                    </Stack>
                }
            </div>
        )
    }
}

export default CustomFieldTypeCheckbox