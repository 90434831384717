import { DetailsList, MarqueeSelection, Selection, SelectionMode } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../context';

export default class AIQuestionsListView extends Component {
    static contextType = MyContext
    _selection: Selection;

    constructor(props) {
        super(props);
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.props.selectAIQuestion(this._selection.getSelection().length > 0 ? this._selection.getSelection()[0].id : null)
            }
        });
    }

    _getKey = (item, index) => {
        return item.key;
    }

    render() {
        const columns = [
            { key: 'name', name: this.context.getTranslation("compliance", "detailPolicyName"), fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
            { key: 'description', name: this.context.getTranslation("compliance", "detailPolicyDescription"), fieldName: 'description', minWidth: 100, maxWidth: 200 },
            { key: 'question', name: this.context.getTranslation("compliance", "question"), fieldName: 'question', minWidth: 100, maxWidth: 200 },
        ]

        return (
            <MarqueeSelection selection={this._selection}>
                <DetailsList
                    items={this.props.aiQuestions}
                    groups={this.props.aiQuestionsGroup}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    selection={this._selection}
                    getKey={this._getKey}
                    setKey="single"
                    onRenderItemColumn={this._renderItemColumn}
                />
            </MarqueeSelection>
        );
    }
}