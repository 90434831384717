import React, { Component } from 'react'
import { Spinner, Stack, Text } from 'office-ui-fabric-react';
import "./DetailView.scss"
import LiveContainer from './DetailContainer/LiveContainer'
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { ApolloProvider } from "react-apollo";
import { Query } from "react-apollo";
import { queryGetLiveEntry } from "./../../queries/queryRecordings";
import { MyContext } from '../../context';
import Utils from "../../components/common/Utils";


export default class LiveViewOnly extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: Utils.guidGenerator()
        }
    }

    renderData = (id, auth) => (
        <MyContext.Consumer>
            {(context) =>
            (
                <div style={{ margin: "25px" }}>
                    <Query
                        query={queryGetLiveEntry}
                        variables={{ id, auth }} pollInterval={10000}>
                        {({ loading, error, data }) => {
                            if (loading) return (
                                <Stack>
                                    <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                </Stack>);
                            if (error) return <div></div>;
                            if (data.getLiveEntry == null) return <div></div>;
                            return (
                                <Stack>
                                    <LiveContainer
                                        apol={this.props.apol}
                                        authorization={this.props.authorization}
                                        data={data.getLiveEntry}
                                        userRules={this.props.userRules}
                                    />
                                </Stack>);
                        }}
                    </Query>
                </div>
            )
            }
        </MyContext.Consumer>
    );

    render() {
        //console.log("renderLiveView!");
        if (this.state.id != null) {
            //console.log("Client");
            //console.log(this.props.client);
            return (<ApolloProvider client={this.props.client}>
                {this.renderData(this.state.id, this.props.authorization)}
            </ApolloProvider>);
        }
        else {
            return ""
        }
    }

    showPanel = (itemId) => {
        this.setState({ id: itemId, isPanelOpen: true });
    };

    hidePanel = () => {
        this.setState({ id: null, isPanelOpen: false });
    };
}
