import gql from 'graphql-tag';

export const queryAllScorecards = gql`query  {
                getScorecards  {
                        id
                        name
                        description
                        automatedEvaluation
                        settingItems{
                            name
                            maxRange
                            minRange
                            color
                        }
                        sections{
                            name
                            evaluationItems{
                                name
                                itemType
                                itemId
                                itemValue
                                points
                                awareOfPoints
                            }
                        }
                        tenantId
                        userId
                        version
                        masterItemId
                        selected
                        creator
                        creationDate
                        lastUpdateDate
                        scorecardGroup{
                            id
                            name
                            generalGroup
                        }
                    }
         }`

export const queryScorecard = gql`query ent ($id: String)  {
                getScorecard (id: $id) {
                        id
                        name
                        description
                        automatedEvaluation
                        settingItems{
                            name
                            maxRange
                            minRange
                            color
                        }
                        sections{
                            name
                            evaluationItems{
                                name
                                itemType
                                itemId
                                itemValue
                                points
                                awareOfPoints
                            }
                        }
                        tenantId
                        userId
                        version
                        masterItemId
                        selected
                        creationDate
                    }
         }`

export const queryScorecardsLabels = gql`query {
                getScorecards {
                    id
                    name
                    description
                    masterItemId
                }
        }`

export const updateScorecard = gql`mutation 
                ent($scorecard: ScorecardInputType, $upsertToTemplate: Boolean){
                    upsertScorecard(scorecard: $scorecard, upsertToTemplate: $upsertToTemplate )  {
                        text
                        status
                        id
                    }
               }`

export const deleteScorecardQuery = gql`mutation 
            ent ( $id: String) {
                deleteScorecard ( id: $id)
                    {
                        text
                        status
                        id
                    }
             }`

export const getAllVersionOfScorecard = gql`query ent ($masterItemId: String) {
                                        getAllVersionOfScorecard(masterItemId: $masterItemId) {
                                                id
                                                version
                                            }
                                    }`

export const restoreScorecard = gql`mutation ent ($id: String) {
                                        restoreScorecard(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`

export const getScorecardResults = gql`query GetScorecardResults($conversationId: String!) {
                                          getScorecardResults(conversationId: $conversationId) {
                                            id
                                            policyId
                                            conversationId
                                            sections {
                                              name
                                              evaluationItems {
                                                points
                                                comment
                                                name
                                                itemValue
                                                itemId
                                                itemName
                                                itemType
                                                score
                                                editedBy
                                                awareOfPoints
                                              }
                                            }
                                            tenantId
                                            userId
                                            assessorComment
                                            scorecardName
                                            automatedEvaluation
                                            supervisors
                                            settingItems{
                                                name
                                                maxRange
                                                minRange
                                                color
                                            }
                                          }
                                        }`

export const upsertScorecardResult = gql`mutation ent($scorecardResult: ScorecardResultInputType) {
                                            upsertScorecardResult(scorecardResult: $scorecardResult) {
                                                id
                                                text
                                                status
                                            }
                                        }`

export const deleteScorecardResult = gql`mutation ent($id: String) {
                                            deleteScorecardResult(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                        }`
