import React, { Component } from 'react'
import { Button } from "office-ui-fabric-react";
import * as Azure from "@azure/storage-blob";
import * as path from "path";
import { TranscriptLanuages } from './../SettingsView/TranscriptLangArr'
import videoIndexerLangArr from './../SettingsView/VideoIndexerLangArr'

import gql from "graphql-tag";
import './UpLoad.scss'
import Utils from "./../common/Utils";
import UploadMail from "./UploadMail";
import { ComboBox } from 'office-ui-fabric-react/lib/';
import { FontSizes } from 'office-ui-fabric-react/lib';
import { MyContext } from '../../context';

const ONE_MINUTE = 60 * 1000;
const aborter = Azure.Aborter.timeout(30 * ONE_MINUTE);
const content = "Test!";
const validFilename = require('valid-filename');

export default class Upload extends Component {
    constructor(props) {
        super(props);

        this.uploadMail = React.createRef();
        this.fileInputRef = React.createRef();

        this.state = {
            auth: props.authorization,
            uploadActive: false,
            contactButtonActive: false,
            helpPanel: false,
            language: "auto"
        }
    }

    getBlockSize(file) {
        const size32Mb = 1024 * 1024 * 32;
        const size4Mb = 1024 * 1024 * 4;
        const size512Kb = 1024 * 512;

        return file.size > size32Mb ? size4Mb : size512Kb;
    }

    async execute(file) {
        // Get SAS url for Tenant and User write access to Blob Storage
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($tenantId : String!, $userId: String, $sessionToken: String) {
                    createBlob (input: { tenantId: $tenantId, userId: $userId, sessionToken: $sessionToken})
                }
                `,
                variables: { tenantId: "", userId: "", sessionToken: "" }
            })
            .then(async result => {
                const anonymousCredential = new Azure.AnonymousCredential();
                const pipeline = Azure.StorageURL.newPipeline(anonymousCredential);
                if (result.data.createBlob === "NO LICENSE") {
                    document.getElementById('result').innerHTML = "No License. Please buy one from ASC Technologies AG";
                    document.getElementById('licenseButton').innerHTML = "Contact ASC";

                    this.setState({ uploadActive: false, contactButtonActive: true });
                }
                else {
                    const serviceURL = new Azure.ServiceURL(result.data.createBlob, pipeline);

                    const containerURL = Azure.ContainerURL.fromServiceURL(serviceURL, Date());
                    var filePath = path.resolve(file.name);

                    const fileName = path.basename(filePath).replace('.md', '-stream.md');
                    if (!validFilename(file.name)) {
                        document.getElementById('result').innerHTML = "";
                        document.getElementById('error').innerHTML = "No valid filename"
                    } else {
                        const blobName = fileName;

                        const blockBlobURL = Azure.BlockBlobURL.fromContainerURL(containerURL, blobName);
                        await blockBlobURL.upload(aborter, content, content.length);

                        const options = {
                            blockSize: this.getBlockSize(file), parallelism: 10, progress: (transferProgressEvent) => {
                                document.getElementById('result').innerHTML = "Upload: " + Math.round((transferProgressEvent.loadedBytes / file.size) * 100) + "%"
                                document.getElementById('error').innerHTML = " File: " + file.name
                            }
                        };
                        await Azure.uploadBrowserDataToBlockBlob(aborter, file, blockBlobURL, options);
                        document.getElementById('result').innerHTML = "File uploaded. Please navigate to Recordings tab.";
                        document.getElementById('error').innerHTML = ""
                        this.props.apol
                            .mutate({
                                mutation: gql`
                        mutation ent ($tenantId : String!, $userId: String, $sessionToken: String, $blobUrl: String, $headerName: String, $language: String) {
                            processBlob (input: { tenantId: $tenantId, userId: $userId, sessionToken: $sessionToken} , blobUrl: $blobUrl, headerName: $headerName, language: $language)
                     }
                     `,
                                variables: {
                                    tenantId: "",
                                    userId: "",
                                    sessionToken: "",
                                    blobUrl: blockBlobURL.url,
                                    headerName: blobName,
                                    language: (this.state.language != null && this.state.language !== "auto") ? this.state.language : null
                                }
                            });
                    }
                    this.setState({ uploadActive: false });
                }
            });
    };

    onSendMail = (ev) => {
        if (this.uploadMail.current) {
            this.uploadMail.current.showPanel();
        }
    }

    render() {
        return (<MyContext.Consumer>{(context) => (
            <div>
                {(this.props.userRules.ruleAccess.userDenyUpload == null || !this.props.userRules.ruleAccess.userDenyUpload) && <div className="upload-body">
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ padding: "8px", fontSize: FontSizes.icon }} >
                        {(this.props.userRules.analytics === "VideoIndexer" || (this.props.userRules.ruleAnalytics != null && this.props.userRules.ruleAnalytics.withVideoIndexer)) &&
                            <ComboBox
                                defaultSelectedKey={"auto"}
                                selectedKey={this.state.language}
                                label={context.getTranslation("upload", "uploadIndexer")}
                                onChange={(event, option, index, newValue) => {
                                    this.setState({
                                        language: option.key,
                                    });
                                }}
                                options={videoIndexerLangArr}
                            />}
                        {(this.props.userRules.analytics === "SpeechToText" || (this.props.userRules.ruleAnalytics != null && this.props.userRules.ruleAnalytics.withTranscription)) &&
                            <TranscriptLanuages
                                defaultSelectedKey={this.props.userRules.ruleAnalytics != null && this.props.userRules.ruleAnalytics.transcriptLanguage != null ? this.props.userRules.ruleAnalytics.transcriptLanguage : "auto"}
                                selectedKey={this.state.language}
                                label={context.getTranslation("upload", "uploadSpeech")}
                                onChange={(event, option, index, newValue) => {
                                    this.setState({
                                        language: option.key,
                                    });
                                }}
                            />
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11" style={{ padding: "8px", fontSize: FontSizes.icon }} >
                        <Button
                            disabled={this.state.uploadActive}
                            text={context.getTranslation("upload", "uploadFile")}
                            style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                            onClick={async () => {
                                document.getElementById('result').innerHTML = '';
                                document.getElementById('error').innerHTML = '';

                                if (this.fileInputRef.current) {
                                    this.fileInputRef.current.click()
                                }
                            }}
                        />

                        <Button id="licenseButton" disabled={!this.state.contactButtonActive} style={this.state.contactButtonActive ? { backgroundColor: 'lightgrey', padding: "8px", fontSize: FontSizes.icon } : { padding: "8px", fontSize: FontSizes.icon }} onClick={() => { this.onSendMail() }} ></Button>
                    </div>
                    <UploadMail
                        id={Utils.guidGenerator()}
                        authorization={this.props.authorization}
                        apol={this.props.apol}
                        ref={this.uploadMail} />

                    <div className="ms-Grid-row" style={{ padding: "10px" }}>
                    </div>
                    <div style={{ padding: "10px" }}>
                        <input type="file"
                            id="files" onChange={async () => {
                                var files = document.getElementById('files').files;
                                if (!files.length) {
                                    alert('Please select a file');
                                } else {
                                    if (!validFilename(files[0])) {
                                        alert('Please input a real filenmae!');
                                    } else {
                                        document.getElementById('result').innerHTML = 'Log in...';
                                        this.setState({ uploadActive: true });
                                        document.getElementById('result').innerHTML = 'Upload running...';
                                        this.execute(files[0]).then(() => console.log("Done")).catch((e) => {
                                            document.getElementById('error').innerHTML = 'Error...' + e; this.setState({ uploadActive: false });
                                        });
                                    }
                                }
                            }} ref={this.fileInputRef} style={{ display: "none" }} accept="video/x-flv, video/mp4, audio/mp4, application/x-mpeg, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv, audio/wav, .mp3" name="avatar" />
                        <div id="result"></div>
                        <div id="error"></div>
                    </div>
                </div>}
            </div>)}</MyContext.Consumer>
        );
    }
}
