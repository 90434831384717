import React, { Component } from 'react'
import { Stage, Layer, Rect } from "react-konva";
import { Text, Stack, Spinner, FontSizes, Icon } from 'office-ui-fabric-react/lib/';
import { ApolloProvider } from "react-apollo";
import { Query } from "react-apollo";
import "./MotionMonitor.scss"
import { MyContext } from '../../../context';
import gql from "graphql-tag";
import "./fabric-icons-inline.scss";

export default class MotionMonitor extends Component {

    state =
        {
            postion: "",
            "sentiments": this.props.sentiments
        };

    handleMouseOver = (val) => {

        this.setState(
            {
                postion: val
            });
    };
    reorderSentimentArray = (senArr) => {
        console.log("REORDER ASSERT input :\n" + senArr);
        let outPut = [];

        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < senArr.length; j++) {
                switch (i) {
                    case 0: if (senArr[j].sentimentKey === "Neutral") { outPut.push(senArr[j]) } break;
                    case 1: if (senArr[j].sentimentKey === "Positive") { outPut.push(senArr[j]) } break;
                    case 2: if (senArr[j].sentimentKey === "Negative") { outPut.push(senArr[j]) } break;
                    case 3: if (senArr[j].sentimentKey === "Mixed") { outPut.push(senArr[j]) } break;
                    default: if (senArr[j].sentimentKey === "Neutral") { outPut.push(senArr[j]) } break;
                }
            }
        }
        console.log("REORDER ASSERT output :\n" + outPut);
        return outPut;
    };


    convertToDiagram = () => {
        let tmp = [], max = 0, windowMax = window.innerWidth * 0.86;
        const size = 4;
        if (this.state.sentiments !== undefined) {
            let workArr = this.reorderSentimentArray(this.state.sentiments);
            for (let i = 0; i < workArr.length; i++) {
                const input = workArr[i].appearances;
                let realTime, timeIn, timeOut, length, tt, sentKey, colorX = "red";
                sentKey = workArr[i].sentimentKey;
                console.log(workArr[i].sentimentKey);

                switch (sentKey) {
                    case "Negative": colorX = "#d92626"; break;
                    case "Neutral": colorX = "#cccccc"; break;
                    case "Positive": colorX = "#33b433"; break;
                    case "Mixed": colorX = "#FFFF00"; break;
                    default: break;
                }

                for (let run = 0; run < input.length; run++) {
                    tt = input[run].startSeconds;
                    timeIn = input[run].startSeconds * size;
                    timeOut = input[run].endSeconds * size;
                    if (timeOut > max) {
                        max = timeOut;
                    }
                    length = timeOut - timeIn;
                    realTime = input[run].startTime + " " + sentKey;
                    tmp.push({ x: timeIn, y: 2, h: 30, w: length, color: colorX, time: realTime, sec: tt });
                }
            }
            return <>
                <div className="display-inline" style={{ "position": "relative", "top": "-15px" }}><i id="sentiment-icon" className={window.innerWidth < 600 ? "ms-Icon ms-Icon--SentimentAnalysis  display-sentiment-icons" :"icons-align-big-screen ms-Icon ms-Icon--SentimentAnalysis  display-sentiment-icons"}></i></div>
                    <div className="display-inline display-sentiment-bars">
                    <Stage className="motion-stage" width={windowMax} height={34}>
                        <Layer>
                            <ColoredRect x={0} y={2} h={30} w={windowMax} color="grey" time="00.00.00" shadowBlur={3} />
                    </Layer>
                    <Layer>
                        {tmp.map(({ x, y, h, w, color, time, sec }, i) =>
                            (
                                <ColoredRect key={i} x={x * windowMax / max} y={y} h={h} w={w * windowMax / max} color={color} time={time} handleMouseOver={this.handleMouseOver} handleClick={this.props.listener} shadowBlur={1} sec={sec} />
                            ))}
                        </Layer>
                    </Stage>

                </div>
               </>

        }

    };

    render(): React.ReactNode {
        return <div className="motion-stage" >
            
            <div>
                {this.convertToDiagram()}
            </div>
        </div>
    }
}



class ColoredRect extends React.Component {

    state =
        {
            shadow: this.props.shadowBlur
        };

    f = (props) => {
        if (props.handleMouseOver !== undefined && props.handleMouseOver != null) {
            props.handleMouseOver(props.time);
            this.setState({ shadow: 3 });
        }

    };
    g = (props) => {
        if (props.handleMouseOver !== undefined && props.handleMouseOver != null) {
            this.setState({ shadow: 0 });
        }
    };

    y = (props) => {
        if (props.handleClick !== undefined && props.handleClick != null) {
            props.handleClick(props.sec);
        }

    };
    render() {
        return (
            <Rect
                x={this.props.x}
                y={this.props.y}
                width={this.props.w}
                height={this.props.h}
                fill={this.props.color}
                shadowBlur={this.state.shadow}
                onClick={() => (this.y(this.props))}
                onmouseover={() => (this.f(this.props))}
                onmouseout={() => (this.g(this.props))}
            />
        );

    }
}



//{plotEmotion(this.state)}
//<ColoredRect  x='10' y='20' h='50' w='50' color="green" time="00.10.01" handleClick={this.handleClick}/>