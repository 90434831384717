import * as React from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import ComanderBar from "../ComanderBar/ComanderBar";
import { MyContext } from '../../../context';
import { deleteCategory } from "../../../queries/queryCategory"
import { queryGetCategory } from "../../../queries/queryCategory"
import { queryGetConfigData } from "../../../queries/querySettings"
import AlertBar from '../../CustomComponents/AlertBar';
import SafetyDialog from '../../CustomComponents/SafetyDialog';
import { Spinner } from 'office-ui-fabric-react';
import Utils from '../../common/Utils'

const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px'
    }
};

export interface IDocument {
    name: String;
    group: String;

}

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}


const orderAlphabetically  = Utils.orderAlphabetically

export default class CategoryList extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: IDocument[];

    static contextType = MyContext

    constructor(props) {
        super(props);

        this._allItems = _generateDocuments(this.props.data);

        let columns: IColumn[] = [
            { key: 'name', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "name")}</>)}</MyContext.Consumer>, fieldName: 'name', minWidth: 100, maxWidth: 200 },
            { key: 'id', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "id")}</>)}</MyContext.Consumer>, fieldName: 'id', minWidth: 250, maxWidth: 200 },
            { key: 'frequence', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "frequence")}</>)}</MyContext.Consumer>, fieldName: 'frequence', minWidth: 100, maxWidth: 200 },
            { key: 'sentiment', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "sentiment")}</>)}</MyContext.Consumer>, fieldName: 'sentiment', minWidth: 100, maxWidth: 200 },
            { key: 'link', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "link")}</>)}</MyContext.Consumer>, fieldName: 'link', minWidth: 100, maxWidth: 200 },
            {
                key: 'keywords', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "keywords")}</>)}</MyContext.Consumer>, fieldName: 'keywords', onRender: (item) => {
                    let ttmp = "";
                    if (item.keywords != null) {
                        for (let i = 0; i < item.keywords.length; i++) {
                            //get the list like "keyword_soundlike" and split by'_'. then get the keyword [0]
                            let splitSoundlikeFromKeyword = item.keywords[i].split('_');
                            if ((i + 1) === item.keywords.length) {
                                ttmp += splitSoundlikeFromKeyword[0]
                            }
                            else {
                                ttmp += splitSoundlikeFromKeyword[0] + ","
                            }

                        }
                        ttmp = trim(ttmp);
                    }
                    return <span>{ttmp} </span>
                }, minWidth: 100, maxWidth: 200
            }

        ];

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                    warningMessage: ""
                });
            }
        });

        this.state = {
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: true,
            count: 0,
            warningMessage: "",
            alertType: "",
            hideDialogDelete: true,
            isProcessing: false

        };
    }

    getCategoryGroups = (data) => {
        //unique groups id
        let allGroupsIds = [...new Set(data.map(pr => pr.categoryGroup.id))];
        //create groups for dataillist
        let groups = allGroupsIds.map(groupId => {
            var startIndex = "";
            var endIndex = "";
            var name = "";
            for (var i = 0; i < data.length; i++) {
                if (data[i].categoryGroup.id === groupId) {
                    name = data[i].categoryGroup.name
                    startIndex = startIndex === "" ? i : startIndex
                    endIndex = i + 1
                }
            }
            return ({
                "key": groupId,
                "name": name,
                "startIndex": startIndex,
                "count": endIndex - startIndex,
                "level": 0
            })
        })
        return groups;
    }

    render() {
        const { columns, isCompactMode } = this.state;
        var dataEntry = this.props.data;
        if (this.props.fromSetterPage == null) {
            //RI-7789 If merge conflict are in place. Take this code instead code in branch RI-7789
            var template = dataEntry.filter(x => x.tenantId == "" || (x.categoryGroup != null && x.categoryGroup.generalGroup))
            var nonTemplate = dataEntry.filter(x => (x.categoryGroup == null || !x.categoryGroup.generalGroup))
            dataEntry = [
                ...template.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase())),
                ...nonTemplate.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))
            ]
            //
            var groups = this.getCategoryGroups(dataEntry)
        }
        return (
            <Fabric>
                {/*Add edit delete buttons*/}
                <ComanderBar
                    sel={"cat"}
                    onChangeX={this._onChangeText}
                    stylesX={controlStyles}
                    close={this.props.close}
                    openNew={this.openNew}
                    edit={this.openSelect}
                    authorization={this.props.authorization}
                    apol={this.props.apol}
                    delete={this.openDialogDelete}
                />


                {/*Warning and error message*/}
                <AlertBar
                    message={this.state.warningMessage}
                    alertType={this.state.alertType}
                    clearAlert={() => { this.setState({ warningMessage: "" }) }}
                />

                {this.state.isProcessing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}

                {/*List categories*/}
                {!this.state.isProcessing && this.props.fromSetterPage &&
                    <DetailsList
                        key={"key" + this.state.count}
                        items={_generateDocuments(dataEntry)}
                        compact={isCompactMode}
                        columns={columns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        selectionMode={SelectionMode.single}
                    />
                }

                {!this.state.isProcessing && this.props.fromSetterPage == null && <DetailsList
                    key={"key" + this.state.count}
                    items={dataEntry}
                    groups={groups}
                    compact={isCompactMode}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    selectionMode={SelectionMode.single}
                />}

                {/*SafetyDialog for delete category*/}
                <SafetyDialog
                    title={this.context.getTranslation("configuration", "deleteCategory")}
                    subText={this.context.getTranslation("compliance", "confirmDelete")}
                    hideDialog={this.state.hideDialogDelete}
                    closeDialog={() => { this.setState({ hideDialogDelete: true }) }}
                    ok={this.deleteCategory}
                />
            </Fabric>
        );
    }

    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isCompactMode: checked });
    };

    _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isModalSelection: checked });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        let tmt;
        console.log(text)
        console.log(this._allItems)
        tmt = this._allItems.filter(i => i.name.includes(text) || i.name.toLocaleLowerCase().includes(text));
        console.log(tmt)

        this.setState({
            count: (this.state.count + 1),
            items: tmt
        });
    };

    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                return 'One item selected: ' + (this._selection.getSelection()[0]).name;
            default:
                return `${selectionCount} Items selected`;
        }
    }

    openSelect = () => {
        if (this._selection.getSelection().length > 0) {
            this.props.func(this._selection.getSelection(), 4);
        } else {
            //no element selected
            this.setState({
                warningMessage: this.context.getTranslation("configuration", "selectCategory"),
                alertType: "warning"
            })
        }
    };

    openNew = () => {

        this.props.func(null, 4);

    };

    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };

    deleteCategory = () => {
        this.setState({ hideDialogDelete: true, isProcessing: true })
        if (this._selection.getSelection()[0] !== undefined) {
            const id = this._selection.getSelection()[0].id
            this.props.apol
                .mutate({
                    mutation: deleteCategory,
                    refetchQueries: [{
                        query: this.props.fromSetterPage != null ? queryGetConfigData : queryGetCategory,
                        variables: { auth: this.props.authorization }
                    }],
                    variables: { id }
                })
                .then((result) => {
                    if (result.data.deleteCategory.status !== "ok") {
                        this.setState({
                            warningMessage: this.context.getTranslation("configuration", result.data.deleteCategory.text),
                            alertType: result.data.deleteCategory.status,
                            isProcessing: false
                        })
                    } else {
                        this.setState({
                            isProcessing: false
                        })
                    }
                });
        }
    }

    openDialogDelete = () => {
        if (this._selection.getSelection()[0] !== undefined) {
            this.setState({ hideDialogDelete: false })
        } else {
            //no element selected
            this.setState({
                warningMessage: this.context.getTranslation("configuration", "selectCategory"),
                alertType: "warning"
            })
        }
    }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _generateDocuments(DataArr) {
    let items = []
    if (DataArr !== undefined && DataArr != null) items = [...DataArr]
    return [...items.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))]
}

function trim(sel) {
    let out;
    const max = 40;
    if (sel !== undefined && sel != null && sel.length > max) {
        out = sel.substring(0, max) + "...";
    } else {
        out = sel;
    }

    return out
}
