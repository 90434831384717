import gql from 'graphql-tag';


export const queryAllAIQuestions = gql`query  {
                getAIQuestions  {
                        id
                        masterItemId
                        name
                        description
                        question
                        tenantId
                        matchAbsence
                        aiQuestionGroup{
                            id
                            name
                            generalGroup
                        }
                    }
         }`

export const queryGetAIQuestion = gql`query ent ($id: String) {
                getAIQuestion (id: $id) {
                        id
                        name
                        description
                        question
                        tenantId
                        version
                        masterItemId
                        selected
                        matchAbsence
                        aiQuestionGroup{
                            id
                            generalGroup
                        }
                    }
         }`

export const createAIQuestion = gql`mutation ent($aiQuestion: AIQuestionInputType){
                                        upsertAIQuestion(aiQuestion: $aiQuestion) {
                                                id
                                                text
                                                status
                                            }
                                        }`

export const createAIQuestionAsTemplate = gql`mutation ent($aiQuestion: AIQuestionInputType){
                                        upsertAIQuestionAsTemplate(aiQuestion: $aiQuestion) {
                                                id
                                                text
                                                status
                                            }
                                        }`

export const deleteAIQuestion = gql`mutation ent ($id: String) {
                                        deleteAIQuestion(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`

export const getAllVersionOfAIQuestion = gql`query ent ($masterItemId: String) {
                                        getAllVersionOfAIQuestion(masterItemId: $masterItemId) {
                                                id
                                                version
                                            }
                                    }`

export const restoreAIQuestion = gql`mutation ent ($id: String) {
                                        restoreAIQuestion(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`
