import React from 'react';
import "./../../ReportView/ReportDetailContainer/ReportDetailContainer.scss"
import DetailViewOverlay from "./../../DetailView/DetailViewOverlay"
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { FontSizes, Stack, Text } from 'office-ui-fabric-react/lib';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';


import { Icon } from "office-ui-fabric-react";

import zipcelx from 'zipcelx';

const config = {
    filename: 'report',
    sheet: {
        data: [
            [
                {
                    value: "User ID",
                    type: 'string'
                },{
                    value: "User Login",
                    type: 'string'
                }, {
                    value: "Conversation ID",
                    type: 'string'
                }, {
                    value: "BotApp ID",
                    type: 'string'
                }, {
                    value: "Scenario ID",
                    type: 'string'
                }, {
                    value: "Tenant ID",
                    type: 'string'
                }, {
                    value: "Call ID",
                    type: 'string'
                }, {
                    value: "Loss Type",
                    type: 'string'
                }, {
                    value: "Recorded",
                    type: 'string'
                }, {
                    value: 'ConversationType',
                    type: 'string'
                }, {
                    value: "Header",
                    type: 'string'
                }, {
                    value: "Date",
                    type: 'string'
                }, {
                    value: "Start",
                    type: 'string'
                }, {
                    value: "Duration",
                    type: 'string'
                }, {
                    value: "Error",
                    type: 'string'
                }, {
                    value: "Participants",
                    type: 'string'
                }, {
                    value: "notes",
                    type: 'string'
                }
            ]
        ]
    }
};

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});

export interface IDocument {
    conversationType: Boolean,
    conversationID: String,
    conversationDirection: String,
    initiator: String,
    date: String,
    starttime: String,
    endtime: String,
    duration: String,
    attendees: String,
    notes: String,
    transcription: String

}

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}



export default class ReportDetailContainer extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: IDocument[];
    constructor(props: {}) {
        super(props);

        this.detailContainerRef = React.createRef();

        this._allItems = _generateDocuments(props.data.lossEntry);

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Conversation Type',
                className: classNames.fileIconCell,
                iconClassName: classNames.fileIconHeaderIcon,
                ariaLabel: 'Column operations for File type, Press to sort on File type',
                iconName: 'Page',
                isIconOnly: true,
                fieldName: 'name',
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    const typeIcon = item.conversationType != null ? item.conversationType : "Unknown";
                    if (typeIcon === "Meeting") {
                        return <div className="IconContainer callIcon"><Icon className='MoreButton' iconName='Group' title="Meeting" /></div>;
                    } else if (typeIcon === "Inbound") {
                        return <div className="IconContainer callIcon"><Icon className='MoreButton' iconName='ArrowDownRightMirrored8' title="Inbound" /></div>;
                    } else if (typeIcon === "Outbound") {
                        return <div className="IconContainer callIcon"><Icon className='MoreButton' iconName='ArrowUpRight8' title="Outbound" /></div>;
                    } else if (typeIcon === "Internal") {
                        return <div className="IconContainer callIcon"><Icon className='MoreButton' iconName='ConnectContacts' title="Internal"/></div>;
                    } else {
                        return <div className="IconContainer callIcon"></div>;
                    }

                }
            },
            {
                key: 'column21',
                name: 'Loss Type',
                fieldName: 'lossType',
                minWidth: 80,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column22',
                name: 'Recorded',
                fieldName: 'conversationRecorded',
                minWidth: 80,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column2',
                name: 'Header',
                fieldName: 'topic',
                minWidth: 80,
                maxWidth: 400,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IDocument) => {
                    if (item.lossType == "TotalLoss")
                        return <mgt-person show-name={true} user-id={item.topic} person-card="hover"></mgt-person>
                    else 
                        return <span>{item.topic}</span>
                }
            },
            {
                key: 'column3',
                name: 'Date',
                fieldName: 'dateValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'date',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.date}</span>;
                }
            }, {
                key: 'column4',
                name: 'Start',
                fieldName: 'dateValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'date',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.starttime}</span>;
                }
            },
            {
                key: 'column5',
                name: 'Duration',
                fieldName: 'duration',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.duration}</span>;
                },
                isPadded: true
            },
            {
                key: 'column8',
                name: 'Error',
                fieldName: 'errorText',
                minWidth: 80,
                maxWidth: 150,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.errorText}</span>;
                },
                isPadded: true
            },
            {
                key: 'column6',
                name: 'Participants',
                fieldName: 'participants',
                minWidth: 50,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.participants}</span>;
                },
                isPadded: true
            },
            {
                key: 'column23',
                name: 'User ID',
                fieldName: 'userId',
                minWidth: 100,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column23a',
                name: 'User Login',
                fieldName: 'userLogin',
                minWidth: 100,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Conversation ID',
                fieldName: 'conversationId',
                minWidth: 100,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column24',
                name: 'BotApp ID',
                fieldName: 'botAppId',
                minWidth: 100,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column25',
                name: 'Call ID',
                fieldName: 'callId',
                minWidth: 100,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column26',
                name: 'Scenario ID',
                fieldName: 'scenarioId',
                minWidth: 100,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column27',
                name: 'Tenant ID',
                fieldName: 'tenantId',
                minWidth: 100,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            }
        ];

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            items: this._allItems,
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: false,
            isCompactMode: false,
            isDialogOpen: false
        };
 
    }

    _onItemInvoked = (item) =>  {
        this.detailContainerRef.current.showPanelItem(item.conversationId, item.data)
    }

    _onExport = (ev) => {
        var itemList = [];
        itemList.push(config.sheet.data[0].slice());
        this.state.items.forEach((anItem) => {
            itemList.push([
                {
                    value: anItem.userId,
                    type: 'string'
                },
                {
                    value: anItem.userLogin,
                    type: 'string'
                }, {
                    value: anItem.conversationId,
                    type: 'string'
                }, {
                    value: anItem.botAppId,
                    type: 'string'
                }, {
                    value: anItem.scenarioId,
                    type: 'string'
                }, {
                    value: anItem.tenantId,
                    type: 'string'
                }, {
                    value: anItem.callId,
                    type: 'string'
                }, {
                    value: anItem.lossType,
                    type: 'string'
                }, {
                    value: anItem.conversationRecorded,
                    type: 'string'
                }, {
                    value: anItem.conversationType,
                    type: 'string'
                }, {
                    value: anItem.topic,
                    type: 'string'
                }, {
                    value: anItem.date,
                    type: 'string'
                }, {
                    value: anItem.starttime,
                    type: 'string'
                }, {
                    value: anItem.duration,
                    type: 'string'
                }, {
                    value: anItem.errorText,
                    type: 'string'
                }, {
                    value: anItem.participants,
                    type: 'string'
                }, {
                    value: anItem.notes,
                    type: 'string'
                }]);
        });
        config.sheet.data = itemList;
        zipcelx(config)
        this.setState({ isDialogOpen: true });
    }


    _dialogClose = () => this.setState({ isDialogOpen: false })

    render() {
        const { columns, items, isModalSelection } = this.state;

    return (<div id="main-wrapper">
        <DetailViewOverlay authorization={this.props.authorization} client={this.props.apol} ref={this.detailContainerRef} loading={true} userRules={this.props.userRules} theme={this.props.theme}/>
        <Dialog
            isOpen={this.state.isDialogOpen}
            type={DialogType.close}
            onDismiss={this._dialogClose.bind(this)}
            title='Excel export'
            subText='Excel file created and downloaded to your favourite directory'
            isBlocking={false}
            closeButtonAriaLabel='Close'
        />
        <header className="report-panel-header ms-Grid-row" style={{ boxShadow: Depths.depth8 }}>
            {/* == Recording Header ========================================= */}
            
            <Stack horizontal>

                <Text style={{ padding: "5px" }} >Report {this.props.data.title}</Text>
                <Stack.Item grow={2} ><div /></Stack.Item>

                <SearchBox
                    placeholder="Search"
                    onChange={(event, newValue) => {
                        if (event != null) event.persist();
                        this.setState({
                            items: newValue ? this._allItems.filter(i => (i.topic.toLowerCase().indexOf(newValue.toLowerCase()) > -1)
                                || (i.userId.toLowerCase().indexOf(newValue.toLowerCase()) > -1)
                                || (i.date.toLowerCase().indexOf(newValue.toLowerCase()) > -1)
                                || (i.botAppId.toLowerCase().indexOf(newValue.toLowerCase()) > -1)
                                || (i.scenarioId.toLowerCase().indexOf(newValue.toLowerCase()) > -1)
                                || (i.tenantId.toLowerCase().indexOf(newValue.toLowerCase()) > -1)
                                || (i.callId.toLowerCase().indexOf(newValue.toLowerCase()) > -1)) : this._allItems
                        });
                    }}
                />
                <CommandBarButton
                    iconProps={{ iconName: 'Download' }}
                    text="Export Report"
                    style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                    onClick={this._onExport}
                />
                </Stack>
        </header>
        <div className="report-details-wrapper">

        {/* == Player and meta data. ===================================== */}

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <div className="ms-Grid-row">

         
              
            </div>
                     <MarqueeSelection selection={this._selection}>
                        <DetailsList
                            items={items}
                            columns={columns}
                            selectionMode={isModalSelection ? SelectionMode.multiple : SelectionMode.none}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selection={this._selection}
                            selectionPreservedOnEmptyClick={true}
                            onActiveItemChanged={this._onItemInvoked}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        /> 
                    </MarqueeSelection>
              </div> {/* end of main contenten */}
        </div>
      </div>
    </div>);

    }
    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {

        this.setState({
            items: text ? this._allItems.filter(i => i.topic.toLowerCase().indexOf(text) > -1) : this._allItems
        });
    };

    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
            default:
                return `${selectionCount} items selected`;
        }
    }

    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({
            items: text ? this._allItems.filter(i => i.topic.toLowerCase().indexOf(text) > -1) : this._allItems
        });
    };
}



function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _generateDocuments(DataArr) {
    const items: IDocument[] = [];
    if (DataArr !== undefined) {
        for (let i = 0; i < DataArr.length; i++) {
            items.push({
                id: DataArr[i].id,
                conversationType: DataArr[i].recordingType,
                topic: DataArr[i].topic != null ? DataArr[i].topic : "",
                conversationDirection: DataArr[i].recordingType,
                conversationId: DataArr[i].conversationId,
                date: DataArr[i].day,
                dateValue: DataArr[i].callStartMs,
                starttime: DataArr[i].timein,
                duration: DataArr[i].duration,
                lossType: DataArr[i].lossType,
                errorText: DataArr[i].errorText,
                conversationRecorded: DataArr[i].conversationRecorded,
                userId: DataArr[i].userId,
                userLogin: DataArr[i].userLogin,
                botAppId: DataArr[i].botAppId,
                callId: DataArr[i].callId,
                scenarioId: DataArr[i].scenarioId,
                tenantId: DataArr[i].tenantId,
                participants: DataArr[i].participantCount != null ? DataArr[i].participantCount : 1,
                data: DataArr[i]
            })
        }
    }
    return items;
}


