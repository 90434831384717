import { DefaultButton, PrimaryButton, Stack, TextField, Spinner, Dropdown } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import FragmentsList from './FragmentsList';
import * as QueryPatterns from '../PatternsQuery';
import { Query } from 'react-apollo';

class EditPattern extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        const pattern = this.props.data !== undefined ? this.props.data : null
        this.state = {
            //page data
            processing: false,
            areFragmentsValid: false,
            //pattern data
            id: pattern !== null && pattern.tenantId !== '' ? pattern.id : null,
            name: pattern !== null ? pattern.name : "",
            description: pattern !== null ? pattern.description : "",
            regExp: pattern !== null ? pattern.regExp : "",
            patternDetectionGroup: pattern !== null && pattern.patternDetectionGroup != null ? pattern.patternDetectionGroup.id : null,
            generalGroup: pattern !== null && pattern.patternDetectionGroup != null ? pattern.patternDetectionGroup.generalGroup : null,
            tenantId: pattern !== null ? pattern.tenantId : null,
            withFragments: pattern !== null ? pattern.withFragments : this.props.withFragments,
            fragments: pattern !== null && pattern.withFragments ? pattern.fragments : null,
            version: pattern !== null ? pattern.version : 0,
            masterItemId: pattern !== null ? pattern.masterItemId : null,
            selected: pattern !== null ? pattern.selected : true,
            //versions
            selectedOtherVersion: false,
            selectedVersion: pattern !== null ? pattern.id : null
        };
    }

    componentDidMount() {
        this.refreshVersions()
    }

    refetchQueryVersions = null;
    numberOfVersions = 1

    refreshVersions = () => {
        if (this.refetchQueryVersions !== null) {
            this.refetchQueryVersions()
        }
    }

    //fragments from component FragmentsList
    fragments = null

    changeName = (e, value) => {
        this.setState({
            name: value,
            selectedOtherVersion: false
        })
    }

    changeDescription = (e, value) => {
        this.setState({
            description: value,
            selectedOtherVersion: false
        })
    }

    changeRegex = (e, value) => {
        this.setState({
            regExp: value,
            selectedOtherVersion: false
        })
    }

    updateFragments = (fragments) => {
        this.fragments = fragments
    }

    isValid = () => {
        return this.state.name !== "" && (this.state.withFragments ? this.state.areFragmentsValid : this.state.regExp !== "");
    }

    validationFragments = (data) => {
        var result = data.length > 0;
        if (result) {
            data.forEach(frag => {
                if (frag.fragmentType === "" || (frag.value === "" && (frag.nDigits === "" || !this.nDigistValidation(frag.nDigits))) || (frag.value === "" && frag.nDigits === "")) {
                    result = false;
                }
            })
        }
        this.setState({
            areFragmentsValid: result,
            selectedOtherVersion: false
        })
    }

    nDigistValidation = (nDigits) => {
        var length = parseInt(nDigits)
        var result = !isNaN(length)
        if (!result || nDigits.toString().includes("-")) {
            var range = nDigits.split("-")
            var validNumbers = parseInt(range[0]) < parseInt(range[1])
            result = /^([1-9]|[1-9][0-9])[-]([0-9]|[1-9][0-9])$/.test(nDigits) && validNumbers
        }
        return result;
    }

    cancel = () => {
        this.props.closePanel()
    }

    save = (saveAsTemplate) => {
        this.setState({ processing: true })
        const auth = this.props.auth
        const patternDetection = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            regExp: this.state.regExp,
            withFragments: this.state.withFragments,
            fragments: this.fragments,
            patternDetectionGroup: { id: this.state.patternDetectionGroup, generalGroup: this.state.generalGroup != null ? this.state.generalGroup : saveAsTemplate },
            tenantId: this.state.tenantId,
            // manage version
            version: this.numberOfVersions,
            masterItemId: this.state.masterItemId,
            selected: this.state.selected,
        }
        this.props.apol
            .mutate({
                mutation: saveAsTemplate ? QueryPatterns.createPatternAsTemplate : QueryPatterns.createPattern,
                variables: { auth, patternDetection }
            })
            .then((result) => {
                var resultData = saveAsTemplate ? result.data.upsertPatternDetectionAsTemplate : result.data.upsertPatternDetection
                var state = {}
                if (resultData.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshPatterns()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", resultData.text))
                }
                this.setState({ ...state, processing: false })
            });
    }

    onChangeVersion = (e, item) => {
        //get specific version to fill the fields
        this.setState({ processing: true })
        let id = item.key
        this.props.apol
            .query({
                query: QueryPatterns.queryGetPattern,
                variables: { id }
            })
            .then(({ loading, error, data, refetch }) => {
                let oldVersion = data.getPatternDetection;
                this.setState({
                    name: oldVersion.name,
                    description: oldVersion.description,
                    regExp: oldVersion.regExp,
                    withFragments: oldVersion.withFragments,
                    patternDetectionGroup: oldVersion.patternDetectionGroup != null ? oldVersion.patternDetectionGroup.id : null,
                    generalGroup: oldVersion.patternDetectionGroup != null ? oldVersion.patternDetectionGroup.generalGroup : null,
                    fragments: oldVersion.withFragments ? oldVersion.fragments : null,
                    tenantId: oldVersion.tenantId,
                    selectedVersion: id,
                    selectedOtherVersion: true,
                    processing: false
                })
            });
    }

    restoreVersion = () => {
        this.setState({ processing: true })
        //restorePatternDetection -> id vesrion to restore
        let id = this.state.selectedVersion
        this.props.apol
            .mutate({
                mutation: QueryPatterns.restorePatternDetection,
                variables: { id }
            })
            .then((result) => {
                if (result.data.restorePatternDetection.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshPatterns()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.restorePatternDetection.text))
                }
                this.setState({
                    selectedOtherVersion: false,
                    processing: false
                })
            });
    }

    render() {
        let masterItemId = this.state.masterItemId
        var saveAsTemplateStyle = { marginTop: "30px", marginLeft: "15px" }

        return (
            <div>
                {/*QUERY ALL VERSIONS*/}
                {masterItemId && (<Query
                    query={QueryPatterns.getAllVersionOfPatternDetection}
                    variables={{ masterItemId }}>
                    {({ loading, error, data, refetch }) => {
                        this.refetchQueryVersions = refetch
                        if (loading) {
                            return (<Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />)
                        }
                        if (error) {
                            return ""
                        }
                        if (data.getAllVersionOfPatternDetection == null) {
                            return ""
                        }
                        let versions = data.getAllVersionOfPatternDetection.map(v => {
                            return {
                                "key": v.id,
                                "text": v.version
                            }
                        })
                        this.numberOfVersions = versions.length
                        return (<div>
                            {versions.length > 0 && <Stack >
                                <Stack.Item>
                                    <Dropdown
                                        label={this.context.getTranslation("compliance", "version")}
                                        selectedKey={this.state.selectedVersion}
                                        onChange={this.onChangeVersion}
                                        options={versions}
                                    />
                                </Stack.Item>
                            </Stack>}
                        </div>
                        )
                    }}
                </Query>)}
                <TextField label={this.context.getTranslation("compliance", "name")} value={this.state.name} onChange={this.changeName} required />
                <TextField label={this.context.getTranslation("compliance", "description")} value={this.state.description} onChange={this.changeDescription} />
                {this.state.withFragments && <FragmentsList fragments={this.state.fragments} updateFragments={this.updateFragments} validationFragments={this.validationFragments} apol={this.props.apol} />}
                {!this.state.withFragments && <TextField label={this.context.getTranslation("compliance", "regex")} value={this.state.regExp} onChange={this.changeRegex} required />}
                {/*FOOTER BUTTONS*/}
                <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                    <DefaultButton text={this.context.getTranslation("common", "cancel")} onClick={this.cancel} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={this.state.processing} />
                    {(!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion) &&
                        <PrimaryButton text={this.context.getTranslation("common", "save")} onClick={() => this.save(false)} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                    }
                    {(this.props.userRules.userTemplateAllowed && (!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion)) &&
                        <PrimaryButton text={this.context.getTranslation("common", "saveAsTemplate")} onClick={() => this.save(true)} style={!this.isValid() || this.state.processing ? saveAsTemplateStyle : { ...saveAsTemplateStyle, background: "red", color: "white" }} allowDisabledFocus={true} disabled={!this.isValid() || this.state.processing} />
                    }
                    {this.state.selectedOtherVersion && this.state.id !== this.state.selectedVersion &&
                        <PrimaryButton text={this.context.getTranslation("compliance", "restoreVersion")} onClick={this.restoreVersion} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                    }
                </Stack>
            </div>
        );
    }
}

export default EditPattern;