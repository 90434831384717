import React from 'react'
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import gql from "graphql-tag";
import "./../ReportView/ReportConfiguration/ReportConfiguration.scss"
import { MyContext } from '../../context';

export default class UploadMail extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            isPanelOpen: false,
            isChanged: false,
            changedSubject: "Interest in ASC Recording Insights",
            changedRequest: "Lead",
            changedName: "",
            changedMailAddress: "",
            changedPhoneNumber: "",
            changedCompanyName: "",
            changedZipCode: "",
            changedCountry: "",
            changedNotes: "",
            changedCause: "",
        }
    }

    options: IDropdownOption[] = [

        { key: 'event', text: 'Fair/Event' },
        { key: 'social', text: 'Social Media' },
        { key: 'store', text: 'Microsoft Teams Store' },
        { key: 'asc', text: 'Contact person at ASC' },
        { key: 'partner', text: 'Partner/Microsoft' },
        { key: 'other', text: 'Other' },
    ];

    optionsRequest: IDropdownOption[] = [

        { key: 'Lead', text: 'Interest' },
        { key: 'Onboarding', text: 'Onboarding' }
    ];

    checkSave = () => {
        this.setState({
            isChanged: (
                this.state.changedSubject !== ""
                && this.state.changedRequest !== ""
                && this.state.changedName !== ""
                && this.state.changedMailAddress !== ""
                && this.state.changedPhoneNumber !== ""
                && this.state.changedCompanyName !== ""
                && this.state.changedZipCode !== ""
                && this.state.changedCountry !== ""
            )
        });
    }


    render() {
        return (<MyContext.Consumer>
            {(context) => (

            <div>
                <Panel
                    isOpen={this.state.isPanelOpen}
                    type={PanelType.medium}
                    onDismiss={this.hidePanel}
                    headerText={ context.getTranslation("product", "sendMailToAsc") }
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={this.onRenderFooterContent}
                >
                    <Dropdown
                        defaultSelectedKey={"Lead"}
                        onChange={(event, item) => {
                            this.setState({
                                changedRequest: item.key,
                                changedSubject: item.key === "Lead" ? "Interest in ASC Recording Insights" : "My Onboarding Information"
                            });
                        }}
                        label="Request Type"
                        options={this.optionsRequest}
                    />
                    <TextField label={context.getTranslation("product", "subject")}
                        value={this.state.changedSubject}
                        onChange={(event, newValue) => {
                            this.setState({
                                changedSubject: newValue
                            });
                            this.checkSave();
                        }}
                    />
                    <TextField label={context.getTranslation("product", "name")}
                        value={this.state.changedName}
                        onChange={(event, newValue) => {
                            this.setState({
                                changedName: newValue
                            });
                            this.checkSave();

                                }}
                            />
                            <TextField label={context.getTranslation("product", "email")}
                                value={this.state.changedMailAddress}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        changedMailAddress: newValue
                                    });
                                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newValue)) {
                                        this.checkSave();
                                    } else {
                                        this.setState({ isChanged: false });
                                    }


                                }}
                            />
                            <TextField label={context.getTranslation("product", "phoneNumber")}
                                value={this.state.changedPhoneNumber}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        changedPhoneNumber: newValue
                                    });
                                    this.checkSave();

                                }}
                            />
                            <TextField label={context.getTranslation("product", "companyName")}
                                value={this.state.changedCompanyName}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        changedCompanyName: newValue
                                    });
                                    this.checkSave();

                                }}
                            />
                            <TextField label={context.getTranslation("product", "zipCode")}
                                value={this.state.changedZipCode}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        changedZipCode: newValue
                                    });
                                    this.checkSave();

                                }}
                            />
                            <TextField label={context.getTranslation("product", "country")}
                                value={this.state.changedCountry}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        changedCountry: newValue
                                    });
                                    this.checkSave();

                                }}
                            />
                            <TextField label={context.getTranslation("product", "notes")}
                                multiline rows="4"
                                value={this.state.changedNotes}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        changedNotes: newValue
                                    });
                                    this.checkSave();

                                }}
                            />
                            <Dropdown
                                defaultSelectedKey={"other"}
                                onChange={(event, item) => {
                                    this.setState({
                                        changedCause: item.key,
                                    });
                                }}
                                label={context.getTranslation("product", "howDidYouApplied")}
                                options={this.options}
                            />
                        </Panel>
                    </div>
                )
                }
            </MyContext.Consumer>
        );
    };

    onRenderFooterContent = () => {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="ms-Grid-row ">
                        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4 button-row">
                            <PrimaryButton onClick={this.savePanel} text={context.getTranslation("product", "send")} disabled={!this.state.isChanged} />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 button-row">
                            <DefaultButton onClick={this.hidePanel} text={context.getTranslation("common", "cancel")} />
                        </div>
                    </div>
                )
                }
            </MyContext.Consumer>
        );
    };

    showPanel = () => {
        this.setState({ isPanelOpen: true });
    };

    hidePanel = () => {
        this.setState({
            isPanelOpen: false,
            isChanged: false,
            changedRequest: "Lead",
            changedSubject: "Interest in ASC Recording Insights",
            changedName: "",
            changedMailAddress: "",
            changedPhoneNumber: "",
            changedCompanyName: "",
            changedZipCode: "",
            changedCountry: "",
            changedNotes: "",
            changedCause: "",
        });
    };


    savePanel = () => {
        // Save Changes
        this.props.apol
            .mutate({
                mutation: gql`
                        mutation ent ($tenantId : String!, $userId: String, $subject: String, $mail: String, $company: String, $notes: String, $zip: String, $country: String, $cause: String, $name: String, $request: String) {
                            sendMail (input: { tenantId: $tenantId, userId: $userId} , subject: $subject, mailAddress: $mail, companyName: $company, notes: $notes, name: $name, zip: $zip, country: $country, cause: $cause, request: $request)
                     }
                     `,
                variables: {
                    tenantId: this.props.authorization != null ? this.props.authorization.tenantId : "unknown",
                    userId: this.props.authorization != null ? this.props.authorization.userId : "unknown",
                    subject: this.state.changedSubject,
                    company: this.state.changedCompanyName,
                    notes: this.state.changedNotes,
                    mail: this.state.changedMailAddress,
                    name: this.state.changedName,
                    zip: this.state.changedZipCode,
                    country: this.state.changedCountry,
                    cause: this.state.changedCause,
                    request: this.state.changedRequest
                }
            });
        this.hidePanel();
    };
}
