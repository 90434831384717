import { DefaultButton, PrimaryButton, TextField, Stack, Label, Spinner, Checkbox } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog'
import { MyContext } from '../../../context';
import { upsertConversationSafe, getConversationSafeFolder, getDefaultConversationSafeFolder } from '../ConversationSafeQuery';
import UserPicker from '../../ReportView/ReportConfiguration/UserPicker';
import ConversationPicker from '../ConversationPicker';
import ListOfDependentCheckboxes from '../../RuleSetter/RuleList/AddRuleComponents/ListOfDependentCheckboxes'
import VisibilityReplayRule from '../../RuleSetter/RuleList/AddRuleComponents/VisibilityReplayRule'




export default class ConversationSafePanel extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            //page data
            processing: false,
            supervisors: [],
            selectedConversationIds: [],
            name: "",
            description: "",
            id: "",
            ownerName: "",
            attentionRequired: "",
            userId: "",
            tenantId: "",
            replayVisibilityDetails: false,
            replayVisibilityDetailsTranscript: false,
            replayVisibilityDetailsAnalyticsChange: false,
            replayVisibilityDetailsAnalytics: false,
            replayVisibilityDetailsNotes: false,
            replayVisibilityDetailsTTL: false,
            replayVisibilityDetailsCustomFields: false,
            replayVisibilityDetailsMetadata: false,
            replayVisibilityDetailsReplay: false,
            visibilityDetailsQM: false,
            addConversations: false,
            removeConversations: false,
            exportFolders: false,
            allowedInPolicy:false,
            valid: false,
            disableButtons: false,
            isOpen: false,
            items: []
        };
    }

    open = (items) => this.setState({ isOpen: true, items })

    removeItem = () => {
        var itemMap = this.state.items.map(x => { return x['key']; });
        this.setState({
            selectedConversationIds: itemMap,
            items: [],
            isOpen: false
        })
    }

    close = () => this.setState({ isOpen: false })

    grantObject = {
        name: { hide: false, disabled: false },
        description: { hide: false, disabled: false },
        assignedTo: { hide: false, disabled: false },
        addConversations: { hide: false, disabled: false },
        removeConversations: { hide: false, disabled: false },
        exportFolders: { hide: false, disabled: false },
        shareFolders: { hide: false, disabled: false },
        conversationPickerAdd: { hide: false, disabled: false },
        conversationPickerRemove: { hide: false, disabled: false },
        save: { hide: false, disabled: false },
        cancel: {hide: false}
    };

    componentDidMount() {
        const { folderId } = this.props
        this.setState({ processing: true })
        if (folderId != undefined && folderId != null && folderId != "") {
            this.props.apol
                .query({
                    query: getConversationSafeFolder,
                    variables: { id: folderId, input: this.props.auth }
                })
                .then((result) => {
                    const { getConversationSafeFolder } = result.data
                    this.buildGrantObject(getConversationSafeFolder);
                    this.setState({
                        processing: false,
                        valid: this.checkForm(getConversationSafeFolder.name),
                        name: getConversationSafeFolder.name,
                        description: getConversationSafeFolder.description,
                        id: getConversationSafeFolder.id,
                        userId: getConversationSafeFolder.userId,
                        ownerName: getConversationSafeFolder.ownerName,
                        attentionRequired: getConversationSafeFolder.attentionRequired,
                        tenantId: getConversationSafeFolder.tenantId,
                        replayVisibilityDetails: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsActive ?? false,
                        replayVisibilityDetailsTranscript: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsTranscript ?? false ,
                        replayVisibilityDetailsTTL: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsTTL ?? false ,
                        replayVisibilityDetailsAnalytics: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsAnalytics ?? false ,
                        replayVisibilityDetailsAnalyticsChange: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsAnalyticsChange ?? false ,
                        replayVisibilityDetailsMetadata: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsMetadata ?? false ,
                        replayVisibilityDetailsCustomFields: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsCustomFields ?? false ,
                        replayVisibilityDetailsNotes: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsNotes ?? false ,
                        replayVisibilityDetailsReplay: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsReplay ?? false,
                        visibilityDetailsQM: getConversationSafeFolder?.visibilityDetails?.visibilityDetailsQM ?? false,
                        addConversations: getConversationSafeFolder.addConversations === null ? false : getConversationSafeFolder.addConversations,
                        removeConversations: getConversationSafeFolder.removeConversations === null ? false : getConversationSafeFolder.removeConversations,
                        exportFolders: getConversationSafeFolder.exportFolders === null ? false : getConversationSafeFolder.exportFolders,
                        selectedConversationIds: getConversationSafeFolder.conversations != undefined && getConversationSafeFolder.conversations != null ?
                            getConversationSafeFolder.conversations.map(x => x.conversationId) : [],
                        supervisors: getConversationSafeFolder.supervisors != undefined && getConversationSafeFolder.supervisors != null ?
                            getConversationSafeFolder.supervisors.map(x => x.userId) : [],
                        allowedToEditFlagAllowedInPolicy: getConversationSafeFolder.allowedToEditFlagAllowedInPolicy,
                        allowedInPolicy: getConversationSafeFolder.allowedInPolicy
                    })
                });
        } else {
            this.buildGrantObject();
            this.setState({ processing: false })
        }
    }

    buildGrantObject = (folder = undefined) => {
        var isSharedFolder = folder != undefined ? this.isSharedFolder(folder.userId) : this.isSharedFolder()
        if (!isSharedFolder) {
            //open my folder or creating a new one
            //see RuleAccess for the grant
            this.grantObject.name.hide = false;
            this.grantObject.name.disabled = false;
            this.grantObject.description.hide = false;
            this.grantObject.description.disabled = false;
            this.grantObject.addConversations.hide = false;
            this.grantObject.addConversations.disabled = false;
            this.grantObject.removeConversations.hide = false;
            this.grantObject.removeConversations.disabled = false;
            this.grantObject.exportFolders.hide = false;
            this.grantObject.exportFolders.disabled = false;
            this.grantObject.shareFolders.hide = false;
            this.grantObject.shareFolders.disabled = false;

            this.grantObject.conversationPickerAdd.hide = false;
            this.grantObject.conversationPickerAdd.disabled = false;
            this.grantObject.conversationPickerAdd.hide = false;
            this.grantObject.conversationPickerRemove.hide = false;

            this.grantObject.save.hide = false;
            this.grantObject.save.disabled = false;
            this.grantObject.cancel.hide = false;
            this.grantObject.cancel.disabled = false;
            if (this.props.userRules.ruleAccess.userSafe == "Full") {
                this.grantObject.assignedTo.hide = false;
                this.grantObject.assignedTo.disabled = false;
                this.grantObject.cancel.hide = false

            } else {
                var ruleAccess = this.props.userRules.ruleAccess.ownFolder;
                this.grantObject.assignedTo.hide = !ruleAccess.shareFolders;
                this.grantObject.assignedTo.disabled = !ruleAccess.shareFolders;
                this.grantObject.addConversations.hide = !ruleAccess.shareFolders;
                this.grantObject.addConversations.disabled = !ruleAccess.shareFolders;
                this.grantObject.removeConversations.hide = !ruleAccess.shareFolders;
                this.grantObject.removeConversations.disabled = !ruleAccess.shareFolders;
                this.grantObject.exportFolders.hide = !ruleAccess.shareFolders;
                this.grantObject.exportFolders.disabled = !ruleAccess.shareFolders;
                this.grantObject.shareFolders.hide = !ruleAccess.shareFolders;
                this.grantObject.shareFolders.disabled = !ruleAccess.shareFolders;
                this.grantObject.conversationPickerAdd.disabled = !ruleAccess.addConversations;
                this.grantObject.conversationPickerRemove.disabled = !ruleAccess.removeConversations;
                this.grantObject.cancel.hide = false
            }


        } else {
            //open shared folder
            //see folder Rule for the grant
            this.grantObject.name.hide = false;
            this.grantObject.description.hide = false;
            this.grantObject.conversationPickerAdd.hide = false;
            this.grantObject.conversationPickerRemove.hide = false;
            this.grantObject.save.disabled = false;
            this.grantObject.cancel.hide = false
            if (this.props.userRules.ruleAccess.userSafe == "Full") {
                this.grantObject.name.disabled = false;
                this.grantObject.description.disabled = false;
                this.grantObject.assignedTo.disabled = false;
                this.grantObject.assignedTo.hide = false;
                this.grantObject.addConversations.hide = false;
                this.grantObject.addConversations.disabled = false;
                this.grantObject.removeConversations.hide = false;
                this.grantObject.removeConversations.disabled = false;
                this.grantObject.shareFolders.hide = false;
                this.grantObject.shareFolders.disabled = false;
                this.grantObject.exportFolders.hide = false;
                this.grantObject.exportFolders.disabled = false;
                this.grantObject.conversationPickerAdd.disabled = false;
                this.grantObject.conversationPickerRemove.disabled = false;
                this.grantObject.save.hide = false;
                this.grantObject.cancel.hide = false
            } else {
                this.grantObject.name.disabled = true;
                this.grantObject.description.disabled = true;
                this.grantObject.assignedTo.disabled = true;
                this.grantObject.assignedTo.hide = true;
                this.grantObject.addConversations.hide = true;
                this.grantObject.addConversations.disabled = !folder.addConversations;
                this.grantObject.removeConversations.hide = true;
                this.grantObject.removeConversations.disabled = !folder.removeConversations;
                this.grantObject.exportFolders.hide = true;
                this.grantObject.exportFolders.disabled = !folder.exportFolders;
                this.grantObject.shareFolders.hide = true;
                this.grantObject.shareFolders.disabled = true;
                this.grantObject.conversationPickerAdd.disabled = !folder.addConversations;
                this.grantObject.conversationPickerRemove.disabled = !folder.removeConversations;
                this.grantObject.save.hide = (!folder.addConversations && !folder.removeConversations) || (folder.attentionRequired !== "");
                this.grantObject.cancel.hide = (!folder.addConversations && !folder.removeConversations) || (folder.attentionRequired !== "");
            }

        }
    }

    onConversationIdsChanged = (selectedConversationIds) => {
        this.setState({
            selectedConversationIds
        })
    }

    changeText = (e, value) => {
        var formData = { ...this.state, [e.target.id]: value }
        this.setState({
            [e.target.id]: value,
            valid: this.checkForm(formData.name)
        })
    }

    changeUserPicker = (users) => {
        this.setState({ supervisors: users.map(u => u.userId), valid: this.checkForm(this.state.name) })
    }

    checkForm = (name) => {
        return name.length > 0
    }

    onChangeAddConversations = () => {
        this.setState(prevState => ({
            addConversations: !prevState.addConversations
        }))
    }

    onChangeRemoveConversations = () => {
        this.setState(prevState => ({
            removeConversations: !prevState.removeConversations
        }))
    }

    onChangeExportFolders = () => {
        this.setState(prevState => ({
            exportFolders: !prevState.exportFolders
        }))
    }

    save = () => {

        this.setState({ disableButtons: true })
        const auth = this.props.auth
        const conversationSafeFolder = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            supervisors: this.state.supervisors != undefined && this.state.supervisors != null ? this.state.supervisors.map(x => { return { userId: x } }) : [],
            conversations: this.state.selectedConversationIds.map(x => { return { conversationId: x } }),
            userId: this.state.userId,
            tenantId: this.state.tenantId,
            visibilityDetails: {
                visibilityDetailsActive: this.state.replayVisibilityDetails,
                visibilityDetailsTranscript: this.state.replayVisibilityDetailsTranscript,
                visibilityDetailsAnalytics: this.state.replayVisibilityDetailsAnalytics,
                visibilityDetailsAnalyticsChange: this.state.replayVisibilityDetailsAnalyticsChange,
                visibilityDetailsMetadata: this.state.replayVisibilityDetailsMetadata,
                visibilityDetailsTTL: this.state.replayVisibilityDetailsTTL,
                visibilityDetailsCustomFields: this.state.replayVisibilityDetailsCustomFields,
                visibilityDetailsNotes: this.state.replayVisibilityDetailsNotes,
                visibilityDetailsReplay: this.state.replayVisibilityDetailsReplay,
                visibilityDetailsQM: this.state.visibilityDetailsQM
            },
            addConversations: this.state.addConversations,
            removeConversations: this.state.removeConversations,
            exportFolders: this.state.exportFolders,
            allowedInPolicy: this.state.allowedInPolicy
        }
        this.props.apol
            .mutate({
                mutation: upsertConversationSafe,
                variables: { auth, conversationSafeFolder }
            })
            .then((result) => {
                var state = {}
                if (result.data.createConversationSafeFolder.status === "ok") {
                    this.props.refreshConversationSafeFolders()
                    this.props.hidePanel()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.createConversationSafeFolder.text))
                }
                this.setState({ ...state, disableButtons: !this.checkForm(this.state.name) })
            });
    }

    //check if that folder is a shared folder
    isSharedFolder = (userId = null) => {
        var uId = userId != null ? userId : this.state.userId;
        return (this.props.folderId != null && uId != this.props.userRules.userId);
    }

    render() {
        return (
            <div>
                <Dialog
                    isOpen={this.state.isOpen}
                    type={DialogType.close}
                    onDismiss={this.close.bind(this)}
                    title={this.context.getTranslation("compliance", "riskRatingAdherence")}
                    subText={this.context.getTranslation("conversationSafe", "teachingBubbleTextLastFolder")}
                    isBlocking={false}
                    closeButtonAriaLabel='Close'
                    items={this.state.items}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this.removeItem} text={this.context.getTranslation("common", "remove")} />
                        <DefaultButton onClick={this.close} text={this.context.getTranslation("common", "cancel")} />
                    </DialogFooter>
                </Dialog>

                {this.state.processing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}
                {!this.state.processing && (
                    <div>
                        {this.state.attentionRequired !== "" &&
                            this.state.userId !== this.props.userRules.userId &&
                            this.props.userRules.ruleAccess.userSafe !== "Full" &&
                            <span style={{ color: "red" }}>{this.context.getTranslation("conversationSafe", "contactOwner") + this.state.ownerName}</span>
                        }
                        <TextField
                            label={this.context.getTranslation("compliance", "name")}
                            id="name"
                            disabled={this.grantObject.name.disabled}
                            value={this.state.name}
                            onChange={this.changeText}
                            required />
                        <TextField
                            label={this.context.getTranslation("compliance", "description")}
                            id="description"
                            disabled={this.grantObject.description.disabled}
                            value={this.state.description}
                            onChange={this.changeText}
                            multiline
                            rows={3} />
                        {this.props.folderId
                        && <TextField
                            label={this.context.getTranslation('compliance', 'creator')}
                            id="creator"
                            disabled={true}
                            value={this.state.ownerName}
                            // onChange={this.changeText}
                        />}
                        <div style={{ display: this.grantObject.assignedTo.hide ? "none" : "" }}>
                            <Label>{this.context.getTranslation("compliance", "detailPolicyUsers")}</Label>
                            <UserPicker
                                id="assignedTo"
                                disabled={this.grantObject.assignedTo.disabled}
                                placeholder={this.context.getTranslation("compliance", "selectUserOrGroup")}
                                user={this.state.supervisors.map(user => {
                                    return ({ "userId": user })
                                })}
                                fc={(user) => { this.changeUserPicker(user) }}
                            />
                        </div>

                        <div
                            style={{
                            marginTop: "20px",
                            marginBottom: "10px",
                            display: (this.grantObject.addConversations.hide
                                && this.grantObject.removeConversations.hide
                                && this.grantObject.shareFolders.hide
                                && this.grantObject.exportFolders.hide)
                                ? "none" : "" }}>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <div style={{ display: this.grantObject.shareFolders.hide ? "none" : "" }} >
                                    
                                    <VisibilityReplayRule
                                        userRules={this.props.userRules}
                                        updateParentState={(newState)=> {
                                            this.setState({
                                                ...newState,
                                                updated: true})
                                        }}
                                        data={
                                            {
                                                replayVisibilityDetails: this.state.replayVisibilityDetails,
                                                userRulesVisibilityActive: this.props.userRules.visibilityDetails,
                                                secondary: {
                                                    replayVisibilityDetailsTranscript: {
                                                        value: this.state.replayVisibilityDetailsTranscript,
                                                        rules: this.props.userRules.visibilityDetailsTranscript
                                                    },
                                                    replayVisibilityDetailsAnalyticsChange: {
                                                        value: this.state.replayVisibilityDetailsAnalyticsChange,
                                                        rules: this.props.userRules.visibilityDetailsAnalyticsChange
                                                    },
                                                    replayVisibilityDetailsAnalytics: {
                                                        value: this.state.replayVisibilityDetailsAnalytics,
                                                        rules: this.props.userRules.visibilityDetailsAnalytics
                                                    },
                                                    replayVisibilityDetailsNotes: {
                                                        value: this.state.replayVisibilityDetailsNotes,
                                                        rules: this.props.userRules.visibilityDetailsNotes
                                                    },
                                                    replayVisibilityDetailsTTL: {
                                                        value: this.state.replayVisibilityDetailsTTL,
                                                        rules: this.props.userRules.visibilityDetailsTTL
                                                    },
                                                    replayVisibilityDetailsCustomFields: {
                                                        value: this.state.replayVisibilityDetailsCustomFields,
                                                        rules: this.props.userRules.visibilityDetailsCustomFields
                                                    },
                                                    replayVisibilityDetailsMetadata: {
                                                        value: this.state.replayVisibilityDetailsMetadata,
                                                        rules: this.props.userRules.visibilityDetailsMetadata
                                                    },
                                                    replayVisibilityDetailsReplay: {
                                                        value: this.state.replayVisibilityDetailsReplay,
                                                        rules: this.props.userRules.visibilityDetailsReplay
                                                    },
                                                    visibilityDetailsQM: {
                                                        value: this.state.visibilityDetailsQM,
                                                        rules: this.props.userRules.visibilityDetailsQM
                                                    }
                                                }
        
                                            }
                                        }
                                    >
                                    </VisibilityReplayRule>
                                </div>
                               
                                <div style={{ display: this.grantObject.addConversations.hide ? "none" : "" }} >
                                    <Checkbox label={this.context.getTranslation("compliance", "addConversationsLabel")} id={"addConversations"} checked={this.state.addConversations} onChange={this.onChangeAddConversations} disabled={this.grantObject.addConversations.disabled} />
                                </div>
                                <div style={{ display: this.grantObject.removeConversations.hide ? "none" : "" }} >
                                    <Checkbox label={this.context.getTranslation("compliance", "removeConversationsLabel")} id={"removeConversations"} checked={this.state.removeConversations} onChange={this.onChangeRemoveConversations} disabled={this.grantObject.removeConversations.disabled} />
                                </div>
                                <div style={{ display: this.grantObject.exportFolders.hide ? "none" : "" }} >
                                    <Checkbox label={this.context.getTranslation("compliance", "exportFoldersLabel")} id={"exportFolders"} checked={this.state.exportFolders} onChange={this.onChangeExportFolders} disabled={this.grantObject.exportFolders.disabled} />
                                </div>
                                <div>
                                    {this.props.userRules.ruleAccess.userCompliancePolicyInitiateWorkflow &&
                                        <Checkbox
                                        checked={this.state.allowedInPolicy}
                                        label={this.context.getTranslation("compliance", "allowedInPolicy")}
                                        id={"allowedInPolicy"}
                                        disabled={!this.state.allowedToEditFlagAllowedInPolicy && this.props.folderId !== null}
                                        onChange={(ev, checked) => this.setState({allowedInPolicy: checked})}/>
                                    }
                                </div>
                            </Stack>
                        </div>

                        <ConversationPicker
                            id="addConversationPicker"
                            apol={this.props.apol}
                            canAddConversations={!this.grantObject.conversationPickerAdd.disabled}
                            canRemoveConversations={!this.grantObject.conversationPickerRemove.disabled}
                            selectedConversationIds={this.state.selectedConversationIds}
                            onSelectionChange={this.onConversationIdsChanged}
                            conversationList={this.props.conversationList} open={this.open}
                        />
                            <Stack horizontal style={{ flexFlow: 'row-reverse' }}>
                            <DefaultButton text={this.grantObject.save.hide
                                ? this.context.getTranslation('common', 'close')
                                : this.context.getTranslation('common', 'cancel')}
                                           onClick={() => { this.props.hidePanel() }}
                                           disabled={this.state.disableButtons}
                                           style={{ marginTop: '30px', marginLeft: '15px',
                                               display: this.grantObject.cancel.hide ? 'none' : '' }}
                            />
                            <PrimaryButton
                                text={this.context.getTranslation('common', 'save')}
                                onClick={this.save}
                                disabled={!this.state.valid
                                    || this.state.disableButtons
                                    || (this.state.replayVisibilityDetails && !this.state.visibilityCount)
                                }
                                
                                style={{
                                    marginTop: '30px',
                                    marginLeft: '15px',
                                    display: this.grantObject.save.hide ? 'none' : ''
                               }}
                            />
                        </Stack>
                    </div>
                )}
            </div>


        );

    }
}
