import React from 'react';

import Map, {
    Label,
    Layer,
    Size
} from 'devextreme-react/vector-map';

import * as mapsData from 'devextreme/dist/js/vectormap-data/world.js';
import { countriesGDP } from './data.js';

const colorGroups = [0, 10000, 50000, 100000, 500000, 1000000, 10000000, 50000000];
function customizeLayer(elements) {
    elements.forEach((element) => {
        const countryGDPData = countriesGDP[element.attribute('name')];
        element.attribute('total', countryGDPData && countryGDPData.total);
    });
}

class  App extends React.Component{
 
    render(){
        return (
            <Map
                elementAttr={{ id: 'vector-map' }} bounds={[-180, 85, 180, -60]}>
                <Layer
                    name="areas"
                    dataSource={mapsData.world}
                    colorGroups={colorGroups}
                    colorGroupingField="total"
                    customize={customizeLayer}
                >
                    <Label dataField="name" enabled={true} />
                </Layer>


                <Size
                    height={this.props.size.height}
                    width={this.props.size.width}
                />
            </Map>
        );
    }
}
export default App;
