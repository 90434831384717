import gql from 'graphql-tag';


export const queryAllAdherencePhrases = gql`query  {
                getAdherencePhrases  {
                        id
                        masterItemId
                        name
                        description
                        phrase
                        adherence
                        tenantId
                        contained
                    }
         }`

export const queryGetAdherencePhrase = gql`query ent ($id: String) {
                getAdherencePhrase (id: $id) {
                        id
                        name
                        description
                        phrase
                        adherence
                        tenantId
                        version
                        masterItemId
                        selected
                        contained
                    }
         }`

export const createAdherencePhrase = gql`mutation ent($adherencePhrase: AdherencePhraseInputType){
                                        upsertAdherencePhrase(adherencePhrase: $adherencePhrase) {
                                                id
                                                text
                                                status
                                            }
                                        }`

export const deleteAdherencePhrase = gql`mutation ent ($id: String) {
                                        deleteAdherencePhrase(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`

export const getAllVersionOfAdherencePhrase = gql`query ent ($masterItemId: String) {
                                        getAllVersionOfAdherencePhrase(masterItemId: $masterItemId) {
                                                id
                                                version
                                            }
                                    }`

export const restoreAdherencePhrase = gql`mutation ent ($id: String) {
                                        restoreAdherencePhrase(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`
