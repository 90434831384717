import React, { Component } from 'react'
import { Stage, Layer, Rect, Text, Label, RegularPolygon, Tag } from "react-konva";
import { Html } from 'react-konva-utils';
import './WaveformAudio.scss';
import { Slider, Checkbox } from '@fluentui/react';


export default class WaveFormAudio extends Component {
    constructor(props) {
        super(props);

        this.playerSlider = React.createRef();

        this.state =
        {
            postion: "",
            sentiments: this.props.sentiments,
            isDragging: false,
            x: 9,
            y: 30,
            isOver: false,
            hoverPosition: 0,
            stageScale: 1,
            stageX: 0,
            stageY: 0,
            keywords: this.props.keywords,
            displayKeywords: true,
            displayPolicies: true
        };
    }

    //THIS IS ZOOM FEATURE------NEEDS IMPROVEMENT

    //handleWheel = e => {
    //    e.evt.preventDefault();

    //    const scaleBy = 1.02;
    //    const stage = e.target.getStage();
    //    const oldScale = stage.scaleX();
    //    const mousePointTo = {
    //        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
    //        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    //    };

    //    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;


    //    this.setState({
    //        stageScale: newScale,
    //        stageX:
    //            -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
    //        stageY:
    //            -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
    //    });
    //};
    handleMouseOverKeyword = (val) => {

        this.setState(
            {
                postion: val
            });
    };
    handleMouseOver = (val, hoverPosition, isOverData) => {

        this.setState(
            {
                postion: val,
                isOver: isOverData,
                hoverPosition: hoverPosition
            });

    };
    handleMouseLeave = (isOverData) => {

        this.setState(
            {
                isOver: isOverData
            });

    };
    reorderSentimentArray = (senArr) => {
        let outPut = [];

        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < senArr.length; j++) {
                switch (i) {
                    case 0: if (senArr[j].sentimentKey === "Neutral") { outPut.push(senArr[j]) } break;
                    case 1: if (senArr[j].sentimentKey === "Positive") { outPut.push(senArr[j]) } break;
                    case 2: if (senArr[j].sentimentKey === "Negative") { outPut.push(senArr[j]) } break;
                    case 3: if (senArr[j].sentimentKey === "Mixed") { outPut.push(senArr[j]) } break;
                    default: if (senArr[j].sentimentKey === "Neutral") { outPut.push(senArr[j]) } break;
                }
            }
        }
        return outPut;
    };

    getTimeInSeconds = (t) => {

        if (t === "")
            return 0;

        //seconds miliseconds and days are divided by dots (d.hh:mm:ss.ml)
        //if there are more dots in string will split by them and divide into time(tmp) and days 
        //if only one dot detected there is no day so will be only tmp
        let tmp = t, d = 0;
        if (t.indexOf(".") !== -1) {
            if (t.split('.').length > 1) {
                tmp = t.split('.')[1];
                d = t.split('.')[0];
            } else {
                tmp = t.split('.')[0];
            }

        }

        let out, s, m, h;
        tmp = tmp.split(':');

        s = Number(tmp[2]);
        m = Number(tmp[1]) * 60;
        h = Number(tmp[0]) * 60 * 60;
        d = Number(d) * 24 * 60 * 60;

        out = s + m + h + d;

        return out;
    }


    //get keywords data (choose (color)
    getKeywords = (color) => {

        let callDurationInSeconds = this.getTimeInSeconds(this.props.data.duration);
        //for keyword position calcul
        let frameLimit = window.innerWidth * 0.86 / callDurationInSeconds;

        let tmpKeywords = [];

        if (this.state.keywords.length > 0) {
            for (let i = 0; i < this.state.keywords.length; i++) {
                const input = this.state.keywords[i].appearances;
                let name = this.state.keywords[i].name;
                let type = "k";
                //tooltip text
                let displayText = "Key: " + this.state.keywords[i].name + ", Cat: " + this.state.keywords[i].categorie;

                for (let run = 0; run < input.length; run++) {
                    let seconds = this.convertToSeconds(input[run].startTime);
                    //keyword data
                    tmpKeywords.push({ x: seconds * frameLimit, color: color, name: name, time: input[run].startTime, sec: seconds, text: displayText, type: type })
                }
            }
        }
        return tmpKeywords;
    }

    convertToSeconds = (time) => {
        var tmp = time.split(":");
        var result = 0;
        if (tmp[2]) {
            var tmp1 = tmp[2].split(".");
            result = parseInt(tmp[0]) * 3600 + parseInt(tmp[1]) * 60 + parseInt(tmp1[0]);
        } else {
            result = parseInt(tmp[0]) * 60 + parseInt(tmp[1]);
        }
        return result;
    }

    //get pololicies data (choose (color)
    getPolicyHits = (color) => {

        let callDurationInSeconds = this.getTimeInSeconds(this.props.data.duration);
        //for PolicyHits position calcul
        let frameLimit = window.innerWidth * 0.86 / callDurationInSeconds;

        let tmpPolicyHits = [];

        if (this.props.policiesData && this.props.policiesData.length > 0) {
            for (let i = 0; i < this.props.policiesData.length; i++) {
                const input = this.props.policiesData[i].appearances;
                let name = this.props.policiesData[i].name;
                let type = "p";

                //tooltip text
                let displayText = this.props.policiesData[i].name + " (" + this.props.policiesData[i].policyResultType + ") " + this.props.policiesData[i].policyResultName;

                for (let run = 0; run < input.length; run++) {
                    //PolicyHits data
                    tmpPolicyHits.push({
                        x: input[run].startSeconds * frameLimit,
                        color: color, name: name,
                        time: input[run].startTime,
                        sec: input[run].startSeconds,
                        text: displayText,
                        type: type
                    })
                }
            }
        }
        return tmpPolicyHits;
    }

    convertToDiagram = () => {
        //PREPARE WAVE DATA-------------------------------------------------------------------------------------------
        let tmp = [], max = 0, windowMax = window.innerWidth * 0.90;

        //if wavedata is missing skip this step
        if (this.props.waveData !== "" && this.props.audioSegments !== "") {
            //calculate step
            let stepCount = this.props.step / 10;

            //go between rms segments
            for (let i = 0; i < this.props.audioSegments.length; i++) {
                let waveColor = "gray";


                let timeIn, timeOut, length, tt;

                //start second for each wave bar is a 0.1 second (100 miliseconds) after the previous bar
                let startSeconds = i * 0.1;
                let startSecondsToCompareSentiments = i * stepCount;
                //let endSeconds = Math.floor((this.props.waveData.endTimeMiliseconds % (1000 * 60)) / 1000);



                //obtain sentiment color 
                if (this.state.sentiments !== undefined) {
                    let workArr = this.reorderSentimentArray(this.state.sentiments);

                    for (let i = 0; i < workArr.length; i++) {
                        const input = workArr[i].appearances;
                        let sentKey, colorX = "";
                        sentKey = workArr[i].sentimentKey;

                        switch (sentKey) {
                            case "Negative": colorX = "#d92626"; break;
                            case "Neutral": colorX = "#cccccc"; break;
                            case "Positive": colorX = "#33b433"; break;
                            case "Mixed": colorX = "#FFFF00"; break;
                            default: break;
                        }

                        //compare if time of the bare is included in sentiment range
                        for (let run = 0; run < input.length; run++) {
                            if (startSecondsToCompareSentiments >= input[run].startSeconds && startSecondsToCompareSentiments + 0.02 <= input[run].endSeconds) {
                                waveColor = colorX
                            }
                        }
                    }

                }


                //define placement of bar by time
                timeIn = startSeconds;
                timeOut = startSeconds + 0.02;
                if (timeOut > max) {
                    max = timeOut;
                }
                length = timeOut - timeIn;


                //get the height of the wave using rms audio segments
                //If the segment is 0 i change its value to 0.1 just to have a fine line of "muted" instead of empty
                let waveHeight = this.props.audioSegments[i];
                if (this.props.audioSegments[i] === 0) {
                    waveHeight = 0.5;
                }


                //send the values to colorRect to draw
                //waveHeight is divided by  2.9 so the height can fit inside designated area
                tmp.push({ x: timeIn, y: 74, h: waveHeight / 2.7, w: length, color: waveColor });
            }
        }

        //get keywords data and choose color

        let keywordsArray = this.getKeywords("blue");

        let policyArray = this.getPolicyHits("#F9C642");

        let policyKeyworsArray = [...keywordsArray, ...policyArray]


        //DRAW THE WAVE----------------------------------------------------------------------------------------------------------------------
        return <>
            <div className="display-inline">
                <Stage
                    width={windowMax}
                    height={this.props.stageHeight}
                    //onWheel={this.handleWheel}
                    scaleX={this.state.stageScale}
                    scaleY={this.state.stageScale}
                    x={this.state.stageX}
                    y={this.state.stageY}
                >

                    {/*THIS IS THE LAYER WHERE THE WAVE IS DISPLAYED*/}

                    {tmp.length > 0 && <>
                        <Layer>
                            {tmp.map(({ x, y, h, w, color }, i) =>
                            (<>
                                <ColoredRect key={i} x={x * window.innerWidth * 0.85 / max} y={y} h={-h} w={w * windowMax / max} color={color} shadowBlur={1} />
                            </>
                            ))}
                        </Layer>
                    </>}

                    <Layer y={this.props.sliderHeight}>
                        <Html>
                            <Slider className="detail-container-slider"
                                label=""
                                ref={this.playerSlider}
                                min={0}
                                max={this.props.max}
                                valueFormat={this.props.valueFormat}
                                step={1}
                                value={this.props.value}
                                showValue={true}
                                onChange={this.props.onChange}
                            />
                        </Html>

                    </Layer>





                    {/*    THIS LAYER CONTAINES TRIANGLES FOR KEYWORDS*/}
                    {policyKeyworsArray.length > 0 && <Layer>
                        {policyKeyworsArray.map(({ x, color, name, time, sec, text, type }, i) => {

                            if ((this.state.displayPolicies && type == "p" && sec !== 0) || (this.state.displayKeywords && type == "k")) {
                                return (<>
                                    <Triangles
                                        x={x}
                                        y={93}
                                        type={type}
                                        color={color}
                                        name={name}
                                        id={i}
                                        handleMouseOver={this.handleMouseOver}
                                        handleClick={this.props.listener}
                                        time={time}
                                        sec={sec}
                                        text={text}

                                    />
                                </>
                                )
                            }
                        })}
                    </Layer>}

                    {/*THIS IS THE LAYER FOR SELECTING IF KEYWORDS/OR ANY OTHER ELEMENTS ADDED ARE VISIBLE(LIKE POLICY)*/}
                    {keywordsArray.length > 0 && <Layer x={-32} y={7}>
                        <Html>
                            <div style={{ display: "inline-flex" }}>
                                <CheckboxTrianglesEnable
                                    id="keywords"
                                    display={this.state.displayKeywords}
                                    text="Keywords"
                                    onChange={(e, newValue) => {
                                        this.setState({ displayKeywords: newValue })
                                    }
                                    }
                                    color="blue"
                                />

                            </div>


                        </Html>
                    </Layer>}

                    {policyArray.length > 0 && <Layer x={keywordsArray.length === 0 ? -32 : 70} y={7}>
                        <Html>

                            <div style={{ display: "inline-flex" }}>
                                <CheckboxTrianglesEnable
                                    id="policy"
                                    display={this.state.displayPolicies}
                                    text="Policies"
                                    onChange={(e, newValue) => {
                                        this.setState({ displayPolicies: newValue })
                                    }}
                                    color="#F9C642"
                                />

                            </div>
                        </Html>
                    </Layer>}

                </Stage>

            </div>
        </>


    };

    render(): React.ReactNode {
        return <div className="motion-stage" >

            <div>
                {this.convertToDiagram()}
            </div>
        </div>
    }
}

//WAVE LINE
class ColoredRect extends React.Component {


    render() {
        return (
            <Rect
                x={this.props.x}
                y={this.props.y}
                width={this.props.w}
                height={this.props.h}
                fill={this.props.color}
                shadowBlur={this.props.shadowBlur}

            />
        );

    }
}

//TRIANGLES TO DISPLAY KEYWORDS, POLICY ETC...
class Triangles extends React.Component {
    state =
        {
            shadow: 0,
            radius: this.props.type === "k" ? 10 : 6,
            visible: false
        };

    mouseOnAction = (props, e) => {
        //change pointer
        const container = e.target.getStage().container();
        container.style.cursor = "pointer";

        //change shadow/size on hover
        if (props.handleMouseOver !== undefined && props.handleMouseOver != null) {
            props.handleMouseOver(props.time);
            this.setState({ shadow: 3, radius: this.props.type === "k" ? 14 : 10, visible: true });
        }

    }

    mouseOffAction = (props, e) => {
        //change pointer
        const container = e.target.getStage().container();
        container.style.cursor = "default";

        //change shadow/size on hover
        if (props.handleMouseOver !== undefined && props.handleMouseOver != null) {
            this.setState({ shadow: 0, radius: this.props.type === "k" ? 10 : 6, visible: false });
        }


    };

    //ON CLICK IT WILL JUMP TO KEYWORD TIME
    mouseClickAction = (props) => {
        if (props.handleClick !== undefined && props.handleClick != null) {
            props.handleClick(props.sec);
        }

    };

    render() {
        return (<>
            <RegularPolygon
                id={this.props.id}
                x={this.props.x}
                y={this.props.y}
                sides={3}
                radius={this.state.radius}
                fill={this.props.color}
                name={this.props.name}
                shadowBlur={this.state.shadow}
                onClick={() => (this.mouseClickAction(this.props))}
                onmouseover={(e) => (this.mouseOnAction(this.props, e))}
                onmouseout={(e) => (this.mouseOffAction(this.props, e))}
            />
            <Tooltip x={this.props.x}
                y={this.props.y - 18}
                text={this.props.text}
                visible={this.state.visible}
            />
        </>);
    }


}

//TOOLTIP DISPLAYED ON TRIANGLE HOVER
const Tooltip = (props) => {

    return (
        <Label opacity={0.75}
            visible={props.visible}
            listening={false}
            x={props.x}
            y={props.y}

        >
            <Tag fill="black"
                pointerDirection="down"
                pointerWidth={10}
                pointerHeight={10}
                lineJoin="round"
                shadowColor="black"
                shadowBlur={15}
                shadowOffsetX={5}
                shadowOffsetY={5}
                shadowOpacity={0.9}
            />

            <Text text={props.text}
                fontFamily="Segoe UI"
                fontSize={13}
                padding={5}
                fill="white"
            />

        </Label>
    );

}

//CHECKBOX TO ENABLE/DISABLE TRIANGLES VISIBILITY
const CheckboxTrianglesEnable = (props) => {

    const checkBoxStyles = {
        checkmark: {
            fontSize: "11px"
        },
        checkbox: {
            width: "15px",
            height: "15px",
            margin: "0px",
            padding: "1px",
            marginRight: "5px",
            customElements: "pointer"
        },
        text: {
            fontSize: "10px",
            marginBottom: "-5px",
            position: "relative",
            top: "-2px",
            '@media(prefers-color-scheme: dark)': {
                color: "white",
                ':hover': {
                    color: "white !important"
                },
            }
        },
        root: {
            padding: "5px",
            border: "0.5px solid",
            borderColor: "lightgray",
            leftBorderStyle: "solid",
            leftBorderColor: "blue",
            borderLeft: "5px solid " + props.color,
            borderRadius: "4px",
            marginRight: "10px"
        }
    }



    return (
        <Checkbox checked={props.display}
            labelPosition="before"
            label={props.text}
            onChange={props.onChange}
            id={props.id}
            styles={checkBoxStyles}
        />
    )
}

//WILL DELETE COMMENTS BEFORE PULL REQUEST






//OLD HOEMMADE SLIDER in case is needed
{/*<ColoredRect key={i + 0.1} x={x * window.innerWidth * 0.86 / max} y={y} h={h} w={w * windowMax / max} color={color} shadowBlur={1} />*/ }


{/*ALL THIS COMMENTED SECTION IS THE CUSTOM MADE PLAYER SLIDER*/ }
{/*BECAUSE IT'S NOT WORKING AT THE MOMENT WITH LONGER CALLS IT HAS BEEN REPLACED WITH THE OLD SLIDER COMPONENT UNTIL A BETTER IMPLEMENTATION IS FOUND*/ }


{/*THIS LAYER CONTAINS THE VISIBLE TIME OF THE SLIDER*/ }
{/*<Layer>*/ }
{/*    {this.state.isOver*/ }
{/*        ? <Text x={window.innerWidth * 0.86} y={35} text={this.state.postion} />*/ }
{/*        : <Text x={window.innerWidth * 0.86} y={35} text={this.props.valueFormat(this.props.value)} />*/ }
{/*    }*/ }

{/*</Layer>*/ }


{/*THIS LAYER CONTAINS THE SLIDER BAR WITH SECOND DATA AND HOVARABLE AREA*/ }

{/*{sliderTmp.map(({ x, y, h, w, time, sec, strokeWidth }, i) =>*/ }
{/*(<>*/ }
{/*    <PlayerSlider*/ }
{/*        key={i}*/ }
{/*        x={x * window.innerWidth * 0.85 / sliderMax}*/ }
{/*        y={y}*/ }
{/*        h={h}*/ }
{/*        w={w * windowMax / sliderMax}*/ }
{/*        color={"rgba(" + 0 + "," + 0 + "," + 0 + ",0.0)"} //transparent*/ }
{/*        time={time}*/ }
{/*        handleMouseOver={this.handleMouseOver}*/ }
{/*        handleMouseLeave={this.handleMouseLeave}*/ }
{/*        handleClick={this.props.listener}*/ }
{/*        sec={sec}*/ }
{/*        strokeWidth={strokeWidth}*/ }
{/*    />*/ }



{/*</>*/ }
{/*))}*/ }
{/*THIS LAYER CONTAINS THE DRAGGABLE SLIDER*/ }
{/*<Layer>*/ }

{/*<Ellipse*/ }
{/*    x={this.props.value * window.innerWidth * 0.85 / sliderMax}*/ }
{/*    y={40}*/ }
{/*    radiusX={5.5}*/ }
{/*    radiusY={5}*/ }
{/*    fill="#00315e"*/ }
{/*    offsetX={-6}*/ }

{/*/>*/ }



//PREPARE SLIDER DATA--------------------------------------------------------------------------------------------------------------------

//This slider it's a reinterpretation that simulates an actual slider.
//the slider can't be dragged, but you can go with the mouse over the seconds and a line will show up, if you click the actual slider will go to that point
//each second has a dedicated rectangle that will be invisible, in this way I can  diplay a slider on hover
//let sliderTmp = [], sliderMax = 0;
//go through seconds

//for (let dur = 0; dur < this.props.duration; dur++) {
//    let timeFormat, inTime, outTime, width;
//    timeFormat = this.props.valueFormat(dur);
//    inTime = dur;
//    outTime = inTime;
//    if (outTime > sliderMax) {
//        sliderMax = outTime;
//    }
    //width = outTime - inTime;

    //added stroke to slider area to make it hoverable and to also be able to go at the end of the slider
    //divide stage length to seconds to get equal stroke width for each second
    //let strokeWidth = (window.innerWidth * 0.85 / this.props.duration) + 0.9;

    //sliderTmp.push({ x: inTime, y: 5, h: 70, w: width, time: timeFormat, sec: inTime, strokeWidth: strokeWidth });

//}

/*HOMEMADE SLIDER COMPONENT*/

//class PlayerSlider extends React.Component {

//    state = {
//        isOver: false
//    }

//    mouseOver = (props) => {
//        this.setState({ isOver: true });
//        if (props.handleMouseOver !== undefined && props.handleMouseOver != null) {
//            props.handleMouseOver(props.time, props.x, this.state.isOver);


//        }


//    };
//    mouseLeave = (props) => {
//        this.setState({ isOver: false });

//        if (props.handleMouseLeave !== undefined && props.handleMouseLeave != null) {
//            props.handleMouseLeave(this.state.isOver);
//        }
//    };

//    mouseClick = (props) => {
//        if (props.handleClick !== undefined && props.handleClick != null) {
//            props.handleClick(props.sec);
//        }

//    };

//    render() {
//        return (
//            <Rect
//                x={this.props.x}
//                y={this.props.y}
//                width={this.props.w}
//                height={this.props.h}
//                fill={this.props.color}
//                onClick={() => (this.mouseClick(this.props))}
//                onmouseover={() => (this.mouseOver(this.props))}
//                onmouseout={() => (this.mouseLeave(this.props))}
//                stroke={this.props.color}
//                strokeWidth={this.props.strokeWidth}
//            />
//        );
//    }
//}