import { DefaultButton, PrimaryButton, Stack, TextField, Checkbox, Slider, Dropdown, Spinner, MessageBar, StackItem, Text, Toggle } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { MyContext } from '../../../../context';
import * as QueryAP from '../AIAdherencePhrasesQuery'
import InputFieldTitle from '../InputFieldTitle'
import RISlider from '../RISlider';
import FormAdherencePhrase from './FormAdherencePhrase';

export default class EditAIAdherencePhrase extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        const phrase = this.props.data !== undefined ? this.props.data : null
        this.state = {
            //page data
            processing: false,
            //phrase data
            id: phrase !== null ? phrase.id : null,
            name: phrase !== null ? phrase.name : "",
            description: phrase !== null ? phrase.description : "",
            phrase: phrase !== null ? phrase.phrase : "",
            tenantId: phrase !== null ? phrase.tenantId : null,
            version: phrase !== null ? phrase.version : 0,
            masterItemId: phrase !== null ? phrase.masterItemId : null,
            selected: phrase !== null ? phrase.selected : true,
            relatedPhrases: phrase !== null ? phrase.relatedPhrases : [],
            unrelatedPhrases: phrase !== null ? phrase.unrelatedPhrases : [],
            //versions
            selectedOtherVersion: false,
            selectedVersion: phrase !== null ? phrase.id : null,
        };
    }

   

    componentDidMount() {
        this.refreshVersions()
    }

    refetchQueryVersions = null;
    numberOfVersions = 1

    refreshVersions = () => {
        if (this.refetchQueryVersions !== null) {
            this.refetchQueryVersions()
        }
    }

    changeText = (e, value) => {
        this.setState({
            [e.target.id]: value,
            selectedOtherVersion: false
        })
    }

    isValid = () => {
        return this.state.name !== "" && this.state.phrase !== "" && (this.state.relatedPhrases.length > 0 && this.state.unrelatedPhrases.length > 0);
    }

    cancel = () => {
        this.props.closePanel()
    }

    save = () => {
        this.setState({ processing: true })
        const auth = this.props.auth
        const aiAdherencePhrase = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            phrase: this.state.phrase,
            tenantId: this.state.tenantId,
            relatedPhrases: this.state.relatedPhrases,
            unrelatedPhrases: this.state.unrelatedPhrases,
            // manage version
            version: this.numberOfVersions,
            masterItemId: this.state.masterItemId,
            selected: this.state.selected,
        }
        this.props.apol
            .mutate({
                mutation: QueryAP.createAdherencePhrase,
                variables: { auth, aiAdherencePhrase }
            })
            .then((result) => {
                var state = {}
                if (result.data.upsertAIAdherencePhrase.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshPhrases()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.upsertAIAdherencePhrase.text))
        

                }
                this.setState({ ...state, processing: false })
            });
    }



    onChangeVersion = (e, item) => {
        //get specific version to fill the fields
        this.setState({ processing: true })
        let id = item.key
        let auth = this.props.auth
        this.props.apol
            .query({
                query: QueryAP.queryGetAdherencePhrase,
                variables: { auth, id }
            })
            .then(({ loading, error, data, refetch }) => {
                let oldVersion = data.getAdherencePhrase;
                let exact = oldVersion.adherence === 100
                this.setState({
                    name: oldVersion.name,
                    description: oldVersion.description,
                    phrase: oldVersion.phrase,
                    exact,
                    adherence: exact ? parseFloat(100) : parseFloat(oldVersion.adherence),
                    tenantId: oldVersion.tenantId,
                    selectedVersion: id,
                    selectedOtherVersion: true,
                    processing: false
                })
            });
    }

    restoreVersion = () => {
        this.setState({ processing: true })
        //restoreAdherencePhrase -> id vesrion to restore
        let id = this.state.selectedVersion
        this.props.apol
            .mutate({
                mutation: QueryAP.restoreAdherencePhrase,
                variables: { id }
            })
            .then((result) => {
                if (result.data.restoreAdherencePhrase.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshPhrases()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.restoreAdherencePhrase.text))
                }
                this.setState({
                    selectedOtherVersion: false,
                    processing: false
                })
            });
    }

   
    updateAdherencePhrases = (arr, type) => {
        this.setState(prevState => {
            return {
                ...prevState,
                [type]: arr
            }
        })
        console.log(arr)
    }

    render() {
        let masterItemId = this.state.masterItemId
        let diableIncludeExclude = this.state.adherence === 0 || this.state.adherence === 100
        return (
            <div>
                {/*QUERY ALL VERSIONS*/}
                <Query
                    query={QueryAP.getAllVersionOfAdherencePhrase}
                    variables={{ masterItemId }}>
                    {({ loading, error, data, refetch }) => {
                        this.refetchQueryVersions = refetch
                        if (loading) {
                            return (<Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />)
                        }
                        if (error) {
                            return ""
                        }
                        if (data.getAllVersionOfAdherencePhrase == null) {
                            return ""
                        }
                        let versions = data.getAllVersionOfAdherencePhrase.map(v => {
                            return {
                                "key": v.id,
                                "text": v.version
                            }
                        })
                        this.numberOfVersions = versions.length
                        return (<div>
                            {versions.length > 0 && <Stack >
                                <Stack.Item>
                                    <Dropdown
                                        label={this.context.getTranslation("compliance", "version")}
                                        selectedKey={this.state.selectedVersion}
                                        onChange={this.onChangeVersion}
                                        options={versions}
                                    />
                                </Stack.Item>
                            </Stack>}
                        </div>
                        )
                    }}
                </Query>
                <TextField label={this.context.getTranslation("compliance", "name")} id="name" value={this.state.name} onChange={this.changeText} required />
                <TextField label={this.context.getTranslation("compliance", "description")} id="description" value={this.state.description} onChange={this.changeText} />
                <TextField label={this.context.getTranslation("compliance", "phrase")} id="phrase" value={this.state.phrase} onChange={this.changeText} required />

               
                <FormAdherencePhrase sectionName="relatedPhrases" adherencePhrases={this.state.relatedPhrases} updateAdherencePhrases={(arr) => this.updateAdherencePhrases(arr, "relatedPhrases")} />
                <FormAdherencePhrase sectionName="unrelatedPhrases" adherencePhrases={this.state.unrelatedPhrases} updateAdherencePhrases={(arr) => this.updateAdherencePhrases(arr, "unrelatedPhrases")} />

                {/*FOOTER BUTTONS*/}
                <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                    <DefaultButton text={this.context.getTranslation("common", "cancel")} onClick={this.cancel} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={this.state.processing} />
                    {(!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion) &&
                        <PrimaryButton text={this.context.getTranslation("common", "save")} onClick={this.save} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                    }
                    {this.state.selectedOtherVersion && this.state.id !== this.state.selectedVersion &&
                        <PrimaryButton text={this.context.getTranslation("compliance", "restoreVersion")} onClick={this.restoreVersion} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                    }
                </Stack>
            </div>
        );
    }
}