import { MessageBar, MessageBarType, Spinner } from "@fluentui/react";
import { Query } from "react-apollo";
import { MyContext } from "../../../../context";
import EditPolicy from "./EditPolicy";
import * as QueryPR from '../PolicyQuery';
import React, { Component } from 'react';

export default class EditPolicyContainer extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            query: 0,
        };
        this.data = null;
    }

    render() {
        const { auth, id, apol, hidePanel } = this.props
        return (
            <div>
                {/*Edit policy*/}
                {id != null && (
                    
                    <Query
                        query={QueryPR.queryPolicy}
                        variables={{ auth, id }}>
                        {({ loading, error, data }) => {
                            if (loading) {
                                return (
                                    <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                )
                            }
                            if (error) {
                                console.log(error)
                                return (
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        dismissButtonAriaLabel="Close"
                                    >
                                        {this.context.getTranslation("compliance", "error")}
                                    </MessageBar>
                                )
                            }
                            if (data.getPolicy == null) {
                                return (<MessageBar>
                                    {this.context.getTranslation("compliance", "noDataPolicy")}
                                </MessageBar>)
                            }
                            return (<div>
                                <EditPolicy
                                    data={data.getPolicy}
                                    closePanel={hidePanel}
                                    apol={apol}
                                    auth={auth}
                                    refreshPolicies={this.props.refreshPolicies}
                                    setMessageError={this.props.setMessageError}
                                    userRules={this.props.userRules}
                                />
                            </div>
                            )
                        }}
                    </Query>
                )
                }
                {/*Create policy*/}
                {
                    id === null && (
                        <div>
                            <EditPolicy
                                closePanel={hidePanel}
                                apol={apol}
                                auth={auth}
                                refreshPolicies={this.props.refreshPolicies}
                                setMessageError={this.props.setMessageError}
                                userRules={this.props.userRules}
                            />
                        </div>
                    )
                }
            </div>
        )
    }

}