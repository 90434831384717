import { DefaultButton, Stack } from "office-ui-fabric-react";
import { DetailsListLayoutMode } from 'office-ui-fabric-react/lib/DetailsList';
import {
    DetailsList,
    Icon,
    Persona,
    PersonaSize,
    PrimaryButton,
    SelectionMode,
    TextField,
    Checkbox,
    Text,
    ComboBox,
    Spinner
} from "office-ui-fabric-react/lib/index";
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from "reactjs-popup";
import { mutationDeleteUser, mutationUpdateUser, mutationResetUser, queryGetConfigData, queryGetAWSRegions, checkS3StorageConnection } from "../../../queries/querySettings";
import RuleSwitch from "../RuleList/RuleSwitch";
import { MyContext } from '../../../context';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Modal, IconButton } from 'office-ui-fabric-react';
import { Query } from "@apollo/react-components";

const checkboxStyles = () => {
    return {
        root: {
            marginTop: '10px'
        }
    };
};

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 250 }
};

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        {
            flex: '1 1 auto',
            borderTop: '4px solid blue',
            // color: theme.palette.neutralPrimary,
            display: 'flex',
            // fontSize: FontSizes.xLarge,
            alignItems: 'center',
            // fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0',
            },
            'p:first-child': {
                marginTop: 0,
            },
            'p:last-child': {
                marginBottom: 0,
            },
        },
    },
});

const iconButtonStyles = mergeStyleSets({
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    }
});

export default class UserConfigContent extends Component {

    state =
        {
            changed: false,
            rulesAnalytics: this.props.data.user.length > 0 ? null : (this.props.data.user[0].analytics != null ? this.props.data.user[0].analytics.split(',') : []),
            rulesAccess: this.props.data.user.length > 0 ? null : this.props.data.user[0].access,
            rulesRecording: this.props.data.user.length > 0 ? null : this.props.data.user[0].recordingRules,
            rulesReplay: this.props.data.user.length > 0 ? null : this.props.data.user[0].replay,
            addOnAnalytics: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnAnalytics,
            addOnFullChat: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnFullChat,
            addOnZoom: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnZoom,
            addOnGenesys: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnGenesys,
            addOnRingcentral: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnRingcentral,
            addOnDynamics: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnDynamics,
            addOnCompliance: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnCompliance,
            addOnQualityManagement: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnQualityManagement,
            addOnLeapXpert: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnLeapXpert,
            size: this.props.data.user.length,
            regionalStorageLocation: this.props.data.user.length > 1 ? "" : this.props.data.user[0].regionalStorageLocation,
            regionalStorageCustomLocation: this.props.data.user.length > 1 ? "" : this.props.data.user[0].regionalStorageCustomLocation,
            integrationUser: this.props.data.user.length > 1 ? "" : this.props.data.user[0].integrationUser,
            showModal: false,
            awsAccessKeyId: this.props.data.user.length > 1 ? "" : this.props.data.user[0].awsAccessKeyId,
            awsSecretAccess: this.props.data.user.length > 1 ? "" : this.props.data.user[0].awsSecretAccess,
            awsBucketName: this.props.data.user.length > 1 ? "" : this.props.data.user[0].awsBucketName,
            awsRegion: this.props.data.user.length > 1 ? "" : this.props.data.user[0].awsRegion,
            s3Check: "",
            awsServiceUrl: this.props.data.user.length > 1 ? "" : this.props.data.user[0].awsServiceUrl,
            awsType: this.props.data.user.length > 1 ? "" : this.props.data.user[0].awsType,
            licenseType: this.props.data.user.length > 1 ? "" : this.props.data.user[0].license,
        };

    render() {

        return this.userPanelContent();
    }
    userPanelContent = () => {

        return (
            <div>
                {this.showInfo(this.props.data.user)}
                <hr />
                {this.renderLicense()}
                {this.renderRules()}
                {/*{this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.confMapUser && this.renderUserMapping()}*/}
                {this.renderOptions()}
            </div>
        );

    };
    showInfo = (user) => {
        if (user.length === 1) {


            let userContent = {
                name: user[0].name,
                department: user[0].department,
                office: user[0].department,
                superviser: "",
                street: "",
                city: "",
                zip: "",
                country: ""
            };


            return (
                <div>
                    <Persona text={userContent.name} size={PersonaSize.size32} />
                    {user.length < 2 && user[0].groupId != null && <Text>Member of group {user[0].groupName}</Text>}
                </div>
            );

        } else {

            return (
                <div>
                    {user.map(({ name }, i) => {
                        return (
                            <Persona
                                key={i}
                                size={PersonaSize.size32}
                                id={i}
                                text={name}
                            />
                        );
                    })}
                </div>
            );

        }
    }

    toggleSaveButton = (newRules) => {
        if (this.state.changed === false) {
            this.setState({ changed: true });
        }
        this.setState(newRules);
    };
    _closeModal = () => {
        this.setState({ showModal: false });
    }

    _openModal = () => {
        this.setState({ showModal: true });
    }

    checkS3StorageConnection = async () => {
        this.setState({ s3Check: "pending" });
        document.getElementById("checkS3Btn").style.border = "";

        var data = await this.props.apol
            .mutate({
                mutation: checkS3StorageConnection,
                variables: {
                    id: this.state.awsAccessKeyId,
                    secret: this.state.awsSecretAccess,
                    region: this.state.awsRegion,
                    bucket: this.state.awsBucketName,
                    awsServiceUrl: this.state.awsServiceUrl,
                    awsType: this.state.awsType,
                }
            });
        if (data.data.checkS3StorageConnection) {
            document.getElementById("checkS3Btn").style.border = "2px solid green";
            this.setState({ s3Check: "true" });

        } else {
            document.getElementById("checkS3Btn").style.border = "2px solid red";
            this.setState({ s3Check: "false" });
        }
    }
    disbaledCheckValidity = () => {
        if (this.state.s3Check == "pending") {
            return true;
        }
        if (this.state.awsAccessKeyId !== "" && this.state.awsAccessKeyId !== null && this.state.awsSecretAccess !== "" && this.state.awsSecretAccess !== null && this.state.awsBucketName !== "" && this.state.awsBucketName !== null) {
            if (this.state.awsType === "Cloud" && this.state.awsRegion !== "" && this.state.awsRegion !== null) {
                return false
            } else if (this.state.awsType === "OnPremise" && this.state.awsServiceUrl !== "" && this.state.awsServiceUrl !== null) {
                return false
            }
        }
        return true;
    }
    renderRules = () => {
        return <MyContext.Consumer>
            {(context) => (
                <div style={{ padding: "5px" }}>
                    <hr />
                    <h4>{context.getTranslation("configuration", "rules")}</h4>
                    <RuleSwitch data={this.props.data} action={this.toggleSaveButton} userRules={this.props.userRules} />
                    <Stack>
                        <Dropdown
                            label={context.getTranslation("tenants", "regionalStorage")}
                            placeholder={context.getTranslation("common", "selectAnOptionRegional")}
                            styles={dropdownStyles}
                            onChange={(event, newValue) => {
                                if (newValue.key === "S3Storage") {
                                    this.setState({
                                        s3Check: "false",
                                        regionalStorageLocation: newValue.key,
                                        changed: true
                                    })
                                } else {
                                    this.setState({
                                        s3Check: "",
                                        regionalStorageLocation: newValue.key,
                                        changed: true
                                    })
                                }



                                //,
                                //() => {
                                //    if ((this.props.data.user[0].regionalStorageLocation !== null) && (this.state.regionalStorageLocation !== this.props.data.user[0].regionalStorageLocation)) {
                                //        this._openModal();
                                //    }
                                //}

                            }}
                            defaultSelectedKey={this.state.regionalStorageLocation}
                            options={[
                                { key: 'storageHeader', text: context.getTranslation("tenants", "regionalStorage"), itemType: DropdownMenuItemType.Header },
                                { key: 'Unknown', text: context.getTranslation("configuration", "unknownRegional") },
                                { key: 'Emea', text: context.getTranslation("configuration", "emeagrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'UkSouth', text: context.getTranslation("configuration", "uksouthgrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'Apac', text: context.getTranslation("configuration", "apacgrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'Americas', text: context.getTranslation("configuration", "americasgrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'Brazil', text: context.getTranslation("configuration", "brazilgrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'Canada', text: context.getTranslation("configuration", "canadagrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'Japan', text: "Japan GRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'Switzerland', text: "Switzerland GRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'UAENorth', text: "UAE North GRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },

                                { key: 'LrsEmea', text: context.getTranslation("configuration", "emealrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'LrsUkSouth', text: context.getTranslation("configuration", "uksouthlrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'LrsApac', text: context.getTranslation("configuration", "apaclrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'LrsAmericas', text: context.getTranslation("configuration", "americaslrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'LrsBrazil', text: context.getTranslation("configuration", "brazillrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'LrsCanada', text: context.getTranslation("configuration", "canadalrs"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'LrsJapan', text: "Japan LRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'LrsSwitzerland', text: "Switzerland LRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: 'LrsUAENorth', text: "UAE North LRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: "LrsAustralia", text: "Australia LRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: "Australia", text: "Australia GRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: "LrsApacSe", text: "APAC Southeast LRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                                { key: "ApacSe", text: "APAC Southeast GRS", disabled: this.props.tenantData.storage === "S3Storage" ? true : false },

                                { key: 'GrsSwiss', text: context.getTranslation("configuration", "swissgrs"), disabled: true },
                                { key: 'S3Storage', text: context.getTranslation("configuration", "S3Storage"), disabled: this.props.tenantData.storage === "S3Storage" ? false : true },
                                { key: 'CustomStorage', text: context.getTranslation("configuration", "customStorage"), disabled: this.props.tenantData.storage === "S3Storage" ? true : false },
                            ]}
                        />
                        {this.state.regionalStorageLocation === 'CustomStorage' && <Stack.Item><TextField type={"text"} errorMessage={(this.state.regionalStorageCustomLocation === "" || this.state.regionalStorageCustomLocation === null) ? "StorageAccountName" : ""} value={this.state.regionalStorageCustomLocation} onChange={(event, newValue) => {
                            this.setState({
                                regionalStorageCustomLocation: newValue,
                                changed: true
                            });
                        }} /></Stack.Item>}

                        {this.state.regionalStorageLocation === 'S3Storage' && <>
                            <ComboBox
                                style={{ width: "300px" }}
                                placeholder="Select an option"
                                label={context.getTranslation("configuration", "awsType")}
                                options={[
                                    { key: "Cloud", text: "Cloud" },
                                    { key: "OnPremise", text: "On-premise" },

                                ]}
                                selectedKey={this.state.awsType}
                                onChange={(ev, newVal) => {
                                    this.setState({
                                        awsType: newVal.key,
                                        s3Check: "false",
                                        changed: true
                                    });
                                }}
                                errorMessage={this.state.awsType == "" || this.state.awsType == null ? "Aws type must be selected" : ""}
                            />
                            <TextField
                                type={"text"}
                                label={context.getTranslation("configuration", "awsAccessKeyId")}
                                value={this.state.awsAccessKeyId}
                                errorMessage={(this.state.awsAccessKeyId === "" || this.state.awsAccessKeyId === null) ? "Access Key Id cannot be empty" : ""}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        awsAccessKeyId: newValue,
                                        s3Check: "false",
                                        changed: true
                                    });
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={context.getTranslation("configuration", "awsSecretAccess")}
                                value={this.state.awsSecretAccess}
                                errorMessage={(this.state.awsSecretAccess === "" || this.state.awsSecretAccess === null) ? "Secret Access key cannot be empty" : ""}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        awsSecretAccess: newValue,
                                        s3Check: "false",
                                        changed: true
                                    });
                                }}
                            />
                            {this.state.awsType == "OnPremise" &&
                                <TextField
                                    type={"text"}
                                    label={context.getTranslation("configuration", "awsServiceUrl")}
                                    value={this.state.awsServiceUrl}
                                    errorMessage={(this.state.awsServiceUrl === "" || this.state.awsServiceUrl === null) ? "Service Url cannot be empty" : ""}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            awsServiceUrl: newValue,
                                            s3Check: "false",
                                            changed: true
                                        });
                                    }}
                                />}
                            <TextField
                                type={"text"}
                                label={context.getTranslation("configuration", "awsBucketName")}
                                value={this.state.awsBucketName}
                                errorMessage={(this.state.awsBucketName === "" || this.state.awsBucketName === null) ? "Bucket name cannot be empty" : ""}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        awsBucketName: newValue,
                                        s3Check: "false",
                                        changed: true
                                    });
                                }}
                            />
                            {this.state.awsType === "Cloud" &&
                                <Query
                                    query={queryGetAWSRegions}
                                    variables={this.props.authorization} >
                                    {({ loading, error, data }) => {
                                        if (loading) return <div>
                                            <Spinner label={context.getTranslation("common", "loadingRegions")} ariaLive="assertive" labelPosition="top" />
                                        </div>;
                                        if (error) return <h1> </h1>;

                                        if (data.getAWSRegions == null) {
                                            return <div></div>;
                                        }
                                        this.tmpKey = [];
                                        const stack = data.getAWSRegions;
                                        if (data.getAWSRegions != null) {
                                            for (let x = 0; x < stack.length; x++) {
                                                let i = {
                                                    key: stack[x].code,
                                                    text: stack[x].displayName,
                                                };
                                                this.tmpKey.push(i);
                                            }
                                        }

                                        return <ComboBox
                                            style={{ width: "300px" }}
                                            placeholder="Select an option"
                                            label={context.getTranslation("configuration", "defaultRegion")}
                                            options={this.tmpKey}
                                            selectedKey={this.state.awsRegion}
                                            onChange={(ev, newVal) => {
                                                this.setState({
                                                    awsRegion: newVal.key,
                                                    s3Check: "false",
                                                    changed: true
                                                });
                                            }}
                                            errorMessage={this.state.awsRegion == "" || this.state.awsRegion == null ? "Default region must be selected" : ""}
                                        />
                                    }}
                                </Query>}
                            <br />
                            <Stack.Item>
                                <DefaultButton
                                    id="checkS3Btn"
                                    text={context.getTranslation("dashboard", "checkValidity")}
                                    onClick={this.checkS3StorageConnection}
                                    disabled={this.disbaledCheckValidity()}
                                />
                            </Stack.Item>
                        </>}
                    </Stack>
                    {this.state.licenseType == "10"
                        ? (<Checkbox
                            label={context.getTranslation("tenants", "addOnAnalytics")}
                            checked={this.state.addOnAnalytics}
                            styles={checkboxStyles}
                            disabled
                        />)

                        : this.props.tenantData.addOnAnalytics
                            ? (<Checkbox
                                label={context.getTranslation("tenants", "addOnAnalytics")}
                                checked={this.state.addOnAnalytics}
                                styles={checkboxStyles}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        addOnAnalytics: newValue,
                                        changed: true
                                    });

                                }}
                            />)
                            : <div></div>}


                    {this.props.tenantData.addOnFullChat ?
                        <Checkbox
                            label={context.getTranslation("tenants", "addOnFullChat")}
                            checked={this.state.addOnFullChat}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    addOnFullChat: newValue,
                                    changed: true
                                });
                            }}
                        />
                        : <div></div>}

                    {this.state.licenseType == "10"
                        ? (<Checkbox
                            label={context.getTranslation("tenants", "addOnCompliance")}
                            checked={this.state.addOnCompliance}
                            styles={checkboxStyles}
                            disabled

                        />)

                        : this.props.tenantData.addOnCompliance
                            ? (<Checkbox
                                label={context.getTranslation("tenants", "addOnCompliance")}
                                checked={this.state.addOnCompliance}
                                styles={checkboxStyles}
                                onChange={(event, newValue) => {
                                    this.setState((prevState) => {
                                        return {
                                            addOnQualityManagement: newValue ? prevState.addOnQualityManagement : false,
                                            addOnCompliance: newValue,
                                            changed: true
                                        }
                                    });
                                }}
                            />)
                            : <div></div>}

                    {this.props.tenantData.addOnQualityManagement ?
                        <Checkbox
                            disabled={!this.state.addOnCompliance}
                            label={context.getTranslation("tenants", "addOnQualityManagement")}
                            checked={this.state.addOnQualityManagement}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    addOnQualityManagement: newValue,
                                    changed: true
                                });
                            }}
                        />
                        : <div></div>}
                    {this.props.tenantData.addOnZoom ?
                        <Checkbox
                            label={context.getTranslation("tenants", "addOnZoom")}
                            checked={this.state.addOnZoom}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    addOnZoom: newValue,
                                    changed: true
                                });
                            }}
                        />
                        : <div></div>}
                    {this.props.tenantData.addOnGenesys ?
                        <Checkbox
                            label={context.getTranslation("tenants", "addOnGenesys")}
                            checked={this.state.addOnGenesys}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    addOnGenesys: newValue,
                                    changed: true
                                });
                            }}
                        />
                        : <div></div>}
                    {this.props.tenantData.addOnRingcentral ?
                        <Checkbox
                            label={context.getTranslation("tenants", "addOnRingcentral")}
                            checked={this.state.addOnRingcentral}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    addOnRingcentral: newValue,
                                    changed: true
                                });
                            }}
                        />
                        : <div></div>}
                    {this.props.tenantData.addOnLeapXpert ?
                        <Checkbox
                            label={context.getTranslation("tenants", "addOnLeapXpert")}
                            checked={this.state.addOnLeapXpert}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    addOnLeapXpert: newValue,
                                    changed: true
                                });
                            }}
                        />
                        : <div></div>}
                    {(this.state.addOnRingcentral || this.state.addOnGenesys || this.state.addOnZoom || this.state.addOnLeapXpert) &&
                        <TextField
                            label={context.getTranslation("tenants", "integrationUser")}
                            placeholder={context.getTranslation("tenants", "integrationUserPlaceholder")}
                            type={"text"}
                            value={this.state.integrationUser}
                            onChange={(event, newValue) => {
                                this.setState({
                                    integrationUser: newValue,
                                    changed: true
                                });
                            }} />}
                    {this.props.tenantData.addOnDynamics ?
                        <Checkbox
                            label={context.getTranslation("tenants", "addOnDynamics")}
                            checked={this.state.addOnDynamics}
                            styles={checkboxStyles}
                            onChange={(event, newValue) => {
                                this.setState({
                                    addOnDynamics: newValue,
                                    changed: true
                                });
                            }}
                        />
                        : <div></div>}


                </div>
            )
            }
        </MyContext.Consumer>
    };

    getLicences = (context) => {

        let items = [];
        if (this.props.tenantData.licenseAna != null && this.props.tenantData.licenseAna.bought) {
            items.push(
                {
                    key: '5',
                    text: context.getTranslation("configuration", "chatRecordingStandalone"),

                }
            );
        }
        if (this.props.tenantData.licenseSmartVoice != null && this.props.tenantData.licenseSmartVoice.bought) {
            items.push(
                {
                    key: '8',
                    text: context.getTranslation("configuration", "smartVoice"),

                }
            );
        }
        if (this.props.tenantData.licenseSmartVoiceAna != null && this.props.tenantData.licenseSmartVoiceAna.bought) {
            items.push(
                {
                    key: '9',
                    text: context.getTranslation("configuration", "smartVoiceAnalytics"),

                }
            );
        }
        if (this.props.tenantData.licenseSmart != null && this.props.tenantData.licenseSmart.bought) {
            items.push(
                {
                    key: '3',
                    text: context.getTranslation("configuration", "smartRecording"),

                }
            );
        }
        if (this.props.tenantData.licenseSmartAna != null && this.props.tenantData.licenseSmartAna.bought) {
            items.push(
                {
                    key: '4',
                    text: context.getTranslation("configuration", "smartRecordingAnalytics"),

                }
            );
        }
        if (this.props.tenantData.licenseCompVoice != null && this.props.tenantData.licenseCompVoice.bought) {
            items.push(
                {
                    key: '6',
                    text: context.getTranslation("configuration", "complianceVoiceRecording"),

                }
            );
        }
        if (this.props.tenantData.licenseCompVoiceAna != null && this.props.tenantData.licenseCompVoiceAna.bought) {
            items.push(
                {
                    key: '7',
                    text: context.getTranslation("configuration", "complianceVoiceAnalytics"),

                }
            );
        }
        if (this.props.tenantData.licenseComp != null && this.props.tenantData.licenseComp.bought) {
            items.push(
                {
                    key: '1',
                    text: context.getTranslation("configuration", "complianceRecording"),

                }
            );
        }
        if (this.props.tenantData.licenseCompAna != null && this.props.tenantData.licenseCompAna.bought) {
            items.push(
                {
                    key: '2',
                    text: context.getTranslation("configuration", "complianceRecordingAnalytics"),

                }
            );
        }
        if (this.props.tenantData.licenseRecordingInsinghtsAI != null && this.props.tenantData.licenseRecordingInsinghtsAI.bought) {
            items.push(
                {
                    key: '10',
                    text: context.getTranslation("tenants", "licenseRecordingInsinghtsAI"),

                }
            );
        }
        return items;

    };

    renderLicense = () => {
        return <MyContext.Consumer>
            {(context) => (
                <div>
                    <Stack>
                        <ComboBox
                            style={{ width: "300px" }}
                            placeholder="Select an option"
                            label={context.getTranslation("configuration", "license")}
                            options={this.getLicences(context)}
                            defaultSelectedKey={this.state.licenseType}
                            selectedKey={this.state.licenseType}
                            onChange={(ev, option) => {
                                if (option.key == "10") {
                                    this.setState({
                                        licenseType: option.key,
                                        addOnCompliance: true,
                                        addOnAnalytics: true,
                                        changed: true
                                    });
                                }
                                else {
                                    this.setState({
                                        licenseType: option.key,
                                        changed: true
                                    });
                                }
                            }}
                        />
                    </Stack>
                </div>
            )}
        </MyContext.Consumer>
    }

    buttenSel = () => {
        let changed = this.state.changed;
        let check = this.state.s3Check;
        let sel = false;
        if (changed) {
            sel = true;
        }
        if (check == "false" || check == "pending") {
            sel = false;
        }
        if ((check == "" || check == "true") && this.state.regionalStorageLocation == "S3Storage") {
            sel = true;
        }
        if (sel) {
            return <MyContext.Consumer>{(context) => (<PrimaryButton data-automation-id="test" text={context.getTranslation("common", "save")} allowDisabledFocus={true} onClick={() => { this.notifySave(); this.props.cl() }} />)}</MyContext.Consumer>
        }
        else {
            return <MyContext.Consumer>{(context) => (<PrimaryButton data-automation-id="test" disabled text={context.getTranslation("common", "save")} allowDisabledFocus={true} />)}</MyContext.Consumer>
        }

    };

    getUserNames = () => {
        var userNames = "";
        this.props.data.user.forEach(function (aUser) {
            userNames += aUser.name + "; ";
        });
        return userNames;
    }

    notifyDelete = async () => {
        var listId = [];
        this.props.data.user.forEach(function (aUser) {
            listId.push(aUser.id);
        });
        var data = await this.props.apol
            .mutate({
                mutation: mutationDeleteUser,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    id: listId,
                }
            });
        if (data.data.deleteUser == null || data.data.deleteUser.length == 0 || data.data.deleteUser[0].id === "error") {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userDeleteNotSuccessful") + ((data.data.deleteUser != null && data.data.deleteUser.length > 0) ? data.data.deleteUser[0].name : "")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userDeleted")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    notifySave = async () => {
        var userList = [];

        this.props.data.user.forEach((aUser) => {
            userList.push({
                id: aUser.id,
                recordingRules: this.state.rulesRecording,
                access: this.state.rulesAccess,
                replay: this.state.rulesReplay,
                analytics: (this.state.rulesAnalytics != null ? this.state.rulesAnalytics.join() : null),
                groupId: null,
                addOnAnalytics: this.state.addOnAnalytics,
                addOnFullChat: this.state.addOnFullChat,
                addOnZoom: this.state.addOnZoom,
                addOnGenesys: this.state.addOnGenesys,
                addOnRingcentral: this.state.addOnRingcentral,
                addOnDynamics: this.state.addOnDynamics,
                addOnCompliance: this.state.addOnCompliance,
                addOnQualityManagement: this.state.addOnQualityManagement,
                addOnLeapXpert: this.state.addOnLeapXpert,
                regionalStorageLocation: this.state.regionalStorageLocation,
                regionalStorageCustomLocation: this.state.regionalStorageCustomLocation,
                integrationUser: this.state.integrationUser,
                awsAccessKeyId: this.state.awsAccessKeyId,
                awsSecretAccess: this.state.awsSecretAccess,
                awsBucketName: this.state.awsBucketName,
                awsRegion: this.state.awsRegion,
                awsServiceUrl: this.state.awsServiceUrl,
                awsType: this.state.awsType,
                license: this.state.licenseType
            });
        });
        var data = await this.props.apol
            .mutate({
                mutation: mutationUpdateUser,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    user: userList
                }
            });
        if (data.data.updateUser == null || data.data.updateUser[0].name === "error") {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userUpdateNotSuccessful")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userUpdated")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    notifyReset = async () => {
        var userList = [];


        this.props.data.user.forEach((aUser) => {
            userList.push({
                id: aUser.id,
                recordingRules: this.state.rulesRecording,
                access: this.state.rulesAccess,
                replay: this.state.rulesReplay,
                analytics: (this.state.rulesAnalytics != null ? this.state.rulesAnalytics.join() : null),
                groupId: null,
                addOnAnalytics: this.state.addOnAnalytics,
                addOnFullChat: this.state.addOnFullChat,
                addOnZoom: this.state.addOnZoom,
                addOnGenesys: this.state.addOnGenesys,
                addOnRingcentral: this.state.addOnRingcentral,
                addOnDynamics: this.state.addOnDynamics,
                addOnCompliance: this.state.addOnCompliance,
                addOnQualityManagement: this.state.addOnQualityManagement,
                addOnLeapXpert: this.state.addOnLeapXpert
            });
        });
        var data = await this.props.apol
            .mutate({
                mutation: mutationResetUser,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    user: userList
                }
            });
        if (data.data.resetUser == null || data.data.resetUser[0].name === "error") {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userResetNotSuccessful")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userResetSuccessful")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    renderOptions = () => {

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>
                        <hr />
                        <p >
                            {this.buttenSel()}
                            <br />
                            <br />
                            <DefaultButton
                                onClick={() => { this.notifyReset(); this.props.cl() }}
                                style={{ background: "red", color: "white" }}
                                data-automation-id="test"
                                text={context.getTranslation("common", "reset")}
                                allowDisabledFocus={true}
                            />
                        </p>
                        <p>

                            <Popup trigger={
                                <DefaultButton
                                    data-automation-id="test"
                                    style={{ background: "red", color: "white" }}
                                    text={context.getTranslation("configuration", "deleteUser")}
                                    allowDisabledFocus={true}
                                />}
                                modal >
                                {close => (
                                    <div>
                                        <div className="ms-Grid" dir="ltr">
                                            <p>{context.getTranslation("configuration", "deleteUserQuestion")} {this.getUserNames()} ?</p>
                                            <p>
                                                <DefaultButton
                                                    onClick={
                                                        () => {
                                                            this.notifyDelete();
                                                            close();
                                                            this.props.cl()
                                                        }
                                                    }
                                                    data-automation-id="test"
                                                    text={context.getTranslation("common", "yes")}
                                                    allowDisabledFocus={true}
                                                />

                                                <PrimaryButton
                                                    data-automation-id="test"
                                                    text={context.getTranslation("common", "no")}
                                                    allowDisabledFocus={true}
                                                    onClick={
                                                        () => {
                                                            close();
                                                            this.props.cl()

                                                        }
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Popup>

                        </p>

                    </div>
                )
                }
            </MyContext.Consumer>
        );

    };

    getItems = () => {
        let _allItems = [];

        return _allItems;
    }
    _columns = [
        { key: 'column1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userId")}</>)}</MyContext.Consumer>, fieldName: 'userId', minWidth: 20, maxWidth: 160, isResizable: true },
        { key: 'column2', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "systemId")}</>)}</MyContext.Consumer>, fieldName: 'systemId', minWidth: 20, maxWidth: 160, isResizable: true },
        { key: 'column3', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "tenantId")}</>)}</MyContext.Consumer>, fieldName: 'tenantId', minWidth: 20, maxWidth: 160, isResizable: true },
        {
            key: 'column4',
            name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "options")}</>)}</MyContext.Consumer>,
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onColumnClick: this._onColumnClick,
            onRender: (item) => {
                return <MyContext.Consumer>
                    {(context) => (
                        <Popup trigger={<a style={{ cursor: "pointer" }}><Icon iconName={"RemoveLink"} /></a>} modal>
                            {close => (

                                <div>
                                    <h2>{context.getTranslation("configuration", "unlinkAccount")} : {item.value}</h2>

                                    <p><DefaultButton onClick={close} data-automation-id="test" text={context.getTranslation("common", "delete")} allowDisabledFocus={true} /><PrimaryButton onClick={close} data-automation-id="test" text={context.getTranslation("common", "cancel")} allowDisabledFocus={true} /></p>
                                </div>
                            )}
                        </Popup>
                    )
                    }
                </MyContext.Consumer>

            }
        }
    ];
    _columnsPopup = [
        { key: 'column1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userId")}</>)}</MyContext.Consumer>, fieldName: 'userId', minWidth: 20, maxWidth: 160, isResizable: true },
        { key: 'column2', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "systemId")}</>)}</MyContext.Consumer>, fieldName: 'systemId', minWidth: 20, maxWidth: 160, isResizable: true },
        { key: 'column3', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "tenantId")}</>)}</MyContext.Consumer>, fieldName: 'tenentId', minWidth: 20, maxWidth: 160, isResizable: true }, {
            key: 'column4',
            name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "options")}</>)}</MyContext.Consumer>,
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onColumnClick: this._onColumnClick,
            onRender: (item) => {
                return <Popup trigger={<a style={{ cursor: "pointer" }}><Icon iconName={"RemoveLink"} /></a>} position={"center"} closeOnDocumentClick>
                    <a style={{ cursor: "pointer", width: "16px", height: "16px" }}><Icon iconName={"Delete"} /></a>
                </Popup>
            }
        }
    ];

    renderUserMapping = () => {
        if (this.state.size > 1) {
            return (<div></div>);
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>
                        <hr />
                        <h4>{context.getTranslation("configuration", "linkedAccounts")}</h4>
                        <DetailsList
                            key={"001"}
                            items={this.getItems()}
                            columns={this._columns}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selection={false}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={false}
                            onItemInvoked={this._onItemInvoked}
                        />

                        <p className="padding-default--tb">

                            <Popup trigger={

                                <PrimaryButton
                                    data-automation-id="test"
                                    text={context.getTranslation("configuration", "linkAccount")}
                                    allowDisabledFocus={true}
                                />
                            } modal >

                                {close => (
                                    <div>
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">

                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                                                    <h2>{context.getTranslation("configuration", "linkAccount")}</h2>

                                                    <div className="padding-default-b">
                                                        <Persona text={this.props.data.user[0].name} size={PersonaSize.size32} />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="ms-Grid-row">

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                                    <form>
                                                        <TextField label={context.getTranslation("configuration", "userId")} />
                                                        <TextField label={context.getTranslation("configuration", "systemId")} />
                                                        <TextField label={context.getTranslation("configuration", "tenantId")} />
                                                        <p className="padding-default--tb">
                                                            <DefaultButton data-automation-id="test" text={context.getTranslation("configuration", "add")} allowDisabledFocus={true} />
                                                        </p>
                                                    </form>
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">

                                                    <DetailsList
                                                        key={"002"}
                                                        items={this.getItems()}
                                                        columns={this._columnsPopup}
                                                        layoutMode={DetailsListLayoutMode.fixedColumns}
                                                        selection={false}
                                                        selectionMode={SelectionMode.none}
                                                        selectionPreservedOnEmptyClick={false}
                                                        onItemInvoked={this._onItemInvoked}
                                                    />

                                                </div>

                                            </div>

                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <p className="padding-default--tb">
                                                        <PrimaryButton
                                                            onClick={() => { close(); this.notifySave() }}
                                                            data-automation-id="test"
                                                            text={context.getTranslation("common", "save")}
                                                            allowDisabledFocus={true}
                                                        />

                                                        <DefaultButton
                                                            onClick={close}
                                                            data-automation-id="test"
                                                            text={context.getTranslation("common", "cancel")}
                                                            allowDisabledFocus={true}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }

                            </Popup>
                        </p>
                    </div>
                )
                }
            </MyContext.Consumer>
        );
    }

}
//this.props.data.user[0]
