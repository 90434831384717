import { CommandBarButton, DetailsList, FontIcon, Icon, MarqueeSelection, Panel, PanelType, Text, Selection, SelectionMode, Separator, Spinner, Stack, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../context';
import { Query } from 'react-apollo';
import * as QueryPR from './PolicyRulesQuery';
import EditPolicyRuleContainers from './Panels/EditPolicyRuleContainers';
import { NetworkStatus } from 'apollo-boost';
import AlertBar from '../../CustomComponents/AlertBar';
import SafetyDialog from '../../CustomComponents/SafetyDialog'
import Utils from '../../common/Utils'

const orderAlphabetically  = Utils.orderAlphabetically


export default class PolicyRules extends Component {
    static contextType = MyContext
    _selection: Selection;

    constructor(props) {
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    policyRuleId: this._selection.getSelection().length > 0 ? this._selection.getSelection()[0].id : "",
                    alertMessage: "",
                });
            }
        });

        this.state = {
            isPanelOpen: false,
            policyRuleId: null,
            alertMessage: "",
            alertMessagePanel: "",
            hideDialogDelete: true,
            isProcessing: false
        };
    }

    editPolicyRule = () => {
        var state = {};
        if (this._selection.getSelection().length > 0 && this._selection.getSelection()[0].id !== null) {
            state = {
                ...state,
                isPanelOpen: true,
                policyRuleId: this._selection.getSelection().length > 0 ? this._selection.getSelection()[0].id : ""
            }
        } else {
            state = {
                ...state,
                alertMessage: this.context.getTranslation("compliance", "selectPolicyRule"),
                alertType: "warning"
            }
        }
        this.setState({
            ...state
        })
    }

    newPolicyRule = () => {
        this.setState({
            isPanelOpen: true,
            policyRuleId: null,
            alertMessage:""
        })
    }

    deletePolicyRule = () => {
        this.setState({ hideDialogDelete: true, isProcessing: true })
        if (this._selection.getSelection().length > 0 && this._selection.getSelection()[0].id !== null) {
            const id = this._selection.getSelection()[0].id
            this.props.apol
                .mutate({
                    mutation: QueryPR.deletePolicyRule,
                    variables: { id }
                })
                .then((result) => {
                    if (result.data.deletePolicyRule.status !== "ok") {
                        this.setState({
                            alertMessage: this.context.getTranslation("compliance", result.data.deletePolicyRule.text),
                            alertType: result.data.deletePolicyRule.status,
                            isProcessing: false
                        })
                    } else {
                        this.setState({ isProcessing: false })
                        this.refetchQueryPolicyRules();
                    }
                });
        }
    }


    hidePanel = () => {
        this.setState({ isPanelOpen: false, alertMessagePanel: "" });
    };

    _getKey = (item, index) => {
        return item.key;
    }

    _renderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName]
        switch (column.key) {
            case 'keywords':
                var keywords = []
                item.areas.forEach(a => {
                    if (a.searchAreaType === "Keywords") {
                        a.areaItems.items.forEach(w => {
                            keywords.push(w.value)
                        })
                    }
                })
                return <span>{this.joinElements(keywords)}</span>;
            case 'categories':
                var categories = []
                item.areas.forEach(a => {
                    if (a.searchAreaType === "Categories") {
                        a.areaItems.items.forEach(c => {
                            categories.push(c.name)
                        })
                    }
                })
                return <span>{this.joinElements(categories)}</span>;
            case "patterns":
                var patterns = []
                item.areas.forEach(a => {
                    if (a.searchAreaType === "Patterns") {
                        a.areaItems.items.forEach(c => {
                            patterns.push(c.name)
                        })
                    }
                })
                return <span>{this.joinElements(patterns)}</span>;
            case "phrases":
                var phrases = []
                item.areas.forEach(a => {
                    if (a.searchAreaType === "AdherencePhrases") {
                        a.areaItems.items.forEach(c => {
                            phrases.push(c.name)
                        })
                    }
                })
                return <span>{this.joinElements(phrases)}</span>;
            case "AIQuestions":
                var AIQuestions = []
                item.areas.forEach(a => {
                    if (a.searchAreaType === "AIQuestions") {
                        a.areaItems.items.forEach(c => {
                            AIQuestions.push(c.name)
                        })
                    }
                })
                return <span>{this.joinElements(AIQuestions)}</span>;
            case "personal":
                return <span>{<FontIcon iconName={fieldContent ? "CheckboxComposite" : "Checkbox"} style={{ size: "10" }} />}</span>;
            case "createdBy":
                return <span>{fieldContent === "" ? this.context.getTranslation("compliance", "system") : fieldContent}</span>;
            default:
                //console.log(`campo: ${column.fieldName} : ${fieldContent}`)
                return <span>{fieldContent !== null ? fieldContent : ""}</span>;
        }
    }

    joinElements = (array) => {
        var stringArray = array.join();
        return stringArray.length > 30 ? stringArray.substring(0, 30) + "..." : stringArray;
    }

    getPolicyRuleGroups = (data) => {
        //unique groups id
        let allGroupsIds = [...new Set(data.map(pr => pr.policyRuleGroup.id))];
        //create groups for dataillist
        let groups = allGroupsIds.map(groupId => {
            var startIndex = "";
            var endIndex = "";
            var name = "";
            for (var i = 0; i < data.length; i++) {
                if (data[i].policyRuleGroup.id === groupId) {
                    name = data[i].policyRuleGroup.name
                    startIndex = startIndex === "" ? i : startIndex
                    endIndex = i + 1
                }
            }
            return ({
                "key": groupId,
                "name": name,
                "startIndex": startIndex,
                "count": endIndex - startIndex,
                "level": 0
            })
        })
        return groups;
    }

    refetchQueryPolicyRules = null;

    refreshPolicyRules = () => {
        this.refetchQueryPolicyRules()
    }

    setMessageError = (message) => {
        this.setState({
            alertMessagePanel: message
        })
    }


    clearAlert = () => {
        if (this.state.alertMessagePanel !== "") {
            this.setState({
                alertMessagePanel: ""
            })
        }
    }

    getHeader = () => {
        var label = this.state.policyRuleId === null ? this.context.getTranslation("compliance", "addPolicyRuleTitle") : this.context.getTranslation("compliance", "editPolicyRuleTitle")
        return (
            <div style={{ flex: "auto", marginLeft: "20px" }}>
                <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>{label}</div>
                <AlertBar
                    message={this.state.alertMessagePanel}
                    alertType={"error"}
                    clearAlert={this.clearAlert}
                />
            </div >
        )
    }

    openDialogDelete = () => {
        if (this._selection.getSelection().length > 0 && this._selection.getSelection()[0].id !== null) {
            this.setState({ hideDialogDelete: false })
        } else {
            this.setState({
                alertMessage: this.context.getTranslation("compliance", "selectPolicyRule"),
                alertType: "warning"
            })
        }
    }


    render() {

        const columns = this.props.userRules.ruleAccess.userComplianceAIQuestions ? [
                { key: 'name', name: this.context.getTranslation("compliance", "name"), fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'description', name: this.context.getTranslation("compliance", "description"), fieldName: 'description', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'keywords', name: this.context.getTranslation("compliance", "keywords"), fieldName: 'keywords', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'categories', name: this.context.getTranslation("compliance", "categories"), fieldName: 'categories', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'patterns', name: this.context.getTranslation("compliance", "patterns"), fieldName: 'patterns', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'phrases', name: this.context.getTranslation("compliance", "AdherencePhrases"), fieldName: 'phrases', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'AIQuestions', name: this.context.getTranslation("compliance", "AIQuestions"), fieldName: 'AIQuestions', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'createdBy', name: this.context.getTranslation("compliance", "createdBy"), fieldName: 'createdBy', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'personal', name: this.context.getTranslation("compliance", "personal"), fieldName: 'personal', minWidth: 80, maxWidth: 100, isResizable: true },
            ] : 
            [
                { key: 'name', name: this.context.getTranslation("compliance", "name"), fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'description', name: this.context.getTranslation("compliance", "description"), fieldName: 'description', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'keywords', name: this.context.getTranslation("compliance", "keywords"), fieldName: 'keywords', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'categories', name: this.context.getTranslation("compliance", "categories"), fieldName: 'categories', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'patterns', name: this.context.getTranslation("compliance", "patterns"), fieldName: 'patterns', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'phrases', name: this.context.getTranslation("compliance", "AdherencePhrases"), fieldName: 'phrases', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'createdBy', name: this.context.getTranslation("compliance", "createdBy"), fieldName: 'createdBy', minWidth: 100, maxWidth: 200, isResizable: true },
                { key: 'personal', name: this.context.getTranslation("compliance", "personal"), fieldName: 'personal', minWidth: 80, maxWidth: 100, isResizable: true },
            ]

        return (
            <div style={{ overflow: "auto", maxHeight: window.innerHeight - 120, position: 'relative', marginTop: "5px" }}>

                {/*Manage button add and edit  and delete policy rule*/}
                <Stack horizontal styles={{ root: { height: 44 } }}>
                    <CommandBarButton
                        styles={{ root: { marginTop: 10 } }}
                        iconProps={{ iconName: 'Add' }}
                        text={this.context.getTranslation("compliance", "addRule")}
                        disabled={!this.props.userRules.ruleAccess.userCompliancePolicyAssign}
                        onClick={this.newPolicyRule}
                    />
                    <CommandBarButton
                        styles={{ root: { marginTop: 10 } }}
                        iconProps={{ iconName: 'Edit' }}
                        text={this.context.getTranslation("compliance", "editRule")}
                        disabled={!this.props.userRules.ruleAccess.userCompliancePolicyAssign}
                        onClick={this.editPolicyRule}
                    />
                    <CommandBarButton
                        styles={{ root: { marginTop: 10 } }}
                        iconProps={{ iconName: 'Delete' }}
                        text={this.context.getTranslation("compliance", "deleteRule")}
                        disabled={!this.props.userRules.ruleAccess.userCompliancePolicyAssign}
                        onClick={this.openDialogDelete}
                    />
                </Stack>

                {/*Warning and error message*/}
                <AlertBar
                    message={this.state.alertMessage}
                    alertType={this.state.alertType}
                    clearAlert={() => { this.setState({ alertMessage: "" }) }}
                />

                {this.state.isProcessing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}

                {/*Policy rules datailList*/}
                {!this.state.isProcessing && <Stack style={{ marginTop: 10 }}>
                    <Query
                        query={QueryPR.queryPolicyRules}
                        notifyOnNetworkStatusChange={true}>
                        {({ loading, error, data, refetch, networkStatus }) => {
                            this.refetchQueryPolicyRules = refetch;
                            if (loading || networkStatus === NetworkStatus.refetch) return (
                                <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                            )
                            if (error) {
                                console.log(error)
                                return (
                                    <AlertBar message={"Error!"} alertType={"error"} />
                                );
                            }
                            if (data.getPolicyRules == null) return (
                                <AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />
                            );
                            //to have always template group up in the table
                            var template = data.getPolicyRules.filter(pr => pr.policyRuleGroup.generalGroup)
                            var otherGroups = data.getPolicyRules.filter(pr => !pr.policyRuleGroup.generalGroup)
                            template.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))
                            otherGroups.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))
                            var sortData = [...template, ...otherGroups]
                            var groups = this.getPolicyRuleGroups(sortData)
                            return (
                                <MarqueeSelection selection={this._selection}>
                                    <DetailsList
                                        items={sortData}
                                        groups={groups}
                                        columns={columns}
                                        selectionMode={SelectionMode.single}
                                        selection={this._selection}
                                        getKey={this._getKey}
                                        setKey="single"
                                        onRenderItemColumn={this._renderItemColumn}
                                    />
                                </MarqueeSelection>
                            )
                        }}
                    </Query>
                </Stack>}

                {/*Policy rule edit and create panel*/}
                <Panel
                    isOpen={this.state.isPanelOpen}
                    onDismiss={this.hidePanel}
                    isFooterAtBottom={false}
                    onRenderHeader={this.getHeader}
                    type={PanelType.medium}
                >
                    <EditPolicyRuleContainers
                        policyRuleId={this.state.policyRuleId}
                        closePanel={this.hidePanel}
                        apol={this.props.apol}
                        auth={this.props.auth}
                        userRules={this.props.userRules }
                        refreshPolicyRules={this.refreshPolicyRules}
                        setMessageError={this.setMessageError}
                        ruleAccess={this.props.userRules.ruleAccess}
                    />
                </Panel>

                {/*SafetyDialog for delete policy rule*/}
                <SafetyDialog
                    title={this.context.getTranslation("compliance", "deleteRule")}
                    subText={this.context.getTranslation("compliance", "confirmDelete")}
                    hideDialog={this.state.hideDialogDelete}
                    closeDialog={() => { this.setState({ hideDialogDelete: true }) }}
                    ok={this.deletePolicyRule}
                />
            </div>
        )
    }





}
