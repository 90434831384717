import React, { Component } from 'react';

import { MyContext } from '../../../context';
import { queryGetConfigData } from "../../../queries/querySettings";
import { toast } from 'react-toastify';
import Utils from '../../common/Utils';
import UserPicker from '../../ReportView/ReportConfiguration/UserPicker';

import gql from 'graphql-tag';

import {
    DefaultButton,
    PrimaryButton,
    Stack,
    TextField,
    ComboBox, Checkbox, Spinner, Text, Label, SpinButton, ChoiceGroup
    , Toggle
} from "office-ui-fabric-react";


class IntegrationConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.data != null ? this.props.data.name : "Integration",
            id: this.props.isNew ? Utils.guidGenerator() : this.props.data.id,
            integrationClientId: this.props.isNew ? "" : this.props.data.integrationClientId,
            integrationClientSecret: this.props.isNew ? "" : this.props.data.integrationClientSecret,
            integrationUsername: this.props.isNew ? "" : this.props.data.integrationUsername,
            integrationPassword: this.props.isNew ? "" : this.props.data.integrationPassword,
            enabled: this.props.isNew ? "true" : this.props.data.enabled,
            processingRegion: this.props.isNew ? "EU" : this.props.data.processingRegion,
            integrationType: this.props.isNew ? "Zoom" : this.props.data.integrationType,
            syncRecordingRule: this.props.isNew ? false : this.props.data.syncRecordingRule,
            allRecordings: this.props.isNew ? false : this.props.data.allRecordings,
            mappingUserId: (this.props.data == null || this.props.data.mappingUserId == null) ? [] : [{
                userId: this.props.data.mappingUserId
            }],
            folderName: this.props.isNew ? "" : this.props.data.folderName,
            integrationPrivateKey: this.props.isNew ? "" : this.props.data.integrationPrivateKey,
            integrationPrivateKeyFile: "",
            hasPrivateKey: this.props.isNew ? false : this.props.data.hasPrivateKey
        };
    }


    loadFile = async (file) => {
        let text = await file.text();
        this.setState({ integrationPrivateKeyFile: text });
    }

    onFileChange = event => {

        let activeFile = event.target.files[0];

        if (activeFile) {
            this.setState({ hasPrivateKey: true });

        }

        if (event.target.files[0]) {

            this.loadFile(event.target.files[0]);
        }

    };

    render() {


        return (
            <MyContext.Consumer>
                {(context) => (

                    <Stack vertical>
                        {/* name */}
                        <TextField type={"text"}
                            label={context.getTranslation("configuration", "jobName")}
                            value={this.state.name}
                            onChange={(event, newValue) => {
                                this.setState({ name: newValue });
                            }}
                        />

                        <Toggle
                            defaultChecked={this.state.enabled}
                            label={context.getTranslation("settings", "enabled")}
                            onText={context.getTranslation("settings", "on")}
                            offText={context.getTranslation("settings", "off")}
                            onChange={(ev, checked) => {
                                this.setState({
                                    enabled: checked,
                                    updated: true
                                });
                            }}
                        />


                        <br />
                        <hr />

                        <ComboBox
                            style={{ width: "300px" }}
                            placeholder="Select an option"
                            selectedKey={this.state.integrationType}
                            label={context.getTranslation("configuration", "integrationType")}
                            onChange={(ev, newVal) => {
                                this.setState({
                                    integrationType: newVal.key,
                                    updated: true
                                });
                            }}
                            options={[{
                                key: "Zoom",
                                text: "Zoom"
                            },
                            {
                                key: "Ringcentral",
                                text: "RingCentral",
                            },
                            {
                                key: "Genesys",
                                text: "Genesys",
                            },
                            {
                                key: "Dynamics",
                                text: "Dynamics",
                            },
                            {
                                key: "LeapXpert",
                                text: "LeapXpert",
                            }]
                            }
                        />
                        <br />

                        <Label>{context.getTranslation("configuration", "integrationCredentials")}</Label>


                        {/* import connection string */}
                        <Stack.Item>
                            {(this.state.integrationType === "Dynamics" || this.state.integrationType === "Genesys" ) && <TextField label={context.getTranslation("configuration", "integrationClientId")}
                                value={this.state.integrationClientId}
                                onChange={(event, newValue) => {
                                    this.setState({ integrationClientId: newValue });
                                }}
                            />}
                            {(this.state.integrationType === "Dynamics" || this.state.integrationType === "Genesys") && <TextField label={context.getTranslation("configuration", "integrationClientSecret")}
                                value={this.state.integrationClientSecret}
                                type="password"
                                onChange={(event, newValue) => {
                                    this.setState({ integrationClientSecret: newValue });
                                }}
                            />}
                            {(this.state.integrationType !== "Ringcentral" && this.state.integrationType !== "LeapXpert") &&
                                <TextField label={context.getTranslation("configuration", this.state.integrationType === "Genesys" ? "regionUrl" : (this.state.integrationType === "Dynamics" ? "integrationDynamicsUrl" : "integrationUsername"))}
                                    value={this.state.integrationUsername}
                                    disabled={this.state.integrationType === "Zoom" ? true : false}
                                    onChange={(event, newValue) => {
                                        this.setState({ integrationUsername: newValue });
                                    }}

                                />}
                            {this.state.integrationType === "Ringcentral" && <TextField label={context.getTranslation("configuration", "integrationJwtToken")}
                                value={this.state.integrationPassword}
                                type="password"
                                onChange={(event, newValue) => {
                                    this.setState({ integrationPassword: newValue });
                                }}
                            />}
                            {this.state.integrationType == "Zoom" && this.state.integrationUsername != "" &&
                                <DefaultButton onClick={this.authorizeWithZoom} text={context.getTranslation("configuration", "reauthenticateWithZoom")} />
                            }

                            {this.state.integrationType === "LeapXpert" && <>
                                <br />

                                <Label>{context.getTranslation("configuration", "uploadPrivateKey")}</Label>

                                <div id="clickable-area">
                                    <input type="file" onChange={this.onFileChange} id="file-btn" />
                                    <div className="upload-button"><span>{this.state.hasPrivateKey ? context.getTranslation("common", "updateFile") : context.getTranslation("common", "uploadFile")}</span>
                                    </div><p id="clickable-area-text">{this.state.hasPrivateKey
                                        ? <i className="ms-Icon ms-Icon--Accept" style={{ fontSize: "20px" }}></i>
                                        : context.getTranslation("common", "noFileSelected")} &nbsp;</p>
                                </div>

                                <TextField label={context.getTranslation("configuration", "privateKey")}
                                    value={this.state.integrationPrivateKey}
                                    type="password"
                                    onChange={(event, newValue) => {
                                        this.setState({ integrationPrivateKey: newValue });
                                    }}
                                />

                                <TextField label={context.getTranslation("configuration", "folderName")}
                                    value={this.state.folderName}
                                    onChange={(event, newValue) => {
                                        this.setState({ folderName: newValue });
                                    }}

                                />
                            </>}
                        </Stack.Item>


                        {this.state.integrationType !== "Dynamics" && <>
                            <br />
                            <hr />

                            <Toggle
                                defaultChecked={this.state.syncRecordingRule}
                                label={context.getTranslation("configuration", "integrationSyncRecordingRule")}
                                onText={context.getTranslation("settings", "on")}
                                offText={context.getTranslation("settings", "off")}
                                onChange={(ev, checked) => {
                                    this.setState({
                                        syncRecordingRule: checked,
                                        updated: true
                                    });
                                }}
                            />
                        </>}

                        {this.state.allRecordings && <Stack.Item>
                            <UserPicker
                                user={this.state.mappingUserId}
                                fc={(user) => {
                                    this.setState({
                                        mappingUserId: user,
                                        updated: true
                                    });
                                }}
                            />
                        </Stack.Item>}
                        <br />
                        <hr />
                        <ComboBox
                            style={{ width: "300px" }}
                            placeholder="Select an option"
                            selectedKey={this.state.processingRegion}
                            label={context.getTranslation("configuration", "processingRegion")}
                            onChange={(ev, newVal) => {
                                this.setState({
                                    processingRegion: newVal.key,
                                    updated: true
                                });
                            }}
                            options={[{
                                key: "EU",
                                text: "EU"
                            },
                            {
                                key: "US",
                                text: "USA",
                            },
                            {
                                key: "AS",
                                text: "APAC",
                            }]
                            }
                        />
                        <br />

                        <br />

                        <Stack.Item>
                            <PrimaryButton onClick={this.savePanel} text={context.getTranslation("common", "save")} disabled={!this.isValid()} />
                            <DefaultButton onClick={this.resetState} text={context.getTranslation("common", "cancel")} />
                        </Stack.Item>
                    </Stack>
                )
                }
            </MyContext.Consumer>
        );
    }

    authorizeWithZoom = () => {
        // Open page to authorize with zoom
        window.open("https://zoom.us/oauth/authorize?response_type=code&client_id=" + this.state.integrationClientId + "&state=" + this.state.id + "&redirect_uri=" + window.location.origin + "/zoom", '_blank');
        this.resetState();
    }

    isValid = () => {
        if (
            (this.state.name != null && this.state.name.length > 0 && (this.state.mappingType != "UsersToComplianceOfficer" || this.state.mappingUserId.length > 0))
        ) {
            return true;

        }

        return false;
    }

    resetState = () => {
        if (this.props.isNew) {
            this.setState({
                isPanelOpen: false,
                isModalOpen: false,
                isChanged: false,
                name: ""
            });
        } else {
            this.setState({
                isPanelOpen: false,
                isModalOpen: false,
                isChanged: false
            });
        }
        this.props.closeModal();

    }

    savePanel = () => {
        // Save Changes
        console.log(this.state.mappingUserId);
        let job =
        {
            id: this.state.id,
            name: this.state.name,
            integrationType: this.state.integrationType,
            integrationClientId: this.state.integrationClientId,
            integrationClientSecret: this.state.integrationClientSecret,
            integrationUsername: this.state.integrationUsername,
            integrationPassword: this.state.integrationPassword,
            enabled: this.state.enabled ? true : false,
            processingRegion: this.state.processingRegion,
            syncRecordingRule: this.state.syncRecordingRule,
            allRecordings: this.state.allRecordings,
            mappingUserId: (this.state.mappingUserId != null && this.state.mappingUserId.length > 0) ? this.state.mappingUserId[0].userId : null,
            folderName: this.state.folderName,
            integrationPrivateKeyFile: this.state.integrationPrivateKeyFile,
            integrationPrivateKey: this.state.integrationPrivateKey

        };
        if (!this.props.isNew) {

            this.props.apol
                .mutate({
                    mutation: gql`
                    mutation ent (
                        $input: AuthInput, $integrationJob: IntegrationRuleInputType) {
                        updateIntegrationJob (
                            input: $input, 
                            integrationJob: $integrationJob
                        ) 
                        {
                            id
                        }
                    }
                `,
                    refetchQueries: [{
                        query: queryGetConfigData,
                        variables: { auth: this.props.authorization },
                    }],
                    variables: {
                        input: this.props.authorization,
                        integrationJob: job
                    },
                })
                .then((result) => {
                    toast.info('Integration Job changed', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                });
        }
        else {
            this.props.apol
                .mutate({
                    mutation: gql`
                    mutation ent (
                        $input: AuthInput, $integrationJob: IntegrationRuleInputType) {
                        createIntegrationJob (
                            input: $input, 
                            integrationJob: $integrationJob
                        ) 
                        {
                            id,
                            integrationClientId
                        }
                    }
                `,
                    refetchQueries: [{
                        query: queryGetConfigData,
                        variables: { auth: this.props.authorization },
                    }],
                    variables: {
                        input: this.props.authorization,
                        integrationJob: job
                    },
                })
                .then((result) => {
                    toast.info('Integration Job created', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    if (this.state.integrationType == "Zoom") {
                        window.open("https://zoom.us/oauth/authorize?response_type=code&client_id=" + result.data.createIntegrationJob.integrationClientId + "&state=" + result.data.createIntegrationJob.id + "&redirect_uri=" + window.location.origin + "/zoom", '_blank');
                    }

                });
        }
        this.props.closeModal();


    };
}



export default IntegrationConfig;