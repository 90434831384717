import React, { Component } from 'react'
import * as microsoftTeams from "@microsoft/teams-js";
import Utils from "./../common/Utils";
import { iniApplicationId, iniApiImpersonation } from "../../ini/ini";
import './Login.scss'

export default class Login extends Component {

    state = {
        loginHint: ""
    }

    componentDidMount() {

        // Generate random state string and store it, so we can verify it in the callback
        // Go to the Azure AD authorization endpoint
        this.timerID = setInterval(
            () => this.authenticate(),
            1000
        );
        microsoftTeams.initialize();
        microsoftTeams.getContext((context => {
            this.setState({ loginHint: context.loginHint });
        }));
    }

    authenticate() {
        clearInterval(this.timerID);
        let queryParams = {
            client_id: iniApplicationId,
            response_type: "id_token token",
            response_mode: "fragment",
            ressource: iniApplicationId,
            grant_type: "client_credentials",
            scope: "openid profile User.ReadBasic.All User.Read Chat.Read" + " " + iniApiImpersonation,
            nonce: Utils.guidGenerator(),
            state: Utils.guidGenerator(),
            redirect_uri: window.location.origin + "/loginResult",
            login_hint: this.state.loginHint != null ? this.state.loginHint : "",
            requested_token_use: "on_behalf_of"
        };
        var esc = encodeURIComponent;
        var query = Object.keys(queryParams)
            .map(k => esc(k) + '=' + esc(queryParams[k]))
            .join('&');
        let authorizeEndpoint = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?" + query
        console.log("Login Page with loginHint:" + this.state.loginHint);
        window.location.assign(authorizeEndpoint);
    }

    render() {
        return (
            <div className="login-body">
            </div>
        );
    }
}
