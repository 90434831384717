import gql from 'graphql-tag';


// queryName:saveFoldersInConversation, params: conversationId, conversationFolders
export const saveFoldersInConversation = gql`mutation saveFoldersInConversation($auth: AuthInput, $conversationId: String!, $folders:[String]!){
    saveFoldersInConversation(input: $auth, conversationId:$conversationId, selectedFolders:$folders)
    {
        id
        text
        status
    }
}`

export const bulkFoldersMutation = gql`
    mutation bulkFoldersMutation($auth: AuthInput, $conversationId:[String], $selectedFolders:[String], $action:String){
        bulkCoversationSafe(input: $auth,conversationId:$conversationId,selectedFolders:$selectedFolders, action:$action){
            status
        }
    }
`

export const getFilterRecordings = gql`
    query getFilterRecordings ($val : String!, $filter: RecordingFilterInputType, $withSave: Boolean, $auth:AuthInput, $path: String){
        getFilterRecordings(search:$val, filter:$filter, withSave:$withSave, input:$auth, path:$path)
        {
            userRules
            {
                defaultFilter {
                    id
                    type
                    cycle
                    startDate
                    endDate
                    categories
                    policies
                    persons
                }
                recordingFilter
                {
                    id
                    type
                    cycle
                    startDate
                    endDate
                    persons
                    defaultFilter
                    userFilter
                    categories
                    integrations
                    policies
                    folders
                }
            }
            statistics
            {
                count
            }
            days
            {
                date
                content
                {
                    day
                    timein
                    timeout
                    topic
                    id
                    conversationId
                    policies{
                        policyId
                        monitoredBy
                        riskRating
                    }
                    recordingType
                    duration
                    thumbnail
                    speech2Text
                    notes
                    isVideo
                    hasVideo
                    hasAudio
                    hasChat
                    hasFiles
                    hasScreenSharing
                    replayAllowed
                    archiveAllowed
                    archiveAccessType
                    isRestoreApproved
                    isApprover
                    linkedUser
                    {
                        id
                        userId
                        tenantId
                        name
                    }
                    callStartMs
                    ownerDisplayName
                    userId
                    archiveStatus
                    hasRestoreStarted
                    customFields
                    conversationSafe
                    conversationSafeFolders{
                        id
                        name
                    }
                    ttl
                }
            }
        }
    }
`

export const getAllFolders = gql`query ent($auth: AuthInput){
            getConversationSafeFolders(input: $auth){
                id
                name
                tenantId
                description
                visibilityDetails{
                    visibilityDetailsActive
                    visibilityDetailsNotes
                    visibilityDetailsTranscript
                    visibilityDetailsTTL
                    visibilityDetailsAnalytics
                    visibilityDetailsAnalyticsChange
                    visibilityDetailsCustomFields
                    visibilityDetailsMetadata
                    visibilityDetailsReplay
                    visibilityDetailsQM
                }
                userId
                ownerName
                addConversations
                removeConversations
                exportFolders
                supervisors{
                  name
                  mail
                  userId
                }
                attentionRequired
                allowedInPolicy
                conversations {
                    day
                    timein
                    timeout
                    topic
                    id
                    conversationId
                    recordingType
                    duration
                    thumbnail
                    notes
                    isVideo
                    hasVideo
                    hasAudio
                    hasChat
                    hasFiles
                    hasScreenSharing
                    replayAllowed
                    archiveAllowed
                    archiveAccessType
                    isRestoreApproved
                    isApprover
                    linkedUser
                       {
                        id
                        userId
                        tenantId
                        name
                        }
                    callStartMs
                    ownerDisplayName
                    userId
                    archiveStatus
                    hasRestoreStarted
                    customFields
                    conversationSafe
                    ttl
                }
            }
        }`

//query to retrieve list of folders flagged with
export const getFoldersAllowedForPolicies = gql`query getFoldersAllowedForPolicies($auth: AuthInput){
    getConversationSafeFolderLabelsPolicy(input: $auth){
        id
        name
    }
}`

// Variation to getAllFolders query to retrieve not all fields but only id and name. to populate dropdown input in ttl
// pivot.
export const getAllFoldersShrinked = gql`query getAllFoldersShrinked($auth: AuthInput){
            getConversationSafeFolders(input: $auth){
                id
                name     
                addConversations
                removeConversations
                userId
            }
        }`

export const getConversationSafeFolder = gql`query getConversationSafeFolder ($id: String, $auth: AuthInput){
    getConversationSafeFolder(id: $id, input: $auth){
        id
        name
        description
        addConversations
        removeConversations
        exportFolders
        supervisors {
            userId
        }
        visibilityDetails{
            visibilityDetailsActive
            visibilityDetailsNotes
            visibilityDetailsTranscript
            visibilityDetailsTTL
            visibilityDetailsAnalytics
            visibilityDetailsAnalyticsChange
            visibilityDetailsCustomFields
            visibilityDetailsMetadata
            visibilityDetailsReplay
            visibilityDetailsQM
        }
        attentionRequired
        conversations{
            id
            conversationId
        }
        ownerName
        userId
        tenantId
        allowedInPolicy
        allowedToEditFlagAllowedInPolicy
    }
}`


export const upsertConversationSafe = gql`mutation ent($auth: AuthInput, $conversationSafeFolder: ConversationSafeFolderInputType){
    createConversationSafeFolder(input: $auth, conversationSafeFolder:$conversationSafeFolder)
    {
        id
        text
        status
    }
}`

export const deleteConversationSafeFolder = gql`mutation deleteCSFolder($conversationSafeFolder: String){
    deleteConversationSafeFolder(id:$conversationSafeFolder)
    {
        status
    }
}`



