import React, { Component } from 'react';
import "./Keyword.scss"
import { ScrollablePane, mergeStyleSets } from 'office-ui-fabric-react/lib';
import { MyContext } from '../../../../../context';



export default class KeywordContainer extends Component {


    renderElements = () => {
        if (this.props.data.summarizedInsights != null && this.props.data.summarizedInsights.keywords != null) {
            return this.props.data.summarizedInsights.keywords.map(({ name, appearances, categorie }, i) => (
                <KeywordElement key={i} name={name} appearances={appearances} categorie={categorie} listener={this.props.listener} />
            ));
        }
        else {
            return <MyContext.Consumer>
                {(context) => (
                    <h2>{context.getTranslation("detailContainer", "keywordsMessage")}</h2>
                )
                }
            </MyContext.Consumer>
        }


    };

    render(): React.ReactNode {
        var classNames = mergeStyleSets({
            wrapper: {
                height: this.props.height,
                position: 'relative',
                minHeight: '100px'
            },
            pane: {
            }
        });
        return (window.innerWidth > 600 ?
            <div className={classNames.wrapper}>
            <ScrollablePane styles={{ root: classNames.pane }}>
                {this.renderElements()}
            </ScrollablePane>
            </div> :
            <div>
                {this.renderElements()}
            </div>);
    }
}

class KeywordElement extends Component {


    state =
        {
            keyword: this.props.name,
            appearances: this.props.appearances,
            referenceUrl: this.props.referenceUrl,
            categorie: this.props.categorie
        };
    helpRender = () => {
        // console.log(" Element Länge"+this.state.appearances.length);
        // console.log(" Element 0"+this.state.appearances[0].startTime);
        return this.state.appearances.map((
            { startTime, startSeconds }, i) => (<KeywordLink key={i} sec={startSeconds} time={startTime} listener={this.props.listener} />))
    };

    render(): React.ReactNode {
        return <><p title="Keyword"> {this.state.keyword}
            <span title="Category"> ({this.state.categorie})</span>
                    : {this.helpRender()}
        </p>
        </>;
    }
}

class KeywordLink extends Component {

    seek = () => {
        if (this.props.listener !== undefined && this.props.listener != null) {
            let seconds = this.convertToSeconds(this.props.time);
            this.props.listener(seconds);
        }
    };
    removeLastDigitsOfTime = (time)=>{
        var tmp = time.split(":");
        var result = "";
        if (tmp[2]) {
            var tmp1 = tmp[2].split(".");
            result = tmp[0] + ":" + tmp[1] + ":" + tmp1[0];
        } else {
            result = tmp[0] + ":" + tmp[1];

        }

        return result;
    }

    convertToSeconds = (time) => { 
        var tmp = time.split(":");
        var result = 0;
        if (tmp[2]) {
            var tmp1 = tmp[2].split(".");
            result = parseInt(tmp[0]) * 3600 + parseInt(tmp[1]) * 60 + parseInt(tmp1[0]);
        } else {
            result = parseInt(tmp[0]) * 60 + parseInt(tmp[1]);
        }
        return result;
    }

    render(): React.ReactNode {
        return <a className="link" onClick={() => (this.seek())}>{this.removeLastDigitsOfTime(this.props.time)}</a>;
    }
}