import React from 'react';
import { getCognitiveServicesLocalesTranslation } from '../../queries/querySettings';
import { Query } from "@apollo/react-components";
import { Spinner } from 'office-ui-fabric-react/lib';
import { MyContext } from '../../../src/context';
import { ComboBox } from "office-ui-fabric-react";

export const TranslateLanuages = ({ label, selectedKey, onChange, ...otherProps }) => (
    <MyContext.Consumer>
        {(context) => (
            <Query
                query={getCognitiveServicesLocalesTranslation}
                variables={{}}
            >
                {({ loading, error, data }) => {
                    if (loading) {
                        return (
                            <div style={{ float: "left", "margin-left": "50px" }}>
                                <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                            </div>
                        );
                    }
                    if (error) return <h4>Failed to connect</h4>;

                    if (data.getTranslatorLocales == null) {
                        return <div />;
                    }
                    let translateLanguageList = [];

                    if (data.getTranslatorLocales != null) {

                        for (var i = 0; i < data.getTranslatorLocales.length; i++) {
                            let translateLanguageData = JSON.parse(data.getTranslatorLocales[i]);

                            translateLanguageList.push({
                                key: translateLanguageData.key,
                                text: translateLanguageData.text
                            });
                        }
                    }

                    return <ComboBox
                        style={{ width: "300px" }}
                        label={label}
                        onChange={onChange}
                        options={translateLanguageList}
                        selectedKey={selectedKey}
                        {...otherProps}
                    />

                }}

            </Query>
        )}
    </MyContext.Consumer>

)




export const translateLangArr = [
        {
            key: "af",
            text: "Afrikaans",
        },
        {
            key: "ar",
            text: "Arabic",
        },
        {
            key: "apc",
            text: "Arabic, Levantine",
        },
        {
            key: "bn",
            text: "Bangla",
        },
        {
            key: "bs",
            text: "Bosnian (Latin)",
        },
        {
            key: "bg",
            text: "Bulgarian",
        },
        {
            key: "yue",
            text: "Cantonese (Traditional)",
        },
        {
            key: "ca",
            text: "Catalan",
        },
        {
            key: "zh-Hans",
            text: "Chinese Simplified",
        },
        {
            key: "zh-Hant",
            text: "Chinese Traditional",
        },
        {
            key: "hr",
            text: "Croatian",
        },
        {
            key: "cs",
            text: "Czech",
        },
        {
            key: "da",
            text: "Danish",
        },
        {
            key: "nl",
            text: "Dutch",
        },
        {
            key: "en",
            text: "English",
        },
        {
            key: "et",
            text: "Estonian",
        },
        {
            key: "fj",
            text: "Fijian",
        },
        {
            key: "fil",
            text: "Filipino",
        },
        {
            key: "fi",
            text: "Finnish",
        },
        {
            key: "fr",
            text: "French",
        },
        {
            key: "de",
            text: "German",
        },
        {
            key: "el",
            text: "Greek",
        },
        {
            key: "ht",
            text: "Haitian Creole",
        },
        {
            key: "he",
            text: "Hebrew",
        },
        {
            key: "hi",
            text: "Hindi",
        },
        {
            key: "mww",
            text: "Hmong Daw",
        },
        {
            key: "hu",
            text: "Hungarian",
        },
        {
            key: "is",
            text: "Icelandic",
        },
        {
            key: "id",
            text: "Indonesian",
        },
        {
            key: "it",
            text: "Italian",
        },
        {
            key: "ja",
            text: "Japanese",
        },
        {
            key: "sw",
            text: "Kiswahili",
        },
        {
            key: "tlh",
            text: "Klingon",
        },
        {
            key: "tlh-Qaak",
            text: "Klingon (plqaD)",
        },
        {
            key: "ko",
            text: "Korean",
        },
        {
            key: "lv",
            text: "Latvian",
        },
        {
            key: "lt",
            text: "Lithuanian",
        },
        {
            key: "mg",
            text: "Malagasy",
        },
        {
            key: "ms",
            text: "Malay",
        },
        {
            key: "mt",
            text: "Maltese",
        },
        {
            key: "nb",
            text: "Norwegian",
        },
        {
            key: "fa",
            text: "Persian",
        },
        {
            key: "pl",
            text: "Polish",
        },
        {
            key: "pt",
            text: "Portuguese",
        },
        {
            key: "otq",
            text: "Queretaro Otomi",
        },
        {
            key: "ro",
            text: "Romanian",
        },
        {
            key: "ru",
            text: "Russian",
        },
        {
            key: "sm",
            text: "Samoan",
        },
        {
            key: "sr-Cyrl",
            text: "Serbian (Cyrillic)",
        },
        {
            key: "sr-Latn",
            text: "Serbian (Latin)",
        },
        {
            key: "sk",
            text: "Slovak",
        },
        {
            key: "sl",
            text: "Slovenian",
        },
        {
            key: "es",
            text: "Spanish",
        },
        {
            key: "sv",
            text: "Swedish",
        },
        {
            key: "ty",
            text: "Tahitian",
        },
        {
            key: "ta",
            text: "Tamil",
        },
        {
            key: "te",
            text: "Telugu",
        },
        {
            key: "th",
            text: "Thai",
        },
        {
            key: "to",
            text: "Tongan",
        },
        {
            key: "tr",
            text: "Turkish",
        },
        {
            key: "uk",
            text: "Ukrainian",
        },
        {
            key: "ur",
            text: "Urdu",
        },
        {
            key: "vi",
            text: "Vietnamese",
        },
        {
            key: "cy",
            text: "Welsh",
        },
        {
            key: "yua",
            text: "Yucatec Maya",
        }
];
