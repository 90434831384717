import React from 'react';

import PieChart, {
    Legend,
    Series,
    Tooltip,
    Label,
    Connector,
} from 'devextreme-react/pie-chart';

import {Size} from "devextreme-react/vector-map";

class Doughnut extends React.Component {

    render() {
        return (
            <PieChart
                id="pie"
                type="doughnut"
                ref={this.props.reference}
                palette={this.props.palette}
                dataSource={this.props.data}
                centerRender={this.TooltipTemplate}


            >
                <Series argumentField="args" valueField="value">
                    <Label visible={true} >
                        <Connector visible={true} />
                    </Label>
                </Series>
                <Legend
                    margin={0}
                    horizontalAlignment="center"
                    verticalAlignment="bot"
                />
                <Tooltip enabled={true} customizeTooltip={this.customizeTooltip}>

                </Tooltip>


                <Size
                    height={this.props.size.height}
                    width={this.props.size.width}
                />
            </PieChart>
        );
    }

    customizeTooltip(arg) {
        return {
            text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`
        };
    }

    TooltipTemplate=(pieChart)=> {
        return (
            <svg x={"-100"} y={"-100"}>
                <circle  cx="100" cy="100" r={pieChart.getInnerRadius() - 6} fill="#eee"></circle>
                <text textAnchor="middle" x="100" y="100" style={{ fontSize: 18, fill:'#494949' }}>
                    <tspan x="100" dy="20px" style={{ fontWeight: 600 }}>{this.props.keyword}</tspan>
                </text>
            </svg>
        );
    }
}



export default Doughnut;