import {  HoverCard } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../context';

export default class AscHover extends Component {

  static contextType = MyContext

  constructor(props) {
    super(props);
  }


  plainCardProps = {
    onRenderPlainCard: () => {
      return (
        <MyContext.Consumer>
          {(context) => (
            <div className="padding-hover-default">
              {this.props.hoverContent}
            </div>
          )
          }
        </MyContext.Consumer>
      );
    }
  }


  render() {
    return (
      (<HoverCard expandingCardProps={this.expandingCardProps}
                  plainCardProps={this.plainCardProps}
                  instantOpenOnClick={true}
                  type="PlainCard">
        <div>
          {this.props.children}
        </div>
      </HoverCard>)

    )
  }

}