import gql from 'graphql-tag';

export const queryGetPolicyErrors = gql`query ent ($conversationId: String) {
                getPolicyErrors  (conversationId: $conversationId)
                {
                    conversationId
                    policyId
                    policyName
                    policyType
                    causes
                 }
         }`

export const queryGetPolicyResult = gql`query ent ($conversationId: String) {
                getPolicyResult  (conversationId: $conversationId)
                {
                        policy{
                            name
                            description
                            riskRating
                            version
                            selected
                            basedOn
                        }
                        policyRule{
                            name
                        }
                        policyResult{
                            name
                            founded
                            type
                            appearances{
                                startSeconds
                                startTime
                                endSeconds
                                endTime
                                words
                            }
                        }
                        errorMessage
                     
                 }
         }`

export const queryGetAIPolicyResult = gql`query ent ($conversationId: String, $aiPolicyIds: [String]) {
                getAIPolicyResult  (conversationId: $conversationId, aipolicyIds: $aiPolicyIds)
                {
                        policy{
                            name
                            description
                            riskRating
                            version
                            selected
                            basedOn
                        }
                        policyRule{
                            name
                        }
                        policyResult{
                            name
                            founded
                            type
                            appearances{
                                startSeconds
                                startTime
                                endSeconds
                                endTime
                                words
                            }
                        }
                        errorMessage
                     
                 }
         }`