import React, { Component } from 'react';
import { ApolloProvider, Query } from "react-apollo";
import { mutationCreateTenant, mutationUpdateTenant, mutationBulkImport, queryGetTenantList, deleteTenant, insertMessage, userGroupSync, changeEncryptionKey, checkCustomKeyVault, checkMaxRetentionTimeSettings, createCustomKeySecret, deleteTenantMetadataQuery, queryGetAWSRegions, checkS3StorageConnection, createAsymmetricKey } from "../../../queries/querySettings";
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { queryGetRuleData } from "../../../queries/querySettings";
import { DatePicker } from 'office-ui-fabric-react/lib/DatePicker';

import Utils from "./../../common/Utils";

import {
    DefaultButton,
    PrimaryButton,
    Stack,
    TextField,
    ComboBox,
    Checkbox,
    Spinner,
    Panel,
    PanelType,
    Label,
    Text,
    SpinButton,
    ChoiceGroup,
    Modal,
    IconButton,
    TeachingBubble
} from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { MyContext } from '../../../context';
import ExportConfig from './ExportConfig';
import ImportConfig from './ImportConfig';
import AudioStorageConfig from './AudioStorageConfig';
import BotOnboardingConfig from './BotOnboardingConfig';
import "../fabric-icons-inline.css";
import { Button } from '@fluentui/react';
import { text } from 'react-dom-factories';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';


const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 250 }
};
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        {
            flex: '1 1 auto',
            borderTop: '4px solid blue',
            // color: theme.palette.neutralPrimary,
            display: 'flex',
            // fontSize: FontSizes.xLarge,
            alignItems: 'center',
            // fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0',
            },
            'p:first-child': {
                marginTop: 0,
            },
            'p:last-child': {
                marginBottom: 0,
            },
        },
    },
});

const iconButtonStyles = mergeStyleSets({
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    }
});

export default class AddTenant extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.openExportSettings = this.openExportSettings.bind(this);
        this._onDismissExportPanel = this._onDismissExportPanel.bind(this);
        this._onOpenExportPanel = this._onOpenExportPanel.bind(this);

        this.openImportSettings = this.openImportSettings.bind(this);
        this._onDismissImportPanel = this._onDismissImportPanel.bind(this);
        this._onOpenImportPanel = this._onOpenImportPanel.bind(this);

        this.openAudioStorageSettings = this.openAudioStorageSettings.bind(this);
        this._onDismissAudioStoragePanel = this._onDismissAudioStoragePanel.bind(this);
        this._onOpenAudioStoragePanel = this._onOpenAudioStoragePanel.bind(this);
        this.openBotOnboardingSettings = this.openBotOnboardingSettings.bind(this);
        this._onDismissBotOnboardingPanel = this._onDismissBotOnboardingPanel.bind(this);
        this._onOpenBotOnboardingPanel = this._onOpenBotOnboardingPanel.bind(this);

        const text = <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "keyManagement")}</>)}</MyContext.Consumer>;
        if (this.props.data != null && this.props.data !== undefined) {
            this.state = {
                name: this.props.data.name,
                adminId: this.props.data.adminId,
                tenantId: this.props.data.tenantId,
                id: this.props.data.id,
                license1: this.props.data.licenseComp != null ? this.props.data.licenseComp.bought : 0,
                license1used: this.props.data.licenseComp != null ? this.props.data.licenseComp.used : 0,
                license1id: this.props.data.licenseComp != null ? this.props.data.licenseComp.id : Utils.guidGenerator(),
                license2: this.props.data.licenseCompAna != null ? this.props.data.licenseCompAna.bought : 0,
                license2used: this.props.data.licenseCompAna != null ? this.props.data.licenseCompAna.used : 0,
                license2id: this.props.data.licenseCompAna != null ? this.props.data.licenseCompAna.id : Utils.guidGenerator(),
                license3: this.props.data.licenseSmart != null ? this.props.data.licenseSmart.bought : 0,
                license3used: this.props.data.licenseSmart != null ? this.props.data.licenseSmart.used : 0,
                license3id: this.props.data.licenseSmart != null ? this.props.data.licenseSmart.id : Utils.guidGenerator(),
                license4: this.props.data.licenseSmartAna != null ? this.props.data.licenseSmartAna.bought : 0,
                license4used: this.props.data.licenseSmartAna != null ? this.props.data.licenseSmartAna.used : 0,
                license4id: this.props.data.licenseSmartAna != null ? this.props.data.licenseSmartAna.id : Utils.guidGenerator(),
                license5: this.props.data.licenseAna != null ? this.props.data.licenseAna.bought : 0,
                license5used: this.props.data.licenseAna != null ? this.props.data.licenseAna.used : 0,
                license5id: this.props.data.licenseAna != null ? this.props.data.licenseAna.id : Utils.guidGenerator(),
                license6: this.props.data.licenseCompVoice != null ? this.props.data.licenseCompVoice.bought : 0,
                license6used: this.props.data.licenseCompVoice != null ? this.props.data.licenseCompVoice.used : 0,
                license6id: this.props.data.licenseCompVoice != null ? this.props.data.licenseCompVoice.id : Utils.guidGenerator(),
                license7: this.props.data.licenseCompVoiceAna != null ? this.props.data.licenseCompVoiceAna.bought : 0,
                license7used: this.props.data.licenseCompVoiceAna != null ? this.props.data.licenseCompVoiceAna.used : 0,
                license7id: this.props.data.licenseCompVoiceAna != null ? this.props.data.licenseCompVoiceAna.id : Utils.guidGenerator(),
                license8: this.props.data.licenseSmartVoice != null ? this.props.data.licenseSmartVoice.bought : 0,
                license8used: this.props.data.licenseSmartVoice != null ? this.props.data.licenseSmartVoice.used : 0,
                license8id: this.props.data.licenseSmartVoice != null ? this.props.data.licenseSmartVoice.id : Utils.guidGenerator(),
                license9: this.props.data.licenseSmartVoiceAna != null ? this.props.data.licenseSmartVoiceAna.bought : 0,
                license9used: this.props.data.licenseSmartVoiceAna != null ? this.props.data.licenseSmartVoiceAna.used : 0,
                license9id: this.props.data.licenseSmartVoiceAna != null ? this.props.data.licenseSmartVoiceAna.id : Utils.guidGenerator(),
                license10: this.props.data.licenseRecordingInsinghtsAI != null ? this.props.data.licenseRecordingInsinghtsAI.bought : 0,
                license10used: this.props.data.licenseRecordingInsinghtsAI != null ? this.props.data.licenseRecordingInsinghtsAI.used : 0,
                license10id: this.props.data.licenseRecordingInsinghtsAI != null ? this.props.data.licenseRecordingInsinghtsAI.id : Utils.guidGenerator(),
                storage: this.props.data.storage,
                customStorage: this.props.data.customStorage,
                packageStorage: this.props.data.packageStorage != null ? this.props.data.packageStorage : 0,
                packageAnalyticsSpeech: this.props.data.packageAnalyticsSpeech != null ? this.props.data.packageAnalyticsSpeech : 0,
                packageAnalyticsTranscript: this.props.data.packageAnalyticsTranscript != null ? this.props.data.packageAnalyticsTranscript : 0,
                packageAnalyticsVideo: this.props.data.packageAnalyticsVideo != null ? this.props.data.packageAnalyticsVideo : 0,
                packageAnalyticsTranslate: this.props.data.packageAnalyticsTranslate != null ? this.props.data.packageAnalyticsTranslate : 0,
                packageAnalyticsAI: this.props.data.packageAnalyticsAI != null ? this.props.data.packageAnalyticsAI : 0,
                packageExport: this.props.data.packageExport != null ? this.props.data.packageExport : 0,
                addOnAnalytics: this.props.data.addOnAnalytics != null ? this.props.data.addOnAnalytics : 0,
                addOnFullChat: this.props.data.addOnFullChat != null ? this.props.data.addOnFullChat : 0,
                addOnZoom: this.props.data.addOnZoom != null ? this.props.data.addOnZoom : 0,
                addOnGenesys: this.props.data.addOnGenesys != null ? this.props.data.addOnGenesys : 0,
                addOnRingcentral: this.props.data.addOnRingcentral != null ? this.props.data.addOnRingcentral : 0,
                addOnDynamics: this.props.data.addOnDynamics != null ? this.props.data.addOnDynamics : 0,
                addOnCompliance: this.props.data.addOnCompliance != null ? this.props.data.addOnCompliance : 0,
                addOnQualityManagement: this.props.data.addOnQualityManagement != null ? this.props.data.addOnQualityManagement : 0,
                addOnLeapXpert: this.props.data.addOnLeapXpert != null ? this.props.data.addOnLeapXpert : 0,
                mailAlarming: this.props.data.mailAlarming != null ? this.props.data.mailAlarming : "",
                contractNumber: this.props.data.contractNumber != null ? this.props.data.contractNumber : "",
                exportType: this.props.data.exportType,
                exportStorage: this.props.data.exportStorage,
                type: this.props.data.type,
                keyManagementType: this.props.data.keyManagementType != null ? this.props.data.keyManagementType : "No",
                keySecurity: this.props.data.keyManagement != null ? this.props.data.keyManagement.keySecurity : "Aes128",
                rotationType: this.props.data.keyManagement != null ? this.props.data.keyManagement.rotationType : "Interval",
                intervalDays: this.props.data.keyManagement != null ? this.props.data.keyManagement.intervalDays : 365,
                activationDelay: this.props.data.keyManagement != null ? this.props.data.keyManagement.activationDelay : 0,
                willExpire: this.props.data.keyManagement != null ? (this.props.data.keyManagement.willExpire == "Yes" ? true : false) : false,
                expireDays: this.props.data.keyManagement != null ? this.props.data.keyManagement.expireDays : 0,
                customEncryptionKeyVaultUrl: this.props.data.keyManagement != null ? this.props.data.keyManagement.customEncryptionKeyVaultUrl : "",
                customEncryptionKeySecretName: this.props.data.keyManagement != null ? this.props.data.keyManagement.customEncryptionKeySecretName : "",
                manualCreationCustom: false,
                manualCreationKeyRotation: false,
                isChanged: false,
                bulkImport: false,
                bulkLicense: "0",
                bulkRuleRecording: "",
                bulkRuleReplay: "",
                bulkRuleAccess: "",
                bulkRuleAnalytics: "",
                bulkUserList: "",
                bulkImportText: "",
                deleteTenantText: "",
                deleteTenant: "",
                deleteTenantMetadataText: "",
                deleteTenantMetadata: "",
                deleteTenantMetadataDate: new Date('2019-01-01'),
                deletePassword: "",
                insertMessage: "",
                insertMessageContent: "",
                insertMessageText: "",
                country: this.props.data.country != null ? this.props.data.country : "",
                partner: this.props.data.partner != null ? this.props.data.partner : "",
                onboardingDate: (this.props.data.onboardingDate !== '' && this.props.data.onboardingDate !== '0001-01-01T00:00:00.0000000') ? new Date(this.props.data.onboardingDate) : '',
                billingDate: (this.props.data.billingDate !== '' && this.props.data.billingDate !== '0001-01-01T00:00:00.0000000') ? new Date(this.props.data.billingDate) : '',
                trainingDate: (this.props.data.trainingDate !== '' && this.props.data.trainingDate !== '0001-01-01T00:00:00.0000000') ? new Date(this.props.data.trainingDate) : '',
                supportNotes: this.props.data.supportNotes != null ? this.props.data.supportNotes : "",
                systemId: this.props.data.systemId != null ? this.props.data.systemId : "",
                bot01: this.props.data.bot01 != null ? this.props.data.bot01 : 0,
                bot02: this.props.data.bot02 != null ? this.props.data.bot02 : 0,
                bot03: this.props.data.bot03 != null ? this.props.data.bot03 : 0,
                bot04: this.props.data.bot04 != null ? this.props.data.bot04 : 0,
                bot05: this.props.data.bot05 != null ? this.props.data.bot05 : 0,
                bot06: this.props.data.bot06 != null ? this.props.data.bot06 : 0,
                bot07: this.props.data.bot07 != null ? this.props.data.bot07 : 0,
                bot08: this.props.data.bot08 != null ? this.props.data.bot08 : 0,
                bot09: this.props.data.bot09 != null ? this.props.data.bot09 : 0,
                bot10: this.props.data.bot10 != null ? this.props.data.bot10 : 0,
                bot11: this.props.data.bot11 != null ? this.props.data.bot11 : 0,
                bot12: this.props.data.bot12 != null ? this.props.data.bot12 : 0,
                bot13: this.props.data.bot13 != null ? this.props.data.bot13 : 0,
                bot14: this.props.data.bot14 != null ? this.props.data.bot14 : 0,
                bot15: this.props.data.bot15 != null ? this.props.data.bot15 : 0,
                bot16: this.props.data.bot16 != null ? this.props.data.bot16 : 0,
                bot17: this.props.data.bot17 != null ? this.props.data.bot17 : 0,
                bot18: this.props.data.bot18 != null ? this.props.data.bot18 : 0,
                bot19: this.props.data.bot19 != null ? this.props.data.bot19 : 0,
                bot20: this.props.data.bot20 != null ? this.props.data.bot20 : 0,
                support: this.props.data.support != null ? this.props.data.support : "",
                partnerContract: this.props.data.partnerContract != null ? this.props.data.partnerContract : "",
                botVisible: false,
                showExportPanel: false,
                showImportPanel: false,
                showAudioStoragePanel: false,
                showBotOnboardingPanel: false,
                keyManagementEnabled: this.props.data.keyManagementEnabled != null ? this.props.data.keyManagementEnabled : false,
                chatModelA: this.props.data.chatModelA != null ? this.props.data.chatModelA : false,
                validateKeyVault: "",
                domainRestrictionsList: this.props.data.domainRestrictionsList,
                isChecked: false,
                showModal: false,
                packageImport: this.props.data.packageImport != null ? this.props.data.packageImport : 0,
                packageArchive: this.props.data.packageArchive != null ? this.props.data.packageArchive : 0,
                tenantSuspensionConvDelProcess: this.props.data.tenantSuspensionConvDelProcess !== null ? this.props.data.tenantSuspensionConvDelProcess : false,
                deletionNotifyMessage: false,
                keyExpirationMessage: false,
                keyExpirationButtonMessage: false,
                maxTtlFound: "0",
                keyExpirationConfirmed: true,
                awsAccessKeyId: this.props.data !== null ? this.props.data.awsAccessKeyId : "",
                awsSecretAccess: this.props.data !== null ? this.props.data.awsSecretAccess : "",
                awsBucketName: this.props.data !== null ? this.props.data.awsBucketName : "",
                awsRegion: this.props.data !== null ? this.props.data.awsRegion : "",
                s3Check: "",
                awsType: this.props.data !== null ? this.props.data.awsType : "",
                awsServiceUrl: this.props.data !== null ? this.props.data.awsServiceUrl : "",
                asymmetricKeyEnabled: this.props.data.keyManagement != null ? this.props.data.keyManagement.asymmetricKeyEnabled : false,
                keyType: this.props.data.keyManagement.asymmetricKeySettings != null ? this.props.data.keyManagement.asymmetricKeySettings.keyType : "RSA",
                keySetting: this.props.data.keyManagement.asymmetricKeySettings != null ? this.props.data.keyManagement.asymmetricKeySettings.keySetting : "",
                asymmetricKeyFileName: this.props.data.keyManagement.asymmetricKeySettings != null ? this.props.data.keyManagement.asymmetricKeySettings.asymmetricKeyFileName : "",
                uploadedContent: "",
                selectedFile: this.props.data.keyManagement.asymmetricKeySettings != null && this.props.data.keyManagement.asymmetricKeySettings != "" ? true : false,
                asymmetricKeySuccess: false,
                isActivateAsymmetricKeyDisabled: false,
                asymmetricKeyButtonText: "createAsymmetricKey"
            }
        }
        else {
            this.state = {
                name: "",
                adminId: "",
                tenantId: "",
                id: null,
                license1: 0,
                license1used: 0,
                license1id: Utils.guidGenerator(),
                license2: 0,
                license2used: 0,
                license2id: Utils.guidGenerator(),
                license3used: 0,
                license3: 0,
                license3id: Utils.guidGenerator(),
                license4: 0,
                license4used: 0,
                license4id: Utils.guidGenerator(),
                license5: 0,
                license5used: 0,
                license5id: Utils.guidGenerator(),
                license6: 0,
                license6used: 0,
                license6id: Utils.guidGenerator(),
                license7: 0,
                license7used: 0,
                license7id: Utils.guidGenerator(),
                license8: 0,
                license8used: 0,
                license8id: Utils.guidGenerator(),
                license9: 0,
                license9used: 0,
                license9id: Utils.guidGenerator(),
                license10: 0,
                license10used: 0,
                license10id: Utils.guidGenerator(),
                storage: "Unknown",
                customStorage: "",
                packageStorage: 0,
                packageAnalyticsSpeech: 0,
                packageAnalyticsTranscript: 0,
                packageAnalyticsVideo: 0,
                packageAnalyticsTranslate: 0,
                packageAnalyticsAI: 0,
                packageExport: 0,
                addOnFullChat: 0,
                addOnZoom: 0,
                addOnCicso: 0,
                addOnRingcentral: 0,
                addOnDynamics: 0,
                addOnCompliance: 0,
                addOnQualityManagement: 0,
                addOnAnalytics: 0,
                addOnLeapXpert: 0,
                contractNumber: "",
                mailAlarming: "",
                exportType: "No",
                exportStorage: "",
                type: "",
                keyManagementType: "No",
                keySecurity: "Aes128",
                rotationType: "Interval",
                intervalDays: 365,
                activationDelay: 0,
                willExpire: false,
                expireDays: 0,
                customEncryptionKeyVaultUrl: "",
                customEncryptionKeySecretName: "",
                manualCreationCustom: false,
                manualCreationKeyRotation: false,
                isChanged: false,
                bulkImport: false,
                bulkLicense: "0",
                bulkRuleRecording: "",
                bulkRuleReplay: "",
                bulkRuleAccess: "",
                bulkRuleAnalytics: "",
                bulkUserList: "",
                bulkImportText: "",
                deleteTenantText: "",
                deleteTenant: "",
                deleteTenantMetadataText: "",
                deleteTenantMetadata: "",
                deleteTenantMetadataDate: new Date('0001-01-01'),
                deletePassword: "",
                insertMessage: "",
                insertMessageContent: "",
                insertMessageText: "",
                country: "",
                partner: "",
                onboardingDate: new Date('1901-01-01'),
                billingDate: new Date('1901-01-01'),
                trainingDate: new Date('1901-01-01'),
                supportNotes: "",
                systemId: "",
                bot01: 0,
                bot02: 0,
                bot03: 0,
                bot04: 0,
                bot05: 0,
                bot06: 0,
                bot07: 0,
                bot08: 0,
                bot09: 0,
                bot10: 0,
                bot11: 0,
                bot12: 0,
                bot13: 0,
                bot14: 0,
                bot15: 0,
                bot16: 0,
                bot17: 0,
                bot18: 0,
                bot19: 0,
                bot20: 0,
                support: "",
                partnerContract: "",
                showExportPanel: false,
                showImportPanel: false,
                showAudioStoragePanel: false,
                botVisible: false,
                keyManagementEnabled: true,
                chatModelA: false,
                validateKeyVault: "",
                domainRestrictionsList: "",
                isChecked: false,
                showModal: false,
                packageImport: 0,
                tenantSuspensionConvDelProcess: false,
                deletionNotifyMessage: false,
                packageArchive: 0,
                maxTtlFound: 0,
                keyExpirationConfirmed: true,
                awsAccessKeyId: "",
                awsSecretAccess: "",
                awsBucketName: "",
                awsRegion: "",
                s3Check: "",
                awsServiceUrl: "",
                awsType: "",
                asymmetricKeyEnabled: false,
                keyType: "",
                keySetting: "",
                asymmetricKeyFileName: "",
                uploadedContent: "",
                selectedFile: false,
                asymmetricKeySuccess: false,
                asymmetricKeyButtonText: "createAsymmetricKey"

            }
        }
    }

    botNames = [
        {
            key: "01",
            text: "australiaeast-asc-wab-p00"
        },
        {
            key: "02",
            text: "eastasia-asc-wab-p00",
        },
        {
            key: "03",
            text: "ueanorth-asc-wab-p00",
        },
        {
            key: "04",
            text: "eastus2-rg-asc-wab-p00",
        },
        {
            key: "05",
            text: "northeurope-asc-wab-p00"
        },
        {
            key: "06",
            text: "northeurope-asc-wab-p01"
        },
        {
            key: "07",
            text: "westeurope-asc-wab-p01"
        },
        {
            key: "08",
            text: "eastasia-asc-wab-p01"
        },
        {
            key: "09",
            text: "southeastasia-asc-wab-p01"
        },
        {
            key: "10",
            text: "eastasia-asc-wab-p02"
        },
        {
            key: "11",
            text: "southeastasia-asc-wab-p02	"
        },
        {
            key: "12",
            text: "eastus2-asc-wab-p01"
        },
        {
            key: "13",
            text: "westus2-asc-wab-p01"
        },
        {
            key: "14",
            text: "southbrazil-asc-wab-p01a"
        },
        {
            key: "15",
            text: "southbrazil-asc-wab-p01b"
        },
        {
            key: "16",
            text: "northeurope-asc-wab-p02"
        },
        {
            key: "17",
            text: "westeurope-asc-wab-p02"
        },
        {
            key: "18",
            text: "bot18"
        },
        {
            key: "19",
            text: "bot19"
        },
        {
            key: "20",
            text: "bot20"
        }
    ];

    styles = {
        root: {
            paddingTop: "20px"
        }
    };
    toastyy = {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    };
    toastyy2 = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    };
    checkS3StorageConnection = async () => {
        this.setState({ s3Check: "pending" });
        document.getElementById("checkS3Btn").style.border = "";

        var data = await this.props.apol
            .mutate({
                mutation: checkS3StorageConnection,
                variables: {
                    id: this.state.awsAccessKeyId,
                    secret: this.state.awsSecretAccess,
                    region: this.state.awsRegion,
                    bucket: this.state.awsBucketName,
                    awsServiceUrl: this.state.awsServiceUrl,
                    awsType: this.state.awsType
                }
            });
        if (data.data.checkS3StorageConnection) {
            document.getElementById("checkS3Btn").style.border = "2px solid green";
            this.setState({ s3Check: "true" });

        } else {
            document.getElementById("checkS3Btn").style.border = "2px solid red";
            this.setState({ s3Check: "false" });
        }
    }

    saveDisableCheck = () => {
        let checkForS3 = true;
        let checkForAsymmetricKey = true;

        //S3 validation
        let check = this.state.s3Check;
        if (check == "false" || check == "pending") {
            checkForS3 = false;
        }
        if ((check == "" || check == "true") && this.state.storage == "S3Storage") {
            checkForS3 = true;
        }

        //asymmetric key uploaded and created validation
        if (this.state.asymmetricKeyEnabled) {
            if (!this.state.asymmetricKeySuccess) {
                checkForAsymmetricKey = false;
            }
        }
        if (this.state.isChanged && this.state.keyExpirationConfirmed && checkForS3 && checkForAsymmetricKey) {
            return false; //save button is enabled
        }
        return true; //save button is disabled

    }
    disbaledCheckValidity = () => {
        if (this.state.s3Check == "pending") {
            return true;
        }
        if (this.state.awsAccessKeyId !== "" && this.state.awsAccessKeyId !== null && this.state.awsSecretAccess !== "" && this.state.awsSecretAccess !== null && this.state.awsBucketName !== "" && this.state.awsBucketName !== null) {
            if (this.state.awsType === "Cloud" && this.state.awsRegion !== "" && this.state.awsRegion !== null) {
                return false
            } else if (this.state.awsType === "OnPremise" && this.state.awsServiceUrl !== "" && this.state.awsServiceUrl !== null) {
                return false
            }
        }
        return true;
    }


    onFileUploadAsymmetricKey = (event, state) => {

        let activeFile = event.target.files[0];

        if (activeFile) {

            this.setState({ selectedFile: true });

        }

        if (event.target.files[0]) {
            this.loadFile(event.target.files[0], state);
            this.setState({ asymmetricKeyFileName: event.target.files[0].name });
        }

        this.setState({ asymmetricKeyButtonText: "createAsymmetricKey" })
    };

    loadFile = async (file, state) => {
        let text = await file.text();
        this.setState({ [state]: text });
    }
    render() {
        let auth = this.props.authorization;
        return (<Stack vertical>
            <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "name")}</>)}</MyContext.Consumer>}
                onChange={(event) => { this.handleChange(event.target.value, 0) }}
                value={this.state.name}
            />
            <Stack.Item styles={this.styles}>
                <Stack>
                    <Stack.Item>
                        {this.props.data == null && <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "tenantId")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 1) }}
                            value={this.state.tenantId}
                            onKeyDown={this._handleKeyDown}

                        />}
                        {this.props.data != null && this.props.data.tenantId !== "" && <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "tenantId")}</>)}</MyContext.Consumer>}
                            value={this.state.tenantId}
                            onKeyDown={this._handleKeyDown}
                            disabled
                        />}
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "adminId")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 2) }}
                            value={this.state.adminId}
                            onKeyDown={this._handleKeyDown}
                        />
                    </Stack.Item>
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.license5} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "chat")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 12) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.license8} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartVoice")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 60) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.license9} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartVoiceAna")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 61) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.license3} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartRec")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 10) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.license4} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartRecAna")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 11) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.license1} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceRec")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 3) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} int value={this.state.license2} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceRecAna")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 4) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.license6} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceVoiceRec")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 57) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.license7} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceVoiceRecAna")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 58) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.license10} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "licenseRecordingInsinghtsAI")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 84) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.addOnAnalytics} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnAnalytics")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 62) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.addOnFullChat} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnFullChat")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 63) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.addOnZoom} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnZoom")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 68) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.addOnGenesys} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnGenesys")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 69) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.addOnRingcentral} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnRingcentral")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 70) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.addOnDynamics} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnDynamics")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 71) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.addOnLeapXpert} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnLeapXpert")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 83) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.addOnCompliance} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnCompliance")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.setState({ addOnCompliance: event.target.value, isChanged: true}) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.addOnQualityManagement} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnQualityManagement")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.setState({ addOnQualityManagement: event.target.value, isChanged: true }) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.packageStorage} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageStorage")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 14) }}
                        />
                    </Stack.Item>}
                    {<Stack.Item styles={this.styles}>
                        <TextField min={0} disabled={!this.props.userRules.ruleAccess.tenantCreate} value={this.state.packageArchive} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageArchive")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 74) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.packageAnalyticsVideo} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaVideo")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 15) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.packageAnalyticsSpeech} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaSpeech")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 18) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.packageAnalyticsTranscript} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaTranscript")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 59) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.packageAnalyticsTranslate} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaTranslate")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 19) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.packageAnalyticsAI} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaAI")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 76) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.packageExport} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageExport")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 16) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField min={0} value={this.state.packageImport} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageImport")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 73) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField value={this.state.contractNumber} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "contractNo")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 20) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField value={this.state.country} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "country")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 22) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && this.props.userRules.access != "Partner" && <Stack.Item styles={this.styles}>
                        <TextField value={this.state.partner} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "partner")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 25) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField value={this.state.partnerContract} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "partnerContract")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 26) }}
                        />
                    </Stack.Item>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack.Item styles={this.styles}>
                        <TextField value={this.state.support} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "support")}</>)}</MyContext.Consumer>}
                            onChange={(event) => { this.handleChange(event.target.value, 27) }}
                        />
                    </Stack.Item>}
                    {true && <Stack>
                        <Stack.Item styles={this.styles}>
                            <Checkbox
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "botUsage")}</>)}</MyContext.Consumer>}
                                value={this.state.botVisible}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        botVisible: newValue
                                    });
                                }}
                            />
                        </Stack.Item>
                    </Stack>}
                    {this.props.userRules.ruleAccess.tenantCreate && this.props.userRules.access != "Partner" && this.state.botVisible && <Stack>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot01} type="Number" label={this.botNames[0].text}
                                onChange={(event) => { this.handleChange(event.target.value, 28) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot02} type="Number" label={this.botNames[1].text}
                                onChange={(event) => { this.handleChange(event.target.value, 29) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot03} type="Number" label={this.botNames[2].text}
                                onChange={(event) => { this.handleChange(event.target.value, 30) }}
                            />                            </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot04} type="Number" label={this.botNames[3].text}
                                onChange={(event) => { this.handleChange(event.target.value, 31) }}
                            />                           </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot05} type="Number" label={this.botNames[4].text}
                                onChange={(event) => { this.handleChange(event.target.value, 32) }}
                            />                           </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot06} type="Number" label={this.botNames[5].text}
                                onChange={(event) => { this.handleChange(event.target.value, 33) }}
                            />                           </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot07} type="Number" label={this.botNames[6].text}
                                onChange={(event) => { this.handleChange(event.target.value, 34) }}
                            />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot08} type="Number" label={this.botNames[7].text}
                                onChange={(event) => { this.handleChange(event.target.value, 35) }}
                            />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot09} type="Number" label={this.botNames[8].text}
                                onChange={(event) => { this.handleChange(event.target.value, 36) }}
                            />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot10} type="Number" label={this.botNames[9].text}
                                onChange={(event) => { this.handleChange(event.target.value, 37) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot11} type="Number" label={this.botNames[10].text}
                                onChange={(event) => { this.handleChange(event.target.value, 38) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot12} type="Number" label={this.botNames[11].text}
                                onChange={(event) => { this.handleChange(event.target.value, 39) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot13} type="Number" label={this.botNames[12].text}
                                onChange={(event) => { this.handleChange(event.target.value, 40) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot14} type="Number" label={this.botNames[13].text}
                                onChange={(event) => { this.handleChange(event.target.value, 41) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot15} type="Number" label={this.botNames[14].text}
                                onChange={(event) => { this.handleChange(event.target.value, 42) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot16} type="Number" label={this.botNames[15].text}
                                onChange={(event) => { this.handleChange(event.target.value, 43) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot17} type="Number" label={this.botNames[16].text}
                                onChange={(event) => { this.handleChange(event.target.value, 44) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot18} type="Number" label={this.botNames[17].text}
                                onChange={(event) => { this.handleChange(event.target.value, 45) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot19} type="Number" label={this.botNames[18].text}
                                onChange={(event) => { this.handleChange(event.target.value, 46) }}
                            />                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot20} type="Number" label={this.botNames[19].text}
                                onChange={(event) => { this.handleChange(event.target.value, 47) }}
                            />
                        </Stack.Item>
                    </Stack>}
                    {this.props.userRules.ruleAccess.tenantChange && this.state.botVisible && <Stack>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot01} type="Number" label={this.botNames[0].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot02} type="Number" label={this.botNames[1].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot03} type="Number" label={this.botNames[2].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot04} type="Number" label={this.botNames[3].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot05} type="Number" label={this.botNames[4].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot06} type="Number" label={this.botNames[5].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot07} type="Number" label={this.botNames[6].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot08} type="Number" label={this.botNames[7].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot09} type="Number" label={this.botNames[8].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot10} type="Number" label={this.botNames[9].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot11} type="Number" label={this.botNames[10].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot12} type="Number" label={this.botNames[11].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot13} type="Number" label={this.botNames[12].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot14} type="Number" label={this.botNames[13].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot15} type="Number" label={this.botNames[14].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot16} type="Number" label={this.botNames[15].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot17} type="Number" label={this.botNames[16].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot18} type="Number" label={this.botNames[17].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot19} type="Number" label={this.botNames[18].text} disabled />
                        </Stack.Item>
                        <Stack.Item styles={this.styles}>
                            <TextField min={0} value={this.state.bot20} type="Number" label={this.botNames[19].text} disabled />
                        </Stack.Item>
                    </Stack>}
                    {(this.props.userRules.ruleAccess.tenantCreate
                        || this.props.userRules.access == "Partner"
                        || this.props.userRules.access == "Order"
                        || this.props.userRules.access == "Support")
                        && <Stack.Item styles={this.styles}>
                            <Stack.Item>
                                <div>Onboarding Date</div>
                                <DatePicker
                                    onSelectDate={(date) => {
                                        this.handleChange(date, 23)
                                    }}
                                    value={this.state.onboardingDate}
                                />
                                <div>Training Date</div>
                                <DatePicker
                                    onSelectDate={(date) => {
                                        this.handleChange(date, 65)
                                    }}
                                    value={this.state.trainingDate}
                                />
                                <div>BillingDate</div>
                                <DatePicker
                                    onSelectDate={(date) => {
                                        this.handleChange(date, 24)
                                    }}
                                    value={this.state.billingDate}
                                />
                            </Stack.Item>
                            <Stack.Item styles={this.styles}>
                                <TextField value={this.state.supportNotes} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "supportNotes")}</>)}</MyContext.Consumer>}
                                    onChange={(event) => { this.handleChange(event.target.value, 66) }}
                                />
                            </Stack.Item>
                            <Stack.Item styles={this.styles}>
                                <TextField value={this.state.systemId} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "systemId")}</>)}</MyContext.Consumer>}
                                    onChange={(event) => { this.handleChange(event.target.value, 67) }}
                                />
                            </Stack.Item>
                        </Stack.Item>}

                    {!this.props.userRules.ruleAccess.tenantCreate && <Stack>
                        {this.state.license5 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license5} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "chat")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.license8 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license8} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartVoice")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.license9 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license9} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartVoiceAna")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.license3 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license3} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartRec")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.license4 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license4} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartRecAna")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.license1 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license1} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceRec")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.license2 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license2} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceRecAna")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.license6 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license6} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceVoiceRec")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.license7 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license7} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceVoiceRecAna")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.license10 > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.license10} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "licenseRecordingInsinghtsAI")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.addOnAnalytics > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.addOnAnalytics} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnAnalytics")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.addOnFullChat > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.addOnFullChat} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnFullChat")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.addOnZoom > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.addOnZoom} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnZoom")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.addOnGenesys > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.addOnGenesys} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnGenesys")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.addOnRingcentral > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.addOnRingcentral} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnRingcentral")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.addOnDynamics > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.addOnDynamics} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnDynamics")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.addOnCompliance > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.addOnCompliance} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnCompliance")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.addOnQualityManagement > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.addOnQualityManagement} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnQualityManagement")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.addOnLeapXpert > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.addOnLeapXpert} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnLeapXpert")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.packageStorage > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.packageStorage} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageStorage")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.packageArchive > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.packageArchive} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageArchive")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.packageAnalyticsVideo > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.packageAnalyticsVideo} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaVideo")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.packageAnalyticsSpeech > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.packageAnalyticsSpeech} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaSpeech")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.packageAnalyticsTranscript > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.packageAnalyticsTranscript} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaTranscript")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.packageAnalyticsTranslate > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.packageAnalyticsTranslate} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaTranslate")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.packageAnalyticsAI > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.packageAnalyticsAI} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageAnaAI")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.packageExport > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.packageExport} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageExport")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {this.state.packageImport > 0 ?
                            <Stack.Item styles={this.styles}>
                                <TextField min={0} value={this.state.packageImport} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "packageImport")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>
                            : <div></div>
                        }
                        {(!this.props.userRules.ruleAccess.tenantCreate && this.props.userRules.access != "Order"
                            && this.props.userRules.access != "Partner"
                            && this.props.userRules.access != "Support") &&
                            < Stack.Item styles={this.styles}>
                                <TextField value={this.state.contractNumber} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "contractNo")}</>)}</MyContext.Consumer>} disabled />
                            </Stack.Item>}
                        {(this.props.userRules.ruleAccess.tenantCreate || this.props.userRules.access == "Order"
                            || this.props.userRules.access == "Partner"
                            || this.props.userRules.access == "Support")
                            && <Stack>
                                <Stack.Item styles={this.styles}>
                                    <TextField value={this.state.contractNumber} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "contractNo")}</>)}</MyContext.Consumer>}
                                        onChange={(event) => { this.handleChange(event.target.value, 20) }}
                                    />
                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <TextField value={this.state.country} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "country")}</>)}</MyContext.Consumer>}
                                        onChange={(event) => { this.handleChange(event.target.value, 22) }}
                                    />
                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <TextField value={this.state.partner} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "partner")}</>)}</MyContext.Consumer>}
                                        onChange={(event) => { this.handleChange(event.target.value, 25) }}
                                    />
                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <TextField value={this.state.partnerContract} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "partnerContract")}</>)}</MyContext.Consumer>}
                                        onChange={(event) => { this.handleChange(event.target.value, 26) }}
                                    />
                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <TextField value={this.state.support} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "support")}</>)}</MyContext.Consumer>}
                                        onChange={(event) => { this.handleChange(event.target.value, 27) }}
                                    />
                                </Stack.Item>
                            </Stack>}
                    </Stack>}

                    {(this.props.userRules.ruleAccess.tenantCreate
                        || this.props.userRules.access == "Order"
                        || this.props.userRules.access == "Support") &&
                        <Stack.Item>
                            <ComboBox
                                style={{ width: "300px" }}
                                placeholder="Select an option"
                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "customerType")}</>)}</MyContext.Consumer>}
                                unknown
                                Europa
                                USA
                                Asia
                                options={[{
                                    key: "Unknown",
                                    text: "Unknown"
                                },
                                {
                                    key: "Paid",
                                    text: "Paid",
                                },
                                {
                                    key: "Trial",
                                    text: "End Customer Trial",
                                },
                                {
                                    key: "Partner",
                                    text: "Partner Trial",
                                }]
                                }
                                selectedKey={this.state.type}
                                onChange={(event, option, index, newValue) => { this.handleChange(option.key, 13) }}
                            />
                        </Stack.Item>}

                    {this.props.userRules.access == "Order" &&
                        <Stack>
                            <Stack.Item>
                                <ComboBox
                                    style={{ width: "300px" }}
                                    disabled="true"
                                    placeholder="Select an option"
                                    label="Storage"
                                    unknown
                                    Europa
                                    USA
                                    Asia
                                    options={[{
                                        key: "Unknown",
                                        text: "Unknown"
                                    },
                                    {
                                        key: "Emea",
                                        text: "EMEA GRS",
                                    },
                                    {
                                        key: "UkSouth",
                                        text: "UK South GRS",
                                    },
                                    {
                                        key: "Apac",
                                        text: "APAC GRS",
                                    },
                                    {
                                        key: "Americas",
                                        text: "Americas GRS",
                                    },
                                    {
                                        key: "Brazil",
                                        text: "Brazil GRS",
                                    },
                                    {
                                        key: "Canada",
                                        text: "Canada GRS",
                                    },
                                    {
                                        key: "Japan",
                                        text: "Japan GRS",
                                    },
                                    {
                                        key: "Switzerland",
                                        text: "Switzerland GRS",
                                    },
                                    {
                                        key: 'UAENorth',
                                        text: "UAE North GRS"
                                    },

                                    {
                                        key: "LrsEmea",
                                        text: "EMEA LRS",
                                    },
                                    {
                                        key: "LrsUkSouth",
                                        text: "UK South LRS",
                                    },
                                    {
                                        key: "LrsApac",
                                        text: "APAC LRS",
                                    },
                                    {
                                        key: "LrsAmericas",
                                        text: "Americas LRS",
                                    },
                                    {
                                        key: "LrsBrazil",
                                        text: "Brazil LRS",
                                    },
                                    {
                                        key: "LrsCanada",
                                        text: "Canada LRS",
                                    },
                                    {
                                        key: "LrsJapan",
                                        text: "Japan LRS",
                                    },
                                    {
                                        key: "LrsSwitzerland",
                                        text: "Switzerland LRS",
                                    },
                                    {
                                        key: 'LrsUAENorth',
                                        text: "UAE North LRS"
                                    },

                                    {
                                        key: "GrsSwiss",
                                        text: "Swiss GRS",
                                        disabled: true
                                    },

                                    {
                                        key: "LrsAustralia",
                                        text: "Australia LRS"
                                    },

                                    {
                                        key: "Australia",
                                        text: "Australia GRS"
                                    },

                                    {
                                        key: "LrsApacSe",
                                        text: "APAC Southeast LRS"
                                    },

                                    {
                                        key: "ApacSe",
                                        text: "APAC Southeast GRS"
                                    },


                                    {
                                        key: "CustomStorage",
                                        text: "Own Storage",
                                    }]
                                    }
                                    selectedKey={this.state.storage}
                                />
                            </Stack.Item>
                        </Stack>
                    }

                    {this.props.userRules.access != "Order" &&
                        <Stack>
                            <Stack.Item styles={this.styles}>
                                <TextField value={this.state.domainRestrictionsList} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "domainRestrictionsList")}</>)}</MyContext.Consumer>}
                                    onChange={(event) => {
                                        this.handleChange(event.target.value, 64)
                                    }}
                                    placeholder="exampleDomain.com, example_domain.com, example_eu.com"
                                />
                            </Stack.Item>
                            <Stack.Item styles={this.styles}>
                                <TextField value={this.state.mailAlarming} label={"Mail Alarming"}
                                    onChange={(event) => { this.handleChange(event.target.value, 17) }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <ComboBox
                                    style={{ width: "300px" }}
                                    placeholder="Select an option"
                                    label="Storage"
                                    options={[{
                                        key: "Unknown",
                                        text: "Unknown",
                                        disabled: (this.state.storage === "Unknown" || this.state.storage === "") ? false : true,
                                    },
                                    {
                                        key: "Emea",
                                        text: "EMEA GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "UkSouth",
                                        text: "UK South GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "Apac",
                                        text: "APAC GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "Americas",
                                        text: "Americas GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "Brazil",
                                        text: "Brazil GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "Canada",
                                        text: "Canada GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "Japan",
                                        text: "Japan GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "Switzerland",
                                        text: "Switzerland GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: 'UAENorth',
                                        text: "UAE North GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },

                                    {
                                        key: "LrsEmea",
                                        text: "EMEA LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "LrsUkSouth",
                                        text: "UK South LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "LrsApac",
                                        text: "APAC LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "LrsAmericas",
                                        text: "Americas LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "LrsBrazil",
                                        text: "Brazil LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "LrsCanada",
                                        text: "Canada LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "LrsJapan",
                                        text: "Japan LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: "LrsSwitzerland",
                                        text: "Switzerland LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },
                                    {
                                        key: 'LrsUAENorth',
                                        text: "UAE North LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    },

                                    {
                                        key: "GrsSwiss",
                                        text: "Swiss GRS",
                                        disabled: true
                                    },

                                    {
                                        key: "LrsAustralia",
                                        text: "Australia LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,

                                    },

                                    {
                                        key: "Australia",
                                        text: "Australia GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,

                                    },

                                    {
                                        key: "LrsApacSe",
                                        text: "APAC Southeast LRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,

                                    },

                                    {
                                        key: "ApacSe",
                                        text: "APAC Southeast GRS",
                                        disabled: this.state.storage === "S3Storage" ? true : false,

                                    },

                                    {
                                        key: "S3Storage",
                                        text: "S3 Storage",
                                        disabled: (this.state.storage === "S3Storage" || this.state.storage === "Unknown" || this.state.storage === "") ? false : true,
                                    },
                                    {
                                        key: "CustomStorage",
                                        text: "Own Storage",
                                        disabled: this.state.storage === "S3Storage" ? true : false,
                                    }]
                                    }
                                    selectedKey={this.state.storage}
                                    onChange={(event, option, index, newValue) => { this.handleChange(option.key, 5) }}
                                />
                                {/*this modal displays a popup to confirm the storage change*/}
                                {/*<Modal*/}
                                {/*    isOpen={this.state.showModal}*/}
                                {/*    onDismiss={this._closeModal}*/}
                                {/*    isBlocking*/}
                                {/*    containerClassName={contentStyles.container}*/}
                                {/*>*/}
                                {/*    <div className={contentStyles.header}>*/}
                                {/*        <span><b><MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "warning")}</>)}</MyContext.Consumer></b></span>*/}
                                {/*        <IconButton*/}
                                {/*            styles={iconButtonStyles}*/}
                                {/*            iconProps={{ iconName: 'Cancel' }}*/}
                                {/*            ariaLabel="Close popup modal"*/}
                                {/*            onClick={this._closeModal}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*    <div className={contentStyles.body}>*/}
                                {/*        <span>*/}
                                {/*            <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "changeStorageWarning")}</>)}</MyContext.Consumer>*/}
                                {/*            <br />*/}
                                {/*            <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "changeStorageWarning1")}</>)}</MyContext.Consumer>*/}
                                {/*        </span>*/}
                                {/*        <br />*/}
                                {/*        <span>*/}
                                {/*            <PrimaryButton*/}
                                {/*                text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "ok")}</>)}</MyContext.Consumer>}*/}
                                {/*                allowDisabledFocus={true}*/}
                                {/*                onClick={*/}
                                {/*                    () => { this._closeModal() }*/}
                                {/*                }*/}
                                {/*                styles={iconButtonStyles}*/}
                                {/*            />*/}
                                {/*            <PrimaryButton*/}
                                {/*                text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "cancel")}</>)}</MyContext.Consumer>}*/}
                                {/*                allowDisabledFocus={true}*/}
                                {/*                onClick={*/}
                                {/*                    () => {*/}
                                {/*                        this.setState({ storage: this.props.data.storage });*/}
                                {/*                        this._closeModal();*/}
                                {/*                    }*/}
                                {/*                }*/}
                                {/*                styles={iconButtonStyles}*/}
                                {/*                style={{ background: "#edebeb", color: "black", border: "#edebeb", "margin-left": "5px" }}*/}
                                {/*            />*/}
                                {/*        </span>*/}
                                {/*    </div>*/}
                                {/*</Modal>*/}
                            </Stack.Item>
                            {this.state.storage == "CustomStorage" &&
                                <Stack.Item>
                                    <TextField label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "storageConnectionString")}</>)}</MyContext.Consumer>}
                                        onChange={(event) => { this.handleChange(event.target.value, 21) }}
                                        value={this.state.customStorage}
                                        onKeyDown={this._handleKeyDown}
                                    />
                                </Stack.Item>}

                            {this.state.storage === 'S3Storage' && <>
                                <ComboBox
                                    style={{ width: "300px" }}
                                    placeholder="Select an option"
                                    label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "awsType")}</>)}</MyContext.Consumer>}
                                    options={[
                                        { key: "Cloud", text: "Cloud" },
                                        { key: "OnPremise", text: "On-premise" },

                                    ]}
                                    selectedKey={this.state.awsType}
                                    onChange={(ev, newVal) => {
                                        this.setState({
                                            awsType: newVal.key,
                                            s3Check: "false",
                                            changed: true
                                        });
                                    }}
                                    errorMessage={this.state.awsType == "" || this.state.awsType == null ? "Aws type must be selected" : ""}
                                />
                                <TextField
                                    type={"text"}
                                    label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "awsAccessKeyId")}</>)}</MyContext.Consumer>}
                                    value={this.state.awsAccessKeyId}
                                    errorMessage={(this.state.awsAccessKeyId === "" || this.state.awsAccessKeyId === null) ? "Access Key Id cannot be empty" : ""}
                                    onChange={(event) => { this.handleChange(event.target.value, 77) }}

                                />
                                <TextField
                                    type={"text"}
                                    label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "awsSecretAccess")}</>)}</MyContext.Consumer>}
                                    value={this.state.awsSecretAccess}
                                    errorMessage={(this.state.awsSecretAccess === "" || this.state.awsSecretAccess === null) ? "Secret Access key cannot be empty" : ""}
                                    onChange={(event) => { this.handleChange(event.target.value, 78) }}

                                />
                                {this.state.awsType == "OnPremise" &&
                                    <TextField
                                        type={"text"}
                                        label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "awsServiceUrl")}</>)}</MyContext.Consumer>}
                                        value={this.state.awsServiceUrl}
                                        errorMessage={(this.state.awsServiceUrl === "" || this.state.awsServiceUrl === null) ? "Service Url cannot be empty" : ""}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                awsServiceUrl: newValue,
                                                s3Check: "false",
                                                changed: true
                                            });
                                        }}
                                    />}
                                <TextField
                                    type={"text"}
                                    label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "awsBucketName")}</>)}</MyContext.Consumer>}
                                    value={this.state.awsBucketName}
                                    errorMessage={(this.state.awsBucketName === "" || this.state.awsBucketName === null) ? "Bucket name cannot be empty" : ""}
                                    onChange={(event) => { this.handleChange(event.target.value, 79) }}

                                />
                                {this.state.awsType === "Cloud" &&
                                    <Query
                                        query={queryGetAWSRegions}
                                        variables={this.props.authorization} >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>
                                                <Spinner label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "loadingRegions")}</>)}</MyContext.Consumer>} ariaLive="assertive" labelPosition="top" />
                                            </div>;
                                            if (error) return <h1> </h1>;

                                            if (data.getAWSRegions == null) {
                                                return <div></div>;
                                            }
                                            this.tmpKey = [];
                                            const stack = data.getAWSRegions;
                                            if (data.getAWSRegions != null) {
                                                for (let x = 0; x < stack.length; x++) {
                                                    let i = {
                                                        key: stack[x].code,
                                                        text: stack[x].displayName,
                                                    };
                                                    this.tmpKey.push(i);
                                                }
                                            }

                                            return <ComboBox
                                                style={{ width: "300px" }}
                                                placeholder="Select an option"
                                                label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "defaultRegion")}</>)}</MyContext.Consumer>}
                                                options={this.tmpKey}
                                                selectedKey={this.state.awsRegion}
                                                onChange={(ev, newVal) => {
                                                    this.setState({
                                                        awsRegion: newVal.key,
                                                        s3Check: "false",
                                                        isChanged: true
                                                    });
                                                }}
                                                errorMessage={this.state.awsRegion == "" || this.state.awsRegion == null ? "Default region must be selected" : ""}
                                            />
                                        }}
                                    </Query>}
                                <br />
                                <Stack.Item>
                                    <DefaultButton
                                        id="checkS3Btn"
                                        text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "checkValidity")}</>)}</MyContext.Consumer>}
                                        onClick={this.checkS3StorageConnection}
                                        disabled={this.disbaledCheckValidity()}
                                    />
                                </Stack.Item>
                            </>}
                        </Stack>
                    }
                    {this.props.userRules.access != "Order" && this.state.keyManagementEnabled && < Stack >

                        <Stack.Item>
                            <MyContext.Consumer>
                                {(context) => (
                                    <ComboBox
                                        style={{ width: "300px" }}
                                        placeholder="Select an option"
                                        label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "keyManagement")}</>)}</MyContext.Consumer>}
                                        options={[{
                                            key: "No",
                                            text: context.getTranslation("tenants", "no"),
                                        },
                                        {
                                            key: "KeyRotation",
                                            text: context.getTranslation("tenants", "keyRotation"),
                                        },
                                        {
                                            key: "CustomKey",
                                            text: context.getTranslation("tenants", "customKeys"),
                                        }]
                                        }
                                        selectedKey={this.state.keyManagementType}
                                        onChange={(event, option, index, newValue) => { this.handleChange(option.key, 48) }}
                                    />
                                )
                                }
                            </MyContext.Consumer>

                        </Stack.Item>


                        {/* Key creation */}

                        {this.state.keyManagementType == "CustomKey" &&
                            <Stack.Item styles={this.styles}>

                                <TextField id="keyVault" name="keyVault" value={this.state.customEncryptionKeyVaultUrl} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "customerKeyURL")}</>)}</MyContext.Consumer>}
                                    onChange={(event) => { this.handleChange(event.target.value, 50) }}
                                />
                                <span id="keyVaultError" style={{ "color": "red" }}></span>

                                <TextField id="secretName" name="secretName" value={this.state.customEncryptionKeySecretName} label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "customerSecretName")}</>)}</MyContext.Consumer>}
                                    onChange={(event) => { this.handleChange(event.target.value, 51) }}
                                />
                                <span id="secretError" style={{ "color": "red" }}></span>
                                <br />
                                <DefaultButton id="checkBtn" text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "checkValidity")}</>)}</MyContext.Consumer>} onClick={(event) => { this.checkCustomKeyVault(this.props.apol) }} />
                                <Modal
                                    isOpen={this.state.showModal}
                                    onDismiss={this._closeModal}
                                    isBlocking
                                    containerClassName={contentStyles.container}
                                >
                                    <div className={contentStyles.header}>
                                        <span><b><MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "warning")}</>)}</MyContext.Consumer></b></span>
                                        <IconButton
                                            styles={iconButtonStyles}
                                            iconProps={{ iconName: 'Cancel' }}
                                            ariaLabel="Close popup modal"
                                            onClick={this._closeModal}
                                        />
                                    </div>
                                    <div className={contentStyles.body}>
                                        <span>
                                            <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "secretDoesNotExist")}</>)}</MyContext.Consumer>
                                            <br />
                                            <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "createSecretQuestion")}</>)}</MyContext.Consumer>
                                        </span>
                                        <br />
                                        <span>
                                            <PrimaryButton
                                                text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "yes")}</>)}</MyContext.Consumer>}
                                                allowDisabledFocus={true}
                                                onClick={
                                                    () => {
                                                        this.createCustomKeySecret(this.props.apol);
                                                        this._closeModal();
                                                    }
                                                }
                                                styles={iconButtonStyles}
                                            />
                                            <PrimaryButton
                                                text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "cancel")}</>)}</MyContext.Consumer>}
                                                allowDisabledFocus={true}
                                                onClick={
                                                    () => {
                                                        this._closeModal();
                                                    }
                                                }
                                                styles={iconButtonStyles}
                                                style={{ background: "#edebeb", color: "black", border: "#edebeb", "margin-left": "5px" }}
                                            />
                                        </span>
                                    </div>
                                </Modal>
                            </Stack.Item>}
                        {/*asymetric keys*/}
                        {(this.state.keyManagementType !== "No" && this.state.storage !== "S3Storage") && <>
                            <br />
                            <Stack.Item>
                                <Checkbox
                                    label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "asymmetricKeyEnabled")}</>)}</MyContext.Consumer>}
                                    checked={this.state.asymmetricKeyEnabled}
                                    onChange={(event, newValue) => { this.handleChange(newValue, 80) }}
                                />
                            </Stack.Item>
                            {this.state.asymmetricKeyEnabled &&
                                <Stack.Item>

                                    <Dropdown
                                        style={{ width: "300px" }}
                                        label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "keyType")}</>)}</MyContext.Consumer>}
                                        selectedKey={this.state.keyType}
                                        onChange={(event, option, index, newValue) => { this.handleChange(option.key, 81) }}
                                        options={[{ key: "RSA", text: "RSA" }]}
                                    />

                                    <Dropdown
                                        style={{ width: "300px" }}
                                        label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "keySetting")}</>)}</MyContext.Consumer>}
                                        selectedKey={this.state.keySetting}
                                        onChange={(event, option, index, newValue) => { this.handleChange(option.key, 82) }}
                                        options={[
                                            { key: "2048", text: "2048" },
                                            { key: "3072", text: "3072" },
                                            { key: "4096", text: "4096" },
                                        ]}

                                    />
                                </Stack.Item>
                            }
                        </>}
                        {(this.state.keyManagementType == "KeyRotation" || this.state.keyManagementType == "CustomKey") &&
                            <Stack.Item styles={this.styles}>

                                {/* Key security */}
                                {!this.state.asymmetricKeyEnabled &&
                                    <ComboBox
                                        style={{ width: "300px" }}
                                        placeholder="Select an option"
                                        label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "keySecurity")}</>)}</MyContext.Consumer>}
                                        options={[{
                                            key: "Aes128",
                                            text: "AES128"
                                        },
                                        {
                                            key: "Aes192",
                                            text: "AES192",
                                        },
                                        {
                                            key: "Aes256",
                                            text: "AES256",
                                        }]
                                        }
                                        selectedKey={this.state.keySecurity}
                                        onChange={(event, option, index, newValue) => { this.handleChange(option.key, 49) }}
                                    />
                                }
                                {/* Rotation Type */}
                                <MyContext.Consumer>
                                    {(context) => (
                                        <ComboBox
                                            style={{ width: "300px" }}
                                            placeholder="Select an option"
                                            label={context.getTranslation("tenants", "rotationType")}
                                            options={[{
                                                key: "No",
                                                text: context.getTranslation("tenants", "no"),
                                                disabled: this.state.asymmetricKeyEnabled

                                            },
                                            {
                                                key: "Interval",
                                                text: context.getTranslation("tenants", "interval"),
                                                disabled: this.state.asymmetricKeyEnabled
                                            },
                                            {
                                                key: "Manual",
                                                text: context.getTranslation("tenants", "manual"),
                                            }]
                                            }
                                            selectedKey={this.state.rotationType}
                                            onChange={(event, option, index, newValue) => { this.handleChange(option.key, 55) }}
                                        />
                                    )
                                    }
                                </MyContext.Consumer>


                                {this.state.rotationType == "Manual" && <div>
                                    {!this.state.asymmetricKeyEnabled && <>

                                        <br />
                                        <Checkbox
                                            label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "manualCreation")}</>)}</MyContext.Consumer>}
                                            value={this.state.manualCreationKeyRotation}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    manualCreationKeyRotation: newValue,
                                                    isChecked: newValue
                                                });
                                            }}
                                            checked={this.state.isChecked}
                                        />
                                        {this.state.manualCreationKeyRotation ? (
                                            <DefaultButton id="requestRotation" text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "requestForRotation")}</>)}</MyContext.Consumer>} onClick={(event) => { this.changeEncryptionKey(this.props.apol) }} />
                                        ) : (
                                            <DefaultButton text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "requestForRotation")}</>)}</MyContext.Consumer>} disabled />
                                        )}

                                        <br />
                                    </>}
                                    {this.state.asymmetricKeyEnabled && <>
                                        <Label style={{ marginTop: '10px' }}><MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "createAsymmetricKey")}</>)}</MyContext.Consumer></Label>
                                        <div id="clickable-area">
                                            <input type="file" onChange={(event) => this.onFileUploadAsymmetricKey(event, "uploadedContent")} id="file-btn" />
                                            <div className="upload-button">
                                                <span>{this.state.selectedFile
                                                    ? <MyContext.Consumer>{(context) => (<>{context.getTranslation("common", "updateFile")}</>)}</MyContext.Consumer>
                                                    : <MyContext.Consumer>{(context) => (<>{context.getTranslation("common", "uploadFile")}</>)}</MyContext.Consumer>}
                                                </span>
                                            </div>
                                            <p className="clickable-area-text">{this.state.selectedFile
                                                ? <p><i className="ms-Icon ms-Icon--Accept" style={{ fontSize: "16px" }}></i><span className="clickable-area-text">{this.state.asymmetricKeyFileName}</span></p>
                                                : <MyContext.Consumer>{(context) => (<>{context.getTranslation("common", "noFileSelected")}</>)}</MyContext.Consumer>}
                                            </p>
                                        </div>
                                        {this.state.selectedFile ? (
                                            <DefaultButton id="createAsymmetricKey" disabled={this.state.isActivateAsymmetricKeyDisabled} text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", this.state.asymmetricKeyButtonText)}</>)}</MyContext.Consumer>} onClick={(event) => { this.createAsymmetricKey(this.props.apol) }} style={{ marginTop: '5px' }} />
                                        ) : (
                                            <DefaultButton text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "createAsymmetricKey")}</>)}</MyContext.Consumer>} disabled style={{ marginTop: '5px' }} />
                                        )}
                                    </>}
                                </div>}

                                {/* Key creation interval */}
                                {this.state.rotationType == "Interval" && <div>
                                    <Label><MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "keyCreationInterval")}</>)}</MyContext.Consumer></Label>
                                    <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "every")}</>)}</MyContext.Consumer><br />
                                    <div style={{ diplay: "flex" }}>
                                        <TextField type="Number" className="displayKeyRotationSettings" name="RotationDays" min={1} defaultValue={this.state.intervalDays}
                                            onChange={(event) => { this.handleChange(event.target.value, 52) }}>
                                        </TextField>
                                        <Label className="displayKeyRotationSettings"><MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "days")}</>)}</MyContext.Consumer></Label>
                                    </div>

                                    {/* Delay of activation */}
                                    <Label><MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "delayOfActivation")}</>)}</MyContext.Consumer></Label>

                                    <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "for")}</>)}</MyContext.Consumer><br />
                                    <div style={{ diplay: "flex" }}>
                                        <TextField type="Number" className="displayKeyRotationSettings" name="ActivationDelayDay" min={0} value={this.state.activationDelay}
                                            onChange={(event) => { this.handleChange(event.target.value, 53) }}>
                                        </TextField>
                                        <Label className="displayKeyRotationSettings"><MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "days")}</>)}</MyContext.Consumer></Label>
                                    </div>

                                    <Label><MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "keyExpirationDate")}</>)}</MyContext.Consumer></Label>

                                    <Checkbox
                                        label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "keyExpiration")}</>)}</MyContext.Consumer>}
                                        checked={this.state.willExpire}
                                        onChange={(event, newValue) => { this.handleChange(newValue, 56) }}
                                        id="keyExpiration"
                                    />
                                    {this.state.keyExpirationMessage &&
                                        <TeachingBubble
                                            target={`#${"keyExpiration"}`}
                                            secondaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "no")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ willExpire: false, keyExpirationMessage: false }) } }}
                                            primaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "yes")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ willExpire: true, keyExpirationMessage: false }) } }}
                                            headline={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "warning")}</>)}</MyContext.Consumer>
                                            }

                                        >
                                            <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "keyExpirationCheckboxWarningMsg")}</>)}</MyContext.Consumer>

                                        </TeachingBubble>
                                    }
                                    {this.state.willExpire && <div>
                                        <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "after")}</>)}</MyContext.Consumer> < br />
                                        <div style={{ diplay: "flex" }}>
                                            <TextField type="number" id="expirationDaysField" className="displayKeyRotationSettings" min={1} name="ExpirationDays" defaultValue={(this.state.expireDays == 0 ? this.state.intervalDays : this.state.expireDays)}
                                                onChange={(event) => {
                                                    this.handleChange(event.target.value, 54);
                                                }}>
                                            </TextField>
                                            <Label className="displayKeyRotationSettings"><MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "days")}</>)}</MyContext.Consumer></Label>
                                        </div></div>}


                                    {this.state.willExpire && <Button id="keyExpirationBtn" text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "checkValidity")}</>)}</MyContext.Consumer>} onClick={(event) => { this.checkKeyExpirationValidity(this.props.apol) }} disabled={this.state.TTLLoading} />}
                                    {this.state.keyExpirationButtonMessage &&
                                        <TeachingBubble
                                            target={`#${"keyExpirationBtn"}`}
                                            secondaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "no")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ keyExpirationButtonMessage: false, keyExpirationConfirmed: false }) } }}
                                            primaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "yes")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ keyExpirationButtonMessage: false, keyExpirationConfirmed: true }) } }}
                                            headline={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "warning")}</>)}</MyContext.Consumer>
                                            }

                                        >
                                            <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "maxTtlFoundMsg") + (this.state.maxTtlFound + this.state.intervalDays) + "(Maximum TTL found + Interval days value)" + context.getTranslation("tenants", "keyExpirationButtonWarningMsg")}</>)}</MyContext.Consumer>

                                        </TeachingBubble>
                                    }
                                </div>}

                            </Stack.Item>}
                    </Stack>}

                    <br />

                    {/* AUDIO STORAGE SETTINGS*/}
                    <Stack.Item>

                        <Panel
                            type={PanelType.medium}
                            isOpen={this.state.showAudioStoragePanel}
                            hasCloseButton={true}
                            headerText={"Audio Storage Configuration"}
                            focusTrapZoneProps={{
                                isClickableOutsideFocusTrap: true,
                                forceFocusInsideTrap: false
                            }}
                            onDismiss={this._onDismissAudioStoragePanel}
                        >
                            {this.openAudioStorageSettings((this.props.data != null ? this.props.data.audioStorage : null), this.state.storage)}
                        </Panel>

                        <Label onClick={this._onOpenAudioStoragePanel} style={{ "cursor": "pointer" }}>
                            <i className="ms-Icon ms-Icon--Audio"></i> <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "audioStorage")}</>)}</MyContext.Consumer>
                        </Label>


                    </Stack.Item>

                    <br />
                    {/*enable/disable suspension of all conversation deletion process*/}
                    <Checkbox
                        id={"suspendDeletion"}
                        label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "suspensionConvDeleteProcess")}</>)}</MyContext.Consumer>}
                        checked={this.state.tenantSuspensionConvDelProcess}
                        onChange={(event, newValue) => {
                            this.handleChange(newValue, 75);

                        }}
                        disabled={!this.props.userRules.ruleAccess.suspensionConvDelProcess}
                    />

                    {this.state.deletionNotifyMessage &&
                        <TeachingBubble
                            target={`#${"suspendDeletion"}`}
                            secondaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "no")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ deletionNotifyMessage: false, tenantSuspensionConvDelProcess: !this.state.tenantSuspensionConvDelProcess }) } }}
                            primaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "yes")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ deletionNotifyMessage: false }) } }}
                            headline={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "warning")}</>)}</MyContext.Consumer>
                            }
                        >

                            {this.state.tenantSuspensionConvDelProcess
                                ? <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "suspensionMsgDeletionWarningCheck")}</>)}</MyContext.Consumer>

                                : <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "suspensionMsgDeletionWarningUncheck")}</>)}</MyContext.Consumer>
                            }
                        </TeachingBubble>
                    }
                    <br />
                    {/*enable Chat Model A*/}
                    <Checkbox
                        id={"enableChatModelA"}
                        label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "chatModelA")}</>)}</MyContext.Consumer>}
                        checked={this.state.chatModelA}
                        onChange={(event, newValue) => {
                            this.handleChange(newValue, 86);

                        }}
                        disabled={this.state.addOnFullChat == 0}
                    />
                    {/* Bot Onboarding */}
                    {/*<Stack.Item>*/}

                    {/*    <Panel*/}
                    {/*        type={PanelType.medium}*/}
                    {/*        isOpen={this.state.showBotOnboardingPanel}*/}
                    {/*        hasCloseButton={true}*/}
                    {/*        headerText={"Bot Onboarding"}*/}
                    {/*        focusTrapZoneProps={{*/}
                    {/*            isClickableOutsideFocusTrap: true,*/}
                    {/*            forceFocusInsideTrap: false*/}
                    {/*        }}*/}
                    {/*        onDismiss={this._onDismissBotOnboardingPanel}*/}
                    {/*    >*/}
                    {/*        {this.openBotOnboardingSettings(this.props.data != null ? this.props.data.botOnboarding : null)}*/}
                    {/*    </Panel>*/}

                    {/*    <Label onClick={this._onOpenBotOnboardingPanel} style={{ "cursor": "pointer" }}>*/}
                    {/*        <i className="ms-Icon ms-Icon--Audio"></i> <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "botOnboarding")}</>)}</MyContext.Consumer>*/}
                    {/*    </Label>*/}


                    {/*</Stack.Item>*/}

                </Stack>
            </Stack.Item>

            <Stack.Item styles={this.styles}>
                <Stack horizontal>
                    <Stack.Item>
                        <DefaultButton onClick={(event) => { this.props.cl() }} text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "cancel")}</>)}</MyContext.Consumer>} />
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton
                            id="saveBtn"
                            onClick={(event) => { this.notifyTenantChange(this.props.apol); this.props.cl() }}
                            text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "save")}</>)}</MyContext.Consumer>}
                            disabled={this.saveDisableCheck()} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            {this.props.userRules.access != "Order" &&
                <Stack>
                    <Stack.Item styles={this.styles}>
                        <Checkbox
                            label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "bulkUserImport")}</>)}</MyContext.Consumer>}
                            value={this.state.bulkImport}
                            onChange={(event, newValue) => {
                                this.setState({
                                    bulkImport: newValue
                                });
                            }}
                        />
                    </Stack.Item>
                    {this.state.bulkImport &&
                        <ApolloProvider client={this.props.apol}>
                            {this.query(this.props.authorization)}

                        </ApolloProvider>
                    }
                    
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack>
                        <Stack.Item styles={this.styles}>
                            <Checkbox
                                label="Delete all metadata of a Tenant"
                                value={this.state.deleteTenantMetadata}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        deleteTenantMetadata: newValue
                                    });
                                }}
                            />
                        </Stack.Item>
                        {this.state.deleteTenantMetadata && <Stack>
                            <Stack.Item styles={this.styles}>
                                <div>End Date</div>
                                <DatePicker
                                    onSelectDate={(date) => {
                                        this.setState({ deleteTenantMetadataDate: date })
                                    }}
                                    value={this.state.deleteTenantMetadataDate}
                                />
                                <TextField min={0} value={this.state.deletePassword} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "password")}</>)}</MyContext.Consumer>}
                                    onChange={(event) => { this.setState({ deletePassword: event.target.value }) }}
                                />
                            </Stack.Item>
                            <Stack.Item styles={this.styles}>
                                <PrimaryButton
                                    onClick={(event) => { this.deleteTenantMetadata(this.props.apol) }}
                                    text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "startDeleteTenantMetadata")}</>)}</MyContext.Consumer>} />
                            </Stack.Item>
                            <Stack.Item styles={this.styles}>
                                <Text>{this.state.deleteTenantMetadataText}</Text>
                            </Stack.Item>
                        </Stack>}
                    </Stack>}
                    {this.props.userRules.ruleAccess.tenantCreate && <Stack>
                        <Stack.Item styles={this.styles}>
                            <Checkbox
                                label="Delete Tenant with all related items and recordings"
                                value={this.state.deleteTenant}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        deleteTenant: newValue
                                    });
                                }}
                            />
                        </Stack.Item>
                        {this.state.deleteTenant && <Stack>
                            <Stack.Item styles={this.styles}>

                                <TextField min={0} value={this.state.deletePassword} type="Number" label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "password")}</>)}</MyContext.Consumer>}
                                    onChange={(event) => { this.setState({ deletePassword: event.target.value }) }}
                                />
                            </Stack.Item>
                            <Stack.Item styles={this.styles}>
                                <PrimaryButton
                                    onClick={(event) => { this.deleteTenant(this.props.apol) }}
                                    text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "startDeleteTenant")}</>)}</MyContext.Consumer>} />
                            </Stack.Item>
                            <Stack.Item styles={this.styles}>
                                <Text>{this.state.deleteTenantText}</Text>
                            </Stack.Item>
                        </Stack>}
                    </Stack>}
                </Stack>}
        </Stack>
        );
    }

    query = (auth) => {
        return (<Query
            query={queryGetRuleData}
            variables={{ auth }}>
            {({ loading, error, data }) => {
                if (loading) return <div>
                    <Spinner label="Loading Rules ..." ariaLive="assertive" labelPosition="top" />
                </div>;
                if (error) return <h1> </h1>;

                if (data.getRuleData == null) {
                    return <div> </div>;
                }
                return <Stack><Stack.Item styles={this.styles}>
                    <ComboBox
                        style={{ width: "300px" }}
                        placeholder="Select an option"
                        label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "exportCycle")}</>)}</MyContext.Consumer>}
                        options={[{
                            key: "1",
                            text: "Compliance UC License"
                        },
                        {
                            key: "2",
                            text: "Compliance&Analytics UC License",
                        },
                        {
                            key: "3",
                            text: "Smart UC License",
                        },
                        {
                            key: "4",
                            text: "Smart&Analytics UC License",
                        },
                        {
                            key: "5",
                            text: "Chat Recording Standalone License"
                        },
                        {
                            key: "6",
                            text: "Compliance Voice License"
                        },
                        {
                            key: "7",
                            text: "Compliance Voice & Analytics License"
                        },
                        {
                            key: "8",
                            text: "Smart Voice License"
                        },
                        {
                            key: "9",
                            text: "Smart Voice & Analytics License"
                        }]
                        }
                        selectedKey={this.state.bulkLicense}
                        onChange={(event, option, index, newValue) => { this.setState({ bulkLicense: option.key }) }}
                    />
                </Stack.Item>
                    <Stack.Item styles={this.styles}>
                        <Dropdown placeholder="Select an option" label="Recording" selectedKey={this.state.bulkRuleRecording} onChange={(event, newValue) => { this.setState({ bulkRuleRecording: newValue.key }) }} options={recordingOption(data.getRuleData.ruledata)} styles={dropdownStyles} />
                        <Dropdown placeholder="Select an option" label="Replay" selectedKey={this.state.bulkRuleReplay} onChange={(event, newValue) => { this.setState({ bulkRuleReplay: newValue.key }) }} options={replayOption(data.getRuleData.ruledata)} styles={dropdownStyles} />
                        <Dropdown placeholder="Select an option" label="Access" selectedKey={this.state.bulkRuleAccess} onChange={(event, newValue) => { this.setState({ bulkRuleAccess: newValue.key }) }} options={accessOption(data.getRuleData.ruledata)} styles={dropdownStyles} />
                        <Dropdown placeholder="Select an option" label="Analytics" selectedKey={this.state.bulkRuleAnalytics} onChange={(event, newValue) => { this.setState({ bulkRuleAnalytics: newValue.key }) }} options={analyticsOption(data.getRuleData.ruledata)} styles={dropdownStyles} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"User List"}
                            onChange={(event) => { this.setState({ bulkUserList: event.target.value }) }}
                            value={this.state.bulkUserList}
                        />
                    </Stack.Item>
                    <Stack.Item styles={this.styles}>
                        <PrimaryButton
                            onClick={(event) => { this.bulkImport(this.props.apol); }}
                            text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "import")}</>)}</MyContext.Consumer>} disabled={this.state.bulkUserList == "" || this.state.bulkLicense == ""} />
                    </Stack.Item>
                    <Stack.Item styles={this.styles}>
                        <Text>{this.state.bulkImportText}</Text>
                    </Stack.Item>
                </Stack>

            }}
        </Query>);
    };

    handleChange(text, id) {
        switch (id) {
            case 0: this.setState({ name: text }); break;
            case 1: this.setState({ tenantId: text }); break;
            case 2: this.setState({ adminId: text }); break;
            case 3: this.setState({ license1: text }); break;
            case 4: this.setState({ license2: text }); break;
            case 5: if (text === "S3Storage") {
                this.setState({
                    s3Check: "false",
                    storage: text,
                    asymmetricKeyEnabled: false
                });
            } else {
                this.setState({
                    s3Check: "",
                    storage: text,
                })
            } break;
            case 6: this.setState({ exportType: text }); break;
            case 7: this.setState({ exportStorage: text }); break;
            case 10: this.setState({ license3: text }); break;
            case 11: this.setState({ license4: text }); break;
            case 12: this.setState({ license5: text }); break;
            case 13: this.setState({ type: text }); break;
            case 14: this.setState({ packageStorage: text }); break;
            case 15: this.setState({ packageAnalyticsVideo: text }); break;
            case 16: this.setState({ packageExport: parseInt(text) }); break;
            case 17: this.setState({ mailAlarming: text }); break;
            case 18: this.setState({ packageAnalyticsSpeech: text }); break;
            case 19: this.setState({ packageAnalyticsTranslate: text }); break;
            case 20: this.setState({ contractNumber: text }); break;
            case 21: this.setState({ customStorage: text }); break;
            case 22: this.setState({ country: text }); break;
            case 23: this.setState({ onboardingDate: text }); break;
            case 24: this.setState({ billingDate: text }); break;
            case 25: this.setState({ partner: text }); break;
            case 26: this.setState({ partnerContract: text }); break;
            case 27: this.setState({ support: text }); break;
            case 28: this.setState({ bot01: text }); break;
            case 29: this.setState({ bot02: text }); break;
            case 30: this.setState({ bot03: text }); break;
            case 31: this.setState({ bot04: text }); break;
            case 32: this.setState({ bot05: text }); break;
            case 33: this.setState({ bot06: text }); break;
            case 34: this.setState({ bot07: text }); break;
            case 35: this.setState({ bot08: text }); break;
            case 36: this.setState({ bot09: text }); break;
            case 37: this.setState({ bot10: text }); break;
            case 38: this.setState({ bot11: text }); break;
            case 39: this.setState({ bot12: text }); break;
            case 40: this.setState({ bot13: text }); break;
            case 41: this.setState({ bot14: text }); break;
            case 42: this.setState({ bot15: text }); break;
            case 43: this.setState({ bot16: text }); break;
            case 44: this.setState({ bot17: text }); break;
            case 45: this.setState({ bot18: text }); break;
            case 46: this.setState({ bot19: text }); break;
            case 47: this.setState({ bot20: text }); break;
            case 48: this.setState({ keyManagementType: text }); break;
            case 49: this.setState({ keySecurity: text }); break;
            case 50: this.setState({ customEncryptionKeyVaultUrl: text }); break;
            case 51: this.setState({ customEncryptionKeySecretName: text }); break;
            case 52: this.setState({ intervalDays: text == 0 ? 1 : parseInt(text) }); break;
            case 53: this.setState({ activationDelay: text }); break;
            case 54: this.setState({ expireDays: parseInt(text), keyExpirationConfirmed: false }); break;
            case 55: this.setState({ rotationType: text }); break;
            case 56: this.setState({ willExpire: text, keyExpirationMessage: text }); break;
            case 57: this.setState({ license6: text }); break;
            case 58: this.setState({ license7: text }); break;
            case 59: this.setState({ packageAnalyticsTranscript: text }); break;
            case 60: this.setState({ license8: text }); break;
            case 61: this.setState({ license9: text }); break;
            case 62: this.setState({ addOnAnalytics: text }); break;
            case 63: this.setState({ addOnFullChat: text }); break;

            case 64: this.setState({ domainRestrictionsList: text }); break;
            case 65: this.setState({ trainingDate: text }); break;
            case 66: this.setState({ supportNotes: text }); break;
            case 67: this.setState({ systemId: text }); break;
            case 68: this.setState({ addOnZoom: text }); break;
            case 69: this.setState({ addOnGenesys: text }); break;
            case 70: this.setState({ addOnRingcentral: text }); break;
            case 71: this.setState({ addOnDynamics: text }); break;
            case 72: this.setState({ addOnCompliance: text }); break;
            case 73: this.setState({ packageImport: parseInt(text) }); break;
            case 74: this.setState({ packageArchive: parseInt(text) }); break;
            case 75: this.setState({ tenantSuspensionConvDelProcess: text, deletionNotifyMessage: true }); break;
            case 76: this.setState({ packageAnalyticsAI: text }); break;
            case 77: this.setState({ awsAccessKeyId: text, s3Check: "false" }); break;
            case 78: this.setState({ awsSecretAccess: text, s3Check: "false" }); break;
            case 79: this.setState({ awsBucketName: text, s3Check: "false" }); break;
            case 80: this.setState({ asymmetricKeyEnabled: text, rotationType: "Manual" }); break;
            case 81: this.setState({ keyType: text }); break;
            case 82: this.setState({ keySetting: text }); break;
            case 83: this.setState({ addOnLeapXpert: text }); break;
            case 84: this.setState({ license10: text }); break;


            case 85: this.setState({ addOnQualityManagement: text }); break;
            case 86: this.setState({ chatModelA: text }); break;
            case 87: break;
            default: break;

        }
        // Decide to set isChanged
        if (/^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/.test(id === 1 ? text : this.state.tenantId)
            && /^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/.test(id === 2 ? text : this.state.adminId)
            && (this.state.storage !== "Own" || this.state.customStorage != "")
            && (id === 0 ? text : this.state.name) !== ""
            && (id === 5 ? text : this.state.storage) !== "Unknown"
            && ((id === 3 ? text : this.state.license1) !== 0
                || (id === 4 ? text : this.state.license2) !== 0
                || (id === 10 ? text : this.state.license3) !== 0
                || (id === 11 ? text : this.state.license4) !== 0
                || (id === 12 ? text : this.state.license5) !== 0
                || (id === 57 ? text : this.state.license6) !== 0
                || (id === 58 ? text : this.state.license7) !== 0
                || (id === 60 ? text : this.state.license8) !== 0
                || (id === 61 ? text : this.state.license9) !== 0)
            && (id === 13 ? text : this.state.type !== "Unknown")
        ) {

            var changed = "";

            if ((id === 48 && text == "CustomKey") || this.state.keyManagementType == "CustomKey") {


                if (this.props.data != null && this.props.data.keyManagementType != null && this.props.data.keyManagementType == "CustomKey" && this.props.data.keyManagement.customEncryptionKeySecretName == this.state.customEncryptionKeySecretName &&
                    this.props.data.keyManagement.customEncryptionKeyVaultUrl == this.state.customEncryptionKeyVaultUrl && this.state.validateKeyVault != "false") {
                    this.setState({ validateKeyVault: "true" });
                    changed = "true";
                }
                if (id === 50 || id === 51 || this.state.validateKeyVault == "false") {
                    this.setState({ validateKeyVault: "false" });
                    changed = "false"
                }
                if (this.state.customEncryptionKeyVaultUrl == "" || this.state.customEncryptionKeySecretName == "") {
                    this.setState({ validateKeyVault: "false" });
                    changed = "false"
                }


                if (changed != "true" && changed != "") {
                    this.setState({ isChanged: false });

                } else {
                    this.setState({ isChanged: true });
                }



            } else {
                this.setState({ isChanged: true });

            }


        } else {
            this.setState({ isChanged: false });
        }
    }

    bulkImport = async (apol) => {
        this.setState({ bulkImportText: "Import running ..." });
        var listUser = this.state.bulkUserList.split(";");
        var sendUserList = [];
        listUser.forEach((aUser) => {
            sendUserList.push({
                userId: aUser,
                license: this.state.bulkLicense,
                access: this.state.bulkRuleAccess,
                recordingRules: this.state.bulkRuleRecording,
                replay: this.state.bulkRuleReplay,
                analytics: this.state.bulkRuleAnalytics,
            });
        });
        var data = await apol
            .mutate({
                mutation: mutationBulkImport,
                variables: {
                    auth: this.props.authorization,
                    user: sendUserList,
                    tenantId: this.props.data.tenantId
                }
            });
        this.setState({ bulkImport: false, bulkImportText: "" });
    }

    groupSync = async (apol) => {
        var data = await apol
            .mutate({
                mutation: userGroupSync,
                variables: {
                    auth: this.props.authorization,
                    tenantId: this.props.data.tenantId
                }
            });

    }

    changeEncryptionKey = async (apol) => {
        var data = await apol
            .mutate({
                mutation: changeEncryptionKey
            });
        if (data) {
            document.getElementById("requestRotation").innerHTML = "Key Rotation Successful";
            document.getElementById("requestRotation").disabled = true;
            this.setState({ isChecked: false })

        } else {
            document.getElementById("requestRotation").innerHTML = "Key Rotation Unsuccessful";
            document.getElementById("requestRotation").disabled = true;
            this.setState({ isChecked: false })

        }
    }

    createCustomKeySecret = async (apol) => {
        var data = await apol
            .mutate({
                mutation: createCustomKeySecret,
                variables: {
                    customKeyVaultUrl: this.state.customEncryptionKeyVaultUrl,
                    customSecretName: this.state.customEncryptionKeySecretName
                }
            });

        if (data.data.createCustomKeySecret == true) {
            this.setState({ validateKeyVault: "true" });
            document.getElementById("secretName").style.border = "2px solid green";
            toast.success("Secret was created successfully", { autoClose: 5000, position: "bottom-left" });
            document.querySelector('#checkBtn').style.border = "2px solid green";
            document.getElementById("secretError").innerHTML = "";
            this.handleChange("", 73);


        } else {
            toast.error("Failed to create secret", { autoClose: 5000, position: "bottom-left" });
            document.getElementById("secretError").innerHTML = "Invalid secret name or SET right is missing. Secret names can only contain alphanumeric characters and dashes.";
            this.handleChange("", 73);

        }
    }

    checkCustomKeyVault = async (apol) => {
        var data = await apol
            .mutate({
                mutation: checkCustomKeyVault,
                variables: {
                    customKeyVaultUrl: this.state.customEncryptionKeyVaultUrl,
                    customSecretName: this.state.customEncryptionKeySecretName,
                    isAsymmetric: this.state.asymmetricKeyEnabled
                }
            });
        switch (data.data.checkCustomKeyVault) {
            case 0: //accessible
                document.getElementById("keyVault").style.border = "2px solid green";
                document.getElementById("secretName").style.border = "2px solid green";
                document.getElementById("keyVaultError").innerHTML = "";
                document.getElementById("secretError").innerHTML = "";
                toast.success("Key Vault and secret name are correct", { autoClose: 5000, position: "bottom-left" });
                document.querySelector('#checkBtn').style.border = "2px solid green";
                this.setState({ validateKeyVault: "true" });
                break;
            case 1: //InvalidKeyVaultUrl
                document.getElementById("keyVaultError").innerHTML = "Key vault doesn't exist or permissions are not set";
                document.getElementById("keyVault").style.border = "2px solid red";
                toast.error("Key vault doesn't exist or permissions are not set", { autoClose: 5000, position: "bottom-left" });
                document.querySelector('#checkBtn').style.border = "2px solid red";
                this.setState({ validateKeyVault: "false" });

                break;
            case 2://SecretNotFound
                document.getElementById("keyVaultError").innerHTML = "";
                document.getElementById("keyVault").style.border = "2px solid green";
                document.getElementById("secretError").innerHTML = "No secret was found with given name";
                document.getElementById("secretName").style.border = "2px solid red";
                toast.error("No secret was found with given name", { autoClose: 5000, position: "bottom-left" });
                document.querySelector('#checkBtn').style.border = "2px solid red";
                this.setState({ validateKeyVault: "false", showModal: "true" });

                break;
            case 3://NoValidSecretVersion
                document.getElementById("keyVaultError").innerHTML = "";
                document.getElementById("keyVault").style.border = "2px solid green";
                document.getElementById("secretError").innerHTML = "No valid version of the secret was found (secret has to be enabled, active and not expired)";
                document.getElementById("secretName").style.border = "2px solid red";
                toast.error("No valid version of the secret was found (secret has to be enabled, active and not expired)", { autoClose: 5000, position: "bottom-left" });
                document.querySelector('#checkBtn').style.border = "2px solid red";
                this.setState({ validateKeyVault: "false" });

                break;
            case 4://IncorrectSecretContentType
                document.getElementById("keyVaultError").innerHTML = "";
                document.getElementById("keyVault").style.border = "2px solid green";
                document.getElementById("secretError").innerHTML = "The content type of the secret is not correct, set the content type to 'application / octet - stream'";
                document.getElementById("secretName").style.border = "2px solid red";
                toast.error("The content type of the secret is not correct, set the content type to 'application / octet - stream'", { autoClose: 5000, position: "bottom-left" });
                document.querySelector('#checkBtn').style.border = "2px solid red";
                this.setState({ validateKeyVault: "false" });

                break;
            case 5://IncorrectKeySize
                document.getElementById("keyVaultError").innerHTML = "";
                document.getElementById("keyVault").style.border = "2px solid green";
                document.getElementById("secretError").innerHTML = "The size of the key is invalid (valid sizes are 128, 192, 256, 384 or 512 bits)";
                document.getElementById("secretName").style.border = "2px solid red";
                toast.error("The size of the key is invalid (valid sizes are 128, 192, 256, 384 or 512 bits)", { autoClose: 5000, position: "bottom-left" });
                document.querySelector('#checkBtn').style.border = "2px solid red";
                this.setState({ validateKeyVault: "false" });

                break;
            case 6: //ForbiddenOperation
                document.getElementById("keyVaultError").innerHTML = "";
                document.getElementById("secretError").innerHTML = "";
                document.getElementById("secretName").style.border = "";
                document.getElementById("keyVault").style.border = "";
                toast.error("Access Denied", { autoClose: 5000, position: "bottom-left" });
                document.querySelector('#checkBtn').style.border = "2px solid red";
                this.setState({ validateKeyVault: "false" });

                break;
            case 7: //UnknownError
                document.getElementById("keyVaultError").innerHTML = "";
                document.getElementById("secretError").innerHTML = "";
                document.getElementById("secretName").style.border = "";
                document.getElementById("keyVault").style.border = "";
                toast.error("Unknown Error", { autoClose: 5000, position: "bottom-left" });
                document.querySelector('#checkBtn').style.border = "2px solid red";
                this.setState({ validateKeyVault: "false" });

                break;
            case 8: //InvalidKeyContentEncoding
                document.getElementById("keyVaultError").innerHTML = "Invalid encoding for the key content";
                document.getElementById("keyVault").style.border = "2px solid red";
                toast.error("Invalid encoding for the key content", { autoClose: 5000, position: "top-left" });
                document.querySelector('#checkBtn').style.border = "2px solid red";
                this.setState({ validateKeyVault: "false" });

                break;


        }
        this.handleChange();

    }

    deleteTenant = async (apol) => {
        if (this.state.deletePassword != "3660") {
            this.setState({ deleteTenantText: "Deleting Tenant: wrong Password" });
        } else {
            this.setState({ deleteTenantText: "Deleting Tenant started ..." });

            var data = await apol
                .mutate({
                    mutation: deleteTenant,
                    variables: {
                        auth: this.props.authorization,
                        tenantId: this.props.data.tenantId
                    }
                });
            this.setState({ deleteTenant: false, deleteTenantText: "" });
        }
    }

    deleteTenantMetadata = async (apol) => {
        if (this.state.deletePassword != "3660") {
            this.setState({ deleteTenantMetadataText: "Deleting Tenant Metadata: wrong Password" });
        } else {
            this.setState({ deleteTenantMetadataText: "Deleting Tenant Metadata started ..." });

            var data = await apol
                .mutate({
                    mutation: deleteTenantMetadataQuery,
                    variables: {
                        auth: this.props.authorization,
                        tenantId: this.props.data.tenantId,
                        date: this.state.deleteTenantMetadataDate
                    }
                });
            this.setState({ deleteTenantMetadata: false, deleteTenantMetadataText: "" });
        }
    }

    insertMessage = async (apol) => {
        if (this.state.deletePassword != "") {
            this.setState({ insertMessageText: "Please input message" });
        } else {

            var data = await apol
                .mutate({
                    mutation: insertMessage,
                    variables: {
                        auth: this.props.authorization,
                        message: this.state.insertMessageContent
                    }
                });
            this.setState({ insertMessage: false, insertMessageText: "" });
        }
    }

    createAsymmetricKey = async (apol) => {
        this.setState({ isActivateAsymmetricKeyDisabled: true });

        var data = await apol
            .mutate({
                mutation: createAsymmetricKey,
                variables: {
                    tenantid: this.state.tenantId,
                    uploadedContent: this.state.uploadedContent,
                    vaultUrl: this.state.customEncryptionKeyVaultUrl,
                    keyName: this.state.customEncryptionKeySecretName,
                }
            });
        if (data.data.createAsymmetricKey) {
            this.setState({ asymmetricKeySuccess: true, asymmetricKeyButtonText: "keyActivationSuccessful" });

        } else {
            this.setState({ asymmetricKeySuccess: false, asymmetricKeyButtonText: "keyActivationUnsuccessful" });

        }
        this.setState({ isActivateAsymmetricKeyDisabled: false });

    }

    notifyTenantChange = async (apol) => {
        // Add or change User

        // Repair legacy settings with 0001 year
        if (this.state.onboardingDate.toDateString() === new Date("0001-01-01T00:00:00.0000000").toDateString())
            this.setState({ onboardingDate: new Date("1901-01-01T00:00:00") });
        if (this.state.trainingDate.toDateString() === new Date("0001-01-01T00:00:00.0000000").toDateString())
            this.setState({ trainingDate: new Date("1901-01-01T00:00:00") });
        if (this.state.billingDate.toDateString() === new Date("0001-01-01T00:00:00.0000000").toDateString())
            this.setState({ billingDate: new Date("1901-01-01T00:00:00") });
        let tmpExpireDays = this.state.expireDays;
        if (!this.state.willExpire) {
            tmpExpireDays = 0;
        }
        var tenant = {
            name: this.state.name,
            tenantId: this.state.tenantId,
            adminId: this.state.adminId,
            licenseComp: {
                type: {
                    id: "1",
                    number: "1",
                    text: "1",
                    name: "ComplianceRec"
                },
                bought: parseInt(this.state.license1),
                used: parseInt(this.state.license1used),
                id: this.state.license1id
            },
            licenseCompAna: {
                type: {
                    id: "2",
                    number: "2",
                    text: "2",
                    name: "ComplianceRec&Ana"
                },
                bought: parseInt(this.state.license2),
                used: parseInt(this.state.license2used),
                id: this.state.license2id
            },
            licenseSmart: {
                type: {
                    id: "3",
                    number: "3",
                    text: "3",
                    name: "SmartRec"
                },
                bought: parseInt(this.state.license3),
                used: parseInt(this.state.license3used),
                id: this.state.license3id
            },
            licenseSmartAna: {
                type: {
                    id: "4",
                    number: "4",
                    text: "4",
                    name: "SmartRecAna"
                },
                bought: parseInt(this.state.license4),
                used: parseInt(this.state.license4used),
                id: this.state.license4id
            },
            licenseAna: {
                type: {
                    id: "5",
                    number: "5",
                    text: "5",
                    name: "Ana"
                },
                bought: parseInt(this.state.license5),
                used: parseInt(this.state.license5used),
                id: this.state.license5id
            },
            licenseCompVoice: {
                type: {
                    id: "6",
                    number: "6",
                    text: "6",
                    name: "CompVoice"
                },
                bought: parseInt(this.state.license6),
                used: parseInt(this.state.license6used),
                id: this.state.license6id
            },
            licenseCompVoiceAna: {
                type: {
                    id: "7",
                    number: "7",
                    text: "7",
                    name: "CompVoiceAna"
                },
                bought: parseInt(this.state.license7),
                used: parseInt(this.state.license7used),
                id: this.state.license7id
            },
            licenseSmartVoice: {
                type: {
                    id: "8",
                    number: "8",
                    text: "8",
                    name: "SmartVoice"
                },
                bought: parseInt(this.state.license8),
                used: parseInt(this.state.license8used),
                id: this.state.license8id
            },
            licenseSmartVoiceAna: {
                type: {
                    id: "9",
                    number: "9",
                    text: "9",
                    name: "SmartVoiceAna"
                },
                bought: parseInt(this.state.license9),
                used: parseInt(this.state.license9used),
                id: this.state.license9id
            },
            licenseRecordingInsinghtsAI: {
                type: {
                    id: "10",
                    number: "10",
                    text: "10",
                    name: "LicenseRecordingInsinghtsAI"
                },
                bought: parseInt(this.state.license10),
                used: parseInt(this.state.license10used),
                id: this.state.license10id
            },
            storage: this.state.storage,
            packageStorage: parseInt(this.state.packageStorage),
            packageAnalyticsVideo: parseInt(this.state.packageAnalyticsVideo),
            packageAnalyticsSpeech: parseInt(this.state.packageAnalyticsSpeech),
            packageAnalyticsTranscript: parseInt(this.state.packageAnalyticsTranscript),
            packageAnalyticsTranslate: parseInt(this.state.packageAnalyticsTranslate),
            packageAnalyticsAI: parseInt(this.state.packageAnalyticsAI),
            packageExport: parseInt(this.state.packageExport),
            packageArchive: parseInt(this.state.packageArchive),
            addOnAnalytics: parseInt(this.state.addOnAnalytics),
            addOnFullChat: parseInt(this.state.addOnFullChat),
            addOnZoom: parseInt(this.state.addOnZoom),
            addOnGenesys: parseInt(this.state.addOnGenesys),
            addOnRingcentral: parseInt(this.state.addOnRingcentral),
            addOnDynamics: parseInt(this.state.addOnDynamics),
            addOnCompliance: parseInt(this.state.addOnCompliance),
            addOnQualityManagement: parseInt(this.state.addOnQualityManagement),
            addOnLeapXpert: parseInt(this.state.addOnLeapXpert),
            contractNumber: this.state.contractNumber,
            domainRestrictionsList: this.state.domainRestrictionsList,
            mailAlarming: this.state.mailAlarming,
            
            customStorage: this.state.customStorage,
            exportType: this.state.exportType,
            exportStorage: this.state.exportStorage,
            type: this.state.type,
            id: this.state.id,
            country: this.state.country,
            partner: this.state.partner,
            onboardingDate: this.state.onboardingDate === '' ? '1901-01-01' : this.state.onboardingDate.toUTCString(),
            billingDate: this.state.billingDate === '' ? '1901-01-01' : this.state.billingDate.toUTCString(),
            trainingDate: this.state.trainingDate === '' ? '1901-01-01' : this.state.trainingDate.toUTCString(),
            supportNotes: this.state.supportNotes,
            systemId: this.state.systemId,
            partnerContract: this.state.partnerContract,
            support: this.state.support,
            bot01: parseInt(this.state.bot01),
            bot02: parseInt(this.state.bot02),
            bot03: parseInt(this.state.bot03),
            bot04: parseInt(this.state.bot04),
            bot05: parseInt(this.state.bot05),
            bot06: parseInt(this.state.bot06),
            bot07: parseInt(this.state.bot07),
            bot08: parseInt(this.state.bot08),
            bot09: parseInt(this.state.bot09),
            bot10: parseInt(this.state.bot10),
            bot11: parseInt(this.state.bot11),
            bot12: parseInt(this.state.bot12),
            bot13: parseInt(this.state.bot13),
            bot14: parseInt(this.state.bot14),
            bot15: parseInt(this.state.bot15),
            bot16: parseInt(this.state.bot16),
            bot17: parseInt(this.state.bot17),
            bot18: parseInt(this.state.bot18),
            bot19: parseInt(this.state.bot19),
            bot20: parseInt(this.state.bot20),
            keyManagementType: this.state.keyManagementType,
            keyManagement: {
                rotationType: this.state.rotationType,
                intervalDays: parseInt(this.state.intervalDays),
                activationDelay: parseInt(this.state.activationDelay),
                willExpire: this.state.willExpire ? "Yes" : "No",
                expireDays: parseInt(tmpExpireDays),
                keySecurity: this.state.keySecurity,
                customEncryptionKeyVaultUrl: this.state.customEncryptionKeyVaultUrl,
                customEncryptionKeySecretName: this.state.customEncryptionKeySecretName,
                asymmetricKeyEnabled: this.state.asymmetricKeyEnabled,
                asymmetricKeySettings: {
                    keyType: this.state.keyType,
                    keySetting: this.state.keySetting,
                    asymmetricKeyFileName: this.state.asymmetricKeyFileName
                },
            },
            packageImport: this.state.packageImport,
            tenantSuspensionConvDelProcess: this.state.tenantSuspensionConvDelProcess,
            awsAccessKeyId: this.state.awsAccessKeyId,
            awsSecretAccess: this.state.awsSecretAccess,
            awsBucketName: this.state.awsBucketName,
            awsRegion: this.state.awsRegion,
            awsServiceUrl: this.state.awsServiceUrl,
            awsType: this.state.awsType,
            chatModelA: this.state.chatModelA
        };
        if (this.props.data != null) {
            var data = await apol
                .mutate({
                    mutation: mutationUpdateTenant,
                    refetchQueries: [{
                        query: queryGetTenantList,
                        variables: { auth: this.props.authorization },
                    }],
                    variables: {
                        auth: this.props.authorization,
                        tenant: tenant
                    }
                });
            if (data.data.updateTenant == null || data.data.updateTenant.name === "error") {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "changeTenantNotSuccessful")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "tenantChanged")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } else {
            data = await apol
                .mutate({
                    mutation: mutationCreateTenant,
                    refetchQueries: [{
                        query: queryGetTenantList,
                        variables: { auth: this.props.authorization },
                    }],
                    variables: {
                        auth: this.props.authorization,
                        tenant: tenant
                    }
                });
            if (data.data.createTenant == null || data.data.createTenant.name === "error") {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addTenantNotSuccessful")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "tenantAdded")}</>)}</MyContext.Consumer>, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    };

    openExportSettings = () => {
        return <ExportConfig cl={this._onDismissExportPanel} />
    }

    _onDismissExportPanel() {
        this.setState({ showExportPanel: false });
    }

    _onOpenExportPanel() {
        this.setState({ showExportPanel: true });
    }

    openImportSettings = () => {
        return <ImportConfig cl={this._onDismissImportPanel} />

    }
    _onDismissImportPanel() {
        this.setState({ showImportPanel: false });
    }
    _onOpenImportPanel() {
        this.setState({ showImportPanel: true });
    }
    openAudioStorageSettings = (audioStorage, storage) => {
        return <AudioStorageConfig tenantId={this.state.tenantId} apol={this.props.apol} data={audioStorage} cl={this._onDismissAudioStoragePanel} storageType={storage} />

    }
    _onDismissAudioStoragePanel() {
        this.setState({ showAudioStoragePanel: false });
    }
    _onOpenAudioStoragePanel() {
        this.setState({ showAudioStoragePanel: true });
    }
    openBotOnboardingSettings = () => {
        return <BotOnboardingConfig tenantId={this.state.tenantId} apol={this.props.apol} cl={this._onDismissBotOnboardingPanel} />
    }
    _onDismissBotOnboardingPanel() {
        this.setState({ showBotOnboardingPanel: false });
    }
    _onOpenBotOnboardingPanel() {
        this.setState({ showBotOnboardingPanel: true });
    }
    _closeModal = () => {
        this.setState({ showModal: false });
    }

    _openModal = () => {
        this.setState({ showModal: true });
    }
    checkKeyExpirationValidity = async (apol) => {
        this.setState({ TTLLoading: true });
        var data = await apol
            .mutate({
                mutation: checkMaxRetentionTimeSettings,
                variables: {
                    tenantId: this.state.tenantId
                }
            });

        this.setState({
            maxTtlFound: data.data.checkMaxRetentionTimeSettings
        });

        if (this.state.expireDays >= (data.data.checkMaxRetentionTimeSettings + this.state.intervalDays)) {
            this.setState({ keyExpirationConfirmed: true });
            document.getElementById("expirationDaysField").style.border = "1px solid green";
            document.querySelector('#keyExpirationBtn').style.border = "1px solid green";
        }
        else {
            document.getElementById("expirationDaysField").style.border = "1px solid orange";
            document.querySelector('#keyExpirationBtn').style.border = "1px solid orange";
            this.setState({ keyExpirationButtonMessage: true })

        }
        this.setState({ TTLLoading: false });

    }
}
function accessOption(rule) {
    let out = [];
    let tmp = [];
    for (let i = 0; i < rule.length; i++) {
        if ((rule[i].group === "access"))//filter the Rule Type
        {
            tmp.push(rule[i]);
        }
    }
    switch (tmp) {
        case 0: out.push({ key: "no Rule", text: "no Rule", disabled: true }); break;
        case 1: out.push({ key: tmp[0].id, text: tmp[0].name, disabled: true }); break;
        default:
            out.push({ key: 'Header', text: 'Access Rules', itemType: DropdownMenuItemType.Header });
            for (let i = 0; i < tmp.length; i++) {
                out.push({ key: tmp[i].id, text: tmp[i].name });
            }
            break;
    }
    return out;
}
function analyticsOption(rule) {
    let out = [];
    let tmp = [];
    for (let i = 0; i < rule.length; i++) {
        if ((rule[i].group === "analytics"))//filter the Rule Type
        {
            tmp.push(rule[i]);
        }
    }
    switch (tmp) {
        case 0: out.push({ key: "no Rule", text: "no Rule", disabled: true }); break;
        case 1: out.push({ key: tmp[0].id, text: tmp[0].name, disabled: true }); break;
        default:
            out.push({ key: 'Header', text: 'Analytic Rules', itemType: DropdownMenuItemType.Header });
            for (let i = 0; i < tmp.length; i++) {
                out.push({ key: tmp[i].id, text: tmp[i].name, title: tmp[i].usage });
            }
            break;
    }
    return out;
} function recordingOption(rule) {
    let out = [];
    let tmp = [];
    if (rule != null) {
        for (let i = 0; i < rule.length; i++) {
            if ((rule[i].group === "recording"))//filter the Rule Type
            {
                tmp.push(rule[i]);
            }
        }
    }
    switch (tmp) {
        case 0: out.push({ key: "no Rule", text: "no Rule", disabled: true }); break;
        case 1: out.push({ key: tmp[0].id, text: tmp[0].name, disabled: true }); break;
        default:
            out.push({ key: 'Header', text: 'Recording Rules', itemType: DropdownMenuItemType.Header });
            for (let i = 0; i < tmp.length; i++) {
                out.push({ key: tmp[i].id, text: tmp[i].name, title: tmp[i].usage });
            }
            break;
    }
    return out;
} function replayOption(rule) {
    let out = [];
    let tmp = [];
    for (let i = 0; i < rule.length; i++) {
        if ((rule[i].group === "replay"))//filter the Rule Type
        {
            tmp.push(rule[i]);
        }

    }
    switch (tmp) {
        case 0: out.push({ key: "no Rule", text: "no Rule", disabled: true }); break;
        case 1: out.push({ key: tmp[0].id, text: tmp[0].name, disabled: true }); break;
        default:
            out.push({ key: 'Header', text: 'Replay Rules', itemType: DropdownMenuItemType.Header });
            for (let i = 0; i < tmp.length; i++) {
                out.push({ key: tmp[i].id, text: tmp[i].name, title: tmp[i].usage });
            }
            break;
    }
    return out;
}