import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { HoverCard } from "office-ui-fabric-react/lib/index";
import { Text, Stack } from 'office-ui-fabric-react/lib';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { MyContext } from '../../../context';

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}


export default class GroupList extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: [];

    constructor(props) {
        super(props);


        let columns: IColumn[] = [
            {
                key: 'column10',
                name: 'Options',
                className: classNames.fileIconCell,
                iconClassName: classNames.fileIconHeaderIcon,
                ariaLabel: 'Column operations for File type, Press to sort on File type',
                isIconOnly: true,
                fieldName: 'name',
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <a style={{ cursor: "pointer" }} onClick={() => { this.props.func(item, 14) }}><Icon iconName={"Settings"} /></a>
                }
            }, {
                key: 'column2',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "name")}</>)}</MyContext.Consumer>,
                fieldName: 'name',
                minWidth: 80,
                maxWidth: 240,
                isResizable: true,
                data: 'number',
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    const expandingCardProps =
                    {
                        onRenderCompactCard: () => {
                            return (
                                <div>
                                    <div className="content-container">
                                        <div className="padding-default-b">
                                            <mgt-person show-name={true} user-id={item.userId} person-card="hover"></mgt-person>
                                        </div>
                                        <ul className="ul-flat">
                                            <li>Name: <a>{item.name}</a> </li>
                                        </ul>
                                        <ul className="ul-flat">
                                            <li>Id: <a>{item.adGroupId}</a> </li>
                                        </ul>

                                    </div>
                                </div>
                            );
                        },
                        onRenderExpandedCard: () => {
                            var nameRecordingRules = "";
                            var nameReplayRules = "";
                            var nameAccessRules = "";
                            var nameAnalyticsRules = "";
                            this.props.ruleData.forEach(function (aRule) {
                                if (aRule.id === item.analytics) {
                                    nameAnalyticsRules = aRule.name;
                                } else if (aRule.id === item.access) {
                                    nameAccessRules = aRule.name;
                                } else if (aRule.id === item.replay) {
                                    nameReplayRules = aRule.name;
                                } else if (aRule.id === item.recordingRules) {
                                    nameRecordingRules = aRule.name;
                                }
                            });

                            return (
                                <div className="padding-default-b">
                                    <Stack styles={{ paddingLeft: 10 }}>
                                        <Stack.Item styles={{ root: { paddingLeft: "10px" } }}>
                                            <Text>Assigned Rules</Text>
                                            <ul>
                                                <li> Recording Rules: {nameRecordingRules}</li>
                                                <li> Replay Rules: {nameReplayRules}</li>
                                                <li> Access Rules: {nameAccessRules}</li>
                                                <li> Analytics Rules: {nameAnalyticsRules}</li>
                                            </ul>
                                        </Stack.Item>
                                    </Stack>
                                </div>
                            );
                        },
                        renderData: item
                    };

                    return <HoverCard expandingCardProps={expandingCardProps} instantOpenOnClick={true}>
                        <a style={{ cursor: "pointer" }}>{item.name}</a>
                    </HoverCard>;
                }
            }, {
                key: 'column3',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "priority")}</>)}</MyContext.Consumer>,
                fieldName: 'priority',
                minWidth: 90,
                maxWidth: 240,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.priority}</span>;
                }
            },
            {
                key: 'column4',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "license")}</>)}</MyContext.Consumer>,
                fieldName: 'license',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    switch (item.license) {
                        case "6":
                            return (<span>Complicance Voice</span>)
                        case "7":
                            return (<span>Complicance Voice & Analytics</span>)
                        case "1":
                            return (<span>Complicance UC Recording</span>)
                        case "2":
                            return (<span>Complicance UC Recording & Analytics</span>)
                        case "8":
                            return (<span>Smart Voice</span>)
                        case "9":
                            return (<span>Smart Voice & Analytics</span>)
                        case "3":
                            return (<span>Smart UC Recording</span>)
                        case "4":
                            return (<span>Smart UC Recording & Analytics</span>)
                        case "5":
                            return (<span>Chat Recording Standalone</span>)
                        case "10":
                            return (<span>Recording Insights AI</span>)
                    }
                }
            },
            {
                key: 'column12',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "regionalStorage")}</>)}</MyContext.Consumer>,
                fieldName: 'regionalStorageLocation',
                minWidth: 120,
                maxWidth: 240,
                isResizable: true,
                data: 'text',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    switch (item.regionalStorageLocation) {
                        case "Unknown":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "unknownRegional")}</>)}</MyContext.Consumer>)
                        case "Emea":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "emeagrs")}</>)}</MyContext.Consumer>)
                        case "UkSouth":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "uksouthgrs")}</>)}</MyContext.Consumer>)
                        case "Apac":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "apacgrs")}</>)}</MyContext.Consumer>)
                        case "Americas":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "americasgrs")}</>)}</MyContext.Consumer>)
                        case "Brazil":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "brazilgrs")}</>)}</MyContext.Consumer>)
                        case "Canada":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "canadagrs")}</>)}</MyContext.Consumer>)
                        case "Japan":
                            return (<span>Japan GRS</span>)
                        case "LrsEmea":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "emealrs")}</>)}</MyContext.Consumer>)
                        case "LrsUkSouth":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "uksouthlrs")}</>)}</MyContext.Consumer>)
                        case "LrsApac":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "apaclrs")}</>)}</MyContext.Consumer>)
                        case "LrsAmericas":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "americaslrs")}</>)}</MyContext.Consumer>)
                        case "LrsBrazil":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "brazillrs")}</>)}</MyContext.Consumer>)
                        case "LrsCanada":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "canadalrs")}</>)}</MyContext.Consumer>)
                        case "LrsJapan":
                            return (<span>Japan LRS</span>)
                        case "GrsSwiss":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "swissgrs")}</>)}</MyContext.Consumer>)
                        case "CustomStorage":
                            return (item.regionalStorageCustomLocation)
                        case "LrsAustralia":
                            return (<span>Australia LRS</span>)
                        case "Australia":
                            return (<span>Australia GRS</span>)
                        case "LrsApacSe":
                            return (<span>APAC Southeast LRS</span>)
                        case "ApacSe":
                            return (<span>APAC Southeast GRS</span>)
                        case "LrsSwitzerland":
                            return (<span>Switzerland LRS</span>)
                        case "LrsUAENorth":
                            return (<span>UAE North LRS</span>)
                        case "S3Storage":
                            return (<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "S3Storage")}</>)}</MyContext.Consumer>)
                    }
                }
            },
            {
                key: 'column6',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "recordingRule")}</>)}</MyContext.Consumer>,
                fieldName: 'recordingRules',
                minWidth: 120,
                maxWidth: 240,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    var name = item.recordingRules;
                    this.props.ruleData.forEach(function (aRule) {
                        if (aRule.id === item.recordingRules) {
                            name = aRule.name;
                        };
                    });

                    return <span>{name}</span>
                }
            },
            {
                key: 'column7',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "replayRule")}</>)}</MyContext.Consumer>,
                fieldName: 'replay',
                minWidth: 120,
                maxWidth: 240,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    var name = item.replay;
                    this.props.ruleData.forEach(function (aRule) {
                        if (aRule.id === item.replay) {
                            name = aRule.name;
                        };
                    });
                    return <span>{name}</span>;
                }
            },
            {
                key: 'column8',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "acccessRule")}</>)}</MyContext.Consumer>,
                fieldName: 'access',
                minWidth: 120,
                maxWidth: 240,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    var name = item.access;
                    this.props.ruleData.forEach(function (aRule) {
                        if (aRule.id === item.access) {
                            name = aRule.name;
                        };
                    });
                    return <span>{name}</span>;
                }
            },
            {
                key: 'column9',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "analyticsRules")}</>)}</MyContext.Consumer>,
                fieldName: 'analytics',
                minWidth: 120,
                maxWidth: 240,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    var name = item.analytics;
                    this.props.ruleData.forEach(function (aRule) {
                        if (aRule.id === item.analytics) {
                            name = aRule.name;
                        };
                    });
                    return <span>{name}</span>;
                }
            }
        ];
        if (this.props.tenantData.addOnAnalytics) {
            columns.push(
                {
                    key: 'column10',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnAnalytics")}</>)}</MyContext.Consumer>,
                    fieldName: 'addOnAnalytics',
                    minWidth: 120,
                    maxWidth: 240,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    onRender: (item) => {
                        return <span>{item.addOnAnalytics ? "true" : "false"}</span>;
                    }
                });
        }
        if (this.props.tenantData.addOnFullChat) {
            columns.push(
                {
                    key: 'column11',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnFullChat")}</>)}</MyContext.Consumer>,
                    fieldName: 'addOnFullChat',
                    minWidth: 120,
                    maxWidth: 240,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    onRender: (item) => {
                        return <span>{item.addOnFullChat ? "true" : "false"}</span>;
                    }
                });
        }
        if (this.props.tenantData.addOnZoom) {
            columns.push(
                {
                    key: 'column12',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnZoom")}</>)}</MyContext.Consumer>,
                    fieldName: 'addOnZoom',
                    minWidth: 120,
                    maxWidth: 240,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    onRender: (item) => {
                        return <span>{item.addOnZoom ? "true" : "false"}</span>;
                    }
                });
        }
        if (this.props.tenantData.addOnGenesys) {
            columns.push(
                {
                    key: 'column13',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnGenesys")}</>)}</MyContext.Consumer>,
                    fieldName: 'addOnGenesys',
                    minWidth: 120,
                    maxWidth: 240,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    onRender: (item) => {
                        return <span>{item.addOnGenesys ? "true" : "false"}</span>;
                    }
                });
        }
        if (this.props.tenantData.addOnRingcentral) {
            columns.push(
                {
                    key: 'column14',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnRingcentral")}</>)}</MyContext.Consumer>,
                    fieldName: 'addOnRingcentral',
                    minWidth: 120,
                    maxWidth: 240,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    onRender: (item) => {
                        return <span>{item.addOnRingcentral ? "true" : "false"}</span>;
                    }
                });
        }
        if (this.props.tenantData.addOnDynamics) {
            columns.push(
                {
                    key: 'column15',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnDynamics")}</>)}</MyContext.Consumer>,
                    fieldName: 'addOnDynamics',
                    minWidth: 120,
                    maxWidth: 240,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    onRender: (item) => {
                        return <span>{item.addOnDynamics ? "true" : "false"}</span>;
                    }
                });
        }
        if (this.props.tenantData.addOnCompliance) {
            columns.push(
                {
                    key: 'column16',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnCompliance")}</>)}</MyContext.Consumer>,
                    fieldName: 'addOnCompliance',
                    minWidth: 120,
                    maxWidth: 240,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    onRender: (item) => {
                        return <span>{item.addOnCompliance ? "true" : "false"}</span>;
                    }
                });
        }
        if (this.props.tenantData.addOnQualityManagement) {
            columns.push(
                {
                    key: 'addOnQualityManagement',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnQualityManagement")}</>)}</MyContext.Consumer>,
                    fieldName: 'addOnQualityManagement',
                    minWidth: 120,
                    maxWidth: 240,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    onRender: (item) => {
                        return <span>{item.addOnQualityManagement ? "true" : "false"}</span>;
                    }
                });
        }
        if (this.props.tenantData.addOnLeapXpert) {
            columns.push(
                {
                    key: 'column17',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnLeapXpert")}</>)}</MyContext.Consumer>,
                    fieldName: 'addOnLeapXpert',
                    minWidth: 120,
                    maxWidth: 240,
                    isResizable: true,
                    data: 'number',
                    onColumnClick: this._onColumnClick,
                    onRender: (item) => {
                        return <span>{item.addOnLeapXpert ? "true" : "false"}</span>;
                    }
                });
        }

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: true,
            items: this._allItems,
            filter: ""
        };

    }

    // Data for CommandBar
    getItems = () => {


        let items = [];
        if (this.props.tenantData.licenseAna.bought) {
            items.push(
                {
                    key: 'Analytics',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "chatRecordingStandalone")} ({this.props.tenantData.licenseAna.used}/{this.props.tenantData.licenseAna.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'Upload'
                    },
                    onClick: () => { this.props.func(null, 9) }
                }
            );
        }
        if (this.props.tenantData.licenseSmartVoice.bought) {
            items.push(
                {
                    key: 'SmartVoice',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "smartVoice")} ({this.props.tenantData.licenseSmartVoice.used}/{this.props.tenantData.licenseSmartVoice.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'Microphone'
                    },
                    onClick: () => { this.props.func(null, 21) }
                }
            );
        }
        if (this.props.tenantData.licenseSmartVoiceAna.bought) {
            items.push(
                {
                    key: 'SmartVoiceAnalytics',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "smartVoiceAnalytics")}({this.props.tenantData.licenseSmartVoiceAna.used}/{this.props.tenantData.licenseSmartVoiceAna.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'AnalyticsView'
                    },
                    onClick: () => { this.props.func(null, 22) }
                }
            );
        }
        if (this.props.tenantData.licenseSmart.bought) {
            items.push(
                {
                    key: 'SmartRecording',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "smartRecording")}({this.props.tenantData.licenseSmart.used}/{this.props.tenantData.licenseSmart.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'Microphone'
                    },
                    onClick: () => { this.props.func(null, 10) }
                }
            );
        }
        if (this.props.tenantData.licenseSmartAna.bought) {
            items.push(
                {
                    key: 'SmartRecordingAnalytics',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "smartRecordingAnalytics")}({this.props.tenantData.licenseSmartAna.used}/{this.props.tenantData.licenseSmartAna.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'AnalyticsView'
                    },
                    onClick: () => { this.props.func(null, 11) }
                }
            );
        }
        if (this.props.tenantData.licenseCompVoice.bought) {
            items.push(
                {
                    key: 'ComplianceVoice',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "complianceVoiceRecording")}({this.props.tenantData.licenseCompVoice.used}/{this.props.tenantData.licenseCompVoice.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'Microphone'
                    },
                    onClick: () => { this.props.func(null, 19) }
                }
            );
        }
        if (this.props.tenantData.licenseCompVoiceAna.bought) {
            items.push(
                {
                    key: 'ComplianceVoiceAna',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "complianceVoiceAnalytics")}({this.props.tenantData.licenseCompVoiceAna.used}/{this.props.tenantData.licenseCompVoiceAna.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'AnalyticsView'
                    },
                    onClick: () => { this.props.func(null, 20) }
                }
            );
        }
        if (this.props.tenantData.licenseComp.bought) {
            items.push(
                {
                    key: 'ComplianceUC',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "complianceRecording")}({this.props.tenantData.licenseComp.used}/{this.props.tenantData.licenseComp.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'Camera'
                    },
                    onClick: () => { this.props.func(null, 12) }
                }
            );
        }
        if (this.props.tenantData.licenseCompAna.bought) {
            items.push(
                {
                    key: 'ComplianceUCAnalytics',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "complianceRecordingAnalytics")}({this.props.tenantData.licenseCompAna.used}/{this.props.tenantData.licenseCompAna.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'AnalyticsView'
                    },
                    onClick: () => { this.props.func(null, 13) }
                }
            );
        }
        if (this.props.tenantData.licenseRecordingInsinghtsAI != null && this.props.tenantData.licenseRecordingInsinghtsAI.bought) {
            items.push(
                {
                    key: 'LicenseRecordingInsinghtsAI',
                    name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "licenseRecordingInsinghtsAI")}({this.props.tenantData.licenseRecordingInsinghtsAI.used}/{this.props.tenantData.licenseRecordingInsinghtsAI.bought})</>)}</MyContext.Consumer>,
                    iconProps: {
                        iconName: 'AnalyticsView'
                    },
                    onClick: () => { this.props.func(null, 28) }
                }
            );
        }

        let addButton = {
            key: 'add',
            name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addGroup")}</>)}</MyContext.Consumer>,
            iconProps: { iconName: 'Add' },
            subMenuProps: {
                items: items
            }

        }

        let searchField = {
            key: 'text',
            name: 'text',
            onClick: () => console.log('Rename'),
            onRender: () => { return <div style={{ padding: "4px" }} > <TextField onChange={this._onChangeText} /> </div> },
            iconProps: {
                iconName: 'Edit'
            }
        };

        if (this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.confAddUser) {
            return [addButton, searchField];
        } else {
            return [searchField];
        }
    };

    render() {
        const { columns, isModalSelection } = this.state;
        this.ruleData = this.props.ruleData;
        this.userData = this._generateDocuments(this.props.data, this.state.filter);
        return (
            <Fabric>
                <div className={classNames.controlWrapper}>
                </div>
                <CommandBar
                    items={this.getItems()}
                    ariaLabel={'Use left and right arrow keys to navigate between commands'}
                />


                <MarqueeSelection selection={this._selection}>
                    <DetailsList
                        items={this.userData}
                        compact={false}
                        columns={columns}
                        selectionMode={isModalSelection ? SelectionMode.multiple : SelectionMode.none}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={this._onItemInvoked}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    />
                </MarqueeSelection>
            </Fabric>
        );
    }

    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isCompactMode: checked });
    };

    _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isModalSelection: checked });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.userData = this.userData.filter(i => i.name.includes(text) || i.name.toLocaleLowerCase().includes(text));
        this.setState({ filter: text });
    };

    openMultySelect = () => {
        console.log(this._selection.getSelection())
        if (this._selection.getSelection().length > 0) {
            this.props.func(this._selection.getSelection(), 0);
        }
    };

    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                return '';
            default:
                return `${selectionCount} Items selected`;
        }
    }

    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        this.setState({
            columns: newColumns,
        });
    };

    _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    };

    _generateDocuments(DataArr, text) {
        // First filter
        let DataArrFiltered = DataArr.filter(i => i.name == null || (i.name.includes(text) || i.name.toLocaleLowerCase().includes(text)));
        // Then sort
        let newColumns = this.state.columns;
        newColumns.forEach((newCol: IColumn) => {
            if (newCol.isSorted) {
                DataArrFiltered = this._copyAndSort(DataArrFiltered, newCol.fieldName, newCol.isSortedDescending);
            }
        });
        return DataArrFiltered;
    };
}