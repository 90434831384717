import React, { Component } from 'react';
import { ASCHeader } from "../ASCHeader/ASCHeader";
import ASCFooter2 from "../ASCFooter/ASCFooter2";
import { Text } from 'office-ui-fabric-react';

import {
    // Checkbox,
    // DefaultButton,
    // PrimaryButton,
    Stack,
} from "office-ui-fabric-react";
import { MyContext } from '../../context';


// Styles definition
const stackContainerStyles: IStackStyles = {
    root: {
        paddingTop: 50
    }
};


const stackStyles: IStackStyles = {
    root: {
        maxWidth: 660,
        marginBottom: 50
    }
};

// Tokens definition
const stackTokens: IStackTokens = { childrenGap: 5 };


export default class BotRegistration extends Component {

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <>

                        <ASCHeader title={context.getTranslation("botRegistration","botRegistration")} />

                        <Stack tokens={stackTokens}>
                            <Stack horizontalAlign="center" verticalAlign="center" styles={stackContainerStyles}>
                                <Stack.Item grow={10} styles={stackStyles}>
                                    <Text variant="xxLarge"></Text>
                                </Stack.Item>
                                <Stack.Item grow={10} styles={stackStyles}>
                                    <Text variant="xxLarge"></Text>
                                </Stack.Item>
                                <Stack.Item grow={10} styles={stackStyles}>
                                    <Text variant="xxLarge">{context.getTranslation("botRegistration", "registrationSuccessfull")}</Text>
                                </Stack.Item>
                                <Stack.Item grow={10} styles={stackStyles}>
                                    <Text variant="xxLarge"></Text>
                                </Stack.Item>
                                <Stack.Item grow={10} styles={stackStyles}>
                                    <Text variant="xxLarge"></Text>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                        <ASCFooter2 />
                    </>
                )
                }
            </MyContext.Consumer>
        );
    }

}
