import React from 'react'
import Chart, {
    ArgumentAxis,
    Legend, Size
} from 'devextreme-react/chart';
import { generateElement } from "./FactoryUtil";
import WorldMap from "./WorldMap/WorldMap";
import PieChartDex from "./PieChart/PieChart";
import SplineChart from "./SplineChart/SplineChart";
import Doughnut from "./Doughnut/Doughnut";
import StackedBar from "./StackedBar/StackedBar";
import StackedBarCompare from "./StackedBar/StackedBarCompare";
import StackedBarLoss from "./StackedBar/StackedBarLoss";
import TreeMaping from "./TreeMap/TreeMap";
import FunnelChart from "./FunelChart/FunnelChart";
import BubbleChart from "./BubbelChart/BubbleChart";
import { CommonSeriesSettings, Series } from "devextreme-react/chart";
import { Icon } from 'office-ui-fabric-react/lib';

const colorSet = {
    a: "#71A2D2",
    b: "#90BF38",
    c: "#6264A7",
    d: "#DF6F15",
    e: "#b47d49"
};

const palette = [colorSet.d, colorSet.b, colorSet.c, colorSet.a, colorSet.e];
const source = [
    { value: 'inbound', name: 'Inbound' },
    { value: 'outbound', name: 'Outbound' },
    { value: 'meeting', name: 'Meeting' },
    { value: 'intern', name: 'Internal' },
    { value: 'chat', name: 'Chat' },
    { value: 'upload', name: 'Upload' },
    { value: 'SMS', name: 'SMS' },
    //{ value: 'unknown', name: 'Other' },
];
const sourceHit = [
    { value: 'hit', name: 'Hit' },
    { value: 'noHit', name: 'No hit' }
];
const sourceCompare = [
    { value: 'total', name: 'Total' },
    { value: 'recorded', name: 'Recorded' },
    { value: 'no', name: 'Never Record' },
    { value: 'noStart', name: 'NoStart' },
    { value: 'callTry', name: 'CallTry' },
    { value: 'error', name: 'Error' }
];

const sourceLoss = [
    { value: 'totalLoss', name: 'Loss' },
    { value: 'onlyTranscript', name: 'Transcript' },
    { value: 'lessDuration', name: 'Duration' },
    { value: 'packetLoss', name: 'Packet' },
    { value: 'onlySilence', name: 'Silence' },
];

const sourceTotal = [
    { value: 'inbound', name: 'Total' },

];

class HealthCheck extends React.Component {
    render() {
        return (
            generateElement(this.props.title,
                <div style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    color: this.props.color,
                    fontSize: 30,
                }
                }>
                    <Icon iconName={"Robot"} />{this.props.val}
                    <br />
                    <Icon iconName={"CloudImportExport"} />000
                    <br />
                    <br />

                </div>)
        );
    }
}

class SimpleCounter extends React.Component {

    render() {
        return (
            generateElement(this.props.title,
                <div>
                    <div style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                        color: this.props.color,
                        fontSize: 60,

                    }
                    }>
                        {this.props.val}
                    </div>
                    <div style={{ fonstSize: 10 }}><br /></div>
                </div>
            )
        );
    }
}


class SimpleBarExChart extends React.Component {

    render() {
        let w, h;

        if (this.props.size.width === -1) {
            w = window.innerWidth * 0.9;
            h = window.innerHeight * 0.9;
        } else {

            h = this.props.size.height;
            w = this.props.size.width;

        }
        let getOneSerie = (this.props.kpi !== "PolicyCompared" && this.props.kpi !== "CategoryCompared")

        let getMoreSeries = (this.props.kpi === "PolicyCompared" || this.props.kpi === "CategoryCompared")

        return (
            generateElement(this.props.title,
                <div style={{
                    width: w,
                    height: h,
                    margin: { top: 5, right: 30, left: 20, bottom: 5 },
                    textAlign: "center",
                    fontSize: "100pt"
                }
                }>
                    <Chart dataSource={this.props.data}
                        palette={this.props.palette}
                        ref={this.props.reference}
                        height={h - 55}
                        width={w - 55}
                        rotated={this.props.type == "user" ? true : false}
                    >

                        <Size
                            height={h - 55}
                        />
                        <ArgumentAxis tickInterval={10} />
                        <CommonSeriesSettings
                            type="bar"
                            argumentField="args"
                            //valueField="value"
                            ignoreEmptyPoints={true}
                        />
                        {getOneSerie &&
                            <Series
                                argumentField="args"
                                valueField="value"
                                name="value"
                            />
                        }
                        {getMoreSeries &&
                            <Series
                                argumentField="args"
                                valueField="hit"
                                name="Hit"
                            />
                        }
                        {getMoreSeries &&
                            <Series
                                argumentField="args"
                                valueField="noHit"
                                name="No hit"
                            />
                        }
                        <Legend visible={getMoreSeries} />
                    </Chart>
                </div>
            )
        );
    }
}

class WorldMapExChart extends React.Component {

    render() {
        let w, h;

        if (this.props.size.width === -1) {
            w = window.innerWidth * 0.9;
            h = window.innerHeight * 0.9;
        } else {

            h = this.props.size.height;
            w = this.props.size.width;

        }
        return (
            generateElement(this.props.title,
                <div
                    ref={this.props.reference}
                >
                       <WorldMap
                           size={{width:w, height:h}}
                                 reference={this.props.reference}
                       />

                </div>
            )
        );
    }
}

class PiExChart extends React.Component {

    render() {
        let w, h;

        if (this.props.size.width === -1) {
            w = window.innerWidth * 0.9;
            h = window.innerHeight * 0.9;
        } else {

            h = this.props.size.height;
            w = this.props.size.width;

        }
        return (
            generateElement(this.props.title,

                <PieChartDex
                    kpi={this.props.kpi}
                    palette={this.props.palette}
                    reference={this.props.reference}
                    data={this.props.data}
                    size={{
                        width: w,
                        height: h
                    }} />
            )
        );
    }
}

// Important Widgets

class SplineExChart extends React.Component {

    render() {
        let w, h;

        if (this.props.size.width === -1) {
            w = window.innerWidth * 0.9;
            h = window.innerHeight * 0.9;
        } else {

            h = this.props.size.height;
            w = this.props.size.width;

        }
        return (
            generateElement(this.props.title,

                <SplineChart size={{
                    width: w,
                    height: h
                }}
                    palette={this.props.palette}
                    reference={this.props.reference}
                    source={this.props.source}
                    data={this.props.data}
                />
            )
        );
    }
}

class  DoughnutExChart extends React.Component{

    render () {
        let w,h;

        if (this.props.size.width === -1) {
            w = window.innerWidth * 0.9;
            h = window.innerHeight * 0.9;
        } else {

            h = this.props.size.height;
            w = this.props.size.width;

        }
        return (
            generateElement(this.props.title,

                <Doughnut size={{
                    width: w,
                    height: h
                }}
                    reference={this.props.reference}
                    palette={this.props.palette}
                    data={this.props.data}
                    keyword={this.props.keyword}
                />
            )
        );
    }
}

class StackedBarExChart extends React.Component {

    render() {
        let w, h;

        if (this.props.size.width === -1) {
            w = window.innerWidth * 0.9;
            h = window.innerHeight * 0.9;
        } else {

            h = this.props.size.height;
            w = this.props.size.width;

        }
        switch (this.props.kpi) {
            case "GCTA":
            case "CTA":
            case "COA":
            case "CIA":
            case "CMA":
            case "CEAA":
                return (
                    generateElement(this.props.title,

                        <StackedBarCompare size={{
                            width: w,
                            height: h
                        }}
                            palette={this.props.palette}
                            reference={this.props.reference}
                            data={this.props.data}
                        />
                    ));
            case "GCNR":
            case "CNR":
                return (
                    generateElement(this.props.title,

                        <StackedBarLoss size={{
                            width: w,
                            height: h
                        }}
                            palette={this.props.palette}
                            reference={this.props.reference}
                            data={this.props.data}
                        />
                    )); default:
                return (
                    generateElement(this.props.title,

                        <StackedBar size={{
                            width: w,
                            height: h
                        }}
                            palette={this.props.palette}
                            reference={this.props.reference}
                            data={this.props.data}
                            convType={this.props.convType}
                        />
                    ));
        }
    }
}

class TreeMapExChart extends React.Component {

    render() {
        let w, h;

        if (this.props.size.width === -1) {
            w = window.innerWidth * 0.9;
            h = window.innerHeight * 0.9;
        } else {

            h = this.props.size.height;
            w = this.props.size.width;

        }
        return (
            generateElement(this.props.title,

                <TreeMaping size={{
                    width: w,
                    height: h
                }}
                    palette={this.props.palette}
                    reference={this.props.reference}
                    data={this.props.data}
                />
            )
        );
    }
}

class FunnelExChart extends React.Component {

    render() {
        let w, h;

        if (this.props.size.width === -1) {
            w = window.innerWidth * 0.9;
            h = window.innerHeight * 0.9;
        } else {

            h = this.props.size.height;
            w = this.props.size.width;

        }
        return (
            generateElement(this.props.title,

                <FunnelChart size={{
                    width: w,
                    height: h
                }}
                    palette={this.props.palette}
                    reference={this.props.reference}
                    data={this.props.data}
                />
            )
        );
    }
}

class BubbleExChart extends React.Component {

    render() {
        let w, h;

        if (this.props.size.width === -1) {
            w = window.innerWidth * 0.9;
            h = window.innerHeight * 0.9;
        } else {

            h = this.props.size.height;
            w = this.props.size.width;

        }
        return (
            generateElement(this.props.title,

                <BubbleChart size={{
                    width: w,
                    height: h
                }}
                    palette={this.props.palette}
                    reference={this.props.reference}
                    data={this.props.data}
                />
            )
        );
    }
}





export {
    SimpleBarExChart,
    WorldMapExChart,
    PiExChart,
    SplineExChart,
    DoughnutExChart,
    StackedBarExChart,
    TreeMapExChart,
    FunnelExChart,
    BubbleExChart,
    SimpleCounter,
    palette,
    colorSet,
    source,
    sourceCompare,
    sourceLoss,
    sourceTotal,
    sourceHit,
    HealthCheck
}