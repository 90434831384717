import React, { Component } from 'react';
import './DashboardView.scss';
import { ApolloProvider, Query } from 'react-apollo';

import gql from 'graphql-tag';
import {
    PrimaryButton, CommandBarButton,
} from 'office-ui-fabric-react/lib/Button';
import { FontSizes, ComboBox } from 'office-ui-fabric-react/lib';
import { TooltipHost, TooltipDelay } from 'office-ui-fabric-react/lib/Tooltip';
import { Stack, Spinner, Toggle } from 'office-ui-fabric-react';
import { Mutation } from '@apollo/react-components';
import DNDBoard from './DNDBoard';
import ReportConfiguration from '../ReportView/ReportConfiguration/ReportConfiguration';
import DashboardConfiguration from '../ReportView/ReportConfiguration/DashboardConfiguration';
import Utils from '../common/Utils';
import { MyContext } from '../../context';



export default class ReportView extends Component {
    constructor(props) {
        super(props);
        this.reportConf = React.createRef();
        this.dashboardConf = React.createRef();
        this.dashboardConfChange = React.createRef();
        this.state = {
            helpPanel: false,
            editMode: false,
            query: 0,
            isEdit: false,
            dashboardItem: { key: "", text: "Dashboard User", users: "", user: this.props.userRules.userId, isOwner: false },
            type: ""
        };
        this.data = null;
    }


    onNewReport = (ev) => {
        if (this.reportConf.current) {
            this.reportConf.current.state.isNew = true;
            this.reportConf.current.showPanel();
        }
    }

    onNewReportModal = (ev) => {
        if (this.reportConf.current) {
            this.reportConf.current.state.isNew = true;
            this.reportConf.current.showModal();
        }
    }

    onNewDashboard = (ev) => {
        if (this.dashboardConf.current) {
            this.dashboardConf.current.state.isNew = true;
            this.dashboardConf.current.showPanel();
        }
    }

    onNewDashboardModal = (ev) => {
        if (this.dashboardConf.current) {
            this.dashboardConf.current.state.isNew = true;
            this.dashboardConf.current.showModal();
        }
    }

    onChangeDashboardModal = (ev) => {
        if (this.dashboardConfChange.current) {
            this.dashboardConfChange.current.state.changedTitle = this.state.dashboardItem.text;
            let users = this.state.dashboardItem.users == "" ? [] : this.state.dashboardItem.users.split(',').map(u => { return { userId: u } })
            this.dashboardConfChange.current.state.dashboardUsers = users;
            this.dashboardConfChange.current.state.isNew = true;
            this.dashboardConfChange.current.showModal();
        }
    }

    changeMode = () => {
        this.setState({ editMode: !this.state.editMode });
    }

    render() {
        // toast.success(""+this.props.user)
        return (
            <div className="Out">
                <ApolloProvider client={this.props.apol}>
                    {this.queryDashboard(this.props.authorization, this.props.apol, this.state.dashboardItem.key)}
                </ApolloProvider>
                <br />
            </div>
        );
    }

    renderHeader = (reload) => (<MyContext.Consumer>
        {(context) => (
            <header className={window.location.pathname.includes("main") ? "dashboard-header-main" : "dashboard-header"}>
                <ReportConfiguration
                    reloadFunc={reload}
                    ref={this.reportConf}
                    id={Utils.guidGenerator()}
                    title=""
                    text=""
                    icon=""
                    type=""
                    cycle=""
                    keywords=""
                    startDate=""
                    endDate=""
                    sortOrder={0}
                    dashboardId={this.state.dashboardItem.key}
                    kpi=""
                    tForm="cycle"
                    isNew
                    authorization={this.props.authorization}
                    apol={this.props.apol}
                    userRules={this.props.userRules}
                    theme={this.props.theme}
                    conversationType=""
                />


                <Stack horizontal>
                    {this.props.userRules.ruleAccess.userReporting != "No" && <TooltipHost
                        content={context.getTranslation("dashboard", "createNewReport")}
                        hidden={Utils.lsGet('helpMode') === 'false'}
                        calloutProps={{ gapSpace: 0 }}
                        delay={TooltipDelay.long}
                    >
                        <CommandBarButton
                            iconProps={{ iconName: 'Add' }}
                            text={context.getTranslation("dashboard", "createReport")}
                            style={{ padding: '7px', cursor: 'pointer', fontSize: FontSizes.Icon }}
                            onClick={this.onNewReportModal}
                        />
                    </TooltipHost>}

                    {this.queryGetAvailableDashboards(this.props.authorization, this.props.apol, "")}

                    <Stack.Item grow={3}><div /></Stack.Item>
                    <Stack horizontal>
                        <Stack.Item styles={{ root: { paddingRight: 10 } }}>
                            {this.renderSaveButton()}
                        </Stack.Item>
                        <Stack.Item>
                            <Toggle
                                label={context.getTranslation("dashboard", "editMode")}
                                checked={this.state.editMode}
                                onChange={(event, val) => {
                                    this.setState({ editMode: val, isEdit: val });
                                }}
                                onText={context.getTranslation("dashboard", "on")}
                                offText={context.getTranslation("dashboard", "off")}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </header>
        )
        }
    </MyContext.Consumer>
    )

    renderSaveButton = () => (
        <MyContext.Consumer>
            {(context) => (
                this.state.isEdit ? (
                    <Mutation
                        mutation={gql` 
       mutation mut($input:[ReportPositionInput]){
        updateReportTable(input:$input)     
      }`}
                    >
                        {
                            (run) => (
                                <PrimaryButton
                                    text={context.getTranslation("common", "save")}
                                    onClick={
                                        () => {
                                            this.setState({ isEdit: false, editMode: false });
                                            run({ variables: { input: this.state.layout } });
                                        }
                                    }
                                />
                            )
                        }
                    </Mutation>

                ) : <></>)
            }
        </MyContext.Consumer>)

    queryDashboard = (input, apol, dashboardId) => (

        <Query
            key={`query:${this.state.query}`}
            query={gql`
        query ent ($input : AuthInput, $dashboardId: String!) {
          getReports (input: $input, dashboardId: $dashboardId)
          {
            id
            title
            widget
            text
            type
            kpi
            tenantId
            botAppId
            jobId
            valueType
            startDate
            endDate
            conversationType
            position {
                w
                h
                x
                y
            }
            cycle
            keywords
            tForm
            categories
            alarming {
                mail
                eventType
                value
                alarmActive
            }
            reportSend
            reportMail
            jobType
            {
                intervalMonth
                intervalDay
                intervalHour
                intervalMinute
                intervalType
                jobStartTime
            }
            persons {
              userId
              name
              id
            }

          }
        }

    `}
            variables={{ input, dashboardId }}
            onCompleted={(data) => {
                this.data = data;
            }
            }
        >
            {({
                loading, error, data, refetch,
            }) => {
                if (loading) return <MyContext.Consumer>{(context) => (<Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />)}</MyContext.Consumer>;
                if (error) return <h1> </h1>;
                if (data.getReports != null
                    && data.getReports.length > 0) {

                    return (
                        <div id={`mainWindow${data.getReports.length}`}>
                            {this.renderHeader(() => {
                                refetch();
                                this.setState({ query: this.state.query });
                            })}
                            <div className={window.location.pathname.includes("main") ? "dashboard-wrapper-main" : "dashboard-wrapper"}>
                                <DNDBoard
                                    id={`board${data.getReports.id}`}
                                    setLayout={this.setLayout}
                                    reloadFunc={() => {
                                        refetch();
                                    }}
                                    data={data.getReports}
                                    input={input}
                                    apol={apol}
                                    isDraggable={this.state.editMode}
                                    userRules={this.props.userRules}
                                    authorization={this.props.authorization}
                                    theme={this.props.theme}
                                />
                            </div>
                        </div>
                    );
                }
                return (<MyContext.Consumer>
                    {(context) => (
                        <div>
                            {this.renderHeader(refetch)}
                            <div className="dashboard-wrapper">
                                <h3>{context.getTranslation("dashboard", "noWidgets")}</h3>
                            </div>
                        </div>)
                    }
                </MyContext.Consumer>
                );
            }}
        </Query>
    );

    queryGetAvailableDashboards = (input, apol, type) => (

        <Query
            query={gql`
        query ent ($input : AuthInput, $type: String) {
          getDashboards (input: $input, type: $type)
          {
            id
            title
            users
            user
            isOwner
          }
        }

    `}
            variables={{ input, type }}
        >
            {({
                loading, error, data, refetch
            }) => {
                if (loading) return <MyContext.Consumer>{(context) => (<Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />)}</MyContext.Consumer>;
                if (error) return <h1> </h1>;

                this.tmpKey = [];
                this.tmpKey.push({ key: "", text: "Dashboard User", users: "", user: this.props.userRules.userId, isOwner: false });
                if (data.getDashboards != null
                    && data.getDashboards.length > 0) {
                    for (let x = 0; x < data.getDashboards.length; x++) {
                        let i = {
                            text: data.getDashboards[x].title,
                            key: data.getDashboards[x].id,
                            users: data.getDashboards[x].users,
                            user: data.getDashboards[x].user,
                            isOwner: data.getDashboards[x].isOwner
                        };
                        this.tmpKey.push(i);
                    }
                }
                return (<MyContext.Consumer>{(context) => (
                    <Stack horizontal>
                        <DashboardConfiguration
                            id={this.state.dashboardItem.key}
                            title={this.state.dashboardItem.text}
                            text=""
                            type=""
                            users={this.state.dashboardItem.users}
                            user={this.state.dashboardItem.user}
                            isOwner={this.state.dashboardItem.isOwner}
                            authorization={this.props.authorization}
                            apol={this.props.apol}
                            ref={this.dashboardConfChange}
                            userRules={this.props.userRules}
                            theme={this.props.theme}
                            reloadFuncDashboard={() => {
                                refetch();
                            }}
                        />
                        <DashboardConfiguration
                            reloadFuncDashboard={() => {
                                refetch();
                            }}
                            id={Utils.guidGenerator()}
                            title=""
                            text=""
                            type=""
                            user={this.props.userRules.userId}
                            isOwner={true}
                            isNew
                            authorization={this.props.authorization}
                            apol={this.props.apol}
                            ref={this.dashboardConf}
                            userRules={this.props.userRules}
                            theme={this.props.theme}
                        />
                        {this.props.userRules.ruleAccess.userReporting != "No" &&
                            <TooltipHost
                                content={context.getTranslation("dashboard", "createNewDashboard")}
                                hidden={Utils.lsGet('helpMode') === 'false'}
                                calloutProps={{ gapSpace: 0 }}
                                delay={TooltipDelay.long}
                            >
                                <CommandBarButton
                                    iconProps={{ iconName: 'Add' }}
                                    style={{ padding: '7px', cursor: 'pointer', fontSize: FontSizes.Icon }}
                                    onClick={this.onNewDashboardModal}
                                />
                            </TooltipHost>}
                        <ComboBox
                            selectedKey={this.state.dashboardItem.key}
                            allowFreeform={false}
                            onChange={this.onChange}
                            options={this.tmpKey}
                        />
                        {this.props.userRules.ruleAccess.userReporting != "No" && <TooltipHost
                            content={context.getTranslation("dashboard", "changeDashboard")}
                            hidden={Utils.lsGet('helpMode') === 'false'}
                            calloutProps={{ gapSpace: 0 }}
                            delay={TooltipDelay.long}
                        >
                            <CommandBarButton
                                iconProps={{ iconName: 'Edit' }}
                                style={{ padding: '7px', cursor: 'pointer', fontSize: FontSizes.Icon }}
                                onClick={this.onChangeDashboardModal}
                            />
                        </TooltipHost>}
                    </Stack>
                )}</MyContext.Consumer>
                );
            }}
        </Query>
    );

    onChange = (event, option, index, value) => {
        console.log('_onChangeMulti() is called: option = ' + JSON.stringify(option));
        this.setState({dashboardItem: option });
    };

    setLayout = (layout) => {
        let out = [];
        for (let item = 0; item < layout.length; item++) {
            out.push({
                i: layout[item].i,
                x: layout[item].x,
                y: layout[item].y,
                w: layout[item].w,
                h: layout[item].h,
            });
        };
        this.setState({ layout: out });
    }
}
