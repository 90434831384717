import React from 'react';
import {
  Text, IStackProps, Stack, PrimaryButton,
} from 'office-ui-fabric-react';
import { ASCHeader } from '../../ASCHeader/ASCHeader';
import ASCFooter from '../../ASCFooter/ASCFooter';
import Styler from '../ShopStyle';

const priceRowStyle = {
  alignItems: 'top',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  margin: '0 auto 56px',

};
const colum1Styles = {
  // background: 'fuchsia',
  width: '90%',
  padding: 16,
  maxWidth: 1000 + 5,
};
const colum2Styles = {
  // background: 'fuchsia',
  width: '45%',
  padding: 16,
  maxWidth: 500,
};

const sectionHeaderStyles = {
  fontWeight: 300,
  fontSize: 36,
};
const headerLightBlue = '#71A2D2';

const priceStyle = { root: { height: '82px' } };

const packageDescriptionStyle = {
  // width: "40%"
};


// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    return (
      <div>
        <ASCHeader />

        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader(sectionHeaderStyles)}>
              Packages
            </Text>
            <p>
              ASC`s Recording Insights for Microsoft Teams includes a variety of options that allow your
              organization to choose the right packages based on your specific needs. The packages are
              calculated on named user basis and include 5GB cloud storage each. It is necessary to add
              additional components for storage and, if required, for the use of Cognitive Services to complete
              the package. For more information on the available packages and prices, please see the
              following overview. Please note it is not possible to combine “Compliance and Smart” packages.
            </p>
          </Stack>
          <Stack style={colum2Styles} />

        </Stack>
        {' '}
        {/* End of Row */}


        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          {this.complience()}
          {this.complieceAnalytics()}


        </Stack>
        {' '}
        {/* End of Row */}


        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          {this.smartRecording()}

          {this.smartRecordingAnalytics()}

        </Stack>
        {' '}
        {/* End of Row */}


        {/* Addon Section */}

        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader(sectionHeaderStyles)}>
              Storage Packages
            </Text>
            <p>
              ASC`s Recording Insights for Microsoft Teams is a cloud-based solution and includes 5GB of
              storage per named user subscription. Additional storage needed for saving your data can be
              bought in 3 different packages explained below. All your data will be stored securely and reliable
              in Microsoft`s Azure Cloud. The storage options include 500GB per tenant and will be charged
              per month, if more than 500GB are required, please subscribe to the package in appropriate
              numbers.
            </p>
          </Stack>

          <Stack style={colum2Styles} />
        </Stack>
        {' '}
        {/* End of Row */}

        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
              Storage 500 GB – GRS (Region 1)
            </Text>
            <Stack.Item align="end" styles={priceStyle}>
              <Text
                variant="xxLarge"
              >
                {this.props.data.getShopData.storage[1].price.toFixed(2)}
                {' '}
                {this.props.currency}
              </Text>
            </Stack.Item>
            <Stack>
              <p>
                This storage option includes the storage of up to 500GB in a Microsoft Azure block object Storage with geo redundancy in the chosen Microsoft Azure Region. Currently available options in Region 1 are Europe North, USA West and USA East. Other regions are available on request.
              </p>
            </Stack>

          </Stack>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
              Storage 500 GB – GRS (Region 2)
            </Text>
            <Stack.Item align="end" styles={priceStyle}>
              <Text
                variant="xxLarge"
              >
                {this.props.data.getShopData.storage[2].price.toFixed(2)}
                {' '}
                {this.props.currency}
              </Text>
            </Stack.Item>
            <Stack>
              <p>
                This storage option includes the storage of up to 500GB in a Microsoft Azure block object Storage with geo redundancy in the chosen Microsoft Azure Region. Currently available option in region 2 is Asia East. Other regions are available on request.
              </p>
            </Stack>

          </Stack>

        </Stack>
        <Stack horizontalAlign="center" style={priceRowStyle}>
          <p>
            GRS storage is only available for Compliance Recording packages (Compliance Recording and Compliance
            Recording & Analytics)
          </p>
        </Stack>
        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
              Storage 500 GB - LRS
            </Text>
            <Stack.Item align="end" styles={priceStyle}>
              <Text
                variant="xxLarge"
              >
                {this.props.data.getShopData.storage[0].price.toFixed(2)}
                {' '}
                {this.props.currency}
              </Text>
            </Stack.Item>
            <Stack>
              <p>
                This storage option includes the storage of up to 500GB in a Microsoft Azure block object Storage with local redundancy in the chosen Microsoft Azure Region. Currently available regions are Europe North, Asia East, USA West and USA East. Other regions are available on request.
              </p>
            </Stack>

          </Stack>
          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()} />

            <Stack>
              <p />
            </Stack>

          </Stack>

        </Stack>
        {' '}

        {/* End of Row */}
        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader(sectionHeaderStyles)}>
              Analytics Packages*
            </Text>
            <p>
              *only needed if Analytics licenses were subscribed
            </p>
            <p>
              After choosing one of our analytics packages (Smart Recording &amp; Analytics or Compliance
              Recording &amp; Analytics) you can integrate Microsoft Cognitive Services into ASC`s Recording
              Insights to analyze all communication across all Microsoft Teams channels. The Cognitive
              Services will be charged per tenant.
            </p>
          </Stack>

          <Stack style={colum2Styles} />
        </Stack>
        {' '}
        {/* End of Row */}

        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
              Speech Analytics - 100h
            </Text>
            <Stack.Item align="end" styles={priceStyle}>
              <Text
                variant="xxLarge"
              >
                {this.props.data.getShopData.cognitive[0].price.toFixed(2)}
                {' '}
                {this.props.currency}
              </Text>
            </Stack.Item>
            <Stack verticalAlign="top" styles={{ root: { height: '150px' } }}>
              <p>
                This package enables you to make use of the Cognitive Services, Speech to Text
                and Text Analytics. the analysis quota can be used within the tenant for
                which it has been activated. If more than 100h of analysis are required,
                please subscribe to the package in appropriate numbers. Please note that
                unused analysis capacities will not be transferred to the next accounting period.
              </p>
            </Stack>

          </Stack>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
              Video Indexer - 100h
            </Text>
            <Stack.Item align="end" styles={priceStyle}>
              <Text
                variant="xxLarge"
              >
                {this.props.data.getShopData.cognitive[1].price.toFixed(2)}
                {' '}
                {this.props.currency}
              </Text>
            </Stack.Item>
            <Stack verticalAlign="top" styles={{ root: { height: '150px' } }}>
              <p>
                The Video Indexer package contains the ability to do an analysis based on
                Microsoft Cognitive Services with Video Indexer for audio and video information
                within a month period; the analysis quota can be used within the tenant for
                which it has been activated. If more than 100 hours of analysis are required,
                please subscribe to the package in appropriate numbers. Please note that unused
                analysis capacities will not be transferred to the next accounting period.
              </p>
            </Stack>

          </Stack>

        </Stack>
        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
              Translate - 100h
            </Text>
            <Stack.Item align="end" styles={priceStyle}>
              <Text
                variant="xxLarge"
              >
                {
                   this.props.data.getShopData.cognitive[2].price.toFixed(2)
                   }
                {' '}
                {this.props.currency}
              </Text>
            </Stack.Item>
            <Stack verticalAlign="top" styles={{ root: { height: '150px' } }}>
              <p>
                The Translate package contains the ability to perform translation of transcribed information based
                on the Microsoft Translate service within a month period; the analysis quota can be used within
                the tenant for which it has been activated. If more than 100 hours of translations are required,
                please subscribe to the package in appropriate numbers. Please note that unused translation
                capacities will not be transferred to the next accounting period. Please also note that transcription
                via Speech Analytics package or Video Indexer package is required.
              </p>
            </Stack>

          </Stack>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()} />

            <Stack>
              <p />
            </Stack>

          </Stack>

        </Stack>

        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader(sectionHeaderStyles)}>
              Additional Packages
            </Text>

          </Stack>

          <Stack style={colum2Styles} />
        </Stack>

        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
              Recording Export 500 GB
            </Text>
            <Stack.Item align="end" styles={priceStyle}>
              <Text
                variant="xxLarge"
              >
                {
                   this.props.data.getShopData.storage[2].price.toFixed(2)
                   }
                {' '}
                {this.props.currency}
              </Text>
            </Stack.Item>
            <Stack verticalAlign="top" styles={{ root: { height: '150px' } }}>
              <p>
                This package contains the ability to export/download an amount of 500 GB of recording data from ASC Recording Insights for archiving to an 3rd party system or simple retrieval purposes. If more than 500 GB are required, please subscribe to the package in appropriate numbers. Please note that unused export/download capacities will not be transferred to the next accounting period.
                {' '}
              </p>
            </Stack>

          </Stack>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()} />

            <Stack>
              <p />
            </Stack>

          </Stack>

        </Stack>
        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader(sectionHeaderStyles)}>
              Training Package
            </Text>

          </Stack>

          <Stack style={colum2Styles} />
        </Stack>

        <Stack horizontal tokens={stackTokens} style={priceRowStyle}>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
              Onboarding &amp; Administration Training
            </Text>
            <Stack.Item align="end" styles={priceStyle}>
              <Text
                variant="xxLarge"
              >
                {
                  this.props.data.getShopData.training.price.toFixed(2)
                }
                {' '}
                {this.props.currency}
              </Text>
            </Stack.Item>
            <Stack verticalAlign="top" styles={{ root: { height: '150px' } }}>
              <p>
                This package contains a 2h web-based training for onboarding and a 2h web-based training for
                user administration &amp; configuration. The Onboarding &amp; Administration Training package is
                mandatory for all first orders of ASC Recording Insights.
                {' '}
              </p>
            </Stack>

          </Stack>

          <Stack style={colum2Styles}>
            <Text variant="xxLarge" style={Styler.basicStyleHeader()} />

            <Stack>
              <p />
            </Stack>

          </Stack>

        </Stack>
        {' '}

        {/* End of Row */}

        <Stack
          horizontalAlign="center"
          styles={{ paddingBottom: '16px' }}
        >
          <PrimaryButton
            style={{
              color: 'white',
              fontSize: '16px',
              background: headerLightBlue,
            }}
            onClick={this.props.closeFunction}
            text="Back To Shop"
            className="buyButton"
          />


          <Stack.Item
            styles={{
              root: {
                paddingBottom: '20px',
              },
            }}
          >

            <Text
              variant="xLarge"
              block
              style={{
                paddingTop: 10,
                fontWeight: 350,
              }}
            >
              If you need help, please
            </Text>
          </Stack.Item>


          <Stack.Item styles={{
            root: {
              paddingBottom: '50px',
            },
          }}
          >
            <PrimaryButton
              style={{
                color: 'white',
                fontSize: '16px',
                background: headerLightBlue,
              }}
              onClick={() => {
                this.props.caller({view:3});
                this.props.closeFunction();
                //window.open(`${window.origin}/contact`, '_blank');
              }}
              text="Contact Us"
              className="buyButton"
            />
          </Stack.Item>


        </Stack>


        <ASCFooter />
      </div>
    );
  }

  smartRecordingAnalytics() {
    return (
      <Stack style={colum2Styles}>
        <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
          Smart Recording & Analytics
        </Text>
        <Stack.Item align="end" styles={priceStyle}>
          <Text
            variant="xxLarge"
          >
            {this.props.data.getShopData.licences.smartRecordingAnalytics.price.toFixed(2)}
            {' '}
            {this.props.currency}
          </Text>
        </Stack.Item>

        <Stack styles={{ root: { height: '150px' } }}>
          <p>
            This package offers you the same features as Smart Recording, but with the possibility to add Microsoft Cognitive Services for analytics to gain deeper insights. It is featured with configurable analytics rule per user and specific analytics centric dashboards and reports.
          </p>
        </Stack>

        <Stack style={packageDescriptionStyle}>
          <ul>
            <li>Recording</li>
            <li>Recording on demand</li>
            <li>Retention time</li>
            <li>Individual user rights & access management</li>
            <li>Search & replay</li>
            <li>Encryption with Microsoft Key Management</li>
            <li>Dashboards</li>
            <li>Reports</li>
            <li>Analytics reports</li>
          </ul>
          <p><b>with cognitive services as add on</b></p>
          <ul>
            <li>Visualized transcript</li>
            <li>Highlight keywords</li>
            <li>Categorization on basis of keywords</li>
            <li>Speech translation</li>
            <li>Displaying sentiment</li>
            <li>Key phrase extraction</li>
            <li>Named entity recognition</li>
          </ul>
        </Stack>

      </Stack>
    );
  }

  smartRecording() {
    return (
      <Stack style={colum2Styles}>
        <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
          Smart Recording
        </Text>
        <Stack.Item align="end" styles={priceStyle}>
          <Text
            variant="xxLarge"
          >
            {this.props.data.getShopData.licences.smartRecording.price.toFixed(2)}
            {' '}
            {this.props.currency}
          </Text>
        </Stack.Item>

        <Stack verticalAlign="top" styles={{ root: { height: '150px' } }}>
          <p>
            The Smart Recording package offers you a solid basis for recording of conversations within
            Microsoft Teams. It features recording, conversation replay and more.
          </p>
        </Stack>

        <Stack style={packageDescriptionStyle}>
          <ul>
            <li>Recording</li>
            <li>Recording on demand</li>
            <li>Retention time</li>
            <li>Individual user rights & access management</li>
            <li>Search & replay</li>
            <li>Encryption with Microsoft Key Management</li>
            <li>Dashboards</li>
            <li>Reports</li>
          </ul>
        </Stack>

      </Stack>
    );
  }

  complience() {
    return (
      <Stack style={colum2Styles}>

        <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
          Compliance Recording
        </Text>
        <Stack.Item align="end" styles={priceStyle}>
          <Text
            variant="xxLarge"
          >
            {this.props.data.getShopData.licences.complianceRecording.price.toFixed(2)}
            {' '}
            {this.props.currency}
          </Text>
        </Stack.Item>

        <Stack styles={{ root: { height: '150px' } }}>
          <p>
            The Compliance Recording package offers you an enhanced feature set that is especially
            designed for companies with regulated users. In addition to that, recording within the Compliance
            Recording package is especially secured with redundant recording capabilities and the option to
            use geo-redundant storage. It features parallel recording, role-dependent replay, audit logs,
            reporting, and much more.
          </p>
        </Stack>

        <Stack.Item align="top" style={packageDescriptionStyle}>
          <ul>
            <li>Parallel recording</li>
            <li>Recording on demand</li>
            <li>Retention time</li>
            <li>Individual user rights & access management</li>
            <li>Search & replay</li>
            <li>Replay authorization</li>
            <li>Conversation safe</li>
            <li>Encryption with Microsoft Key Management</li>
            <li>Audit logs</li>
            <li>Dashboards</li>
            <li>Reports</li>
            <li>Additional KPI‘s</li>
            <li>Geo-redundant storage</li>
          </ul>
        </Stack.Item>

      </Stack>
    );
  }

  complieceAnalytics() {
    return (
      <Stack style={colum2Styles}>
        <Text variant="xxLarge" style={Styler.basicStyleHeader()}>
          Compliance Recording & Analytics
        </Text>
        <Stack.Item align="end" styles={priceStyle}>
          <Text
            variant="xxLarge"
          >
            {this.props.data.getShopData.licences.complianceRecordingAnalytics.price.toFixed(2)}
            {' '}
            {this.props.currency}
          </Text>
        </Stack.Item>

        <Stack verticalAlign="top" styles={{ root: { height: '150px' } }}>
          <p>
            This package offers you the same features as Compliance Recording, but with the possibility to
            add Microsoft Cognitive Services for analytics to gain deeper insights. It is featured with
            configurable analytics rule per user and specific analytics centric dashboards and reports.
            {' '}
          </p>
        </Stack>

        <Stack style={packageDescriptionStyle}>
          <ul>
            <li>Parallel recording</li>
            <li>Recording on demand</li>
            <li>Retention time</li>
            <li>Individual user rights & access management</li>
            <li>Search & replay</li>
            <li>Replay authorization</li>
            <li>Conversation safe</li>
            <li>Encryption with Microsoft Key Management</li>
            <li>Audit logs</li>
            <li>Dashboards</li>
            <li>Reports</li>
            <li>Analytics reports</li>
            <li>Additional KPI‘s</li>
            <li>Geo-redundant storage</li>

          </ul>
          <p><b>with cognitive services as add on</b></p>
          <ul>
            <li>Visualized transcript</li>
            <li>Highlight keywords</li>
            <li>Categorization on basis of keywords</li>
            <li>Speech translation</li>
            <li>Displaying sentiment</li>
            <li>Key phrase extraction</li>
            <li>Named entity recognition</li>
          </ul>
        </Stack>

      </Stack>
    );
  }
}
