import React from 'react';
import { getCognitiveServicesLocalesTranscription } from '../../queries/querySettings';
import { Query } from "@apollo/react-components";
import { Spinner } from 'office-ui-fabric-react/lib';
import { MyContext } from '../../../src/context';
import { ComboBox } from "office-ui-fabric-react";

export const TranscriptLanuages = ({ label, selectedKey, onChange, ...otherProps }) => (
    <MyContext.Consumer>
        {(context) => (
            <Query
                query={getCognitiveServicesLocalesTranscription}
                variables={{}}
            >
                {({ loading, error, data }) => {
                    if (loading) {
                        return (
                            <div style={{ float: "left", "margin-left": "50px" }}>
                                <Spinner label={context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                            </div>
                        );
                    }
                    if (error) return <h4>Failed to connect</h4>;
                    if (data.getTranscriptionLocales == null) {
                        return <div />;
                    }
                    let transcriptLanguageList = [];

                    if (data.getTranscriptionLocales != null) {

                        let languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
                        for (var i = 0; i < data.getTranscriptionLocales.length; i++) {
                            var countryName = languageNames.of(data.getTranscriptionLocales[i]);
                            transcriptLanguageList.push({
                                key: data.getTranscriptionLocales[i],
                                text: countryName
                            });
                        }
                    }

                    return <ComboBox
                        style={{ width: "300px" }}
                        label={label}
                        onChange={onChange}
                        options={transcriptLanguageList}
                        selectedKey={selectedKey}
                        {...otherProps}
                    />

                }}

            </Query>
        )}
    </MyContext.Consumer>

)


export const transcriptLangArr = [
    {
        key: "ar-DZ",
        text: "Arabic (Algeria)",
    },
    {
        key: "ar-BH",
        text: "Arabic (Bahrain)",
    },
    {
        key: "ar-EG",
        text: "Arabic (Egypt)",
    },
    {
        key: "ar-IQ",
        text: "Arabic (Iraq)",
    },
    {
        key: "ar-IL",
        text: "Arabic (Israel)",
    },
    {
        key: "ar-JO",
        text: "Arabic (Jordan)",
    },
    {
        key: "ar-KW",
        text: "Arabic (Kuwait)",
    },
    {
        key: "ar-LB",
        text: "Arabic (Lebanon)",
    },
    {
        key: "ar-LY",
        text: "Arabic (Libya)",
    },
    {
        key: "ar-MA",
        text: "Arabic (Morocco)",
    },
    {
        key: "ar-OM",
        text: "Arabic (Oman)",
    },
    {
        key: "ar-QA",
        text: "Arabic (Qatar)",
    },
    {
        key: "ar-SA",
        text: "Arabic (Saudi Arabia)",
    },
    {
        key: "ar-PS",
        text: "Arabic (Palestinian Authority)",
    },
    {
        key: "ar-SY",
        text: "Arabic (Syria)",
    },
    {
        key: "ar-TN",
        text: "Arabic (TN)",
    },
    {
        key: "ar-AE",
        text: "Arabic (UAE)",
    },
    {
        key: "ar-YE",
        text: "Arabic (Yemen)",
    },
    {
        key: "bg-BG",
        text: "Bulgarian (BG)",
    },
    {
        key: "ca-ES",
        text: "Catalan (Spain)",
    },
    {
        key: "zh-HK",
        text: "Chinese (Cantonese, Traditional)",
    },
    {
        key: "zh-CN",
        text: "Chinese (Mandarin, simplified)",
    },
    {
        key: "zh-TW",
        text: "Chinese (Taiwanese Mandarin)",
    },
    {
        key: "hr-HR",
        text: "Croatian (Croatia)",
    },
    {
        key: "cs-CZ",
        text: "Czech (Czech Republic)",
    },
    {
        key: "da-DK",
        text: "Danish (Denmark)",
    },
    {
        key: "nl-NL",
        text: "Dutch (Netherlands)",
    },
    {
        key: "en-AU",
        text: "English (Australia)",
    },
    {
        key: "en-CA",
        text: "English (Canada)",
    },
    {
        key: "en-GH",
        text: "English (Ghana)",
    },
    {
        key: "en-HK",
        text: "English (Hong Kong)",
    },
    {
        key: "en-IN",
        text: "English (India)",
    },
    {
        key: "en-IE",
        text: "English (Ireland)",
    },
    {
        key: "en-NZ",
        text: "English (New Zealand)",
    },
    {
        key: "en-NG",
        text: "English (Nigeria)",
    },
    {
        key: "en-PH",
        text: "English (Philippines)",
    },
    {
        key: "en-SG",
        text: "English (Singapore)",
    },
    {
        key: "en-ZA",
        text: "English (South Africa)",
    },
    {
        key: "en-TZ",
        text: "English (Tanzania)",
    },
    {
        key: "en-GB",
        text: "English (United Kingdom)",
    },
    {
        key: "en-US",
        text: "English (United States)",
    },
    {
        key: "et-EE",
        text: "Estonian (Estonia)",
    },
    {
        key: "fil-PH",
        text: "Filipino (Philippines)",
    },
    {
        key: "fi-FI",
        text: "Finnish (Finland)",
    },
    {
        key: "fr-CA",
        text: "French (Canada)",
    },
    {
        key: "fr-FR",
        text: "French (France)",
    },
    {
        key: "fr-CH",
        text: "French (Switzerland)",
    },
    {
        key: "de-AT",
        text: "German (Austria)",
    },
    {
        key: "de-CH",
        text: "German (Switzerland)",
    },
    {
        key: "de-DE",
        text: "German (Germany)",
    },
    {
        key: "el-GR",
        text: "Greek (Greece)",
    },
    {
        key: "gu-IN",
        text: "Gujarati (Indian)",
    },
    {
        key: "he-IL",
        text: "Hebrew (Israel)",
    },
    {
        key: "hi-IN",
        text: "Hindi (India)",
    },
    {
        key: "hu-HU",
        text: "Hungarian (Hungary)",
    },
    {
        key: "id-ID",
        text: "Indonesia (Indonesia)",
    },
    {
        key: "ga-IE",
        text: "Irish (Ireland)",
    },
    {
        key: "it-IT",
        text: "Italian (Italy)",
    },
    {
        key: "ja-JP",
        text: "Japanese (Japan)",
    },
    {
        key: "kn-IN",
        text: "Kannada (India)",
    },
    {
        key: "ko-KR",
        text: "Korean (Korea)",
    },
    {
        key: "lv-LV",
        text: "Latvian (Latvia)",
    },
    {
        key: "lt-LT",
        text: "Lithuanian (Lithuania)",
    },
    {
        key: "ms-MY",
        text: "Malay (Malaysia)",
    },
    {
        key: "mt-MT",
        text: "Maltese (Malta)",
    },
    {
        key: "mr-IN",
        text: "Marathi (India)",
    },
    {
        key: "nb-NO",
        text: "Norwegian (Norway)",
    },
    {
        key: "fa-IR",
        text: "Persian (Iran)",
    },
    {
        key: "pl-PL",
        text: "Polish (Poland)",
    },
    {
        key: "pt-BR",
        text: "Portuguese (Brazil)",
    },
    {
        key: "pt-PT",
        text: "Portuguese (Portugal)",
    },
    {
        key: "ro-Ro",
        text: "Romanian (Romania)",
    },
    {
        key: "ru-RU",
        text: "Russian (Russia)",
    },
    {
        key: "sk-SK",
        text: "Slovak (Slovakia)",
    },
    {
        key: "sl-SI",
        text: "Slovenian (Slovenia)",
    },
    {
        key: "es-AR",
        text: "Spanish (Argentina)",
    },
    {
        key: "es-BO",
        text: "Spanish (Bolivia)",
    },
    {
        key: "es-CL",
        text: "Spanish (Chile)",
    },
    {
        key: "es-CO",
        text: "Spanish (Colombia)",
    },
    {
        key: "es-CR",
        text: "Spanish (Costa Rica)",
    },
    {
        key: "es-CU",
        text: "Spanish (Cuba)",
    },
    {
        key: "es-DO",
        text: "Spanish (Dominican Republic)",
    },
    {
        key: "es-EC",
        text: "Spanish (Ecuador)",
    },
    {
        key: "es-SV",
        text: "Spanish (El Salvador)",
    },
    {
        key: "es-GT",
        text: "Spanish (Guatemala)",
    },
    {
        key: "es-HN",
        text: "Spanish (Honduras)",
    },
    {
        key: "es-MX",
        text: "Spanish (Mexico)",
    },
    {
        key: "es-NI",
        text: "Spanish (Nicaragua)",
    },
    {
        key: "es-PY",
        text: "Spanish (Paraguay)",
    },
    {
        key: "es-PE",
        text: "Spanish (Peru)",
    },
    {
        key: "es-PR",
        text: "Spanish (Puerto Rico)",
    },
    {
        key: "es-ES",
        text: "Spanish (Spain)",
    },
    {
        key: "es-UY",
        text: "Spanish (Urugay)",
    },
    {
        key: "es-US",
        text: "Spanish (USA)",
    },
    {
        key: "es-VE",
        text: "Spanish (Venezuela)",
    },
    {
        key: "sv-SE",
        text: "Swedish (Sweden)",
    },
    {
        key: "ta-IN",
        text: "Tamil (India)",
    },
    {
        key: "te-IN",
        text: "Telugu (India)",
    },
    {
        key: "th-TH",
        text: "Thai (Thailand)",
    },
    {
        key: "tr-TR",
        text: "Turkish (Turkey)",
    },
    {
        key: "vi-VN",
        text: "Vietnamese (Vietnam)",
    }
];

