import { Separator, Stack, TextField, PrimaryButton, IconButton, Dropdown } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import { IIconProps, initializeIcons } from '@fluentui/react';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';


export default class FormAdherencePhrase extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            newKeyword: "",
            error:""
        }
    }

    addKeyword = () => {
        if (this.state.newKeyword === "") {
            this.setState({
                error: this.context.getTranslation("compliance", "errorEmptyKeywords")
            })
        } else if (this.props.adherencePhrases.includes(this.state.newKeyword)) {
            this.setState({
                error: this.context.getTranslation("compliance", "errorDuplicatedKeywords")
            })
        } else {
            this.setState({
                newKeyword: "",
                error:""
            })
            this.props.updateAdherencePhrases([...this.props.adherencePhrases, this.state.newKeyword])
        }
    }

    changeNewKeyword = (e, value) => {
        this.setState({
            newKeyword: value,
            error: ""
        })
    }

   
    deleteWord = (word) => {
        this.props.updateAdherencePhrases(this.props.adherencePhrases.filter(w => { return w !== word }))
        this.setState({
            error: ""
        })
    }

    deleteAllKeywords = () => {
        this.props.updateAdherencePhrases([])
        this.setState({
            error: ""
        })
    }

   
    render() {
        return (
            <div>
                <Stack horizontal>
                    <span style={{ marginTop: "5px", marginRight: "5px" }}>{`${this.context.getTranslation("compliance", this.props.sectionName)} (${this.props.adherencePhrases.length})`}</span>
                    <span style={{ color:"red" }}>*</span>
                </Stack>
                <Stack horizontal style={{ marginTop: "10px"}}>
                    <Stack.Item align="center" grow={3}>
                        <TextField value={this.state.newKeyword} onChange={this.changeNewKeyword} disabled={this.props.disabled} fullWidth/>
                    </Stack.Item>
                    <Stack.Item align="center">
                        <PrimaryButton style={{ marginLeft: "20px" }} text={this.context.getTranslation("compliance", "add")} onClick={this.addKeyword} allowDisabledFocus disabled={this.props.disabled} />
                    </Stack.Item>
                    <Stack.Item align="center">
                        <PrimaryButton style={{ marginLeft: "20px" }} text={this.context.getTranslation("compliance", "deleteAll")} onClick={this.deleteAllKeywords} allowDisabledFocus disabled={this.props.disabled} />
                    </Stack.Item>
                </Stack>
                {this.state.error!="" &&
                    <Stack horizontal style={{ marginLeft: "30px" }}>
                        <Stack.Item align="center">
                            <span style={{ color: "red", fontSize: "10px" }}>{this.state.error}</span>
                        </Stack.Item>
                    </Stack>
                }
                <div style={{ maxHeight: '20vh', position: 'relative', overflow: 'auto' }}>
                    {this.props.adherencePhrases.map(w => {
                        return (<Stack key={w} horizontal style={{ marginTop: "5px", marginLeft: "10px" }}>
                            <Stack.Item align="center"> <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => { this.deleteWord(w) }} disabled={this.props.disabled} /></Stack.Item>
                            <Stack.Item align="center"><span >{w}</span></Stack.Item>
                        </Stack>)
                    })}
                </div>
            </div>
        )
    }
}