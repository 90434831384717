import * as React from 'react';
import { IPersonaProps } from 'office-ui-fabric-react/lib/Persona';
import { Query } from "react-apollo";
import { queryGetAudit } from "../../../queries/querySettings";
import { Spinner } from "office-ui-fabric-react/lib/Spinner";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { MyContext } from '../../../context';

const checkboxStyles = () => {
    return {
        root: {
            marginTop: '10px'
        }
    };
};

export interface IPeoplePickerExampleState {
    currentPicker?: number | string;
    delayResults?: boolean;
    peopleList: IPersonaProps[];
    mostRecentlyUsed: IPersonaProps[];
    currentSelectedItems?: IPersonaProps[];
    isPickerDisabled?: boolean;
}


export default class AuditPicker extends React.Component {
    _picker = React.createRef();

    constructor(props: {}) {
        super(props);
        this.query = this.query.bind(this);
        this.state = {
            currentPicker: 1,
            delayResults: false,
            eventList: this.props.events != null ? this.props.events.split(",") : "",
            currentSelectedItems: [],
            isPickerDisabled: false,
        };
    }

    render() {
        console.log(this.state)
        return (
            <>
                {this.query()}
            </>
        );
    }

    query = (layerFunction, auth, ) => {
        if (this.audit == null) {
            return <MyContext.Consumer>
                {(context) => (<Query
                query={queryGetAudit}
                variables={{ auth }} >
                {({ loading, error, data }) => {
                    if (loading) return <div>
                        <Spinner label={context.getTranslation("common", "stillLoading")} ariaLive="assertive" labelPosition="top" />
                    </div>;
                    if (error) return <h4>{context.getTranslation("detailContainer", "failedToConnect")}</h4>;

                    if (data.getAuditData == null) {
                        return <div></div>;
                    }
                    this.audit = data.getAuditData;
                    this.audit.map(({ key }, i) => {
                        if (this.props.events.includes(key)) {
                            this.audit[i].checked = true;
                        } else {
                            this.audit[i].checked = false;
                        }
                    });
                    return this._renderNormalPicker()
                }
                }
                </Query>)
                }
            </MyContext.Consumer>
        }
        else {
            return this._renderNormalPicker()
        }

}

    _onChanged = () => {
        var checkedEvents = "";
        this.audit.map(({ key, checked }) => {
            if (checked != null && checked === true) {
                checkedEvents += key + ",";
            }
            return 0;
        });
        console.log("Checked Events: " + checkedEvents);
        this.props.fc(checkedEvents);

    };

     _renderNormalPicker() {
         return (
             <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
                 {this.audit.map(({ id, name, group, key, checked }, i) => (
                     <Checkbox
                         label={name}
                         key={i}
                         styles={checkboxStyles}
                         checked={checked}
                         onChange={(event, newValue) => {
                             this.audit[i].checked = newValue;
                             this._onChanged();
                         }}
                     />))}
                 </Stack>
        );
    }

}
