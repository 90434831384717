import React from 'react';
import "./DetailContainer.scss"
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Icon, FontSizes } from 'office-ui-fabric-react/lib';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { TooltipDelay } from 'office-ui-fabric-react/lib/Tooltip';
import { Button, PrimaryButton, Dropdown } from 'office-ui-fabric-react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { queryGetLiveEntry } from "./../../../queries/queryRecordings";
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import Transscript from "../../DetailView/DetailPivot/PivotElements/Transscript/Transscript";
import Utils from "./../../common/Utils";


import gql from "graphql-tag";
import { MyContext } from '../../../context';
import CustomFieldsConversation from '../CustomFields/CustomFieldsConversation';
import { IconButton } from '@fluentui/react';
import RecordingBlink from './RecordingBlink';

const columnProps = {
    tokens: { childrenGap: 2 },
    styles: { root: { width: 250 } },
};

export default class LiveContainer extends React.Component {
    constructor(props) {
        super(props);
        // const { disabled, checked } = this.props;
        this.changeCurrentTime = this.changeCurrentTime.bind(this);
        this.azurePlayerRef = React.createRef();
        this.playerLoadedCallback = this.playerLoadedCallback.bind(this);
        this.countdownRef = React.createRef();
    }

    state = {
        spotting: true,
        isOpen: this.props.isOpen,
        text: "",
        startButtonPressed: false,
        stopButtonPressed: false,
        graphqlRequestPending: false,
        conversationId: this.props.data.conversationId,
        switchRecording: (this.props.userRules.ruleRecording != null && this.props.userRules.ruleRecording.disableNextRecording) ? true : false,
        showConversationUrl: false,
        stopReason: "",
        keepDeleteToggle: (this.props.data !== null && this.props.data.deleteState !== null && this.props.data.deleteState === "Keep") ? true : false,
        endWrapUp: false
    };

    playerLoadedCallback = () => {

    };
    componentDidUpdate(prevProps) {

        if (this.props.data !== prevProps.data) {
            let keepDeleteToggle = this.state.keepDeleteToggle;
            let endWrapUpDisable = this.state.endWrapUp;
            if (this.props.data.deleteState === "Keep") {
                keepDeleteToggle = true;
            }
            if (this.props.data.conversationId === "noCall") {
                endWrapUpDisable = false;
            }
            if (keepDeleteToggle !== this.state.keepDeleteToggle || endWrapUpDisable !== this.state.endWrapUp) {
                this.setState({
                    keepDeleteToggle: keepDeleteToggle,
                    endWrapUp: endWrapUpDisable,
                });
            }
        }

    }
    togleEmotion = () => {
        let tmp = this.state.emotion;
        tmp = !tmp;

        this.setState({ emotion: tmp })
    };


    hidePanel = () => {
        this.setState({ isOpen: false })
    };

    selectScenarioId = () => {
        this.setState({ showConversationUrl: true });
        var element = document.getElementById("scenarioId");
        element.hidden = false;
        element.focus();
        element.select();
        document.execCommand('copy');
        element.hidden = true;
    };

    changeCurrentTime = (val) => {
        this.azurePlayerRef.current.jumpTo(val)
    };

    onSwitchRecording = (ev: React.MouseEvent<HTMLElement>, checked: boolean) => {
        this.setState({ switchRecording: checked });
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($state: Boolean) {
                    disableNextRecording (disable: $state)
                }
                `,
                variables: { state: checked }
            })

    }

    submit = (type, operation, text) => {
        var date = Date.now().toString();
        this.setState({ graphqlRequestPending: true })
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($auth:AuthInput $id : String!, $date: String, $type: String, $operation: String, $text: String, $scenarioId: String, $botAppId: String, $callId: String, $userId: String, $stopReason:String) {
                    setEntryTag (input: $auth, id: $id, date: $date, type: $type, operation: $operation, text: $text, scenarioId: $scenarioId, botAppId: $botAppId, callId: $callId, userId: $userId, stopReason:$stopReason)
                }
                `,
                refetchQueries: [{
                    query: queryGetLiveEntry,
                    variables: {
                        id: this.props.data.id,
                        auth: this.props.authorization,
                    }
                }],
                variables: {
                    id: this.props.data.id,
                    date: date,
                    type: type,
                    operation: operation,
                    text: text,
                    scenarioId: this.props.data.scenarioId,
                    botAppId: this.props.data.botAppId,
                    callId: this.props.data.callId,
                    userId: this.props.data.userId,
                    auth: this.props.authorization,
                    stopReason: this.state.stopReason
                }
            })
            .then(result => {
                this.setState({ graphqlRequestPending: false })
            });
    }

    disableStopRecButton = () => {
        if (!this.props.data.replayAllowed) {
            return true;
        }
        if (this.state.stopButtonPressed || (this.props.userRules.ruleRecording != null && this.props.userRules.ruleRecording.demandStartOnly)) {
            return true;
        }
        if (this.props.data.rodReasons !== null && this.props.data.rodReasons !== "") {
            if (this.state.stopReason === "") {
                return true
            } else {
                return false
            }
        }
        return false;
    }
    onClickTimer = () => {
        this.countdownRef.current.startTimer();
    };

    setKeepDeleteSliderInfo = (newState) => {

        var date = Date.now().toString();
        let operation = "Delete"
        if (newState) {
            operation = "Keep";
        }
        this.setState({ keepDeleteToggle: newState });
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($input:AuthInput $id : String!, $date: String, $operation: String, $scenarioId: String, $botAppId: String, $callId: String, $userId: String, $isLive:Boolean) {
                    setKeepDeleteSliderInfo (input: $input, id: $id, date: $date, operation: $operation, scenarioId: $scenarioId, botAppId: $botAppId, callId: $callId, userId: $userId, isLive:$isLive)
                }
                `,
                variables: {
                    id: this.props.data.id,
                    date: date,
                    operation: operation,
                    scenarioId: this.props.data.scenarioId,
                    botAppId: this.props.data.botAppId,
                    callId: this.props.data.callId,
                    userId: this.props.data.userId,
                    input: this.props.authorization,
                    isLive: true
                }
            })
    }
    setCallWrapUpEnd = () => {
        this.countdownRef.current.stopTimer();
        var date = Date.now().toString();
        this.setState({ endWrapUp: true });
        let operation = "Delete";
        if (this.state.keepDeleteToggle) {
            operation = "Keep";
        }
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($input:AuthInput $id : String!, $date: String, $scenarioId: String, $botAppId: String, $callId: String, $userId: String, $operation:String) {
                    setCallWrapUpEnd (input: $input, id: $id, date: $date, scenarioId: $scenarioId, botAppId: $botAppId, callId: $callId, userId: $userId, operation:$operation)
                }
                `,
                variables: {
                    id: this.props.data.id,
                    date: date,
                    scenarioId: this.props.data.scenarioId,
                    botAppId: this.props.data.botAppId,
                    callId: this.props.data.callId,
                    userId: this.props.data.userId,
                    input: this.props.authorization,
                    operation: operation
                }
            })

    }
    render() {
        //manage disable cusotm fields depens on recording rule
        let disableCustomFields = !this.props.data.hasRestoreStarted;

        if (this.state.conversationId !== this.props.data.conversationId) {
            this.setState({ conversationId: this.props.data.conversationId, startButtonPressed: false, stopButtonPressed: false })
        }
        //get rod easons
        let rodReasonsOptions = [];
        if (this.props.data.rodReasons !== null) {
            var splitReasons = this.props.data.rodReasons.split("|");

            for (var i = 0; i < splitReasons.length; i++) {
                rodReasonsOptions.push({ key: splitReasons[i], text: splitReasons[i] });
            }
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <React.Fragment>
                        <Stack vertical {...columnProps}>
                            {/*INFO CALL*/}
                            <Stack.Item>
                                <Text variant='large'>{context.getTranslation("liveViewPanel", this.props.data.topic)}</Text>
                                <input type="text" id="scenarioId" hidden value={this.props.data.scenarioId} />
                                {this.props.data.conversationId !== "noCall" && <Icon style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.icon }}
                                    onClick={() => this.selectScenarioId()}
                                    iconName={"Copy"}
                                    title={context.getTranslation("detailContainer", "liveCopyUrl")}
                                />}
                            </Stack.Item>
                            <Stack.Item>
                                <span hidden={!this.state.showConversationUrl}>Scenario ID:&nbsp;{this.props.data.scenarioId}</span>
                            </Stack.Item>
                            <Stack.Item>
                                <TooltipHost content={context.getTranslation("liveViewPanel", "dateMessage")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                    <Text variant='medium'>{context.getTranslation("liveViewPanel", "date")}: {this.props.data.day}</Text>
                                </TooltipHost>
                            </Stack.Item>
                            <Stack.Item>
                                <TooltipHost content={context.getTranslation("liveViewPanel", "timeMessage")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                    <Text variant='medium'>{this.props.data.conversationId !== "noCall" ? "Start" : context.getTranslation("liveViewPanel", "time")}: {this.props.data.timein}</Text>
                                </TooltipHost>
                            </Stack.Item>
                            <Stack.Item>
                                <TooltipHost content={context.getTranslation("liveViewPanel", "languageText")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                    <Text variant='medium'>{this.props.data.videoInfo != null ? (context.getTranslation("liveViewPanel", "language") + this.props.data.videoInfo.language) : ""}</Text>
                                </TooltipHost>
                            </Stack.Item>


                            {/*STOP RECORDING BUTTON - selective Recording*/}
                            {(this.props.data.conversationId !== "noCall" && this.props.data.onDemand === false && this.props.data.activeConversation === true && this.props.userRules.ruleRecording != null && this.props.userRules.ruleRecording.disableNextRecording) &&
                                <Stack.Item>
                                    {<TooltipHost content={context.getTranslation("liveViewPanel", "startStopRecordingDescription")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                        <IconButton
                                            iconProps={{ iconName: 'CircleStopSolid', styles: { root: { fontSize: "43px" } } }}
                                            title=""
                                            ariaLabel=""
                                            disabled={!this.props.data.replayAllowed}
                                            onClick={() => { this.submit("StartStop", "FinalStop", ""); this.setState({ stopButtonPressed: true, startButtonPressed: false }); }}
                                            style={{ margin: "15px", padding: "5px" }}
                                        />
                                    </TooltipHost>
                                    }
                                </Stack.Item>
                            }

                            {/*START AND STOP RECORDING onDemand*/}
                            {this.props.data.conversationId !== "noCall" && <Text variant='large'>{context.getTranslation("liveViewPanel", "recording")}</Text>}

                            {this.props.data.onDemand === true && <div style={{ marginTop: "15px" }}>
                                <TooltipHost content={context.getTranslation("liveViewPanel", "startStopRecordingDescription")} hidden={Utils.lsGet("helpMode") === "false"} calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.long} >
                                    {!this.props.data.replayAllowed && <Stack horizontal >
                                        <IconButton
                                            iconProps={{ iconName: 'Location', styles: { root: { fontSize: "50px" } } }}
                                            title=""
                                            ariaLabel=""
                                            onClick={() => { this.submit("StartStop", "Start", ""); this.setState({ startButtonPressed: true, stopButtonPressed: false }); }}
                                            style={{ margin: "15px", padding: "5px" }}
                                            disabled={(this.props.data.keepDeleteAllowed && this.props.data.isWrapUpCall) ? true : this.state.startButtonPressed}
                                        />
                                        <Text variant='medium' style={{ marginTop: "20px" }}>{context.getTranslation("liveViewPanel", "recordingOff")}</Text>
                                    </Stack>
                                    }
                                    {this.props.data.replayAllowed && <>
                                        {(this.props.data.rodReasons !== null && this.props.data.rodReasons !== "") &&
                                            <Dropdown
                                                placeholder={context.getTranslation("liveViewPanel", "selectReason")}
                                                selectedKey={this.state.stopReason}
                                                disabled={!this.props.data.replayAllowed}
                                                options={rodReasonsOptions}
                                                onChange={(event, item) => {
                                                    this.setState({
                                                        stopReason: item.key
                                                    });
                                                }}
                                                allowFreeform={false}
                                                label={context.getTranslation("liveViewPanel", "stopReason")}
                                            />
                                        }
                                        <Stack horizontal >
                                            <IconButton
                                                iconProps={{ iconName: 'CircleStopSolid', styles: { root: { fontSize: "43px" } } }}
                                                title="" ariaLabel=""
                                                onClick={() => { this.submit("StartStop", "Stop", ""); this.setState({ stopButtonPressed: true, startButtonPressed: false }); }}
                                                style={{ margin: "15px", padding: "5px" }}
                                                disabled={this.disableStopRecButton()}
                                            />
                                            <Text variant='medium' style={{ marginTop: "20px" }}>{context.getTranslation("liveViewPanel", "recordingOn")}</Text>
                                            {!this.state.stopButtonPressed && <div style={{ marginTop: "20px" }} ><RecordingBlink /></div>}
                                        </Stack>
                                    </>
                                    }
                                </TooltipHost>
                            </div>}

                            {/*keep delete*/}
                            {this.props.data.conversationId !== "noCall" && this.props.userRules.ruleRecording != null && <>

                                {this.props.data.keepDeleteAllowed && <Stack style={{ marginTop: "15px" }}>
                                    <Stack.Item>
                                        <Toggle label="" checked={this.state.keepDeleteToggle} offText={context.getTranslation("liveViewPanel", "deleteRecording")} onText={context.getTranslation("liveViewPanel", "keepRecording")} onChange={(e, newValue) => this.setKeepDeleteSliderInfo(newValue)} />
                                    </Stack.Item>

                                </Stack>

                                }

                                {this.props.data.isWrapUpCall && <>
                                    <Stack.Item>
                                        <Stack horizontal >
                                            <IconButton
                                                iconProps={{ iconName: 'CircleStopSolid', styles: { root: { fontSize: "30px" } } }}
                                                onClick={() => { this.setCallWrapUpEnd() }}
                                                style={{ marginTop: "15px", marginRight: "5px", padding: "5px" }}
                                                disabled={this.state.endWrapUp}
                                            />
                                            <Text variant='medium' style={{ marginTop: "20px" }}>{context.getTranslation("liveViewPanel", "endWrapupTime")}</Text>
                                        </Stack>
                                    </Stack.Item>
                                    <br />
                                    <Stack.Item className="timer-wrap">
                                        <Text variant='large'>{context.getTranslation("liveViewPanel", "remainingTimeToStoreConv")}</Text>

                                        <Countdown ref={this.countdownRef} time={this.props.data.wrapUpDateTime} />
                                    </Stack.Item>
                                </>
                                }
                            </>
                            }

                            {/*NotRecordNextConversationn*/}
                            {this.props.data.conversationId == "noCall" && this.props.userRules.ruleRecording != null && (this.props.userRules.ruleRecording.disableNextRecording) &&
                                <div style={{ marginTop: "15px" }}>
                                    <Stack vertical style={this.state.stackStyle}>
                                        <Text variant='large'>Recording</Text>
                                        <Toggle
                                            defaultChecked={this.props.data.disableNextRecording}
                                            label={context.getTranslation("liveViewPanel", "NotRecordNextConversation")}
                                            onText={context.getTranslation("common", "yes")}
                                            offText={context.getTranslation("common", "no")}
                                            onChange={this.onSwitchRecording}
                                        />
                                    </Stack>
                                </div>}

                            {/*CUSTOM FIELDS*/}
                            {this.props.data.conversationId !== "noCall" && this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.hasCustomFieldsAccess &&
                                <Stack style={{ marginTop: "15px" }}>
                                    <Text variant='large'>{context.getTranslation("liveViewPanel", "customFields")}</Text>
                                    <CustomFieldsConversation
                                        data={this.props.data}
                                        authorization={this.props.authorization}
                                        client={this.props.apol}
                                        userRules={this.props.userRules}
                                        alignVertical={true}
                                        disabled={disableCustomFields}
                                        isProcessing={(value) => { }}
                                    />
                                </Stack>
                            }
                            {/*TAG*/}
                            {(this.props.data.conversationId !== "noCall" && this.props.userRules.ruleRecording != null && !this.props.userRules.ruleAccess.cfDisableTagging) && <Stack >
                                <Text variant='large'>{context.getTranslation("liveViewPanel", "settag")}</Text>
                                <Stack vertical>
                                    <TextField title={context.getTranslation("liveViewPanel", "note")} cols="75" key="4" multiline rows={2} componentRef={this.noteField} value={this.state.text} onChange={(event, newValue) => { this.setState({ text: newValue }); }} />
                                </Stack>
                                <Stack horizontal style={{ flexFlow: "row-reverse", marginTop: "20px" }}>
                                <PrimaryButton disabled={this.state.graphqlRequestPending} text={context.getTranslation("common", "save")} onClick={() => { this.submit("StartStop", "Note", this.state.text); this.setState({ text: "" }) }} />
                                </Stack>
                            </Stack>
                            }

                            {this.props.data.conversationId !== "noCall" && <Stack.Item >
                                <div style={{ marginTop: "20px" }}>
                                    {this.f(this.props.data)}
                                </div>
                            </Stack.Item>}
                        </Stack>

                    </React.Fragment>
                )
                }
            </MyContext.Consumer>
        );
    }
    f(props) {
        return (
            <Transscript
                key="0"
                client={this.props.apol}
                authorization={this.props.authorization}
                sum={this.props.data.summarizedInsights}
                data={this.props.data.unifiedTranscript}
                metadata={this.props.data}
                user={this.props.linkedUser}
                viewType={"liveView"}
                height={window.innerHeight - 280}
            />);
    }
}

class Countdown extends React.Component {
    constructor(props) {
        super(props);
        let secondsTime = this.calculateWrapTime()
        this.state = { time: {}, seconds: secondsTime };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);

        this.startTimer();
    }
    calculateWrapTime() {
        let now = new Date();

        let diff = new Date(this.props.time) - now;
        return diff / 1000;
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }
    stopTimer() {
        if (this.timer !== 0) {
            clearInterval(this.timer);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
        }
    }

    displayTimer() {
        let time = "";
        if (this.state.time.h > 0) {
            if (this.state.time.h < 10) {
                time += "0";
            }
            time += this.state.time.h + " : ";
        }
        if (this.state.time.m > 0) {
            if (this.state.time.m < 10) {
                time += "0";
            }
            time += this.state.time.m + " : ";
        }
        if (this.state.time.s > 0) {
            if (this.state.time.s < 10) {
                time += "0";
            }
            time += this.state.time.s + " ";
        }
        return time;
    }

    render() {
        return (
            <div>
                <Text variant={"xLarge"}>{this.displayTimer()}</Text>
            </div>
        );
    }
}