import * as React from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import ComanderBar from "../ComanderBar/ComanderBar";
import { MyContext } from '../../../context';
import Utils from '../../common/Utils'

const orderAlphabetically  = Utils.orderAlphabetically

const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px'
    }
};

export interface IDocument {
    name: String;
    group: String;

}

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}

export default class RuleList extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: IDocument[];

    constructor(props)
    {
        super(props);

        let columns: IColumn[] = [
            {
                key: 'name', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "name")}</>)}</MyContext.Consumer>, fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true, isSorted: true},
            {
                key: 'created', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "time")}</>)}</MyContext.Consumer>, fieldName: 'created', minWidth: 100, maxWidth: 200},
            { key: 'group', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "group")}</>)}</MyContext.Consumer>, fieldName: 'group', minWidth: 100, isCollapsible: true, maxWidth: 200 },

            {
                key: 'settings',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "options")}</>)}</MyContext.Consumer>,
                isIconOnly: true,
                isResizable: false,
                fieldName: 'name',
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <a style={{ cursor: "pointer" }} onClick={() => { this.props.func(item, 1) }}><Icon iconName={"Settings"} /></a>
                }
            }

        ];

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            items: this._allItems,
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: false ,
            isCompactMode: true,
            filter: ""
        };
    }

    render() {
        const { columns, isCompactMode, isModalSelection } = this.state;
        this.ruleData = this._generateDocuments(this.props.data, this.state.filter);

        return (
            <Fabric>
                <ComanderBar sel={"rule"} onChangeX={this._onChangeText} stylesX={controlStyles}  close={this.props.close} openNew={this.openNew} edit={this.openSelect} authorization={this.props.authorization} apol={this.props.apol} />
                <MarqueeSelection selection={this._selection}>
                    <DetailsList
                        items={this.ruleData}
                        compact={isCompactMode}
                        groups={this.generateGroups()}
                        columns={columns}
                        selectionMode={isModalSelection ? SelectionMode.multiple : SelectionMode.none}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        groupProps={{
                            showEmptyGroups: true
                        }}
                    />
                </MarqueeSelection>
            </Fabric>
        );
    }

    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }
    generateGroups=()=>
    {

            let out=[];
            let replay=0,access=0,recording=0,analytics=0;

            switch (this.props.data)
            {

                case null:return out;
                case  undefined :return out;

                default:
                    for (let i=0;i<this.props.data.length;i++)
                    {
                        switch (this.props.data[i].group)
                        {
                            case "replay":replay++;break;
                            case "access":access ++;break;
                            case "recording":recording ++;break;
                            case "analytics": analytics++; break;
                            default: break;
                        }

                    }
                    return[
                        { key: 'groupaccess0', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "accessAndRightRules")}</>)}</MyContext.Consumer>, startIndex: 0, count: access },
                        { key: 'groupanalytics1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "analyticsRules")}</>)}</MyContext.Consumer>, startIndex: access, count: analytics },
                        { key: 'grouprecording2', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "recordingRules")}</>)}</MyContext.Consumer>, startIndex: (analytics+access), count: recording },
                        { key: 'groupreplay3', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "replayRules")}</>)}</MyContext.Consumer>, startIndex: analytics+access+recording, count:replay },

                    ]
            }
        };


    _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isCompactMode: checked });
    };

    _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isModalSelection: checked });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.ruleData = this.ruleData.filter(i => i.name.includes(text) || i.name.toLocaleLowerCase().includes(text));
        this.setState({ filter: text });
    };


    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                return 'One item selected: ' + (this._selection.getSelection()[0]).name;
            default:
                return `${selectionCount} Items selected`;
        }
    }
    openSelect=()=>
    {
        console.log(this._selection.getSelection())
        if (this._selection.getSelection().length>0)
        {
            this.props.func(this._selection.getSelection(),1);
        }
    };
    openNew=()=>
    {
            this.props.func(null,1);

    };

    _generateDocuments(DataArr, text) {
        // First filter
        let DataArrFiltered = DataArr.filter(i => i.name.includes(text) || i.name.toLocaleLowerCase().includes(text));
        // Then sort
        let newColumns = this.state.columns;
        let replayCount=0,accessCount=0,recordingCount=0,analyticsCount=0;
        replayCount = DataArrFiltered.filter(e=>e.group == "replay").sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))
        accessCount = DataArrFiltered.filter(e=>e.group == "access").sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))
        recordingCount = DataArrFiltered.filter(e=>e.group == "recording").sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))
        analyticsCount = DataArrFiltered.filter(e=>e.group == "analytics").sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))
    
        return [...accessCount, ...analyticsCount, ...recordingCount, ...replayCount,];
    };

    _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    };
}




