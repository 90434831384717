import * as React from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { DefaultButton, Icon } from "office-ui-fabric-react";
import DetailViewOverlay from "./../DetailView/DetailViewOverlay"
import { FontSizes, Spinner, Stack } from 'office-ui-fabric-react/lib/';
import Utils from "./../common/Utils";
import "./DayListView.scss"
import { toast } from "react-toastify";
import { MyContext } from '../../context';
import "./fabric-icons-inline.scss";
import { getAllNameCustomFields, queryGetLoggedUserData } from '../../queries/querySettings'
import SettingsColumnsRecording from './SettingsColumnsRecording';
import SearchExport from '../DetailView/DetailPivot/PivotElements/Transscript/SearchExport';
import DownloadDialog from '../DetailView/DetailPivot/PivotElements/Transscript/DownloadDialog';
import AscHover from "./AscHover";




const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    },
    row: {
        flex: '0 0 auto',
    },
});

export interface IDocument {
    id: String,
    conversationType: String,
    topic: String,
    conversationId: String,
    conversationDirection: String,
    date: String,
    starttime: String,
    duration: String,
    participants: String,
    transcription: String,
    notes: String
}

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}



export default class List extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: IDocument[];
    toastId = null;
    toastComplete = null;

    defaultSettingsColumns = ["recordingType", 'topic', "media", 'dateValue', "start", "duration", "owner", "participantCount", "notes", "transcription", "conversationId"]

    constructor(props) {
        super(props);

        this._allItems = _generateDocuments(props.data);

        this.detailContainerRef = React.createRef();
        this.LiveViewRef = React.createRef();

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            items: this._allItems,
            allColumns: [],
            columns: [],
            settingsColumns: [],
            selectionDetails: this._getSelectionDetails(),
            isCompactMode: false,
            helpMode: Utils.lsGet("helpMode") === "true" ? true : false,
            helpPanel: false,
            count: this._selection.getSelectedCount(),
            isBulkOperation: false
        };
    }


    async componentDidMount() {

        //event to rerender the list when resize of the window
        addEventListener('resize', (event) => {
            this.setState({})
        });

        //get List view settings from db
        let auth = this.props.authorization
        let result = await this.props.apol
            .query({
                query: queryGetLoggedUserData,
                variables: { auth }
            })
        let user = result.data.getLoggedUserData
        let settingsColumns = user === null || user.listViewSettings === null ? this.defaultSettingsColumns : user.listViewSettings

        const columns = [
            {
                key: 'column1',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "conversationType")}</>)}</MyContext.Consumer>,
                className: classNames.fileIconCell,
                iconClassName: classNames.fileIconHeaderIcon,
                ariaLabel: 'Column operations for File type, Press to sort on File type',
                iconName: 'Page',
                isIconOnly: true,
                fieldName: 'recordingType',
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    const typeIcon = item.recordingType != null ? item.recordingType : "Unknown";
                    if (typeIcon === "Meeting") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Group' title="Meeting" />;
                    } else if (typeIcon === "Inbound") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ArrowDownRightMirrored8' title="Inbound" />;
                    } else if (typeIcon === "Outbound") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ArrowUpRight8' title="Outbound" />;
                    } else if (typeIcon === "Internal") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ConnectContacts' title="Internal" />;
                    } else if (typeIcon === "Chat") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Chat' title="Chat" />;
                    } else if (typeIcon === "") {
                        return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='CloudUpload' />;
                    }
                    else {
                        return <div></div>;
                    }

                }
            },
            {
                key: 'column2',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "header")}</>)}</MyContext.Consumer>,
                fieldName: 'topic',
                minWidth: 120,
                maxWidth: 400,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                fixed: true,
            }
            ,
            {
                key: 'column3',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "media")}</>)}</MyContext.Consumer>,
                fieldName: 'media',
                minWidth: 80,
                maxWidth: 80,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IDocument) => {
                    if (item.data.isVideo) {
                        return (
                            <Stack.Item style={{ padding: "0px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Video' title="Video" />
                            </Stack.Item>
                        );
                    }
                    else {
                        return (<Stack horizontal align="start" style={{ padding: "0px" }} tokens={{ childrenGap: '10' }}>
                            {item.data.isDuplicate && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon, color: 'red' }} iconName='Error' title="Duplicate" />
                            </Stack.Item>}
                            {item.data.hasAudio && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Microphone' title="Audio" />
                            </Stack.Item>}
                            {item.data.hasScreenSharing && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ScreenCast' title="Screen share" />
                            </Stack.Item>}
                            {item.data.hasVideo && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Video' title="Video" />
                            </Stack.Item>}
                            {item.data.hasChat && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Chat' title="Chat" />
                            </Stack.Item>}
                            {item.data.hasFiles && <Stack.Item style={{ padding: "4px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Attach' title="Files" />
                            </Stack.Item>}
                        </Stack>);
                    }
                }
            },
            {
                key: 'column4',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "date")}</>)}</MyContext.Consumer>,
                fieldName: 'dateValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'date',
                isSortedDescending: true,
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.date}</span>;
                },
                fixed: true,
            },
            {
                key: 'todo',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "todo")}</>)}</MyContext.Consumer>,
                fieldName: 'todo',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'string',
                isSortedDescending: true,
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.todo ? "To Do" : ""}</span>;
                }
            },
            {
                key: 'column5',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "start")}</>)}</MyContext.Consumer>,
                fieldName: 'start',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isSortedDescending: true,
                data: 'date',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.starttime}</span>;
                },
                fixed: true
            }
            ,
            {
                key: 'column5a',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "duration")}</>)}</MyContext.Consumer>,
                fieldName: 'duration',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.duration}</span>;
                },
                isPadded: true
            },
            {
                key: 'column6',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "owner")}</>)}</MyContext.Consumer>,
                fieldName: 'owner',
                minWidth: 120,
                maxWidth: 140,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.data.ownerDisplayName}</span>;
                },
                isPadded: true
            },
            {
                key: 'column7',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "participants")}</>)}</MyContext.Consumer>,
                fieldName: 'participantCount',
                minWidth: 220,
                maxWidth: 1000,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.participants}</span>;
                },
                isPadded: true
            },
            {
                key: 'column10',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "notes")}</>)}</MyContext.Consumer>,
                fieldName: 'notes',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.notes !== "" ? "YES" : "NO"}</span>;
                },
                isPadded: true
            },
            {
                key: 'column11',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "transcript")}</>)}</MyContext.Consumer>,
                fieldName: 'transcription',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.transcription}</span>;
                },
                isPadded: true
            },
            {
                key: 'column9',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "conversationId")}</>)}</MyContext.Consumer>,
                fieldName: 'conversationId',
                minWidth: 200,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column15',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "ScenarioID")}</>)}</MyContext.Consumer>,
                fieldName: 'scenarioId',
                minWidth: 200,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column12',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("conversationsPage", "archived")}</>)}</MyContext.Consumer>,
                fieldName: 'archived',
                minWidth: 80,
                maxWidth: 80,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IDocument) => {
                    if (item.data.archiveStatus === 1 || item.data.archiveStatus === 2) {

                        return (
                            <Stack.Item style={{ padding: "0px" }}>
                                <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='Archive' />
                            </Stack.Item>
                        );
                    } else if (item.data.hasRestoreStarted) {
                        return (
                            <Stack.Item style={{ padding: "0px" }}>
                                <Icon className="ms-Icon--ArchiveUndo ms-Icon" style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='ArchiveUndo' />
                            </Stack.Item >
                        );
                    }
                }
            },
            {
                key: 'column14',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "ttl")}</>)}</MyContext.Consumer>,
                fieldName: 'ttlDate',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'date',
                isSortedDescending: true,
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.data.ttl !== "" && item.data.ttl !== null ? new Date(item.data.ttl).toLocaleDateString(this.props.authorization.locale, { day: "2-digit", month: "2-digit", year: "numeric" }) : ""}</span>;
                }
            }
        ];

        /*CONVERSATION SAFE COLUMN*/
        const safeAccess = this.props.userRules.ruleAccess.userSafe !== "No"
        if (safeAccess) {
            const safeColumn = {
                key: 'conversationSafe',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "conversationSafe")}</>)}</MyContext.Consumer>,
                fieldName: 'conversationSafe',
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IDocument) => {

                    function NotHoverContent() {
                        return (
                            <Icon style={{ padding: "0px", fontSize: FontSizes.icon }} iconName='LockSolid' />
                        )
                    }

                    if (item.conversationSafe === false) {
                        return < span ></span >;
                    } else {
                        if (!item.conversationSafeFolders.length) {
                            return (<AscHover hoverContent={'Unallocated'}>
                                <NotHoverContent />
                            </AscHover>)
                        }
                        else {
                            return (<AscHover hoverContent={item.conversationSafeFolders.map(e => (<div key={e.name}>{e.name}</div>))}>
                                <NotHoverContent />
                            </AscHover>)
                        }
                    }

                }
            }
            columns.splice(9, 0, safeColumn);
        }


        /* COMPLIANCE COLUMN */
        const { complianceResultsAccess, userCompliancePolicy } = this.props.userRules.ruleAccess
        const addOnCompliance = this.props.userRules.addOnCompliance
        //chiedere conferma a Ralf
        const complianceAccess = addOnCompliance && complianceResultsAccess && userCompliancePolicy
        if (complianceAccess) {
            const columnCompliance = {
                key: 'columnCompliance',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("compliance", "complianceTitle")}</>)}</MyContext.Consumer>,
                fieldName: 'compliance',
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    const policies = item.data.policies
                    var resultRiskRatings = [];
                    if (policies !== null && policies !== undefined && policies.length > 0) {
                        policies.forEach(pol => {
                            if (!resultRiskRatings.includes(pol.riskRating))
                                resultRiskRatings.push(pol.riskRating)
                        });
                    }
                    if (resultRiskRatings.length > 0) {
                        return (<div>{
                            this.getGreatestRiskRating(resultRiskRatings)
                        }
                        </div>)
                    } else {
                        return <span style={{ fontSize: "12px" }}>NO</span>;
                    }
                }
            }
            columns.splice(10, 0, columnCompliance);
        }

        /* CUSTOM FIELDS COLUMNS */
        if (this.props.userRules.ruleAccess.hasCustomFieldsAccess) {
            this.props.apol
                .query({
                    query: getAllNameCustomFields,
                    variables: { auth: this.props.authorization }
                })
                .then(res => {
                    let result = res.data.getAllCustomFieldDefinitions.map(cf => {
                        if (this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.id) || this.props.userRules.ruleAccess.accesibleCustomFields.includes(cf.fieldName)) {
                            return (
                                {
                                    key: cf.id,
                                    name: cf.fieldName,
                                    fieldName: cf.id,
                                    minWidth: 70,
                                    maxWidth: 70,
                                    isResizable: true,
                                    data: 'string',
                                    onColumnClick: this._onColumnClick,
                                    onRender: (item: IDocument, index, column) => {
                                        let customFields = JSON.parse(item.data.customFields)
                                        if (customFields.customFields.length > 0) {
                                            let customField = customFields.customFields.filter(cf => {
                                                return cf.fieldId === column.fieldName
                                            })
                                            if (customField !== null && customField.length > 0) {
                                                return <span>{customField[0].fieldValues.join(",")}</span>;
                                            } else {
                                                return <span></span>;
                                            }
                                        } else {
                                            return <span></span>;
                                        }
                                    },
                                    isPadded: true,
                                    customField: true
                                }
                            )
                        }
                    }).filter(cf => cf !== undefined)
                    let resultColumns = [...columns, ...result]
                    //remove from settingsColumns field what the user can't see anymore
                    settingsColumns = settingsColumns.filter(sc => {
                        return resultColumns.filter(rc => sc === rc.fieldName).length > 0
                    })
                    resultColumns = settingsColumns.map(c => {
                        return resultColumns.filter(col => col.fieldName === c)[0]
                    }).filter(d => d !== undefined)
                    this.setState({
                        columns: resultColumns,
                        allColumns: [...columns, ...result],
                        settingsColumns
                    })
                })
        } else {
            let resultColumns = columns
            //remove from settingsColumns field what the user can't see anymore
            settingsColumns = settingsColumns.filter(sc => {
                return resultColumns.filter(rc => sc === rc.fieldName).length > 0
            })
            resultColumns = settingsColumns.map(c => {
                return resultColumns.filter(col => col.fieldName === c)[0]
            }).filter(d => d !== undefined)
            this.setState({
                columns: resultColumns,
                allColumns: columns,
                settingsColumns
            })
        }

       

    }
    
    getGreatestRiskRating = (risksArray)=>{
        if (risksArray.includes("Warn")) {
            return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon, marginRight: "2px" }} iconName='WarningSolid' title="Warning" />
        }
        if (risksArray.includes("Adherence")) {
            return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon, marginRight: "2px" }} iconName='Warning' title="Attention" />
        }
        if (risksArray.includes("Info")) {
            return <Icon className="ContentColor" style={{ padding: "0px", fontSize: FontSizes.icon, marginRight: "2px" }} iconName='Info' title="Information" />
        }
    }

    onDelete = (item) => {
        this.deletion = false;

        let toastId = toast.info(
            <MyContext.Consumer>
                {(context) => (
                    <>
                        <p> {context.getTranslation("conversationsPage", "undoDeltionMessage")}<br />
                            {item.conversationID} ?</p>
                        <DefaultButton onClick={() => { this.dismiss(toastId) }} text={context.getTranslation("conversationsPage", "undoDeletionButton")} />
                    </>
                )
                }
            </MyContext.Consumer>
            , {
                onClose: () => {
                    if (!this.deletion) {
                        toast.info(<MyContext.Consumer>{(context) => (<h3>{context.getTranslation("conversationsPage", "deletionConfirmationMessage")}</h3>)}</MyContext.Consumer>, { autoClose: 5000 })
                    }


                }
            });
    };

    dismiss = (toastId) => {

        this.deletion = true;
        toast.dismiss(toastId);
        toast.info(<MyContext.Consumer>{(context) => (<h3>{context.getTranslation("conversationsPage", "undoDeletionConfirmationMessage")}</h3>)}</MyContext.Consumer>, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    updateColumnsSettings = (columns) => {
        this.setState({ columns })
    }

    onRenderDetailsFooter = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        return (<div className={classNames.row}></div>);
    };

    render() {
        const { columns, allColumns, settingsColumns, isCompactMode, items } = this.state;
        const gridStyles: Partial<IDetailsListStyles> = {
            root: {
                overflowX: 'scroll',
                selectors: {
                    '& [role=grid]': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        height: window.innerHeight - 100,
                    },
                },
            },
            headerWrapper: {
                flex: '0 0 auto',
            },
            contentWrapper: {
                flex: '1 1 auto',
                overflowY: 'auto',
                overflowX: 'hidden',
            },
        };
        return (
            <Fabric>
                <DetailViewOverlay authorization={this.props.authorization} client={this.props.apol} ref={this.detailContainerRef} loading={true} userRules={this.props.userRules} theme={this.props.theme} />
                <MarqueeSelection selection={this._selection}>
                    {columns.length > 0 && settingsColumns.length > 0 && < div >
                        <MyContext.Consumer>{(context) => (
                            <Stack horizontal>
                                <Stack.Item>
                                    <div className="app-statistics">{(this.state.isBulkOperation ? this._selection.getSelectedCount() : this.props.count) + " " + context.getTranslation("common", "conversations") + " " + this.props.inform}</div>
                                </Stack.Item>
                                {this.props.isBulkOperation && this.props.count > 0 &&
                                    <Stack.Item>
                                        {this.state.isBulkOperation ?
                                            <Stack horizontal>
                                                <DownloadDialog
                                                    refetchQueryFields={this.props.refetchQueryFields}
                                                    client={this.props.apol}
                                                    authorization={this.props.authorization}
                                                    bulkOperation={this.props.isBulkOperation}
                                                    bulkData={this._selection.getSelection()}
                                                    userRules={this.props.userRules}
                                                    count={this._selection.getSelectedCount()}
                                                    passwordLength={(this.props.userRules.ruleAccess != null && this.props.userRules.ruleAccess.userBulkPasswordLength != undefined) ? this.props.userRules.ruleAccess.userBulkPasswordLength : 8}
                                                />
                                                <DefaultButton onClick={() => { this.setState({ isBulkOperation: false }) }} text={context.getTranslation("configuration", "Cancel")} />
                                            </Stack>
                                            :
                                            <DefaultButton onClick={() => { this.setState({ isBulkOperation: true }) }} text={context.getTranslation("configuration", "bulkOperation")} />
                                        }
                                    </Stack.Item>
                                }
                                {this.props.count !== undefined && this.props.userRules.ruleAccess != null && !this.props.userRules.ruleAccess.userBulkExcel && <Stack.Item>
                                    <SearchExport
                                        dataList={this.state.isBulkOperation ? this._selection.getSelection() : this._allItems}
                                        userRules={this.props.userRules}
                                        apol={this.props.apol}
                                        count={this.state.isBulkOperation ? this._selection.getSelectedCount() : this.props.count}
                                    />
                                </Stack.Item>}
                                <Stack.Item>
                                    <SettingsColumnsRecording settingsColumns={settingsColumns} allColumns={allColumns} updateColumnsSettings={this.updateColumnsSettings} apol={this.props.apol} auth={this.props.authorization} />
                                </Stack.Item>
                            </Stack>
                        )}
                        </MyContext.Consumer>
                        <DetailsList
                            items={items}
                            compact={isCompactMode}
                            columns={columns}
                            selectionMode={this.state.isBulkOperation ? SelectionMode.multiple : SelectionMode.none}
                            setKey="set"
                            layoutMode={0}
                            isHeaderVisible={true}
                            selection={this._selection}
                            selectionPreservedOnEmptyClick={true}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            onRenderRow={(props, defaultRender) => (
                                <div >
                                    {!this.state.isBulkOperation && defaultRender({
                                        ...props, onClick: (event) => {
                                            event.preventDefault(); this._onItemInvoked(props.item)
                                        }
                                    })
                                    }
                                    {this.state.isBulkOperation && defaultRender({
                                        ...props

                                    })
                                    }
                                </div>
                            )}
                            onRenderDetailsFooter={this.onRenderDetailsFooter}
                            styles={gridStyles}
                        />
                    </div>}
                    {(columns.length === 0 || settingsColumns.length === 0) && <MyContext.Consumer>{(context) => (<Spinner label={context.getTranslation("configuration", "loadingConversations")} ariaLive="assertive" labelPosition="top" />)}</MyContext.Consumer>}
                </MarqueeSelection>
            </Fabric>
        );
    }

    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
        if (previousProps.data != this.props.data) {
            this.setState({ items: _generateDocuments(this.props.data) })
        }

    }

    _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isCompactMode: checked });
    };

    _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isModalSelection: checked });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({
            items: text ? this._allItems.filter(i => i.conversationID.toLowerCase().indexOf(text) > -1) : this._allItems
        });
    };


    _onItemInvoked = (item) => {
        this.detailContainerRef.current.showPanelItem(item.conversationId, item.data);
    }

    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
            default:
                return `${selectionCount} items selected`;
        }
    }

    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        let newItems = []
        if (column.customField) {
            newItems = _copyAndSortCustomFields(items, currColumn.fieldName, currColumn.isSortedDescending);
        } else if (column.fieldName === "ttlDate") {
            newItems = _copyAndSortTtl(items, currColumn.fieldName, currColumn.isSortedDescending);
        } else if (column.fieldName === "compliance") {
            newItems = _copyAndSortCompliance(items, currColumn.fieldName, currColumn.isSortedDescending);
        } else {
            newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        }
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _copyAndSortTtl<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? new Date(a.data.ttl) < new Date(b.data.ttl) : new Date(a.data.ttl) > new Date(b.data.ttl)) ? 1 : -1));
}

function _copyAndSortCompliance<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? getRiskRating(a) < getRiskRating(b) : getRiskRating(a) > getRiskRating(b)) ? 1 : -1));
}

function getRiskRating(i) {
    let policies = i.data.policies
    let resultRiskRatings = []
    if (policies.length > 0) {
        policies.forEach(pol => {
            if (!resultRiskRatings.includes(pol.riskRating))
                resultRiskRatings.push(pol.riskRating)
        });
    }
    return resultRiskRatings.sort().join(",")
}

function _copyAndSortCustomFields<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey;
    return items.slice(0).sort((a: T, b: T) => {
        let customFieldA = JSON.parse(a.data.customFields).customFields.filter(f => { return f.fieldId === key })
        let customFieldB = JSON.parse(b.data.customFields).customFields.filter(f => { return f.fieldId === key })
        let aa = ""
        let bb = ""
        if (customFieldA.length > 0 && customFieldA[0].fieldValues.length > 0) {
            aa = customFieldA[0].fieldValues[0].toLowerCase()
        }
        if (customFieldB.length > 0 && customFieldB[0].fieldValues.length > 0) {
            bb = customFieldB[0].fieldValues[0].toLowerCase()
        }
        return (aa !== "" ? ((isSortedDescending ? aa < bb : aa > bb) ? 1 : -1) : 1)
    });
}



function _generateDocuments(DataArr) {
    const items: IDocument[] = [];
    if (DataArr !== undefined) {
        for (let i = 0; i < DataArr.length; i++) {
            let item = DataArr[i];
            item["owner"] = DataArr[i].data.ownerDisplayName;
            items.push(item);
        }

    }
    return items;
}





