import gql from "graphql-tag";

export const queryPurchase = gql`
                         query ent ($auth:AuthInput, $url:String){
                            transactablePurchase(input:$auth, url:$url)
                                    
                              }
                            `;

export const mutationPurchaseConfirm = gql`
                          mutation ent ($subscriptionId:String, $planId:String, $seatQuantity: String){
                            transactablePurchaseConfirm(subscriptionId: $subscriptionId, planId: $planId, seatQuantity: $seatQuantity)
                          }
                            `;
