import gql from 'graphql-tag';


export const queryAllAICategorizations = gql`query  {
                getAICategorizations  {
                        id
                        name
                        tenantId
                    }
         }`

export const queryGetAICategorization = gql`query ent ($id: String) {
                getAICategorization (id: $id) {
                        id
                        name
                        userId
                        tenantId
                        aiCategorizationList{
                            id
                            name
                            priority
                            conversationIds
                            deleted
                            linkedCategorization
                            userId
                            tenantId
                        }
                    }
         }`

export const upsertAICategorization = gql`mutation ent($aiCategorization: AICategorizationInputType){
                                        upsertAICategorization(aiCategorization: $aiCategorization) {
                                                id
                                                text
                                                status
                                            }
                                        }`

export const deleteAICategorization = gql`mutation ent ($id: String) {
                                        deleteAICategorization(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`