import React from 'react';

import {
    Chart,
    Series,
    CommonSeriesSettings,
    Legend,
    ValueAxis,
    Title,
    Tooltip,
    ArgumentAxis, Label, Format
} from 'devextreme-react/chart';
import {Size} from "devextreme-react/vector-map";


class StackedBarLoss extends React.Component {
    render() {
        return (
            <Chart
                id="chart"
                ref={this.props.reference}

                dataSource={this.props.data}
                palette={this.props.palette}
            >
                <CommonSeriesSettings
                    ignoreEmptyPoints={true}
                    argumentField="time" type="stackedBar"
                />
                <Series
                    valueField="totalLoss"
                    name="Loss"

                />
                <Series
                    valueField="onlyTranscript"
                    name="Transcript"

                />
                <Series
                    valueField="lessDuration"
                    name="Duration"

                />
                <Series
                    valueField="packetLoss"
                    name="Packet"
                />
                <Series
                    valueField="onlySilence"
                    name="Silence"
                />

                <ArgumentAxis
                    allowDecimals={false}
                    axisDivisionFactor={60}
                >
                    <Label>
                        <Format type="decimal" />
                    </Label>
                    <Title text="Time" />
                </ArgumentAxis>
                <ValueAxis>
                    <Title text="Calls With Problems" />
                </ValueAxis>
                <Legend
                    margin={0}
                    horizontalAlignment="center"
                    verticalAlignment="bot"
                />
                <Tooltip enabled={true} />

                <Size
                    height={this.props.size.height}
                    width={this.props.size.width}
                />
            </Chart>
        );
    }
}

export default StackedBarLoss;
