import { CommandBarButton, PanelType, Spinner, Stack, Panel } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { MyContext } from '../../../context';
import AIQuestionsListView from './AIQuestionsListView';
import * as query from './AIQuestionQuery';
import EditAIQuestionContainer from '../AIQuestion/Panels/EditAIQuestionContainer'
import AlertBar from '../../CustomComponents/AlertBar';
import SafetyDialog from '../../CustomComponents/SafetyDialog'
import Utils from '../../common/Utils'

const orderAlphabetically  = Utils.orderAlphabetically

export default class AIQuestions extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            isPanelOpen: false,
            aiQuestionId: null,
            warningMessage: "",
            panelTitle: null,
            alertMessagePanel: "",
            hideDialogDelete: true,
            isProcessing: false
        };
    }

    openPanel = (title, aiQuestionId) => {
        if (title === "addAIQuestion" || (title === "editAIQuestion" && aiQuestionId !== null)) {
            this.setState({
                isPanelOpen: true,
                panelTitle: title,
                warningMessage: ""
            })
        } else {
            this.setState({
                warningMessage: this.context.getTranslation("compliance", "selectAIQuestion"),
                alertType: "warning"
            })
        }

    }

    getHeaderPanel = () => {
        return (
            <div style={{ flex: "auto", marginLeft: "20px" }}>
                <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>{this.context.getTranslation("compliance", this.state.panelTitle)}</div>
                <AlertBar
                    message={this.state.alertMessagePanel}
                    alertType={"error"}
                    clearAlert={this.clearAlert}
                />
            </div >
        )
    }

    hidePanel = () => {
        this.setState({ isPanelOpen: false, alertMessagePanel: "" });
    };

    selectAIQuestion = (id) => {
        this.setState({
            aiQuestionId: id,
            warningMessage: ""
        });
    }

    setMessageError = (message) => {
        this.setState({
            alertMessagePanel: message
        })
    }

    refreshAIQuestions = () => {
        this.refetchQueryAIQuestions()
    }

    refetchQueryAIQuestions = null;

    deleteAIQuestion = () => {
        this.setState({ hideDialogDelete: true, isProcessing: true })
        if (this.state.aiQuestionId !== null) {
            const id = this.state.aiQuestionId
            this.props.apol
                .mutate({
                    mutation: query.deleteAIQuestion,
                    variables: { id }
                })
                .then((result) => {
                    if (result.data.deleteAIQuestion.status !== "ok") {
                        this.setState({
                            warningMessage: this.context.getTranslation("compliance", result.data.deleteAIQuestion.text),
                            alertType: "error",
                            isProcessing: false
                        })
                    } else {
                        this.setState({
                            aiQuestionId: null,
                            isProcessing: false
                        })
                        this.refetchQueryAIQuestions();
                    }
                });
        }
    }

    openDialogDelete = () => {
        if (this.state.aiQuestionId !== null) {
            this.setState({ hideDialogDelete: false })
        } else {
            this.setState({
                warningMessage: this.context.getTranslation("compliance", "selectAIQuestion"),
                alertType: "warning"
            })
        }
    }

    getAIQuestionGroups = (data) => {
        //unique groups id
        let allGroupsIds = [...new Set(data.map(aiQ => aiQ.aiQuestionGroup.id))];
        //create groups for dataillist
        let groups = allGroupsIds.map(groupId => {
            var startIndex = "";
            var endIndex = "";
            var name = "";
            for (var i = 0; i < data.length; i++) {
                if (data[i].aiQuestionGroup.id === groupId) {
                    name = data[i].aiQuestionGroup.name
                    startIndex = startIndex === "" ? i : startIndex
                    endIndex = i + 1
                }
            }
            return ({
                "key": groupId,
                "name": name,
                "startIndex": startIndex,
                "count": endIndex - startIndex,
                "level": 0
            })
        })
        return groups;
    }

    render() {
        return (<div style={{ overflow: "auto", maxHeight: window.innerHeight - 120, position: 'relative', marginTop: "5px" }}>

            {/*Manage button add, edit and delete*/}
            <Stack horizontal styles={{ root: { height: 44 } }}>
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Add' }}
                    text={this.context.getTranslation("compliance", "addAIQuestion")}
                    disabled={false}
                    checked={false}
                    onClick={() => { this.openPanel("addAIQuestion", null) }}
                />
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Edit' }}
                    text={this.context.getTranslation("compliance", "editAIQuestion")}
                    disabled={false}
                    checked={false}
                    onClick={() => { this.openPanel("editAIQuestion", this.state.aiQuestionId) }}
                />
                <CommandBarButton
                    styles={{ root: { marginTop: 10 } }}
                    iconProps={{ iconName: 'Delete' }}
                    text={this.context.getTranslation("compliance", "deleteAIQuestion")}
                    onClick={this.openDialogDelete}
                />
            </Stack>

            {/*Warning and error message*/}
            <AlertBar
                message={this.state.warningMessage}
                alertType={this.state.alertType}
                clearAlert={() => { this.setState({ warningMessage: "" }) }}
            />

            {this.state.isProcessing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}

            {/*AIQuestion datailList*/}
            {!this.state.isProcessing && < Stack style={{ marginTop: 10 }}>
                <Query
                    query={query.queryAllAIQuestions}
                    notifyOnNetworkStatusChange={true}
                >
                    {({ loading, error, data, refetch, networkStatus }) => {
                        this.refetchQueryAIQuestions = refetch
                        if (loading) return (
                            <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                        )
                        if (error) {
                            console.log(error)
                            return (<AlertBar message={"Error!"} alertType={"error"} />);
                        }
                        if (data.getAIQuestions == null || data.getAIQuestions.length === 0)
                            return (<AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />);
                        //to have always template group up in the table
                        var template = data.getAIQuestions.filter(pr => pr?.aiQuestionGroup?.generalGroup ?? false)
                        var otherGroups = data.getAIQuestions.filter(pr => !pr.aiQuestionGroup.generalGroup)
                        var sortData = [
                            ...template.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase())),
                            ...otherGroups.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))
                        ]
                        var groups = this.getAIQuestionGroups(sortData)
                        return (
                            <div>
                                <AIQuestionsListView selectAIQuestion={this.selectAIQuestion} aiQuestions={sortData} aiQuestionsGroup={groups} />
                            </div>
                        )
                    }}
                </Query>
            </Stack>}

            {/*Phrase panel*/}
            <Panel
                isOpen={this.state.isPanelOpen}
                onDismiss={this.hidePanel}
                isFooterAtBottom={false}
                onRenderHeader={this.getHeaderPanel}
                type={PanelType.medium}
            >
                <EditAIQuestionContainer
                    apol={this.props.apol}
                    auth={this.props.auth}
                    userRules={this.props.userRules}
                    hidePanel={this.hidePanel}
                    theme={this.props.theme} 
                    aiQuestionId={this.state.panelTitle === "addAIQuestion" ? null : this.state.aiQuestionId}
                    refreshAIQuestions={this.refreshAIQuestions}
                    setMessageError={this.setMessageError} />
            </Panel>

            {/*SafetyDialog for delete AI Question*/}
            <SafetyDialog
                title={this.context.getTranslation("compliance", "deleteAIQuestion")}
                subText={this.context.getTranslation("compliance", "confirmDelete")}
                hideDialog={this.state.hideDialogDelete}
                closeDialog={() => { this.setState({ hideDialogDelete: true }) }}
                ok={this.deleteAIQuestion}
            />
        </div>)
    }
}