import { Separator, Stack, TextField, PrimaryButton, IconButton, Dropdown } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import { IIconProps, initializeIcons } from '@fluentui/react';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';


export default class FormKeywords extends Component {
    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            newKeyword: "",
            frequence: this.props.frequence,
            selectedConnection: this.props.connectionInsideArea,
            connections: this.props.availableConnections,
            isFrequencyEnabled: this.props.frequence > 1
        }
    }

    addKeyword = () => {
        if (this.state.newKeyword === "") {
            this.setState({
                error: this.context.getTranslation("compliance", "errorEmptyKeywords")
            })
        } else if (this.props.keywords.includes(this.state.newKeyword)) {
            this.setState({
                error: this.context.getTranslation("compliance", "errorDuplicatedKeywords")
            })
        } else {
            this.setState({
                newKeyword: ""
            })
            this.props.updateKeywords(this.props.position, [...this.props.keywords, this.state.newKeyword])
        }
    }

    changeNewKeyword = (e, value) => {
        this.setState({
            newKeyword: value,
            error: ""
        })
    }

    changeNewFrequence = (e, value) => {
        this.setState({
            frequence: parseInt(value),
            error: ""
        })
        this.props.updateFrequenceInsideArea(this.props.position, parseInt(value))
    }

    deleteWord = (word) => {
        this.props.updateKeywords(this.props.position, this.props.keywords.filter(w => { return w !== word }))
        this.setState({
            error: ""
        })
    }

    deleteAllKeywords = () => {
        this.props.updateKeywords(this.props.position, [])
        this.setState({
            error: ""
        })
    }

    onChangeSelectConnection = (e, item) => {
        this.setState({
            selectedConnection: item.key,
            isFrequencyEnabled: !(item.key == "AND" || item.key == "OR")
        })
        this.props.updateConnectionInsideArea(this.props.position, item.key)
    }

    changeFrequencyVisibility = () => {
        if (this.state.selectedConnection != "NONE" && this.state.selectedConnection != null) {
            let isFrequencyEnabled = this.state.selectedConnection == "AT_LEAST" ? true : !this.state.isFrequencyEnabled
            if (!isFrequencyEnabled) {
                this.props.updateFrequenceInsideArea(this.props.position, parseInt(1))
            }
            this.setState(prevState => ({
                isFrequencyEnabled: isFrequencyEnabled,
                frequence: isFrequencyEnabled ? prevState.frequence : 1
            }))
        }
    }

    render() {
        return (
            <div>
                <Stack horizontal>
                    <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => this.props.deleteArea(this.props.position)} />
                    <span style={{ marginLeft: "5px", marginTop: "5px", marginRight: "5px", fontWeight: "bold" }}>{`${this.context.getTranslation("compliance", "keywords")} (${this.props.keywords.length})`}</span>
                    {!["NONE","AT_LEAST",null].includes(this.state.selectedConnection) && (<TooltipHost
                        content={this.context.getTranslation("compliance", "tooltipFrequencyButton")}
                        setAriaDescribedBy={false}
                    >
                        <IconButton style={this.state.isFrequencyEnabled ? { color: "gray" } : {}} iconProps={{ iconName: 'Info' }} aria-label="Emoji" disabled={false} onClick={this.changeFrequencyVisibility} />
                    </TooltipHost>)}
                </Stack>
                <Stack horizontal style={{ marginTop: "10px", marginLeft: "30px" }}>
                    <Stack.Item align="center">
                        <TextField value={this.state.newKeyword} onChange={this.changeNewKeyword} disabled={this.props.disabled} />
                    </Stack.Item>
                    <Stack.Item align="center">
                        <PrimaryButton style={{ marginLeft: "20px" }} text={this.context.getTranslation("compliance", "add")} onClick={this.addKeyword} allowDisabledFocus disabled={this.props.disabled} />
                    </Stack.Item>
                    <Stack.Item align="center">
                        <PrimaryButton style={{ marginLeft: "20px" }} text={this.context.getTranslation("compliance", "deleteAll")} onClick={this.deleteAllKeywords} allowDisabledFocus disabled={this.props.disabled} />
                    </Stack.Item>
                    <Stack.Item align="center">
                        {this.props.keywords.length > 1 && < Dropdown
                            style={{ marginLeft: "20px" }}
                            placeholder={this.context.getTranslation("compliance", "selectConnection")}
                            label=""
                            selectedKey={this.state.selectedConnection}
                            onChange={this.onChangeSelectConnection}
                            options={this.state.connections}
                            disabled={this.props.disabled}
                        />}
                    </Stack.Item>
                    {this.state.isFrequencyEnabled && (<Stack.Item align="center" style={{ marginLeft: "20px", width: "50px" }}>
                        <TextField min={1} type="Number" value={this.state.frequence} onChange={this.changeNewFrequence} disabled={this.props.disabled} />
                    </Stack.Item>)}
                </Stack>
                {this.props.keywordsCheck &&
                    <Stack horizontal style={{ marginLeft: "30px" }}>
                        <Stack.Item align="center">
                            <span style={{ color: "red", fontSize: "10px" }}>{this.state.error}</span>
                        </Stack.Item>
                    </Stack>
                }
                <div style={{ maxHeight: '20vh', position: 'relative', overflow: 'auto' }}>
                    {this.props.keywords.map(w => {
                        return (<Stack key={w} horizontal style={{ marginTop: "5px", marginLeft: "30px" }}>
                            <Stack.Item align="center"> <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => { this.deleteWord(w) }} disabled={this.props.disabled} /></Stack.Item>
                            <Stack.Item align="center"><span >{w}</span></Stack.Item>
                        </Stack>)
                    })}
                </div>
            </div>
        )
    }
}