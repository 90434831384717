import React, { Component } from 'react'
import { CommandBarButton, Spinner, Stack, Panel, PanelType } from 'office-ui-fabric-react';
import AlertBar from '../../CustomComponents/AlertBar';
import SafetyDialog from '../../CustomComponents/SafetyDialog';
import { MyContext } from '../../../context';
import EditAICategorizationContainer from './Panels/EditAICategorizationContainer';
import { Query } from "react-apollo";
import AICategorizationList from './AICategorizationList';
import * as QueryAICat from "./AICategorizationQuery"
import { NetworkStatus } from 'apollo-client';


export default class AICategorization extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);

        this.state = {
            isPanelOpen: false,
            categorizationId: null,
            action: "",
            warningMessage: "",
            hideDialogDelete: true,
            isProcessing: false,
            alertMessagePanel: ""
        };

    }

    refreshAICategorization = null;

    refreshAICat = () => {
        this.refreshAICategorization()
    }

    openPanel = (action, categorizationId = null) => {
        var state = {}
        switch (action) {
            case "create":
                state = {
                    isPanelOpen: true,
                    action: "create",
                    panelTitle: "createCategorizationPanel"
                }
                break;
            case "edit":
                if (categorizationId) {
                    state = {
                        ...state,
                        isPanelOpen: true,
                        action: "edit",
                        panelTitle: "editCategorizationPanel"
                    }
                }
                else {
                    state = { warningMessage: this.context.getTranslation("compliance", "selectCategorization"), alertType: "warning" }
                }
                break;
        }
        this.setState(state)
    }

    hidePanel = () => {
        this.setState({ isPanelOpen: false, alertMessagePanel: "" });
    };

    getHeader = () => {
        return (
            <div style={{ flex: "auto", marginLeft: "20px" }}>
                <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>{this.context.getTranslation("compliance", this.state.panelTitle)}</div>
                <AlertBar
                    message={this.state.alertMessagePanel}
                    alertType={"error"}
                    clearAlert={this.clearAlert}
                />
            </div >
        )
    }

    clearAlert = () => {
        if (this.state.alertMessagePanel !== "") {
            this.setState({
                alertMessagePanel: ""
            })
        }
    }

    openDialogDelete = () => {
        if (this.state.categorizationId !== null) {
            this.setState({ hideDialogDelete: false })
        } else {
            this.setState({
                warningMessage: this.context.getTranslation("compliance", "selectCategorization"),
                alertType: "warning"
            })
        }
    }

    deleteCategorization = () => {
        this.setState({ hideDialogDelete: true, isProcessing: true })
        if (this.state.categorizationId !== null) {
            const id = this.state.categorizationId
            this.props.apol
                .mutate({
                    mutation: QueryAICat.deleteAICategorization,
                    variables: { id }
                })
                .then((result) => {
                    if (result.data.deleteAICategorization.status !== "ok") {
                        this.setState({
                            warningMessage: this.context.getTranslation("compliance", result.data.deleteAICategorization.text),
                            alertType: result.data.deleteAICategorization.status,
                            isProcessing: false
                        })
                    } else {
                        this.setState({
                            isProcessing: false
                        })
                        this.refreshAICat();
                    }
                });
        }
    }

    selectAICat = (id) => {
        this.setState({
            categorizationId: id,
            warningMessage: ""
        });
    }

    setMessageError = (message) => {
        this.setState({
            alertMessagePanel: message
        })
    }


    render() {
        const { auth } = this.props
        return (
            <div style={{ overflow: "auto", maxHeight: window.innerHeight - 120, position: 'relative', marginTop: "5px" }}>
                {(<div>
                    {/*Manage button add and edit */}
                    <Stack horizontal styles={{ root: { height: 44 } }}>
                        <CommandBarButton
                            styles={{ root: { marginTop: 10 } }}
                            iconProps={{ iconName: 'Add' }}
                            text={this.context.getTranslation("compliance", "addCategorization")}
                            //disabled={}
                            checked={false}
                            onClick={() => this.openPanel("create")}
                        />
                        <CommandBarButton
                            styles={{ root: { marginTop: 10 } }}
                            iconProps={{ iconName: 'Edit' }}
                            text={this.context.getTranslation("compliance", "editCategorization")}
                            //disabled={}
                            checked={false}
                            onClick={() => { this.openPanel("edit", this.state.categorizationId) }}
                        />
                        <CommandBarButton
                            styles={{ root: { marginTop: 10 } }}
                            iconProps={{ iconName: 'Delete' }}
                            //disabled={}
                            text={this.context.getTranslation("compliance", "deleteCategorization")}
                            onClick={this.openDialogDelete}
                        />
                    </Stack>

                    {/*Warning and error message*/}
                    <AlertBar
                        message={this.state.warningMessage}
                        alertType={this.state.alertType}
                        clearAlert={() => { this.setState({ warningMessage: "" }) }}
                    />

                    {this.state.isProcessing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}


                    {!this.state.isProcessing && <Stack style={{ marginTop: 10 }}>
                        <Query
                            query={QueryAICat.queryAllAICategorizations}
                            variables={{ auth }}
                            notifyOnNetworkStatusChange={true}>
                            {({ loading, error, data, refetch, networkStatus }) => {
                                this.refreshAICategorization = refetch
                                if (loading || networkStatus === NetworkStatus.refetch) return (
                                    <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                )
                                if (error) {
                                    console.log(error)
                                    return (<AlertBar message={"Error!"} alertType={"error"} />);
                                }
                                if (data.getAICategorizations == null)
                                    return (<AlertBar message={this.context.getTranslation("compliance", "noData")} alertType={"warning"} />);
                                return (
                                    <AICategorizationList
                                        selectAICat={this.selectAICat}
                                        aiCategorization={data.getAICategorizations} />
                                )
                            }}
                        </Query>

                    </Stack>
                    }

                    <Panel
                        isOpen={this.state.isPanelOpen}
                        onDismiss={this.hidePanel}
                        isFooterAtBottom={false}
                        onRenderHeader={this.getHeader}
                        type={PanelType.medium}
                    >
                        <EditAICategorizationContainer
                            id={this.state.action === "create" ? null : this.state.categorizationId}
                            apol={this.props.apol}
                            auth={this.props.auth}
                            hidePanel={this.hidePanel}
                            refreshAICat={this.refreshAICat}
                            userRules={this.props.userRules}
                            setMessageError={this.setMessageError}
                        />
                    </Panel>

                    {/*SafetyDialog for delete categorization*/}
                    <SafetyDialog
                        title={this.context.getTranslation("compliance", "deleteCategorization")}
                        subText={this.context.getTranslation("compliance", "confirmDelete")}
                        hideDialog={this.state.hideDialogDelete}
                        closeDialog={() => { this.setState({ hideDialogDelete: true }) }}
                        ok={this.deleteCategorization}
                    />
                </div>
                )}
            </div>
        )
    }

}
