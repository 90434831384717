import React from 'react';
import "./../../ReportView/ReportDetailContainer/ReportDetailContainer.scss"
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import DetailViewOverlay from "./../../DetailView/DetailViewOverlay"

import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { FontSizes, Stack, Text } from 'office-ui-fabric-react/lib';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import zipcelx from 'zipcelx';
import { MyContext } from '../../../context';

const config = {
    filename: 'report',
    sheet: {
        data: [
            [
                {
                    value: "created",
                    type: 'string'
                }, {
                    value: "userLogin",
                    type: 'string'
                }, {
                    value: "userId",
                    type: 'string'
                }, {
                    value: "type",
                    type: 'string'
                }, {
                    value: "name",
                    type: 'string'
                }, {
                    value: "title",
                    type: 'string'
                }, {
                    value: "group",
                    type: 'string'
                }, {
                    value: "text",
                    type: 'string'
                }, {
                    value: "idType",
                    type: 'string'
                }, {
                    value: "itemId",
                    type: 'string'
                },{
                    value: "scenarioId",
                    type: 'string'
                }, {
                    value: "details",
                    type: 'string'
                }
            ]
        ]
    }
};

export interface IDocument {
    conversationType: Boolean,
    conversationID: String,
    conversationDirection: String,
    initiator: String,
    date: String,
    starttime: String,
    endtime: String,
    duration: String,
    attendees: String,
    notes: String,
    transcription: String

}

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}



export default class AuditDetailContainer extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: IDocument[];
    constructor(props: {}) {
        super(props);

        this.detailContainerRef = React.createRef();

        this._allItems = _generateDocuments(props.data.auditEvents);

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "date")}</>)}</MyContext.Consumer>,
                fieldName: 'createdMs',
                minWidth: 120,
                maxWidth: 140,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'number',
                isPadded: true,
                onRender: (item: IDocument) => {
                    return <span>{item.created}</span>;
                }
            },
            {
                key: 'column4a',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "userLogin")}</>)}</MyContext.Consumer>,
                fieldName: 'userLogin',
                minWidth: 180,
                maxWidth: 200,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.userLogin}</span>;
                }
            }
            , {
                key: 'column5',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "title")}</>)}</MyContext.Consumer>,
                fieldName: 'title',
                minWidth: 200,
                maxWidth: 250,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.title}</span>;
                }
            }
            ,
            {
                key: 'column8',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "text")}</>)}</MyContext.Consumer>,
                fieldName: 'text',
                minWidth: 660,
                maxWidth: 900,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.text}</span>;
                },
                isPadded: true
            },
            {
                key: 'column7a',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "idType")}</>)}</MyContext.Consumer>,
                fieldName: 'idType',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.idType}</span>;
                },
                isPadded: true
            },
            {
                key: 'column7',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "itemId")}</>)}</MyContext.Consumer>,
                fieldName: 'conversationId',
                minWidth: 230,
                maxWidth: 230,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.conversationId}</span>;
                },
                isPadded: true
            },
            {
                key: 'column10',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "ScenarioID")}</>)}</MyContext.Consumer>,
                fieldName: 'scenarioId',
                minWidth: 230,
                maxWidth: 230,
                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.data.scenarioId}</span>;
                },
                isPadded: true
            },
            {
                key: 'column6',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "group")}</>)}</MyContext.Consumer>,
                fieldName: 'group',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.group}</span>;
                }
            },
            {
                key: 'column3',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "type")}</>)}</MyContext.Consumer>,
                fieldName: 'type',
                minWidth: 180,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item: IDocument) => {
                    return <span>{item.type}</span>;
                },
                isPadded: true
            },
            {
                key: 'column2',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "key")}</>)}</MyContext.Consumer>,
                fieldName: 'key',
                minWidth: 230,
                maxWidth: 230,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item: IDocument) => {
                    return <span>{item.id}</span>;
                },
                isPadded: true
            },
            {
                key: 'column4',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "userId")}</>)}</MyContext.Consumer>,
                fieldName: 'userId',
                minWidth: 230,
                maxWidth: 230,
                isResizable: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.userId}</span>;
                }
            },
            {
                key: 'column9',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("detailContainer", "details")}</>)}</MyContext.Consumer>,
                fieldName: 'details',

                isResizable: true,
                data: 'string',
                onColumnClick: this._onColumnClick,

            }
        ];
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            items: this._allItems,
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: false,
            isCompactMode: false,
            isDialogOpen: false
        };

    }

    _onItemInvoked = (item) => {
        this.detailContainerRef.current.showPanelItem(item.id, item.data)
    }

    _onExport = (ev) => {
        var itemList = [];
        itemList.push(config.sheet.data[0].slice());
        this.state.items.forEach((anItem) => {
            itemList.push([
                {
                    value: anItem.created,
                    type: 'string'
                }, {
                    value: anItem.userLogin,
                    type: 'string'
                }, {
                    value: anItem.userId,
                    type: 'string'
                }, {
                    value: anItem.key,
                    type: 'string'
                }, {
                    value: anItem.name,
                    type: 'string'
                }, {
                    value: anItem.title,
                    type: 'string'
                }, {
                    value: anItem.group,
                    type: 'string'
                }, {
                    value: anItem.text,
                    type: 'string'
                }, {
                    value: anItem.idType,
                    type: 'string'
                }, {
                    value: anItem.conversationId,
                    type: 'string'
                }, {
                    value: anItem.data.scenarioId,
                    type: 'string'
                }, {
                    value: anItem.details,
                    type: 'string'
                }]);
        });
        config.sheet.data = itemList;
        zipcelx(config)
        this.setState({ isDialogOpen: true });
    }



    _dialogClose = () => this.setState({ isDialogOpen: false })

    render() {
        const { columns, items, isModalSelection } = this.state;
        const gridStyles: Partial<IDetailsListStyles> = {
            root: {
                overflowX: 'scroll',
                selectors: {
                    '& [role=grid]': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        height: window.innerHeight - 100,
                    },
                },
            },
            headerWrapper: {
                flex: '0 0 auto',
            },
            contentWrapper: {
                flex: '1 1 auto',
                overflowY: 'auto',
                overflowX: 'hidden',
            },
        };
        return (<MyContext.Consumer>
            {(context) => (
                <div id="main-wrapper">
                    <DetailViewOverlay authorization={this.props.authorization} client={this.props.apol} ref={this.detailContainerRef} loading={true} userRules={this.props.userRules} theme={this.props.theme} />

                    <Dialog
                        isOpen={this.state.isDialogOpen}
                        type={DialogType.close}
                        onDismiss={this._dialogClose.bind(this)}
                        title={context.getTranslation("detailContainer", "excelExport")}
                        subText={context.getTranslation("detailContainer", "excelExportMessage")}
                        isBlocking={false}
                        closeButtonAriaLabel='Close'
                    />
                    <header className="report-panel-header ms-Grid-row" style={{ boxShadow: Depths.depth8 }}>
                        {/* == Recording Header ========================================= */}

                        <Stack horizontal>

                            <Text style={{ padding: "5px" }} >{context.getTranslation("detailContainer", "report")}  {this.props.data.title}</Text>
                            <Stack.Item grow={2} ><div /></Stack.Item>
                            <SearchBox
                                placeholder="Search"
                                onChange={(event, newValue) => {
                                    if (event != null) event.persist();
                                    this.setState({
                                        items: newValue ? this._allItems.filter(i => (i.text.toLowerCase().indexOf(newValue.toLowerCase()) > -1 || i.title.toLowerCase().indexOf(newValue.toLowerCase()) > -1 || i.conversationId.toLowerCase().indexOf(newValue.toLowerCase()) > -1 || i.userLogin.toLowerCase().indexOf(newValue.toLowerCase()) > -1)) : this._allItems
                                    });
                                }}
                            />
                            <CommandBarButton
                                iconProps={{ iconName: 'Download' }}
                                text={context.getTranslation("detailContainer", "exportReport")}
                                style={{ padding: "7px", cursor: "pointer", fontSize: FontSizes.Icon }}
                                onClick={this._onExport}
                            />
                        </Stack>
                    </header>
                    <div className="report-details-wrapper">

                        {/* == Player and meta data. ===================================== */}

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className="ms-Grid-row">



                                </div>
                                <MarqueeSelection selection={this._selection}>
                                    <DetailsList
                                        items={items}
                                        columns={columns}
                                        selectionMode={isModalSelection ? SelectionMode.multiple : SelectionMode.none}
                                        setKey="set"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        onActiveItemChanged={this._onItemInvoked}
                                        isHeaderVisible={true}
                                        selection={this._selection}
                                        selectionPreservedOnEmptyClick={true}
                                        enterModalSelectionOnTouch={true}
                                        ariaLabelForSelectionColumn="Toggle selection"
                                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                        styles={gridStyles}
                                    />
                                </MarqueeSelection>
                            </div> {/* end of main contenten */}
                        </div>
                    </div>
                </div>
            )
            }
        </MyContext.Consumer>);

    }
    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
            default:
                return `${selectionCount} items selected`;
        }
    }


    _onItemInvoked = (item) => {
        if (this.isAllowedToOpen(item))
            this.detailContainerRef.current.showPanelItem(item.conversationId, item.data)
    }

    isAllowedToOpen = (item) => {
        if (item.type.toUpperCase().includes("CATEGORY") || item.type.toUpperCase().includes("COMPLIANCEQUESTION")
            || item.type.toUpperCase().includes("ADHERENCEPHRASE") || item.type.toUpperCase().includes("PATTERN")
            || item.type.toUpperCase().includes("POLICYRULE") || item.type.toUpperCase().includes("POLICY")) {
            return false;
        } else {
            return true;
        }
    }

    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };
}



function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _generateDocuments(DataArr) {
    const items: IDocument[] = [];
    if (DataArr !== undefined) {
        for (let i = 0; i < DataArr.length; i++) {
            items.push({
                id: DataArr[i].id,
                created: DataArr[i].created,
                createdMs: DataArr[i].createdMs,
                type: DataArr[i].key,
                group: DataArr[i].group,
                name: DataArr[i].name,
                title: DataArr[i].title,
                text: DataArr[i].text,
                userId: DataArr[i].userId,
                userLogin: DataArr[i].userLogin,
                idType: DataArr[i].idType,
                details: DataArr[i].details,
                conversationId: DataArr[i].conversationId ? DataArr[i].conversationId : "",
                data: DataArr[i]
            })
        }
    }
    return items;
}


