import React from 'react';
import { Text } from 'office-ui-fabric-react';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from 'office-ui-fabric-react';

import './ASCFooter.scss';

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    alignItems: 'flex-end'
  }
};
const stackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    height: 50,
    justifyContent: 'center'
  }
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10
};


// Styles
const ascFooter2 = (props) => {
  return (

      <Stack horizontal styles={stackStyles} tokens={stackTokens}>
       <Stack.Item grow styles={stackItemStyles}>
        <Text>&copy; 2020 ASC Technologies AG</Text>
      </Stack.Item>


       <Stack.Item grow styles={stackItemStyles}>
        <ul className="footerNav">
          <li><a href="https://www.asc.de/english/ASC_Recording_Insights_for_Microsoft_Teams.html">Contact</a></li>
          <li><a href="./impressum/">Legal Statements</a></li>
          <li><a href="./privacy/">Privacy Policy</a></li>
          {/* <li><a href="#">Sitemap</a></li> */}
          <li><a href="./termsofuse/">Terms of use</a></li>
        </ul>
      </Stack.Item>
    </Stack>
  );
}

export default ascFooter2;
