import React from 'react';
import {
    Checkbox,
    ChoiceGroup,
    FontWeights,
    TextField,
    Stack
} from "office-ui-fabric-react";
import { MyContext } from '../../../../context';

const KeepDeleteSettings = ({ updateState, data, type, ...props }) => {
    return (
        <MyContext.Consumer>
            {(context) => (<Stack>
                {/*first layer*/}
                <Stack.Item style={{ marginLeft: "25px" }}>
                    <ChoiceGroup
                        selectedKey={data.startMode}
                        style={{ width: "200px" }}
                        options={[
                            {
                                key: 'Keep',
                                text: context.getTranslation("configuration", "keep"),
                                styles: { field: { fontWeight: FontWeights.regular } }

                            },
                            {
                                key: 'Delete',
                                text: context.getTranslation("configuration", "delete"),
                                styles: { field: { fontWeight: FontWeights.regular } }

                            },
                        ]}
                        onChange={(event, newValue) => { updateState(type, newValue.key, "startMode") }}
                        label={context.getTranslation("configuration", "keepDeleteConfiguration")}
                    />
                    

                    {/*DO NOT REMOVE WILL BE USED LATER*/}
                    {/*<Checkbox*/}
                    {/*    label={context.getTranslation("configuration", "keepDeleteDuringWrapupTimeOnly")}*/}
                    {/*    checked={data.isWrapUpOnly}*/}
                    {/*    styles={props.styleCheckbox}*/}
                    {/*    onChange={(event, newValue) => { updateState(type, newValue, "isWrapUpOnly") }}*/}

                    {/*/>*/}
                    <Checkbox
                        label={context.getTranslation("configuration", "specificSettings")}
                        checked={data.specificSettings}
                        styles={props.styleCheckbox}
                        onChange={(event, newValue) => { updateState(type, newValue, "specificSettings") }}

                    />
                </Stack.Item>
                {/*second layer*/}
                {data.specificSettings && <>
                    <Stack.Item style={{ marginLeft: "50px" }}>
                        <ChoiceGroup
                            selectedKey={data.recordingMode}
                            style={{ width: "200px" }}
                            options={[
                                {
                                    key: 'Bulk',
                                    text: context.getTranslation("configuration", "bulkRecordingRecMode"),
                                    styles: { field: { fontWeight: FontWeights.regular } }

                                },
                                {
                                    key: 'Demand',
                                    text: context.getTranslation("configuration", "recordOnDemandRecMode"),
                                    styles: { field: { fontWeight: FontWeights.regular } }

                                },
                                {
                                    key: 'DemandForBulk',
                                    text: context.getTranslation("configuration", "recordOnDemandForBulk"),
                                    styles: { field: { fontWeight: FontWeights.regular } }

                                },
                            ]}
                            onChange={(event, newValue) => { updateState(type, newValue.key, "recordingMode") }}
                        />

                        {/*DO NOT REMOVE WILL BE USED LATER */}

                        {/*<Checkbox*/}
                        {/*    label={context.getTranslation("dashboard", "widgetMenuActions")}*/}
                        {/*    checked={data.actions}*/}
                        {/*    styles={props.styleCheckbox}*/}
                        {/*    onChange={(event, newValue) => { updateState(type, newValue, "actions") }}*/}

                        {/*/>*/}
                    </Stack.Item>

                    {/*DO NOT REMOVE WILL BE USED LATER */}

                    {/*third layer*/}
                    { /*
                        {data.actions && <>
                            <Stack.Item style={{ marginLeft: "75px" }}>
                                <Checkbox
                                    label={context.getTranslation("configuration", "countdownAlertCheckbox")}
                                    checked={data.hasPopupAlert}
                                    styles={props.styleCheckbox}
                                    onChange={(event, newValue) => { updateState(type, newValue, "hasPopupAlert") }}

                                />

                                <Stack horizontal>
                                    <Checkbox
                                        label={context.getTranslation("configuration", "sendToDo")}
                                        checked={data.isSendToDo}
                                        styles={props.styleCheckbox}
                                        onChange={(event, newValue) => { updateState(type, newValue, "isSendToDo") }}

                                    />
                                    <div style={{ marginTop: "5px", marginLeft: "10px" }}>

                                        <TextField type={"number"}
                                            value={data.toDoTimer}
                                            onChange={(event, newValue) => { updateState(type, parseInt(newValue), "toDoTimer") }}
                                            disabled={!data.isSendToDo}
                                            min="1"
                                            max="48"
                                            errorMessage={data.toDoTimer < 1 ? "Minimum value is 1 hour" : data.toDoTimer > 48 ? "Maximum value is 48 hours" : ""}
                                        />
                                    </div>
                                    <span style={{ marginTop: "8px", marginLeft: "10px" }}>hour(s)</span>
                                </Stack>
                            </Stack.Item>
                            
                             Foutrh layer   
                                
                            {data.isSendToDo && <Stack.Item style={{ marginLeft: "100px" }}>
                                <Checkbox
                                    label={context.getTranslation("configuration", "sendNotificationToRecOwner")}
                                    checked={data.hasUserNotification}
                                    styles={props.styleCheckbox}
                                    onChange={(event, newValue) => { updateState(type, newValue, "hasUserNotification") }}

                                />
                            </Stack.Item>
                            }

                        </>
                    }
                    */}
                </>}



            </Stack>)
            }</MyContext.Consumer>
    )
}

export default KeepDeleteSettings;