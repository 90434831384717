import * as React from 'react';
import { Checkbox } from "office-ui-fabric-react";
import { MyContext } from '../../../context';
import { Component } from 'react'
import SafetyDialog from '../../CustomComponents/SafetyDialog'

export default class RestrictedAccessForm extends Component {

    static contextType = MyContext

    constructor(props) {

        super(props);
        if (this.props.data.restrictedAccess !== null) {
            let ownFolder = this.props.data.restrictedAccess.ownFolder
            this.state = {
                ownFolder: {
                    addConversations: ownFolder.addConversations,
                    upserFolder: ownFolder.upserFolder,
                    removeConversations: ownFolder.removeConversations,
                    exportFolders: ownFolder.exportFolders,
                    deleteFolders: ownFolder.deleteFolders,
                    shareFolders: ownFolder.shareFolders
                },
              hideDialogSafe: true
            }
        } else {
            this.state = {
                ownFolder: { addConversations: false, upserFolder: true, removeConversations: false, exportFolders: false, deleteFolders: false, shareFolders: false }
            }
            this.createRestictedAccess(this.state.ownFolder)
        }
    }

    createRestictedAccess = (own) => {
        let restrictedAccess = {
            ownFolder: own
        }
        this.props.updateRestrictedAccess(restrictedAccess)
    }

    updateOwnFields = (newValue, field) => {
        let nextOwnFolderObject = { ...this.state.ownFolder, [field]: newValue }
        this.setState({ ownFolder: nextOwnFolderObject })
        this.createRestictedAccess(nextOwnFolderObject)
    }


    render() {
        return (
            <div style={{ paddingLeft: '30px', marginTop: '10px' }}>
                <Checkbox
                    label={this.context.getTranslation("configuration", "addFolders")}
                    checked={this.state.ownFolder.upserFolder}
                    onChange={(event, newValue) => { this.updateOwnFields(newValue, "upserFolder"); }}
                />
                <Checkbox
                    label={this.context.getTranslation("configuration", "deleteFolders")}
                    checked={this.state.ownFolder.deleteFolders}
                    onChange={(event, newValue) => { this.updateOwnFields(newValue, "deleteFolders"); }}
                />
                <Checkbox
                    label={this.context.getTranslation("configuration", "shareFolders")}
                    checked={this.state.ownFolder.shareFolders}
                    onChange={(event, newValue) => {
                        this.setState({
                          ownFolder:{
                            ...this.state.ownFolder,
                            shareFolders: newValue
                          },
                          hideDialogSafe: newValue
                        });
                      this.updateOwnFields(true, "shareFolders")
                      }}
                />
                <SafetyDialog
                  title={this.context.getTranslation("configuration", "removeShareAlertTitle")}
                  subText={this.context.getTranslation("configuration", "removeShareAlertText")}
                  hideDialog={this.state?.hideDialogSafe ?? true}
                  closeDialog={() => {
                    this.setState({ hideDialogSafe: true, })
                    this.updateOwnFields(true, "shareFolders")
                  }}
                  ok={() => {
                    this.setState({ hideDialogSafe: true })
                    this.updateOwnFields(false, "shareFolders")
                  }}
                />
                <Checkbox
                    label={this.context.getTranslation("configuration", "addConversations")}
                    checked={this.state.ownFolder.addConversations}
                    onChange={(event, newValue) => { this.updateOwnFields(newValue, "addConversations"); }}
                />
                <Checkbox
                    label={this.context.getTranslation("configuration", "removeConversations")}
                    checked={this.state.ownFolder.removeConversations}

                    onChange={(event, newValue) => { this.updateOwnFields(newValue, "removeConversations"); }}
                />
                <Checkbox
                    label={this.context.getTranslation("configuration", "exportFolders")}
                    checked={this.state.ownFolder.exportFolders}
                    onChange={(event, newValue) => { this.updateOwnFields(newValue, "exportFolders"); }}
                />
            </div>
        )
    }

}