import * as React from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { MyContext } from '../../../context';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Toggle } from "office-ui-fabric-react";

const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px'
    }
};

export interface IDocument {
    name: String;
    group: String;

}

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}

let displayArchivedJobs = false;


export default class JobList extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: IDocument[];

    constructor(props) {
        super(props);

        this._allItems = _generateDocuments(this.props.dataExport, this.props.dataImport, this.props.dataIntegration);
        let columns: IColumn[] = [
            {
                key: 'column20',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "options")}</>)}</MyContext.Consumer>,
                isIconOnly: true,
                isResizable: false,
                fieldName: 'name',
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    if (item.group == "Export Job")
                        return <a style={{ cursor: "pointer" }} onClick={() => { this.props.func(item, 24) }}><Icon iconName={"Settings"} /></a>
                    else if (item.group == "Import Job")
                        return <a style={{ cursor: "pointer" }} onClick={() => { this.props.func(item, 25) }}><Icon iconName={"Settings"} /></a>
                    else
                        return <a style={{ cursor: "pointer" }} onClick={() => { this.props.func(item, 26) }}><Icon iconName={"Settings"} /></a>

                }
            },
            { key: 'name', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "name")}</>)}</MyContext.Consumer>, fieldName: 'name', minWidth: 200, maxWidth: 200 },
            {
                key: 'enabled', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "enabled")}</>)}</MyContext.Consumer>, fieldName: 'enabled', minWidth: 60, maxWidth: 70,
                onRender: (item) => {
                    if (item.enabled)
                        return <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "on")}</>)}</MyContext.Consumer>
                    else
                        return <MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "off")}</>)}</MyContext.Consumer>
                }
            },
            { key: 'group', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "group")}</>)}</MyContext.Consumer>, fieldName: 'group', minWidth: 70, maxWidth: 90 },
            { key: 'state', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "state")}</>)}</MyContext.Consumer>, fieldName: 'state', minWidth: 100, maxWidth: 160 },
            {
                key: 'nextRun', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "nextRun")}</>)}</MyContext.Consumer>, fieldName: 'nextRun', minWidth: 150, maxWidth: 150,
                onRender: (item) => {
                    if (item.nextRun !== "0001-01-01T00:00:00" && item.nextRun !== undefined) {
                        var parsedDate = new Date(item.nextRun);
                        return parsedDate.toLocaleString(this.props.authorization.locale, { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
                    } else {
                        return ""
                    }
                }
            },
            {
                key: 'lastSuccessfulRun', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "lastSuccessfulRun")}</>)}</MyContext.Consumer>, fieldName: 'lastSuccessfulRun', minWidth: 150, maxWidth: 150,
                onRender: (item) => {
                    if (item.lastSuccessfulRun !== "0001-01-01T00:00:00" && item.lastSuccessfulRun !== undefined) {
                        var parsedDate = new Date(item.lastSuccessfulRun);
                        return parsedDate.toLocaleString(this.props.authorization.locale, { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
                    } else {
                        return ""
                    }
                }
            },
            { key: 'numberOfConversations', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "numberOfConversations")}</>)}</MyContext.Consumer>, fieldName: 'numberOfConversations', minWidth: 165, maxWidth: 165 },
            { key: 'totalCalls', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "totalCalls")}</>)}</MyContext.Consumer>, fieldName: 'totalCalls', minWidth: 250, maxWidth: 200 },
            { key: 'remainingPackageSize', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "remainingPackageSize")}</>)}</MyContext.Consumer>, fieldName: 'remainingPackageSize', minWidth: 160, maxWidth: 160 },
            { key: 'id', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "id")}</>)}</MyContext.Consumer>, fieldName: 'id', minWidth: 250, maxWidth: 200 },
        ];

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: true,
            count: 0
        };
    }

    render() {
        const { columns, isCompactMode } = this.state;

        return (
            <Fabric>
                <CommandBar
                    items={this.getItems()}

                />
                <DetailsList
                    key={"key" + this.state.count}
                    items={_generateDocuments(this.props.dataExport, this.props.dataImport, this.props.dataIntegration)}
                    compact={isCompactMode}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    selectionMode={SelectionMode.single}
                    onRenderRow={(props, defaultRender) => (
                        <div className='red'>
                            {props.item.isArchived
                                ? defaultRender({ ...props, styles: { root: { backgroundColor: '#EDEDED', color: '#666666' } } })
                                : defaultRender({ ...props })
                            }
                        </div>
                    )}
                />
            </Fabric>
        );
    }

    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }


    _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isCompactMode: checked });
    };

    _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isModalSelection: checked });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        let tmt;
        console.log(text)
        console.log(this._allItems)
        tmt = this._allItems.filter(i => i.name.includes(text) || i.name.toLocaleLowerCase().includes(text));
        console.log(tmt)

        this.setState({
            count: (this.state.count + 1),
            items: tmt
        });
    };


    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                //return 'One item selected: ' + (this._selection.getSelection()[0]).name;
                return { id: this._selection.getSelection()[0].id, type: this._selection.getSelection()[0].group };
            default:
                return `${selectionCount} Items selected`;
        }
    }
    openSelect = () => {
        if (this._selection.getSelection().length > 0) {
            this.props.func(this._selection.getSelection(), 4);
        }
    };
    openNew = () => {

        this.props.func(null, 24);

    };

    getItems = () => {

        let addButton = {
            key: 'add',
            name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addJob")}</>)}</MyContext.Consumer>,
            iconProps: { iconName: 'Add' },
            subMenuProps: {
                items: [
                    {
                        key: 'exportJob',
                        name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "exportJob")}</>)}</MyContext.Consumer>,
                        iconProps: {
                            iconName: 'Export'
                        },
                        onClick: () => { this.props.func(null, 24) },
                    },
                    {
                        key: 'importJob',
                        name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "importJob")}</>)}</MyContext.Consumer>,
                        iconProps: {
                            iconName: 'Import'
                        },
                        onClick: () => { this.props.func(null, 25) },
                    },
                    {
                        key: 'integrationJob',
                        name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "integrationJob")}</>)}</MyContext.Consumer>,
                        iconProps: {
                            iconName: 'CloudImportExport'
                        },
                        onClick: () => { this.props.func(null, 26) },
                    }
                ]
            }
        };

        let displayArchive = {
            key: 'displayArchivedJobs',
            name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "displayArchivedJobs")}</>)}</MyContext.Consumer>,
            onRender: () => {
                return <>

                    <div style={{ float: "left", marginRight: "10px", marginLeft: "20px", alignSelf: "center" }}><MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "displayArchivedJobs")}</>)}</MyContext.Consumer></div>
                    <div style={{ float: "right", alignSelf: "center", marginTop: "8px" }}>
                        <Toggle
                            defaultChecked={displayArchivedJobs}
                            onText={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "on")}</>)}</MyContext.Consumer>}
                            offText={<MyContext.Consumer>{(context) => (<> {context.getTranslation("settings", "off")}</>)}</MyContext.Consumer >}
                            onChange={(ev, checked) => {
                                this.setDisplayArchive(checked);
                            }}
                        /></div>
                </>
            }
        };

        return [addButton, displayArchive];

    };

    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };
    setDisplayArchive(val) {
        displayArchivedJobs = val;
        this.forceUpdate();
    }

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _generateDocuments(DataArrExport, DataArrImport, DataArrIntegration) {
    const items = [];
    const itemsArchived = [];
    if (DataArrExport !== undefined && DataArrExport != null) {
        for (let i = 0; i < DataArrExport.length; i++) {
            DataArrExport[i].group = "Export Job";

            if (DataArrExport[i].isArchived) {
                itemsArchived.push(DataArrExport[i]);

            } else {
                items.push(DataArrExport[i]);
            }

        }
    }
    if (DataArrImport !== undefined && DataArrImport != null) {
        for (let i = 0; i < DataArrImport.length; i++) {
            DataArrImport[i].group = "Import Job";

            if (DataArrImport[i].isArchived) {
                itemsArchived.push(DataArrImport[i]);

            } else {
                items.push(DataArrImport[i]);
            }

        }
    }
    if (DataArrIntegration !== undefined && DataArrIntegration != null) {
        for (let i = 0; i < DataArrIntegration.length; i++) {
            DataArrIntegration[i].group = "Integration Job";
            items.push(DataArrIntegration[i]);
        }
    }
    var resultItems = _copyAndSort(items, "group", false);
    var archievedItems = _copyAndSort(itemsArchived, "group", false);

    if (displayArchivedJobs) {
        resultItems = resultItems.concat(archievedItems);

    }
    return resultItems;
}

function trim(sel) {
    let out;
    const max = 40;
    if (sel !== undefined && sel != null && sel.length > max) {
        out = sel.substring(0, max) + "...";
    } else {
        out = sel;
    }

    return out
}
