import React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Button } from 'office-ui-fabric-react/lib/Button';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import "./Notes.scss"
import gql from "graphql-tag";
import { queryGetEntry } from "./../../../../../queries/queryRecordings";
import { MyContext } from '../../../../../context';


export default class NotesContainer extends React.Component {

    noteField = React.createRef();


    state = {
        isChanged: false,
        text: (this.props.data.notes != null) ? this.props.data.notes : "",
        saveActive: false
    };


    textChanged = () => {
        this.setState({ isChanged: true });
    }

    submit = () => {
        this.setState({ saveActive: true, isChanged: false });
        this.props.client
            .mutate({
                mutation: gql`
                mutation ent ($id : String!, $message: String) {
                    setEntryNotes (id: $id, message: $message)
                }
                `,
                refetchQueries: [{
                    query: queryGetEntry,
                    variables: { id: this.props.data.conversationId, auth: this.props.data.authorization, callStartMs: this.props.data.callStartMs }

                }],
                variables: {
                    id: this.props.data.conversationId, message: this.noteField.current.value
                }
            })
            .then(result => this.setState({saveActive: false}));
    }


    render(): React.ReactNode {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="ms-Grid-row ">
                        <div className="ms-Grid-col ms-sm12 ms-md11 ms-lg11">
                            <div className="notes-container">
                                <TextField key="4" multiline rows={4} componentRef={this.noteField} value={this.state.text} onChange={(event, newValue) => { this.setState({ text: newValue, isChanged: true }); }} />
                            </div>
                            <div className="notes-container">
                                {this.state.saveActive ? <Spinner /> : <Button text={context.getTranslation("common", "save")} onClick={this.submit} disabled={!this.state.isChanged} />}
                            </div>
                        </div>
                    </div>
                )
                }
            </MyContext.Consumer>);
    }
}
