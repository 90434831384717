import React, { Component } from 'react';

import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption, Pivot, PivotItem, FontWeights, Separator, Toggle, Button } from 'office-ui-fabric-react/lib';
import { DatePicker } from 'office-ui-fabric-react/lib/DatePicker';
import { MyContext } from '../../../context';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import Utils from '../../common/Utils';
import UserPicker from './../../ReportView/ReportConfiguration/UserPicker';
import DateTimePicker from 'react-datetime-picker';
import { Query } from "@apollo/react-components";

import { queryGetConfigData, queryGetAWSRegions, mutationMarkDeleteExportRule, getProcessingRegions } from "../../../queries/querySettings";
import {
    DefaultButton,
    PrimaryButton,
    Stack,
    TextField,
    ComboBox, Checkbox, Spinner, Text, Label, SpinButton, ChoiceGroup
} from "office-ui-fabric-react";
import '../../RuleSetter/Setter.scss';


const checkboxStyles = () => {
    return {
        root: {
            marginTop: '10px'
        }
    };
};
class ExportConfig extends React.Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();

        let exportUser = [];
        let exportUserSource = (this.props.data == null || this.props.data.userIds === "undefined" || this.props.data.userIds == null || this.props.data.userIds == "") ? "" : this.props.data.userIds;
        for (let x = 0; x < exportUserSource.length; x++) {
            exportUser.push({
                userId: exportUserSource[x],
                key: exportUserSource[x]
            }
            );
        }

        //I added this part because for new jobs or existing recent jobs are fine with new Date() and new date(jobTime)
        //but we have old jobs that have as value '0001-01-01T00:00:00' and it's not working because it's not recognized as a valid DateTime because it reads as 0000-12-31T22:15:36.000Z
        //so in case of an old wrong value I will change it in 0001-01-01T00:00:00Z because the Z adds an extra 1h:44min and is able to read the date correct
        let jobTime = new Date();
        let callTimeStart = new Date();
        if (this.props.data != null && this.props.data.jobType != null) {
            if (this.props.data.jobType.jobStartTime === '0001-01-01T00:00:00') {
                jobTime = new Date('0001-01-01T00:00:00Z');
            } else if (this.props.data.jobType.jobStartTime === '') {
                jobTime = new Date();
            } else {
                jobTime = new Date(this.props.data.jobType.jobStartTime)
            }
        }
        //same for this case
        if (this.props.data != null) {
            if (this.props.data.callStartTime === '0001-01-01T00:00:00') {
                callTimeStart = new Date('0001-01-01T00:00:00Z');
            } else if (this.props.data.callStartTime === '') {
                callTimeStart = new Date();
            } else {
                callTimeStart = new Date(this.props.data.callStartTime)
            }
        }

        this.state = {
            name: this.props.data != null ? this.props.data.name : "Export",
            id: this.props.isNew ? Utils.guidGenerator() : this.props.data.id,
            jobStartTime: jobTime,
            intervalType: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalType : "OneTime",
            intervalMonth: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalMonth : 0,
            intervalDay: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalDay : 0,
            intervalHour: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalHour : 0,
            intervalMinute: (this.props.data != null && this.props.data.jobType != null) ? this.props.data.jobType.intervalMinute : 0,
            exportUser: exportUser,
            exportType: (this.props.data != null && this.props.data.exportType != null) ? this.props.data.exportType : "Blobstorage",
            audioCodec: (this.props.data != null && this.props.data.audioCodec != null) ? this.props.data.audioCodec : "Wav",
            sourceTtl: this.props.isNew ? -1 : this.props.data.sourceTtl,
            sourceTtlEnabled: this.props.isNew ? false : this.props.data.sourceTtl != -1,
            storageConnectionString: this.props.isNew ? "" : this.props.data.storageConnectionString,
            storageContainerName: this.props.isNew ? "ascexport" : this.props.data.storageContainerName,
            storageRootDirectory: this.props.isNew ? "" : this.props.data.storageRootDirectory,
            mediaType: this.props.isNew ? 0 : this.props.data.conversationType,
            enabled: this.props.isNew ? true : this.props.data.enabled,
            processingRegion: this.props.isNew ? "EU" : this.props.data.processingRegion,
            callStartTime: callTimeStart,
            callEndTime: ((this.props.data != null && this.props.data.callEndTime !== '')) ? new Date(this.props.data.callEndTime) : '9999-12-31T23:59:59',
            defaultRegion: (this.props.data != null && this.props.data.defaultRegion != null) ? this.props.data.defaultRegion : "",
            PGPEncryption: (this.props.data != null && this.props.data.pgpEncryption != null) ? this.props.data.pgpEncryption : false,
            pgpEncryptionKeyUpload: "",
            selectedFile: (this.props.data != null && this.props.data.pgpEncryption != null) ? this.props.data.pgpEncryption : false,
            sftpPrivateKeyUpload: "",
            hasSftpKey: (this.props.data !== null && this.props.data.hasSftpKey !== null) ? this.props.data.hasSftpKey : false,
            auditEventTypes: (this.props.data !== null && this.props.data.auditEventTypes !== null) ? this.props.data.auditEventTypes : "",
            jobDeletionActive: false,
            mergeAudioToVideo: (this.props.data != null && this.props.data.mergeAudioToVideo != null) ? this.props.data.mergeAudioToVideo : false
        };

    }

    auditLogTypesOptions =
        [ 
        {
            key: "RECORDING_CONVERSATION",
            text: "Recording conversation",
        },
        {
            key: "RECORDING_NOTRECORDED",
            text: "Recording not recorded",
        },
        {
            key: "REPLAY_CONVERSATION",
            text: "Replay conversation",
        },
        {
            key: "REPLAY_REQUEST",
            text: "Replay request",
        },
        {
            key: "REPLAY_APPROVED",
            text: "Replay approved",
        },
        {
            key: "DELETE_CONVERSATION",
            text: "Delete conversation",
        },
        {
            key: "DELETE_REQUEST",
            text: "Delete request",
        },
        {
            key: "DELETE_APPROVED",
            text: "Delete approved",
        },
        {
            key: "ANALYTICS_MANUAL",
            text: "Analytics manual",
        },
        {
            key: "RULE_NEW",
            text: "Rule New",
        },
        {
            key: "RULE_ASSIGN",
            text: "Rule assign",
        },
        {
            key: "RULE_DELETE",
            text: "Rule delete",
        },
        {
            key: "RULE_CHANGE",
            text: "Rule change",
        },
        {
            key: "SETTING_CHANGE",
            text: "Setting change",
        },
        {
            key: "USER_ADD",
            text: "User add",
        },
        {
            key: "USER_DELETE",
            text: "User Delete",
        },
        {
            key: "TENANT_ADD",
            text: "Tenant add",
        },
        {
            key: "TENANT_CHANGE",
            text: "Tenant change",
        },
        {
            key: "PARTNER_ADD",
            text: "Partner add",
        },
        {
            key: "PARTNER_CHANGE",
            text: "Partner change",
        },
        {
            key: "RECORDING_EXPORT",
            text: "Recording export",
        },
        {
            key: "RECORDING_UPLOAD",
            text: "Recording upload",
        },
        {
            key: "USER_LOGIN",
            text: "User login",
        },
        {
            key: "USER_CHANGE",
            text: "User change",
        },
        {
            key: "GROUP_ADD",
            text: "Group add",
        },
        {
            key: "GROUP_DELETE",
            text: "Group delete",
        },
        {
            key: "GROUP_CHANGE",
            text: "Group change",
        },
        {
            key: "RECORDING_IMPORT",
            text: "Recording import",
        },
        {
            key: "DELETE_MANUAL_CONVERSATION",
            text: "Delete manual conversation",
        },
        {
            key: "RECORDING_SWITCH",
            text: "Recording switch",
        },
        {
            key: "RECORDING_DISABLE_NEXT",
            text: "Recording disable next",
        },
        {
            key: "RECORDING_DISABLE_CANCEL",
            text: "Recording disable cancel",
        },
        {
            key: "RECORDING_DISABLE_STOP",
            text: "Recording disable stop",
        },
        {
            key: "RECORDING_DISABLE_CURRENT",
            text: "Recording disable current",
        },
        {
            key: "RESTORE_ARCHIVE_REQUEST",
            text: "Restore archive request",
        },
        {
            key: "RESTORE_ARCHIVE_APPROVED",
            text: "Restore archive approved",
        },
        {
            key: "RESTORE_ARCHIVE",
            text: "Restore archive",
        },
        {
            key: "RECORDING_ARCHIVE",
            text: "Recording archvive",
        },
        {
            key: "POLICYRULE_DELETE",
            text: "Policy rule delete",
        },
        {
            key: "TTL_CHANGE",
            text: "TTL change",
        },
        {
            key: "AUDIT_EXPORT",
            text: "Audit export",
        }];
    updateSelectedOptionKeys = (selectedKeys, option): string[] => {
        selectedKeys = [...selectedKeys]; // modify a copy
        const index = selectedKeys.indexOf(option.key);
        if (option.selected && index < 0) {
            selectedKeys.push(option.key);
        } else {
            selectedKeys.splice(index, 1);
        }
        return selectedKeys;
    };

    findKeys = (k, options) => {
        let out;
        for (let x = 0; x < options.length; x++) {
            if (k === options[x].key) {
                out = options[x];
                break;
            }
        }
        return out;
    }
    onChangeMulti = (event, option, index, value) => {
        console.log('_onChangeMulti() is called: option = ' + JSON.stringify(option));
        const currentSelectedKeys = this.state.auditEventTypes || [];
        if (option) {
            // User selected/de-selected an existing option
            const tmp = this.updateSelectedOptionKeys(currentSelectedKeys, option);
            let xx = [];
            for (let i = 0; i < tmp.length; i++) {
                xx.push(this.findKeys(tmp[i], this.auditLogTypesOptions))
            }
            this.setState({
                auditEventTypes: tmp,
                updated: true
            });
        }
    };
    changeTab(newActiveTabIndex) {
        this.setState({
            activeTabIndex: newActiveTabIndex
        });
    }
    onFileChangePGP = event => {

        let activeFile = event.target.files[0];

        if (activeFile) {
            this.setState({ selectedFile: true });

        }

        if (event.target.files[0]) {
            this.loadFile(event.target.files[0]);

        }

    };

    loadFile = async (file) => {
        let text = await file.text();
        this.setState({ pgpEncryptionKeyUpload: text });
    }
    loadFileSftp = async (file) => {
        let text = await file.text();
        this.setState({ sftpPrivateKeyUpload: text });
    }

    onFileChangeSftp = event => {

        let activeFile = event.target.files[0];

        if (activeFile) {
            this.setState({ hasSftpKey: true });

        }

        if (event.target.files[0]) {

            this.loadFileSftp(event.target.files[0]);
        }

    };

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Stack vertical>
                        {/* rule name */}
                        <TextField type={"text"}
                            label={context.getTranslation("configuration", "jobName")}
                            value={this.state.name}
                            onChange={(event, newValue) => {
                                this.setState({ name: newValue });
                            }}
                            errorMessage={this.state.name == "" ? "Rule name cannot be empty" : ""}
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}
                        />

                        <Toggle
                            defaultChecked={this.state.enabled}
                            label={context.getTranslation("settings", "enabled")}
                            onText={context.getTranslation("settings", "on")}
                            offText={context.getTranslation("settings", "off")}
                            onChange={(ev, checked) => {
                                this.setState({
                                    enabled: checked,
                                    updated: true
                                });
                            }}
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                        />

                        <br />
                        <hr />

                        <Label alignContent="start">{context.getTranslation("configuration", "interval")}</Label>

                        <ChoiceGroup
                            selectedKey={this.state.intervalType}
                            style={{ width: "200px" }}
                            options={[
                                {
                                    key: 'OneTime',
                                    text: context.getTranslation("configuration", "single"),
                                    'data-automation-id': 'auto1',
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                },
                                {
                                    key: 'Periodical',
                                    text: context.getTranslation("configuration", "periodical"),
                                    styles: { field: { fontWeight: FontWeights.regular } }
                                }
                            ]}
                            onChange={(event, newValue) => {
                                this.setState({
                                    intervalType: newValue.key,
                                    updated: true
                                });
                            }}
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                        />

                        <Stack.Item>
                            <Label>{context.getTranslation("configuration", "startTime")}</Label>

                            <DateTimePicker
                                locale={this.props.authorization.locale}
                                calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}

                                firstDayOfWeek={1}
                                value={new Date(this.state.jobStartTime)}
                                onChange={(date) => {
                                    let parsedDate = new Date(date);
                                    if (parsedDate < new Date("0001-01-01T00:00:00Z")) {
                                        console.log("Date is <");
                                        parsedDate = new Date("0001-01-01T00:00:00Z")
                                    }
                                    else if (parsedDate > new Date("9999-12-31T23:59:59Z")) {
                                        console.log("Date is >");
                                        parsedDate = new Date("9999-12-31T23:59:59Z")
                                    }
                                    console.log(parsedDate);

                                    this.setState({
                                        jobStartTime: parsedDate
                                    });
                                }
                                }
                                minDate={new Date("0001-01-01T00:00:00Z")}
                                maxDate={new Date("9999-12-31T23:59:59Z")}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />

                        </Stack.Item>
                        {this.state.intervalType == "Periodical" &&
                            <Stack>
                                <Stack.Item>
                                    <Label>{context.getTranslation("configuration", "cycle")}</Label>

                                    <div style={{ diplay: "flex" }}>
                                        <TextField
                                            name="CycleTime"
                                            type="Number"
                                            min={0}

                                            label={context.getTranslation("configuration", "cycleMonth")}
                                            value={this.state.intervalMonth}
                                            onChange={(event, newValue) => {
                                                if (newValue < 0)
                                                    newValue = 0;
                                                this.setState({
                                                    intervalMonth: parseInt(newValue)
                                                })
                                            }}
                                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                        />

                                    </div>

                                    <div style={{ diplay: "flex" }}>
                                        <TextField
                                            name="CycleTime"
                                            type="Number"
                                            min={0}

                                            label={context.getTranslation("configuration", "cycleDay")}
                                            value={this.state.intervalDay}
                                            onChange={(event, newValue) => {
                                                if (newValue < 0)
                                                    newValue = 0;
                                                this.setState({
                                                    intervalDay: parseInt(newValue)
                                                })
                                            }}
                                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                        />

                                    </div>

                                    <div style={{ diplay: "flex" }}>
                                        <TextField
                                            name="CycleTime"
                                            type="Number"
                                            min={0}

                                            label={context.getTranslation("configuration", "cycleHour")}
                                            value={this.state.intervalHour}
                                            onChange={(event, newValue) => {
                                                if (newValue < 0)
                                                    newValue = 0;
                                                this.setState({
                                                    intervalHour: parseInt(newValue),
                                                })
                                            }}
                                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                        />

                                    </div>

                                    <div style={{ diplay: "flex" }}>
                                        <TextField
                                            name="CycleTime"
                                            type="Number"
                                            min={0}
                                            step="30"
                                            onKeyPress={(e) => e.preventDefault()}
                                            label={context.getTranslation("configuration", "cycleMinute")}
                                            value={this.state.intervalMinute}
                                            onChange={(event, newValue) => {
                                                if (newValue < 0)
                                                    newValue = 0;
                                                this.setState({
                                                    intervalMinute: parseInt(newValue)
                                                })
                                            }}
                                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                        />

                                    </div>

                                </Stack.Item>
                            </Stack>
                        }
                        <br />
                        <hr />
                        <Stack.Item>
                            {/* target storage */}
                            <ComboBox
                                style={{ width: "300px" }}
                                placeholder="Select an option"
                                label={context.getTranslation("configuration", "exportType")}
                                options={[{
                                    key: "Blobstorage",
                                    text: "BlobStorage"
                                },
                                {
                                    key: "Neo",
                                    text: "EVOIPneo",
                                },
                                {
                                    key: "Hubstor",
                                    text: "Hubstor",
                                },
                                {
                                    key: "Bloomberg",
                                    text: "Bloomberg Vault",
                                },
                                {
                                    key: "SFTP",
                                    text: "SFTP/FTP",
                                },
                                {
                                    key: "AWS",
                                    text: "AWS S3",
                                },
                                {
                                    key: "eKontakt",
                                    text: "eKontakt",
                                },
                                {
                                    key: "AuditLogSFTPCustom",
                                    text: "AuditLogSFTPCustom",
                                },
                                {
                                    key: "azureDatalake",
                                    text: "Azure Datalake",
                                },
                                {
                                    key: "AuditEvent",
                                    text: "Audit Event",
                                }
                                ]
                                }
                                selectedKey={this.state.exportType}
                                onChange={(ev, newVal) => {
                                    this.setState({
                                        exportType: newVal.key,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}
                            />
                        </Stack.Item>

                        {this.state.exportType === "AWS" && <Stack.Item>

                            <Query
                                query={queryGetAWSRegions}
                                variables={this.props.authorization} >
                                {({ loading, error, data }) => {
                                    if (loading) return <div>
                                        <Spinner label={context.getTranslation("common", "loadingRegions")} ariaLive="assertive" labelPosition="top" />
                                    </div>;
                                    if (error) return <h1> </h1>;

                                    if (data.getAWSRegions == null) {
                                        return <div></div>;
                                    }
                                    this.tmpKey = [];
                                    const stack = data.getAWSRegions;
                                    if (data.getAWSRegions != null) {
                                        for (let x = 0; x < stack.length; x++) {
                                            let i = {
                                                key: stack[x].code,
                                                text: stack[x].displayName,
                                            };
                                            this.tmpKey.push(i);
                                        }
                                    }

                                    return <ComboBox
                                        style={{ width: "300px" }}
                                        placeholder="Select an option"
                                        label={context.getTranslation("configuration", "defaultRegion")}
                                        options={this.tmpKey}
                                        selectedKey={this.state.defaultRegion}
                                        onChange={(ev, newVal) => {
                                            this.setState({
                                                defaultRegion: newVal.key,
                                                updated: true
                                            });
                                        }}
                                        errorMessage={this.state.defaultRegion == "" ? "Default region must be selected" : ""}
                                        disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                    />
                                }}
                            </Query>


                            <Checkbox
                                label={context.getTranslation("configuration", "pgpEncryption")}
                                checked={this.state.PGPEncryption}
                                styles={checkboxStyles}
                                onChange={(ev, newVal) => {
                                    this.setState({
                                        PGPEncryption: newVal,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                        </Stack.Item>
                        }
                        {(this.state.PGPEncryption && this.state.exportType === "AWS") && <>

                            <div id="clickable-area">
                                <input type="file" onChange={this.onFileChangePGP} id="file-btn" disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false} />
                                <div className="upload-button"><span>{this.state.selectedFile ? context.getTranslation("common", "updateFile") : context.getTranslation("common", "uploadFile")}</span>
                                </div><p id="clickable-area-text">{this.state.selectedFile
                                    ? <i className="ms-Icon ms-Icon--Accept" style={{ fontSize: "20px" }}></i>
                                    : context.getTranslation("common", "noFileSelected")} &nbsp;</p>
                            </div>
                        </>}
                        {(this.state.exportType === "AuditLogSFTPCustom" || this.state.exportType === "AuditEvent") &&
                            <Stack.Item>
                                <ComboBox
                                    label={context.getTranslation("configuration", "auditLogType")}
                                    selectedKey={this.state.auditEventTypes}
                                    allowFreeform={false}
                                    onChange={this.onChangeMulti}
                                    autoComplete={true}
                                    options={this.auditLogTypesOptions}
                                    style={{ width: "300px" }}
                                    multiSelect
                                    disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}
                                />
                            </Stack.Item>}
                        {/* audio codec */}
                        {(this.state.exportType !== "AuditLogSFTPCustom" && this.state.exportType !== "eKontakt" && this.state.exportType !== "AuditEvent") &&
                            <Stack.Item>
                                <ComboBox
                                    style={{ width: "300px" }}
                                    placeholder="Select an option"
                                    label={context.getTranslation("configuration", "audioFormat")}
                                    options={[{
                                        key: "NONE",
                                        text: context.getTranslation("configuration", "keepCurrent")
                                    },
                                    {
                                        key: "WAV",
                                        text: "WAV",
                                    },
                                    {
                                        key: "MP3",
                                        text: "MP3",
                                    }]
                                    }
                                    selectedKey={this.state.audioCodec}
                                    onChange={(ev, newVal) => {
                                        this.setState({
                                            audioCodec: newVal.key,
                                            updated: true
                                        });
                                    }}
                                    disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                />
                            </Stack.Item>}

                        <br />
                        <hr />
                        <Label>{context.getTranslation("configuration", "storage")}</Label>

                        {(this.state.exportType === "eKontakt" || this.state.exportType === "AuditLogSFTPCustom" || this.state.exportType === "AuditEvent" || this.state.exportType === "SFTP") &&
                            <Stack.Item>
                                <Label>{context.getTranslation("configuration", "privateKeyUpload")}</Label>
                                <div id="clickable-area">
                                    <input type="file" onChange={this.onFileChangeSftp} id="file-btn" disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false} />
                                    <div className="upload-button"><span>{this.state.hasSftpKey ? context.getTranslation("common", "updateFile") : context.getTranslation("common", "uploadFile")}</span>
                                    </div><p id="clickable-area-text">{this.state.sftpPrivateKeyUpload
                                        ? <i className="ms-Icon ms-Icon--Accept" style={{ fontSize: "20px" }}></i>
                                        : context.getTranslation("common", "noFileSelected")} &nbsp;</p>
                                </div>
                            </Stack.Item>}


                        {/* import connection string */}
                        <Stack.Item>
                            <TextField label={context.getTranslation("configuration", "storageConnectionString")}
                                value={this.state.storageConnectionString}
                                onChange={(event, newValue) => {
                                    this.setState({ storageConnectionString: newValue });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <span style={{ marginLeft: "9px", fontSize: "smaller", fontStyle: "italic" }}>{this.storageConnectionStringPlaceHolder()}</span>
                            <TextField label={context.getTranslation("configuration", "storageContainerName")}
                                value={this.state.storageContainerName}
                                onChange={(event, newValue) => {
                                    this.setState({ storageContainerName: newValue });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <TextField label={context.getTranslation("configuration", "storageRootDirectory")}
                                value={this.state.storageRootDirectory}
                                onChange={(event, newValue) => {
                                    this.setState({ storageRootDirectory: newValue });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                        </Stack.Item>
                        <br />
                        {(this.state.exportType !== "AuditLogSFTPCustom" && this.state.exportType !== "AuditEvent") && <>
                            <hr />
                            <Label>{context.getTranslation("configuration", "retentionTimeAfterExport")}</Label>
                            <Toggle
                                defaultChecked={this.state.sourceTtlEnabled}
                                onText={context.getTranslation("settings", "on")}
                                offText={context.getTranslation("settings", "off")}
                                onChange={(ev, checked) => {
                                    this.setState({
                                        sourceTtl: checked ? 10 : -1,
                                        sourceTtlEnabled: checked,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                        </>}
                        {this.state.sourceTtlEnabled && < TextField
                            value={this.state.sourceTtl}
                            type="Number"
                            label={context.getTranslation("configuration", "retentionTimeAfterExportDays")}
                            onChange={(event, newValue) => {
                                this.setState({
                                    sourceTtl: newValue,
                                });
                            }}
                            disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                        />}
                        <br />
                        <hr />

                        {/*merge audio into video*/}
                        {(this.state.exportType !== "Bloomberg" && this.state.exportType !== "eKontakt" && this.state.exportType !== "AuditLogSFTPCustom" && this.state.exportType !== "Neo" && this.state.exportType !== "AuditEvent") && <>
                            <Stack.Item>
                                <Label>{context.getTranslation("configuration", "exportSettings")}</Label>

                                <Checkbox
                                    label={context.getTranslation("configuration", "mergeAudioToVideo")}
                                    checked={this.state.mergeAudioToVideo}
                                    styles={checkboxStyles}
                                    onChange={(ev, newVal) => {
                                        this.setState({
                                            mergeAudioToVideo: newVal,
                                            updated: true
                                        });
                                    }}
                                />

                            </Stack.Item>
                            <br />
                            <hr />
                        </>
                        }



                        <Label alignContent="start">{context.getTranslation("configuration", "filter")}</Label>



                        {/* media type */}
                        {(this.state.exportType !== "AuditLogSFTPCustom" && this.state.exportType !== "eKontakt" && this.state.exportType !== "AuditEvent") && <Stack.Item>
                            <Label>{context.getTranslation("configuration", "mediaTypes")}</Label>
                            <Checkbox
                                label={context.getTranslation("configuration", "conversationFilterMetaData")}
                                checked={true}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <Checkbox
                                label={context.getTranslation("configuration", "conversationFilterAudio")}
                                checked={this.state.mediaType & 1}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        mediaType: newValue ? this.state.mediaType | 1 : this.state.mediaType - 1,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <Checkbox
                                label={context.getTranslation("configuration", "conversationFilterVideo")}
                                checked={this.state.mediaType & 2}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        mediaType: newValue ? this.state.mediaType | 2 : this.state.mediaType - 2,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <Checkbox
                                label={context.getTranslation("configuration", "conversationFilterScreenSharing")}
                                checked={this.state.mediaType & 4}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        mediaType: newValue ? this.state.mediaType | 4 : this.state.mediaType - 4,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <Checkbox
                                label={context.getTranslation("configuration", "conversationFilterUpload")}
                                checked={this.state.mediaType & 8}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        mediaType: newValue ? this.state.mediaType | 8 : this.state.mediaType - 8,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <Checkbox
                                label={context.getTranslation("configuration", "conversationFilterDocuments")}
                                checked={this.state.mediaType & 16}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        mediaType: newValue ? this.state.mediaType | 16 : this.state.mediaType - 16,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <Checkbox
                                label={context.getTranslation("configuration", "conversationFilterChat")}
                                checked={this.state.mediaType & 32}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        mediaType: newValue ? this.state.mediaType | 32 : this.state.mediaType - 32,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                            <Checkbox
                                label={context.getTranslation("configuration", "conversationFilterTranscript")}
                                checked={this.state.mediaType & 64}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        mediaType: newValue ? this.state.mediaType | 64 : this.state.mediaType - 64,
                                        updated: true
                                    });
                                }}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />
                        </Stack.Item>}

                        {/* timeframe */}
                        <Stack.Item>
                            <Label>{context.getTranslation("configuration", "startTime")}</Label>

                            <DateTimePicker
                                locale={this.props.authorization.locale}
                                calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}
                                firstDayOfWeek={1}
                                value={new Date(this.state.callStartTime)}
                                onChange={(date) => {
                                    let parsedDate = new Date(date);
                                    if (parsedDate < new Date("0001-01-01T00:00:00Z")) {
                                        console.log("Date is <");
                                        parsedDate = new Date("0001-01-01T00:00:00Z")
                                    }
                                    else if (parsedDate > new Date("9999-12-31T23:59:59Z")) {
                                        console.log("Date is >");
                                        parsedDate = new Date("9999-12-31T23:59:59Z")
                                    }
                                    console.log(parsedDate);

                                    this.setState({
                                        callStartTime: parsedDate
                                    });
                                }
                                }
                                minDate={new Date("0001-01-01T00:00:00Z")}
                                maxDate={new Date("9999-12-31T23:59:59Z")}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />

                            <Label>{context.getTranslation("configuration", "endTime")}</Label>

                            <DateTimePicker
                                locale={this.props.authorization.locale}
                                calendarClassName={this.props.theme.palette.black < "#100000" ? "Light" : "Dark"}
                                firstDayOfWeek={1}
                                value={new Date(this.state.callEndTime)}
                                onChange={(date) => {
                                    let parsedDate = new Date(date);
                                    if (parsedDate < new Date("0001-01-01T00:00:00Z")) {
                                        console.log("Date is <");
                                        parsedDate = new Date("0001-01-01T00:00:00Z")
                                    }
                                    else if (parsedDate > new Date("9999-12-31T23:59:59Z")) {
                                        console.log("Date is >");
                                        parsedDate = new Date("9999-12-31T23:59:59Z")
                                    }
                                    console.log(parsedDate);

                                    this.setState({
                                        callEndTime: parsedDate
                                    });
                                }
                                }
                                minDate={new Date("0001-01-01T00:00:00Z")}
                                maxDate={new Date("9999-12-31T23:59:59Z")}
                                disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                            />

                        </Stack.Item>

                        {(this.state.exportType !== "AuditLogSFTPCustom" && this.state.exportType !== "AuditEvent") &&
                            <Stack.Item>
                                <Label>{context.getTranslation("configuration", "userFilter")}</Label>

                                <UserPicker
                                    user={this.state.exportUser}

                                    fc={(user) => {
                                        this.setState({
                                            exportUser: user
                                        });
                                    }}
                                    disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                />
                            </Stack.Item>}

                        <Query
                            query={getProcessingRegions}
                            variables={this.props.authorization} >
                            {({ loading, error, data }) => {
                                if (loading) return <div>
                                    <Spinner label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "loadingRegions")}</>)}</MyContext.Consumer>} ariaLive="assertive" labelPosition="top" />
                                </div>;
                                if (error) return <h1> </h1>;

                                if (data.getProcessingRegions == null) {
                                    return <div></div>;
                                }

                                const licenseText = {
                                    EU: "EU",
                                    US: "USA",
                                    AS: "APAC",
                                    EUW: "EUW",
                                };
                                this.tmpKey = [];
                                const license = data.getProcessingRegions;
                                if (data.getProcessingRegions != null) {
                                    for (let x = 0; x < license.length; x++) {
                                        let i = {
                                            key: license[x],
                                            text: licenseText[license[x]] || license[x],
                                        };
                                        this.tmpKey.push(i);
                                    }
                                }


                                return <ComboBox
                                    style={{ width: "300px" }}
                                    placeholder="Select an option"
                                    label={<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "processingRegion")}</>)}</MyContext.Consumer>}
                                    options={this.tmpKey}
                                    selectedKey={this.state.processingRegion}
                                    onChange={(ev, newVal) => {
                                        this.setState({
                                            processingRegion: newVal.key,
                                            updated: true
                                        });
                                    }}
                                    disabled={(this.props.data !== null && this.props.data.isArchived) ? true : false}

                                />
                            }
                            }

                        </Query>

                        <br />

                        <br />
                        <Stack.Item>
                            {(this.props.data !== null && this.props.data.isArchived)
                                ? <></>
                                : <PrimaryButton onClick={this.savePanel} text={context.getTranslation("common", "save")} disabled={this.isValid()} />

                            }
                            <DefaultButton onClick={this.resetState} text={context.getTranslation("common", "cancel")} />
                            {(this.props.data !== null && !this.state.enabled && !this.props.data.isArchived) &&
                                <DefaultButton style={{ background: "red", color: "white", marginLeft: "5px" }} onClick={this.deleteExportJob} text={context.getTranslation("configuration", "deleteJob")} disabled={this.state.jobDeletionActive} />
                            }
                        </Stack.Item>
                    </Stack>
                )
                }
            </MyContext.Consumer>
        );
    }

    storageConnectionStringPlaceHolder = () => {
        var placeholderText = "";
        switch (this.state.exportType) {
            case "Blobstorage":
                placeholderText = "e.g: AccountName=<name>;AccountKey=<key>;"
                break;
            case "Neo":
                placeholderText = "e.g: AccountName=<name>;AccountKey=<key>;"
                break;
            case "Hubstor":
                placeholderText = "e.g: AccountName=<name>;AccountKey=<key>;"
                break;
            case "Bloomberg":
                placeholderText = "e.g: host = <ip>;port=<port>;username=<username>;password=<password>; voiceuploaddir=/Voice/;reconciliationdir=/Reconciliation/Voice;customString=<customString>"
                break;
            case "SFTP":
                placeholderText = "e.g: host = <ip>;port=<port>;username=<username>;password=<password>; voiceuploaddir=/Voice/"
                break;
            case "AWS":
                placeholderText = "e.g: keyid=<id>;accesskey=<key>"
                break;
            case "eKontakt":
                placeholderText = "e.g: host = <ip>;port=<port>;username=<username>;password=<password>; voiceuploaddir=/Voice/"
                break;
            case "AuditLogSFTPCustom":
                placeholderText = "e.g: host = <ip>;port=<port>;username=<username>;password=<password>; voiceuploaddir=/Voice/"
                break;
            case "azureDatalake":
                placeholderText = "e.g: host=127.0.0.1;port=2222;username=tester;password=password;voiceuploaddir=/Voice/"
                break;
            case "AuditEvent":
                placeholderText = "e.g: host = <ip>;port=<port>;username=<username>;password=<password>; voiceuploaddir=/Voice/"
                break;
            default: break;
        }
        return placeholderText;
    }

    isValid = () => {
        if (this.state.name == null || this.state.name == "") {

            return true;
        }

        if (this.state.exportType == "AWS" && this.state.defaultRegion === "") {

            return true;
        }

        return false;
    }

    resetState = () => {

        this.props.closeModal();

    }

    deleteExportJob = async () => {
        this.setState({ jobDeletionActive: true });

        let exportUserIds = [];
        for (let x = 0; x < this.state.exportUser.length; x++) {
            exportUserIds.push(this.state.exportUser[x].key);
        };

        let defaultRegion = this.state.defaultRegion;
        let pgpEncryption = this.state.PGPEncryption;
        let pgpEncryptionKeyUpload = this.state.pgpEncryptionKeyUpload;

        if (this.state.exportType !== "AWS") {
            defaultRegion = "";
            pgpEncryption = false;
            pgpEncryptionKeyUpload = "";
        }
        let job =
        {
            id: this.state.id,
            name: this.state.name,
            userIds: exportUserIds,
            sourceTtl: this.state.sourceTtlEnabled ? parseInt(this.state.sourceTtl) : -1,
            storageConnectionString: this.state.storageConnectionString,
            storageContainerName: this.state.storageContainerName,
            storageRootDirectory: this.state.storageRootDirectory,
            conversationType: this.state.mediaType,
            exportType: this.state.exportType,
            audioCodec: this.state.audioCodec,
            callStartTime: this.state.callStartTime === '' ? null : this.state.callStartTime,
            callEndTime: this.state.callEndTime === '' ? null : this.state.callEndTime,
            enabled: this.state.enabled ? true : false,
            processingRegion: this.state.processingRegion,
            jobType: {
                intervalType: this.state.intervalType,
                jobStartTime: this.state.jobStartTime === '' ? null : this.state.jobStartTime,
                intervalMonth: this.state.intervalMonth,
                intervalDay: this.state.intervalDay,
                intervalHour: this.state.intervalHour,
                intervalMinute: this.state.intervalMinute
            },
            defaultRegion: defaultRegion,
            pgpEncryption: pgpEncryption,
            pgpEncryptionKeyUpload: pgpEncryptionKeyUpload,
            sftpPrivateKeyUpload: this.state.sftpPrivateKeyUpload,
            auditEventTypes: this.state.auditEventTypes
        };

        console.log(job);
        console.log("JOB");

        var data = await this.props.apol
            .mutate({
                mutation: mutationMarkDeleteExportRule,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    rule: job,
                }
            });

        if (data.data.markDeleteExportRule == null) {
            toast.info('Job delete not successful', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info('Job moved to archive', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }



        this.setState({ jobDeletionActive: false });
        this.props.closeModal();
    }

    savePanel = () => {
        // Save Changes
        let exportUserIds = [];
        for (let x = 0; x < this.state.exportUser.length; x++) {
            exportUserIds.push(this.state.exportUser[x].key);
        };

        let defaultRegion = this.state.defaultRegion;
        let pgpEncryption = this.state.PGPEncryption;
        let pgpEncryptionKeyUpload = this.state.pgpEncryptionKeyUpload;

        if (this.state.exportType !== "AWS") {
            defaultRegion = "";
            pgpEncryption = false;
            pgpEncryptionKeyUpload = "";
        }


        let job =
        {
            id: this.state.id,
            name: this.state.name,
            userIds: exportUserIds,
            sourceTtl: this.state.sourceTtlEnabled ? parseInt(this.state.sourceTtl) : -1,
            storageConnectionString: this.state.storageConnectionString,
            storageContainerName: this.state.storageContainerName,
            storageRootDirectory: this.state.storageRootDirectory,
            conversationType: this.state.mediaType,
            exportType: this.state.exportType,
            audioCodec: this.state.audioCodec,
            callStartTime: this.state.callStartTime === '' ? null : this.state.callStartTime,
            callEndTime: this.state.callEndTime === '' ? null : this.state.callEndTime,
            enabled: this.state.enabled ? true : false,
            processingRegion: this.state.processingRegion,
            jobType: {
                intervalType: this.state.intervalType,
                jobStartTime: this.state.jobStartTime === '' ? null : this.state.jobStartTime,
                intervalMonth: parseInt(this.state.intervalMonth),
                intervalDay: parseInt(this.state.intervalDay),
                intervalHour: parseInt(this.state.intervalHour),
                intervalMinute: parseInt(this.state.intervalMinute)
            },
            defaultRegion: defaultRegion,
            pgpEncryption: pgpEncryption,
            pgpEncryptionKeyUpload: pgpEncryptionKeyUpload,
            sftpPrivateKeyUpload: this.state.sftpPrivateKeyUpload,
            auditEventTypes: this.state.auditEventTypes,
            mergeAudioToVideo: this.state.mergeAudioToVideo
        };
        if (this.props.data != null) {

            this.props.apol
                .mutate({
                    mutation: gql`
                    mutation ent (
                        $input: AuthInput, 
                        $exportJob: ExportRuleInputType) {
                        updateExportJob (
                            input: $input, 
                            exportJob: $exportJob
                        ) 
                        {
                            id
                        }
                    }
                `,
                    refetchQueries: [{
                        query: queryGetConfigData,
                        variables: { auth: this.props.authorization },
                    }],
                    variables: {
                        input: this.props.input,
                        exportJob: job
                    },
                })
                .then((result) => {

                    if (result.data.updateExportJob !== null) {
                        toast.info('Export Job changed', {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.error('Export Job not changed', {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }

                });
        }
        else {
            this.props.apol
                .mutate({
                    mutation: gql`
                    mutation ent (
                        $input: AuthInput, 
                        $exportJob: ExportRuleInputType) {
                        createExportJob (
                            input: $input, 
                            exportJob: $exportJob
                        ) 
                        {
                            id
                        }
                    }
                `,
                    refetchQueries: [{
                        query: queryGetConfigData,
                        variables: { auth: this.props.authorization },
                    }],
                    variables: {
                        input: this.props.input,
                        exportJob: job
                    },
                })
                .then((result) => {
                    if (result.data.updateExportJob !== null) {
                        toast.info('Export Job created', {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.error('Export Job not created', {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }


                });
        }
        this.props.closeModal();

    };


}
export default ExportConfig;