import React, { Component } from 'react';
import { MyContext } from '../../context';
import {
    CommandBarButton,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    Spinner,
    Stack
} from "office-ui-fabric-react";
import ConversationSafePanel from "./Panel/ConversationSafePanel";
import { ConversationSafeList } from "./ConversationSafeList";
import { deleteConversationSafeFolder, getAllFolders } from './ConversationSafeQuery'
import SafetyDialog from '../CustomComponents/SafetyDialog'
import { toast } from 'react-toastify'


export default class ConversationSafe extends Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            isPanelOpen: false,
            warningMessage: "",
            alertMessagePanel: "",
            folderId: null,
            conversationSafeFolders: [],
            loading: false,
            idDefaultFolder: false,
            dialogDeleteIsOpen: false,
            folderIdToDelete: null
        };
    }


    openPanel = (folderId = null) => {
        this.setState({
            isPanelOpen: true,
            folderId
        })
    }

    authRights = (data) => {
        let folders = data.getConversationSafeFolders
        let ownFolderRule = this.props.userRules.ruleAccess.ownFolder
        let user = this.props.userRules.userId

        if (this.props.userRules.ruleAccess.userSafe == "Full") {
            return folders.map(folder => {
                let userIsCreator = folder.userId == user
                return {
                    folderId: folder.id,
                    addConvRight: true,
                    removeConvRight: true,
                    upserRight: true,
                    deleteFolder: true,
                    exportFolder: true,
                    userIsCreator
                }
            })
        }

        return folders.map(folder => {
            let userIsCreator = folder.userId == user
            let userIsAssignee = folder.supervisors.map(e => e.userId).includes(user)
            let addConvRight = (ownFolderRule.addConversations && userIsCreator) || (userIsAssignee && folder.addConversations)
            let removeConvRight = (ownFolderRule.removeConversations && userIsCreator) || (userIsAssignee && folder.removeConversations)
            let upserRight = ownFolderRule.upserFolder && userIsCreator
            let deleteFolder = userIsCreator && ownFolderRule.deleteFolders
            let exportFolder = (ownFolderRule.exportFolders && userIsCreator) || (userIsAssignee && folder.exportFolders)
            return {
                folderId: folder.id,
                addConvRight,
                removeConvRight,
                upserRight,
                deleteFolder,
                userIsCreator,
                exportFolder
            }
        })
    }

    componentDidMount() {
        this.refreshPage();
    }

    refreshPage = () => {
        this.setState({ loading: true })
        this.props.apol
            .query({
                query: getAllFolders,
                variables: { auth: this.props.auth },
                fetchPolicy: 'network-only'
            })
            .then((result) => {
                const { getConversationSafeFolders } = result.data
                // count folders in every conversation for dialog in conversationpicker
                const conversationList = new Map();
                getConversationSafeFolders.forEach((f) => {
                    f.conversations.forEach((c) => {
                        if (conversationList.get(c.conversationId) == undefined) {
                            conversationList.set(c.conversationId, 1)
                        }
                        else {
                            conversationList.set(c.conversationId, conversationList.get(c.conversationId) + 1)
                        }
                    })
                });
                let rightsArray = this.authRights(result.data)
                this.setState({
                    loading: false,
                    conversationSafeFolders: getConversationSafeFolders,
                    conversationList: conversationList,
                    rights: rightsArray
                })
            });
    }

    getHeaderPanel = () => {
        return (
            <div style={{ flex: "auto", marginLeft: "20px" }}>
                <div role="heading" aria-level="1" style={{ fontSize: "20px", fontWeight: "600" }}>{this.context.getTranslation("conversationSafe", "folderSettings")}</div>
                {/*Alert*/}
                {this.state.alertMessagePanel !== "" && <Stack style={{ marginTop: "10px" }}>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close"
                        onDismiss={() => { this.setState({ alertMessagePanel: "" }) }}
                    >
                        {this.state.alertMessagePanel}
                    </MessageBar>
                </Stack>
                }
            </div >
        )
    }
    checkAccessRules = () => {
        if (this.props.userRules.ruleAccess.userSafe == "Full" || this.props.userRules.ruleAccess.ownFolder.upserFolder) return false
        return true
    }

    openDialogDeleteFolder = (folderId) => {
        this.setState({
            folderIdToDelete: folderId,
            dialogDeleteIsOpen: true
        })
    }


    hidePanel = () => {
        this.setState({ isPanelOpen: false, alertMessagePanel: "" });
    };

    setMessageError = (message) => {
        this.setState({
            alertMessagePanel: message
        })
    }

    deleteFolder = () => {
        this.setState({ dialogDeleteIsOpen: false, loading: true })
        this.props.apol.mutate({
            mutation: deleteConversationSafeFolder,
            variables: { conversationSafeFolder: this.state.folderIdToDelete }
        })
            .then(result => {
                this.setState({
                    loading: false
                })
                if (result.data.deleteConversationSafeFolder.status == "ok") {
                    toast.info('Folder deleted.', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                    this.refreshPage();
                }
            }
            )
    }


    render() {
        return (
            <div style={{ overflow: "auto", maxHeight: window.innerHeight - 120, position: 'relative', marginTop: "5px" }}>
                <Stack horizontal styles={{ root: { height: 44 } }}>
                    <CommandBarButton
                        styles={{ root: { marginTop: 10 } }}
                        iconProps={{ iconName: 'Add' }}
                        text={this.context.getTranslation("conversationSafe", "addFolder")}
                        disabled={this.state.rights === undefined || this.checkAccessRules()}
                        onClick={() => { this.openPanel() }}
                    />
                </Stack>
                <Stack style={{ marginTop: "10px" }}>
                    {this.state.loading && (<Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />)}
                    {!this.state.loading && (
                        <ConversationSafeList
                            data={this.state.conversationSafeFolders}
                            rights={this.state.rights}
                            openPanel={this.openPanel}
                            openDeleteFolderDialog={this.openDialogDeleteFolder}
                            auth={this.props.auth}
                            apol={this.props.apol}
                            userRules={this.props.userRules}
                            theme={this.props.theme}
                            refreshCSList={this.refreshPage}
                        />
                    )}
                </Stack>
                <Panel
                    isOpen={this.state.isPanelOpen}
                    onDismiss={this.hidePanel}
                    isFooterAtBottom={false}
                    onRenderHeader={this.getHeaderPanel}
                    type={PanelType.medium}
                >
                    <ConversationSafePanel
                        apol={this.props.apol}
                        auth={this.props.auth}
                        hidePanel={this.hidePanel}
                        refreshConversationSafeFolders={this.refreshPage}
                        userRules={this.props.userRules}
                        folderId={ this.state.folderId}
                        setMessageError={this.setMessageError}
                        conversationList={this.state.conversationList}
                    />
                </Panel>
                <SafetyDialog
                    title={this.context.getTranslation("compliance", "deleteFolder")}
                    subText={`${this.context.getTranslation("compliance", "deleteFolderMessage")}`}
                    hideDialog={!this.state.dialogDeleteIsOpen}
                    closeDialog={() => { this.setState({ dialogDeleteIsOpen: false }) }}
                    ok={this.deleteFolder}
                />


            </div>

        );
    }
}
