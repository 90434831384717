import React from 'react'
import List from './List'
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Query } from "react-apollo";
import { queryGetEntry } from "../../queries/queryRecordings";
import DetailContainer from "../DetailView/DetailContainer/DetailContainer";
export default class DayListView extends React.Component {

    state = {
        showPanel: false
    };
    convertArray = () => {
        let input = this.props.data;
        let outArr = [];

        if (input !== undefined && input != null) {
            for (let i = 0; i < input.length; i++) {

                for (let j = 0; j < input[i].content.length; j++) {
                    outArr.push({
                        id: input[i].content[j].id,
                        conversationType: input[i].content[j].recordingType,
                        topic: input[i].content[j].topic,
                        conversationId: input[i].content[j].conversationId,
                        date: input[i].content[j].day,
                        dateValue: input[i].content[j].callStartMs,
                        starttime: input[i].content[j].timein,
                        duration: input[i].content[j].duration,
                        notes: input[i].content[j].notes != null ? input[i].content[j].notes : "",
                        transcription: input[i].content[j].speech2Text != null ? input[i].content[j].speech2Text : "",
                        participantCount: input[i].content[j].participantCount,
                        recordingType: input[i].content[j].recordingType,
                        participants: input[i].content[j].participantShort,
                        todo: input[i].date === "To Do",
                        conversationSafe: input[i].content[j].conversationSafe,
                        scenarioId: input[i].content[j].scenarioId,
                        conversationSafeFolders: input[i].content[j].conversationSafeFolders,
                        data: input[i].content[j]
                    }
                    )
                }

            }
        }



        return outArr
    };

    render() {

        return this.selView();
    }

    renderData = (id, auth, callStartMs) => (

        <Query
            query={queryGetEntry}
            variables={{ id, auth, callStartMs }}>
            {({ loading, error, data }) => {
                if (loading) return <div></div>;
                if (error) return <div></div>;
                return (

                    <DetailContainer
                        apol={this.props.apol}
                        authorization={this.props.authorization}
                        data={data.getEntry}
                        type={"delete"}
                        theme={this.props.theme}
                        hidePanel={this._hidePanel} />);
            }}
        </Query>
    );
    selView = () => {
        switch (this.props.type) {
            case null:
            case undefined:
            default:
                return <List
                    refetchQueryFields={this.props.refetchQueryFields}
                    type={this.props.type}
                    authorization={this.props.authorization}
                    apol={this.props.apol}
                    data={this.convertArray()}
                    userRules={this.props.userRules}
                    theme={this.props.theme}
                    count={this.props.count}
                    inform={this.props.inform}
                    days={this.props.data}
                    isBulkOperation={
                        (this.props.userRules.ruleAccess.userBulkSafe || this.props.userRules.ruleAccess.userBulkTranscript || this.props.userRules.ruleAccess.userBulkExport || this.props.userRules.ruleAccess.userBulkRestoreArchive || this.props.userRules.ruleAccess.userBulkDelete)
                            ? true
                            : false
                    }
                />;
        }
    };

    _isRdy = () => {
        if (this.state.content !== undefined && this.state.content != null) {
            return <Panel isOpen={this.state.showPanel} onDismiss={this._hidePanel} type={PanelType.medium} headerText="Delete Converstion">
                {this.renderData(this.state.content.id, this.props.authorization, this.state.content.callStartMs)}
            </Panel>;

        }
    }
    _setContent = (content) => {
        console.log(content);
        this.setState({ content: content, showPanel: true });

    }
    _showPanel = () => {
        this.setState({ showPanel: true });
    };

    _hidePanel = () => {
        this.setState({ showPanel: false });
    };
}
