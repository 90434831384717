import React from 'react';

import {
    Chart,
    Series,
    CommonSeriesSettings,
    Legend,
    ValueAxis,
    Title,
    Tooltip,
    ArgumentAxis, Label, Format
} from 'devextreme-react/chart';
import {Size} from "devextreme-react/vector-map";


class StackedBar extends React.Component {
    render() {
        return (
            <Chart
                id="chart"
                ref={this.props.reference}
                
                dataSource={this.props.data}
                palette={this.props.palette}
            >
                <CommonSeriesSettings
                    ignoreEmptyPoints={true}
                    argumentField="time" type="stackedBar"
                />
                {((this.props.convType.includes("Inbound") && this.props.convType.length !== 0) || (this.props.convType.length === 0))&&
                    <Series
                        valueField="inbound"
                        name="Inbound"

                    />}
                {((this.props.convType.includes("Outbound") && this.props.convType.length !== 0) || (this.props.convType.length === 0)) &&

                    <Series
                        valueField="outbound"
                        name="Outbound"

                    />}
                {((this.props.convType.includes("Meeting") && this.props.convType.length !== 0) || (this.props.convType.length === 0))&&

                    <Series
                        valueField="meeting"
                        name="Meeting"

                    />}
              
                {((this.props.convType.includes("Internal") && this.props.convType.length !== 0) || (this.props.convType.length === 0))&&

                    <Series
                        valueField="intern"
                        name="Internal"
                    />}
                {((this.props.convType.includes("Chat") && this.props.convType.length !== 0) || (this.props.convType.length === 0))&&

                    <Series
                        valueField="chat"
                        name="Chat"
                    />}
                {((this.props.convType.includes("Upload") && this.props.convType.length !== 0) || (this.props.convType.length === 0))&&

                    <Series
                        valueField="upload"
                        name="Upload"
                    />}

                <ArgumentAxis
                    allowDecimals={false}
                    axisDivisionFactor={60}
                >
                    <Label>
                        <Format type="decimal" />
                    </Label>
                    <Title text="Time" />
                </ArgumentAxis>
                <ValueAxis>
                    <Title text="Total Calls" />
                </ValueAxis>
                <Legend
                    margin={0}
                    horizontalAlignment="center"
                    verticalAlignment="bot"
                />
                <Tooltip enabled={true} />

                <Size
                    height={this.props.size.height}
                    width={this.props.size.width}
                />
            </Chart>
        );
    }
}

export default StackedBar;
