import React, { Component } from 'react'
import { MyContext } from '../../../../../context';
import CustomFieldsConversation from '../../../CustomFields/CustomFieldsConversation';

class FieldsContainer extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <CustomFieldsConversation
                    data={this.props.data}
                    authorization={this.props.authorization}
                    client={this.props.client}
                    userRules={this.props.userRules}
                    alignVertical={false}
                    disabled={false}
                    isProcessing={this.props.isProcessing}
                />
            </div>
        )
    }
}

export default FieldsContainer