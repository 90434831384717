import React, { Component } from 'react'
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { Panel, PanelType, Text, PrimaryButton, Stack, TextField } from 'office-ui-fabric-react/lib';
import { ApolloProvider, Mutation } from "react-apollo";
import { Query } from "react-apollo";
import { queryPurchase, mutationPurchaseConfirm } from "./../../queries/queryTransactable";
import { MyContext } from '../../context';
import { toast } from "react-toastify";
import { ASCHeader } from "../ASCHeader/ASCHeader";
import ASCFooter from "../ASCFooter/ASCFooter";


// Styles definition
const stackContainerStyles: IStackStyles = {
    root: {
        paddingTop: 50
    }
};


const stackStyles: IStackStyles = {
    root: {
        maxWidth: 660,
        marginBottom: 50
    }
};

// Tokens definition
const stackTokens: IStackTokens = { childrenGap: 5 };


export default class TransactableView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: this.props.path,
            success: false,
            error: false
        }
    }

    renderData = (url, auth) => (
        <MyContext.Consumer>
            {(context) => (
                <div>
                    <ASCHeader title={"Recording Insights"} />

                    <Stack tokens={stackTokens}>

                        <Stack horizontalAlign="center" styles={stackContainerStyles}>
                            <Stack horizontalAlign="center"  styles={stackStyles}>
                                <Text variant="xLarge">{context.getTranslation("shop", "welcome")}</Text>
                                <Text variant="xLarge">{context.getTranslation("shop", "title")}</Text>                                
                            </Stack>
                            <Stack>
                            <Query
                                query={queryPurchase}
                                variables={{ url, auth }}>
                                {({ loading, error, data }) => {
                                    if (loading) return (
                                        <Spinner label={context.getTranslation("shop", "loading")} ariaLive="assertive" labelPosition="top" />
                                    );
                                        if (error) return <div></div>;
                                        if (data.transactablePurchase === "Error") return <div>No connection to Marketplace</div>;
                                    let purchaseObject = JSON.parse(data.transactablePurchase);
                                    let subscriptionId = purchaseObject.subscriptionId;
                                    let subscriptionName = purchaseObject.subscriptionName;
                                    let planId = purchaseObject.planId;
                                    let offerId = purchaseObject.offerId;
                                    let seatQuantity = purchaseObject.seatQuantity.toString();
                                    return (
                                        <Stack horizontalAlign="left" styles={stackStyles}>
                                            <TextField label={context.getTranslation("shop", "subscriptionName")} value={subscriptionName} disabled />
                                            <TextField label={context.getTranslation("shop", "planId")} value={planId} disabled />
                                            <TextField label={context.getTranslation("shop", "offerId")} value={planId} disabled />
                                            <TextField label={context.getTranslation("shop", "seatQuantity")} value={seatQuantity} disabled />
                                            <TextField label={context.getTranslation("shop", "purchaser")} value={purchaseObject.purchaser.userEmail} disabled />
                                            <TextField label={context.getTranslation("shop", "beneficiary")} value={purchaseObject.beneficiary.userEmail} disabled />
                                            <br/>
                                            {!this.state.success && <PrimaryButton onClick={event => {
                                                this.confirmSubscription(subscriptionId, planId, seatQuantity);
                                            }} text={context.getTranslation("shop", "activate")}
                                            />}
                                        </Stack>
                                    );
                                }}
                            </Query>
                            </Stack>

                            <Stack styles={stackStyles}>
                                {this.state.success && <Text variant="xLarge">Activation successful</Text>}
                                {this.state.error && <Text variant="xLarge">Activation not successful</Text>}
                            </Stack>

                        </Stack>


                    </Stack>

                    <ASCFooter />
                  
                </div>
            )
            }
        </MyContext.Consumer>
    );

    render() {
        console.log("renderTransactable!");
  
            return (<ApolloProvider client={this.props.client}>
                {this.renderData(this.state.url, this.props.authorization)}
            </ApolloProvider>);
    }

    confirmSubscription = async(subscriptionId, planId, seatQuantity) => {
        var data = await this.props.apol
            .mutate({
                mutation: mutationPurchaseConfirm,
                variables: {
                    subscriptionId: subscriptionId,
                    planId: planId,
                    seatQuantity: seatQuantity
                }
            });
        if (data.data.transactablePurchaseConfirm == null || data.data.transactablePurchaseConfirm === "Error") {
            this.setState({ error: true });
        } else {
            this.setState({ success: true });
        }
    };

 
}
