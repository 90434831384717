import { DetailsList, Selection, SelectionMode } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../context';
import Utils from '../../common/Utils'

const orderAlphabetically  = Utils.orderAlphabetically


export default class AdherencePhrasesListView extends Component {
    static contextType = MyContext
    _selection: Selection;

    constructor(props) {
        super(props);
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.props.selectPhrase(this._selection.getSelection().length > 0 ? this._selection.getSelection()[0].id : null)
            }
        });
    }

    getKey = (item, index) => {
        return item.key;
    }

    renderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName]
        switch (column.key) {
            case 'adherence':
                return <span>{fieldContent}%</span>;
            case 'contained': {
                if (item.adherence !== 0 && item.adherence !== 100) {
                    return (
                        <span>
                            {
                                (fieldContent ?
                                    this.context.getTranslation("compliance", "exceeded") :
                                    this.context.getTranslation("compliance", "fallenBelow"))
                            }
                        </span>
                    )
                } else { return (<span></span>) }
            }
            default:
                return <span>{fieldContent !== null ? fieldContent : ""}</span>;
        }
    }

    render() {
        const columns = [
            { key: 'name', name: this.context.getTranslation("compliance", "detailPolicyName"), fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
            { key: 'description', name: this.context.getTranslation("compliance", "detailPolicyDescription"), fieldName: 'description', minWidth: 100, maxWidth: 200 },
            { key: 'phrase', name: this.context.getTranslation("compliance", "phrase"), fieldName: 'phrase', minWidth: 100, maxWidth: 200 },
            { key: 'adherence', name: this.context.getTranslation("compliance", "adherence"), fieldName: 'adherence', minWidth: 30, maxWidth: 80 },
            { key: 'contained', name: this.context.getTranslation("compliance", "contained"), fieldName: 'contained', minWidth: 100, maxWidth: 200 },
        ]
        return (
            <DetailsList
                items={this.props.phrases.sort((a,b)=>orderAlphabetically.call(null,a.name.toLowerCase(),b.name.toLowerCase()))}
                columns={columns}
                selectionMode={SelectionMode.single}
                selection={this._selection}
                getKey={this.getKey}
                setKey="single"
                onRenderItemColumn={this.renderItemColumn}
            />
        );
    }
}