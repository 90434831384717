import React, { Component } from 'react'
import { Stack, Slider } from 'office-ui-fabric-react';
import InputFieldTitle from './InputFieldTitle';

class RISlider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sliderValue: props.input,
        }
    }

    onSliderChange = (data) => {
        this.setState({
            sliderValue: data
        })
        this.props.onSliderChange(data)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.input !== state.sliderValue) {
            return {
                sliderValue: props.input
            };
        }
        return null;
    }

    render() {
        return (
            <Stack>
                <Stack.Item>
                    <InputFieldTitle label={this.props.label} required={true} disabled={this.props.disabled}></InputFieldTitle>
                </Stack.Item>
                {this.props.showPercentage &&
                    <Stack.Item>
                        <Stack horizontal grow={true} style={{ justifyContent: "space-between", marginRight: "50px", marginLeft: "-5px", textAlign: "center" }}>
                            <Stack.Item style={{ width: "20%" }}>
                                {this.props.startLabel !== undefined && <span>{this.props.startLabel}</span>}
                                {this.props.startLabel === undefined &&
                                    <div style={{ marginLeft: "25px" }}>
                                        <span>{this.props.min}%</span>
                                    </div>}
                            </Stack.Item>
                            <Stack.Item style={{ width: "20%" }}>
                                <div style={{ marginLeft: "10px" }}>
                                    <span>{(((this.props.max - this.props.min) * 25) / 100) + this.props.min}%</span>
                                </div>
                            </Stack.Item>
                            <Stack.Item style={{ width: "20%" }}>
                                <span>{(((this.props.max - this.props.min) * 50) / 100) + this.props.min}%</span>
                            </Stack.Item>
                            <Stack.Item style={{ width: "20%" }}>
                                <div style={{ marginRight: "10px" }}>
                                    <span>{(((this.props.max - this.props.min) * 75) / 100) + this.props.min}%</span>
                                </div>
                            </Stack.Item>
                            <Stack.Item style={{ width: "20%" }}>
                                {this.props.endLabel !== undefined && <span>{this.props.endLabel}</span>}
                                {this.props.endLabel === undefined &&
                                    <div style={{ marginRight: "25px" }}>
                                        <span>{this.props.max}%</span>
                                    </div>}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                }
                {this.props.showPercentage &&
                    <Stack.Item style={{ width: "79%", textAlign: "center", marginLeft: "60px" }}>
                        <Stack horizontal grow={true} style={{ justifyContent: "space-between", marginRight: "50px", marginBottom: "-35px" }}>
                            <Stack.Item>
                                <span>|</span>
                            </Stack.Item>
                            <Stack.Item>
                                <span>|</span>
                            </Stack.Item>
                            <Stack.Item>
                                <span>|</span>
                            </Stack.Item>
                            <Stack.Item>
                                <span>|</span>
                            </Stack.Item>
                            <Stack.Item>
                                <span>|</span>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                }
                <Stack.Item style={{ width: "80%", textAlign: "center", marginLeft: "60px" }}>
                    <Slider
                        value={this.state.sliderValue}
                        valueFormat={(value) => `${value}%`}
                        min={this.props.min}
                        max={this.props.max}
                        onChange={this.onSliderChange}
                        size={this.props.size}
                        step={this.props.step}
                        vertical={this.props.vertical}
                        disabled={this.props.disabled}
                        styles={this.props.revert ?
                            {
                                activeSection: {
                                    background: "rgb(194, 194, 194)",
                                },
                                inactiveSection: {
                                    background: "rgb(55, 55, 55)",
                                },
                                slideBox: {
                                    selectors: {
                                        ":hover .ms-Slider-active": {
                                            backgroundColor: "rgb(168, 194, 218)"
                                        },
                                        ":hover .ms-Slider-inactive": {
                                            backgroundColor: "rgb(0, 49, 94)"
                                        }
                                    }
                                }
                            } : {}}
                    />
                </Stack.Item>
            </Stack>

        )
    }
}

export default RISlider
