import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import { DetailsList, TextField, DetailsRow, SelectionMode, FocusTrapCallout, DirectionalHint, ColorPicker, Stack, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';


export default class EditScorecardSettings extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props)
        this.state = {
            showDialogColor: false,
            id: 0,
            selectedColor: "#ffffff"
        }
    }

    onRenderRow = (props) => {
        const customStyles = {}
        if (props) {
            customStyles.root = {
                ":hover": {
                    backgroundColor: "transparent",
                }
            };

            return <DetailsRow {...props} styles={customStyles} />
        }
        return null;
    }

    render() {
        return (
            <div>
                <DetailsList
                    compact={true}
                    items={this.props.settingItems}
                    columns={[
                        {
                            key: 'name',
                            name: this.context.getTranslation("compliance", "evaluationKey"),
                            fieldName: 'name',
                            minWidth: 150,
                            maxWidth: 150,
                            isResizable: true,
                            onRender: (item, i) => {
                                return <TextField
                                    underlined
                                    value={item.name}
                                    onChange={(e) => {
                                        let newItems = this.props.settingItems.map((s, index) => {
                                            if (index === i) {
                                                s.name = e.target.value
                                            }
                                            return s
                                        })
                                        this.props.updateSettingItems(newItems)
                                    }
                                    }
                                />
                            }
                        },
                        {
                            key: 'minRange',
                            fieldName: 'minRange',
                            name: this.context.getTranslation("settings", "ticketFrom") + " %",
                            minWidth: 50,
                            maxWidth: 50,
                            isResizable: true,
                            onRender: (item) => {
                                return <div style={{ fontSize: "14px", marginTop: "5px", marginLeft: "5px" }}>
                                    <span>{item.minRange}</span>
                                </div>
                            }
                        },
                        {
                            key: 'maxRange',
                            fieldName: 'maxRange',
                            name: this.context.getTranslation("settings", "ticketTo") + " %",
                            minWidth: 50,
                            maxWidth: 50,
                            isResizable: true,
                            onRender: (item, i) => {
                                if (i === 0) {
                                    return <div style={{ fontSize: "14px", marginTop: "5px", marginLeft: "5px" }}>
                                        <span>{item.maxRange}</span>
                                    </div>
                                } else {
                                    return <TextField
                                        underlined
                                        value={item.maxRange}
                                        onChange={(e) => {
                                            let newItems = this.props.settingItems.map((s, index) => {
                                                if (index === i) {
                                                    s.maxRange = e.target.value
                                                    this.props.settingItems[index - 1].minRange = parseInt(e.target.value) + 1
                                                }
                                                return s
                                            })
                                            this.props.updateSettingItems(newItems)
                                        }
                                        }
                                    />
                                }
                            }
                        },
                        {
                            key: 'color',
                            fieldName: 'color',
                            name: this.context.getTranslation("compliance", "color"),
                            minWidth: 30,
                            maxWidth: 30,
                            isResizable: true,
                            onRender: (item, i) => {
                                return <div
                                    id={`colorDiv` + i}
                                    style={{ width: "20px", height: "20px", backgroundColor: item.color, marginTop: "5px" }}
                                    onClick={() => {
                                        this.setState({ showDialogColor: true, id: i, selectedColor: item.color })
                                    }}
                                ></div>
                            }
                        }
                    ]}
                    selectionMode={SelectionMode.none}
                    onRenderRow={this.onRenderRow}
                />
                {this.state.showDialogColor && <FocusTrapCallout
                    role="dialog"
                    target={`#colorDiv` + this.state.id}
                    directionalHint={DirectionalHint.leftCenter}
                >
                    <ColorPicker
                        color={this.state.selectedColor}
                        onChange={(e, colorObj) => {
                            this.setState({ selectedColor: colorObj.str })
                        }}
                        alphaType={'none'}
                        showPreview={true}
                    />
                    <Stack horizontal style={{ flexFlow: "row-reverse", marginBottom: "10px", marginRight: "10px" }}>
                        <PrimaryButton
                            text={this.context.getTranslation("common", "ok")}
                            onClick={() => {
                                let newItems = this.props.settingItems.map((s, index) => {
                                    if (index === this.state.id) {
                                        s.color = this.state.selectedColor
                                    }
                                    return s
                                })
                                this.setState({ showDialogColor: false })
                                this.props.updateSettingItems(newItems)
                            }}
                        />
                        <DefaultButton
                            style={{ marginRight: "10px" }}
                            text={this.context.getTranslation("common", "cancel")}
                            onClick={() => { this.setState({ showDialogColor: false }) }}
                        />
                    </Stack>
                </FocusTrapCallout>}
            </div>
        )
    }
}