import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import EditAICategorization from './EditAICategorization';
import { Spinner, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Query } from "react-apollo";
import * as QueryAICat from "../AICategorizationQuery"


export default class EditAICategorizationContainer extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {

        };

    }

    render() {
        const { auth, id, apol, hidePanel } = this.props
        return (<div>
            {id !== null && (<div>
                {<Query
                    query={QueryAICat.queryGetAICategorization}
                    variables={{ auth, id }}
                    fetchPolicy='network-only'>
                    {({ loading, error, data }) => {
                        if (loading) {
                            return (
                                <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                            )
                        }
                        if (error) {
                            console.log(error)
                            return (
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={false}
                                    dismissButtonAriaLabel="Close"
                                >
                                    {this.context.getTranslation("compliance", "error")}
                                </MessageBar>
                            )
                        }
                        if (data.getAICategorization == null) {
                            return (<MessageBar>
                                {this.context.getTranslation("compliance", "noData")}
                            </MessageBar>)
                        }
                        return (
                            <EditAICategorization
                                data={data.getAICategorization}
                                closePanel={hidePanel}
                                apol={apol}
                                auth={auth}
                                refreshAICat={this.props.refreshAICat}
                                setMessageError={this.props.setMessageError}
                                userRules={this.props.userRules}
                            />
                        )
                    }}
                </Query>}
            </div>
            )
            }
            {id === null &&
                <EditAICategorization
                    closePanel={hidePanel}
                    apol={apol}
                    auth={auth}
                    refreshAICat={this.props.refreshAICat}
                    setMessageError={this.props.setMessageError}
                    userRules={this.props.userRules}
                />

            }
        </div>)
    }
}