// Header & Footer for Elements
import React from "react";
import {Icon} from "office-ui-fabric-react";



// Element Layout

function generateElement(title,content) {
    //console.log(title);
    return ( content)

}


export {
    generateElement
}