


const videoIndexerLangArr = [
    {
        key: "auto",
        text: "Automatic Detection",
    },
    {
        key: "en-US",
        text: "English",
    },
    {
        key: "zh-Hans",
        text: "Chinese (Simplified)",
    },
    {
        key: "es-ES",
        text: "Spanish",
    },
    {
        key: "fr-FR",
        text: "French",
    },
    {
        key: "ar-EG",
        text: "Arabic",
    },
    {
        key: "de-DE",
        text: "German",
    },
    {
        key: "it-IT",
        text: "Italian",
    },
    {
        key: "ja-JP",
        text: "Japanese",
    },
    {
        key: "hi-IN",
        text: "Hindi",
    },
    {
        key: "ko-KR",
        text: "Korean",
    },
    {
        key: "ru-RU",
        text: "Russian",
    },
    {
        key: "pt-BR",
        text: "Portuguese",
    }
];

export default videoIndexerLangArr;
