import React, { Component } from 'react'
import * as Azure from "@azure/storage-blob";
import * as path from "path";

import gql from "graphql-tag";

import { Icon, Button } from 'office-ui-fabric-react/lib';
import { MyContext } from '../../context';
import Utils from "../common/Utils";
import "./Setter.scss";

const ONE_MINUTE = 60 * 1000;
const aborter = Azure.Aborter.timeout(30 * ONE_MINUTE);
const content = "Test!";
const validFilename = require('valid-filename');

export default class UploadAnnouncement extends Component {
    constructor(props) {
        super(props);

        this.fileInputRef = React.createRef();

        this.state = {
            auth: props.authorization,
            uploadActive: false,
            contactButtonActive: false,
            helpPanel: false,
            language: "auto",
            playActive: false,
            playURL: ""
        }
    }

    componentDidMount() {
        this.getReplayUrl(this.props.file);

    }
    getBlockSize(file) {
        const size32Mb = 1024 * 1024 * 32;
        const size4Mb = 1024 * 1024 * 4;
        const size512Kb = 1024 * 512;

        return file.size > size32Mb ? size4Mb : size512Kb;
    }

    getReplayUrl = async (file) => {
        if (file !== null && file !== "") {
            this.props.apol
                .mutate({
                    mutation: gql`
                    mutation ent ($tenantId : String!, $userId: String, $sessionToken: String, $fileName:String) {
                        createAnnouncementFileBlobSas (input: { tenantId: $tenantId, userId: $userId, sessionToken: $sessionToken}, fileName:$fileName)
                    }
                    `,
                    variables: { tenantId: "", userId: "", sessionToken: "", fileName: file }
                }).then(async result => {
                    this.setState({ playURL: result.data.createAnnouncementFileBlobSas });
                });

        } else {
            return;
        }

    }
    
    async execute(file) {
        // Get SAS url for Tenant and User write access to Blob Storage
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($tenantId : String!, $userId: String, $sessionToken: String) {
                    createAnnouncementBlob (input: { tenantId: $tenantId, userId: $userId, sessionToken: $sessionToken})
                }
                `,
                variables: { tenantId: "", userId: "", sessionToken: "" }
            })
            .then(async result => {

                const anonymousCredential = new Azure.AnonymousCredential();
                const pipeline = Azure.StorageURL.newPipeline(anonymousCredential);
                if (result.data.createAnnouncementBlob === "NO LICENSE") {
                    document.getElementById(this.props.idResult).innerHTML = "No License. Please buy one from ASC Technologies AG";
                    //document.getElementById('licenseButton').innerHTML = "Contact ASC";

                    this.setState({ uploadActive: false, contactButtonActive: true });
                }
                else {
                    const serviceURL = new Azure.ServiceURL(result.data.createAnnouncementBlob, pipeline);

                    const containerURL = Azure.ContainerURL.fromServiceURL(serviceURL, "");

                    var filePath = path.resolve(file.name);

                    const fileName = path.basename(filePath).replace('.md', '-stream.md');
                    let fileNameUUID = Utils.guidGenerator() + fileName;
                  
                    if (!validFilename(file.name)) {
                        document.getElementById(this.props.idResult).innerHTML = "";
                        document.getElementById(this.props.idError).innerHTML = "No valid filename"
                    } else {

                        const blobName = fileNameUUID;

                        const blockBlobURL = Azure.BlockBlobURL.fromContainerURL(containerURL, blobName);

                        await blockBlobURL.upload(aborter, content, content.length);

                        const options = {
                            blockSize: this.getBlockSize(file), parallelism: 10, progress: (transferProgressEvent) => {
                                document.getElementById(this.props.idResult).innerHTML = "Upload: " + Math.round((transferProgressEvent.loadedBytes / file.size) * 100) + "%"
                                document.getElementById(this.props.idError).innerHTML = " File: " + file.name
                            }
                        };
                        await Azure.uploadBrowserDataToBlockBlob(aborter, file, blockBlobURL, options);


                        document.getElementById(this.props.idResult).innerHTML = "File uploaded";
                        document.getElementById(this.props.idError).innerHTML = "";

                        //save file to announcement
                        this.props.updateAnnouncement(fileNameUUID, this.props.type);

                        //set url
                        this.getReplayUrl(fileNameUUID);

                    }
                    this.setState({ uploadActive: false });
                }
            });
    };


    render() {
        return (<MyContext.Consumer>{(context) => (<>

            <div style={{ display: "inline" }}
                onClick={async () => {
                    document.getElementById(this.props.idResult).innerHTML = '';
                    document.getElementById(this.props.idError).innerHTML = '';

                    if (this.fileInputRef.current) {
                        this.fileInputRef.current.click()
                    }
                }}
            >
                {/*<div className="upload-button" style={{ display: "inline" }}>*/}
                {/*    <span>{context.getTranslation("common", "uploadFile")}</span>*/}
                {/*</div>*/}

                <Button disabled={this.state.uploadActive} className="upload-button" style={{ display: "inline" }}>
                    {(this.props.file !== null && this.props.file !== "")
                        ? context.getTranslation("common", "updateFile")
                        : context.getTranslation("common", "uploadFile")
                    }
                </Button>

            </div>
            {(this.props.fileBtnText !== "Start file" && this.props.fileBtnText !== "Stop file" && this.props.fileBtnText !== "") &&
                <>
                    <audio id={this.props.idResult + "player"} src={this.state.playURL} onEnded={() => this.setState({ playActive: false })}></audio>

                <div style={{ display: "inline", verticalAlign: "middle", marginRight: "10px" }}>
                        {!this.state.playActive
                            ? <Icon iconName={"Play"} style={{ cursor: "pointer", fontSize: "18px" }} onClick={() => { document.getElementById(this.props.idResult + "player").play(); this.setState({ playActive: true }) }} />
                            : <Icon iconName={"Pause"} style={{ cursor: "pointer", fontSize: "18px" }} onClick={() => { document.getElementById(this.props.idResult + "player").pause(); this.setState({ playActive: false }) }} />
                        }
                    </div>
                </>
            }

            <p style={{ display: "inline", marginRight: "10px", wordBreak: "break-all" }}>{this.props.fileBtnText}</p>


            <div >
                <input type="file"
                    id={this.props.idFiles} onChange={async () => {
                        var files = document.getElementById(this.props.idFiles).files;
                        if (!files.length) {
                            alert('Please select a file');
                        } else {
                            if (!validFilename(files[0])) {
                                alert('Please input a real filenmae!');
                            } else {
                                document.getElementById(this.props.idResult).innerHTML = 'Log in...';
                                this.setState({ uploadActive: true });
                                document.getElementById(this.props.idResult).innerHTML = 'Upload running...';
                                this.execute(files[0]).then(() => console.log("Done")).catch((e) => {
                                    document.getElementById(this.props.idError).innerHTML = 'Error...' + e; this.setState({ uploadActive: false });
                                });
                            }
                        }
                    }} ref={this.fileInputRef} style={{ display: "none" }} accept="audio/wav" name="avatar" />
                <div style={{ marginTop: "5px" }} id={this.props.idResult}></div>
                <div style={{ marginTop: "5px" }} id={this.props.idError}></div>
            </div>


            <br />

        </>)}</MyContext.Consumer>
        );
    }
}
