import React, { Component } from 'react';
import { ScrollablePane, mergeStyleSets, DetailsList, SelectionMode, FontIcon, Spinner, Stack } from 'office-ui-fabric-react/lib';
import { MyContext } from '../../../../../context';
import { IDetailsColumnStyles } from '@fluentui/react';
import AlertBar from '../../../../CustomComponents/AlertBar';
import * as Query from './PolicyContainerQuery';

export default class PolicyContainer extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            isFilteredByAIWithError: false,
            policyFilteredByAIWithError: false,
            errors: ""
        };
    }


    componentDidMount() {
        let conversationId = this.props.conversationId
        let aiCategorizationPolicies = this.props.aiCategorizationPolicies
        var errorsForPolicies = null;
        var dataForState = this.props.dataPolicy
        //Get the errors come by some policies on that conversation
        this.props.apol
            .query({
                query: Query.queryGetPolicyErrors,
                variables: {
                    conversationId: conversationId
                }
            }).then((result) => {
                if (result.data.getPolicyErrors != undefined) {
                    errorsForPolicies = result.data.getPolicyErrors
                }
            }).then(() => {
                if (Array.isArray(aiCategorizationPolicies) && aiCategorizationPolicies.length > 0) {
                    if (this.props.userRules.addOnCompliance && (!this.props.userRules.visibilityDetails || this.props.userRules.visibilityDetailsAnalytics) &&
                        (this.props.userRules.ruleAccess.complianceResultsAccess)) {
                        this.setState({ isLoading: true })
                        this.props.apol
                            .query({
                                query: Query.queryGetAIPolicyResult,
                                variables: {
                                    conversationId: conversationId,
                                    aiPolicyIds: aiCategorizationPolicies
                                }
                            })
                            .then((result) => {
                                if (result.data.getAIPolicyResult != undefined) {
                                    if (dataForState != null && dataForState.length > 0) {
                                        dataForState = [...dataForState, ...result.data.getAIPolicyResult]
                                    }
                                    else {
                                        dataForState = result.data.getAIPolicyResult
                                    }
                                }
                                this.setState({
                                    data: dataForState,
                                    isFilteredByAIWithError: this.props.isFilteredByAIWithError,
                                    policyFilteredByAIWithError: this.props.policyFilteredByAIWithError,
                                    isLoading: false,
                                    errors: errorsForPolicies.map(x => x.policyName + ": " + x.causes).join(";")
                                });
                            });
                    }
                } else {
                    this.setState({
                        data: dataForState,
                        isFilteredByAIWithError: this.props.isFilteredByAIWithError,
                        policyFilteredByAIWithError: this.props.policyFilteredByAIWithError,
                        isLoading: false,
                        errors: errorsForPolicies.map(x => x.policyName + ": " + x.causes).join(";")
                    });
                }
            })


    }

    headerStyle: Partial<IDetailsColumnStyles> = {
        cellTitle: {
            display: 'block',
            textAlign: 'center',
        }
    }


    getRiskRating = (riskRatingString) => {
        const iconStyle = {
            display: 'block',
            textAlign: 'center',
        }

        switch (riskRatingString) {
            case "Info":
                return <span style={iconStyle}>{<FontIcon iconName="Info" style={{ fontSize: "20px" }} title="Information" />}</span>;
            case "Warn":
                return <span style={iconStyle}>{<FontIcon iconName="WarningSolid" style={{ fontSize: "20px" }} title="Warning" />}</span>;
            case "Adherence":
                return <span style={iconStyle}>{<FontIcon iconName="Warning" style={{ fontSize: "20px" }} title="Attention" />}</span>;
            default:
                return <span />
        }
    }

    _renderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName]
        switch (column.key) {
            case "name":
                return <span>{item.policy.name}</span>;
            case "version":
                return <span>{item.policy.version}</span>;
            case "selected": {
                return <span>{item.policy.selected ? this.context.getTranslation("common", "yes") : this.context.getTranslation("common", "no")} </span>;
            }
            case "description":
                return <span>{item.policy.description}</span>;
            case "riskRating":
                return this.getRiskRating(item.policy.riskRating);
            case "policyRuleName": {
                let icon = ""
                let title = ""
                switch (item.policy.basedOn) {
                    case "PolicyRule": {
                        icon = "TaskManager"
                        title = "Policy rule"
                        break;
                    }
                    case "AICategorization": {
                        icon = "UserGauge"
                        title = "AI categorization"
                        break;
                    }
                    case "Scorecard": {
                        icon = "TaskManager" //to fix
                        title = "Scorecard"
                        break;
                    }
                    default: break;
                }
                return (
                    <div>
                        <span>
                            <FontIcon
                                iconName={icon}
                                title={title}
                                style={{ color: this.props.theme.palette.themePrimary, marginRight: "5px", marginTop: "2px" }}
                            />
                        </span>
                        <span>{item.policyRule.name}</span>
                    </div>
                );
            }
            case 'results':
                //in case we have policy results we render them otherwise, if we are dealing with AICAT based policies, we show an information note
                return (
                    item.policyResult.length > 0
                        ? item.policyResult.map((item, i) => {
                            if (item.type === "AICategorizationItem") {
                                return <PolicyAIResult item={item} key={i} />
                            } else {
                                return <PolicyResult item={item} key={i} listener={this.props.listener} />
                            }
                        })
                        : (
                            item.policy.baseOn === "aICategorization"
                                ? <div>{this.context.getTranslation("detailContainer", "noResultsAICatBasedPolicyNotice")}</div>
                                : <div></div>
                        )
                )
            default:
                return <span>{fieldContent !== null ? fieldContent : ""}</span>;
        }
    }

    render() {
        var classNames = mergeStyleSets({
            wrapper: {
                height: this.props.height,
                position: 'relative',
                minHeight: '100px'
            },
            pane: {
            }
        });

        const columns = [
            { key: 'name', name: this.context.getTranslation("compliance", "policy"), minWidth: 100, maxWidth: 150, isResizable: true },
            { key: 'version', name: this.context.getTranslation("compliance", "policyVersion"), maxWidth: 50, isResizable: true },
            { key: 'selected', name: this.context.getTranslation("compliance", "selectedVersion"), maxWidth: 50 },
            { key: 'description', name: this.context.getTranslation("compliance", "policyDescription"), minWidth: 100, maxWidth: 200 },
            { key: 'policyRuleName', name: this.context.getTranslation("compliance", "basedOn"), minWidth: 100, maxWidth: 150 },
            { key: 'riskRating', styles: this.headerStyle, name: this.context.getTranslation("compliance", "detailPolicyRiskRating"), minWidth: 50, maxWidth: 100 },
            { key: 'results', name: this.context.getTranslation("compliance", "policyResult"), minWidth: 200, maxWidth: 400 },
        ]

        return (window.innerWidth > 600 ?
            <div className={classNames.wrapper}>
                <ScrollablePane styles={{ root: classNames.pane }}>
                    {this.state.isLoading && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}
                    {this.state.errors != "" && (<AlertBar
                        message={this.state.errors}
                        alertType={"warning"}
                    />)}
                    {this.state.data !== null && < div >

                        {this.state.isFilteredByAIWithError && (<AlertBar
                            message={this.context.getTranslation("compliance", "isFilteredByAI") + this.state.policyFilteredByAIWithError}
                            alertType={"warning"}
                        />)}



                        <DetailsList
                            items={this.state.data}
                            columns={columns}
                            onRenderItemColumn={this._renderItemColumn}
                            selectionMode={SelectionMode.none}
                        />
                    </div>}
                    {this.state.data === null && !this.state.isLoading && <div> <h2>{this.context.getTranslation("detailContainer", "policyMessage")}</h2> </div>}
                </ScrollablePane>
            </div> : <div>
                {this.state.isLoading && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}
                {this.state.data !== null && < div >

                    {this.state.isFilteredByAIWithError && (<AlertBar
                        message={this.context.getTranslation("compliance", "isFilteredByAI") + this.state.policyFilteredByAIWithError}
                        alertType={"warning"}
                    />)}

                    <DetailsList
                        items={this.state.data}
                        columns={columns}
                        onRenderItemColumn={this._renderItemColumn}
                        selectionMode={SelectionMode.none}
                    />
                </div>}
                {this.state.data === null && !this.state.isLoading && <div> <h2>{this.context.getTranslation("detailContainer", "policyMessage")}</h2> </div>}
            </div>);
    }
}

class PolicyAIResult extends Component {

    render() {
        return (
            <Stack horizontal style={{ flexFlow: "wrap" }} >
                <Stack.Item>
                    <span style={{ fontSize: "12px" }}> {`(${this.props.item.type}) ${this.props.item.name} `}</span>
                </Stack.Item>
            </Stack>
        )

    }
}

class PolicyResult extends Component {

    render() {
        return (
            <Stack horizontal style={{ flexFlow: "wrap" }} >
                <Stack.Item>
                    <span style={{ fontSize: "12px" }}> {`(${this.props.item.type}) ${this.props.item.name} `}</span>
                </Stack.Item>
                {this.props.item.appearances.sort((a, b) => { return (a.startSeconds > b.startSeconds) ? 1 : ((b.startSeconds > a.startSeconds) ? -1 : 0) }).map((a, i) => {
                    return <Stack.Item key={i}>
                        < PolicyLink key={i} sec={a.startSeconds} time={a.startTime} words={a.words} listener={this.props.listener} />
                    </Stack.Item>
                })}
            </Stack>
        )

    }
}

class PolicyLink extends Component {

    static contextType = MyContext

    seek = () => {
        if (this.props.listener !== undefined && this.props.listener != null)
            this.props.listener(this.props.sec);
    };

    removeLastDigitsOfTime = (time) => {
        var tmp = time.split(":");
        var result = "";
        if (tmp[2]) {
            var tmp1 = tmp[2].split(".");
            result = tmp[0] + ":" + tmp[1] + ":" + tmp1[0];
        } else {
            result = tmp[0] + ":" + tmp[1];

        }

        return result;
    }

    render() {
        return <div>
            {this.props.time !== "0" &&
                <div style={{ marginLeft: "3px" }}>
                    <span style={{ fontSize: "12px" }}>{this.props.words.join(", ")}</span>
                    <a style={{ padding: "4px", cursor: "pointer", outline: "none", fontSize: "12px" }}
                        onClick={() => (this.seek())}>{this.removeLastDigitsOfTime(this.props.time)}
                    </a>
                </div>}
            {this.props.time === "0" &&
                <span style={{ padding: "4px", outline: "none", fontSize: "12px" }}>
                    {"- " + this.context.getTranslation("compliance", "notMentioned")}
                </span>
            }
        </div>;
    }
}