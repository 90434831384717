import { Stack, Dropdown, IconButton, Separator, Spinner, TextField } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import * as query from '../PolicyRulesQuery';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';



export default class FormPatternsDetection extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            availablePatterns: [],
            frequence: this.props.frequence,
            selectedConnection: this.props.connectionInsideArea,
            connections: this.props.availableConnections,
            isFrequencyEnabled: this.props.frequence > 1
        }
    }

    componentDidMount() {
        //load patterns from db
        this.props.apol
            .query({
                query: query.getPatternsDetection,
            })
            .then((result) => {
                if (result.data.getPatternsDetection !== null && result.data.getPatternsDetection.length > 0) {
                    const availablePatterns = result.data.getPatternsDetection.map(pattern => {
                        return ({
                            "key": pattern.masterItemId,
                            "text": pattern.name
                        })
                    })

                    //to understand if a PATTERN was deleted
                    let idAvailablePattern = availablePatterns.map(pat => pat.key)
                    let includedPattern = this.props.selectedPatterns.filter(p => idAvailablePattern.includes(p))
                    let deletedElement = includedPattern.length !== this.props.selectedPatterns.length
                    this.props.checkElementToRestoreOldVersion(deletedElement)

                    this.setState({
                        availablePatterns,
                        deletedElement
                    })
                }
            });
    }

    onChangeSelectPattern = (e, item) => {
        if (item) {
            this.props.updatePatterns(this.props.position, item.selected ? [...this.props.selectedPatterns, item.key] : this.props.selectedPatterns.filter(key => key !== item.key))
        }
    }

    onChangeSelectConnection = (e, item) => {
        this.setState({
            selectedConnection: item.key,
            isFrequencyEnabled: !(item.key == "AND" || item.key == "OR")
        })
        this.props.updateConnectionInsideArea(this.props.position, item.key)
    }

    changeNewFrequence = (e, value) => {
        this.setState({
            frequence: parseInt(value),
            error: ""
        })
        this.props.updateFrequenceInsideArea(this.props.position, parseInt(value))
    }

    changeFrequencyVisibility = () => {
        if (this.state.selectedConnection != "NONE" && this.state.selectedConnection != null) {
            let isFrequencyEnabled = this.state.selectedConnection == "AT_LEAST" ? true : !this.state.isFrequencyEnabled
            if (!isFrequencyEnabled) {
                this.props.updateFrequenceInsideArea(this.props.position, parseInt(1))
            }
            this.setState(prevState => ({
                isFrequencyEnabled: isFrequencyEnabled,
                frequence: isFrequencyEnabled ? prevState.frequence : 1
            }))
        }
    }

    render() {

        return (
            <div>
                <Stack horizontal>
                    <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => this.props.deleteArea(this.props.position)} />
                    <span style={{ marginLeft: "5px", marginTop: "5px", fontWeight: "bold" }}>{`${this.context.getTranslation("compliance", "patternDetection")} (${this.props.selectedPatterns.length})`}</span>
                    {!["NONE", "AT_LEAST", null].includes(this.state.selectedConnection) && (<TooltipHost
                        content={this.context.getTranslation("compliance", "tooltipFrequencyButton")}
                        setAriaDescribedBy={false}
                    >
                        <IconButton style={this.state.isFrequencyEnabled ? { color: "gray" } : {}} iconProps={{ iconName: 'Info' }} aria-label="Emoji" disabled={false} onClick={this.changeFrequencyVisibility} />
                    </TooltipHost>)}
                </Stack>
                {!this.state.deletedElement && <Stack horizontal style={{ marginTop: "10px", marginLeft: "30px" }}>
                    <Stack.Item align="center" style={{ maxWidth: "600px", minWidth: "200px" }}>
                        {this.state.availablePatterns.length === 0 &&
                            <Spinner ariaLive="assertive" />
                        }
                        {this.state.availablePatterns.length > 0 && <Dropdown
                            placeholder={this.context.getTranslation("compliance", "selectPatterns")}
                            label=""
                            selectedKeys={this.props.selectedPatterns}
                            onChange={this.onChangeSelectPattern}
                            multiSelect
                            options={this.state.availablePatterns}
                            disabled={!this.props.patternsCheck}
                        />}
                    </Stack.Item>
                    <Stack.Item align="center">
                        {this.props.selectedPatterns.length > 1 && < Dropdown
                            style={{ marginLeft: "20px" }}
                            placeholder={this.context.getTranslation("compliance", "selectConnection")}
                            label=""
                            selectedKey={this.state.selectedConnection}
                            onChange={this.onChangeSelectConnection}
                            options={this.state.connections}
                        />}
                    </Stack.Item>
                    {this.state.isFrequencyEnabled && (<Stack.Item align="center" style={{ marginLeft: "20px", width: "50px" }}>
                        <TextField type="number" value={this.state.frequence} onChange={this.changeNewFrequence} disabled={this.props.disabled} />
                    </Stack.Item>)}
                </Stack>}
                {this.state.deletedElement && <Stack horizontal style={{ marginTop: "10px", marginLeft: "30px" }}>
                    <span style={{ color: "red" }}>{this.context.getTranslation("compliance", "deletedElement") + " " + this.props.selectedPatterns.join(", ")}</span>
                </Stack>}
            </div>
        )
    }
}