import React, { Component } from 'react';
import { ASCHeader } from "../ASCHeader/ASCHeader";
import ASCFooter from "../ASCFooter/ASCFooter";
import UploadMail from "../UploadView/UploadMail"
import { Text, FontSizes, Button } from 'office-ui-fabric-react';

import {
    Stack
} from "office-ui-fabric-react";
import { MyContext } from '../../context';


// Styles definition
const stackContainerStyles: IStackStyles = {
    root: {
        paddingTop: 50
    }
};


const stackStyles: IStackStyles = {
    root: {
        maxWidth: 660,
        marginBottom: 50
    }
};

// Tokens definition
const stackTokens: IStackTokens = { childrenGap: 5 };


export default class Product extends Component {
    constructor(props) {
        super(props);
        this.uploadMail = React.createRef();
        this.state = {
            uploadActive: false
        }
    }





    onSendMail = (ev) => {
        if (this.uploadMail.current) {
            this.uploadMail.current.showPanel();
        }
    }


    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <>

                        <ASCHeader title={context.getTranslation("product","recordingInsights")} />

                        <Stack tokens={stackTokens}>

                            <Stack horizontalAlign="center" styles={stackContainerStyles}>

                                <Stack styles={stackStyles}>
                                    <Text variant="xxLarge">1. {context.getTranslation("product", "overview")}</Text>
                                    <Text variant="large"> {context.getTranslation("product", "overviewText")}</Text>
                                </Stack>
                                <Button id="licenseButton" style={{ backgroundColor: 'lightgrey', padding: "8px", fontSize: FontSizes.icon }} onClick={() => { this.onSendMail() }} >{context.getTranslation("product", "contactAsc")}</Button>
                                <UploadMail
                                    id={1}
                                    apol={this.props.apol}
                                    authorization={this.props.authorization}
                                    ref={this.uploadMail} />
                                <Stack styles={stackStyles}>


                                    <Text variant="xxLarge">2. {context.getTranslation("product", "description")}</Text>
                                    <Text variant="large"> {context.getTranslation("product", "descriptionText1")}</Text>
                                    <Text variant="large"> {context.getTranslation("product", "descriptionText2")}</Text>
                                    <Text variant="large"> {context.getTranslation("product", "descriptionText3")}</Text>
                                    <Text variant="large"> {context.getTranslation("product", "descriptionText4")}</Text>

                                </Stack>

                            </Stack>


                        </Stack>

                        <ASCFooter />
                    </>
                )
                }
            </MyContext.Consumer>
        );
    }

}