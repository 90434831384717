import { DefaultButton } from "office-ui-fabric-react";
import { DetailsListLayoutMode } from 'office-ui-fabric-react/lib/DetailsList';
import {
    DetailsList,
    Icon,
    Persona,
    PersonaSize,
    PrimaryButton,
    SelectionMode,
    TextField
} from "office-ui-fabric-react/lib/index";
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from "reactjs-popup";
import { mutationDeleteUser, mutationUpdateUser, queryGetConfigData } from "../../../queries/querySettings";
import RuleSwitch from "../RuleList/RuleSwitch";
import { MyContext } from '../../../context';


const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});

export default class DepartmentConfigContent extends Component {

    state =
        {
            changed: false,
            rulesAnalytics: this.props.data.user[0].analytics,
            rulesAccess: this.props.data.user[0].access,
            rulesRecording: this.props.data.user[0].recordingRules,
            rulesReplay: this.props.data.user[0].replay,
            size: this.props.data.user.length
        };

    render() {
        return this.userPanelContent();
    }
    userPanelContent = () => {

        return (
            <div>
                {this.renderRules()}
                {this.renderOptions()}
            </div>
        );

    };
    showInfo = (user) => {
        if (user.length === 1) {


            let userContent = {
                name: user[0].name,
                department: user[0].department,
                office: user[0].department,
                superviser: "",
                street: "",
                city: "",
                zip: "",
                country: ""
            };


            return (
                <MyContext.Consumer>
                    {(context) => (
                        <div>
                            <Persona text={userContent.name} />
                            <hr />
                            <p>{context.getTranslation("configuration", "department")}: {userContent.department}</p>
                            <p>{context.getTranslation("configuration", "office")}: {userContent.office}</p>
                            <p>{context.getTranslation("configuration", "supervisor")}: {userContent.superviser}</p>
                            <p>{context.getTranslation("configuration", "street")}: {userContent.street}</p>
                            <p>{context.getTranslation("configuration", "city")}: {userContent.city}</p>
                            <p>{context.getTranslation("configuration", "zip")}: {userContent.zip}</p>
                            <p>{context.getTranslation("configuration", "country")}: {userContent.country}</p>
                        </div>
                    )
                    }
                </MyContext.Consumer>
            );

        } else {

            return (
                <div>
                    {user.map(({ name }, i) => {
                        return (
                            <p key={i} style={{ margin: "4px 0" }}>
                                <Persona
                                    size={PersonaSize.size40}
                                    id={i}
                                    text={name}
                                />
                            </p>
                        );
                    })}
                </div>
            );

        }
    }

    toggleSaveButton = (newRules) => {
        if (this.state.changed === false) {
            this.setState({ changed: true });
        }
        this.setState(newRules);
    };

    renderRules = () => {
        return <MyContext.Consumer>
            {(context) => (
                <div style={{ padding: "5px" }}>
                    <hr />
                    <h4>{context.getTranslation("configuration", "rules")}</h4>
                    <RuleSwitch data={this.props.data} action={this.toggleSaveButton} />
                </div>
            )
            }
        </MyContext.Consumer>
    };

    buttenSel = (sel) => {
        if (sel) {
            return <MyContext.Consumer>{(context) => (<PrimaryButton data-automation-id="test" text={context.getTranslation("common", "save")} allowDisabledFocus={true} onClick={() => { this.notifySave(); this.props.cl() }} />)}</MyContext.Consumer>
        }
        else {
            return <MyContext.Consumer>{(context) => (<PrimaryButton data-automation-id="test" disabled text={context.getTranslation("common", "save")} allowDisabledFocus={true} />)}</MyContext.Consumer>
        }
    };

    getUserNames = () => {
        var userNames = "";
        this.props.data.user.forEach(function (aUser) {
            userNames += aUser.name + "; ";
        });
        return userNames;
    }

    notifyDelete = async () => {
        var listId = [];
        this.props.data.user.forEach(function (aUser) {
            listId.push(aUser.id);
        });
        var data = await this.props.apol
            .mutate({
                mutation: mutationDeleteUser,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    id: listId,
                }
            });
        if (data.data.deleteUser == null || data.data.deleteUser[0].id === "error") {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userDeleteNotSuccessful")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userDeleted")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    notifySave = async () => {
        var userList = [];
        this.props.data.user.forEach((aUser) => {
            userList.push({
                id: aUser.id,
                recordingRules: this.state.rulesRecording,
                access: this.state.rulesAccess,
                replay: this.state.rulesReplay,
                analytics: this.state.rulesAnalytics != null ? this.state.rulesAnalytics.join() : null
            });
        });
        var data = await this.props.apol
            .mutate({
                mutation: mutationUpdateUser,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    auth: this.props.authorization,
                    user: userList
                }
            });
        if (data.data.updateUser == null || data.data.updateUser[0].name === "error") {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userUpdateNotSuccessful")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userUpdated")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    renderOptions = () => {

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>
                        <hr />
                        <p>
                            {this.buttenSel(this.state.changed)}
                        </p>
                        <p>
                            <Popup trigger={
                                <DefaultButton
                                    data-automation-id="test"
                                    style={{ background: "red", color: "white" }}
                                    text={context.getTranslation("configuration", "deleteDepartment")}
                                    allowDisabledFocus={true}
                                />}
                                modal >
                                {close => (
                                    <div>
                                        <div className="ms-Grid" dir="ltr">
                                            <p>{context.getTranslation("configuration", "deleteUserQuestion")} {this.getUserNames()} ?</p>
                                            <p>
                                                <DefaultButton
                                                    onClick={
                                                        () => {
                                                            this.notifyDelete();
                                                            close();
                                                            this.props.cl()
                                                        }
                                                    }
                                                    data-automation-id="test"
                                                    text={context.getTranslation("common", "yes")}
                                                    allowDisabledFocus={true}
                                                />

                                                <PrimaryButton
                                                    data-automation-id="test"
                                                    text={context.getTranslation("common", "no")}
                                                    allowDisabledFocus={true}
                                                    onClick={
                                                        () => {
                                                            close();
                                                            this.props.cl()

                                                        }
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Popup>

                        </p>

                    </div>
                )
                }
            </MyContext.Consumer>

        );

    };

    getItems = () => {
        let _allItems = [];
        for (let i = 30000; i < 30005; i++) {
            _allItems.push({
                userId: i,
                systemId: i,
                tenentId: i
            });
        }
        return _allItems;
    }
    _columns = [
        { key: 'column1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userId")}</>)}</MyContext.Consumer>, fieldName: 'userId', minWidth: 20, maxWidth: 160, isResizable: true },
        { key: 'column2', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "systemId")}</>)}</MyContext.Consumer>, fieldName: 'systemId', minWidth: 20, maxWidth: 160, isResizable: true },
        { key: 'column3', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "tenantId")}</>)}</MyContext.Consumer>, fieldName: 'tenentId', minWidth: 20, maxWidth: 160, isResizable: true },
        {
            key: 'column4',
            name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "options")}</>)}</MyContext.Consumer>,
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onColumnClick: this._onColumnClick,
            onRender: (item) => {
                return <Popup trigger={<a style={{ cursor: "pointer" }}><Icon iconName={"RemoveLink"} /></a>} modal>
                    {close => (
                        <MyContext.Consumer>
                            {(context) => (
                                <div>
                                    <h2>{context.getTranslation("configuration", "unlinkAccount")} : {item.value}</h2>

                                    <p><DefaultButton onClick={close} data-automation-id="test" text={context.getTranslation("common", "delete")} allowDisabledFocus={true} /><PrimaryButton onClick={close} data-automation-id="test" text="Cancel" allowDisabledFocus={true} /></p>
                                </div>
                            )
                            }
                        </MyContext.Consumer>
                    )}
                </Popup>

            }
        }
    ];
    _columnsPopup = [
        { key: 'column1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "userId")}</>)}</MyContext.Consumer>, fieldName: 'userId', minWidth: 20, maxWidth: 160, isResizable: true },
        { key: 'column2', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "systemId")}</>)}</MyContext.Consumer>, fieldName: 'systemId', minWidth: 20, maxWidth: 160, isResizable: true },
        { key: 'column3', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "tenantId")}</>)}</MyContext.Consumer>, fieldName: 'tenentId', minWidth: 20, maxWidth: 160, isResizable: true }, {
            key: 'column4',
            name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "options")}</>)}</MyContext.Consumer>,
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onColumnClick: this._onColumnClick,
            onRender: (item) => {
                return <Popup trigger={<a style={{ cursor: "pointer" }}><Icon iconName={"RemoveLink"} /></a>} position={"center"} closeOnDocumentClick>
                    <a style={{ cursor: "pointer", width: "16px", height: "16px" }}><Icon iconName={"Delete"} /></a>
                </Popup>
            }
        }
    ];

    renderUserMapping = () => {
        if (this.state.size > 1) {
            return (<div></div>);
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>
                        <hr />
                        <h4>{context.getTranslation("configuration", "linkedAccounts")}</h4>
                        <DetailsList
                            key={"001"}
                            items={this.getItems()}
                            columns={this._columns}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selection={false}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={false}
                            onItemInvoked={this._onItemInvoked}
                        />

                        <p className="padding-default--tb">

                            <Popup trigger={

                                <PrimaryButton
                                    data-automation-id="test"
                                    text={context.getTranslation("configuration", "linkAccount")}
                                    allowDisabledFocus={true}
                                />
                            } modal >

                                {close => (
                                    <div>
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">

                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                                                    <h2>{context.getTranslation("configuration", "linkAccount")}</h2>

                                                    <div className="padding-default-b">
                                                        <Persona text={this.props.data.user[0].name} />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="ms-Grid-row">

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                                    <form>
                                                        <TextField label={context.getTranslation("configuration", "userId")} />
                                                        <TextField label={context.getTranslation("configuration", "systemId")} />
                                                        <TextField label={context.getTranslation("configuration", "tenantId")} />
                                                        <p className="padding-default--tb">
                                                            <DefaultButton data-automation-id="test" text={context.getTranslation("configuration", "add")} allowDisabledFocus={true} />
                                                        </p>
                                                    </form>
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">

                                                    <DetailsList
                                                        key={"002"}
                                                        items={this.getItems()}
                                                        columns={this._columnsPopup}
                                                        layoutMode={DetailsListLayoutMode.fixedColumns}
                                                        selection={false}
                                                        selectionMode={SelectionMode.none}
                                                        selectionPreservedOnEmptyClick={false}
                                                        onItemInvoked={this._onItemInvoked}
                                                    />

                                                </div>

                                            </div>

                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <p className="padding-default--tb">
                                                        <PrimaryButton
                                                            onClick={() => { close(); this.notifySave() }}
                                                            data-automation-id="test"
                                                            text={context.getTranslation("common", "save")}
                                                            allowDisabledFocus={true}
                                                        />
                                                        <DefaultButton
                                                            onClick={close}
                                                            data-automation-id="test"
                                                            text={context.getTranslation("common", "cancel")}
                                                            allowDisabledFocus={true}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }

                            </Popup>
                        </p>
                    </div>
                )
                }
            </MyContext.Consumer>
        );
    }

}
//this.props.data.user[0]
