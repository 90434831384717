import React, { Component } from 'react'
import { FontIcon } from '@fluentui/react/lib/Icon'
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

const iconClass = mergeStyles({
    fontSize: 12,
    transform: 'rotate(-90deg)',
    marginTop:"15px"
})

const divIconClass = mergeStyles({
    background: "trasparent",
    ':hover': {
        backgroundColor: '#e3e3e3'
    },
    fontSize: 12,
    marginLeft: '5px',
    marginRight: '10px',
    '@media(prefers-color-scheme: dark)': {
        background: "trasparent",
        ':hover': {
            backgroundColor: '#414141'
        },
        borderBottom: "1px solid rgb(32, 32, 32)"
    },
    height: "48px",
    width: "35px",
    alignItems: 'center',
    textAlign: "center",
    borderBottom: "1px solid rgb(247, 247, 247);"
})

const headerAccordion = mergeStyles({
    background: '#f1f1f1', 
    ':hover': {
        backgroundColor: '#ededed'
    },
    '@media(prefers-color-scheme: dark)': {
        background: "#2b2b2b",
        ':hover': {
            backgroundColor: '#333333'
        },
        borderBottom: "1px solid rgb(32, 32, 32)"
    },
    height: 48,
    borderBottom: "1px solid rgb(247, 247, 247);"
})

class RIAccordion extends Component {


    constructor(props) {
        super(props)

        this.state = {
            selectedKey: null,
        }
    }

    selectKeyAccordion = (selectedKey) => {
        if (selectedKey === this.state.selectedKey) {
            this.setState({
                selectedKey: null
            })
        } else {
            this.setState({
                selectedKey
            })
        }

    }

    render() {
        return (
            <div>
                {
                    this.props.data.map((d, i) => {
                        return (
                            <div key={i} >
                                <div className={headerAccordion}>
                                    <div style={{ marginLeft: "10px", display: 'flex', alignItems: 'center' }}>
                                        <div className={divIconClass} onClick={() => this.selectKeyAccordion(i)} >
                                            <FontIcon
                                                style={this.state.selectedKey == i ? { transform: 'rotate(-0deg)', } : {}}
                                                iconName="ChevronDown" className={iconClass} />
                                        </div>
                                        {d.header}
                                    </div>
                                </div>
                                <div hidden={this.state.selectedKey !== i}>{d.content}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default RIAccordion