import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { MyContext } from '../../../context';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { queryGetLoggedUserData } from '../../../queries/querySettings'
import SettingsColumnsTenant from './SettingsColumnsTenant';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { FontSizes } from 'office-ui-fabric-react/lib';

import zipcelx from 'zipcelx';

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '20px',
        maxWidth: '20px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});

const config = {
    filename: 'TenantReport',
    sheet: {
        data: [
            [{
                value: "Tenant",
                type: 'string'
            }, {
                value: 'Type',
                type: 'string'
            }, {
                value: 'Reseller',
                type: 'bool'
            }, {
                value: "Onboarding",
                type: 'date'
            }, {
                value: "Training",
                type: 'date'
            }, {
                value: "Billing",
                type: 'date'
            }, {
                value: "SupportNotes",
                type: 'string'
            }, {
                value: "SystemId",
                type: 'string'
            }, {
                value: "Country",
                type: 'string'
            }, {
                value: "OrderNr",
                type: 'string'
            }, {
                value: "Partner",
                type: 'string'
            }, {
                value: "PartnerContract",
                type: 'string'
            }, {
                value: "LicenseSmartVoiceUsed",
                type: 'string'
            }, {
                value: "LicenseSmartVoiceBought",
                type: 'string'
            }, {
                value: "LicenseSmartVoiceAnaUsed",
                type: 'string'
            }, {
                value: "LicenseSmartVoiceAnaBought",
                type: 'string'
            }, {
                value: "LicenseSmartUCUsed",
                type: 'string'
            }, {
                value: "LicenseSmartUCBought",
                type: 'string'
            }, {
                value: "LicenseSmartUCAnaUsed",
                type: 'string'
            }, {
                value: "LicenseSmartUCAnaBought",
                type: 'string'
            }, {
                value: "LicenseCompUsed",
                type: 'string'
            }, {
                value: "LicenseCompBought",
                type: 'string'
            }, {
                value: "LicenseCompAnaUsed",
                type: 'string'
            }, {
                value: "LicenseCompAnaBought",
                type: 'string'
            }, {
                value: "LicenseAnaUsed",
                type: 'string'
            }, {
                value: "LicenseAnaBought",
                type: 'string'
            }, {
                value: "LicenseCompVoiceUsed",
                type: 'string'
            }, {
                value: "LicenseCompVoiceBought",
                type: 'string'
            }, {
                value: "LicenseCompVoiceAnaUsed",
                type: 'string'
            }, {
                value: "LicenseCompVoiceAnaBought",
                type: 'string'
            }, {
                value: "LicenseRecordingInsinghtsAIUsed",
                type: 'string'
            }, {
                value: "LicenseRecordingInsinghtsAIBought",
                type: 'string'
            }, {
                value: "AddOnAnalyticsUsed",
                type: 'string'
            }, {
                value: "AddOnAnalyticsBought",
                type: 'string'
            }, {
                value: "AddOnFullChatUsed",
                type: 'string'
            }, {
                value: "AddOnFullChatBought",
                type: 'string'
            }, {
                value: "AddOnZoomUsed",
                type: 'string'
            }, {
                value: "AddOnZoomBought",
                type: 'string'
            }, {
                value: "AddOnGenesysUsed",
                type: 'string'
            }, {
                value: "AddOnGenesysBought",
                type: 'string'
            }, {
                value: "AddOnRingcentralUsed",
                type: 'string'
            }, {
                value: "AddOnRingcentralBought",
                type: 'string'
            }, {
                value: "AddOnDynamicsUsed",
                type: 'string'
            }, {
                value: "AddOnDynamicsBought",
                type: 'string'
            }, {
                value: "AddOnComplianceUsed",
                type: 'string'
            }, {
                value: "AddOnComplianceBought",
                type: 'string'
            }, {
                value: "AddOnQualityManagementUsed",
                type: 'string'
            }, {
                value: "AddOnQualityManagementBought",
                type: 'string'
            }, {
                value: "AddOnLeapXpertUsed",
                type: 'string'
            }, {
                value: "AddOnLeapXpertBought",
                type: 'string'
            }, {
                value: "PackageStorage",
                type: 'string'
            }, {
                value: "PackageVideoAna",
                type: 'string'
            }, {
                value: "PackageSpeechAna",
                type: 'string'
            }, {
                value: "PackageTranscriptAna",
                type: 'string'
            }, {
                value: "PackageTranslate",
                type: 'string'
            }, {
                value: "PackageAnalyticsAI",
                type: 'string'
            }, {
                value: "StorageLocation",
                type: 'string'
            }, {
                value: "TenantId",
                type: 'string'
            }, {
                value: "storageUsed",
                type: 'string'
            }, {
                value: "storageBought",
                type: 'string'
            }, {
                value: "TranscriptUsed",
                type: 'string'
            }, {
                value: "TranscriptBought",
                type: 'string'
            }, {
                value: "VideoIndexerUsed",
                type: 'string'
            }, {
                value: "VideoIndexerBought",
                type: 'string'
            }, {
                value: "SpeechAnalyticsUsed",
                type: 'string'
            }, {
                value: "SpeechAnalyticsBought",
                type: 'string'
            }, {
                value: "TranslateUsed",
                type: 'string'
            }, {
                value: "TranslateBought",
                type: 'string'
            }, {
                value: "ImportUsed",
                type: 'string'
            }, {
                value: "PackageImport",
                type: 'string'
            }, {
                value: "ExportUsed",
                type: 'string'
            }, {
                value: "PackageExport",
                type: 'string'
            }, {
                value: "MailAlarming",
                type: 'string'
            }
            ]
        ]
    }
};

const configGlobal = {
    filename: 'TenantReport',
    sheet: {
        data: [
            [{
                value: "Tenant",
                type: 'string'
            }, {
                value: 'Type',
                type: 'string'
            }, {
                value: 'Reseller',
                type: 'bool'
            }, {
                value: "Onboarding",
                type: 'date'
            }, {
                value: "Training",
                type: 'date'
            }, {
                value: "Billing",
                type: 'date'
            }, {
                value: "SupportNotes",
                type: 'string'
            }, {
                value: "SystemId",
                type: 'string'
            }, {
                value: "Country",
                type: 'string'
            }, {
                value: "OrderNr",
                type: 'string'
            }, {
                value: "Partner",
                type: 'string'
            }, {
                value: "PartnerContract",
                type: 'string'
            }, {
                value: "Support",
                type: 'string'
            }, {
                value: "LicenseSmartVoiceUsed",
                type: 'string'
            }, {
                value: "LicenseSmartVoiceBought",
                type: 'string'
            }, {
                value: "LicenseSmartVoiceAnaUsed",
                type: 'string'
            }, {
                value: "LicenseSmartVoiceAnaBought",
                type: 'string'
            }, {
                value: "LicenseSmartUCUsed",
                type: 'string'
            }, {
                value: "LicenseSmartUCBought",
                type: 'string'
            }, {
                value: "LicenseSmartUCAnaUsed",
                type: 'string'
            }, {
                value: "LicenseSmartUCAnaBought",
                type: 'string'
            }, {
                value: "LicenseCompUsed",
                type: 'string'
            }, {
                value: "LicenseCompBought",
                type: 'string'
            }, {
                value: "LicenseCompAnaUsed",
                type: 'string'
            }, {
                value: "LicenseCompAnaBought",
                type: 'string'
            }, {
                value: "LicenseAnaUsed",
                type: 'string'
            }, {
                value: "LicenseAnaBought",
                type: 'string'
            }, {
                value: "LicenseCompVoiceUsed",
                type: 'string'
            }, {
                value: "LicenseCompVoiceBought",
                type: 'string'
            }, {
                value: "LicenseCompVoiceAnaUsed",
                type: 'string'
            }, {
                value: "LicenseCompVoiceAnaBought",
                type: 'string'
            }, {
                value: "LicenseRecordingInsinghtsAIUsed",
                type: 'string'
            }, {
                value: "LicenseRecordingInsinghtsAIBought",
                type: 'string'
            }, {
                value: "AddOnAnalyticsUsed",
                type: 'string'
            }, {
                value: "AddOnAnalyticsBought",
                type: 'string'
            }, {
                value: "AddOnFullChatUsed",
                type: 'string'
            }, {
                value: "AddOnFullChatBought",
                type: 'string'
            }, {
                value: "AddOnZoomUsed",
                type: 'string'
            }, {
                value: "AddOnZoomBought",
                type: 'string'
            }, {
                value: "AddOnGenesysUsed",
                type: 'string'
            }, {
                value: "AddOnGenesysBought",
                type: 'string'
            }, {
                value: "AddOnRingcentralUsed",
                type: 'string'
            }, {
                value: "AddOnRingcentralBought",
                type: 'string'
            }, {
                value: "AddOnDynamicsUsed",
                type: 'string'
            }, {
                value: "AddOnDynamicsBought",
                type: 'string'
            }, {
                value: "AddOnComplianceUsed",
                type: 'string'
            }, {
                value: "AddOnQualityManagementUsed",
                type: 'string'
            }, {
                value: "AddOnQualityManagementBought",
                type: 'string'
            }, {
                value: "AddOnComplianceBought",
                type: 'string'
            }, {
                value: "AddOnLeapXpertUsed",
                type: 'string'
            }, {
                value: "AddOnLeapXpertBought",
                type: 'string'
            }, {
                value: "PackageStorage",
                type: 'string'
            }, {
                value: "PackageVideoAna",
                type: 'string'
            }, {
                value: "PackageSpeechAna",
                type: 'string'
            }, {
                value: "PackageTranscriptAna",
                type: 'string'
            }, {
                value: "PackageTranslate",
                type: 'string'
            }, {
                value: "PackageAnalyticsAI",
                type: 'string'
            }, {
                value: "StorageLocation",
                type: 'string'
            }, {
                value: "TenantId",
                type: 'string'
            }, {
                value: "Bot01",
                type: 'string'
            }, {
                value: "Bot02",
                type: 'string'
            }, {
                value: "Bot03",
                type: 'string'
            }, {
                value: "Bot04",
                type: 'string'
            }, {
                value: "Bot05",
                type: 'string'
            }, {
                value: "Bot06",
                type: 'string'
            }, {
                value: "Bot07",
                type: 'string'
            }, {
                value: "Bot08",
                type: 'string'
            }, {
                value: "Bot09",
                type: 'string'
            }, {
                value: "Bot10",
                type: 'string'
            }, {
                value: "Bot11",
                type: 'string'
            }, {
                value: "Bot12",
                type: 'string'
            }, {
                value: "Bot13",
                type: 'string'
            }, {
                value: "Bot14",
                type: 'string'
            }, {
                value: "Bot15",
                type: 'string'
            }, {
                value: "Bot16",
                type: 'string'
            }, {
                value: "Bot17",
                type: 'string'
            }, {
                value: "Bot18",
                type: 'string'
            }, {
                value: "Bot19",
                type: 'string'
            }, {
                value: "Bot20",
                type: 'string'
            }, {
                value: "storageUsed",
                type: 'string'
            }, {
                value: "storageBought",
                type: 'string'
            }, {
                value: "TranscriptUsed",
                type: 'string'
            }, {
                value: "TranscriptBought",
                type: 'string'
            }, {
                value: "VideoIndexerUsed",
                type: 'string'
            }, {
                value: "VideoIndexerBought",
                type: 'string'
            }, {
                value: "SpeechAnalyticsUsed",
                type: 'string'
            }, {
                value: "SpeechAnalyticsBought",
                type: 'string'
            }, {
                value: "TranslateUsed",
                type: 'string'
            }, {
                value: "TranslateBought",
                type: 'string'
            }, {
                value: "ImportUsed",
                type: 'string'
            }, {
                value: "PackageImport",
                type: 'string'
            }, {
                value: "ExportUsed",
                type: 'string'
            }, {
                value: "PackageExport",
                type: 'string'
            }, {
                value: "MailAlarming",
                type: 'string'
            }
            ]
        ]
    }
};

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}


export default class UserList extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: [];

    defaultSettingsColumns = ["option", "name", "type", "storageContent", "license1", "license2"];


    constructor(props) {
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: true,
            items: this._allItems,
            allColumns: [],
            columns: [],
            settingsColumns: [],
            filter: "",
            isDialogOpen: false
        };

    }

    styles = {
        root: {
            paddingTop: "20px"
        }
    };

    async componentDidMount() {
        //get List view settings from db
        let auth = this.props.authorization
        let result = await this.props.apol
            .query({
                query: queryGetLoggedUserData,
                variables: { auth }
            })
        let user = result.data.getLoggedUserData
        let settingsColumns = user === null || user.listTenantSettings === null ? this.defaultSettingsColumns : user.listTenantSettings

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "options")}</>)}</MyContext.Consumer>,
                isIconOnly: true,
                isResizable: false,
                fieldName: 'option',
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    if (item.reseller)
                        return <Icon iconName={"Calculator"} />
                    else
                        return <a style={{ cursor: "pointer" }} onClick={() => { this.props.func(item, 0) }}><Icon iconName={"Settings"} /></a>
                }
            },
            {
                key: 'column2',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "name")}</>)}</MyContext.Consumer>,
                fieldName: 'name',
                minWidth: 160,
                maxWidth: 400,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.name}</span>;
                }
            },
            {
                key: 'column3',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "storage(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'storageContent',
                minWidth: 120,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.storageContent}GB / {item.storageMax}GB</span>;
                }
            },
            {
                key: 'column31',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "archive(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'archiveSizeUsed',
                minWidth: 120,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.archiveSizeUsed}GB / {item.packageArchive * 500}GB</span>;
                }
            },
            {
                key: 'column4',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "import(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'importSizeUsed',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.importSizeUsed}GB / {item.packageImport * 500}GB</span>;
                }
            },
            {
                key: 'column5',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "export(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'exportSizeUsed',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.exportSizeUsed}GB / {item.packageExport * 500}GB</span>;
                }
            },
            {
                key: 'column6',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "speechAnalytics(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'speechAnalytics',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.analyticsSpeechCapacityUsed}h / {item.analyticsSpeechCapacity}h</span>;
                }
            },
            {
                key: 'column7',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "transcript(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'transcriptAnalytics',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.analyticsTranscriptCapacityUsed}h / {item.analyticsTranscriptCapacity}h</span>;
                }
            },
            {
                key: 'column8',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "videoAnalytics(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'videoAnalytics',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.analyticsVideoCapacityUsed}h / {item.analyticsVideoCapacity}h</span>;
                }
            },
            {
                key: 'column9',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "translateAnalytics(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'translateAnalytics',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.analyticsTranslateCapacityUsed}h / {item.analyticsTranslateCapacity}h</span>;
                }
            },
            {
                key: 'column32',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "AnalyticsAICapacity(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'analyticsAICapacity',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.analyticsAICapacityUsed}Token / {item.analyticsAICapacity}Token</span>;
                }
            },
            {
                key: 'column10',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnAnalytics(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'addOnAnalytics',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.addOnAnalyticsUsed} / {item.addOnAnalytics}</span>;
                }
            },
            {
                key: 'column11',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnFullChat(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'addOnFullChat',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.addOnFullChatUsed} / {item.addOnFullChat}</span>;
                }
            },
            {
                key: 'column12',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnZoom(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'addOnZoom',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.addOnZoomUsed} / {item.addOnZoom}</span>;
                }
            },
            {
                key: 'column13',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnGenesys(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'addOnGenesys',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.addOnGenesysUsed} / {item.addOnGenesys}</span>;
                }
            },
            {
                key: 'column14',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnRingcentral(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'addOnRingcentral',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.addOnRingcentralUsed} / {item.addOnRingcentral}</span>;
                }
            },
            {
                key: 'column15',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnDynamics(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'addOnDynamics',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.addOnDynamicsUsed} / {item.addOnDynamics}</span>;
                }
            },

            {
                key: 'column16',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnCompliance(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'addOnCompliance',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.addOnComplianceUsed} / {item.addOnCompliance}</span>;
                }
            },
            {
                key: 'addOnQualityManagement',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnQualityManagement(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'addOnQualityManagement',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.addOnQualityManagementUsed} / {item.addOnQualityManagement}</span>;
                }
            },
            {
                key: 'column31',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "addOnLeapXpert(Used/Bought)")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'addOnLeapXpert',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.addOnLeapXpertUsed} / {item.addOnLeapXpert}</span>;
                }
            },
            {
                key: 'column17',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "tenantId")}</>)}</MyContext.Consumer>,
                fieldName: 'tenantId',
                isResizable: true,
                minWidth: 140,
                maxWidth: 250,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.tenantId}</span>;
                }
            },
            {
                key: 'column18',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "adminUserId")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'adminId',
                minWidth: 140,
                maxWidth: 250,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.adminId}</span>;
                }
            },
            {
                key: 'column19',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "reseller")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'reseller',
                minWidth: 100,
                maxWidth: 100,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.reseller ? "Yes" : "No"}</span>;
                }
            },
            {
                key: 'column20',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "type")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'type',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.type}</span>;
                }
            },
            {
                key: 'column21',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "contractNo")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'contractNumber',
                minWidth: 120,
                maxWidth: 140,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.contractNumber}</span>;
                }
            },
            {
                key: 'column22',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceRec")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license1',
                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseComp != null ? item.licenseComp.used + "/" + item.licenseComp.bought : 0 / 0}</span>;
                }
            },
            {
                key: 'column23',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceRecAna")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license2',
                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseCompAna != null ? item.licenseCompAna.used + "/" + item.licenseCompAna.bought : 0 / 0}</span>;
                }
            },
            {
                key: 'column24',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartVoice")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license8',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseSmartVoice != null ? item.licenseSmartVoice.used + "/" + item.licenseSmartVoice.bought : "0/0"}</span>;
                }
            },
            {
                key: 'column25',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartVoiceAna")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license9',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseSmartVoiceAna != null ? item.licenseSmartVoiceAna.used + "/" + item.licenseSmartVoiceAna.bought : "0/0"}</span>;
                }
            },
            {
                key: 'column26',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartRec")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license3',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseSmart != null ? item.licenseSmart.used + "/" + item.licenseSmart.bought : "0/0"}</span>;
                }
            },
            {
                key: 'column27',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "smartRecAna")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license4',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseSmartAna != null ? item.licenseSmartAna.used + "/" + item.licenseSmartAna.bought : "0/0"}</span>;
                }
            },
            {
                key: 'column28',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceVoiceRec")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license6',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseCompVoice != null ? item.licenseCompVoice.used + "/" + item.licenseCompVoice.bought : "0/0"}</span>;
                }
            },
            {
                key: 'column29',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "complianceVoiceRecAna")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license7',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseCompVoiceAna != null ? item.licenseCompVoiceAna.used + "/" + item.licenseCompVoiceAna.bought : "0/0"}</span>;
                }
            },
            {
                key: 'column30',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "chatRecordingStandalone")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license5',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseAna != null ? item.licenseAna.used + "/" + item.licenseAna.bought : "0/0"}</span>;
                }
            },
            {
                key: 'column31',
                name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "licenseRecordingInsinghtsAI")}</>)}</MyContext.Consumer>,
                isResizable: true,
                fieldName: 'license10',

                minWidth: 100,
                maxWidth: 200,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    return <span>{item.licenseRecordingInsinghtsAI != null ? item.licenseRecordingInsinghtsAI.used + "/" + item.licenseRecordingInsinghtsAI.bought : "0/0"}</span>;
                }
            }

        ];
        let resultColumns = columns
        resultColumns = settingsColumns.map(c => {
            return resultColumns.filter(col => col.fieldName === c)[0]
        }).filter(d => d !== undefined)
        this.setState({
            columns: resultColumns,
            allColumns: columns,
            settingsColumns
        })

    }

    _dialogClose = () => this.setState({ isDialogOpen: false })


    _onExport = (ev) => {
        var itemList = [];
        itemList.push(this.props.userRules.ruleAccess.tenantCreate ? configGlobal.sheet.data[0].slice() : config.sheet.data[0].slice());
        var exportList = this._selection.getSelection().length ? this._selection.getSelection() : this.userData;
        exportList.forEach((anItem) => {
            this.props.userRules.ruleAccess.tenantCreate ?
                itemList.push([
                    {
                        value: anItem.name,
                        type: 'string'
                    }, {
                        value: anItem.type,
                        type: 'string'
                    }, {
                        value: anItem.reseller,
                        type: 'string'
                    }, {
                        value: anItem.onboardingDate,
                        type: 'date'
                    }, {
                        value: anItem.trainingDate,
                        type: 'date'
                    }, {
                        value: anItem.billingDate,
                        type: 'date'
                    }, {
                        value: anItem.supportNotes,
                        type: 'string'
                    }, {
                        value: anItem.systemId,
                        type: 'string'
                    }, {
                        value: anItem.country,
                        type: 'string'
                    }, {
                        value: anItem.contractNumber,
                        type: 'string'
                    }, {
                        value: anItem.partner,
                        type: 'string'
                    }, {
                        value: anItem.partnerContract,
                        type: 'string'
                    }, {
                        value: anItem.support,
                        type: 'string'
                    }, {
                        value: anItem.licenseSmartVoice != null ? anItem.licenseSmartVoice.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartVoice != null ? anItem.licenseSmartVoice.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartVoiceAna != null ? anItem.licenseSmartVoiceAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartVoiceAna != null ? anItem.licenseSmartVoiceAna.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmart != null ? anItem.licenseSmart.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmart != null ? anItem.licenseSmart.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartAna != null ? anItem.licenseSmartAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartAna != null ? anItem.licenseSmartAna.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseComp != null ? anItem.licenseComp.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseComp != null ? anItem.licenseComp.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompAna != null ? anItem.licenseCompAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompAna != null ? anItem.licenseCompAna.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseAna != null ? anItem.licenseAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseAna != null ? anItem.licenseAna.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompVoice != null ? anItem.licenseCompVoice.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompVoice != null ? anItem.licenseCompVoice.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompVoiceAna != null ? anItem.licenseCompVoiceAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompVoiceAna != null ? anItem.licenseCompVoiceAna.bought : 0,
                        type: 'number'
                    },{
                        value: anItem.licenseRecordingInsinghtsAI != null ? anItem.licenseRecordingInsinghtsAI.used : 0,
                        type: 'number'
                    },{
                        value: anItem.licenseRecordingInsinghtsAI != null ? anItem.licenseRecordingInsinghtsAI.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.addOnAnalyticsUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnAnalytics,
                        type: 'number'
                    }, {
                        value: anItem.addOnFullChatUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnFullChat,
                        type: 'number'
                    }, {
                        value: anItem.addOnZoomUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnZoom,
                        type: 'number'
                    }, {
                        value: anItem.addOnGenesysUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnGenesys,
                        type: 'number'
                    }, {
                        value: anItem.addOnRingcentralUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnRingcentral,
                        type: 'number'
                    }, {
                        value: anItem.addOnDynamicsUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnDynamics,
                        type: 'number'
                    }, {
                        value: anItem.addOnComplianceUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnCompliance,
                        type: 'number'
                    }, {
                        value: anItem.addOnQualityManagementUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnQualityManagement,
                        type: 'number'
                    }, {
                        value: anItem.addOnLeapXpert,
                        type: 'number'
                    }, {
                        value: anItem.addOnLeapXpertUsed,
                        type: 'number'
                    }, {
                        value: anItem.packageStorage,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsVideo,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsSpeech,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsTranscript,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsTranslate,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsAI,
                        type: 'number'
                    }, {
                        value: anItem.storage,
                        type: 'string'
                    }, {
                        value: anItem.tenantId,
                        type: 'string'
                    }, {
                        value: anItem.bot01,
                        type: 'number'
                    }, {
                        value: anItem.bot02,
                        type: 'number'
                    }, {
                        value: anItem.bot03,
                        type: 'number'
                    }, {
                        value: anItem.bot04,
                        type: 'number'
                    }, {
                        value: anItem.bot05,
                        type: 'number'
                    }, {
                        value: anItem.bot06,
                        type: 'number'
                    }, {
                        value: anItem.bot07,
                        type: 'number'
                    }, {
                        value: anItem.bot08,
                        type: 'number'
                    }, {
                        value: anItem.bot09,
                        type: 'number'
                    }, {
                        value: anItem.bot10,
                        type: 'number'
                    }, {
                        value: anItem.bot11,
                        type: 'number'
                    }, {
                        value: anItem.bot12,
                        type: 'number'
                    }, {
                        value: anItem.bot13,
                        type: 'number'
                    }, {
                        value: anItem.bot14,
                        type: 'number'
                    }, {
                        value: anItem.bot15,
                        type: 'number'
                    }, {
                        value: anItem.bot16,
                        type: 'number'
                    }, {
                        value: anItem.bot17,
                        type: 'number'
                    }, {
                        value: anItem.bot18,
                        type: 'number'
                    }, {
                        value: anItem.bot19,
                        type: 'number'
                    }, {
                        value: anItem.bot20,
                        type: 'number'
                    }, {
                        value: anItem.storageContent,
                        type: 'number'
                    }, {
                        value: anItem.storageMax,
                        type: 'number'
                    }, {
                        value: anItem.analyticsTranscriptCapacityUsed,
                        type: 'number'
                    }, {
                        value: anItem.analyticsTranscriptCapacity,
                        type: 'number'
                    }, {
                        value: anItem.analyticsVideoCapacityUsed,
                        type: 'number'
                    }, {
                        value: anItem.analyticsVideoCapacity,
                        type: 'number'
                    }, {
                        value: anItem.analyticsSpeechCapacityUsed,
                        type: 'number'
                    }, {
                        value: anItem.analyticsSpeechCapacity,
                        type: 'number'
                    }, {
                        value: anItem.analyticsTranslateCapacityUsed,
                        type: 'number'
                    }, {
                        value: anItem.analyticsTranslateCapacity,
                        type: 'number'
                    }, {
                        value: anItem.importSizeUsed,
                        type: 'number'
                    }, {
                        value: anItem.packageImport,
                        type: 'number'
                    }, {
                        value: anItem.exportSizeUsed,
                        type: 'number'
                    }, {
                        value: anItem.packageExport,
                        type: 'number'
                    }, {
                        value: anItem.mailAlarming,
                        type: 'string'
                    }]) :
                itemList.push([
                    {
                        value: anItem.name,
                        type: 'string'
                    }, {
                        value: anItem.type,
                        type: 'string'
                    }, {
                        value: anItem.reseller,
                        type: 'string'
                    }, {
                        value: anItem.onboardingDate,
                        type: 'date'
                    }, {
                        value: anItem.trainingDate,
                        type: 'date'
                    }, {
                        value: anItem.billingDate,
                        type: 'date'
                    }, {
                        value: anItem.supportNotes,
                        type: 'string'
                    }, {
                        value: anItem.systemId,
                        type: 'string'
                    }, {
                        value: anItem.country,
                        type: 'string'
                    }, {
                        value: anItem.contractNumber,
                        type: 'string'
                    }, {
                        value: anItem.partner,
                        type: 'string'
                    }, {
                        value: anItem.partnerContract,
                        type: 'string'
                    }, {
                        value: anItem.licenseSmartVoice != null ? anItem.licenseSmartVoice.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartVoice != null ? anItem.licenseSmartVoice.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartVoiceAna != null ? anItem.licenseSmartVoiceAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartVoiceAna != null ? anItem.licenseSmartVoiceAna.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmart != null ? anItem.licenseSmart.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmart != null ? anItem.licenseSmart.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartAna != null ? anItem.licenseSmartAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseSmartAna != null ? anItem.licenseSmartAna.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseComp != null ? anItem.licenseComp.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseComp != null ? anItem.licenseComp.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompAna != null ? anItem.licenseCompAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompAna != null ? anItem.licenseCompAna.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseAna != null ? anItem.licenseAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseAna != null ? anItem.licenseAna.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompVoice != null ? anItem.licenseCompVoice.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompVoice != null ? anItem.licenseCompVoice.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompVoiceAna != null ? anItem.licenseCompVoiceAna.used : 0,
                        type: 'number'
                    }, {
                        value: anItem.licenseCompVoiceAna != null ? anItem.licenseCompVoiceAna.bought : 0,
                        type: 'number'
                    },{
                        value: anItem.licenseRecordingInsinghtsAI != null ? anItem.licenseRecordingInsinghtsAI.used : 0,
                        type: 'number'
                    },{
                        value: anItem.licenseRecordingInsinghtsAI != null ? anItem.licenseRecordingInsinghtsAI.bought : 0,
                        type: 'number'
                    }, {
                        value: anItem.addOnAnalyticsUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnAnalytics,
                        type: 'number'
                    }, {
                        value: anItem.addOnFullChatUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnFullChat,
                        type: 'number'
                    }, {
                        value: anItem.addOnZoomUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnZoom,
                        type: 'number'
                    }, {
                        value: anItem.addOnGenesysUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnGenesys,
                        type: 'number'
                    }, {
                        value: anItem.addOnRingcentralUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnRingcentral,
                        type: 'number'
                    }, {
                        value: anItem.addOnDynamicsUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnDynamics,
                        type: 'number'
                    }, {
                        value: anItem.addOnComplianceUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnCompliance,
                        type: 'number'
                    }, {
                        value: anItem.addOnQualityManagementUsed,
                        type: 'number'
                    }, {
                        value: anItem.addOnQualityManagement,
                        type: 'number'
                    }, {
                        value: anItem.addOnLeapXpert,
                        type: 'number'
                    }, {
                        value: anItem.addOnLeapXpertUsed,
                        type: 'number'
                    }, {
                        value: anItem.packageStorage,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsVideo,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsSpeech,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsTranscript,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsTranslate,
                        type: 'number'
                    }, {
                        value: anItem.packageAnalyticsAI,
                        type: 'number'
                    }, {
                        value: anItem.storage,
                        type: 'string'
                    }, {
                        value: anItem.tenantId,
                        type: 'string'
                    }, {
                        value: anItem.storageContent,
                        type: 'number'
                    }, {
                        value: anItem.storageMax,
                        type: 'number'
                    }, {
                        value: anItem.analyticsTranscriptCapacityUsed,
                        type: 'number'
                    }, {
                        value: anItem.analyticsTranscriptCapacity,
                        type: 'number'
                    }, {
                        value: anItem.analyticsVideoCapacityUsed,
                        type: 'number'
                    }, {
                        value: anItem.analyticsVideoCapacity,
                        type: 'number'
                    }, {
                        value: anItem.analyticsSpeechCapacityUsed,
                        type: 'number'
                    }, {
                        value: anItem.analyticsSpeechCapacity,
                        type: 'number'
                    }, {
                        value: anItem.analyticsTranslateCapacityUsed,
                        type: 'number'
                    }, {
                        value: anItem.analyticsTranslateCapacity,
                        type: 'number'
                    }, {
                        value: anItem.importSizeUsed,
                        type: 'number'
                    }, {
                        value: anItem.packageImport,
                        type: 'number'
                    }, {
                        value: anItem.exportSizeUsed,
                        type: 'number'
                    }, {
                        value: anItem.packageExport,
                        type: 'number'
                    }, {
                        value: anItem.mailAlarming,
                        type: 'string'
                    }]);
        });
        config.sheet.data = itemList;
        zipcelx(config)
        this.setState({ isDialogOpen: true });
    }


    // Data for CommandBar
    getItems = () => {
        let addbutton = {
            key: 'add',
            name: 'Add Tenant',
            onClick: () => { this.props.func(null, 1) },
            iconProps: {
                iconName: 'Add'
            }

        };

        let searchField = {
            key: 'text',
            name: 'text',
            onRender: () => { return <div style={{ padding: "4px" }} > <TextField onChange={this._onChangeText} /> </div> },
        }

        let download = {
            key: 'download',
            iconProps: { iconName: 'Download' },
            name: 'Export Report',
            onClick: () => { this._onExport() }
        }

        if (this.props.userRules.ruleAccess.tenantCreate) {
            return [addbutton, searchField, download];
        }
        else if (this.props.userRules.access == null || this.props.userRules.access == "Administrator" || this.props.userRules.access == "") {
            return [];
        } else {
            return [searchField, download];
        }
    };

    render() {
        const { columns, allColumns, settingsColumns, isModalSelection } = this.state;
        this.ruleData = this.props.ruleData;
        this.userData = this._generateDocuments(this.props.data, this.state.filter);
        if (columns.length === 0 || settingsColumns.length === 0)
            return (<MyContext.Consumer >
                {(context) => (
                    <Fabric>
                        <MyContext.Consumer>{(context) => (<Spinner label={context.getTranslation("configuration", "loading")} ariaLive="assertive" labelPosition="top" />)}</MyContext.Consumer>
                    </Fabric>
                )}
            </MyContext.Consumer>
            );
        else return (<MyContext.Consumer >
            {(context) => (
                <Fabric>
                    {this.userData.length === 1 && (this.props.userRules.access === null || this.props.userRules.access === "Administrator" || this.props.userRules.access === "") ?
                        <Stack style={{ padding: "10px" }}>
                            <Stack.Item >
                                <Stack>
                                    <Stack.Item>
                                        <a style={{ cursor: "pointer" }} onClick={() => { this.props.func(this.userData[0], 0) }}><Icon iconName={"Settings"} /></a>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "name")}
                                            value={this.userData[0].name}
                                            disabled
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "tenantId")}
                                            value={this.userData[0].tenantId}
                                            disabled
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "adminUserId")}
                                            value={this.userData[0].adminId}
                                            disabled
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "type")}
                                            value={this.userData[0].type}
                                            disabled
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "contractNo")}
                                            value={this.userData[0].contractNumber}
                                            disabled
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "storage(Used/Bought)")}
                                            value={this.userData[0].storageContent + "GB / " + this.userData[0].storageMax + "GB"}
                                            disabled
                                        />
                                    </Stack.Item>
                                    {this.userData[0].packageArchive != null && this.userData[0].packageArchive > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "archive(Used/Bought)")}
                                            value={this.userData[0].archiveSizeUsed + "GB / " + this.userData[0].packageArchive * 500 + "GB"}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].packageImport != null && this.userData[0].packageImport > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "import(Used/Bought)")}
                                            value={this.userData[0].importSizeUsed + "GB / " + this.userData[0].packageImport * 500 + "GB"}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].packageExport != null && this.userData[0].packageExport > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "export(Used/Bought)")}
                                            value={this.userData[0].exportSizeUsed + "GB / " + this.userData[0].packageExport * 500 + "GB"}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].analyticsSpeechCapacity != null && this.userData[0].analyticsSpeechCapacity > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "speechAnalytics(Used/Bought)")}
                                            value={this.userData[0].analyticsSpeechCapacityUsed + "h / " + this.userData[0].analyticsSpeechCapacity + "h"}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].analyticsTranscriptCapacity != null && this.userData[0].analyticsTranscriptCapacity > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "transcript(Used/Bought)")}
                                            value={this.userData[0].analyticsTranscriptCapacityUsed + "h / " + this.userData[0].analyticsTranscriptCapacity + "h"}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].analyticsVideoCapacity != null && this.userData[0].analyticsVideoCapacity > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "videoAnalytics(Used/Bought)")}
                                            value={this.userData[0].analyticsVideoCapacityUsed + "h / " + this.userData[0].analyticsVideoCapacity + "h"}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].analyticsTranslateCapacity != null && this.userData[0].analyticsTranslateCapacity > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "translateAnalytics(Used/Bought)")}
                                            value={this.userData[0].analyticsTranslateCapacityUsed + "h / " + this.userData[0].analyticsTranslateCapacity + "h"}
                                            disabled
                                        />
                                    </Stack.Item>}

                                    {this.userData[0].licenseComp != null && this.userData[0].licenseComp.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "complianceRec")}
                                            value={this.userData[0].licenseComp.used + "/" + this.userData[0].licenseComp.bought}
                                            disabled
                                        />
                                    </Stack.Item>}

                                    {this.userData[0].analyticsAICapacity != null && this.userData[0].analyticsAICapacity > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "AnalyticsAICapacity(Used/Bought)")}
                                            value={this.userData[0].analyticsAICapacityUsed + "Token / " + this.userData[0].analyticsAICapacity + "Token"}
                                            disabled
                                        />
                                    </Stack.Item>}

                                    {this.userData[0].licenseCompAna != null && this.userData[0].licenseCompAna.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "complianceRecAna")}
                                            value={this.userData[0].licenseCompAna.used + "/" + this.userData[0].licenseCompAna.bought}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].licenseSmartVoice != null && this.userData[0].licenseSmartVoice.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "smartVoice")}
                                            value={this.userData[0].licenseSmartVoice.used + "/" + this.userData[0].licenseSmartVoice.bought}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].licenseSmartVoiceAna != null && this.userData[0].licenseSmartVoiceAna.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "smartVoiceAna")}
                                            value={this.userData[0].licenseSmartVoiceAna.used + "/" + this.userData[0].licenseSmartVoiceAna.bought}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].licenseSmart != null && this.userData[0].licenseSmart.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "smartRec")}
                                            value={this.userData[0].licenseSmart.used + "/" + this.userData[0].licenseSmart.bought}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].licenseSmartAna != null && this.userData[0].licenseSmartAna.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "smartRecAna")}
                                            value={this.userData[0].licenseSmartAna.used + "/" + this.userData[0].licenseSmartAna.bought}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].licenseCompVoice != null && this.userData[0].licenseCompVoice.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "complianceVoiceRec")}
                                            value={this.userData[0].licenseCompVoice.used + "/" + this.userData[0].licenseCompVoice.bought}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].licenseCompVoiceAna != null && this.userData[0].licenseCompVoiceAna.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "complianceVoiceRecAna")}
                                            value={this.userData[0].licenseCompVoiceAna.used + "/" + this.userData[0].licenseCompVoiceAna.bought}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].licenseAna != null && this.userData[0].licenseAna.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("configuration", "chatRecordingStandalone")}
                                            value={this.userData[0].licenseAna.used + "/" + this.userData[0].licenseAna.bought}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].licenseRecordingInsinghtsAI != null && this.userData[0].licenseRecordingInsinghtsAI.bought > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "licenseRecordingInsinghtsAI")}
                                            value={this.userData[0].licenseRecordingInsinghtsAI.used + "/" + this.userData[0].licenseRecordingInsinghtsAI.bought}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].addOnAnalytics != null && this.userData[0].addOnAnalytics > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "addOnAnalytics(Used/Bought)")}
                                            value={this.userData[0].addOnAnalyticsUsed + "/" + this.userData[0].addOnAnalytics}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].addOnFullChat != null && this.userData[0].addOnFullChat > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "addOnFullChat(Used/Bought)")}
                                            value={this.userData[0].addOnFullChatUsed + "/" + this.userData[0].addOnFullChat}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].addOnZoom != null && this.userData[0].addOnZoom > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "addOnZoom(Used/Bought)")}
                                            value={this.userData[0].addOnZoomUsed + "/" + this.userData[0].addOnZoom}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].addOnGenesys != null && this.userData[0].addOnGenesys > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "addOnGenesys(Used/Bought)")}
                                            value={this.userData[0].addOnGenesysUsed + "/" + this.userData[0].addOnGenesys}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].addOnRingcentral != null && this.userData[0].addOnRingcentral > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "addOnRingcentral(Used/Bought)")}
                                            value={this.userData[0].addOnRingcentralUsed + "/" + this.userData[0].addOnRingcentral}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].addOnDynamics != null && this.userData[0].addOnDynamics > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "addOnDynamics(Used/Bought)")}
                                            value={this.userData[0].addOnDynamicsUsed + "/" + this.userData[0].addOnDynamics}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].addOnCompliance != null && this.userData[0].addOnCompliance > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "addOnCompliance(Used/Bought)")}
                                            value={this.userData[0].addOnComplianceUsed + "/" + this.userData[0].addOnCompliance}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].addOnQualityManagement != null && this.userData[0].addOnQualityManagement > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "addOnQualityManagement(Used/Bought)")}
                                            value={this.userData[0].addOnQualityManagementUsed + "/" + this.userData[0].addOnQualityManagement}
                                            disabled
                                        />
                                    </Stack.Item>}
                                    {this.userData[0].addOnLeapXpert != null && this.userData[0].addOnLeapXpert > 0 && <Stack.Item>
                                        <TextField label={context.getTranslation("tenants", "addOnLeapXpert(Used/Bought)")}
                                            value={this.userData[0].addOnLeapXpertUsed + "/" + this.userData[0].addOnLeapXpert}
                                            disabled
                                        />
                                    </Stack.Item>}

                                </Stack>
                            </Stack.Item>
                        </Stack>

                        : < div >
                            <Dialog
                                isOpen={this.state.isDialogOpen}
                                type={DialogType.close}
                                onDismiss={this._dialogClose.bind(this)}
                                title='Excel export'
                                subText='Excel file created and downloaded to your favourite directory'
                                isBlocking={false}
                                closeButtonAriaLabel='Close'
                            />
                            <div className={classNames.controlWrapper}>
                            </div>
                            <Stack horizontal><CommandBar items={this.getItems()} ariaLabel={context.getTranslation("common", "navigateBetweenCommands")} />
                                <Stack.Item style={{ padding: "8px" }}><SettingsColumnsTenant settingsColumns={settingsColumns} allColumns={allColumns} updateColumnsSettings={this.updateColumnsSettings} apol={this.props.apol} /></Stack.Item>
                            </Stack>
                            <MarqueeSelection selection={this._selection}>
                                <DetailsList
                                    items={this.userData}
                                    compact={false}
                                    columns={columns}
                                    groups={this.generateGroups()}
                                    selectionMode={isModalSelection ? SelectionMode.multiple : SelectionMode.none}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    selection={this._selection}
                                    selectionPreservedOnEmptyClick={true}
                                    onItemInvoked={this._onItemInvoked}
                                    enterModalSelectionOnTouch={true}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                />
                            </MarqueeSelection>
                        </div>}

                </Fabric>
            )
            }
        </MyContext.Consumer>
        );
    }

    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isCompactMode: checked });
    };

    _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isModalSelection: checked });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.userData = this.userData.filter(i => i.name.includes(text) || i.name.toLocaleLowerCase().includes(text));
        this.setState({ filter: text });
    };

    openMultySelect = () => {
        console.log(this._selection.getSelection())
        if (this._selection.getSelection().length > 0) {
            this.props.func(this._selection.getSelection(), 0);
        }
    };

    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                return '';
            default:
                return `${selectionCount} Items selected`;
        }
    }

    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        this.setState({
            columns: newColumns,
        });
    };

    _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    };

    _generateDocuments(DataArr, text) {
        // Take out first one and remember
        var ownTenant;
        let DataArrFiltered = DataArr.slice(0);
        if (DataArrFiltered.length > 0) {
            ownTenant = DataArr[0];
            DataArrFiltered.splice(0, 1);
        }

        // First filter
        DataArrFiltered = DataArrFiltered.filter(i => i.name.includes(text) || i.name.toLocaleLowerCase().includes(text));
        // Then sort
        let newColumns = this.state.columns;
        newColumns.forEach((newCol: IColumn) => {
            if (newCol.isSorted) {
                DataArrFiltered = this._copyAndSort(DataArrFiltered, newCol.fieldName, newCol.isSortedDescending);
            }
        });
        // Add it again
        if (DataArr.length > 0) {
            DataArrFiltered.unshift(ownTenant);
        }
        return DataArrFiltered;

    };

    updateColumnsSettings = (columns) => {
        this.setState({ columns })
    }

    generateGroups = () => {

        let out = [];
        let replay = 0, access = 0, recording = 0, analytics = 0;

        {
            return [
                { key: 'ownTenant', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "ownTenant")}</>)}</MyContext.Consumer>, startIndex: 0, count: 1 },
                { key: 'allTenants', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "otherTenants")}</>)}</MyContext.Consumer>, startIndex: 1, count: this.userData.length - 1 },
            ]
        }
    };



}