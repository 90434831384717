
import React from 'react'
import {
    queryGetConfigData, mutationCreateCustomField, mutationUpdateCustomField, mutationDeleteCustomField, mutationCheckCustomFieldIsUsed,
    checkRetentionTimeValidity, checkCustomFieldName
} from "../../../queries/querySettings";
import {
    DefaultButton,
    SelectionMode,
    DetailsList,
    DetailsListLayoutMode,
    PrimaryButton,
    Stack,
    Selection,
    TextField,
    Slider,
    Checkbox,
    Dropdown,
    IColumn,
    Text,
    Label,
    ChoiceGroup,
    FontWeights,
    IconButton,
    CommandBarButton,
    Button,
    TeachingBubble
} from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { MyContext } from '../../../context';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import UserPicker from '../../ReportView/ReportConfiguration/UserPicker';

export default class AddCustomField extends React.Component {

    constructor(props) {
        super(props);

        if (this.props.data != null && this.props.data !== undefined) {
            this.state = this.props.data;
            this.state.ttlConfirmed = (this.props.data != null && this.props.data.actionType !== null && this.props.data.actionType == "SetTTl") ? true : false

        }
        else {
            this.state = {
                id: null,
                fieldName: "",
                mapping: "",
                isMapping: false,
                isNumeric: false,
                isAlphanumeric: false,
                maxNumberOfFields: 1,
                fieldLength: 1,
                hasExactLength: false,
                isCheckbox: false,
                hasTriggerAction: false,
                actionType: "NoAction",
                ttlSettingYes: 90,
                ttlSettingNo: 90,
                notifications: [],
                NotificationTime: 0,
                NotificationText: "",
                UsersToNotify: [],
                AddToToDo: false,
                IsCheckboxValueDependent: false,
                CheckboxDependencyType: "",
                usersList: [],
                usersIdList: [],
                validNotificaton: true,
                displayNotificationsConfig: false,
                updated: false,
                SendToRecordingOwner: false,
                numberCheckAlgorithmType: "None",
                hasAdditionalNumberCheck: false,
                ttlConfirmed: false,
                tempUserNotification:""
            }
        }


    }



    styles = {
        root: {
            paddingTop: "20px"
        }
    };
    columns = [
        {
            key: 'col0', name: "", fieldName: 'delete', minWidth: 10, maxWidth: 10, isResizable: true, onRender: () => {
                return <IconButton iconProps={{ iconName: 'Delete' }} onClick={this.delKeylist} style={{ height: "12px" }} />

            }
        },
        {
            key: 'col1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "time")}</>)}</MyContext.Consumer>, fieldName: 'NotificationTime', minWidth: 50, maxWidth: 50, isResizable: true, onRender: (item) => {
                return <span>{item.NotificationTime} d</span>
            }
        },
        {
            key: 'col2', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "recipient")}</>)}</MyContext.Consumer>, fieldName: 'UsersToNotify', minWidth: 100, maxWidth: 200, isResizable: true,
            onRender: (item) => {
                let emailsList = item.UsersToNotify.split(',');
                return <p className="overflow" id="emails" >
                    {emailsList.map(email => {
                        return <a className="linebreaks" key={email}>{email}</a>
                    })}
                </p>
            }

        },
        {
            key: 'col6', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "sendToRecordingOwner")}</>)}</MyContext.Consumer>, fieldName: 'SendToRecordingOwner', minWidth: 160, maxWidth: 200, isResizable: true, onRender: (item) => {
                return <Checkbox checked={item.SendToRecordingOwner} />
            }
        },
        {
            key: 'col3', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "text")}</>)}</MyContext.Consumer>, fieldName: 'NotificationText', minWidth: 100, maxWidth: 200, isResizable: true,
            onRender: (item) => {
                return <p className="overflow">{item.NotificationText}</p>
            }
        },
        {
            key: 'col4', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "toDo")}</>)}</MyContext.Consumer>, fieldName: 'AddToToDo', minWidth: 40, maxWidth: 200, isResizable: true, onRender: (item) => {
                return <Checkbox checked={item.AddToToDo} />
            }
        },
        {
            key: 'col5', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "dependency")}</>)}</MyContext.Consumer>, fieldName: 'CheckboxDependencyType', minWidth: 160, maxWidth: 200, isResizable: true, onRender: (item) => {
                let result = ""
                switch (item.CheckboxDependencyType) {
                    case 'YesDependent': result = "'Yes' selected"; break;
                    case 'NoDependent': result = "'No' selected"; break;
                    case 'NoDependency': result = "'Nothing' selected"; break;
                }
                return result;
            }
        },
    ];
    _selection = new Selection({
        onSelectionChanged: () => {
            this.setState({

                selectionDetails: this._selection.getSelection()[0]
            });
        }
    });


    checkCustomFieldUsed = () => {
        this.setState({ processingCheck: true, showDeleteButton: false });

        this.props.apol
            .mutate({
                mutation: mutationCheckCustomFieldIsUsed,
                variables: { customFieldId: this.props.data.id }
            })
            .then((result) => {

                this.setState({ customFieldUsed: result.data.checkCustomFieldIsUsed, processingCheck: false })
            });
    }

    deleteCustomField = async () => {
        var data = await this.props.apol
            .mutate({
                mutation: mutationDeleteCustomField,
                refetchQueries: [{
                    query: queryGetConfigData,
                    variables: { auth: this.props.authorization },
                }],
                variables: {
                    customFieldId: this.props.data.id
                }
            });

        if (data.data.deleteCustomField) {
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "customFieldDeleted")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "customFieldDeletedError")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        this.props.cl();

    }


    addToKeylist = (time, recipient, text, toDo, IsCheckboxValueDependent, CheckboxDependencyType, recipientsId, sendToRecordingOwner) => {
        let validCheck = this.validateNotification();

        if (validCheck) {

            var recipientVal = [];
            var recipientIdVal = [];
            let tmp = this.state.notifications;
            if (recipient !== undefined && recipient !== null) {
                recipientVal = recipient;
            }
            if (recipientsId !== undefined && recipientsId !== null) {
                recipientIdVal = recipientsId;
            }
            let stringUsersToNotify = recipientVal.join(',');
            let stringUsersToNotifyId = recipientIdVal.join(',');

            tmp.push({
                NotificationTime: time,
                UsersToNotify: stringUsersToNotify,
                NotificationText: text,
                AddToToDo: toDo,
                CheckboxDependencyType: CheckboxDependencyType,
                UsersToNotifyId: stringUsersToNotifyId,
                IsCheckboxValueDependent: IsCheckboxValueDependent,
                SendToRecordingOwner: sendToRecordingOwner,
                updated: true
            });
            let updateState = true;
            if (this.state.fieldName == "") {
                updateState = false;
                document.getElementById("fieldNameError").innerHTML = "Name cannot be empty";
                document.getElementById("fieldNameError").style.color = "red";
            } else {
                document.getElementById("fieldNameError").innerHTML = "";

            }
            this.setState({ NotificationTime: 0, usersList: [], NotificationText: "", AddToToDo: false, CheckboxDependencyType: "", IsCheckboxValueDependent: false, UsersToNotifyId: [], notifications: tmp, displayNotificationsConfig: false, updated: updateState, SendToRecordingOwner: false, tempUserNotification:"" });
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addSuccessful")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addCustomFieldNotSuccessfull")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }




    }
    delKeylist = () => {
        let tmp = this.state.notifications;
        let out = [];

        let ex = false;

        for (let x = 0; x < tmp.length; x++) {
            if (JSON.stringify(tmp[x]) !== JSON.stringify(this.state.selectionDetails)) {
                out.push(tmp[x])
            } else {
                ex = true;

            }
        }

        if (ex) {
            let updateState = true;
            if (this.state.fieldName == "") {
                updateState = false;
                document.getElementById("fieldNameError").innerHTML = "Name cannot be empty";
                document.getElementById("fieldNameError").style.color = "red";
            } else {
                document.getElementById("fieldNameError").innerHTML = "";

            }

            this.setState({ notifications: out, selectionDetails: undefined, updated: updateState });
            toast.info(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "deletingSuccessful")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        else {
            toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "errorDeleting")}</>)}</MyContext.Consumer>, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }

    }

    validateInputs = (id, newValue) => {
        switch (id) {
            case "fieldName":
                this.setState({ fieldName: newValue, updated: true });
                break;
            case "mapping":
                this.setState({ mapping: newValue, updated: true });
                break;
            case "isMapping":
                this.setState({ isMapping: newValue, updated: true });
                break;
            case "numeric":
                if (newValue) {
                    this.setState({
                        isNumeric: newValue,
                        hasTriggerAction: false,
                        actionType: "NoAction",
                        isAlphanumeric: false,
                        updated: true
                    });
                } else {
                    this.setState({ isNumeric: newValue, hasAdditionalNumberCheck: false, updated: true });
                }
                break;
            case "alphaNumeric":
                if (newValue) {
                    this.setState({
                        isAlphanumeric: newValue,
                        hasTriggerAction: false,
                        actionType: "NoAction",
                        isNumeric: false,
                        hasAdditionalNumberCheck: false,
                        updated: true
                    });
                } else {
                    this.setState({ isAlphanumeric: newValue, updated: true });
                }
                break;
            case "maxFields":
                if (newValue > 0) {
                    this.setState({
                        maxNumberOfFields: parseInt(newValue),
                        hasTriggerAction: false,
                        actionType: "NoAction",
                        updated: true
                    });
                } else {
                    this.setState({ maxNumberOfFields: parseInt(newValue), updated: true });
                }
                break;

            case "fieldLength":
                if (newValue > 0) {
                    this.setState({
                        fieldLength: parseInt(newValue),
                        hasTriggerAction: false,
                        actionType: "NoAction",
                        updated: true
                    });
                } else {
                    this.setState({ fieldLength: parseInt(newValue), updated: true });
                }
                break;
            case "hasExact":
                if (newValue) {
                    this.setState({
                        hasExactLength: newValue,
                        hasTriggerAction: false,
                        actionType: "NoAction",
                        updated: true
                    });
                } else {
                    this.setState({ hasExactLength: newValue, updated: true });
                }
                break;
            case "checkbox":
                if (newValue) {
                    this.setState({
                        isCheckbox: newValue,
                        isNumeric: false,
                        isAlphanumeric: false,
                        maxNumberOfFields: 1,
                        fieldLength: 1,
                        hasExactLength: false,
                        updated: true
                    });
                } else {
                    this.setState({
                        isCheckbox: newValue,
                        hasTriggerAction: false,
                        actionType: "NoAction",
                        IsCheckboxValueDependent: false,
                        updated: true
                    });
                }
                break;
            case "triggerAction":
                this.setState({ hasTriggerAction: newValue, updated: true });
                break;
            case "selectAction":
                this.setState({ actionType: newValue, updated: true })
                break;
            case "ttlSettingYes":
                this.setState({ ttlSettingYes: parseInt(newValue), updated: true, ttlConfirmed: false })
                break;
            case "ttlSettingNo":
                this.setState({ ttlSettingNo: parseInt(newValue), updated: true, ttlConfirmed: false })
                break;
            case "hasAdditionalNumberCheck":
                this.setState({ hasAdditionalNumberCheck: newValue, updated: true })
                break;
            case "numberCheckAlgorithmType ":
                this.setState({ numberCheckAlgorithmType: newValue, updated: true })
                break;
            default: break;
        }

        let ttlYes = id == "ttlSettingYes" ? newValue : this.state.ttlSettingYes;
        let ttlNo = id == "ttlSettingNo" ? newValue : this.state.ttlSettingNo;

        if (this.state.hasTriggerAction && this.state.actionType == "SetTTl") {
            if (ttlYes < 0) {
                document.getElementById("ttlChangeError").innerHTML = "Value must be greater or equal than 0";
                document.getElementById("ttlChangeError").style.color = "red";
                this.setState({ updated: false });

            } else if (ttlNo < 0) {
                document.getElementById("ttlChangeError").innerHTML = "Value must be greater or equal than 0";
                document.getElementById("ttlChangeError").style.color = "red";
                this.setState({ updated: false });
            } else {
                document.getElementById("ttlChangeError").innerHTML = "";

            }
        }

        let name = "";
        if (id == "fieldName") {
            name = newValue;
        } else {
            name = this.state.fieldName;

        }
        if (name == "") {
            document.getElementById("fieldNameError").innerHTML = "Name cannot be empty";
            document.getElementById("fieldNameError").style.color = "red";
            this.setState({ updated: false });
        } else {
            document.getElementById("fieldNameError").innerHTML = "";

        }

    }

    validateNotification = () => {
        let valid = true;

        if (this.state.NotificationTime < 0 || this.state.NotificationTime == undefined) {
            document.getElementById("notificationsDayError").innerHTML = "Notification time should not be lower than 0";
            document.getElementById("notificationsDayError").style.color = "red";
            valid = false;

        } else if (isNaN(this.state.NotificationTime)) {
            document.getElementById("notificationsDayError").innerHTML = "Field cannot be empty";
            document.getElementById("notificationsDayError").style.color = "red";
        } else {
            document.getElementById("notificationsDayError").innerHTML = "";

        }
        if (this.state.IsCheckboxValueDependent && this.state.IsCheckboxValueDependent != undefined) {
            if (this.state.CheckboxDependencyType == "" || this.state.CheckboxDependencyType == undefined) {
                document.getElementById("dependencySelectError").innerHTML = "Dependency type must be selected";
                document.getElementById("dependencySelectError").style.color = "red";

                valid = false;
            } else {

                document.getElementById("dependencySelectError").innerHTML = "";

            }
        }

        if ((this.state.usersList == [] || this.state.usersList == "" || this.state.usersList == undefined) && !this.state.AddToToDo && !this.state.SendToRecordingOwner) {
            document.getElementById("emailOrToDoError").innerHTML = "At least email field or 'add to do' must be completed";
            document.getElementById("emailOrToDoError").style.color = "red";

            valid = false;
        } else {
            document.getElementById("emailOrToDoError").innerHTML = "";

        }


        //if (this.state.usersList == [] || this.state.usersList == "" || this.state.usersList == undefined) {
        //    document.getElementById("recipientSelectError").innerHTML = "Users to notify must be selected";
        //    document.getElementById("recipientSelectError").style.color = "red";

        //    valid = false;
        //} else {
        //    document.getElementById("recipientSelectError").innerHTML = "";

        //}

        return valid;

    }

    checkTTLValidity = async (apol, idField, idBtn, stateValue, stateBtnType) => {
        this.setState({ TTLLoading: true });
        var data = await apol
            .mutate({
                mutation: checkRetentionTimeValidity,
                variables: {
                    ttl: parseInt(stateValue)
                }
            });


        if (data.data.checkRetentionTimeValidity) {
            this.setState({ ttlConfirmed: true });
            document.getElementById(idField).style.border = "1px solid green";
            document.querySelector('#' + idBtn).style.border = "1px solid green";
        }
        else {
            document.getElementById(idField).style.border = "1px solid orange";
            document.querySelector('#' + idBtn).style.border = "1px solid orange";
            stateBtnType === "Yes" ? this.setState({ ttlBtnMessageYes: true }) : this.setState({ ttlBtnMessageNo: true })

        }
        this.setState({ TTLLoading: false });

    }
    disableSaveBtn = () => {
        if (this.state.updated) {
            if (this.state.actionType == "SetTTl" && !this.state.ttlConfirmed) {
                return true;
            }

            if (!this.state.isNumeric && !this.state.isAlphanumeric && !this.state.isCheckbox) {
                return true;
            }

            return false;
        }
        return true;
    }
    render() {
        return (<MyContext.Consumer>
            {(context) => (
                <Stack vertical>

                    {/*If Edit mode --> hide fileds, only notifications are editable*/}
                    {/*{!this.props.isEdit && <>*/}
                    <Stack.Item styles={this.styles}>
                        <TextField label="Field Name"
                            value={this.state.fieldName}
                            onChange={(event, newValue) => {
                                this.validateInputs("fieldName", newValue);
                            }}
                            disabled={!this.props.userRules.ruleAccess.confAdministrateCustomFields}
                        />
                        <span id="fieldNameError"></span>

                        <Checkbox
                            styles={this.styles}
                            label="Mapping"
                            checked={this.state.isMapping}
                            onChange={(event, newValue) => {
                                this.validateInputs("isMapping", newValue);

                            }}
                        />

                        {this.state.isMapping && <TextField
                            value={this.state.mapping}
                            label="Mapping (SFTP-Export)"
                            onChange={(event, newValue) => {
                                this.validateInputs("mapping", newValue);
                            }}
                        />}
                    </Stack.Item>
                    <br />
                    <hr />
                    <Stack.Item >
                        <Checkbox label="Numeric"
                            checked={this.state.isNumeric}
                            onChange={(event, newValue) => {
                                this.validateInputs("numeric", newValue);

                            }}
                            disabled={this.state.isCheckbox}

                        />

                        <Checkbox label="Alpha Numeric"
                            checked={this.state.isAlphanumeric}
                            onChange={(event, newValue) => {
                                this.validateInputs("alphaNumeric", newValue);

                            }}
                            disabled={this.state.isCheckbox}

                        />

                        {/*check digit logic*/}

                        <Checkbox
                            label={context.getTranslation("configuration", "hasAdditionalNumberCheck")}
                            checked={this.state.hasAdditionalNumberCheck}
                            onChange={(event, newValue) => {
                                this.validateInputs("hasAdditionalNumberCheck", newValue);

                            }}
                            disabled={(this.state.isNumeric) ? false : true}

                        />

                        {this.state.hasAdditionalNumberCheck &&
                            <ChoiceGroup
                                style={{ marginLeft: "25px" }}
                                placeholder="Select type"
                                options={[
                                    //{
                                    //    key: "None",
                                    //    text: "None",
                                    //    'data-automation-id': 'auto1',
                                    //    styles: { field: { fontWeight: FontWeights.regular } }

                                    //},
                                    {
                                        key: "DigitCheck",
                                        text: "Digit calculation based on German Bundes Bank",
                                        styles: { field: { fontWeight: FontWeights.regular } }
                                    }
                                ]}
                                selectedKey={this.state.numberCheckAlgorithmType}
                                onChange={(event, newValue) => {
                                    this.setState({ numberCheckAlgorithmType: newValue.key, updated: true })
                                }}
                            />}


                        <TextField min={1}
                            value={this.state.maxNumberOfFields}
                            type="Number"
                            label="Maximum number of fields"
                            onChange={(event, newValue) => {
                                this.validateInputs("maxFields", newValue);
                            }}
                            disabled={this.state.isCheckbox ? true : (!this.state.isAlphanumeric&&!this.state.isNumeric)?true:false}

                        />

                        <TextField min={1}
                            value={this.state.fieldLength}
                            type="Number"
                            label="Field Length"
                            onChange={(event, newValue) => {
                                this.validateInputs("fieldLength", newValue);
                            }}
                            disabled={this.state.isCheckbox ? true : (!this.state.isAlphanumeric && !this.state.isNumeric) ? true : false}

                        />

                        <Toggle
                            defaultChecked={this.state.hasExactLength}
                            label="Has exact Length"
                            onText="exact"
                            onChange={(event, newValue) => {
                                this.validateInputs("hasExact", newValue);
                            }}
                            disabled={this.state.isCheckbox ? true : (!this.state.isAlphanumeric && !this.state.isNumeric) ? true : false}

                        />
                    </Stack.Item>
                    <hr />
                    <Stack.Item>
                        <Checkbox label="Check Box"
                            checked={this.state.isCheckbox}
                            onChange={(event, newValue) => {
                                this.validateInputs("checkbox", newValue);

                            }}
                            disabled={this.props.isEdit || this.state.isNumeric || this.state.isAlphanumeric}

                        />

                    </Stack.Item>
                    {/*if define action allowed*/}

                    {/*{this.props.userRules.ruleAccess.confDefineActionOnCustomFields && <>*/}
                    {this.state.isCheckbox && <>
                        <Stack.Item styles={this.styles}>
                            <Checkbox label="Trigger Action"
                                checked={this.state.hasTriggerAction}
                                onChange={(event, newValue) => {
                                    this.validateInputs("triggerAction", newValue);
                                }}
                                disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                            />
                        </Stack.Item>
                        {this.state.hasTriggerAction && <Stack.Item style={this.styles}>

                            <Stack horizontal>
                                <Stack vertical>
                                    <Dropdown
                                        placeholder="Select action"
                                        options={[
                                            { key: 'SetTTl', text: 'Set TTL' },
                                            { key: 'NoAction', text: 'No Action' }
                                        ]}
                                        style={{ width: "120px", marginRight: "10px" }}
                                        defaultSelectedKey={this.state.actionType}
                                        onChange={(event, option) => {
                                            this.validateInputs("selectAction", option.key);
                                        }}
                                        disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                    />
                                    <span id="selectActionError"></span>
                                </Stack>
                                {this.state.actionType === "SetTTl" && <>
                                    <div style={{ marginRight: "10px" }}>
                                        <div>
                                            <TextField
                                                id="csRetentionTimeFieldYes"
                                                type="Number"
                                                value={this.state.ttlSettingYes}
                                                style={{ width: "100px" }}
                                                onChange={(event, newValue) => {
                                                    this.validateInputs("ttlSettingYes", newValue);
                                                }}
                                                min={1}
                                                disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                            />
                                        </div>
                                        <span style={{ marginLeft: "40px" }}>Yes</span><br />
                                        <Button
                                            id="csRetentionTimeBtnYes"
                                            text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "checkValidity")}</>)}</MyContext.Consumer>}
                                            onClick={(event) => { this.checkTTLValidity(this.props.apol, "csRetentionTimeFieldYes", "csRetentionTimeBtnYes", this.state.ttlSettingYes, "Yes") }}
                                            style={{ width: "100px", marginTop: "5px", padding: "0px" }}
                                            disabled={this.state.TTLLoading}
                                        />
                                        {this.state.ttlBtnMessageYes && <TeachingBubble
                                            target={`#${"csRetentionTimeBtnYes"}`}
                                            secondaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "no")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ ttlBtnMessageYes: false, ttlConfirmed: false }) } }}
                                            primaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "yes")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ ttlBtnMessageYes: false, ttlConfirmed: true }) } }}
                                            headline={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "warning")}</>)}</MyContext.Consumer>
                                            }

                                        >
                                            <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "TTLErrorMessage")}</>)}</MyContext.Consumer>

                                        </TeachingBubble>}
                                    </div>
                                    <div>
                                        <TextField
                                            id="csRetentionTimeFieldNo"
                                            type="Number"
                                            value={this.state.ttlSettingNo}
                                            style={{ width: "100px" }}
                                            onChange={(event, newValue) => {
                                                this.validateInputs("ttlSettingNo", newValue);
                                            }}
                                            min={1}
                                            disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}
                                        />
                                        <span style={{ marginLeft: "40px" }}>No</span>
                                        <br />
                                        <Button
                                            id="csRetentionTimeBtnNo"
                                            text={<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "checkValidity")}</>)}</MyContext.Consumer>}
                                            onClick={(event) => { this.checkTTLValidity(this.props.apol, "csRetentionTimeFieldNo", "csRetentionTimeBtnNo", this.state.ttlSettingNo) }}
                                            style={{ width: "100px", marginTop: "5px", padding: "0px" }}
                                            disabled={this.state.TTLLoading}

                                        />
                                        {this.state.ttlBtnMessageNo && <TeachingBubble
                                            target={`#${"csRetentionTimeBtnNo"}`}
                                            secondaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "no")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ ttlBtnMessageNo: false, ttlConfirmed: false }) } }}
                                            primaryButtonProps={{ children: <MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "yes")}</>)}</MyContext.Consumer>, onClick: () => { this.setState({ ttlBtnMessageNo: false, ttlConfirmed: true }) } }}
                                            headline={<MyContext.Consumer>{(context) => (<> {context.getTranslation("common", "warning")}</>)}</MyContext.Consumer>
                                            }

                                        >
                                            <MyContext.Consumer>{(context) => (<> {context.getTranslation("tenants", "TTLErrorMessage")}</>)}</MyContext.Consumer>

                                        </TeachingBubble>}
                                    </div>


                                </>}
                            </Stack>
                            <span id="ttlChangeError" style={{ marginLeft: "150px" }}></span>

                        </Stack.Item>
                        }
                    </>}
                    {/*</>} END OF NO EDIT */}
                    {/*}*/}
                    {/*---------------------NOTIFICATIONS-----------------------*/}
                    {this.state.isCheckbox && <>
                        <Stack vertical>
                            <hr />
                            <Text variant="large" block><MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "notifications")}</>)}</MyContext.Consumer></Text>

                            <Stack.Item styles={this.styles}>
                                <DetailsList
                                    key={"notifications" + ""}
                                    items={this.state.notifications}
                                    columns={this.columns}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    checkButtonAriaLabel="Row checkbox"
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                    checkboxVisibility={2}
                                    styles={{ root: { color: "black" } }}
                                    disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                />
                            </Stack.Item>
                            <Stack.Item styles={this.styles}>
                                <CommandBarButton
                                    styles={{ root: { paddingTop: 5, paddingBottom: 5 } }}
                                    iconProps={{ iconName: 'Add' }}
                                    text={context.getTranslation("configuration", "addNotification")}
                                    disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}
                                    onClick={() => {
                                        this.setState({ displayNotificationsConfig: true })
                                    }}
                                />
                            </Stack.Item>
                            {/*------------------DISPLAY ON ADD NOTIFICATION BUTTON--------------------*/}
                            {this.state.displayNotificationsConfig && <>
                                <Stack.Item styles={this.styles}>
                                    <TextField
                                        type="number"
                                        label="Notification in(time in days)"
                                        value={this.state.NotificationTime}
                                        onChange={(event) => {
                                            this.setState({ NotificationTime: parseInt(event.target.value) })
                                        }}
                                        min={0}
                                        disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                    />
                                    <span id="notificationsDayError"></span>
                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <Checkbox label="Notification dependecy on checkbox input"
                                        checked={this.state.IsCheckboxValueDependent}
                                        onChange={(event, newValue) => {
                                            this.setState({ IsCheckboxValueDependent: newValue })
                                        }}
                                        disabled={!this.state.isCheckbox}

                                    />
                                    {this.state.IsCheckboxValueDependent && <div style={{ marginLeft: '24px' }}>
                                        <ChoiceGroup
                                            selectedKey={this.state.CheckboxDependencyType}
                                            style={{ width: "200px" }}
                                            options={[
                                                {
                                                    key: 'YesDependent',
                                                    text: '"Yes" selected',
                                                    'data-automation-id': 'auto1',
                                                    styles: { field: { fontWeight: FontWeights.regular } }
                                                },
                                                {
                                                    key: 'NoDependent',
                                                    text: '"No" selected',
                                                    'data-automation-id': 'auto1',
                                                    styles: { field: { fontWeight: FontWeights.regular } }
                                                },
                                                {
                                                    key: 'NoDependency',
                                                    text: '"Nothing" selected',
                                                    'data-automation-id': 'auto1',
                                                    styles: { field: { fontWeight: FontWeights.regular } }
                                                }
                                            ]}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    CheckboxDependencyType: newValue.key
                                                });
                                            }}
                                            disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                        />
                                        <span id="dependencySelectError"></span>

                                    </div>
                                    }
                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <Label>{context.getTranslation("configuration", "sendNotificationTo")}</Label>
                                    <UserPicker
                                        user={this.state.tempUserNotification}
                                        fc={(user) => {
                                            this.setState({
                                                usersList: user.map(u => u.email),
                                                usersIdList: user.map(u => u.userId),
                                                tempUserNotification:user //used just to display correct the user inside the input
                                            });
                                        }}
                                        disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                    />
                                    <span id="recipientSelectError"></span>

                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <Checkbox
                                        checked={this.state.SendToRecordingOwner}
                                        label={context.getTranslation("configuration", "sendToRecordingOwner")}
                                        onChange={(event, newValue) => {
                                            this.setState({ SendToRecordingOwner: newValue })
                                        }}
                                        disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                    />

                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <TextField
                                        value={this.state.NotificationText}
                                        label="Notification text"
                                        onChange={(event) => {
                                            this.setState({ NotificationText: event.target.value })
                                        }}
                                        disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                    />
                                </Stack.Item>
                                <Stack.Item styles={this.styles}>
                                    <Checkbox
                                        checked={this.state.AddToToDo}
                                        label="Add 'to do' area"
                                        onChange={(event, newValue) => {
                                            this.setState({ AddToToDo: newValue })
                                        }}
                                        disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                    />
                                    <span id="emailOrToDoError"></span>

                                </Stack.Item>
                                <Stack.Item>
                                    <PrimaryButton onClick={event => {
                                        this.addToKeylist(this.state.NotificationTime, this.state.usersList, this.state.NotificationText, this.state.AddToToDo, this.state.IsCheckboxValueDependent, this.state.CheckboxDependencyType, this.state.usersIdList, this.state.SendToRecordingOwner)
                                    }} text={context.getTranslation("configuration", "addNotification")}
                                        disabled={!this.props.userRules.ruleAccess.confDefineActionOnCustomFields}

                                    />
                                </Stack.Item>
                            </>}
                        </Stack>
                    </>}

                    <Stack.Item styles={this.styles}>
                        <Stack horizontal>
                            <Stack.Item style={{ marginRight: "5px" }}>
                                <PrimaryButton
                                    onClick={(event) => { this.notifyCustomFieldChange(this.props.apol, context.getTranslation ) }}
                                    text={context.getTranslation("common", "save")}
                                    disabled={this.disableSaveBtn()}

                                />
                            </Stack.Item>
                            <Stack.Item style={{ marginRight: "5px" }}>
                                <DefaultButton onClick={(event) => { this.props.cl() }} text={context.getTranslation("common", "cancel")} />
                            </Stack.Item>
                            {(this.props.isEdit) &&
                                <Stack.Item>
                                    {(this.state.showDeleteButton || this.state.showDeleteButton == undefined) &&
                                        <DefaultButton
                                            data-automation-id="test"
                                            text={context.getTranslation("configuration", "checkIfFieldUsed")}
                                            allowDisabledFocus={true}
                                            onClick={this.checkCustomFieldUsed}
                                        />
                                    }
                                    {this.state.processingCheck &&
                                        <DefaultButton
                                            data-automation-id="test"
                                            text={context.getTranslation("common", "loading")}
                                            allowDisabledFocus={true}
                                            disabled
                                        />
                                    }
                                    {this.state.customFieldUsed == false &&
                                        <DefaultButton
                                            data-automation-id="test"
                                            style={{ background: "red", color: "white" }}
                                            text={context.getTranslation("configuration", "deleteCustomField")}
                                            allowDisabledFocus={true}
                                            onClick={this.deleteCustomField}
                                        />
                                    }
                                    {this.state.customFieldUsed == true &&
                                        <DefaultButton
                                            data-automation-id="test"
                                            text={context.getTranslation("configuration", "unableToDeleteCustomField")}
                                            allowDisabledFocus={true}
                                            disabled
                                        />
                                    }
                                </Stack.Item>


                            }
                        </Stack>
                    </Stack.Item>
                </Stack>
            )
            }
        </MyContext.Consumer>

        );
    }

    notifyCustomFieldChange = async (apol, getTranslation) => {
        //check if custom field is identic to other fields

        document.getElementById("fieldNameError").innerHTML = "";
        let checkName = false;
        if (this.props.data == null) {
            checkName = true;
        }
        if (this.props.data !== null && this.props.data.fieldName !== this.state.fieldName) {
            checkName = true;
        }
        var checkField = await apol
            .mutate({
                mutation: checkCustomFieldName,
                variables: {
                    fieldName: this.state.fieldName
                }
            });


        if (!checkField.data.checkCustomFieldName && checkName) {
            
            document.getElementById("fieldNameError").innerHTML = "Field name is already used.";
            document.getElementById("fieldNameError").style.color = "red";

        } else {

            // Add or change field if field is not the same as others

            var customField;

            var notificationsList = []
            if (this.state.isCheckbox) {
                notificationsList = this.state.notifications;
            }
            var mappingVal = "";
            if (this.state.isMapping) {
                mappingVal = this.state.mapping.replaceAll(" ", "");
            }

            customField = {
                id: this.state.id,
                fieldName: this.state.fieldName,
                fieldLength: this.state.fieldLength,
                mapping: mappingVal,
                hasExactLength: this.state.hasExactLength,
                isAlphanumeric: this.state.isAlphanumeric,
                isCheckbox: this.state.isCheckbox,
                isNumeric: this.state.isNumeric,
                maxNumberOfFields: this.state.maxNumberOfFields,
                hasTriggerAction: this.state.hasTriggerAction,
                actionType: this.state.actionType,
                ttlSettingYes: this.state.ttlSettingYes,
                ttlSettingNo: this.state.ttlSettingNo,
                notifications: JSON.stringify(notificationsList),
                hasAdditionalNumberCheck: this.state.hasAdditionalNumberCheck,
                numberCheckAlgorithmType: this.state.numberCheckAlgorithmType

            };
            if (this.props.data != null) {
                var data = await apol
                    .mutate({
                        mutation: mutationUpdateCustomField,
                        refetchQueries: [{
                            query: queryGetConfigData,
                            variables: { auth: this.props.authorization },
                        }],
                        variables: {
                            auth: this.props.authorization,
                            customField: customField
                        }
                    });
                if (data.data.updateCustomField == null) {
                    toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "editCustomFieldNotSuccessfull")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.success(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "customFieldChanged")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            } else {
                data = await apol
                    .mutate({
                        mutation: mutationCreateCustomField,
                        refetchQueries: [{
                            query: queryGetConfigData,
                            variables: { auth: this.props.authorization },
                        }],
                        variables: {
                            auth: this.props.authorization,
                            customField: customField
                        }
                    });

                if (data.data.createCustomField == null) {
                    toast.error(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "addCustomFieldNotSuccessfull")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                } else {
                    toast.success(<MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "customFieldAdded")}</>)}</MyContext.Consumer>, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            this.props.cl();

        }



    };




}