import React from 'react';

import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

import { WidthProvider, Responsive } from 'react-grid-layout';
import _ from 'lodash';


import {
  HoverCard, HoverCardType, PersonaInitialsColor, Persona, PersonaSize, Stack,
} from 'office-ui-fabric-react';
import CardOption from './CardOption';
import { toast } from 'react-toastify';
import ReportView from '../ReportView/ReportView';
import gql from 'graphql-tag';
import { MyContext } from '../../context';

import DevboardWidget from '../DevboardView/Widgets/DevboardWidget';
const ResponsiveReactGridLayout = WidthProvider(Responsive);



function getSvg() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 640"
      width="24"
      height="24"
    >

      <path
        d="M640 320.73C640 496.94 496.61 640 320 640C143.39 640 0 496.94 0 320.73C0 144.52 143.39 1.46 320 1.46C496.61 1.46 640 144.52 640 320.73Z"
        id="a1xa5Iq2wx"
        fill="#727272"
      />
      <path
        d="M234.09 331.48C234.09 359.08 211.69 381.48 184.09 381.48C156.5 381.48 134.09 359.08 134.09 331.48C134.09 303.89 156.5 281.48 184.09 281.48C211.69 281.48 234.09 303.89 234.09 331.48Z"
        id="deI6MszI0"
        fill="#fff"
      />
      <path
        d="M365.11 330.48C365.11 358.08 342.71 380.48 315.11 380.48C287.52 380.48 265.11 358.08 265.11 330.48C265.11 302.89 287.52 280.48 315.11 280.48C342.71 280.48 365.11 302.89 365.11 330.48Z"
        id="bSzs3vqyf"
        fill="#fff"
      />
      <path
        d="M500.16 330.48C500.16 358.08 477.75 380.48 450.16 380.48C422.56 380.48 400.16 358.08 400.16 330.48C400.16 302.89 422.56 280.48 450.16 280.48C477.75 280.48 500.16 302.89 500.16 330.48Z"
        id="aQ48JKhR"
        fill="#fff"
      />

    </svg>
  );
}

export default class DNDBoard extends React.Component {
  static defaultProps = {
    className: 'layout',
    cols: {
      xl: 20, lg: 14, md: 10, sm: 8, xs: 6, xxs: 4,
    },
    rowHeight: 100,
    isResizable: false,

  };

    color = PersonaInitialsColor.darkBlue;

    constructor(props) {
        super(props);

        this.onAddItem = this.onAddItem.bind(this);
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.items = null;
        this.initializeItems(this.props.data);
        this.state = {
            newCounter: 0,
        };
    }

    initializeItems = (data) => {
        var items = [];
        for (let i = 0; i < data.length; i++) {
            const tmp = this.widgetFabricator(data[i], i);
            if (tmp != null) {
                items.push(tmp);
                //console.log(tmp);
            } else {
                toast.warn(<MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "errorLoadingElement")}</>)}</MyContext.Consumer>);
            }
        }
        this.reportViewRef = React.createRef();
        this.items = items;
    }

    showDetail = (id) => {
      this.reportViewRef.current.showPanel(id);
    };

    createElement(el) {
        const i = el.add ? '+' : el.i;
        let titlesize = 18;
        
        return (
            <div key={i} data-grid={el} style={{ backgroundColor: this.props.theme.palette.white }}>
                <Stack vertical>
                    <Stack.Item>
                        <span style={{
                            float: 'right',
                            cursor: 'pointer',
                        }}
                        >
                            <CardOption
                                element={el.element}
                                reloadFunc={this.props.reloadFunc}
                                reference={el.reference}
                                title={el.title}
                                deleteReport={this.deleteReport}
                                keyId={el.i}
                                list={this.showDetail}
                                delfunc={this.onRemoveItem.bind(this, i)}
                                authorization={this.props.authorization}
                                apol={this.props.apol}
                                userRules={this.props.userRules}
                                input={this.props.input}
                                theme={this.props.theme}

                            />
                        </span>
                        <Stack vertical>
                        <Stack.Item>
                            <span style={{ float: 'left', color: 'grey', paddingLeft: '5px' }}>
                                <span>{this.getCycle(el.element)}</span>
                            </span>
                        </Stack.Item>
                        <Stack.Item>

                            <span style={{ float: 'left', color: 'grey', paddingLeft: '5px' }}>
                                <span>{this.getKpi(el.element)}</span>
                            </span>
                            </Stack.Item>
                            </Stack>

                    </Stack.Item>
                        <span style={{ float: 'left', fontSize: titlesize, paddingLeft: '5px' }}>
                            <span>{el.title}</span>
                        </span>
                    

                    <Stack vertival horizontalAlign="center">
                        {
                            <DevboardWidget element={el.element} reference={el.reference} input={this.props.input}></DevboardWidget>
                        }
                    </Stack>
                    <Stack.Item>
                        <span style={{
                            float: 'left',
                            transform: 'translate(0px, -24px)',
                            marginLeft: '5px',
                            cursor: 'pointer',

                        }}
                        >
                            {
                                this.renderPersona(el.persons)
                            }
                        </span>
                        <span style={{
                            float: 'right',
                            transform: 'translate(0px, -24px)',
                            marginRight: '5px',
                            cursor: 'pointer',

                        }}
                        >
                                    <span style={{ float: 'right', color: 'grey', paddingLeft: '5px' }}>
                                        <span>{this.getUnit(el.element)}</span>
                                    </span>
                        </span>

                    </Stack.Item>
                </Stack>
            </div>
        );
    }

    getCycle = (data) => {
        if (data.tForm == "custom") {
            let startDate = new Date(data.startDate);
            let endDate = new Date(data.endDate);
            return (
                <Stack horizontal>
                    <Stack.Item>{startDate.toLocaleDateString(this.props.authorization.locale)}|</Stack.Item>
                    <Stack.Item>{endDate.toLocaleDateString(this.props.authorization.locale)}</Stack.Item>
                    </Stack>
            );
        } else {
            return (
                            <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", data.cycle)}</>)}</MyContext.Consumer>
            );
        }
    }

    getUnit = (data) => {
        if (data.kpi == "AgentTalkTime") {
            return (
                <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "unitTalkTime")}</>)}</MyContext.Consumer>
            );
        }
        else if (data.kpi == "HealthCheck") {
            return (
                <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "unitHealthCheck")}</>)}</MyContext.Consumer>
            );
        }
        else if (data.valueType == null || data.valueType == "") {
            return (<span></span>);
        }
        else
        {
            return (
                <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", "unit" + data.valueType)}</>)}</MyContext.Consumer>
            );
        }
    }

    getKpi = (data) => {
        return (
            <MyContext.Consumer>{(context) => (<> {context.getTranslation("dashboard", data.kpi)}</>)}</MyContext.Consumer>
        );
    }

    renderPersona = (data) => {
      let user = [];
      if (data != undefined && data != null) {
        user = data;
      }


      const fill = [];
      if (user.length > 2) {
        for (let i = 2; i < user.length; i++) {
          fill.push(user[i]);
        }
      }

      if (user.length < 3) {
        return (
          <Stack horizontal>
            {user.map(({ name, tid }, i) => (
              <Stack.Item key={i}>
                <HoverCard
                  type={HoverCardType.plain}
                  plainCardProps={{
                    onRenderPlainCard: () => (
                      <div style={{ padding: '5px' }}>
                        <Persona
                          text={name}
                          initialsColor={this.color}
                          size={PersonaSize.size48}
                        />
                      </div>
                    ),
                  }}
                  instantOpenOnClick
                >
                  <Persona
                    text={name}
                    initialsColor={this.color}
                    size={PersonaSize.size24}
                    hidePersonaDetails
                  />
                </HoverCard>


              </Stack.Item>

            ))}

          </Stack>
        );
      }
      return (
        <Stack horizontal>
          <Stack.Item>
            <HoverCard
              type={HoverCardType.plain}
              plainCardProps={{
                onRenderPlainCard: () => (
                  <div style={{ padding: '5px' }}>
                    <Persona
                      text={user[0].name}
                      initialsColor={this.color}
                      size={PersonaSize.size48}
                    />
                  </div>
                ),
              }}
              instantOpenOnClick
            >
              <Persona
                text={user[0].name}
                initialsColor={this.color}
                size={PersonaSize.size24}
                hidePersonaDetails
              />
            </HoverCard>


          </Stack.Item>
          <Stack.Item>
            <HoverCard
              type={HoverCardType.plain}
              plainCardProps={{
                onRenderPlainCard: () => (
                  <div style={{ padding: '5px' }}>
                    <Persona
                      text={user[1].name}
                      initialsColor={this.color}
                      size={PersonaSize.size48}
                    />
                  </div>
                ),
              }}
              instantOpenOnClick
            >
              <Persona
                text={user[1].name}
                initialsColor={this.color}
                size={PersonaSize.size24}
                hidePersonaDetails
              />
            </HoverCard>


          </Stack.Item>
          <Stack.Item>
            <HoverCard
              type={HoverCardType.plain}
              plainCardProps={{
                onRenderPlainCard: () => (
                  <Stack vertical styles={{ root: { padding: '5px' } }}>
                    {
                      fill.map(({ name, tid }, i) => (
                          <Stack.Item key={i} styles={{ root: { padding: '5px' } }}>

                          <Persona
                            text={name}
                            initialsColor={this.color}
                            size={PersonaSize.size48}
                          />
                        </Stack.Item>
                      ))
                    }
                  </Stack>
                ),
              }}
              instantOpenOnClick
            >
              {getSvg()}
            </HoverCard>


          </Stack.Item>
        </Stack>
      );
    }

    onAddItem() {

      const rng = ['Number', 'DoughnutChart',
        'SplineChart',
        'PieChart',
        'SimpleBarChart',
        'StackedBarChart',
        'TreeMap',
        'FunnelChart',
            'BubbleChart'];
        this.items = this.state.items.concat(this.widgetFabricator({
            title: `Demo Widget #${this.state.newCounter}`,
            text: `${Math.floor(Math.random() * Math.floor(9999))}`,
            widget: rng[this.state.newCounter % rng.length],
            id: `id${this.state.newCounter}`,
        }, 0));
      this.setState({
        // Increment the counter to ensure key is always unique.
        newCounter: this.state.newCounter + 1,
      });
    }

    onBreakpointChange(breakpoint, cols) {
      this.setState({
        breakpoint,
        cols,
      });
    }

    onLayoutChange(layout) {
      this.props.setLayout(layout);
      this.setState({ layout });
    }

    onRemoveItem(i) {
      this.items = _.reject(this.items, { i });
    }

    render() {
      return (
        this.renderDND()
      );
    }

    widgetFabricator = (element, co) => {
      let size;
      switch (element.widget) {
        case 'TagCloud':
            size= {w:6, h:4}
              break;
        case 'Number':
          size = { w: 2, h: 2 };
          break;
        case 'DoughnutChart':
        case 'SplineChart':
        case 'PieChart':
        case 'SimpleBarChart':
        case 'StackedBarChart':
        case 'TreeMap':
        case 'FunnelChart':
        case 'BubbleChart':
          size = { w: 4, h: 4 };
          break;
        default:
          size = { w: 2, h: 2 };
          break;
      }
      let x;
      if (this.items != null) {
        x = (this.items.length * 4) % (this.state.cols || 12);
      } else {
        x = (co * 4) % 12;
      }
      const reference = React.createRef();
      if (element.position.w !== undefined && element.position.w != null && element.position.w !== 0) {
        return {
          i: element.id,
          x: element.position.x,
          y: element.position.y,
          w: element.position.w,
          h: element.position.h,
          type: element.type,
          title: element.title,
          cycle: element.cycle,
          element,
          data: element.data,
          widget: element.widget,
          persons: element.persons,
          reference,
        };
      }
      return {
        i: element.id,
        x,
        y: Infinity, // puts it at the bottom
        w: size.w,
        h: size.h,
        type: element.type,
        title: element.title,
        cycle: element.cycle,
        element,
        data: element.data,
        widget: element.widget,
        persons: element.persons,
        reference,
      };
    }

    getWidget = (element, reference, resize, input) => {
        return (
            <DevboardWidget element={element} reference={reference} resize={resize} input={input} ></DevboardWidget>
        );
    }

    deleteReport = (id) => {
      this.props.apol
        .mutate({
          mutation: gql`
                mutation ent ($input: AuthInput, $id : String!) {
                    deleteReport (input: $input, id: $id) {
                        id
                    }
                }
            `,
          variables: { id, input: this.props.authorization },
        })
        .then((result) => {
          if (result && result.data.deleteReport != null){
            toast.success('Report deleted');
          } else {
            toast.error("Couldn't delete");
          }
          this.props.reloadFunc();
        });
    }

    renderDND = () => {
        this.initializeItems(this.props.data);
        return (
      <div>
        <ReportView
                    ref={this.reportViewRef}
                    apol={this.props.apol}
                    authorization={this.props.input}
                    userRules={this.props.userRules}
                    theme={this.props.theme}
        />
        <ResponsiveReactGridLayout
          onLayoutChange={this.onLayoutChange}
          onBreakpointChange={this.onBreakpointChange}
          {...this.props}
        >
        {_.map(this.items, (el) => this.createElement(el))}
        </ResponsiveReactGridLayout>
      </div>
    )}

    demoButton = () => (
      <button onClick={() => {
        this.onAddItem();
      }}
      >
        Add demo Item
      </button>
    )
}

/*
*
*

* */
