import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import cloud from 'd3-cloud';

function TagCloud({
    tags,
    containerDim,
    fontSize,
    onClickTag,
}) {

    const containerW = containerDim !== undefined ? containerDim[0] : 600;
    const containerH = containerDim !== undefined ? containerDim[1] : 350;
    const minFontSize = fontSize !== undefined ? fontSize[0] : 10;
    const maxFontSize = fontSize !== undefined ? fontSize[1] : 100;

    const containerRef = useRef(null);

    useEffect(() => {
        if (!containerRef.current || !tags.length) return;

        const fontScale = calculateFontScale(tags);

        const resizeWords = (word) => {
            const fontS = fontScale(word.value)
            const maxWordLength = Math.floor((containerW * 0.8) / fontS);
            const displayText = word.text.slice(0, maxWordLength);
            return displayText;
        };

        const layout = cloud()
            .size([containerW, containerH])
            .words(tags.map(tag => ({
                text: resizeWords(tag),
                size: fontScale(tag.value),
                id: tag.id,
                tooltip: tag.text,
                value: tag.value
            })))
            .padding(1)
            .rotate(0)//.rotate(() => Math.random() * 120 - 60)
            .font("Segoe UI")
            .fontSize(d => d.size)
            .on('end', renderCloud);

        layout.start();

        function renderCloud(words) {

            containerRef.current.innerHTML = '';


            const svg = d3.select(containerRef.current)
                .append('svg')
                .attr('width', containerW)
                .attr('height', containerH)

            const tranlateX = (containerW / 2);
            const tranlateY = (containerH / 2) + 10;

            const g = svg.append('g')
                .attr('transform', `translate(${tranlateX},${tranlateY})`);

            const randomHsl = () => `hsla(${Math.random() * 360}, 80%, 50%, 1)`

            var idTooltip = 1;

            g.selectAll('text')
                .data(words)
                .enter()
                .append('text')
                .style('font-size', d => `${d.size}px`)
                .style('fill', () => randomHsl())
                .style('cursor', 'default')
                .attr('text-anchor', 'middle')
                .attr('transform', d => `translate(${[d.x, d.y]}) rotate(${d.rotate})`)
                .text(d => d.text)
                .on('mouseover', function (e, d) {
                    idTooltip = idTooltip + 1;
                    const tooltip = d3.select('body').append('div')
                        .attr('class', 'tooltipTagCloud' + idTooltip + "")
                        .style('position', 'absolute')
                        .style('background-color', 'rgba(253, 253, 253, 0.8)')
                        .style('color', 'black')
                        .style('padding', '5px')
                        .style('border-radius', '5px')
                        .style('box-shadow', '0 2px 4px rgba(0, 0, 0, 0.1)')
                        .style('font-size', '14px')
                        .style('pointer-events', 'none')
                        .style('z-index', '999999999999999999')
                        .html(`<div>${d.tooltip} (${d.value})</div>`);


                    const [x, y] = [e.pageX, e.pageY];
                    tooltip.style('left', `${x}px`)
                        .style('top', `${y}px`);
                })
                .on('mouseout', function () {
                    d3.select('.tooltipTagCloud' + idTooltip + "").remove();
                })
                .on('click', function (e, d) {
                    //console.log(d, e)
                    if (onClickTag !== undefined) {
                        onClickTag(d);
                    }
                })

        }

        function calculateFontScale(tags) {
            const maxFrequency = Math.max(...tags.map(tag => tag.value));
            const minFrequency = Math.min(...tags.map(tag => tag.value));

            return d3.scaleLog()
                .domain([minFrequency, maxFrequency])
                .range([minFontSize, maxFontSize])
                .clamp(false);
        }
    }, [tags]);

    return <div style={{ position: "relative" }} ref={containerRef}></div>;
}

export default TagCloud;
