import * as React from 'react';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IContextualMenuListProps, IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { IRenderFunction } from '@uifabric/utilities';
import videoIndexerLangArr from './../SettingsView/VideoIndexerLangArr'
import { MyContext } from '../../context';


const ITEMSINDEXER: IContextualMenuItem[] =
    videoIndexerLangArr
    ;



export default class TranscriptSelect extends React.Component<
    {},
    {
        items: IContextualMenuItem[];
    }
    > {
    constructor(props: {}) {
        super(props);
        this._renderMenuList = this._renderMenuList.bind(this);
        this._onAbort = this._onAbort.bind(this);
        this._onChange = this._onChange.bind(this);
        this.ITEMSSPEECH = this.props.languageList;
        this.state = {
            items: this.props.isSpeechToText ? this.ITEMSSPEECH : ITEMSINDEXER,
            languageFrom: this.props.language !== undefined ? this.props.language.split("-")[0] : "",
            languageTo: "",
            languageToText: this.props.language
        };
    }

     render() {
         return (<MyContext.Consumer>{(context) => (
             <div>
                 <DefaultButton
                     id="ContextualMenuWithCustomMenuListExample"
                     text={this.state.languageToText !== "" ? context.getTranslation("detailContainer", "transcript") + " : " + this.state.languageToText : context.getTranslation("detailContainer", "transcriptAuto")}
                     menuProps={{
                         onRenderMenuList: this._renderMenuList,
                         onItemClick: this._onItemClick,
                         title: 'Language',
                         shouldFocusOnMount: true,
                         items: this.state.items
                     }}
                 />
             </div>)}
         </MyContext.Consumer>
        );
    }

     _onAbort() {
         this.setState({ items: this.props.isSpeechToText ? this.ITEMSSPEECH : ITEMSINDEXER });
    }

    _onItemClick = (ev, item) => {
        this.setState({ languageTo: item.key, languageToText: item.text});
        this.props.onTranslate(item.key, item.text);
    }

    _onChange(newValue: any) {
        const items = this.props.isSpeechToText ? this.ITEMSSPEECH : ITEMSINDEXER;
        const filteredItems = ITEMSINDEXER.filter(item => item.text && item.text.toLowerCase().includes(newValue.toLowerCase()));

        if (!filteredItems || !filteredItems.length) {
            filteredItems.push({
                key: 'no_results',
                onRender: (item, dismissMenu) => (
                    <div
                        key="no_results"
                        style={{
                            width: '100%',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}
                    >
                        <Icon iconName="SearchIssue" title="No actions found" />
                        <span>No actions found</span>
                    </div>
                )
            });
        }

        this.setState((prevState, props) => ({
            items: filteredItems
        }));
    }

     _renderMenuList(menuListProps: IContextualMenuListProps, defaultRender: IRenderFunction<IContextualMenuListProps>) {
        return (
            <div>
                <div style={{ borderBottom: '1px solid #ccc' }}>
                    
                </div>
                {defaultRender(menuListProps)}
            </div>
        );
    }
}