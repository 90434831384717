import React, { Component } from 'react'
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { MyContext } from '../../../context';

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 250 }
};

export default class RuleSwitch extends Component {
    
    state =
        {
            rulesReplay: this.props.data.user.length > 1 ? null : this.props.data.user[0].replay,
            rulesAccess: this.props.data.user.length > 1 ? null : this.props.data.user[0].access,
            rulesRecording: this.props.data.user.length > 1 ? null : this.props.data.user[0].recordingRules,
            rulesAnalytics: this.props.data.user.length > 1 ? null : this.props.data.user[0].analytics != null ? this.props.data.user[0].analytics.split(',') : [],
            addOnAnalytics: this.props.data.user.length > 1 ? null : this.props.data.user[0].addOnAnalytics,
            license: this.props.data.user[0].license
        };

    render() {
        let administrator = (this.props.userRules.access === "Administrator" || this.props.userRules.access === "SuperUser");
        console.log("administrator: " + administrator);  
        // Check if user is allowed to assign rules to these user
        let assignmentPossible = false;
        if ((administrator || this.props.userRules.ruleAccess != null)) {
            if (this.props.userRules != null && this.props.userRules.ruleAccess.confUserAssignment == "All") {
                assignmentPossible = true;
            } else if (this.props.userRules != null && this.props.userRules.ruleAccess.confUserAssignment == "Certain") {
                if (
                    this.props.userRules.ruleAccess.userAssignmentUsers.includes(this.props.data.user[0].userId) ||
                    this.props.userRules.ruleAccess.userAssignmentUsers.includes(this.props.data.user[0].groupId) ||
                    this.props.userRules.ruleAccess.userAssignmentUsers.includes(this.props.data.user[0].adGroupId))
                    assignmentPossible = true;
                
            }
            return <MyContext.Consumer>{(context) => (
                <div>
                    {assignmentPossible && this.props.userRules.ruleAccess.confAssignRecording && (this.state.license!== "10") && <Dropdown placeholder="Select an option" onChange={this._onChangeRecording} label={context.getTranslation("configuration", "recording")} defaultSelectedKey={this.state.rulesRecording} options={recordingOption(this.props.data.rule)} styles={dropdownStyles} />}
                    {(!assignmentPossible || !this.props.userRules.ruleAccess.confAssignRecording) && <Dropdown placeholder="Select an option" onChange={this._onChangeRecording} disabled label={context.getTranslation("configuration", "recording")} defaultSelectedKey={this.state.rulesRecording} options={recordingOption(this.props.data.rule)} styles={dropdownStyles} />}
                    {assignmentPossible && this.props.userRules.ruleAccess.confAssignReplay && <Dropdown placeholder="Select an option" onChange={this._onChangeReplay} label={context.getTranslation("configuration", "replay")} defaultSelectedKey={this.state.rulesReplay} options={replayOption(this.props.data.rule)} styles={dropdownStyles} />}
                    {(!assignmentPossible || !this.props.userRules.ruleAccess.confAssignReplay) && <Dropdown placeholder="Select an option" onChange={this._onChangeReplay} disabled label={context.getTranslation("configuration", "replay")} defaultSelectedKey={this.state.rulesReplay} options={replayOption(this.props.data.rule)} styles={dropdownStyles} />}
                    {assignmentPossible && this.props.userRules.ruleAccess.confAssignAccess && <Dropdown placeholder="Select an option" onChange={this._onChangeAccess} label={context.getTranslation("configuration", "access")} defaultSelectedKey={this.state.rulesAccess} options={accessOption(this.props.data.rule)} styles={dropdownStyles} />}
                    {(!assignmentPossible || !this.props.userRules.ruleAccess.confAssignAccess) && <Dropdown placeholder="Select an option" onChange={this._onChangeAccess} disabled label={context.getTranslation("configuration", "access")} defaultSelectedKey={this.state.rulesAccess} options={accessOption(this.props.data.rule)} styles={dropdownStyles} />}
                    {(assignmentPossible && this.props.userRules.ruleAccess.confAssignAnalytics) && (this.state.license === "2" || this.state.license === "4" || this.state.license === "5" || this.state.license === "7" || this.state.license === "9" || this.state.license === "10" || this.state.addOnAnalytics) && <Dropdown placeholder="Select an option" onChange={this._onChangeAnalytics} multiSelect label={context.getTranslation("configuration", "analytics")} defaultSelectedKeys={this.state.rulesAnalytics} options={analyticsOption(this.props.data.rule)} styles={dropdownStyles} />}
                    {(!assignmentPossible || !this.props.userRules.ruleAccess.confAssignAnalytics) && (this.state.license === "2" || this.state.license === "4" || this.state.license === "5" || this.state.license === "7" || this.state.license === "9" || this.state.license === "10" || this.state.addOnAnalytics) && <Dropdown placeholder="Select an option" onChange={this._onChangeAnalytics} disabled multiSelect label={context.getTranslation("configuration", "analytics")} defaultSelectedKeys={this.state.rulesAnalytics} options={analyticsOption(this.props.data.rule)} styles={dropdownStyles} />}
                </div>)}
            </MyContext.Consumer>
        }
    }

    _onChangeRecording = async (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        console.log(`Selection change: ${item.text} ${item.selected ? 'selected' : 'unselected'}`);
        console.log(item.key);
        await this.setState({ rulesRecording: item.key });
        this.props.action(this.state);

    };


    _onChangeAnalytics = async (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        var newSelectedItems = this.state.rulesAnalytics;
        if (item.selected) {
            // Remove all other selections
            newSelectedItems = [];
            // add the option if it's checked
            newSelectedItems.push(item.key);
        } else {
            // remove the option if it's unchecked
            const currIndex = newSelectedItems.indexOf(item.key);
            if (currIndex > -1) {
                newSelectedItems.splice(currIndex, 1);
            }
        }
        await this.setState({
            rulesAnalytics: newSelectedItems
        });
        this.props.action(this.state);

    };

    _onChangeAccess = async (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        console.log(`Selection change: ${item.text} ${item.selected ? 'selected' : 'unselected'}`);
        console.log(item.key);
        await this.setState({ rulesAccess: item.key });
        this.props.action(this.state);

    };

    _onChangeReplay = async (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        console.log(`Selection change: ${item.text} ${item.selected ? 'selected' : 'unselected'}`);
        console.log(item.key);
        await this.setState({ rulesReplay: item.key });
        console.log(this.state.rulesReplay);
        this.props.action(this.state);

    };
};

function accessOption(rule)
    {
         let out =[];
         let tmp=[];
        for(let i=0;i<rule.length;i++)
        {
            if ((rule[i].group==="access"))//filter the Rule Type
            {
                tmp.push(rule[i]);
            }
        }
        switch (tmp)
        {
            case 0: out.push({ key:"no Rule", text: "no Rule", disabled: true }); break;
            case 1:   out.push({ key:tmp[0].id, text: tmp[0].name, disabled: true }); break;
            default :
                out.push({ key: 'Header', text: 'Access Rules', itemType: DropdownMenuItemType.Header });
                for(let i=0;i<tmp.length;i++)
                    {
                        out.push({ key:tmp[i].id, text:tmp[i].name });
                    }
                break;
        }
        return out;
    }
function analyticsOption(rule)
{
    let out =[];
    let tmp=[];
    for(let i=0;i<rule.length;i++)
    {
        if ((rule[i].group==="analytics"))//filter the Rule Type
        {
            tmp.push(rule[i]);
        }
    }
    switch (tmp)
    {
        case 0: out.push({ key:"no Rule", text: "no Rule", disabled: true }); break;
        case 1:   out.push({ key:tmp[0].id, text: tmp[0].name, disabled: true }); break;
        default :
            out.push( { key: 'Header', text: 'Analytic Rules', itemType: DropdownMenuItemType.Header });
            for(let i=0;i<tmp.length;i++)
            {
                out.push({ key:tmp[i].id, text:tmp[i].name,title:tmp[i].usage});
            }
            break;
    }
    return out;
}function recordingOption(rule)
{
    let out =[];
    let tmp = [];
    if (rule != null) {
        for (let i = 0; i < rule.length; i++) {
            if ((rule[i].group === "recording"))//filter the Rule Type
            {
                tmp.push(rule[i]);
            }
        }
    }
    switch (tmp)
    {
        case 0: out.push({ key:"no Rule", text: "no Rule", disabled: true }); break;
        case 1:   out.push({ key:tmp[0].id, text: tmp[0].name, disabled: true }); break;
        default :
            out.push( { key: 'Header', text: 'Recording Rules', itemType: DropdownMenuItemType.Header });
            for(let i=0;i<tmp.length;i++)
            {
                out.push({ key:tmp[i].id, text:tmp[i].name,title:tmp[i].usage});
            }
            break;
    }
    return out;
}function replayOption(rule)
{
    let out =[];
    let tmp=[];
    for(let i=0;i<rule.length;i++)
    {
        if ((rule[i].group==="replay"))//filter the Rule Type
        {
            tmp.push(rule[i]);
        }

    }
    switch (tmp)
    {
        case 0: out.push({ key:"no Rule", text: "no Rule", disabled: true }); break;
        case 1:   out.push({ key:tmp[0].id, text: tmp[0].name, disabled: true }); break;
        default :
            out.push( { key: 'Header', text: 'Replay Rules', itemType: DropdownMenuItemType.Header });
            for(let i=0;i<tmp.length;i++)
            {
                out.push({ key:tmp[i].id, text:tmp[i].name,title:tmp[i].usage});
            }
            break;
    }
    return out;
}

