const region = [
  {
    key: 'AF',
    text: 'Afghanistan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'AX',
    text: 'Aland Islands',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'AL',
    text: 'Albania',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'DZ',
    text: 'Algeria',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'AS',
    text: 'American Samoa',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'AD',
    text: 'Andorra',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'AO',
    text: 'Angola',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'AI',
    text: 'Anguilla',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'AQ',
    text: 'Antarctica',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'AG',
    text: 'Antigua and Barbuda',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'AR',
    text: 'Argentina',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'AM',
    text: 'Armenia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'AW',
    text: 'Aruba',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'SH',
    text: 'Ascension',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'AU',
    text: 'Australia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'AT',
    text: 'Austria',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'AZ',
    text: 'Azerbaijan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'BS',
    text: 'Bahamas',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'BH',
    text: 'Bahrain',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'BD',
    text: 'Bangladesh',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'BB',
    text: 'Barbados',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'BY',
    text: 'Belarus',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'BE',
    text: 'Belgium',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'BZ',
    text: 'Belize',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'BJ',
    text: 'Benin',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'BM',
    text: 'Bermuda',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'BT',
    text: 'Bhutan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'BO',
    text: 'Bolivia',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'BQ',
    text: 'Bonaire, Saint Eustatius and Saba',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'BA',
    text: 'Bosnia and Herzegovina',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'BW',
    text: 'Botswana',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'BV',
    text: 'Bouvet Island',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'BR',
    text: 'Brazil',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'IO',
    text: 'British Territories in the Indian Ocean',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'VG',
    text: 'British Virgin Islands',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'BN',
    text: 'Brunei',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'BG',
    text: 'Bulgaria',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'BF',
    text: 'Burkina Faso',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'BI',
    text: 'Burundi',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'CV',
    text: 'Cabo Verde',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'KH',
    text: 'Cambodia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'CM',
    text: 'Cameroon',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'CA',
    text: 'Canada',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'KY',
    text: 'Cayman Islands',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'CF',
    text: 'Central African Republic',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'TD',
    text: 'Chad',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'CL',
    text: 'Chile',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'CN',
    text: 'China',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'CX',
    text: 'Christmas Island',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'CC',
    text: 'Cocos (Keeling) Islands',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'CO',
    text: 'Colombia',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'KM',
    text: 'Comoros',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'CG',
    text: 'Congo (Brazzaville)',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'CD',
    text: 'Congo (Kinshasa)',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'CK',
    text: 'Cook Islands',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'CR',
    text: 'Costa Rica',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'CI',
    text: "Côte d'Ivoire",
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'HR',
    text: 'Croatia',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'CU',
    text: 'Cuba',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'CY',
    text: 'Cyprus',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'CZ',
    text: 'Czechia',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'DK',
    text: 'Denmark',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'DJ',
    text: 'Djibouti',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'DM',
    text: 'Dominica',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'DO',
    text: 'Dominican Republic',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'EC',
    text: 'Ecuador',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'EG',
    text: 'Egypt',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'SV',
    text: 'El Salvador',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'GQ',
    text: 'Equatorial Guinea',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'ER',
    text: 'Eritrea',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'EE',
    text: 'Estonia',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'SZ',
    text: 'Eswatini',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'ET',
    text: 'Ethiopia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'FO',
    text: 'Faeroe Islands',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'FK',
    text: 'Falkland Islands',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'FJ',
    text: 'Fiji',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'FI',
    text: 'Finland',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'FR',
    text: 'France',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'GF',
    text: 'French Guyana',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'PF',
    text: 'French Polynesia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'TF',
    text: 'French Southern and Antarctic Lands',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'GA',
    text: 'Gabon',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'GM',
    text: 'Gambia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'GE',
    text: 'Georgia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'DE',
    text: 'Germany',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'GH',
    text: 'Ghana',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'GI',
    text: 'Gibraltar',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'GR',
    text: 'Greece',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'GL',
    text: 'Greenland',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'GD',
    text: 'Grenada',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'GP',
    text: 'Guadeloupe',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'GU',
    text: 'Guam',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'GT',
    text: 'Guatemala',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'GG',
    text: 'Guernsey',
    endpoint: 'D',
    currency: 'GBP',
    vat: false,
    support: 'order-uk@asctechnologies.com',
  },
  {
    key: 'GN',
    text: 'Guinea',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'GW',
    text: 'Guinea-Bissau',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'GY',
    text: 'Guyana',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'HT',
    text: 'Haiti',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'HM',
    text: 'Heard Island and McDonald Islands',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'HN',
    text: 'Honduras',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'HK',
    text: 'Hong Kong',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'HU',
    text: 'Hungary',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'IS',
    text: 'Iceland',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'IN',
    text: 'India',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'ID',
    text: 'Indonesia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'IR',
    text: 'Iran',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'IQ',
    text: 'Iraq',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'IE',
    text: 'Ireland',
    endpoint: 'D',
    currency: 'GBP',
    vat: true,
    support: 'order-uk@asctechnologies.com',
  },
  {
    key: 'IM',
    text: 'Isle of Man',
    endpoint: 'D',
    currency: 'GBP',
    vat: false,
    support: 'order-uk@asctechnologies.com',
  },
  {
    key: 'IL',
    text: 'Israel',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'IT',
    text: 'Italy',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'JM',
    text: 'Jamaica',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'JP',
    text: 'Japan',
    endpoint: 'D',
    currency: 'YEN',
    vat: false,
    support: 'order-jp@asctechnologies.com',
  },
  {
    key: 'JE',
    text: 'Jersey',
    endpoint: 'D',
    currency: 'GBP',
    vat: false,
    support: 'order-uk@asctechnologies.com',
  },
  {
    key: 'UM',
    text: 'Johnston Island',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'JO',
    text: 'Jordan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'KZ',
    text: 'Kazakhstan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'KE',
    text: 'Kenya',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'KI',
    text: 'Kiribati',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'KW',
    text: 'Kuwait',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'KG',
    text: 'Kyrgyzstan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'LA',
    text: 'Laos',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'LV',
    text: 'Latvia',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'LB',
    text: 'Lebanon',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'LS',
    text: 'Lesotho',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'LR',
    text: 'Liberia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'LY',
    text: 'Libya',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'LI',
    text: 'Liechtenstein',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ch@asctechnologies.com',
  },
  {
    key: 'LT',
    text: 'Lithuania',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'LU',
    text: 'Luxembourg',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'MO',
    text: 'Macao',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'MG',
    text: 'Madagascar',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'MW',
    text: 'Malawi',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'MY',
    text: 'Malaysia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'MV',
    text: 'Maldives',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'ML',
    text: 'Mali',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'MT',
    text: 'Malta',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'MH',
    text: 'Marshall Islands',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'MQ',
    text: 'Martinique',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'MR',
    text: 'Mauritania',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'MU',
    text: 'Mauritius',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'YT',
    text: 'Mayotte',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'MX',
    text: 'Mexico',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'FM',
    text: 'Micronesia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'UM',
    text: 'Midway Island',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'MD',
    text: 'Moldova',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'MC',
    text: 'Monaco',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'MN',
    text: 'Mongolia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'ME',
    text: 'Montenegro',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'MS',
    text: 'Montserrat',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'MA',
    text: 'Morocco',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'MZ',
    text: 'Mozambique',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'MM',
    text: 'Myanmar',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'NA',
    text: 'Namibia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'NR',
    text: 'Nauru',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'NP',
    text: 'Nepal',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'NL',
    text: 'Netherlands',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'NC',
    text: 'New Caledonia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'NZ',
    text: 'New Zealand',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'NI',
    text: 'Nicaragua',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'NE',
    text: 'Niger',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'NG',
    text: 'Nigeria',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'NU',
    text: 'Niue',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'NF',
    text: 'Norfolk Island',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'KP',
    text: 'North Korea',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'MK',
    text: 'North Macedonia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'MP',
    text: 'Northern Marianas',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'NO',
    text: 'Norway',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'OM',
    text: 'Oman',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'PK',
    text: 'Pakistan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'PW',
    text: 'Palau',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'PS',
    text: 'Palestine',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'PA',
    text: 'Panama',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'PG',
    text: 'Papua New Guinea',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'PY',
    text: 'Paraguay',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'PE',
    text: 'Peru',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'PH',
    text: 'Philippines',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'PN',
    text: 'Pitcairn Islands',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'PL',
    text: 'Poland',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'PT',
    text: 'Portugal',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'PR',
    text: 'Puerto Rico',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'QA',
    text: 'Qatar',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'RE',
    text: 'Réunion',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'RO',
    text: 'Romania',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'RU',
    text: 'Russia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'RW',
    text: 'Rwanda',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'BL',
    text: 'Saint Barthélemy',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'SH',
    text: 'Saint Helena',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'KN',
    text: 'Saint Kitts and Nevis',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'LC',
    text: 'Saint Lucia',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'MF',
    text: 'Saint Martin (France)',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'PM',
    text: 'Saint Pierre and Miquelon',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'VC',
    text: 'Saint Vincent and the Grenadines',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'WS',
    text: 'Samoa',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'SM',
    text: 'San Marino',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'ST',
    text: 'São Tomé and Príncipe',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: '',
    text: 'Sark',
    endpoint: 'D',
    currency: 'GBP',
    vat: false,
    support: 'order-uk@asctechnologies.com',
  },
  {
    key: 'SA',
    text: 'Saudi Arabia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'SN',
    text: 'Senegal',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'RS',
    text: 'Serbia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'SC',
    text: 'Seychelles',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'SL',
    text: 'Sierra Leone',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'SG',
    text: 'Singapore',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'SX',
    text: 'Sint Maarten (Netherlands)',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'SK',
    text: 'Slovakia',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'SI',
    text: 'Slovenia',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'SB',
    text: 'Solomon Islands',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'SO',
    text: 'Somalia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'ZA',
    text: 'South Africa',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'GS',
    text: 'South Georgia and the South Sandwich Islands',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'KR',
    text: 'South Korea',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'SS',
    text: 'South Sudan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'ES',
    text: 'Spain',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'LK',
    text: 'Sri Lanka',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'SD',
    text: 'Sudan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'SR',
    text: 'Suriname',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'SJ',
    text: 'Svalbard and Jan Mayen',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'SE',
    text: 'Sweden',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'CH',
    text: 'Switzerland',
    endpoint: 'D',
    currency: 'EUR',
    vat: true,
    support: 'order-ch@asctechnologies.com',
  },
  {
    key: 'SY',
    text: 'Syria',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'TW',
    text: 'Taiwan (Chinese Taipei)',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'TJ',
    text: 'Tajikistan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'TZ',
    text: 'Tanzania',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'TH',
    text: 'Thailand',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'TL',
    text: 'Timor-Leste',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'TG',
    text: 'Togo',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'TK',
    text: 'Tokelau',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'TO',
    text: 'Tonga',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'TT',
    text: 'Trinidad and Tobago',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'SH',
    text: 'Tristan da Cunha',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'TN',
    text: 'Tunisia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'TR',
    text: 'Turkey',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'TM',
    text: 'Turkmenistan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'TC',
    text: 'Turks and Caicos Islands',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'TV',
    text: 'Tuvalu',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'UG',
    text: 'Uganda',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'UA',
    text: 'Ukraine',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'AE',
    text: 'United Arab Emirates',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'GB',
    text: 'United Kingdom',
    endpoint: 'D',
    currency: 'GBP',
    vat: false,
    support: 'order-uk@asctechnologies.com',
  },
  {
    key: 'US',
    text: 'United States',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'UY',
    text: 'Uruguay',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'VI',
    text: 'US Virgin Islands',
    endpoint: 'US',
    currency: 'USD',
    vat: false,
    support: 'order-us@asctechnologies.com',
  },
  {
    key: 'UZ',
    text: 'Uzbekistan',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'VU',
    text: 'Vanuatu',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'VA',
    text: 'Vatican City',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'VE',
    text: 'Venezuela',
    endpoint: 'D',
    currency: 'USD',
    vat: false,
    support: 'order-br@asctechnologies.com',
  },
  {
    key: 'VN',
    text: 'Vietnam',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'UM',
    text: 'Wake Island',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'WF',
    text: 'Wallis and Futuna',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-apac@asctechnologies.com',
  },
  {
    key: 'EH',
    text: 'Western Sahara',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-fr@asctechnologies.com',
  },
  {
    key: 'YE',
    text: 'Yemen',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'order-ae@asctechnologies.com',
  },
  {
    key: 'ZM',
    text: 'Zambia',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
  {
    key: 'ZW',
    text: 'Zimbabwe',
    endpoint: 'D',
    currency: 'EUR',
    vat: false,
    support: 'Order-hq@asctechnologies.com',
  },
];
export default region;
