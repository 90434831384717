import { CommandBarButton, DetailsList, FontIcon, Icon, MarqueeSelection, Panel, PanelType, Text, Selection, SelectionMode, Separator, Spinner, Stack, MessageBar, MessageBarType, DocumentCard, DocumentCardTitle, DocumentCardActions, DocumentCardLocation, TeachingBubble } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import '../ComplianceView.scss';
import { MyContext } from '../../../context';
import { IStackTokens, IDetailsColumnStyles } from '@fluentui/react';

export default class PolicyListView extends Component {
    static contextType = MyContext
    _selection: Selection;

    constructor(props) {
        super(props)
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.props.selectPolicy(this._selection.getSelection().length > 0 ? this._selection.getSelection()[0].id : null)
            }
        });
    }

    headerStyle: Partial<IDetailsColumnStyles> = {
        cellTitle: {
            display: 'block',
            textAlign: 'center',
        }
    }

    getUsersAndGroups = (users, groups) => {
        var result = ""
        result += users.length > 0 ? users.map(x => { return x.username }).join() : "";
        result += groups.length > 0 ? result.length > 0 ? "/" + groups.map(x => { return x.name }).join() : groups.map(x => { return x.name }).join() : "";
        return result;
    }

    getChannelRender = (channelString) => {
        var result;
        switch (channelString) {
            case 'None':
                result = this.getDivForChannels(false, false)
                break
            case 'Text':
                result = this.getDivForChannels(true, false)
                break
            case 'Voice':
                result = this.getDivForChannels(false, true)
                break
            case 'Both':
                result = this.getDivForChannels(true, true)
                break
        }
        return (
            <span>{result}</span>
        )
    }


    getDivForChannels = (textEnable, voiceEnable) => {
        const numericalSpacingStackTokens: IStackTokens = {
            childrenGap: 10,
            paddingRight: 10,
        };

        return (
            <Stack horizontal disableShrink tokens={numericalSpacingStackTokens}>
                <span>{this.context.getTranslation("compliance", "text")} : <FontIcon iconName={textEnable ? "CheckboxComposite" : "Checkbox"} style={{ size: "10" }} /></span>
                <span>{this.context.getTranslation("compliance", "voice")} : <FontIcon iconName={voiceEnable ? "CheckboxComposite" : "Checkbox"} style={{ size: "10" }} /></span>
            </Stack>
        )
    }

    _getKey = (item, index) => {
        return item.key;
    }

    _renderItemColumn = (item, index, column) => {

        const fieldContent = item[column.fieldName]
        switch (column.key) {
            case 'users':
                return <span>{this.getUsersAndGroups(item.users, item.groups)}</span>;
            case 'basedOn': {
                switch (item.basedOn) {
                    case "PolicyRule": {
                        return <div>
                            <FontIcon
                                iconName={"TaskManager"}
                                style={{ marginRight: "5px" }}
                            />
                            <span>{item.policyRules !== null ? item.policyRules.map(cat => { return cat.name }).join() : ""}</span>
                        </div>;
                    }
                    case "AICategorization": {
                        return <div>
                            <FontIcon
                                iconName={"UserGauge"}
                                style={{ marginRight: "5px" }}
                            />
                            <span>{item.aICategorizationInfos !== null ? item.aICategorizationInfos.map(cat => { return cat.categorizationName }).join() : ""}</span>
                        </div>;
                    }
                    case "Scorecard": {
                        return <div>
                            <FontIcon
                                iconName={"TrackersMirrored"}
                                style={{ marginRight: "5px" }}
                            />
                            <span>{item.aIScorecardInfos !== null ? item.aIScorecardInfos.map(s => s.scorecardName).join() : ""}</span>
                        </div>;
                    }
                    default: return <div></div>
                }
            }
            case "active":
                return <span>{<FontIcon iconName={fieldContent ? "CheckboxComposite" : "Checkbox"} style={{ size: "10" }} />}</span>;
            case "channel":
                return this.getChannelRender(fieldContent);
            case "riskRating":
                return this.props.getRiskRating(fieldContent);
            case 'supervisors':
                return <span>{(item.supervisors != null && item.supervisors.length > 0) ? item.supervisors.map(sv => sv.username).join() : ""}</span>;
            default:
                return <span>{fieldContent !== null ? fieldContent : ""}</span>;
        }
    }

    render() {
        const columns = [
            { key: 'name', name: this.context.getTranslation("compliance", "detailPolicyName"), fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
            { key: 'description', name: this.context.getTranslation("compliance", "detailPolicyDescription"), fieldName: 'description', minWidth: 100, maxWidth: 200 },
            { key: 'channel', name: this.context.getTranslation("compliance", "detailPolicyChannel"), fieldName: 'channel', minWidth: 100, maxWidth: 150 },
            { key: 'riskRating', styles: this.headerStyle, name: this.context.getTranslation("compliance", "detailPolicyRiskRating"), fieldName: 'riskRating', minWidth: 100, maxWidth: 150 },
            { key: 'users', name: this.context.getTranslation("compliance", "detailPolicyUsers"), minWidth: 100, maxWidth: 200 },
            { key: 'supervisors', name: this.context.getTranslation("compliance", "detailPolicySupervisor"), minWidth: 100, maxWidth: 200 },
            { key: 'basedOn', name: this.context.getTranslation("compliance", "basedOn"), minWidth: 100, maxWidth: 200 },
            { key: 'active', name: this.context.getTranslation("compliance", "detailPolicyActive"), fieldName: 'active', minWidth: 80, maxWidth: 100 }
        ]

        return (
            <MarqueeSelection selection={this._selection}>
                <DetailsList
                    items={this.props.policies}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    selection={this._selection}
                    getKey={this._getKey}
                    setKey="single"
                    onRenderItemColumn={this._renderItemColumn}
                />
            </MarqueeSelection>
        )
    }

}