import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import {
    TextField, Stack, DefaultButton, PrimaryButton, Dropdown, Toggle, Spinner, TeachingBubble, Separator
} from 'office-ui-fabric-react';
import { getSearchAreaTypeEnum } from '../../PolicyRules/PolicyRulesQuery';
import { updateScorecard, getAllVersionOfScorecard, queryScorecard, restoreScorecard } from "../AIQualityManagementQuery"
import EditScorecardSection from './EditScorecardSection';
import { Query } from 'react-apollo';
import AlertBar from '../../../CustomComponents/AlertBar';
import ScorecardFolding from '../../../DetailView/DetailPivot/PivotElements/QualityManagement/ScorecardFolding';
import EditScorecardSettings from './EditScorecardSettings';

export default class EditScorecard extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        const scorecard = this.props.data !== undefined ? this.props.data : null
        const defaultSettingItems = [
            {
                name: "Excellent",
                maxRange: 100,
                minRange: 90,
                color: "#00B050"
            },
            {
                name: "Good",
                maxRange: 89,
                minRange: 80,
                color: "#00B0F0"
            },
            {
                name: "Average",
                maxRange: 79,
                minRange: 70,
                color: "#FFFF00"
            },
            {
                name: "Sufficient",
                maxRange: 69,
                minRange: 60,
                color: "#FFC000"
            },
            {
                name: "Bad",
                maxRange: 59,
                minRange: 0,
                color: "#156082"
            }
        ]
        //console.log(scorecard)
        this.state = {
            //page data
            processing: false,
            selectCriteria: null,
            allAvailableAreas: [],
            availableAreas: [],
            selectedOtherVersion: false,
            selectedVersion: scorecard !== null ? scorecard.id : null,
            processingVersion: false,
            alertAutomatedEvaluation: false,
            selectedKey: ["elements"],
            openModalColor: false,
            // scorecard data
            id: scorecard !== null ? scorecard.id : null,
            name: scorecard !== null ? scorecard.name : "",
            description: scorecard !== null ? scorecard.description : "",
            sections: scorecard !== null ? this.cleanSectionsItems(scorecard.sections) : [],
            automatedEvaluation: scorecard !== null ? scorecard.automatedEvaluation : false,
            masterItemId: scorecard !== null ? scorecard.masterItemId : null,
            version: scorecard !== null ? scorecard.version : 0,
            selected: scorecard !== null ? scorecard.selected : true,
            creationDate: scorecard !== null ? scorecard.creationDate : null,
            settingItems: scorecard !== null ? this.cleanSettingItems(scorecard.settingItems) : defaultSettingItems
        }

    }

    refetchQueryVersions = null;
    numberOfVersions = 1

    refreshVersions = () => {
        if (this.refetchQueryVersions !== null) {
            this.refetchQueryVersions()
        }
    }

    cleanSettingItems = (settingItems) => {
        return settingItems.map(item => {
            delete item.__typename
            return item
        })
    }

    cleanSectionsItems = (sections) => {
        return sections.map(s => {
            s.evaluationItems.map(e => {
                delete e.__typename
                return e
            })
            delete s.__typename
            return s
        })
    }

    componentDidMount() {
        this.props.apol
            .query({
                query: getSearchAreaTypeEnum,
            })
            .then((result) => {
                var areas = result.data.getSearchAreaTypeEnum;
                var allAvailableAreas = areas.filter(area =>
                    area.name !== "Keywords" && area.name !== "Categories" && area.name !== "Patterns"
                ).map(r => {
                    return {
                        "key": r.name,
                        "text": this.context.getTranslation("compliance", r.name)
                    }
                })
                let availableAreas = this.state.automatedEvaluation ?
                    allAvailableAreas :
                    allAvailableAreas.filter(area => area.key === "QMQuestions")
                this.setState({
                    allAvailableAreas,
                    availableAreas
                })
            });
        this.refreshVersions()
    }

    change = (e, field) => {
        this.setState({ [field]: e.target.value, selectedOtherVersion: false })
    }

    getOptionsCriteria = () => {
        let section = { key: "section", text: this.context.getTranslation("compliance", "section") }
        let evaluation = { key: "evaluation", text: this.context.getTranslation("compliance", "evaluation") }
        let lengthSections = this.state.sections.length
        if (lengthSections === 0) {
            return [section]
        } else if (lengthSections > 0 && this.state.sections[lengthSections - 1].evaluationItems.length === 0) {
            return [evaluation]
        }
        else {
            return [section, evaluation]
        }
    }


    onChangeSelectCriteria = (e, item) => {
        this.setState({ selectCriteria: item.key })
    }

    addCriteria = () => {
        if (this.state.selectCriteria === "section") {
            this.setState({ sections: [...this.state.sections, { name: "", evaluationItems: [] }], selectCriteria: null })
        }
        if (this.state.selectCriteria === "evaluation") {
            let newSections = this.state.sections.map((s, i) => {
                if (i === this.state.sections.length - 1) {
                    return (
                        {
                            name: s.name, evaluationItems: [...s.evaluationItems, {
                                name: "",
                                itemType: "",
                                itemId: "",
                                itemValue: "",
                                points: parseFloat(0),
                                awareOfPoints: false
                            }]
                        }
                    )
                } else {
                    return s
                }
            })
            this.setState({ sections: newSections, selectCriteria: null, selectedOtherVersion: false })
        }
    }

    isValid = () => {
        const checkName = this.state.name !== ""
        const chackNumberOfSections = this.state.sections.length > 0
        const checkSectionsName = this.state.sections.filter(s => s.name === "").length === 0
        const checkNumberOfEvaluationItems = this.state.sections.filter(s => s.evaluationItems.length === 0).length === 0
        //check every evaluation item
        let checkEvaluationItems = true
        this.state.sections.forEach(s => {
            s.evaluationItems.forEach(e => {
                if (
                    e.itemType === "" ||
                    e.points < 0 ||
                    (e.itemType !== "QMQuestions" && e.itemId === "") ||
                    (e.itemType === "QMQuestions" && e.itemValue === "")
                ) {
                    checkEvaluationItems = false
                }
            })
        })
        //check settings
        let checkSettingItems = true
        const regex = new RegExp("^[0-9]*$")
        this.state.settingItems.forEach(s => {
            if (s.name === "" || s.maxRange <= s.minRange || s.maxRange === "" || !regex.test(s.maxRange)) {
                checkSettingItems = false
            }
        })
        return checkName && chackNumberOfSections && checkSectionsName && checkNumberOfEvaluationItems && checkEvaluationItems && checkSettingItems
    }

    save = (upsertToTemplate, saveAsNew) => {
        this.setState({ processing: true })
        const scorecard = {
            id: saveAsNew ? null : this.state.id,
            name: this.state.name,
            description: this.state.description,
            sections: this.state.sections,
            automatedEvaluation: this.state.automatedEvaluation,
            userId: this.props.userRules.userId,
            version: saveAsNew ? "1" : this.numberOfVersions.toString(),
            masterItemId: saveAsNew ? null : this.state.masterItemId,
            selected: this.state.selected,
            creationDate: this.state.creationDate,
            settingItems: this.state.settingItems
        }
        this.props.apol
            .mutate({
                mutation: updateScorecard,
                variables: { scorecard, upsertToTemplate }
            })
            .then((result) => {
                var resultData = result.data.upsertScorecard
                if (resultData.status === "ok") {
                    this.props.hidePanel()
                    this.props.updateScorecardId(resultData.id)
                    this.props.refreshScorecards()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", resultData.text))
                }
                this.setState({ processing: false })
            });
    }

    //VERSIONING

    onChangeVersion = (e, item) => {
        //get specific version to fill the fields
        this.setState({ processingVersion: true })
        let id = item.key
        this.props.apol
            .query({
                query: queryScorecard,
                variables: { id }
            })
            .then(({ loading, error, data, refetch }) => {
                let oldVersion = data.getScorecard;
                this.setState({
                    //scorecard data
                    name: oldVersion.name,
                    description: oldVersion.description,
                    userId: oldVersion.userId,
                    sections: this.cleanSectionsItems(oldVersion.sections),
                    automatedEvaluation: oldVersion.automatedEvaluation,
                    creationDate: oldVersion.creationDate,
                    settingItems: this.cleanSettingItems(oldVersion.settingItems),
                    //page data
                    selectedVersion: id,
                    selectedOtherVersion: true,
                    processingVersion: false
                })
            });
    }

    restoreVersion = () => {
        this.setState({ processing: true })
        //restoreScorecard -> id vesrion to restore
        let id = this.state.selectedVersion
        this.props.apol
            .mutate({
                mutation: restoreScorecard,
                variables: { id }
            })
            .then((result) => {
                if (result.data.restoreScorecard.status === "ok") {
                    this.props.hidePanel()
                    this.props.updateScorecardId(id)
                    this.props.refreshScorecards()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.restoreScorecard.text))
                }
                this.setState({
                    selectedOtherVersion: false,
                    processing: false
                })
            });
    }

    render() {
        return (
            <div>
                {this.state.processingVersion && <div>
                    <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                </div>}
                {!this.state.processingVersion && <div>
                    {this.state.masterItemId !== null &&
                        <Query
                            query={getAllVersionOfScorecard}
                            variables={{ masterItemId: this.state.masterItemId }}>
                            {({ loading, error, data, refetch }) => {
                                this.refetchQueryVersions = refetch
                                if (loading) return <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                if (error) {
                                    console.log(error)
                                    return (
                                        <AlertBar message={this.context.getTranslation("compliance", "error")} alertType="error" />
                                    );
                                }
                                if (data.getAllVersionOfScorecard == null) {
                                    return <AlertBar message={this.context.getTranslation("common", "noData")} alertType={"warning"} />
                                }
                                let versions = data.getAllVersionOfScorecard.map(v => {
                                    return {
                                        "key": v.id,
                                        "text": v.version
                                    }
                                })
                                this.numberOfVersions = versions.length
                                return (<div>
                                    {versions.length > 0 &&
                                        <Dropdown
                                            label={this.context.getTranslation("compliance", "version")}
                                            selectedKey={this.state.selectedVersion}
                                            onChange={this.onChangeVersion}
                                            options={versions}
                                        />
                                    }
                                </div>
                                )
                            }}
                        </Query>}
                    <TextField label={this.context.getTranslation("compliance", "name")} value={this.state.name} onChange={(e) => this.change(e, "name")} required />
                    <TextField label={this.context.getTranslation("compliance", "description")} value={this.state.description} onChange={(e) => this.change(e, "description")} />
                    <Stack style={{ marginTop: "15px" }}>
                        {this.state.alertAutomatedEvaluation && <TeachingBubble
                            target={`#${"automatedEvaluation"}`}
                            secondaryButtonProps={{
                                children: this.context.getTranslation("common", "no"),
                                onClick: () => {
                                    this.setState({ alertAutomatedEvaluation: false })
                                }
                            }}
                            primaryButtonProps={{
                                children: this.context.getTranslation("common", "yes"),
                                onClick: () => {
                                    let availableAreas = []
                                    let checked = !this.state.automatedEvaluation
                                    if (!checked) {
                                        availableAreas = this.state.allAvailableAreas.filter(area => area.key === "QMQuestions")
                                    } else {
                                        availableAreas = this.state.allAvailableAreas
                                    }
                                    this.setState({
                                        automatedEvaluation: checked,
                                        selectedOtherVersion: false,
                                        availableAreas,
                                        sections: [],
                                        alertAutomatedEvaluation: false
                                    })
                                }
                            }}
                        >
                            {this.context.getTranslation("compliance", "alertAutomatedEvaluation")}
                        </TeachingBubble>}
                        <Toggle
                            id="automatedEvaluation"
                            checked={this.state.automatedEvaluation}
                            onText={this.context.getTranslation("compliance", "automatedEvaluation")}
                            offText={this.context.getTranslation("compliance", "manualEvaluation")}
                            onChange={(e, checked) => {
                                if (this.state.sections.length > 0) {
                                    this.setState({ alertAutomatedEvaluation: true })
                                } else {
                                    let availableAreas = []
                                    if (!checked) {
                                        availableAreas = this.state.allAvailableAreas.filter(area => area.key === "QMQuestions")
                                    } else {
                                        availableAreas = this.state.allAvailableAreas
                                    }
                                    this.setState({
                                        automatedEvaluation: checked,
                                        selectedOtherVersion: false,
                                        availableAreas,
                                        sections: []
                                    })
                                }
                            }}
                        />
                    </Stack>
                    <Stack horizontal>
                        <Stack.Item style={{ marginTop: "5px", marginRight: "5px" }}>
                            <ScorecardFolding
                                selectedKey={this.state.selectedKey}
                                selected={"elements"}
                                open={() => {
                                    this.setState({
                                        selectedKey: [...this.state.selectedKey, "elements"]
                                    })
                                }}
                                close={() => {
                                    this.setState({
                                        selectedKey: this.state.selectedKey.filter(s => s !== "elements")
                                    })
                                }}
                            />
                        </Stack.Item>
                        <h4>{this.context.getTranslation("compliance", "criteria")}</h4>
                    </Stack>
                    {this.state.selectedKey.includes("elements") && <Stack style={{ marginTop: "10px" }}>
                        <Stack>
                            {
                                //MANAGE SECTIONS
                                this.state.sections.map((section, indexSection) => {
                                    return <EditScorecardSection
                                        key={"section" + indexSection}
                                        section={section}
                                        indexSection={indexSection}
                                        availableAreas={this.state.availableAreas}
                                        updateSection={(sec, index, action) => {
                                            let newSections = null
                                            if (action === "delete") {
                                                newSections = this.state.sections.filter((item, i) => i !== index)
                                            }
                                            if (action === "update") {
                                                newSections = this.state.sections.map((item, i) => {
                                                    if (i === index) {
                                                        return sec
                                                    } else {
                                                        return item
                                                    }
                                                })
                                            }
                                            this.setState({ sections: newSections, selectedOtherVersion: false })
                                        }}
                                    />
                                })
                            }
                        </Stack>
                        <Stack horizontal>
                            <Stack.Item align="center" grow>
                                <Dropdown
                                    placeholder={this.context.getTranslation("compliance", "selectCriteria")}
                                    label=""
                                    selectedKey={this.state.selectCriteria}
                                    onChange={this.onChangeSelectCriteria}
                                    options={this.getOptionsCriteria()}
                                />
                            </Stack.Item>
                            <Stack.Item align="center" grow>
                                <PrimaryButton
                                    style={{ marginLeft: "30px" }}
                                    text={this.context.getTranslation("compliance", "add")}
                                    onClick={this.addCriteria}
                                    allowDisabledFocus
                                />
                            </Stack.Item>
                        </Stack>
                        <Separator className="separator" />
                    </Stack>}
                    <Stack horizontal>
                        <Stack.Item style={{ marginTop: "5px", marginRight: "5px" }}>
                            <ScorecardFolding
                                selectedKey={this.state.selectedKey}
                                selected={"settings"}
                                open={() => {
                                    this.setState({
                                        selectedKey: [...this.state.selectedKey, "settings"]
                                    })
                                }}
                                close={() => {
                                    this.setState({
                                        selectedKey: this.state.selectedKey.filter(s => s !== "settings")
                                    })
                                }}
                            />
                        </Stack.Item>
                        <h4>{this.context.getTranslation("compliance", "scorecardSettings")}</h4>
                    </Stack>
                    {this.state.selectedKey.includes("settings") &&
                        <Stack >
                            <EditScorecardSettings
                                settingItems={this.state.settingItems}
                                updateSettingItems={(settingItems) => this.setState({ settingItems })}
                            />
                        </Stack>
                    }
                    {/*FOOTER BUTTONS*/}
                    <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                        <DefaultButton
                            text={this.context.getTranslation("common", "cancel")}
                            onClick={() => { this.props.hidePanel() }}
                            style={{ marginTop: "30px", marginLeft: "15px" }}
                            disabled={this.state.processing}
                        />
                        {(!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion) &&
                            <PrimaryButton
                                text={this.context.getTranslation("common", "save")}
                                onClick={() => this.save(false, false)}
                                style={{ marginTop: "30px", marginLeft: "15px" }}
                                disabled={!this.isValid() || this.state.processing}
                            />
                        }
                        {(!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion) &&
                            this.state.id !== null &&
                            <PrimaryButton
                                text={this.context.getTranslation("compliance", "saveAsNew")}
                                onClick={() => this.save(false, true)}
                                style={{ marginTop: "30px", marginLeft: "15px" }}
                                disabled={!this.isValid() || this.state.processing}
                            />
                        }
                        {(this.props.userRules.userTemplateAllowed && (!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion)) &&
                            <PrimaryButton
                                text={this.context.getTranslation("common", "saveAsTemplate")}
                                onClick={() => this.save(true, false)}
                                style={!this.isValid() || this.state.processing ?
                                    { marginTop: "30px", marginLeft: "15px" } :
                                    { marginTop: "30px", marginLeft: "15px", background: "red", color: "white" }}
                                allowDisabledFocus={true}
                                disabled={!this.isValid() || this.state.processing}
                            />
                        }
                        {this.state.selectedOtherVersion && this.state.id !== this.state.selectedVersion &&
                            <PrimaryButton
                                text={this.context.getTranslation("compliance", "restoreVersion")}
                                onClick={this.restoreVersion} style={{ marginTop: "30px", marginLeft: "15px" }}
                                disabled={!this.isValid() || this.state.processing}
                            />
                        }
                    </Stack>
                </div>}
            </div>

        )
    }
}