import React, {Component} from 'react';
import './GridRow.scss'
import GridItem from '../GridElement/GridElement'
export default class gridRow extends Component {

  render() {
    let list = [];
    return (

        <div className="grid-row-inner">
        {
          this.props.rowContent.map((item, i) => {
            if (item.scenarioId != null && item.scenarioId !== ""){ // skip for empty Scenario, e.g. Chat messages
              let unique_id = item.scenarioId + item.userId;
              if (list.includes(unique_id)){
                item.isDuplicate = true;
              } else {
                list.push(unique_id);
              }
            }
            return (<GridItem
                key={i}
                apol={this.props.apol}
                item={item}
                authorization={this.props.authorization}
                userRules={this.props.userRules}
                onlyOne={this.props.onlyOne}
                theme={this.props.theme}
                toDoArea={this.props.toDoArea}
                />
            );
          })
        }
        </div>

    );
  };
}
