import React from 'react';
import { DefaultButton, Button, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Panel, PanelType, Separator, TextField } from 'office-ui-fabric-react/lib/';
import { Stack } from 'office-ui-fabric-react';
import gql from 'graphql-tag';
import './ReportConfiguration.scss';
import { mergeStyleSets } from '@uifabric/merge-styles';
import UserPicker from './UserPicker';
import { MyContext } from '../../../context';
import { toast } from 'react-toastify';

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        {
            flex: '1 1 auto',
            borderTop: '4px solid blue',
            color: 'black',
            display: 'flex',
            fontSize: '12px',
            alignItems: 'center',
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        //padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0',
            },
            'p:first-child': {
                marginTop: 0,
            },
            'p:last-child': {
                marginBottom: 0,
            },
        },
    },
});




export default class DashboardConfiguration extends React.Component {

    constructor(props) {
        super(props);

        this.categoriesList = [];
        this.categoriesListSource = [];
        this.allowedTenantsList = [];
        this.dashboardConf = React.createRef();

        let dashboardUsersSource = (this.props.users === "undefined" || this.props.users == null || this.props.users == "") ? "" : this.props.users.split(',');

        this.state = {
            isModalOpen: false,
            isChanged: false,
            changedTitle: props.title,
            changedType: props.type,
            tenantId: props.tenantId,
            dashboardUsers: dashboardUsersSource === "" ? [] : dashboardUsersSource.map(u => { return { userId: u } }),
        };
    }


    styles = {
        root: {
            paddingTop: "20px"
        }
    };


    render() {
        return (<MyContext.Consumer>
            {(context) => (

                <div>
                    <Panel
                        isOpen={this.state.isModalOpen}
                        onDismiss={this.hidePanel}
                        titleAriaId={this._titleId}
                        subtitleAriaId={this._subtitleId}
                        headerText={this.props.title !== "" ? context.getTranslation("dashboard", "changeDashboard") : context.getTranslation("dashboard", "createDashboard")}
                        closeButtonAriaLabel="Close"
                        type={PanelType.medium}
                    >

                        <div id={this._subtitleId} className={contentStyles.body} >
                            <Stack vertical>
                                <Stack.Item>
                                    <TextField
                                        label={context.getTranslation("dashboard", "title")}
                                        value={this.props.isOwner ? this.state.changedTitle: this.props.title}
                                        disabled={!this.props.isOwner}
                                        onChange={(event, newValue) => {
                                            if (newValue == "CC")
                                                this.setState({ changedCycle: "Current" });

                                            this.setState({
                                                changedTitle: newValue
                                            });
                                        }}
                                        errorMessage={this.state.changedTitle === '' ? "Set Title" : ""}
                                    />
                                </Stack.Item>

                                <Stack.Item styles={{ root: { padding: '0px' } }}>
                                    <h5>{context.getTranslation("conversationsPage", "owner")}</h5>
                                    <UserPicker
                                        disabled={true} 
                                        user={[{userId: this.props.user}]}
                                        fc={(user) => {
                                            this.setState({
                                                dashboardUsers: user
                                            });
                                        }}
                                    />

                                </Stack.Item>
                                <Stack.Item styles={{ root: { padding: '0px' } }}>
                                    <h5>{context.getTranslation("dashboard", "selectedUser")}</h5>
                                    <UserPicker
                                        user={this.props.isOwner ? this.state.dashboardUsers : (this.props.users == "" ? "" : this.props.users.split(',').map(u => { return { userId: u } }))}
                                        fc={(user) => {
                                            this.setState({
                                                dashboardUsers: user
                                            });
                                        }}
                                        disabled={!this.props.isOwner}
                                    />

                                </Stack.Item>
                            </Stack>
                            <Stack vertical horizontalAlign="center" >
                                <Separator />

                                {this.onRenderFooterContent()}

                            </Stack>

                        </div>

                    </Panel>
                </div>
            )
            }
        </MyContext.Consumer>

        );
    }


    onRenderFooterContent = () => {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Stack horizontal horizontalAlign="center" style={{ gap: "5px" }}>

                        {this.props.isOwner && <Stack.Item>
                            <PrimaryButton onClick={this.savePanel} text={context.getTranslation("common", "save")} disabled={!this.isValid()} />
                        </Stack.Item>}
                        {!this.props.isNew && this.props.isOwner && <Stack.Item>
                            <Button onClick={this.deleteDashboard} text={context.getTranslation("common", "delete")} style={{ backgroundColor: 'red' }} />
                        </Stack.Item>}
                        <Stack.Item>
                            <DefaultButton onClick={this.resetState} text={context.getTranslation("common", "cancel")} />
                        </Stack.Item>
                    </Stack>
                )
                }
            </MyContext.Consumer>
        );
    };

    isValid = () => {
        if (
            (this.state.changedTitle != null && this.state.changedTitle.length > 0)
        ) {

            return true;
        }
        return false;
    }

    showModal = () => {
        this.setState({ isModalOpen: true });
    };

    hidePanel = () => {
        this.resetState();
    };


    deleteDashboard = () => {
        console.log("Dashboard id: ", this.props.id);
        this.props.apol
            .mutate({
                mutation: gql`
                mutation ent ($input: AuthInput, $id : String!) {
                    deleteDashboard (input: $input, id: $id) {
                        id
                    }
                }
            `,
                variables: { id: this.props.id, input: this.props.input },
            })
            .then((result) => {
                this.props.reloadFuncDashboard();

            });
        this.hidePanel();

    }

    savePanel = () => {
        // Collect persons
        var users = "";
        if (this.state.dashboardUsers != null) {
            for (let x = 0; x < this.state.dashboardUsers.length; x++) {
                users += (x > 0 ? "," : "") + this.state.dashboardUsers[x].userId;
            }
        }

        if (!this.props.isNew) {
            this.props.apol
                .mutate({
                    mutation: gql`

                    mutation ent ($input: AuthInput, $id : String!, $title: String, $users: String, $type: String, $tenantId: String) {
                        changeDashboard (input: $input, dashboard: {id: $id, title: $title, users: $users, type: $type, tenantId: $tenantId}) {
                            id
                        }
                    }
                `,

                    variables: {
                        input: this.props.input,
                        id: this.props.id,
                        title: this.state.changedTitle,
                        users: users,
                        tenantId: this.state.tenantId,
                    },
                })
                .then((result) => {
                    if (result && result.data.changeDashboard != null){
                        toast.success("Dashboard changed");
                    } else {
                        toast.error("Couldn't change");
                    }
                    this.props.reloadFuncDashboard();
                });
        } else {


            this.props.apol
                .mutate({
                    mutation: gql`
                    mutation ent ($input: AuthInput, $id: String, $title: String, $users: String, $type: String, $tenantId: String) {
                        createDashboard (input: $input, dashboard: {id: $id, title: $title, users: $users, type: $type, tenantId: $tenantId}) {
                            id
                        }
                    }
                `,
                    variables: {
                        input: this.props.input,
                        id: this.props.id,
                        title: this.state.changedTitle,
                        users: users,
                        tenantId: this.state.tenantId,
                    },
                })
                .then((result) => {
                    toast.success('Dashboard created');
                    this.props.reloadFuncDashboard();

                });
        }
        this.hidePanel();

    };

    resetState = () => {
        if (this.props.isNew) {
            this.setState({
                isPanelOpen: false,
                isModalOpen: false,
                isChanged: false,
                changedTitle: this.props.title,
                changedType: this.props.type,
                tenantId: '',
            });
        } else {
            this.setState({
                isPanelOpen: false,
                isModalOpen: false,
                isChanged: false
            });
        }
    };



}
