import gql from 'graphql-tag';

export const queryPolicyRules = gql`query {
                getPolicyRules {
                    id
                    name
                    description
                    personal
                    createdBy
                    userId
                    tenantId
                    policyRuleGroup {
                        id
                        name
                        generalGroup
                    }
                    areas{
                        searchAreaType
                        connectionType
                        position
                        frequence
                        areaItems{
                            connectionType
                            items{
                                position
                                operatorType
                                value
                                name
                            }
                        }
                    }
                    masterItemId
                }
        }`

export const queryPolicyRuleLabels = gql`query {
                getPolicyRuleLabels {
                    id
                    name
                    description
                    masterItemId
                }
        }`
export const queryAICategorizationLabels = gql`query {
    			getAICategorizations {
                    id
                    name
                }
        }`

export const queryPolicyRule = gql`query ent ( $id: String) {
                getPolicyRule ( id: $id) {
                        id
                        name
                        description
                        personal
                        createdBy
                        userId
                        tenantId
                        policyRuleGroup {
                            id
                            generalGroup
                        }
                        areas{
                            searchAreaType
                            connectionType
                            position
                            frequence
                            areaItems{
                                connectionType
                                items{
                                    position
                                    operatorType
                                    value
                                    name
                                }
                            }
                        }
                        version
                        masterItemId
                        selected
              }
         }`

export const createPolicyRule = gql`mutation ent( $policyRule: PolicyRuleInputType){
                                        createPolicyRule( policyRule: $policyRule) {
                                            text
                                            status
                                            id
            } }`

export const createPolicyRuleTemplate = gql`mutation ent( $policyRule: PolicyRuleInputType){
                                        createPolicyRuleTemplate( policyRule: $policyRule) {
                                            text
                                            status
                                            id
            } }`

export const deletePolicyRule = gql`mutation ent ( $id: String) {
                                        deletePolicyRule ( id: $id)
 {
                                            text
                                            status
                                            id
            }
                                    }`

export const getPatternsDetection = gql`query {
                getPatternsDetection {
                    id
                    name
                    masterItemId
                }
        }`

export const getSearchAreaTypeEnum = gql`query ent {
                                    getSearchAreaTypeEnum
                                    {
                                        name
                                    }
                                }`

export const getConnectionTypeEnum = gql`query ent {
                                    getConnectionTypeEnum
                                    {
                                        name
                                    }
                                }`

export const getAdherencePhrases = gql`query  {
                getAdherencePhrases  {
                        id
                        name
                        masterItemId
                    }
         }`

export const getAIAdherencePhrases = gql`query  {
                getAIAdherencePhrases  {
                        id
                        name
                        masterItemId
                    }
         }`

export const getAIQuestions = gql`query  {
                getAIQuestions  {
                        id
                        name
                        masterItemId
                    }
         }`

export const getAllVersionOfPolicyRule = gql`query ent ($masterItemId: String) {
                                        getAllVersionOfPolicyRule(masterItemId: $masterItemId) {
                                                id
                                                version
                                            }
                                    }`

export const restorePolicyRule = gql`mutation ent ($id: String) {
                                        restorePolicyRule(id: $id) {
                                                id
                                                text
                                                status
                                            }
                                    }`