import React, { Component } from "react";
import { ConversationSafeFolder } from "./ConversationSafeFolder";
import { MyContext } from "../../context";
import RIAccordion from "./Accordion/RIAccordion";
import { ConversationSafeAccordionHeader } from "./Accordion/ConversationSafeAccordionHeader";

export class ConversationSafeList extends Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
    }
    
    propsForExportDialog = {
        client: this.props.apol,
        auth: this.props.auth,
        export: []
    }

    render() {
        return (
            <div>
                <RIAccordion data={
                    this.props.data.map((folder, i) => {
                        return ({
                            header: <ConversationSafeAccordionHeader
                                openPanel={this.props.openPanel}
                                folder={folder}
                                rights={this.props.rights[i]}
                                openDeleteFolderDialog={this.props.openDeleteFolderDialog}
                                userRules={this.props.userRules}
                                propsForExportDialog={this.propsForExportDialog}
                            />,
                            content: <ConversationSafeFolder key={i}
                                value={i}
                                folder={folder}
                                openPanel={this.props.openPanel}
                                auth={this.props.auth}
                                apol={this.props.apol}
                                userRules={this.props.userRules}
                                rights={this.props.rights[i]}
                                theme={this.props.theme}
                                refreshCSList={this.props.refreshCSList}
                            />
                        })
                    })}>
                </RIAccordion>
            </div >
        );
    }
} 