import { DefaultButton, PrimaryButton, Stack, TextField, Text, Dropdown, Spinner, Label, TooltipHost, Checkbox } from 'office-ui-fabric-react';
import { FontIcon, ITooltipProps } from '@fluentui/react/lib/Icon';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { MyContext } from '../../../../context';
import * as QueryAI from '../AIQuestionQuery';
import InputFieldTitle from '../../AdherencePhrases/InputFieldTitle';





export default class EditAIQuestion extends Component {
    static contextType = MyContext

    tooltipProps: ITooltipProps = {
        onRenderContent: () => (
            <div>
                <div>
                    {this.context.getTranslation("compliance", "questionTooltipTitle")}
                </div>
                <div>
                    {this.context.getTranslation("compliance", "questionTooltipSubtitle")}
                </div>
                <ol>
                    <li>{this.context.getTranslation("compliance", "questionTooltipRow1")}</li>
                    <li>{this.context.getTranslation("compliance", "questionTooltipRow2")}</li>
                    <li>{this.context.getTranslation("compliance", "questionTooltipRow3")}</li>
                    <li>{this.context.getTranslation("compliance", "questionTooltipRow4")}</li>
                </ol>
            </div>
        ),
    };

    constructor(props) {
        super(props);
        const aiQuestion = this.props.data !== undefined ? this.props.data : null
        this.state = {
            //page data
            processing: false,
            //phrase data
            id: aiQuestion !== null ? aiQuestion.id : null,
            name: aiQuestion !== null ? aiQuestion.name : "",
            description: aiQuestion !== null ? aiQuestion.description : "",
            question: aiQuestion !== null ? aiQuestion.question : "",
            tenantId: aiQuestion !== null ? aiQuestion.tenantId : null,
            version: aiQuestion !== null ? aiQuestion.version : 0,
            aiQuestionGroup: aiQuestion !== null ? aiQuestion.aiQuestionGroup.id : null,
            generalGroup: aiQuestion !== null ? aiQuestion.aiQuestionGroup.generalGroup : false,
            masterItemId: aiQuestion !== null ? aiQuestion.masterItemId : null,
            selected: aiQuestion !== null ? aiQuestion.selected : true,
            matchAbsence: aiQuestion !== null ? aiQuestion.matchAbsence : false,
            //versions
            selectedOtherVersion: false,
            selectedVersion: aiQuestion !== null ? aiQuestion.id : null
        };
    }






    componentDidMount() {
        this.refreshVersions()
    }

    refetchQueryVersions = null;
    numberOfVersions = 1

    refreshVersions = () => {
        if (this.refetchQueryVersions !== null) {
            this.refetchQueryVersions()
        }
    }

    changeText = (e, value) => {
        this.setState({
            [e.target.id]: value,
            selectedOtherVersion: false
        })
    }

    changeMatchAbsence = (e, value) => {
        this.setState({
            matchAbsence: value,
            selectedOtherVersion: false
        })
    }

    isValid = () => {
        return this.state.name !== "" && this.state.question !== "";
    }

    cancel = () => {
        this.props.closePanel()
    }

    save = (saveAsTemplate) => {
        this.setState({ processing: true })
        const auth = this.props.auth
        const aiQuestion = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            question: this.state.question,
            tenantId: this.state.tenantId,
            // manage version
            version: this.numberOfVersions,
            aiQuestionGroup: { id: this.state.aiQuestionGroup, generalGroup: this.state.generalGroup },
            masterItemId: this.state.masterItemId,
            selected: this.state.selected,
            matchAbsence: this.state.matchAbsence,
        }
        this.props.apol
            .mutate({
                mutation: saveAsTemplate ? QueryAI.createAIQuestionAsTemplate : QueryAI.createAIQuestion,
                variables: { auth, aiQuestion }
            })
            .then((result) => {
                var resultData = saveAsTemplate ? result.data.upsertAIQuestionAsTemplate : result.data.upsertAIQuestion
                var state = {}
                if (resultData.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshAIQuestions()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", resultData.text))
        

                }
                this.setState({ ...state, processing: false })
            });
    }

    onChangeVersion = (e, item) => {
        //get specific version to fill the fields
        this.setState({ processing: true })
        let id = item.key
        let auth = this.props.auth
        this.props.apol
            .query({
                query: QueryAI.queryGetAIQuestion,
                variables: { auth, id }
            })
            .then(({ loading, error, data, refetch }) => {
                let oldVersion = data.getAIQuestion;
                this.setState({
                    name: oldVersion.name,
                    description: oldVersion.description,
                    question: oldVersion.question,
                    tenantId: oldVersion.tenantId,
                    selectedVersion: id,
                    selectedOtherVersion: true,
                    processing: false
                })
            });
    }

    restoreVersion = () => {
        this.setState({ processing: true })
        //restoreAdherencePhrase -> id vesrion to restore
        let id = this.state.selectedVersion
        this.props.apol
            .mutate({
                mutation: QueryAI.restoreAIQuestion,
                variables: { id }
            })
            .then((result) => {
                if (result.data.restoreAIQuestion.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshAIQuestions()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.restoreAIQuestion.text))
                }
                this.setState({
                    selectedOtherVersion: false,
                    processing: false
                })
            });
    }


    render() {
        let masterItemId = this.state.masterItemId
        var saveAsTemplateStyle = { marginTop: "30px", marginLeft: "15px" }
        return (
            <div>
                {/*QUERY ALL VERSIONS*/}
                <Query
                    query={QueryAI.getAllVersionOfAIQuestion}
                    variables={{ masterItemId }}>
                    {({ loading, error, data, refetch }) => {
                        this.refetchQueryVersions = refetch
                        if (loading) {
                            return (<Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />)
                        }
                        if (error) {
                            return ""
                        }
                        if (data.getAllVersionOfAIQuestion == null) {
                            return ""
                        }
                        let versions = data.getAllVersionOfAIQuestion.map(v => {
                            return {
                                "key": v.id,
                                "text": v.version
                            }
                        })
                        this.numberOfVersions = versions.length
                        return (<div>
                            {versions.length > 0 && <Stack >
                                <Stack.Item>
                                    <Dropdown
                                        label={this.context.getTranslation("compliance", "version")}
                                        selectedKey={this.state.selectedVersion}
                                        onChange={this.onChangeVersion}
                                        options={versions}
                                    />
                                </Stack.Item>
                            </Stack>}
                        </div>
                        )
                    }}
                </Query>
                <TextField label={this.context.getTranslation("compliance", "name")} id="name" value={this.state.name} onChange={this.changeText} required />
                <TextField label={this.context.getTranslation("compliance", "description")} id="description" value={this.state.description} onChange={this.changeText} />
                <Stack style={{ marginTop: '10px' }}>
                    <Stack horizontal>
                        <Label required> {this.context.getTranslation("compliance", "question")}</Label>
                        <TooltipHost tooltipProps={this.tooltipProps}>
                            <FontIcon aria-label="Compass" iconName="Info" style={{ color: this.props.theme.palette.black, marginTop: '5px', marginLeft: '5px', cursor: 'pointer' }} />
                        </TooltipHost>
                    </Stack>
                    <TextField id="question" value={this.state.question} onChange={this.changeText} />
                </Stack>
                {/*<div style={{ paddingTop: "5px", paddingBottom: "5px", fontWeight: "600" }}>*/}
                {/*    <span>{this.context.getTranslation("compliance", "complianceQuestionsMatchSectionCaption")}</span>*/}
                {/*</div>*/}

                <Stack style={{ marginTop: "10px" }}>
                    <InputFieldTitle label={this.context.getTranslation("compliance", "complianceQuestionsMatchSectionCaption")} />
                    <Stack.Item>
                        <div style={{ marginTop: "10px" }}>
                            <Checkbox id="matchAbsence" label={this.context.getTranslation("compliance", "complianceQuestionsMatchSectionDescription")} checked={this.state.matchAbsence} onChange={this.changeMatchAbsence} />
                        </div>
                    </Stack.Item>
                </Stack>

                {/*FOOTER BUTTONS*/}
                <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                    <DefaultButton text={this.context.getTranslation("common", "cancel")} onClick={this.cancel} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={this.state.processing} />
                    {(!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion) &&
                        <PrimaryButton text={this.context.getTranslation("common", "save")} onClick={() => this.save(false)} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                    }
                    {(this.props.userRules.userTemplateAllowed && (!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion)) &&
                        <PrimaryButton text={this.context.getTranslation("common", "saveAsTemplate")} onClick={() => this.save(true)} style={!this.isValid() || this.state.processing ? saveAsTemplateStyle : { ...saveAsTemplateStyle, background: "red", color: "white" }} allowDisabledFocus={true} disabled={!this.isValid() || this.state.processing} />
                    }
                    {this.state.selectedOtherVersion && this.state.id !== this.state.selectedVersion &&
                        <PrimaryButton text={this.context.getTranslation("compliance", "restoreVersion")} onClick={this.restoreVersion} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                    }
                </Stack>
            </div>
        );
    }
}