import {
    Checkbox,
    Stack,
    TextField,
    PrimaryButton,
    Dropdown,
    DefaultButton,
    Spinner,
    TooltipHost,
    ChoiceGroup,
    ComboBox, MessageBar, MessageBarButton, MessageBarType
} from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { MyContext } from '../../../../context';
import * as QueryP from '../PolicyQuery';
import * as QueryPR from '../../PolicyRules/PolicyRulesQuery';
import UserPicker from '../../../ReportView/ReportConfiguration/UserPicker';
import { Query } from 'react-apollo';
import _ from 'lodash'
import PolicyCSFolders from "./PolicyCSFolders";
import { queryScorecardsLabels } from '../../AIQualityManagement/AIQualityManagementQuery';


export default class EditPolicy extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        const policy = this.props.data !== undefined ? this.props.data : null
        //policyUsersWOAOC -> users(assignees) from the policy WithOut Add On Compliance license
        const policyUsersWOAOC = policy?.users.filter(u => !u.addOnCompliance).length ?? 0
        const policySupervisorsWOAOC = policy?.supervisors.filter(u => !u.addOnCompliance).length ?? 0
        // selectedUsersAndGroup: set of users and groups'members (no duplicates)
        let selectedUsersAndGroup = policy !== null
            ? [...new Set([
                ...policy.users.map(u => u.userId),
                ...policy.groups.map(g => g?.users.split(',')).flat()
            ])]
            : []
        let metaAssigneeUsersDeleted = policy?.metaAssigneeUsersDeleted ?? []
        let metaUsersThatAreNotInUsersYet = [...metaAssigneeUsersDeleted.filter(metaUser => !selectedUsersAndGroup.find(e => e === metaUser))]
        selectedUsersAndGroup = [...metaUsersThatAreNotInUsersYet, ...selectedUsersAndGroup,]


        let selectedMonitors = policy !== null ? [...policy.supervisors.map(u => u.userId),] : []
        let metaMonitorUsersDeleted = policy?.metaMonitorUsersDeleted ?? []
        let metaMonitorUsersThatAreNotInUsersYet = [...metaMonitorUsersDeleted.filter(metaUser => !selectedMonitors.find(e => e === metaUser))]
        selectedMonitors = [...metaMonitorUsersThatAreNotInUsersYet, ...selectedMonitors,]
        this.state = {
            //user data, information regarding add on compliance, this state is populated by its child userPicker
            userdata: null,
            //page data\
            processing: false,
            processingVersion: false,
            //policy data
            id: policy !== null ? policy.id : null,
            name: policy !== null ? policy.name : "",
            description: policy !== null ? policy.description : "",
            userId: policy !== null ? policy.userId : null,
            tenantId: policy !== null ? policy.tenantId : null,
            channel: policy !== null ? policy.channel : "None",
            riskRating: policy !== null ? policy.riskRating : "None",
            active: policy !== null ? policy.active : false,
            selectedPolicyRule: policy !== null
                ? policy?.basedOn !== "PolicyRule" ?? null
                    ? []
                    : (policy.policyRules[0].masterItemId != null
                        ? policy.policyRules[0].masterItemId
                        : policy.policyRules[0].id)
                : [],
            selectedSupervisors: selectedMonitors,
            selectedUsersAndGroup: selectedUsersAndGroup,
            version: policy !== null ? policy.version : 0,
            masterItemId: policy !== null ? policy.masterItemId : null,
            selected: policy !== null ? policy.selected : true,
            //versions
            selectedOtherVersion: false,
            selectedVersion: policy !== null ? policy.id : null,
            allAssigneeHaveAOC: !metaAssigneeUsersDeleted.length,
            allMonitorsHaveAOC: !metaMonitorUsersDeleted.length,
            //number of users in the picker bordered with red (without AOC), useful for bulk deleting from message Bar
            deleteNoAOCUsersBulk: metaUsersThatAreNotInUsersYet.length + policyUsersWOAOC,
            deleteNoAOCUsersBulkMonitor: metaMonitorUsersThatAreNotInUsersYet.length + policySupervisorsWOAOC,
            // metaAssigneeUsersDeleted: metaAssigneeUsersDeleted,
            metaMonitorUsersDeleted: policy?.metaMonitorUsersDeleted ?? [],
            //initialize policy CS folders
            conversationSafeFolders: policy?.conversationSafeFolders.map(e => e.id) ?? [],
            basedOn: policy?.basedOn ?? "PolicyRule",
            aICategorizationId: policy !== null && policy.aICategorizationInfos !== null && policy.aICategorizationInfos.length > 0 ? policy.aICategorizationInfos[0].aICategorizationId : "",
            AICatOccurrences: policy !== null && policy.aICategorizationInfos !== null && policy.aICategorizationInfos.length > 0 ? policy.aICategorizationInfos[0].occurrences : 0,
            scorecardId: policy !== null && policy.aIScorecardInfos !== null && policy.aIScorecardInfos.length > 0 ? policy.aIScorecardInfos[0].scorecardId : null,
            conversationType: policy !== null && policy.aIScorecardInfos !== null && policy.aIScorecardInfos.length > 0 ? policy.aIScorecardInfos[0].conversationType : [],
        };
        // this.metaAssigneeUsersDeleted = policy.metaAssigneeUsersDeleted
    }

    componentDidMount() {
        this.refreshVersions()
    }

    refetchQueryVersions = null;
    numberOfVersions = 1

    refreshVersions = () => {
        if (this.refetchQueryVersions !== null) {
            this.refetchQueryVersions()
        }
    }

    changeName = (e, value) => {
        this.setState({
            name: value,
            selectedOtherVersion: false,
        })
    }

    changeDescription = (e, value) => {
        this.setState({
            description: value,
            selectedOtherVersion: false,
        })
    }

    onChangeText = () => {
        let channel = ""
        switch (this.state.channel) {
            case "Text": channel = "None"; break;
            case "None": channel = "Text"; break;
            case "Both": channel = "Voice"; break;
            case "Voice": channel = "Both"; break;
        }
        this.setState({
            channel,
            selectedOtherVersion: false,
        })
    }

    onChangeRiskRating = (ev) => {
        let riskRating = ""
        switch (ev.target.id) {
            case "Info": riskRating = "Info"; break;
            case "Adherence": riskRating = "Adherence"; break;
            case "Warning": riskRating = "Warn"; break;
        }
        this.setState({
            riskRating,
            selectedOtherVersion: false,
        })
    }

    onChangeVoice = () => {
        let channel = ""
        switch (this.state.channel) {
            case "Text": channel = "Both"; break;
            case "None": channel = "Voice"; break;
            case "Both": channel = "Text"; break;
            case "Voice": channel = "None"; break;
        }
        this.setState({
            channel,
            selectedOtherVersion: false,
        })
    }

    onChangeActive = () => {
        this.setState({
            active: !this.state.active,
            selectedOtherVersion: false,
        })
    }

    onChangeSelectPolicyRule = (e, item) => {
        this.setState({
            selectedPolicyRule: item.key,
            selectedOtherVersion: false,
        })
    }

    /*Buttons cancel and save*/

    cancel = () => {
        this.props.closePanel()
    }

    save = () => {
        this.setState({ processing: true })
        const auth = this.props.auth
        const policy = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            channel: this.state.channel,
            riskRating: this.state.riskRating,
            active: this.state.active,
            userId: this.state.userId,
            tenantId: this.state.tenantId,
            supervisors: this.state.selectedSupervisors.map(userIdOrGroupId => { return ({ userId: userIdOrGroupId }) }),
            policyRules: this.state.selectedPolicyRule.length ? [
                {
                    id: this.state.selectedPolicyRule
                }
            ] : [],
            //FIXME: for now all users and groups are stored here in users
            users: this.state.selectedUsersAndGroup.map(userIdOrGroupId => ({ userId: userIdOrGroupId })),
            metaAssigneeUsersDeleted: [],
            metaMonitorUsersDeleted: this.state.metaMonitorUsersDeleted,
            //manage version
            version: this.numberOfVersions,
            masterItemId: this.state.masterItemId,
            selected: this.state.selected,
            //managed conversationSafeFolders
            conversationSafeFolders: this.state?.conversationSafeFolders?.map(id => ({ id })) ?? [],
            basedOn: this.state.basedOn,
            aICategorizationInfos: this.state.basedOn === "AICategorization" ? [{
                aICategorizationId: this.state?.aICategorizationId,
                occurrences: parseInt(this.state.AICatOccurrences)
            }] : [],
            aIScorecardInfos: this.state.basedOn === "Scorecard" ? [{
                scorecardId: this.state.scorecardId,
                conversationType: this.state.conversationType
            }] : [],
        }

        this.props.apol
            .mutate({
                mutation: QueryP.createPolicy,
                variables: { auth, policy }
            })
            .then((result) => {
                var state = {}
                if (result.data.createPolicy.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshPolicies()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.createPolicy.text))
                }
                this.setState({ ...state, processing: false })
            });
    }

    //form validation -> disable save button with this method
    isValid = () => {
        const name = this.state.name !== ""
        const assignTo = this.state.selectedUsersAndGroup.length > 0
        const channel = this.state.channel !== "None"
        const riskRating = this.state.riskRating !== ""
        let basedOnCheck = false
        switch (this.state.basedOn) {
            case "PolicyRule": {
                basedOnCheck = this.state.basedOn !== "PolicyRule" ? true : this.state.selectedPolicyRule.length > 0
                break;
            }
            case "AICategorization": {
                const AICatSectionFilled = this.state.basedOn !== "AICategorization" ? true :
                    this.state.aICategorizationId !== "" && (_.inRange(this.state?.AICatOccurrences, 1, 6))
                basedOnCheck = AICatSectionFilled
                break;
            }
            case "Scorecard": {
                basedOnCheck = this.state.scorecardId !== null
                break;
            }
            default: break;
        }

        const allSelectedUsersHaveAOC = this.state.selectedUsersAndGroup.reduce((acc, cur) => {
            return acc &&
                (this.state?.userdata?.find(e => e.id == cur)?.addOnCompliance ?? false)
        }, true)
        const allSelectedMonitorsHaveAOC = this.state.selectedSupervisors.reduce((acc, cur) => {
            return acc &&
                (this.state?.userdata?.find(e => e.id == cur)?.addOnCompliance ?? false)
        }, true)

        return name && assignTo && channel && riskRating && basedOnCheck && allSelectedUsersHaveAOC && allSelectedMonitorsHaveAOC
    }

    onChangeVersion = (e, item) => {
        //get specific version to fill the fields
        this.setState({ processingVersion: true })
        let id = item.key
        let auth = this.props.auth
        this.props.apol
            .query({
                query: QueryP.queryPolicy,
                variables: { auth, id }
            })
            .then(({ loading, error, data, refetch }) => {
                let oldVersion = data.getPolicy;
                this.setState({
                    name: oldVersion.name,
                    description: oldVersion.description,
                    userId: oldVersion.userId,
                    channel: oldVersion.channel,
                    riskRating: oldVersion.riskRating,
                    active: oldVersion.active,
                    selectedPolicyRule: oldVersion !== null
                        ? oldVersion?.basedOn !== "PolicyRule" ?? null
                            ? []
                            : (oldVersion.policyRules[0].masterItemId != null
                                ? oldVersion.policyRules[0].masterItemId
                                : oldVersion.policyRules[0].id)
                        : [],
                    selectedSupervisors: oldVersion.supervisors.map(u => u.userId),
                    selectedUsersAndGroup: [...oldVersion.users.map(u => u.userId), ...oldVersion.groups.map(g => g.id)],
                    tenantId: oldVersion.tenantId,
                    selectedVersion: id,
                    selectedOtherVersion: true,
                    processingVersion: false,
                    metaAssigneeUsersDeleted: oldVersion.metaMonitorUsersDeleted,
                    metaMonitorUsersDeleted: oldVersion.metaMonitorUsersDeleted,
                    conversationSafeFolders: oldVersion?.conversationSafeFolders.map(e => e.id) ?? [],
                    basedOn: oldVersion.basedOn,
                    aICategorizationId: oldVersion.aICategorizationInfos !== null && oldVersion.aICategorizationInfos.length > 0 ? oldVersion.aICategorizationInfos[0].aICategorizationId : "",
                    AICatOccurrences: oldVersion.aICategorizationInfos !== null && oldVersion.aICategorizationInfos.length > 0 ? oldVersion.aICategorizationInfos[0].occurrences : 0,
                    scorecardId: oldVersion.aIScorecardInfos !== null && oldVersion.aIScorecardInfos.length > 0 ? oldVersion.aIScorecardInfos[0].scorecardId : null,
                    conversationType: oldVersion.aIScorecardInfos !== null && oldVersion.aIScorecardInfos.length > 0 ? oldVersion.aIScorecardInfos[0].conversationType : [],

                })
            });
    }

    onChangePolicyFolders = (newSelection) => {
        this.setState({ conversationSafeFolders: newSelection, selectedOtherVersion: false })
    }

    restoreVersion = () => {
        this.setState({ processing: true })
        //restorePolicy -> id vesrion to restore
        let id = this.state.selectedVersion
        this.props.apol
            .mutate({
                mutation: QueryP.restorePolicy,
                variables: { id }
            })
            .then((result) => {
                if (result.data.restorePolicy.status === "ok") {
                    this.props.closePanel()
                    this.props.refreshPolicies()
                } else {
                    this.props.setMessageError(this.context.getTranslation("compliance", result.data.restorePolicy.text))
                }
                this.setState({
                    selectedOtherVersion: false,
                    processing: false
                })
            });
    }

    basedOnOpt = () => {
        var opt =
            [
                {
                    key: 'PolicyRule',
                    text: this.context.getTranslation("compliance", "policyRuleCheck"),
                    styles: { field: { fontWeight: '400' } }
                },
                {
                    key: 'AICategorization',
                    text: this.context.getTranslation("compliance", "autocatagorizationAI"),
                    styles: { field: { fontWeight: '400' } }
                }
            ]
        if (this.props.userRules.addOnQualityManagement && this.props.userRules.ruleAccess.userComplianceQM) {
            opt = [...opt,
            {
                key: 'Scorecard',
                text: this.context.getTranslation("compliance", "scorecard"),
                styles: { field: { fontWeight: '400' } }
            }]
        }

        return opt
    }



    render() {
        const numericalSpacingStackTokens: IStackTokens = {
            childrenGap: 15
        };
        return (<div>
            {
                this.state.processingVersion && <div>
                    <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                </div>
            }
            {!this.state.processingVersion && <div>
                {/*QUERY ALL VERSIONS*/}
                {this.state.masterItemId !== null && <Query
                    query={QueryP.getAllVersionOfPolicy}
                    variables={{ masterItemId: this.state.masterItemId }}>
                    {({ loading, error, data, refetch }) => {
                        this.refetchQueryVersions = refetch
                        if (loading) {
                            return (<Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />)
                        }
                        if (error) {
                            return ""
                        }
                        if (data.getAllVersionOfPolicy == null) {
                            return ""
                        }
                        let versions = data.getAllVersionOfPolicy.map(v => {
                            return {
                                "key": v.id,
                                "text": v.version
                            }
                        })
                        this.numberOfVersions = versions.length
                        return (<div>
                            {versions.length > 0 && <Stack >
                                <Stack.Item>
                                    <Dropdown
                                        label={this.context.getTranslation("compliance", "version")}
                                        selectedKey={this.state.selectedVersion}
                                        onChange={this.onChangeVersion}
                                        options={versions}
                                    />
                                </Stack.Item>
                            </Stack>}
                        </div>
                        )
                    }}
                </Query>}
                <TextField label={this.context.getTranslation("compliance", "name")} value={this.state.name} onChange={this.changeName} required />
                <TextField label={this.context.getTranslation("compliance", "description")} value={this.state.description} onChange={this.changeDescription} />
                {/*BASED ON*/}
                <h5>{this.context.getTranslation("compliance", "basedOn")}</h5>
                <Stack>
                    <ChoiceGroup
                        style={{ marginBottom: "10px" }}
                        selectedKey={this.state.basedOn}
                        options={this.basedOnOpt()}
                        onChange={(e, option) => this.setState({
                            basedOn: option.key,
                            riskRating: option.key === 'Scorecard' ? "None" : this.state.riskRating,
                            selectedOtherVersion: false,
                        })}
                    />
                    {/*BASED ON POLICY RULE*/}
                    {this.state.basedOn === "PolicyRule" &&
                        <Query query={QueryPR.queryPolicyRuleLabels} variables={{}}>
                            {({ loading, error, data }) => {
                                if (loading) return (<Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />);
                                if (error) return <h4>Failed to connect</h4>;
                                if (data.getPolicyRuleLabels === null) return <div />;
                                const availablePolicyRules = data.getPolicyRuleLabels.map(pr => {
                                    return { key: pr.masterItemId, text: pr.name }
                                })
                                return (
                                    <Dropdown
                                        placeholder={this.context.getTranslation("compliance", "selectPolicyRule")}
                                        selectedKey={this.state.selectedPolicyRule}
                                        onChange={this.onChangeSelectPolicyRule}
                                        options={availablePolicyRules}
                                    />
                                )
                            }}
                        </Query>
                    }
                    {/*BASED ON CATEGORIZATION*/}
                    {this.state.basedOn === "AICategorization" &&
                        <Query
                            query={QueryPR.queryAICategorizationLabels}
                            variables={{}}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return (<Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />);
                                if (error) return <h4>Failed to connect</h4>;
                                if (data.getAICategorizations == null) return <div />;
                                return <Stack horizontal>
                                    <Stack.Item grow={3}>
                                        <ComboBox
                                            placeholder={this.context.getTranslation("compliance", "selectAICategorization")}
                                            style={{ marginRight: 15 }}
                                            // label={this.context.getTranslation("compliance", "AICategorization")}
                                            selectedKey={this.state.aICategorizationId ?? undefined}
                                            allowFreeform={false}
                                            onChange={(e, i) => {
                                                this.setState({
                                                    aICategorizationId: i.key,
                                                    selectedOtherVersion: false,
                                                })
                                            }}
                                            autoComplete={true}
                                            options={data.getAICategorizations.map(e => ({ text: e.name, key: e.id }))}
                                        />
                                    </Stack.Item>
                                    <Stack.Item align="center" style={{ marginRight: 12 }}>
                                        {this.context.getTranslation("compliance", "max#")}
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TooltipHost content={this.context.getTranslation("compliance", "max#Tooltip")}>
                                            <TextField
                                                id="AICategorizationNumber"
                                                value={this.state?.AICatOccurrences ?? 0}
                                                type="Number"
                                                min={0}
                                                max={5}
                                                errorMessage={(this.state.date < 7) ? "Minimum 7 days allowed" : ""}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        AICatOccurrences: newValue,
                                                        selectedOtherVersion: false,
                                                    });
                                                }}
                                            />
                                        </TooltipHost>
                                    </Stack.Item>
                                </Stack>
                            }}
                        </Query>
                    }
                    {/*BASED ON SCORECARD - TO DO*/}
                    {this.state.basedOn === "Scorecard" &&
                        <Query
                            query={queryScorecardsLabels}
                            variables={{}}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return (<Spinner label="Still loading..." ariaLive="assertive" labelPosition="top" />);
                                if (error) return <h4>Failed to connect</h4>;
                                if (data.getScorecards == null) return <div />;
                                const availableScorecards = data.getScorecards.map(s => {
                                    return { key: s.masterItemId, text: s.name }
                                })
                                return <div>
                                    <ComboBox
                                        placeholder={this.context.getTranslation("compliance", "selectAIScorecard")}
                                        selectedKey={this.state.scorecardId}
                                        allowFreeform={false}
                                        onChange={(e, i) => {
                                            this.setState({
                                                scorecardId: i.key,
                                                selectedOtherVersion: false,
                                            })
                                        }}
                                        autoComplete={true}
                                        options={availableScorecards}
                                    />
                                    <Dropdown
                                        multiSelect
                                        selectedKeys={this.state.conversationType}
                                        options={[
                                            { key: 'Inbound', text: this.context.getTranslation("filterPanel", "inbound") },
                                            { key: 'Outbound', text: this.context.getTranslation("filterPanel", "outbound") },
                                            { key: 'Meeting', text: this.context.getTranslation("filterPanel", "meetings") },
                                            { key: 'Internal', text: this.context.getTranslation("filterPanel", "internal") },
                                            //{ key: 'External', text: this.context.getTranslation("filterPanel", "external") },
                                            { key: 'Chat', text: this.context.getTranslation("configuration", "chat") },
                                            //key: 'Other', text: this.context.getTranslation("filterPanel", "upload") }
                                        ]}
                                        onChange={(event, item) => {
                                            if (item) {
                                                this.setState({
                                                    conversationType: item.selected ? [...this.state.conversationType, item.key] : this.state.conversationType.filter(key => key !== item.key),
                                                    selectedOtherVersion: false,
                                                });
                                            }
                                        }}
                                        autoComplete={true}
                                        allowFreeform={false}
                                        label={this.context.getTranslation("conversationsPage", "conversationType")}
                                        placeholder={this.context.getTranslation("conversationsPage", "selectConversationType")}
                                    />
                                </div>
                            }}
                        </Query>



                    }
                </Stack>
                {/*CHANNEL*/}
                <h5>{this.context.getTranslation("compliance", "detailPolicyChannel")}</h5>
                <Stack style={{ marginTop: "10px" }} horizontal tokens={numericalSpacingStackTokens}>
                    <Checkbox label={this.context.getTranslation("compliance", "text")} checked={this.state.channel === "Text" || this.state.channel === "Both"} onChange={this.onChangeText} />
                    <Checkbox label={this.context.getTranslation("compliance", "voice")} checked={this.state.channel === "Voice" || this.state.channel === "Both"} onChange={this.onChangeVoice} />
                </Stack>
                {/*Policy Workflow*/}
                {this.props.userRules.ruleAccess.userCompliancePolicyInitiateWorkflow && this.state.basedOn !== "Scorecard" &&
                    <div>
                        <h5>{this.context.getTranslation("compliance", "detailPolicyFoldersTitle")}</h5>
                        <Stack style={{ marginTop: "-8px" }}>
                            <PolicyCSFolders
                                apol={this.props.apol}
                                selectedFolders={this.state?.conversationSafeFolders ?? []}
                                onChangePolicyFolders={this.onChangePolicyFolders} />
                        </Stack>
                    </div>
                }
                {/*RISK RATING*/}
                <h5 style={this.state.basedOn === "Scorecard" ? { color: "#595959" } : {}}>
                    {this.context.getTranslation("compliance", "detailPolicyRiskRating")}
                </h5>
                <Stack style={{ marginTop: "10px" }} horizontal tokens={numericalSpacingStackTokens}>
                    <Checkbox
                        label={this.context.getTranslation("compliance", "riskRatingInformation")}
                        id={"Info"}
                        checked={this.state.riskRating === "Info"}
                        onChange={this.onChangeRiskRating}
                        disabled={this.state.basedOn === "Scorecard"}
                    />
                    <Checkbox
                        label={this.context.getTranslation("compliance", "riskRatingAdherence")}
                        id={"Adherence"}
                        checked={this.state.riskRating === "Adherence"}
                        onChange={this.onChangeRiskRating}
                        disabled={this.state.basedOn === "Scorecard"}
                    />
                    <Checkbox
                        label={this.context.getTranslation("compliance", "riskRatingWarning")}
                        id={"Warning"}
                        checked={this.state.riskRating === "Warn"}
                        onChange={this.onChangeRiskRating}
                        disabled={this.state.basedOn === "Scorecard"}
                    />
                </Stack>
                {/*ASSIGN TO*/}
                <h5>{this.context.getTranslation("compliance", "detailPolicyUsers")}</h5>
                {/*//ADD HERE NOTICE*/}
                {
                    !this.state.selectedUsersAndGroup.reduce((acc, cur) => {
                        return acc && (this.state?.userdata?.find(e => e.id == cur)?.addOnCompliance ?? false)
                    }, true)
                    && <div style={{ marginBottom: 5 }}>
                        <MessageBar
                            messageBarType={MessageBarType.blocked}
                            actions={<div>
                                <MessageBarButton onClick={
                                    () => {
                                        const noOfUsersWOAOC = this.state.selectedUsersAndGroup.reduce((acc, cur) => {
                                            return acc + ((this.state?.userdata?.find(e => e.id == cur)?.addOnCompliance ?? false) ? 0 : 1)

                                        }, 0)
                                        this.setState({
                                            selectedUsersAndGroup: this.state.selectedUsersAndGroup.slice(noOfUsersWOAOC),
                                            allAssigneeHaveAOC: true,
                                            allUsersHaveAOC: this.state.allMonitorsHaveAOC
                                        })
                                    }
                                }>{this.context.getTranslation('compliance', 'removeUsersWithoutAOCActionLabel')}</MessageBarButton>
                            </div>}>
                            {this.context.getTranslation('compliance', 'removeUsersWithoutAOCNotice')}

                        </MessageBar>
                    </div>}
                <UserPicker
                    editPolicy={true}
                    passUserDataToParent={({ userdata }) => this.setState({ userdata })}
                    userdata={this.state.userdata}
                    placeholder={this.context.getTranslation("compliance", "selectUserOrGroup")}
                    user={(() => {
                        // add elements from metaAssign to selectedUsersAndGroup only those who are not yet present,
                        // we do so by finding metaUsers in selectedUsersAndGroup, then we filter.

                        // let metaUsersThatAreNotInUsersYet = [...this.state.metaAssigneeUsersDeleted.filter(metaUser => !this.state.selectedUsersAndGroup.find(e=>e.userId===metaUser))]
                        return [...this.state.selectedUsersAndGroup.map(user => ({ "userId": user }))]
                    })()}
                    fc={(users, peopleList) => {
                        let usersFromPicker = [...users]

                        // let usersFromMetaAssigneeThatRemain = this.metaAssigneeUsersDeleted.filter(metaUser => {
                        //     return usersFromPicker.find(ufp=>ufp.userId===metaUser)
                        // })
                        //
                        let groupIdIndexToBeDeleted = 0
                        for (let i = 0; i < users.length; i++) {
                            if (usersFromPicker[i].members) {
                                groupIdIndexToBeDeleted = i
                                let groupMembers = users[i].members.split(',')
                                for (let member = 0; member < groupMembers.length; member++) {
                                    if (!this.state.selectedUsersAndGroup.includes(groupMembers[member])) {
                                        let memberWithAddOnComplianceFromPeopleList = peopleList.find(pl => pl.userId == groupMembers[member])
                                        usersFromPicker.push({ userId: groupMembers[member], addOnCompliance: memberWithAddOnComplianceFromPeopleList.addOnCompliance })

                                    }
                                }
                            }
                        }
                        //delete group element from users list
                        if (usersFromPicker.length && usersFromPicker[groupIdIndexToBeDeleted].members) usersFromPicker.splice(groupIdIndexToBeDeleted, 1)
                        let newUsersForPicker = usersFromPicker.sort((a, b) => {
                            if (a.addOnCompliance && !b.addOnCompliance) return 1
                            else if (a.addOnCompliance && b.addOnCompliance) return 0
                            else return -1
                        }).map(u => u.userId)
                        // let metaUsersThatAreNotInUsersYet = [...this.state.metaAssigneeUsersDeleted.filter(metaUser => !newUsersForPicker.find(e=>e===metaUser))]

                        this.setState({
                            //all users in assignee list have add on compliance
                            deleteNoAOCUsersBulk: usersFromPicker.filter(u => !u.addOnCompliance).length,
                            allAssigneeHaveAOC: !(usersFromPicker.filter(u => !u.addOnCompliance).length),
                            selectedUsersAndGroup: [...newUsersForPicker,],
                            selectedOtherVersion: false,
                            // metaAssigneeUsersDeleted: usersFromMetaAssigneeThatRemain

                        });
                    }}
                />
                {/*MONITORED BY*/}
                <h5>{this.context.getTranslation("compliance", "detailPolicySupervisor")}</h5>
                {!this.state.allMonitorsHaveAOC && <div style={{ marginBottom: 5 }}>
                    <MessageBar
                        messageBarType={MessageBarType.blocked}
                        actions={<div>
                            <MessageBarButton onClick={
                                () => {
                                    this.setState({
                                        selectedSupervisors: this.state.selectedSupervisors.slice(this.state.deleteNoAOCUsersBulkMonitor),
                                        allMonitorsHaveAOC: true,
                                    })
                                }
                            }>{this.context.getTranslation('compliance', 'removeUsersWithoutAOCActionLabel')}</MessageBarButton>
                        </div>}>
                        {this.context.getTranslation('compliance', 'removeUsersWithoutAOCNotice')}

                    </MessageBar>
                </div>}

                <UserPicker
                    editPolicy={true}
                    placeholder={this.context.getTranslation("compliance", "selectUserOrGroup")}
                    user={this.state.selectedSupervisors.map(user => {
                        return ({
                            "userId": user
                        })
                    })}
                    fc={(users, peopleList) => {
                        let usersFromPicker = [...users]
                        let groupIdIndexToBeDeleted = 0
                        for (let i = 0; i < users.length; i++) {
                            if (usersFromPicker[i].members) {
                                groupIdIndexToBeDeleted = i
                                let groupMembers = users[i].members.split(',')
                                for (let member = 0; member < groupMembers.length; member++) {
                                    if (!this.state.selectedUsersAndGroup.includes(groupMembers[member])) {
                                        let memberWithAddOnComplianceFromPeopleList = peopleList.find(pl => pl.userId == groupMembers[member])
                                        usersFromPicker.push({ userId: groupMembers[member], addOnCompliance: memberWithAddOnComplianceFromPeopleList.addOnCompliance })

                                    }
                                }
                            }
                        }
                        if (usersFromPicker.length && usersFromPicker[groupIdIndexToBeDeleted].members) usersFromPicker.splice(groupIdIndexToBeDeleted, 1)
                        let newUsersForPicker = usersFromPicker.sort((a, b) => {
                            if (a.addOnCompliance && !b.addOnCompliance) return 1
                            else if (a.addOnCompliance && b.addOnCompliance) return 0
                            else return -1
                        }).map(u => u.userId)
                        this.setState({
                            //all users in assignee list have add on compliance
                            deleteNoAOCUsersBulkMonitor: usersFromPicker.filter(u => !u.addOnCompliance).length,
                            allMonitorsHaveAOC: !(usersFromPicker.filter(u => !u.addOnCompliance).length),
                            selectedSupervisors: [...newUsersForPicker,],
                            selectedOtherVersion: false,
                            // metaAssigneeUsersDeleted: usersFromMetaAssigneeThatRemain

                        })
                    }}
                />
                {/*ACTIVE*/}
                <Stack style={{ marginTop: "20px" }} horizontal>
                    <Checkbox label={this.context.getTranslation("compliance", "detailPolicyActive")} checked={this.state.active} onChange={this.onChangeActive} disabled={!this.props.userRules.ruleAccess.userCompliancePolicyActivate} />
                </Stack>
                {/*FOOTER BUTTONS*/}
                <Stack horizontal style={{ flexFlow: "row-reverse" }}>
                    <DefaultButton text={this.context.getTranslation("common", "cancel")} onClick={this.cancel} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={this.state.processing} />
                    {(!this.state.selectedOtherVersion || this.state.id === this.state.selectedVersion) &&
                        <PrimaryButton text={this.context.getTranslation("common", "save")} onClick={this.save} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                    }
                    {this.state.selectedOtherVersion && this.state.id !== this.state.selectedVersion &&
                        <PrimaryButton text={this.context.getTranslation("compliance", "restoreVersion")} onClick={this.restoreVersion} style={{ marginTop: "30px", marginLeft: "15px" }} disabled={!this.isValid() || this.state.processing} />
                    }
                </Stack>
            </div>
            }
        </div>
        )
    }


}
