import React from "react";
import {DetailsList, Selection} from "office-ui-fabric-react";

class ASCDetailsList extends React.Component {
  _selection = new Selection({
    onSelectionChanged: (item) => console.log(item, "all items selectable"),
  });
  _selectionOnlyFavorite = new Selection({
    canSelectItem: (e, i) => !e.priority,
    onSelectionChanged: () => console.log("only favorites"),
  });

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props?.selectionAfterMergeContainerIsRenamed?.forEach(e=>{

      this.props.selection.setKeySelected(e.id, true, false)
    }) ?? null
  }

  render() {
    return (
      <div>
        {this.props.selection.label == "favoritesOnly" ? (
          <DetailsList {...this.props} />
        ) : null}
        {this.props.selection.label == "notIgnoredOnly" ? (
          <DetailsList {...this.props} />
        ) : null}
        {this.props.selection.label == "all" ? (
          <DetailsList
            {...this.props}
            // selection={this._selection}
          />
        ) : null}
      </div>
    );
  }
}

export default ASCDetailsList