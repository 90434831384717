import { MessageBar, MessageBarType, Spinner } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { MyContext } from '../../../../context';
import EditPolicyRule from './EditPolicyRule'
import * as QueryPR from '../PolicyRulesQuery';

export default class EditPolicyRuleContainers extends Component {

    static contextType = MyContext

    render() {
        const { auth } = this.props
        const id = this.props.policyRuleId
        return (
            <div>
                {/*edit policy*/}
                {
                    id != null && (
                        <Query
                            query={QueryPR.queryPolicyRule}
                            variables={{ auth, id }}>
                            {({ loading, error, data }) => {
                                if (loading) {
                                    return (
                                        <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />
                                    )
                                }
                                if (error) {
                                    return (
                                        <MessageBar
                                            messageBarType={MessageBarType.error}
                                            isMultiline={false}
                                            dismissButtonAriaLabel="Close"
                                        >
                                            {this.context.getTranslation("compliance", "error")}
                                        </MessageBar>
                                    )
                                }
                                if (data.getPolicyRule == null) {
                                    return (<MessageBar>
                                        {this.context.getTranslation("compliance", "noDataPolicyRule")}
                                    </MessageBar>)
                                }
                                return (<div>
                                    <EditPolicyRule
                                        data={data.getPolicyRule}
                                        closePanel={this.props.closePanel}
                                        apol={this.props.apol}
                                        auth={this.props.auth}
                                        userRules={this.props.userRules}
                                        refreshPolicyRules={this.props.refreshPolicyRules}
                                        setMessageError={this.props.setMessageError}
                                        ruleAccess={this.props.ruleAccess}
                                    />
                                </div>
                                )
                            }}
                        </Query>
                    )
                }
                {/*create policy*/}
                {
                    id === null && (
                        <EditPolicyRule
                            closePanel={this.props.closePanel}
                            apol={this.props.apol}
                            auth={this.props.auth}
                            userRules={this.props.userRules}
                            refreshPolicyRules={this.props.refreshPolicyRules}
                            setMessageError={this.props.setMessageError}
                            ruleAccess={this.props.ruleAccess}
                        />
                    )
                }
            </div>
        )
    }
}