import React, { Component } from 'react';

//APPOLO + GQL
import { ApolloProvider, Query } from "react-apollo";
import { queryGetConfigData, queryGetRuleRecording, queryGetRuleReplay, queryGetRuleAccess, queryGetRuleAnalytics } from "../../queries/querySettings";

//OFFICE Fabric
import { Customizer, getId } from '@uifabric/utilities';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PivotItem, Pivot } from 'office-ui-fabric-react/lib/Pivot';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';

//Styles

import './Setter.scss';
//OTHER COMPONENS
import RuleList from './RuleList/RuleList'
import UserList from "./UserList/UserList";
import GroupList from "./UserList/GroupList";
import UserConfigContent from "./UserList/UserConfigContent";
import GroupConfigContent from "./UserList/GroupConfigContent";
import { ToastContainer } from "react-toastify";
import AddRule from "./RuleList/AddRule";
import AddUser from "./UserList/AddUser";
import AddGroup from "./UserList/AddGroup";
import ImportConfig from "./UserList/ImportConfig";
import ExportConfig from "./UserList/ExportConfig";
import IntegrationConfig from "./UserList/IntegrationConfig";
import ReportConfiguration from "../ReportView/ReportConfiguration/ReportConfiguration";
import DepartmentConfigContent from "./UserList/DepartmentConfigContent";
import CategoryList from "./RuleList/CategoryList";
import AddCategorie from "./RuleList/AddCategorie";
import JobList from "./RuleList/JobList";
import { MyContext } from '../../context';
import Utils from '../common/Utils';
import FieldsList from './RuleList/FieldsList';
import AddCustomField from './RuleList/AddCustomField';
import AlertBar from '../CustomComponents/AlertBar';
import { Dropdown } from '@fluentui/react';



export default class Setter extends Component {

    state = {
        showPanel: false,
        trapPanel: false,
        dropdownPivot: "users"

    };
    _layerHostId: string = getId('layerHost');

    // This is based on the definition of items
    render() {
        return (
            <div>
                <div className={window.location.pathname.includes("main") ? "setter-content-main" : "setter-content"}>
                    <div className="ms-Grid">

                        <div className="ms-Grid-row">

                            <Customizer
                                scopedSettings={
                                    this.state.trapPanel
                                        ? {
                                            Layer: {
                                                hostId: this._layerHostId
                                            }
                                        }
                                        : {}
                                }
                            >
                                {this.state.showPanel && (
                                    <MyContext.Consumer>
                                        {(context) => (
                                            <Panel
                                                type={PanelType.medium}
                                                isOpen={true}
                                                hasCloseButton={true}
                                                headerText={context.getTranslation("configuration", this.configurationTextSelect())}
                                                focusTrapZoneProps={{
                                                    isClickableOutsideFocusTrap: true,
                                                    forceFocusInsideTrap: false
                                                }}
                                                onDismiss={this._onDismissPanel}>
                                                {this.panelSelect()}
                                            </Panel>)}
                                    </MyContext.Consumer>

                                )}
                            </Customizer>


                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                <ApolloProvider client={this.props.apol}>
                                    {this.query(this._show, this.props.authorization, this.props.apol, this.props.userRules)}

                                </ApolloProvider>

                            </div>
                        </div>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }

    _onDismissPanel = (): void => {
        this.setState({
            showPanel: false
        });
    };

    rules = [];

    _show = (current, vall) => {
        switch (vall) {
            case undefined:
            case null:
                break;
            default:
                if (current == null) {
                    this.setState({ showPanel: true, case: vall, info: null, isEdit: false });

                }
                else {
                    switch (current.length) {
                        case undefined:
                            this.setState({ showPanel: true, case: vall, info: [current], isEdit: true });
                            break;
                        default:
                            this.setState({ showPanel: true, case: vall, info: current, isEdit: true });
                            break;
                    }
                }
        }
    }

    query = (layerFunction, auth, apol, userRules) => {

        return <MyContext.Consumer>
            {(context) => (
                <Query
                    query={queryGetConfigData}
                    variables={{ auth }} >
                    {({ loading, error, data, refetch }) => {
                        if (loading) return <div>
                            <Spinner classname="setter-content" label={context.getTranslation("common", "loadingData")} ariaLive="assertive" labelPosition="top" />
                        </div>;
                        if (error) {
                            console.log(error)
                            return (<AlertBar message={"Error!"} alertType={"error"} />);
                        }
                        if (data.getConfigData == null) {
                            return (<AlertBar message={context.getTranslation("compliance", "noData")} bonTon alertType={"warning"} />);
                        }

                        this.rules = data.getConfigData.ruledata;
                        this.users = data.getConfigData.userdata;
                        this.groups = data.getConfigData.groupdata;

                        //RI-7789 If merge conflict are in place. Take this code instead code in branch RI-7789
                        this.categorydata = data.getConfigData.categorydata != null ? data.getConfigData.categorydata.filter(categoryData => {
                            return categoryData.categoryGroup.tenantId != null && !categoryData.categoryGroup.generalGroup
                        }) : null;
                        //
                        this.integrationdata = data.getConfigData.integrationdata;
                        this.tenantdata = data.getConfigData.tenant;
                        this.customfieldsdata = data.getConfigData.customfieldsdata;
                        // Sum export and import jobs
                        let countJobs = 0;
                        countJobs += data.getConfigData.importdata != null ? data.getConfigData.importdata.length : 0;
                        countJobs += data.getConfigData.exportdata != null ? data.getConfigData.exportdata.length : 0;
                        countJobs += data.getConfigData.integrationdata != null ? data.getConfigData.integrationdata.length : 0;

                        //get options for tabs dropdown, only for small screen view
                        let options = [
                            { key: "users", text: context.getTranslation("configuration", "users") + " (" + (data.getConfigData.userdata != null ? data.getConfigData.userdata.length : 0) + ")" },
                            { key: "rules", text: context.getTranslation("configuration", "rules") + " (" + (data.getConfigData.ruledata != null ? data.getConfigData.ruledata.length : 0) + ")" },
                            { key: "categories", text: context.getTranslation("configuration", "categories") + " (" + (data.getConfigData.categorydata != null ? data.getConfigData.categorydata.length : 0) + ")" }
                        ];
                        if (this.props.userRules.ruleAccess.groupFeature) {
                            options.push({ key: "groups", text: context.getTranslation("configuration", "groups") + " (" + (data.getConfigData.groups != null ? data.getConfigData.groups.length : 0) + ")" })
                        }
                        if (this.props.userRules.ruleAccess.confJobs) {
                            options.push({ key: "jobs", text: context.getTranslation("configuration", "jobs") + " (" + countJobs + ")" })
                        }
                        if (this.props.userRules.ruleAccess.confCustomFields) {
                            options.push({ key: "fields", text: context.getTranslation("configuration", "fields") + " (" + (data.getConfigData.customfieldsdata != null ? data.getConfigData.customfieldsdata.length : 0) + ")" })
                        }

                        return <>
                            {window.innerWidth < 700
                                ? <>
                                    <Dropdown
                                        selectedKey={this.state.dropdownPivot}
                                        onChange={(e, newValue) => { this.setState({ dropdownPivot: newValue.key }) }}
                                        options={options}
                                    />
                                    {
                                        {
                                            'users': <UserList key="01" icon={"PlayerSettings"} data={data.getConfigData.userdata} ruleData={data.getConfigData.ruledata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                                            ,
                                            'rules': <RuleList key="03" icon={"Shield"} theme={this.props.theme} data={data.getConfigData.ruledata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                                            ,
                                            'categories': <CategoryList key="04" icon={"AnalyticsView"} data={data.getConfigData.categorydata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} fromSetterPage={true} />
                                            ,
                                            'groups': <GroupList key="05" icon={"PlayerSettings"} data={data.getConfigData.groupdata} ruleData={data.getConfigData.ruledata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                                            ,
                                            'jobs': <JobList key="06" icon={"GoToDashboard"} dataExport={data.getConfigData.exportdata} dataImport={data.getConfigData.importdata} dataIntegration={data.getConfigData.integrationdata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                                            ,
                                            'fields': <FieldsList key="07" icon={"ClipboardList"} data={data.getConfigData.customfieldsdata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />

                                        }[this.state.dropdownPivot]
                                    }
                                </>
                                : <Pivot>
                                    <PivotItem key="01" linkText={context.getTranslation("configuration", "users")} itemCount={data.getConfigData.userdata != null ? data.getConfigData.userdata.length : 0} itemIcon="PlayerSettings">
                                        <UserList key="01" icon={"PlayerSettings"} data={data.getConfigData.userdata} ruleData={data.getConfigData.ruledata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                                    </PivotItem>
                                    <PivotItem key="03" linkText={context.getTranslation("configuration", "rules")} itemCount={data.getConfigData.ruledata != null ? data.getConfigData.ruledata.length : 0} itemIcon="Shield">
                                        <RuleList key="03" icon={"Shield"} theme={this.props.theme} data={data.getConfigData.ruledata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                                    </PivotItem>
                                    <PivotItem key="04" linkText={context.getTranslation("configuration", "categories")} itemCount={this.categorydata != null ? this.categorydata.length : 0} itemIcon="AnalyticsView">
                                        <CategoryList key="04" icon={"AnalyticsView"} data={this.categorydata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} fromSetterPage={true} />
                                    </PivotItem>
                                    {this.props.userRules.ruleAccess.groupFeature && <PivotItem key="05" linkText={context.getTranslation("configuration", "groups")} itemCount={data.getConfigData.groupdata != null ? data.getConfigData.groupdata.length : 0} itemIcon="PlayerSettings">
                                        <GroupList key="05" icon={"PlayerSettings"} data={data.getConfigData.groupdata} ruleData={data.getConfigData.ruledata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                                    </PivotItem>}
                                    {this.props.userRules.ruleAccess.confJobs && <PivotItem key="06" linkText={context.getTranslation("configuration", "jobs")} itemCount={countJobs} itemIcon="GoToDashboard">
                                        <JobList key="06" icon={"GoToDashboard"} dataExport={data.getConfigData.exportdata} dataImport={data.getConfigData.importdata} dataIntegration={data.getConfigData.integrationdata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                                    </PivotItem>}
                                    {this.props.userRules.ruleAccess.confCustomFields && <PivotItem key="07" linkText={context.getTranslation("configuration", "fields")} itemCount={data.getConfigData.customfieldsdata != null ? data.getConfigData.customfieldsdata.length : 0} itemIcon="ClipboardList">
                                        <FieldsList key="07" icon={"ClipboardList"} data={data.getConfigData.customfieldsdata} tenantData={data.getConfigData.tenant} func={layerFunction} close={this._onDismissPanel} authorization={auth} apol={apol} userRules={userRules} />
                                    </PivotItem>}
                                </Pivot>
                            }

                        </>

                    }
                    }
                </Query>
            )
            }
        </MyContext.Consumer>
    }

    validateRuleName = (name) => {

        //no empty name allowed
        if (name.length === 0) return false;

        return !this.rules.some(rule => {
            // Remove extra spaces from start and end from the name and rule name before comparing
            const trimmedName = name.trim();
            const trimmedRuleName = rule.name.trim();
            return trimmedName.toLowerCase() === trimmedRuleName.toLowerCase();
        });
    }

    configurationTextSelect() {
        switch (this.state.case) {
            case 0: return "userConfiguration"
            case 1: return "addRule"
            case 2: return "addUser"
            case 4: return "addCategory"
            case 5: return "addUser"
            case 6: return "addUser"
            case 7: return "addUser"
            case 8: return "addUser"
            case 9: return "addGroup"
            case 10: return "addGroup"
            case 11: return "addGroup"
            case 12: return "addGroup"
            case 13: return "addGroup"
            case 14: return "groupConfiguration"
            case 15: return "addUser"
            case 16: return "addUser"
            case 17: return "addUser"
            case 18: return "addUser"
            case 19: return "addGroup"
            case 20: return "addGroup"
            case 21: return "addGroup"
            case 22: return "addGroup"
            case 23: return "addGroup"
            case 24: return "exportJob"
            case 25: return "importJob"
            case 26: return "integrationJob"
            case 27: return "addCustomField"
            case 28: return "addGroup"
            case 29: return "addUser"
            default: return ""
        }
    }

    readRule = (id, group, auth) => {
        console.log("Group: " + group);

        switch (group) {
            case "recording":
                return (<MyContext.Consumer>
                    {(context) => (
                        <Query
                            query={queryGetRuleRecording}
                            fetchPolicy="network-only"
                            variables={{ id, auth }} >
                            {({ loading, error, data }) => {
                                if (loading) return <div>
                                    <Spinner label={context.getTranslation("common", "loadingRuleData")} ariaLive="assertive" labelPosition="top" />
                                </div>;
                                if (error) return <h1> </h1>;

                                if (data.getRuleRecording == null) {
                                    return <div> </div>;
                                }
                                return <AddRule
                                    closeModal={this._onDismissPanel}
                                    authorization={this.props.authorization}
                                    apol={this.props.apol}
                                    data={data.getRuleRecording}
                                    dataUsers={this.users}
                                    dataGroups={this.groups}
                                    dataCategory={this.categorydata}
                                    dataIntegration={this.categ}
                                    theme={this.props.theme}
                                    validateNameFcn={this.validateRuleName}
                                    userRules={this.props.userRules}
                                />

                            }
                            }
                        </Query>
                    )
                    }
                </MyContext.Consumer>
                );
            case "replay":
                return (<MyContext.Consumer>
                    {(context) => (
                        <Query
                            query={queryGetRuleReplay}
                            fetchPolicy="network-only"
                            variables={{ id, auth }} >
                            {({ loading, error, data }) => {
                                if (loading) return <div>
                                    <Spinner label={context.getTranslation("common", "loadingRuleData")} ariaLive="assertive" labelPosition="top" />
                                </div>;
                                if (error) return <h1> </h1>;

                                if (data.getRuleReplay == null) {
                                    return <div> </div>;
                                }
                                return <AddRule
                                    closeModal={this._onDismissPanel}
                                    authorization={this.props.authorization}
                                    apol={this.props.apol}
                                    data={data.getRuleReplay}
                                    dataUsers={this.users}
                                    dataGroups={this.groups}
                                    dataCategory={this.categorydata}
                                    dataIntegration={this.integrationdata}
                                    theme={this.props.theme}
                                    validateNameFcn={this.validateRuleName}
                                    userRules={this.props.userRules}
                                />

                            }
                            }
                        </Query>
                    )
                    }
                </MyContext.Consumer>

                );
            case "access":
                return (<MyContext.Consumer>
                    {(context) => (
                        <Query
                            query={queryGetRuleAccess}
                            fetchPolicy="network-only"
                            variables={{ id, auth }} >
                            {({ loading, error, data }) => {
                                if (loading) return <div>
                                    <Spinner label={context.getTranslation("common", "loadingRuleData")} ariaLive="assertive" labelPosition="top" />
                                </div>;
                                if (error) return <h1> </h1>;

                                if (data.getRuleAccess == null) {
                                    return <div> </div>;
                                }
                                return <AddRule
                                    closeModal={this._onDismissPanel}
                                    authorization={this.props.authorization}
                                    apol={this.props.apol}
                                    data={data.getRuleAccess}
                                    dataUsers={this.users}
                                    dataGroups={this.groups}
                                    dataCategory={this.categorydata}
                                    dataIntegration={this.integrationdata}
                                    theme={this.props.theme}
                                    validateNameFcn={this.validateRuleName}
                                    userRules={this.props.userRules}
                                />

                            }
                            }
                        </Query>
                    )
                    }
                </MyContext.Consumer>
                );
            case "analytics":
                return (<MyContext.Consumer>
                    {(context) => (
                        <Query
                            query={queryGetRuleAnalytics}
                            fetchPolicy="network-only"
                            variables={{ id, auth }} >
                            {({ loading, error, data }) => {
                                if (loading) return <div>
                                    <Spinner label={context.getTranslation("common", "loadingRuleData")} ariaLive="assertive" labelPosition="top" />
                                </div>;
                                if (error) return <h1> </h1>;

                                if (data.getRuleAnalytics == null) {
                                    return <div ></div>;
                                }
                                return <AddRule
                                    closeModal={this._onDismissPanel}
                                    authorization={this.props.authorization}
                                    apol={this.props.apol}
                                    data={data.getRuleAnalytics}
                                    dataUsers={this.users}
                                    dataGroups={this.groups}
                                    dataCategory={this.categorydata}
                                    dataIntegration={this.integrationdata}
                                    theme={this.props.theme}
                                    validateNameFcn={this.validateRuleName}
                                    userRules={this.props.userRules}
                                />


                            }
                            }
                        </Query>
                    )
                    }
                </MyContext.Consumer>
                );
            default: return <div> </div>
        }
    }

    panelSelect() {
        switch (this.state.case) {
            case 0: return <UserConfigContent
                data={{ rule: this.rules, user: this.state.info }}
                cl={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol}
                userRules={this.props.userRules}
                tenantData={this.tenantdata}
            />

            case 1:
                {
                    let out;
                    if (this.state.info != null) {
                        return this.readRule(this.state.info[0].id, this.state.info[0].group, this.props.authorization);
                    } else {
                        return <AddRule
                            closeModal={this._onDismissPanel}
                            authorization={this.props.authorization}
                            apol={this.props.apol}
                            data={out}
                            dataUsers={this.users}
                            theme={this.props.theme}
                            dataCategory={this.categorydata}
                            dataIntegration={this.integrationdata}
                            userRules={this.props.userRules}
                            validateNameFcn={this.validateRuleName}
                        />

                    }
                }
            case 2: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol}
                data={this.users}
                addType={"1"} userRules={this.props.userRules}
                tenantData={this.tenantdata}
            />
            case 3: return <DepartmentConfigContent data={{ rule: this.rules, user: this.state.info }} cl={this._onDismissPanel} authorization={this.props.authorization} apol={this.props.apol} />
            case 4:
                {
                    let alreadyExistingNames = this.categorydata !== null ? this.categorydata.map(c => c.name.toLowerCase()) : []
                    let out = null;
                    if (this.state.info != null) {
                        let tmpp = [];
                        if (this.state.info[0].keywords != null) {
                            for (let x = 0; x < this.state.info[0].keywords.length; x++) {
                                let splitKeywordAndSoundlike = this.state.info[0].keywords[x].split('_');
                                tmpp.push({ text: splitKeywordAndSoundlike[0], soundlike: splitKeywordAndSoundlike[1] });
                            }
                        }
                        out = {
                            sel4list: tmpp,
                            name: this.state.info[0].name,
                            sentiment: this.state.info[0].sentiment,
                            frequence: this.state.info[0].frequence,
                            link: this.state.info[0].link,
                            keyword: "",
                            id: this.state.info[0].id,
                            searchExactKeywords: this.state.info[0].searchExactKeywords,
                            countUniqueWordsOnce: this.state.info[0].countUniqueWordsOnce,
                            categorizationActionType: this.state.info[0].categorizationActionType,
                            retentionTimeSetting: this.state.info[0].retentionTimeSetting,
                            version: this.state.info[0].version,
                            masterItemId: this.state.info[0].masterItemId,
                            selected: this.state.info[0].selected,
                            selectedOtherVersion: false,
                            selectedVersion: this.state.info[0].id
                        }
                        alreadyExistingNames = alreadyExistingNames.filter(c => c !== this.state.info[0].name.toLowerCase())
                    }
                    return <AddCategorie
                        data={out}
                        cl={this._onDismissPanel}
                        authorization={this.props.authorization}
                        apol={this.props.apol}
                        userRules={this.props.userRules}
                        fromCompliance={false}
                        alreadyExistingNames={alreadyExistingNames}
                    />
                }
            case 5: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.users} addType={"2"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 6: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.users} addType={"3"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 7: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.users} addType={"4"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 8: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.users} addType={"5"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 9: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"5"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 10: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"3"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 11: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"4"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 12: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"1"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 13: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"2"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 14: return <GroupConfigContent data={{ rule: this.rules, user: this.state.info }} cl={this._onDismissPanel} authorization={this.props.authorization} apol={this.props.apol} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 15: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.users} addType={"6"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 16: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.users} addType={"7"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 17: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.users} addType={"8"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 18: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.users} addType={"9"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 19: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"6"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 20: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"7"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 21: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"8"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 22: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"9"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 23: return <ReportConfiguration
                id={Utils.guidGenerator()}
                title=""
                text=""
                icon=""
                type=""
                cycle=""
                keywords=""
                startDate=""
                endDate=""
                sortOrder={0}
                kpi=""
                tForm="cycle"
                isNew
                configurationReport
                theme={this.props.theme}

                authorization={this.props.authorization}
                apol={this.props.apol}
                userRules={this.props.userRules} />
            case 24: return <ExportConfig
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol}
                data={this.state.info != null ? this.state.info[0] : null}
                isNew={this.state.info == null}
                theme={this.props.theme}

            />
            case 25: return <ImportConfig
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol}
                data={this.state.info != null ? this.state.info[0] : null}
                isNew={this.state.info == null}
                theme={this.props.theme}

            />
            case 26: return <IntegrationConfig
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol}
                data={this.state.info != null ? this.state.info[0] : null}
                isNew={this.state.info == null}
                theme={this.props.theme}

            />
            case 27:
                {
                    let out = null;
                    if (this.state.info != null) {
                        let notificationsData = [];
                        let isMappingVal = false;

                        if (this.state.info[0].notifications !== "null"
                            && this.state.info[0].notifications !== ""
                            && this.state.info[0].notifications !== null
                            && this.state.info[0].notifications !== "[]"
                            && this.state.info[0].notifications !== undefined) {

                            notificationsData = JSON.parse(this.state.info[0].notifications);
                        }
                        if (this.state.info[0].mapping !== "" && this.state.info[0].mapping !== null && this.state.info[0].mapping !== undefined) {
                            isMappingVal = true;
                        }

                        out = {
                            id: this.state.info[0].id,
                            fieldName: this.state.info[0].fieldName,
                            mapping: this.state.info[0].mapping,
                            isMapping: isMappingVal,
                            isNumeric: this.state.info[0].isNumeric,
                            isAlphanumeric: this.state.info[0].isAlphanumeric,
                            maxNumberOfFields: this.state.info[0].maxNumberOfFields,
                            fieldLength: this.state.info[0].fieldLength,
                            hasExactLength: this.state.info[0].hasExactLength,
                            isCheckbox: this.state.info[0].isCheckbox,
                            hasTriggerAction: this.state.info[0].hasTriggerAction,
                            actionType: this.state.info[0].actionType,
                            ttlSettingYes: this.state.info[0].ttlSettingYes,
                            ttlSettingNo: this.state.info[0].ttlSettingNo,
                            notifications: notificationsData,
                            hasAdditionalNumberCheck: this.state.info[0].hasAdditionalNumberCheck,
                            numberCheckAlgorithmType: this.state.info[0].numberCheckAlgorithmType

                        }
                    }
                    return <AddCustomField
                        data={out}
                        cl={this._onDismissPanel}
                        authorization={this.props.authorization}
                        apol={this.props.apol}
                        userRules={this.props.userRules}
                        isEdit={this.state.isEdit}
                    />

                }
            case 28: return <AddGroup
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.groups} addType={"10"} userRules={this.props.userRules} tenantData={this.tenantdata} />
            case 29: return <AddUser
                closeModal={this._onDismissPanel}
                authorization={this.props.authorization}
                apol={this.props.apol} data={this.users} addType={"10"} userRules={this.props.userRules} tenantData={this.tenantdata} />

            default: return <></>

        }
    }

}
