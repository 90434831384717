import React, { Component } from 'react'
import { Stack, Spinner, DetailsList, SelectionMode, TextField, CommandBarButton, DetailsRow } from 'office-ui-fabric-react';
import { MyContext } from '../../../../../context';
import { deleteScorecardResult, upsertScorecardResult } from '../../../../ComplianceView/AIQualityManagement/AIQualityManagementQuery';
import SafetyDialog from '../../../../CustomComponents/SafetyDialog';
import AlertBar from '../../../../CustomComponents/AlertBar';
import ScorecardSettings from './ScorecardSettings';
import * as utils from "./ScorecardResultUtils"
import ScorecardFolding from './ScorecardFolding';

export default class ScorecardResult extends Component {

    static contextType = MyContext

    constructor(props) {
        super(props);
        this.state = {
            scorecardResult: this.cleanScorecardResult(props.scorecardResult),
            editMode: false,
            editModeComment: false,
            isProcessing: false,
            hideDialogDelete: true,
            warningMessage: "",
            alertType: "",
            selectedKey: []
        };
    }

    cleanScorecardResult = (scorecardResult) => {
        delete scorecardResult.__typename
        if (scorecardResult.settingItems !== null) {
            scorecardResult.settingItems.map(si => {
                delete si.__typename
                return si
            })
        }
        let sections = scorecardResult.sections.map(s => {
            s.evaluationItems.map(e => {
                delete e.__typename
                return e
            })
            delete s.__typename
            return s
        })
        return { ...scorecardResult, sections }
    }

    saveScorecardResult = () => {
        this.setState({
            editMode: false,
            editModeComment: false,
            isProcessing: true
        })
        let scorecardResult = this.state.scorecardResult
        scorecardResult.sections.forEach((section) => {
            section.evaluationItems.forEach((item) => {
                if (item.score === "") {
                    item.score = -1
                } else {
                    item.score = parseFloat(item.score)
                }
            })
        })
        this.props.apol.mutate({
            mutation: upsertScorecardResult,
            variables: { scorecardResult }
        }).then((result) => {
            if (result.data.upsertScorecardResult.status !== "ok") {
                this.setState({
                    warningMessage: this.context.getTranslation("compliance", result.data.upsertScorecardResult.text),
                    alertType: result.data.upsertScorecardResult.status,
                    isProcessing: false,
                    scorecardResult: this.cleanScorecardResult(this.props.scorecardResult)
                })
            } else {
                this.setState({ isProcessing: false })
                this.props.refreshResult()
            }

        });
    }

    isValidScorecardResult = () => {
        let validationScore = true
        this.state.scorecardResult.sections.forEach((section) => {
            section.evaluationItems.forEach((item) => {
                if (
                    (item.awareOfPoints && item.score > item.points) || isNaN(item.score) ||
                    (!item.awareOfPoints && item.score != item.points && item.score != "0" && item.score != "" && item.score !== -1)
                ) {
                    validationScore = false
                }
            })
        })
        return validationScore
    }

    deleteScorecardResult = () => {
        const scorecardResult = this.state.scorecardResult
        this.setState({ hideDialogDelete: true, isProcessing: true })
        if (scorecardResult.id !== null) {
            //console.log("delete scorecard result", scorecardResult.id)
            this.props.apol.mutate({
                mutation: deleteScorecardResult,
                variables: { id: scorecardResult.id }
            }).then((result) => {
                if (result.data.deleteScorecardResult.status !== "ok") {
                    this.setState({
                        warningMessage: this.context.getTranslation("compliance", result.data.deleteScorecardResult.text),
                        alertType: result.data.deleteScorecardResult.status,
                        isProcessing: false
                    })
                } else {
                    this.props.refreshResult()
                    this.setState({ isProcessing: false })
                    this.props.closeDetail()
                }

            });
        }
    }


    validateInput = (value) => {
        const regex = /^$|^([0-9]+([.,][0-9]*)?|[.,][0-9]+)$/;
        return regex.test(value);
    };

    change = (sectionName, evaluation, field, value) => {
        //console.log(sectionName, evaluation, field, value)
        let newSections = this.state.scorecardResult.sections.map((section) => {
            if (section.name === sectionName) {
                let newEvaluationItems = section.evaluationItems.map((item) => {
                    if (item.itemType === evaluation.itemType &&
                        item.itemValue === evaluation.itemValue &&
                        item.itemId === evaluation.itemId
                    ) {
                        if (field === "score" && this.validateInput(value)) {
                            let inputValue = value.replace(',', '.')
                            return { ...item, score: value !== "" ? inputValue : "" }
                        }
                        if (field === "comment") {
                            return { ...item, comment: value }
                        }
                    }
                    return item
                })
                return { ...section, evaluationItems: newEvaluationItems }
            } else {
                return section
            }
        })
        this.setState({ scorecardResult: { ...this.state.scorecardResult, sections: newSections } })
    }

    getEvaluation = () => {
        let totalScore = utils.getTotalScore(this.state.scorecardResult);
        let totalPoints = utils.getTotalPoints(this.state.scorecardResult);
        let rangeEvaluation = utils.getRangeEvaluation(this.state.scorecardResult, totalScore, totalPoints)
        let label = rangeEvaluation !== undefined ? rangeEvaluation.name : "N/A"
        let color = rangeEvaluation !== undefined ? rangeEvaluation.color : "trasparent"
        return (
            <Stack.Item style={{ backgroundColor: color, padding: "5px" }}>
                <h4 >{this.context.getTranslation("compliance", "totalScore")}: {`${label} (${totalScore}/${totalPoints})`}</h4>
            </Stack.Item>
        )
    }



    onRenderRow = (props) => {
        const customStyles = {}
        if (props) {
            if (props.item.itemName === "Section total") {
                customStyles.root = {
                    backgroundColor: props.item.color,
                    color: "black",
                    ":hover": {
                        backgroundColor: props.item.color,
                        color: "black",
                    }
                };
            }
            return <DetailsRow {...props} styles={customStyles} />
        }
        return null;
    }

    render() {
        return (<div>
            {this.state.isProcessing && <Spinner label={this.context.getTranslation("common", "loading")} ariaLive="assertive" labelPosition="top" />}
            {!this.state.isProcessing && <Stack>
                <Stack horizontal style={{ marginBottom: "8px" }}>
                    <Stack.Item style={{ marginLeft: "10px" }}>
                        <h4>{this.state.scorecardResult.scorecardName}</h4>
                    </Stack.Item>
                    <Stack horizontal style={{ marginLeft: 'auto' }}>
                        {!this.state.editMode &&
                            !this.state.editModeComment &&
                            this.state.scorecardResult.supervisors.includes(this.props.userRules.userId) &&
                            <CommandBarButton
                                styles={{ root: { marginTop: 10 } }}
                                iconProps={{ iconName: 'Edit' }}
                                text={this.context.getTranslation("configuration", "edit")}
                                //disabled={!this.state.scorecardResult.supervisors.includes(this.props.userRules.userId)}
                                checked={false}
                                onClick={() => {
                                    this.setState({
                                        editMode: true,
                                    })
                                }}
                            />
                        }
                        {(this.state.editMode || this.state.editModeComment) &&
                            <CommandBarButton
                                styles={{ root: { marginTop: 10 } }}
                                iconProps={{ iconName: 'Cancel' }}
                                text={this.context.getTranslation("common", "cancel")}
                                disabled={false}
                                checked={false}
                                onClick={() => {
                                    this.setState({
                                        editMode: false,
                                        editModeComment: false,
                                        scorecardResult: this.cleanScorecardResult(this.props.scorecardResult)
                                    })
                                }}
                            />
                        }
                        {(this.state.editMode || this.state.editModeComment) &&
                            <CommandBarButton
                                styles={{ root: { marginTop: 10 } }}
                                iconProps={{ iconName: 'Save' }}
                                text={this.context.getTranslation("common", "save")}
                                disabled={!this.isValidScorecardResult()}
                                checked={false}
                                onClick={this.saveScorecardResult}
                            />
                        }
                        {/*!this.state.editMode && !this.state.editModeComment &&
                            this.state.scorecardResult.supervisors.includes(this.props.userRules.userId) &&
                            <CommandBarButton
                                styles={{ root: { marginTop: 10 } }}
                                iconProps={{ iconName: 'Delete' }}
                                text={this.context.getTranslation("common", "remove")}
                                disabled={false}
                                checked={false}
                                onClick={() => { this.setState({ hideDialogDelete: false }) }}
                            />
                        */}
                        {this.props.showButtonCancel && !this.state.editMode && !this.state.editModeComment &&
                            <CommandBarButton
                                styles={{ root: { marginTop: 10 } }}
                                iconProps={{ iconName: 'Cancel' }}
                                disabled={false}
                                checked={false}
                                onClick={this.props.closeDetail}
                            />
                        }
                    </Stack>
                    {/*SafetyDialog for delete
                    <SafetyDialog
                        title={this.context.getTranslation("compliance", "deleteScorecardResult")}
                        subText={this.context.getTranslation("compliance", "confirmDelete")}
                        hideDialog={this.state.hideDialogDelete}
                        closeDialog={() => { this.setState({ hideDialogDelete: true }) }}
                        ok={this.deleteScorecardResult}
                    />*/}
                </Stack>
                {/*Warning and error message*/}
                <AlertBar
                    message={this.state.warningMessage}
                    alertType={this.state.alertType}
                    clearAlert={() => { this.setState({ warningMessage: "" }) }}
                />
                {this.state.scorecardResult.sections.map((section) => {
                    let totalScore = section.evaluationItems.reduce((acc, item) => acc + (item.score !== "" && item.score !== -1 ? parseFloat(item.score) : 0), 0)
                    let totalPoints = section.evaluationItems.reduce((acc, item) => acc + (item.score !== "" && item.score !== -1 ? item.points : 0), 0)
                    let rangeEvaluation = utils.getRangeEvaluation(this.state.scorecardResult, totalScore, totalPoints)
                    let color = rangeEvaluation !== undefined ? rangeEvaluation.color : "trasparent"
                    let evaluationItems = [
                        ...section.evaluationItems,
                        {
                            itemName: this.context.getTranslation("compliance", "totalEvaluationScorecard"),
                            score: totalScore,
                            points: totalPoints,
                            color
                        }
                    ]
                    let columns =
                        [
                            /*{
                                key: 'itemType',
                                name: section.name,
                                fieldName: 'itemType',
                                minWidth: 100,
                                maxWidth: 120,
                                isResizable: true,
                                onRender: (item) => {
                                    return <span>{this.context.getTranslation("compliance", item.itemType)}</span>
                                }
                            },*/
                            {
                                key: 'item',
                                fieldName: 'item',
                                name: section.name, //+ " (" + totalScore + "/" + totalPoints + ")",
                                minWidth: 500,
                                maxWidth: 500,
                                isResizable: true,
                                isMultiline: true,
                                onRender: (item) => {
                                    let style = item.score === "" || item.score === -1 ? { color: "grey" } : {}
                                    if (item.itemName === "Section total") {
                                        return <span style={{ fontWeight: "bold" }}>{item.itemName}</span>
                                    } else if (item.itemType === "QMQuestions") {
                                        return <span style={style}>{item.itemValue}</span>
                                    } else {
                                        return <span style={style}>{item.itemName}</span>
                                    }
                                }
                            },
                            {
                                key: 'score',
                                name: this.context.getTranslation("compliance", "scoring"),
                                fieldName: 'score',
                                minWidth: 50,
                                maxWidth: 50,
                                isResizable: true,
                                onRender: (item) => {
                                    let style = item.score === "" || item.score === -1 ? { color: "grey" } : {}
                                    if (item.itemName === "Section total") {
                                        return <span style={{ fontWeight: "bold" }}>{item.score}</span>
                                    } else if (!this.state.editMode) {
                                        return <span style={style}>{item.score === -1 ? "N/A" : item.score}</span>
                                    } else {
                                        let value = item.score === -1 ? "" : item.score
                                        if (item.awareOfPoints) {
                                            return <TextField
                                                value={value}
                                                onChange={(e) => this.change(section.name, item, "score", e.target.value)}
                                                description={value === "" ? `N/A` : ""}
                                            />
                                        } else {
                                            return <TextField
                                                value={value}
                                                onChange={(e) => this.change(section.name, item, "score", e.target.value)}
                                                description={value === "" ? `N/A` : `${this.context.getTranslation("compliance", "yesno")}`}
                                            />
                                        }

                                    }
                                }
                            },
                            {
                                key: 'points',
                                name: this.context.getTranslation("compliance", "points"),
                                fieldName: 'points',
                                minWidth: 50,
                                maxWidth: 50,
                                isResizable: true,
                                onRender: (item) => {
                                    let style = item.score === "" || item.score === -1 ? { color: "grey" } : {}
                                    if (item.itemName === "Section total") {
                                        return <span style={{ fontWeight: "bold" }}>{item.points}</span>
                                    } else if (!this.state.editMode) {
                                        return <span style={style}>{item.points}</span>
                                    } else {
                                        return <div style={{ ...style, marginTop: "8px" }}><span>{item.points}</span></div>
                                    }
                                }
                            },
                            {
                                key: 'comment',
                                name: this.context.getTranslation("settings", "ticketComment"),
                                fieldName: 'comment',
                                minWidth: 900,
                                maxWidth: 900,
                                isResizable: true,
                                isMultiline: true,
                                onRender: (item) => {
                                    let style = item.score === "" || item.score === -1 ? { color: "grey" } : {}
                                    if (!this.state.editMode || item.itemName === "Section total") {
                                        return <span style={style}>{item.comment}</span>
                                    } else {
                                        return <TextField
                                            value={item.comment}
                                            multiline={true}
                                            onChange={(e) => this.change(section.name, item, "comment", e.target.value)}
                                        />
                                    }
                                }
                            }

                        ]

                    return (
                        <Stack key={section.name} horizontal style={{ display: 'flex' }}>
                            <Stack.Item style={{ marginTop: "20px" }}>
                                <ScorecardFolding
                                    selectedKey={this.state.selectedKey}
                                    selected={section.name}
                                    open={() => {
                                        this.setState({
                                            selectedKey: [...this.state.selectedKey, section.name]
                                        })
                                    }}
                                    close={() => {
                                        this.setState({
                                            selectedKey: this.state.selectedKey.filter(s => s !== section.name)
                                        })
                                    }}
                                />
                            </Stack.Item>
                            {!this.state.selectedKey.includes(section.name) &&
                                <Stack.Item style={{ marginTop: "27px", marginLeft: "12px", fontWeight: 600, marginBottom: "10px" }}>
                                    <Stack horizontal>
                                        {section.name + " (" + totalScore + "/" + totalPoints + ")"}
                                        <div
                                            style={{
                                                width: "10px",
                                                height: "10px",
                                                backgroundColor: rangeEvaluation.color,
                                                borderRadius: "100%",
                                                marginTop: "6px",
                                                marginLeft: "8px"
                                            }}
                                        ></div>
                                    </Stack>
                                </Stack.Item>
                            }
                            {this.state.selectedKey.includes(section.name) &&
                                <Stack.Item style={{ flexGrow: 1, flexBasis: '100%' }}>
                                    <DetailsList
                                        items={evaluationItems}
                                        columns={columns}
                                        selectionMode={SelectionMode.none}
                                        onRenderRow={this.onRenderRow}
                                    />
                                </Stack.Item>
                            }

                        </Stack>
                    )
                })}
                <Stack horizontal horizontalAlign="space-between" style={{ marginLeft: "10px" }}>
                    <Stack.Item align="start" style={{ marginLeft: "20px", marginTop: "75px" }}>
                        {this.getEvaluation()}
                    </Stack.Item>
                    <Stack.Item align="end" style={{ marginLeft: "20px", marginTop: "-10px", zIndex: "-1" }}>
                        <ScorecardSettings
                            settingItems={this.state.scorecardResult.settingItems !== null ? this.state.scorecardResult.settingItems : []}
                        />
                    </Stack.Item>
                </Stack>

                <Stack horizontal style={{ marginLeft: "10px" }}>
                    <Stack.Item style={{ width: "100%", marginTop: "40px" }}>
                        <TextField
                            label={this.context.getTranslation("compliance", "assessorComment")}
                            value={this.state.scorecardResult.assessorComment}
                            multiline={true}
                            disabled={!this.state.scorecardResult.supervisors.includes(this.props.userRules.userId)}
                            onChange={(e) => {
                                this.setState({
                                    editModeComment: true,
                                    scorecardResult: { ...this.state.scorecardResult, assessorComment: e.target.value }
                                })
                            }}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>}
        </div>)
    }
}