import * as React from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import ComanderBar from "../ComanderBar/ComanderBar";
import { MyContext } from '../../../context';
import { Checkbox, TextField, Stack, Dropdown, Button, Modal, IconButton } from "office-ui-fabric-react";
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';

const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px'
    }
};

export interface IDocument {
    name: String;
    group: String;

}

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        height: "50%",
        width: "60%"
    },
    header: [
        {
            flex: '1 1 auto',
            //borderTop: '4px solid blue',
            // color: theme.palette.neutralPrimary,
            display: 'flex',
            // fontSize: FontSizes.xLarge,
            alignItems: 'center',
            // fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0',
            },
            'p:first-child': {
                marginTop: 0,
            },
            'p:last-child': {
                marginBottom: 0,
            },
        },
    },
});

const iconButtonStyles = mergeStyleSets({
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    }
});


export default class FieldsList extends React.Component<{}, IDetailsListDocumentsExampleState> {
    _selection: Selection;
    _allItems: IDocument[];

    constructor(props) {
        super(props);
        this._allItems = _generateDocuments(this.props.data);
        this.notificationsId = "";



        let columns: IColumn[] = [
            //{ key: 'customField', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "customfield")}</>)}</MyContext.Consumer>, fieldName: 'customField', minWidth: 100, maxWidth: 200 },
            { key: 'fieldName', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "customfieldName")}</>)}</MyContext.Consumer>, fieldName: 'fieldName', minWidth: 150, maxWidth: 200 },
            {
                key: 'mapping', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "mapping")}</>)}</MyContext.Consumer>, mapping: 'mapping', minWidth: 60, maxWidth: 60, onRender: (item) => {
                    if (item.mapping === "null" || item.mapping === "" || item.mapping === null || item.mapping === undefined) {
                        return <Button style={{ marginLeft: "-12px" }} disabled>No</Button>

                    } else {
                        return <Button
                            style={{ marginLeft: "-12px" }}
                            id={item.fieldName + "mapping"}
                            onClick={() => {
                                this.setState({ mappingItems: item.mapping })
                                this._openModalMapping();
                            }}
                        >
                            Yes
                        </Button>

                    }
                }
            },
            {
                key: 'hasAdditionalNumberCheck', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "checkLogic")}</>)}</MyContext.Consumer>, fieldName: 'hasAdditionalNumberCheck', onRender: (item) => {

                    return <Checkbox checked={item.hasAdditionalNumberCheck} />
                }, minWidth: 73, maxWidth: 73
            },
            {
                key: 'isNumeric', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "numeric")}</>)}</MyContext.Consumer>, fieldName: 'isNumeric', onRender: (item) => {

                    return <Checkbox checked={item.isNumeric} />
                }, minWidth: 55, maxWidth: 55
            },
            {
                key: 'isAlphanumeric', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "alphaNumeric")}</>)}</MyContext.Consumer>, fieldName: 'isAlphanumeric', minWidth: 95, maxWidth: 95, onRender: (item) => {
                    return <Checkbox checked={item.isAlphanumeric} />
                }
            },
            { key: 'maxNumberOfFields', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "maxAppear")}</>)}</MyContext.Consumer>, fieldName: 'maxNumberOfFields', minWidth: 81, maxWidth: 81 },
            { key: 'fieldLength', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "fieldLength")}</>)}</MyContext.Consumer>, fieldName: 'fieldLength', minWidth: 80, maxWidth: 80 },
            {
                key: 'hasExactLength', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "maxOrExact")}</>)}</MyContext.Consumer>, fieldName: 'hasExactLength', minWidth: 80, maxWidth: 80, onRender: (item) => {
                    return <Toggle checked={item.hasExactLength} onText="exact" />
                }
            },
            {
                key: 'isCheckbox', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "checkBox")}</>)}</MyContext.Consumer>, fieldName: 'isCheckbox', minWidth: 70, maxWidth: 70, onRender: (item) => {
                    return <Checkbox checked={item.isCheckbox} />
                }
            },
            {
                key: 'hasTriggerAction', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "triggerAction")}</>)}</MyContext.Consumer>, fieldName: 'hasTriggerAction', minWidth: 90, maxWidth: 90, onRender: (item) => {
                    return <Checkbox checked={item.hasTriggerAction} />
                }
            },
            {
                key: 'actionType', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "selectAction")}</>)}</MyContext.Consumer>, fieldName: 'actionType', minWidth: 170, maxWidth: 200, onRender: (item) => {
                    return <Stack horizontal>
                        <Dropdown options={[{ key: item.actionType, text: item.actionType }]} defaultSelectedKey={item.actionType} style={{ marginRight: "2px", width: "97px", marginLeft: "-10px" }} />
                        {item.actionType === "SetTTl" && <>
                            <div style={{ marginRight: "2px" }}>
                                <TextField value={item.ttlSettingYes} style={{ width: "40px" }} />
                                <span style={{ marginLeft: "25%" }}>Yes</span>
                            </div>
                            <div>
                                <TextField value={item.ttlSettingNo} style={{ width: "40px" }} />
                                <span style={{ marginLeft: "25%" }}>No</span>
                            </div>
                        </>}
                    </Stack>
                }
            },
            {
                key: 'notifications', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "notifications")}</>)}</MyContext.Consumer>, fieldName: 'notifications', minWidth: 100, maxWidth: 200, onRender: (item) => {

                    if (item.notifications === "null" || item.notifications === "" || item.notifications === null || item.notifications === "[]") {
                        return <Button disabled>No</Button>

                    } else {
                        return <Button
                            id={item.fieldName}
                            onClick={() => {
                                this._openModal();
                                this.notificationsId = item.fieldName
                            }}
                        >
                            Yes
                        </Button>

                    }
                }
            },


        ];


        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: true,
            count: 0,
            showModal: false,
            showModalMapping: false,
            mappingItems: ""
        };
    }

    columnsNotify = [
        {
            key: 'col1', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "time")}</>)}</MyContext.Consumer>, fieldName: 'NotificationTime', minWidth: 30, maxWidth: 40, isResizable: true, onRender: (item => {
                return <span>{item.NotificationTime} d</span>
            })
        },
        {
            key: 'col2', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "recipient")}</>)}</MyContext.Consumer>, fieldName: 'UsersToNotify', minWidth: 100, maxWidth: 200, isResizable: true,
            onRender: (item) => {
                let emailsList = item.UsersToNotify.split(',');
                return <p className="overflow" id="emails" >
                    {emailsList.map(email => {
                        return <a className="linebreaks" key={email}>{email}</a>
                    })}
                </p>
            }
        },
        {
            key: 'col6', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "sendToRecordingOwner")}</>)}</MyContext.Consumer>, fieldName: 'SendToRecordingOwner', minWidth: 160, maxWidth: 200, isResizable: true, onRender: (item) => {
                return <Checkbox checked={item.SendToRecordingOwner} />
            }
        },
        {
            key: 'col3', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "text")}</>)}</MyContext.Consumer>, fieldName: 'NotificationText', minWidth: 100, maxWidth: 200, isResizable: true,
            onRender: (item) => {
                return <p className="overflow">{item.NotificationText}</p>
            }
        },
        {
            key: 'col4', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "toDo")}</>)}</MyContext.Consumer>, fieldName: 'AddToToDo', minWidth: 40, maxWidth: 40, isResizable: true, onRender: (item) => {
                return <Checkbox checked={item.AddToToDo} />
            }
        },
        {
            key: 'col5', name: <MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "dependency")}</>)}</MyContext.Consumer>, fieldName: 'CheckboxDependencyType', minWidth: 100, maxWidth: 200, isResizable: true, onRender: (item) => {
                let result = ""
                switch (item.CheckboxDependencyType) {
                    case 'YesDependent': result = "'Yes' selected"; break;
                    case 'NoDependent': result = "'No' selected"; break;
                    case 'NoDependency': result = "'Nothing' selected"; break;
                }
                return result;
            }
        },
    ];
    setNotif = (id) => {
        this.notificationsId = id;
    }
    _closeModal = () => {
        this.notificationsId = ""
        this.setState({ showModal: false });
    }

    _openModal = () => {

        this.setState({ showModal: true });


    }
    _openModalMapping = () => {

        this.setState({ showModalMapping: true });


    }
    _closeModalMapping = () => {
        this.setState({ showModalMapping: false, mappingItems: "" });
    }
    render() {
        const { columns, isCompactMode } = this.state;

        return (
            <Fabric>
                {this.props.userRules.ruleAccess.confAdministrateCustomFields && <ComanderBar sel={"field"} onChangeX={this._onChangeText} stylesX={controlStyles} close={this.props.close} openNew={this.openNew} edit={this.openSelect} authorization={this.props.authorization} apol={this.props.apol} />}

                <DetailsList
                    key={"key" + this.state.count}
                    items={_generateDocuments(this.props.data)}
                    compact={isCompactMode}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    selectionMode={SelectionMode.single}
                />

                {/*notifications Modal*/}
                {this.state.showModal &&
                    <Modal
                        id="notificationsList"
                        isOpen={this.state.showModal}
                        onDismiss={this._closeModal}
                        isBlocking
                        containerClassName={contentStyles.container}
                    >
                        <div className={contentStyles.header}>
                            <span><b><MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "notifications")}</>)}</MyContext.Consumer></b></span>

                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel="Close popup modal"
                                onClick={this._closeModal}

                            />
                        </div>
                        <div className={contentStyles.body}>
                            <DetailsList
                                key={"notifications" + ""}
                                items={this._generateDocumentsNotifications(this.props.data)}
                                columns={this.columnsNotify}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="Row checkbox"
                                selectionMode={SelectionMode.none}
                                selection={this._selection}
                                checkboxVisibility={2}
                                styles={{ root: { color: "black" } }}

                            />
                        </div>
                    </Modal>
                }

                {/*    mapping modal*/}
                {this.state.showModalMapping &&
                    <Modal
                        id="mappingModal"
                        isOpen={this.state.showModalMapping}
                        onDismiss={this._closeModalMapping}
                        isBlocking
                        containerClassName={contentStyles.container}
                    >
                        <div className={contentStyles.header}>
                            <span><b><MyContext.Consumer>{(context) => (<> {context.getTranslation("configuration", "Mapping")}</>)}</MyContext.Consumer></b></span>

                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel="Close popup modal"
                                onClick={this._closeModalMapping}

                            />
                        </div>
                        <div className={contentStyles.body}>
                            <ul>
                                {this.state.mappingItems.split(",").map((item,i) => {
                                    return <li key={i}>{item}</li>
                                })}
                            </ul>
                        </div>
                    </Modal>
                }
            </Fabric>
        );
    }

    componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }


    _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isCompactMode: checked });
    };

    _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.setState({ isModalSelection: checked });
    };

    _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        let tmt;
        console.log(text)
        console.log(this._allItems)
        tmt = this._allItems.filter(i => i.name.includes(text) || i.name.toLocaleLowerCase().includes(text));
        console.log(tmt)

        this.setState({
            count: (this.state.count + 1),
            items: tmt
        });
    };


    _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                return 'One item selected: ' + (this._selection.getSelection()[0]).name;
            default:
                return `${selectionCount} Items selected`;
        }
    }
    openSelect = () => {
        if (this._selection.getSelection().length > 0) {
            this.props.func(this._selection.getSelection(), 27);
        }
    };
    openNew = () => {

        this.props.func(null, 27);

    };
    _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };
    _generateDocumentsNotifications = (DataArr) => {

        var notificationFindByName = "";
        for (let n = 0; n < DataArr.length; n++) {
            if (DataArr[n].fieldName === this.notificationsId) {
                notificationFindByName = JSON.parse(DataArr[n].notifications);
                break;
            }
        }
        return notificationFindByName;
    };
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _generateDocuments(DataArr) {
    const items = [];
    if (DataArr !== undefined && DataArr != null || DataArr !== "null") {
        for (let i = 0; i < DataArr.length; i++) {

            items.push(DataArr[i]);
        }
    }
    var resultItems = _copyAndSort(items, "group", false);
    return resultItems;
}

function trim(sel) {
    let out;
    const max = 40;
    if (sel !== undefined && sel != null && sel.length > max) {
        out = sel.substring(0, max) + "...";
    } else {
        out = sel;
    }

    return out
}
